/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Box, Flex, Text, Button, Center, useToast } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import { Formik, Form } from 'formik';
import BreadcrumbComponent from '../../../../components/BreadcrumbComponent/BreadcrumbComponent';
import TextFormField from '../../../../components/form/TextFormField';
import CheckBoxFormField from '../../../../components/form/CheckBoxFormField';
import * as Yup from 'yup';
import c from '../../../../constant';

const M_CREATE_DOCUMENT_TYPE_REF = gql`
	mutation create($khTitle: String!, $enTitle: String!, $description: String, $defaultChoice: Boolean) {
		createDocumentNumberTypeReference(khTitle: $khTitle, enTitle: $enTitle, description: $description, defaultChoice: $defaultChoice)
	}
`;

const validationSchema = Yup.object().shape({
	khTitle: Yup.string().required('required'),
	enTitle: Yup.string().required('required'),
	description: Yup.string(),
	defaultChoice: Yup.boolean(),
});

function ReferenceTypeAdd() {
	const { t } = useTranslation();
	const history = useHistory();
	const toast = useToast();
	const [createDocumentTypeReference, { error, loading, data }] = useMutation(M_CREATE_DOCUMENT_TYPE_REF);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if (data) {
			if (data?.createDocumentNumberTypeReference) {
				toast({
					title: t('Document type reference added successfully'),
					status: 'success',
					duration: 5000,
					isClosable: true,
					position: 'bottom-right',
				});
				history.push('/type-of-issued-letter?page=reference-type');
			}
		}
	}, [data]);

	useEffect(() => {
		if (error) {
			if (error?.graphQLErrors?.length > 0) {
				error?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(error?.message ? error?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [error]);

	return (
		<>
			<Container maxW="container.xl" mt="16px" mb="16px">
				<BreadcrumbComponent
					list={[
						{
							name: 'Settings',
							path: '/settings',
						},
						{
							name: 'TYPE OF ISSUED LETTER',
							path: '/type-of-issued-letter?page=reference-type',
						},
						{
							name: 'Add Reference Type',
							path: '#',
						},
					]}
				/>
				<Center mt="5">
					<Box
						w="100%"
						bg="white"
						borderRadius={c.borderRadius}
						border="1px solid #dbdbdb"
						boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
						pb="32px"
					>
						<Flex
							alignItems="center"
							justifyContent="space-between"
							bg="#FAFAFA"
							p="16px"
							pt="8px"
							pb="8px"
							mb="16px"
							borderBottom="1px solid #dbdbdb"
						>
							<Flex justifyContent="center" alignItems="center">
								<Text fontSize="x-large" fontWeight="bold" mr="4">
									{t('Add Reference Type')}
								</Text>
							</Flex>
						</Flex>
						<Box p="16px" maxW="480px">
							<Formik
								initialValues={{
									khTitle: '',
									enTitle: '',
									description: '',
									defaultChoice: false,
								}}
								validationSchema={validationSchema}
								onSubmit={(values) => {
									const variables = {
										khTitle: values.khTitle,
										enTitle: values.enTitle,
										description: values.description,
										defaultChoice: values.defaultChoice,
									};
									createDocumentTypeReference({ variables });
								}}
							>
								{(props) => (
									<Box>
										<Form>
											<TextFormField {...props} label="Khmer Title" name="khTitle" required />
											<TextFormField {...props} label="English Title" name="enTitle" required />
											<TextFormField {...props} label="Description" name="description" />
											<CheckBoxFormField {...props} label="Default" name="defaultChoice" />
											<Button
												id="login-btn"
												variant="solid"
												size="md"
												h="42px"
												w="128px"
												mt={4}
												type="submit"
												borderRadius={c.borderRadius}
												isLoading={loading}
											>
												{t('Submit')}
											</Button>
										</Form>
									</Box>
								)}
							</Formik>
						</Box>
					</Box>
				</Center>
			</Container>
		</>
	);
}

export default ReferenceTypeAdd;
