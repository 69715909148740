import React from "react";
import {
    Box,
    Flex,
    Text,
    Image
} from "@chakra-ui/react";
import './style.css'
import LOGO from "../../assets/images/logo.png"
import Underlint from "../../assets/images/underline.png"

// https://codepen.io/srees-evd/pen/wvvRmLw
function PrintLayout(props) {

    return (
        <>

  {/* <div class="page-header">
    I'm The Header
    <br/>
    <button type="button" onClick="window.print()">
      PRINT ME!
    </button>
  </div> */}

  <div class="page-footer">
  <div className="footer show-only-printing">
                <Flex mt="1" justifyContent="space-between">
                    <Box>
                        <Text fontSize="11px" color="#62B3ED" textAlign="left">
                            អគាលេខ ១៦៨ ( ជាន់ទី៦ )
                            ផ្លូវ ៥៩៨ សង្កាត់ច្រាំងចំរេះ១ ខណ្ឌឬស្សីកែវ<br />
                            រាជធានីភ្នំពេញ កម្ពុជា</Text>
                    </Box>
                    <Box>
                        <Text fontSize="11px" fontWeight="600" color="#62B3ED" textAlign="left">
                            Building # 168 (6th Floor),
                            St. 598, Sangkat Chraing Chamres 1,<br />
                            Khan Russey Keo, Phnom Penh, Cambodia
                        </Text>
                    </Box>
                </Flex>
            </div>
  </div>

  <table style={{width:"100%"}}>

    {/* <thead>
      <tr>
        <td>
          <div class="page-header-space"></div>
        </td>
      </tr>
    </thead> */}

    <tbody>
      <tr>
        <td style={{width:"100%"}}>
        <div className="header show-only-printing">
                <Flex>
                    <Flex minW="180px" w="180px" align="center" direction="column">
                        <Image mt="16px" src={LOGO} w="114px" h="114px" />
                        <Text color="#62B3ED" fontSize="14px" fontFamily="Moul">និយ័តករធានារ៉ាប់រងកម្ពុជា</Text>
                        {/* <Text
                            borderBottom="1px solid #457499"
                            lineHeight="16px"
                            mt="4px" color="#62B3ED" fontWeight="600">លេខ...............................ន.ធ.ក.</Text> */}
                    </Flex>
                    <Flex flexGrow="1" alignItems="center" direction="column">
                        <Text fontSize="14px" fontFamily="Moul" color="#62B3ED" >
                            ព្រះរាជាណាចក្រកម្ពុជា
                        </Text>
                        <Text mt="4px" color="#62B3ED" fontWeight="600">
                            ជាតិ សាសនា ព្រះមហាក្សត្រ
                        </Text>
                        <Image src={Underlint} h="auto" w="90px" />
                    </Flex>
                    <Flex w="170px" minW="170px"></Flex>
                </Flex>
            </div>
            <div className="content">
                <Box>
                    {props?.children ? props?.children : null}
                </Box>
            </div>
        </td>
      </tr>
    </tbody>

    <tfoot>
      <tr>
        <td>
          <div class="page-footer-space"></div>
        </td>
      </tr>
    </tfoot>

  </table>
        </>
    );
}

export default PrintLayout;
