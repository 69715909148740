import { Badge, Text, Tooltip } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const TerminationRequestStatus = ({ status = null }) => {
	const { t } = useTranslation();

	switch (status) {
		case 1:
			return (
				<Tooltip label={t('Reviewing By Office')}>
					<Badge pl={2} pr={2} colorScheme="blue" variant="solid">
						<Text fontWeight="bold" fontSize="xs" color="white">
							{t('Reviewing By Office')}
						</Text>
					</Badge>
				</Tooltip>
			);
		case 2:
			return (
				<Tooltip label={t('Reviewing By Department')}>
					<Badge pl={2} pr={2} colorScheme="blue" variant="solid">
						<Text fontWeight="bold" fontSize="xs" color="white">
							{t('Reviewing By Department')}
						</Text>
					</Badge>
				</Tooltip>
			);
		case 3:
			return (
				<Tooltip label={t('Done')}>
					<Badge pl={2} pr={2} colorScheme="green" variant="solid">
						<Text fontWeight="bold" fontSize="xs" color="white">
							{t('Done')}
						</Text>
					</Badge>
				</Tooltip>
			);
		default:
			return (
				<Text fontSize="sm" fontWeight="medium">
					{t('N/A')}
				</Text>
			);
	}
};

export default TerminationRequestStatus;
