import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Text,
    Box,
    Spinner,
    Table,
    Tbody,
    Tr,
    Td,
    TableContainer,
} from "@chakra-ui/react";
import c from "../../constant"
import { gql, useLazyQuery } from '@apollo/client';

const QUERY_DATA = gql`
query get($id: String!){
    getCamdigikeyUserById(id:$id){
        firstNameEn
        firstNameKh
        lastNameEn
        lastNameKh
        dob
        email
        gender
        mobilePhone
        nationality
        personalCode
    }
  }
`




const QUERY_COMPANY_INFO_DATA = gql`
query get($id: String!){
    getCompanyShortInfoById(id:$id){
        name
        nameKhmer
        logo 
    }
  }
`

const QUERY_CLIENT_AUTH_DATA = gql`
query get($id: String!){
    getClientUserById(id:$id){
        id
        companyId
        camDigiKeyId
        loginType
    }
  }
`



function ViewCamDigiUser({
    id,
    onClose
}) {
    const { t } = useTranslation();
    const [loadData, { error, loading, data }] = useLazyQuery(QUERY_DATA)
    const [loadClientData, { error:clientError, loading: clientLoading, data: clientData }] = useLazyQuery(QUERY_CLIENT_AUTH_DATA)
    const [loadCompanyData, { error:companyError, loading: companyLoading, data: companyData }] = useLazyQuery(QUERY_COMPANY_INFO_DATA)

    useEffect(() => {
        loadClientData({
            variables: {
                id
            }
        })
        
    }, [])

    useEffect(()=>{
        if(clientData?.getClientUserById){
            if(clientData?.getClientUserById?.loginType==="CLI"){
                loadCompanyData({
                    variables:{
                        id: clientData?.getClientUserById?.companyId
                    }
                })
            } else {
                loadData({
                    variables: {
                        id:clientData?.getClientUserById?.camDigiKeyId
                    }
                })
            }
        }
    },[clientData])

    return (
        <Modal
            isOpen={true}
            onClose={() => {
                onClose()
            }}
            size="md"
        >
            <ModalOverlay />
            <ModalContent pb="4" borderRadius={c.borderRadius}>
                <ModalHeader> {t("Submitted by")}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Box w="100%" pb="16px">
                        {error && <Text color="red">Can't load data</Text>}
                        {(loading||clientLoading||companyLoading) && <center>
                            <Spinner
                                thickness='4px'
                                speed='0.65s'
                                emptyColor='gray.200'
                                color='blue.500'
                                size='xl'
                            />
                        </center>}

                        {data?.getCamdigikeyUserById&&<TableContainer>
                            <Table size='sm'>
                                <Tbody>
                                    <Tr>
                                        <Td>{t("First Name Kh")}</Td>
                                        <Td>{data?.getCamdigikeyUserById?.firstNameKh}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td>{t("Last Name Kh")}</Td>
                                        <Td>{data?.getCamdigikeyUserById?.lastNameKh}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td>{t("First Name En")}</Td>
                                        <Td>{data?.getCamdigikeyUserById?.firstNameEn}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td>{t("Last Name En")}</Td>
                                        <Td>{data?.getCamdigikeyUserById?.lastNameEn}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td>{t("DOB")}</Td>
                                        <Td>{data?.getCamdigikeyUserById?.dob}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td>{t("Email")}</Td>
                                        <Td>{data?.getCamdigikeyUserById?.email}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td>{t("Gender")}</Td>
                                        <Td>{data?.getCamdigikeyUserById?.gender}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td>{t("Mobile Phone")}</Td>
                                        <Td>{data?.getCamdigikeyUserById?.mobilePhone}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td>{t("Nationality")}</Td>
                                        <Td>{data?.getCamdigikeyUserById?.nationality}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td>{t("ID Card Number")}</Td>
                                        <Td>{data?.getCamdigikeyUserById?.personalCode}</Td>
                                    </Tr>
                                </Tbody>
                            </Table>
                        </TableContainer>}
                        {
                            companyData?.getCompanyShortInfoById&&<TableContainer>
                            <Table size='sm'>
                                <Tbody>
                                    <Tr>
                                        <Td>{t("Company Name")}</Td>
                                        <Td>{companyData?.getCompanyShortInfoById?.nameKhmer}</Td>
                                    </Tr>

                                    <Tr>
                                        <Td>{t("Company Name Latin")}</Td>
                                        <Td>{companyData?.getCompanyShortInfoById?.name}</Td>
                                    </Tr>
                                </Tbody>
                            </Table>
                        </TableContainer>
                        }
                    </Box>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

export default ViewCamDigiUser;
