import { Badge, Text, Tooltip } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const TerminationRequestEditingStatus = () => {
	const { t } = useTranslation();

	return (
		<Tooltip label={t('EDITING')}>
			<Badge pl={2} pr={2} colorScheme="yellow" variant="outline">
				<Text fontWeight="bold" fontSize="xs" color="yellow.600">
					{t('EDITING')}
				</Text>
			</Badge>
		</Tooltip>
	);
};

export default TerminationRequestEditingStatus;
