/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSlidersH } from '@fortawesome/free-solid-svg-icons';
import { SectionHeader } from '../../Other';
import { Box, Flex, Button, Table, Thead, Tbody, Tfoot, Tr, Th, Td, Center, useDisclosure, Badge } from '@chakra-ui/react';
import ReactPaginate from 'react-paginate';
import c from '../../../constant';
import SaleStaffModal from '../../../containers/CompanySaleProfessional/component/SaleStaffModal';
import LocalizedDate from '../../../components/LocalizedDate';

const Q_GET_COMPANY_AGENTS = gql`
	query get($offset: Float, $companyId: String, $status: String) {
		getCompanySaleProfessionalList(pagerInput: { limit: 10, offset: $offset }, companyId: $companyId, status: $status) {
			totalRows
			totalPages
			list {
				id
				idCardNumber
				firstName
				lastName
				firstNameEn
				lastNameEn
				gender
				dob
				company {
					id
					name
					nameKhmer
					logo
					type
				}
				status
			}
		}
	}
`;

let limit = 10;
let offset = 0;
let name = null;
let branch = null;
let type = null;

function DistributionSystemSaleStaff({ id, ...props }) {
	const { t } = useTranslation();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [loadCompanySaleStaff, { loading, data }] = useLazyQuery(Q_GET_COMPANY_AGENTS);
	const [openData, setOpenData] = useState(null);

	useEffect(() => {
		offset = 0;
		getCompanyAgents();
	}, [id]);

	function onPageChange(value) {
		offset = value * limit;
		getCompanyAgents();
	}

	function getCompanyAgents() {
		const variables = {
			status: 'approved',
			companyId: id,
			offset,
		};
		loadCompanySaleStaff({ variables });
	}

	function getStatus(status) {
		switch (status) {
			case 'active':
				return <Badge colorScheme="green">{t('ACTIVE')}</Badge>;
			case 'terminated':
				return <Badge colorScheme="red">{t('Terminated')}</Badge>;
			default:
				return null;
		}
	}

	return (
		<>
			<Flex mt={6} {...props}>
				<Box className="hidden-print" width="100%">
					<SectionHeader title={t('Sale Staff')} />
					<Center mt={4}>
						<Box
							w="100%"
							bg="white"
							borderRadius={c.borderRadius}
							border="1px solid #dbdbdb"
							boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
							pb="32px"
						>
							<Flex p={4} pt={2} pb={2} mb={4} h="48px" bg="#FAFAFA" alignItems="center" justifyContent="end" borderBottom="1px solid #dbdbdb">
								<Flex alignItems="center">
									{/* <Button
										onClick={onOpen}
										leftIcon={<FontAwesomeIcon icon={faSlidersH} style={{ fontSize: 16 }} />}
										colorScheme={`${name || branch || type ? 'yellow' : 'gray'}`}
										variant="solid"
										size="sm"
										borderRadius={c.borderRadius}
										border="1px solid #bdc3c7}"
									>
										{t('Filter')}
									</Button> */}
								</Flex>
							</Flex>

							<Box p="16px">
								<Table variant="simple" size="md">
									<Thead>
										<Tr>
											<Th>{t('ID Card Number')}</Th>
											<Th>{t('First Name')}</Th>
											<Th>{t('Last Name')}</Th>
											<Th>{t('First Name En')}</Th>
											<Th>{t('Last Name En')}</Th>
											<Th>{t('Gender')}</Th>
											<Th>{t('DOB')}</Th>
											<Th isNumeric>{t('Status')}</Th>
										</Tr>
									</Thead>
									<Tbody>
										{loading && (
											<>
												<Tr>
													<Td colSpan={8}>
														<Flex justifyContent="center" alignItems="center">
															{t('Loading')}
														</Flex>
													</Td>
												</Tr>
											</>
										)}
										{data?.getCompanySaleProfessionalList?.list?.length <= 0 && (
											<>
												<Tr>
													<Td colSpan={8}>
														<Flex justifyContent="center" alignItems="center">
															{t('No Data')}
														</Flex>
													</Td>
												</Tr>
											</>
										)}
										{data?.getCompanySaleProfessionalList?.list?.map((item, index) => {
											return (
												<Tr
													key={index}
													cursor="pointer"
													_hover={{ bg: 'gray.100' }}
													onMouseDown={() => {
														onOpen();
														setOpenData(item);
													}}
												>
													<Td>{item?.idCardNumber}</Td>
													<Td>{item?.firstName}</Td>
													<Td>{item?.lastName}</Td>
													<Td>{item?.firstNameEn}</Td>
													<Td>{item?.lastNameEn}</Td>
													<Td>{t(item?.gender)}</Td>
													<Td>
														<LocalizedDate dateString={item?.dob} dateFormat={c.dateOnlyFormat} />
													</Td>
													{/* <Td>
																<Text noOfLines={1}>
																	<CompanyName company={item?.company} />
																</Text>
															</Td> */}
													<Td isNumeric>{getStatus(item?.status === 'approved' ? 'active' : item?.status)}</Td>
												</Tr>
											);
										})}
									</Tbody>
									<Tfoot>
										<Tr>
											<Th>{t('ID Card Number')}</Th>
											<Th>{t('First Name')}</Th>
											<Th>{t('Last Name')}</Th>
											<Th>{t('First Name En')}</Th>
											<Th>{t('Last Name En')}</Th>
											<Th>{t('Gender')}</Th>
											<Th>{t('DOB')}</Th>
											<Th isNumeric>{t('Status')}</Th>
										</Tr>
									</Tfoot>
								</Table>

								<Flex justifyContent="flex-end" w="100%" mt="4">
									{data?.getCompanySaleProfessionalList?.totalPages > 1 && (
										<ReactPaginate
											previousLabel={<ChevronLeftIcon fontSize="20px" />}
											nextLabel={<ChevronRightIcon fontSize="20px" />}
											breakLabel={'...'}
											breakClassName={'break-me'}
											disableInitialCallback={true}
											pageCount={data?.getCompanySaleProfessionalList?.totalPages}
											marginPagesDisplayed={2}
											pageRangeDisplayed={3}
											onPageChange={({ selected }) => onPageChange(selected)}
											containerClassName={'pagination'}
											activeClassName={'active'}
											initialPage={offset / limit}
										/>
									)}
								</Flex>
							</Box>
						</Box>
					</Center>
				</Box>
			</Flex>

			<SaleStaffModal isOpen={isOpen} onClose={() => onClose()} data={openData} />
		</>
	);
}

export default DistributionSystemSaleStaff;
