import c from "../constant"
const getDepartmentLabel=(value)=>{
    let label = ""
    c.departments.map((d)=>{
        if(d.value===value){
            label = d.label
        }
    })
    return label
}

export default getDepartmentLabel