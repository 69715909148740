/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { Center, Box, Flex, Text, Table, Tbody, Tr, Th, Td, Tfoot, Checkbox, Badge, Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { gql, useLazyQuery } from '@apollo/client';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { faSlidersH, faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CompanyDetailReportFilter } from '../../Filter';
import { SectionHeader } from '../../Other';
import c from '../../../constant';
import dateFromString from '../../../utils/datePickerFromToToDate';
import useStore from '../../../store';
import useMoment from '../../../hooks/useMoment';
import ReactPaginate from 'react-paginate';
import LoadingTable from '../../Table/Loading';
import ReportPrint from './ReportPrint';

const tabList = [
	{ label: 'All', value: 'all' },
	{ label: 'Monthly', value: 'MONTHLY' },
	{ label: 'Quarterly', value: 'QUARTERLY' },
	{ label: 'Semesterly', value: 'SEMESTERLY' },
	{ label: 'Annually', value: 'ANNUALLY' },
];

const QUERY_REPORTS = gql`
	query getCompanySubmissionReportsByCompanyId(
		$companyId: String!
		$limit: Float
		$offset: Float
		$fromDate: DateTime
		$toDate: DateTime
		$read: Boolean
		$type: CompanySubmissionReportTypes
		$spam: Boolean
		$reportType: CompanySubmissionReportTypesOfReport
		$newReportType: String
	) {
		getCompanySubmissionReportsByCompanyId(
			pagerInput: { limit: $limit, offset: $offset }
			fromDate: $fromDate
			toDate: $toDate
			read: $read
			companyId: $companyId
			type: $type
			spam: $spam
			reportType: $reportType
			newReportType: $newReportType
		) {
			companySubmissionReports {
				id
				companyName
				createdAt
				type
				reportType
				spam
				company {
					id
					name
					nameKhmer
					logo
				}
				notification {
					read
				}
				assignTo {
					user {
						id
						username
						fullName
						fullNameKhmer
						avatar
					}
					createdBy
				}
				newTypeDetail {
					titleKh
					title
				}
			}
			countUnreadCompanySubmissionReports
			totalPages
			totalRows
		}
	}
`;

let maxLimit = 300;
let minLimit = 10;
let offset = 0;
let fromDate = null;
let toDate = null;
let type = null;
let newReportType = null;
let unread = null;
let spam = null;

function Report({ companyId, onDataChanged, onPrint, ...props }) {
	const history = useHistory();
	const { t } = useTranslation();
	const { toDateString } = useMoment();
	const { currentLang } = useStore((state) => state.currentLang);
	const [loadReports, { loading, data }] = useLazyQuery(QUERY_REPORTS);
	const [isShowingMore, setShowingMore] = useState(false);
	const [isShowingFilterModal, setShowingFilterModal] = useState(false);

	const companyChange = useCallback(() => {
		offset = 0;
	}, [companyId]);

	const loadCompanyReports = useCallback(() => {
		const variables = {
			companyId,
			limit: isShowingMore ? maxLimit : minLimit,
			offset,
			fromDate: dateFromString(fromDate),
			toDate: dateFromString(toDate),
			type: type === 'all' ? null : type,
			newReportType: newReportType,
			read: unread ? false : null,
			spam: spam,
		};
		loadReports({ variables });
	}, [companyId, isShowingMore]);

	useEffect(() => {
		companyChange();
	}, [companyChange]);

	useEffect(() => {
		loadCompanyReports();
	}, [loadCompanyReports]);

	useEffect(() => {
		if (data) {
			onDataChanged(data);
		}
	}, [data]);

	function onApplyFilter(e) {
		offset = 0;
		if (e.dateRange) {
			const range = JSON.parse(e.dateRange);
			if (range?.from && range?.to) {
				fromDate = JSON.stringify(range.from);
				toDate = JSON.stringify(range.to);
			} else {
				fromDate = null;
				toDate = null;
			}
		} else {
			fromDate = null;
			toDate = null;
		}
		type = e.type;
		newReportType = e.newReportType;
		unread = e.unread;
		spam = e.spam;
		loadCompanyReports();
	}

	function panelTabChange(value) {
		offset = 0;
		type = value;
		newReportType = null;
		loadCompanyReports();
	}

	function onShowingMore() {
		offset = 0;
		setShowingMore(!isShowingMore);
	}

	function onPageChange(value) {
		offset = value * (isShowingMore ? maxLimit : minLimit);
		loadCompanyReports();
	}

	function panelTab({ name, value }) {
		let active = type ? type : 'all';
		return (
			<Flex
				key={name}
				userSelect="none"
				minW="100px"
				onClick={() => panelTabChange(value)}
				cursor="pointer"
				mb={'-2px'}
				_hover={{
					boxShadow: `inset 0 -2px 0 0 ${value === active ? '#2980b9' : '#bdc3c7'}`,
				}}
				boxShadow={value === active ? 'inset 0 -2px 0 0 #2980b9' : ''}
				pt="16px"
				pb="16px"
				pl="12px"
				pr="12px"
				alignItems="center"
				justifyContent="center"
			>
				<Text>{t(name)}</Text>
			</Flex>
		);
	}

	function getDisplayCompanyName(item) {
		let displayName = item?.companyName;
		if (item?.company?.id) {
			if (currentLang === 'kh') {
				displayName = item?.company?.nameKhmer;
			} else {
				displayName = item?.company?.name;
			}
		}
		return displayName;
	}

	function reportTypeOfReports(type, newTypeDetail) {
		switch (type) {
			case 'FINANCIAL_REPORT':
				return <Badge colorScheme="green">{t('Financial Report')}</Badge>;
			case 'COMPLAINT_SETTLEMENT_REPORT':
				return <Badge colorScheme="yellow">{t('Complaint Settlement Report')}</Badge>;
			case 'INSURANCE_AGENT_REPORT':
				return <Badge colorScheme="purple">{t('Insurance Agent Report')}</Badge>;
			case 'NEW_TYPE':
				return <Badge colorScheme="gray">{currentLang === 'kh' ? newTypeDetail?.titleKh : newTypeDetail?.title}</Badge>;
			default:
				break;
		}
	}

	function reportTypeBadge(type) {
		switch (type) {
			case 'MONTHLY':
				return <Badge colorScheme="green">{t('MONTHLY')}</Badge>;
			case 'QUARTERLY':
				return <Badge colorScheme="yellow">{t('QUARTERLY')}</Badge>;
			case 'SEMESTERLY':
				return <Badge colorScheme="purple">{t('SEMESTERLY')}</Badge>;
			case 'ANNUALLY':
				return <Badge colorScheme="red">{t('ANNUALLY')}</Badge>;
			default:
				return null;
		}
	}

	return (
		<>
			<Flex className="hidden-print" mt={6} {...props}>
				<Box width="100%">
					<SectionHeader title={t('Report')} />
					<Center mt={4}>
						<Box
							w="100%"
							bg="white"
							borderRadius={c.borderRadius}
							border="1px solid #dbdbdb"
							boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
							pb={4}
						>
							<Flex
								h="48px"
								p={4}
								pt={0}
								pb={0}
								mb={4}
								bg="#FAFAFA"
								alignItems="center"
								justifyContent="space-between"
								borderBottom="1px solid #dbdbdb"
							>
								<Flex pb="2px">
									{tabList.map((item) => {
										return panelTab({ name: item.label, value: item.value });
									})}
								</Flex>
								<Flex alignItems="center">
									<Button
										mr="2"
										onClick={onPrint}
										leftIcon={<FontAwesomeIcon icon={faPrint} style={{ fontSize: 16 }} />}
										colorScheme="gray"
										variant="solid"
										size="sm"
										borderRadius={c.borderRadius}
										border="1px solid #bdc3c7"
									>
										{t('Print')}
									</Button>
									<Button
										onClick={() => setShowingFilterModal(true)}
										leftIcon={<FontAwesomeIcon icon={faSlidersH} style={{ fontSize: 16 }} />}
										colorScheme={`${fromDate || toDate || unread || spam || newReportType ? 'yellow' : 'gray'}`}
										variant="solid"
										size="sm"
										borderRadius={c.borderRadius}
										border="1px solid #bdc3c7"
									>
										{t('Filter')}
									</Button>
								</Flex>
							</Flex>
							<Box p="16px">
								<Table variant="simple">
									<Tbody>
										<Tr className="td-as-th">
											<Th>{t('Company Name')}</Th>
											<Th>{t('Created At')}</Th>
											<Th isNumeric>{t('Report Type')}</Th>
											<Th isNumeric>{t('Type')}</Th>
										</Tr>
										{loading && !data && <LoadingTable column={4} row={5} />}
										{data?.getCompanySubmissionReportsByCompanyId?.companySubmissionReports.length <= 0 && (
											<Tr>
												<Td>
													<Text>{t('No Data')}</Text>
												</Td>
											</Tr>
										)}
										{data?.getCompanySubmissionReportsByCompanyId?.companySubmissionReports?.map((item, index) => {
											return (
												<Tr
													cursor="pointer"
													_hover={{ bg: 'gray.200' }}
													onClick={() => history.push(`/company-submission-report/detail?id=${item.id}`)}
													key={`item-${index}`}
													bg={item?.notification?.read === false ? 'gray.100' : 'white'}
												>
													<Td fontWeight="500">
														<Text maxW="400px" noOfLines={4}>
															{getDisplayCompanyName(item)}
														</Text>
													</Td>
													<Td>{toDateString(item.createdAt, c.longDateFormat)}</Td>
													<Td isNumeric>{reportTypeOfReports(item.reportType, item?.newTypeDetail)}</Td>
													<Td isNumeric>
														{reportTypeBadge(item.type)} {item.spam === true && <Badge colorScheme="red">{t('Spam')}</Badge>}
													</Td>
												</Tr>
											);
										})}
									</Tbody>
									<Tfoot>
										<Tr>
											<Th>{t('Company Name')}</Th>
											<Th>{t('Created At')}</Th>
											<Th isNumeric>{t('Report Type')}</Th>
											<Th isNumeric>{t('Type')}</Th>
										</Tr>
										<Tr>
											<Th />
											<Th />
											<Th />
											<Th isNumeric>
												{t('Total Rows')}: {data?.getCompanySubmissionReportsByCompanyId?.totalRows}
											</Th>
										</Tr>
									</Tfoot>
								</Table>
								<Flex justifyContent="flex-end" w="100%" mt="4">
									{data?.getCompanySubmissionReportsByCompanyId?.totalPages > 1 && (
										<ReactPaginate
											previousLabel={<ChevronLeftIcon fontSize="20px" />}
											nextLabel={<ChevronRightIcon fontSize="20px" />}
											breakLabel={'...'}
											breakClassName={'break-me'}
											pageCount={data?.getCompanySubmissionReportsByCompanyId?.totalPages}
											marginPagesDisplayed={2}
											pageRangeDisplayed={3}
											disableInitialCallback={true}
											onPageChange={({ selected }) => onPageChange(selected)}
											containerClassName={'pagination'}
											activeClassName={'active'}
											initialPage={offset / (isShowingMore ? maxLimit : minLimit)}
										/>
									)}
								</Flex>
								<Box justifyContent="flex-end" className="show-only-printing">
									<Text ml="2" mt="4">
										{t('Total Rows')}: <b>{data?.getCompanySubmissionReportsByCompanyId?.totalRows}</b>
									</Text>
								</Box>
								{data?.getCompanySubmissionReportsByCompanyId?.companySubmissionReports.length > 0 && (
									<Flex justifyContent="flex-end" mt="6">
										<Text mr="2">{t('Show more rows')}</Text>
										<Checkbox isChecked={isShowingMore} onChange={onShowingMore}></Checkbox>
									</Flex>
								)}
							</Box>
						</Box>
					</Center>
				</Box>
			</Flex>
			{isShowingFilterModal && (
				<CompanyDetailReportFilter
					onApply={onApplyFilter}
					onClose={() => setShowingFilterModal(false)}
					fromDate={fromDate}
					toDate={toDate}
					type={type}
					newReportType={newReportType}
					unread={unread}
					spam={spam}
				/>
			)}
			{data && <ReportPrint data={data} />}
		</>
	);
}

export default Report;
