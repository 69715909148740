import React, { useEffect, } from "react";
import { useTranslation } from "react-i18next";
import { Container, Center, Box, Flex, Text, useToast, Table, Thead, Tr, Th, Tbody, Td, Tfoot, Button } from "@chakra-ui/react";
import BreadcrumbComponent from "../../../components/BreadcrumbComponent/BreadcrumbComponent";
import { Link } from "react-router-dom";
import { useLocation, useHistory } from "react-router-dom";
import { capitalizeFirstLetter } from "../../../utils"
import { gql, useLazyQuery } from '@apollo/client';
import ReactPaginate from 'react-paginate';
import '../style.css'
import c from "../../../constant"
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import LoadingTable from "../../../components/Table/Loading";
import { templateSettings } from "lodash";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const limit = 10
const QUERY_FORM_SETTING = gql`
query list($offset:Float){
    getPublicFormList(
        pagerInput:{
            limit:${limit}
            offset:$offset
        }
    ){
        publicForms{
            id
            title
            header
            formSetting
        }
        totalPages
    }
  }
`

function PublicFormList() {
    const toast = useToast()
    const { t } = useTranslation();
    const location = useLocation();
    const history = useHistory();
    const [loadFormSetting, { error, loading, data }] = useLazyQuery(QUERY_FORM_SETTING)
    const urlParams = new URLSearchParams(location.search);
    const category = urlParams.get('category');
    const currentPage = urlParams.get('page')?urlParams.get('page'):1;
    useEffect(() => {
        window.scrollTo(0, 0);
        loadFormSetting({
            variables:{
                offset:currentPage?((currentPage-1)*limit):0,
            }
        })
    }, []);

    function handlePageClick(data){
        let selected = data.selected;
        const page= selected+1
        history.push(`/website/articles?category=${category}&page=${page}`)
    }

    useEffect(()=>{
        if(error){
            error.graphQLErrors.map(({ message }, i) => (
                toast({
                    title: message,
                    status: "error",
                    isClosable: true,
                    position:"top-right"
                })
            ))
        }
    },[error])

    useEffect(()=>{
        if(currentPage){
            window.scrollTo(0, 0);
            loadFormSetting({
                variables:{
                    offset:currentPage?((currentPage-1)*limit):0,
                }
            })
        }
    },[currentPage])

    return (
        <>
            <Container maxW="container.xl" mt="16px" mb="16px">
                <BreadcrumbComponent
                    list={[
                        {
                            name: "WEBSITE",
                            path: "/website",
                        },
                        {
                            name: "Public Form",
                            path: "#",
                        },
                    ]}
                />
                <Center mt="5">
                    <Box
                        w="100%"
                        bg="white"
                        borderRadius={c.borderRadius}
                        border="1px solid #dbdbdb"
                        boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
                        pb="32px"
                    >
                        <Flex bg="#FAFAFA" p="16px" pt="8px" pb="8px" mb="16px" justifyContent="space-between" borderBottom="1px solid #dbdbdb">
                            <Text
                                fontSize="x-large"
                                fontWeight="bold"
                            >
                                {t("Public Form")}
                            </Text>
                            <Link to={`/website/public-form-add`}>
                                <Button
                                    leftIcon={<FontAwesomeIcon icon={faPlus} style={{ fontSize: 16 }} />}
                                    colorScheme='gray'
                                    variant='solid'
                                    size="sm"
                                    borderRadius={c.borderRadius}
                                    border="1px solid #bdc3c7"
                                >
                                    {t("Add")}
                                </Button>
                            </Link>
                        </Flex>
                        {!error &&
                            // <Box p="16px">
                            //     {data?.getPublicFormList?.publicForms.map((item, index) => {
                            //         return (
                            //             <Box>
                            //             <Link  key={index} to={`/website/edit-article?id=${item.id}`}>
                            //                 {/* <ArticleRow data={item} loading={loading} /> */}
                            //                 {item.title}
                            //             </Link>
                            //             </Box>
                            //         );
                            //     })}
                            // </Box>
                            <Table variant="simple">
                                {/* <TableCaption>Imperial to metric conversion factors</TableCaption> */}
                                <Thead>
                                    <Tr>
                                        <Th>{t("Title")}</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {loading && <LoadingTable column={6}/>}
                                    {
                                        data?.getPublicFormList?.publicForms.length <= 0 && <Tr>
                                            <Td><Text>{t("No Data")}</Text></Td>
                                        </Tr>
                                    }
                                    {data?.getPublicFormList?.publicForms?.map((item, index) => {
                                        return (
                                            <Tr key={`pf-${index}`}>
                                                <Td onClick={() => history.push(`/website/public-form-detail?id=${item.id}`)} color="#2980b9" fontWeight="500" cursor="pointer" _hover={{ textDecoration: "underline", textUnderlineOffset: 2 }}>{item.title}</Td>
                                            </Tr>
                                        )
                                    })}
                                </Tbody>
                                <Tfoot>
                                    <Tr>
                                        <Th>{t("Title")}</Th>
                                    </Tr>
                                       
                                            <Tr>
                                                {/* <Th/> */}
                                                <Th isNumeric>                                   
                                                    {t("Total Rows")}: {data?.getPublicFormList?.totalRows}
                                                </Th>
                                            </Tr>
                                </Tfoot>
                            </Table>
                        }
                        <center>
                            {data?.adminArticles?.totalPages>1&&<ReactPaginate
                                previousLabel={<ChevronLeftIcon fontSize="20px"/>}
                                nextLabel={<ChevronRightIcon fontSize="20px"/>}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={data?.adminArticles?.totalPages}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={3}
                                onPageChange={handlePageClick}
                                containerClassName={'pagination'}
                                activeClassName={'active'}
                                initialPage={(currentPage-1)|0}
                            />}
                        </center>
                    </Box>
                </Center>
            </Container>
        </>
    );
}

export default PublicFormList;
