import React, {useState, useRef, useEffect, useMemo} from 'react';
import JoditEditor from "jodit-react";

const RichTextEditor = ({initContent, onContentChange}) => {
	const editor = useRef(null)
	const [content, setContent] = useState("")
	
    useEffect(()=>{
        if(!content && initContent){
            setContent(initContent)
        }
    },[initContent])

    

    const config = useMemo(
        () => ({
            readonly: false, 
        }),
        []
    );
    
	
	return (
            <JoditEditor
            	ref={editor}
                value={content}
                config={config}
                tabIndex={1} // tabIndex of textarea
                onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                onChange={newContent => {
                    onContentChange(newContent)
                }}
            />
        );
}

export default RichTextEditor