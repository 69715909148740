import { Badge } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

function IntermediaryModel({ model = null, color = 'orange' }) {
	const { t } = useTranslation();

	return (
		<>
			{model === 'direct' && (
				<Badge variant="solid" colorScheme={color}>
					{t('Direct Sale')}
				</Badge>
			)}
			{model === 'ref' && (
				<Badge variant="solid" colorScheme={color}>
					{t('Referral')}
				</Badge>
			)}
		</>
	);
}

export default IntermediaryModel;
