import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
    Container, Box, Flex, Text, Button, Table,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td, Center,
    Switch,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogCloseButton,
    useDisclosure
} from "@chakra-ui/react";
import BreadcrumbComponent from "../../../components/BreadcrumbComponent/BreadcrumbComponent";
import { Link } from "react-router-dom";
import LoadingTable from "../../../components/Table/Loading";
import moment from "moment"
import useStore from "../../../store";
import { useHistory } from "react-router-dom";
import c from "../../../constant"
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import Count from "./count"


const QUERY_DATA = gql`
query{
    getCamDxOpenApiWhiteList{
      id
      domain
      active
    }
  }
`


const TOGGLE = gql`
    mutation toggle(
        $id:String!
    ){
        whiteListToggleActive(id:$id)
    }   
`



const QUERY_ORG_TOKEN = gql`
query{
    getCamDxOrgLevelToken
  }
`

function ProductList() {
    const { t } = useTranslation();
    const { currentLang } = useStore((state) => state.currentLang);
    const history = useHistory();

    const [loadData, { error, loading, data }] = useLazyQuery(QUERY_DATA)
    const [loadORGToken, { error: errorToken, loading: loadingToken, data: dataToken }] = useLazyQuery(QUERY_ORG_TOKEN)
    const [toggleActive, { error: errorToggle, loading: loadingToggle, data: dataToggle }] = useMutation(TOGGLE)

    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = React.useRef()

    useEffect(() => {
        if (isOpen) {
            loadORGToken()
        }
    }, [isOpen])

    moment.locale(currentLang === "kh" ? "km" : "en-gb")

    useEffect(() => {
        loadData()
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (dataToggle) {
            loadData()
        }
    }, [dataToggle])

    return (
        <>
            <Container maxW="container.xl" mt="16px" mb="16px">
                <BreadcrumbComponent
                    list={[
                        {
                            name: "Settings",
                            path: "/settings",
                        },
                        {
                            name: "White List ORG CamDX",
                            path: "#",
                        },
                    ]}
                />
                <Center mt="5" >

                    <Box
                        w="100%"
                        bg="white"
                        borderRadius={c.borderRadius}
                        border="1px solid #dbdbdb"
                        boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
                        pb="32px"
                    >
                        <Flex alignItems="center" justifyContent="space-between" bg="#FAFAFA" p="16px" pt="8px" pb="8px" mb="16px" borderBottom="1px solid #dbdbdb">
                            <Flex justifyContent="center" alignItems="center">
                                <Text
                                    fontSize="x-large"
                                    fontWeight="bold"
                                    mr="4"
                                >
                                    {t("White List ORG CamDX")}
                                </Text>
                            </Flex>
                            <Flex>
                                <Flex
                                    cursor="pointer"
                                    alignItems="center"
                                    justifyContent="center"
                                    h="32px"
                                    borderRadius={c.borderRadius}
                                    border="1px solid #dbdbdb"
                                    boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
                                    mr="4"
                                    onClick={onOpen}
                                >
                                    <Text mt="2px" fontWeight="500" pl="2" pr="2">
                                        Get IRC ORG Level Token
                                    </Text>
                                </Flex>
                                <Link to={`/settings/whitelist-org-camdx/add`}>
                                    <Flex
                                        cursor="pointer"
                                        alignItems="center"
                                        justifyContent="center"
                                        w="32px"
                                        h="32px"
                                        borderRadius={c.borderRadius}
                                        border="1px solid #dbdbdb"
                                        boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
                                    >
                                        <Text fontSize="26px" mt="2px" fontWeight="500">
                                            +
                                        </Text>
                                    </Flex>
                                </Link>
                            </Flex>
                        </Flex>
                        <Table variant="simple">
                            <Tbody>
                                <Tr className="td-as-th">
                                    <Th>Domain</Th>
                                    <Th>Count Request</Th>
                                    <Th>Active</Th>
                                </Tr>
                                {loading && <LoadingTable column={3} />}
                                {
                                    data?.getCamDxOpenApiWhiteList?.length <= 0 && <Tr>
                                        <Td isNumeric><Text>{t("No Data")}</Text></Td>
                                    </Tr>
                                }
                                {data?.getCamDxOpenApiWhiteList?.map((item, index) => {
                                    return (
                                        <Tr cursor="pointer" _hover={{ bg: "gray.200" }} key={`item-${index}`} >
                                            <Td>{item?.domain}</Td>
                                            <Td><Count id={item?.id} /></Td>
                                            <Td>
                                                <Switch onChange={() => toggleActive({ variables: { id: item?.id } })} isChecked={item?.active} colorScheme='teal' size='lg' />
                                            </Td>
                                        </Tr>
                                    )
                                })}
                            </Tbody>
                            <Tfoot className="hidden-print">
                                <Tr>
                                    <Th>Domain</Th>
                                    <Th>Count Request</Th>
                                    <Th>Active</Th>
                                </Tr>
                            </Tfoot>
                        </Table>

                    </Box>
                </Center>
            </Container>

            <AlertDialog
                motionPreset='slideInBottom'
                leastDestructiveRef={cancelRef}
                onClose={onClose}
                isOpen={isOpen}
                isCentered
            >
                <AlertDialogOverlay />

                <AlertDialogContent>
                    <AlertDialogHeader>IRC ORG level token</AlertDialogHeader>
                    <AlertDialogCloseButton />
                    <AlertDialogBody>
                        {(dataToken?.getCamDxOrgLevelToken)}
                    </AlertDialogBody>
                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={onClose}>
                            Ok
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        </>
    );
}

export default ProductList;

