import React, { useEffect, useState } from "react";
import { Tag, TagLabel, TagCloseButton, Box, FormControl, FormLabel, FormHelperText, InputGroup, InputRightElement, Button, Flex } from "@chakra-ui/react";
import { Field } from "formik";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons";
import c from "../../constant";
import useStore from "../../store";
import SelectUserModal from "../Modal/SelectUserModal"
import { capitalizeFirstLetter } from "../../utils"

const UserFormField = props => {
    const { currentLang } = useStore((state) => state.currentLang);
    const [openUserModal, setOpenUserModal] = useState(false)
    const { t } = useTranslation('common');
    const {
        name,
        label,
        required,
    } = props;

    const [selectedUser, setSelectedUser] = useState({})

    function getDisplayName(user) {
		let displayName = capitalizeFirstLetter(user?.username)
		if (currentLang === "kh") {
			displayName = user?.fullNameKhmer ? user?.fullNameKhmer : (user?.fullName ? user?.fullName : capitalizeFirstLetter(user?.username))
		} else {
			displayName = user?.fullName ? user?.fullName : (user?.fullNameKhmer ? user?.fullNameKhmer : capitalizeFirstLetter(user?.username))
		}
		return displayName
	}

    return (
        <>
            <Field name={name}>
                {({ meta, form }) => (
                    <>
                    <Box mb="4">
                        <FormControl id={name} >
                            <FormLabel>{t(label)}{required && <span style={{ color: "red" }}>*</span>}</FormLabel>
                            <InputGroup size='md'>
                                <Flex alignItems="center" w="100%" borderRadius={c.borderRadius} h="48px" border="1px solid #CBD5E0">
                                    {/* <Text fontSize={18} ml="4">asd</Text> */}
                                    {selectedUser?.id&&<Tag
                                        size="lg"
                                        ml="2px"
                                        borderRadius={c.borderRadius}
                                        variant='solid'
                                        colorScheme='green'
                                        w="calc(100% - 75px)"
                                        h="42px"
                                        >
                                        <TagLabel w="100%" fontSize={18} pt="8px" pb="8px">
                                            {selectedUser?.id?getDisplayName(selectedUser):null}
                                        </TagLabel>
                                        </Tag>}
                                </Flex>

                                <InputRightElement width='4.5rem'>
                                <Button
                                    onClick={()=>setOpenUserModal(true)}
                                    leftIcon={
                                        <FontAwesomeIcon
                                            style={{
                                                fontSize: 16,
                                                cursor: "pointer",
                                            }}
                                            icon={faList}
                                        />
                                    } 
                                    mt="7px"
                                    variant="solid"
                                    h="42px"
                                    mr="3px"
                                    borderRadius={c.borderRadius}
                                >
                                    {t("List")}
                                </Button>
                                </InputRightElement>
                            </InputGroup>

                            {meta.touched && meta.error && (
                                <FormHelperText
                                    id={`error-message-${name}`}
                                    color="red.400"
                                    fontSize="12px"
                                >
                                    {t(name)}{" "}
                                    {t("is")}{" "}
                                    {t(meta.error)}
                                </FormHelperText>
                            )}
                        </FormControl>

                    </Box>
                    <SelectUserModal callback={e=>{
                        form.setFieldValue(name, e?.id)
                        setSelectedUser(e)
                        }} isOpen={openUserModal}  onClose={()=>setOpenUserModal(false)}
                        viewType={props?.viewType}    
                    />
                    </>
                )}
            </Field>

        </>
    );
};

export default UserFormField;
