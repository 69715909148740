import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Box, Flex, Button, useToast, Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
} from "@chakra-ui/react";
import c from "../../constant"
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { gql, useMutation } from '@apollo/client';
import { useHistory } from "react-router-dom";
import RadioFormField from "../../components/form/RadioFormField"
import TextFormField from "../../components/form/TextFormField"

const CREATE_COMPANY_STAFF_MUTATION = gql`
mutation create($name: String!, $gender: String!, $phoneNumber: String!, $email: String, $companyId: String!){
    createCompanyStaff(
      companyId:$companyId
      phoneNumber:$phoneNumber
      email: $email
      gender:$gender
      name:$name
    )
  }
 ` 

const signUpSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    phoneNumber: Yup.string().required("Required"),
    email: Yup.string(),
    gender: Yup.string().required("Required"),

});

function Create({
    isOpen = false, onClose = () => null, callback = () => null, companyId
}) {
    const history = useHistory();
    const toast = useToast()
    const { t } = useTranslation();

    
    const [createCompanyStaff, { loading, error, data }] = useMutation(
        CREATE_COMPANY_STAFF_MUTATION
    );
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (data) {
            if (data?.createCompanyStaff) {
                toast({
                    title: t("Company staff created successfully"),
                    status: "success",
                    isClosable: true,
                    position: "top-right"
                })
                onClose()
                callback("Company staff created successfully")
            }
        }
    }, [data])

    useEffect(() => {
        if (error) {
            error.graphQLErrors.map(({ message }, i) => (
                toast({
                    title: t(message),
                    status: "error",
                    isClosable: true,
                    position: "top-right"
                })
            ))
        }
    }, [error])


    function create(values) {
        createCompanyStaff({
            variables:{
                companyId:companyId,
                phoneNumber:values.phoneNumber,
                gender:values.gender,
                name:values.name,
                email: values.email
            }
        })
    }

    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={() => {
                    onClose()

                }}
                size="md"
            >
                <ModalOverlay />
                <ModalContent borderRadius={c.borderRadius} pb="2">
                    <ModalHeader>{t("Add Company Staff")}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Formik
                            initialValues={{
                                name: "",
                                gender:"",
                                phoneNumber:"",
                                email:"",
                            }}
                            validationSchema={signUpSchema}
                            onSubmit={(values) => {
                                create(values);
                            }}
                        >
                            {(props) => (
                                <Form>
                                    <Box mt="3">
                                        <TextFormField
                                            {...props}
                                            label="Name"
                                            name="name"
                                            required
                                        />

                                        <RadioFormField
                                            label="Gender"
                                            name="gender"
                                            radios={[
                                                { label: "Male", value: "male" },
                                                { label: "Female", value: "female" },
                                            ]}
                                            required
                                        />

                                        <TextFormField
                                            {...props}
                                            label="Phone number"
                                            name="phoneNumber"
                                            required
                                        />

                                        <TextFormField
                                            {...props}
                                            label="Email"
                                            name="email"
                                        />

                                        <Flex
                                            textAlign="right"
                                            mt="6"
                                            justifyContent="space-between"
                                            alignItems="center"
                                        >
                                            <Button
                                                id="login-btn"
                                                variant="solid"
                                                size="md"
                                                h="42px"
                                                w="128px"
                                                bg="primary.dark"
                                                _hover={{ bg: "primary.dark" }}
                                                color="white"
                                                // borderRadius="31px"
                                                type="submit"
                                                isLoading={loading}
                                                loadingText={t("Add")}
                                                spinnerPlacement="start"
                                            >
                                                {t(`Add`)}
                                            </Button>
                                        </Flex>
                                    </Box>
                                </Form>
                            )}
                        </Formik>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
}

export default Create;
