/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Box,
	Flex,
	Button,
	useToast,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	Text,
	Divider,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	IconButton,
	Input,
	Checkbox,
	Textarea,
	ModalFooter,
	FormControl,
	FormLabel,
	CloseButton,
	RadioGroup,
	Stack,
	Radio,
} from '@chakra-ui/react';
import c from '../../constant';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { v4 as uuid } from 'uuid';
import { DragHandleIcon, AddIcon, AttachmentIcon, CheckCircleIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { SortableContainer, SortableElement, sortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import FileStructureCheckBox from './FileStructureCheckBox';
import PublicServicePrimaryAssignee from '../Assignee/PublicServicePrimaryAssignee';
import { IoSaveOutline } from 'react-icons/io5';

const EDIT_MUTATION = gql`
	mutation edit(
		$publicServiceId: String!
		$publicServiceValue: String!
		$stepId: String!
		$data: JSON
		$paymentData: JSON
		$additionalFeature: String!
		$primaryAssignees: JSON!
	) {
		editPublicServiceStep(
			publicServiceStepInput: {
				publicServiceId: $publicServiceId
				publicServiceValue: $publicServiceValue
				stepId: $stepId
				data: $data
				paymentData: $paymentData
				additionalFeature: $additionalFeature
				primaryAssignees: $primaryAssignees
			}
		)
	}
`;

const QUERY_DATA = gql`
	query get($publicServiceId: String!, $step: String!) {
		getPublicServiceStepForm(publicServiceId: $publicServiceId, step: $step) {
			id
			data
			paymentData
			additionalFeature
			primaryAssignees
		}
	}
`;

const CREATE_PRODUCT = gql`
	mutation create($name: String!, $code: String!, $amount: Float!, $currency: String!, $refType: String!) {
		createProductByPublicServiceStep(name: $name, code: $code, amount: $amount, currency: $currency, refType: $refType)
	}
`;

let primaryAssignees = [];

function FileStructure({ publicServiceId, publicServiceValue, stepData }) {
	const toast = useToast();
	const { t } = useTranslation();
	const [openFileInfoModal, setOpenFileInfoModal] = useState(false);
	const [listItem, setListItem] = useState([]);
	const [fileModalType, setFileModalType] = useState(null);
	const [fileModalFieldType, setFileModalFieldType] = useState(null);
	const [editingData, setEditingData] = useState(null);
	const [additionalFeatureValue, setAdditionalFeatureValue] = useState('0');

	const tmpUUID = uuid();
	const [paymentData, setPaymentData] = useState([
		{
			id: tmpUUID,
			productCode: `kh.gov.irc.public-service.${tmpUUID}`,
			label: 'កម្រៃសេវារដ្ឋបាល',
			amount: '',
			multiUnits: false,
			multiUnitDescription: '',
			multiUnitLabel: '',
		},
	]);

	const [editPublicService, { loading, error, data }] = useMutation(EDIT_MUTATION);

	const [loadData, { loading: loadingData, error: errorData, data: dataData }] = useLazyQuery(QUERY_DATA);

	const [createProductByPublicServiceStep, { error: errorCreateProduct }] = useMutation(CREATE_PRODUCT);

	useEffect(() => {
		if (dataData?.getPublicServiceStepForm) {
			setListItem(dataData?.getPublicServiceStepForm?.data);
			if (dataData?.getPublicServiceStepForm?.paymentData?.length > 0) {
				setPaymentData(dataData?.getPublicServiceStepForm?.paymentData);
			}
			if (dataData?.getPublicServiceStepForm?.additionalFeature) {
				setAdditionalFeatureValue(dataData?.getPublicServiceStepForm?.additionalFeature);
			} else {
				setAdditionalFeatureValue('0');
			}
			if (dataData?.getPublicServiceStepForm?.primaryAssignees) {
				primaryAssignees = dataData?.getPublicServiceStepForm?.primaryAssignees;
			} else {
				primaryAssignees = [];
			}
		}
	}, [dataData]);

	useEffect(() => {
		if (error) {
			alert(error);
		}
	}, [error]);

	useEffect(() => {
		if (errorCreateProduct) {
			alert(errorCreateProduct);
		}
	}, [errorCreateProduct]);

	useEffect(() => {
		if ((publicServiceId, stepData?.id)) {
			loadData({
				variables: {
					publicServiceId,
					step: stepData?.id,
				},
			});
		}
	}, [publicServiceId, stepData]);

	useEffect(() => {
		if (data?.editPublicServiceStep) {
			paymentData?.map((item, index) => {
				createProductByPublicServiceStep({
					variables: {
						name: data?.editPublicServiceStep + '_' + item?.label,
						code: item?.productCode,
						amount: parseFloat(item?.amount),
						currency: 'KHR',
						refType: 'public_service',
					},
				});
			});

			toast({
				title: t('Public service step form updated successfully'),
				status: 'success',
				isClosable: true,
				position: 'top-right',
			});
		}
	}, [data]);

	const SortableList = SortableContainer(({ items }) => {
		return (
			<Box mt="4">
				{items.map((value, index) => (
					<SortableItem key={`item-${value?.id}`} index={index} kIndex={index} value={value} />
				))}
			</Box>
		);
	});

	function onSortEnd({ oldIndex, newIndex }) {
		const newArray = arrayMove(listItem, oldIndex, newIndex);
		setListItem(newArray);
		const updateIndexList = [];
		newArray.map((item, index) => {
			updateIndexList.push({ id: item.id, index });
		});
		// updateIndex({
		//     variables: {
		//         array: JSON.stringify(updateIndexList)
		//     }
		// })
	}

	function deleteRowByIndex(index) {
		const tmpListItem = [...listItem];
		tmpListItem.splice(index, 1);
		setListItem(tmpListItem);
	}

	function editRow(item) {
		setOpenFileInfoModal(true);
		setFileModalType('edit');
		setFileModalFieldType(item?.value?.type);
		setEditingData(item);
	}

	function callbackListChange(e, item) {
		const tmpListItem = [...listItem];
		tmpListItem[item?.kIndex] = {
			...tmpListItem[item?.kIndex],
			list: e,
		};
		setListItem(tmpListItem);
	}

	const SortableItem = SortableElement((item) => {
		return (
			<Flex
				key={`stitem-${item?.value?.id}`}
				zIndex={99999}
				w="100%"
				tabIndex={0}
				mb="2"
				minH="32px"
				alignItems="center"
				bg="white"
				borderRadius={c.borderRadius}
				border="1px solid #dbdbdb"
				boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
				p="16px"
			>
				<DragHandle />
				<Flex w="100%" h="100%">
					{item?.value?.type === 'checklist' ? (
						<Box w="100%">
							<Flex justifyContent="space-between" flex="1" alignItems="center">
								<Text>
									<pre style={{ whiteSpace: 'pre-wrap', fontSize: 16, fontFamily: 'Rajdhani, Hanuman, sans-serif, Moul !important' }}>
										{item?.value?.label}
									</pre>
								</Text>
								<Flex>
									<Button mr="2" onClick={() => editRow(item)} size="xs" colorScheme="blue" variant="solid">
										<EditIcon />
									</Button>
									<Button onClick={() => deleteRowByIndex(item?.kIndex)} size="xs" colorScheme="red" variant="solid">
										<DeleteIcon />
									</Button>
								</Flex>
							</Flex>
							<Box mt="4" p="4" pt="0" pb="0" border="2px solid #e67e22" borderRadius={c.borderRadius}>
								<FileStructureCheckBox
									list={item?.value?.list ? item?.value?.list : []}
									callbackListChange={(e) => callbackListChange(e, item)}
								/>
							</Box>
						</Box>
					) : (
						<Flex flex="1" justifyContent={'space-between'} alignItems="center">
							<Text>
								<pre style={{ whiteSpace: 'pre-wrap', fontSize: 16, fontFamily: 'Rajdhani, Hanuman, sans-serif, Moul !important' }}>
									{item?.value?.label}
								</pre>
							</Text>
							<Flex>
								<Checkbox ml="4" w="120px" isChecked={item?.value?.required}>
									{t('Required')}
								</Checkbox>
								<Button mr="2" onClick={() => editRow(item)} size="xs" colorScheme="blue" variant="solid">
									<EditIcon />
								</Button>
								<Button onClick={() => deleteRowByIndex(item?.kIndex)} size="xs" colorScheme="red" variant="solid">
									<DeleteIcon />
								</Button>
							</Flex>
						</Flex>
					)}
				</Flex>
			</Flex>
		);
	});

	function addNewRow(type) {
		setOpenFileInfoModal(true);
		setFileModalType('add');
		setFileModalFieldType(type);
	}

	function fileModalCallback(e) {
		if (e?.fileModalType === 'add') {
			const newData = {
				id: e?.id,
				type: e?.fileModalFieldType,
				label: e?.label,
				required: e?.required,
			};
			setListItem([...listItem, newData]);
		}
	}

	function callbackEdit(e) {
		const tmpListItem = [...listItem];
		let tmpData = e?.value;
		if (editingData?.value?.list) {
			tmpData = { ...tmpData, list: editingData?.value?.list };
		}
		tmpListItem[e?.kIndex] = tmpData;
		setListItem(tmpListItem);
	}

	function saveData() {
		const variables = {
			publicServiceId: publicServiceId,
			publicServiceValue: publicServiceValue,
			stepId: stepData?.id,
			data: listItem,
			// amount:parseFloat(amountKHR)
			paymentData,
			additionalFeature: additionalFeatureValue,
			primaryAssignees,
		};
		editPublicService({ variables });
	}

	function AddNewPayment() {
		const tmpData = [...paymentData];
		const tmpUUID = uuid();
		tmpData.push({ id: tmpUUID, productCode: `kh.gov.irc.public-service.${tmpUUID}`, label: '', amount: '' });
		setPaymentData(tmpData);
	}

	function removePaymentById(id) {
		const tmpData = [...paymentData];
		let tmpPaymentData = [];
		// tmpData.push({id:tmpUUID, productCode: `kh.gov.irc.public-service.${tmpUUID}` , label:"", amount:""})
		tmpData?.map((item) => {
			if (item?.id !== id) {
				tmpPaymentData.push(item);
			}
		});
		setPaymentData(tmpPaymentData);
	}

	function toggleMultipleUnitsById(index) {
		let tmpData = [...paymentData];
		tmpData[index].multiUnits = !tmpData[index].multiUnits;
		setPaymentData(tmpData);
	}

	function labelChange(value, index) {
		let tmpData = [...paymentData];
		tmpData[index].label = value;
		setPaymentData(tmpData);
	}

	function amountChange(value, index) {
		let tmpData = [...paymentData];
		tmpData[index].amount = value;
		setPaymentData(tmpData);
	}

	function labelMultipleUnitChange(value, index) {
		let tmpData = [...paymentData];
		tmpData[index].multiUnitLabel = value;
		setPaymentData(tmpData);
	}

	function descriptionMultipleUnitChange(value, index) {
		let tmpData = [...paymentData];
		tmpData[index].multiUnitDescription = value;
		setPaymentData(tmpData);
	}

	return (
		<>
			<Box>
				{loadingData && <Text>{t('Loading')}</Text>}
				{errorData && <Text color="red">Error Load Data</Text>}
				{!loadingData && (
					<FormControl>
						<Text fontWeight="bold" color="#3498db" mb="4" fontSize="lg">
							{t('Primary Assignees')}
						</Text>
						<Box>
							<PublicServicePrimaryAssignee
								userIds={dataData?.getPublicServiceStepForm?.primaryAssignees}
								callback={(e) => (primaryAssignees = e)}
							/>
						</Box>
						<Text fontWeight="bold" color="#3498db" mb="4" fontSize="lg">
							{t('Payment List')}
						</Text>
						{paymentData?.map((item, index) => {
							return (
								<Box pb="4" mb="2" borderBottom="1px solid gray" _hover={{ bg: 'gray.100' }}>
									<table>
										<thead>
											<tr>
												<td>
													<FormLabel>{t('Label')}</FormLabel>
													<Input minW="250px" w="100%" value={item?.label} onChange={(e) => labelChange(e.target.value, index)} />
												</td>
												<td>
													<FormLabel>{t('Amount')} (KHR)</FormLabel>
													<Input
														minW="250px"
														w="100%"
														type="number"
														value={item?.amount}
														onChange={(e) => amountChange(e.target.value, index)}
													/>
												</td>
												<td>
													<CloseButton ml="2" mt="30px" onClick={() => removePaymentById(item?.id)} />
												</td>
											</tr>
											<tr>
												<td>
													<Flex alignItems="center">
														{!item?.multiUnits && (
															<Checkbox
																mt="2"
																colorScheme="red"
																isChecked={item?.multiUnits}
																onChange={() => toggleMultipleUnitsById(index)}
															>
																Multiple units
															</Checkbox>
														)}
														{item?.multiUnits && (
															<Box mt="2">
																<FormLabel>{t('Unit Label')}</FormLabel>
																<Input
																	w="250px"
																	value={item?.multiUnitLabel}
																	onChange={(e) => labelMultipleUnitChange(e.target.value, index)}
																/>
															</Box>
														)}
													</Flex>
												</td>
												<td>
													{item?.multiUnits && (
														<Box mt="2">
															<FormLabel>{t('Unit Description')}</FormLabel>
															<Input
																w="250px"
																value={item?.multiUnitDescription}
																onChange={(e) => descriptionMultipleUnitChange(e.target.value, index)}
															/>
														</Box>
													)}
												</td>
												<td>
													{item?.multiUnits && (
														<Checkbox
															ml="4"
															mt="30px"
															colorScheme="red"
															isChecked={item?.multiUnits}
															onChange={() => toggleMultipleUnitsById(index)}
														>
															Multiple units
														</Checkbox>
													)}
												</td>
											</tr>
										</thead>
									</table>
								</Box>
							);
						})}
					</FormControl>
				)}
				<Button size="sm" variant="outline" onClick={() => AddNewPayment()}>
					<AddIcon />
				</Button>
				<Divider mt="4" mb="4" />
				<Text fontWeight="bold" color="#3498db" mb="4" fontSize="lg">
					{t('File Input')}
				</Text>
				<SortableList useDragHandle items={listItem} onSortEnd={onSortEnd} />
				<Menu>
					<MenuButton as={IconButton} aria-label="Options" icon={<AddIcon />} variant="outline" />
					<MenuList>
						<MenuItem
							onClick={() => {
								addNewRow('file');
							}}
							icon={<AttachmentIcon />}
						>
							{t('File')}
						</MenuItem>
						<MenuItem
							onClick={() => {
								addNewRow('checklist');
							}}
							icon={<CheckCircleIcon />}
						>
							{t('Check List')}
						</MenuItem>
					</MenuList>
				</Menu>

				<Divider mt="4" mb="4" />
				<Text fontWeight="bold" color="#3498db" mb="4" fontSize="lg">
					{t('Additional Feature')}
				</Text>

				<RadioGroup onChange={setAdditionalFeatureValue} value={additionalFeatureValue}>
					<Stack direction="column">
						<Radio value="0">{t('None')}</Radio>
						<Radio value="request_audit_service_license">{t('Request Audit Service License')}</Radio>
						<Radio value="request_audit_service_license_renewal">{t('Request Audit Service License Renewal')}</Radio>
					</Stack>
				</RadioGroup>

				<Button
					mt="4"
					float="right"
					isLoadin={loading}
					leftIcon={<IoSaveOutline />}
					variant="solid"
					borderRadius={c.borderRadius}
					ml="4"
					colorScheme="green"
					size="md"
					onClick={() => saveData()}
				>
					{t('Save')}
				</Button>
			</Box>
			{openFileInfoModal && (
				<FileInfoModal
					onClose={() => {
						setOpenFileInfoModal(false);
						setEditingData(null);
					}}
					fileModalType={fileModalType}
					fileModalFieldType={fileModalFieldType}
					callback={(e) => fileModalCallback(e)}
					callbackEdit={(e) => callbackEdit(e)}
					editingData={editingData}
				/>
			)}
		</>
	);
}

export default FileStructure;

const DragHandle = sortableHandle(() => (
	<Flex minH="32px" justifyContent="center" alignItems="center" cursor="move" mr="4">
		<DragHandleIcon />
	</Flex>
));

const FileInfoModal = ({ onClose, editingData, fileModalType, fileModalFieldType, callback, callbackEdit }) => {
	const { t } = useTranslation();
	const [label, setLabel] = useState(editingData?.value?.label || '');
	const [required, setRequired] = useState(editingData?.value?.required === false ? false : true);
	return (
		<>
			<Modal isOpen={true} onClose={onClose}>
				<ModalOverlay />
				<ModalContent borderRadius={c.borderRadius}>
					<ModalHeader>{t('File Info')}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Textarea autoFocus value={label} onChange={(e) => setLabel(e.target.value)} placeholder={t('Label')} />
						{fileModalFieldType === 'file' && (
							<Checkbox mt="2" defaultChecked={required} onChange={(e) => setRequired(e.target.checked)}>
								{t('Required')}
							</Checkbox>
						)}
					</ModalBody>
					<ModalFooter>
						{fileModalType === 'add' && (
							<Button
								colorScheme="blue"
								onClick={() => {
									if (label) {
										callback({
											id: uuid(),
											label,
											required,
											fileModalType,
											fileModalFieldType,
										});
										onClose();
									} else {
										alert('Label is required');
									}
								}}
							>
								{t('Add')}
							</Button>
						)}
						{fileModalType === 'edit' && (
							<Button
								colorScheme="blue"
								onClick={() => {
									if (label) {
										callbackEdit({
											kIndex: editingData?.kIndex,
											value: {
												// ...editingData?.value,
												id: editingData?.value?.id,
												type: editingData?.value?.type,
												label,
												required,
											},
										});
										onClose();
									} else {
										alert('Label is required');
									}
								}}
							>
								{t('Update')}
							</Button>
						)}
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
};
