/* eslint-disable react-hooks/exhaustive-deps */
import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Box, Flex, Text, Table, Thead, Tbody, Tfoot, Tr, Th, Td, Badge, Image, Tooltip } from '@chakra-ui/react';
import { gql, useLazyQuery } from '@apollo/client';
import { faMailBulk, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import LoadingTable from '../../components/Table/Loading';
import datePickerFromToToDate from '../../utils/datePickerFromToToDate';
import formatMoneyKH from '../../utils/formatMoneyKH';
import ReactPaginate from 'react-paginate';
import PlaceHolderLogo from '../../assets/images/logo-placeholder.png';
import LOGO_KHQR from '../../assets/images/payment/KHQR-logo.png';
import LOGO_ABA from '../../assets/images/payment/ABA-PAY.png';
import LOGO_CARD from '../../assets/images/payment/ic_CreditDebit.png';
import useStore from '../../store';
import useMoment from '../../hooks/useMoment';
import c from '../../constant';
import './style.css';

const Q_PAYMENTS = gql`
	query GetPayments(
		$limit: Float
		$offset: Float
		$trxId: String
		$phone: String
		$lastName: String
		$firstName: String
		$email: String
		$fromDate: DateTime
		$toDate: DateTime
		$fromApprovedDate: DateTime
		$toApprovedDate: DateTime
		$paymentStatus: String
		$pageType: String
		$companyName: String
		$selectedCompanyId: String
		$proposalNumber: String
	) {
		getPayments(
			pageType: $pageType
			trxId: $trxId
			phone: $phone
			lastName: $lastName
			firstName: $firstName
			email: $email
			fromDate: $fromDate
			toDate: $toDate
			fromApprovedDate: $fromApprovedDate
			toApprovedDate: $toApprovedDate
			status: $paymentStatus
			pagerInput: { limit: $limit, offset: $offset }
			companyName: $companyName
			selectedCompanyId: $selectedCompanyId
			proposalNumber: $proposalNumber
		) {
			payments {
				id
				tranId
				userId
				createdAt
				approvedAt
				firstName
				lastName
				email
				phone
				paymentOption
				amount
				currency
				status
				refType
				refId
				company {
					id
					name
					nameKhmer
					logo
				}
				proposal {
					id
					publicService
					no
					selectedStep
				}
				publicService {
					id
					title
					steps
				}
				agentProposal {
					id
					number
				}
			}
			totalRows
			totalPages
		}
	}
`;

const PaymentList = forwardRef(
	(
		{
			page = null,
			limit = null,
			trxId = null,
			lastName = null,
			firstName = null,
			email = null,
			phone = null,
			paymentStatus = null,
			fromDate = null,
			toDate = null,
			fromApprovedDate = null,
			toApprovedDate = null,
			pageType = null,
			companyName = null,
			selectedCompanyId = null,
			proposalNumber = null,
			onPageChanged = () => null,
		},
		ref
	) => {
		const history = useHistory();
		const { t } = useTranslation();
		const { toDateString } = useMoment();
		const { currentLang: lang } = useStore((state) => state.currentLang);
		const [loadData, { loading, data }] = useLazyQuery(Q_PAYMENTS);

		useEffect(() => {
			if (data) {
				console.log(data);
			}
		}, data);

		useEffect(() => {
			loadPaymentWithVariables();
		}, [
			page,
			limit,
			trxId,
			lastName,
			firstName,
			email,
			phone,
			paymentStatus,
			fromDate,
			toDate,
			fromApprovedDate,
			toApprovedDate,
			pageType,
			companyName,
			selectedCompanyId,
			proposalNumber,
		]);

		useImperativeHandle(ref, () => ({
			generateExcel() {
				const tmp = [];
				data?.getPayments?.payments?.forEach((item) => {
					tmp.push({
						tranId: item?.tranId,
						createdAt: toDateString(item?.createdAt, c.excelDateTimeFormat),
						amount: item?.amount,
						currency: item?.currency,
						lastName: item?.lastName,
						firstName: item?.firstName,
						companyName: item?.company?.nameKhmer,
						companyNameLatin: item?.company?.name,
						email: item?.email,
						phone: item?.phone,
						paymentMethod: item?.paymentOption,
						publicService: item?.publicService?.title,
						publicServiceStep: item?.proposal?.selectedStep
							? item?.publicService?.steps?.length > 1
								? getStepLabel(item?.proposal?.selectedStep, item?.publicService?.steps)
								: ''
							: '',
						status: item?.status,
					});
				});
				return tmp;
			},
			payments: data?.getPayments?.payments,
		}));

		function loadPaymentWithVariables() {
			const variables = {
				offset: page ? (page - 1) * limit : 0,
				limit,
				trxId,
				lastName,
				firstName,
				email,
				phone,
				paymentStatus,
				fromDate: datePickerFromToToDate(fromDate),
				toDate: datePickerFromToToDate(toDate),
				fromApprovedDate: datePickerFromToToDate(fromApprovedDate),
				toApprovedDate: datePickerFromToToDate(toApprovedDate),
				pageType,
				companyName,
				selectedCompanyId,
				proposalNumber,
			};
			loadData({ variables });
		}

		function getStatusBadge(type) {
			switch (type) {
				case 'PENDING':
					return <Badge colorScheme="yellow">{t('PENDING')}</Badge>;
				case 'APPROVED':
					return <Badge colorScheme="green">{t('APPROVED')}</Badge>;
				case 'EXPIRED':
					return <Badge colorScheme="gray">{t('EXPIRED')}</Badge>;
				case 'DECLINED':
					return <Badge colorScheme="gray">{t('DECLINED')}</Badge>;
				case 'REFUNDED':
					return <Badge colorScheme="purple">{t('REFUNDED')}</Badge>;
				case 'ERROR':
					return <Badge colorScheme="red">{t('ERROR')}</Badge>;
				default:
					return null;
			}
		}

		function displayPaymentOptionImage(key) {
			let displayImage = null;
			switch (key) {
				case 'bakong':
					displayImage = LOGO_KHQR;
					break;
				case 'cards':
					displayImage = LOGO_CARD;
					break;
				case 'abapay':
					displayImage = LOGO_ABA;
					break;
				default:
					displayImage = null;
					break;
			}
			return <Image w="auto" h="24px" src={displayImage} objectFit="contain" fallbackSrc={PlaceHolderLogo} mr="2" />;
		}

		function getStepLabel(selectedStep, steps) {
			if (selectedStep && steps) {
				let tmpStep = steps;
				tmpStep = tmpStep.filter((e) => e?.id === selectedStep);
				return tmpStep[0]?.typeKH || '';
			}
			return '';
		}

		return (
			<>
				<Table variant="simple">
					<Thead>
						<Tr>
							<Th>
								{pageType === 'agent' && <Flex>{t('Trx. ID')}</Flex>}
								{pageType !== 'agent' && (
									<Box>
										<Flex>{t('Trx. ID')}</Flex>
										<Flex>{t('No.')}</Flex>
									</Box>
								)}
							</Th>
							<Th>{t('Created At')}</Th>
							<Th>{t('Payment Approved At')}</Th>
							<Th isNumeric>{t('Amount')}</Th>
							<Th>{t('Created By')}</Th>
							<Th>
								{pageType === 'public-service' && t('Public Service')}
								{pageType !== 'public-service' && t('Contact')}
							</Th>
							<Th>{t('Payment Option')}</Th>
							<Th>{t('Status')}</Th>
						</Tr>
					</Thead>
					<Tbody>
						{loading && <LoadingTable column={8} />}
						{data?.getPayments?.payments.length <= 0 && (
							<Tr>
								<Td isNumeric>
									<Text>{t('No Data')}</Text>
								</Td>
							</Tr>
						)}
						{data?.getPayments?.payments?.map((item, index) => {
							return (
								<Tr
									cursor="pointer"
									_hover={{ bg: 'gray.200' }}
									key={`item-${index}`}
									bg={item?.notification?.read === false ? 'gray.100' : 'white'}
								>
									<Td fontWeight="bold" onClick={() => history.push(`/payment/detail?id=${item.id}`)}>
										{item?.proposal || item?.agentProposal ? (
											<Box>
												<Tooltip label="Trx. ID">
													<Flex>{item?.tranId}</Flex>
												</Tooltip>
												<>
													{item?.proposal && (
														<>
															<Tooltip label={t('Proposal Number')}>
																<Flex color="#d35400" fontWeight="bold">
																	#{item?.proposal?.no}
																</Flex>
															</Tooltip>
														</>
													)}
												</>
												<>
													{item?.agentProposal && (
														<>
															<Tooltip label={t('Agent Proposal Number')}>
																<Flex color="#d35400" fontWeight="bold">
																	{item?.agentProposal?.number}
																</Flex>
															</Tooltip>
														</>
													)}
												</>
											</Box>
										) : (
											<Tooltip label="Trx. ID">
												<Flex>{item?.tranId}</Flex>
											</Tooltip>
										)}
									</Td>
									<Td w="190px" onClick={() => history.push(`/payment/detail?id=${item.id}`)}>
										{toDateString(item.createdAt, c.longDateFormat)}
									</Td>
									<Td w="190px" onClick={() => history.push(`/payment/detail?id=${item.id}`)}>
										{item.approvedAt ? toDateString(item.approvedAt, c.longDateFormat) : '-'}
									</Td>
									<Td onClick={() => history.push(`/payment/detail?id=${item.id}`)} isNumeric fontWeight="bold">
										<Flex justifyContent="flex-end" color={item?.status === 'APPROVED' ? '#27ae60' : '#bdc3c7'}>
											<Text color="inherit">{formatMoneyKH(parseFloat(item.amount))}</Text>
											<Text color="#bdc3c7" ml="1">
												{item?.currency}
											</Text>
										</Flex>
									</Td>
									<Td onClick={() => history.push(`/payment/detail?id=${item.id}`)}>
										<Flex alignItems="center">
											<Text noOfLines={1}>
												{item?.lastName}
												&nbsp;
												{item?.firstName}
											</Text>
											{item?.company?.logo && (
												<>
													<Image
														w="auto"
														h="24px"
														maxW="194px"
														src={item?.company?.logo ? c.baseFilePath + (item?.company?.logo ? item?.company?.logo : null) : ''}
														objectFit="contain"
														fallbackSrc={PlaceHolderLogo}
														mr="2"
													/>
												</>
											)}
											<Text noOfLines={1}>{lang === 'kh' ? item?.company?.nameKhmer : item?.company?.name}</Text>
										</Flex>
									</Td>
									<Td onClick={() => history.push(`/payment/detail?id=${item.id}`)}>
										{pageType === 'public-service' && (
											<>
												{item?.publicService ? (
													<>
														{item?.publicService?.title}
														<br />
														<Text color="#d35400" fontWeight="bold">
															{item?.publicService?.steps?.length > 1
																? `(${getStepLabel(item?.proposal?.selectedStep, item?.publicService?.steps)})`
																: ''}
														</Text>
													</>
												) : (
													<>ការអនុញ្ញាតឱ្យចុះកិច្ចសន្យាជាមួយភ្នាក់ងារធានារ៉ាប់រងជារូបវន្តបុគ្គល</>
												)}
											</>
										)}
										{pageType !== 'public-service' && (
											<>
												{item?.email && (
													<Text display="inline" whiteSpace="nowrap">
														<FontAwesomeIcon icon={faMailBulk} style={{ fontSize: 12, color: 'silver' }} />
														&nbsp;{item?.email}
													</Text>
												)}
												{item?.phone && (
													<Text>
														<FontAwesomeIcon icon={faPhone} style={{ fontSize: 12, color: 'silver' }} />
														&nbsp;{item?.phone}
													</Text>
												)}
											</>
										)}
									</Td>
									<Td onClick={() => history.push(`/payment/detail?id=${item.id}`)}>{displayPaymentOptionImage(item?.paymentOption)}</Td>
									<Td onClick={() => history.push(`/payment/detail?id=${item.id}`)}>{getStatusBadge(item?.status)}</Td>
								</Tr>
							);
						})}
					</Tbody>
					<Tfoot>
						<Tr>
							<Th>
								{pageType === 'agent' && <Flex>{t('Trx. ID')}</Flex>}
								{pageType !== 'agent' && (
									<Box>
										<Flex>{t('Trx. ID')}</Flex>
										<Flex>{t('No.')}</Flex>
									</Box>
								)}
							</Th>
							<Th>{t('Created At')}</Th>
							<Th>{t('Payment Approved At')}</Th>
							<Th isNumeric>{t('Amount')}</Th>
							<Th>{t('Created By')}</Th>
							<Th>
								{pageType === 'public-service' && t('Public Service')}
								{pageType !== 'public-service' && t('Contact')}
							</Th>
							<Th>{t('Payment Option')}</Th>
							<Th>{t('Status')}</Th>
						</Tr>
						<Tr>
							<Th />
							<Th />
							<Th />
							<Th />
							<Th />
							<Th />
							<Th />
							<Th isNumeric>
								{t('Total Rows')}: {data?.getPayments?.totalRows}
							</Th>
						</Tr>
					</Tfoot>
				</Table>
				<Flex justifyContent="flex-end" w="100%" mt="4">
					{data?.getPayments?.totalPages > 1 && (
						<ReactPaginate
							previousLabel={<ChevronLeftIcon fontSize="20px" />}
							nextLabel={<ChevronRightIcon fontSize="20px" />}
							breakLabel={'...'}
							breakClassName={'break-me'}
							pageCount={data?.getPayments?.totalPages}
							marginPagesDisplayed={2}
							pageRangeDisplayed={3}
							onPageChange={({ selected }) => onPageChanged(selected)}
							containerClassName={'pagination'}
							activeClassName={'active'}
							initialPage={(page - 1) | 0}
							disableInitialCallback={true}
						/>
					)}
				</Flex>
			</>
		);
	}
);

export default PaymentList;
