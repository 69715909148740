import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Container, Box, Flex, Text,
    Button,
    Badge,
    useToast
} from "@chakra-ui/react";
import BreadcrumbComponent from "../../../components/BreadcrumbComponent/BreadcrumbComponent";
import c from "../../../constant"
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import moment from "moment"
import { useLocation, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TableComponent from "../../../components/Table/Cloudscape/TableComponent";
import useLocalStorage from 'use-local-storage';
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import {
    ButtonDropdown,
} from '@cloudscape-design/components';
import 'moment/locale/en-gb';
import 'moment/locale/km';
import SelectUserModal from '../../../components/Modal/SelectUserModal';

const NAME_COLUMN_WIDTH = 180;


const QUERY_USERS = gql`
	query get($type: String!){
		getAutoAssignProposalList(type:$type) {
			user {
				id
				username
				fullName
				fullNameKhmer
				createdAt
				role
				avatar
				active
                department
			}
            type
			id
		}
	}
`;

const ADD_TO = gql`
	mutation add($id: String!, $type: String!) {
		addToAutoAssignProposal(id: $id, type: $type)
	}
`;

const REMOVE_FROM = gql`
	mutation remove($id: String!) {
		deleteFromAutoAssignProposal(id: $id)
	}
`;

function UserList() {
    const { t } = useTranslation();
    const location = useLocation();
    const history = useHistory();
    const [loadUsers, { error, loading, data }] = useLazyQuery(QUERY_USERS)
    const [selectUserModalOpen, setSelectUserModalOpen] = useState(false)
    const [addUser, { error: errorAdd, data: addData, loading: addLoading }] = useMutation(ADD_TO);
    const [removeUser, { error: errorRemove, data: removeData, loading: removeLoading }] = useMutation(REMOVE_FROM);

    useEffect(() => {
        if (removeData || addData) {
            loadUsers({
                variables: {
                    type: "auto"
                }
            })
        }
    }, [removeData, addData])

    function actionMenu({ id }) {
        return (
            <ButtonDropdown
                className="action-button-table"
                variant="inline-icon"
                ariaLabel={`${id} actions`}
                expandToViewport={true}
                items={[
                    { id: 'delete', text: <p style={{ color: "red" }}>{t("Delete")}</p> },
                ]}
                onItemClick={e => {
                    switch (e?.detail?.id) {
                        case "delete":
                            removeUser({
                                variables: { id }
                            })
                            break;
                        default:
                            break;
                    }
                }}
            />
        )
    }


    // Table config
    const tableName = "auto-assign-user-to-proposal";
    const rawColumns = [
        {
            id: 'username',
            label: t('Username'),
            alwayVisible: true,
            header: <Flex style={{ paddingLeft: 20, paddingRight: 20 }}>{t('Username')}</Flex>,
            cell: (item) => (
                <Flex pl="20px" pr="20px" cursor="pointer">
                    <Text fontWeight={600} color="#0972d3" textDecoration="underline">
                        {item?.user?.username}
                    </Text>
                </Flex>
            ),
            isRowHeader: true,
            width: 160,
        },
        {
            id: 'fullNameKhmer',
            label: t('Full Name Khmer'),
            header: t('Full Name Khmer'),
            cell: (item) => item?.user?.fullNameKhmer || '-',
            width: NAME_COLUMN_WIDTH,
        },
        {
            id: 'fullName',
            label: t('Full Name'),
            header: t('Full Name'),
            cell: (item) => item?.user?.fullName || '-',
            width: NAME_COLUMN_WIDTH,
        },
        {
            id: 'actions',
            label: t('Actions'),
            header: t('Actions'),
            cell: (item) => actionMenu({ id: item?.id }),
        },
    ]
    const [preferences, setPreferences] = useLocalStorage(`${tableName}-table-Preferences`, {
        pageSize: 10,
        contentDisplay: rawColumns.map((column) => ({ id: column.id, visible: true })),
        wrapLines: false,
        stripedRows: false,
        contentDensity: 'comfortable',
        stickyColumns: { first: 1, last: 1 },
    });
    const { pageSize } = preferences;

    function getHeader() {
        return (
            <Flex className="table-header" direction={'row'} alignItems={'center'}>
                <Flex flexGrow={1} className="title">
                    <Text fontSize="xl" fontWeight={600} mt="10px" mb="10px">
                        {t('Users')}
                    </Text>
                </Flex>
                <Flex direction={'row'}>
                    <Button
                        ml="10px"
                        onClick={() => setSelectUserModalOpen(true)}
                        leftIcon={<FontAwesomeIcon icon={faPlus} style={{ fontSize: 16 }} />}
                        colorScheme={`${false ? 'yellow' : 'gray'}`}
                        variant="solid"
                        size="sm"
                        borderRadius={c.borderRadius}
                        border="1px solid #bdc3c7}"
                    >
                        {t("Add")}
                    </Button>
                </Flex>
            </Flex>
        )
    }
    // end table config

    useEffect(() => {
        window.scrollTo(0, 0);
        loadUsers({
            variables: {
                type: "auto"
            }
        })
    }, []);


    return (
        <>
            <Container maxW="container.xl" mt="16px" mb="16px">
                <BreadcrumbComponent
                    list={[
                        {
                            name: 'Settings',
                            path: '/settings',
                        },
                        {
                            name: 'Proposal',
                            path: '/settings/proposal',
                        },
                        {
                            name: 'Auto assign to proposal',
                            path: '/settings/proposal/auto-assign-to-proposal',
                        },
                    ]}
                />
                <Box pb="32px" w="100%" maxW={"8xl"} mt="5">
                    <TableComponent
                        tableName={tableName}
                        rawColumns={rawColumns}
                        currentPage={1}
                        totalPage={1}
                        loading={loading}
                        items={data?.getAutoAssignProposalList || []}
                        preferences={preferences}
                        setPreferences={setPreferences}
                        onTablePageChange={() => null}
                        totalRows={data?.getUsers?.totalRows || ''}
                        header={getHeader()}
                        onSearchTextChange={() => null}
                    />
                </Box>
            </Container>

            {selectUserModalOpen && (
                <SelectUserModal
                    role="companySubmissionProposal"
                    callback={e => addUser({
                        variables: { id: e?.id, type: "auto" }
                    })}
                    isOpen={selectUserModalOpen}
                    onClose={() => setSelectUserModalOpen(false)}
                />
            )}
        </>
    );
}

export default UserList;
