import { Modal, ModalOverlay, ModalContent, Text, CircularProgress, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import c from '../../constant';

export default function UploadingModal({ isOpen }) {
	const { t } = useTranslation();

	return (
		<>
			<Modal isOpen={isOpen}>
				<ModalOverlay />
				<ModalContent borderRadius={c.borderRadius}>
					<Flex direction="column" alignItems="center" p="10">
						<CircularProgress isIndeterminate color="blue.300" size="100px" thickness="6px" />
						<Text mt="8">{t('Uploading')}</Text>
					</Flex>
				</ModalContent>
			</Modal>
		</>
	);
}
