/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Box, Flex, Skeleton } from '@chakra-ui/react';

function LoadingCompanyInformation() {
	return (
		<>
			<Flex w="100%">
				<Box width="100%">
					<Flex justifyContent="space-between" mb={4}>
						<Skeleton width="164px" height="164px" mb={4} />
						<Skeleton width="132px" height="132px" />
					</Flex>
					<Box>
						<Skeleton width="220px" height="24px" mt={0} />
						<Skeleton width="220px" height="24px" mt={2} />
						<Skeleton width="220px" height="24px" mt={2} />
					</Box>
					<Flex alignItems="center" mt="2">
						<Skeleton width="124px" height="21px" mr={4} />
						<Skeleton width="400px" height="21px" />
					</Flex>
					<Flex alignItems="center" mt="2">
						<Skeleton width="124px" height="21px" mr={4} />
						<Skeleton width="400px" height="21px" />
					</Flex>
					<Flex alignItems="center" mt="2">
						<Skeleton width="124px" height="21px" mr={4} />
						<Skeleton width="400px" height="21px" />
					</Flex>
					<Flex alignItems="center" mt="2">
						<Skeleton width="124px" height="21px" mr={4} />
						<Skeleton width="400px" height="21px" />
					</Flex>
					<Flex alignItems="center" mt="2">
						<Skeleton width="124px" height="21px" mr={4} />
						<Skeleton width="400px" height="21px" />
					</Flex>
					<Flex alignItems="center" mt="2">
						<Skeleton width="124px" height="21px" mr={4} />
						<Skeleton width="400px" height="21px" />
					</Flex>
				</Box>
			</Flex>
		</>
	);
}
export default LoadingCompanyInformation;
