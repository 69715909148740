/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Button, useToast, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, position } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { gql, useMutation } from '@apollo/client';
import RadioFormField from '../../components/form/RadioFormField';
import TextFormField from '../../components/form/TextFormField';
import SelectSearchFormField from '../../components/form/SelectSearchFormField';
import DateFormField from '../form/DateFormField';
import FileFormField from '../../components/form/FileFormField';
import countriesData from '../../utils/countries';
import moment from 'moment';
import c from '../../constant';
import * as Yup from 'yup';

const CREATE_COMPANY_SHAREHOLDER_MUTATION = gql`
	mutation create(
		$name: String!
		$gender: String
		$nationality: String
		$position: String
		$companyId: String!
		$approvalDate: DateTime
		$file: Upload
		$fileSize: Float
		$type: String
	) {
		createCompanyCEO(
			companyId: $companyId
			name: $name
			gender: $gender
			nationality: $nationality
			position: $position
			approvalDate: $approvalDate
			file: $file
			fileSize: $fileSize
			type: $type
		)
	}
`;

const signUpSchema = Yup.object().shape({
	name: Yup.string().required('Required'),
	gender: Yup.string().nullable(),
	nationality: Yup.object().nullable(),
	position: Yup.object().nullable(),
	approvalDate: Yup.string().nullable(),
	file: Yup.mixed().test('fileSize', 'over limit', (value) => {
		if (value) {
			return value?.size <= c.maxFileSize / 10;
		} else {
			return true;
		}
	}),
});

function Create({ type = null, companyId = null, isOpen = false, onClose = () => null, callback = () => null }) {
	const toast = useToast();
	const { t } = useTranslation();
	const [createCeo, { loading, error, data }] = useMutation(CREATE_COMPANY_SHAREHOLDER_MUTATION);

	useEffect(() => {
		if (data) {
			if (data?.createCompanyCEO) {
				toast({
					title: t('Company CEO created successfully'),
					status: 'success',
					isClosable: true,
					position: 'top-right',
				});
				onClose();
				callback('Company CEO created successfully');
			}
		}
	}, [data]);

	useEffect(() => {
		if (error) {
			error.graphQLErrors.map(({ message }, i) =>
				toast({
					title: t(message),
					status: 'error',
					isClosable: true,
					position: 'top-right',
				})
			);
		}
	}, [error]);

	function create(values) {
		createCeo({
			variables: {
				companyId: companyId,
				name: values?.name,
				gender: values?.gender,
				nationality: values?.nationality?.value ? values?.nationality?.value : null,
				position: values?.position,
				approvalDate: values.approvalDate ? moment(values.approvalDate).toDate() : null,
				file: values?.file,
				fileSize: values?.file?.size,
				type: type === c.governanceCeo.ceo ? null : type,
			},
		});
	}

	return (
		<>
			<Modal isOpen={isOpen} onClose={onClose} size="md">
				<ModalOverlay />
				<ModalContent borderRadius={c.borderRadius} pb="2">
					<ModalHeader>{t('Add')}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Formik
							initialValues={{
								name: '',
								gender: '',
								nationality: '',
								position: '',
								approvalDate: null,
								file: null,
							}}
							validationSchema={signUpSchema}
							onSubmit={(values) => create(values)}
						>
							{(props) => (
								<Form>
									<Box mt="3">
										<TextFormField {...props} label="Name" name="name" required />
										<RadioFormField
											label="Gender"
											name="gender"
											radios={[
												{ label: 'Male', value: 'male' },
												{ label: 'Female', value: 'female' },
											]}
										/>
										<SelectSearchFormField
											{...props}
											name="nationality"
											label="Nationality"
											placeholder="Nationality"
											options={countriesData.map((item) => ({ label: t(item.nationality), value: item.nationality }))}
										/>
										{type !== c.governanceCeo.ceo && <TextFormField {...props} label="Position" name="position" />}
										<DateFormField name="approvalDate" label="Approval Date" />
										<FileFormField {...props} label="Permit letter" name="file" maxFileSize={c.maxFileSize / 10} />
										<Flex textAlign="right" mt="6" justifyContent="space-between" alignItems="center">
											<Button
												id="login-btn"
												variant="solid"
												size="md"
												h="42px"
												w="128px"
												bg="primary.dark"
												_hover={{ bg: 'primary.dark' }}
												color="white"
												type="submit"
												isLoading={loading}
												loadingText={t('Add')}
												spinnerPlacement="start"
											>
												{t(`Add`)}
											</Button>
										</Flex>
									</Box>
								</Form>
							)}
						</Formik>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
}

export default Create;
