import React, { useEffect } from "react";

import { gql, useLazyQuery } from '@apollo/client';
import { useState } from "react";

const QUERY_DATA = gql`
query get($value:String!){
  getPublicServiceByValue(value:$value){
    id
    title
    steps
  }
}
`

function PublicService({ value, step }) {
	const [load, { data }] = useLazyQuery(QUERY_DATA)
	const [stepLabel, setStepLabel] = useState("")

	useEffect(() => {
		load({
			variables: {
				value
			}
		})
	}, [])

	useEffect(() => {
		if (data?.getPublicServiceByValue) {
			let stepData = data?.getPublicServiceByValue?.steps?.filter(e => {
				return e?.id === step
			})
			setStepLabel(stepData?.[0]?.typeKH)
		}
	}, [data])

	return (
		<>
			{data?.getPublicServiceByValue?.title} {stepLabel ? ` (${stepLabel})` : ""}
		</>
	);
}

export default PublicService;
