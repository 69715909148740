import { Badge } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

function IntermediaryType({ type = null }) {
	const { t } = useTranslation();

	return (
		<>
			{type === 'new' && (
				<>
					<Badge variant="solid" colorScheme="blue">
						{t('New')}
					</Badge>
				</>
			)}

			{type === 'change' && (
				<>
					<Badge variant="solid" colorScheme="teal">
						{t('Change')}
					</Badge>
				</>
			)}
		</>
	);
}

export default IntermediaryType;
