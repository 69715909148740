/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Text, Table, Thead, Tbody, Tfoot, Tr, Th, Td, Center, Tooltip, Badge } from '@chakra-ui/react';
import { SectionHeader, FullAddress } from '../../Other';
import DistributionSystemCertificateSalePartner from './DistributionSystemCertificateSalePartner';
import useMoment from '../../../hooks/useMoment';
import useStore from '../../../store';
import c from '../../../constant';

function DistributionSystemCertificate({ dataCertificates = null, isExclusive = false, isInsuranceAgent = false, ...props }) {
	const { toDateString } = useMoment();
	const { t } = useTranslation();
	const { currentLang } = useStore((state) => state.currentLang);

	return (
		<>
			<Flex className="show-only-printing" mt={4} {...props}>
				<Box width="100%">
					<Center>
						<Box
							w="100%"
							bg="white"
							borderRadius={c.borderRadius}
							border="1px solid #dbdbdb"
							boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
							pb={2}
						>
							<Flex
								p={4}
								pt={2}
								pb={2}
								mb={4}
								h="48px"
								bg="#FAFAFA"
								alignItems="center"
								justifyContent="space-between"
								borderBottom="1px solid #dbdbdb"
							>
								<SectionHeader title={t('Branches')} fontSize="lg" />
							</Flex>
							<Box p="16px">
								{isExclusive ? (
									<Table variant="simple">
										<Thead>
											<Tr>
												<Th>{t('Number')}</Th>
												<Th>{t('Name')}</Th>
												<Th>{t('Insurance product sale manager')}</Th>
												<Th>
													{t('Certificate')}/{t('Permit letter')}
												</Th>
												{isInsuranceAgent && <Th>{t('Patnered Company')}</Th>}
											</Tr>
										</Thead>
										<Tbody>
											{dataCertificates?.getCompanyCertificate?.branches?.length <= 0 && (
												<Tr>
													<Td>
														<Text>{t('No Data')}</Text>
													</Td>
												</Tr>
											)}
											{dataCertificates?.getCompanyCertificate?.branches?.map((item, index) => {
												return (
													<Tr key={`item-${index}`} bg="white" _hover={{ bg: 'gray.200' }}>
														<Td>
															<Text fontWeight="bold">{item?.number}</Text>
														</Td>
														<Td>
															<Text>{item?.name}</Text>
														</Td>
														<Td>
															<Text>{currentLang === 'kh' ? item?.managerName : item?.managerNameLatin}</Text>
														</Td>
														<Td>
															{item?.validFrom && item?.validTo && (
																<>
																	<>
																		{item?.status === 'Normal' && (
																			<Flex direction="column" mb={1}>
																				<Box mb={1}>
																					<Tooltip label={t('Valid')}>
																						<Badge backgroundColor="green.500">
																							<Text fontSize="xs" color="white">
																								{t('Valid')}
																							</Text>
																						</Badge>
																					</Tooltip>
																				</Box>
																				<Text fontSize="sm" fontWeight="md" color="black.800">
																					<>{toDateString(item?.validFrom, c.excelDateOnlyTextFormat)}</>
																					<> - </>
																					<>{toDateString(item?.validTo, c.excelDateOnlyTextFormat)}</>
																				</Text>
																			</Flex>
																		)}
																	</>
																	<>
																		{item?.status === 'NearlyDue' && (
																			<Flex direction="column" mb={1}>
																				<Box mb={1}>
																					<Tooltip label={t('Nearly Due')}>
																						<Badge backgroundColor="yellow.500">
																							<Text fontSize="xs" color="white">
																								{t('Nearly Due')}
																							</Text>
																						</Badge>
																					</Tooltip>
																				</Box>
																				<Text fontSize="sm" fontWeight="bold" color="yellow.500">
																					<>{toDateString(item?.validFrom, c.excelDateOnlyTextFormat)}</>
																					<> - </>
																					<>{toDateString(item?.validTo, c.excelDateOnlyTextFormat)}</>
																				</Text>
																			</Flex>
																		)}
																	</>
																	<>
																		{item?.status === 'Due' && (
																			<Flex direction="column" mb={1}>
																				<Box mb={1}>
																					<Tooltip label={t('Due')}>
																						<Badge backgroundColor="red.500">
																							<Text fontSize="xs" color="white">
																								{t('Due')}
																							</Text>
																						</Badge>
																					</Tooltip>
																				</Box>
																				<Text fontSize="sm" fontWeight="bold" color="red.500">
																					<>{toDateString(item?.validFrom, c.excelDateOnlyTextFormat)}</>
																					<> - </>
																					<>{toDateString(item?.validTo, c.excelDateOnlyTextFormat)}</>
																				</Text>
																			</Flex>
																		)}
																	</>
																</>
															)}
														</Td>
														{isInsuranceAgent && (
															<Td>
																{item?.salePartners?.map((el, i) => (
																	<Box key={i} mt={i === 0 ? 0 : 4}>
																		<DistributionSystemCertificateSalePartner partner={el?.partner} />
																	</Box>
																))}
															</Td>
														)}
													</Tr>
												);
											})}
										</Tbody>
										<Tfoot>
											<Tr>
												<Th>{t('Number')}</Th>
												<Th>{t('Name')}</Th>
												<Th>{t('Insurance product sale manager')}</Th>
												<Th>
													{t('Certificate')}/{t('Permit letter')}
												</Th>
												{isInsuranceAgent && <Th>{t('Patnered Company')}</Th>}
											</Tr>
										</Tfoot>
									</Table>
								) : (
									<Table variant="simple">
										<Thead>
											<Tr>
												<Th>{t('Number')}</Th>
												<Th>{t('Name')}</Th>
												<Th>{t('Name Latin')}</Th>
												<Th>{t('Branch Manager')}</Th>
												<Th>{t('Address')}</Th>
												<Th isNumeric>{t('Valid')}</Th>
											</Tr>
										</Thead>
										<Tbody>
											{dataCertificates?.getCompanyCertificate?.branches?.length <= 0 && (
												<Tr>
													<Td>
														<Text>{t('No Data')}</Text>
													</Td>
												</Tr>
											)}
											{dataCertificates?.getCompanyCertificate?.branches?.map((item, index) => {
												return (
													<Tr cursor="pointer" _hover={{ bg: 'gray.200' }} key={`item-${index}`} bg="white">
														<Td>{item?.number}</Td>
														<Td>{item?.name}</Td>
														<Td>{item?.nameLatin}</Td>
														<Td>{currentLang === 'kh' ? item?.managerName : item?.managerNameLatin}</Td>
														<Td>{item?.isGeoManual ? item?.geoManual : <FullAddress object={item} />}</Td>
														<Td isNumeric>
															{item?.validFrom && item?.validTo && (
																<>
																	<>
																		{item?.status === 'Normal' && (
																			<Flex direction="column" mb={1}>
																				<Box mb={1}>
																					<Tooltip label={t('Valid')}>
																						<Badge backgroundColor="green.500">
																							<Text fontSize="xs" color="white">
																								{t('Valid')}
																							</Text>
																						</Badge>
																					</Tooltip>
																				</Box>
																				<Text fontSize="sm" fontWeight="md" color="black.800">
																					<>{toDateString(item?.validFrom, c.excelDateOnlyTextFormat)}</>
																					<> - </>
																					<>{toDateString(item?.validTo, c.excelDateOnlyTextFormat)}</>
																				</Text>
																			</Flex>
																		)}
																	</>
																	<>
																		{item?.status === 'NearlyDue' && (
																			<Flex direction="column" mb={1}>
																				<Box mb={1}>
																					<Tooltip label={t('Nearly Due')}>
																						<Badge backgroundColor="yellow.500">
																							<Text fontSize="xs" color="white">
																								{t('Nearly Due')}
																							</Text>
																						</Badge>
																					</Tooltip>
																				</Box>
																				<Text fontSize="sm" fontWeight="bold" color="yellow.500">
																					<>{toDateString(item?.validFrom, c.excelDateOnlyTextFormat)}</>
																					<> - </>
																					<>{toDateString(item?.validTo, c.excelDateOnlyTextFormat)}</>
																				</Text>
																			</Flex>
																		)}
																	</>
																	<>
																		{item?.status === 'Due' && (
																			<Flex direction="column" mb={1}>
																				<Box mb={1}>
																					<Tooltip label={t('Due')}>
																						<Badge backgroundColor="red.500">
																							<Text fontSize="xs" color="white">
																								{t('Due')}
																							</Text>
																						</Badge>
																					</Tooltip>
																				</Box>
																				<Text fontSize="sm" fontWeight="bold" color="red.500">
																					<>{toDateString(item?.validFrom, c.excelDateOnlyTextFormat)}</>
																					<> - </>
																					<>{toDateString(item?.validTo, c.excelDateOnlyTextFormat)}</>
																				</Text>
																			</Flex>
																		)}
																	</>
																</>
															)}
														</Td>
													</Tr>
												);
											})}
										</Tbody>
										<Tfoot>
											<Tr>
												<Th>{t('Number')}</Th>
												<Th>{t('Name')}</Th>
												<Th>{t('Name Latin')}</Th>
												<Th>{t('Branch Manager')}</Th>
												<Th>{t('Address')}</Th>
												<Th isNumeric>{t('Valid')}</Th>
											</Tr>
										</Tfoot>
									</Table>
								)}
							</Box>
						</Box>
					</Center>
				</Box>
			</Flex>
		</>
	);
}

export default DistributionSystemCertificate;
