import React from 'react';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react';
import { faChevronRight, faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function BreadcrumbComponent({ list = [], clickable = true }) {
	const { t } = useTranslation();
	const history = useHistory();

	return (
		<Breadcrumb
			className="hidden-print"
			style={{
				fontSize: '16px',
				fontWeight: '500',
				textTransform: 'uppercase',
			}}
			mt="6"
			color="#303030"
			spacing="8px"
			separator={<FontAwesomeIcon icon={faChevronRight} color="#303030" style={{ fontSize: 14 }} />}
		>
			<BreadcrumbItem>
				<BreadcrumbLink
					onMouseDown={() => {
						if (clickable) {
							history.push('/');
						}
					}}
				>
					<FontAwesomeIcon style={{ marginRight: 8 }} icon={faHome} color="#303030" />
					{t('HOME')}
				</BreadcrumbLink>
			</BreadcrumbItem>

			{list?.map((item, index) => {
				return (
					<BreadcrumbItem key={`bci-${index}`}>
						<BreadcrumbLink
							onMouseDown={() => {
								if (clickable) {
									if (item?.path !== '#') {
										history.push(item?.path ? item?.path : '/');
									}
								}
							}}
						>
							{t(item.name)}
						</BreadcrumbLink>
					</BreadcrumbItem>
				);
			})}
		</Breadcrumb>
	);
}

export default BreadcrumbComponent;
