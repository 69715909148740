import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
    Box, Flex, Button, useToast, Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
} from "@chakra-ui/react";
import c from "../../constant"
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import RadioFormField from "../../components/form/RadioFormField"
import TextFormField from "../../components/form/TextFormField"

const UPDATE_MUTATION = gql`
mutation update(
    $name: String!,
    $gender: String , 
    $email: String , 
    $phoneNumber: String!, 
    $id: String!
){
    updateCompanyStaffById(
      id:$id
      name:$name
      email:$email
      phoneNumber:$phoneNumber
      gender:$gender
    )
  }
 `


const QUERY_DATA = gql`
 query get($id: String!){
    getCompanyStaffById(id:$id){
        id
        name
        gender
        phoneNumber
        email
        createdAt
      }
   }
 `

const signUpSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    gender: Yup.string(),
    phoneNumber: Yup.string().required("Required"),
    email: Yup.string()

});

function Create({
    isOpen = false, onClose = () => null, callback = () => null, id
}) {
    const toast = useToast()
    const { t } = useTranslation();


    const [updateMutation, { loading, error, data }] = useMutation(
        UPDATE_MUTATION
    );

    const [loadData, { loading: loadingData, error: errorData, data: dataData }] = useLazyQuery(
        QUERY_DATA
    );

    useEffect(() => {
        if (id) {
            loadData({
                variables: {
                    id
                }
            })
        }
    }, [id]);

    useEffect(() => {
        if (data) {
            if (data?.updateCompanyStaffById) {
                toast({
                    title: t("Company Staff updated successfully"),
                    status: "success",
                    isClosable: true,
                    position: "top-right"
                })
                onClose()
                callback("Company Staff updated successfully")
            }
        }
    }, [data])

    useEffect(() => {
        if (error) {
            error.graphQLErrors.map(({ message }, i) => (
                toast({
                    title: t(message),
                    status: "error",
                    isClosable: true,
                    position: "top-right"
                })
            ))
        }
    }, [error])


    function update(values) {
        updateMutation({
            variables: {
                name: values.name,
                gender: values.gender,
                email: values.email,
                phoneNumber: values.phoneNumber,
                id: id
            }
        })
    }

    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={() => {
                    onClose()

                }}
                size="md"
            >
                <ModalOverlay />
                <ModalContent borderRadius={c.borderRadius} pb="2">
                    <ModalHeader>{t("Edit Company Staff")}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {dataData?.getCompanyStaffById && <Formik
                            initialValues={{
                                name: dataData?.getCompanyStaffById?.name,
                                gender: dataData?.getCompanyStaffById?.gender,
                                phoneNumber: dataData?.getCompanyStaffById?.phoneNumber,
                                email: dataData?.getCompanyStaffById?.email,
                            }}
                            validationSchema={signUpSchema}
                            onSubmit={(values) => {
                                update(values);
                            }}
                        >
                            {(props) => (
                                <Form>
                                    <Box mt="3">
                                        <TextFormField
                                            {...props}
                                            label="Name"
                                            name="name"
                                            required
                                        />

                                        <RadioFormField
                                            label="Gender"
                                            name="gender"
                                            radios={[
                                                { label: "Male", value: "male" },
                                                { label: "Female", value: "female" },
                                            ]}
                                            initValue={dataData?.getCompanyStaffById?.gender}
                                        />

                                        <TextFormField
                                            {...props}
                                            label="Phone number"
                                            name="phoneNumber"
                                            required
                                        /> 

                                        <TextFormField
                                            {...props}
                                            label="Email"
                                            name="email"
                                        />  

                                        <Flex
                                            textAlign="right"
                                            mt="6"
                                            justifyContent="space-between"
                                            alignItems="center"
                                        >
                                            <Button
                                                id="login-btn"
                                                variant="solid"
                                                size="md"
                                                h="42px"
                                                w="128px"
                                                bg="primary.dark"
                                                _hover={{ bg: "primary.dark" }}
                                                color="white"
                                                // borderRadius="31px"
                                                type="submit"
                                                isLoading={loading}
                                                loadingText={t("Edit")}
                                                spinnerPlacement="start"
                                            >
                                                {t(`Edit`)}
                                            </Button>
                                        </Flex>
                                    </Box>
                                </Form>
                            )}
                        </Formik>}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
}

export default Create;
