/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container, Box, Flex, Text, Center, Tabs, TabList, Tab, TabPanels, TabPanel } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import BreadcrumbComponent from '../../../components/BreadcrumbComponent/BreadcrumbComponent';
import c from '../../../constant';
import PrintLayout from '../../../components/Print/PrintLayout';
import TypeIssuedLetter from './IssuedLetter';
import ReferenceType from './ReferenceType';

function TypeOfDocumentNumberList() {
	const history = useHistory();
	const location = useLocation();
	const urlParams = new URLSearchParams(location.search);
	const page = urlParams.get('page') === 'type-of-issued-letter' ? 0 : 1;
	const { t } = useTranslation();

	return (
		<>
			<PrintLayout>
				<Container maxW="container.xl" mt="16px" mb="16px">
					<BreadcrumbComponent
						list={[
							{
								name: 'Settings',
								path: '/settings',
							},
							{
								name: 'TYPE OF ISSUED LETTER',
								path: '#',
							},
						]}
					/>
					<Center mt="5">
						<Box w="100%" bg="white" borderRadius={c.borderRadius} border="1px solid #dbdbdb" boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)">
							<Flex bg="#FAFAFA" p="16px" pt="8px" pb="8px" justifyContent="space-between" borderBottom="1px solid #dbdbdb">
								<Text fontSize="x-large" fontWeight="bold">
									{t('Type Of Issued Letter')}
								</Text>
								<Link to={page === 0 ? '/type-of-issued-letter/add-type-of-issued-letter' : '/type-of-issued-letter/add-reference-type'}>
									<Flex
										className="hidden-print"
										cursor="pointer"
										alignItems="center"
										justifyContent="center"
										w="32px"
										h="32px"
										borderRadius={c.borderRadius}
										border="1px solid #dbdbdb"
										boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
									>
										<Text fontSize="26px" mt="2px" fontWeight="500">
											+
										</Text>
									</Flex>
								</Link>
							</Flex>
							<Box width="100%" pl={4} pr={4} pt={6} pb={12}>
								<Tabs defaultIndex={page} className="agent_tab hidden-print" variant="enclosed">
									<TabList>
										<Tab onClick={() => history.push('/type-of-issued-letter?page=type-of-issued-letter')}>
											<>{t('Type Of Issued Letter')}</>
										</Tab>
										<Tab onClick={() => history.push('/type-of-issued-letter?page=reference-type')}>
											<>{t('Reference Type')}</>
										</Tab>
									</TabList>
									<TabPanels>
										<TabPanel p={0}>
											<TypeIssuedLetter />
										</TabPanel>
										<TabPanel p={0}>
											<ReferenceType />
										</TabPanel>
									</TabPanels>
								</Tabs>
							</Box>
						</Box>
					</Center>
				</Container>
			</PrintLayout>
		</>
	);
}

export default TypeOfDocumentNumberList;
