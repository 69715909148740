/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Button, Box, Flex, DrawerBody, Drawer, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerHeader } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import TextFormField from '../../../../components/form/TextFormField';
import DateRangeFormField from '../../../../components/form/DateRangeFormField';
import CompanyInputOrSelectFormField from '../../../form/CompanyInputOrSelectFormField';
import c from '../../../../constant';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
	number: Yup.string(),
	receivedDate: Yup.string(),
	approvedDate: Yup.string(),
	company: Yup.object(),
});

function PartnerRequestFilter({
	number = null,
	receivedDateFrom = null,
	receivedDateTo = null,
	approvedDateFrom = null,
	approvedDateTo = null,
	companyName = null,
	companyId = null,
	isOpen = false,
	onClose = () => null,
	onCallback = () => null,
}) {
	const { t } = useTranslation();

	const defaultReceivedDate = () => {
		if (receivedDateFrom && receivedDateTo) {
			const from = JSON.parse(receivedDateFrom);
			const to = JSON.parse(receivedDateTo);
			return JSON.stringify({ from, to });
		}
		return null;
	};

	const defaultApprovedDate = () => {
		if (approvedDateFrom && approvedDateTo) {
			const from = JSON.parse(approvedDateFrom);
			const to = JSON.parse(approvedDateTo);
			return JSON.stringify({ from, to });
		}
		return null;
	};

	return (
		<>
			<Drawer isOpen={isOpen} onClose={onClose} size="sm" placement="right">
				<DrawerOverlay />
				<DrawerContent>
					<DrawerCloseButton />
					<DrawerHeader>{t('Filter')}</DrawerHeader>
					<Formik
						initialValues={{
							number: number || '',
							receivedDate: defaultReceivedDate() || '',
							approvedDate: defaultApprovedDate() || '',
							company: { companyName: companyName || '', companyId },
						}}
						validationSchema={validationSchema}
						onSubmit={(values) => {
							let result = {};
							if (values?.number) {
								result.number = values?.number;
							}
							if (values?.receivedDate) {
								let object = JSON.parse(values?.receivedDate);
								if (object?.from && object?.to) {
									result.receivedDateFrom = JSON.stringify(object?.from);
									result.receivedDateTo = JSON.stringify(object?.to);
								}
							}
							if (values?.approvedDate) {
								let object = JSON.parse(values?.approvedDate);
								if (object?.from && object?.to) {
									result.approvedDateFrom = JSON.stringify(object?.from);
									result.approvedDateTo = JSON.stringify(object?.to);
								}
							}
							if (values?.company?.companyName) {
								result.companyName = values?.company?.companyName;
							}
							if (values?.company?.companyId) {
								result.companyId = values?.company?.companyId;
							}
							onClose();
							onCallback(result);
						}}
					>
						{(props) => (
							<DrawerBody>
								<Box>
									<Form>
										<Box>
											<center>
												<TextFormField {...props} label="Number" name="number" />
											</center>
										</Box>
										<Box>
											<center>
												<CompanyInputOrSelectFormField label="Company" name="company" inputPlaceholder="Company" />
											</center>
										</Box>
										<Box>
											<center>
												<DateRangeFormField
													{...props}
													label="Received Request Date"
													name="receivedDate"
													initialDate={{ fromDate: receivedDateFrom, toDate: receivedDateTo }}
												/>
											</center>
										</Box>
										<Box>
											<center>
												<DateRangeFormField
													{...props}
													label="Approved Request Date"
													name="approvedDate"
													initialDate={{ fromDate: approvedDateFrom, toDate: approvedDateTo }}
												/>
											</center>
										</Box>
										<Flex mt={8} mb={8} justifyContent="flex-end">
											<Button
												ml={0}
												size="md"
												h="42px"
												w="148px"
												variant="ghost"
												colorScheme="gray"
												borderRadius={c.borderRadius}
												color="black"
												onClick={() => {
													onClose();
													onCallback({});
												}}
											>
												{t('Clear')}
											</Button>
											<Button
												ml={4}
												size="md"
												h="42px"
												w="148px"
												variant="solid"
												colorScheme="gray"
												borderRadius={c.borderRadius}
												color="#3498db"
												type="submit"
											>
												{t('Apply')}
											</Button>
										</Flex>
									</Form>
								</Box>
							</DrawerBody>
						)}
					</Formik>
				</DrawerContent>
			</Drawer>
		</>
	);
}

export default PartnerRequestFilter;
