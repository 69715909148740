/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { Container, Box, Flex, Text, Center, Button, useToast } from '@chakra-ui/react';
import { useLocation, useHistory } from 'react-router-dom';
import BreadcrumbComponent from '../../../../components/BreadcrumbComponent/BreadcrumbComponent';
import LoadingOverlayText from '../../../../components/LoadingOverlay/LoadingOverlayText';
import TextFormField from '../../../../components/form/TextFormField';
import c from '../../../../constant';
import * as Yup from 'yup';

const Q_GET_TYPE_DOCUMENT = gql`
	query get($id: String!) {
		getTypeOfDocumentNumberById(id: $id) {
			id
			type
			typeEn
			description
		}
	}
`;

const M_EDIT_TYPE_DOCUMENT = gql`
	mutation edit($id: String!, $type: String!, $typeEn: String, $description: String) {
		editTypeOfDocumentNumber(id: $id, type: $type, typeEn: $typeEn, description: $description) {
			id
			type
			typeEn
			description
		}
	}
`;

const validationSchema = Yup.object().shape({
	type: Yup.string().required('required'),
	typeEn: Yup.string(),
	description: Yup.string(),
});

function TypeOfDocumentNumberEdit() {
	const toast = useToast();
	const history = useHistory();
	const location = useLocation();
	const urlParams = new URLSearchParams(location.search);
	const id = urlParams.get('id') || null;
	const { t } = useTranslation();
	const [getTypeOfDocumentNumber, { error: errorGet, data: dataGet, loading: loadingGet }] = useLazyQuery(Q_GET_TYPE_DOCUMENT);
	const [editTypeOfDocumentNumber, { error: errorEdit, data: dataEdit, loading: loadingEdit }] = useMutation(M_EDIT_TYPE_DOCUMENT);

	useEffect(() => {
		if (id) {
			const variables = { id };
			getTypeOfDocumentNumber({ variables });
		}
	}, [id]);

	useEffect(() => {
		if (errorGet) {
			if (errorGet?.graphQLErrors?.length > 0) {
				errorGet?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(errorGet?.message ? errorGet?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [errorGet]);

	useEffect(() => {
		if (dataEdit?.editTypeOfDocumentNumber) {
			toast({
				title: t('Type of document number edited successfully'),
				status: 'success',
				duration: 5000,
				isClosable: true,
				position: 'bottom-right',
			});
			history.push('/type-of-issued-letter/detail-type-of-issued-letter?id=' + id);
		}
	}, [dataEdit]);

	useEffect(() => {
		if (errorEdit) {
			if (errorEdit?.graphQLErrors?.length > 0) {
				errorEdit?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(errorEdit?.message ? errorEdit?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [errorEdit]);

	return (
		<>
			{(loadingGet || loadingEdit) && <LoadingOverlayText />}
			<Container maxW="container.xl" mt="16px" mb="16px">
				<BreadcrumbComponent
					list={[
						{
							name: 'Settings',
							path: '/settings',
						},
						{
							name: 'TYPE OF ISSUED LETTER',
							path: '/type-of-issued-letter?page=type-of-issued-letter',
						},
						{
							name: 'Edit Issued Letter Type',
							path: '/type-of-issued-letter/edit-type-of-issued-letter?id=' + id,
						},
					]}
				/>
				<Center mt="5">
					<Box
						w="100%"
						bg="white"
						borderRadius={c.borderRadius}
						border="1px solid #dbdbdb"
						boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
						pb="32px"
					>
						<Flex bg="#FAFAFA" p="16px" pt="8px" pb="8px" mb="16px" justifyContent="space-between" borderBottom="1px solid #dbdbdb">
							<Text fontSize="x-large" fontWeight="bold">
								{t('Edit Issued Letter Type')}
							</Text>
						</Flex>
						{dataGet?.getTypeOfDocumentNumberById && (
							<Box p="16px" maxW="480px">
								<Formik
									initialValues={{
										type: dataGet?.getTypeOfDocumentNumberById?.type,
										typeEn: dataGet?.getTypeOfDocumentNumberById?.typeEn,
										description: dataGet?.getTypeOfDocumentNumberById?.description,
									}}
									validationSchema={validationSchema}
									onSubmit={(values) => {
										const variables = { id, type: values.type, typeEn: values.typeEn, description: values.description };
										editTypeOfDocumentNumber({ variables });
									}}
								>
									{(props) => (
										<Box>
											<Form>
												<TextFormField {...props} label="Type" name="type" required />
												<TextFormField {...props} label="Type En" name="typeEn" />
												<TextFormField {...props} label="Description" name="description" />

												<Button
													id="login-btn"
													variant="solid"
													size="md"
													h="42px"
													w="128px"
													type="submit"
													borderRadius={c.borderRadius}
													isLoading={loadingEdit}
												>
													{t('Edit')}
												</Button>
											</Form>
										</Box>
									)}
								</Formik>
							</Box>
						)}
					</Box>
				</Center>
			</Container>
		</>
	);
}

export default TypeOfDocumentNumberEdit;
