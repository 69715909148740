import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Container, Box, Flex, Text, Center, Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    Badge,
    Tooltip,
    Button
} from "@chakra-ui/react";
import BreadcrumbComponent from "../../components/BreadcrumbComponent/BreadcrumbComponent";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import c from "../../constant"
import { gql, useLazyQuery } from '@apollo/client';
import moment from "moment"
import ReactPaginate from 'react-paginate';
import './style.css'
import { useLocation, useHistory } from "react-router-dom";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import useStore from "../../store";
import LoadingTable from "../../components/Table/Loading";
import { Link } from "react-router-dom";
import LeaveRequestFilter from "../../components/Filter/LeaveRequestFilter";
import LeaveReportModal from "../../components/Modal/LeaveReportModal";

const limit = 10
const QUERY_DATA = gql`
  query get(
        $offset:Float
    ){
        getLeavesAdjustment(
            pagerInput:{
                limit:${limit}
                offset:$offset
            }
        )
    {
      
        leavesAdjustment{
            id
            amount
            type
            year
            description
            user{
                id
                username
                fullName
                fullNameKhmer
            }
            creator{
                id
                username
                fullName
                fullNameKhmer
            }
        }
        totalPages
        totalRows
    }
  }
`

const QUERY_COUNT = gql`
query{
  countUnreadLeaveRequest
}
`

function Report() {
    const { t } = useTranslation();
    const location = useLocation();
    const history = useHistory();
    const urlParams = new URLSearchParams(location.search);
    const currentPage = urlParams.get('page')?urlParams.get('page'):1;

    const number = urlParams.get('number')?urlParams.get('number'):null;
    const name = urlParams.get('name')?urlParams.get('name'):null;
    const type = urlParams.get('type')?urlParams.get('type'):null;
    const fromDate = urlParams.get('fromDate')?urlParams.get('fromDate'):null;
    const toDate = urlParams.get('toDate')?urlParams.get('toDate'):null;
    const read = urlParams.get('read')?urlParams.get('read'):null;
    const status = urlParams.get('status')?urlParams.get('status'):null;
    
    const { currentLang } = useStore((state) => state.currentLang);
    const { currentUser } = useStore((state) => state.currentUser);
    const [loadCount, { data: countData }] = useLazyQuery(QUERY_COUNT)
    moment.locale(currentLang==="kh"?"km":"en-gb")
    const [loadData, { error, loading, data }] = useLazyQuery(QUERY_DATA)
    useEffect(() => {
        window.scrollTo(0, 0);
        loadData({
            variables:{
                offset:currentPage?((currentPage-1)*limit):0,
            }
        })
        loadCount()
    }, []);

    const [openFilter, setOpenFilter] = useState(false)
    const [openLeaveReportModal, setOpenLeaveReportModal] = useState(false)

    function handlePageClick(data){
        let selected = data.selected;
        const page= selected+1

        let url = `?page=${page}`

        if(status){
            url+=`&status=${status}`
        }
        if(number){
            url+=`&number=${number}`
        }
        if(name){
            url+=`&name=${name}`
        }

        if(fromDate){
            url+=`&fromDate=${fromDate}`
        }
        if(toDate){
            url+=`&toDate=${toDate}`
        }
        if(type){
            url+=`&type=${type}`
        }
        if(read!==null){
            const unreadOnly= read?read:""
            if(unreadOnly){
                url+=`&read=false`
            }
        }
        history.push(`/leave-adjustment${url}`)
    }

    useEffect(()=>{
        if(currentPage){
            window.scrollTo(0, 0);
            loadData({
                variables:{
                    offset:currentPage?((currentPage-1)*limit):0,
                }
            })
        }
    },[currentPage, number, name, fromDate, toDate, type, read, status])

    function getApproveStatus(approve,date, user){
        let res = null
        if(approve===null){
            res = <Badge colorScheme="yellow">{t("In progress")}</Badge>
        } else if (approve===true){
            res = <Badge colorScheme="green">{t("Approved Leave")}</Badge>
        } else {
            res = <Badge colorScheme="red">{t("Rejected Leave")}</Badge>
        }
        if(date){
            res = <Tooltip label={`${moment(date).format("LLLL")} ${t("By")} ${getDisplayName(user)}`}>
                {res}
            </Tooltip>
        }
        return res
    }

    function callbackFilter(e){
        history.push(`/leave-adjustment${e}`)
    }

    function checkReadRow(item){
        let read = true
        read = item?.notification?.read 
        return read
    }

    function getDisplayName(user) {
        let displayName = user?.username
        if (currentLang === "kh") {
            displayName = user?.fullNameKhmer ? user?.fullNameKhmer : (user?.fullName ? user?.fullName : user?.username)
        } else {
            displayName = user?.fullName ? user?.fullName : (user?.fullNameKhmer ? user?.fullNameKhmer : user?.username)
        }
        return displayName
    }

    function handleStatusChange(statusValue){
        const page= 1

        let url = `?page=${page}`

        if(statusValue){
            url+=`&status=${statusValue}`
        }
        if(number){
            url+=`&number=${number}`
        }
        if(name){
            url+=`&name=${name}`
        }

        if(fromDate){
            url+=`&fromDate=${fromDate}`
        }
        if(toDate){
            url+=`&toDate=${toDate}`
        }
        if(type){
            url+=`&type=${type}`
        }
        if(read!==null){
            const unreadOnly= read?read:""
            if(unreadOnly){
                url+=`&read=false`
            }
        }
        history.push(`/leave-adjustment${url}`)
    }

    function getTypeBadge(type){
        switch (type) {
            case "ANNUAL_LEAVE":
                return <Badge colorScheme="green">{t("Annual Leave")}</Badge>
            case "SHORT_LEAVE":
                return <Badge colorScheme="yellow">{t("Short Leave")}</Badge>
            default:
                return null;
        }
    }

    return (
        <>
            <Container maxW="container.xl" mt="16px" mb="16px">
                <BreadcrumbComponent
                    list={[
                        {
                            name: "Request Leave",
                            path: "/request-leave?page=1",
                        },
                        {
                            name: "Leave Adjustment",
                            path: "#",
                        },
                        
                    ]}
                />
                    <Center mt="5">
                    <Box
                            w="100%"
                            bg="white"
                            borderRadius={c.borderRadius}
                            border="1px solid #dbdbdb"
                            boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
                            pb="32px"
                        >
                            <Flex bg="#FAFAFA" p="16px" pt="8px" pb="8px" mb="16px" alignItems="center" justifyContent="space-between" borderBottom="1px solid #dbdbdb">
                                <Flex >
                                <Text
                                    fontSize="x-large"
                                    fontWeight="bold"
                                >
                                    {t("Leave Adjustment")}
                                </Text>
                                </Flex>
                                    <Flex alignItems="center">
                                        <Link to={`/leave-adjustment/add`}>
                                            <Button
                                                leftIcon={<FontAwesomeIcon icon={faPlus} style={{ fontSize: 16 }} />}
                                                colorScheme='gray'
                                                variant='solid'
                                                size="sm"
                                                borderRadius={c.borderRadius}
                                                border="1px solid #bdc3c7"
                                            >
                                                {t("Add")}
                                            </Button>
                                        </Link>
                                    </Flex>
                                </Flex>
                                <Box p="16px">
                                <Table variant="simple">
                                    {/* <TableCaption>Imperial to metric conversion factors</TableCaption> */}
                                    <Thead>
                                        <Tr>
                                            <Th>{t("Name")}</Th>
                                            <Th>{t("Leave Type")}</Th>
                                            <Th isNumeric>{t("Amount (Days)")}</Th>
                                            <Th isNumeric>{t("Year")}</Th>
                                            <Th>{t("Description")}</Th>
                                            <Th>{t("Created At")}</Th>
                                            <Th isNumeric>{t("Created By")}</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {loading && <LoadingTable column={9}/>}
                                        {
                                            data?.getLeaves?.leaves.length <= 0 && <Tr>
                                                <Td isNumeric><Text>{t("No Data")}</Text></Td>
                                            </Tr>
                                        }
                                        {data?.getLeavesAdjustment?.leavesAdjustment?.map((item, index)=>{
                                            return(
                                                <Tr cursor="pointer" _hover={{bg:"gray.200"}} onClick={()=>history.push(`/leave-adjustment/detail?id=${item.id}`)}  key={`item-${index}`} bg={checkReadRow(item)===false?"gray.100":"white"}>
                                                <Td>
                                                    {getDisplayName(item?.user)}
                                                </Td>
                                                <Td>{getTypeBadge(item.type)}</Td>
                                                <Td isNumeric>{(item.amount)}</Td>
                                                <Td isNumeric>{(item.year)}</Td>
                                                <Td maxW={350}>
                                                    <Text noOfLines={3}>
                                                        {item?.description}
                                                    </Text>
                                                </Td>
                                                <Td>{moment(item.createdAt).format("LLLL")}</Td>
                                                <Td isNumeric>
                                                    {getDisplayName(item?.creator)}
                                                </Td>
                                            </Tr>
                                            )
                                        })}
                                    </Tbody>
                                    <Tfoot>
                                        <Tr>
                                            <Th>{t("Name")}</Th>
                                            <Th>{t("Leave Type")}</Th>
                                            <Th isNumeric>{t("Amount (Days)")}</Th>
                                            <Th isNumeric>{t("Year")}</Th>
                                            <Th>{t("Description")}</Th>
                                            <Th>{t("Created At")}</Th>
                                            <Th isNumeric>{t("Created By")}</Th>
                                        </Tr>
                                        <Tr>
                                            <Th/>
                                            <Th/>
                                            <Th/>
                                            <Th/>
                                            <Th/>
                                            <Th/>
                                            <Th isNumeric>                                   
                                                {t("Total Rows")}: {data?.getLeavesAdjustment?.totalRows}
                                            </Th>
                                        </Tr>
                                    </Tfoot>
                                    </Table>
                                    <Flex justifyContent="flex-end" w="100%" mt="4">
                                        {data?.getLeavesAdjustment?.totalPages>1&&<ReactPaginate
                                            previousLabel={<ChevronLeftIcon fontSize="20px"/>}
                                            nextLabel={<ChevronRightIcon fontSize="20px"/>}
                                            breakLabel={'...'}
                                            breakClassName={'break-me'}
                                            pageCount={data?.getLeavesAdjustment?.totalPages}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={3}
                                            onPageChange={handlePageClick}
                                            containerClassName={'pagination'}
                                            activeClassName={'active'}
                                            initialPage={(currentPage-1)|0}
                                        />}
                                    </Flex>
                                </Box>
                        </Box>
                    </Center>
            </Container>
            <LeaveRequestFilter 
                callback={callbackFilter} 
                isOpen={openFilter} 
                onClose={()=>setOpenFilter(false)}
                number={number}
                name={name}
                type={type}
                fromDate={fromDate}
                toDate={toDate}
                read={read}
                status={status}
            />
            {openLeaveReportModal && <LeaveReportModal isOpen={true} onClose={()=>setOpenLeaveReportModal(false)}/>}
        </>
    );
}

export default Report;

