/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {
	Box,
	Flex,
	Text,
	Button,
	Table,
	Thead,
	Tbody,
	Tfoot,
	Tr,
	Th,
	Td,
	Center,
	Tooltip,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	AlertDialog,
	AlertDialogOverlay,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogBody,
	AlertDialogFooter,
	useToast,
	useDisclosure,
	Badge,
	Divider,
} from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faSlidersH } from '@fortawesome/free-solid-svg-icons';
import { SectionHeader, FullAddress } from '../../Other';
import ReactPaginate from 'react-paginate';
import DownloadAndViewButton from '../../Files/DownloadAndViewButton';
import AddCompanyCertificateModal from '../../Modal/AddCompanyCertificateModal';
import AddCompanyCertificateExclusiveModal from '../../Modal/AddCompanyCertificateExclusiveModal';
import EditCompanyCertificateModal from '../../Modal/EditCompanyCertificateModal';
import EditCompanyCertificateExclusiveModal from '../../Modal/EditCompanyCertificateExclusiveModal';
import AddManualCompanyBranchPartnerModal from '../../Modal/AddManualCompanyBranchPartnerModal';
import DeleteManualCompanyBranchPartnerModal from '../../Modal/DeleteManualCompanyBranchPartnerModal';
import DistributionSystemCertificatePrint from './DistributionSystemCertificatePrint';
import CompanyDetailBranchFilter from '../../Filter/CompanyDetailBranchFilter';
import DistributionSystemCertificateSalePartner from './DistributionSystemCertificateSalePartner';
import LoadingTable from '../../Table/Loading';
import useMoment from '../../../hooks/useMoment';
import useStore from '../../../store';
import c from '../../../constant';

const Q_GET_COMPANY = gql`
	query get($id: String!) {
		getCompanyById(id: $id) {
			type
		}
	}
`;

const Q_GET_CERTIFICATES = gql`
	query get($id: String!, $offset: Float, $limit: Float, $companyCertificateType: String, $name: String, $status: ValidityType) {
		getCompanyCertificate(
			companyId: $id
			companyCertificateType: $companyCertificateType
			pagerInput: { offset: $offset, limit: $limit }
			name: $name
			status: $status
		) {
			totalRows
			totalPages
			branches {
				id
				number
				name
				nameLatin
				managerName
				managerNameLatin
				validFrom
				validTo
				file
				streetNumber
				houseNumber
				geoManual
				isGeoManual
				provinceObject {
					code
					name
					latinName
					administrativeUnit
					administrativeUnitLatin
				}
				districtObject {
					code
					name
					latinName
					administrativeUnit
					administrativeUnitLatin
				}
				communeObject {
					code
					name
					latinName
					administrativeUnit
					administrativeUnitLatin
				}
				villageObject {
					code
					name
					latinName
					administrativeUnit
					administrativeUnitLatin
				}
				isPermanentLicense
				status
				salePartners {
					id
					branch {
						id
						number
						name
						nameLatin
					}
					partner {
						id
						name
						nameKhmer
						logo
						type
					}
				}
			}
		}
	}
`;

const M_DELETE_CERTIFICATE = gql`
	mutation DeleteCompanyCertificate($id: String!) {
		deleteCompanyCertificate(id: $id)
	}
`;

let workingId = null;
let limit = 5;
let offset = 0;

function DistributionSystemCertificate({ id, ...props }) {
	const deleteDialogRef = useRef();
	const deleteDialogCancelRef = useRef();
	const toast = useToast();
	const { t } = useTranslation();
	const { toDateString } = useMoment();
	const { currentUser } = useStore((state) => state.currentUser);
	const { currentLang } = useStore((state) => state.currentLang);
	const { branchValidityAlertCount } = useStore((state) => state.branchValidityAlertCount);
	const { isOpen: isInserting, onOpen: openInserting, onClose: closeInserting } = useDisclosure();
	const { isOpen: isEditing, onOpen: openEditing, onClose: closeEditing } = useDisclosure();
	const { isOpen: isDeleting, onOpen: openDeleting, onClose: closeDeleting } = useDisclosure();
	const { isOpen: isFiltering, onOpen: openFiltering, onClose: closeFiltering } = useDisclosure();
	const addModal = useDisclosure();
	const deleteModal = useDisclosure();
	const [name, setName] = useState(null);
	const [status, setStatus] = useState(null);
	const [getCompany, { loading: loadingCompany, data: dataCompany }] = useLazyQuery(Q_GET_COMPANY);
	const [getCertificates, { loading: loadingCertificates, error: errorCertificates, data: dataCertificates }] = useLazyQuery(Q_GET_CERTIFICATES);
	const [deleteCertificate, { loading: loadingDelCertificate, error: errorDelCertificate, data: dataDelCertificate }] = useMutation(M_DELETE_CERTIFICATE);

	useEffect(() => {
		offset = 0;
	}, [id]);

	useEffect(() => {
		const variables = { id };
		getCompany({ variables });
	}, [id]);

	useEffect(() => {
		if (dataCompany) {
			offset = 0;
			const variables = {
				id,
				offset,
				limit,
				companyCertificateType: 'b',
				name,
				status,
			};
			getCertificates({ variables });
		}
	}, [id, dataCompany, name, status]);

	useEffect(() => {
		if (errorCertificates) {
			errorCertificates.graphQLErrors.map(({ message }, i) =>
				toast({
					title: t(message),
					status: 'error',
					isClosable: true,
					position: 'top-right',
				})
			);
		}
	}, [errorCertificates]);

	useEffect(() => {
		if (dataDelCertificate?.deleteCompanyCertificate) {
			toast({
				title: t('Company Certificate deleted successfully'),
				status: 'success',
				isClosable: true,
				position: 'top-right',
			});
			const variables = {
				id,
				offset,
				limit,
				companyCertificateType: 'b',
				name,
				status,
			};
			getCertificates({ variables });
			closeDeleting();
		}
	}, [dataDelCertificate]);

	useEffect(() => {
		if (errorDelCertificate) {
			errorDelCertificate.graphQLErrors.map(({ message }, i) =>
				toast({
					title: t(message),
					status: 'error',
					isClosable: true,
					position: 'top-right',
				})
			);
		}
	}, [errorDelCertificate]);

	function isExclusive() {
		return ['INSURANCE_BROKER', 'INSURANCE_AGENT', 'INSURANCE_LOSS_ADJUSTER'].includes(dataCompany?.getCompanyById?.type);
	}

	function isInsuranceAgent() {
		return ['INSURANCE_AGENT'].includes(dataCompany?.getCompanyById?.type);
	}

	function ActionMenu({ id = null }) {
		return (
			<Menu>
				<Tooltip
					label={
						isInsuranceAgent()
							? `${t('Add Patnered Company')} ${t('Delete Patnered Company')} ${t('Edit')} ${t('Delete')}`
							: `${t('Edit')} ${t('Delete')}`
					}
				>
					<MenuButton cursor="pointer">
						<Flex pl="2" pr="2">
							<FontAwesomeIcon icon={faEllipsisV} style={{ fontSize: 16, marginTop: 4, marginLeft: 6 }} />
						</Flex>
					</MenuButton>
				</Tooltip>
				<MenuList pt="4" pb="4" pl="3" pr="3" borderRadius={c.borderRadius}>
					{isInsuranceAgent() && (
						<>
							<MenuItem
								color={'blue.500'}
								onClick={() => {
									workingId = id;
									addModal?.onOpen();
								}}
							>
								{t('Add Patnered Company')}
							</MenuItem>
							<MenuItem
								color={'red.500'}
								onClick={() => {
									workingId = id;
									deleteModal?.onOpen();
								}}
							>
								{t('Delete Patnered Company')}
							</MenuItem>
							<Divider />
						</>
					)}
					<MenuItem
						color={'blue.500'}
						onClick={() => {
							workingId = id;
							openEditing();
						}}
					>
						{t('Edit')}
					</MenuItem>
					<MenuItem
						color={'red.500'}
						onClick={() => {
							workingId = id;
							openDeleting();
						}}
					>
						{t('Delete')}
					</MenuItem>
				</MenuList>
			</Menu>
		);
	}

	function onPageChange(value) {
		offset = value * limit;
		const variables = {
			id,
			offset,
			limit,
			companyCertificateType: 'b',
			name,
			status,
		};
		getCertificates({ variables });
	}

	return (
		<>
			<Flex mt={6} {...props}>
				<Box className="hidden-print" width="100%">
					<Flex alignItems="center">
						<SectionHeader title={t('Branches')} />
						{branchValidityAlertCount > 0 && (
							<Box>
								<Tooltip label={t('Need(s) Renewal')}>
									<Badge colorScheme="red" variant="solid" ml={1}>
										{branchValidityAlertCount}
									</Badge>
								</Tooltip>
							</Box>
						)}
					</Flex>
					<Center mt={4}>
						<Box
							w="100%"
							bg="white"
							borderRadius={c.borderRadius}
							border="1px solid #dbdbdb"
							boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
							pb={2}
						>
							<Flex p={4} pt={2} pb={2} mb={4} h="48px" bg="#FAFAFA" alignItems="center" justifyContent="end" borderBottom="1px solid #dbdbdb">
								<Flex alignItems="center">
									<Button
										onMouseDown={openFiltering}
										leftIcon={<FontAwesomeIcon icon={faSlidersH} style={{ fontSize: 16 }} />}
										colorScheme={`${name || status ? 'yellow' : 'gray'}`}
										variant="solid"
										size="sm"
										mr={2}
										borderRadius={c.borderRadius}
										border="1px solid #bdc3c7}"
									>
										{t('Filter')}
									</Button>
									{(currentUser?.role === 'admin' || currentUser?.canUpdateCompanyLicensing) && (
										<Tooltip label={t('Add Company Certificate')}>
											<Flex
												userSelect="none"
												onClick={openInserting}
												cursor="pointer"
												alignItems="center"
												justifyContent="center"
												w="32px"
												h="32px"
												borderRadius={c.borderRadius}
												border="1px solid #dbdbdb"
												boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
											>
												<Text fontSize="26px" mt="2px" fontWeight="500">
													+
												</Text>
											</Flex>
										</Tooltip>
									)}
								</Flex>
							</Flex>
							{loadingCompany && <Text m={4}>{t('Loading')}</Text>}
							{dataCompany?.getCompanyById?.type && (
								<Box p="16px">
									{isExclusive() ? (
										<Table variant="simple">
											<Thead>
												<Tr>
													<Th>{t('Number')}</Th>
													<Th>{t('Name')}</Th>
													<Th>{t('Insurance product sale manager')}</Th>
													<Th>
														{t('Certificate')}/{t('Permit letter')}
													</Th>
													{isInsuranceAgent() && <Th>{t('Patnered Company')}</Th>}
													{(currentUser?.role === 'admin' || currentUser?.canUpdateCompanyLicensing) && (
														<Th isNumeric>{t('Actions')}</Th>
													)}
												</Tr>
											</Thead>
											<Tbody>
												{loadingCertificates && <LoadingTable column={5} row={3} />}
												{dataCertificates?.getCompanyCertificate?.branches?.length <= 0 && (
													<Tr>
														<Td>
															<Text>{t('No Data')}</Text>
														</Td>
													</Tr>
												)}
												{dataCertificates?.getCompanyCertificate?.branches?.map((item, index) => {
													return (
														<Tr key={`item-${index}`} bg="white" _hover={{ bg: 'gray.200' }}>
															<Td>
																<Text fontWeight="bold">{item?.number}</Text>
															</Td>
															<Td>
																<Text>{item?.name}</Text>
															</Td>
															<Td>
																<Text>{currentLang === 'kh' ? item?.managerName : item?.managerNameLatin}</Text>
															</Td>
															<Td>
																{item?.validFrom && item?.validTo && (
																	<>
																		<>
																			{item?.status === 'Normal' && (
																				<Flex direction="column" mb={1}>
																					<Box mb={1}>
																						<Tooltip label={t('Valid')}>
																							<Badge backgroundColor="green.500">
																								<Text fontSize="xs" color="white">
																									{t('Valid')}
																								</Text>
																							</Badge>
																						</Tooltip>
																					</Box>
																					<Text fontSize="sm" fontWeight="md" color="black.800">
																						<>{toDateString(item?.validFrom, c.excelDateOnlyTextFormat)}</>
																						<> - </>
																						<>{toDateString(item?.validTo, c.excelDateOnlyTextFormat)}</>
																					</Text>
																				</Flex>
																			)}
																		</>
																		<>
																			{item?.status === 'NearlyDue' && (
																				<Flex direction="column" mb={1}>
																					<Box mb={1}>
																						<Tooltip label={t('Nearly Due')}>
																							<Badge backgroundColor="yellow.500">
																								<Text fontSize="xs" color="white">
																									{t('Nearly Due')}
																								</Text>
																							</Badge>
																						</Tooltip>
																					</Box>
																					<Text fontSize="sm" fontWeight="bold" color="yellow.500">
																						<>{toDateString(item?.validFrom, c.excelDateOnlyTextFormat)}</>
																						<> - </>
																						<>{toDateString(item?.validTo, c.excelDateOnlyTextFormat)}</>
																					</Text>
																				</Flex>
																			)}
																		</>
																		<>
																			{item?.status === 'Due' && (
																				<Flex direction="column" mb={1}>
																					<Box mb={1}>
																						<Tooltip label={t('Due')}>
																							<Badge backgroundColor="red.500">
																								<Text fontSize="xs" color="white">
																									{t('Due')}
																								</Text>
																							</Badge>
																						</Tooltip>
																					</Box>
																					<Text fontSize="sm" fontWeight="bold" color="red.500">
																						<>{toDateString(item?.validFrom, c.excelDateOnlyTextFormat)}</>
																						<> - </>
																						<>{toDateString(item?.validTo, c.excelDateOnlyTextFormat)}</>
																					</Text>
																				</Flex>
																			)}
																		</>
																	</>
																)}
																{item?.file?.file && item?.file?.size && (
																	<DownloadAndViewButton url={item?.file?.file} size={item?.file?.size} marginLeft={0} />
																)}
															</Td>
															{isInsuranceAgent() && (
																<Td>
																	{item?.salePartners?.map((el, i) => (
																		<Box key={i} mt={i === 0 ? 0 : 4}>
																			<DistributionSystemCertificateSalePartner partner={el?.partner} />
																		</Box>
																	))}
																</Td>
															)}
															{(currentUser?.role === 'admin' || currentUser?.canUpdateCompanyLicensing) && (
																<Td isNumeric>
																	<ActionMenu id={item?.id} />
																</Td>
															)}
														</Tr>
													);
												})}
											</Tbody>
											<Tfoot>
												<Tr>
													<Th>{t('Number')}</Th>
													<Th>{t('Name')}</Th>
													<Th>{t('Insurance product sale manager')}</Th>
													<Th>
														{t('Certificate')}/{t('Permit letter')}
													</Th>
													{isInsuranceAgent() && <Th>{t('Patnered Company')}</Th>}
													{(currentUser?.role === 'admin' || currentUser?.canUpdateCompanyLicensing) && (
														<Th isNumeric>{t('Actions')}</Th>
													)}
												</Tr>
											</Tfoot>
										</Table>
									) : (
										<Table variant="simple">
											<Thead>
												<Tr>
													<Th>{t('Number')}</Th>
													<Th>{t('Name')}</Th>
													<Th>{t('Name Latin')}</Th>
													<Th>{t('Branch Manager')}</Th>
													<Th>{t('Address')}</Th>
													<Th>{t('Valid')}</Th>
													<Th>
														{t('Certificate')}/{t('Permit letter')}
													</Th>
													{(currentUser?.role === 'admin' || currentUser?.canUpdateCompanyLicensing) && (
														<Th isNumeric>{t('Actions')}</Th>
													)}
												</Tr>
											</Thead>
											<Tbody>
												{loadingCertificates && <LoadingTable column={8} row={3} />}
												{dataCertificates?.getCompanyCertificate?.branches?.length <= 0 && (
													<Tr>
														<Td>
															<Text>{t('No Data')}</Text>
														</Td>
													</Tr>
												)}
												{dataCertificates?.getCompanyCertificate?.branches?.map((item, index) => {
													return (
														<Tr cursor="pointer" _hover={{ bg: 'gray.200' }} key={`item-${index}`} bg="white">
															<Td>{item?.number}</Td>
															<Td>{item?.name}</Td>
															<Td>{item?.nameLatin}</Td>
															<Td>{currentLang === 'kh' ? item?.managerName : item?.managerNameLatin}</Td>
															<Td>{item?.isGeoManual ? item?.geoManual : <FullAddress object={item} />}</Td>
															<Td>
																{item?.validFrom && item?.validTo && (
																	<>
																		<>
																			{item?.status === 'Normal' && (
																				<Flex direction="column" mb={1}>
																					<Box mb={1}>
																						<Tooltip label={t('Valid')}>
																							<Badge backgroundColor="green.500">
																								<Text fontSize="xs" color="white">
																									{t('Valid')}
																								</Text>
																							</Badge>
																						</Tooltip>
																					</Box>
																					<Text fontSize="sm" fontWeight="md" color="black.800">
																						<>{toDateString(item?.validFrom, c.excelDateOnlyTextFormat)}</>
																						<> - </>
																						<>{toDateString(item?.validTo, c.excelDateOnlyTextFormat)}</>
																					</Text>
																				</Flex>
																			)}
																		</>
																		<>
																			{item?.status === 'NearlyDue' && (
																				<Flex direction="column" mb={1}>
																					<Box mb={1}>
																						<Tooltip label={t('Nearly Due')}>
																							<Badge backgroundColor="yellow.500">
																								<Text fontSize="xs" color="white">
																									{t('Nearly Due')}
																								</Text>
																							</Badge>
																						</Tooltip>
																					</Box>
																					<Text fontSize="sm" fontWeight="bold" color="yellow.500">
																						<>{toDateString(item?.validFrom, c.excelDateOnlyTextFormat)}</>
																						<> - </>
																						<>{toDateString(item?.validTo, c.excelDateOnlyTextFormat)}</>
																					</Text>
																				</Flex>
																			)}
																		</>
																		<>
																			{item?.status === 'Due' && (
																				<Flex direction="column" mb={1}>
																					<Box mb={1}>
																						<Tooltip label={t('Due')}>
																							<Badge backgroundColor="red.500">
																								<Text fontSize="xs" color="white">
																									{t('Due')}
																								</Text>
																							</Badge>
																						</Tooltip>
																					</Box>
																					<Text fontSize="sm" fontWeight="bold" color="red.500">
																						<>{toDateString(item?.validFrom, c.excelDateOnlyTextFormat)}</>
																						<> - </>
																						<>{toDateString(item?.validTo, c.excelDateOnlyTextFormat)}</>
																					</Text>
																				</Flex>
																			)}
																		</>
																	</>
																)}
															</Td>
															<Td w="228px">
																{item?.file?.file && item?.file?.size && (
																	<DownloadAndViewButton url={item?.file?.file} size={item?.file?.size} marginLeft={0} />
																)}
															</Td>
															{(currentUser?.role === 'admin' || currentUser?.canUpdateCompanyLicensing) && (
																<Td isNumeric>
																	<ActionMenu id={item?.id} />
																</Td>
															)}
														</Tr>
													);
												})}
											</Tbody>
											<Tfoot>
												<Tr>
													<Th>{t('Number')}</Th>
													<Th>{t('Name')}</Th>
													<Th>{t('Name Latin')}</Th>
													<Th>{t('Branch Manager')}</Th>
													<Th>{t('Address')}</Th>
													<Th>{t('Valid')}</Th>
													<Th>
														{t('Certificate')}/{t('Permit letter')}
													</Th>
													{(currentUser?.role === 'admin' || currentUser?.canUpdateCompanyLicensing) && (
														<Th isNumeric>{t('Actions')}</Th>
													)}
												</Tr>
											</Tfoot>
										</Table>
									)}
									<Flex justifyContent="flex-end" w="100%" mt="4">
										{dataCertificates?.getCompanyCertificate?.totalPages > 1 && (
											<ReactPaginate
												previousLabel={<ChevronLeftIcon fontSize="20px" />}
												nextLabel={<ChevronRightIcon fontSize="20px" />}
												breakLabel={'...'}
												breakClassName={'break-me'}
												disableInitialCallback={true}
												pageCount={dataCertificates?.getCompanyCertificate?.totalPages}
												marginPagesDisplayed={2}
												pageRangeDisplayed={3}
												onPageChange={({ selected }) => onPageChange(selected)}
												containerClassName={'pagination'}
												activeClassName={'active'}
												initialPage={offset / limit}
											/>
										)}
									</Flex>
								</Box>
							)}
						</Box>
					</Center>
				</Box>
			</Flex>
			<AlertDialog isOpen={isDeleting} leastDestructiveRef={deleteDialogRef} onClose={closeDeleting}>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							{t('Delete Company Certificate')}
						</AlertDialogHeader>

						<AlertDialogBody>{t("Are you sure? You can't undo this action afterwards")}</AlertDialogBody>
						<AlertDialogFooter>
							<Button mr={4} isLoading={loadingDelCertificate} ref={deleteDialogCancelRef} onClick={closeDeleting}>
								{t('Cancel')}
							</Button>
							<Button
								isLoading={loadingDelCertificate}
								colorScheme="red"
								onClick={() => {
									const variables = { id: workingId };
									deleteCertificate({ variables });
								}}
							>
								{t('Delete')}
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
			{isExclusive() ? (
				<AddCompanyCertificateExclusiveModal
					companyId={id}
					isOpen={isInserting}
					onClose={closeInserting}
					callback={(e) => {
						const variables = {
							id,
							offset,
							limit,
							companyCertificateType: 'b',
							name,
							status,
						};
						getCertificates({ variables });
					}}
				/>
			) : (
				<AddCompanyCertificateModal
					companyId={id}
					isOpen={isInserting}
					onClose={closeInserting}
					callback={(e) => {
						const variables = {
							id,
							offset,
							limit,
							companyCertificateType: 'b',
							name,
							status,
						};
						getCertificates({ variables });
					}}
				/>
			)}
			{isEditing && (
				<>
					{isExclusive() ? (
						<EditCompanyCertificateExclusiveModal
							id={workingId}
							isOpen={true}
							onClose={closeEditing}
							callback={(e) => {
								const variables = {
									id,
									offset,
									limit,
									companyCertificateType: 'b',
									name,
									status,
								};
								getCertificates({ variables });
							}}
						/>
					) : (
						<EditCompanyCertificateModal
							id={workingId}
							isOpen={true}
							onClose={closeEditing}
							callback={(e) => {
								const variables = {
									id,
									offset,
									limit,
									companyCertificateType: 'b',
									name,
									status,
								};
								getCertificates({ variables });
							}}
						/>
					)}
				</>
			)}
			{dataCertificates && (
				<DistributionSystemCertificatePrint dataCertificates={dataCertificates} isExclusive={isExclusive()} isInsuranceAgent={isInsuranceAgent()} />
			)}
			{isFiltering && (
				<CompanyDetailBranchFilter
					name={name}
					status={status}
					onClose={closeFiltering}
					callback={(el) => {
						setName(el?.name);
						setStatus(el?.status);
					}}
				/>
			)}
			{isInsuranceAgent() && (
				<>
					{addModal?.isOpen && (
						<AddManualCompanyBranchPartnerModal
							branchId={workingId}
							isOpen={true}
							onClose={addModal?.onClose}
							onCallback={() => {
								const variables = {
									id,
									offset,
									limit,
									companyCertificateType: 'b',
									name,
									status,
								};
								getCertificates({ variables });
							}}
						/>
					)}
					{deleteModal?.isOpen && (
						<DeleteManualCompanyBranchPartnerModal
							branchId={workingId}
							partners={dataCertificates?.getCompanyCertificate?.branches
								?.find((el) => el?.id === workingId)
								?.salePartners?.map((el) => el?.partner)}
							isOpen={true}
							onClose={deleteModal?.onClose}
							onCallback={() => {
								const variables = {
									id,
									offset,
									limit,
									companyCertificateType: 'b',
									name,
									status,
								};
								getCertificates({ variables });
							}}
						/>
					)}
				</>
			)}
		</>
	);
}

export default DistributionSystemCertificate;
