import { Flex, Text, Button } from "@chakra-ui/react";

import c from "../../constant"
import { useTranslation } from "react-i18next";

export default function ArticlePDF({content, localFile}) {
	const { t } = useTranslation();
    return (
        <>
            <Flex p="4" w="250px" direction="column" bg="#3498db" borderRadius="3px">
                <Flex alignItems="center">
                    <Text color="white">
                        {t("PDF File")}
                    </Text>
                    <a
                        href={
                            localFile?content.file:
                            c.baseFilePath+content.file
                        }
                        target="_blank"
                        download
                    >
                        <Button borderRadius={c.borderRadius} size="sm" ml="2">{t("Download")} {content.fileSize&&` ( ${parseFloat(content.fileSize/1048576).toFixed(2)}MB ) `}</Button>
                    </a>
                </Flex>
                <Text mt="2" color="white">{content.fileName}</Text>
            </Flex>
        </>
    );
}
