import { Badge, Text, Tooltip } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const PartnerRequestStatus = ({ status = null }) => {
	const { t } = useTranslation();

	switch (status) {
		case 1:
			return (
				<Tooltip label={t('Cancelled')}>
					<Badge pl={2} pr={2} colorScheme="red" variant="solid">
						<Text fontWeight="bold" fontSize="xs" color="white">
							{t('Cancelled')}
						</Text>
					</Badge>
				</Tooltip>
			);
		case 2:
			return (
				<Tooltip label={t('Waiting For Contract')}>
					<Badge pl={2} pr={2} colorScheme="yellow" variant="solid">
						<Text fontWeight="bold" fontSize="xs" color="white">
							{t('Waiting For Contract')}
						</Text>
					</Badge>
				</Tooltip>
			);
		case 3:
			return (
				<Tooltip label={t('Reviewing Agent By Office')}>
					<Badge pl={2} pr={2} colorScheme="orange" variant="solid">
						<Text fontWeight="bold" fontSize="xs" color="white">
							{t('Reviewing Agent By Office')}
						</Text>
					</Badge>
				</Tooltip>
			);
		case 4:
			return (
				<Tooltip label={t('Reviewing Agent By Department')}>
					<Badge pl={2} pr={2} colorScheme="orange" variant="solid">
						<Text fontWeight="bold" fontSize="xs" color="white">
							{t('Reviewing Agent By Department')}
						</Text>
					</Badge>
				</Tooltip>
			);
		case 5:
			return (
				<Tooltip label={t('Waiting For Contract')}>
					<Badge pl={2} pr={2} colorScheme="teal" variant="solid">
						<Text fontWeight="bold" fontSize="xs" color="white">
							{t('Waiting For Contract')}
						</Text>
					</Badge>
				</Tooltip>
			);
		case 6:
			return (
				<Tooltip label={t('Reviewing Contract By Office')}>
					<Badge pl={2} pr={2} colorScheme="blue" variant="solid">
						<Text fontWeight="bold" fontSize="xs" color="white">
							{t('Reviewing Contract By Office')}
						</Text>
					</Badge>
				</Tooltip>
			);
		case 7:
			return (
				<Tooltip label={t('Reviewing Contract By Department')}>
					<Badge pl={2} pr={2} colorScheme="blue" variant="solid">
						<Text fontWeight="bold" fontSize="xs" color="white">
							{t('Reviewing Contract By Department')}
						</Text>
					</Badge>
				</Tooltip>
			);
		case 8:
			return (
				<Tooltip label={t('Done')}>
					<Badge pl={2} pr={2} colorScheme="green" variant="solid">
						<Text fontWeight="bold" fontSize="xs" color="white">
							{t('Done')}
						</Text>
					</Badge>
				</Tooltip>
			);
		case 9:
			return (
				<Tooltip label={t('Expired')}>
					<Badge pl={2} pr={2} colorScheme="gray" variant="solid">
						<Text fontWeight="bold" fontSize="xs" color="white">
							{t('Expired')}
						</Text>
					</Badge>
				</Tooltip>
			);
		default:
			return (
				<Text fontSize="sm" fontWeight="medium">
					{t('N/A')}
				</Text>
			);
	}
};

export default PartnerRequestStatus;
