import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Container, Box, Flex, Text, Center, Button, useToast,
    RadioGroup, Stack, Radio, FormControl, FormLabel, FormHelperText
} from "@chakra-ui/react";
import BreadcrumbComponent from "../../components/BreadcrumbComponent/BreadcrumbComponent";
import c from "../../constant"
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { useHistory } from "react-router-dom";
import DateRangeFormField from "../../components/form/DateRangeFormField";
import moment from "moment";
import 'moment/locale/en-gb';
import CompanyPickerModal from "../../components/Modal/CompanyPickerModal"
import UploadingModalProgress from "../../components/UploadingModalProgress/UploadingModalProgress"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons";
import useStore from "../../store";

import CompanyFormField from "../../components/form/CompanyFormField"
import TextFormField from "../../components/form/TextFormField"
import TextAreaFormField from "../../components/form/TextAreaFormField"
import RadioFormField from "../../components/form/RadioFormField"
import SelectFormField from "../../components/form/SelectFormField"
import FileFormField from "../../components/form/FileFormField"
const MAX_FILE_SIZE = 104857600;

const QUERY_PUBLIC_SERVICE = gql`
query{
    getPublicPublicServices{
      id
      title
      value
      steps
    }
  }
`

const complaintOnInsuranceSchema = Yup.object().shape({
    companyName: Yup.string().when('selectedCompany', {
        is: (selectedCompany) => !selectedCompany,
        then: Yup.string().required("required"),
        otherwise: Yup.string()
    }),
    selectedCompany: Yup.string(),
    subject: Yup.string().required("required"),

    type: Yup.string().required("required"),

    publicService: Yup.string().when('type', {
        is: (type) => type === 'PUBLIC_SERVICE',
        then: Yup.string().required("required"),
        otherwise: Yup.string()
    }),

    file: Yup.mixed().test("fileSize", "over limit", (value) => {
        if (value) {
            return value?.size <= MAX_FILE_SIZE
        } else {
            return true
        }
    }).required('required'),

    // serviceFee: Yup.string().when('type', {
    //     is: (type) => type === 'PUBLIC_SERVICE',
    //     then: Yup.mixed().test("fileSize", "over limit", (value) => {
    //         if (value) {
    //             return value?.size <= (MAX_FILE_SIZE/20)
    //         } else {
    //             return true
    //         }
    //     }).required('required'),
    //     otherwise: Yup.string()
    // }),
    serviceFee: Yup.mixed().when('type', {
        is: (type) => type === 'PUBLIC_SERVICE',
        then: Yup.mixed().test( "fileSize", "over limit", (value) =>{
            if(value){
                return value?.size <= MAX_FILE_SIZE/20
            }else{ 
                return true
            }
        }).required('required'),
        otherwise: Yup.mixed().test( "fileSize", "over limit", (value) =>{
            if(value){
                return value?.size <= MAX_FILE_SIZE/20
            }else{ 
                return true
            }
        })
    }),
});


const CREATE_PROPOSAL_MUTATION = gql`
    mutation createCompanySubmissionProposalAdmin(
        $companyName: String
        $companyId: String
        $subject: String!
        $publicService: String
        $fileSize: Float!
        $file: Upload!
        $serviceFeeFileSize: Float
        $serviceFee: Upload
        $selectedStep: String
    ){
        createCompanySubmissionProposalAdmin(
            companyName: $companyName
            companyId: $companyId
            subject: $subject
            publicService: $publicService
            file: $file
            fileSize: $fileSize
            serviceFeeFileSize: $serviceFeeFileSize
            serviceFee: $serviceFee
            selectedStep: $selectedStep
        )
    }
`

function AddCompanyProposal() {
    moment.locale("en-gb")
    const history = useHistory();
    const toast = useToast()
    const { t } = useTranslation();
    const { currentLang } = useStore((state) => state.currentLang);

    const [showCompanyPicker, setShowCompanyPicker] = useState(false)
    const [selectedCompany, setSelectedCompany] = useState(null)
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [type, setType] = useState("OTHER")
    
    const [uploading, setUploading] = useState(false)
    const [progress, setProgress] = useState(0);

    const [createProposal, { error, data }] = useMutation(
        CREATE_PROPOSAL_MUTATION
    );
    const [loadPublicService, { error:errorPublicService, data:dataPublicService }] = useLazyQuery(
        QUERY_PUBLIC_SERVICE
    );


    const [selectedPublicService, setSelectedPublicService] = useState(null)
    const [selectedStep, setSelectedStep] = useState(null)
    const [mustSelectStep, setMustSelectStep] = useState(false)


    useEffect(()=>{
        loadPublicService()
    },[])

    useEffect(() => {
        if (error) {
            setUploading(false)
            if(error?.graphQLErrors?.length>0){
            error?.graphQLErrors.map(({ message }, i) => (
                toast({
                    title: t(message),
                    status: "error",
                    isClosable: true,
                    position: "bottom-right"
                })
            ))
            } else {
                toast({
                    title: t(error?.message?error?.message:"something went wrong"),
                    status: "error",
                    isClosable: true,
                    position: "bottom-right"
                })
            }
        }
    }, [error])

    useEffect(() => {
        if (data) {
            if (data.createCompanySubmissionProposalAdmin) {
                setUploading(false)
                toast({
                    title: t("Proposal submitted"),
                    description: t("We have received your proposal"),
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                    position: "bottom-right"
                })
                history.push(`/company-submission-proposal/success?number=${data.createCompanySubmissionProposalAdmin}`)
            }
        }
    }, [data])

    async function submit(values) {
        setUploading(true)
        let variables ={}
        if(type==="PUBLIC_SERVICE"){
            variables = {
                companyName: values.companyName,
                companyId: values?.selectedCompany,
                subject: values.subject,
                publicService: values.publicService,
                file: values.file,
                fileSize: values?.file?.size,
                serviceFee: values.serviceFee,
                serviceFeeFileSize: values.serviceFee?.size,
                selectedStep
            }
        } else {
            variables = {
                companyName: values.companyName,
                companyId: values?.selectedCompany,
                subject: values.subject,
                file: values.file,
                fileSize: values?.file?.size,
            }
        }

        let abort;
        createProposal({
            variables,
            context: {
                fetchOptions: {
                    useUpload: true,
                    onProgress: (ev) => {
                        setProgress(ev.loaded / ev.total);
                    },
                    onAbortPossible: (abortHandler) => {
                        abort = abortHandler;
                    }
                }
            }
        })

        if (abort) {
            abort();
        }
    }

    function getPublicServiceOptions(data){
        const res = []
        data.map((item)=>{
            res.push({
                label:item.title,
                value:item.value
            })
        })
        return res
    }

    function selectSteps(key){
        const tmpPB  = dataPublicService?.getPublicPublicServices?.filter(e=>{
            return e?.value===selectedPublicService
        })
        if(tmpPB?.[0]?.steps?.length>1){
            setMustSelectStep(true)
            return(
                <>
                    <FormControl mb="4"  key={key}>
                        {<FormLabel>{t("Step")}<span style={{color:"red"}}>*</span></FormLabel>}
                        <RadioGroup 
                            onChange={e=>{
                                setSelectedStep(e)
                            }} 
                            value={selectedStep}
                        >
                            <Stack direction="column">
                                
                                {
                                tmpPB?.[0]?.steps?.map((item,index)=>{
                                    return <Radio key={`rd-${index}`} value={item.id}>{currentLang==="kh"?item?.typeKH:item.type}</Radio>
                                })
                            }
                            
                            </Stack>
                        </RadioGroup>
                            {!selectedStep&&<FormHelperText
                                id="error-message-password"
                                color="red.400"
                                fontSize="12px"
                            >
                                {currentLang==="kh"?"សូមជ្រើសរើសដំណាក់កាល":"Please choose a step"}
                            </FormHelperText>}
                    
                    </FormControl>
                    
                </>
            )
        } else {
            setMustSelectStep(false)
        }

        return null
    }
    
    return (
        <>
            <Container maxW="container.xl" mt="16px" mb="16px">
                <BreadcrumbComponent
                    list={[
                        {
                            name: "PROPOSAL",
                            path: "/company-submission-proposal?page=1",
                        },
                        {
                            name: "Add proposal",
                            path: "#",
                        },
                    ]}
                />
                <Center mt="5">
                    <Box
                        w="100%"
                        bg="white"
                        borderRadius={c.borderRadius}
                        border="1px solid #dbdbdb"
                        boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
                        pb="32px"
                    >
                        <Flex bg="#FAFAFA" p="16px" pt="8px" pb="8px" mb="16px" justifyContent="space-between" borderBottom="1px solid #dbdbdb">
                            <Text
                                fontSize="x-large"
                                fontWeight="bold"
                            >
                                {t("Add proposal")}
                            </Text>
                        </Flex>

                        <Box p="16px" maxW="480px">
                        <Formik
                                    initialValues={{
                                        companyName: "",
                                        subject: "",
                                        type: "OTHER",
                                        publicService: "",
                                        file: null,
                                        serviceFee: null
                                    }}
                                    validationSchema={complaintOnInsuranceSchema}
                                    onSubmit={(values) => {
                                        if(mustSelectStep){
                                            if(selectedStep){
                                                submit(values)
                                            } else {
                                                alert(currentLang==="kh"?"សូមជ្រើសរើសដំណាក់កាល":"Please choose a step")
                                            }
                                        } else {
                                            submit(values)
                                        }
                                    }}
                                >
                                    {(props) => (
                                        <Box>
                                            <Form>
                                                
                                                {selectedCompany?.id&&<CompanyFormField
                                                    {...props}
                                                    label="Company/Institution Name"
                                                    name="selectedCompany"
                                                    required
                                                    callBackClear={()=> setSelectedCompany(null)}
                                                    callBackList={()=>setShowCompanyPicker(true)}
                                                    selectedCompany={selectedCompany}
                                                />}
                                                {!selectedCompany?.id&&<TextFormField
                                                    {...props}
                                                    label="Company/Institution Name"
                                                    name="companyName"
                                                    required
                                                    rightElement={
                                                        <Button
                                                            onClick={()=>setShowCompanyPicker(true)}
                                                            leftIcon={
                                                                <FontAwesomeIcon
                                                                    style={{
                                                                        fontSize: 16,
                                                                        cursor: "pointer",
                                                                    }}
                                                                    icon={faList}
                                                                />
                                                            } 
                                                            mt="7px"
                                                            variant="solid"
                                                            h="42px"
                                                            mr="3px"
                                                            borderRadius={c.borderRadius}
                                                        >
                                                            {t("List")}
                                                        </Button>
                                                    }
                                                />}

                                                <TextAreaFormField
                                                    {...props}
                                                    label="Subject"
                                                    name="subject"
                                                    required
                                                />

                                                {/* <RadioFormField
                                                    name="type"
                                                    label="Type"
                                                    radios={[
                                                        {label:"Public Service", value:"PUBLIC_SERVICE"},
                                                        {label:"Other", value:"OTHER"}
                                                    ]}
                                                    required
                                                    callbackValueChange={e=>setType(e)}
                                                /> */}

                                                {type==="PUBLIC_SERVICE"&&<>

                                                    <SelectFormField
                                                        label="Public Service"
                                                        name="publicService"
                                                        options={getPublicServiceOptions(dataPublicService?.getPublicPublicServices)}
                                                        required
                                                        callbackValueChange={e=>{
                                                            setSelectedPublicService(e?.target?.value)
                                                            setSelectedStep(null)
                                                        }}
                                                    />

                                                    {selectSteps(selectedPublicService)}

                                                    <FileFormField
                                                        {...props}
                                                        label="Required Document"
                                                        name="file"
                                                        maxFileSize={MAX_FILE_SIZE}
                                                        required
                                                    />

                                                    <FileFormField
                                                        {...props}
                                                        label="Service Fee (Receipt image)"
                                                        name="serviceFee"
                                                        maxFileSize={MAX_FILE_SIZE/20}
                                                        required
                                                        accept="image/jpeg,image/gif,image/png,application/pdf"
                                                    />

                                                </>}

                                                {type==="OTHER"&&<>
                                                    <FileFormField
                                                        {...props}
                                                        label="File"
                                                        name="file"
                                                        maxFileSize={MAX_FILE_SIZE}
                                                        required
                                                    />
                                                </>}


                                                <Button
                                                    id="login-btn"
                                                    variant="solid"
                                                    size="md"
                                                    h="42px"
                                                    w="128px"
                                                    type="submit"
                                                    borderRadius={c.borderRadius}
                                                >
                                                    {t("Submit")}
                                                </Button>

                                            </Form>

                                        </Box>
                                    )}
                                </Formik>
                        </Box>
                    </Box>
                </Center>
            </Container>

            <UploadingModalProgress isOpen={uploading} progress={progress}/>

            {showCompanyPicker&&<CompanyPickerModal onClose={()=>setShowCompanyPicker(false)} callBack={e=>setSelectedCompany(e)}/>}

        </>
    );
}

export default AddCompanyProposal;
