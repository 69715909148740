/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Input, Box, FormControl, FormLabel, FormHelperText, Flex, WrapItem, Avatar, Text } from '@chakra-ui/react';
import { Field, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { gql, useLazyQuery } from '@apollo/client';
import useStore from '../../store';
import c from '../../constant';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

const Q_AGENT_BY_CODE = gql`
	query get($code: String!) {
		getAgentByCode(code: $code) {
			id
			code
			name
			latinName
		}
	}
`;

const InputAgentCodeFormField = ({ label, name, placeholder, required }) => {
	const { values, setFieldValue } = useFormikContext();
	const { t } = useTranslation();
	const { currentLang: lang } = useStore((state) => state.currentLang);
	const [text, setText] = useState(values[name]);
	const [getAgent, { data }] = useLazyQuery(Q_AGENT_BY_CODE);

	useEffect(() => {
		const timeOut = setTimeout(() => {
			getAgent({ variables: { code: text || '' } });
		}, 600);

		return () => {
			clearTimeout(timeOut);
		};
	}, [text]);

	const UserInformation = ({ name, code }) => {
		return (
			<Flex>
				<Avatar mr={2} size="md" name={name} />
				<Box>
					<Text fontWeight="600" color="gray.400">
						{name}
					</Text>
					<Text fontWeight="400" color="blue.400">
						{code}
					</Text>
				</Box>
			</Flex>
		);
	};

	return (
		<Field name={name}>
			{({ meta: { touched, error } }) => (
				<Box mb="4">
					<FormControl id={name}>
						<FormLabel>
							{t(label)}
							{required && <span style={{ color: 'red' }}>*</span>}
						</FormLabel>
						{values[name] ? (
							<>
								<Flex
									_hover={{ bg: '#ecf0f1' }}
									onClick={() => null}
									boxShadow="0px 4px 4px rgba(var(--black, #000), 0.2)"
									borderWidth="2px"
									borderColor="gray.200"
									borderRadius={c.borderRadius}
									cursor="pointer"
									direction="row"
									alignItems="center"
									justifyContent="space-between"
									mt={1}
									pl={3}
									pr={3}
									pt={1}
									pb={1}
								>
									<UserInformation
										name={lang === 'kh' ? data?.getAgentByCode?.name : data?.getAgentByCode?.latinName}
										code={data?.getAgentByCode?.code}
									/>
									<FontAwesomeIcon icon={faTimesCircle} color="#A0AEC0" size="lg" onClick={() => setFieldValue(name, '')} />
								</Flex>
							</>
						) : (
							<>
								<Input
									type="text"
									size="lg"
									value={text}
									placeholder={t(placeholder)}
									errorBorderColor="red.300"
									isInvalid={touched && error}
									onChange={(e) => setText(e.target.value)}
								/>
								{data?.getAgentByCode && (
									<Flex
										_hover={{ bg: '#ecf0f1' }}
										onClick={() => setFieldValue(name, data?.getAgentByCode?.code)}
										boxShadow="0px 4px 4px rgba(var(--black, #000), 0.2)"
										borderWidth="2px"
										borderColor="gray.200"
										borderRadius={c.borderRadius}
										cursor="pointer"
										direction="row"
										alignItems="center"
										mt={1}
										pl={3}
										pr={3}
										pt={1}
										pb={1}
									>
										<UserInformation
											name={lang === 'kh' ? data?.getAgentByCode?.name : data?.getAgentByCode?.latinName}
											code={data?.getAgentByCode?.code}
										/>
									</Flex>
								)}
								{touched && error && (
									<FormHelperText id={`error-message-${name}`} color="red.400" fontSize="12px">
										{t(name)} {t('is')} {t(error)}
									</FormHelperText>
								)}
							</>
						)}
					</FormControl>
				</Box>
			)}
		</Field>
	);
};

export default InputAgentCodeFormField;
