/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Text } from '@chakra-ui/react';
import useMoment from '../../../hooks/useMoment';
import c from '../../../constant';

export default function CardDate({ input = null }) {
	const { toDateString } = useMoment();
	return <Text>{input ? toDateString(input, c.dateTimeFormat) : 'N/A'}</Text>;
}
