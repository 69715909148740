/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { Box, Flex, Text, Center, Table, Checkbox, Tbody, Tfoot, Tr, Th, Td, Badge, Tooltip, Button, useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { gql, useLazyQuery } from '@apollo/client';
import { faSlidersH, faPrint, faChartLine, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { SectionHeader } from '../../Other';
import { CompanyDetailProposalFilter } from '../../../components/Filter';
import './style.css';
import c from '../../../constant';
import useMoment from '../../../hooks/useMoment';
import useStore from '../../../store';
import dateFromString from '../../../utils/datePickerFromToToDate';
import ReactPaginate from 'react-paginate';
import LoadingTable from '../../../components/Table/Loading';
import ProposalDetailModal from '../../../containers/CompanySubmissionProposal/detailModal';
import ExcelExportModal from '../../Modal/ExcelExportModal';
import ProposalPrint from './ProposalPrint';

const Q_GET_PROPOSALS_BY_COMPANY_ID = gql`
	query GetCompanySubmissionProposalsByCompanyId(
		$companyId: String!
		$limit: Float
		$offset: Float
		$fromDate: DateTime
		$toDate: DateTime
		$read: Boolean
		$number: Float
		$status: String
		$publicService: Boolean
		$spam: Boolean
		$showNearlyDue: Boolean
		$showIncomplete: Boolean
		$state: String
	) {
		getCompanySubmissionProposalsByCompanyId(
			companyId: $companyId
			pagerInput: { limit: $limit, offset: $offset }
			fromDate: $fromDate
			toDate: $toDate
			read: $read
			number: $number
			status: $status
			publicService: $publicService
			spam: $spam
			showNearlyDue: $showNearlyDue
			showIncomplete: $showIncomplete
			state: $state
		) {
			countUnreadCompanySubmissionProposals
			totalRows
			totalPages
			companySubmissionProposals {
				id
				no
				companyName
				subject
				createdAt
				read
				assignTo {
					read
					user {
						id
					}
				}
				approved
				approvedAt
				dueDate
				publicService
				spam
				company {
					id
					name
					nameKhmer
					logo
				}
				done
				doneAt
				submissionStatus
				startServiceDate
			}
		}
	}
`;

const Q_GET_ALL_PUBLIC_SERVICES = gql`
	query GetAllPublicServices {
		getAllPublicServices {
			id
			value
			title
			active
		}
	}
`;

let workingId = null;
let maxLimit = 300;
let minLimit = 10;
let offset = 0;
let fromDate = null;
let toDate = null;
let unread = null;
let number = null;
let status = null;
let publicService = null;
let spam = null;
let showNearlyDue = null;
let showIncomplete = null;
let state = null;

function Proposal({ companyId, onPrint, onDataChanged, ...props }) {
	const history = useHistory();
	const toast = useToast();
	const { t } = useTranslation();
	const { toDateString } = useMoment();
	const { currentLang } = useStore((state) => state.currentLang);
	const { currentUser } = useStore((state) => state.currentUser);
	const [isShowingMore, setShowingMore] = useState(false);
	const [isShowingFilterModal, setShowingFilterModal] = useState(false);
	const [isShowingProposalDetailModal, setShowingProposalDetailModal] = useState(false);
	const [excelData, setExcelData] = useState(null);
	const [loadProposalsByCompanyId, { loading: loadingProposalsByCompanyId, error: errorProposalsByCompanyId, data: dataProposalsByCompanyId }] =
		useLazyQuery(Q_GET_PROPOSALS_BY_COMPANY_ID);
	const [loadAllPublicServices, { loading: loadingAllPublicServices, error: errorAllPublicServices, data: dataAllPublicServices }] =
		useLazyQuery(Q_GET_ALL_PUBLIC_SERVICES);

	const refreshProposalsByCompanyIdCallback = useCallback(() => {
		offset = 0;
	}, [companyId]);

	const loadProposalsByCompanyIdCallback = useCallback(() => {
		let localStatus = null;
		let localPublicService = null;
		if (null === status) {
			localStatus = 'in progress';
		} else {
			if ('all' === status) {
				localStatus = null;
			} else {
				localStatus = status;
			}
		}
		if (null !== publicService) {
			switch (publicService) {
				case 'PUBLIC_SERVICE':
					localPublicService = true;
					break;
				case 'OTHER':
					localPublicService = false;
					break;
				case 'all':
					localPublicService = null;
					break;
				default:
					localPublicService = null;
					break;
			}
		}
		const variables = {
			companyId,
			limit: isShowingMore ? maxLimit : minLimit,
			offset,
			number: number,
			fromDate: dateFromString(fromDate),
			toDate: dateFromString(toDate),
			status: localStatus,
			publicService: localPublicService,
			read: unread ? false : null,
			spam: spam,
			showNearlyDue: showNearlyDue,
			showIncomplete: showIncomplete,
			state: state ? state : 'open',
		};
		loadProposalsByCompanyId({ variables });
	}, [companyId, isShowingMore]);

	const loadAllPublicServicesCallback = useCallback(() => {
		loadAllPublicServices();
	}, []);

	useEffect(() => {
		refreshProposalsByCompanyIdCallback();
	}, [refreshProposalsByCompanyIdCallback]);

	useEffect(() => {
		loadProposalsByCompanyIdCallback();
	}, [loadProposalsByCompanyIdCallback]);

	useEffect(() => {
		loadAllPublicServicesCallback();
	}, [loadAllPublicServicesCallback]);

	useEffect(() => {
		if (errorProposalsByCompanyId) {
			errorProposalsByCompanyId.graphQLErrors.map(({ message }, i) =>
				toast({
					title: t(message),
					status: 'error',
					isClosable: true,
					position: 'top-right',
				})
			);
		}
	}, [errorProposalsByCompanyId]);

	useEffect(() => {
		if (errorAllPublicServices) {
			errorAllPublicServices.graphQLErrors.map(({ message }, i) =>
				toast({
					title: t(message),
					status: 'error',
					isClosable: true,
					position: 'top-right',
				})
			);
		}
	}, [errorAllPublicServices]);

	useEffect(() => {
		if (dataProposalsByCompanyId) {
			onDataChanged(dataProposalsByCompanyId);
		}
	}, [dataProposalsByCompanyId]);

	function panelTabChange(value) {
		offset = 0;
		status = value;
		loadProposalsByCompanyIdCallback();
	}

	function panelTabs({ name, value }) {
		let active = status ? status : 'in progress';
		return (
			<Flex
				userSelect="none"
				minW="100px"
				onClick={() => panelTabChange(value)}
				cursor="pointer"
				mb={'-2px'}
				_hover={{
					boxShadow: `inset 0 -2px 0 0 ${value === active ? '#2980b9' : '#bdc3c7'}`,
				}}
				boxShadow={value === active ? 'inset 0 -2px 0 0 #2980b9' : ''}
				pt="16px"
				pb="16px"
				pl="12px"
				pr="12px"
				alignItems="center"
				justifyContent="center"
			>
				<Text fontWeight={value === active ? '700' : '500'}>{t(name)}</Text>
			</Flex>
		);
	}

	function getPublicServiceName(input) {
		if (input) {
			let data = dataAllPublicServices?.getAllPublicServices?.filter((element) => element.value === input);
			return data[0]?.title;
		}
		return null;
	}

	function getRowColor(item) {
		let read = false;
		if (currentUser?.role === 'admin') {
			read = item?.read;
		} else {
			item?.assignTo.forEach((assignee) => {
				if (assignee.user?.id === currentUser?.id) {
					read = assignee?.read;
				}
			});
		}
		return read ? 'white' : 'gray.100';
	}

	function getCompanyName(item) {
		let displayName = item?.companyName;
		if (item?.company?.id) {
			if (currentLang === 'kh') {
				displayName = item?.company?.nameKhmer;
			} else {
				displayName = item?.company?.name;
			}
		}
		return displayName;
	}

	function getCreatedDate(input, fallback, format, locale = null) {
		if (input) {
			return toDateString(input, format, locale);
		} else if (fallback) {
			return toDateString(fallback, format, locale);
		} else {
			return 'N/A';
		}
	}

	function getDueDate(input, format, locale = null) {
		if (input) {
			return toDateString(input, format, locale);
		} else {
			return 'N/A';
		}
	}

	function getType(item) {
		return (
			<>
				{item?.publicService ? <Badge colorScheme="green">{t('Public Service')}</Badge> : <Badge colorScheme="yellow">{t('Other')}</Badge>}
				{item.spam === true && (
					<Badge ml={2} colorScheme="red">
						{t('Spam')}
					</Badge>
				)}
			</>
		);
	}

	function getStatus({ done, doneDate }) {
		if (done) {
			return (
				<Tooltip label={toDateString(doneDate, c.longDateFormat)}>
					<Badge colorScheme="green">{t('Done')}</Badge>
				</Tooltip>
			);
		} else {
			return <Badge colorScheme="yellow">{t('In progress')}</Badge>;
		}
	}

	function getSubmissionStatus(item) {
		switch (item?.submissionStatus) {
			case '1':
				return getStatus({ done: item?.done, doneDate: item?.doneAt });
			case '2':
				return <Badge colorScheme="purple">{t('PENDING PAYMENT')}</Badge>;
			case '3':
				return <Badge colorScheme="red">{t('CANCEL')}</Badge>;
			default:
				return null;
		}
	}

	function onPageChange(value) {
		offset = value * (isShowingMore ? maxLimit : minLimit);
		loadProposalsByCompanyIdCallback();
	}

	function onShowingMore() {
		offset = 0;
		setShowingMore(!isShowingMore);
	}

	function onApplyFilter(e) {
		offset = 0;
		number = e.number;
		if (e.dateRange) {
			const range = JSON.parse(e.dateRange);
			if (range?.from && range?.to) {
				fromDate = JSON.stringify(range.from);
				toDate = JSON.stringify(range.to);
			} else {
				fromDate = null;
				toDate = null;
			}
		} else {
			fromDate = null;
			toDate = null;
		}
		status = e.status;
		unread = e.unread;
		spam = e.spam;
		publicService = e.publicService;
		showNearlyDue = e.showNearlyDue;
		showIncomplete = e.showIncomplete;
		state = e.state;
		loadProposalsByCompanyIdCallback();
	}

	function createExcelData() {
		const records = [];
		dataProposalsByCompanyId?.getCompanySubmissionProposalsByCompanyId?.companySubmissionProposals.forEach((element) => {
			records.push({
				number: element?.no,
				companyName: getCompanyName(element),
				subject: element?.subject,
				createdAt: getCreatedDate(element?.createdAt, element?.createdAt, c.excelDateTimeFormat, 'en-gb'),
				dueDate: getDueDate(element?.dueDate, c.excelDateOnlyFormat, 'en-gb'),
				type: element?.publicService ? 'Public Service' : 'Other',
				publicServiceName: getPublicServiceName(element?.publicService),
				status: false === element?.done ? 'In progress' : 'Done',
			});
		});
		setExcelData(records);
	}

	return (
		<>
			<Flex className="hidden-print" {...props}>
				<Box width="100%">
					<SectionHeader title={t('Proposal')} />
					<Center mt={4}>
						<Box
							w="100%"
							bg="white"
							borderRadius={c.borderRadius}
							border="1px solid #dbdbdb"
							boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
							pb={4}
						>
							<Flex
								h="48px"
								p={4}
								pt={0}
								pb={0}
								mb={4}
								bg="#FAFAFA"
								alignItems="center"
								justifyContent="space-between"
								borderBottom="1px solid #dbdbdb"
							>
								<Flex pb="2px">
									<Flex>
										{panelTabs({ name: 'In progress', value: 'in progress' })}
										{panelTabs({ name: 'Done', value: 'done' })}
										{panelTabs({ name: 'All', value: 'all' })}
									</Flex>
								</Flex>
								<Flex alignItems="center">
									{currentUser?.canViewListReportToCompanyProposal && (
										<Link to={`/company-submission-proposal-list-report`}>
											<Button
												mr="2"
												leftIcon={<FontAwesomeIcon icon={faChartLine} style={{ fontSize: 16 }} />}
												colorScheme="gray"
												variant="solid"
												size="sm"
												borderRadius={c.borderRadius}
												border="1px solid #bdc3c7"
											>
												{t('List Report')}
											</Button>
										</Link>
									)}
									<Button
										mr="2"
										onClick={() => createExcelData()}
										leftIcon={<FontAwesomeIcon icon={faFileExcel} style={{ fontSize: 16 }} />}
										colorScheme="gray"
										variant="solid"
										size="sm"
										borderRadius={c.borderRadius}
										border="1px solid #bdc3c7"
									>
										{t('Export')}
									</Button>
									<Button
										mr="2"
										onClick={onPrint}
										leftIcon={<FontAwesomeIcon icon={faPrint} style={{ fontSize: 16 }} />}
										colorScheme="gray"
										variant="solid"
										size="sm"
										borderRadius={c.borderRadius}
										border="1px solid #bdc3c7"
									>
										{t('Print')}
									</Button>
									<Button
										onClick={() => setShowingFilterModal(true)}
										leftIcon={<FontAwesomeIcon icon={faSlidersH} style={{ fontSize: 16 }} />}
										colorScheme={`${
											number || fromDate || toDate || publicService || unread || spam || showNearlyDue || showIncomplete
												? 'yellow'
												: 'gray'
										}`}
										variant="solid"
										size="sm"
										borderRadius={c.borderRadius}
										border="1px solid #bdc3c7}"
									>
										{t('Filter')}
									</Button>
								</Flex>
							</Flex>
							<Box p="16px">
								<Table variant="simple" className="table-company-proposal-list">
									<Tbody>
										<Tr className="td-as-th">
											<Td>{t('Number')}</Td>
											<Td>{t('Company/Institution Name')}</Td>
											<Td>{t('Subject')}</Td>
											<Td>{t('Proposal Created At')}</Td>
											<Td>{t('Due Date')}</Td>
											<Td>{t('Type')}</Td>
											<Td isNumeric>{t('Status')}</Td>
										</Tr>
										{loadingProposalsByCompanyId && !dataProposalsByCompanyId && <LoadingTable column={7} row={5} />}
										{dataProposalsByCompanyId?.getCompanySubmissionProposalsByCompanyId?.companySubmissionProposals.length <= 0 && (
											<Tr>
												<Td>
													<Text>{t('No Data')}</Text>
												</Td>
											</Tr>
										)}
										{dataProposalsByCompanyId?.getCompanySubmissionProposalsByCompanyId?.companySubmissionProposals?.map((item, index) => {
											return (
												<Tr cursor="pointer" _hover={{ bg: 'gray.200' }} key={`item-${index}`} bg={getRowColor(item)}>
													<Td
														_hover={{ bg: 'rgba(52, 152, 219,.1)' }}
														cursor="zoom-in"
														onClick={() => {
															workingId = item?.id;
															setShowingProposalDetailModal(true);
														}}
													>
														{item?.no}
													</Td>
													<Td onClick={() => history.push(`/company-submission-proposal/detail?id=${item.id}`)} fontWeight="500">
														<Text maxW="400px" noOfLines={4}>
															{getCompanyName(item)}
														</Text>
													</Td>
													<Td onClick={() => history.push(`/company-submission-proposal/detail?id=${item.id}`)} fontWeight="500">
														<Text maxW="400px" noOfLines={4}>
															{item.subject}
														</Text>
													</Td>
													<Td onClick={() => history.push(`/company-submission-proposal/detail?id=${item.id}`)}>
														{getCreatedDate(item?.startServiceDate, item?.createdAt, c.longDateFormat)}
													</Td>
													<Td onClick={() => history.push(`/company-submission-proposal/detail?id=${item.id}`)}>
														{getDueDate(item?.dueDate, c.dateTextOnlyFormat)}
													</Td>
													<Td onClick={() => history.push(`/company-submission-proposal/detail?id=${item.id}`)}>
														<Flex>{getType(item)}</Flex>
													</Td>
													<Td onClick={() => history.push(`/company-submission-proposal/detail?id=${item.id}`)} isNumeric>
														<Box>{getSubmissionStatus(item)}</Box>
													</Td>
												</Tr>
											);
										})}
									</Tbody>
									<Tfoot>
										<Tr>
											<Th>{t('Number')}</Th>
											<Th>{t('Company/Institution Name')}</Th>
											<Th>{t('Subject')}</Th>
											<Th>{t('Proposal Created At')}</Th>
											<Th>{t('Due Date')}</Th>
											<Th>{t('Type')}</Th>
											<Th isNumeric>{t('Status')}</Th>
										</Tr>
										<Tr>
											<Th />
											<Th />
											<Th />
											<Th />
											<Th />
											<Th />
											<Th isNumeric>
												{t('Total Rows')}: {dataProposalsByCompanyId?.getCompanySubmissionProposalsByCompanyId?.totalRows}
											</Th>
										</Tr>
									</Tfoot>
								</Table>
								<Flex justifyContent="flex-end" w="100%" mt="4">
									{dataProposalsByCompanyId?.getCompanySubmissionProposalsByCompanyId?.totalPages > 1 && (
										<ReactPaginate
											previousLabel={<ChevronLeftIcon fontSize="20px" />}
											nextLabel={<ChevronRightIcon fontSize="20px" />}
											breakLabel={'...'}
											breakClassName={'break-me'}
											pageCount={dataProposalsByCompanyId?.getCompanySubmissionProposalsByCompanyId?.totalPages}
											marginPagesDisplayed={2}
											pageRangeDisplayed={3}
											disableInitialCallback={true}
											onPageChange={({ selected }) => onPageChange(selected)}
											containerClassName={'pagination'}
											activeClassName={'active'}
											initialPage={offset / (isShowingMore ? maxLimit : minLimit)}
										/>
									)}
								</Flex>
								{dataProposalsByCompanyId?.getCompanySubmissionProposalsByCompanyId?.companySubmissionProposals.length > 0 && (
									<Flex justifyContent="flex-end" mt="6">
										<Text mr="2">{t('Show more rows')}</Text>
										<Checkbox isChecked={isShowingMore} onChange={onShowingMore}></Checkbox>
									</Flex>
								)}
							</Box>
						</Box>
					</Center>
				</Box>
			</Flex>
			{isShowingFilterModal && (
				<CompanyDetailProposalFilter
					onApply={onApplyFilter}
					onClose={() => setShowingFilterModal(false)}
					number={number}
					fromDate={fromDate}
					toDate={toDate}
					status={status}
					publicService={publicService}
					unread={unread}
					spam={spam}
					showNearlyDue={showNearlyDue}
					showIncomplete={showIncomplete}
					state={state}
				/>
			)}
			{isShowingProposalDetailModal && <ProposalDetailModal id={workingId} onCloseModal={() => setShowingProposalDetailModal(false)} />}
			{excelData && <ExcelExportModal title="Proposal" isOpen={true} onClose={() => setExcelData(null)} data={excelData} />}
			{dataProposalsByCompanyId && <ProposalPrint data={dataProposalsByCompanyId} />}
		</>
	);
}

export default Proposal;
