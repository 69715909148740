import { Badge } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

function IntermediaryStatus({ status = null }) {
	const { t } = useTranslation();

	return (
		<>
			{status === 'completed' && (
				<>
					<Badge variant="solid" colorScheme="green">
						{t('Completed')}
					</Badge>
				</>
			)}
			{status === 'reviewing_by_dg' && (
				<>
					<Badge variant="solid" colorScheme="teal">
						{t('Reviewing by Director General')}
					</Badge>
				</>
			)}
			{status === 'reviewing_by_department' && (
				<>
					<Badge variant="solid" colorScheme="blue">
						{t('Reviewing by Department')}
					</Badge>
				</>
			)}
			{status === 'reviewing_by_office' && (
				<>
					<Badge variant="solid" colorScheme="yellow">
						{t('Reviewing by Office')}
					</Badge>
				</>
			)}
			{status === 'waiting_for_contract' && (
				<>
					<Badge variant="solid" colorScheme="yellow">
						{t('Waiting for Partnership Agreement')}
					</Badge>
				</>
			)}

			{status === 'reviewing_contract_by_office' && (
				<>
					<Badge variant="solid" colorScheme="yellow">
						{t('Reviewing Partnership Agreement By Office')}
					</Badge>
				</>
			)}

			{status === 'reviewing_contract_by_department' && (
				<>
					<Badge variant="solid" colorScheme="yellow">
						{t('Reviewing Partnership Agreement By Department')}
					</Badge>
				</>
			)}

			{status === 'expired' && (
				<>
					<Badge variant="solid" colorScheme="gray">
						{t('Expired')}
					</Badge>
				</>
			)}
		</>
	);
}

export default IntermediaryStatus;
