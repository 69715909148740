/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import {
	Container,
	Box,
	Flex,
	Text,
	Center,
	useToast,
	Button,
	Image,
	AlertDialog,
	AlertDialogOverlay,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogCloseButton,
	AlertDialogBody,
	useDisclosure,
} from '@chakra-ui/react';
import BreadcrumbComponent from '../../components/BreadcrumbComponent/BreadcrumbComponent';
import { faPrint, faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import c from '../../constant';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import moment from 'moment';
import './style.css';
import { useLocation } from 'react-router-dom';
import useStore from '../../store';
import PrintLayout from '../../components/Print/PrintLayout';
import formatMoneyKH from '../../utils/formatMoneyKH';
import PlaceHolderLogo from '../../assets/images/logo-placeholder.png';
import DownloadAndViewButton from '../../components/Files/DownloadAndViewButton';
import { Form, Formik } from 'formik';
import TextAreaFormField from '../../components/form/TextAreaFormField';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import EditLicenseOrFineModal from '../../components/Modal/EditLicenseOrFineModal';

const leftLabelWidth = '160px';
const marginTopRow = 1;
const paddingTopRow = 1;
const paddingBottomRow = 1;

const QUERY_LICENSE_OR_FEE_DETAIL = gql`
	query getLicenseOrFineByID($number: String) {
		getLicenseOrFineByID(idNumber: $number) {
			id
			number
			createdAt
			amount
			currency
			subject
			type
			file
			fileSize
			company {
				id
				name
				nameKhmer
				logo
				url
				type
				latestUpdateBranchAt
			}
			companyName
			invoiceDate
		}
	}
`;

const DELETE_LICENSE_OR_FINE = gql`
	mutation updateLicenseOrFine($number: String, $deleted: Boolean, $description: String, $type: String) {
		updateLicenseOrFine(deleted: $deleted, number: $number, description: $description, type: $type)
	}
`;

function LicenseOrFineReportDetail() {
	const { t } = useTranslation();
	const history = useHistory();
	const location = useLocation();
	const urlParams = new URLSearchParams(location.search);
	const id = urlParams.get('id') ? urlParams.get('id') : null;
	const { currentLang } = useStore((state) => state.currentLang);
	const { currentUser } = useStore((state) => state.currentUser);
	moment.locale(currentLang === 'kh' ? 'km' : 'en-gb');
	const [loadLicenseDetail, { error: errorLicenseFineData, loading: loadingLicenseFineData, data: dataLicenseFine }] =
		useLazyQuery(QUERY_LICENSE_OR_FEE_DETAIL);
	const [deleteLicenseOrFine, { data }] = useMutation(DELETE_LICENSE_OR_FINE);
	const toast = useToast();

	useEffect(() => {
		loadLicenseDetail({
			variables: {
				number: id,
			},
		});
	}, [id]);

	useEffect(() => {
		if (errorLicenseFineData) {
			if (errorLicenseFineData?.graphQLErrors?.length > 0) {
				errorLicenseFineData?.graphQLErrors.map(({ message }) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(errorLicenseFineData?.message ? errorLicenseFineData?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [errorLicenseFineData]);

	function getDisplayCompanyName(company) {
		let displayName = '';
		if (company?.id) {
			if (currentLang === 'kh') {
				displayName = company?.nameKhmer;
			} else {
				displayName = company?.name;
			}
		}
		return displayName;
	}
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { isOpen: isOpenModal, onOpen: onOpenModal, onClose: onCloseModal } = useDisclosure();
	const cancelRef = React.useRef();

	async function submit(values) {
		let variables = {};
		variables = {
			deleted: true,
			number: values.id,
			description: values.description,
			type: values.type,
		};
		deleteLicenseOrFine({ variables });
	}

	useEffect(() => {
		if (data) {
			if (data.updateLicenseOrFine === 'success') {
				toast({
					title: t('Revenue Deleted'),
					description: t('The revenue has been deleted successfully'),
					status: 'success',
					duration: 5000,
					isClosable: true,
					position: 'bottom-right',
				});
				history.push(`/payment?page=${localStorage.getItem('paymentPage')}&pageType=${localStorage.getItem('pageType')}`);
			} else {
				toast({
					title: t('Forbidden'),
					description: t('Failed to delete the revenue'),
					status: 'error',
					duration: 5000,
					isClosable: true,
					position: 'bottom-right',
				});
				history.push(`/payment?page=${localStorage.getItem('paymentPage')}&pageType=${localStorage.getItem('pageType')}`);
			}
		}
	}, [data]);

	const onEditLicenseOrFineCallback = () => {
		const variables = { number: id };
		loadLicenseDetail({ variables });
	};

	const licenseOrFineSchema = Yup.object().shape({
		deleteDescription: Yup.string().required('required'),
	});

	return (
		<>
			<PrintLayout>
				<>
					<Container maxW="container.xl" mt="16px" mb="16px">
						<BreadcrumbComponent
							list={[
								{
									name: 'Revenue',
									path: `/payment?page=${localStorage.getItem('paymentPage')}&pageType=${localStorage.getItem('pageType')}`,
								},
								{
									name: 'Detail',
									path: '#',
								},
							]}
						/>
						<Center mt="5">
							<Box
								w="100%"
								bg="white"
								borderRadius={c.borderRadius}
								border="1px solid #dbdbdb"
								boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
								pb={2}
							>
								<Flex
									alignItems="center"
									justifyContent="space-between"
									bg="#FAFAFA"
									p="16px"
									pt="8px"
									pb="8px"
									mb="16px"
									borderBottom="1px solid #dbdbdb"
								>
									<Flex justifyContent="center" alignItems="center">
										<Text fontSize="x-large" fontWeight="bold" mr="4">
											{t('Detail')}
										</Text>
									</Flex>
									<Flex className="hidden-print">
										<Button
											onClick={() => window?.print()}
											leftIcon={<FontAwesomeIcon icon={faPrint} style={{ fontSize: 16 }} />}
											colorScheme="gray"
											variant="solid"
											size="sm"
											borderRadius={c.borderRadius}
											border="1px solid #bdc3c7"
										>
											{t('Print')}
										</Button>
										{((currentUser?.canUpdateLicense && dataLicenseFine?.getLicenseOrFineByID?.type?.toLowerCase() === 'license-fee') ||
											(currentUser?.canUpdateFine && dataLicenseFine?.getLicenseOrFineByID?.type?.toLowerCase() === 'fine') ||
											currentUser?.role === 'admin') && (
											<>
												<Button
													onClick={onOpenModal}
													leftIcon={<FontAwesomeIcon icon={faEdit} style={{ fontSize: 16 }} />}
													colorScheme="gray"
													variant="solid"
													size="sm"
													ml={2}
													borderRadius={c.borderRadius}
													border="1px solid #bdc3c7"
												>
													{t('Edit')}
												</Button>
												<Button
													onClick={onOpen}
													leftIcon={<FontAwesomeIcon icon={faTrash} style={{ fontSize: 16 }} />}
													colorScheme="gray"
													variant="solid"
													size="sm"
													ml={2}
													borderRadius={c.borderRadius}
													border="1px solid #bdc3c7"
												>
													{t('Delete')}
												</Button>
											</>
										)}
									</Flex>
								</Flex>
								{loadingLicenseFineData && <Text ml="8">{t('Loading')}</Text>}
								{!errorLicenseFineData && dataLicenseFine?.getLicenseOrFineByID?.id && (
									<Box ml={12} mr={12} mt={6} mb={6} className="leave-detail-container">
										<Flex mt={2} alignItems="center">
											<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
												{t('Number')}
											</Text>
											<Text ml="4" fontWeight="bold">
												{dataLicenseFine?.getLicenseOrFineByID?.number}
											</Text>
										</Flex>
										<Flex mt={2} alignItems="center">
											<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
												{t('Created At')}
											</Text>
											<Text ml="4">{moment(dataLicenseFine?.getLicenseOrFineByID?.createdAt).format('LLLL')}</Text>
										</Flex>
										<Flex mt={2} alignItems="center">
											<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
												{t('Transaction Date')}
											</Text>
											<Text ml="4">
												{dataLicenseFine?.getLicenseOrFineByID?.invoiceDate &&
													moment(dataLicenseFine?.getLicenseOrFineByID?.invoiceDate).format(c.dateTextOnlyFormat)}
											</Text>
										</Flex>
										<Flex mt={2} alignItems="center">
											<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
												{t('Amount')}
											</Text>
											<Text ml="4" textTransform={'uppercase'} fontWeight="bold">
												{formatMoneyKH(parseFloat(dataLicenseFine?.getLicenseOrFineByID?.amount))}{' '}
												{dataLicenseFine?.getLicenseOrFineByID?.currency}
											</Text>
										</Flex>
										<Flex mt={2} alignItems="center">
											<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
												{t('Subject')}
											</Text>
											<Text ml="4">{dataLicenseFine?.getLicenseOrFineByID?.subject}</Text>
										</Flex>
										<Flex mt={2} alignItems="center">
											<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
												{t('Type')}
											</Text>
											<Text ml="4">{t(dataLicenseFine?.getLicenseOrFineByID?.type)}</Text>
										</Flex>
										{dataLicenseFine?.getLicenseOrFineByID?.companyName && (
											<Flex mt={2} alignItems="center">
												<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
													{t('Company')}
												</Text>
												<Text ml="4">{dataLicenseFine?.getLicenseOrFineByID?.companyName}</Text>
											</Flex>
										)}
										{dataLicenseFine?.getLicenseOrFineByID?.company && (
											<Flex mt={2} alignItems="center">
												<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
													{t('Company')}
												</Text>
												<Flex ml="4" alignItems="center">
													{dataLicenseFine?.getLicenseOrFineByID?.company?.logo && (
														<>
															<Image
																w="auto"
																h="24px"
																maxW="194px"
																src={
																	dataLicenseFine?.getLicenseOrFineByID?.company?.logo
																		? c.baseFilePath +
																		  (dataLicenseFine?.getLicenseOrFineByID?.company?.logo
																				? dataLicenseFine?.getLicenseOrFineByID?.company?.logo
																				: null)
																		: ''
																}
																objectFit="contain"
																fallbackSrc={PlaceHolderLogo}
																mr="2"
															/>
														</>
													)}
													<Text noOfLines={1}>{getDisplayCompanyName(dataLicenseFine?.getLicenseOrFineByID?.company)}</Text>
												</Flex>
											</Flex>
										)}
										<Flex mt={2} alignItems="center">
											<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
												{t('File')}
											</Text>
											<DownloadAndViewButton
												url={dataLicenseFine?.getLicenseOrFineByID?.file}
												size={dataLicenseFine?.getLicenseOrFineByID?.fileSize}
											/>
										</Flex>
									</Box>
								)}
							</Box>
						</Center>
					</Container>
				</>
			</PrintLayout>
			<AlertDialog motionPreset="slideInBottom" leastDestructiveRef={cancelRef} onClose={onClose} isOpen={isOpen} isCentered>
				<AlertDialogOverlay />
				<AlertDialogContent>
					<AlertDialogHeader>{t('Delete Revenue')}?</AlertDialogHeader>
					<AlertDialogCloseButton />
					<AlertDialogBody>
						<Formik
							initialValues={{
								deleteDescription: '',
								id: dataLicenseFine?.getLicenseOrFineByID?.id,
								type: localStorage.getItem('pageType'),
							}}
							validationSchema={licenseOrFineSchema}
							onSubmit={(values) => {
								submit(values);
							}}
						>
							{(props) => (
								<>
									<Form>
										<TextAreaFormField {...props} name="deleteDescription" label="Description" placeholder={t('description')} required />
										<Flex justifyContent={'center'}>
											<Button
												ref={cancelRef}
												onClick={onClose}
												variant="solid"
												size="md"
												h="42px"
												w="128px"
												bg="gray.100"
												m="16px 8px"
												_hover={{ bg: 'gray.300' }}
												color="black"
											>
												{t('No')}
											</Button>
											<Button
												variant="solid"
												size="md"
												h="42px"
												w="128px"
												bg="red"
												m="16px 8px"
												_hover={{ bg: 'red.600' }}
												color="white"
												type="submit"
												spinnerPlacement="start"
											>
												{t(`Yes`)}
											</Button>
										</Flex>
									</Form>
								</>
							)}
						</Formik>
					</AlertDialogBody>
				</AlertDialogContent>
			</AlertDialog>
			{isOpenModal && dataLicenseFine && (
				<EditLicenseOrFineModal
					detail={dataLicenseFine?.getLicenseOrFineByID}
					isOpen={isOpenModal}
					callback={onEditLicenseOrFineCallback}
					onClose={onCloseModal}
				/>
			)}
		</>
	);
}

export default LicenseOrFineReportDetail;
