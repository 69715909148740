import QRCode from "easyqrcodejs";
import { useEffect, useRef } from "react";
import {
    Box
} from "@chakra-ui/react";
export default function QRCodeGenerator({size=100, text="", mainColor="#34495e", cornerColorInnerColor="#27ae60"}) {
  const qrCodeRef = useRef(null);
  const qrCode = useRef(null);
  const generate = (text) => {
    if (qrCode.current) {
      qrCode.current.makeCode(text);
    } else {
      qrCode.current = new QRCode(qrCodeRef.current, {
        text,
        width: size,
        height: size,
        correctLevel: QRCode.CorrectLevel.L,
        dotScale: 1,
        PI: cornerColorInnerColor,
        timing_H: mainColor,
        timing_V: mainColor, 
        colorDark: mainColor
      });
    }
  };

  useEffect(() => {
      generate(text);
  }, []);

  return (
      <Box p="5px" bg="white !important" borderRadius={"2px"} boxShadow="0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%)">
        <div ref={qrCodeRef} />
      </Box>
  );
}
