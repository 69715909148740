import React, { useEffect, useState } from "react";
import {
    Textarea, Text, Box, Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    IconButton,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Flex,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { AddIcon } from '@chakra-ui/icons'
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import TextFormField from "../form/TextFormField"
import RadioFormField from "../form/RadioFormField"
import PreUploadFileFormField from "../form/PreUploadFileFormField"
import TextAreaFormField from "../form/TextAreaFormField"
import DateFormField from "../form/DateFormField"
import EmployeeFormField from "../form/EmployeeFormField"
import arrayMove from 'array-move';
import { SortableContainer, SortableElement, sortableHandle } from 'react-sortable-hoc';
import { DragHandleIcon } from '@chakra-ui/icons'


import useStore from "../../store";
import c from "../../constant";
import { faSave, faPenSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { v4 as uuid } from 'uuid';
import _ from 'lodash';
import moment from "moment";
import DownloadAndViewButtonUserUpload from "../Files/DownloadAndViewButtonUserUpload"
const TableFormField = props => {
    const { t } = useTranslation('common');
    const { currentLang } = useStore((state) => state.currentLang);
    moment.locale(currentLang === "kh" ? "km" : "en-gb")
    const {
        name,
        label,
        labelKh,
        columns,
        value,
        orderBy,
        onSave
    } = props;
    const originalValue = value ? [...value] : []
    const [editRow, setEditRow] = useState(null)
    const [openEditRow, setOpenEditRow] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [tableData, setTableData] = useState(value ? value : [])


    function isJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    function getDisplayName(data) {
        let user = {}

        if (isJsonString(data)) {
            user = JSON.parse(data)
        } else {
            return data
        }
        let result = user?.username

        if (currentLang === "kh") {
            if (result = user?.fullNameKhmer) {
                result = user?.fullNameKhmer
            } else {
                if (user?.fullName) {
                    result = user?.fullName
                } else {
                    result = user.username
                }
            }
        } else {
            if (result = user?.fullName) {
                result = user?.fullName
            } else {
                if (user?.fullNameKhmer) {
                    result = user?.fullNameKhmer
                } else {
                    result = user.username
                }
            }
        }

        return result
    }

    function onAdd(e) {
        let tmpData = [...tableData]
        tmpData = [...tmpData, e]
        setTableData(tmpData)
    }

    function onUpdate(e) {
        let tmpData = [...tableData]
        let updateTmpData = []
        tmpData.map(item => {
            if (item?.id === e?.id) {
                updateTmpData.push(e)
            } else {
                updateTmpData.push(item)
            }
        })
        setTableData(updateTmpData)
    }

    function onDelete(e) {
        let tmpData = [...tableData]
        let updateTmpData = []
        tmpData.map(item => {
            if (item?.id === e?.id) {
            } else {
                updateTmpData.push(item)
            }
        })
        setTableData(updateTmpData)
        setOpenEditRow(false)
    }

    function displayRowData({ value, type }) {
        console.log("dp r:", { value, type })
        switch (type) {
            case "text":
                return getDisplayName(value)
            case "capitalText":
                return value?.toUpperCase()
            case "textArea":
                return <pre style={{
                    maxWidth: 400, whiteSpace: "pre-wrap"
                }}>{value}</pre>
            case "date":
                return value ? moment(value).format(c.excelDateOnlyTextFormat) : ""
            case "file": {
                const tmpValue = value ? JSON.parse(value) : null
                return tmpValue ? <DownloadAndViewButtonUserUpload url={tmpValue?.file} size={tmpValue?.fileSize} /> : ""
            }
            case "radio": {
                return t(value)
            }
            default:
                return "";
        }
    }

    const SortableItem = SortableElement((tmpItem, kIndex) => {
        const item = tmpItem?.value
        return (
            <Tr w="100%" key={`td-${kIndex}`} onClick={() => {
                if (isEditing) {
                    setOpenEditRow(true)
                    setEditRow(item)
                }
            }}
                cursor={isEditing ? "pointer" : "unset"}
                _hover={{
                    bg: "rgba(236, 240, 241,.3)"
                }}
            >
                <Td>
                    {isEditing && <DragHandle />}
                </Td>
                {columns.map((col, colIndex) => {
                    console.log("col:", col)
                    console.log("colIndex:", colIndex)
                    if (!col?.hide) {
                        return (<Td w="100%" key={`td-${kIndex}-${colIndex}`} >{
                            displayRowData({ value: item[col?.name], type: col?.type })
                        }</Td>
                        )
                    }
                })}
            </Tr>
        )
    });

    function onSortEnd({ oldIndex, newIndex }) {
        const newArray = arrayMove(tableData, oldIndex, newIndex)
        setTableData(newArray)
        const updateIndexList = []
        newArray.map((item, index) => {
            updateIndexList.push({ id: item.id, index })
        })
        console.log("updateIndexList:", updateIndexList)
    };

    const SortableList = SortableContainer(({ items }) => {
        return (
            <Box w="100%" m="4">
                {items.map((value, index) => (
                    <SortableItem key={`item-${value?.id}`} index={index} kIndex={index} value={value} />
                ))}
            </Box>
        );
    });


    return (
        <Flex direction="column" border={`3px dashed ${isEditing ? "#e74c3c" : "white"}`}>

            <Flex mt="4" mb="4" alignItems="center">
                {currentLang === "kh" ? labelKh : label}
                {isEditing && <Button
                    variant="outline"
                    size="sm"
                    ml="4"
                    borderRadius={"0px !important"}
                    onClick={() => {
                        setTableData(originalValue)
                        setIsEditing(false)
                    }
                    }
                    colorScheme='gray'
                >
                    {t(`Cancel`)}
                </Button>}
                {isEditing && <Button
                    id="login-btn"
                    // variant="outline"
                    size="sm"
                    ml="4"
                    borderRadius={"0px !important"}
                    leftIcon={
                        <FontAwesomeIcon icon={faSave} style={{ fontSize: 14 }} />
                    }
                    colorScheme='green'
                    // type="submit"
                    onClick={() => onSave(tableData)}
                    // isLoading={loading}
                    loadingText={t("Save")}
                    spinnerPlacement="start"
                >
                    {t(`Save`)}
                </Button>}
                {!isEditing && <Button
                    variant="outline"
                    size="sm"
                    ml="4"
                    borderRadius={"0px !important"}
                    leftIcon={<FontAwesomeIcon icon={faPenSquare} style={{ fontSize: 14 }} />}
                    onClick={() => setIsEditing(true)}
                    colorScheme='whatsapp'
                >
                    {t(`Edit`)}
                </Button>}

            </Flex>
            <TableContainer p="26px" pt="0">
                <Table variant='simple'>
                    {/* <Thead>
                        <Tr>
                            {columns.map((item, index) => {
                                if(!item?.hide){
                                return (
                                    <Th key={`th-${index}`}>{currentLang === "kh" ? item?.labelKh : item?.label}</Th>
                                )}
                            })}
                        </Tr>
                    </Thead> */}
                    <Tbody>
                        {/* {
                            (!tableData || tableData?.length <= 0) && <Tr
                            >
                                {columns.map((item, index) => {
                                    if(!item?.hide){
                                    return (
                                        <Td key={`td-null-${index}`}>N/A</Td>
                                    )}
                                })}
                            </Tr>
                        } */}
                        {((tableData?.length > 0)) && <SortableList useDragHandle items={tableData} onSortEnd={onSortEnd} />}

                        {/* {
                            _.orderBy(tableData,[`${orderBy?.field}`],[`${orderBy?.type}`])?.map((item, index) => {
                                return (
                                    <Tr key={`td-${index}`} onClick={() => {
                                        if (isEditing) {
                                            setOpenEditRow(true)
                                            setEditRow(item)
                                        }
                                    }}
                                        cursor={isEditing ? "pointer" : "unset"}
                                        _hover={{
                                            bg: "rgba(236, 240, 241,.3)"
                                        }}
                                    >
                                        {columns.map((col, colIndex) => {
                                            if(!col?.hide){
                                                return (
                                                    <Td key={`td-${index}-${colIndex}`}>{
                                                        displayRowData({value:item[col?.name],type:col?.type})
                                                        // (item[col?.name]).toString()
                                                    }</Td>
                                                )
                                            }
                                        })}
                                    </Tr>
                                )
                            })
                        } */}
                    </Tbody>
                </Table>
            </TableContainer>
            {isEditing && <RowForm {...props} onAdd={onAdd} type="add" />}

            {isEditing && openEditRow && <RowForm {...props} onUpdate={onUpdate} initData={editRow} type="edit" onCloseCallBack={() => {
                setOpenEditRow(false)
                setEditRow(null)
            }} onDelete={onDelete} />}
        </Flex>
    );
};

export default TableFormField;


const RowForm = props => {
    const { t } = useTranslation('common');
    const {
        name,
        label,
        columns,
        value,
        onAdd,
        type,
        onCloseCallBack = () => null,
        initData, onUpdate,
        onDelete,
    } = props;

    const [schem, setSchem] = useState(null)
    const [initValue, setInitValue] = useState(null)
    const [isOpen, setIsOpen] = useState(type === "add" ? false : true)
    const { currentLang } = useStore((state) => state.currentLang);
    const [isEmployeePicker, setIsEmployeePicker] = useState(false)

    const [uuidRow, setUuidRow] = useState(uuid())

    useEffect(() => {
        if (isOpen) {
            setIsEmployeePicker(false)
        }
    }, [isOpen])

    useEffect(() => {
        if (columns) {

            let tmpSchem = {}
            let tmpInitValue = {}
            columns?.map((item, index) => {
                if (item?.type === "text" || item?.type === "capitalText") {
                    tmpSchem = { ...tmpSchem, [item?.name]: Yup.string() }
                    tmpInitValue = { ...tmpInitValue, [item?.name]: initData ? initData[item?.name] : (item?.name === "id" ? uuidRow : "") }
                } else if (item?.type === "file") {
                    tmpSchem = { ...tmpSchem, [item?.name]: Yup.string() }
                    tmpInitValue = { ...tmpInitValue, [item?.name]: initData ? initData[item?.name] : (item?.name === "id" ? uuidRow : "") }
                } else if (item?.type === "textArea") {
                    tmpSchem = { ...tmpSchem, [item?.name]: Yup.string() }
                    tmpInitValue = { ...tmpInitValue, [item?.name]: initData ? initData[item?.name] : "" }
                } else if (item?.type === "date") {
                    tmpSchem = { ...tmpSchem, [item?.name]: Yup.string() }
                    tmpInitValue = { ...tmpInitValue, [item?.name]: initData ? initData[item?.name] : "" }
                } else if (item?.type === "radio") {
                    tmpSchem = { ...tmpSchem, [item?.name]: Yup.string() }
                    tmpInitValue = { ...tmpInitValue, [item?.name]: initData ? initData[item?.name] : "" }
                    if (initData && initData["type"] === "employee") {
                        setIsEmployeePicker(true)
                    } else {
                        setIsEmployeePicker(false)
                    }
                } else {
                    tmpSchem = { ...tmpSchem, [item?.name]: Yup.string() }
                    tmpInitValue = { ...tmpInitValue, [item?.name]: initData ? initData[item?.name] : "" }
                }
            })
            setSchem(Yup.object().shape({
                ...tmpSchem
            }))
            setInitValue(tmpInitValue)
        }
    }, [columns, uuidRow])

    return (
        <>
            {type === "add" && <Button mb="26px" ml="26px" mr="26px" colorScheme='green' borderRadius="0px !important" onClick={() => setIsOpen(true)}>{t("Add")}</Button>}
            <Modal isOpen={isOpen} onClose={() => {
                setIsOpen(false)
                onCloseCallBack()
            }}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{t("Add")}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody mb="4">
                        {(schem && initValue) && <Formik
                            key="inputFormTable"
                            initialValues={{ ...initValue }}
                            validationSchema={
                                schem
                            }
                            onSubmit={(values) => {
                                if (type === "add") {
                                    setUuidRow(uuid())
                                    onAdd(values);
                                    setIsOpen(false)
                                } else {
                                    onUpdate(values)
                                    onCloseCallBack()
                                }
                            }}
                        >
                            {(props) => {
                                return (
                                    <Box>
                                        <Form key="formKey">
                                            {columns.map((col, colIndex) => {
                                                return (
                                                    <Box key={`td-add-${colIndex}`} display={col?.hide ? "none" : "unset"}>
                                                        {(col.type === "text" || col.type === "capitalText") && <>
                                                            {isEmployeePicker ? <>
                                                                <EmployeeFormField
                                                                    {...props}
                                                                    label={"Employee"}
                                                                    name={col?.name}
                                                                    initialData={initValue[col?.name] || null}
                                                                />
                                                            </> : <TextFormField
                                                                {...props}
                                                                label={currentLang === "kh" ? col?.labelKh : col?.label}
                                                                name={col?.name}
                                                            />}
                                                        </>}
                                                        {col.type === "file" && <PreUploadFileFormField
                                                            {...props}
                                                            label={currentLang === "kh" ? col?.labelKh : col?.label}
                                                            name={col?.name}
                                                        />}
                                                        {col.type === "textArea" && <TextAreaFormField
                                                            {...props}
                                                            label={currentLang === "kh" ? col?.labelKh : col?.label}
                                                            name={col?.name}
                                                        />}
                                                        {col.type === "date" && <DateFormField
                                                            {...props}
                                                            label={currentLang === "kh" ? col?.labelKh : col?.label}
                                                            name={col?.name}
                                                            initialDate={initValue[col?.name] || null}
                                                        />}

                                                        {(col.type === "radio"&&col?.name!=="title") && <RadioFormField
                                                            {...props}
                                                            label={col?.label}
                                                            name={col?.name}
                                                            radios={col?.options}
                                                            initValue={initValue[col?.name] || ""}
                                                            callbackValueChange={e => {
                                                                if (e === "employee") {
                                                                    setIsEmployeePicker(true)
                                                                } else {
                                                                    setIsEmployeePicker(false)
                                                                }
                                                            }}
                                                        />}
                                                        {(col.type === "radio"&&col?.name==="title"&&isEmployeePicker) && <RadioFormField
                                                            {...props}
                                                            label={col?.label}
                                                            name={col?.name}
                                                            radios={col?.options}
                                                            initValue={initValue[col?.name] || ""}
                                                            
                                                        />}
                                                        
                                                    </Box>
                                                )
                                            })}
                                            <Flex justifyContent="flex-end">
                                                {type === "edit" && <Button
                                                    variant="ghost"
                                                    colorScheme='red'
                                                    size="md"
                                                    h="42px"
                                                    w="128px"
                                                    mr="4"
                                                    onClick={() => onDelete(initData)}
                                                >
                                                    {t(`Delete`)}
                                                </Button>}
                                                <Button
                                                    id="login-btn"
                                                    variant="solid"
                                                    size="md"
                                                    h="42px"
                                                    w="128px"
                                                    bg="primary.dark"
                                                    _hover={{ bg: "primary.dark" }}
                                                    color="white"
                                                    // borderRadius="31px"
                                                    type="submit"
                                                    // isLoading={loading}
                                                    loadingText={t("CREATE")}
                                                    spinnerPlacement="start"
                                                >
                                                    {t(type === "add" ? `Add` : `Update`)}
                                                </Button>
                                            </Flex>
                                        </Form>
                                    </Box>
                                )
                            }}
                        </Formik>}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

const DragHandle = sortableHandle(() => <Flex minH="32px" justifyContent="center" alignItems="center" cursor="move" mr="4"><DragHandleIcon /></Flex>);
