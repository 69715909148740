import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
// import checkLogin from "../../utils/checkLogin";
import {
  Box
} from "@chakra-ui/react";
import { checkLogin } from "../utils"

function Layout(props) {
//   const isLogin = checkLogin();
  const isLogin = checkLogin()
  const history = useHistory();

  useEffect(() => {
    if (!isLogin) {
      history.push("/login");
    }
  }, []);

  return (
    <>
      <Header />
      <Box minH="calc(100vh - 80px)">
        {props?.children ? props?.children : null}
      </Box>
      <Footer />
    </>
  );
}

export default Layout;
