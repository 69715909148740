import React from "react";
import c from "../../constant"
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Button,
    Box,
    Flex
} from "@chakra-ui/react"
import { Formik, Form } from "formik";
import * as Yup from "yup";
import TextFormField from "../form/TextFormField";
import DateRangeFormField from "../form/DateRangeFormField";
import moment from "moment";
import { useTranslation } from "react-i18next";
import RadioFormField from "../form/RadioFormField";
import 'moment/locale/en-gb';

const filterSchema = Yup.object().shape({
    number: Yup.string(),
    subject: Yup.string(),
    department: Yup.string(),
    companyName: Yup.string(),
    date: Yup.string(),
});

function DocumentNumberFilter({ isOpen, onClose, callback , 
    number="",
    subject="",
    department="",
    companyName="",
    fromDate=null,
    toDate=null,
}) {
    moment.locale("en-gb")
    const { t } = useTranslation();
    let defaultRange = ""
	if(fromDate&&toDate){
        const defaultFrom = JSON.parse(fromDate);

		const defaultTo = JSON.parse(toDate);
		defaultRange = {
			from: defaultFrom,
			to: defaultTo
		}
    }

    return (
        <>
            <Modal borderRadius={c.borderRadius} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent borderRadius={c.borderRadius}>
                    <ModalHeader>{t("Filter")}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Formik
                            initialValues={{
                                number:number||"",
                                subject:subject||"",
                                department:department||"",
                                companyName:companyName||"",
                                date: defaultRange?JSON.stringify(defaultRange):"",
                            }}
                            validationSchema={filterSchema}
                            onSubmit={(values) => {
                                let url = "?page=1"
                                if(values.number){
                                    url+=`&number=${values.number}`
                                }
                                if(values.subject){
                                    url+=`&subject=${values.subject}`
                                }
                                if(values.department){
                                    url+=`&department=${values.department}`
                                }
                                if(values.companyName){
                                    url+=`&companyName=${values.companyName}`
                                }
                                if(values.date){
                                    const tmpDate = JSON.parse(values.date)
                                    if(tmpDate?.from&&tmpDate?.to){
                                        const dateFrom = JSON.stringify(tmpDate.from)
                                        const dateTo = JSON.stringify(tmpDate.to)
                                        url+=`&fromDate=${dateFrom}&toDate=${dateTo}`
                                    }
                                }
                                callback(url)
                                onClose()
                            }}
                        >
                            {(props) => (
                                <Box pb="4">
                                    <Form>

                                        <TextFormField
                                            {...props}
                                            label="Number"
                                            name="number"
                                        />
                                        <TextFormField
                                            {...props}
                                            label="Subject"
                                            name="subject"
                                        />
                                        <TextFormField
                                            {...props}
                                            label="Company/Institution Name"
                                            name="companyName"
                                        />

                                        <RadioFormField
                                            label="Department"
                                            name="department"
                                            radios={c.departments}
                                            initValue={department}
                                        />
                                        <center>
                                        <DateRangeFormField
                                            {...props}
                                            label="Date"
                                            name="date"
                                            initialDate={{fromDate,toDate}}
                                        />
                                        </center>
                                        
                                        <Flex mt="32px" justifyContent="flex-end">
                                            <Button
                                                id="login-btn"
                                                variant="solid"
                                                size="md"
                                                h="42px"
                                                w="128px"
                                                type="submit"
                                                borderRadius={c.borderRadius}
                                                color="#3498db"
                                            >
                                                {t("Apply")}
                                            </Button>
                                            <Button
                                                size="md"
                                                h="42px"
                                                ml={4}
                                                w="128px" onClick={() => {
                                                    callback('?page=1')
                                                    onClose()
                                                }} variant="ghost">{t("Clear")}</Button>
                                        </Flex>
                                    </Form>

                                </Box>
                            )}
                        </Formik>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export default DocumentNumberFilter