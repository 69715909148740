import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Container, Center, Box, Flex, Text, Button, useToast, RadioGroup, Stack, Radio, Image,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    useDisclosure
 } from "@chakra-ui/react";
import BreadcrumbComponent from "../../components/BreadcrumbComponent/BreadcrumbComponent";
import { faNewspaper } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import c from "../../constant"
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import useStore from "../../store";
import getRoleLabel from "../../utils/getRoleLabel"
import ImagePickerAvatar from "../../components/ImagePicker/ImagePickerAvatar"
import CamdigiKeyLogo from "../../assets/images/camdigikey-no-bg.png";

const QUERY_CURRENT_USER = gql`
query{
  getCurrentuser{
    id
    username
    role
    fullName
    fullNameKhmer
    canAssignUserToCompanyProposal
    avatar
    camDigiKeyId
  }
}
`


const CHANGE_AVATAR_MUTATION = gql`
mutation change($avatar: JSON!){
    changeAvatar(
        avatar:$avatar
    )
}
`

const MUTATION_UNLINK_CAMDIGIKEY = gql`
mutation {
    unlinkCamDigiKey
}
`

function Account() {
    const toast = useToast()
    const { t } = useTranslation();
    const [loadCurrentUser, { error, loading, data }] = useLazyQuery(QUERY_CURRENT_USER)
    const setCurrentUser = useStore((state) => state.setCurrentUser);

    const [changeAvatarMutation, { loading:loadingChangeAvatar, error:errorChangeAvatar, data:dataChangeAvatar }] = useMutation(
        CHANGE_AVATAR_MUTATION
    );

    const [unlinkCamDigiKeyMutation, { loading:loadingUnlink, error:errorUnlink, data:dataUnlink }] = useMutation(
        MUTATION_UNLINK_CAMDIGIKEY
    );

    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = React.useRef()
  


    useEffect(()=>{
        if(dataChangeAvatar?.changeAvatar){
            toast({
                title: t("Avatar changed successfully"),
                status: "success",
                isClosable: true,
                position: "top-right"
            })
            loadCurrentUser()
        }
    },[dataChangeAvatar])

    useEffect(()=>{
        if(errorChangeAvatar){
            errorChangeAvatar.graphQLErrors.map(({ message }, i) => (
                toast({
                    title: message,
                    status: "error",
                    isClosable: true,
                    position: "top-right"
                })
            ))
        }
    },[errorChangeAvatar])

    useEffect(() => {
        window.scrollTo(0, 0);
        loadCurrentUser()
    }, []);

    useEffect(() => {
        if (data) {
            setCurrentUser(data?.getCurrentuser);
        }
    }, [data]);

    function row(label, value) {
        return (
            <Flex p="2" _hover={{ bg: "gray.200" }}>
                <Text mr="2" fontWeight="500" minW="160px" w="160px">{t(label)}:</Text>
                <Box>
                    {value?.file ? <Box>
                        <a href={value?.file} download>
                            <Text color="blue.300">Download ({`
                        ${parseFloat(value?.fileSize / 1048576).toFixed(2)}
                        `} MB)</Text>
                        </a>
                    </Box> :
                        <>

                            {
                                // label === "Complaint ID"?
                                // <Text color="blue.300" cursor="pointer" onClick={()=>history.push(`/complaint-on-insurance/detail?id=${value}`)}>{value?value:""}</Text>:
                                <Text>{value ? value : ""}</Text>

                            }
                        </>
                    }
                </Box>

            </Flex>
        )
    }

    function getRolesText(roles){
        let result = ""
        if(roles){
            const tmpRoles = roles.split(",")
            tmpRoles.map((r, index)=>{
                if(index===0){
                    result =(t(getRoleLabel(r)))
                } else {
                    result +=`, ${(t(getRoleLabel(r)))}`
                }
            })
             
        }
        return result
    }

    let defaultFont = 'Hanuman'
	let setFont = localStorage.getItem("font")
	if(setFont){
		defaultFont = setFont
	}

    const [loadingCamdigikey, setLoadingCamdigikey] = useState(false)
    function linkWithCamDigiKey(){
        const accessToken = sessionStorage.getItem("accessToken")
        setLoadingCamdigikey(true)
        localStorage.setItem("adminAccessToken", accessToken)
        sessionStorage.clear()
        window.location.href = c.adminCamDigiKeyRedirectUrlLink;
    }

    function unlinkWithCamDigiKey(){
        unlinkCamDigiKeyMutation()
        
    }

    useEffect(()=>{
        if(dataUnlink){
            toast({
                title: t("CamDigiKey unlinked successfully"),
                status: "success",
                isClosable: true,
                position: "top-right"
            })
            onClose()
            window.scrollTo(0, 0);
            loadCurrentUser()
        }
    },[dataUnlink])

    return (
        <>
            <Container maxW="container.xl" mt="16px" mb="16px">
                <BreadcrumbComponent
                    list={[
                        {
                            name: t("Account"),
                            path: "#",
                        },
                    ]}
                />
                <Center mt="5">
                    <Box
                        w="100%"
                        bg="white"
                        borderRadius={c.borderRadius}
                        border="1px solid #dbdbdb"
                        boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
                        pb="32px"
                    >
                        <Flex bg="#FAFAFA" p="16px" pt="8px" pb="8px" mb="16px" justifyContent="space-between" borderBottom="1px solid #dbdbdb">
                            <Text
                                fontSize="x-large"
                                fontWeight="bold"
                            >
                                {t("Account")}
                            </Text>
                        </Flex>

                        <Box p="16px" maxW="400px">
                            <Box w="300px" overflow="hidden">
                                <ImagePickerAvatar
                                    label={t("Avatar") }
                                    onContentChange={e=>{
                                        changeAvatarMutation({
                                            variables:{
                                                avatar: (e?.icon&&e?.medium)?e:null
                                            }
                                        })
                                    }}
                                    initContent={data?.getCurrentuser?.avatar}
                                />
                                {loadingChangeAvatar&&<Text>Saving...</Text>}
                            </Box>
                            {row("Username", data?.getCurrentuser?.username)}
                            {row("Full Name In Latin", data?.getCurrentuser?.fullName)}
                            {row("Full Name In Khmer", data?.getCurrentuser?.fullNameKhmer)}
                            {/* {row("Role", getRolesText(data?.getCurrentuser?.role))} */}
                            <Link to="/change-password">
                                <Button mt="4" w="100%" borderRadius={c.borderRadius}>
                                    <Text color="blue.300">{t("Change Password")}</Text>
                                </Button>
                            </Link>

                            {(data?.getCurrentuser?.camDigiKeyId)&&<Button
                                mt="4"
                                variant="solid"
                                size="md"
                                h="42px"
                                w="100%"
                                colorScheme='red'
                                isLoading={false}
                                loadingText={t("Loading")}
                                spinnerPlacement="start"
                                onClick={onOpen}
                                leftIcon={<Image  src={CamdigiKeyLogo} w="20px" h="20px"/>} 
                            >
                                {t(`Unlink with CamDigiKey`)}
                            </Button>}

                            {!(data?.getCurrentuser?.camDigiKeyId)&&<Button
                                mt="4"
                                variant="outline"
                                size="md"
                                h="42px"
                                w="100%"
                                colorScheme='blue'
                                isLoading={loadingCamdigikey}
                                loadingText={t("Loading")}
                                spinnerPlacement="start"
                                onClick={()=>linkWithCamDigiKey()}
                                leftIcon={<Image  src={CamdigiKeyLogo} w="20px" h="20px"/>} 
                            >
                                {t(`Link with CamDigiKey`)}
                            </Button>}

                            <RadioGroup defaultValue={defaultFont} mt="4" onChange={e=>{
                                localStorage.setItem("font", e)
                                window.location.reload();
                            }}>
                            <Stack spacing={5} direction='row'>
                            <Text>{t("Font")}:</Text>

                                <Radio value='Hanuman'>
                                Hanuman
                                </Radio>
                                <Radio value='MEF1'>
                                MEF1
                                </Radio>
                                <Radio value='Nokora'>
                                Nokora
                                </Radio>
                            </Stack>
                            </RadioGroup>
                        </Box>
                    </Box>
                </Center>
            </Container>


            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                    {t("Unlink CamDigiKey")}
                    </AlertDialogHeader>

                    <AlertDialogBody>
                    {t("Are you sure?")}
                    </AlertDialogBody>

                    <AlertDialogFooter>
                    <Button ref={cancelRef} onClick={onClose}>
                        {t("Cancel")}
                    </Button>
                    <Button isLoading={loadingUnlink} colorScheme='red' onClick={()=>{
                        unlinkWithCamDigiKey()
                        }} ml={3}>
                        {t("Unlink")}
                    </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    );
}

export default Account;