/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {
	Flex,
	Tooltip,
	Text,
	Box,
	ListItem,
	Image,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	useDisclosure,
	List,
	SimpleGrid,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import LogoPlaceholder from '../../../assets/images/logo-placeholder.png';
import ProcessOfficeCard from './ProcessOfficeCard';
import ProcessDepartmentCard from './ProcessDepartmentCard';
import ProcessDirectorGeneralCard from './ProcessDirectorGeneralCard';
import InvoiceApproved from './Invoice/InvoiceApproved';
import InvoiceRejected from './Invoice/InvoiceRejected';
import InvoiceValidation from './Invoice/InvoiceValidation';
import InvoiceInprogress from './Invoice/InvoiceInprogress';
import InvoiceProgressRegenerateNote from './Invoice/InvoiceProgressRegenerateNote';
import InvoiceApprovedRegenerateLicense from './Invoice/InvoiceApprovedRegenerateLicense';
import moment from 'moment';
import c from '../../../constant';
import './style.css';
import 'moment/locale/km';
require('@thyrith/momentkh')(moment);

export default function RequestAuditLicense({
	proposalId = null,
	logo = null,
	name = null,
	nameKhmer = null,
	address = null,
	status = null,
	licenseFile = null,
	user = null,
	process = null,
	invoice = null,
	callback = () => null,
}) {
	const { t } = useTranslation();
	const { isOpen, onOpen, onClose } = useDisclosure();

	const ProcessBoard = ({ office = null, department = null, directorGeneral = null }) => {
		return (
			<SimpleGrid columns={[2, null, 3]} spacing={8} mt={6} pt={2} pb={6} borderTop="2px dotted #718096" borderBottom="2px dotted #718096">
				<ProcessOfficeCard proposalId={proposalId} office={office} callback={callback} process={process} />
				<ProcessDepartmentCard proposalId={proposalId} office={office} department={department} callback={callback} process={process} />
				<ProcessDirectorGeneralCard
					type="request_audit_service_license"
					proposalId={proposalId}
					office={office}
					department={department}
					directorGeneral={directorGeneral}
					callback={callback}
					process={process}
				/>
			</SimpleGrid>
		);
	};

	const RejectionReasonForCompany = ({ process }) => {
		let messageReason = '';
		if (process?.office?.status === 'rejected') {
			messageReason = process?.office?.rejectionReasonForCompany;
		}

		if (process?.department?.status === 'rejected') {
			messageReason = process?.department?.rejectionReasonForCompany;
		}

		if (process?.directorGeneral?.status === 'rejected') {
			messageReason = process?.directorGeneral?.rejectionReasonForCompany;
		}

		return (
			<Flex mt="4">
				<Box _hover={{ bg: '#ecf0f1' }} w="100%">
					<Text mb="4" fontSize="sm" fontWeight="600">
						{t('Rejection reason for company')}:
					</Text>
					<pre className="text-area-viewer" style={{ color: '#d35400' }}>
						{messageReason}
					</pre>
				</Box>
			</Flex>
		);
	};

	const InvoiceBoard = ({ invoice = null }) => {
		if (invoice) {
			if (invoice?.status === c.waiting) {
				return <InvoiceValidation proposalId={proposalId} invoice={invoice} type="request_audit_service_license" callback={callback} />;
			} else if (invoice?.status === c.rejected) {
				return <InvoiceRejected invoice={invoice} />;
			} else if (invoice?.status === c.approved) {
				return (
					<>
						<InvoiceApproved label="Payment invoice of registration fee" invoice={invoice} licenseFile={licenseFile} />
						<InvoiceApprovedRegenerateLicense id={proposalId} callback={callback} />
					</>
				);
			}
			return (
				<>
					<InvoiceInprogress label="Waiting for payment of registration fee" />
					<InvoiceProgressRegenerateNote id={proposalId} callback={callback} />
				</>
			);
		}
		return null;
	};

	return (
		<>
			<Box w="100%" bg="white" borderRadius={c.borderRadius} border="1px solid #dbdbdb" boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)">
				<Flex alignItems="center" pl={4} pr={4} pt={3} pb={3} bg="white" borderBottom="1px solid #dbdbdb">
					<Text fontSize="lg" fontWeight={600}>
						{t('Request for audit service license')}
					</Text>
				</Flex>
				<Box bg="#FBFAFD" w="100%" pl={8} pr={8} pt={4} pb={8}>
					<Box mb={2}>
						<Text fontSize="lg" fontWeight={600}>
							{t('Company Information')}
						</Text>
					</Box>
					<List color="#68686D">
						<ListItem mt={1}>
							<Box>
								<Box pl={2}>
									<Flex alignItems="center">
										<Flex alignItems="center">
											<FontAwesomeIcon icon={faCircle} style={{ fontSize: 5, color: '#4A5568', marginRight: 8 }} />
											<Text color="gray.700" mr={1}>
												{t('Logo')}:
											</Text>
										</Flex>
										<Box bg="#ecf0f1" borderWidth={1} borderColor="gray.200" borderRadius={c.borderRadius} p={1 / 2}>
											<Tooltip label={t('Click to view')}>
												<Image
													src={c.baseFilePath + logo}
													w="auto"
													minW="20px"
													minH="20px"
													h="20px"
													fallbackSrc={LogoPlaceholder}
													cursor="pointer"
													onClick={onOpen}
												/>
											</Tooltip>
										</Box>
									</Flex>
									<Flex alignItems="center">
										<Flex alignItems="center">
											<FontAwesomeIcon icon={faCircle} style={{ fontSize: 5, color: '#4A5568', marginRight: 8 }} />
											<Text color="gray.700" mr={1}>
												{t('Khmer Name')}:
											</Text>
										</Flex>
										<Text color="#3498DA" fontWeight="bold">
											{nameKhmer}
										</Text>
									</Flex>
									<Flex alignItems="center">
										<Flex alignItems="center">
											<FontAwesomeIcon icon={faCircle} style={{ fontSize: 5, color: '#4A5568', marginRight: 8 }} />
											<Text color="gray.700" mr={1}>
												{t('Latin Name')}:
											</Text>
										</Flex>
										<Text color="#3498DA" fontWeight="bold">
											{name}
										</Text>
									</Flex>
									<Flex alignItems="center">
										<Flex alignItems="center">
											<FontAwesomeIcon icon={faCircle} style={{ fontSize: 5, color: '#4A5568', marginRight: 8 }} />
											<Text color="gray.700" mr={1}>
												{t('Address')}:
											</Text>
										</Flex>
										<Text color="#3498DA" fontWeight="bold">
											{address}
										</Text>
									</Flex>
								</Box>
							</Box>
						</ListItem>
					</List>
					<ProcessBoard office={process?.office} department={process?.department} directorGeneral={process?.directorGeneral} />
					<RejectionReasonForCompany process={process} />
					<InvoiceBoard invoice={invoice} />
				</Box>
			</Box>
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent borderRadius={c.borderRadius} pt="2">
					<ModalHeader>{t('Logo')}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Flex justifyContent="center" pt={8} pb={12}>
							<Image src={c.baseFilePath + logo} fallbackSrc={LogoPlaceholder} />
						</Flex>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
}
