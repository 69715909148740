import React, {useEffect} from "react";
import {
    Badge,
} from "@chakra-ui/react";
import { gql, useLazyQuery } from '@apollo/client';
const QUERY_COUNT_UNREAD_COMPLAINTS = gql`
query{
  getCountUnreadComplaints
}
`
const QUERY_COUNT_UNREAD_COMPLAINTS_ON_INSURANCE = gql`
query{
  getCountUnreadComplaintsOnInsurance
}
`
function ComplaintBadge() {
  const [loadCountUnreadComplaints, { data: countComplaints }] = useLazyQuery(QUERY_COUNT_UNREAD_COMPLAINTS)
  const [loadCountUnreadComplaintsOnInsurance, { data: countComplaintsOnInsurance }] = useLazyQuery(QUERY_COUNT_UNREAD_COMPLAINTS_ON_INSURANCE)
  useEffect(() => {
    loadCountUnreadComplaints()
    loadCountUnreadComplaintsOnInsurance()
  }, [])
  return (
    (parseInt(countComplaints?.getCountUnreadComplaints) + parseInt(countComplaintsOnInsurance?.getCountUnreadComplaintsOnInsurance))>0?<Badge
      fontSize="x-large"
      zIndex="99"
      top="2px" right="2px"
      position="absolute"
      colorScheme="red"
    >      
      {parseInt(countComplaints?.getCountUnreadComplaints) + parseInt(countComplaintsOnInsurance?.getCountUnreadComplaintsOnInsurance)}
    </Badge>:null
  );
}

export default ComplaintBadge;
