import React, { useEffect, useState } from 'react';
import {
	Box,
	Flex,
	Text,
	Table,
	Thead,
	Tbody,
	Tfoot,
	Tr,
	Th,
	Td,
	Input,
	InputRightElement,
	InputGroup,
	Button,
	useToast,
	WrapItem,
	Wrap,
	Avatar,
	Divider,
} from '@chakra-ui/react';
import c from '../../constant';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import ReactPaginate from 'react-paginate';
import './style.css';
import { useTranslation } from 'react-i18next';
import useStore from '../../store';

import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from '@cloudscape-design/components/modal';

const limit = 6;
const QUERY_USERS = gql`
query getUsers($offset:Float, $name: String, $role: String, $departments: String, $offices: String){
    getUsers(
        pagerInput:{
            limit:${limit}
            offset:$offset
        }
        name: $name
        role: $role
        active: true,
		departments: $departments
		offices: $offices
    ){
      users{
        id
        username
        fullName
        fullNameKhmer
        createdAt
        role
        avatar
        active
      }
      totalPages
    }
  }
`;

const ADD_TO_FAVORITE = gql`
	mutation add($id: String!) {
		addToFavorite(id: $id)
	}
`;

const REMOVE_FROM_FAVORITE = gql`
	mutation remove($id: String!) {
		removeFromFavorite(id: $id)
	}
`;

const QUERY_FAVORITE_USERS = gql`
	query {
		getFavotireList {
			user {
				id
				username
				fullName
				fullNameKhmer
				createdAt
				role
				avatar
				active
			}
			id
		}
	}
`;

function SelectUserModal({ isOpen = false, onClose = () => null, callback = () => null, role = '', viewType, departments = null, offices = null }) {
	const { t } = useTranslation();
	const toast = useToast();
	const [currentPage, setCurrentPage] = useState(0);
	const [loadUsers, { error, loading, data }] = useLazyQuery(QUERY_USERS);
	const [username, setUsername] = useState('');
	const { currentLang } = useStore((state) => state.currentLang);
	const { currentUser } = useStore((state) => state.currentUser);

	const [addUserToFavorite, { error: errorAdd, data: addData, loading: addLoading }] = useMutation(ADD_TO_FAVORITE);
	const [removeUserFromFavorite, { error: errorRemove, data: removeData, loading: removeLoading }] = useMutation(REMOVE_FROM_FAVORITE);
	const [loadFavoriteUsers, { error: errorFavorite, loading: loadingFavorite, data: dataFavorite }] = useLazyQuery(QUERY_FAVORITE_USERS);

	function isAdmin() {
		return currentUser?.role === 'admin';
	}

	useEffect(() => {
		if (isOpen) {
			loadUserWithVar();
			if (isAdmin()) {
				loadFavoriteUsers();
			}
		}
	}, [isOpen]);

	useEffect(() => {
		loadUserWithVar();
	}, [currentPage]);

	function reset() {
		setUsername('');
		setCurrentPage(0);
	}

	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			setCurrentPage(0);
			loadUserWithVar();
		}
	};

	function handleSearch() {
		setCurrentPage(0);
		loadUserWithVar();
	}

	function loadUserWithVar() {
		loadUsers({
			variables: {
				offset: currentPage ? (currentPage - 1) * limit : 0,
				name: username,
				role: viewType === 'all' ? null : role,
				departments,
				offices,
			},
		});
	}

	function handlePageClick(data) {
		let selected = data.selected < 0 ? 0 : data.selected;
		const page = selected + 1;
		setCurrentPage(page);
	}

	function getDisplayName(user) {
		let displayName = user?.username;
		if (currentLang === 'kh') {
			displayName = user?.fullNameKhmer ? user?.fullNameKhmer : user?.fullName ? user?.fullName : user?.username;
		} else {
			displayName = user?.fullName ? user?.fullName : user?.fullNameKhmer ? user?.fullNameKhmer : user?.username;
		}
		return displayName;
	}

	useEffect(() => {
		if (errorAdd) {
			if (errorAdd?.graphQLErrors?.length > 0) {
				errorAdd?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(errorAdd?.message ? errorAdd?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [errorAdd]);

	useEffect(() => {
		if (errorRemove) {
			if (errorRemove?.graphQLErrors?.length > 0) {
				errorRemove?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(errorRemove?.message ? errorRemove?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [errorRemove]);

	function checkIsFavorite(id) {
		const checkFilter = dataFavorite?.getFavotireList.filter((data) => {
			return data?.user?.id === id;
		});
		return checkFilter?.length > 0;
	}

	useEffect(() => {
		if (addData || removeData) {
			loadFavoriteUsers();
		}
	}, [addData, removeData]);

	return (
		<Modal
			onDismiss={() => {
				onClose();
				reset();
			}}
			visible={isOpen}
			header={t('User List')}
			size="large"
		>
			<Box>
				<InputGroup size="md">
					<Input
						onKeyDown={handleKeyDown}
						autoFocus
						w="100%"
						pr="4.5rem"
						placeholder={t('Name')}
						value={username}
						onChange={(e) => setUsername(e.target.value)}
					/>
					<InputRightElement zIndex={0} width="4.5rem"></InputRightElement>
					<Button size="md" ml="2" onClick={handleSearch}>
						{t('Search')}
					</Button>
				</InputGroup>
				<Box p="16px">
					{loading && <Text>{t('Loading')}</Text>}
					{isAdmin() && (
						<Box alignItems="center" mt="0">
							<Wrap ml="4">
								{dataFavorite?.getFavotireList.map((item, index) => {
									return (
										<Flex
											p="2"
											borderRadius={c.borderRadius}
											_hover={{ bg: '#ecf0f1' }}
											cursor="pointer"
											direction="row"
											justifyContent="center"
											alignItems="center"
											key={'u-' + index}
											onClick={() => {
												callback(item?.user);
												onClose();
											}}
										>
											<WrapItem className="avatarWraper">
												<Avatar
													size="xs"
													name={getDisplayName(item?.user)}
													src={item?.user?.avatar?.icon ? c.baseFilePath + item?.user?.avatar?.icon : null}
												/>
											</WrapItem>
											<Text ml="2" textAlign="center">
												{getDisplayName(item.user)}
											</Text>
										</Flex>
									);
								})}
							</Wrap>
							<Divider mt="2" />
						</Box>
					)}

					<Table variant="simple">
						<Thead>
							<Tr>
								<Th>{t('Username')}</Th>
								<Th>{t('Full Name In Latin')}</Th>
								<Th>{t('Full Name In Khmer')}</Th>
								{isAdmin() && <Th></Th>}
							</Tr>
						</Thead>
						<Tbody>
							{data?.getUsers?.users?.map((user, index) => {
								return (
									<Tr key={`user-${index}`} cursor="pointer" _hover={{ backgroundColor: 'gray.200' }}>
										<Td
											color="#2980b9"
											fontWeight="500"
											onClick={() => {
												callback(user);
												onClose();
											}}
										>
											{user.username}
										</Td>
										<Td
											color="#2980b9"
											fontWeight="500"
											onClick={() => {
												callback(user);
												onClose();
											}}
										>
											{user.fullName}
										</Td>
										<Td
											color="#2980b9"
											fontWeight="500"
											onClick={() => {
												callback(user);
												onClose();
											}}
										>
											{user.fullNameKhmer}
										</Td>
										{isAdmin() && (
											<Td color="#2980b9" fontWeight="500">
												<Button
													onClick={() => {
														if (checkIsFavorite(user?.id)) {
															removeUserFromFavorite({
																variables: {
																	id: user?.id,
																},
															});
														} else {
															addUserToFavorite({
																variables: {
																	id: user?.id,
																},
															});
														}
													}}
													_hover={{ bg: '#bdc3c7' }}
													size="xs"
													variant="ghost"
												>
													<FontAwesomeIcon
														style={{
															color: checkIsFavorite(user?.id) ? '#f39c12' : '#ecf0f1',
															fontSize: 14,
															marginRight: 8,
															marginLeft: 8,
														}}
														icon={faStar}
													/>
												</Button>
											</Td>
										)}
									</Tr>
								);
							})}
						</Tbody>
						<Tfoot>
							<Tr>
								<Th>{t('Username')}</Th>
								<Th>{t('Full Name In Latin')}</Th>
								<Th>{t('Full Name In Khmer')}</Th>
								{isAdmin() && <Th></Th>}
							</Tr>
						</Tfoot>
					</Table>
					<Flex justifyContent="flex-end" w="100%" mt="4">
						{data?.getUsers?.totalPages > 1 && (
							<ReactPaginate
								previousLabel={<ChevronLeftIcon fontSize="20px" />}
								nextLabel={<ChevronRightIcon fontSize="20px" />}
								breakLabel={'...'}
								breakClassName={'break-me'}
								pageCount={data?.getUsers?.totalPages}
								marginPagesDisplayed={2}
								pageRangeDisplayed={3}
								onPageChange={handlePageClick}
								containerClassName={'pagination'}
								activeClassName={'active'}
								initialPage={(currentPage - 1) | 0}
							/>
						)}
					</Flex>
				</Box>
			</Box>
		</Modal>
	);
}

export default SelectUserModal;
