/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Flex, Box, Center, Tooltip, Text, useDisclosure, Table, Thead, Tr, Th, Tbody, Tfoot, Td } from '@chakra-ui/react';
import { SectionHeader } from '../../Other';
import { useTranslation } from 'react-i18next';
import { gql, useLazyQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import AddLicensedProfessionalAgentModal from '../../Modal/AddLicensedProfessionalAgentModal';
import AddLicensedProfessionalBrokerModal from '../../Modal/AddLicensedProfessionalBrokerModal';
import LoadingTable from '../../Table/Loading';
import DownloadAndViewButton from '../../Files/DownloadAndViewButton';
import LicensedProIndividuaUpdateDelete from './LicensedProIndividuaUpdateDelete';
import ReactPaginate from 'react-paginate';

import useStore from '../../../store';
import c from '../../../constant';

const Q_GET_COMPANY_DETAIL = gql`
	query get($id: String!) {
		getCompanyById(id: $id) {
			id
			name
			nameKhmer
			logo
			url
			type
			streetNumber
			houseNumber
			ceoName
			ceoNationality
			province
			district
			commune
			village
			isGeoManual
			geoManual
			statuteFileSigned
			statuteSize
			licenseFileSigned
			licenseSize
			provinceObject {
				code
				name
				latinName
				administrativeUnit
				administrativeUnitLatin
			}
			districtObject {
				code
				name
				latinName
				administrativeUnit
				administrativeUnitLatin
			}
			communeObject {
				code
				name
				latinName
				administrativeUnit
				administrativeUnitLatin
			}
			villageObject {
				code
				name
				latinName
				administrativeUnit
				administrativeUnitLatin
			}
			licenseValidTo
			licenseValidFrom
			number
			displayNumber
			description
			isPermanentLicense
		}
	}
`;

const Q_GET_COMPANY_PROFESSIONALS = gql`
	query get($companyId: String!, $pagerInput: PagerInput!) {
		getCompanyProIndividuals(companyId: $companyId, pagerInput: $pagerInput) {
			list {
				id
				company {
					id
				}
				agent {
					id
					name
					latinName
					code
				}
				khmerName
				latinName
				number
				reference
			}
			totalRows
			totalPages
		}
	}
`;

function LicensedProfessional({ id }) {
	const { t } = useTranslation();
	const { currentUser: loginUser } = useStore((state) => state.currentUser);
	const { currentLang: lang } = useStore((state) => state.currentLang);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [page, setPage] = useState(1);
	const [loadCompany, { data: dataCompany }] = useLazyQuery(Q_GET_COMPANY_DETAIL);
	const [loadProfessionals, { loading: loadingProfessional, data: dataProfessional }] = useLazyQuery(Q_GET_COMPANY_PROFESSIONALS);

	useEffect(() => {
		if (id) {
			loadCompany({ variables: { id } });
		}
	}, [id]);

	useEffect(() => {
		if (
			dataCompany?.getCompanyById?.type === c.insuranceCompanyType.agent.value ||
			dataCompany?.getCompanyById?.type === c.insuranceCompanyType.broker.value||
			dataCompany?.getCompanyById?.type === c.insuranceCompanyType.lossAdjuster.value||
			dataCompany?.getCompanyById?.type === c.insuranceCompanyType.claimManagement.value||
			dataCompany?.getCompanyById?.type === c.insuranceCompanyType.actuarialFirm.value
		) {
			loadPaginatedProfessionals();
		}
	}, [dataCompany, page]);

	function loadPaginatedProfessionals() {
		const limit = 5;
		const offset = parseInt(page ? (page - 1) * limit : 0) || 0;
		const pagerInput = { limit, offset: offset < 0 ? 0 : offset };
		loadProfessionals({ variables: { companyId: id, pagerInput } });
	}

	return (
		<>
			{(
				dataCompany?.getCompanyById?.type === c.insuranceCompanyType.agent.value ||
				dataCompany?.getCompanyById?.type === c.insuranceCompanyType.broker.value||
				dataCompany?.getCompanyById?.type === c.insuranceCompanyType.lossAdjuster.value||
				dataCompany?.getCompanyById?.type === c.insuranceCompanyType.claimManagement.value||
				dataCompany?.getCompanyById?.type === c.insuranceCompanyType.actuarialFirm.value
			) && (
				<>
					<Flex className="hidden-print" mt={6}>
						<Box width="100%">
							<SectionHeader title={t('Professional Individual based on Licensing')} />
							<Center mt={4}>
								<Box
									width="100%"
									bg="white"
									borderRadius={c.borderRadius}
									border="1px solid #dbdbdb"
									boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
									pb={2}
								>
									<Flex
										h="48px"
										bg="#FAFAFA"
										pl="16px"
										pr="16px"
										mb="16px"
										alignItems="center"
										justifyContent="end"
										borderBottom="1px solid #dbdbdb"
									>
										<Flex alignItems="center">
											{(loginUser?.role === 'admin' || loginUser?.canUpdateCompanyLicensing) && (
												<Tooltip label={t('Add Professional Individual based on Licensing')}>
													<Flex
														className="hidden-print"
														userSelect="none"
														onClick={onOpen}
														cursor="pointer"
														alignItems="center"
														justifyContent="center"
														w="32px"
														h="32px"
														borderRadius={c.borderRadius}
														border="1px solid #dbdbdb"
														boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
													>
														<Text fontSize="26px" mt="2px" fontWeight="500">
															+
														</Text>
													</Flex>
												</Tooltip>
											)}
										</Flex>
									</Flex>
									<Box p="16px">
										<Table variant="simple">
											<Thead>
												<Tr>
													<Th>{t('Number')}</Th>
													<Th>{t('Professional Individual based on Licensing')}</Th>
													{dataCompany?.getCompanyById?.type !== 'INSURANCE_BROKER' && <Th>{t('Agent Code')}</Th>}
													<Th>{t('Reference Document')}</Th>
													{(loginUser?.role === 'admin' || loginUser?.canUpdateCompanyLicensing) && <Th isNumeric>{t('Actions')}</Th>}
												</Tr>
											</Thead>
											<Tbody>
												{loadingProfessional && <LoadingTable column={4} row={3} />}
												{dataProfessional?.getCompanyProIndividuals?.list?.length <= 0 && (
													<Tr>
														<Td>
															<Text>{t('No Data')}</Text>
														</Td>
													</Tr>
												)}
												{dataProfessional?.getCompanyProIndividuals?.list?.map((item, index) => {
													return (
														<Tr key={`item-${index}`} bg="white">
															<Td>
																<Text fontSize="sm" fontWeight="semibold" color="gray.600">
																	{item?.number}
																</Text>
															</Td>
															{dataCompany?.getCompanyById?.type === 'INSURANCE_BROKER' ? (
																<Td>
																	{lang === 'kh' ? item?.khmerName || item?.latinName : item?.latinName || item?.khmerName}
																</Td>
															) : (
																<Td>
																	{lang === 'kh'
																		? item?.agent?.name || item?.agent?.latinName
																		: item?.agent?.latinName || item?.agent?.name}
																</Td>
															)}
															{dataCompany?.getCompanyById?.type !== 'INSURANCE_BROKER' && (
																<Td>
																	<Tooltip label={t('Agent Code')}>
																		<Text fontSize="sm" fontWeight="semibold" color="blue.500">
																			{t(item?.agent?.code)}
																		</Text>
																	</Tooltip>
																</Td>
															)}
															<Td>
																{item?.reference?.file ? (
																	<DownloadAndViewButton
																		marginLeft={0}
																		url={item?.reference?.file}
																		size={item?.reference?.size}
																	/>
																) : (
																	'N/A'
																)}
															</Td>
															{(loginUser?.role === 'admin' || loginUser?.canUpdateCompanyLicensing) && (
																<Td isNumeric>
																	<LicensedProIndividuaUpdateDelete
																		id={item?.id}
																		number={item?.number}
																		khmerName={item?.khmerName}
																		latinName={item?.latinName}
																		onCallback={loadPaginatedProfessionals}
																	/>
																</Td>
															)}
														</Tr>
													);
												})}
											</Tbody>
											<Tfoot>
												<Tr>
													<Th>{t('Number')}</Th>
													<Th>{t('Professional Individual based on Licensing')}</Th>
													{dataCompany?.getCompanyById?.type !== 'INSURANCE_BROKER' && <Th>{t('Agent Code')}</Th>}
													<Th>{t('Reference Document')}</Th>
													{(loginUser?.role === 'admin' || loginUser?.canUpdateCompanyLicensing) && <Th isNumeric>{t('Actions')}</Th>}
												</Tr>
											</Tfoot>
										</Table>
										{dataProfessional?.getCompanyProIndividuals?.totalRows > 0 && (
											<>
												<Flex justifyContent="flex-end" direction="row">
													<Text fontSize="sm" fontWeight="md" color="gray.600" mt={2}>
														{t('Total Rows')}: {dataProfessional?.getCompanyProIndividuals?.totalRows}
													</Text>
												</Flex>
											</>
										)}
										{dataProfessional?.getCompanyProIndividuals?.totalPages > 1 && (
											<>
												<Flex alignItems="flex-end" direction="column" mt={6}>
													<Flex alignItems="flex-end" direction="column">
														<ReactPaginate
															previousLabel={<FontAwesomeIcon icon={faChevronLeft} style={{ fontSize: 12, cursor: 'pointer' }} />}
															nextLabel={<FontAwesomeIcon icon={faChevronRight} style={{ fontSize: 12, cursor: 'pointer' }} />}
															breakLabel={'...'}
															breakClassName={'break-me'}
															disableInitialCallback={true}
															pageCount={dataProfessional?.getCompanyProIndividuals?.totalPages}
															marginPagesDisplayed={2}
															pageRangeDisplayed={3}
															onPageChange={({ selected }) => setPage(selected + 1)}
															containerClassName={'pagination'}
															activeClassName={'active'}
															initialPage={(page - 1) | 0}
														/>
													</Flex>
												</Flex>
											</>
										)}
									</Box>
								</Box>
							</Center>
						</Box>
					</Flex>
					{isOpen && dataCompany?.getCompanyById?.type !== 'INSURANCE_BROKER' && (
						<>
							<AddLicensedProfessionalAgentModal companyId={id} isOpen={true} onClose={onClose} onCallback={loadPaginatedProfessionals} />
						</>
					)}
					{isOpen && dataCompany?.getCompanyById?.type === 'INSURANCE_BROKER' && (
						<>
							<AddLicensedProfessionalBrokerModal companyId={id} isOpen={true} onClose={onClose} onCallback={loadPaginatedProfessionals} />
						</>
					)}
					<Flex className="show-only-printing" mt={6}>
						<Box width="100%">
							<Center>
								<Box
									width="100%"
									bg="white"
									borderRadius={c.borderRadius}
									border="1px solid #dbdbdb"
									boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
									pb={2}
								>
									<Flex
										h="48px"
										bg="#FAFAFA"
										pl="16px"
										pr="16px"
										mb="16px"
										alignItems="center"
										justifyContent="space-between"
										borderBottom="1px solid #dbdbdb"
									>
										<SectionHeader title={t('Professional Individual based on Licensing')} fontSize="lg" />
									</Flex>
									<Box p="16px">
										<Table variant="simple">
											<Thead>
												<Tr>
													<Th>{t('Number')}</Th>
													<Th>{t('Professional Individual based on Licensing')}</Th>
													{dataCompany?.getCompanyById?.type !== 'INSURANCE_BROKER' && <Th>{t('Agent Code')}</Th>}
													<Th>{t('Reference Document')}</Th>
												</Tr>
											</Thead>
											<Tbody>
												{dataProfessional?.getCompanyProIndividuals?.list?.length <= 0 && (
													<Tr>
														<Td>
															<Text>{t('No Data')}</Text>
														</Td>
													</Tr>
												)}
												{dataProfessional?.getCompanyProIndividuals?.list?.map((item, index) => {
													return (
														<Tr cursor="pointer" _hover={{ bg: 'gray.200' }} key={`item-${index}`} bg="white">
															<Td>
																<Text fontSize="md" fontWeight="md" color="gray.600">
																	{item?.number}
																</Text>
															</Td>
															{dataCompany?.getCompanyById?.type === 'INSURANCE_BROKER' ? (
																<Td>
																	{lang === 'kh' ? item?.khmerName || item?.latinName : item?.latinName || item?.khmerName}
																</Td>
															) : (
																<Td>
																	{lang === 'kh'
																		? item?.agent?.name || item?.agent?.latinName
																		: item?.agent?.latinName || item?.agent?.name}
																</Td>
															)}
															{dataCompany?.getCompanyById?.type !== 'INSURANCE_BROKER' && <Td>{t(item?.agent?.code)}</Td>}
															<Td>
																{item?.reference?.file ? (
																	<DownloadAndViewButton
																		marginLeft={0}
																		url={item?.reference?.file}
																		size={item?.reference?.size}
																	/>
																) : (
																	'N/A'
																)}
															</Td>
														</Tr>
													);
												})}
											</Tbody>
											<Tfoot>
												<Tr>
													<Th>{t('Number')}</Th>
													<Th>{t('Professional Individual based on Licensing')}</Th>
													{dataCompany?.getCompanyById?.type !== 'INSURANCE_BROKER' && <Th>{t('Agent Code')}</Th>}
													<Th>{t('Reference Document')}</Th>
												</Tr>
											</Tfoot>
										</Table>
									</Box>
								</Box>
							</Center>
						</Box>
					</Flex>
				</>
			)}
		</>
	);
}

export default LicensedProfessional;
