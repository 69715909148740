/* eslint-disable react-hooks/exhaustive-deps */
import { Badge, Text, Tooltip } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { PartnerRequestStatus } from '../../../../containers/ManageAgentPartner/PartnerRequest/constants';
import useStore from '../../../../store';
import c from '../../../../constant';
import PartnerRequestEditingStatus from './PartnerRequestEditingStatus';

const PartnerRequestContractStatusDescription = ({
	requestStatus = null,
	contractStatus = null,
	contractStatusDescription = null,
	certificateStatus = null,
	cancelledContract = null,
}) => {
	const { t } = useTranslation();
	const { currentUser: user } = useStore((state) => state.currentUser);

	return (
		<>
			{requestStatus > PartnerRequestStatus.reviewContractFinal && (
				<>
					{contractStatus === 'APPROVED' ? (
						<>
							<Tooltip label={`${t('Completed')}`}>
								<Badge pl={2} pr={2} colorScheme="green">
									<Text fontWeight="bold" fontSize="xs" color="green.500">
										{t('Completed')}
									</Text>
								</Badge>
							</Tooltip>
						</>
					) : (
						<>
							{contractStatusDescription ? (
								<>
									<Text fontSize="sm" fontWeight="medium">
										{contractStatusDescription}
									</Text>
								</>
							) : (
								<Text fontSize="sm" fontWeight="normal">
									{'N/A'}
								</Text>
							)}
						</>
					)}
				</>
			)}
			{requestStatus === PartnerRequestStatus.reviewContract && (
				<>
					{c.stepOfficeRoles.includes(user?.position) ? (
						<>
							{contractStatusDescription ? (
								<>
									<Text fontSize="sm" fontWeight="medium">
										{contractStatusDescription}
									</Text>
								</>
							) : (
								<>
									{contractStatus ? (
										<>
											<Text fontSize="sm" fontWeight="normal">
												{'N/A'}
											</Text>
										</>
									) : (
										<>
											{cancelledContract ? (
												<>
													<Text fontSize="sm" fontWeight="normal">
														{'N/A'}
													</Text>
												</>
											) : (
												<>
													{certificateStatus === 'APPROVED' ? (
														<>
															<Text fontSize="sm" fontWeight="medium">
																{'-'}
															</Text>
														</>
													) : (
														<>
															<Text fontSize="sm" fontWeight="normal">
																{'N/A'}
															</Text>
														</>
													)}
												</>
											)}
										</>
									)}
								</>
							)}
						</>
					) : (
						<>
							<Text fontSize="sm" fontWeight="medium">
								{t('-')}
							</Text>
						</>
					)}
				</>
			)}
			{requestStatus === PartnerRequestStatus.reviewContractFinal && (
				<>
					{contractStatusDescription ? (
						<>
							<Text fontSize="sm" fontWeight="medium">
								{contractStatusDescription}
							</Text>
						</>
					) : (
						<>
							{contractStatus ? (
								<>
									<Text fontSize="sm" fontWeight="normal">
										{'N/A'}
									</Text>
								</>
							) : (
								<>
									<>
										{cancelledContract ? (
											<>
												<Text fontSize="sm" fontWeight="normal">
													{'N/A'}
												</Text>
											</>
										) : (
											<>
												{certificateStatus === 'APPROVED' ? (
													<>
														{c.stepDepartmentRoles.includes(user?.position) ? (
															<>
																<Text fontSize="sm" fontWeight="medium">
																	{'-'}
																</Text>
															</>
														) : (
															<>
																<PartnerRequestEditingStatus />
															</>
														)}
													</>
												) : (
													<>
														<Text fontSize="sm" fontWeight="normal">
															{'N/A'}
														</Text>
													</>
												)}
											</>
										)}
									</>
								</>
							)}
						</>
					)}
				</>
			)}
			{requestStatus === PartnerRequestStatus.reviewAgent && (
				<>
					<Text fontSize="sm" fontWeight="medium">
						{'-'}
					</Text>
				</>
			)}
			{requestStatus === PartnerRequestStatus.reviewAgentFinal && (
				<>
					<Text fontSize="sm" fontWeight="medium">
						{'-'}
					</Text>
				</>
			)}
			{requestStatus === PartnerRequestStatus.waitingContract && (
				<>
					<Text fontSize="sm" fontWeight="medium">
						{'-'}
					</Text>
				</>
			)}
		</>
	);
};

export default PartnerRequestContractStatusDescription;
