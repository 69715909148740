import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Box, Flex, Button, Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Text,
    Input,
    Checkbox,
    ModalFooter
} from "@chakra-ui/react";
import c from "../../constant"
import { v4 as uuid } from 'uuid';
import { DragHandleIcon, AddIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons'
import { SortableContainer, SortableElement, sortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';

function FileStructureCheckBox({
    callbackListChange,
    list
}) {
    const { t } = useTranslation();
    const [openFileInfoModal, setOpenFileInfoModal] = useState(false)
    const [listItem, setListItem] = useState(list||[])
    const [fileModalType, setFileModalType] = useState(null)
    const [fileModalFieldType, setFileModalFieldType] = useState(null)
    const [editingData, setEditingData] = useState(null)

    const SortableList = SortableContainer(({ items }) => {
        return (
            <Box mt="4">
                {items.map((value, index) => (
                    <SortableItem key={`item-${value?.id}`} index={index} kIndex={index} value={value} />
                ))}
            </Box>
        );
    });

    function onSortEnd({ oldIndex, newIndex }) {
        const newArray = arrayMove(listItem, oldIndex, newIndex)
        setListItem(newArray)
        callbackListChange(newArray)
        const updateIndexList = []
        newArray.map((item, index) => {
            updateIndexList.push({ id: item.id, index })
        })
    };

    function deleteRowByIndex(index) {
        const tmpListItem = [...listItem]
        tmpListItem.splice(index, 1);
        setListItem(tmpListItem)
        callbackListChange(tmpListItem)
    }

    function editRow(item){
        setOpenFileInfoModal(true)
        setFileModalType("edit")
        setEditingData(item)
    }

    const SortableItem = SortableElement((item) => {
        return (
            <Flex
                key={`stitem-${item?.value?.id}`}
                zIndex={99999}
                w="100%"
                tabIndex={0}
                mb="2"
                minH="32px"
                alignItems="center"
                bg="white"
                borderRadius={c.borderRadius}
                border="1px solid #dbdbdb"
                boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
                p="16px"
            >
                <DragHandle />
                <Flex
                    w="100%"
                    h="100%"
                >
                    {
                        item?.value?.type === "checklist" ?
                            <Box w="100%">
                            </Box> :
                            <Flex flex="1" justifyContent={"space-between"} alignItems="center">
                                <Text>{item?.value?.label}</Text>
                                <Flex>
                                    <Checkbox ml="4" w="120px" isChecked={item?.value?.required}>{t("Required")}</Checkbox>
                                    <Button mr="2" onClick={() => editRow(item)} size="xs" colorScheme='blue' variant='solid'>
                                        <EditIcon />
                                    </Button>
                                    <Button onClick={() => deleteRowByIndex(item?.kIndex)} size="xs" colorScheme='red' variant='solid'>
                                        <DeleteIcon />
                                    </Button>
                                </Flex>
                            </Flex>
                    }
                </Flex>
            </Flex>
        )
    });

    function addNewRow(type) {
        setOpenFileInfoModal(true)
        setFileModalType("add")
        setFileModalFieldType(type)
    }

    function fileModalCallback(e) {
        if (e?.fileModalType === "add") {
            const newData = {
                id: e?.id,
                type: e?.fileModalFieldType,
                label: e?.label,
                required: e?.required
            }
            setListItem([...listItem, newData])
            callbackListChange([...listItem, newData])
        }
    }

    function callbackEdit(e){
        const tmpListItem = [...listItem]
        tmpListItem[e?.kIndex] = e?.value
        setListItem(tmpListItem)
        callbackListChange(tmpListItem)
    }

    return (
        <>
            <Box pb="4">
                <SortableList useDragHandle items={listItem} onSortEnd={onSortEnd} />
                <Button variant='outline' size="sm" onClick={() => { addNewRow("file") }}>
                <AddIcon />
                </Button>
            </Box>
            {openFileInfoModal &&
                <FileInfoModal
                    onClose={() => {
                        setOpenFileInfoModal(false)
                        setEditingData(null)
                    }}
                    fileModalType={fileModalType}
                    fileModalFieldType={fileModalFieldType}
                    callback={e => fileModalCallback(e)}
                    callbackEdit={e=>callbackEdit(e)}
                    editingData={editingData}
                />
            }
        </>
    );
}

export default React.memo(FileStructureCheckBox);

const DragHandle = sortableHandle(() => <Flex minH="32px" justifyContent="center" alignItems="center" cursor="move" mr="4"><DragHandleIcon /></Flex>);

const FileInfoModal = ({ onClose, editingData, fileModalType, fileModalFieldType, callback, callbackEdit }) => {
    const { t } = useTranslation();
    const [label, setLabel] = useState(editingData?.value?.label||"")
    const [required, setRequired] = useState(editingData?.value?.required===false?false:true)
    return (
        <>
            <Modal isOpen={true} onClose={onClose}>
                <ModalOverlay />
                <ModalContent borderRadius={c.borderRadius}>
                    <ModalHeader>{t("File Info")}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Input autoFocus value={label} onChange={e => setLabel(e.target.value)} placeholder={t('Label')} />
                        <Checkbox
                            mt="2" defaultChecked={required}
                            onChange={(e) => setRequired(e.target.checked)}
                        >{t("Required")}</Checkbox>
                    </ModalBody>
                    <ModalFooter>
                        {fileModalType==="add"&&<Button colorScheme='blue' onClick={() => {
                            if (label) {
                                callback({
                                    id: uuid(),
                                    label,
                                    required,
                                    fileModalType,
                                    fileModalFieldType
                                })
                                onClose()
                            } else {
                                alert("Label is required")
                            }
                        }}>
                            {t("Add")}
                        </Button>}
                        {
                            fileModalType==="edit"&&<Button colorScheme='blue' onClick={() => {
                                if (label) {
                                    callbackEdit({
                                        kIndex:editingData?.kIndex,
                                        value:{
                                            // ...editingData?.value,
                                            id:editingData?.value?.id,
                                            type: editingData?.value?.type,
                                            label,
                                            required
                                        }
                                    })
                                    onClose()
                                } else {
                                    alert("Label is required")
                                }
                            }}>
                                {t("Update")}
                            </Button>
                        }
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}