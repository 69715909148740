/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSlidersH } from '@fortawesome/free-solid-svg-icons';
import { SectionHeader, AgentBadge } from '../../Other';
import { Box, Flex, Text, Button, Table, Thead, Tbody, Tfoot, Tr, Th, Td, Center, useDisclosure } from '@chakra-ui/react';
import AgentCardViewer from '../../Modal/AgentCardViewer';
import ReactPaginate from 'react-paginate';
import LoadingTable from '../../Table/Loading';
import DistributionSystemAgentPrint from './DistributionSystemAgentPrint';
import CompanyDetailAgentFilter from '../../Filter/CompanyDetailAgentFilter';
import useMoment from '../../../hooks/useMoment';
import useStore from '../../../store';
import c from '../../../constant';

const Q_GET_COMPANY_AGENTS = gql`
	query GetAgentsByCompanyId($name: String, $id: String!, $branchId: String, $type: AgentTypeTypes, $pagerInput: PagerInput!) {
		getAgentsByCompanyId(name: $name, companyId: $id, branchId: $branchId, type: $type, pagerInput: $pagerInput) {
			totalPages
			totalRows
			agents {
				id
				name
				latinName
				dob
				validFrom
				expireAt
				agentValidity {
					cardImage
				}
				agentCompany {
					id
					companyId
					startDate
					companyId
					endDate
					deleted
					status
					insuranceAgentCompanyType
				}
			}
		}
	}
`;

let limit = 10;
let offset = 0;
let name = null;
let branch = null;
let type = null;

function DistributionSystemAgent({ id, ...props }) {
	const history = useHistory();
	const { t } = useTranslation();
	const { toDateString } = useMoment();
	const { currentLang: lang } = useStore((state) => state.currentLang);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [cardData, setCardData] = useState(null);
	const [loadCompanyAgents, { loading, data }] = useLazyQuery(Q_GET_COMPANY_AGENTS);

	useEffect(() => {
		offset = 0;
	}, [id]);

	useEffect(() => {
		getCompanyAgents();
	}, [id]);

	function onPageChange(value) {
		offset = value * limit;
		getCompanyAgents();
	}

	function getCompanyAgents() {
		const variables = {
			id,
			name,
			branchId: branch?.value || null,
			type,
			pagerInput: { offset, limit },
		};
		loadCompanyAgents({ variables });
	}

	function openAgentDetail(id) {
		history.push(`/agent/detail?id=${id}`);
	}

	function getName(input) {
		return lang === 'kh' ? input?.name : input?.latinName;
	}

	function getDate(input) {
		return toDateString(input, c.dateOnlyFormat);
	}

	function getValid(from, to) {
		return `${getDate(from)} - ${getDate(to)}`;
	}

	return (
		<>
			<Flex mt={6} {...props}>
				<Box className="hidden-print" width="100%">
					<SectionHeader title={t('Agent')} />
					<Center mt={4}>
						<Box
							w="100%"
							bg="white"
							borderRadius={c.borderRadius}
							border="1px solid #dbdbdb"
							boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
							pb="32px"
						>
							<Flex p={4} pt={2} pb={2} mb={4} h="48px" bg="#FAFAFA" alignItems="center" justifyContent="end" borderBottom="1px solid #dbdbdb">
								<Flex alignItems="center">
									<Button
										onClick={onOpen}
										leftIcon={<FontAwesomeIcon icon={faSlidersH} style={{ fontSize: 16 }} />}
										colorScheme={`${name || branch || type ? 'yellow' : 'gray'}`}
										variant="solid"
										size="sm"
										borderRadius={c.borderRadius}
										border="1px solid #bdc3c7}"
									>
										{t('Filter')}
									</Button>
								</Flex>
							</Flex>

							<Box p="16px">
								<Table variant="simple">
									<Thead>
										<Tr>
											<Th>{t('Name')}</Th>
											<Th>{t('DOB')}</Th>
											<Th>{t('Start Working Date')}</Th>
											<Th>{t('Validity')}</Th>
											<Th>{t('Card')}</Th>
											<Th isNumeric>{t('Type of Agent')}</Th>
										</Tr>
									</Thead>
									<Tbody>
										{loading && <LoadingTable column={6} row={3} />}
										{data?.getAgentsByCompanyId?.agents.length <= 0 && (
											<Tr>
												<Td>
													<Text>{t('No Data')}</Text>
												</Td>
											</Tr>
										)}
										{data?.getAgentsByCompanyId?.agents?.map((item, index) => {
											return (
												<Tr cursor="pointer" _hover={{ bg: 'gray.200' }} key={`item-${index}`} bg="white">
													<Td onClick={() => openAgentDetail(item?.id)}>{getName(item)}</Td>
													<Td onClick={() => openAgentDetail(item?.id)}>{getDate(item?.dob)}</Td>
													<Td onClick={() => openAgentDetail(item?.id)}>{getDate(item?.agentCompany?.startDate)}</Td>
													<Td onClick={() => openAgentDetail(item?.id)}>{getValid(item?.validFrom, item?.expireAt)}</Td>
													<Td>
														<Box>
															{item?.agentValidity[0]?.cardImage ? (
																<Button size="sm" colorScheme="blue" onClick={() => setCardData(item?.agentValidity[0])}>
																	{t('View Card')}
																</Button>
															) : (
																<Text>N/A</Text>
															)}
														</Box>
													</Td>
													<Td isNumeric onClick={() => openAgentDetail(item?.id)}>
														<AgentBadge type={item?.agentCompany.insuranceAgentCompanyType} />
													</Td>
												</Tr>
											);
										})}
									</Tbody>
									<Tfoot>
										<Tr>
											<Th>{t('Name')}</Th>
											<Th>{t('DOB')}</Th>
											<Th>{t('Start Working Date')}</Th>
											<Th>{t('Validity')}</Th>
											<Th>{t('Card')}</Th>
											<Th isNumeric>{t('Type of Agent')}</Th>
										</Tr>
										<Tr>
											<Th />
											<Th />
											<Th />
											<Th />
											<Th />
											<Th isNumeric>
												{t('Total Rows')}: {data?.getAgentsByCompanyId?.totalRows}
											</Th>
										</Tr>
									</Tfoot>
								</Table>
								<Flex justifyContent="flex-end" w="100%" mt="4">
									{data?.getAgentsByCompanyId?.totalPages > 1 && (
										<ReactPaginate
											previousLabel={<ChevronLeftIcon fontSize="20px" />}
											nextLabel={<ChevronRightIcon fontSize="20px" />}
											breakLabel={'...'}
											breakClassName={'break-me'}
											disableInitialCallback={true}
											pageCount={data?.getAgentsByCompanyId?.totalPages}
											marginPagesDisplayed={2}
											pageRangeDisplayed={3}
											onPageChange={({ selected }) => onPageChange(selected)}
											containerClassName={'pagination'}
											activeClassName={'active'}
											initialPage={offset / limit}
										/>
									)}
								</Flex>
							</Box>
						</Box>
					</Center>
				</Box>
			</Flex>
			<CompanyDetailAgentFilter
				onApply={(e) => {
					offset = 0;
					name = e?.name;
					branch = e?.branch;
					type = e?.type;
					getCompanyAgents();
				}}
				onClose={onClose}
				isOpen={isOpen}
				id={id}
				name={name}
				branch={branch}
				type={type}
			/>
			{cardData && <AgentCardViewer onClose={() => setCardData(null)} cardValidData={cardData} />}
			{data && <DistributionSystemAgentPrint dataCompanyAgents={data} />}
		</>
	);
}

export default DistributionSystemAgent;
