import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Box, Flex, Text, Center, Button, useToast, RadioGroup, Stack, Radio, SimpleGrid } from '@chakra-ui/react';
import BreadcrumbComponent from '../../components/BreadcrumbComponent/BreadcrumbComponent';
import c from '../../constant';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/en-gb';
import CompanyPickerModal from '../../components/Modal/CompanyPickerModal';
import UploadingModalProgress from '../../components/UploadingModalProgress/UploadingModalProgress';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList } from '@fortawesome/free-solid-svg-icons';
import CompanyFormField from '../../components/form/CompanyFormField';
import TextFormField from '../../components/form/TextFormField';
import TextAreaFormField from '../../components/form/TextAreaFormField';
import RadioFormField from '../../components/form/RadioFormField';
import FileFormField from '../../components/form/FileFormField';
import LoadingOverlayText from '../../components/LoadingOverlay/LoadingOverlayText';
import DateFormField from '../../components/form/DateFormField';
import ReferenceInputField from '../../components/form/ReferenceInputField';

const MAX_FILE_SIZE = 104857600;

const QUERY_NUMBER_DATA = gql`
	query get($id: String) {
		getTypeOfDocumentNumberById(id: $id) {
			id
			type
			typeEn
			description
		}
	}
`;

const QUERY_DATA = gql`
	query get($id: String!) {
		getDocumentNumberById(id: $id) {
			id
			subject
			attachmentFile
			createdBy
			createdAt
			type
			language
			department
			companyName
			updateLogs
			number
			year
			displayNumber
			proposalNumber
			company {
				id
				name
				nameKhmer
				latestUpdateBranchAt
				logo
				type
				url
			}
			writeDate
			references {
				id
				documentNumberTypeReferenceValue
				documentNumberTypeReference {
					id
					enTitle
					khTitle
				}
			}
		}
	}
`;

const complaintOnInsuranceSchema = Yup.object().shape({
	proposalNo: Yup.string(),
	subject: Yup.string().required('required'),
	companyName: Yup.string(),
	selectedCompany: Yup.string(),

	department: Yup.string(),

	file: Yup.mixed().test('fileSize', 'over limit', (value) => {
		if (value) {
			return value?.size <= MAX_FILE_SIZE;
		} else {
			return true;
		}
	}),
	date: Yup.string().nullable(),
	references: Yup.array().of(
		Yup.object()
			.shape({
				referenceId: Yup.string().required('required'),
				referenceValue: Yup.string().required('required'),
			})
			.required('required')
	),
});

const EDIT_DOCUMENT_NUMBER_MUTATION = gql`
	mutation edit(
		$id: String!
		$type: String!
		$number: Float
		$language: String
		$proposalNumber: Float
		$subject: String
		$companyName: String
		$companyId: String
		$department: String
		$file: Upload
		$fileSize: Float
		$writeDate: DateTime
		$references: [DocumentNumberTypeReferenceInput!]
	) {
		editDocumentNumber(
			id: $id
			documentNumberInput: {
				type: $type
				number: $number
				language: $language
				proposalNumber: $proposalNumber
				subject: $subject
				companyName: $companyName
				companyId: $companyId
				department: $department
				references: $references
			}
			file: $file
			fileSize: $fileSize
			writeDate: $writeDate
		)
	}
`;

function AddCompanyProposal() {
	moment.locale('en-gb');
	const history = useHistory();
	const location = useLocation();
	const toast = useToast();
	const { t } = useTranslation();
	const urlParams = new URLSearchParams(location.search);
	const id = urlParams.get('id') || null;
	const typeId = urlParams.get('typeId') || null;
	const typeLabel = urlParams.get('typeLabel') || null;
	const [showCompanyPicker, setShowCompanyPicker] = useState(false);
	const [selectedCompany, setSelectedCompany] = useState(null);
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	const [uploading, setUploading] = useState(false);
	const [progress, setProgress] = useState(0);
	const [numberLang, setNumberLang] = useState('kh');
	const [loadData, { loading, data, error }] = useLazyQuery(QUERY_DATA);
	const [editDocumentNumber, { error: errorEdit, data: editData }] = useMutation(EDIT_DOCUMENT_NUMBER_MUTATION);
	const [loadNumberData, { error: errorNumberData, data: numberData }] = useLazyQuery(QUERY_NUMBER_DATA);

	useEffect(() => {
		loadNumberData({
			variables: {
				id: typeId,
			},
		});

		loadData({
			variables: {
				id,
			},
		});
	}, []);

	useEffect(() => {
		if (data?.getDocumentNumberById) {
			const tmpData = data?.getDocumentNumberById;
			setNumberLang(tmpData?.language);
			if (data?.getDocumentNumberById?.company?.id) {
				setSelectedCompany(data?.getDocumentNumberById?.company);
			}
		}
	}, [data]);

	useEffect(() => {
		if (errorEdit) {
			setUploading(false);
			if (errorEdit?.graphQLErrors?.length > 0) {
				errorEdit?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(error?.message ? error?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [errorEdit]);

	useEffect(() => {
		if (editData) {
			if (editData.editDocumentNumber) {
				setUploading(false);
				toast({
					title: t('Document number edited successfully'),
					status: 'success',
					duration: 5000,
					isClosable: true,
					position: 'bottom-right',
				});
				history.push(`/issued-letter/detail?typeId=${typeId}&typeLabel=${typeLabel}&id=${editData.editDocumentNumber}`);
			}
		}
	}, [editData]);

	async function submitEditDocumentNumber(values, numberLang) {
		setUploading(true);
		console.log("values:", values)

		let variables = {
			id,
			type: typeId,
			language: numberLang,
			proposalNumber: parseInt(values?.proposalNo) || null,
			subject: values?.subject,
			companyName: values?.companyName,
			companyId: values?.selectedCompany,
			department: values?.department,
			file: values.file || null,
			fileSize: values?.file?.size || null,
			writeDate: values?.date || null,
			references: values?.references?.map((element) => {
				return { referenceId: element?.id || '', typeReferenceId: element?.referenceId, typeReferenceValue: element?.referenceValue };
			}),
		};

		let abort;
		editDocumentNumber({
			variables,
			context: {
				fetchOptions: {
					useUpload: true,
					onProgress: (ev) => {
						setProgress(ev.loaded / ev.total);
					},
					onAbortPossible: (abortHandler) => {
						abort = abortHandler;
					},
				},
			},
		});

		if (abort) {
			abort();
		}
	}

	return (
		<>
			<Container maxW="container.xl" mt="16px" mb="16px">
				<BreadcrumbComponent
					list={[
						{
							name: 'ISSUED LETTER',
							path: '/issued-letter?page=1&count=300',
						},
						{
							name: typeLabel || '#',
							path: `/issued-letter/list?page=1&typeId=${typeId}&typeLabel=${typeLabel}`,
						},
						{
							name: 'Detail',
							path: `/issued-letter/detail?typeId=${typeId}&typeLabel=${typeLabel}&id=${id}`,
						},
						{
							name: 'Edit',
							path: '#',
						},
					]}
				/>
				<Center mt="5">
					<Box
						w="100%"
						bg="white"
						borderRadius={c.borderRadius}
						border="1px solid #dbdbdb"
						boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
						pb="32px"
					>
						<Flex bg="#FAFAFA" p="16px" pt="8px" pb="8px" mb="16px" justifyContent="space-between" borderBottom="1px solid #dbdbdb">
							<Text fontSize="x-large" fontWeight="bold">
								{t('Edit')}
							</Text>
						</Flex>

						{loading && <LoadingOverlayText />}

						{data?.getDocumentNumberById && (
							<Box p="16px">
								<Formik
									initialValues={{
										companyName: data?.getDocumentNumberById?.companyName || '',
										subject: data?.getDocumentNumberById?.subject || '',
										file: null,
										department: data?.getDocumentNumberById?.department || '',
										proposalNo: data?.getDocumentNumberById?.proposalNumber || '',
										selectedCompany: data?.getDocumentNumberById?.company || '',
										date: data?.getDocumentNumberById?.writeDate || moment(),
										references: data?.getDocumentNumberById?.references?.map((element) => {
											return {
												id: element?.id,
												referenceId: element?.documentNumberTypeReference?.id,
												referenceValue: element?.documentNumberTypeReferenceValue,
											};
										}),
									}}
									validationSchema={complaintOnInsuranceSchema}
									onSubmit={(values) => {
										submitEditDocumentNumber(values, numberLang);
									}}
								>
									{(props) => (
										<Form>
											<SimpleGrid columns={[2]} spacing="40px" w="100%">
												<Box>
													<ReferenceInputField name="references" label="Document Reference" />
													<TextAreaFormField {...props} label="Subject" name="subject" required />
													{selectedCompany?.id && (
														<CompanyFormField
															{...props}
															label="Company/Institution Name"
															name="selectedCompany"
															callBackClear={() => setSelectedCompany(null)}
															callBackList={() => setShowCompanyPicker(true)}
															selectedCompany={selectedCompany}
														/>
													)}

													{!selectedCompany?.id && (
														<TextFormField
															{...props}
															label="Company/Institution Name"
															name="companyName"
															rightElement={
																<Button
																	onClick={() => setShowCompanyPicker(true)}
																	leftIcon={
																		<FontAwesomeIcon
																			style={{
																				fontSize: 16,
																				cursor: 'pointer',
																			}}
																			icon={faList}
																		/>
																	}
																	mt="7px"
																	variant="solid"
																	h="42px"
																	mr="3px"
																	borderRadius={c.borderRadius}
																>
																	{t('List')}
																</Button>
															}
														/>
													)}

													{
														<RadioFormField
															label="Department"
															name="department"
															radios={c.departments}
															initValue={data?.getDocumentNumberById?.department}
														/>
													}

													<FileFormField {...props} label="Document" name="file" maxFileSize={MAX_FILE_SIZE} />
												</Box>
												<Box>
													<RadioGroup value={numberLang} mb="4" onChange={(e) => setNumberLang(e)}>
														<Stack>
															{numberData?.getTypeOfDocumentNumberById?.type && (
																<Radio colorScheme="green" value="kh">
																	<Text fontSize="xl" fontWeight="bold">
																		{numberData?.getTypeOfDocumentNumberById?.type}
																	</Text>
																</Radio>
															)}
															{numberData?.getTypeOfDocumentNumberById?.typeEn && (
																<Radio colorScheme="green" value="en">
																	<Text fontSize="xl" fontWeight="bold">
																		{numberData?.getTypeOfDocumentNumberById?.typeEn}
																	</Text>
																</Radio>
															)}
														</Stack>
													</RadioGroup>

													<DateFormField
														{...props}
														label="Date"
														name="date"
														required
														initialDate={
															data?.getDocumentNumberById?.writeDate ? moment(data?.getDocumentNumberById?.writeDate) : moment()
														}
													/>

													<Button
														id="login-btn"
														variant="solid"
														size="md"
														h="42px"
														w="128px"
														type="submit"
														borderRadius={c.borderRadius}
														mt="4"
														float="right"
													>
														{t('Edit')}
													</Button>
												</Box>
											</SimpleGrid>
										</Form>
									)}
								</Formik>
							</Box>
						)}
					</Box>
				</Center>
			</Container>

			<UploadingModalProgress isOpen={uploading} progress={progress} />

			{showCompanyPicker && (
				<CompanyPickerModal
					onClose={() => setShowCompanyPicker(false)}
					callBack={(e) => {
						setSelectedCompany(e);
					}}
				/>
			)}
		</>
	);
}

export default AddCompanyProposal;
