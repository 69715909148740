/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Box,
	Button,
	Flex,
	List,
	ListItem,
	Text,
	Textarea,
	useDisclosure,
	useToast,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { gql, useMutation } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import DownloadAndViewButton from '../../../Files/DownloadAndViewButton';
import useStore from '../../../../store';
import useMoment from '../../../../hooks/useMoment';
import c from '../../../../constant';

const M_UPDATE_INVOICE_STATUS = gql`
	mutation update($proposalId: String!, $data: JSON!) {
		updateAdditionalFeatureInvoiceFileStatus(proposalId: $proposalId, data: $data)
	}
`;

export default function InvoiceValidation({ proposalId = null, invoice = null, type = null, end = null, callback = () => null }) {
	const toast = useToast();
	const { t } = useTranslation();
	const {
		dateNow,
		paperGeneralDateString,
		paperSolarDateString,
		paperLunarDateString,
		auCertificateIssuedFromDate,
		auCertificateIssuedToDate,
		auCertificateRenewalFromDate,
		auCertificateRenewalToDate,
	} = useMoment();
	const { currentUser: user } = useStore((state) => state.currentUser);
	const { isOpen: isOpenApproving, onOpen: onOpenApproving, onClose: onCloseApproving } = useDisclosure();
	const { isOpen: isOpenRejecting, onOpen: onOpenRejecting, onClose: onCloseRejecting } = useDisclosure();
	const [reason, setReason] = useState('');
	const [updateInvoiceStatus, { loading, error, data }] = useMutation(M_UPDATE_INVOICE_STATUS);

	useEffect(() => {
		if (data) {
			toast({
				title: t('All tasks are completed'),
				status: 'success',
				isClosable: true,
				position: 'top-right',
			});
			callback();
			onCloseApproving();
			onCloseRejecting();
		}
	}, [data]);

	useEffect(() => {
		if (error) {
			error.graphQLErrors.map(({ message }, i) =>
				toast({
					title: t(message),
					status: 'error',
					isClosable: true,
					position: 'top-right',
				})
			);
		}
	}, [error]);

	const approve = () => {
		let from = null;
		let to = null;
		if (type === 'request_audit_service_license') {
			from = auCertificateIssuedFromDate();
			to = auCertificateIssuedToDate(from);
		} else if (type === 'request_audit_service_license_renewal') {
			from = auCertificateRenewalFromDate(end);
			to = auCertificateRenewalToDate(from);
		}
		const variables = {
			proposalId,
			data: {
				type,
				status: c.approved,
				approvedAt: dateNow(),
				approvedBy: user?.id,
				rejectedAt: null,
				rejectedBy: null,
				rejectionReason: null,
				printedValidFrom: paperGeneralDateString(from),
				printedValidTo: paperGeneralDateString(to),
				signedSolarDate: paperSolarDateString(),
				signedLunarDate: paperLunarDateString(),
			},
		};
		updateInvoiceStatus({ variables });
	};

	const reject = () => {
		const variables = {
			proposalId,
			data: {
				type,
				status: c.rejected,
				approvedAt: null,
				approvedBy: null,
				rejectedAt: dateNow(),
				rejectedBy: user?.id,
				rejectionReason: reason,
			},
		};
		updateInvoiceStatus({ variables });
	};

	return (
		<>
			<Flex mt={6} flexDirection="column" justifyContent="center" alignItems="center" border="2px dotted #718096">
				<Text fontSize="lg" fontWeight="600" mt={4}>
					{t('Payment Invoice Review')}
				</Text>
				<Text fontSize="sm" fontWeight="400" mt={1}>
					{t('Please review payment invoice below')}
				</Text>
				<Box mt={4}>
					<DownloadAndViewButton url={c.baseFilePath + invoice?.file} size={invoice?.fileSize} marginLeft={0} />
				</Box>
				<Flex mt={6} mb={6}>
					<Button w="100px" mr={4} borderRadius={c.borderRadius} size="sm" colorScheme="green" onClick={onOpenApproving}>
						{t('approve')}
					</Button>
					<Button w="100px" borderRadius={c.borderRadius} size="sm" colorScheme="red" onClick={onOpenRejecting}>
						{t('Reject')}
					</Button>
				</Flex>
			</Flex>
			<AlertDialog closeOnEsc={false} closeOnOverlayClick={false} isOpen={isOpenApproving} onClose={onCloseApproving}>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							{t('approve')}
						</AlertDialogHeader>
						<AlertDialogBody>
							<Text>{t('Are you sure to approve this invoice?')}</Text>
							<List color="#68686D">
								<ListItem mt={1}>
									{type === 'request_audit_service_license' && (
										<Box pl={2}>
											<Flex alignItems="center">
												<FontAwesomeIcon icon={faCircle} style={{ fontSize: 5, color: '#4A5568', marginRight: 8 }} />
												<Text color="gray.700">{t('Company will be created automatically')}</Text>
											</Flex>
											<Flex alignItems="center">
												<FontAwesomeIcon icon={faCircle} style={{ fontSize: 5, color: '#4A5568', marginRight: 8 }} />
												<Text color="gray.700">{t('Registration image will be generated')}</Text>
											</Flex>
										</Box>
									)}
									{type === 'request_audit_service_license_renewal' && (
										<Box pl={2}>
											<Flex alignItems="center">
												<FontAwesomeIcon icon={faCircle} style={{ fontSize: 5, color: '#4A5568', marginRight: 8 }} />
												<Text color="gray.700">{t('New registration validity will be created')}</Text>
											</Flex>
											<Flex alignItems="center">
												<FontAwesomeIcon icon={faCircle} style={{ fontSize: 5, color: '#4A5568', marginRight: 8 }} />
												<Text color="gray.700">{t('New registration image will be generated')}</Text>
											</Flex>
										</Box>
									)}
								</ListItem>
							</List>
						</AlertDialogBody>
						<AlertDialogFooter>
							<Button mr={4} isLoading={loading} onClick={onCloseApproving}>
								{t('Cancel')}
							</Button>
							<Button isLoading={loading} colorScheme="green" onClick={approve}>
								{t('approve')}
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
			<AlertDialog closeOnEsc={false} closeOnOverlayClick={false} isOpen={isOpenRejecting} onClose={onCloseRejecting}>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							{t('Reject')}
						</AlertDialogHeader>
						<AlertDialogBody>
							<Text>
								{t('Please input rejection reason')}
								<span style={{ color: 'red' }}>*</span>
							</Text>
							<Textarea mt={2} value={reason} onChange={(e) => setReason(e.target.value ?? '')} />
						</AlertDialogBody>
						<AlertDialogFooter>
							<Button mr={4} isLoading={loading} onClick={onCloseRejecting}>
								{t('Cancel')}
							</Button>
							<Button isLoading={loading} colorScheme="red" disabled={reason ? false : true} onClick={reject}>
								{t('Reject')}
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</>
	);
}
