import React, { useEffect, useState } from "react";
import {
    Box, Flex, Modal, Image,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Text,
    Center
} from "@chakra-ui/react";
import c from "../../constant"
import QRCodeGenerator from "../QRCodeGenerator"
import Logo from "../../assets/images/logo-border-white-92px.png";
import moment from "moment"
import { formatAgentCode } from "../../utils/index"
import Background from "../../assets/images/agent-card/new-bg1.jpg"
import "moment/locale/en-gb";


const labelFontSize = "11px"
const valueFontSize = "18px"
const labelColor = "#2980b9"
const leftLabelwidth = "124px"

function AgentCardViewer({
    isOpen = true, onClose = () => null, data = null, cardValidData = null
}) {
    moment.locale("en-gb")

    function rowView({ label, value }) {
        return (
            <Box pb="4px">
                <Text lineHeight="12px" fontWeight="bold" color="#2980b9" fontSize="11px" minW={leftLabelwidth} w={leftLabelwidth}>{label}</Text>
                <Text lineHeight="22px" fontSize="21px" fontWeight="600">{value}</Text>
            </Box>
        )
    }

    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={() => {
                    onClose()
                }}
                size="3xl"
            >
                <ModalOverlay />
                <ModalContent borderRadius={c.borderRadius} pt="2">
                    <ModalHeader></ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Center>
                            <Image w="100%" h="auto" src={`${c.baseFilePath}${cardValidData?.cardImage}`}/>
                            {/* <Box>
                                <Box 
                                backgroundImage={`url(${Background})`} 
                                backgroundSize="cover" w="654px" h="415px" p="5px" borderRadius={"2px"} boxShadow="0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%)">
                                    <Flex justifyContent="space-between" mt="120px" pl="16px" mr="16px">
                                        <Flex w="168px">
                                            <Image src={c.baseFilePath + data?.profileImage?.file} w={`${480 / 2.9}px`} h={`${640 / 2.9}px`} />
                                        </Flex>
                                        <Flex flex="1" direction="column">
                                            <Flex>
                                                <Flex flex={1} ml="5" direction="column">

                                                    {rowView({
                                                        label: "លេខកូដសម្គាល",
                                                        value: formatAgentCode(data?.code)
                                                    })}

                                                    {rowView({
                                                        label: "នាម-គោត្តនាម",
                                                        value: data?.name
                                                    })}

                                                    {rowView({
                                                        label: "Name",
                                                        value: data?.latinName
                                                    })}

                                                    {rowView({
                                                        label: "លេខអត្តសញ្ញាណប័ណ្ណ",
                                                        value: data?.idCardNumber
                                                    })}


                                                </Flex>
                                                <Flex>
                                                    <Flex>
                                                        <Box ml="4" mr="2" cursor="pointer" onClick={() => {
                                                            window.open(`http://localhost:3009/agent/info?id=${data?.code}`);
                                                        }}>
                                                            <QRCodeGenerator size={120} text={"https://irc.gov.kh/agent/info?id="+data?.code} />
                                                        </Box>
                                                    </Flex>
                                                </Flex>
                                            </Flex>

                                            <Flex ml="20px">
                                                {cardValidData &&
                                                    rowView({
                                                        label: "សុពលភាព",
                                                        value: <>
                                                            {moment(cardValidData?.validFrom).format("DD/MM/YYYY")}
                                                            &nbsp;-&nbsp;
                                                            {moment(cardValidData?.validTo).format("DD/MM/YYYY")}
                                                        </>
                                                    })
                                                }
                                            </Flex>
                                        </Flex>

                                    </Flex>

                                </Box>

                                <Box mt="4" w="654px" h="415px" p="5px" bg="white !important" borderRadius={"2px"} boxShadow="0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%)">
                                    <Box>
                                        <Text>
                                            ប័ណ្ណចុះបញ្ជីភ្នាក់ងារធានារ៉ាប់រងជារូបវន្តបុគ្គល<br />
                                            Individual Insurance Agent Registration Card
                                        </Text>
                                    </Box>
                                    <Box>
                                        <Text>
                                            ក្នុងករណីបាត់ឬខូច ត្រូវរាយការណ៍ជូនសមត្ថកិច្ច<br />
                                            អ្នកក្លែងប័ណ្ណសម្គាល់នឹងត្រូវផ្តន្ទាទោសតាមច្បាប់
                                        </Text>
                                    </Box>
                                </Box>
                            </Box> */}
                        </Center>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
}

export default AgentCardViewer;
