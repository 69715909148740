/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	Box,
	Text,
	Tabs,
	TabList,
	Tab,
	TabPanels,
	TabPanel,
	Flex,
	Badge,
	Image,
} from '@chakra-ui/react';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faFileAlt } from '@fortawesome/free-solid-svg-icons';
import useStore from '../../store';
import useMoment from '../../hooks/useMoment';
import c from '../../constant';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import './style.css';
import LOGO_PLACEHOLDER from '../../assets/images/logo-placeholder.png';

function InsuranceInstitutionNearlyDueModal({ data = null, isOpen = false, onClose = () => null }) {
	const history = useHistory();
	const { t } = useTranslation();
	const { toDateString } = useMoment();
	const { currentLang: lang } = useStore((state) => state.currentLang);

	return (
		<Modal isOpen={isOpen} onClose={onClose} size="6xl">
			<ModalOverlay />
			<ModalContent pb="4" borderRadius={c.borderRadius}>
				<ModalHeader>{t('Need(s) Renewal')}</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Box w="100%">
						<>
							{data ? (
								<>
									<Tabs size="md" variant="enclosed">
										<TabList>
											<Tab>
												<Text fontSize="md" fontWeight="md">
													{t('License Validity')}
												</Text>
												<>
													{data?.licenseCount > 0 && (
														<>
															<Badge colorScheme="red" variant="solid" ml={1}>
																{data?.licenseCount}
															</Badge>
														</>
													)}
												</>
											</Tab>
											<Tab>
												<Text fontSize="md" fontWeight="md">
													{t('Branch Validity')}
												</Text>
												<>
													{data?.branchCount > 0 && (
														<>
															<Badge colorScheme="red" variant="solid" ml={1}>
																{data?.branchCount}
															</Badge>
														</>
													)}
												</>
											</Tab>
										</TabList>
										<TabPanels>
											<TabPanel pl={0} pr={0} pt={2} pb={4}>
												{data?.licenseCount > 0 ? (
													<>
														<Table variant="simple" className="table-company-proposal-list responsive-table-container">
															<Thead>
																<Tr>
																	<Th align="left">{t('Logo')}</Th>
																	<Th align="left">{t('Company/Institution Name')}</Th>
																	<Th align="left">{t('Validity')}</Th>
																	<Th align="right">{t('Status')}</Th>
																</Tr>
															</Thead>
															<Tbody>
																<>
																	{data?.licenses?.map((item, index) => {
																		return (
																			<Tr
																				key={`company-item-key-${index}`}
																				onMouseDown={() => {
																					onClose();
																					history.push('/company/detail?id=' + item?.company?.id);
																				}}
																			>
																				<Td align="left">
																					<Image
																						src={item?.company?.logo ? c.baseFilePath + item?.company?.logo : ''}
																						w="auto"
																						minW="20px"
																						minH="20px"
																						h="20px"
																						fallbackSrc={LOGO_PLACEHOLDER}
																					/>
																				</Td>
																				<Td align="left">
																					<Text fontWeight="medium" noOfLines={1}>
																						{lang === 'kh' ? item?.company?.nameKhmer : item?.company?.name}
																					</Text>
																					<>
																						{item?.separatedLicenseType && (
																							<>
																								<Text
																									fontSize="sm"
																									fontWeight="medium"
																									color="gray.400"
																									noOfLines={1}
																								>
																									{t(item?.separatedLicenseType)}
																								</Text>
																							</>
																						)}
																					</>
																				</Td>
																				<Td align="left">
																					<Text fontWeight="medium" noOfLines={1}>
																						<>{toDateString(item?.validFrom, c.excelDateOnlyTextFormat)}</>
																						<> - </>
																						<>{toDateString(item?.validTo, c.excelDateOnlyTextFormat)}</>
																					</Text>
																				</Td>
																				<Td align="right">
																					{item?.status === 'Due' && (
																						<>
																							<Badge colorScheme="red" variant="solid" ml={1}>
																								{t('Due')}
																							</Badge>
																						</>
																					)}
																					{item?.status === 'NearlyDue' && (
																						<>
																							<Badge colorScheme="yellow" variant="solid" ml={1}>
																								{t('Nearly Due')}
																							</Badge>
																						</>
																					)}
																				</Td>
																			</Tr>
																		);
																	})}
																</>
															</Tbody>
														</Table>
													</>
												) : (
													<>
														<Flex
															w="100%"
															justifyContent="center"
															alignItems="center"
															direction="column"
															pt={24}
															pb={24}
															pl={0}
															pr={0}
														>
															<FontAwesomeIcon
																icon={faCheckCircle}
																style={{
																	fontSize: 40,
																	color: '#48BB78',
																}}
															/>
															<Text fontSize="md" color="gray.600" mt={4}>
																{t('All licenses are valid')}
															</Text>
														</Flex>
													</>
												)}
											</TabPanel>
											<TabPanel pl={0} pr={0} pt={2} pb={4}>
												{data?.branchCount > 0 ? (
													<>
														<Table variant="simple" className="table-company-proposal-list responsive-table-container">
															<Thead>
																<Tr>
																	<Th align="left">{t('Company/Institution Name')}</Th>
																	<Th align="left">{t('Branch')}</Th>
																	<Th align="left">{t('Validity')}</Th>
																	<Th align="right">{t('Status')}</Th>
																</Tr>
															</Thead>
															<Tbody>
																<>
																	{data?.branches?.map((item, index) => {
																		return (
																			<Tr
																				key={`company-item-key-${index}`}
																				onMouseDown={() => {
																					onClose();
																					history.push('/company/detail?id=' + item?.company?.id);
																				}}
																			>
																				<Td align="left">
																					<Flex justifyContent="left" alignItems="center">
																						<Image
																							src={
																								item?.company?.logo ? c.baseFilePath + item?.company?.logo : ''
																							}
																							w="auto"
																							minW="20px"
																							minH="20px"
																							h="20px"
																							mr={2}
																							fallbackSrc={LOGO_PLACEHOLDER}
																						/>
																						<Text fontWeight="medium" noOfLines={1}>
																							{lang === 'kh' ? item?.company?.nameKhmer : item?.company?.name}
																						</Text>
																					</Flex>
																				</Td>
																				<Td align="left">
																					<Text fontWeight="medium" noOfLines={1}>
																						{lang === 'kh' ? item?.khmerName : item?.latinName}
																					</Text>
																				</Td>
																				<Td align="left">
																					<Text fontWeight="medium" noOfLines={1}>
																						<>{toDateString(item?.validFrom, c.excelDateOnlyTextFormat)}</>
																						<> - </>
																						<>{toDateString(item?.validTo, c.excelDateOnlyTextFormat)}</>
																					</Text>
																				</Td>
																				<Td align="right">
																					{item?.status === 'Due' && (
																						<>
																							<Badge colorScheme="red" variant="solid" ml={1}>
																								{t('Due')}
																							</Badge>
																						</>
																					)}
																					{item?.status === 'NearlyDue' && (
																						<>
																							<Badge colorScheme="yellow" variant="solid" ml={1}>
																								{t('Nearly Due')}
																							</Badge>
																						</>
																					)}
																				</Td>
																			</Tr>
																		);
																	})}
																</>
															</Tbody>
														</Table>
													</>
												) : (
													<>
														<Flex
															w="100%"
															justifyContent="center"
															alignItems="center"
															direction="column"
															pt={24}
															pb={24}
															pl={0}
															pr={0}
														>
															<FontAwesomeIcon
																icon={faCheckCircle}
																style={{
																	fontSize: 40,
																	color: '#48BB78',
																}}
															/>
															<Text fontSize="md" color="gray.600" mt={4}>
																{t('All branches have valid license')}
															</Text>
														</Flex>
													</>
												)}
											</TabPanel>
										</TabPanels>
									</Tabs>
								</>
							) : (
								<>
									<Flex w="100%" justifyContent="center" alignItems="center" direction="column" pt={24} pb={24} pl={0} pr={0}>
										<FontAwesomeIcon
											icon={faFileAlt}
											style={{
												fontSize: 40,
												color: '#E2E8F0',
											}}
										/>
										<Text color="gray.400" mt={4}>
											{t('No Data')}
										</Text>
									</Flex>
								</>
							)}
						</>
					</Box>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
}

export default InsuranceInstitutionNearlyDueModal;
