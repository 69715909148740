import React, { useState, useEffect } from "react";

const ProgressiveImage = (props) =>{
	const { alt } = props;
	const [loading, setLoading] = useState(true)
	const [currentSrc, setCurrentSrc] = useState(props.placeholder)

	useEffect(()=>{
		const { src } = props;
		const imageToLoad = new Image();
		imageToLoad.src = src;
		imageToLoad.onload = () => {
			setLoading(false)
			setCurrentSrc(src)
		}
	},[props])

    return (
      <img
        src={currentSrc}
        style={{
          opacity: loading ? 0.5 : 1,
		  filter: loading?"blur(8px)":"unset",		
		  transition: "opacity 0.15s linear",
		  ...props?.style
        }}
        alt={alt}
      />
    );
}

export default ProgressiveImage;