/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, Text, ModalBody, ModalCloseButton, Button, Box, Flex } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { getDepartment, getDepartmentOffices, getDepartments, getOffice } from '../../utils/departmentOfficeSelectionWrapper';
import TextFormField from '../form/TextFormField';
import DateRangeFormField from '../form/DateRangeFormField';
import CheckBoxFormField from '../form/CheckBoxFormField';
import RadioFormField from '../form/RadioFormField';
import SelectSearchFormField from '../form/SelectSearchFormField';
import useStore from '../../store';
import moment from 'moment';
import c from '../../constant';
import * as Yup from 'yup';
import 'moment/locale/en-gb';

const filterSchema = Yup.object().shape({
	number: Yup.number(),
	companyName: Yup.string(),
	department: Yup.object().nullable(),
	office: Yup.object().nullable(),
	status: Yup.string(),
	type: Yup.string(),
	createdDate: Yup.string(),
	unreadOnly: Yup.boolean(),
	showSpam: Yup.boolean(),
	showNearlyDue: Yup.boolean(),
	showIncomplete: Yup.boolean(),
});

function CompanySubmissionProposalFilter({
	isOpen,
	onClose,
	callback,
	number = '',
	companyName = '',
	department = null,
	office = null,
	status = '',
	type = '',
	fromDate = null,
	toDate = null,
	read = null,
	showSpam = null,
	showNearlyDue = null,
	fromListReport = false,
	showIncomplete = false,
	auditServiceOnly = false,
}) {
	moment.locale('en-gb');
	const { t } = useTranslation();
	const { currentLang: lang } = useStore((state) => state.currentLang);
	const { currentUser } = useStore((state) => state.currentUser);

	let defaultRange = '';
	if (fromDate && toDate) {
		const defaultFrom = JSON.parse(fromDate);
		const defaultTo = JSON.parse(toDate);
		defaultRange = {
			from: defaultFrom,
			to: defaultTo,
		};
	}

	return (
		<>
			<Modal borderRadius={c.borderRadius} isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent borderRadius={c.borderRadius}>
					<ModalHeader>{t('Filter')}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Formik
							initialValues={{
								number: number ? number : '',
								companyName: companyName ? companyName : '',
								department: getDepartment(department, lang),
								office: getOffice(department, office, lang),
								status: status ? status : '',
								type: type ? type : '',
								createdDate: defaultRange ? JSON.stringify(defaultRange) : '',
								unreadOnly: read ? read : '',
								showSpam: showSpam ? showSpam : false,
								showNearlyDue: showNearlyDue ? showNearlyDue : false,
								showIncomplete: showIncomplete ? showIncomplete : false,
							}}
							validationSchema={filterSchema}
							onSubmit={(values) => {
								let url = '?page=1';
								if (values.number) {
									url += `&number=${values.number}`;
								}
								if (values.companyName) {
									url += `&companyName=${values.companyName}`;
								}
								if (values?.department?.value) {
									url += `&department=${values?.department?.value}`;
								}
								if (values?.office?.value) {
									url += `&office=${values?.office?.value}`;
								}
								if (values.status) {
									url += `&status=${values.status}`;
								}
								if (values.type) {
									url += `&type=${values.type}`;
								}
								if (values.createdDate) {
									const tmpDate = JSON.parse(values.createdDate);
									if (tmpDate?.from && tmpDate?.to) {
										const dateFrom = JSON.stringify(tmpDate.from);
										const dateTo = JSON.stringify(tmpDate.to);
										url += `&fromDate=${dateFrom}&toDate=${dateTo}`;
									}
								}
								if (values.unreadOnly) {
									url += `&read=false`;
								}
								if (values.showSpam) {
									url += `&showSpam=true`;
								}
								if (values.showNearlyDue) {
									url += `&showNearlyDue=true`;
								}
								if (values.showIncomplete) {
									url += `&showIncomplete=true`;
								}
								callback(url);
								onClose();
							}}
						>
							{(props) => {
								return (
									<Box pb="4">
										<Form>
											<TextFormField {...props} label="Number" name="number" type="number" />
											<TextFormField {...props} label="Company/Institution Name" name="companyName" />
											<SelectSearchFormField
												{...props}
												name="department"
												label="Department"
												placeholder="Department"
												options={getDepartments(lang)}
												callback={(e) => props?.setFieldValue('office', null)}
											/>
											<>
												{props?.values?.department && (
													<>
														<SelectSearchFormField
															{...props}
															name="office"
															label="Office"
															placeholder="Office"
															options={getDepartmentOffices(props?.values?.department?.value, lang)}
															callback={(e) => console.log(e)}
														/>
													</>
												)}
											</>
											<center>
												<DateRangeFormField {...props} label="Created Date" name="createdDate" initialDate={{ fromDate, toDate }} />
											</center>
											<>
												{!auditServiceOnly && (
													<>
														<RadioFormField
															label="Type"
															name="type"
															radios={[
																{ label: 'All', value: 'all' },
																{ label: 'Public Service', value: 'PUBLIC_SERVICE' },
																{ label: 'Other', value: 'OTHER' },
															]}
															initValue={type}
														/>
													</>
												)}
											</>
											<>
												{!fromListReport && (
													<>
														<Box marginTop="4">
															<CheckBoxFormField {...props} label="Show unread only" name="unreadOnly" />
														</Box>
													</>
												)}
											</>
											<Box marginTop="4">
												<CheckBoxFormField {...props} label="Show Only Due Within 7 Days" name="showNearlyDue" />
											</Box>
											<Text fontSize={11} color="gray">
												( {t('When show only due within 7 days, other filter will not affect')} )
											</Text>
											<>
												{currentUser?.role === 'admin' && (
													<>
														<Box marginTop="4">
															<CheckBoxFormField {...props} label="Show spam" name="showSpam" />
														</Box>
													</>
												)}
											</>
											<>
												{currentUser?.role === 'admin' && (
													<>
														<Box marginTop="4">
															<CheckBoxFormField {...props} label="Show incomplete submission" name="showIncomplete" />
														</Box>
													</>
												)}
											</>
											<Flex mt="32px" justifyContent="flex-end">
												<Button
													id="login-btn"
													variant="solid"
													size="md"
													h="42px"
													w="128px"
													type="submit"
													borderRadius={c.borderRadius}
													color="#3498db"
												>
													{t('Apply')}
												</Button>
												<Button
													size="md"
													h="42px"
													ml={4}
													w="128px"
													onClick={() => {
														callback('?page=1');
														onClose();
													}}
													variant="ghost"
												>
													{t('Clear')}
												</Button>
											</Flex>
										</Form>
									</Box>
								);
							}}
						</Formik>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
}

export default CompanySubmissionProposalFilter;
