import {
    Modal,
    ModalOverlay,
    ModalContent,
    Text,
    CircularProgress,
    Flex
  } from "@chakra-ui/react"
import c from "../../constant"
  export default function LoadingOverlay() {
    return (
      <>
        <Modal bg="rgba(0,0,0,0)" isOpen={true}>
          <ModalOverlay bg="rgba(0,0,0,0.2)" />
          <ModalContent borderRadius={c.borderRadius} bg="rgba(0,0,0,0)" p="4" boxShadow="unset">
            <Flex bg="rgba(0,0,0,0)" justifyContent="center" >
              <CircularProgress isIndeterminate color="blue.300" size="32px" thickness="6px" />
            </Flex> 
          </ModalContent>
        </Modal>
      </>
    )
  }