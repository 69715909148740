import React, { useEffect } from "react";
import { Flex, Text, Image } from "@chakra-ui/react";
import KHImage from "../assets/images/kh.svg"
import GBImage from "../assets/images/gb.svg"
import i18next from "../i18n"
import useStore from "../store"
export default function ChangeLanguage() {
    const lang = localStorage.getItem("language");

    const setCurrentLang = useStore((state) => state.setCurrentLang);
    const { currentLang } = useStore((state) => state.currentLang);

    useEffect(()=>{
        setCurrentLang(lang ? lang : "en")
    },[setCurrentLang, lang])

    function changeLanguage() {
        if (currentLang === "kh") {
            i18next.changeLanguage("en");
            setCurrentLang("en")
            localStorage.setItem("language", "en")
        } else {
            i18next.changeLanguage("kh");
            setCurrentLang("kh")
            localStorage.setItem("language", "kh")
        }
    }

    return (
        <Flex
            onClick={() => changeLanguage()}
            mb={currentLang === 'kh' ? "3px" : "0"}
            cursor="pointer"
            h="37px"
            alignItems="center"
            pt="3px"
            w="90px"
            justifyContent="center"
        >
            <Image userSelect="none" mt="1px" src={currentLang === 'kh' ? GBImage : KHImage} width="20px" height="20px" />
            <Text userSelect="none" color="white" ml="1" mr="16px" >{currentLang === 'kh' ? "English" : "ភាសាខ្មែរ"}</Text>
        </Flex>
    );
}
