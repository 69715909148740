import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Box, Flex, Button, useToast, Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Text,
    Tabs, TabList, TabPanels, Tab, TabPanel,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
} from "@chakra-ui/react";
import c from "../../constant"
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { useHistory } from "react-router-dom";
import TextFormField from "../../components/form/TextFormField"
import TextAreaFormField from "../../components/form/TextAreaFormField"
import FileFormField from "../../components/form/FileFormField"
import CheckBoxFormField from "../../components/form/CheckBoxFormField"
import RichTextEditor from "../../components/RichTextEditor/RichTextEditor";
import TableForm from "../../components/Table/TableFormWithoutSaveButton";
import { v4 as uuid } from 'uuid';
import useStore from "../../store";
import FileStructure from "../PublicService/FileStructure";

const EDIT_MUTATION = gql`
    mutation edit(
        $title: String!,
        $contentBody: String,
        $disableMessage: String
        $steps: JSON,
        $value:String,
        $active:Boolean,
        $attachmentFileSize: Float,
        $attachmentFile: Upload,
        $removeFile:Boolean,
        $id: String!
    ){
        editPublicService(
            attachmentFileSize:$attachmentFileSize,
            attachmentFile: $attachmentFile,
            publicServiceInput:{
                title:$title
                contentBody: $contentBody
                disableMessage: $disableMessage
                steps: $steps
                value:$value
                active:$active
                
            }
            removeFile: $removeFile
            id: $id
        )
    }
`


const QUERY_DATA = gql`
query get($id: String!){
    getPublicServiceById(id:$id){
        id
        title
        contentBody
        disableMessage
        steps
        licenseFee
        administrativeFee
        servicePeriod
        dueDate
        valid
        value
        active
        file
    }
  }
`

const MAX_FILE_SIZE = 104857600

const signUpSchema = Yup.object().shape({
    title: Yup.string().required("Required"),
    licenseFee: Yup.string(),
    administrativeFee: Yup.string(),
    servicePeriod: Yup.string(),
    dueDate: Yup.number().nullable(),
    valid: Yup.string(),
    value: Yup.string(),
    file: Yup.mixed().test("fileSize", "over limit", (value) => {
        if (value) {
            return value?.size <= MAX_FILE_SIZE
        } else {
            return true
        }
    }),
    removeFile: Yup.boolean(),
});

function Create({
    isOpen = false, onClose = () => null, callback = () => null, id, value
}) {
    const history = useHistory();
    const toast = useToast()
    const { t } = useTranslation();
    const [body, setBody] = useState(null)
    const [steps, setSteps] = useState(null)
    const { currentLang } = useStore((state) => state.currentLang);
    const [disableMessage, setDisableMessage] = useState(null)

    const [editPublicService, { loading, error, data }] = useMutation(
        EDIT_MUTATION
    );

    const [loadData, { loading: loadingData, error: errorData, data: dataData }] = useLazyQuery(
        QUERY_DATA
    );

    useEffect(() => {
        if (dataData?.getPublicServiceById) {
            setBody(dataData?.getPublicServiceById?.contentBody)
            setDisableMessage(dataData?.getPublicServiceById?.disableMessage || null)
        }
    }, [dataData])

    useEffect(() => {
        if (id) {
            loadData({
                variables: {
                    id
                }
            })
        }
    }, [id]);

    useEffect(() => {
        if (data) {
            if (data?.editPublicService) {
                toast({
                    title: t("Public service edited successfully"),
                    status: "success",
                    isClosable: true,
                    position: "top-right"
                })
                onClose()
                callback("Public service edited successfully")
            }
        }
    }, [data])

    useEffect(() => {
        if (error) {
            error.graphQLErrors.map(({ message }, i) => (
                toast({
                    title: t(message),
                    status: "error",
                    isClosable: true,
                    position: "top-right"
                })
            ))
        }
    }, [error])


    function create(values) {
        let variables = {
            title: values.title,
            contentBody: body,
            disableMessage: disableMessage,
            steps,
            value: values.value,
            active: values.active,
            attachmentFile: values.file ? values.file : null,
            attachmentFileSize: values?.file?.size ? values?.file?.size : null,
            removeFile: values?.removeFile,
            id
        }
        editPublicService({
            variables
        })
    }

    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={() => {
                    onClose()

                }}
                size="6xl"
                autoFocus={false}
                returnFocusOnClose={false}
            >
                <ModalOverlay />
                <ModalContent borderRadius={c.borderRadius} pb="2">
                    <ModalHeader>{t("Edit Public Service")}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Tabs>
                            <TabList>
                                <Tab>{t("Information")}</Tab>
                                {
                                    dataData?.getPublicServiceById?.steps?.map((item, index) => {
                                        return (
                                            <Tab key={`st-${index}`}>{currentLang === "en" ? item?.type : item?.typeKH}</Tab>
                                        )
                                    })
                                }
                            </TabList>

                            <TabPanels>
                                <TabPanel>
                                    {dataData?.getPublicServiceById && <Formik
                                        initialValues={{
                                            title: dataData?.getPublicServiceById?.title,
                                            value: dataData?.getPublicServiceById?.value,
                                            file: null,
                                            removeFile: false
                                        }}
                                        validationSchema={signUpSchema}
                                        onSubmit={(values) => {
                                            create(values);
                                        }}
                                    >
                                        {(props) => (
                                            <Form>
                                                <Box mt="3">
                                                    <Flex>
                                                        <Flex flex="1" direction="column">
                                                            <TextAreaFormField
                                                                {...props}
                                                                label="Title"
                                                                name="title"
                                                                required
                                                            />
                                                            <Box display="none">
                                                                <TextFormField
                                                                    {...props}
                                                                    label="Value"
                                                                    name="value"
                                                                />
                                                            </Box>

                                                            <RichTextEditor
                                                                initContent={dataData?.getPublicServiceById.contentBody}
                                                                onContentChange={(content) =>
                                                                    setBody(content)
                                                                }
                                                            />
                                                            <Accordion mt="4" allowToggle>
                                                                <AccordionItem>
                                                                    <h2>
                                                                        <AccordionButton _expanded={{ bg: 'tomato', color: 'white' }}>
                                                                            <Box as='span' flex='1' textAlign='left'>
                                                                                Enter message to disable this public service
                                                                            </Box>
                                                                            <AccordionIcon />
                                                                        </AccordionButton>
                                                                    </h2>
                                                                    <AccordionPanel>
                                                                        <RichTextEditor
                                                                            initContent={dataData?.getPublicServiceById.disableMessage}
                                                                            onContentChange={(content) =>
                                                                                setDisableMessage(content)
                                                                            }
                                                                        />
                                                                    </AccordionPanel>
                                                                </AccordionItem>
                                                            </Accordion>


                                                        </Flex>
                                                        <Flex ml="16px" flex="1" direction="column">
                                                            <FileFormField
                                                                {...props}
                                                                label="File"
                                                                name="file"
                                                                maxFileSize={MAX_FILE_SIZE}
                                                                accept="application/pdf"
                                                            />
                                                            <CheckBoxFormField
                                                                {...props}
                                                                label="Remove File"
                                                                name="removeFile"
                                                            />
                                                            <Text fontWeight="500" fontSize={"16px"}>{t("Steps")}</Text>

                                                            <TableForm
                                                                name="steps"
                                                                hideDelete={true}
                                                                label={null}
                                                                labelKh={null}
                                                                columns={[
                                                                    { name: "id", label: "id", labelKh: "id", type: "text", hide: true },
                                                                    { name: "type", label: "Type", labelKh: "ប្រភេទ", type: "text" },
                                                                    { name: "typeKH", label: "Type KH", labelKh: "ប្រភេទជាភាសាខ្មែរ", type: "text" },
                                                                    { name: "dueDate", label: "Due Date", labelKh: "កាលបរិច្ឆេទ​កំណត់", type: "text" },
                                                                    {
                                                                        name: "active", label: "Active", labelKh: "សកម្ម", type: "radio", options: [
                                                                            { value: "true", label: "true" },
                                                                            { value: "false", label: "false" }
                                                                        ]
                                                                    },
                                                                ]}
                                                                value={dataData?.getPublicServiceById?.steps ? dataData?.getPublicServiceById?.steps : [
                                                                    { id: uuid(), type: 'Request  Policy', typeKH: 'ស្នើសុំការអនុញ្ញាតជាគោលការណ៏', dueDate: '' }
                                                                ]}
                                                                onSave={e => {
                                                                    setSteps(e)
                                                                }}
                                                            />
                                                        </Flex>
                                                    </Flex>



                                                    <Flex
                                                        w="100%"
                                                        mt="6"
                                                        justifyContent="flex-end"
                                                    >
                                                        <Button
                                                            id="login-btn"
                                                            variant="solid"
                                                            size="md"
                                                            h="42px"
                                                            w="128px"
                                                            bg="primary.dark"
                                                            _hover={{ bg: "primary.dark" }}
                                                            color="white"
                                                            // borderRadius="31px"
                                                            type="submit"
                                                            isLoading={loading}
                                                            loadingText={t("Edit")}
                                                            spinnerPlacement="start"
                                                        >
                                                            {t(`Edit`)}
                                                        </Button>
                                                    </Flex>
                                                </Box>
                                            </Form>
                                        )}
                                    </Formik>}
                                </TabPanel>
                                {
                                    steps?.map((item, index) => {
                                        return (
                                            <TabPanel key={`s-tp${index}`}>
                                                <FileStructure publicServiceId={id} publicServiceValue={value} stepData={item} key={`f-${index}`} />
                                            </TabPanel>
                                        )
                                    })
                                }

                            </TabPanels>
                        </Tabs>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
}

export default Create;

