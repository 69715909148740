import React, { useState } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, Box, Text } from '@chakra-ui/react';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { useTranslation } from 'react-i18next';
import ProposalReassignStatus from '../Other/ProposalReassignStatus';
import useMoment from '../../hooks/useMoment';
import useStore from '../../store';
import c from '../../constant';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import './style.css';

function ProposalReassignModal({ proposals = [], isOpen = false, onClose = () => null }) {
	const { t } = useTranslation();
	const { toDateString } = useMoment();
	const { currentLang: lang } = useStore((state) => state.currentLang);
	const [currentIndex, setCurrentIndex] = useState(0);

	function getCompanyName(item) {
		let displayName = item?.companyName;
		if (item?.company?.id) {
			if (lang === 'kh') {
				displayName = item?.company?.nameKhmer;
			} else {
				displayName = item?.company?.name;
			}
		}
		return displayName;
	}

	function onNext() {
		setCurrentIndex(currentIndex + 1);
	}

	return (
		<Modal isOpen={isOpen} onClose={onClose} size="5xl">
			<ModalOverlay />
			<ModalContent pb="4" borderRadius={c.borderRadius}>
				<ModalHeader>{t('Reassign')}</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Box w="100%" pt="16px" pb="16px">
						<Table variant="simple" className="table-company-proposal-list responsive-table-container">
							<Thead>
								<Tr>
									<Th align="left">{t('Number')}</Th>
									<Th align="left">{t('Company/Institution Name')}</Th>
									<Th align="left">{t('Subject')}</Th>
									<Th align="left">{t('Proposal Created At')}</Th>
									<Th align="right">{t('Reassign')}</Th>
								</Tr>
							</Thead>
							<Tbody>
								<>
									{proposals.map((item, index) => {
										return (
											<Tr key={`proposal-items-key-${index}`}>
												<Td align="left">
													<Text fontWeight="semibold" noOfLines={1}>
														{item?.no}
													</Text>
												</Td>
												<Td align="left">
													<Text fontWeight="medium" noOfLines={1}>
														{getCompanyName(item)}
													</Text>
												</Td>
												<Td align="left">
													<Text fontWeight="medium" noOfLines={1} maxWidth="240px">
														{item?.subject}
													</Text>
												</Td>
												<Td align="left">
													<Text fontWeight="medium" noOfLines={1}>
														{toDateString(item?.startServiceDate ? item?.startServiceDate : item.createdAt, c.longDateFormat)}
													</Text>
												</Td>
												<Td align="right">
													<>{currentIndex >= index && <ProposalReassignStatus id={item?.id} onNext={onNext} />}</>
												</Td>
											</Tr>
										);
									})}
								</>
							</Tbody>
						</Table>
					</Box>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
}

export default ProposalReassignModal;
