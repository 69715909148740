/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from 'react-i18next';
import { Container, Box, Flex, Center, Button, useToast, Select } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList } from '@fortawesome/free-solid-svg-icons';
import { Formik, Form } from 'formik';
import { gql, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { SectionHeader } from '../../components/Other';
import CompanyPickerModal from '../../components/Modal/CompanyPickerModal';
import UploadingModalProgress from '../../components/UploadingModalProgress/UploadingModalProgress';
import BreadcrumbComponent from '../../components/BreadcrumbComponent/BreadcrumbComponent';
import CompanyFormField from '../../components/form/CompanyFormField';
import TextFormField from '../../components/form/TextFormField';
import TextAreaFormField from '../../components/form/TextAreaFormField';
import FileFormField from '../../components/form/FileFormField';
import DateFormField from '../../components/form/DateFormField';
import c from '../../constant';
import * as Yup from 'yup';

const M_CREATE_LICENSE_OR_FINE = gql`
	mutation CreateLicenseOrFee(
		$companyID: String
		$companyName: String
		$amount: Float
		$currency: String
		$subject: String
		$file: Upload!
		$fileSize: Float
		$type: String!
		$invoiceDate: DateTime
	) {
		CreateLicenseOrFee(
			companyID: $companyID
			companyName: $companyName
			amount: $amount
			currency: $currency
			subject: $subject
			file: $file
			fileSize: $fileSize
			type: $type
			invoiceDate: $invoiceDate
		)
	}
`;

function AddLicenseChargeOrFine() {
	const history = useHistory();
	const toast = useToast();
	const location = useLocation();
	const urlParams = new URLSearchParams(location.search);
	const type = urlParams.get('Type') ? urlParams.get('Type') : null;
	const { t } = useTranslation();
	const [showCompanyPicker, setShowCompanyPicker] = useState(false);
	const [company, setCompany] = useState(null);
	const [currency, setCurrency] = useState('KHR');
	const [createLicenseOrFine, { loading, error, data }] = useMutation(M_CREATE_LICENSE_OR_FINE);

	const validationSchema = Yup.object().shape({
		companyID: Yup.string(),
		companyName: Yup.string().when('companyID', {
			is: (companyID) => companyID,
			then: Yup.string(),
			otherwise: Yup.string().required('required'),
		}),
		subject: Yup.string().required('required'),
		amount: Yup.number()
			.typeError('required to input number')
			.positive('required to input number greater than zero')
			.integer('required to input only integer')
			.required('required'),
		file: Yup.mixed()
			.test('fileSize', 'over limited size', (value) => {
				if (value) {
					return value?.size <= c.maxFileSize;
				} else {
					return true;
				}
			})
			.required('required'),
		transactionDate: Yup.string().required('required'),
	});

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if (data) {
			if (data.CreateLicenseOrFee) {
				toast({
					title: t('Revenue added successfully'),
					status: 'success',
					duration: 5000,
					isClosable: true,
					position: 'bottom-right',
				});
				history.push(`/payment/?page=1&pageType=${type}`);
			}
		}
	}, [data]);

	useEffect(() => {
		if (error) {
			if (error?.graphQLErrors?.length > 0) {
				error?.graphQLErrors.map(({ message }) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(error?.message ? error?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [error]);

	async function submit(values) {
		let companyName = values?.companyName;
		if (values?.companyID) {
			companyName = null;
		}
		let variables = {
			companyID: values?.companyID,
			companyName: companyName,
			subject: values?.subject,
			amount: parseFloat(values?.amount),
			currency,
			file: values?.file,
			fileSize: values?.file?.size,
			type,
			invoiceDate: values?.transactionDate,
		};
		createLicenseOrFine({ variables });
	}

	return (
		<>
			<Container maxW="container.xl" mt="16px" mb="16px">
				<BreadcrumbComponent
					list={[
						{
							name: `Revenue`,
							path: `/payment?page=1&pageType=${type}`,
						},
						{
							name: `Add ${type}`,
							path: '#',
						},
					]}
				/>
				<Center mt="5">
					<Box
						w="100%"
						bg="white"
						borderRadius={c.borderRadius}
						border="1px solid #dbdbdb"
						boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
						pb={2}
					>
						<Flex bg="#FAFAFA" p="16px" pt="8px" pb="8px" mb="16px" justifyContent="space-between" borderBottom="1px solid #dbdbdb">
							<SectionHeader title={t(`Add ${type}`)} />
						</Flex>
						<Box p="16px" maxW="480px">
							<Formik
								initialValues={{
									companyID: '',
									companyName: '',
									amount: '',
									subject: '',
									file: null,
									transactionDate: '',
								}}
								validationSchema={validationSchema}
								onSubmit={(values) => {
									submit(values);
								}}
							>
								{(props) => (
									<Box>
										<Form>
											{null !== company && (
												<CompanyFormField
													{...props}
													label="Company/Institution Name"
													name="companyID"
													required
													callBackClear={() => setCompany(null)}
													callBackList={() => setShowCompanyPicker(true)}
													selectedCompany={company}
												/>
											)}
											{null === company && (
												<TextFormField
													{...props}
													label="Company/Institution Name"
													name="companyName"
													required
													rightElement={
														<Button
															onClick={() => setShowCompanyPicker(true)}
															leftIcon={
																<FontAwesomeIcon
																	style={{
																		fontSize: 16,
																		cursor: 'pointer',
																	}}
																	icon={faList}
																/>
															}
															mt="7px"
															variant="solid"
															h="42px"
															mr="3px"
															borderRadius={c.borderRadius}
														>
															{t('List')}
														</Button>
													}
												/>
											)}
											<TextFormField
												{...props}
												label="Amount"
												name="amount"
												rightElement={
													<Select
														variant="filled"
														defaultValue={'KHR'}
														onChange={function (e) {
															setCurrency(e.target.value);
														}}
														fontWeight={'bold'}
														fontSize={'lg'}
														m={'10px 5px 0 0'}
														p={'0'}
														cursor={'pointer'}
													>
														<option value="KHR">KHR</option>
														<option value="USD">USD</option>
													</Select>
												}
												required
											/>
											<TextAreaFormField {...props} label="Subject" name="subject" required />
											<FileFormField {...props} label="File" name="file" maxFileSize={c.maxFileSize} required />
											<DateFormField {...props} label="Transaction Date" name="transactionDate" required />
											<Button
												id="login-btn"
												variant="solid"
												mt={2}
												size="md"
												h="42px"
												w="128px"
												type="submit"
												borderRadius={c.borderRadius}
											>
												{t('Submit')}
											</Button>
										</Form>
									</Box>
								)}
							</Formik>
						</Box>
					</Box>
				</Center>
			</Container>
			<UploadingModalProgress isOpen={loading} />
			{showCompanyPicker && <CompanyPickerModal onClose={() => setShowCompanyPicker(false)} callBack={(e) => setCompany(e)} />}
		</>
	);
}

export default AddLicenseChargeOrFine;
