import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
    Container, Box, Flex, Text, Center,
    Badge, Menu, MenuButton, MenuList, MenuItem,
    useToast,
    Tooltip,
    Button
} from "@chakra-ui/react";
import BreadcrumbComponent from "../../components/BreadcrumbComponent/BreadcrumbComponent";
import { faEllipsisV, faPrint, faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import c from "../../constant"
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import moment from "moment"
import './style.css'
import { useLocation } from "react-router-dom";
import ViewComment from "../../components/Comment/ViewComment"
import useStore from "../../store";
import { CheckIcon, CloseIcon } from '@chakra-ui/icons'
import PrintLayout from "../../components/Print/PrintLayout";
import DownloadAndViewButton from "../../components/Files/DownloadAndViewButton";

const leftLabelWidth = "175px"
const marginTopRow = 1
const paddingTopRow = 1
const paddingBottomRow = 1
const QUERY_DETAIL = gql`
query get($id: String!){
    getLeaveById(id:$id){
      id
      purpose
      no
      amount
      createdAt
      type
      from
      to
      comeback
      approveByDepartment
      approveByDepartmentBy
      approveByDepartmentAt
      approveByDirectorGeneral
      approveByDirectorGeneralBy
      approveByDirectorGeneralAt
      attachmentFile
      attachmentFileSize
      user{
        username
        fullName
        fullNameKhmer
        department
      }
      userDepartment{
        username
        fullName
        fullNameKhmer
      }
      userDG{
        username
        fullName
        fullNameKhmer
      }
      notification{
        id
        read
      }
    }
  }
`

const APPROVE_MUTATION = gql`
mutation approve($id: String!){
    approveLeaveRequest(id:$id)
  }
`

const REJECT_MUTATION = gql`
mutation reject($id: String!){
    rejectLeaveRequest(id:$id)
  }
`

const MUTATION_READ_NOTIFICATION = gql`
    mutation read($id:String!){
        readNotificationById(id:$id)
    }
`

function ReportDetail() {
    const { t } = useTranslation();
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const id = urlParams.get('id') ? urlParams.get('id') : null;
    const { currentLang } = useStore((state) => state.currentLang);
    moment.locale(currentLang === "kh" ? "km" : "en-gb")
    const [loadDetail, { error, loading, data }] = useLazyQuery(QUERY_DETAIL)
    const { currentUser } = useStore((state) => state.currentUser);
    const toast = useToast()
    const { countUnreadNotifications } = useStore((state) => state.countUnreadNotifications);
	const setCountUnreadNotifications = useStore((state) => state.setCountUnreadNotifications);
    const [approveLeave, { error:errorApproveLeave, loading:loadingApproveLeave, data:dataApproveLeave }] = useMutation(APPROVE_MUTATION)
    const [rejectLeave, { error:errorRejectLeave, loading:loadingRejectLeave, data:dataRejectLeave }] = useMutation(REJECT_MUTATION)
    const [readNotification, {  }] = useMutation(MUTATION_READ_NOTIFICATION)

    useEffect(() => {
        window.scrollTo(0, 0);
        loadDetail({
            variables: {
                id
            }
        })
    }, []);

    useEffect(() => {
        if (id) {
            loadDetail({
                variables: {
                    id
                }
            })
        }
    }, [id])

    useEffect(()=>{
        if(data?.getLeaveById?.notification?.read===false){
            setCountUnreadNotifications(countUnreadNotifications-1)
            readNotification({variables:{id:data?.getLeaveById?.notification?.id} })
        }
    },[data])

    function getDisplayName(user) {
        let result = user?.username
        if (currentLang === "kh") {
            if (result = user?.fullNameKhmer) {
                result = user?.fullNameKhmer
            } else {
                if (user?.fullName) {
                    result = user?.fullName
                } else {
                    result = user?.username
                }
            }
        } else {
            if (result = user?.fullName) {
                result = user?.fullName
            } else {
                if (user?.fullNameKhmer) {
                    result = user?.fullNameKhmer
                } else {
                    result = user?.username
                }
            }
        }

        return result
    }

    function reportType(type) {
        return (
            type === "MONTHLY" ? <Badge colorScheme="green">{t("MONTHLY")}</Badge> :
                (type === "QUARTERLY" ? <Badge colorScheme="yellow">{t("QUARTERLY")}</Badge> :
                    <Badge colorScheme="red">{t("ANNUALLY")}</Badge>)
        )
    }

    

    function getLeaveType(type){
        let res = ""
        leaveType.map(item=>{
            if(item.value===type){
                res = item.label
            }
        })
        return res
    }

    function getApproveStatus(approve,date, user){
        let res = null
        if(approve===null){
            res = <Badge colorScheme="yellow">{t("In progress")}</Badge>
        } else if (approve===true){
            res = <Badge colorScheme="green">{t("Approved Leave")}</Badge>
        } else {
            res = <Badge colorScheme="red">{t("Rejected Leave")}</Badge>
        }
        if(date){
            res = <Tooltip label={`${moment(date).format("LLLL")} ${t("By")} ${getDisplayName(user)}`}>
                {res}
            </Tooltip>
        }
        return res
    }

    useEffect(()=>{
        if (errorApproveLeave) {
            if(errorApproveLeave?.graphQLErrors?.length>0){
                errorApproveLeave?.graphQLErrors.map(({ message }, i) => (
                toast({
                    title: t(message),
                    status: "error",
                    isClosable: true,
                    position: "top-right"
                })
            ))
            } else {
                toast({
                    title: t(errorApproveLeave?.message?errorApproveLeave?.message:"something went wrong"),
                    status: "error",
                    isClosable: true,
                    position: "top-right"
                })
            }
        }
    },[errorApproveLeave])

    useEffect(() => {
        if (dataApproveLeave?.approveLeaveRequest) {
            toast({
                title: t("Leave request approved successfully"),
                status: "success",
                isClosable: true,
                position: "top-right"
            })
            loadDetail({
                variables: {
                    id
                }
            })
        }
    }, [dataApproveLeave])

    useEffect(()=>{
        if (errorRejectLeave) {
            if(errorRejectLeave?.graphQLErrors?.length>0){
                errorRejectLeave?.graphQLErrors.map(({ message }, i) => (
                toast({
                    title: t(message),
                    status: "error",
                    isClosable: true,
                    position: "top-right"
                })
            ))
            } else {
                toast({
                    title: t(errorRejectLeave?.message?errorRejectLeave?.message:"something went wrong"),
                    status: "error",
                    isClosable: true,
                    position: "top-right"
                })
            }
        }
    },[errorRejectLeave])

    useEffect(() => {
        if (dataRejectLeave?.rejectLeaveRequest) {
            toast({
                title: t("Leave request rejected successfully"),
                status: "success",
                isClosable: true,
                position: "top-right"
            })
            loadDetail({
                variables: {
                    id
                }
            })
        }
    }, [dataRejectLeave])

    function getDepartmentName(dp){
        let label = "N/A"
        c.departments.map(item=>{
            if(item.value===dp){
                label = item.label
            }
        })
        return label
    }

    function checkIfDeputyDirector(department) {
        const dp = department?department?.split(","):null
        if(dp?.length>1){
            return true
        } else {
            return false
        }
    }


    return (
        <>
        <PrintLayout>
            <>
                <Container maxW="container.xl" mt="16px" mb="16px">
                    <BreadcrumbComponent
                        list={[
                            {
                                name: "Request Leave",
                                path: "/request-leave?page=1",
                            },
                            {
                                name: "DETAIL",
                                path: "#",
                            },
                        ]}
                    />
                    <Center mt="5">
                        <Box
                            w="100%"
                            bg="white"
                            borderRadius={c.borderRadius}
                            border="1px solid #dbdbdb"
                            boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
                            pb="32px"
                        >
                            <Flex alignItems="center" justifyContent="space-between" bg="#FAFAFA" p="16px" pt="8px" pb="8px" mb="16px" borderBottom="1px solid #dbdbdb">
                                <Flex justifyContent="center" alignItems="center">
                                    <Text
                                        fontSize="x-large"
                                        fontWeight="bold"
                                        mr="4"
                                    >
                                        {t("Request Leave")}
                                    </Text>
                                    </Flex>
                                <Flex className="hidden-print">
                                    <Button 
                                        mr="2"
                                        onClick={() => window?.print()}
                                        leftIcon={<FontAwesomeIcon icon={faPrint} style={{ fontSize: 16 }} />} 
                                        colorScheme='gray' 
                                        variant='solid'
                                        size="sm"
                                        borderRadius={c.borderRadius}
                                        border="1px solid #bdc3c7"
                                    >
                                        {t("Print")}
                                    </Button>
                                    
                                    {((currentUser?.role==="admin"&& (data?.getLeaveById?.approveByDirectorGeneral===null))||((currentUser?.canApproveRejectLeaveRequest&& (data?.getLeaveById?.approveByDepartment===null)) && !checkIfDeputyDirector(data?.getLeaveById?.user?.department)) )&&
                                    // <Menu>
                                    //     <MenuButton cursor="pointer">
                                    //         <Flex>
                                    //             <FontAwesomeIcon icon={faEllipsisV} style={{ fontSize: 16, marginTop: 4, marginLeft: 6 }} />
                                    //         </Flex>
                                    //     </MenuButton>
                                        
                                    //     <MenuList pt="4" pb="4" pl="3" pr="3" borderRadius={c.borderRadius}>
                                    //             <MenuItem icon={<CheckIcon />} color="green" onClick={() => approveLeave({variables:{id}})}>{t("Approve Leave")}</MenuItem>
                                    //             <MenuItem icon={<CloseIcon />} color="red" onClick={() => rejectLeave({variables:{id}})}>{t("Reject Leave")}</MenuItem>
                                    //     </MenuList>
                                        
                                    // </Menu>
                                    <>
                                        <Button 
                                            mr="2"
                                            onClick={() => approveLeave({variables:{id}})}
                                            leftIcon={<FontAwesomeIcon icon={faCheck} style={{ fontSize: 16 }} />} 
                                            colorScheme='green' 
                                            variant='solid'
                                            size="sm"
                                            borderRadius={c.borderRadius}
                                            border="1px solid #bdc3c7"
                                        >
                                            {t("Approve Leave")}
                                        </Button>
                                        <Button 
                                            onClick={() => rejectLeave({variables:{id}})}
                                            leftIcon={<FontAwesomeIcon icon={faTimes} style={{ fontSize: 16 }} />} 
                                            colorScheme='red' 
                                            variant='solid'
                                            size="sm"
                                            borderRadius={c.borderRadius}
                                            border="1px solid #bdc3c7"
                                        >
                                            {t("Reject Leave")}
                                        </Button>
                                    </>
                                    }
                                    

                                </Flex>
                            </Flex>

                            {loading && <Text ml="8">{t("Loading")}...</Text>}

                            {(!error && data?.getLeaveById?.id) &&
                                <Box p="16px" className="leave-detail-container">
                                    <Flex pt={paddingTopRow} pb={paddingBottomRow}>
                                        <Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>{t("Number")}</Text>
                                        <Text ml="4">{data?.getLeaveById?.no}</Text>
                                    </Flex>
                                    <Flex pt={paddingTopRow} pb={paddingBottomRow}>
                                        <Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>{t("Department")}</Text>
                                        <Text ml="4">{t(getDepartmentName(data?.getLeaveById?.user?.department))}</Text>
                                    </Flex>
                                    
                                    <Flex mt={marginTopRow} pt={paddingTopRow} pb={paddingBottomRow}>
                                        <Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>{t("Name")}</Text>
                                        <Text ml="4">{getDisplayName(data?.getLeaveById?.user)}</Text>
                                    </Flex>
                                    <Flex mt={marginTopRow} pt={paddingTopRow} pb={paddingBottomRow}>
                                        <Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>{t("Type")}</Text>
                                        <Text ml="4">{t(getLeaveType(data?.getLeaveById?.type))}</Text>
                                    </Flex>
                                    <Flex mt={marginTopRow} pt={paddingTopRow} pb={paddingBottomRow}>
                                        <Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>{t("Created At")}</Text>
                                        <Text ml="4">{moment(data?.getLeaveById?.createdAt).format("LLLL")}</Text>
                                    </Flex>
                                    <Flex mt={marginTopRow} pt={paddingTopRow} pb={paddingBottomRow}>
                                        <Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>{t("Leave amount (Days)")}</Text>
                                        <Text ml="4">{data?.getLeaveById?.amount}</Text>
                                    </Flex>
                                    <Flex mt={marginTopRow} pt={paddingTopRow} pb={paddingBottomRow}>
                                        <Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>{t("Leave from date")}</Text>
                                        <Text ml="4">{moment(data?.getLeaveById?.from).format("LLLL")}</Text>
                                    </Flex>
                                    <Flex mt={marginTopRow} pt={paddingTopRow} pb={paddingBottomRow}>
                                        <Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>{t("Leave to date")}</Text>
                                        <Text ml="4">{moment(data?.getLeaveById?.to).format("LLLL")}</Text>
                                    </Flex>
                                    <Flex mt={marginTopRow} pt={paddingTopRow} pb={paddingBottomRow}>
                                        <Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>{t("Leave comeback date")}</Text>
                                        <Text ml="4">{moment(data?.getLeaveById?.comeback).format("LLLL")}</Text>
                                    </Flex>

                                    <Flex mt={marginTopRow} pt={paddingTopRow} pb={paddingBottomRow}>
                                        <Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>{t("Purpose")}</Text>
                                        <Text ml="4">
                                            <pre style={{ whiteSpace: "pre-wrap", paddingBottom: 16, fontSize: 14, fontFamily: "Rajdhani, Hanuman, sans-serif, Moul !important" }}>
                                                {data?.getLeaveById?.purpose}
                                            </pre>
                                        </Text>
                                    </Flex>

                                    {data?.getLeaveById?.attachmentFile && <Flex alignItems="center" mt={marginTopRow} pt={paddingTopRow} pb={paddingBottomRow}>
                                        <Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>{t("Attachment File")}</Text>
                                        <DownloadAndViewButton url={data?.getLeaveById?.attachmentFile} size={data?.getLeaveById?.attachmentFileSize} />
                                    </Flex>}
                                    
                                    {checkIfDeputyDirector(data?.getLeaveById?.user?.department)?null:<Flex mt={marginTopRow} pt={paddingTopRow} pb={paddingBottomRow}>
                                        <Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>{t("Approved By Department")}</Text>
                                        <Box ml="4">
                                            {getApproveStatus(data?.getLeaveById?.approveByDepartment,data?.getLeaveById?.approveByDepartmentAt, data?.getLeaveById?.userDepartment)}
                                        </Box>
                                    </Flex>}

                                    <Flex mt={marginTopRow} pt={paddingTopRow} pb={paddingBottomRow}>
                                        <Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>{t("Approved By DG")}</Text>
                                        <Box ml="4">
                                            {getApproveStatus(data?.getLeaveById?.approveByDirectorGeneral,data?.getLeaveById?.approveByDirectorGeneralAt, data?.getLeaveById?.userDG)}
                                        </Box>
                                    </Flex>
                                </Box>
                            }
                        </Box>
                    </Center>
                    {
                        (!error && data?.getLeaveById?.id) &&
                        <ViewComment id={id} type="LEAVE_REQUEST" />
                    }
                </Container>
            </>
        </PrintLayout>
        </>
    );
}

export default ReportDetail;


const leaveType = [
    {label:"Annual leave", value:"ANNUAL_LEAVE"},
    {label:"Short leave", value:"SHORT_LEAVE"},
    {label:"Maternity leave", value:"MATERNITY_LEAVE"},
    {label:"Sick leave", value:"SICK_LEAVE"},
    {label:"Personal leave", value:"PERSONAL_LEAVE"},
    {label:"Work from home", value:"WORK_FROM_HOME"},
]