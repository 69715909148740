/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { Container, Box, Flex, Text, Center, Button, useToast } from '@chakra-ui/react';
import { useLocation, useHistory } from 'react-router-dom';
import BreadcrumbComponent from '../../../../components/BreadcrumbComponent/BreadcrumbComponent';
import LoadingOverlayText from '../../../../components/LoadingOverlay/LoadingOverlayText';
import TextFormField from '../../../../components/form/TextFormField';
import CheckBoxFormField from '../../../../components/form/CheckBoxFormField';
import c from '../../../../constant';
import * as Yup from 'yup';

const Q_GET_TYPE_REFERENCE = gql`
	query get($id: String!) {
		getDocumentNumberTypeReferenceById(id: $id) {
			id
			khTitle
			enTitle
			description
			defaultChoice
		}
	}
`;

const M_UPDATE_TYPE_REFERENCE = gql`
	mutation update($id: String!, $khTitle: String!, $enTitle: String!, $description: String, $defaultChoice: Boolean) {
		updateDocumentNumberTypeReference(id: $id, khTitle: $khTitle, enTitle: $enTitle, description: $description, defaultChoice: $defaultChoice)
	}
`;

const validationSchema = Yup.object().shape({
	khTitle: Yup.string().required('required'),
	enTitle: Yup.string().required('required'),
	description: Yup.string(),
	defaultChoice: Yup.boolean(),
});

function ReferenceTypeEdit() {
	const toast = useToast();
	const history = useHistory();
	const location = useLocation();
	const urlParams = new URLSearchParams(location.search);
	const id = urlParams.get('id') || null;
	const { t } = useTranslation();
	const [getTypeReference, { error: errorGet, data: dataGet, loading: loadingGet }] = useLazyQuery(Q_GET_TYPE_REFERENCE);
	const [updateTypeReference, { error: errorEdit, data: dataEdit, loading: loadingEdit }] = useMutation(M_UPDATE_TYPE_REFERENCE);

	useEffect(() => {
		if (id) {
			const variables = { id };
			getTypeReference({ variables });
		}
	}, [id]);

	useEffect(() => {
		if (errorGet) {
			if (errorGet?.graphQLErrors?.length > 0) {
				errorGet?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(errorGet?.message ? errorGet?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [errorGet]);

	useEffect(() => {
		if (dataEdit?.updateDocumentNumberTypeReference) {
			toast({
				title: t('Document type reference edited successfully'),
				status: 'success',
				duration: 5000,
				isClosable: true,
				position: 'bottom-right',
			});
			history.push('/type-of-issued-letter/detail-reference-type?id=' + id);
		}
	}, [dataEdit]);

	useEffect(() => {
		if (errorEdit) {
			if (errorEdit?.graphQLErrors?.length > 0) {
				errorEdit?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(errorEdit?.message ? errorEdit?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [errorEdit]);

	return (
		<>
			{(loadingGet || loadingEdit) && <LoadingOverlayText />}
			<Container maxW="container.xl" mt="16px" mb="16px">
				<BreadcrumbComponent
					list={[
						{
							name: 'Settings',
							path: '/settings',
						},
						{
							name: 'TYPE OF ISSUED LETTER',
							path: '/type-of-issued-letter?page=reference-type',
						},
						{
							name: 'Edit Reference Type',
							path: '/type-of-issued-letter/edit-reference-type?id=' + id,
						},
					]}
				/>
				<Center mt="5">
					<Box
						w="100%"
						bg="white"
						borderRadius={c.borderRadius}
						border="1px solid #dbdbdb"
						boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
						pb="32px"
					>
						<Flex bg="#FAFAFA" p="16px" pt="8px" pb="8px" mb="16px" justifyContent="space-between" borderBottom="1px solid #dbdbdb">
							<Text fontSize="x-large" fontWeight="bold">
								{t('Edit Reference Type')}
							</Text>
						</Flex>
						{dataGet?.getDocumentNumberTypeReferenceById?.id && (
							<Box p="16px" maxW="480px">
								<Formik
									initialValues={{
										khTitle: dataGet?.getDocumentNumberTypeReferenceById?.khTitle,
										enTitle: dataGet?.getDocumentNumberTypeReferenceById?.enTitle,
										description: dataGet?.getDocumentNumberTypeReferenceById?.description,
										defaultChoice: dataGet?.getDocumentNumberTypeReferenceById?.defaultChoice,
									}}
									validationSchema={validationSchema}
									onSubmit={(values) => {
										const variables = {
											id,
											khTitle: values.khTitle,
											enTitle: values.enTitle,
											description: values.description,
											defaultChoice: values.defaultChoice,
										};
										updateTypeReference({ variables });
									}}
								>
									{(props) => (
										<Box>
											<Form>
												<TextFormField {...props} label="Khmer Title" name="khTitle" required />
												<TextFormField {...props} label="English Title" name="enTitle" required />
												<TextFormField {...props} label="Description" name="description" />
												<CheckBoxFormField {...props} label="Default" name="defaultChoice" />
												<Button
													id="login-btn"
													variant="solid"
													size="md"
													h="42px"
													w="128px"
													mt={4}
													type="submit"
													borderRadius={c.borderRadius}
													isLoading={loadingEdit}
												>
													{t('Edit')}
												</Button>
											</Form>
										</Box>
									)}
								</Formik>
							</Box>
						)}
					</Box>
				</Center>
			</Container>
		</>
	);
}

export default ReferenceTypeEdit;
