import React from "react";
import moment from "moment"
import { Text } from "@chakra-ui/react"
import { useTranslation } from "react-i18next";

function AgentActiveDayCount({ validFrom, expireAt, activeDays }) {
  const { t } = useTranslation()
  return (
    <Text color="#d35400" mt="4">{t("From")} <span style={{ fontWeight: 600 }}>{moment(validFrom).format("DD/MM/YYYY")}</span> {t("To")} <span style={{ fontWeight: 600 }}>{moment(expireAt).format("DD/MM/YYYY")}</span> {t("Total working days is")} <b style={{ fontSize: 18, color: "black" }}>{activeDays}</b> </Text>
  )
}

export default AgentActiveDayCount