import { Badge } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

function IntermediaryTermination({ type = null }) {
	const { t } = useTranslation();

	return (
		<>
			<Badge size="md" colorScheme="gray">
				{t(type)}
			</Badge>
		</>
	);
}

export default IntermediaryTermination;
