/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import {
	Box,
	Center,
	Checkbox,
	Container,
	Flex,
	Text,
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	Tfoot,
	TableContainer,
	useToast,
	Badge,
	Button,
	useDisclosure,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { gql, useLazyQuery } from '@apollo/client';
import { useLocation, useHistory } from 'react-router-dom';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faPrint, faSlidersH } from '@fortawesome/free-solid-svg-icons';
import PrintLayout from '../../../components/Print/PrintLayout';
import ReactPaginate from 'react-paginate';
import BreadcrumbComponent from '../../../components/BreadcrumbComponent/BreadcrumbComponent';
import PartnerRequestStatus from '../../../components/ManageAgentPartner/PartnerRequest/Status/PartnerRequestStatus';
import PartnerRequestFilter from '../../../components/ManageAgentPartner/PartnerRequest/Filter';
import useStore from '../../../store';
import useMoment from '../../../hooks/useMoment';
import datePickerFromToToDate from '../../../utils/datePickerFromToToDate';
import c from '../../../constant';
import './style.css';

const Q_GET_REQUESTS = gql`
	query get(
		$offset: Float!
		$limit: Float!
		$companyId: String
		$companyName: String
		$status: PartnerReqeustStatus
		$number: String
		$receivedDateFrom: DateTime
		$receivedDateTo: DateTime
		$approvedDateFrom: DateTime
		$approvedDateTo: DateTime
	) {
		getAgentPartnerRequests(
			pagerInput: { limit: $limit, offset: $offset }
			companyId: $companyId
			companyName: $companyName
			status: $status
			number: $number
			receivedDateFrom: $receivedDateFrom
			receivedDateTo: $receivedDateTo
			approvedDateFrom: $approvedDateFrom
			approvedDateTo: $approvedDateTo
		) {
			list {
				id
				number
				createdAt
				startedAt
				status
				numberOfRequestedAgent
				numberOfApprovedAgent
				numberOfSubmittedContract
				numberOfApprovedContract
				company {
					id
					name
					nameKhmer
				}
			}
			countPrimaryCertificateReview
			countSecondaryCertificateReview
			countWaitingForContract
			countPrimaryContractReview
			countSecondaryContractReview
			countCompleted
			countAll
			totalRows
			totalPages
		}
	}
`;

const tabs = [
	{ label: 'Reviewing Agent By Office', value: 'ReviewingAgent', secondValue: 3 },
	{ label: 'Reviewing Agent By Department', value: 'ReviewingAgentFinal', secondValue: 4 },
	{ label: 'Waiting For Contract', value: 'WatingForContract', secondValue: 5 },
	{ label: 'Reviewing Contract', value: 'ReviewingContract', secondValue: 6 },
	{ label: 'Done', value: 'Completed', secondValue: 8 },
	{ label: 'All', value: 'All', secondValue: 0 },
];

function PartnerRequest() {
	const toast = useToast();
	const location = useLocation();
	const history = useHistory();
	const urlParams = new URLSearchParams(location.search);
	const currentPage = urlParams.get('page') ? urlParams.get('page') : 1;
	const status = urlParams.get('status') ? urlParams.get('status') : null;
	const number = urlParams.get('number') ? urlParams.get('number') : null;
	const receivedDateFrom = urlParams.get('receivedDateFrom') ? urlParams.get('receivedDateFrom') : null;
	const receivedDateTo = urlParams.get('receivedDateTo') ? urlParams.get('receivedDateTo') : null;
	const approvedDateFrom = urlParams.get('approvedDateFrom') ? urlParams.get('approvedDateFrom') : null;
	const approvedDateTo = urlParams.get('approvedDateTo') ? urlParams.get('approvedDateTo') : null;
	const companyName = urlParams.get('companyName') ? urlParams.get('companyName') : null;
	const companyId = urlParams.get('companyId') ? urlParams.get('companyId') : null;
	const more = urlParams.get('more') ? urlParams.get('more') : null;
	const { isOpen, onClose, onOpen } = useDisclosure();
	const { currentLang: lang } = useStore((state) => state.currentLang);
	const { currentUser: user } = useStore((state) => state.currentUser);
	const { t } = useTranslation();
	const { toDateString } = useMoment();
	const [loadPartnerRequest, { loading, data, error }] = useLazyQuery(Q_GET_REQUESTS);

	useEffect(() => {
		load();
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		load();
	}, [more, currentPage, companyId, companyName, status, number, receivedDateFrom, receivedDateTo, approvedDateFrom, approvedDateTo]);

	useEffect(() => {
		if (error) {
			if (error?.graphQLErrors?.length > 0) {
				error?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(error?.message ? error?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [error]);

	function load() {
		const offset = parseInt(currentPage ? (currentPage - 1) * (more === 'true' ? 300 : 10) : 0) || 0;
		const variables = {
			offset: offset < 0 ? 0 : offset,
			limit: more === 'true' ? 300 : 10,
			companyId,
			companyName,
			status: queryStatus(),
			number,
			receivedDateFrom: datePickerFromToToDate(receivedDateFrom),
			receivedDateTo: datePickerFromToToDate(receivedDateTo),
			approvedDateFrom: datePickerFromToToDate(approvedDateFrom),
			approvedDateTo: datePickerFromToToDate(approvedDateTo),
		};
		loadPartnerRequest({ variables });
	}

	function handleTabChanged(value) {
		let url = `?page=1`;

		if (companyId) {
			url += `&companyId=${companyId}`;
		}

		if (companyName) {
			url += `&companyName=${companyName}`;
		}

		if (number) {
			url += `&number=${number}`;
		}

		if (receivedDateFrom) {
			url += `&receivedDateFrom=${receivedDateFrom}`;
		}

		if (receivedDateTo) {
			url += `&receivedDateTo=${receivedDateTo}`;
		}

		if (approvedDateFrom) {
			url += `&approvedDateFrom=${approvedDateFrom}`;
		}

		if (approvedDateTo) {
			url += `&approvedDateTo=${approvedDateTo}`;
		}

		if (value) {
			url += `&status=${value}`;
		}

		if (more !== null) {
			url += `&more=${more}`;
		}

		history.push(`/manage-agent-partner/partner-request${url}`);
	}

	function handlePageChanged({ selected }) {
		let url = `?page=${selected + 1}`;

		if (companyId) {
			url += `&companyId=${companyId}`;
		}

		if (companyName) {
			url += `&companyName=${companyName}`;
		}

		if (number) {
			url += `&number=${number}`;
		}

		if (receivedDateFrom) {
			url += `&receivedDateFrom=${receivedDateFrom}`;
		}

		if (receivedDateTo) {
			url += `&receivedDateTo=${receivedDateTo}`;
		}

		if (approvedDateFrom) {
			url += `&approvedDateFrom=${approvedDateFrom}`;
		}

		if (approvedDateTo) {
			url += `&approvedDateTo=${approvedDateTo}`;
		}

		if (status) {
			url += `&status=${status}`;
		}

		if (more !== null) {
			url += `&more=${more}`;
		}

		history.push(`/manage-agent-partner/partner-request${url}`);
	}

	function handleMoreChanged() {
		let url = `?page=1`;

		if (companyId) {
			url += `&companyId=${companyId}`;
		}

		if (companyName) {
			url += `&companyName=${companyName}`;
		}

		if (number) {
			url += `&number=${number}`;
		}

		if (receivedDateFrom) {
			url += `&receivedDateFrom=${receivedDateFrom}`;
		}

		if (receivedDateTo) {
			url += `&receivedDateTo=${receivedDateTo}`;
		}

		if (approvedDateFrom) {
			url += `&approvedDateFrom=${approvedDateFrom}`;
		}

		if (approvedDateTo) {
			url += `&approvedDateTo=${approvedDateTo}`;
		}

		if (status) {
			url += `&status=${status}`;
		}

		url += `&more=${more === 'true' ? 'false' : 'true'}`;

		history.push(`/manage-agent-partner/partner-request${url}`);
	}

	function queryStatus() {
		if (status) {
			if ('All'.toLowerCase() === status?.toLowerCase()) {
				return null;
			} else {
				if (tabs.map((e) => e?.value?.toLowerCase()).includes(status?.toLowerCase())) {
					return status;
				} else {
					return 'ReviewingAgent';
				}
			}
		} else {
			return 'ReviewingAgent';
		}
	}

	function panelTab({ label, value, secondValue }) {
		let active = tabs.map((e) => e.value).includes(status) ? status : 'ReviewingAgent';
		return (
			<Flex
				_hover={{ boxShadow: `inset 0 -2px 0 0 ${value === active ? '#2980b9' : '#bdc3c7'}` }}
				className="panel-tab"
				userSelect="none"
				minW="100px"
				onClick={() => handleTabChanged(value)}
				cursor="pointer"
				mb={'-2px'}
				boxShadow={value === active ? 'inset 0 -2px 0 0 #2980b9' : ''}
				pt="16px"
				pb="16px"
				pl="12px"
				pr="12px"
				alignItems="center"
				justifyContent="center"
			>
				<Text fontWeight={value === active ? '700' : '500'} color="gray.700">
					{t(label)}
				</Text>
				<Box minW="18px">
					{secondValue === 3 && (
						<>
							&nbsp;
							<>
								<Badge
									colorScheme={c.stepOfficeRoles.includes(user?.position) ? 'red' : 'blue'}
									variant={value === active ? 'solid' : 'outline'}
									pl={1}
									pr={1}
								>
									{data?.getAgentPartnerRequests?.countPrimaryCertificateReview}
								</Badge>
							</>
						</>
					)}
					{secondValue === 4 && (
						<>
							&nbsp;
							<>
								<Badge
									colorScheme={c.stepDepartmentRoles.includes(user?.position) ? 'red' : 'blue'}
									variant={value === active ? 'solid' : 'outline'}
									pl={1}
									pr={1}
								>
									{data?.getAgentPartnerRequests?.countSecondaryCertificateReview}
								</Badge>
							</>
						</>
					)}
					{secondValue === 5 && (
						<>
							&nbsp;
							<>
								<Badge colorScheme="blue" variant={value === active ? 'solid' : 'outline'} pl={1} pr={1}>
									{data?.getAgentPartnerRequests?.countWaitingForContract}
								</Badge>
							</>
						</>
					)}
					{secondValue === 6 && (
						<>
							&nbsp;
							<>
								<Badge
									colorScheme={c.stepOfficeRoles.includes(user?.position) ? 'red' : 'blue'}
									variant={value === active ? 'solid' : 'outline'}
									pl={1}
									pr={1}
								>
									{data?.getAgentPartnerRequests?.countPrimaryContractReview}
								</Badge>
							</>
						</>
					)}
					{secondValue === 7 && (
						<>
							&nbsp;
							<>
								<Badge
									colorScheme={c.stepDepartmentRoles.includes(user?.position) ? 'red' : 'blue'}
									variant={value === active ? 'solid' : 'outline'}
									pl={1}
									pr={1}
								>
									{data?.getAgentPartnerRequests?.countSecondaryContractReview}
								</Badge>
							</>
						</>
					)}
					{secondValue === 8 && (
						<>
							&nbsp;
							<>
								<Badge colorScheme="blue" variant={value === active ? 'solid' : 'outline'} pl={1} pr={1}>
									{data?.getAgentPartnerRequests?.countCompleted}
								</Badge>
							</>
						</>
					)}
					{secondValue === 0 && (
						<>
							&nbsp;
							<>
								<Badge colorScheme="blue" variant={value === active ? 'solid' : 'outline'} pl={1} pr={1}>
									{data?.getAgentPartnerRequests?.countAll}
								</Badge>
							</>
						</>
					)}
				</Box>
			</Flex>
		);
	}

	function CountStatistic({ v1 = null, v2 = null }) {
		return <Text fontWeight="medium">{`${parseInt(v1) || 0} / ${parseInt(v2) || 0}`}</Text>;
	}

	return (
		<PrintLayout>
			<>
				<Container maxW="container.xl" mt="16px" mb="16px">
					<BreadcrumbComponent
						list={[
							{
								name: 'MANAGE AGENT PARTNER',
								path: '/manage-agent-partner',
							},
							{
								name: 'AGENT PARTNER REQUEST',
								path: '#',
							},
						]}
					/>
					<Center mt="5">
						<Box
							w="100%"
							bg="white"
							borderRadius={c.borderRadius}
							border="1px solid #dbdbdb"
							boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
							pb="32px"
						>
							<Flex
								className="hidden-print responsive-header-for-table"
								bg="#FAFAFA"
								p="16px"
								pt="0"
								pb="0"
								mb="16px"
								alignItems="center"
								justifyContent="space-between"
								borderBottom="1px solid #dbdbdb"
							>
								<Flex className="panel-tab-container" minW="303px">
									{tabs.map((e) => panelTab({ label: e.label, value: e.value, secondValue: e.secondValue }))}
								</Flex>
								<Flex justifyContent="center" alignItems="center">
									<Button
										onClick={() => window?.print()}
										leftIcon={<FontAwesomeIcon icon={faPrint} style={{ fontSize: 16 }} />}
										colorScheme="gray"
										variant="solid"
										size="sm"
										borderRadius={c.borderRadius}
										border="1px solid #bdc3c7"
										ml={2}
									>
										{t('Print')}
									</Button>
									<Button
										onClick={onOpen}
										leftIcon={<FontAwesomeIcon icon={faSlidersH} style={{ fontSize: 16 }} />}
										colorScheme={
											number || (receivedDateFrom && receivedDateTo) || (approvedDateFrom && approvedDateTo) || companyId || companyName
												? 'yellow'
												: 'gray'
										}
										variant="solid"
										size="sm"
										borderRadius={c.borderRadius}
										border="1px solid #bdc3c7"
										ml={2}
									>
										{t('Filter')}
									</Button>
								</Flex>
							</Flex>
							<Box p="16px" pt="0">
								{loading && (
									<Flex justifyContent="center" alignItems="center">
										<Text>{t('Loading')}</Text>
									</Flex>
								)}
								{!loading && data?.getAgentPartnerRequests?.list?.length === 0 && (
									<Flex direction="column" alignItems="center" justifyContent="center" pt={16} pl={4} pr={4} pb={16}>
										<FontAwesomeIcon icon={faFileAlt} style={{ fontSize: 56, color: '#A0AEC0' }} />
										<Text fontSize="lg" fontWeight="bold" color="gray.600" mt={6}>
											{t('No Data')}
										</Text>
									</Flex>
								)}
								{!loading && data?.getAgentPartnerRequests?.list?.length > 0 && (
									<>
										<TableContainer>
											<Table variant="simple">
												<Thead>
													<Tr>
														<Th>{t('Number')}</Th>
														<Th>{t('Company')}</Th>
														<Th>{t('Received Request Date')}</Th>
														<Th>{t('No of Agent Requested/Approved')}</Th>
														<Th>{t('No of Contract Reqeusted/Approved')}</Th>
														<Th>{t('Status')}</Th>
													</Tr>
												</Thead>
												<Tbody>
													{data?.getAgentPartnerRequests?.list?.map((e, i) => {
														return (
															<Tr
																_hover={{ bg: 'gray.200' }}
																className="new"
																cursor="pointer"
																key={`e-${i}`}
																onClick={() => history.push(`/manage-agent-partner/partner-request/detail?id=${e?.id}`)}
															>
																<Td>
																	<Text fontWeight="medium">{e?.number}</Text>
																</Td>
																<Td>
																	<Text fontWeight="normal">{lang === 'kh' ? e?.company?.nameKhmer : e?.company?.name}</Text>
																</Td>
																<Td>
																	<Text fontWeight="normal">{toDateString(e?.startedAt, c.dateTimeFormat)}</Text>
																</Td>
																<Td>
																	<CountStatistic v1={e?.numberOfRequestedAgent} v2={e?.numberOfApprovedAgent} />
																</Td>
																<Td>
																	<CountStatistic v1={e?.numberOfSubmittedContract} v2={e?.numberOfApprovedContract} />
																</Td>
																<Td>
																	<PartnerRequestStatus status={e?.status} />
																</Td>
															</Tr>
														);
													})}
												</Tbody>
												<Tfoot>
													<Tr>
														<Th>{t('Number')}</Th>
														<Th>{t('Company')}</Th>
														<Th>{t('Received Request Date')}</Th>
														<Th>{t('No of Agent Requested/Approved')}</Th>
														<Th>{t('No of Contract Reqeusted/Approved')}</Th>
														<Th>{t('Status')}</Th>
													</Tr>
												</Tfoot>
											</Table>
										</TableContainer>
										{data?.getAgentPartnerRequests?.totalPages > 1 && (
											<Flex className="hidden-print" justifyContent="flex-end" w="100%" mt={10}>
												<ReactPaginate
													previousLabel={<ChevronLeftIcon fontSize="20px" />}
													nextLabel={<ChevronRightIcon fontSize="20px" />}
													breakLabel={'...'}
													breakClassName={'break-me'}
													disableInitialCallback={true}
													pageCount={data?.getAgentPartnerRequests?.totalPages}
													marginPagesDisplayed={2}
													pageRangeDisplayed={3}
													onPageChange={handlePageChanged}
													containerClassName={'pagination'}
													activeClassName={'active'}
													initialPage={(currentPage - 1) | 0}
												/>
											</Flex>
										)}
										<Flex className="hidden-print" justifyContent="flex-end" mt="6">
											<Text mr="2">{t('Show more rows')}</Text>
											<Checkbox isChecked={more === 'true'} onChange={handleMoreChanged} />
										</Flex>
									</>
								)}
							</Box>
						</Box>
					</Center>
				</Container>
				<PartnerRequestFilter
					number={number}
					receivedDateFrom={receivedDateFrom}
					receivedDateTo={receivedDateTo}
					approvedDateFrom={approvedDateFrom}
					approvedDateTo={approvedDateTo}
					companyName={companyName}
					companyId={companyId}
					isOpen={isOpen}
					onClose={onClose}
					onCallback={(result) => {
						let url = `?page=1`;

						if (status) {
							url += `&status=${status}`;
						}

						if (result?.number) {
							url += `&number=${result?.number}`;
						}

						if (result?.receivedDateFrom && result?.receivedDateTo) {
							url += `&receivedDateFrom=${result?.receivedDateFrom}&receivedDateTo=${result?.receivedDateTo}`;
						}

						if (result?.approvedDateFrom && result?.approvedDateTo) {
							url += `&approvedDateFrom=${result?.approvedDateFrom}&approvedDateTo=${result?.approvedDateTo}`;
						}

						if (result?.companyName) {
							url += `&companyName=${result?.companyName}`;
						}

						if (result?.companyId) {
							url += `&companyId=${result?.companyId}`;
						}

						if (more !== null) {
							url += `&more=${more}`;
						}

						history.push(`/manage-agent-partner/partner-request${url}`);
					}}
				/>
			</>
		</PrintLayout>
	);
}

export default PartnerRequest;
