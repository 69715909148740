import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
    Container, Box, Flex, Text, Center,
    Badge, Menu, MenuButton, MenuList, MenuItem,
    useToast,
    Wrap,
    WrapItem,
    Avatar,
    Button,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
    Tooltip
} from "@chakra-ui/react";
import BreadcrumbComponent from "../../components/BreadcrumbComponent/BreadcrumbComponent";
import { faPrint, faPlusSquare, faMinusSquare, faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import c from "../../constant"
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import moment from "moment"
import './style.css'
import { useLocation } from "react-router-dom";
import ViewComment from "../../components/Comment/ViewComment"
import useStore from "../../store";
import { WarningIcon, RepeatIcon } from '@chakra-ui/icons'
import PrintLayout from "../../components/Print/PrintLayout";
import SelectUserModal from "../../components/Modal/SelectUserModal"
import DownloadAndViewButton from "../../components/Files/DownloadAndViewButton";
import UserDislayNameById from "../../components/UserDislayNameById"


const leftLabelWidth = "120px"
const QUERY_REPORT_DETAIL = gql`
query get($id: String!){
    getCompanySubmissionReportById(id:$id){
        id
        companyName
        createdAt
        fileSize
        file
        type
        reportType
        spam
        company{
            id
            name
            nameKhmer
            logo
        }
        notification{
            read
        }
        assignTo{
            read
            user{
              id
              username
              fullName
              fullNameKhmer
              avatar
            }
            createdBy
            createdAt
        }
        newTypeDetail{
            titleKh
            title
        }
        createdByClientId
    }
  }
`


const QUERY_SET_AS_SPAM_REPORT = gql`
    mutation setReportByIdAsSpam($id:String!){
        setReportByIdAsSpam(
            id:$id
        )
    }
`

const QUERY_SET_AS_NOT_SPAM_REPORT = gql`
    mutation setReportByIdAsNotSpam($id:String!){
        setReportByIdAsNotSpam(
            id:$id
        )
    }
`


const QUERY_REMOVE_USER = gql`
    mutation remove($id:String!,$userId:String!){
        removeAssignedCompanySubmissionReportFromUser(
        id:$id
        userId:$userId
        )
    }
`

const QUERY_ASSIGN_USER = gql`
    mutation assign($id:String!,$userId:String!){
        assignCompanySubmissionReportToUser(
            id:$id
            userId:$userId
        )
    }
`


function ReportDetail() {
    const { t } = useTranslation();
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const id = urlParams.get('id') ? urlParams.get('id') : null;
    const { currentLang } = useStore((state) => state.currentLang);
    moment.locale(currentLang === "kh" ? "km" : "en-gb")
    const [loadReportDetail, { error, loading, data }] = useLazyQuery(QUERY_REPORT_DETAIL)
    const { currentUser } = useStore((state) => state.currentUser);
    const [setAsSpamReport, { error: errorSetAsSpam, loading: loadingSetAsSpam, data: dataSetAsSpam }] = useMutation(QUERY_SET_AS_SPAM_REPORT)
    const [setAsNotSpamReport, { error: errorSetAsNotSpam, loading: loadingSetAsNotSpam, data: dataSetAsNotSpam }] = useMutation(QUERY_SET_AS_NOT_SPAM_REPORT)
    const toast = useToast()
    const { countUnreadNotifications } = useStore((state) => state.countUnreadNotifications);
    const setCountUnreadNotifications = useStore((state) => state.setCountUnreadNotifications);
    const [isOpen, setIsOpen] = useState(false)
    const [removingUserId, setRemovingUserId] = useState(null)
    const [selectUserModalOpen, setSelectUserModalOpen] = useState(false)
    const [removeUserMutation, { error: errorRemove, loading: loadingRemove, data: dataRemove }] = useMutation(QUERY_REMOVE_USER)
    const [assignUserMutation, { error: errorAssign, loading: loadingAssign, data: dataAssign }] = useMutation(QUERY_ASSIGN_USER)
    const cancelRef = useRef()
    const onClose = () => setIsOpen(false)

    useEffect(() => {
        window.scrollTo(0, 0);
        loadReportDetail({
            variables: {
                id
            }
        })
    }, []);

    useEffect(() => {
        if (id) {
            loadReportDetail({
                variables: {
                    id
                }
            })
        }
    }, [id])

    useEffect(() => {
        if (data?.getCompanySubmissionReportById?.notification?.read === false) {
            setCountUnreadNotifications(countUnreadNotifications - 1)
        }
    }, [data])

    function getDisplayName(user) {
        let result = user?.username
        if (currentLang === "kh") {
            if (result = user?.fullNameKhmer) {
                result = user?.fullNameKhmer
            } else {
                if (user?.fullName) {
                    result = user?.fullName
                } else {
                    result = user?.username
                }
            }
        } else {
            if (result = user?.fullName) {
                result = user?.fullName
            } else {
                if (user?.fullNameKhmer) {
                    result = user?.fullNameKhmer
                } else {
                    result = user?.username
                }
            }
        }

        return result
    }

    function reportType(type) {
        switch (type) {
            case "MONTHLY":
                return <Badge colorScheme="green">{t("MONTHLY")}</Badge>
            case "QUARTERLY":
                return <Badge colorScheme="yellow">{t("QUARTERLY")}</Badge>
            case "SEMESTERLY":
                return <Badge colorScheme="purple">{t("SEMESTERLY")}</Badge>
            case "ANNUALLY":
                return <Badge colorScheme="red">{t("ANNUALLY")}</Badge>

            default:
                return null
        }
    }

    function setSpam() {
        setAsSpamReport({
            variables: {
                id
            }
        })
    }

    function setNotSpam() {
        setAsNotSpamReport({
            variables: {
                id
            }
        })
    }

    useEffect(() => {
        if (errorSetAsSpam) {
            toast({
                title: t("Set as spam error"),
                status: "error",
                isClosable: true,
                position: "top-right"
            })
            loadReportDetail({
                variables: {
                    id
                }
            })
        }
    }, [errorSetAsSpam])

    useEffect(() => {
        if (errorSetAsNotSpam) {
            toast({
                title: t("Set as not spam error"),
                status: "error",
                isClosable: true,
                position: "top-right"
            })
            loadReportDetail({
                variables: {
                    id
                }
            })
        }
    }, [errorSetAsNotSpam])

    useEffect(() => {
        if (dataSetAsNotSpam?.setReportByIdAsNotSpam) {
            toast({
                title: t("Set as not spam successfully"),
                status: "success",
                isClosable: true,
                position: "top-right"
            })
            loadReportDetail({
                variables: {
                    id
                }
            })
        }
    }, [dataSetAsNotSpam])

    useEffect(() => {
        if (dataSetAsSpam?.setReportByIdAsSpam) {
            toast({
                title: t("Set as spam successfully"),
                status: "success",
                isClosable: true,
                position: "top-right"
            })
            loadReportDetail({
                variables: {
                    id
                }
            })
        }
    }, [dataSetAsSpam])

    function reportTypeOfReports(type, newTypeDetail) {
        switch (type) {
            case "FINANCIAL_REPORT":
                return (<Badge colorScheme="green">{t("Financial Report")}</Badge>)
            case "COMPLAINT_SETTLEMENT_REPORT":
                return (<Badge colorScheme="yellow">{t("Complaint Settlement Report")}</Badge>)
            case "INSURANCE_AGENT_REPORT":
                return (<Badge colorScheme="purple">{t("Insurance Agent Report")}</Badge>)
            case "NEW_TYPE":
                return (<Badge colorScheme="gray">{currentLang === "kh" ? newTypeDetail?.titleKh : newTypeDetail?.title}</Badge>)
            default:
                break;
        }
    }

    function getDisplayCompanyName(item) {
        let displayName = item?.companyName
        if (item?.company?.id) {
            if (currentLang === "kh") {
                displayName = item?.company?.nameKhmer
            } else {
                displayName = item?.company?.name
            }
        }
        return displayName
    }

    function removeAssignUserDialog(user) {
        setIsOpen(true)
        setRemovingUserId(user)
    }

    function selectAssignUser(userData) {
        assignUserMutation({
            variables: {
                id: id,
                userId: userData.id
            }
        })
    }

    useEffect(() => {
        if (dataAssign?.assignCompanySubmissionReportToUser) {
            toast({
                title: t("User assigned successfully"),
                status: "success",
                isClosable: true,
                position: "top-right"
            })
            loadReportDetail({
                variables: {
                    id
                }
            })
        }
    }, [dataAssign])

    useEffect(() => {
        if (dataRemove?.removeAssignedCompanySubmissionReportFromUser) {
            toast({
                title: t("User removed successfully"),
                status: "success",
                isClosable: true,
                position: "top-right"
            })
            loadReportDetail({
                variables: {
                    id
                }
            })
            onClose()
        }
    }, [dataRemove])

    useEffect(() => {
        if (errorAssign) {
            if (errorAssign?.graphQLErrors?.length > 0) {
                errorAssign?.graphQLErrors.map(({ message }, i) => (
                    toast({
                        title: t(message),
                        status: "error",
                        isClosable: true,
                        position: "top-right"
                    })
                ))
            } else {
                toast({
                    title: t(errorAssign?.message ? errorAssign?.message : "something went wrong"),
                    status: "error",
                    isClosable: true,
                    position: "top-right"
                })
            }
        }
    }, [errorAssign])

    return (
        <>
            <PrintLayout>
                <>
                    <Container maxW="container.xl" mt="16px" mb="16px">
                        <BreadcrumbComponent
                            list={[
                                {
                                    name: "COMPANY REPORT",
                                    path: "/company-submission-report?page=1",
                                },
                                {
                                    name: "DETAIL",
                                    path: "#",
                                },
                            ]}
                        />
                        <Center mt="5">
                            <Box
                                w="100%"
                                bg="white"
                                borderRadius={c.borderRadius}
                                border="1px solid #dbdbdb"
                                boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
                                pb="32px"
                            >
                                <Flex alignItems="center" justifyContent="space-between" bg="#FAFAFA" p="16px" pt="8px" pb="8px" mb="16px" borderBottom="1px solid #dbdbdb">
                                    <Flex justifyContent="center" alignItems="center">
                                        <Text
                                            fontSize="x-large"
                                            fontWeight="bold"
                                            mr="4"
                                        >
                                            {t("Company Report Detail")}
                                        </Text>
                                        {data?.getCompanySubmissionReportById?.type && reportType(data?.getCompanySubmissionReportById?.type)}
                                        {data?.getCompanySubmissionReportById?.spam === true && <Badge ml="4" colorScheme="red">{t("Spam")}</Badge>}
                                    </Flex>
                                    <Flex className="hidden-print">
                                        <Button
                                            mr="2"
                                            onClick={() => window?.print()}
                                            leftIcon={<FontAwesomeIcon icon={faPrint} style={{ fontSize: 16 }} />}
                                            colorScheme='gray'
                                            variant='solid'
                                            size="sm"
                                            borderRadius={c.borderRadius}
                                            border="1px solid #bdc3c7"
                                        >
                                            {t("Print")}
                                        </Button>
                                        {currentUser?.role === "admin" &&
                                            <>
                                                {(!error && data?.getCompanySubmissionReportById?.id) && <Menu>
                                                    <MenuButton cursor="pointer">
                                                        <Button
                                                            colorScheme='gray'
                                                            variant='solid'
                                                            size="sm"
                                                            borderRadius={c.borderRadius}
                                                            border="1px solid #bdc3c7"
                                                        >
                                                            <FontAwesomeIcon icon={faEllipsisH} style={{ fontSize: 16 }} />
                                                        </Button>
                                                    </MenuButton>
                                                    <MenuList pt="4" pb="4" pl="3" pr="3" borderRadius={c.borderRadius}>

                                                        {data?.getCompanySubmissionReportById?.spam === false &&
                                                            <MenuItem icon={<WarningIcon />} onClick={() => setSpam()}>{t("Spam")}</MenuItem>
                                                        }
                                                        {data?.getCompanySubmissionReportById?.spam === true &&
                                                            <MenuItem icon={<RepeatIcon />} onClick={() => setNotSpam()}>{t("Not Spam")}</MenuItem>
                                                        }

                                                    </MenuList>
                                                </Menu>}
                                            </>}
                                    </Flex>
                                </Flex>

                                {loading && <Text ml="8">{t("Loading")}...</Text>}

                                {(!error && data?.getCompanySubmissionReportById?.id) &&
                                    <Box p="16px">
                                        <Flex>
                                            <Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>{t("Company Name")}</Text>
                                            <Text ml="4">{getDisplayCompanyName(data?.getCompanySubmissionReportById)}</Text>
                                        </Flex>
                                        <Flex mt="2">
                                            <Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>{t("Report Type")}</Text>
                                            <Text ml="4">{reportTypeOfReports(data?.getCompanySubmissionReportById?.reportType, data?.getCompanySubmissionReportById?.newTypeDetail)}</Text>
                                        </Flex>

                                        <Flex mt="2">
                                            <Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>{t("Create At")}</Text>
                                            <Text ml="4">
                                                {moment(data?.getCompanySubmissionReportById?.createdAt).format("LLLL")}
                                            </Text>
                                        </Flex>


                                        {data?.getCompanySubmissionReportById?.file && <Flex alignItems="center" mt="2">
                                            <Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>{t("File")}</Text>
                                            <DownloadAndViewButton url={data?.getCompanySubmissionReportById?.file} size={data?.getCompanySubmissionReportById?.fileSize} />
                                        </Flex>}

                                        <Flex alignItems="center" mt="2">
                                            <Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>{t("Assign to")}:</Text>
                                            <Wrap ml="4">
                                                {
                                                    data?.getCompanySubmissionReportById?.assignTo?.map((aTo, index) => {
                                                        return (
                                                            <Flex direction="column" justifyContent="center" alignItems="center" key={'u-' + index}>
                                                                <Tooltip label={
                                                                    <>
                                                                        {t("Assign By")}&nbsp;<UserDislayNameById id={aTo?.createdBy} />&nbsp;{", "}&nbsp;
                                                                        {moment(aTo.createdAt).format("LLLL")}
                                                                    </>
                                                                }>
                                                                    <WrapItem className="avatarWraper">
                                                                        {(currentUser?.role === "admin" || (
                                                                            currentUser?.canAssignUserToCompanyReport === true &&
                                                                            currentUser.id === aTo.createdBy
                                                                        )) && <FontAwesomeIcon onClick={() => removeAssignUserDialog(aTo?.user)} className="remove" icon={faMinusSquare} style={{ fontSize: 18, display: "none", position: "absolute", zIndex: "100", marginLeft: 32, color: "red", backgroundColor: "white", padding: 2, cursor: "pointer" }} />}
                                                                        <Avatar name={getDisplayName(aTo?.user)} src={aTo?.user?.avatar?.icon ? (c.baseFilePath + aTo?.user?.avatar?.icon) : null} />
                                                                    </WrapItem>
                                                                </Tooltip>
                                                                <Text textAlign="center">{getDisplayName(aTo.user)}</Text>
                                                            </Flex>
                                                        )
                                                    })
                                                }
                                                {(currentUser?.role === "admin" || currentUser?.canAssignUserToCompanyReport === true) && <Flex>
                                                    <Button className="hidden-print" onClick={() => setSelectUserModalOpen(true)} mt={1} leftIcon={<FontAwesomeIcon icon={faPlusSquare} style={{ fontSize: 18 }} />} colorScheme='blue' variant='outline'>
                                                        {t("Add")}
                                                    </Button>
                                                </Flex>}
                                            </Wrap>
                                        </Flex>
                                    </Box>
                                }
                            </Box>
                        </Center>
                        {
                            (!error && data?.getCompanySubmissionReportById?.id) &&
                            <ViewComment id={id} type="COMPANY_SUBMISSION_REPORT" />
                        }
                    </Container>
                </>
            </PrintLayout>
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            {t("Remove Assigned User")}
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            {t(`Are you sure you want to remove`)} "{getDisplayName(removingUserId)}"?
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                {t("Cancel")}
                            </Button>
                            <Button isLoading={loadingRemove} colorScheme='red' onClick={() => {
                                removeUserMutation({
                                    variables: {
                                        id: id,
                                        userId: removingUserId?.id
                                    }
                                })
                            }} ml={3}>
                                {t("Remove")}
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
            {selectUserModalOpen &&
                <SelectUserModal 
                    role="companySubmissionReport" 
                    callback={selectAssignUser} 
                    isOpen={selectUserModalOpen} 
                    onClose={() => setSelectUserModalOpen(false)} 
                    departments={
                        currentUser?.role!=="admin"?(
                            currentUser?.position>1? currentUser?.department: null
                        ):null
                    }
                    offices={
                        currentUser?.position>4? currentUser?.office: null
                    }
                />
            }
        </>
    );
}

export default ReportDetail;
