/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Container,
	Box,
	Flex,
	Text,
	Center,
	Button,
	Drawer,
	DrawerHeader,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
	useDisclosure,
	Checkbox,
} from '@chakra-ui/react';
import { useLocation, useHistory } from 'react-router-dom';
import { faSlidersH, faRetweet, faFileExcel, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import BreadcrumbComponent from '../../components/BreadcrumbComponent/BreadcrumbComponent';
import PaymentList from './paymentList';
import LicenseOrFineList from './licenseOrFineList';
import FilterAccordion from './filterAccordion';
import PaymentFilter from '../../components/Filter/PaymentFilter';
import ExcelExportModal from '../../components/Modal/ExcelExportModal';
import RecheckTransactionModal from '../../components/Modal/RecheckTransactionModal';
import useStore from '../../store';
import c from '../../constant';
import './style.css';

function Revenue() {
	const { t } = useTranslation();
	const paymentRef = useRef(null);
	const licenseOrFineRef = useRef(null);
	const history = useHistory();
	const location = useLocation();
	const urlParams = new URLSearchParams(location.search);
	const currentPage = urlParams.get('page') ? urlParams.get('page') : 1;
	const trxId = urlParams.get('trxId') ? urlParams.get('trxId') : null;
	const lastName = urlParams.get('lastName') ? urlParams.get('lastName') : null;
	const firstName = urlParams.get('firstName') ? urlParams.get('firstName') : null;
	const email = urlParams.get('email') ? urlParams.get('email') : null;
	const phone = urlParams.get('phone') ? urlParams.get('phone') : null;
	const paymentStatus = urlParams.get('paymentStatus') ? urlParams.get('paymentStatus') : null;
	const fromDate = urlParams.get('fromDate') ? urlParams.get('fromDate') : null;
	const toDate = urlParams.get('toDate') ? urlParams.get('toDate') : null;
	const fromApprovedDate = urlParams.get('fromApprovedDate') ? urlParams.get('fromApprovedDate') : null;
	const toApprovedDate = urlParams.get('toApprovedDate') ? urlParams.get('toApprovedDate') : null;
	const fromTransactionDate = urlParams.get('fromTransactionDate') ? urlParams.get('fromTransactionDate') : null;
	const toTransactionDate = urlParams.get('toTransactionDate') ? urlParams.get('toTransactionDate') : null;
	const pageType = urlParams.get('pageType') ? urlParams.get('pageType') : '';
	const companyName = urlParams.get('companyName') ? urlParams.get('companyName') : '';
	const selectedCompanyId = urlParams.get('selectedCompanyId') ? urlParams.get('selectedCompanyId') : '';
	const proposalNumber =
		!pageType || pageType === 'public-service' || pageType === 'all' ? (urlParams.get('proposalNumber') ? urlParams.get('proposalNumber') : '') : '';
	const { currentUser: user } = useStore((state) => state.currentUser);
	const { isOpen: isOpenDrawer, onOpen: onOpenDrawer, onClose: onCloseDrawer } = useDisclosure();
	const [rowLimit, setRowLimit] = useState(10);
	const [recheckData, setRecheckData] = useState(null);
	const [excelData, setExcelData] = useState(null);

	useEffect(() => {
		if (!pageType) {
			if (user?.paymentAgentRegistration && user?.paymentPublicService) {
				handleTabChanged('all');
			} else if (user?.paymentAgentRegistration) {
				handleTabChanged('agent');
			} else if (user?.paymentPublicService) {
				handleTabChanged('public-service');
			} else if (user?.paymentLicense) {
				handleTabChanged('license-fee');
			} else if (user?.paymentFine) {
				handleTabChanged('Fine');
			}
		}
	}, [user, pageType]);

	function handlePageChanged(i) {
		let url = `?page=${i + 1}`;
		if (pageType) {
			url += `&pageType=${pageType}`;
			if (pageType === 'public-service' || pageType === 'all') {
				if (proposalNumber) {
					url += `&proposalNumber=${proposalNumber}`;
				}
			}
		} else {
			if (proposalNumber) {
				url += `&proposalNumber=${proposalNumber}`;
			}
		}
		if (trxId) {
			url += `&trxId=${trxId}`;
		}
		if (fromDate) {
			url += `&fromDate=${fromDate}`;
		}
		if (toDate) {
			url += `&toDate=${toDate}`;
		}
		if (fromApprovedDate) {
			url += `&fromApprovedDate=${fromApprovedDate}`;
		}
		if (toApprovedDate) {
			url += `&toApprovedDate=${toApprovedDate}`;
		}
		if (fromTransactionDate) {
			url += `&fromTransactionDate=${fromTransactionDate}`;
		}
		if (toTransactionDate) {
			url += `&toTransactionDate=${toTransactionDate}`;
		}
		if (lastName) {
			url += `&lastName=${lastName}`;
		}
		if (firstName) {
			url += `&firstName=${firstName}`;
		}
		if (email) {
			url += `&email=${email}`;
		}
		if (phone) {
			url += `&phone=${phone}`;
		}
		if (paymentStatus) {
			url += `&paymentStatus=${paymentStatus}`;
		}
		if (companyName) {
			url += `&companyName=${companyName}`;
		}
		if (selectedCompanyId) {
			url += `&selectedCompanyId=${selectedCompanyId}`;
		}
		history.push(`/payment${url}`);
	}

	function handleTabChanged(type) {
		let url = `?page=${1}`;
		if (type) {
			url += `&pageType=${type}`;
			if (type === 'public-service' || type === 'all') {
				if (proposalNumber) {
					url += `&proposalNumber=${proposalNumber}`;
				}
			}
		} else {
			if (proposalNumber) {
				url += `&proposalNumber=${proposalNumber}`;
			}
		}
		if (trxId) {
			url += `&trxId=${trxId}`;
		}
		if (fromDate) {
			url += `&fromDate=${fromDate}`;
		}
		if (toDate) {
			url += `&toDate=${toDate}`;
		}
		if (fromApprovedDate) {
			url += `&fromApprovedDate=${fromApprovedDate}`;
		}
		if (toApprovedDate) {
			url += `&toApprovedDate=${toApprovedDate}`;
		}
		if (fromTransactionDate) {
			url += `&fromTransactionDate=${fromTransactionDate}`;
		}
		if (toTransactionDate) {
			url += `&toTransactionDate=${toTransactionDate}`;
		}
		if (lastName) {
			url += `&lastName=${lastName}`;
		}
		if (firstName) {
			url += `&firstName=${firstName}`;
		}
		if (email) {
			url += `&email=${email}`;
		}
		if (phone) {
			url += `&phone=${phone}`;
		}
		if (paymentStatus) {
			url += `&paymentStatus=${paymentStatus}`;
		}
		if (companyName) {
			url += `&companyName=${companyName}`;
		}
		if (selectedCompanyId) {
			url += `&selectedCompanyId=${selectedCompanyId}`;
		}
		history.push(`/payment${url}`);
	}

	function downloadExcell() {
		if (!pageType || (pageType !== 'license-fee' && pageType !== 'Fine')) {
			setExcelData(paymentRef.current.generateExcel());
		} else {
			setExcelData(licenseOrFineRef.current.generateExcel());
		}
	}

	function recheckTransaction() {
		setRecheckData(paymentRef.current.payments);
	}

	function panelTab({ name, value, disabled = false }) {
		let active = pageType ? pageType : 'all';
		let tmpDisable = user?.role === 'admin' ? false : disabled;
		return (
			<Flex
				userSelect="none"
				minW="100px"
				onClick={() => {
					if (!tmpDisable) {
						handleTabChanged(value);
					}
				}}
				cursor={tmpDisable ? 'not-allowed' : 'pointer'}
				mb={'-2px'}
				_hover={{ boxShadow: `inset 0 -2px 0 0 ${value === active ? '#2980b9' : '#bdc3c7'}` }}
				boxShadow={value === active ? 'inset 0 -2px 0 0 #2980b9' : ''}
				pt="16px"
				pb="16px"
				pl="12px"
				pr="12px"
				alignItems="center"
				justifyContent="center"
			>
				<Text>{t(name)}</Text>
			</Flex>
		);
	}

	return (
		<>
			<Container maxW="container.xl" mt="16px" mb="16px">
				<BreadcrumbComponent
					list={[
						{
							name: 'Revenue',
							path: '#',
						},
					]}
				/>
				<Center mt="5">
					<Box
						w="100%"
						bg="white"
						borderRadius={c.borderRadius}
						border="1px solid #dbdbdb"
						boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
						pb="32px"
					>
						<Flex
							className="hidden-print"
							bg="#FAFAFA"
							p="16px"
							pt="0"
							pb="0"
							mb="16px"
							alignItems="center"
							justifyContent="space-between"
							borderBottom="1px solid #dbdbdb"
						>
							<Flex pb="2px">
								{panelTab({
									name: 'All',
									value: 'all',
									disabled: !(user?.paymentAgentRegistration && user?.paymentPublicService),
								})}
								{panelTab({
									name: 'Agent Registration Fee',
									value: 'agent',
									disabled: !user?.paymentAgentRegistration,
								})}
								{panelTab({
									name: 'Public Service Fee',
									value: 'public-service',
									disabled: !user?.paymentPublicService,
								})}
								{panelTab({ name: 'License Fee', value: 'license-fee', disabled: !user?.paymentLicense })}
								{panelTab({ name: 'Fine', value: 'Fine', disabled: !user?.paymentFine })}
							</Flex>
							<Flex alignItems="center">
								<Button
									mr={2}
									onClick={recheckTransaction}
									leftIcon={<FontAwesomeIcon icon={faRetweet} style={{ fontSize: 16 }} />}
									colorScheme="gray"
									variant="solid"
									size="sm"
									borderRadius={c.borderRadius}
									border="1px solid #bdc3c7"
									hidden={!(!pageType || (pageType !== 'license-fee' && pageType !== 'Fine'))}
								>
									{t('Recheck Transaction')}
								</Button>
								<Button
									mr={2}
									leftIcon={<FontAwesomeIcon icon={faFileExcel} style={{ fontSize: 16 }} />}
									variant="solid"
									size="sm"
									borderRadius={c.borderRadius}
									border="1px solid #bdc3c7}"
									onClick={downloadExcell}
								>
									{t('Export')}
								</Button>
								{(pageType === 'license-fee' || pageType === 'Fine') &&
									((user?.canUpdateLicense && pageType === 'license-fee') ||
										(user?.canUpdateFine && pageType === 'Fine') ||
										user?.role === 'admin') && (
										<Link to={`/payment/add?Type=${pageType}`}>
											<Button
												mr="2"
												leftIcon={<FontAwesomeIcon icon={faPlus} style={{ fontSize: 16 }} />}
												variant="solid"
												size="sm"
												borderRadius={c.borderRadius}
												border="1px solid #bdc3c7}"
											>
												{t('Add')}
											</Button>
										</Link>
									)}
								<Button
									onClick={onOpenDrawer}
									leftIcon={<FontAwesomeIcon icon={faSlidersH} style={{ fontSize: 16 }} />}
									variant="solid"
									size="sm"
									borderRadius={c.borderRadius}
									border="1px solid #bdc3c7}"
									colorScheme={
										trxId ||
										lastName ||
										firstName ||
										email ||
										phone ||
										paymentStatus ||
										fromDate ||
										toDate ||
										fromApprovedDate ||
										toApprovedDate ||
										fromTransactionDate ||
										toTransactionDate ||
										companyName ||
										selectedCompanyId ||
										proposalNumber
											? 'yellow'
											: 'gray'
									}
								>
									{t('Filter')}
								</Button>
							</Flex>
						</Flex>
						<Box p="16px">
							{!pageType || (pageType !== 'license-fee' && pageType !== 'Fine') ? (
								<PaymentList
									ref={paymentRef}
									page={currentPage}
									limit={rowLimit}
									trxId={trxId}
									lastName={lastName}
									firstName={firstName}
									email={email}
									phone={phone}
									paymentStatus={paymentStatus}
									fromDate={fromDate}
									toDate={toDate}
									fromApprovedDate={fromApprovedDate}
									toApprovedDate={toApprovedDate}
									pageType={pageType}
									companyName={companyName}
									selectedCompanyId={selectedCompanyId}
									proposalNumber={proposalNumber}
									onPageChanged={(i) => handlePageChanged(i)}
								/>
							) : (
								<LicenseOrFineList
									ref={licenseOrFineRef}
									pageType={pageType}
									page={currentPage}
									limit={rowLimit}
									selectedCompanyId={selectedCompanyId}
									fromDate={fromDate}
									toDate={toDate}
									fromTransactionDate={fromTransactionDate}
									toTransactionDate={toTransactionDate}
									trxId={trxId}
									deleted={false}
									onPageChanged={(i) => handlePageChanged(i)}
								/>
							)}
							<Flex className="hidden-print" justifyContent="flex-end" mt={6}>
								<Text mr={2}>{t('Show more rows')}</Text>
								<Checkbox
									isChecked={rowLimit > 10}
									onChange={() => {
										handlePageChanged(0);
										if (rowLimit > 10) {
											setRowLimit(10);
										} else {
											setRowLimit(2000);
										}
									}}
								/>
							</Flex>
							<FilterAccordion
								trxId={trxId}
								lastName={lastName}
								firstName={firstName}
								email={email}
								phone={phone}
								paymentStatus={paymentStatus}
								fromDate={fromDate}
								toDate={toDate}
								fromApprovedDate={fromApprovedDate}
								toApprovedDate={toApprovedDate}
								fromTransactionDate={fromTransactionDate}
								toTransactionDate={toTransactionDate}
								companyName={companyName}
								selectedCompanyId={selectedCompanyId}
								proposalNumber={proposalNumber}
							/>
						</Box>
					</Box>
				</Center>
			</Container>
			<Drawer isOpen={isOpenDrawer} onClose={onCloseDrawer} size="md" placement="right">
				<DrawerOverlay />
				<DrawerContent>
					<DrawerCloseButton />
					<DrawerHeader>{t('Filter')}</DrawerHeader>
					<PaymentFilter
						onClose={onCloseDrawer}
						callback={(e) => {
							onCloseDrawer();
							history.push(`/payment${e}`);
						}}
						fromDate={fromDate}
						toDate={toDate}
						fromApprovedDate={fromApprovedDate}
						toApprovedDate={toApprovedDate}
						fromTransactionDate={fromTransactionDate}
						toTransactionDate={toTransactionDate}
						trxId={trxId}
						lastName={lastName}
						firstName={firstName}
						email={email}
						phone={phone}
						paymentStatus={paymentStatus}
						pageType={pageType}
						companyName={companyName}
						selectedCompanyId={selectedCompanyId}
						proposalNumber={proposalNumber}
					/>
				</DrawerContent>
			</Drawer>
			{recheckData && <RecheckTransactionModal onClose={() => setRecheckData(null)} data={recheckData} />}
			{excelData && <ExcelExportModal title="Payment" isOpen={true} onClose={() => setExcelData(null)} data={excelData} />}
		</>
	);
}

export default Revenue;
