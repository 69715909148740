import React, { useEffect, useState } from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Box,
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    Text,
    TableContainer,
    Badge,
    Image,
    Flex
} from "@chakra-ui/react";
import c from "../../constant"
import './style.css'
import { useTranslation } from "react-i18next";
import RecheckAndGetUpdateStatusOfTransaction from "../Other/RecheckAndGetUpdateStatusOfTransaction";
import useStore from "../../store";
import PlaceHolderLogo from "../../assets/images/logo-placeholder.png"

function RecheckTransactionModal({
    onClose = () => null, data = null
}) {
    const { t } = useTranslation();
    const { currentLang } = useStore((state) => state.currentLang);

    const [currentIndex, setCurrentIndex] = useState(0)

    function getDisplayCompanyName(company) {
        let displayName = ""
        if (company?.id) {
            if (currentLang === "kh") {
                displayName = company?.nameKhmer
            } else {
                displayName = company?.name
            }
        }
        return displayName
    }

    function getStatusBadge(type){
        switch (type) {
            case "PENDING":
                return <Badge colorScheme="yellow">{t("PENDING")}</Badge>
            case "APPROVED":
                return <Badge colorScheme="green">{t("APPROVED")}</Badge>
            case "EXPIRED":
                return <Badge colorScheme="gray">{t("EXPIRED")}</Badge>
            case "DECLINED":
                return <Badge colorScheme="gray">{t("DECLINED")}</Badge>
            case "REFUNDED":
                return <Badge colorScheme="purple">{t("REFUNDED")}</Badge>
            case "ERROR":
                return <Badge colorScheme="red">{t("ERROR")}</Badge>
            default:
                return null;
        }
    }

    function onNext(){
        setCurrentIndex(currentIndex+1)
    }
    
    return (
        <Modal
            isOpen={true}
            onClose={() => {
                onClose()
            }}
            size="5xl"
        >
            <ModalOverlay />
            <ModalContent pb="4" borderRadius={c.borderRadius}>
                <ModalHeader>{t("Recheck Transaction")}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Box w="100%" pt="16px" pb="16px">
                    <TableContainer>
                        <Table size='sm'>
                            <Thead>
                                <Tr>
                                    <Th>{t("TRX. ID")}</Th>
                                    <Th>{t("Created By")}</Th>
                                    <Th isNumeric>{t("Status")}</Th>
                                    <Th isNumeric>{t("To Status")}</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {
                                    data?.length<=0&&<Text textAlign="center" m="4">{t("No Data")}</Text>
                                }
                            {
                                data?.map((item,index)=>{
                                    return(
                                        <Tr key={`tr-${index}`}>
                                            <Td>{item?.tranId}</Td>
                                            <Td>
                                                <Flex alignItems="center">
                                                    <Text noOfLines={1}>
                                                        {item?.lastName}
                                                        &nbsp;
                                                        {item?.firstName}
                                                    </Text>
                                                    {item?.company?.logo&&<>
                                                        <Image
                                                            w="auto"
                                                            h="24px"
                                                            maxW="194px"
                                                            src={item?.company?.logo ? (c.baseFilePath + (item?.company?.logo ? item?.company?.logo : null)) : ""}
                                                            objectFit="contain"
                                                            fallbackSrc={PlaceHolderLogo}
                                                            mr="2"
                                                        />
                                                    </>}
                                                    <Text noOfLines={1}>
                                                        {getDisplayCompanyName(item?.company)}
                                                    </Text>
                                                </Flex>    
                                            </Td>
                                            <Td isNumeric>{getStatusBadge(item?.status)}</Td>
                                            <Td isNumeric>{currentIndex>=index?
                                                <RecheckAndGetUpdateStatusOfTransaction tranId={item?.tranId} id={item?.id}  onNext={()=>onNext()} />
                                                :null}</Td>
                                        </Tr>
                                    )
                                })
                            }
                            
                            
                            </Tbody>
                            <Tfoot>
                                <Tr>
                                    <Th>{t("TRX. ID")}</Th>
                                    <Th>{t("Created By")}</Th>
                                    <Th isNumeric>{t("Status")}</Th>
                                    <Th isNumeric>{t("To Status")}</Th>
                                </Tr>
                            </Tfoot>
                        </Table>
                    </TableContainer>
                    </Box>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

export default RecheckTransactionModal;
