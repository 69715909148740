import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
// import checkLogin from "../../utils/checkLogin";
import Footer from "./Footer";
// import Header from "./Header";
import { checkLogin } from "../utils"
import {
  Box
} from "@chakra-ui/react";

function LayoutPublic(props) {
//   const isLogin = checkLogin();
  const isLogin = checkLogin()
  const history = useHistory();

  useEffect(() => {
    if (isLogin) {
      history.push("/");
    }
  }, []);

  return (
    <>
    {/* <Header /> */}
    <Box minH="calc(100vh - 80px)">
      {props?.children ? props?.children : null}
    </Box>
    <Footer />
    </>
  );
}

export default LayoutPublic;
