/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faFileAlt, faPrint } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { Badge, Box, Button, Center, Container, Flex, SimpleGrid, Text, Tooltip } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useLocation, Link } from 'react-router-dom';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { useTerminationRequest } from '../../../contexts/termination-request';
import { TerminationRequestStatus as Status, GradeApproval, TerminationRequestLocaleKeyValue } from './constands';
import LoadingOverlayText from '../../../components/LoadingOverlay/LoadingOverlayText';
import PrintLayout from '../../../components/Print/PrintLayout';
import BreadcrumbComponent from '../../../components/BreadcrumbComponent/BreadcrumbComponent';
import TerminationRequestStatus from '../../../components/ManageAgentPartner/TerminationRequest/Status/TerminationRequestStatus';
import TerminationRequestCandidateStatus from '../../../components/ManageAgentPartner/TerminationRequest/Status/TerminationRequestCandidateStatus';
import TerminationRequestDescription from '../../../components/ManageAgentPartner/TerminationRequest/Status/TerminationRequestDescription';
import TerminationRequestCandidateStatusDescription from '../../../components/ManageAgentPartner/TerminationRequest/Status/TerminationRequestCandidateStatusDescription';
import TerminationRequestCandidatesApproval from '../../../components/ManageAgentPartner/TerminationRequest/Approval/TerminationRequestCandidatesApproval';
import CardName from '../../../components/CompanyProposal/AdditionalFeature/CardName';
import CardDate from '../../../components/CompanyProposal/AdditionalFeature/CardDate';
import CardLabelValue from '../../../components/CompanyProposal/AdditionalFeature/CardLabelValue';
import EventLoggingButton from '../../../components/ManageAgentPartner/Modal/EventLoggingButton';
import useStore from '../../../store';
import useMoment from '../../../hooks/useMoment';
import c from '../../../constant';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import './style.css';

function TerminationRequestDetail() {
	const history = useHistory();
	const location = useLocation();
	const urlParams = new URLSearchParams(location.search);
	const id = urlParams.get('id') ? urlParams.get('id') : null;
	const { toDateString } = useMoment();
	const { loadRequest, loadingRequest, loadedData } = useTerminationRequest();
	const { currentLang: lang } = useStore((state) => state.currentLang);
	const { currentUser: user } = useStore((state) => state.currentUser);
	const { t } = useTranslation();

	useEffect(() => {
		loadRequest(id);
		window.scrollTo(0, 0);
	}, [id]);

	function Number({ number = null }) {
		return (
			<Text fontSize="sm" fontWeight="semibold">
				{number}
			</Text>
		);
	}

	function Company({ company = null }) {
		return <Text fontSize="sm">{lang === 'kh' ? company?.nameKhmer : company?.name}</Text>;
	}

	function Reason({ reason = null }) {
		return (
			<Tooltip label={t(TerminationRequestLocaleKeyValue[reason])}>
				<Badge colorScheme="gray" variant="outline">
					{t(TerminationRequestLocaleKeyValue[reason])}
				</Badge>
			</Tooltip>
		);
	}

	return (
		<>
			<PrintLayout>
				<Container maxW="container.xl" mt="16px" mb="16px">
					<BreadcrumbComponent
						list={[
							{
								name: 'MANAGE AGENT PARTNER',
								path: '/manage-agent-partner',
							},
							{
								name: 'AGENT TERMINATION REQUEST',
								path: '/manage-agent-partner/termination-request',
							},
							{
								name: 'DETAIL',
								path: '#',
							},
						]}
					/>
					<Center mt="5">
						<Box
							w="100%"
							bg="white"
							borderRadius={c.borderRadius}
							border="1px solid #dbdbdb"
							boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
							pb="32px"
						>
							<Flex
								className="hidden-print responsive-header-for-table"
								bg="#FAFAFA"
								p="16px"
								pt="8px"
								pb="8px"
								mb="16px"
								alignItems="center"
								justifyContent="space-between"
								borderBottom="1px solid #dbdbdb"
							>
								<Text fontSize="x-large" fontWeight="bold">
									{t('Detail')}
								</Text>
								<Flex>
									<>
										<Button
											onClick={() => window?.print()}
											leftIcon={<FontAwesomeIcon icon={faPrint} style={{ fontSize: 16 }} />}
											colorScheme="gray"
											variant="solid"
											size="sm"
											borderRadius={c.borderRadius}
											border="1px solid #bdc3c7"
											ml={2}
										>
											{t('Print')}
										</Button>
									</>
									<>
										<EventLoggingButton label="Logs" typeObjectId={id} />
									</>
									{loadedData?.status === Status.reviewing && (
										<>
											{c.stepOfficeRoles?.includes(user?.position) && (
												<>
													<TerminationRequestCandidatesApproval
														id={id}
														grade={GradeApproval.primary}
														candidates={loadedData?.candidates}
													/>
												</>
											)}
										</>
									)}
									{loadedData?.status === Status.reviewingFinal && (
										<>
											{c.stepDepartmentRoles?.includes(user?.position) && (
												<>
													<TerminationRequestCandidatesApproval
														id={id}
														grade={GradeApproval.secondary}
														candidates={loadedData?.candidates}
													/>
												</>
											)}
										</>
									)}
								</Flex>
							</Flex>
							{!loadingRequest && loadedData?.id && (
								<Box p="16px" pt="0">
									<Flex w="100%" bg="white" border="1px dotted #dbdbdb" borderRadius={c.borderRadius} pl={4} pr={4} pt={4} pb={4} mb={2}>
										<SimpleGrid w="100%" columns={[1, null, 2]} spacing={4}>
											<Flex direction="column">
												<CardLabelValue width="260px" label={'Number'} value={<Number number={loadedData?.number} />} />
												<CardLabelValue width="260px" label={'Company'} value={<Company company={loadedData?.company} />} />
												<CardLabelValue
													width="260px"
													label={'Received Request Date'}
													value={<CardDate input={loadedData?.createdAt} />}
												/>
												<CardLabelValue
													width="260px"
													label={'Requested Agents'}
													value={<Number number={loadedData?.numberOfRequestedAgent} />}
												/>
												<CardLabelValue
													width="260px"
													label={'Approved Agents'}
													value={<Number number={loadedData?.numberOfApprovedAgent} />}
												/>
												<CardLabelValue
													width="260px"
													label={'Status'}
													value={<TerminationRequestStatus status={loadedData?.status} />}
												/>
											</Flex>
											<Flex direction="column">
												<CardLabelValue
													width="260px"
													label={'approved at'}
													value={<CardDate input={loadedData?.terminationsOfficeApprovedAt} />}
												/>
												<CardLabelValue
													width="260px"
													label={'Approved By'}
													value={<CardName id={loadedData?.terminationsOfficeApprovedBy} />}
												/>
												<CardLabelValue width="260px" label={'Last Update'} value={<CardDate input={loadedData?.updatedAt} />} />
												<CardLabelValue width="260px" label={'Request Done At'} value={<CardDate input={loadedData?.completedAt} />} />
											</Flex>
										</SimpleGrid>
									</Flex>
									<Flex w="100%" bg="white">
										<Flex
											flex={1}
											direction="column"
											border="1px dotted #dbdbdb"
											borderRadius={c.borderRadius}
											pl={4}
											pr={4}
											pt={4}
											pb={4}
											mt={2}
										>
											<Table variant="simple" className="table-company-proposal-list responsive-table-container">
												<Thead>
													<Tr className="td-as-th">
														<Th align="left">{t('Code')}</Th>
														<Th align="left">{t('Name')}</Th>
														<Th align="left">{t('Company Branch')}</Th>
														{/* HIDE SALE LOCATION <Th align="left">{t('Sale Location')}</Th> */}
														<Th align="left">{t('End At')}</Th>
														<Th align="left">{t('Reason')}</Th>
														<Th align="left">{t('Description')}</Th>
														<Th align="left">{t('Status')}</Th>
														<Th align="right">{t('Status Description')}</Th>
														<Th className="hidden-print" align="right">
															{t('Logs')}
														</Th>
													</Tr>
												</Thead>
												<Tbody>
													{loadedData?.candidates?.map((e, i) => (
														<Tr _hover={{ bg: 'gray.200' }} cursor="pointer" key={`item-${i}`}>
															<Td>
																<Tooltip label={t('Click to view agent detail')}>
																	<Link to={`/agent/detail?id=${e?.agent?.id}`}>
																		<Text
																			fontSize="small"
																			fontWeight="semibold"
																			color="blue.700"
																			_hover={{ color: 'blue.400', textDecoration: 'underline' }}
																		>
																			{e?.agent?.code}
																		</Text>
																	</Link>
																</Tooltip>
															</Td>
															<Td>
																<Text fontSize="sm" fontWeight="medium">
																	<>
																		{e?.agent?.nationality !== 'KH' ? (
																			<>{e?.agent?.latinName}</>
																		) : (
																			<>{lang === 'kh' ? e?.agent?.name : e?.agent?.latinName}</>
																		)}
																	</>
																</Text>
															</Td>
															<Td>
																<>
																	{e?.branch ? (
																		<>
																			<Text fontSize="sm" fontWeight="md" color="blue.700">
																				{lang === 'kh'
																					? e?.branch?.name || e?.branch?.nameLatin || 'N/A'
																					: e?.branch?.nameLatin || e?.branch?.name || 'N/A'}
																			</Text>
																		</>
																	) : (
																		<>
																			<Text fontSize="sm" fontWeight="normal">
																				{'N/A'}
																			</Text>
																		</>
																	)}
																</>
															</Td>
															{/* HIDE SALE LOCATION <Td>
																<>
																	{e?.geoLocation ? (
																		<>
																			<Text fontSize="sm" fontWeight="md" color="blue.700">
																				{lang === 'kh' ? e?.geoLocation?.name : e?.geoLocation?.latinName}
																			</Text>
																		</>
																	) : (
																		<>
																			<Text fontSize="sm" fontWeight="normal">
																				{'N/A'}
																			</Text>
																		</>
																	)}
																</>
															</Td> */}
															<Td>
																<>
																	{e?.endDate ? (
																		<>
																			<Text fontSize="sm" fontWeight="md" color="blue.700">
																				{toDateString(e?.endDate, c.excelDateOnlyTextFormat)}
																			</Text>
																		</>
																	) : (
																		<>
																			<Text fontSize="sm" fontWeight="normal">
																				{'N/A'}
																			</Text>
																		</>
																	)}
																</>
															</Td>
															<Td>
																<Reason reason={e?.reason} />
															</Td>
															<Td>
																<TerminationRequestDescription
																	requestStatus={loadedData?.status}
																	description={e?.description}
																/>
															</Td>
															<Td>
																<TerminationRequestCandidateStatus
																	id={e?.id}
																	requestStatus={loadedData?.status}
																	candidateStatus={e?.status}
																/>
															</Td>
															<Td align="right">
																<TerminationRequestCandidateStatusDescription
																	requestStatus={loadedData?.status}
																	candidateStatus={e?.status}
																	candidateStatusDescription={e?.statusDescription}
																/>
															</Td>
															<Td className="hidden-print" align="right">
																<EventLoggingButton typeObjectId={id} actionObjectId={e?.id} />
															</Td>
														</Tr>
													))}
												</Tbody>
												<tfoot className="responsive-footer">
													<Tr className="td-as-th">
														<Th align="left">{t('Code')}</Th>
														<Th align="left">{t('Name')}</Th>
														<Th align="left">{t('Company Branch')}</Th>
														{/* HIDE SALE LOCATION <Th align="left">{t('Sale Location')}</Th> */}
														<Th align="left">{t('End At')}</Th>
														<Th align="left">{t('Reason')}</Th>
														<Th align="left">{t('Description')}</Th>
														<Th align="left">{t('Status')}</Th>
														<Th align="right">{t('Status Description')}</Th>
														<Th className="hidden-print" align="right">
															{t('Logs')}
														</Th>
													</Tr>
												</tfoot>
											</Table>
										</Flex>
									</Flex>
								</Box>
							)}
							{!loadingRequest && !loadedData?.id && (
								<Flex direction="column" justifyContent="center" alignItems="center" p={24}>
									<FontAwesomeIcon icon={faFileAlt} style={{ fontSize: 56, color: '#A0AEC0' }} />
									<Text fontSize="lg" fontWeight="bold" color="gray.600" mt={6}>
										{t('No termination request with given ID has been found!')}
									</Text>
									<Button
										leftIcon={<FontAwesomeIcon icon={faArrowLeft} style={{ fontSize: 16 }} />}
										colorScheme="gray"
										variant="solid"
										size="sm"
										borderRadius={c.borderRadius}
										border="1px solid #bdc3c7"
										onClick={() => history.push(`/manage-agent-partner/termination-request`)}
										mt={4}
									>
										<Text fontSize="md" fontWeight="semibold">
											{t('Back To List')}
										</Text>
									</Button>
								</Flex>
							)}
							{loadingRequest && <Flex direction="column" justifyContent="center" alignItems="center" p={32} />}
						</Box>
					</Center>
				</Container>
			</PrintLayout>
			{loadingRequest && <LoadingOverlayText />}
		</>
	);
}

export default TerminationRequestDetail;
