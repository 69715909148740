/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Container,
	Box,
	Flex,
	Text,
	Center,
	Button,
	useToast,
	RadioGroup,
	Stack,
	Radio,
	useEditableControls,
	ButtonGroup,
	IconButton,
	EditablePreview,
	Input,
	EditableInput,
	Editable,
	SimpleGrid,
} from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList } from '@fortawesome/free-solid-svg-icons';
import { CheckIcon, CloseIcon, EditIcon } from '@chakra-ui/icons';
import CompanyPickerModal from '../../components/Modal/CompanyPickerModal';
import UploadingModalProgress from '../../components/UploadingModalProgress/UploadingModalProgress';
import CompanyFormField from '../../components/form/CompanyFormField';
import TextFormField from '../../components/form/TextFormField';
import TextAreaFormField from '../../components/form/TextAreaFormField';
import RadioFormField from '../../components/form/RadioFormField';
import FileFormField from '../../components/form/FileFormField';
import DateFormField from '../../components/form/DateFormField';
import ReferenceInputField from '../../components/form/ReferenceInputField';
import BreadcrumbComponent from '../../components/BreadcrumbComponent/BreadcrumbComponent';
import moment from 'moment';
import 'moment/locale/en-gb';
import c from '../../constant';
import * as Yup from 'yup';
import useStore from '../../store';

const MAX_FILE_SIZE = 104857600;

const QUERY_NUMBER_DATA = gql`
	query get($id: String) {
		getTypeOfDocumentNumberById(id: $id) {
			id
			type
			typeEn
			description
		}
	}
`;

const complaintOnInsuranceSchema = Yup.object().shape({
	proposalNo: Yup.string(),
	subject: Yup.string().required('required'),
	companyName: Yup.string(),
	selectedCompany: Yup.string(),
	department: Yup.string(),
	file: Yup.mixed().test('fileSize', 'over limit', (value) => {
		if (value) {
			return value?.size <= MAX_FILE_SIZE;
		} else {
			return true;
		}
	}),
	date: Yup.string().nullable(),
	references: Yup.array().of(
		Yup.object()
			.shape({
				referenceId: Yup.string().required('required'),
				referenceValue: Yup.string().required('required'),
			})
			.required('required')
	),
});

const CREATE_DOCUMENT_NUMBER_MUTATION = gql`
	mutation create(
		$type: String!
		$number: Float
		$language: String
		$proposalNumber: Float
		$subject: String
		$companyName: String
		$companyId: String
		$department: String
		$file: Upload
		$fileSize: Float
		$writeDate: DateTime
		$references: [DocumentNumberTypeReferenceInput!]
	) {
		createDocumentNumber(
			documentNumberInput: {
				type: $type
				number: $number
				language: $language
				proposalNumber: $proposalNumber
				subject: $subject
				companyName: $companyName
				companyId: $companyId
				department: $department
				references: $references
			}
			file: $file
			fileSize: $fileSize
			writeDate: $writeDate
		)
	}
`;

function AddCompanyProposal() {
	moment.locale('en-gb');
	const toast = useToast();
	const history = useHistory();
	const location = useLocation();
	const urlParams = new URLSearchParams(location.search);
	const typeId = urlParams.get('typeId') || null;
	const typeLabel = urlParams.get('typeLabel') || null;
	const { t } = useTranslation();
	const [showCompanyPicker, setShowCompanyPicker] = useState(false);
	const [selectedCompany, setSelectedCompany] = useState(null);
	const [uploading, setUploading] = useState(false);
	const [progress, setProgress] = useState(0);
	const [numberLang, setNumberLang] = useState('kh');
	const [manualNumber, setManualNumber] = useState(null);
	const [createDocumentNumber, { error, data }] = useMutation(CREATE_DOCUMENT_NUMBER_MUTATION);
	const [loadNumberData, { data: numberData }] = useLazyQuery(QUERY_NUMBER_DATA);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		loadNumberData({
			variables: {
				id: typeId,
			},
		});
	}, []);

	useEffect(() => {
		if (error) {
			setUploading(false);
			if (error?.graphQLErrors?.length > 0) {
				error?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(error?.message ? error?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [error]);

	useEffect(() => {
		if (data) {
			if (data.createDocumentNumber) {
				setUploading(false);
				toast({
					title: t('Document number added successfully'),
					status: 'success',
					duration: 5000,
					isClosable: true,
					position: 'bottom-right',
				});
				history.push(`/issued-letter/detail?typeId=${typeId}&typeLabel=${typeLabel}&id=${data.createDocumentNumber}`);
			}
		}
	}, [data]);

	async function submitCreateDocumentNumber(values, numberLang, manualNumber) {
		console.log("values:", values)
		setUploading(true);
		let variables = {
			type: typeId,
			number: parseInt(manualNumber) || null,
			language: numberLang,
			proposalNumber: parseInt(values?.proposalNo) || null,
			subject: values?.subject,
			companyName: values?.companyName,
			companyId: values?.selectedCompany,
			department: values?.department,
			file: values.file,
			fileSize: values?.file?.size,
			writeDate: values?.date,
			references: values?.references?.map((element) => {
				return { referenceId: '', typeReferenceId: element?.referenceId, typeReferenceValue: element?.referenceValue };
			}),
		};
		let abort;
		createDocumentNumber({
			variables,
			context: {
				fetchOptions: {
					useUpload: true,
					onProgress: (ev) => {
						setProgress(ev.loaded / ev.total);
					},
					onAbortPossible: (abortHandler) => {
						abort = abortHandler;
					},
				},
			},
		});
		if (abort) {
			abort();
		}
	}

	function EditableControls() {
		const { isEditing, getSubmitButtonProps, getCancelButtonProps, getEditButtonProps } = useEditableControls();

		return isEditing ? (
			<ButtonGroup justifyContent="center" size="sm">
				<IconButton icon={<CheckIcon />} {...getSubmitButtonProps()} />
				<IconButton icon={<CloseIcon />} {...getCancelButtonProps()} />
			</ButtonGroup>
		) : (
			<Flex justifyContent="center">
				<IconButton size="sm" icon={<EditIcon />} {...getEditButtonProps()} />
			</Flex>
		);
	}

	return (
		<>
			<Container maxW="container.xl" mt="16px" mb="16px">
				<BreadcrumbComponent
					list={[
						{
							name: 'ISSUED LETTER',
							path: '/issued-letter?page=1&count=300',
						},
						{
							name: typeLabel || '#',
							path: `/issued-letter/list?page=1&typeId=${typeId}&typeLabel=${typeLabel}`,
						},
						{
							name: 'Add',
							path: '#',
						},
					]}
				/>
				<Center mt="5">
					<Box
						w="100%"
						bg="white"
						borderRadius={c.borderRadius}
						border="1px solid #dbdbdb"
						boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
						pb="32px"
					>
						<Flex bg="#FAFAFA" p="16px" pt="8px" pb="8px" mb="16px" justifyContent="space-between" borderBottom="1px solid #dbdbdb">
							<Text fontSize="x-large" fontWeight="bold">
								{t('Add')}
							</Text>
						</Flex>

						<Box p="16px">
							<Formik
								initialValues={{
									companyName: '',
									subject: '',
									file: null,
									department: '',
									proposalNo: '',
									selectedCompany: '',
									date: null,
									references: [],
								}}
								validationSchema={complaintOnInsuranceSchema}
								onSubmit={(values) => {
									submitCreateDocumentNumber(values, numberLang, manualNumber);
								}}
							>
								{(props) => (
									<Form>
										<SimpleGrid columns={[2]} spacing="40px" w="100%">
											<Box>
												<ReferenceInputField name="references" label="Document Reference" />
												<TextAreaFormField {...props} label="Subject" name="subject" required />
												{selectedCompany?.id && (
													<CompanyFormField
														{...props}
														label="Company/Institution Name"
														name="selectedCompany"
														callBackClear={() => setSelectedCompany(null)}
														callBackList={() => setShowCompanyPicker(true)}
														selectedCompany={selectedCompany}
													/>
												)}

												{!selectedCompany?.id && (
													<TextFormField
														{...props}
														label="Company/Institution Name"
														name="companyName"
														rightElement={
															<Button
																onClick={() => setShowCompanyPicker(true)}
																leftIcon={
																	<FontAwesomeIcon
																		style={{
																			fontSize: 16,
																			cursor: 'pointer',
																		}}
																		icon={faList}
																	/>
																}
																mt="7px"
																variant="solid"
																h="42px"
																mr="3px"
																borderRadius={c.borderRadius}
															>
																{t('List')}
															</Button>
														}
													/>
												)}

												{<RadioFormField label="Department" name="department" radios={c.departments} />}

												<FileFormField {...props} label="Document" name="file" maxFileSize={MAX_FILE_SIZE} />
											</Box>
											<Box height="80px">
												<RadioGroup defaultValue="kh" mb="4" onChange={(e) => setNumberLang(e)}>
													<Stack>
														{numberData?.getTypeOfDocumentNumberById?.type && (
															<Radio colorScheme="green" value="kh">
																<Text fontSize="xl" fontWeight="bold">
																	{numberData?.getTypeOfDocumentNumberById?.type}
																</Text>
															</Radio>
														)}
														{numberData?.getTypeOfDocumentNumberById?.typeEn && (
															<Radio colorScheme="green" value="en">
																<Text fontSize="xl" fontWeight="bold">
																	{numberData?.getTypeOfDocumentNumberById?.typeEn}
																</Text>
															</Radio>
														)}
													</Stack>
												</RadioGroup>
												<Box>
													<Text fontWeight="500" fontSize="16px">
														{t('Number')}
													</Text>
													<Editable
														mt="2"
														mb="2"
														pt="2"
														pb="2"
														bg="rgba(26, 188, 156,.1)"
														textAlign="center"
														defaultValue="Auto"
														fontSize="2xl"
														isPreviewFocusable={false}
														onChange={(e) => setManualNumber(e)}
													>
														<EditablePreview />
														{/* Here is the custom input */}
														<Input type="number" as={EditableInput} />
														<EditableControls />
													</Editable>
												</Box>
												<DateFormField {...props} label="Date" name="date" required initialDate={moment()} />
												<Button
													id="login-btn"
													variant="solid"
													colorScheme="blue"
													size="md"
													h="42px"
													w="128px"
													type="submit"
													borderRadius={c.borderRadius}
													mt="8"
													float="right"
												>
													{t('Create')}
												</Button>
											</Box>
										</SimpleGrid>
									</Form>
								)}
							</Formik>
						</Box>
					</Box>
				</Center>
			</Container>

			<UploadingModalProgress isOpen={uploading} progress={progress} />

			{showCompanyPicker && <CompanyPickerModal onClose={() => setShowCompanyPicker(false)} callBack={(e) => setSelectedCompany(e)} />}
		</>
	);
}

export default AddCompanyProposal;
