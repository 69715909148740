/* eslint-disable react-hooks/exhaustive-deps */
import { Badge, Text, Tooltip } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { TerminationRequestStatus } from '../../../../containers/ManageAgentPartner/TerminationRequest/constands';
import TerminationRequestEditingStatus from './TerminationRequestEditingStatus';
import useStore from '../../../../store';
import c from '../../../../constant';

const TerminationRequestCandidateStatusDescription = ({ requestStatus = null, candidateStatus = null, candidateStatusDescription = null }) => {
	const { t } = useTranslation();
	const { currentUser: user } = useStore((state) => state.currentUser);

	return (
		<>
			{requestStatus > TerminationRequestStatus.reviewingFinal && (
				<>
					<>
						{candidateStatus === 'APPROVED' ? (
							<>
								<Tooltip label={`${t('Completed')}`}>
									<Badge pl={2} pr={2} colorScheme="green">
										<Text fontWeight="bold" fontSize="xs" color="green.500">
											{t('Completed')}
										</Text>
									</Badge>
								</Tooltip>
							</>
						) : (
							<>
								{candidateStatusDescription ? (
									<>
										<Text fontSize="sm" fontWeight="medium">
											{candidateStatusDescription}
										</Text>
									</>
								) : (
									<Text fontSize="sm" fontWeight="normal">
										{'N/A'}
									</Text>
								)}
							</>
						)}
					</>
				</>
			)}
			{requestStatus === TerminationRequestStatus.reviewing && (
				<>
					{c.stepOfficeRoles.includes(user?.position) ? (
						<>
							{candidateStatusDescription ? (
								<>
									<Text fontSize="sm" fontWeight="medium">
										{candidateStatusDescription}
									</Text>
								</>
							) : (
								<>
									{candidateStatus ? (
										<>
											<Text fontSize="sm" fontWeight="normal">
												{'N/A'}
											</Text>
										</>
									) : (
										<>
											<Text fontSize="sm" fontWeight="medium">
												{'-'}
											</Text>
										</>
									)}
								</>
							)}
						</>
					) : (
						<>
							<Text fontSize="sm" fontWeight="medium">
								{t('-')}
							</Text>
						</>
					)}
				</>
			)}
			{requestStatus === TerminationRequestStatus.reviewingFinal && (
				<>
					{candidateStatusDescription ? (
						<>
							<Text fontSize="sm" fontWeight="medium">
								{candidateStatusDescription}
							</Text>
						</>
					) : (
						<>
							{candidateStatus ? (
								<>
									<Text fontSize="sm" fontWeight="normal">
										{'N/A'}
									</Text>
								</>
							) : (
								<>
									{c.stepDepartmentRoles.includes(user?.position) ? (
										<>
											<Text fontSize="sm" fontWeight="medium">
												{'-'}
											</Text>
										</>
									) : (
										<>
											<TerminationRequestEditingStatus />
										</>
									)}
								</>
							)}
						</>
					)}
				</>
			)}
		</>
	);
};

export default TerminationRequestCandidateStatusDescription;
