import React from 'react';
import { Alert, AlertDescription, AlertIcon, AlertTitle, Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import CardName from '../CardName';
import CardDate from '../CardDate';
import CardReason from '../CardReason';

export default function InvoiceRejected({ invoice = null }) {
	const { t } = useTranslation();

	return (
		<>
			<Alert status="error" variant="subtle" flexDirection="column" alignItems="center" justifyContent="center" textAlign="center" p={6} mt={6}>
				<AlertIcon boxSize="40px" mt={0} mr={0} />
				<AlertTitle fontSize="lg" mt={4} mr={0}>
					{t('Payment invoice has been rejected')}
				</AlertTitle>
				<AlertDescription mt={1} mr={0}>
					<Flex>
						<Flex ml={1} mr={1}>
							<Text fontWeight="semibold">{t('Rejected At')}</Text>&nbsp; <CardDate input={invoice?.rejectedAt} />
						</Flex>
						<Flex ml={1} mr={1}>
							<Text fontWeight="semibold">{t('Rejected By')}</Text>&nbsp; <CardName id={invoice?.rejectedBy} />
						</Flex>
						<Flex ml={1} mr={1}>
							<Text fontWeight="semibold">{t('Rejection Reason')}</Text>&nbsp; <CardReason reason={invoice?.rejectionReason} />
						</Flex>
					</Flex>
				</AlertDescription>
			</Alert>
		</>
	);
}
