/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { Badge, Flex, Text, Tooltip } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { CloseIcon } from '@chakra-ui/icons';
import { gql, useMutation } from '@apollo/client';
import { useBranchChangeRequest } from '../../../../contexts/branch-change-request';
import BranchChangeRequestCandidateApproval from '../Approval/BranchChangeRequestCandidateApproval';
import useStore from '../../../../store';
import c from '../../../../constant';

const M_SUBMIT_APPROVAL = gql`
	mutation submit($id: String!, $status: BranchChangeCandidateStatus, $statusDescription: String) {
		submitBranchChangeCandidateApproval(id: $id, status: $status, statusDescription: $statusDescription)
	}
`;

let status = null;
let statusDescription = null;

const BranchChangeRequestCandidateStatus = ({ id = null, requestStatus = null, candidateStatus = null }) => {
	const { t } = useTranslation();
	const { loadedData, setLoadedData } = useBranchChangeRequest();
	const { currentUser: user } = useStore((state) => state.currentUser);
	const [submitApproval, { data }] = useMutation(M_SUBMIT_APPROVAL);

	useEffect(() => {
		if (data?.submitBranchChangeCandidateApproval) {
			setLoadedData({
				...loadedData,
				candidates: loadedData?.candidates?.map((e) => {
					if (e?.id === id) {
						e.status = status;
						e.statusDescription = statusDescription;
					}
					return e;
				}),
			});
		}
	}, [data]);

	return (
		<>
			{requestStatus > 1 && (
				<>
					{candidateStatus === 'APPROVED' && (
						<>
							<Tooltip label={`${t('approved')}`}>
								<Badge pl={2} pr={2} colorScheme="green">
									<Text fontWeight="bold" fontSize="xs" color="green.500">
										{t('approved')}
									</Text>
								</Badge>
							</Tooltip>
						</>
					)}
					{candidateStatus === 'REJECTED' && (
						<>
							<Tooltip label={`${t('Rejected')}`}>
								<Badge pl={2} pr={2} colorScheme="red">
									<Text fontWeight="bold" fontSize="xs" color="red.500">
										{t('Rejected')}
									</Text>
								</Badge>
							</Tooltip>
						</>
					)}
					{!candidateStatus && (
						<>
							<>
								<Text fontSize="sm" fontWeight="medium">
									{t('N/A')}
								</Text>
							</>
						</>
					)}
				</>
			)}
			{requestStatus === 1 && (
				<>
					{c.stepOfficeRoles.includes(user?.position) ? (
						<>
							{candidateStatus ? (
								<>
									{candidateStatus === 'APPROVED' && (
										<>
											<Tooltip label={`${t('approved')} (${t('You can change this status')})`}>
												<Badge pl={2} pr={2} colorScheme="green">
													<Flex justifyContent="center" alignItems="center">
														<Text fontWeight="bold" fontSize="xs" color="green.500">
															{t('approved')}
														</Text>
														<>
															<CloseIcon
																w={2}
																h={2}
																ml={2}
																color="green.500"
																onClick={() => {
																	status = null;
																	statusDescription = null;
																	const variables = {
																		id,
																		status,
																		statusDescription,
																	};
																	submitApproval({ variables });
																}}
															/>
														</>
													</Flex>
												</Badge>
											</Tooltip>
										</>
									)}
									{candidateStatus === 'REJECTED' && (
										<>
											<Tooltip label={`${t('Rejected')} (${t('You can change this status')})`}>
												<Badge pl={2} pr={2} colorScheme="red">
													<Flex justifyContent="center" alignItems="center">
														<Text fontWeight="bold" fontSize="xs" color="red.500">
															{t('Rejected')}
														</Text>
														<>
															<CloseIcon
																w={2}
																h={2}
																ml={2}
																color="red.500"
																onClick={() => {
																	status = null;
																	statusDescription = null;
																	const variables = {
																		id,
																		status,
																		statusDescription,
																	};
																	submitApproval({ variables });
																}}
															/>
														</>
													</Flex>
												</Badge>
											</Tooltip>
										</>
									)}
								</>
							) : (
								<>
									<BranchChangeRequestCandidateApproval id={id} requestStatus={requestStatus} />
								</>
							)}
						</>
					) : (
						<>
							<Text fontSize="sm" fontWeight="medium">
								{t('-')}
							</Text>
						</>
					)}
				</>
			)}
		</>
	);
};

export default BranchChangeRequestCandidateStatus;
