/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import { gql, useMutation } from '@apollo/client';
import { Box, Flex, Button, useToast, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, Grid, GridItem } from '@chakra-ui/react';
import TextFormField from '../../components/form/TextFormField';
import FileFormField from '../../components/form/FileFormField';
import DateFormField from '../form/DateFormField';
import c from '../../constant';
import * as Yup from 'yup';

const M_CREATE_CERTIFICATE = gql`
	mutation create(
		$certificateFile: Upload
		$certificateFileSize: Float
		$geoLocation: String
		$isGeoManual: Boolean
		$geoManual: String
		$validTo: DateTime
		$validFrom: DateTime
		$name: String!
		$nameLatin: String
		$managerName: String
		$managerNameLatin: String
		$number: String
		$companyID: String!
		$companyCertificateType: String
	) {
		createCompanyCertificate(
			certificateFile: $certificateFile
			certificateFileSize: $certificateFileSize
			geoLocation: $geoLocation
			isGeoManual: $isGeoManual
			geoManual: $geoManual
			validTo: $validTo
			validFrom: $validFrom
			name: $name
			nameLatin: $nameLatin
			managerName: $managerName
			managerNameLatin: $managerNameLatin
			number: $number
			companyID: $companyID
			companyCertificateType: $companyCertificateType
		)
	}
`;

const validationSchema = Yup.object().shape({
	number: Yup.string().nullable(),
	name: Yup.string().required('required'),
	managerName: Yup.string().nullable(),
	managerNameLatin: Yup.string().nullable(),
	validFrom: Yup.string().nullable(),
	validTo: Yup.string().nullable(),
	file: Yup.mixed().test('fileSize', 'over limit', (value) => {
		if (value) {
			return value?.size <= c.maxFileSize / 10;
		} else {
			return true;
		}
	}),
});

function Create({ companyId, isOpen = false, onClose = () => null, callback = () => null }) {
	const toast = useToast();
	const { t } = useTranslation();
	const [createCertificate, { loading, error, data }] = useMutation(M_CREATE_CERTIFICATE);

	useEffect(() => {
		if (data) {
			if (data?.createCompanyCertificate) {
				toast({
					title: t('Company Certificate created successfully'),
					status: 'success',
					isClosable: true,
					position: 'top-right',
				});
				onClose();
				callback('Company Certificate created successfully');
			}
		}
	}, [data]);

	useEffect(() => {
		if (error) {
			error.graphQLErrors.map(({ message }, i) =>
				toast({
					title: t(message),
					status: 'error',
					isClosable: true,
					position: 'top-right',
				})
			);
		}
	}, [error]);

	return (
		<>
			<Modal isOpen={isOpen} onClose={onClose} size="xl">
				<ModalOverlay />
				<ModalContent borderRadius={c.borderRadius} pb="2">
					<ModalHeader>{t('Add Company Certificate')}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Formik
							validationSchema={validationSchema}
							initialValues={{
								number: '',
								name: '',
								nameLatin: '',
								managerName: '',
								managerNameLatin: '',
								geoLocation: null,
								isGeoManual: false,
								geoManual: '',
								validFrom: null,
								validTo: null,
								file: null,
							}}
							onSubmit={(values) => {
								const variables = {
									companyID: companyId,
									number: values.number,
									name: values.name,
									nameLatin: values.nameLatin,
									managerName: values.managerName,
									managerNameLatin: values.managerNameLatin,
									geoLocation: JSON.stringify(values.geoLocation),
									isGeoManual: values?.isGeoManual,
									geoManual: values?.geoManual,
									certificateFile: values.file,
									certificateFileSize: values?.file?.size,
									validTo: values?.validTo,
									validFrom: values?.validFrom,
									companyCertificateType: 'b',
								};
								createCertificate({ variables });
							}}
						>
							{(props) => (
								<Form>
									<Box mt="3">
										<Grid templateColumns="repeat(1, 1fr)" gap={6}>
											<GridItem w="100%">
												<TextFormField {...props} label="Number" name="number" />
												<TextFormField {...props} label="Name" name="name" required />
												<TextFormField {...props} label="Insurance product sale manager's Khmer name" name="managerName" />
												<TextFormField {...props} label="Insurance product sale manager's Latin name" name="managerNameLatin" />
												<FileFormField {...props} label="File" name="file" maxFileSize={c.maxFileSize / 10} />
												<Grid templateColumns="repeat(2, 1fr)" gap={6}>
													<GridItem w="100%">
														<DateFormField name="validFrom" label="Valid From" />
													</GridItem>
													<GridItem w="100%">
														<DateFormField name="validTo" label="Valid To" />
													</GridItem>
												</Grid>
											</GridItem>
										</Grid>
										<Flex textAlign="right" mt="6" justifyContent="space-between" alignItems="center" float="right">
											<Button
												id="login-btn"
												variant="solid"
												size="md"
												h="42px"
												w="128px"
												bg="primary.dark"
												_hover={{ bg: 'primary.dark' }}
												color="white"
												type="submit"
												isLoading={loading}
												loadingText={t('Add')}
												spinnerPlacement="start"
											>
												{t(`Add`)}
											</Button>
										</Flex>
									</Box>
								</Form>
							)}
						</Formik>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
}

export default Create;
