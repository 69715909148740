import React, { useEffect, useState } from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Button,
    Box,
    Text
} from "@chakra-ui/react";
import c from "../../constant"
import './style.css'
import { useTranslation } from "react-i18next";
import ReactExport from "react-export-excel";
import moment from "moment";
import { gql, useLazyQuery } from '@apollo/client';
import _ from 'lodash'

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const QUERY_AGENT = gql`
query{
    getAgentReportList{
      id
      code
      name
      latinName
      gender
      validFrom
      expireAt
    }
  }
`

function ExcelExportModalActiveAgent({
    isOpen = true, onClose = () => null
}) {
    const [loadAgent, { error, loading, data:agentData }] = useLazyQuery(QUERY_AGENT)

    const { t } = useTranslation();

    const [data, setData] = useState([])
    useEffect(()=>{
        loadAgent()
    },[])

    useEffect(()=>{
        if(agentData){
            const tmpData = agentData?.getAgentReportList
            let newData = []
            tmpData.map((item,index)=>{
                newData.push({
                    number: index+1,
                    name: item?.name,
                    nameLatin: item?.latinName,
                    gender: item?.gender==="male"?"ប្រុស":"ស្រី",
                    code: item?.code,
                    validFrom: moment(item?.validFrom).format(c.excelDateOnlyFormat),
                    expireAt: moment(item?.expireAt).format(c.dateOnlyFormat)
                })
            })
            setData(newData)
        }
    },[agentData])

    const title = "Insurance Agent"
    
    return (
        <Modal
            isOpen={isOpen}
            onClose={() => {
                onClose()
            }}
            size="md"
        >
            <ModalOverlay />
            <ModalContent pb="4" borderRadius={c.borderRadius}>
                <ModalHeader>{t("Export To Excel")}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>

                    <Box w="100%" pt="16px" pb="16px">
                        {loading&&<Text>{t("Loading")}...</Text>}
                        {(data?.length>0)&&<ExcelFile
                            filename={`${title} - Generated At ${moment().format("DD MM YYYY hh mm")}`}
                            element={
                                <Button
                                    variant="solid"
                                    size="md"
                                    h="42px"
                                    w="128px"
                                    type="submit"
                                    borderRadius={c.borderRadius}
                                    color="#3498db"
                                >
                                    {t("Download")}
                                </Button>
                            }>
                            <ExcelSheet data={data} name={`Table`}>
                                <ExcelColumn label="ល.រ" value="number"/>
                                <ExcelColumn label="ឈ្មោះ" value="name"/>
                                <ExcelColumn label="ឈ្មោះជាភាសាឡាតាំង" value="nameLatin"/>
                                <ExcelColumn label="ភេទ" value="gender"/>
                                <ExcelColumn label="អត្តលេខបណ្ណចុះបញ្ជី" value="code"/>
                                <ExcelColumn label="ការបរិច្ឆេទចេញបណ្ណចុះបញ្ជី" value="validFrom"/>
                                <ExcelColumn label="ការបរិច្ឆេទផុតសុពលភាពបណ្ណចុះបញ្ជី" value="expireAt"/>
                            </ExcelSheet>
                        </ExcelFile>}
                    </Box>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

export default ExcelExportModalActiveAgent;


