import React, { useEffect, useState } from "react";
import { Input, Box, FormControl, FormLabel, FormHelperText, InputGroup, InputRightElement, Button } from "@chakra-ui/react";
import { Field } from "formik";
import { useTranslation } from "react-i18next";
import SelectUserModal from "../Modal/SelectUserModal"
import useStore from "../../store";

const EmployeeFormField = props => {
    const {
        name,
        type,
        label,
        placeholder,
        required,
        rightElement,
        min,
        disabled = false,
        initialData=null
    } = props;
    const { t } = useTranslation();

    const [showEmployee, setShowEmployee] = useState(false)

    const [currentValue, setCurrentValue] = useState(null)

    const { currentLang } = useStore((state) => state.currentLang);

    useEffect(()=>{
        if(initialData){
            setCurrentValue(initialData)
        }
    },[initialData])

    function getDisplayName(data) {
        let user = {}

        if(data?.length>40){
            user = JSON.parse(data)
        }
        let result = user?.username
       
        if (currentLang === "kh") {
            if (result = user?.fullNameKhmer) {
                result = user?.fullNameKhmer
            } else {
                if (user?.fullName) {
                    result = user?.fullName
                } else {
                    result = user.username
                }
            }
        } else {
            if (result = user?.fullName) {
                result = user?.fullName
            } else {
                if (user?.fullNameKhmer) {
                    result = user?.fullNameKhmer
                } else {
                    result = user.username
                }
            }
        }

        return result
    }
    return (
        <>
        <Field name={name}>
            {({ field, meta, form }) => (
                <Box mb="4">
                    <FormControl id={name} >
                        <FormLabel>{t(label)}{required && <span style={{ color: "red" }}>*</span>}</FormLabel>
                        <InputGroup size='md'>
                            <Input
                                type={type ? type : "text"}
                                value={currentValue?getDisplayName(currentValue):""}
                                size="lg"
                                disabled={true}
                            />
                            {rightElement && <InputRightElement width='4.5rem'>
                                {rightElement}
                            </InputRightElement>}
                            <InputRightElement width='4.5rem'>
                                <Button size='md' h="40px" mt="2" onClick={() => setShowEmployee(true)}>
                                    {t("Pick")}
                                </Button>
                            </InputRightElement>
                        </InputGroup>
                        {meta.touched && meta.error && (
                            <FormHelperText
                                id={`error-message-${name}`}
                                color="red.400"
                                fontSize="12px"
                            >
                                {t(name)}{" "}
                                {t("is")}{" "}
                                {t(meta.error)}
                            </FormHelperText>
                        )}
                    </FormControl>
                    {showEmployee&&<SelectUserModal role="" callback={e=>{
                        const userData = JSON.stringify({
                            id:e.id,
                            username:e.username,
                            fullName:e.fullName,
                            fullNameKhmer: e.fullNameKhmer
                        })
                        form.setFieldValue(name, userData)
                        setCurrentValue(userData)
                        }} isOpen={true} onClose={() => setShowEmployee(false)} />}

                </Box>
            )}
        </Field>

        </>
    );
};

export default EmployeeFormField;
