import axios from 'axios';
import mime from "mime-types"
import useStore from "../store";

// const setFileViewerLoadedPercentage = useStore((state) => state.setFileViewerLoadedPercentage);

export const fetchFile = async (apiURL) =>{
    try {
        const fileName = ((apiURL?.split('/')?.pop())?.split("?X-Amz-Algorithm"))[0]
        const ext = fileName.substr(fileName.lastIndexOf('.') + 1);
        const mimeType = mime.lookup(ext)
        if((mimeType.includes("pdf"))||(mimeType.includes("image"))){
            const res = await axios(`${apiURL}`, {
                method: 'GET',
                responseType: 'blob', //Force to receive data in a Blob Format
                onDownloadProgress: progressEvent => {
                    // console.log("total:", progressEvent?.total)
                    // console.log("loaded:", progressEvent?.loaded)
                    
                    // const total = parseFloat(progressEvent.currentTarget.responseHeaders['Content-Length'])
                    // const current = progressEvent.currentTarget.response.length
                
                    let percentCompleted = Math.floor(progressEvent?.loaded / progressEvent?.total * 100)
                    // console.log('completed: ', percentCompleted)
                    // setFileViewerLoadedPercentage(percentCompleted)
                    useStore.setState({ fileViewerLoadedPercentage: percentCompleted })

                }
                
            })
            .then(response => {
                
                //Create a Blob from the PDF Stream
                const file = new Blob(
                  [response.data], 
                  {type: mimeType});
                //Build a URL from the file
                const fileURL = URL.createObjectURL(file);
                //Open the URL on new Window
                // window.open(fileURL);
                return {fileURL, mimeType}
            })
    
            return res
        } else {
            return {fileURL:apiURL, mimeType}
        }
        
    } catch (error) {
        return error
    }
    
}
