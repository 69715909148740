/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Badge, Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FullAddress } from '../../Other';
import DownloadAndViewButton from '../../Files/DownloadAndViewButton';
import useMoment from '../../../hooks/useMoment';
import c from '../../../constant';

const CompanyLongInformation = ({
	id = null,
	type = null,
	url = null,
	number = null,
	displayNumber = null,
	isGeoManual = null,
	geoManual = null,
	houseNumber = null,
	streetNumber = null,
	villageObject = null,
	communeObject = null,
	districtObject = null,
	provinceObject = null,
	licenseFileSigned = null,
	licenseSize = null,
	isPermanentLicense = null,
	statuteFileSigned = null,
	statuteSize = null,
	description = null,
	validFrom = null,
	validTo = null,
	status = null,
}) => {
	const { t } = useTranslation();
	const { toDateString } = useMoment();

	return (
		<>
			<Box>
				<Flex alignItems="center" mt={0}>
					<Text fontWeight="bold" minW={140} w={140}>
						{t('Number')}
					</Text>
					{type === c.insuranceCompanyType.auditService.value ? (
						<Text color="#3498db" fontWeight="bold">
							{displayNumber ? displayNumber : number || 'N/A'}
						</Text>
					) : (
						<Text color="#3498db" fontWeight="bold">
							{number ? number : 'N/A'}
						</Text>
					)}
				</Flex>
				<Flex alignItems="center" mt="2">
					<Text fontWeight="bold" minW={140} w={140}>
						{t('URL')}
					</Text>
					{url ? (
						<a href={url} target="_blank" rel="noopener noreferrer">
							<Text mt="2" color="blue.400" _hover={{ textDecoration: 'underline' }}>
								{url}
							</Text>
						</a>
					) : (
						<Text>N/A</Text>
					)}
				</Flex>
				{isGeoManual && (
					<Flex alignItems="center" mt="2">
						<Text fontWeight="bold" minW={140} w={140}>
							{t('Address')}
						</Text>
						<Text>{geoManual}</Text>
					</Flex>
				)}
				{!isGeoManual && (
					<Flex alignItems="center" mt="2">
						<Text fontWeight="bold" minW={140} w={140}>
							{t('Address')}
						</Text>
						{houseNumber || streetNumber || villageObject || communeObject || districtObject || provinceObject ? (
							<FullAddress object={{ houseNumber, streetNumber, villageObject, communeObject, districtObject, provinceObject }} />
						) : (
							<Text>N/A</Text>
						)}
					</Flex>
				)}
				{statuteFileSigned && (
					<Flex alignItems="center" mt="2">
						<Text fontWeight="bold" minW={140} w={140}>
							{t('Statute')}
						</Text>
						<Box ml="-4">
							<DownloadAndViewButton url={statuteFileSigned} size={statuteSize} />
						</Box>
					</Flex>
				)}
				{licenseFileSigned && (
					<Flex alignItems="center" mt="2">
						<Text fontWeight="bold" minW={140} w={140}>
							{t('License')}
						</Text>
						<Box ml="-4">
							<DownloadAndViewButton url={licenseFileSigned} size={licenseSize} />
						</Box>
					</Flex>
				)}
				{isPermanentLicense ? (
					<Flex alignItems="center" mt="2">
						<Text fontWeight="bold" minW={140} w={140}>
							{t('License Valid')}
						</Text>
						<Text color="red" fontWeight="bold">
							{t('Permanent')}
						</Text>
					</Flex>
				) : (
					<Flex alignItems="center" mt="2">
						<Text fontWeight="bold" minW={140} w={140}>
							{t('License Valid')}
						</Text>
						<>
							<>
								{status === 'Normal' && (
									<>
										<Text fontSize="sm" fontWeight="md" color="black.800">
											<>{toDateString(validFrom, c.excelDateOnlyTextFormat)}</>
											<> - </>
											<>{toDateString(validTo, c.excelDateOnlyTextFormat)}</>
										</Text>
										<Tooltip label={t('Valid')}>
											<Badge backgroundColor="green.500" ml={1}>
												<Text fontSize="xs" color="white">
													{t('Valid')}
												</Text>
											</Badge>
										</Tooltip>
									</>
								)}
							</>
							<>
								{status === 'NearlyDue' && (
									<>
										<Text fontSize="sm" fontWeight="bold" color="yellow.500">
											<>{toDateString(validFrom, c.excelDateOnlyTextFormat)}</>
											<> - </>
											<>{toDateString(validTo, c.excelDateOnlyTextFormat)}</>
										</Text>
										<Tooltip label={t('Nearly Due')}>
											<Badge backgroundColor="yellow.500" ml={1}>
												<Text fontSize="xs" color="white">
													{t('Nearly Due')}
												</Text>
											</Badge>
										</Tooltip>
									</>
								)}
							</>
							<>
								{status === 'Due' && (
									<>
										<Text fontSize="sm" fontWeight="bold" color="red.500">
											<>{toDateString(validFrom, c.excelDateOnlyTextFormat)}</>
											<> - </>
											<>{toDateString(validTo, c.excelDateOnlyTextFormat)}</>
										</Text>
										<Tooltip label={t('Due')}>
											<Badge backgroundColor="red.500" ml={1}>
												<Text fontSize="xs" color="white">
													{t('Due')}
												</Text>
											</Badge>
										</Tooltip>
									</>
								)}
							</>
						</>
					</Flex>
				)}
				<Flex alignItems="center" mt="2">
					<Text fontWeight="bold" minW={140} w={140}>
						{t('Note')}
					</Text>
					<Box>
						{description ? (
							<pre
								style={{
									whiteSpace: 'pre-wrap',
									fontFamily: 'Rajdhani, Hanuman, sans-serif, Moul !important',
								}}
							>
								{description}
							</pre>
						) : (
							'N/A'
						)}
					</Box>
				</Flex>
			</Box>
		</>
	);
};

export default CompanyLongInformation;
