import c from "../constant";

const themeCloudscape = {
    tokens: { 
        fontFamilyMonospace: "Rajdhani, Hanuman, sans-serif, Moul",
        fontFamilyBase: "Rajdhani, Hanuman, sans-serif, Moul", 
        borderRadiusAlert: c.borderRadius, 
        borderRadiusBadge: c.borderRadius, 
        borderRadiusButton: c.borderRadius, 
        borderRadiusCalendarDayFocusRing: c.borderRadius, 
        borderRadiusContainer: c.borderRadius, 
        borderRadiusControlCircularFocusRing: c.borderRadius, 
        borderRadiusControlDefaultFocusRing: c.borderRadius, 
        borderRadiusDropdown: c.borderRadius, 
        borderRadiusFlashbar: c.borderRadius, 
        borderRadiusItem: c.borderRadius, 
        borderRadiusInput: c.borderRadius, 
        borderRadiusPopover: c.borderRadius, 
        borderRadiusTabsFocusRing: c.borderRadius, 
        borderRadiusTiles: c.borderRadius, 
        borderRadiusToken: c.borderRadius }, 
        contexts: { "top-navigation": { "tokens": {} }, "header": { "tokens": {} }, "flashbar": { "tokens": {} }, "alert": { "tokens": {} } }
}

export default themeCloudscape;
