import { gql, useLazyQuery } from '@apollo/client';
import { useContext, createContext, useState, useEffect } from 'react';

const Q_GET_REQUEST = gql`
	query get($id: String!) {
		getAgentPartnerRequestById(id: $id) {
			id
			number
			createdAt
			startedAt
			updatedAt
			expiredAt
			status
			numberOfRequestedAgent
			numberOfApprovedAgent
			numberOfSubmittedContract
			numberOfApprovedContract
			certificatesPrimaryApprovedAt
			certificatesPrimaryApprovedBy
			certificatesSecondaryApprovedAt
			certificatesSecondaryApprovedBy
			contractsSubmittedAt
			contractsPrimaryApprovedAt
			contractsPrimaryApprovedBy
			contractsSecondaryApprovedAt
			contractsSecondaryApprovedBy
			completedAt
			paymentStatus
			paymentData
			permit
			company {
				id
				name
				nameKhmer
			}
			candidates {
				id
				agent {
					id
					code
					name
					latinName
					nationality
				}
				branch {
					id
					name
					nameLatin
				}
				geoLocation {
					id
					name
					latinName
				}
				certificate
				certificateStatus
				certificateStatusDescription
				contract
				contractStatus
				contractStatusDescription
				cancelledContract
			}
		}
	}
`;

const PartnerRequestContext = createContext();

export const PartnerRequestProvider = ({ children }) => {
	const [load, { loading: loadingRequest, data }] = useLazyQuery(Q_GET_REQUEST);
	const [loadedData, setLoadedData] = useState(null);

	useEffect(() => {
		if (data) {
			setLoadedData(data?.getAgentPartnerRequestById);
		}
	}, [data]);

	const loadRequest = (id) => {
		load({ variables: { id } });
	};

	return <PartnerRequestContext.Provider value={{ loadRequest, loadingRequest, loadedData, setLoadedData }}>{children}</PartnerRequestContext.Provider>;
};

export const usePartnerRequest = () => {
	return useContext(PartnerRequestContext);
};
