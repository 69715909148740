/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Text, Wrap, WrapItem, Avatar, Button, useDisclosure } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare, faMinusSquare } from '@fortawesome/free-solid-svg-icons';
import SelectUserModal from '../Modal/SelectUserModal';
import useStore from '../../store';
import c from '../../constant';
import { gql, useLazyQuery } from '@apollo/client';

const Q_USERS = gql`
	query get($ids: [String!]!) {
		getUsersByIds(ids: $ids) {
			id
			username
			fullName
			fullNameKhmer
			createdAt
			role
			avatar
			active
		}
	}
`;

function PublicServicePrimaryAssignee({ userIds = [], callback = () => null }) {
	const { t } = useTranslation();
	const { currentLang: lang } = useStore((state) => state.currentLang);
	const { currentUser: user } = useStore((state) => state.currentUser);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [users, setUsers] = useState([]);
	const [getUsers, { data }] = useLazyQuery(Q_USERS);

	useEffect(() => {
		if (userIds) {
			const variables = { ids: userIds };
			getUsers({ variables });
		}
	}, []);

	useEffect(() => {
		if (data) {
			setUsers(data?.getUsersByIds);
		}
	}, [data]);

	useEffect(() => {
		if (users) {
			callback(users.map((u) => u.id));
		}
	}, [users]);

	function getName(user) {
		if (lang === 'kh') {
			return user?.fullNameKhmer ? user?.fullNameKhmer : user?.fullName ? user?.fullName : user?.username;
		} else {
			return user?.fullName ? user?.fullName : user?.fullNameKhmer ? user?.fullNameKhmer : user?.username;
		}
	}

	function onSelect(e) {
		if (false === users.some((u) => u.id === e.id)) {
			setUsers([...users, e]);
		}
	}

	function onRemove(e) {
		if (true === users.some((u) => u.id === e.id)) {
			setUsers(users.filter((i) => i.id !== e.id));
		}
	}

	return (
		<>
			<Box mb={6}>
				<Wrap>
					{users.map((u, i) => {
						return (
							<Flex direction="column" justifyContent="center" alignItems="center" key={'u-' + i}>
								<WrapItem className="avatarWraper">
									{user?.role === 'admin' && (
										<FontAwesomeIcon
											onClick={() => onRemove(u)}
											className="remove"
											icon={faMinusSquare}
											style={{
												fontSize: 18,
												display: 'none',
												position: 'absolute',
												zIndex: '100',
												marginLeft: 32,
												color: 'red',
												backgroundColor: 'white',
												padding: 2,
												cursor: 'pointer',
											}}
										/>
									)}
									<Avatar name={getName(u)} src={u?.avatar?.icon ? c.baseFilePath + u?.avatar?.icon : null} />
								</WrapItem>
								<Text textAlign="center">{getName(u)}</Text>
							</Flex>
						);
					})}
					{user?.role === 'admin' && (
						<Flex>
							<Button
								className="hidden-print"
								colorScheme="blue"
								variant="outline"
								leftIcon={<FontAwesomeIcon icon={faPlusSquare} style={{ fontSize: 18 }} />}
								onClick={onOpen}
							>
								{t('Assign')}
							</Button>
						</Flex>
					)}
				</Wrap>
			</Box>
			{isOpen && <SelectUserModal role="" isOpen={true} callback={onSelect} onClose={onClose} />}
		</>
	);
}

export default PublicServicePrimaryAssignee;
