/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SectionHeader } from '../../Other';
import { Box, Flex, Text, Table, Thead, Tbody, Tfoot, Tr, Th, Td, Center } from '@chakra-ui/react';
import useMoment from '../../../hooks/useMoment';
import c from '../../../constant';

function DistributionSystemRegisteredOffice({ dataCompanyCertificates, ...props }) {
	const { toDateString } = useMoment();
	const { t } = useTranslation();

	return (
		<>
			<Flex className="show-only-printing" {...props}>
				<Box width="100%">
					<Center>
						<Box
							w="100%"
							bg="white"
							borderRadius={c.borderRadius}
							border="1px solid #dbdbdb"
							boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
							pb={2}
						>
							<Flex
								p={4}
								pt={2}
								pb={2}
								mb={4}
								h="48px"
								bg="#FAFAFA"
								alignItems="center"
								justifyContent="space-between"
								borderBottom="1px solid #dbdbdb"
							>
								<SectionHeader title={t('Registered Office')} fontSize="lg" />
							</Flex>
							<Box p="16px">
								<Table variant="simple">
									<Thead>
										<Tr>
											<Th>{t('Number')}</Th>
											<Th>{t('Name')}</Th>
											<Th isNumeric>{t('Valid')}</Th>
										</Tr>
									</Thead>
									<Tbody>
										{dataCompanyCertificates?.getCompanyCertificate?.branches?.length <= 0 && (
											<Tr>
												<Td>
													<Text>{t('No Data')}</Text>
												</Td>
											</Tr>
										)}
										{dataCompanyCertificates?.getCompanyCertificate?.branches?.map((item, index) => {
											return (
												<Tr cursor="pointer" _hover={{ bg: 'gray.200' }} key={`item-${index}`} bg="white">
													<Td>
														<Text>{item.number}</Text>
													</Td>
													<Td>
														<Text>{item.name}</Text>
													</Td>
													<Td isNumeric>
														{item?.isPermanentLicense ? (
															<Text>{t('Permanent')}</Text>
														) : (
															<>
																{item.validFrom && item.validTo && (
																	<>
																		<Text>
																			{toDateString(item.validFrom, c.dateOnlyFormat)}
																			&nbsp;-&nbsp;
																			{toDateString(item.validTo, c.dateOnlyFormat)}
																		</Text>
																	</>
																)}
															</>
														)}
													</Td>
												</Tr>
											);
										})}
									</Tbody>
									<Tfoot>
										<Tr>
											<Th>{t('Number')}</Th>
											<Th>{t('Name')}</Th>
											<Th isNumeric>{t('Valid')}</Th>
										</Tr>
									</Tfoot>
								</Table>
							</Box>
						</Box>
					</Center>
				</Box>
			</Flex>
		</>
	);
}

export default DistributionSystemRegisteredOffice;
