import moment  from "moment"
const datePickerFromToToDate=(data)=>{
     
    if(data){
        const value = JSON.parse(data)
        const date = moment({
            year: value.year,
            day: value.day,
            month: value.month - 1
        }).toDate()
        return date
    }
}

export default datePickerFromToToDate