/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Box, Button, Flex, FormControl, FormLabel, IconButton, Input, Tooltip, Select, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { gql, useLazyQuery } from '@apollo/client';
import { faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Field, FieldArray, ErrorMessage } from 'formik';
import useStore from '../../store';
import c from '../../constant';

const Q_REFERENCES = gql`
	query get {
		getDocumentNumberTypeReferences(pagerInput: { offset: 0, limit: 10000 }) {
			list {
				id
				enTitle
				khTitle
				description
				defaultChoice
				order
			}
			totalPages
			totalRows
		}
	}
`;

export default function ReferenceInputField({ name = null, label = null, required = false }) {
	const { t } = useTranslation();
	const { currentLang: lang } = useStore((state) => state.currentLang);
	const [references, setReferences] = useState([]);
	const [loadReferences, { data }] = useLazyQuery(Q_REFERENCES);

	useEffect(() => {
		loadReferences();
	}, []);

	useEffect(() => {
		const list = data?.getDocumentNumberTypeReferences?.list;
		if (list) {
			setReferences(
				list?.map((element) => {
					return { label: lang === 'kh' ? element?.khTitle : element?.enTitle, value: element?.id };
				})
			);
		}
	}, [data]);

	return (
		<>
			<FieldArray name={name}>
				{({ remove, push, form }) => {
					return (
						<Box mb="4">
							<>
								<>
									<FormLabel>
										{t(label)}
										{required && <span style={{ color: 'red' }}>*</span>}
									</FormLabel>
								</>
								<>
									{form.values.references?.map((reference, i) => (
										<>
											<Flex key={i} alignItems="flex-start" mt={2} mb={2}>
												<Flex flex={1} mr={4}>
													<Field name={`${name}.${i}.referenceId`}>
														{({ field, meta, form }) => {
															return (
																<FormControl>
																	<>
																		<Select
																			value={field?.value}
																			borderRadius={c.borderRadius}
																			height="40px"
																			fontSize="md"
																			fontWeight="md"
																			placeholder={t('Choose reference type')}
																			onChange={(event) => {
																				const localValue = event?.target?.value;
																				form?.setFieldValue(`${name}.${i}.referenceId`, localValue);
																			}}
																		>
																			<>
																				{references?.map((element, i) => (
																					<option key={`reference-option-${i}`} value={element?.value}>
																						{element?.label}
																					</option>
																				))}
																			</>
																		</Select>
																	</>
																	<>
																		{meta.touched && meta.error && (
																			<>
																				<ErrorMessage
																					name={`${name}.${i}.referenceId`}
																					children={(errorMessage) => (
																						<>
																							<Text mt={2} color="red">
																								{t('referenceId')} {t('is')} {t(errorMessage)}
																							</Text>
																						</>
																					)}
																				/>
																			</>
																		)}
																	</>
																</FormControl>
															);
														}}
													</Field>
												</Flex>
												<Flex flex={1} mr={4}>
													<Field name={`${name}.${i}.referenceValue`}>
														{({ field, meta, form }) => {
															return (
																<FormControl>
																	<>
																		<Input
																			value={field?.value}
																			borderRadius={c.borderRadius}
																			height="40px"
																			fontSize="md"
																			fontWeight="md"
																			autoComplete="off"
																			placeholder={t('Input reference value')}
																			onChange={(event) => {
																				const localValue = event?.target?.value;
																				form?.setFieldValue(`${name}.${i}.referenceValue`, localValue);
																			}}
																		/>
																	</>
																	<>
																		{meta.touched && meta.error && (
																			<>
																				<ErrorMessage
																					name={`${name}.${i}.referenceValue`}
																					children={(errorMessage) => (
																						<>
																							<Text mt={2} color="red">
																								{t('referenceValue')} {t('is')} {t(errorMessage)}
																							</Text>
																						</>
																					)}
																				/>
																			</>
																		)}
																	</>
																</FormControl>
															);
														}}
													</Field>
												</Flex>
												<>
													<Tooltip label={t('Delete')}>
														<IconButton
															variant="outline"
															colorScheme="gray"
															borderColor="gray.200"
															borderWidth={1}
															icon={<FontAwesomeIcon icon={faTrash} style={{ fontSize: 16 }} />}
															onClick={() => remove(i)}
														/>
													</Tooltip>
												</>
											</Flex>
										</>
									))}
								</>
								<>
									<Flex alignItems="center">
										<Tooltip label={t('Add')}>
											<Button
												leftIcon={<FontAwesomeIcon icon={faPlusCircle} style={{ fontSize: 16 }} />}
												colorScheme="blue"
												variant="solid"
												borderRadius={c.borderRadius}
												border="1px solid #bdc3c7}"
												pl={4}
												pr={4}
												onClick={() => {
													const list = data?.getDocumentNumberTypeReferences?.list;
													const reference = list.find((element) => element?.defaultChoice === true);
													push({ referenceId: reference?.id, referenceValue: '' });
												}}
											>
												{t('Add')}
											</Button>
										</Tooltip>
									</Flex>
								</>
								<>
									{typeof form.errors.references === 'string' && (
										<>
											<ErrorMessage
												name={name}
												children={(errorMessage) => (
													<>
														<Text mt={2} color="red">
															{t(name)} {t('is')} {t(errorMessage)}
														</Text>
													</>
												)}
											/>
										</>
									)}
								</>
							</>
						</Box>
					);
				}}
			</FieldArray>
		</>
	);
}
