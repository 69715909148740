import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Box, Flex, Text, Center, Input, Button, useToast, InputRightElement, InputGroup, FormLabel, SimpleGrid } from '@chakra-ui/react';
import BreadcrumbComponent from '../../components/BreadcrumbComponent/BreadcrumbComponent';
import { faNewspaper } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import c from '../../constant';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { gql, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import RadioFormField from '../../components/form/RadioFormField';
import TextFormField from '../../components/form/TextFormField';
import TextAreaFormField from '../../components/form/TextAreaFormField';
import DateFormField from '../../components/form/DateFormField';
import CheckListFormField from '../../components/form/CheckListFormFieldNested';
import GeoFormField from '../../components/form/GeoFormField';
import CheckboxFormField from '../../components/form/CheckBoxFormField';
import ImageCropFormField from '../../components/form/ImageCropFormField';

const MAX_FILE_SIZE = 104857600 / 20;

const MUTATION_ADD_EMPLOYEE = gql`
	mutation add(
		$profileImage: Upload
		$profileImageSize: Float
		$govtNumber: String
		$mefNumber: String
		$fullName: String
		$fullNameLatin: String
		$gender: String
		$dob: DateTime
		$maritalStatus: String
		$pob: String
		$isPobManual: Boolean
		$pobManual: String
		$currentAddress: String
		$isCurrentAddressManual: Boolean
		$currentAddressManual: String
		$email: String
		$phoneNumber: String
		$idCardNumber: String
		$idCardExpirationDate: DateTime
		$passportNumber: String
		$passportExpirationDate: DateTime
	) {
		addEmployee(
			profileImage: $profileImage
			profileImageSize: $profileImageSize
			employeeInput: {
				govtNumber: $govtNumber
				mefNumber: $mefNumber
				fullName: $fullName
				fullNameLatin: $fullNameLatin
				gender: $gender
				dob: $dob
				maritalStatus: $maritalStatus
				pob: $pob
				isPobManual: $isPobManual
				pobManual: $pobManual
				currentAddress: $currentAddress
				isCurrentAddressManual: $isCurrentAddressManual
				currentAddressManual: $currentAddressManual
				email: $email
				phoneNumber: $phoneNumber
				idCardNumber: $idCardNumber
				idCardExpirationDate: $idCardExpirationDate
				passportNumber: $passportNumber
				passportExpirationDate: $passportExpirationDate
			}
		)
	}
`;

const signUpSchema = Yup.object().shape({
	profileImage: Yup.mixed()
		.test('fileSize', 'over limit', (value) => {
			if (value) {
				return value?.size <= MAX_FILE_SIZE;
			} else {
				return true;
			}
		})
		.required('required'),
	govtNumber: Yup.string(),
	mefNumber: Yup.string(),
	fullName: Yup.string(),
	fullNameLatin: Yup.string(),
	gender: Yup.string(),
	dob: Yup.string(),
	maritalStatus: Yup.string(),
	pob: Yup.object().nullable(),
	isPobManual: Yup.boolean(),
	pobManual: Yup.string(),
	currentAddress: Yup.object().nullable(),
	isCurrentAddressManual: Yup.boolean(),
	currentAddressManual: Yup.string(),
	email: Yup.string(),
	phoneNumber: Yup.string(),
	idCardNumber: Yup.string(),
	idCardExpirationDate: Yup.string(),
	passportNumber: Yup.string(),
	passportExpirationDate: Yup.string(),
});

function Create() {
	const history = useHistory();
	const toast = useToast();
	const { t } = useTranslation();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const [addEmployee, { error, data, loading }] = useMutation(MUTATION_ADD_EMPLOYEE);

	const [isPobManual, setIsPobManual] = useState(false);
	const [isCurrentAddressManual, setCurrentAddressManual] = useState(false);

	function geoObjectToValue(obj) {
		return JSON.stringify({
			province: obj?.province?.value || '',
			district: obj?.district?.value || '',
			commune: obj?.commune?.value || '',
			village: obj?.village?.value || '',
			streetNumber: obj?.streetNumber || '',
			houseNumber: obj?.houseNumber || '',
		});
	}

	function signUp(value) {
		addEmployee({
			variables: {
				...value,
				pob: value?.pob ? geoObjectToValue(value?.pob) : null,
				currentAddress: value?.currentAddress ? geoObjectToValue(value?.pob) : null,
				profileImage: value?.profileImage,
				profileImageSize: value?.profileImage?.size,
				dob: value.profileImage?.dob || null,
				idCardExpirationDate: value?.idCardExpirationDate || null,
				passportExpirationDate: value?.passportExpirationDate || null,
			},
		});
	}

	useEffect(() => {
		if (error) {
			// setUploading(false)
			if (error?.graphQLErrors?.length > 0) {
				error?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(error?.message ? error?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [error]);

	useEffect(() => {
		if (data) {
			if (data.addEmployee) {
				// setUploading(false)
				toast({
					title: t('Employee added'),
					description: t('Employee added successfully'),
					status: 'success',
					duration: 5000,
					isClosable: true,
					position: 'bottom-right',
				});
				history.push(`/employee/list?page=1`);
			}
		}
	}, [data]);

	return (
		<>
			{/* <Container maxW="container.xl" mt="16px" mb="16px">
                <BreadcrumbComponent
                    list={[
                        {
                            name: t("OFFICIAL PROFILE"),
                            path: "/employee/list?page=1",
                        },
                        {
                            name: t("Add Officials"),
                            path: "#",
                        },
                    ]}
                />
                <Center mt="5">
                    <Box
                        w="100%"
                        bg="white"
                        borderRadius={c.borderRadius}
                        border="1px solid #dbdbdb"
                        boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
                        pb="32px"
                    >
                        <Flex bg="#FAFAFA" p="16px" pt="8px" pb="8px" mb="16px" justifyContent="space-between" borderBottom="1px solid #dbdbdb">
                            <Text
                                fontSize="x-large"
                                fontWeight="bold"
                            >
                                {t("Add Officials")}
                            </Text>
                        </Flex>

                        <Box p="16px">
                            <Formik
                                initialValues={{
                                    profileImage: null,
                                    govtNumber: "",
                                    mefNumber: "",
                                    fullName: "",
                                    fullNameLatin: "",
                                    gender: "",
                                    dob: "",
                                    maritalStatus: "",
                                    pob: null,
                                    isPobManual: false,
                                    pobManual: "",
                                    currentAddress: null,
                                    isCurrentAddressManual: false,
                                    currentAddressManual: "",
                                    email: "",
                                    phoneNumber: "",
                                    idCardNumber: "",
                                    idCardExpirationDate: "",
                                    passportNumber: "",
                                    passportExpirationDate: "",
                                }}
                                validationSchema={signUpSchema}
                                onSubmit={(values) => {
                                    signUp(values);
                                }}
                            >
                                {(props) => (
                                    <Form>
                                        <Box mt="3">
                                            <SimpleGrid columns={[1, 2, 3]} spacing={2}>
                                                <ImageCropFormField
                                                    {...props}
                                                    label="Profile Image"
                                                    name="profileImage"
                                                    maxFileSize={MAX_FILE_SIZE}
                                                    required
                                                    aspect={480 / 640}
                                                    resizedToWidth={480}
                                                />
                                            </SimpleGrid>
                                            <SimpleGrid columns={[1, 2, 3]} spacing={2}>
                                                <TextFormField
                                                    {...props}
                                                    label="អត្តលេខមន្ត្រីរាជការ"
                                                    name="govtNumber"
                                                />
                                                <TextFormField
                                                    {...props}
                                                    label="លេខប័ណ្ណសម្គាល់មន្ត្រីកសហវ"
                                                    name="mefNumber"
                                                />
                                            </SimpleGrid>
                                            <SimpleGrid columns={[1, 2, 3]} spacing={2}>
                                                <TextFormField
                                                    {...props}
                                                    label="គោត្តនាម និងនាម"
                                                    name="fullName"
                                                />
                                                <TextFormField
                                                    {...props}
                                                    label="គោត្តនាម និងនាមជាអក្សរឡាតាំង"
                                                    name="fullNameLatin"
                                                    required
                                                />
                                            </SimpleGrid>
                                            <SimpleGrid columns={[1, 2, 3]} spacing={2}>


                                                <RadioFormField
                                                    {...props}
                                                    label="ភេទ"
                                                    name="gender"
                                                    radios={[
                                                        { label: "Male", value: "male" },
                                                        { label: "Female", value: "female" }
                                                    ]}
                                                />

                                                <RadioFormField
                                                    {...props}
                                                    label="ស្ថានភាពគ្រូសារ"
                                                    name="maritalStatus"
                                                    radios={[
                                                        { label: "Single", value: "single" },
                                                        { label: "Married", value: "married" },
                                                        { label: "Divorced", value: "divorced" }
                                                    ]}
                                                />
                                            </SimpleGrid>
                                            <SimpleGrid columns={[1, 2, 3]} spacing={2}>
                                                <DateFormField
                                                    {...props}
                                                    label="ថ្ងៃខែឆ្នាំកំណើត"
                                                    name="dob"
                                                />

                                            </SimpleGrid>
                                            <SimpleGrid columns={[1, 2, 3]} spacing={2}>

                                                {!isPobManual && <GeoFormField
                                                    {...props}
                                                    name="pob"
                                                    label="កន្លែងកំណើត"
                                                    buttomComponent={
                                                        <CheckboxFormField
                                                            {...props}
                                                            label="Input"
                                                            name="isPobManual"
                                                            callbackValueChange={e => setIsPobManual(e)}
                                                        // values={isPobManual}
                                                        />
                                                    }
                                                />}

                                                {isPobManual && <TextAreaFormField
                                                    {...props}
                                                    label="កន្លែងកំណើត"
                                                    name="pobManual"
                                                    required={true}
                                                    buttomComponent={
                                                        <CheckboxFormField
                                                            {...props}
                                                            label="Input"
                                                            name="isPobManual"
                                                            callbackValueChange={e => setIsPobManual(e)}
                                                        // values={isPobManual}
                                                        />
                                                    }
                                                />}

                                                {!isCurrentAddressManual && <GeoFormField
                                                    {...props}
                                                    name="currentAddress"
                                                    label="អាសយដ្ឋានបច្ចុប្បន្ន"
                                                    buttomComponent={
                                                        <CheckboxFormField
                                                            {...props}
                                                            label="Input"
                                                            name="isCurrentAddressManual"
                                                            callbackValueChange={e => setCurrentAddressManual(e)}
                                                        // values={isCurrentAddressManual}
                                                        />
                                                    }
                                                />}

                                                {isCurrentAddressManual && <TextAreaFormField
                                                    {...props}
                                                    label="អាសយដ្ឋានបច្ចុប្បន្ន"
                                                    name="currentAddressManual"
                                                    buttomComponent={
                                                        <CheckboxFormField
                                                            {...props}
                                                            label="Input"
                                                            name="isCurrentAddressManual"
                                                            callbackValueChange={e => setCurrentAddressManual(e)}
                                                        // values={isCurrentAddressManual}
                                                        />
                                                    }
                                                />}

                                            </SimpleGrid>
                                            <SimpleGrid columns={[1, 2, 3]} spacing={2}>

                                                <TextFormField
                                                    {...props}
                                                    label="អុីម៉ែល"
                                                    name="email"
                                                />

                                                <TextFormField
                                                    {...props}
                                                    label="លេខទូរសព្ទ"
                                                    name="phoneNumber"
                                                />

                                            </SimpleGrid>
                                            <SimpleGrid columns={[1, 2, 3]} spacing={2}>


                                                <TextFormField
                                                    {...props}
                                                    label="អត្តសញ្ញាណប័ណ្ណ"
                                                    name="idCardNumber"
                                                />

                                                <DateFormField
                                                    {...props}
                                                    label="កាលបរិច្ឆេទផុតកំណត់អត្តសញ្ញាណប័ណ្ណ"
                                                    name="idCardExpirationDate"
                                                />

                                            </SimpleGrid>
                                            <SimpleGrid columns={[1, 2, 3]} spacing={2}>

                                                <TextFormField
                                                    {...props}
                                                    label="លិខិតឆ្លងដែន"
                                                    name="passportNumber"
                                                />

                                                <DateFormField
                                                    {...props}
                                                    label="កាលបរិច្ឆេទផុតកំណត់លិខិតឆ្លងដែន"
                                                    name="passportExpirationDate"
                                                />


                                            </SimpleGrid>
                                            <Flex
                                                textAlign="right"
                                                mt="6"
                                                justifyContent="space-between"
                                                alignItems="center"
                                            >
                                                <Button
                                                    id="login-btn"
                                                    variant="solid"
                                                    size="md"
                                                    h="42px"
                                                    w="128px"
                                                    bg="primary.dark"
                                                    _hover={{ bg: "primary.dark" }}
                                                    color="white"
                                                    // borderRadius="31px"
                                                    type="submit"
                                                    isLoading={loading}
                                                    loadingText={t("CREATE")}
                                                    spinnerPlacement="start"
                                                >
                                                    {t(`CREATE`)}
                                                </Button>
                                            </Flex>
                                        </Box>
                                    </Form>
                                )}
                            </Formik>
                        </Box>
                    </Box>
                </Center>
            </Container> */}
		</>
	);
}

export default Create;
