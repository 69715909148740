import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Box, Flex, Button, useToast, Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Text
} from "@chakra-ui/react";
import c from "../../constant"
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { gql, useMutation } from '@apollo/client';
import { useHistory } from "react-router-dom";
import TextFormField from "../../components/form/TextFormField"
import TextAreaFormField from "../../components/form/TextAreaFormField"
import FileFormField from "../../components/form/FileFormField"
import RichTextEditor from "../../components/RichTextEditor/RichTextEditor";
import TableForm from "../../components/Table/TableFormWithoutSaveButton";
import { v4 as uuid } from 'uuid';

const CREATE_MUTATION = gql`
    mutation create(
        $title: String!,
        $contentBody:String,
        $steps: JSON,
        $value:String,
        $active:Boolean,
        $attachmentFileSize: Float,
        $attachmentFile: Upload
    ){
        createPublicService(
            attachmentFileSize:$attachmentFileSize,
            attachmentFile: $attachmentFile,
            publicServiceInput:{
                title:$title,
                contentBody:$contentBody,
                steps:$steps,
                value:$value,
                active:$active
            }
        )
    }
` 
 const MAX_FILE_SIZE = 104857600

const signUpSchema = Yup.object().shape({
    title: Yup.string().required("Required"),
    value: Yup.string(),
    file: Yup.mixed().test("fileSize", "over limit", (value) => {
        if (value) {
            return value?.size <= MAX_FILE_SIZE
        } else {
            return true
        }
    })
});

function Create({
    isOpen = false, onClose = () => null, callback = () => null, companyId
}) {
    const history = useHistory();
    const toast = useToast()
    const { t } = useTranslation();

    const [body, setBody] = useState(null)
    const [steps, setSteps] = useState(null)
    
    const [createPublicService, { loading, error, data }] = useMutation(
        CREATE_MUTATION
    );

    
    

    useEffect(() => {
        if (data) {
            if (data?.createPublicService) {
                toast({
                    title: t("Public service created successfully"),
                    status: "success",
                    isClosable: true,
                    position: "top-right"
                })
                onClose()
                callback("Public service created successfully")
            }
        }
    }, [data])

    useEffect(() => {
        if (error) {
            error.graphQLErrors.map(({ message }, i) => (
                toast({
                    title: t(message),
                    status: "error",
                    isClosable: true,
                    position: "top-right"
                })
            ))
        }
    }, [error])


    function create(values) {   
        if(steps?.length>=1)   {  
        let variables = {
            title: values.title,
            value:values.value,
            contentBody:body,
            steps,
            active:true,
            attachmentFile: values.file?values.file:null,
            attachmentFileSize: values?.file?.size?values?.file?.size:null,
        }

        createPublicService({
            variables
        })
        } else {
            alert("No Steps")
        }
    }

    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={() => {
                    onClose()

                }}
                size="6xl"
            >
                <ModalOverlay />
                <ModalContent borderRadius={c.borderRadius} pb="2">
                    <ModalHeader>{t("Add Public Service")}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Formik
                            initialValues={{
                                title: "",
                                value:"",
                                files:""
                            }}
                            validationSchema={signUpSchema}
                            onSubmit={(values) => {
                                create(values);
                            }}
                        >
                            {(props) => (
                                <Form>
                                    <Box mt="3">
                                        <Flex>
                                            <Flex flex="1" direction="column">
                                                <TextAreaFormField
                                                    {...props}
                                                    label="Title"
                                                    name="title"
                                                    required
                                                />
                                                <Box display="none">
                                                <TextFormField
                                                    {...props}
                                                    label="Value"
                                                    name="value"
                                                />
                                                </Box>
                                                

                                                <RichTextEditor
                                                    onContentChange={(content) =>
                                                        setBody(content)
                                                    }
                                                />
                                            </Flex>
                                            <Flex ml="16px" flex="1" direction="column">
                                                <FileFormField
                                                    {...props}
                                                    label="File"
                                                    name="file"
                                                    maxFileSize={MAX_FILE_SIZE}
                                                    accept="application/pdf"
                                                />
                                                <Text fontWeight="500" fontSize={"16px"}>{t("Steps")}</Text>

                                                <TableForm
                                                    name="steps"
                                                    label={null}
                                                    labelKh={null}
                                                    columns={[
                                                        { name: "id", label: "id", labelKh: "id", type: "text", hide: true },
                                                        { name: "type", label: "Type", labelKh: "ប្រភេទ", type: "text" },
                                                        { name: "typeKH", label: "Type KH", labelKh: "ប្រភេទជាភាសាខ្មែរ", type: "text" },
                                                        { name: "dueDate", label: "Due Date", labelKh: "កាលបរិច្ឆេទ​កំណត់", type: "text" },
                                                    ]}
                                                    value={[
                                                        {id: uuid(), type: 'Request  Policy', typeKH: 'ស្នើសុំការអនុញ្ញាតជាគោលការណ៏', dueDate: ''}
                                                    ]}
                                                    onSave={e => setSteps( e )}
                                                />
                                            </Flex>
                                        </Flex>
                                        

                                        <Flex
                                            w="100%"
                                            mt="6"
                                            justifyContent="flex-end"
                                        >
                                            <Button
                                                id="login-btn"
                                                variant="solid"
                                                size="md"
                                                h="42px"
                                                w="128px"
                                                bg="primary.dark"
                                                _hover={{ bg: "primary.dark" }}
                                                color="white"
                                                // borderRadius="31px"
                                                type="submit"
                                                isLoading={loading}
                                                loadingText={t("Add")}
                                                spinnerPlacement="start"
                                            >
                                                {t(`Add`)}
                                            </Button>
                                        </Flex>
                                    </Box>
                                </Form>
                            )}
                        </Formik>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
}

export default Create;
