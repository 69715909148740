import React, { useEffect } from "react";
import {
    Flex,
    Text,
    Spinner
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { gql, useMutation } from '@apollo/client';

const QUERY_ASSIGN_USER = gql`
	mutation assign($id: String!, $userId: String!, $notification: Boolean) {
		assignCompanySubmissionProposalToUser(id: $id, userId: $userId, notification:$notification)
	}
`;

function TransferProposalAndGetUpdateStatus({ id, userId, notificationValue, onNext }) {

    const [assignUserMutation, { error: errorAssign, loading: loadingAssign, data: dataAssign }] = useMutation(QUERY_ASSIGN_USER);

    useEffect(() => {
        if (errorAssign || dataAssign) {
            onNext()
        }
    }, [errorAssign, dataAssign])

    useEffect(() => {
        if (id) {
            assignUserMutation({
                variables: {
                    id,
                    userId,
                    notification: notificationValue==="none"? false : true
                },
            });
        }
    }, [])

    return (
        <Flex justifyContent="flex-end" ml="2">
            {
                errorAssign && <Text color="red" ml="1">Error</Text>
            }
            {
                (loadingAssign) &&
                <Spinner
                    thickness='4px'
                    speed='0.65s'
                    emptyColor='gray.200'
                    color='blue.500'
                    size='sm'
                />
            }
            {
                dataAssign?.assignCompanySubmissionProposalToUser && <>
                    <FontAwesomeIcon icon={faCheckCircle} style={{ fontSize: 14, color: "#2ecc71" }} />
                </>
            }
        </Flex>
    )
}

export default React.memo(TransferProposalAndGetUpdateStatus)