/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, Button, Box, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import TextFormField from '../form/TextFormField';
import RadioFormField from '../form/RadioFormField';
import c from '../../constant';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
	name: Yup.string().nullable(),
	status: Yup.string().nullable(),
});

function CompanyDetailBranchFilter({ name = null, status = null, onClose = () => null, callback = () => null }) {
	const { t } = useTranslation();

	return (
		<>
			<Modal borderRadius={c.borderRadius} isOpen={true} onClose={onClose}>
				<ModalOverlay />
				<ModalContent borderRadius={c.borderRadius}>
					<ModalHeader>{t('Filter')}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Formik
							validationSchema={validationSchema}
							initialValues={{ name, status }}
							onSubmit={(values) => {
								callback({ name: values?.name, status: values?.status });
								onClose();
							}}
						>
							{(props) => (
								<Box pb="4">
									<Form>
										<TextFormField {...props} label="Name" name="name" />
										<RadioFormField
											name="status"
											label="Status"
											radios={[
												{ label: 'Nearly Due', value: 'NearlyDue' },
												{ label: 'Due', value: 'Due' },
											]}
											initValue={status}
										/>
										<Flex mt={8} justifyContent="flex-end">
											<Button
												id="login-btn"
												variant="solid"
												size="md"
												h="42px"
												w="128px"
												type="submit"
												borderRadius={c.borderRadius}
												color="#3498db"
											>
												{t('Apply')}
											</Button>
											<Button
												size="md"
												h="42px"
												ml={4}
												w="128px"
												onClick={() => {
													callback({ name: null, status: null });
													onClose();
												}}
												variant="ghost"
											>
												{t('Clear')}
											</Button>
										</Flex>
									</Form>
								</Box>
							)}
						</Formik>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
}

export default CompanyDetailBranchFilter;
