import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Container, Box, Flex, Text, Center, Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Input,
    InputRightElement,
    InputGroup,
    Button,
    Badge,
    useToast,
    Image,
    useShortcut
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactPaginate from 'react-paginate';
import { useEffect, useState } from "react";
import c from "../../constant"
import { useLazyQuery, gql } from "@apollo/client";
import { faChevronRight, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import LoadingTable from "../Table/Loading"
import { useTranslation } from "react-i18next";
import LOGO_PLACEHOLDER from "../../assets/images/logo-placeholder.png"
const limit = 5
const QUERY_COMPANY = gql`
query get($offset: Float, $companyName: String){
    getCompaniesPublic(pagerInput:{
      limit:${limit}
      offset: $offset
    }
    companyName: $companyName
    ){
      companies{
        id
        name
        nameKhmer
        logo
        url
        type
        latestUpdateBranchAt
      }
      totalPages
    }
  }
`

export default function CompanyPickerModal({ onClose, callBack }) {
    const { t } = useTranslation('common');

    const [loadCompany, { error, loading, data }] = useLazyQuery(QUERY_COMPANY)
    useEffect(() => {
        loadCompanyWithVar()
    }, [])

    const [currentPage, setCurrentPage] = useState(1)
    const [companyName, setCompanyName] = useState("")


    useEffect(() => {
        loadCompanyWithVar()
    }, [currentPage])

    function loadCompanyWithVar() {
        loadCompany({
            variables: {
                offset: currentPage ? ((currentPage - 1) * limit) : 0,
                companyName: companyName
            }
        })
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            setCurrentPage(1)
            loadCompanyWithVar()
        }
    }

    function handlePageClick(data) {
        let selected = data.selected < 0 ? 0 : data.selected;
        const page = selected + 1
        setCurrentPage(page)
    }

    function handleSearch() {
        setCurrentPage(1)
        loadCompanyWithVar()
    }

    return (
        <>
            <Modal
                isOpen={true}
                onClose={() => {
                    onClose()
                }}
                size="5xl"
            >
                <ModalOverlay />
                <ModalContent borderRadius={c.borderRadius}>
                    <ModalHeader>{t("Company List")}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <InputGroup size='md' >
                            <Input onKeyDown={handleKeyDown}
                                w="100%"
                                pr='4.5rem'
                                placeholder={t("Name")}
                                autoFocus
                                value={companyName}
                                onChange={e => setCompanyName(e.target.value)}
                            />
                            <InputRightElement zIndex={0} width='4.5rem'>

                            </InputRightElement>
                            <Button size='md' ml="2"
                                onClick={handleSearch}
                                borderRadius={c.borderRadius}
                            >
                                {t("Search")}
                            </Button>
                        </InputGroup>
                        <Box p="16px">
                            
                            <Table variant="simple">
                                {/* <TableCaption>Imperial to metric conversion factors</TableCaption> */}
                                <Thead>
                                    <Tr>
                                        <Th>{t("Logo")}</Th>
                                        <Th>{t("Khmer Name")}</Th>
                                        <Th>{t("English Name")}</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {loading && <LoadingTable column={3}/>}
                                    {
                                        data?.getCompaniesPublic?.companies.length <= 0 && <Tr>
                                            <Td><Text>{t("No Data")}</Text></Td>
                                        </Tr>
                                    }
                                    {data?.getCompaniesPublic?.companies?.map((item, index) => {
                                        return (
                                            <Tr key={`company-${index}`} onMouseDown={() => {
                                                callBack(item)
                                                onClose()
                                            }} cursor="pointer" _hover={{ backgroundColor: "gray.200" }}>
                                                <Td pt="0" pb="0">
                                                    <Image src={item.logo ? (c.baseFilePath + item.logo) : ""} w="auto" minW="40px" minH="40px" h="40px" fallbackSrc={LOGO_PLACEHOLDER} />
                                                </Td>
                                                <Td>{item.nameKhmer}</Td>
                                                <Td>{item.name}</Td>
                                            </Tr>
                                        )
                                    })}
                                </Tbody>
                                <Tfoot>
                                    <Tr>
                                        <Th>{t("Logo")}</Th>
                                        <Th>{t("Khmer Name")}</Th>
                                        <Th>{t("English Name")}</Th>
                                    </Tr>
                                </Tfoot>
                            </Table>
                            <Flex justifyContent="flex-end" w="100%" mt="4">
                                {data?.getCompaniesPublic?.totalPages > 1 && <ReactPaginate
                                    previousLabel={<FontAwesomeIcon
                                        style={{
                                            fontSize: 16,
                                            cursor: "pointer",
                                        }}
                                        icon={faChevronLeft}
                                    />}
                                    nextLabel={<FontAwesomeIcon
                                        style={{
                                            fontSize: 16,
                                            cursor: "pointer",
                                        }}
                                        icon={faChevronRight}
                                    />}
                                    breakLabel={'...'}
                                    breakClassName={'break-me'}
                                    pageCount={data?.getCompaniesPublic?.totalPages}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={3}
                                    onPageChange={handlePageClick}
                                    containerClassName={'pagination'}
                                    activeClassName={'active'}
                                    initialPage={(currentPage - 1) | 0}
                                />}
                            </Flex>
                        </Box>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
}
