import React from "react";
import { RadioGroup, Stack, Radio, FormControl, Box, FormLabel, FormHelperText, Select, option } from "@chakra-ui/react";
import { Field } from "formik";
import { useTranslation } from 'react-i18next'
import c from "../../constant"

const SelectFormField = props => {
    const { t } = useTranslation('common');
    const {
        name,
        label,
        radios,
        callbackValueChange,
        required,
        options,
        initialValue = null
    } = props;
    const [value, setValue] = React.useState(initialValue ? initialValue : "")

    return (
        <Field name={name}>
            {({ field, meta, form }) => {
                return (
                    <Box mb="4" overflow="hidden">
                        <FormControl id={name} >
                            {label && <FormLabel>{t(label)}{required && <span style={{ color: "red" }}>*</span>}</FormLabel>}
                            <Select placeholder={t('Select option')}
                                onChange={e => {
                                    form.setFieldValue(name, e.target.value)
                                    setValue(e.target.value)
                                    if (callbackValueChange) {
                                        callbackValueChange(e)
                                    }
                                }}
                                value={value}
                                borderRadius={c.borderRadius}
                                h="48px"
                            >
                                {
                                    options?.map((item, index) => {
                                        return <option key={`rd-${index}`} value={item.value}>{t(item.label)}</option>
                                    })
                                }
                            </Select>

                            {meta.touched && meta.error && (
                                <FormHelperText
                                    id="error-message-password"
                                    color="red.400"
                                    fontSize="12px"
                                >
                                    {t(name)}{" "}
                                    {t("is")}{" "}
                                    {t(meta.error)}
                                </FormHelperText>
                            )}
                        </FormControl>

                    </Box>

                )
            }}
        </Field>
    );
};

export default SelectFormField;
