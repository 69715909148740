/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Button,
	Flex,
	Text,
	Textarea,
	useDisclosure,
} from '@chakra-ui/react';
import { gql, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { GradeApproval, TerminationRequestStatus } from '../../../../containers/ManageAgentPartner/TerminationRequest/constands';
import { useTerminationRequest } from '../../../../contexts/termination-request';
import useStore from '../../../../store';
import c from '../../../../constant';

const M_SUBMIT_APPROVAL = gql`
	mutation submit($id: String!, $grade: TerminationReqeustApprovalGrade!, $status: TerminationRequestCandidateStatus, $statusDescription: String) {
		submitCandidateApproval(id: $id, grade: $grade, status: $status, statusDescription: $statusDescription)
	}
`;

let status = null;
let statusDescription = null;

export default function TerminationRequestCandidateApproval({ id = null, requestStatus = null }) {
	const { t } = useTranslation();
	const { loadedData, setLoadedData } = useTerminationRequest();
	const { currentUser: user } = useStore((state) => state.currentUser);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [reason, setReason] = useState('');
	const [submitApproval, { loading, data }] = useMutation(M_SUBMIT_APPROVAL);

	useEffect(() => {
		if (data?.submitCandidateApproval) {
			onClose();
			setLoadedData({
				...loadedData,
				candidates: loadedData?.candidates?.map((e) => {
					if (e?.id === id) {
						e.status = status?.toUpperCase();
						e.statusDescription = statusDescription;
					}
					return e;
				}),
			});
		}
	}, [data]);

	return (
		<>
			<Flex>
				<Button
					colorScheme="green"
					variant="solid"
					size="md"
					h="28px"
					w="76px"
					fontSize="sm"
					fontWeight="normal"
					mr={1}
					borderRadius={c.borderRadius}
					isLoading={loading}
					isDisabled={
						(requestStatus === TerminationRequestStatus.reviewing && c.stepOfficeRoles.includes(user?.position)) ||
						(requestStatus === TerminationRequestStatus.reviewingFinal && c.stepDepartmentRoles.includes(user?.position))
							? false
							: true
					}
					onClick={() => {
						status = 'Approved';
						statusDescription = null;
						const grade = c.stepOfficeRoles.includes(user?.position) ? GradeApproval.primary : GradeApproval.secondary;
						const variables = { id, grade, status, statusDescription };
						submitApproval({ variables });
					}}
				>
					{t('approve')}
				</Button>
				<Button
					colorScheme="red"
					variant="solid"
					size="md"
					h="28px"
					w="76px"
					fontSize="sm"
					fontWeight="normal"
					ml={1}
					borderRadius={c.borderRadius}
					isLoading={loading}
					isDisabled={
						(requestStatus === TerminationRequestStatus.reviewing && c.stepOfficeRoles.includes(user?.position)) ||
						(requestStatus === TerminationRequestStatus.reviewingFinal && c.stepDepartmentRoles.includes(user?.position))
							? false
							: true
					}
					onClick={onOpen}
				>
					{t('Reject')}
				</Button>
			</Flex>
			<AlertDialog closeOnEsc={false} closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							{t('Reject')}
						</AlertDialogHeader>
						<AlertDialogBody>
							<Text>
								{t('Please input rejection reason')}
								<span style={{ color: 'red' }}>*</span>
							</Text>
							<Textarea mt={2} value={reason} onChange={(e) => setReason(e.target.value ?? '')} />
						</AlertDialogBody>
						<AlertDialogFooter>
							<Button mr={4} isLoading={loading} onClick={onClose}>
								{t('Cancel')}
							</Button>
							<Button
								isLoading={loading}
								colorScheme="red"
								disabled={reason ? false : true}
								onClick={() => {
									status = 'Rejected';
									statusDescription = reason;
									const grade = c.stepOfficeRoles.includes(user?.position) ? GradeApproval.primary : GradeApproval.secondary;
									const variables = { id, grade, status, statusDescription };
									submitApproval({ variables });
								}}
							>
								{t('Reject')}
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</>
	);
}
