/* eslint-disable react-hooks/exhaustive-deps */
import { Container, Box, Text, Flex } from '@chakra-ui/react';
import { gql, useLazyQuery } from '@apollo/client';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';
import ProgressiveImage from './ProgressiveImage';
import c from '../../constant';
import moment from 'moment';
import ArticlePDF from './ArticlePDF';
import 'moment/locale/en-gb';

const QUERY_ARTICLE_BY_ID = gql`
	query article($id: String!) {
		adminArticle(id: $id) {
			id
			title
			description
			thumbnail
			isPublish
			content
			titleKH
			descriptionKH
			thumbnailKH
			contentKH
			category
			publishDate
		}
	}
`;

export default function Article({ id }) {
	moment.locale('en-gb');

	const { t } = useTranslation();
	const [loadArticle, { error, loading, data }] = useLazyQuery(QUERY_ARTICLE_BY_ID);

	useEffect(() => {
		loadArticle({ variables: { id } });
	}, []);

	return (
		<>
			<Container maxW="container.lg" data-aos="fade">
				{loading && (
					<Flex justifyContent="center" alignItems="center">
						{t('Loading')}
					</Flex>
				)}
				<Box p="16px">
					<Text mt="8px" fontWeight="bold" lineHeight="52px" fontSize="32px">
						{data?.adminArticle?.title}
					</Text>
					<Text pt="8px" color="text.gray" fontWeight="500" fontSize="13px">
						{data?.adminArticle?.publishDate && moment(data?.adminArticle?.publishDate).format(c.excelDateTimeFormat)}
					</Text>
					<Box mt="32px">
						{data?.adminArticle?.content?.map((item, index) => {
							if (item.type === 'rich-text') {
								return (
									<Box mb="6" w="100%" overflow="hidden" key={`html-${index}`}>
										{ReactHtmlParser(item?.content)}
									</Box>
								);
							}

							if (item.type === 'image') {
								return (
									<Box mb="6" key={`image-${index}`}>
										<ProgressiveImage
											alt={item?.content?.fileName}
											src={c.baseFilePath + item?.content?.medium}
											placeholder={c.baseFilePath + item?.content?.placeholder}
											style={{
												width: '100%',
												height: 'auto',
												backgroundColor: 'silver',
											}}
										/>
									</Box>
								);
							}

							if (item.type === 'pdf') {
								return (
									<Box mb="6" key={`pdf-${index}`}>
										<ArticlePDF content={item?.content} />
									</Box>
								);
							}
						})}
					</Box>
				</Box>
			</Container>
		</>
	);
}
