import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Container, Box, Flex, Text, Center, Input, Button, useToast, InputRightElement, InputGroup } from "@chakra-ui/react";
import BreadcrumbComponent from "../../components/BreadcrumbComponent/BreadcrumbComponent";
import { faNewspaper } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import c from "../../constant"
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { useHistory, useLocation } from "react-router-dom";
import swal from 'sweetalert';


const CHANGE_PASSWORD_MUTATION = gql`
mutation changePassword($newPassword: String!, $currentPassword: String!){
    changePassword(
        newPassword:$newPassword, 
        currentPassword:$currentPassword
    )
}
`

const changePasswordSchema = Yup.object().shape({
    currentPassword: Yup.string().min(8).required("Required"),
    newPassword: Yup.string().min(8).required("Required"),
    confirmNewPassword: Yup.string().min(8).when("newPassword", {
        is: val => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
        [Yup.ref("newPassword")],
        "Both password need to be the same"
        )
    })
});

function ChangePassword() {
    const history = useHistory();
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const id = urlParams.get('id') ? urlParams.get('id') : null;

    const toast = useToast()
    const { t } = useTranslation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [changePassword, { loading, error, data }] = useMutation(
        CHANGE_PASSWORD_MUTATION
    );

    function formChangePassword(value) {
        changePassword({
            variables: {
                newPassword: value.newPassword,
                currentPassword: value.currentPassword
            }
        })
    }

    useEffect(() => {
        if (error) {
            error.graphQLErrors.map(({ message }, i) => (
                toast({
                    title: message,
                    status: "error",
                    isClosable: true,
                    position: "top-right"
                })
            ))
        }
    }, [error])

    useEffect(() => {
        if (data) {
            if (data?.changePassword) {
                toast({
                    title: t("Password changed successfully"),
                    status: "success",
                    isClosable: true,
                    position: "top-right"
                })
                sessionStorage.clear()
                history.push("/login")
                toast({
                    title: t("Please login again"),
                    status: "warning",
                    isClosable: true,
                    position: "top-right"
                })
            }
            

        }
    }, [data])

    return (
        <>
            <Container maxW="container.xl" mt="16px" mb="16px">
                <BreadcrumbComponent
                    list={[
                        {
                            name: t("Account"),
                            path: "/account",
                        },
                        {
                            name: t("Change Password"),
                            path: "#",
                        }
                    ]}
                />
                <Center mt="5">
                    <Box
                        w="100%"
                        bg="white"
                        borderRadius={c.borderRadius}
                        border="1px solid #dbdbdb"
                        boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
                        pb="32px"
                    >
                        <Flex bg="#FAFAFA" p="16px" pt="8px" pb="8px" mb="16px" justifyContent="space-between" borderBottom="1px solid #dbdbdb">
                            <Text
                                fontSize="x-large"
                                fontWeight="bold"
                            >
                                {t("Change Password")}
                            </Text>
                        </Flex>

                        <Box p="16px" maxW="480px">
                            <Formik
                                initialValues={{
                                    currentPassword: "",
                                    newPassword: "",
                                    confirmNewPassword:""
                                }}
                                validationSchema={changePasswordSchema}
                                onSubmit={(values) => {
                                    formChangePassword(values);
                                }}
                            >
                                {({ errors, touched }) => (
                                    <Form>
                                        <Box mt="8">
                                            <Field name="currentPassword">
                                                {({ field, meta }) => (
                                                    <>
                                                        <Input
                                                            {...field}
                                                            id="currentPassword"
                                                            size="lg"
                                                            placeholder={t(`Current Password`)}
                                                            fontWeight="500"
                                                            autoComplete="off"
                                                            type="password"
                                                        />
                                                        {meta.touched && meta.error && (
                                                            <Text
                                                                id="error-message-currentPassword"
                                                                color="red.400"
                                                                fontSize="12px"
                                                            >
                                                                {t("Current Password")}{" "}
                                                                {t("is")}{" "}
                                                                {t(meta.error)}
                                                            </Text>
                                                        )}
                                                    </>
                                                )}
                                            </Field>

                                            <Field name="newPassword">
                                                {({ field, meta, form }) => (
                                                    <>
                                                        <InputGroup size="md"
                                                            mt="3">
                                                            <Input
                                                                {...field}
                                                                id="newPassword"
                                                                placeholder={t(`New Password`)}
                                                                size="lg"
                                                                fontWeight="500"
                                                                autoComplete="off"
                                                                type="password"
                                                            />
                                                        </InputGroup>

                                                        {meta.touched && meta.error && (
                                                            <Text
                                                                id="error-message-newPassword"
                                                                color="red.400"
                                                                fontSize="12px"
                                                            >
                                                                {t("New Password")}{" "}
                                                                {t("is")}{" "}
                                                                {t(meta.error)}
                                                            </Text>
                                                        )}
                                                    </>
                                                )}
                                            </Field>

                                            <Field name="confirmNewPassword">
                                                {({ field, meta }) => (
                                                    <>
                                                        <Input
                                                            marginTop="3"
                                                            {...field}
                                                            id="confirmNewPassword"
                                                            size="lg"
                                                            placeholder={t(`Confirm New Password`)}
                                                            fontWeight="500"
                                                            autoComplete="off"
                                                            type="password"
                                                        />
                                                        {meta.touched && meta.error && (
                                                            <Text
                                                                id="error-message-confirmNewPassword"
                                                                color="red.400"
                                                                fontSize="12px"
                                                            >
                                                                {t(meta.error)}
                                                            </Text>
                                                        )}
                                                    </>
                                                )}
                                            </Field>

                                            <Flex
                                                textAlign="right"
                                                mt="6"
                                                justifyContent="space-between"
                                                alignItems="center"
                                            >
                                                <Button
                                                    id="login-btn"
                                                    variant="solid"
                                                    size="md"
                                                    h="42px"
                                                    w="128px"
                                                    bg="primary.dark"
                                                    _hover={{ bg: "primary.dark" }}
                                                    color="white"
                                                    // borderRadius="31px"
                                                    type="submit"
                                                    isLoading={loading}
                                                    loadingText={t("CREATE")}
                                                    spinnerPlacement="start"
                                                >
                                                    {t(`SAVE`)}
                                                </Button>
                                            </Flex>
                                        </Box>
                                    </Form>
                                )}
                            </Formik>
                        </Box>
                    </Box>
                </Center>
            </Container>
        </>
    );
}

export default ChangePassword;

const generatePassword = (
    length = 8,
    wishlist = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz~!@-#$'
) =>
    Array.from(crypto.getRandomValues(new Uint32Array(length)))
        .map((x) => wishlist[x % wishlist.length])
        .join('')