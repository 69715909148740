import React from "react";
import { Textarea, Box, FormControl, FormLabel, FormHelperText } from "@chakra-ui/react";
import { Field } from "formik";
import { useTranslation } from "react-i18next";

const TextAreaFormField = props => {
    const { t } = useTranslation('common');
    const {
        name,
        type,
        label,
        placeholder,
        required,
        buttomComponent=null
    } = props;
    return (
        <Field name={name}>
            {({ field, meta }) => (
                <Box mb="4">
                    <FormControl id={name} >
                        <FormLabel>{t(label)}{required&&<span style={{color:"red"}}>*</span>}</FormLabel>
                        <Textarea
                            className="custom-text-input"
                            {...field}
                            id={name}
                            type={type ? type : "text"}
                            placeholder={placeholder ? placeholder : ""}
                            size="lg"
                            errorBorderColor="red.300"
                            isInvalid={meta.touched && meta.error}
                            noOfLines={4}
                        />
                        {buttomComponent}
                        {meta.touched && meta.error && (
                            <FormHelperText
                                id="error-message-password"
                                color="red.400"
                                fontSize="12px"
                            >
                                {t(label)}{" "}
                                {t("is")}{" "}
                                {t(meta.error)}
                            </FormHelperText>
                        )}
                    </FormControl>

                </Box>
            )}
        </Field>
    );
};

export default TextAreaFormField;
