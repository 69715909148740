import { Flex, Image, Text } from '@chakra-ui/react';
import useStore from '../store';
import c from '../constant';

function CompanyName({ companyName = null, company = null, color = '#303030' }) {
	const { currentLang: lang } = useStore((state) => state.currentLang);

	return (
		<>
			{company ? (
				<>
					<Flex justifyContent="left" alignItems="center">
						<Image src={company?.logo ? c.baseFilePath + company?.logo : ''} w="auto" minH="20px" h="20px" mr={2} />
						<Text color={color}>
							{lang === 'kh' ? company?.nameKhmer : company?.name}
						</Text>
					</Flex>
				</>
			) : (
				<>
					<Text color={color}>
						{companyName}
					</Text>
				</>
			)}
		</>
	);
}

export default CompanyName;
