import React, { useState } from "react";
import { Input, FormControl, FormLabel, FormHelperText } from "@chakra-ui/react";
import { Field } from "formik";
import { useTranslation } from 'react-i18next'

const VideoInput = props => {
    const { t } = useTranslation('common');
    const {
        name,
        label,
        required,
        maxFileSize = 5242880,
        accept="image/jpeg,image/gif,image/png,application/pdf,.zip,.rar,.7zip"
    } = props;

    return (
        <Field name={name}>
            {({ field, meta, form }) => (
                <FormControl margin="normal" mb="4">
                    <FormLabel>{t(label)}{required&&<span style={{color:"red"}}>*</span>}</FormLabel>
                    <Input
                        accept={accept}
                        pt="4px"
                        id={name}
                        name={name}
                        type="file"
                        onChange={e => {
                            e.preventDefault();
                            let reader = new FileReader();
                            let file = e.target.files[0];
                            if (file) {
                                reader.readAsDataURL(file);
                                form.setFieldValue(name, file);
                            }
                        }}
                    />

                    <FormLabel fontSize="13px" color="gray.500">{t("Maximum file size")}{` ( ${parseFloat(maxFileSize / 1048576).toFixed(2)}MB ) `}</FormLabel>
                    {meta.touched && meta.error && (
                        <FormHelperText
                            id={`error-message-${name}`}
                            color="red.400"
                            fontSize="12px"
                        >
                            {t(label)}{" "}
                            {t("is")}{" "}
                            {t(meta.error)}
                        </FormHelperText>
                    )}

                </FormControl>)}
        </Field>
    );
};

export default VideoInput;
