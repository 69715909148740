/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import {
	Button,
	AlertDialog,
	AlertDialogBody,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogContent,
	AlertDialogOverlay,
	useDisclosure,
	useToast,
	Textarea,
	Flex,
	Badge,
	CloseButton,
	Tooltip,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { gql, useMutation } from '@apollo/client';

import c from '../../../constant';

const MUTATION_DATA = gql`
	mutation update($description: String, $status: Boolean, $id: String!) {
		updateProfessionalApproval(id: $id, status: $status, description: $description) {
			id
			idCardNumber
			firstName
			lastName
			firstNameEn
			lastNameEn
			gender
			dob
			baseCertificate
			professionalCertificate
			contract
			createdAt
			createdBy
			status
			approved
			rejectDescription
		}
	}
`;

function UpdateProfessionalApproval({ item, updateCallback = () => null, isDisabled }) {
	const cancelRef = useRef();
	const toast = useToast();
	const { t } = useTranslation();
	const [update, { error, loading, data }] = useMutation(MUTATION_DATA);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [rejectDesc, setRejectDesc] = useState('');

	function approve() {
		update({
			variables: {
				id: item?.id,
				status: true,
				description: null,
			},
		});
	}

	function reject() {
		if (rejectDesc) {
			update({
				variables: {
					id: item?.id,
					status: false,
					description: rejectDesc,
				},
			});
		}
	}

	function reset() {
		update({
			variables: {
				id: item?.id,
				status: null,
				description: null,
			},
		});
	}

	useEffect(() => {
		if (data?.updateProfessionalApproval) {
			updateCallback(data?.updateProfessionalApproval);
			onClose();
		}
	}, [data]);

	return (
		<>
			{item?.approved === null && (
				<>
					<Flex justifyContent="flex-start" alignItems="center">
						<Button
							size="sm"
							isDisabled={loading || isDisabled}
							colorScheme="green"
							onMouseDown={() => approve()}
							ml={0}
							borderRadius={c.borderRadius}
							isLoading={loading}
						>
							{t('approve')}
						</Button>
						<Button
							size="sm"
							isDisabled={loading || isDisabled}
							colorScheme="red"
							onMouseDown={onOpen}
							ml={2}
							borderRadius={c.borderRadius}
							isLoading={loading}
						>
							{t('reject')}
						</Button>
					</Flex>
				</>
			)}
			{item?.approved === true && (
				<>
					<Flex justifyContent="flex-end" alignItems="center">
						<Tooltip label={t('approved')}>
							<Badge colorScheme="green" variant="solid">
								{t('approved')}
							</Badge>
						</Tooltip>
						<CloseButton
							hidden={loading || isDisabled}
							borderRadius={c.borderRadius}
							variant="solid"
							size="xs"
							p={1}
							ml={1}
							color="gray.400"
							onMouseDown={() => reset()}
						/>
					</Flex>
				</>
			)}
			{item?.approved === false && (
				<>
					<Flex justifyContent="flex-end" alignItems="center">
						<Tooltip label={item?.rejectDescription}>
							<Badge colorScheme="red" variant="solid">
								{t('rejected')}
							</Badge>
						</Tooltip>
						<CloseButton
							hidden={loading || isDisabled}
							borderRadius={c.borderRadius}
							variant="solid"
							size="xs"
							p={1}
							ml={1}
							color="gray.400"
							onMouseDown={() => reset()}
						/>
					</Flex>
				</>
			)}
			<AlertDialog closeOnEsc={false} closeOnOverlayClick={false} isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							{t('Reason of Rejection')}
						</AlertDialogHeader>
						<AlertDialogBody>
							{t('Please input reason of rejection')}
							<Textarea value={rejectDesc} onChange={(e) => setRejectDesc(e.currentTarget.value)} />
						</AlertDialogBody>
						<AlertDialogFooter>
							<Button isLoading={loading} borderRadius={c.borderRadius} colorScheme="gray" ref={cancelRef} onMouseDown={onClose} ml={0}>
								{t('Cancel')}
							</Button>
							<Button
								isLoading={loading}
								borderRadius={c.borderRadius}
								isDisabled={rejectDesc ? false : true}
								colorScheme="red"
								onMouseDown={() => reject()}
								ml={2}
							>
								{t('Reject')}
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</>
	);
}

export default UpdateProfessionalApproval;
