export const capitalizeFirstLetter = string => {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
}

export const checkLogin = () =>{
    let accessToken = sessionStorage.getItem("accessToken")
    return accessToken
}

export const getInitSelectSearch = ({value,options}) =>{
    const filter = options.filter(function (el) {
        return el.value === value
    });
    return filter.length>0?filter[0]:null
}

export const formatAgentCode = code =>{
    return code.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1-')
}