import React from 'react';
import { Badge } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

function CompanyBadge({ type }) {
	const { t } = useTranslation();
	switch (type) {
		case 'ACTUARIAL_FIRM':
			return <Badge colorScheme="teal">{t('INSURANCE ACTUARIAL FIRM')}</Badge>;
		case 'INSURANCE_CLAIM_MANAGEMENT':
			return <Badge colorScheme="blue">{t('INSURANCE CLAIM MANAGEMENT')}</Badge>;
		case 'AUDIT_SERVICE':
			return <Badge colorScheme="cyan">{t('INSURANCE AUDIT SERVICE')}</Badge>;
		case 'ASSOCIATION':
			return <Badge colorScheme="messenger">{t('ASSOCIATION')}</Badge>;
		case 'GENERAL_INSURANCE':
			return <Badge colorScheme="orange">{t('GENERAL INSURANCE')}</Badge>;
		case 'LIFE_INSURANCE':
			return <Badge colorScheme="green">{t('LIFE INSURANCE')}</Badge>;
		case 'MICRO_INSURANCE':
			return <Badge colorScheme="yellow">{t('MICRO INSURANCE')}</Badge>;
		case 'REINSURANCE':
			return <Badge colorScheme="teal">{t('REINSURANCE')}</Badge>;
		case 'INSURANCE_BROKER':
			return <Badge colorScheme="purple">{t('INSURANCE BROKER')}</Badge>;
		case 'INSURANCE_AGENT':
			return <Badge colorScheme="gray">{t('Insurance Agent Co Ltd')}</Badge>;
		case 'INSURANCE_LOSS_ADJUSTER':
			return <Badge colorScheme="red">{t('Insurance Loss Adjuster Co Ltd')}</Badge>;
		default:
			return null;
	}
}
export default CompanyBadge;
