import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Box, Flex, Text, Center, Table, Thead, Tbody, Tfoot, Tr, Th, Td, Badge, Tooltip, Button } from '@chakra-ui/react';
import BreadcrumbComponent from '../../components/BreadcrumbComponent/BreadcrumbComponent';
import { faSlidersH, faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import c from '../../constant';
import { gql, useLazyQuery } from '@apollo/client';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import './style.css';
import { useLocation, useHistory } from 'react-router-dom';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import ComplaintFilter from '../../components/Filter/ComplaintFilter';
import useStore from '../../store';
import LoadingTable from '../../components/Table/Loading';
import datePickerFromToToDate from '../../utils/datePickerFromToToDate';
import PrintLayout from '../../components/Print/PrintLayout';

import 'moment/locale/en-gb';
import 'moment/locale/km';

const limit = 10;
const QUERY_COMPLAINTS = gql`
  query getComplaints(
      $offset:Float, 
      $number: String,
      $name: String,
      $fromDate: DateTime,
      $toDate: DateTime,
      $read: Boolean,
      $settled: Boolean,
      $type: ComplaintOnInsuranceTypes,
      $spam: Boolean
    ){
    getComplaintsOnInsurance(
        pagerInput:{
            limit:${limit}
            offset:$offset
        }
        number: $number
        name: $name
        fromDate: $fromDate
        toDate: $toDate
        read: $read
        settled: $settled
        complaintType: $type
        spam: $spam
    ){
      
      complaints{
        id
        no
        subject
        createdAt
        complaintType
        plaintiffOrRepresentativeName
        settled
        settledDate
        spam
        notification{
            read
        }
      }
      countUnreadComplaints
      totalPages
      totalRows
    }
  }
`;

function Complaint() {
	const { t } = useTranslation();
	const location = useLocation();
	const history = useHistory();
	const urlParams = new URLSearchParams(location.search);
	const currentPage = urlParams.get('page') ? urlParams.get('page') : 1;
	const { currentLang } = useStore((state) => state.currentLang);
	moment.locale(currentLang === 'kh' ? 'km' : 'en-gb');
	const number = urlParams.get('number') ? urlParams.get('number') : null;
	const name = urlParams.get('name') ? urlParams.get('name') : null;
	const status = urlParams.get('status') ? urlParams.get('status') : null;
	const type = urlParams.get('type') ? urlParams.get('type') : null;
	const fromDate = urlParams.get('fromDate') ? urlParams.get('fromDate') : null;
	const toDate = urlParams.get('toDate') ? urlParams.get('toDate') : null;
	const read = urlParams.get('read') ? urlParams.get('read') : null;
	const showSpam = urlParams.get('showSpam') ? urlParams.get('showSpam') : null;

	const [openFilter, setOpenFilter] = useState(false);

	const [loadComplaints, { error, loading, data }] = useLazyQuery(QUERY_COMPLAINTS);
	useEffect(() => {
		window.scrollTo(0, 0);
		loadComplaints({
			variables: {
				offset: currentPage ? (currentPage - 1) * limit : 0,
				number,
				name,
				fromDate: datePickerFromToToDate(fromDate),
				toDate: datePickerFromToToDate(toDate),
				read: read === 'false' ? false : read === 'true' ? true : null,
				spam: showSpam === 'true' ? true : false,
			},
		});
	}, []);

	function handlePageClick(data) {
		let selected = data.selected;
		const page = selected + 1;

		let url = `?page=${page}`;
		if (number) {
			url += `&number=${number}`;
		}
		if (name) {
			url += `&name=${name}`;
		}
		if (status) {
			url += `&status=${status}`;
		}
		if (type) {
			url += `&type=${type}`;
		}
		if (fromDate) {
			url += `&fromDate=${fromDate}`;
		}
		if (toDate) {
			url += `&toDate=${toDate}`;
		}
		if (read !== null) {
			const unreadOnly = read ? read : '';
			if (unreadOnly) {
				url += `&read=false`;
			}
		}
		if (showSpam) {
			url += `&showSpam=${showSpam}`;
		}
		history.push(`/complaint-on-insurance${url}`);
	}

	useEffect(() => {
		if (currentPage) {
			window.scrollTo(0, 0);
			loadComplaints({
				variables: {
					offset: currentPage ? (currentPage - 1) * limit : 0,
					number,
					name,
					fromDate: datePickerFromToToDate(fromDate),
					toDate: datePickerFromToToDate(toDate),
					settled: status !== null ? (status === 'in progress' ? false : status === 'settled' ? true : null) : null,
					type: type !== null ? (type === 'all' ? null : type) : null,
					read: read === 'false' ? false : read === 'true' ? true : null,
					spam: showSpam === 'true' ? true : false,
				},
			});
		}
	}, [currentPage, number, name, fromDate, toDate, read, status, type, showSpam]);

	function complaintType(type) {
		return type === 'COMPLAINT' ? <Badge colorScheme="purple">{t('Complaint')}</Badge> : <Badge colorScheme="green">{t('Cancel Complaint')}</Badge>;
	}

	function callbackFilter(e) {
		history.push(`/complaint-on-insurance${e}`);
	}

	function getStatus({ settled, settledDate }) {
		return settled === true ? (
			<Tooltip label={settledDate ? moment(settledDate).format('LLLL') : ''}>
				<Badge colorScheme="green">{t('Settled')}</Badge>
			</Tooltip>
		) : (
			<Badge colorScheme="yellow">{t('In progress')}</Badge>
		);
	}

	function panelTabs({ name, value }) {
		let active = status ? status : 'in progress';

		return (
			<Flex
				userSelect="none"
				minW="100px"
				onClick={() => {
					handleTabChange(value);
				}}
				cursor="pointer"
				mb={'-2px'}
				_hover={{ boxShadow: `inset 0 -2px 0 0 ${value === active ? '#2980b9' : '#bdc3c7'}` }}
				boxShadow={value === active ? 'inset 0 -2px 0 0 #2980b9' : ''}
				pt="16px"
				pb="16px"
				pl="12px"
				pr="12px"
				alignItems="center"
				justifyContent="center"
			>
				<Text>{t(name)}</Text>
			</Flex>
		);
	}

	function handleTabChange(tabValue) {
		const page = 1;

		let url = `?page=${page}`;

		if (tabValue) {
			url += `&status=${tabValue}`;
		}

		if (fromDate) {
			url += `&fromDate=${fromDate}`;
		}
		if (toDate) {
			url += `&toDate=${toDate}`;
		}
		if (read !== null) {
			const unreadOnly = read ? read : '';
			if (unreadOnly) {
				url += `&read=false`;
			}
		}
		if (showSpam) {
			url += `&showSpam=${showSpam}`;
		}
		history.push(`/complaint-on-insurance${url}`);
	}

	return (
		<>
			<PrintLayout>
				<Container maxW="container.xl" mt="16px" mb="16px">
					<BreadcrumbComponent
						list={[
							{
								name: 'COMPLAINT',
								path: '/complaint-type',
							},
							{
								name: 'COMPLAINT ON INSURANCE',
								path: '#',
							},
						]}
					/>
					<Center className="show-only-printing">
						<Text textAlign="center" color="#62B3ED" fontFamily="Moul" fontSize={18}>
							ពាក្យបណ្តឹងធានារ៉ាប់រង
						</Text>
					</Center>
					<Center mt="5">
						<Box
							w="100%"
							bg="white"
							borderRadius={c.borderRadius}
							border="1px solid #dbdbdb"
							boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
							pb="32px"
						>
							<Flex
								className="hidden-print"
								bg="#FAFAFA"
								p="16px"
								pt="0"
								pb="0"
								mb="16px"
								alignItems="center"
								justifyContent="space-between"
								borderBottom="1px solid #dbdbdb"
							>
								<Flex pb="2px">
									{tabList.map((item) => {
										return panelTabs({ name: item.label, value: item.value });
									})}
								</Flex>
								<Flex alignItems="center">
									<Button
										mr="2"
										onClick={() => window?.print()}
										leftIcon={<FontAwesomeIcon icon={faPrint} style={{ fontSize: 16 }} />}
										colorScheme="gray"
										variant="solid"
										size="sm"
										borderRadius={c.borderRadius}
										border="1px solid #bdc3c7"
									>
										{t('Print')}
									</Button>
									<Button
										onClick={() => setOpenFilter(true)}
										leftIcon={<FontAwesomeIcon icon={faSlidersH} style={{ fontSize: 16 }} />}
										colorScheme={`${number || name || fromDate || toDate || read || type || showSpam ? 'yellow' : 'gray'}`}
										variant="solid"
										size="sm"
										borderRadius={c.borderRadius}
										border="1px solid #bdc3c7}"
									>
										{t('Filter')}
									</Button>
								</Flex>
							</Flex>

							<Box p="16px">
								<Table variant="simple">
									<Thead>
										<Tr>
											<Th>{t('Number')}</Th>
											<Th>{t('Subject')}</Th>
											<Th>{t('Created At')}</Th>
											<Th>{t('Plaintiff/Representative Name')}</Th>
											<Th isNumeric>{t('Type')}</Th>
											<Th isNumeric>{t('Status')}</Th>
										</Tr>
									</Thead>
									<Tbody>
										{loading && <LoadingTable column={6} />}
										{data?.getComplaintsOnInsurance?.complaints.length <= 0 && (
											<Tr>
												<Td>
													<Text>{t('No Data')}</Text>
												</Td>
											</Tr>
										)}
										{data?.getComplaintsOnInsurance?.complaints?.map((complaint, index) => {
											return (
												<Tr
													cursor="pointer"
													_hover={{ bg: 'gray.200' }}
													onClick={() => history.push(`/complaint-on-insurance/detail?id=${complaint.id}`)}
													key={`complaint-${index}`}
													bg={complaint?.notification?.read === false ? 'gray.100' : 'white'}
												>
													<Td>{complaint.no}</Td>
													<Td fontWeight="500" maxW="250px">
														<Text maxW="400px" noOfLines={4}>
															{complaint.subject}
														</Text>
													</Td>
													<Td>{moment(complaint.createdAt).format('LLLL')}</Td>
													<Td>{complaint.plaintiffOrRepresentativeName}</Td>
													<Td isNumeric>{complaintType(complaint.complaintType)}</Td>
													<Td isNumeric>
														{getStatus({ settled: complaint.settled, settledDate: complaint.settledDate })}{' '}
														{complaint.spam === true && <Badge colorScheme="red">{t('Spam')}</Badge>}
													</Td>
												</Tr>
											);
										})}
									</Tbody>
									<Tfoot>
										<Tr>
											<Th>{t('Number')}</Th>
											<Th>{t('Subject')}</Th>
											<Th>{t('Created At')}</Th>
											<Th>{t('Plaintiff/Representative Name')}</Th>
											<Th isNumeric>{t('Type')}</Th>
											<Th isNumeric>{t('Status')}</Th>
										</Tr>
										<Tr>
											<Th />
											<Th />
											<Th />
											<Th />
											<Th />
											<Th isNumeric>
												{t('Total Rows')}: {data?.getComplaintsOnInsurance?.totalRows}
											</Th>
										</Tr>
									</Tfoot>
								</Table>
								<Flex justifyContent="flex-end" w="100%" mt="4">
									{data?.getComplaintsOnInsurance?.totalPages > 1 && (
										<ReactPaginate
											previousLabel={<ChevronLeftIcon fontSize="20px" />}
											nextLabel={<ChevronRightIcon fontSize="20px" />}
											breakLabel={'...'}
											breakClassName={'break-me'}
											pageCount={data?.getComplaintsOnInsurance?.totalPages}
											marginPagesDisplayed={2}
											pageRangeDisplayed={3}
											onPageChange={handlePageClick}
											containerClassName={'pagination'}
											activeClassName={'active'}
											initialPage={(currentPage - 1) | 0}
										/>
									)}
								</Flex>
							</Box>
						</Box>
					</Center>
				</Container>

				<ComplaintFilter
					callback={callbackFilter}
					isOpen={openFilter}
					onClose={() => setOpenFilter(false)}
					number={number}
					name={name}
					fromDate={fromDate}
					toDate={toDate}
					read={read}
					status={status}
					type={type}
					showSpam={showSpam === 'true' ? true : false}
				/>
			</PrintLayout>
		</>
	);
}

export default Complaint;

const tabList = [
	{ label: 'In progress', value: 'in progress' },
	{ label: 'Settled', value: 'settled' },
	{ label: 'All', value: 'all' },
];
