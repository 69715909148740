import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Container, Box, Flex, Text, Center, Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    Badge,
    Tooltip,
    Checkbox,
    Button
} from "@chakra-ui/react";
import BreadcrumbComponent from "../../components/BreadcrumbComponent/BreadcrumbComponent";
import { faSlidersH, faChartLine, faFileExcel, faPencilRuler, faPrint, faPlus, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import c from "../../constant"
import { gql, useLazyQuery } from '@apollo/client';
import moment from "moment"
import ReactPaginate from 'react-paginate';
import './style.css'
import { useLocation, useHistory } from "react-router-dom";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import useStore from "../../store";
import LoadingTable from "../../components/Table/Loading";
import datePickerFromToToDate from "../../utils/datePickerFromToToDate";
import { Link } from "react-router-dom";
import LeaveRequestFilter from "../../components/Filter/LeaveRequestFilter";
import LeaveReportModal from "../../components/Modal/LeaveReportModal";
import PrintLayout from "../../components/Print/PrintLayout";

const QUERY_DATA = gql`
  query getCompanySubmissionReports(
        $limit:Float
        $offset:Float
        $number: Float
        $name: String
        $fromDate: DateTime
        $toDate: DateTime
        $type: TypeOfLeave
        $read: Boolean
        $status: String
    ){
        getLeaves(
        pagerInput:{
            limit:$limit
            offset:$offset
        }
        number: $number
        name: $name
        fromDate: $fromDate
        toDate: $toDate
        type: $type
        read: $read
        status: $status
    ){
      
        leaves{
            id
            no
            from
            to
            comeback
            purpose
            amount
            createdBy
            createdAt
            from
            to
            approveByDepartment
            approveByDepartmentBy
            approveByDepartmentAt
            approveByDirectorGeneral
            approveByDirectorGeneralBy
            approveByDirectorGeneralAt
            user{
                username
                fullName
                fullNameKhmer
                avatar
                department
            }
            userDepartment{
                username
                fullName
                fullNameKhmer
            }
            userDG{
                username
                fullName
                fullNameKhmer
            }
            notification{
                id
                read
            }
        }
        totalPages
        totalRows
    }
  }
`

const QUERY_COUNT = gql`
query{
  countUnreadLeaveRequest
}
`

function Report() {
    const { t } = useTranslation();
    const location = useLocation();
    const history = useHistory();
    const urlParams = new URLSearchParams(location.search);
    const currentPage = urlParams.get('page') ? urlParams.get('page') : 1;
    const [rowLimit, setRowLimit] = useState(10)
    const number = urlParams.get('number') ? urlParams.get('number') : null;
    const name = urlParams.get('name') ? urlParams.get('name') : null;
    const type = urlParams.get('type') ? urlParams.get('type') : null;
    const fromDate = urlParams.get('fromDate') ? urlParams.get('fromDate') : null;
    const toDate = urlParams.get('toDate') ? urlParams.get('toDate') : null;
    const read = urlParams.get('read') ? urlParams.get('read') : null;
    const status = urlParams.get('status') ? urlParams.get('status') : null;

    const { currentLang } = useStore((state) => state.currentLang);
    const { currentUser } = useStore((state) => state.currentUser);
    const [loadCount, { data: countData }] = useLazyQuery(QUERY_COUNT)
    moment.locale(currentLang === "kh" ? "km" : "en-gb")
    const [loadData, { error, loading, data }] = useLazyQuery(QUERY_DATA)
    useEffect(() => {
        window.scrollTo(0, 0);
        loadData({
            variables: {
                offset: currentPage ? ((currentPage - 1) * rowLimit) : 0,
                limit: rowLimit,
                number,
                name,
                fromDate: datePickerFromToToDate(fromDate),
                toDate: datePickerFromToToDate(toDate),
                type,
                read,
                status: status ? status : "in progress"
            }
        })
        loadCount()
    }, []);

    const [openFilter, setOpenFilter] = useState(false)
    const [openLeaveReportModal, setOpenLeaveReportModal] = useState(false)

    function handlePageClick(data) {
        let selected = data.selected;
        const page = selected + 1

        let url = `?page=${page}`

        if (status) {
            url += `&status=${status}`
        }
        if (number) {
            url += `&number=${number}`
        }
        if (name) {
            url += `&name=${name}`
        }

        if (fromDate) {
            url += `&fromDate=${fromDate}`
        }
        if (toDate) {
            url += `&toDate=${toDate}`
        }
        if (type) {
            url += `&type=${type}`
        }
        if (read !== null) {
            const unreadOnly = read ? read : ""
            if (unreadOnly) {
                url += `&read=false`
            }
        }
        history.push(`/request-leave${url}`)
    }

    useEffect(() => {
        if (currentPage) {
            window.scrollTo(0, 0);
            loadData({
                variables: {
                    offset: currentPage ? ((currentPage - 1) * rowLimit) : 0,
                    limit: rowLimit,
                    number: parseInt(number),
                    name,
                    fromDate: datePickerFromToToDate(fromDate),
                    toDate: datePickerFromToToDate(toDate),
                    type,
                    read: read === "false" ? false : (read === "true" ? true : null),
                    status: status ? status : "in progress"
                }
            })
        }
    }, [rowLimit, currentPage, number, name, fromDate, toDate, type, read, status])

    function getApproveStatus(approve, date, user) {
        let res = null
        if (approve === null) {
            res = <Badge colorScheme="yellow">{t("In progress")}</Badge>
        } else if (approve === true) {
            res = <Badge colorScheme="green">{t("Approved Leave")}</Badge>
        } else {
            res = <Badge colorScheme="red">{t("Rejected Leave")}</Badge>
        }
        if (date) {
            res = <Tooltip label={`${moment(date).format("LLLL")} ${t("By")} ${getDisplayName(user)}`}>
                {res}
            </Tooltip>
        }
        return res
    }

    function callbackFilter(e) {
        history.push(`/request-leave${e}`)
    }

    function checkReadRow(item) {
        let read = true
        read = item?.notification?.read
        return read
    }

    function getDisplayName(user) {
        let displayName = user?.username
        if (currentLang === "kh") {
            displayName = user?.fullNameKhmer ? user?.fullNameKhmer : (user?.fullName ? user?.fullName : user?.username)
        } else {
            displayName = user?.fullName ? user?.fullName : (user?.fullNameKhmer ? user?.fullNameKhmer : user?.username)
        }
        return displayName
    }

    function handleStatusChange(statusValue) {
        const page = 1

        let url = `?page=${page}`

        if (statusValue) {
            url += `&status=${statusValue}`
        }
        if (number) {
            url += `&number=${number}`
        }
        if (name) {
            url += `&name=${name}`
        }

        if (fromDate) {
            url += `&fromDate=${fromDate}`
        }
        if (toDate) {
            url += `&toDate=${toDate}`
        }
        if (type) {
            url += `&type=${type}`
        }
        if (read !== null) {
            const unreadOnly = read ? read : ""
            if (unreadOnly) {
                url += `&read=false`
            }
        }
        history.push(`/request-leave${url}`)
    }

    function panelTabs({ name, value }) {
        let active = status ? status : "in progress"

        return (
            <Flex userSelect="none" minW="100px" onClick={() => {
                handleStatusChange(value)
            }} cursor="pointer" mb={"-2px"} _hover={{ boxShadow: `inset 0 -2px 0 0 ${value === active ? "#2980b9" : "#bdc3c7"}` }} boxShadow={value === active ? "inset 0 -2px 0 0 #2980b9" : ""} pt="16px" pb="16px" pl="12px" pr="12px" alignItems="center" justifyContent="center">
                <Text>{t(name)}</Text>
                {/* <Badge mb="2px" ml="4px">{amount>=0?amount:""}</Badge> */}
            </Flex>
        )
    }

    function checkIfDeputyDirector(department) {
        const dp = department?.split(",")
        if (dp?.length > 1) {
            return true
        } else {
            return false
        }
    }

    return (
        <PrintLayout>
            <>
                <Container maxW="container.xl" mt="16px" mb="16px">
                    <BreadcrumbComponent
                        list={[
                            {
                                name: "Request Leave",
                                path: "#",
                            }
                        ]}
                    />
                    <Center className="show-only-printing">
                        <Text textAlign="center" color="#62B3ED" fontFamily="Moul" fontSize={18}>របាយការណ៍សុំច្បាប់</Text>
                    </Center>
                    <Center mt="5">
                        <Box
                            w="100%"
                            bg="white"
                            borderRadius={c.borderRadius}
                            border="1px solid #dbdbdb"
                            boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
                            pb="32px"
                        >
                            <Flex className="hidden-print" bg="#FAFAFA" p="16px" pt="0" pb="0" mb="16px" alignItems="center" justifyContent="space-between" borderBottom="1px solid #dbdbdb">
                                <Flex pb="2px">
                                    {panelTabs({ name: "In progress", value: "in progress" })}
                                    {panelTabs({ name: "Done", value: "done" })}
                                    {panelTabs({ name: "All", value: "all" })}
                                    {currentUser?.canViewLeaveRequestReport ? panelTabs({ name: "IRC leave report", value: "irc-leave-report" }) : null}
                                </Flex>
                                <Flex alignItems="center">
                                    <Button
                                        mr="2"
                                        onClick={() => window?.print()}
                                        leftIcon={<FontAwesomeIcon icon={faPrint} style={{ fontSize: 16 }} />}
                                        colorScheme='gray'
                                        variant='solid'
                                        size="sm"
                                        borderRadius={c.borderRadius}
                                        border="1px solid #bdc3c7"
                                    >
                                        {t("Print")}
                                    </Button>
                                    {(currentUser?.role === "admin") &&
                                        <Button
                                            mr="2"
                                            onClick={() => history.push("/leave-adjustment?page=1")}
                                            leftIcon={<FontAwesomeIcon icon={faPencilRuler} style={{ fontSize: 16 }} />}
                                            colorScheme='gray'
                                            variant='solid'
                                            size="sm"
                                            borderRadius={c.borderRadius}
                                            border="1px solid #bdc3c7"
                                        >
                                            {t("Leave Adjustment")}
                                        </Button>
                                    }
                                    {(currentUser?.role === "admin" || currentUser?.canViewLeaveRequestReport) &&
                                        <Button
                                            mr="2"
                                            onClick={() => setOpenLeaveReportModal(true)}
                                            leftIcon={<FontAwesomeIcon icon={faFileExcel} style={{ fontSize: 16 }} />}
                                            colorScheme='gray'
                                            variant='solid'
                                            size="sm"
                                            borderRadius={c.borderRadius}
                                            border="1px solid #bdc3c7"
                                        >
                                            {t("Export")}
                                        </Button>
                                    }
                                    {(currentUser?.canViewLeaveRequestReport) &&
                                        <Link to={`/request-leave/pdf-report?page=1&status=irc-leave-report`}>
                                            <Button
                                                mr="2"
                                                leftIcon={<FontAwesomeIcon icon={faFilePdf} style={{ fontSize: 16 }} />}
                                                colorScheme='gray'
                                                variant='solid'
                                                size="sm"
                                                borderRadius={c.borderRadius}
                                                border="1px solid #bdc3c7"
                                            >
                                                {t("Export")}
                                            </Button>
                                        </Link>
                                    }
                                    {currentUser?.role !== "admin" &&
                                        <Link to={`/request-leave/add`}>
                                            <Button
                                                mr="2"
                                                leftIcon={<FontAwesomeIcon icon={faPlus} style={{ fontSize: 16 }} />}
                                                colorScheme='gray'
                                                variant='solid'
                                                size="sm"
                                                borderRadius={c.borderRadius}
                                                border="1px solid #bdc3c7"
                                            >
                                                {t("Create New Leave")}
                                            </Button>
                                        </Link>
                                    }
                                    <Button
                                        onClick={() => setOpenFilter(true)}
                                        leftIcon={<FontAwesomeIcon icon={faSlidersH} style={{ fontSize: 16 }} />}
                                        colorScheme={`${(number || name || fromDate || toDate || read || type) ? "yellow" : "gray"}`}
                                        variant='solid'
                                        size="sm"
                                        borderRadius={c.borderRadius}
                                        border="1px solid #bdc3c7}"
                                    >
                                        {t("Filter")}
                                    </Button>
                                </Flex>
                            </Flex>

                            <Box p="16px">
                                <Table variant="simple">
                                    <Tbody>
                                        <Tr className="td-as-th">
                                            <Th isNumeric>{t("Number")}</Th>
                                            <Th>{t("Name")}</Th>
                                            <Th>{t("Purpose")}</Th>
                                            <Th isNumeric>{t("Leave amount (Days)")}</Th>
                                            <Th>{t("Leave from date")}</Th>
                                            <Th>{t("Leave to date")}</Th>
                                            <Th>{t("Created At")}</Th>
                                            <Th isNumeric>{t("Approved Leave By Department")}</Th>
                                            <Th isNumeric>{t("Approved Leave By DG")}</Th>
                                        </Tr>
                                        {loading && <LoadingTable column={9} />}
                                        {
                                            data?.getLeaves?.leaves.length <= 0 && <Tr>
                                                <Td isNumeric><Text>{t("No Data")}</Text></Td>
                                            </Tr>
                                        }
                                        {data?.getLeaves?.leaves?.map((item, index) => {
                                            return (
                                                <Tr cursor="pointer" _hover={{ bg: "gray.200" }} onClick={() => history.push(`/request-leave/detail?id=${item.id}`)} key={`item-${index}`} bg={checkReadRow(item) === false ? "gray.100" : "white"}>
                                                    <Td isNumeric>
                                                        {item?.no}
                                                    </Td>
                                                    <Td>
                                                        {getDisplayName(item?.user)}
                                                    </Td>
                                                    <Td maxW={350}>
                                                        <Text noOfLines={3}>
                                                            {item?.purpose}
                                                        </Text>
                                                    </Td>
                                                    <Td isNumeric>{(item.amount)}</Td>
                                                    <Td>{moment(item.from).format("LLLL")}</Td>
                                                    <Td>{moment(item.to).format("LLLL")}</Td>
                                                    <Td>{moment(item.createdAt).format("LLLL")}</Td>
                                                    <Td isNumeric>{
                                                        checkIfDeputyDirector(item?.user?.department) ? null :
                                                            getApproveStatus(item?.approveByDepartment, item?.approveByDepartmentAt, item?.userDepartment)
                                                    }</Td>
                                                    <Td isNumeric>{getApproveStatus(item?.approveByDirectorGeneral, item?.approveByDirectorGeneralAt, item?.userDG)}</Td>

                                                </Tr>
                                            )
                                        })}
                                    </Tbody>
                                    <Tfoot className="hidden-print">
                                        <Tr>
                                            <Th isNumeric>{t("Number")}</Th>
                                            <Th>{t("Name")}</Th>
                                            <Th>{t("Purpose")}</Th>
                                            <Th isNumeric>{t("Leave amount (Days)")}</Th>
                                            <Th>{t("Leave from date")}</Th>
                                            <Th>{t("Leave to date")}</Th>
                                            <Th>{t("Created At")}</Th>
                                            <Th isNumeric>{t("Approved Leave By Department")}</Th>
                                            <Th isNumeric>{t("Approved Leave By DG")}</Th>
                                        </Tr>
                                        <Tr>
                                            <Th />
                                            <Th />
                                            <Th />
                                            <Th />
                                            <Th />
                                            <Th />
                                            <Th />
                                            <Th />
                                            <Th isNumeric>
                                                {t("Total Rows")}: {data?.getLeaves?.totalRows}
                                            </Th>
                                        </Tr>
                                    </Tfoot>
                                </Table>
                                <Flex justifyContent="flex-end" w="100%" mt="4">
                                    {data?.getLeaves?.totalPages > 1 && <ReactPaginate
                                        previousLabel={<ChevronLeftIcon fontSize="20px" />}
                                        nextLabel={<ChevronRightIcon fontSize="20px" />}
                                        breakLabel={'...'}
                                        breakClassName={'break-me'}
                                        pageCount={data?.getLeaves?.totalPages}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={3}
                                        onPageChange={handlePageClick}
                                        containerClassName={'pagination'}
                                        activeClassName={'active'}
                                        initialPage={(currentPage - 1) | 0}
                                    />}
                                </Flex>
                                <Box justifyContent="flex-end" className="show-only-printing">
                                    <Text ml="2" mt="4">{t("Total Rows")}: <b>{data?.getLeaves?.totalRows}</b></Text>
                                </Box>
                                <Flex className="hidden-print" justifyContent="flex-end" mt="6">
                                    <Text mr="2">{t("Show more rows")}</Text>
                                    <Checkbox isChecked={rowLimit > 10} onChange={() => {
                                        handlePageClick({ selected: 0 })
                                        if (rowLimit > 10) {
                                            setRowLimit(10)
                                        } else {
                                            setRowLimit(300)
                                        }
                                    }}></Checkbox>
                                </Flex>
                            </Box>
                        </Box>
                    </Center>
                </Container>
                <LeaveRequestFilter
                    callback={callbackFilter}
                    isOpen={openFilter}
                    onClose={() => setOpenFilter(false)}
                    number={number}
                    name={name}
                    type={type}
                    fromDate={fromDate}
                    toDate={toDate}
                    read={read}
                    status={status}
                />
                {openLeaveReportModal && <LeaveReportModal isOpen={true} onClose={() => setOpenLeaveReportModal(false)} />}
            </>
        </PrintLayout>
    );
}

export default Report;

