/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import {
	Box,
	Flex,
	Button,
	Text,
	Tag,
	TagCloseButton,
	TagLabel,
	FormControl,
	FormLabel,
	InputGroup,
	InputRightElement,
	FormHelperText,
	useDisclosure,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Field, useFormikContext } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList } from '@fortawesome/free-solid-svg-icons';
import { gql, useLazyQuery } from '@apollo/client';
import CompanyName from '../../CompanyName';
import Modal from './Modal';
import c from '../../../constant';

const Q_COMPANY = gql`
	query get($id: String!) {
		getCompanyByCompanyId(id: $id) {
			id
			name
			nameKhmer
			logo
			url
			type
		}
	}
`;

function CustomField({ name = null, label = null, required = false, companyTypes = null }) {
	const { t } = useTranslation('common');
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { values } = useFormikContext();
	const [fetData, { data }] = useLazyQuery(Q_COMPANY);

	useEffect(() => {
		values?.companyId && fetData({ variables: { id: values?.companyId } });
	}, [values?.companyId]);

	return (
		<>
			<Field name={name}>
				{({ field, meta, form }) => (
					<>
						<Box mb="4">
							<FormControl id={name}>
								<FormLabel>
									{t(label)}
									{required && <span style={{ color: 'red' }}>*</span>}
								</FormLabel>
								<InputGroup size="md">
									<Flex alignItems="center" w="100%" borderRadius={c.borderRadius} h="48px" border="1px solid #CBD5E0">
										{data?.getCompanyByCompanyId && values?.companyId ? (
											<Tag
												size="lg"
												ml="2px"
												borderRadius={c.borderRadius}
												variant="solid"
												colorScheme="green"
												w="calc(100% - 75px)"
												h="42px"
											>
												<TagLabel w="100%" fontSize={18} pt="8px" pb="8px">
													<CompanyName company={data?.getCompanyByCompanyId} />
												</TagLabel>
												<TagCloseButton onClick={() => form?.setFieldValue(name, '')} />
											</Tag>
										) : (
											<Text fontSize="medium" fontWeight="medium" color="gray.600" ml={4} mr={4}>
												{t('Company')}
											</Text>
										)}
									</Flex>
									<InputRightElement width="4.5rem">
										<Button
											variant="solid"
											mr="4px"
											mt="8px"
											h="42px"
											borderRadius={c.borderRadius}
											leftIcon={
												<FontAwesomeIcon
													icon={faList}
													style={{
														cursor: 'pointer',
														fontSize: 16,
													}}
												/>
											}
											onClick={onOpen}
										>
											{t('List')}
										</Button>
									</InputRightElement>
								</InputGroup>
								{meta.touched && meta.error && (
									<FormHelperText id={`error-message-${name}`} color="red.400" fontSize="12px">
										{t(meta.error)}
									</FormHelperText>
								)}
							</FormControl>
						</Box>
						{isOpen && <Modal companyTypes={companyTypes} isOpen={true} onClose={onClose} onCallback={(e) => form?.setFieldValue(name, e?.id)} />}
					</>
				)}
			</Field>
		</>
	);
}

export default CustomField;
