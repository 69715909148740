import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Box, Flex, Text, Button, useToast, SimpleGrid, Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure
} from "@chakra-ui/react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import TextFormField from "../../../../components/form/TextFormField"
import DateFormField from "../../../../components/form/DateFormField"
import DateInputFormField from "../../../../components/form/DateInputFormField"

import { gql, useMutation } from '@apollo/client';
import moment from "moment"
import useStore from "../../../../store";
import { faSave, faPenSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import c from "../../../../constant";
import getPositionLabel from "../../../../utils/getPositionLabel"
import TextFormFieldReadOnly from "../../../../components/form/TextFormFieldReadOnly"
import PreUploadFileFormField from "../../../../components/form/PreUploadFileFormField"
import DownloadAndViewButtonUserUpload from "../../../../components/Files/DownloadAndViewButtonUserUpload"

const UPDATE_EMPLOYEE_MUTATION = gql`
mutation update($id:String!, $currentWorkingStatus: String,$firstWorkingPlace:String){
    updateEmployeeFirstWorkingPlaceAndCurrentWorkingStatus(
      id:$id
      currentWorkingStatus:$currentWorkingStatus
      firstWorkingPlace:$firstWorkingPlace
    )
  }
`


const signUpSchema = Yup.object().shape({
    firstWorkingstartDate: Yup.string().nullable(),
    firstWorkingPassedDate: Yup.string().nullable(),
    firstWorkingPosition: Yup.string(),
    firstWorkingInstitution: Yup.string(),
    firstWorkingJobTitle: Yup.string(),
    firstWorkingOrganization: Yup.string(),
    firstWorkingDepartment: Yup.string(),
    firstWorkingOffice: Yup.string(),
    documentFirst: Yup.string(),

    currentWorkingStatusPosition: Yup.string(),
    currentWorkingStatusDateOfChangePosition: Yup.string().nullable(),
    currentWorkingStatusJobTitle: Yup.string(),
    currentWorkingStatusDateOfChangeJobTitle: Yup.string().nullable(),
    currentWorkingStatusOrganization: Yup.string().default("និយ័តករធានារ៉ាប់រងកម្ពុជា"),
    documentCurrent: Yup.string(),

});

function FirstWorkingPlaceAndCurrentWorkingStatus({ id, initData, callbackOnSuccess }) {
    const toast = useToast()
    const { t } = useTranslation();
    const [updateEmployee, { error, data, loading }] = useMutation(
        UPDATE_EMPLOYEE_MUTATION
    );
    if(initData){
        console.log(initData?.firstWorkingstartDate)
        console.log(moment(initData?.firstWorkingstartDate).format("DD MMMM YYYY"))
    }
    const { currentLang } = useStore((state) => state.currentLang);
    moment.locale(currentLang === "kh" ? "km" : "en-gb")

    const [isEditing, setIsEditing] = useState(false)

    const {
        isOpen,
        onClose,
        onOpen,
    } = useDisclosure()

    useEffect(() => {
        if (error) {
            if (error?.graphQLErrors?.length > 0) {
                error?.graphQLErrors.map(({ message }, i) => (
                    toast({
                        title: t(message),
                        status: "error",
                        isClosable: true,
                        position: "bottom-right"
                    })
                ))
            } else {
                toast({
                    title: t(error?.message ? error?.message : "something went wrong"),
                    status: "error",
                    isClosable: true,
                    position: "bottom-right"
                })
            }
        }
    }, [error])

    useEffect(() => {
        if (data) {
            if (data.updateEmployeeFirstWorkingPlaceAndCurrentWorkingStatus) {
                toast({
                    title: t("Officials updated"),
                    description: t("Officials updated successfully"),
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                    position: "bottom-right"
                })
                callbackOnSuccess()
            }
        }
    }, [data])

    function viewFile(value){
        const tmpValue = value?JSON.parse(value):null
        return tmpValue?<DownloadAndViewButtonUserUpload url={tmpValue?.file} size={tmpValue?.fileSize}/>:"" 
    }

    function rowData({ labelEN, labelKH, value }) {
        return (
            <Flex alignItems="center">
                <Text fontWeight="bold">{currentLang === "en" ? labelEN : labelKH}</Text>:
                <Text ml="4">{value || ""}</Text>
            </Flex>
        )
    }

    function displayData() {
        return (
            <Box p="26px">
                <Text fontSize="lg" fontWeight="bold"  mb="4" color="#2980b9">ក. ចូលបម្រើការងាររដ្ឋដំបូង</Text>
                <SimpleGrid columns={[1, 2, 3]} spacing={2}>
                    {rowData({ labelEN: "កាលបរិច្ឆេទចូលបំរើការងាររដ្ឋដំបូង", labelKH: "កាលបរិច្ឆេទចូលបំរើការងាររដ្ឋដំបូង", value: initData?.firstWorkingstartDate ? moment(initData?.firstWorkingstartDate).format(c.excelDateOnlyTextFormat) : "" })}
                    {rowData({ labelEN: "កាលបរិច្ឆេទតាំងស៊ប់", labelKH: "កាលបរិច្ឆេទតាំងស៊ប់", value: initData?.firstWorkingPassedDate ? moment(initData?.firstWorkingPassedDate).format(c.excelDateOnlyTextFormat) : "" })}
                    {rowData({ labelEN: "មុខតំណែង", labelKH: "មុខតំណែង", value: initData?.firstWorkingPosition })}
                    {rowData({ labelEN: "ក្រសួង/ស្ថាប័ន", labelKH: "ក្រសួង/ស្ថាប័ន", value: initData?.firstWorkingInstitution })}
                    {rowData({ labelEN: "ក្របខ័ណ្ឌ ឋានន្តរស័ក្តិ និងថ្នាក់", labelKH: "ក្របខ័ណ្ឌ ឋានន្តរស័ក្តិ និងថ្នាក់", value: initData?.firstWorkingJobTitle })}
                    {rowData({ labelEN: "អង្គភាព", labelKH: "អង្គភាព", value: initData?.firstWorkingOrganization })}
                    {rowData({ labelEN: "នាយកដ្ឋាន/អង្គភាព/មន្ទីរ", labelKH: "នាយកដ្ឋាន/អង្គភាព/មន្ទីរ", value: initData?.firstWorkingDepartment })}
                    {rowData({ labelEN: "ការិយាល័យ", labelKH: "ការិយាល័យ", value: initData?.firstWorkingOffice })}
                    {rowData({ labelEN: "ឯកសារយោង", labelKH: "ឯកសារយោង", value: viewFile(initData?.documentFirst) })}

                </SimpleGrid>
                <Text fontSize="lg" fontWeight="bold" mt="4" mb="4" color="#2980b9">ខ. ស្ថានភាពមុខងារបច្ចុប្បន្ន</Text>

                <SimpleGrid columns={[1, 2, 3]} spacing={2}>
                    {rowData({ labelEN: "មុខតំណែង", labelKH: "មុខតំណែង", value: initData?.currentWorkingStatusPosition?getPositionLabel(initData?.currentWorkingStatusPosition):"" })}
                    {rowData({ labelEN: "កាលបរិច្ឆេទប្តូរក្របខ័ណ្ឌ ឋានន្តរស័ក និងថ្នាក់ចុងក្រោយ", labelKH: "កាលបរិច្ឆេទប្តូរក្របខ័ណ្ឌ ឋានន្តរស័ក និងថ្នាក់ចុងក្រោយ", value: initData?.currentWorkingStatusDateOfChangePosition ? moment(initData?.currentWorkingStatusDateOfChangePosition).format(c.excelDateOnlyTextFormat) : "" })}
                    {rowData({ labelEN: "ក្របខ័ណ្ឌ ឋានន្តរស័ក និងថ្នាក់", labelKH: "ក្របខ័ណ្ឌ ឋានន្តរស័ក និងថ្នាក់", value: initData?.currentWorkingStatusJobTitle })}
                    {rowData({ labelEN: "កាលបរិច្ឆេទទទួលមុខតំណែងចុងក្រោយ", labelKH: "កាលបរិច្ឆេទទទួលមុខតំណែងចុងក្រោយ", value: initData?.currentWorkingStatusDateOfChangeJobTitle ? moment(initData?.currentWorkingStatusDateOfChangeJobTitle).format(c.excelDateOnlyTextFormat) : "" })}
                    {rowData({ labelEN: "អង្គភាព", labelKH: "អង្គភាព", value: initData?.currentWorkingStatusOrganization||"និយ័តករធានារ៉ាប់រងកម្ពុជា" })}
                    {rowData({ labelEN: "ឯកសារយោង", labelKH: "ឯកសារយោង", value: viewFile(initData?.documentCurrent) })}

                </SimpleGrid>
            </Box>
        )
    }

    return (
        <>

            <Box mt="3">
                <Flex mt="4" mb="4" alignItems="center">
                    <Text fontSize="xl" fontWeight="bold" color="#c0392b">២. ព័ត៌មានអំពីស្ថានភាពមុខងារសាធារណៈ/មន្ត្រីលក្ខន្តិកៈ</Text>

                    {isEditing && <Button
                        variant="outline"
                        size="sm"
                        ml="4"
                        borderRadius={c.borderRadius}
                        onClick={() => setIsEditing(false)}
                        colorScheme='gray'
                    >
                        {t(`Cancel`)}
                    </Button>}
                    {!isEditing && <Button
                        variant="outline"
                        size="sm"
                        ml="4"
                        borderRadius={"0px !important"}
                        leftIcon={<FontAwesomeIcon icon={faPenSquare} style={{ fontSize: 14 }} />}
                        onClick={() => onOpen()}
                        colorScheme='whatsapp'
                    >
                        {t(`Edit`)}
                    </Button>}
                </Flex>

                {
                    !isEditing && <>
                        {displayData()}
                    </>
                }
                <Modal scrollBehavior="inside" size="5xl" isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay
                        backdropFilter='auto'
                        bg='blackAlpha.300'
                        backdropBlur='2px'
                    />
                    <Formik
                        initialValues={{
                            firstWorkingstartDate: initData?.firstWorkingstartDate || "",
                            firstWorkingPassedDate: initData?.firstWorkingPassedDate || "",
                            firstWorkingPosition: initData?.firstWorkingPosition || "",
                            firstWorkingInstitution: initData?.firstWorkingInstitution || "",
                            firstWorkingJobTitle: initData?.firstWorkingJobTitle || "",
                            firstWorkingOrganization: initData?.firstWorkingOrganization || "",
                            firstWorkingDepartment: initData?.firstWorkingDepartment || "",
                            firstWorkingOffice: initData?.firstWorkingOffice || "",
                            documentFirst: initData?.documentFirst || "",

                            currentWorkingStatusPosition: initData?.currentWorkingStatusPosition || "",
                            currentWorkingStatusDateOfChangePosition: initData?.currentWorkingStatusDateOfChangePosition || "",
                            currentWorkingStatusJobTitle: initData?.currentWorkingStatusJobTitle || "",
                            currentWorkingStatusDateOfChangeJobTitle: initData?.currentWorkingStatusDateOfChangeJobTitle || "",
                            currentWorkingStatusOrganization: initData?.currentWorkingStatusOrganization || "និយ័តករធានារ៉ាប់រងកម្ពុជា",
                            documentCurrent: initData?.documentCurrent || "",
                        }}
                        validationSchema={signUpSchema}
                        onSubmit={(values) => {
                            updateEmployee({
                                variables: {
                                    id,
                                    currentWorkingStatus: JSON.stringify({
                                        currentWorkingStatusPosition: values?.currentWorkingStatusPosition,
                                        currentWorkingStatusDateOfChangePosition: values?.currentWorkingStatusDateOfChangePosition,
                                        currentWorkingStatusJobTitle: values?.currentWorkingStatusJobTitle,
                                        currentWorkingStatusDateOfChangeJobTitle: values?.currentWorkingStatusDateOfChangeJobTitle,
                                        currentWorkingStatusOrganization: values?.currentWorkingStatusOrganization?values?.currentWorkingStatusOrganization:"និយ័តករធានារ៉ាប់រងកម្ពុជា",
                                        documentCurrent: values?.documentCurrent,

                                    }),
                                    firstWorkingPlace: JSON.stringify({
                                        firstWorkingstartDate: values?.firstWorkingstartDate,
                                        firstWorkingPassedDate: values?.firstWorkingPassedDate,
                                        firstWorkingPosition: values?.firstWorkingPosition,
                                        firstWorkingInstitution: values?.firstWorkingInstitution,
                                        firstWorkingJobTitle: values?.firstWorkingJobTitle,
                                        firstWorkingOrganization: values?.firstWorkingOrganization,
                                        firstWorkingDepartment: values?.firstWorkingDepartment,
                                        firstWorkingOffice: values?.firstWorkingOffice,
                                        documentFirst: values?.documentFirst,
                                    })
                                }
                            })
                        }}
                    >
                        {(props) => (
                            <Form>
                                <ModalContent borderRadius={c.borderRadius}>
                                    <ModalHeader>{t("Edit")}</ModalHeader>
                                    <ModalCloseButton />
                                    <ModalBody>

                                        <Box>
                                            <Text fontSize="lg" fontWeight="bold" mt="2" mb="4">ក. ចូលបម្រើការងាររដ្ឋដំបូង</Text>
                                            <SimpleGrid columns={[1, 2, 3]} spacing={2}>


                                                <DateInputFormField
                                                    {...props}
                                                    label="កាលបរិច្ឆេទចូលបំរើការងាររដ្ឋដំបូង"
                                                    name="firstWorkingstartDate"
                                                    initialDate={initData?.firstWorkingstartDate}
                                                />
                                                <DateInputFormField
                                                    {...props}
                                                    label="កាលបរិច្ឆេទតាំងស៊ប់"
                                                    name="firstWorkingPassedDate"
                                                    initialDate={initData?.firstWorkingPassedDate}
                                                />
                                                <TextFormField
                                                    {...props}
                                                    label="មុខតំណែង"
                                                    name="firstWorkingPosition"
                                                />
                                                <TextFormField
                                                    {...props}
                                                    label="ក្រសួង/ស្ថាប័ន"
                                                    name="firstWorkingInstitution"
                                                />
                                                <TextFormField
                                                    {...props}
                                                    label="ក្របខ័ណ្ឌ ឋានន្តរស័ក្តិ និងថ្នាក់"
                                                    name="firstWorkingJobTitle"
                                                />

                                                <TextFormField
                                                    {...props}
                                                    label="អង្គភាព"
                                                    name="firstWorkingOrganization"
                                                />

                                                <TextFormField
                                                    {...props}
                                                    label="នាយកដ្ឋាន/អង្គភាព/មន្ទីរ"
                                                    name="firstWorkingDepartment"
                                                />

                                                <TextFormField
                                                    {...props}
                                                    label="ការិយាល័យ"
                                                    name="firstWorkingOffice"
                                                />

                                                <PreUploadFileFormField
                                                    {...props}
                                                    label={"ឯកសារយោង"}
                                                    name={"documentFirst"}
                                                />

                                            </SimpleGrid>
                                            <Text fontSize="lg" fontWeight="bold" mt="4" mb="4">ខ. ស្ថានភាពមុខងារបច្ចុប្បន្ន</Text>

                                            <SimpleGrid columns={[1, 2, 3]} spacing={2}>

                                                <TextFormFieldReadOnly
                                                    {...props}
                                                    label="មុខតំណែង"
                                                    name="currentWorkingStatusPosition"
                                                    placeholder={initData?.currentWorkingStatusPosition?getPositionLabel(initData?.currentWorkingStatusPosition):""}
                                                    disabled={true}
                                                />

                                                <DateInputFormField
                                                    {...props}
                                                    label="កាលបរិច្ឆេទទទួលមុខតំណែងចុងក្រោយ"
                                                    name="currentWorkingStatusDateOfChangePosition"
                                                    initialDate={initData?.currentWorkingStatusDateOfChangePosition}

                                                />

                                                <TextFormField
                                                    {...props}
                                                    label="ក្របខ័ណ្ឌ ឋានន្តរស័ក និងថ្នាក់"
                                                    name="currentWorkingStatusJobTitle"
                                                />

                                                <DateInputFormField
                                                    {...props}
                                                    label="កាលបរិច្ឆេទប្តូរក្របខ័ណ្ឌ ឋានន្តរស័ក និងថ្នាក់ចុងក្រោយ"
                                                    name="currentWorkingStatusDateOfChangeJobTitle"
                                                    initialDate={initData?.currentWorkingStatusDateOfChangeJobTitle}

                                                />
                                                <TextFormFieldReadOnly
                                                    {...props}
                                                    label={"អង្គភាព"}
                                                    name="currentWorkingStatusOrganization"
                                                    placeholder="និយ័តករធានារ៉ាប់រងកម្ពុជា"
                                                    disabled={true}
                                                />
                                                <PreUploadFileFormField
                                                    {...props}
                                                    label={"ឯកសារយោង"}
                                                    name={"documentCurrent"}
                                                />
                                            
                                            </SimpleGrid>
                                        </Box>
                                    </ModalBody>
                                    <ModalFooter bg="rgba(236, 240, 241,0.5)">
                                        <Button
                                            borderRadius={c.borderRadius}
                                            colorScheme='green'
                                            type="submit"
                                            isLoading={loading}
                                            loadingText={t("Save")}
                                            spinnerPlacement="start"
                                        >
                                            {t("Save")}
                                        </Button>
                                    </ModalFooter>
                                </ModalContent>
                            </Form>
                        )}
                    </Formik>
                </Modal>
            </Box>
        </>
    );
}

export default FirstWorkingPlaceAndCurrentWorkingStatus;