import { Td, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

function TableData({ title = null, child = null, isNumeric = false, width = 'auto', titleColor = '#303030', pl = 1, pr = 1, pt = 1, pb = 1 }) {
	const { t } = useTranslation();

	return (
		<Td isNumeric={isNumeric} width={width} pl={pl} pr={pr} pt={pt} pb={pb}>
			{title ? (
				<>
					<Text fontSize="14px" color={titleColor}>
						{t(title)}
					</Text>
				</>
			) : (
				<>{child}</>
			)}
		</Td>
	);
}

export default TableData;
