import React, {useEffect} from "react";
import {
    Badge,
} from "@chakra-ui/react";
import { gql, useLazyQuery } from '@apollo/client';
const QUERY_COUNT_UNREAD_COMPLAINTS = gql`
query{
  getCompanySubmissionReports(pagerInput:{
    offset:0
    limit:1
  }){
    totalPages
    totalRows
    countUnreadCompanySubmissionReports
    companySubmissionReports{
      id
    }
  }
}
`

function CompanySubmissionReportBadge() {
  const [loadCountUnreadComplaints, { data }] = useLazyQuery(QUERY_COUNT_UNREAD_COMPLAINTS)
  useEffect(() => {
    loadCountUnreadComplaints()
  }, [])
  return (
    (parseInt(data?.getCompanySubmissionReports?.countUnreadCompanySubmissionReports))>0?<Badge
      fontSize="x-large"
      zIndex="99"
      top="2px" right="2px"
      position="absolute"
      colorScheme="red"
    >      
      {parseInt(data?.getCompanySubmissionReports?.countUnreadCompanySubmissionReports)}
    </Badge>:null
  );
}

export default CompanySubmissionReportBadge;
