import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Box, Flex, Text, Center, Button, AspectRatio, SimpleGrid, Skeleton } from '@chakra-ui/react';
import BreadcrumbComponent from '../../components/BreadcrumbComponent/BreadcrumbComponent';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import c from '../../constant';
import { gql, useLazyQuery } from '@apollo/client';
import moment from 'moment';
import './style.css';
import PrintLayout from '../../components/Print/PrintLayout';
import { Bar } from 'react-chartjs-2';
import Card from '../../components/Card/Card';
import LoadingOverlayText from '../../components/LoadingOverlay/LoadingOverlayText';

const COMPLAINT_ON_INSURANCE_COLOR = 'rgba(26, 188, 156,1.0)';
const CANCEL_COMPLAINT_ON_INSURANCE_COLOR = 'rgba(52, 152, 219,1.0)';
const OTHER_COMPLAINT = 'rgba(230, 126, 34,1.0)';
const SETTLED_COLOR = '#27ae60';
const INPROGRESS_COLOR = '#c0392b';

const QUERY_STATISTICS = gql`
	query get($year: String!) {
		getComplaintStatistic(year: $year)
	}
`;

function ComplaintDetail() {
	const { t } = useTranslation();
	moment.locale('en-gb');
	const [loadStatistics, { error, loading, data: statisticsData }] = useLazyQuery(QUERY_STATISTICS);

	const [monthData, setMonthData] = useState(null);
	const [totalData, setTotalData] = useState(null);
	const [years, setYears] = useState(null);
	const [selectedYear, setSelectedYear] = useState(moment().format('yyyy'));

	useEffect(() => {
		loadStatistics({ variables: { year: selectedYear.toString() } });
	}, [selectedYear]);

	useEffect(() => {
		if (statisticsData) {
			const sData = statisticsData?.getComplaintStatistic ? JSON.parse(statisticsData?.getComplaintStatistic) : [];
			setYears(sData?.years?.reverse());
			setTotalData({
				totalCancelComplaintOnInsurance: sData?.totalCancelComplaintOnInsurance,
				totalComplaint: sData?.totalComplaint,
				totalComplaintOnInsurance: sData?.totalComplaintOnInsurance,
				totalComplaintOnInsuranceSettle: sData?.totalComplaintOnInsuranceSettle,
				totalComplaintSettle: sData?.totalComplaintSettle,
			});

			const totalCancelComplaintOnInsurance = [];
			const totalComplaint = [];
			const totalComplaintOnInsurance = [];
			const totalComplaintSettle = [];
			const totalComplaintInprogress = [];
			const tmpLabel = [];
			if (sData?.monthlyData?.length > 0) {
				sData?.monthlyData?.map((item) => {
					if (
						item?.totalCancelComplaintOnInsurance ||
						item?.totalComplaint ||
						item?.totalComplaintOnInsurance ||
						item?.totalComplaintOnInsuranceSettle ||
						item?.totalComplaintSettle
					) {
						tmpLabel.push(moment(item?.fromDate).format('MM/YY'));
						totalComplaintOnInsurance.push(item?.totalComplaintOnInsurance);
						totalCancelComplaintOnInsurance.push(item?.totalCancelComplaintOnInsurance);
						totalComplaint.push(item?.totalComplaint);
						totalComplaintSettle.push(item?.totalComplaintSettle + item?.totalComplaintOnInsuranceSettle);

						totalComplaintInprogress.push(
							parseInt(item?.totalComplaintOnInsurance) +
								parseInt(item?.totalCancelComplaintOnInsurance) +
								parseInt(item?.totalComplaint) -
								parseInt(item?.totalComplaintSettle + item?.totalComplaintOnInsuranceSettle)
						);
					}
				});
			}

			const tmpGraphMonthData = {
				labels: tmpLabel,
				datasets: [
					{
						label: t('Complaint on Insurance'),
						data: totalComplaintOnInsurance,
						backgroundColor: COMPLAINT_ON_INSURANCE_COLOR,
						stack: 'Stack 0',
					},
					{
						label: t('Cancel Complaint on Insurance'),
						data: totalCancelComplaintOnInsurance,
						backgroundColor: CANCEL_COMPLAINT_ON_INSURANCE_COLOR,
						stack: 'Stack 0',
					},
					{
						label: t('Other Complaint'),
						data: totalComplaint,
						backgroundColor: OTHER_COMPLAINT,
						stack: 'Stack 0',
					},
					{
						label: t('Settled'),
						data: totalComplaintSettle,
						backgroundColor: SETTLED_COLOR,
						stack: 'Stack 1',
					},
					{
						label: t('In progress'),
						data: totalComplaintInprogress,
						backgroundColor: INPROGRESS_COLOR,
						stack: 'Stack 1',
					},
				],
			};
			setMonthData(tmpGraphMonthData);
		}
	}, [statisticsData]);

	const options = {
		responsive: true,
		interaction: {
			intersect: false,
		},
		scales: {
			x: {
				stacked: true,
			},
			y: {
				stacked: true,
			},
		},
	};

	function displayBox(data) {
		return (
			<AspectRatio maxW="100%" ratio={2 / 1}>
				<Card>
					<Flex w="100%" h="100%" direction="column">
						<Text m="2" fontWeight="bold">
							{t(data?.label)}
						</Text>
						<Flex w="100%" h="100%" alignItems="center" justifyContent="center">
							<Text color={data?.color} fontWeight="bold" fontSize="3xl">
								{data?.value ? data?.value : '-'}
							</Text>
						</Flex>
					</Flex>
				</Card>
			</AspectRatio>
		);
	}

	return (
		<>
			<PrintLayout>
				<>
					<Container maxW="container.xl" mt="16px" mb="16px">
						<BreadcrumbComponent
							list={[
								{
									name: 'COMPLAINT',
									path: '/complaint-type',
								},
								{
									name: 'REPORT',
									path: '#',
								},
							]}
						/>
						<Center mt="5">
							<Box
								w="100%"
								bg="white"
								borderRadius={c.borderRadius}
								border="1px solid #dbdbdb"
								boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
								pb="32px"
								minH="100vh"
							>
								<Flex
									justifyContent="space-between"
									alignItems="center"
									bg="#FAFAFA"
									p="16px"
									pt="8px"
									pb="8px"
									mb="16px"
									borderBottom="1px solid #dbdbdb"
								>
									<Flex alignItems="center">
										<Text fontSize="x-large" fontWeight="bold" mr="4">
											{t('Complaint Report')}
										</Text>
									</Flex>
									<Flex className="hidden-print">
										<Button
											mr="2"
											onClick={() => window?.print()}
											leftIcon={<FontAwesomeIcon icon={faPrint} style={{ fontSize: 16 }} />}
											colorScheme="gray"
											variant="solid"
											size="sm"
											borderRadius={c.borderRadius}
											border="1px solid #bdc3c7"
										>
											{t('Print')}
										</Button>
									</Flex>
								</Flex>
								<Box p="16px">
									{
										<Flex>
											{years?.length > 0 &&
												years?.map((item, index) => {
													return (
														<Button
															borderRadius={c.borderRadius}
															onClick={() => setSelectedYear(item)}
															mr="2"
															size="sm"
															key={index}
															colorScheme="blue"
															variant={selectedYear == item ? 'solid' : 'outline'}
														>
															{item}
														</Button>
													);
												})}
											<Skeleton isLoaded={years}>
												<Button
													borderRadius={c.borderRadius}
													onClick={() => setSelectedYear('all')}
													mr="2"
													size="sm"
													colorScheme="blue"
													variant={selectedYear == 'all' ? 'solid' : 'outline'}
												>
													{t('All Years')}
												</Button>
											</Skeleton>
										</Flex>
									}

									<SimpleGrid mt="8" mb="6" columns={[4, null, 5]} spacing={['1.5rem', null, '1.5rem']}>
										{displayBox({
											label: 'Complaint on Insurance',
											value: totalData?.totalComplaintOnInsurance,
											color: COMPLAINT_ON_INSURANCE_COLOR,
										})}
										{displayBox({
											label: 'Cancel Complaint on Insurance',
											value: totalData?.totalCancelComplaintOnInsurance,
											color: CANCEL_COMPLAINT_ON_INSURANCE_COLOR,
										})}
										{displayBox({ label: 'Other Complaint', value: totalData?.totalComplaint, color: OTHER_COMPLAINT })}
										{displayBox({
											label: 'Settled',
											value: parseInt(totalData?.totalComplaintOnInsuranceSettle) + parseInt(totalData?.totalComplaintSettle),
											color: SETTLED_COLOR,
										})}
										{displayBox({
											label: 'In progress',
											value:
												parseInt(totalData?.totalComplaintOnInsurance) +
												parseInt(totalData?.totalCancelComplaintOnInsurance) +
												parseInt(totalData?.totalComplaint) -
												(parseInt(totalData?.totalComplaintOnInsuranceSettle) + parseInt(totalData?.totalComplaintSettle)),
											color: INPROGRESS_COLOR,
										})}
									</SimpleGrid>

									{monthData && <Bar data={monthData} options={options} />}
								</Box>
							</Box>
						</Center>
					</Container>
				</>
			</PrintLayout>
			{loading && <LoadingOverlayText />}
		</>
	);
}

export default ComplaintDetail;
