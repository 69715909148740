/* eslint-disable react-hooks/exhaustive-deps */
import { gql, useLazyQuery } from '@apollo/client';
import { useContext, createContext } from 'react';

const Q_GET_REQUEST = gql`
	query get {
		getCountBranchChangeRequest {
			countReview
			countCompleted
			countAll
		}
	}
`;

const BranchChangeRequestCountContext = createContext();

export const BranchChangeRequestCountProvider = ({ children }) => {
	const [load, { data }] = useLazyQuery(Q_GET_REQUEST);

	const loadCountBranchChangeRequest = () => {
		load();
	};

	return (
		<BranchChangeRequestCountContext.Provider
			value={{
				loadCountBranchChangeRequest,
				countReview: parseInt(data?.getCountBranchChangeRequest?.countReview),
				countCompleted: parseInt(data?.getCountBranchChangeRequest?.countCompleted),
				countAll: parseInt(data?.getCountBranchChangeRequest?.countAll),
			}}
		>
			{children}
		</BranchChangeRequestCountContext.Provider>
	);
};

export const useBranchChangeRequestCount = () => {
	return useContext(BranchChangeRequestCountContext);
};
