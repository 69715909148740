import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Container, Box, Flex, Text, Center, Button, useToast,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
} from "@chakra-ui/react";
import BreadcrumbComponent from "../../components/BreadcrumbComponent/BreadcrumbComponent";
import c from "../../constant"
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { gql, useMutation } from '@apollo/client';
import { useHistory } from "react-router-dom";
import DateRangeFormField from "../../components/form/DateRangeFormField";
import moment from "moment";
import 'moment/locale/en-gb';


const DELETE_NOTIFICAION = gql`
    mutation delete($fromDate: DateTime!, $toDate: DateTime!){
        deleteNotificationByDateRange(fromDate:$fromDate,toDate:$toDate)
    }
`

const filterSchema = Yup.object().shape({
    createdDate: Yup.string(),
});


function Create() {
    moment.locale("en-gb")
    const history = useHistory();
    const toast = useToast()
    const { t } = useTranslation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [isOpen, setIsOpen] = React.useState(false)
    const onClose = () => setIsOpen(false)
    const cancelRef = React.useRef()

    const [dateFrom, setDateFrom] = useState(null)
    const [dateTo, setDateTo] = useState(null)

    const [deleteNotificaion, { error, loading, data }] = useMutation(DELETE_NOTIFICAION)


    useEffect(()=>{
        if(data){
            toast({
                title: t("Notifications deleted successfully"),
                status: "success",
                isClosable: true,
                position:"top-right"
            })
            onClose()
            history.push("/settings")
        }
    }, [data])

    useEffect(()=>{
        if(error){
            error.graphQLErrors.map(({ message }, i) => (
                toast({
                    title: t(message),
                    status: "error",
                    isClosable: true,
                    position:"top-right"
                })
            ))
        }
    },[error])

    return (
        <>
            <Container maxW="container.xl" mt="16px" mb="16px">
                <BreadcrumbComponent
                    list={[
                        {
                            name: t("Settings"),
                            path: "/settings",
                        },
                        {
                            name: t("Delete notification from all user"),
                            path: "#",
                        },
                    ]}
                />
                <Center mt="5">
                    <Box
                        w="100%"
                        bg="white"
                        borderRadius={c.borderRadius}
                        border="1px solid #dbdbdb"
                        boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
                        pb="32px"
                    >
                        <Flex bg="#FAFAFA" p="16px" pt="8px" pb="8px" mb="16px" justifyContent="space-between" borderBottom="1px solid #dbdbdb">
                            <Text
                                fontSize="x-large"
                                fontWeight="bold"
                            >
                                {t("Delete notification from all user")}
                            </Text>
                        </Flex>

                        <Box p="16px" maxW="480px">
                            <Text mb="2">{t("Note: only read notification will delete")}</Text>
                            <Formik
                                initialValues={{
                                    createdDate: "",
                                }}
                                validationSchema={filterSchema}
                                onSubmit={(values) => {
                                    if (values.createdDate) {
                                        const tmpDate = JSON.parse(values.createdDate)
                                        if (tmpDate?.from && tmpDate?.to) {
                                            const dateFrom = moment({
                                                year: tmpDate.from.year,
                                                day: tmpDate.from.day,
                                                month: tmpDate.from.month - 1
                                            }).toDate()
                                            const dateTo = moment({
                                                year: tmpDate.to.year,
                                                day: tmpDate.to.day,
                                                month: tmpDate.to.month - 1
                                            }).toDate()
                                            setDateFrom(dateFrom)
                                            setDateTo(dateTo)
                                            setIsOpen(true)
                                        }
                                    }

                                }}
                            >
                                {(props) => (
                                    <Box pb="4">
                                        <Form>
                                            <DateRangeFormField
                                                {...props}
                                                label="Created Date"
                                                name="createdDate"
                                                initialDate={{}}
                                            />


                                            <Flex mt="16px">
                                                <Button
                                                    id="login-btn"
                                                    variant="solid"
                                                    size="md"
                                                    h="42px"
                                                    w="128px"
                                                    type="submit"
                                                    borderRadius={c.borderRadius}
                                                    color="#3498db"
                                                >
                                                    {t("Apply")}
                                                </Button>
                                            </Flex>
                                        </Form>

                                    </Box>
                                )}
                            </Formik>
                        </Box>
                    </Box>
                </Center>
                <>
                    <AlertDialog
                        isOpen={isOpen}
                        leastDestructiveRef={cancelRef}
                        onClose={onClose}
                    >
                        <AlertDialogOverlay>
                            <AlertDialogContent>
                                <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                                    {t("Delete notification from all user")}
                                </AlertDialogHeader>

                                <AlertDialogBody>
                                    {t("Are you sure? You can't undo this action afterwards")}
                                </AlertDialogBody>

                                <AlertDialogFooter>
                                    <Button ref={cancelRef} disabled={loading} onClick={onClose}>
                                        {t("Cancel")}
                                    </Button>
                                    <Button isLoading={loading} colorScheme='red' onClick={()=>{
                                        deleteNotificaion({
                                            variables:{
                                                fromDate:dateFrom,
                                                toDate:dateTo
                                            }
                                        })
                                        }} ml={3}>
                                        {t("Delete")}
                                    </Button>
                                </AlertDialogFooter>
                            </AlertDialogContent>
                        </AlertDialogOverlay>
                    </AlertDialog>
                </>
            </Container>
        </>
    );
}

export default Create;