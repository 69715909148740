/* eslint-disable no-use-before-define */
import moment from 'moment';
import useStore from '../store';
import c from '../constant';

require('@thyrith/momentkh')(moment);

function useMoment() {
	const { currentLang: lang } = useStore((state) => state.currentLang);

	function toDateString(input, format, locale = null) {
		if (input) {
			if (locale) {
				moment.locale(locale);
			} else {
				moment.locale(lang === 'kh' ? c.localeKm : c.localeEn);
			}
			return moment(input).format(format);
		}
		return null;
	}

	function toDate(input, format = null) {
		if (input) {
			moment.locale(c.localeEn);
			if (format) {
				return moment(input, format).toDate();
			} else {
				return moment(input).toDate();
			}
		}
		return null;
	}

	function dateNow() {
		return moment().toDate();
	}

	function paperSolarDateString(fromDate = moment().toDate()) {
		moment.locale(c.localeKm);
		const D = 'DD';
		const M = 'MMMM';
		const Y = 'YYYY';
		const solarDate = `រាជធានីភ្នំពេញ ថ្ងៃទី${moment(fromDate).format(D)} ខែ${moment(fromDate).format(M)} គ.ស.${moment(fromDate).format(Y)}`;
		return solarDate;
	}

	function paperLunarDateString(fromDate = moment().toDate()) {
		const lunarDate = moment(fromDate).toLunarDate('ថ្ងៃW dN ខែm ឆ្នាំa e ព.ស.b');
		return lunarDate;
	}

	function paperGeneralDateString(input, year = 'គ.ស.') {
		moment.locale(c.localeKm);
		const D = 'DD';
		const M = 'MMMM';
		const Y = 'YYYY';
		const date = `ថ្ងៃទី${moment(input).format(D)} ខែ${moment(input).format(M)} ${year}${moment(input).format(Y)}`;
		return date;
	}

	function auCertificateIssuedFromDate() {
		return moment().toDate();
	}

	function auCertificateIssuedToDate(from) {
		return moment(from).add(3, 'years').subtract(1, 'days').endOf('day').toDate();
	}

	function auCertificateRenewalFromDate(from) {
		return moment(from).add(1, 'days').toDate();
	}

	function auCertificateRenewalToDate(from) {
		return auCertificateIssuedToDate(from);
	}

	function isValidDate(input) {
		if (input) {
			moment.locale(c.localeEn);
			const valid = moment(input, c.dateOnlyFormat).isValid();
			return valid;
		}
		return false;
	}

	return {
		toDateString,
		toDate,
		dateNow,
		paperSolarDateString,
		paperLunarDateString,
		paperGeneralDateString,
		auCertificateIssuedFromDate,
		auCertificateIssuedToDate,
		auCertificateRenewalFromDate,
		auCertificateRenewalToDate,
		isValidDate,
	};
}

export default useMoment;
