/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import {
	Button,
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	useDisclosure,
} from '@chakra-ui/react';
import { gql, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { usePartnerRequest } from '../../../../contexts/partner-request';
import { usePartnerRequestCount } from '../../../../contexts/partner-request-count';
import c from '../../../../constant';
import useMoment from '../../../../hooks/useMoment';

const M_SUBMIT_APPROVAL = gql`
	mutation submit($id: String!, $solarDate: String!, $lunarDate: String!, $grade: PartnerReqeustApprovalGrade!) {
		submitContractsApproval(id: $id, solarDate: $solarDate, lunarDate: $lunarDate, grade: $grade)
	}
`;

export default function PartnerRequestContractsApproval({ id = null, grade = null, candidates = [] }) {
	const { t } = useTranslation();
	const { paperSolarDateString, paperLunarDateString } = useMoment();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { loadRequest } = usePartnerRequest();
	const { loadCountPartnerRequest } = usePartnerRequestCount();
	const [required, setRequired] = useState(true);
	const [submitApproval, { loading, data }] = useMutation(M_SUBMIT_APPROVAL);

	useEffect(() => {
		setRequired(
			candidates
				?.filter((e) => e?.certificateStatus === 'APPROVED')
				?.reduce((p, c) => {
					if (c?.contractStatus === null && c?.cancelledContract === false) {
						return true;
					} else {
						return p;
					}
				}, false)
		);
	}, [candidates]);

	useEffect(() => {
		if (data?.submitContractsApproval) {
			onClose();
			loadRequest(id);
			loadCountPartnerRequest();
		}
	}, [data]);

	return (
		<>
			<Button
				isLoading={loading}
				isDisabled={required}
				onClick={onOpen}
				rightIcon={<FontAwesomeIcon icon={faCheck} style={{ fontSize: 16 }} />}
				colorScheme="green"
				variant="solid"
				size="sm"
				borderRadius={c.borderRadius}
				border="1px solid #bdc3c7"
				ml={2}
			>
				{t('Done')}
			</Button>
			<AlertDialog closeOnEsc={false} closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							{t('Done')}
						</AlertDialogHeader>
						<AlertDialogBody>{t('Are you sure?')}</AlertDialogBody>
						<AlertDialogFooter>
							<Button mr={4} isLoading={loading} onClick={onClose}>
								{t('Cancel')}
							</Button>
							<Button
								isLoading={loading}
								colorScheme="green"
								onClick={() => {
									const variables = { id, solarDate: paperSolarDateString(), lunarDate: paperLunarDateString(), grade };
									submitApproval({ variables });
								}}
							>
								{t('Yes')}
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</>
	);
}
