import { Flex, Text } from '@chakra-ui/react';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

function NoData({ label = 'No Data' }) {
	const { t } = useTranslation();

	return (
		<>
			<Flex direction="column" justifyContent="center" alignItems="center" p={8}>
				<FontAwesomeIcon icon={faFileAlt} style={{ fontSize: 48, color: '#A0AEC0' }} />
				<Text fontSize="md" fontWeight="bold" color="gray.600" mt={4}>
					{t(label)}
				</Text>
			</Flex>
		</>
	);
}

export default NoData;
