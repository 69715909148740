import React, { useState, useRef, useEffect } from "react";
import { Flex, Tooltip, Text, Button, useToast } from "@chakra-ui/react";
import {
    faFile,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { gql, useMutation } from '@apollo/client';
import { useTranslation } from "react-i18next";
import c from "../../constant";
const maxFileSize = 10485760 * 10


const ADD_DONE_FILE_MUTATION = gql`
mutation add(
    $attachmentFileSize: Float!
    $attachmentFile: Upload!
    $id: String!
){
    addProposalDoneFileById(
      attachmentFile:$attachmentFile,
      attachmentFileSize:$attachmentFileSize,
      id: $id
    )
  }
`

export default function DoneProposalFile({ id, callback }) {
    const { t } = useTranslation();
    const toast = useToast()
    const fileInput = useRef(null)

    const [attachmentFile, setAttachmentFile] = useState(null)

    const [addDoneFile, { loading, error, data }] = useMutation(
        ADD_DONE_FILE_MUTATION
    );

    useEffect(() => {
        if (error) {
            error.graphQLErrors.map(({ message }, i) => (
                toast({
                    title: t(message),
                    status: "error",
                    isClosable: true,
                    position: "top-right"
                })
            ))
        }
    }, [error])

    useEffect(() => {
        if (data?.addProposalDoneFileById) {
            toast({
                title: t("Done file upload successfully"),
                status: "success",
                isClosable: true,
                position: "top-right"
            })
            callback()
        }
    }, [data])

    const handleClickFile = () => {
        fileInput.current.click()
    }

    const handleFileChange = event => {
        var fileInput = false;
        if (event.target.files[0]) {
            fileInput = true;
        }
        if (fileInput) {
            const file = event.target.files[0]
            if (file.size > maxFileSize) {
                toast({
                    title: t("File size over limit"),
                    status: "warning",
                    isClosable: true,
                    position: "top-right"
                })
            } else {
                setAttachmentFile(event.target.files[0])
            }
        }
    }

    function submitComment() {
        if (attachmentFile) {
            const variables = {
                attachmentFile: attachmentFile,
                attachmentFileSize: attachmentFile?.size,
                id: id,
            }
            addDoneFile({
                variables
            })
        }
    }

    return (
        <>
            <Flex direction="row" alignItems="center" pl="2" className="hidden-print">

                <Flex>
                    <Tooltip label={t("Maximum file size 100MB")}>
                        <Flex maxW={480} borderRadius={c.borderRadius} pr="2" pl="2" h="37px" bg="blue.400" cursor="pointer" alignItems="center" m="2" onClick={() => {
                            if (!loading) {
                                handleClickFile()
                            }
                        }}>
                            <Text minW="122px" w="122px" color="white">{t("Choose Attachment")}</Text>
                            <FontAwesomeIcon
                                icon={faFile}
                                color="white"
                                style={{ fontSize: 18, marginLeft: 4 }}
                            />
                            {attachmentFile?.name && <Text noOfLines={1} bg="gray.200" pl="2" pr="2" ml="2">{attachmentFile?.name}</Text>}
                        </Flex>
                    </Tooltip>
                    <input
                        style={{ display: "none" }}
                        type="file"
                        onChange={(e) => handleFileChange(e)}
                        ref={fileInput}
                    />
                </Flex>
                {attachmentFile?.name && <Flex justifyContent="flex-end">
                    <Button color="#3498DA" isLoading={loading} onClick={() => submitComment()}>{t("Submit")}</Button>
                </Flex>}
            </Flex>
        </>
    );
}
