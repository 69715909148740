import React, { useEffect } from "react";
import c from "../../constant"
import { gql, useLazyQuery } from '@apollo/client';
import useStore from "../../store";
import {
    Text
} from "@chakra-ui/react";

const QUERY_DATA = gql`
query get($province: String, $district: String, $commune: String, $village: String){
    geoCodeResolver(
      province:$province,
      district:$district,
      commune:$commune,
      village:$village
    ){
        province{
            code
            name
            latinName
            administrativeUnit
            administrativeUnitEn
            administrativeUnitLatin
        }
        district{
            code
            name
            latinName
            administrativeUnit
            administrativeUnitEn
            administrativeUnitLatin
        }
        commune{
            code
            name
            latinName
            administrativeUnit
            administrativeUnitEn
            administrativeUnitLatin
        }
        village{
            code
            name
            latinName
            administrativeUnit
            administrativeUnitEn
            administrativeUnitLatin
        }
    }
  }
`
function GeoCodeResoliver({ province = null, district = null, commune = null, village = null, houseNumber = null, streetNumber = null }) {
    const [loadData, { error, loading, data }] = useLazyQuery(QUERY_DATA)
    const { currentLang } = useStore((state) => state.currentLang);

    useEffect(() => {
        loadData({
            variables: {
                province,
                district,
                commune,
                village
            }
        })
    }, [])

    function getAddress(geo) {
        const geoData = geo?.geoCodeResolver

        return <Text>
            {houseNumber && <>
                {
                    `${currentLang === "kh" ? "​ផ្ទះលេខ " : "#"}${houseNumber ? houseNumber : null}`
                }
                {commaEn()}
                &nbsp;
            </>}
            {streetNumber && <>
                {
                    `${currentLang === "kh" ? "ផ្លូវ " : "St. "}${streetNumber ? streetNumber : null}`
                }
                {commaEn()}
                &nbsp;
            </>}
            {geoData?.village && <>
                {getGeoLocationName(geoData?.village)}
                {commaEn()}
                &nbsp;
            </>}
            {geoData?.commune && <>
                {getGeoLocationName(geoData?.commune)}
                {commaEn()}
                &nbsp;
            </>}
            {geoData?.district && <>
                {getGeoLocationName(geoData?.district)}
                {commaEn()}
                &nbsp;
            </>}
            {geoData?.province && <>
                {getGeoLocationName(geoData?.province)}
            </>}
        </Text>
    }

    function getGeoLocationName(data) {
        return data?.code ? (currentLang === "kh" ? `${data?.administrativeUnit}${data?.name}` : `${data?.administrativeUnitLatin} ${data?.latinName}`) : null
    }

    function commaEn() {
        return currentLang !== 'kh' ? ',' : null
    }

    return (
        <>
            {getAddress(data)}
        </>
    );
}

export default GeoCodeResoliver;
