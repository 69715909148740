/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { Box, Text } from '@chakra-ui/react';
import c from '../constant';

const Q_PROPOSAL_BY_ID = gql`
	query get($id: String!) {
		getCompanySubmissionProposalNumberById(id: $id)
	}
`;

const ProposalNumber = ({ id = null }) => {
	const [loadProposal, { data }] = useLazyQuery(Q_PROPOSAL_BY_ID);

	useEffect(() => {
		if (id) {
			loadProposal({ variables: { id } });
		}
	}, [id]);

	return (
		<Box backgroundColor="orange.200" pl={2} pr={2} borderRadius={c.borderRadius}>
			<Text fontSize="md" fontWeight="bold" color="white">
				{data?.getCompanySubmissionProposalNumberById}
			</Text>
		</Box>
	);
};

export default ProposalNumber;
