import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Box, Flex, Button, useToast, Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Grid, GridItem
} from "@chakra-ui/react";
import c from "../../constant"
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { useHistory } from "react-router-dom";
import TextFormField from "../../components/form/TextFormField"
import DateFormField from "../form/DateFormField";
import moment from "moment";
import FileFormField from "../../components/form/FileFormField"
import GeoFormField from "../../components/form/GeoFormField";
import CheckboxFormField from "../../components/form/CheckBoxFormField";
import TextAreaFormField from "../../components/form/TextAreaFormField";

const UPDATE_MUTATION = gql`
mutation create(
    $certificateFile: Upload
    $certificateFileSize: Float
    $geoLocation: String
    $isGeoManual: Boolean
    $geoManual: String
    $validTo: DateTime
    $validFrom: DateTime
    $name: String!
    $nameLatin: String
    $number: String
    $id: String!
    $managerName: String!
    $managerNameLatin: String!
){
    updateCompanyCertificateById(
        certificateFile: $certificateFile
        certificateFileSize: $certificateFileSize
        geoLocation: $geoLocation
        isGeoManual: $isGeoManual
        geoManual: $geoManual
        validTo: $validTo
        validFrom: $validFrom
        name: $name
        nameLatin: $nameLatin
        number: $number
        id: $id
        managerName: $managerName
        managerNameLatin: $managerNameLatin
    )
}
 `


const QUERY_DATA = gql`
 query get($id: String!){
    getCompanyCertificateById(id:$id){
        id
        number
        name
        nameLatin
        managerName
        managerNameLatin
        validFrom
        validTo
        file
        streetNumber
        houseNumber
        geoManual
        isGeoManual
        province
        district
        commune
        village
     }
   }
 `

const signUpSchema = Yup.object().shape({
    number: Yup.string(),
    name: Yup.string().required("Required"),
    nameLatin: Yup.string(),
    managerName: Yup.string(),
    managerNameLatin: Yup.string(),
    isGeoManual: Yup.boolean(),
    geoManual: Yup.mixed().when('isGeoManual', {
        is: (isGeoManual) => isGeoManual === true,
        then: Yup.string().required("required"),
        otherwise: Yup.string()
    }),
    geoLocation: Yup.object().nullable(),
    validFrom: Yup.string().nullable(),
    validTo: Yup.string().nullable(),
    file: Yup.mixed().test("fileSize", "over limit", (value) => {
        if (value) {
            return value?.size <= c.maxFileSize / 10
        } else {
            return true
        }
    }),
});

function Create({
    isOpen = false, onClose = () => null, callback = () => null, id
}) {
    const history = useHistory();
    const toast = useToast()
    const { t } = useTranslation();
    const [isGeoManual, setIsGeoManual] = useState(false)

    const [updateMutation, { loading, error, data }] = useMutation(
        UPDATE_MUTATION
    );

    const [loadData, { loading: loadingData, error: errorData, data: dataData }] = useLazyQuery(
        QUERY_DATA
    );

    useEffect(() => {
        if (dataData?.getCompanyCertificateById?.isGeoManual) {
            setIsGeoManual(true)
        }
    }, [dataData])


    useEffect(() => {
        if (id) {
            loadData({
                variables: {
                    id
                }
            })
        }
    }, [id]);

    function geoObjectToValue(obj) {
        return JSON.stringify({
            province: obj?.province?.value || (obj?.province || "") ? { value: obj?.province?.value || (obj?.province || "") } : null,
            district: obj?.district?.value || (obj?.district || "") ? { value: obj?.district?.value || (obj?.district || "") } : null,
            commune: obj?.commune?.value || (obj?.commune || "") ? { value: obj?.commune?.value || (obj?.commune || "") } : null,
            village: obj?.village?.value || (obj?.village || "") ? { value: obj?.village?.value || (obj?.village || "") } : null,
            streetNumber: obj?.streetNumber || "",
            houseNumber: obj?.houseNumber || ""
        })
    }

    useEffect(() => {
        if (data) {
            if (data?.updateCompanyCertificateById) {
                toast({
                    title: t("Company certificate updated successfully"),
                    status: "success",
                    isClosable: true,
                    position: "top-right"
                })
                onClose()
                callback("Company certificate updated successfully")
            }
        }
    }, [data])

    useEffect(() => {
        if (error) {
            error.graphQLErrors.map(({ message }, i) => (
                toast({
                    title: t(message),
                    status: "error",
                    isClosable: true,
                    position: "top-right"
                })
            ))
        }
    }, [error])

    function geoObjectToValue(obj) {
        return JSON.stringify({
            province: obj?.province?.value || (obj?.province || "") ? { value: obj?.province?.value || (obj?.province || "") } : null,
            district: obj?.district?.value || (obj?.district || "") ? { value: obj?.district?.value || (obj?.district || "") } : null,
            commune: obj?.commune?.value || (obj?.commune || "") ? { value: obj?.commune?.value || (obj?.commune || "") } : null,
            village: obj?.village?.value || (obj?.village || "") ? { value: obj?.village?.value || (obj?.village || "") } : null,
            streetNumber: obj?.streetNumber || "",
            houseNumber: obj?.houseNumber || ""
        })
    }


    function update(values) {
        updateMutation({
            variables: {
                id: id,
                number: values.number,
                name: values.name,
                nameLatin: values.nameLatin,
                managerName: values.managerName,
                managerNameLatin: values.managerNameLatin,
                geoLocation: values.geoLocation ? JSON.stringify(values.geoLocation) : null,
                isGeoManual: values?.isGeoManual,
                geoManual: values?.geoManual,
                certificateFile: values.file,
                certificateFileSize: values?.file?.size,
                validTo: values?.validTo,
                validFrom: values?.validFrom,
            }
        })
    }

    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={() => {
                    onClose()

                }}
                size="4xl"
            >
                <ModalOverlay />
                <ModalContent borderRadius={c.borderRadius} pb="2">
                    <ModalHeader>{t("Edit Company Certificate")}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {dataData?.getCompanyCertificateById && <Formik
                            initialValues={{
                                number: dataData?.getCompanyCertificateById?.number || "",
                                name: dataData?.getCompanyCertificateById?.name || "",
                                nameLatin: dataData?.getCompanyCertificateById?.nameLatin || "",

                                managerName: dataData?.getCompanyCertificateById?.managerName || "",
                                managerNameLatin: dataData?.getCompanyCertificateById?.managerNameLatin || "",

                                geoLocation: {
                                    province: dataData?.getCompanyCertificateById?.province,
                                    district: dataData?.getCompanyCertificateById?.district,
                                    commune: dataData?.getCompanyCertificateById?.commune,
                                    village: dataData?.getCompanyCertificateById?.village,
                                    streetNumber: dataData?.getCompanyCertificateById?.streetNumber,
                                    houseNumber: dataData?.getCompanyCertificateById?.houseNumber,
                                },
                                isGeoManual: dataData?.getCompanyCertificateById?.isGeoManual || false,
                                geoManual: dataData?.getCompanyCertificateById?.geoManual || "",
                                validFrom: dataData?.getCompanyCertificateById?.validFrom ? moment(dataData?.getCompanyCertificateById?.validFrom).toDate() : null,
                                validTo: dataData?.getCompanyCertificateById?.validTo ? moment(dataData?.getCompanyCertificateById?.validTo).toDate() : null,
                                file: null,
                            }}
                            validationSchema={signUpSchema}
                            onSubmit={(values) => {
                                update(values);
                            }}
                        >
                            {(props) => (
                                <Form>
                                    <Box mt="3">
                                        <Grid templateColumns='repeat(2, 1fr)' gap={6} w="100%">
                                            <GridItem w='100%'>
                                                <TextFormField
                                                    {...props}
                                                    label="Number"
                                                    name="number"
                                                />

                                                <TextFormField
                                                    {...props}
                                                    label="Name"
                                                    name="name"
                                                    required
                                                />

                                                <TextFormField
                                                    {...props}
                                                    label="Name Latin"
                                                    name="nameLatin"
                                                />

                                                <TextFormField
                                                    {...props}
                                                    label="Branch Manager Name"
                                                    name="managerName"
                                                />

                                                <TextFormField
                                                    {...props}
                                                    label="Branch Manager Name Latin"
                                                    name="managerNameLatin"
                                                />
                                            </GridItem>
                                            <GridItem w='100%'>
                                                {!isGeoManual && <GeoFormField
                                                    {...props}
                                                    name="geoLocation"
                                                    label="Address"
                                                    buttomComponent={
                                                        <CheckboxFormField
                                                            {...props}
                                                            label="Input"
                                                            name="isGeoManual"
                                                            callbackValueChange={e => setIsGeoManual(e)}
                                                        // values={isGeoManual}
                                                        />
                                                    }
                                                />}

                                                {isGeoManual && <TextAreaFormField
                                                    {...props}
                                                    label="Address"
                                                    name="geoManual"
                                                    required={true}
                                                    buttomComponent={
                                                        <CheckboxFormField
                                                            {...props}
                                                            label="Input"
                                                            name="isGeoManual"
                                                            callbackValueChange={e => setIsGeoManual(e)}
                                                        // values={isGeoManual}
                                                        />
                                                    }
                                                />}

                                                <FileFormField
                                                    {...props}
                                                    label="File"
                                                    name="file"
                                                    maxFileSize={c.maxFileSize / 10}
                                                />
                                            </GridItem>
                                        </Grid>

                                        <Grid templateColumns='repeat(2, 1fr)' gap={6} w="100%">
                                            <GridItem w='100%'>

                                                <DateFormField
                                                    name="validFrom"
                                                    label="Valid From"
                                                    initialDate={dataData?.getCompanyCertificateById?.validFrom}
                                                />
                                            </GridItem>
                                            <GridItem w='100%'>


                                                <DateFormField
                                                    name="validTo"
                                                    label="Valid To"
                                                    initialDate={dataData?.getCompanyCertificateById?.validTo}
                                                />
                                            </GridItem>
                                        </Grid>

                                        <Flex
                                            textAlign="right"
                                            mt="6"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            float="right"
                                        >
                                            <Button
                                                id="login-btn"
                                                variant="solid"
                                                size="md"
                                                h="42px"
                                                w="128px"
                                                bg="primary.dark"
                                                _hover={{ bg: "primary.dark" }}
                                                color="white"
                                                // borderRadius="31px"
                                                type="submit"
                                                isLoading={loading}
                                                loadingText={t("Edit")}
                                                spinnerPlacement="start"
                                            >
                                                {t(`Edit`)}
                                            </Button>
                                        </Flex>
                                    </Box>
                                </Form>
                            )}
                        </Formik>}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
}

export default Create;
