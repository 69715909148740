/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Button,
	Flex,
	Text,
	Textarea,
	Tooltip,
	useDisclosure,
	useToast,
} from '@chakra-ui/react';
import { gql, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { GradeApproval, PartnerRequestStatus } from '../../../../containers/ManageAgentPartner/PartnerRequest/constants';
import useStore from '../../../../store';
import c from '../../../../constant';
import { usePartnerRequest } from '../../../../contexts/partner-request';

const M_SUBMIT_APPROVAL = gql`
	mutation submit($id: String!, $grade: PartnerReqeustApprovalGrade!, $status: PartnerReqeustCertificateStatus, $description: String) {
		submitCertificateApproval(id: $id, grade: $grade, status: $status, description: $description)
	}
`;

let status = null;
let description = null;

export default function PartnerRequestCertificateApproval({ id = null, requestStatus = null }) {
	const toast = useToast();
	const { t } = useTranslation();
	const { loadedData, setLoadedData } = usePartnerRequest();
	const { currentUser: user } = useStore((state) => state.currentUser);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [reason, setReason] = useState('');
	const [submitApproval, { loading, data, error }] = useMutation(M_SUBMIT_APPROVAL);

	useEffect(() => {
		if (data?.submitCertificateApproval) {
			onClose();
			setLoadedData({
				...loadedData,
				candidates: loadedData?.candidates?.map((e) => {
					if (e?.id === id) {
						e.certificateStatus = status?.toUpperCase();
						e.certificateStatusDescription = description;
					}
					return e;
				}),
			});
		}
	}, [data]);

	useEffect(() => {
		if (error) {
			onClose();
			if (error?.graphQLErrors?.length > 0) {
				error?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(error?.message ? error?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [error]);

	return (
		<>
			<Flex>
				<Tooltip label={t('approve')}>
					<Button
						colorScheme="green"
						variant="solid"
						size="md"
						h="28px"
						w="76px"
						fontSize="sm"
						fontWeight="normal"
						mr={1}
						borderRadius={c.borderRadius}
						isLoading={loading}
						isDisabled={
							(requestStatus === PartnerRequestStatus.reviewAgent && c.stepOfficeRoles.includes(user?.position)) ||
							(requestStatus === PartnerRequestStatus.reviewAgentFinal && c.stepDepartmentRoles.includes(user?.position))
								? false
								: true
						}
						onClick={() => {
							status = 'Approved';
							description = null;
							const grade = c.stepOfficeRoles.includes(user?.position) ? GradeApproval.primary : GradeApproval.secondary;
							const variables = { id, grade, status, description };
							submitApproval({ variables });
						}}
					>
						{t('approve')}
					</Button>
				</Tooltip>
				<Tooltip label={t('Reject')}>
					<Button
						colorScheme="red"
						variant="solid"
						size="md"
						h="28px"
						w="76px"
						fontSize="sm"
						fontWeight="normal"
						ml={1}
						borderRadius={c.borderRadius}
						isLoading={loading}
						isDisabled={
							(requestStatus === PartnerRequestStatus.reviewAgent && c.stepOfficeRoles.includes(user?.position)) ||
							(requestStatus === PartnerRequestStatus.reviewAgentFinal && c.stepDepartmentRoles.includes(user?.position))
								? false
								: true
						}
						onClick={onOpen}
					>
						{t('Reject')}
					</Button>
				</Tooltip>
			</Flex>
			<AlertDialog closeOnEsc={false} closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							{t('Reject')}
						</AlertDialogHeader>
						<AlertDialogBody>
							<Text>
								{t('Please input rejection reason')}
								<span style={{ color: 'red' }}>*</span>
							</Text>
							<Textarea mt={2} value={reason} onChange={(e) => setReason(e.target.value ?? '')} />
						</AlertDialogBody>
						<AlertDialogFooter>
							<Button mr={4} isLoading={loading} onClick={onClose}>
								{t('Cancel')}
							</Button>
							<Button
								isLoading={loading}
								colorScheme="red"
								disabled={reason ? false : true}
								onClick={() => {
									status = 'Rejected';
									description = reason;
									const grade = c.stepOfficeRoles.includes(user?.position) ? GradeApproval.primary : GradeApproval.secondary;
									const variables = { id, grade, status, description };
									submitApproval({ variables });
								}}
							>
								{t('Reject')}
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</>
	);
}
