/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {
	Box,
	FormControl,
	FormLabel,
	FormHelperText,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	NumberIncrementStepper,
	NumberDecrementStepper,
} from '@chakra-ui/react';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';

const InputNumberFormField = ({ name = null, label = null, min = 1, max = 100, step = 1, precision = 2, disabled = false, required = false }) => {
	const { t } = useTranslation();

	return (
		<Field name={name}>
			{({ field, meta, form }) => {
				return (
					<Box mb="4">
						<FormControl id={name}>
							<FormLabel>
								{t(label)}
								{required && <span style={{ color: 'red' }}>*</span>}
							</FormLabel>
							<NumberInput
								isDisabled={disabled}
								defaultValue={parseFloat(field?.value)}
								min={min}
								max={max}
								step={step}
								precision={precision}
								onChange={(value) => form.setFieldValue(name, parseFloat(value))}
							>
								<NumberInputField />
								<NumberInputStepper>
									<NumberIncrementStepper />
									<NumberDecrementStepper />
								</NumberInputStepper>
							</NumberInput>
							{meta.touched && meta.error && (
								<FormHelperText id={`error-message-${name}`} color="red.400" fontSize="12px">
									{t(name)} {t('is')} {t(meta.error)}
								</FormHelperText>
							)}
						</FormControl>
					</Box>
				);
			}}
		</Field>
	);
};

export default InputNumberFormField;
