import { Badge, Text, Tooltip } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const PartnerRequestCancelledContractStatus = () => {
	const { t } = useTranslation();

	return (
		<Tooltip label={t('CANCELLED CONTRACT')}>
			<Badge pl={2} pr={2} colorScheme="red" variant="outline">
				<Text fontWeight="bold" fontSize="xs" color="red.500">
					{t('CANCELLED CONTRACT')}
				</Text>
			</Badge>
		</Tooltip>
	);
};

export default PartnerRequestCancelledContractStatus;
