/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, Flex, Box, useToast, Button } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { gql, useMutation } from '@apollo/client';
import TextFormField from '../form/TextFormField';
import FileFormField from '../form/FileFormField';
import c from '../../constant';
import * as Yup from 'yup';

const EDIT_PROFESSIONAL_MUTATION = gql`
	mutation edit($id: String!, $number: String, $khmerName: String, $latinName: String, $referenceFile: Upload, $referenceSize: Float) {
		editCompanyProIndividual(
			id: $id
			number: $number
			khmerName: $khmerName
			latinName: $latinName
			referenceFile: $referenceFile
			referenceSize: $referenceSize
		)
	}
`;

const validationSchema = Yup.object().shape({
	documentNumber: Yup.string(),
	isBroker: Yup.boolean(),
	khmerName: Yup.mixed().when('isBroker', {
		is: (isBroker) => isBroker === true,
		then: Yup.string(),
		otherwise: Yup.string(),
	}),
	latinName: Yup.mixed().when('isBroker', {
		is: (isBroker) => isBroker === true,
		then: Yup.string(),
		otherwise: Yup.string(),
	}),
	referenceDocument: Yup.mixed().test('fileSize', 'over limit', (value) => {
		if (value) {
			return value?.size <= c.maxFileSize / 10;
		} else {
			return true;
		}
	}),
});

function EditLicensedProIndividualModal({
	id = null,
	number = null,
	khmerName = null,
	latinName = null,
	isOpen = false,
	onClose = () => null,
	onCallback = () => null,
}) {
	const toast = useToast();
	const { t } = useTranslation();
	const [isBroker] = useState(khmerName !== null && latinName !== null);
	const [editCompanyProIndividual, { loading, data, error }] = useMutation(EDIT_PROFESSIONAL_MUTATION);

	useEffect(() => {
		if (error) {
			if (error?.graphQLErrors?.length > 0) {
				error?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(error?.message ? error?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [error]);

	useEffect(() => {
		if (data) {
			if (data.editCompanyProIndividual) {
				toast({
					title: t('Professional individual has been edited successfully'),
					status: 'success',
					duration: 5000,
					isClosable: true,
					position: 'bottom-right',
				});
				onClose();
				onCallback();
			}
		}
	}, [data]);

	const submit = (values) => {
		const variables = {
			id,
			number: values?.documentNumber,
			khmerName: values?.khmerName,
			latinName: values?.latinName,
			referenceFile: values?.referenceDocument,
			referenceSize: values?.referenceDocument?.size,
		};
		editCompanyProIndividual({ variables });
	};

	return (
		<>
			<Modal closeOnEsc={false} closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent borderRadius={c.borderRadius} pb="2">
					<ModalHeader>{t('Edit')}</ModalHeader>
					<ModalCloseButton isDisabled={loading} />
					<ModalBody>
						<Formik
							initialValues={{
								documentNumber: number,
								isBroker,
								khmerName: khmerName || '',
								latinName: latinName || '',
								referenceDocument: null,
							}}
							validationSchema={validationSchema}
							onSubmit={submit}
						>
							{(props) => (
								<Form>
									<Box mt="3">
										<TextFormField {...props} label="Number" name="documentNumber" />
										{isBroker && <TextFormField {...props} label="Khmer Name" name="khmerName" />}
										{isBroker && <TextFormField {...props} label="Latin Name" name="latinName" />}
										<FileFormField {...props} label="Reference Document" name="referenceDocument" maxFileSize={c.maxFileSize / 10} />
										<Flex textAlign="right" mt="6" justifyContent="space-between" alignItems="center">
											<Button
												id="login-btn"
												variant="solid"
												size="md"
												h="42px"
												w="128px"
												bg="primary.dark"
												_hover={{ bg: 'primary.dark' }}
												color="white"
												type="submit"
												isLoading={loading}
												loadingText={t('Add')}
												spinnerPlacement="start"
											>
												{t(`Edit`)}
											</Button>
										</Flex>
									</Box>
								</Form>
							)}
						</Formik>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
}

export default EditLicensedProIndividualModal;
