/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Button, useToast, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, Grid, GridItem } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { gql, useMutation } from '@apollo/client';
import TextFormField from '../../components/form/TextFormField';
import FileFormField from '../../components/form/FileFormField';
import GeoFormField from '../../components/form/GeoFormField';
import CheckboxFormField from '../../components/form/CheckBoxFormField';
import TextAreaFormField from '../../components/form/TextAreaFormField';
import DateFormField from '../form/DateFormField';
import c from '../../constant';
import * as Yup from 'yup';

const CREATE_MUTATION = gql`
	mutation create(
		$certificateFile: Upload
		$certificateFileSize: Float
		$geoLocation: String
		$isGeoManual: Boolean
		$geoManual: String
		$validTo: DateTime
		$validFrom: DateTime
		$name: String!
		$nameLatin: String
		$managerName: String
		$managerNameLatin: String
		$number: String
		$companyID: String!
		$companyCertificateType: String
	) {
		createCompanyCertificate(
			certificateFile: $certificateFile
			certificateFileSize: $certificateFileSize
			geoLocation: $geoLocation
			isGeoManual: $isGeoManual
			geoManual: $geoManual
			validTo: $validTo
			validFrom: $validFrom
			name: $name
			nameLatin: $nameLatin
			managerName: $managerName
			managerNameLatin: $managerNameLatin
			number: $number
			companyID: $companyID
			companyCertificateType: $companyCertificateType
		)
	}
`;

const signUpSchema = Yup.object().shape({
	number: Yup.string(),
	name: Yup.string().required('Required'),
	nameLatin: Yup.string(),
	managerName: Yup.string(),
	managerNameLatin: Yup.string(),
	isGeoManual: Yup.boolean(),
	geoManual: Yup.mixed().when('isGeoManual', {
		is: (isGeoManual) => isGeoManual === true,
		then: Yup.string().required('required'),
		otherwise: Yup.string(),
	}),
	geoLocation: Yup.object().nullable(),
	validFrom: Yup.string().nullable(),
	validTo: Yup.string().nullable(),
	file: Yup.mixed().test('fileSize', 'over limit', (value) => {
		if (value) {
			return value?.size <= c.maxFileSize / 10;
		} else {
			return true;
		}
	}),
});

function Create({ isOpen = false, onClose = () => null, callback = () => null, companyId }) {
	const toast = useToast();
	const { t } = useTranslation();
	const [isGeoManual, setIsGeoManual] = useState(false);
	const [createCompanyCertificate, { loading, error, data }] = useMutation(CREATE_MUTATION);

	useEffect(() => {
		if (data) {
			if (data?.createCompanyCertificate) {
				toast({
					title: t('Company Certificate created successfully'),
					status: 'success',
					isClosable: true,
					position: 'top-right',
				});
				onClose();
				callback('Company Certificate created successfully');
			}
		}
	}, [data]);

	useEffect(() => {
		if (error) {
			error.graphQLErrors.map(({ message }, i) =>
				toast({
					title: t(message),
					status: 'error',
					isClosable: true,
					position: 'top-right',
				})
			);
		}
	}, [error]);

	function create(values) {
		createCompanyCertificate({
			variables: {
				companyID: companyId,
				number: values.number,
				name: values.name,
				nameLatin: values.nameLatin,
				managerName: values.managerName,
				managerNameLatin: values.managerNameLatin,
				geoLocation: JSON.stringify(values.geoLocation),
				isGeoManual: values?.isGeoManual,
				geoManual: values?.geoManual,
				certificateFile: values.file,
				certificateFileSize: values?.file?.size,
				validTo: values?.validTo,
				validFrom: values?.validFrom,
				companyCertificateType: 'b',
			},
		});
	}

	return (
		<>
			<Modal isOpen={isOpen} onClose={onClose} size="4xl">
				<ModalOverlay />
				<ModalContent borderRadius={c.borderRadius} pb="2">
					<ModalHeader>{t('Add Company Certificate')}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Formik
							initialValues={{
								number: '',
								name: '',
								nameLatin: '',
								managerName: '',
								managerNameLatin: '',
								geoLocation: null,
								isGeoManual: isGeoManual,
								geoManual: '',
								validFrom: null,
								validTo: null,
								file: null,
							}}
							validationSchema={signUpSchema}
							onSubmit={create}
						>
							{(props) => (
								<Form>
									<Box mt="3">
										<Grid templateColumns="repeat(2, 1fr)" gap={6} w="100%">
											<GridItem w="100%">
												<TextFormField {...props} label="Number" name="number" />
												<TextFormField {...props} label="Name" name="name" required />
												<TextFormField {...props} label="Name Latin" name="nameLatin" />
												<TextFormField {...props} label="Branch Manager Name" name="managerName" />
												<TextFormField {...props} label="Branch Manager Name Latin" name="managerNameLatin" />
											</GridItem>
											<GridItem w="100%">
												{!isGeoManual && (
													<GeoFormField
														{...props}
														name="geoLocation"
														label="Address"
														buttomComponent={
															<CheckboxFormField
																{...props}
																label="Input"
																name="isGeoManual"
																callbackValueChange={(e) => setIsGeoManual(e)}
																// values={isGeoManual}
															/>
														}
													/>
												)}
												{isGeoManual && (
													<TextAreaFormField
														{...props}
														label="Address"
														name="geoManual"
														required={true}
														buttomComponent={
															<CheckboxFormField
																{...props}
																label="Input"
																name="isGeoManual"
																callbackValueChange={(e) => setIsGeoManual(e)}
																// values={isGeoManual}
															/>
														}
													/>
												)}
												<FileFormField {...props} label="File" name="file" maxFileSize={c.maxFileSize / 10} />
											</GridItem>
										</Grid>
										<Grid templateColumns="repeat(2, 1fr)" gap={6} w="100%">
											<GridItem w="100%">
												<DateFormField name="validFrom" label="Valid From" />
											</GridItem>
											<GridItem w="100%">
												<DateFormField name="validTo" label="Valid To" />
											</GridItem>
										</Grid>
										<Flex textAlign="right" mt="6" justifyContent="space-between" alignItems="center" float="right">
											<Button
												id="login-btn"
												variant="solid"
												size="md"
												h="42px"
												w="128px"
												bg="primary.dark"
												_hover={{ bg: 'primary.dark' }}
												color="white"
												// borderRadius="31px"
												type="submit"
												isLoading={loading}
												loadingText={t('Add')}
												spinnerPlacement="start"
											>
												{t(`Add`)}
											</Button>
										</Flex>
									</Box>
								</Form>
							)}
						</Formik>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
}

export default Create;
