import React, { useEffect } from "react";
import {
  Box, Flex, Text, Button,
  FormLabel,
  InputGroup,
  Input,
  InputRightElement,
  useToast
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { useTranslation } from "react-i18next";
import moment from "moment";
import UserDislayNameById from "../../UserDislayNameById"

const CREATE_COMPANY_USER = gql`
    mutation clientSignUp($companyId: String!, $username:String!, $password: String!, $email: String){
        clientSignUp(companyId:$companyId,username:$username, password:$password, email:$email)
    }
`

const UPDATE_COMPANY_USER = gql`
    mutation update($companyId: String!, $username:String!, $password: String!, $email: String){
        updateClientUsernameOrPasswordByCompanyId(
            companyId:$companyId,
            username:$username,
            password:$password,
            email:$email
        )
    }
`

const QUERY_COMPANY_USER = gql`
query get($id: String!){
    getClientUserByCompanyId(companyId:$id){
      id
      email
      username
      lastChangePassword
      lastChangePasswordBy
    }
  }
`

const signUpSchema = Yup.object().shape({
  username: Yup.string().min(3).required("Required"),
  password: Yup.string().min(8),
  email: Yup.string().email()
});

function AccountComponent({ id }) {

  const { t } = useTranslation();
  const toast = useToast()

  const [createCompanyUser, { loading, error, data }] = useMutation(
    CREATE_COMPANY_USER
  );

  const [updateCompanyUser, { loading: updateLoading, error: updateError, data: updateData }] = useMutation(
    UPDATE_COMPANY_USER
  );

  const [loadCompanyUser, { loading: loadingCompanyUser, error: errorCompanyUser, data: dataCompanyUser }] = useLazyQuery(
    QUERY_COMPANY_USER
  );

  useEffect(() => {
    loadCompanyUser({
      variables: {
        id
      }
    })
  }, [])

  useEffect(() => {
    if (data) {
      toast({
        title: t("Company user created successfully"),
        status: "success",
        isClosable: true,
        position: "top-right"
      })
      loadCompanyUser({
        variables: {
          id
        }
      })
    }
  }, [data])

  useEffect(() => {
    if (updateData) {
      toast({
        title: t("Company user updated successfully"),
        status: "success",
        isClosable: true,
        position: "top-right"
      })
      loadCompanyUser({
        variables: {
          id
        }
      })
    }
  }, [updateData])

  useEffect(() => {
    if (error) {
      toast({
        title: t(error?.message ? error?.message : "something went wrong"),
        status: "error",
        isClosable: true,
        position: "bottom-right"
      })
    }
  }, [error])

  useEffect(() => {
    if (updateError) {
      toast({
        title: t(updateError?.message ? updateError?.message : "something went wrong"),
        status: "error",
        isClosable: true,
        position: "bottom-right"
      })
    }
  }, [updateError])

  function submitUpdateCompanyUser(values) {
    if (dataCompanyUser?.getClientUserByCompanyId?.username) {
      updateCompanyUser({
        variables: {
          companyId: id,
          username: values?.username,
          password: values?.password,
          email: values?.email,
        }
      })
    } else {
      createCompanyUser({
        variables: {
          companyId: id,
          username: values?.username,
          password: values?.password,
          email: values?.email,
        }
      })
    }
  }

  return (
    <Box maxW="480px">
      {!loadingCompanyUser && <Formik
        initialValues={{
          username: dataCompanyUser?.getClientUserByCompanyId?.username || "",
          password: "",
          email: dataCompanyUser?.getClientUserByCompanyId?.email || "",
        }}
        validationSchema={signUpSchema}
        onSubmit={(values) => {
          submitUpdateCompanyUser(values);
          // console.log(values);
        }}
      >
        {(props) => (
          <Form>
            <Box mt="3">
              <Field name="username">
                {({ field, meta }) =>
                (
                  <>
                    <FormLabel>{t("Username")}<span style={{ color: "red" }}>*</span></FormLabel>
                    <Input
                      {...field}
                      id="username"
                      size="lg"
                      fontWeight="500"
                      autoComplete="off"
                    />
                    {meta.touched && meta.error && (
                      <Text
                        id="error-message-username"
                        color="red.400"
                        fontSize="12px"
                      >
                        {t("Username")}{" "}
                        {t("is")}{" "}
                        {t(meta.error)}
                      </Text>
                    )}
                  </>
                )}
              </Field>

              <Field name="email">
                {({ field, meta }) =>
                (
                  <>
                    <FormLabel mt="4">{t("Email")}</FormLabel>
                    <Input
                      {...field}
                      id="email"
                      size="lg"
                      fontWeight="500"
                      autoComplete="off"
                    />
                    {meta.touched && meta.error && (
                      <Text
                        id="error-message-email"
                        color="red.400"
                        fontSize="12px"
                      >
                        {t("Email")}{" "}
                        {t("is")}{" "}
                        {t(meta.error)}
                      </Text>
                    )}
                  </>
                )}
              </Field>

              <Field name="password">
                {({ field, meta, form }) => (
                  <>
                    <FormLabel mt="4">{t("Password")}<span style={{ color: "red" }}>*</span></FormLabel>
                    <InputGroup size="md">
                      <Input
                        {...field}
                        id="password"
                        size="lg"
                        fontWeight="500"
                        autoComplete="off"
                      />
                      <InputRightElement width="4.5rem">
                        <Button
                          mt="8px"
                          mr="6px"
                          h="100%"
                          size="sm"
                          onClick={() => {
                            form.setFieldValue('password', generatePassword(), true);
                          }}
                        >
                          {t("Generate")}
                        </Button>
                      </InputRightElement>
                    </InputGroup>

                    {meta.touched && meta.error && (
                      <Text
                        id="error-message-password"
                        color="red.400"
                        fontSize="12px"
                      >
                        {t("Password")}{" "}
                        {t("is")}{" "}
                        {t(meta.error)}
                      </Text>
                    )}
                    {dataCompanyUser?.getClientUserByCompanyId?.lastChangePassword && <>
                      <Text mt="4">{t("Last Changed Password At")}:&nbsp;{dataCompanyUser?.getClientUserByCompanyId?.lastChangePassword ? moment(dataCompanyUser?.getClientUserByCompanyId?.lastChangePassword).format("LLLL") : ""}</Text>
                      <Text mt="4">{t("Last Changed Password By")}:&nbsp;{dataCompanyUser?.getClientUserByCompanyId?.lastChangePasswordBy ? <b><UserDislayNameById id={dataCompanyUser?.getClientUserByCompanyId?.lastChangePasswordBy} /></b> : <b>{t("Company")}</b>}</Text>
                    </>}
                  </>
                )}
              </Field>


              <Flex
                textAlign="right"
                mt="6"
                justifyContent="space-between"
                alignItems="center"
              >
                <Button
                  id="login-btn"
                  variant="solid"
                  size="md"
                  h="42px"
                  w="128px"
                  bg="primary.dark"
                  _hover={{ bg: "primary.dark" }}
                  color="white"
                  // borderRadius="31px"
                  type="submit"
                  isLoading={loading}
                  loadingText={t("CREATE")}
                  spinnerPlacement="start"
                >
                  {t(`CREATE`)}
                </Button>
              </Flex>
            </Box>
          </Form>
        )}
      </Formik>}
    </Box>
  );
}

export default AccountComponent;

const generatePassword = (
  length = 8,
  wishlist = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz~!@-#$'
) =>
  Array.from(crypto.getRandomValues(new Uint32Array(length)))
    .map((x) => wishlist[x % wishlist.length])
    .join('')