import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Container, Box, Flex, Text, Center, Button, useToast,
    RadioGroup, Stack, Radio, FormControl, FormLabel, FormHelperText, SimpleGrid
} from "@chakra-ui/react";
import BreadcrumbComponent from "../../../components/BreadcrumbComponent/BreadcrumbComponent";
import c from "../../../constant"
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { useHistory } from "react-router-dom";
import DateRangeFormField from "../../../components/form/DateRangeFormField";
import moment from "moment";
import 'moment/locale/en-gb';
import CompanyPickerModal from "../../../components/Modal/CompanyPickerModal"
import UploadingModalProgress from "../../../components/UploadingModalProgress/UploadingModalProgress"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons";
import useStore from "../../../store";
import { utils } from '@amir04lm26/react-modern-calendar-date-picker';

import CompanyFormField from "../../../components/form/CompanyFormField"
import TextFormField from "../../../components/form/TextFormField"
import TextAreaFormField from "../../../components/form/TextAreaFormField"
import RadioFormField from "../../../components/form/RadioFormField"
import SelectFormField from "../../../components/form/SelectFormField"
import FileFormField from "../../../components/form/FileFormField"
import DateInputFormField from "../../../components/form/DateInputFormField"

const MAX_FILE_SIZE = 10485760;

const QUERY_PUBLIC_SERVICE = gql`
query{
    getPublicPublicServices{
      id
      title
      value
      steps
    }
  }
`

const complaintOnInsuranceSchema = Yup.object().shape({
    companyName: Yup.string().when('selectedCompany', {
        is: (selectedCompany) => !selectedCompany,
        then: Yup.string().required("required"),
        otherwise: Yup.string()
    }),
    selectedCompany: Yup.string(),

    idCardNumber: Yup.string().required("required"),
    firstName: Yup.string().required("required"),
    lastName: Yup.string().required("required"),
    firstNameEn: Yup.string().required("required"),
    lastNameEn: Yup.string().required("required"),
    gender: Yup.string().required("required"),
    dob: Yup.string().nullable(),
    educationCertificate: Yup.mixed().test("fileSize", "over limit", (value) => {
        if (value) {
            return value?.size <= MAX_FILE_SIZE
        } else {
            return true
        }
    }).required('required'),
    specializedCertificate: Yup.mixed().test("fileSize", "over limit", (value) => {
        if (value) {
            return value?.size <= MAX_FILE_SIZE
        } else {
            return true
        }
    }).required('required'),
    contract: Yup.mixed().test("fileSize", "over limit", (value) => {
        if (value) {
            return value?.size <= MAX_FILE_SIZE
        } else {
            return true
        }
    }).required('required'),
});


const CREATE_MUTATION = gql`
    mutation cteareInsuranceSaleStaff(
        $contract: Upload
        $contractSize: Float
        $specializedCertificate: Upload
        $specializedCertificateSize: Float
        $educationCertificate: Upload
        $educationCertificateSize: Float
        $gender: String!
        $dob: String!
        $lastNameEn: String!
        $firstNameEn: String!
        $lastName: String!
        $firstName: String!
        $idCardNumber: String!
        $selectedCompany: String!
    ){
        adminAddInsuranceSaleStaff(
            contract: $contract
            contractSize: $contractSize
            specializedCertificate: $specializedCertificate
            specializedCertificateSize: $specializedCertificateSize
            educationCertificate: $educationCertificate
            educationCertificateSize: $educationCertificateSize
            gender: $gender
            dob: $dob
            lastNameEn: $lastNameEn
            firstNameEn: $firstNameEn
            lastName: $lastName
            firstName: $firstName
            idCardNumber: $idCardNumber
            selectedCompany: $selectedCompany
        )
    }
`

function AddSaleStaff() {
    moment.locale("en-gb")
    const history = useHistory();
    const toast = useToast()
    const { t } = useTranslation();
    const [showCompanyPicker, setShowCompanyPicker] = useState(false)
    const [selectedCompany, setSelectedCompany] = useState(null)
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const [uploading, setUploading] = useState(false)
    const [progress, setProgress] = useState(0);

    const [createSaleStaff, { error, data }] = useMutation(
        CREATE_MUTATION
    );

    useEffect(() => {
        if (error) {
            setUploading(false)
            if (error?.graphQLErrors?.length > 0) {
                error?.graphQLErrors.map(({ message }, i) => (
                    toast({
                        title: t(message),
                        status: "error",
                        isClosable: true,
                        position: "bottom-right"
                    })
                ))
            } else {
                toast({
                    title: t(error?.message ? error?.message : "something went wrong"),
                    status: "error",
                    isClosable: true,
                    position: "bottom-right"
                })
            }
        }
    }, [error])

    useEffect(() => {
        if (data) {
            if (data.adminAddInsuranceSaleStaff) {
                setUploading(false)
                toast({
                    title: t("Sale staff created successfully"),
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                    position: "bottom-right"
                })
                history.push('/company-sale-professional/list?page=1&status=approved')
            }
        }
    }, [data])

    async function submit(values) {
        setUploading(true)
        let variables = {
            contract: values.contract,
            contractSize: values?.contract?.size,
            specializedCertificate: values.specializedCertificate,
            specializedCertificateSize: values?.specializedCertificate?.size,
            educationCertificate: values.educationCertificate,
            educationCertificateSize: values?.educationCertificate?.size,
            gender: values?.gender,
            dob: values?.dob,
            lastNameEn: values?.lastNameEn,
            firstNameEn: values?.firstNameEn,
            lastName: values?.lastName,
            firstName: values?.firstName,
            idCardNumber: values?.idCardNumber,
            selectedCompany: values?.selectedCompany
        }


        let abort;
        createSaleStaff({
            variables,
            context: {
                fetchOptions: {
                    useUpload: true,
                    onProgress: (ev) => {
                        setProgress(ev.loaded / ev.total);
                    },
                    onAbortPossible: (abortHandler) => {
                        abort = abortHandler;
                    }
                }
            }
        })

        if (abort) {
            abort();
        }
    }

    return (
        <>
            <Container maxW="container.xl" mt="16px" mb="16px">
                <BreadcrumbComponent
                    list={[
                        {
                            name: 'Company Sale Professional',
                            path: '/company-sale-professional/menu',
                        },
                        {
                            name: 'List',
                            path: '/company-sale-professional/list?page=1&status=approved',
                        },
                        {
                            name: 'Add',
                            path: '#',
                        },
                    ]}
                />
                <Center mt="5">
                    <Box
                        w="100%"
                        bg="white"
                        borderRadius={c.borderRadius}
                        border="1px solid #dbdbdb"
                        boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
                        pb="32px"
                    >
                        <Flex bg="#FAFAFA" p="16px" pt="8px" pb="8px" mb="16px" justifyContent="space-between" borderBottom="1px solid #dbdbdb">
                            <Text
                                fontSize="x-large"
                                fontWeight="bold"
                            >
                                {t("Add Sale Staff")}
                            </Text>
                        </Flex>

                        <Box p="16px">
                            <Formik
                                initialValues={{

                                }}
                                validationSchema={complaintOnInsuranceSchema}
                                onSubmit={(values) => {
                                    submit(values)
                                }}
                            >
                                {(props) => (
                                    <Form>
                                        <SimpleGrid columns={[2, null, 4]} spacing={4}>
                                            {selectedCompany?.id && <CompanyFormField
                                                {...props}
                                                label="Company/Institution Name"
                                                name="selectedCompany"
                                                required
                                                callBackClear={() => setSelectedCompany(null)}
                                                callBackList={() => setShowCompanyPicker(true)}
                                                selectedCompany={selectedCompany}
                                            />}
                                            {!selectedCompany?.id && <TextFormField
                                                disabled
                                                {...props}
                                                label="Company/Institution Name"
                                                name="companyName"
                                                required
                                                rightElement={
                                                    <Button
                                                        onMouseDown={() => setShowCompanyPicker(true)}
                                                        leftIcon={
                                                            <FontAwesomeIcon
                                                                style={{
                                                                    fontSize: 16,
                                                                    cursor: "pointer",
                                                                }}
                                                                icon={faList}
                                                            />
                                                        }
                                                        mt="7px"
                                                        variant="solid"
                                                        h="42px"
                                                        mr="3px"
                                                        borderRadius={c.borderRadius}
                                                    >
                                                        {t("List")}
                                                    </Button>
                                                }
                                            />}
                                            <TextFormField
                                                {...props}
                                                label="ID Card Number"
                                                name="idCardNumber"
                                                required
                                            />
                                            <TextFormField
                                                {...props}
                                                label="First Name"
                                                name="firstName"
                                                required
                                            />
                                            <TextFormField
                                                {...props}
                                                label="Last Name"
                                                name="lastName"
                                                required
                                            />
                                            <TextFormField
                                                {...props}
                                                label="First Name En"
                                                name="firstNameEn"
                                                required
                                            />
                                            <TextFormField
                                                {...props}
                                                label="Last Name En"
                                                name="lastNameEn"
                                                required
                                            />

                                            <RadioFormField
                                                label="Gender"
                                                name="gender"
                                                radios={[
                                                    { label: 'Male', value: 'M' },
                                                    { label: 'Female', value: 'F' },
                                                ]}
                                                direction="row"
                                                required
                                            />

                                            <DateInputFormField {...props} name="dob" label="DOB" required />

                                            <FileFormField
                                                {...props}
                                                label="Insurance Education Certificate"
                                                name="educationCertificate"
                                                maxFileSize={MAX_FILE_SIZE}
                                                required
                                            />
                                            <FileFormField
                                                {...props}
                                                label="Specialized Certificate"
                                                name="specializedCertificate"
                                                maxFileSize={MAX_FILE_SIZE}
                                                required
                                            />
                                            <FileFormField
                                                {...props}
                                                label="Contract"
                                                name="contract"
                                                maxFileSize={MAX_FILE_SIZE}
                                                required
                                            />

                                        </SimpleGrid>

                                        <Button
                                            id="login-btn"
                                            variant="solid"
                                            size="md"
                                            h="42px"
                                            w="128px"
                                            type="submit"
                                            borderRadius={c.borderRadius}
                                            float="right"
                                        >
                                            {t("Submit")}
                                        </Button>

                                    </Form>

                                )}
                            </Formik>
                        </Box>
                    </Box>
                </Center>
            </Container>

            <UploadingModalProgress isOpen={uploading} progress={progress} />

            {showCompanyPicker && <CompanyPickerModal onClose={() => setShowCompanyPicker(false)} callBack={e => setSelectedCompany(e)} />}
        </>
    );
}

export default AddSaleStaff;
