/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Container,
	Box,
	Flex,
	Text,
	Center,
	Checkbox,
	Badge,
	Tooltip,
	Button,
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon,
	useDisclosure,
} from '@chakra-ui/react';
import BreadcrumbComponent from '../../components/BreadcrumbComponent/BreadcrumbComponent';
import { faSlidersH, faPrint, faFileExcel, faRetweet } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { gql, useLazyQuery } from '@apollo/client';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { useLocation, useHistory } from 'react-router-dom';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import ReactPaginate from 'react-paginate';
import ListAuditCompanyProposalFilter from '../../components/Filter/ListAuditCompanyProposalFilter';
import LoadingResponsiveTable from '../../components/Table/LoadingResponsive';
import datePickerFromToToDate from '../../utils/datePickerFromToToDate';
import PrintLayout from '../../components/Print/PrintLayout';
import ExcelExportModal from '../../components/Modal/ExcelExportModal';
import ProposalDetailModal from './detailModal';
import LoadingOverlayText from '../../components/LoadingOverlay/LoadingOverlayText';
import ProposalReassignModal from '../../components/Modal/ProposalReassignModal';
import moment from 'moment';
import useStore from '../../store';
import c from '../../constant';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import './style.css';

const approved = 'approved';
const rejected = 'rejected';
const tabs = [
	{ label: 'In progress', value: 'in progress' },
	{ label: 'approved', value: approved },
	{ label: 'Rejected', value: rejected },
	{ label: 'All', value: 'all' },
];

const QUERY_PROPOSALS = gql`
	query getCompanySubmissionProposals(
		$limit: Float
		$offset: Float
		$fromDate: DateTime
		$toDate: DateTime
		$read: Boolean
		$number: Float
		$companyName: String
		$department: String
		$office: String
		$status: String
		$publicService: Boolean
		$spam: Boolean
		$showNearlyDue: Boolean
		$state: String
		$showIncomplete: Boolean
		$auditServiceStatus: AuditServiceStatus
	) {
		getCompanySubmissionProposals(
			pagerInput: { limit: $limit, offset: $offset }
			fromDate: $fromDate
			toDate: $toDate
			read: $read
			number: $number
			companyName: $companyName
			department: $department
			office: $office
			status: $status
			publicService: $publicService
			spam: $spam
			showNearlyDue: $showNearlyDue
			state: $state
			showIncomplete: $showIncomplete
			auditServiceOnly: true
			auditServiceStatus: $auditServiceStatus
		) {
			companySubmissionProposals {
				id
				no
				companyName
				subject
				createdAt
				read
				assignTo {
					read
					user {
						id
					}
				}
				approved
				approvedAt
				dueDate
				publicService
				spam
				company {
					id
					name
					nameKhmer
					logo
				}
				done
				doneAt
				submissionStatus
				startServiceDate
				auditServiceStatus
			}
			countUnreadCompanySubmissionProposals
			totalPages
			totalRows
		}
	}
`;

const QUERY_ALL_PUBLIC_SERVICES = gql`
	query {
		getAllPublicServices {
			id
			value
			title
			active
		}
	}
`;

function AuditCompanyProposal() {
	const { t } = useTranslation();
	const { currentLang } = useStore((state) => state.currentLang);
	const { currentUser } = useStore((state) => state.currentUser);
	const location = useLocation();
	const history = useHistory();
	const urlParams = new URLSearchParams(location.search);
	const currentPage = urlParams.get('page') ? urlParams.get('page') : 1;
	const companyName = urlParams.get('companyName') ? urlParams.get('companyName') : null;
	const number = urlParams.get('number') ? urlParams.get('number') : null;
	const department = urlParams.get('department') ? urlParams.get('department') : null;
	const office = urlParams.get('office') ? urlParams.get('office') : null;
	const status = urlParams.get('status') ? urlParams.get('status') : null;
	const auditServiceStatus = urlParams.get('auditServiceStatus') ? urlParams.get('auditServiceStatus') : null;
	const fromDate = urlParams.get('fromDate') ? urlParams.get('fromDate') : null;
	const toDate = urlParams.get('toDate') ? urlParams.get('toDate') : null;
	const read = urlParams.get('read') ? urlParams.get('read') : null;
	const showSpam = urlParams.get('showSpam') ? urlParams.get('showSpam') : null;
	const showNearlyDue = urlParams.get('showNearlyDue') ? urlParams.get('showNearlyDue') : null;
	const type = urlParams.get('type') ? urlParams.get('type') : null;
	const showIncomplete = urlParams.get('showIncomplete') ? urlParams.get('showIncomplete') : null;
	const more = urlParams.get('more') ? urlParams.get('more') : null;
	moment.locale(currentLang === 'kh' ? 'km' : 'en-gb');
	const [loadProposals, { loading, data }] = useLazyQuery(QUERY_PROPOSALS);
	const [loadPublicServices, { data: dataPublicServices }] = useLazyQuery(QUERY_ALL_PUBLIC_SERVICES);
	const [excelData, setExcelData] = useState(null);
	const [openDownloadExcel, setOpenDownloadExcel] = useState(null);
	const [viewingId, setViewingId] = useState(null);
	const [openDetailModal, setOpenDetailModal] = useState(false);
	const [openFilter, setOpenFilter] = useState(false);
	const { isOpen, onOpen, onClose } = useDisclosure();

	useEffect(() => {
		loadProposals({
			variables: {
				companyName,
				number: parseInt(number),
				department,
				office,
				offset: currentPage ? (currentPage - 1) * (more === 'true' ? 300 : 10) : 0,
				fromDate: datePickerFromToToDate(fromDate),
				toDate: datePickerFromToToDate(toDate),
				read: read === 'false' ? false : read === 'true' ? true : null,
				type,
				status: queryStatus(),
				limit: more === 'true' ? 300 : 10,
				showIncomplete: showIncomplete === 'true' ? true : null,
				auditServiceStatus: status === 'done' ? queryAuditServiceStatus() : null,
			},
		});
		loadPublicServices();
	}, []);

	useEffect(() => {
		if (currentPage) {
			loadProposals({
				variables: {
					number: parseInt(number),
					companyName,
					offset: currentPage ? (currentPage - 1) * (more === 'true' ? 300 : 10) : 0,
					department,
					office,
					fromDate: datePickerFromToToDate(fromDate),
					toDate: datePickerFromToToDate(toDate),
					read: read === 'false' ? false : read === 'true' ? true : null,
					publicService: type !== null ? (type === 'all' ? null : type === 'PUBLIC_SERVICE' ? true : false) : null,
					status: queryStatus(),
					spam: showSpam === 'true' ? true : null,
					showNearlyDue: showNearlyDue === 'true' ? true : null,
					limit: more === 'true' ? 300 : 10,
					showIncomplete: showIncomplete === 'true' ? true : null,
					auditServiceStatus: status === 'done' ? queryAuditServiceStatus() : null,
				},
			});
		}
	}, [
		more,
		currentPage,
		fromDate,
		toDate,
		read,
		number,
		companyName,
		department,
		office,
		type,
		status,
		showSpam,
		showNearlyDue,
		showIncomplete,
		auditServiceStatus,
	]);

	function handleTabChanged(value) {
		let url = `?page=1`;

		if (value) {
			if (value === approved) {
				url += `&status=done&auditServiceStatus=${approved}`;
			} else if (value === rejected) {
				url += `&status=done&auditServiceStatus=${rejected}`;
			} else {
				url += `&status=${value}`;
			}
		}

		if (number) {
			url += `&number=${number}`;
		}

		if (companyName) {
			url += `&companyName=${companyName}`;
		}

		if (department) {
			url += `&department=${department}`;
		}

		if (office) {
			url += `&office=${office}`;
		}

		if (fromDate) {
			url += `&fromDate=${fromDate}`;
		}

		if (toDate) {
			url += `&toDate=${toDate}`;
		}

		if (type) {
			url += `&type=${type}`;
		}

		if (read !== null) {
			const unreadOnly = read ? read : '';
			if (unreadOnly) {
				url += `&read=false`;
			}
		}

		if (showSpam) {
			url += `&showSpam=${showSpam}`;
		}

		if (showNearlyDue) {
			url += `&showNearlyDue=${showNearlyDue}`;
		}

		if (showIncomplete) {
			url += `&showIncomplete=${showIncomplete}`;
		}

		if (more !== null) {
			url += `&more=${more}`;
		}

		history.push(`/audit-company${url}`);
	}

	function handlePageChanged({ selected }) {
		let url = `?page=${selected + 1}`;

		if (number) {
			url += `&number=${number}`;
		}

		if (companyName) {
			url += `&companyName=${companyName}`;
		}

		if (department) {
			url += `&department=${department}`;
		}

		if (office) {
			url += `&office=${office}`;
		}

		if (status) {
			url += `&status=${status}`;
		}

		if (auditServiceStatus) {
			url += `&auditServiceStatus=${auditServiceStatus}`;
		}

		if (fromDate) {
			url += `&fromDate=${fromDate}`;
		}

		if (toDate) {
			url += `&toDate=${toDate}`;
		}

		if (type) {
			url += `&type=${type}`;
		}

		if (read !== null) {
			const unreadOnly = read ? read : '';
			if (unreadOnly) {
				url += `&read=false`;
			}
		}

		if (showSpam) {
			url += `&showSpam=${showSpam}`;
		}

		if (showNearlyDue) {
			url += `&showNearlyDue=${showNearlyDue}`;
		}

		if (showIncomplete) {
			url += `&showIncomplete=${showIncomplete}`;
		}

		if (more !== null) {
			url += `&more=${more}`;
		}

		history.push(`/audit-company${url}`);
	}

	function handleMoreChanged() {
		let url = `?page=1`;

		if (number) {
			url += `&number=${number}`;
		}

		if (companyName) {
			url += `&companyName=${companyName}`;
		}

		if (department) {
			url += `&department=${department}`;
		}

		if (office) {
			url += `&office=${office}`;
		}

		if (status) {
			url += `&status=${status}`;
		}

		if (auditServiceStatus) {
			url += `&auditServiceStatus=${auditServiceStatus}`;
		}

		if (fromDate) {
			url += `&fromDate=${fromDate}`;
		}

		if (toDate) {
			url += `&toDate=${toDate}`;
		}

		if (type) {
			url += `&type=${type}`;
		}

		if (read !== null) {
			const unreadOnly = read ? read : '';
			if (unreadOnly) {
				url += `&read=false`;
			}
		}

		if (showSpam) {
			url += `&showSpam=${showSpam}`;
		}

		if (showNearlyDue) {
			url += `&showNearlyDue=${showNearlyDue}`;
		}

		if (showIncomplete) {
			url += `&showIncomplete=${showIncomplete}`;
		}

		url += `&more=${more === 'true' ? 'false' : 'true'}`;

		history.push(`/audit-company${url}`);
	}

	function queryStatus() {
		if (null !== status) {
			if ('all' === status) {
				return null;
			} else {
				if (status === 'done' || tabs.map((e) => e.value).includes(status)) {
					return status;
				} else {
					return 'in progress';
				}
			}
		} else {
			return 'in progress';
		}
	}

	function queryAuditServiceStatus() {
		if (auditServiceStatus) {
			return auditServiceStatus?.toUpperCase();
		} else {
			return approved.toUpperCase();
		}
	}

	// function queryMore() {
	// 	if (null !== more) {
	// 		if (more === 'true') {
	// 			return true;
	// 		}
	// 	}
	// 	return false;
	// }

	function checkRead(item) {
		let read = false;
		if (currentUser?.role === 'admin') {
			read = item?.read;
		} else {
			item?.assignTo?.forEach((aTo) => {
				if (aTo?.user?.id === currentUser?.id) {
					read = aTo?.read;
				}
			});
		}
		return read;
	}

	function getStatus({ auditServiceStatus }) {
		if (auditServiceStatus === 'APPROVED') {
			return (
				<Tooltip label={t('approved')}>
					<Badge colorScheme="green">{t('approved')}</Badge>
				</Tooltip>
			);
		} else if (auditServiceStatus === 'REJECTED') {
			return (
				<Tooltip label={t('Rejected')}>
					<Badge colorScheme="red">{t('Rejected')}</Badge>
				</Tooltip>
			);
		} else {
			return (
				<Tooltip label={t('In progress')}>
					<Badge colorScheme="yellow">{t('In progress')}</Badge>
				</Tooltip>
			);
		}
	}

	function getDisplayCompanyName(item) {
		let displayName = item?.companyName;
		if (item?.company?.id) {
			if (currentLang === 'kh') {
				displayName = item?.company?.nameKhmer;
			} else {
				displayName = item?.company?.name;
			}
		}
		return displayName;
	}

	function panelTab({ label, value }) {
		let localAuditServiceStatus = queryAuditServiceStatus();
		let localStatus = status === 'done' ? localAuditServiceStatus.toLowerCase() : status;
		let active = tabs.map((e) => e.value).includes(localStatus) ? localStatus : 'in progress';
		return (
			<Flex
				_hover={{ boxShadow: `inset 0 -2px 0 0 ${value === active ? '#2980b9' : '#bdc3c7'}` }}
				className="panel-tab"
				userSelect="none"
				minW="100px"
				onClick={() => handleTabChanged(value)}
				cursor="pointer"
				mb={'-2px'}
				boxShadow={value === active ? 'inset 0 -2px 0 0 #2980b9' : ''}
				pt="16px"
				pb="16px"
				pl="12px"
				pr="12px"
				alignItems="center"
				justifyContent="center"
			>
				<Text fontWeight={value === active ? '700' : '500'}>{t(label)}</Text>
			</Flex>
		);
	}

	function generateExcelData() {
		const tmp = [];
		data?.getCompanySubmissionProposals?.companySubmissionProposals?.forEach((item, index) => {
			tmp.push({
				number: item?.no,
				companyName: getDisplayCompanyName(item),
				subject: item.subject,
				createdAt: moment(item.createdAt).format(c.excelDateTimeFormat),
				dueDate: item?.dueDate ? moment(item?.dueDate).format(c.excelDateOnlyFormat) : 'N/A',
				type: item?.publicService ? 'Public Service' : 'Other',
				publicServiceName: item?.publicService ? getPublicServiceByValue(item?.publicService) : null,
				status: item?.done === false ? 'In progress' : 'Done',
			});
		});
		setExcelData(tmp);
		setTimeout(() => {
			setOpenDownloadExcel(true);
		}, 100);
	}

	function getPublicServiceByValue(value) {
		let res = '';
		if (value) {
			let data = dataPublicServices?.getAllPublicServices?.filter((pb) => pb.value === value);
			res = data[0]?.title;
		}
		return res;
	}

	return (
		<PrintLayout>
			<>
				<Container maxW="container.xl" mt="16px" mb="16px">
					<BreadcrumbComponent
						list={[
							{
								name: 'AUDIT COMPANY',
								path: '#',
							},
						]}
					/>
					<Center className="show-only-printing">
						<Text textAlign="center" color="#62B3ED" fontFamily="Moul" fontSize={18}>
							ការស្នើសុំ
						</Text>
					</Center>
					<Center mt="5">
						<Box
							w="100%"
							bg="white"
							borderRadius={c.borderRadius}
							border="1px solid #dbdbdb"
							boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
							pb="32px"
						>
							<Flex
								className="hidden-print responsive-header-for-table"
								bg="#FAFAFA"
								p="16px"
								pt="0"
								pb="0"
								mb="16px"
								alignItems="center"
								justifyContent="space-between"
								borderBottom="1px solid #dbdbdb"
							>
								<Flex className="panel-tab-container" minW="303px">
									{tabs.map((e) => panelTab({ label: e.label, value: e.value }))}
								</Flex>
								<Box className="mobile-header-menu" mt="8px" w="100%" display="none">
									<Accordion w="100%" allowMultiple>
										<AccordionItem>
											<h2>
												<AccordionButton>
													<Box as="span" flex="1" textAlign="left" fontWeight="bold">
														{t('Menu')}
													</Box>
													<AccordionIcon />
												</AccordionButton>
											</h2>
											<AccordionPanel pb={4}>
												<Box>
													<Flex justify="space-between" mb="6" mt="2">
														{(currentUser?.username === 'admin' || currentUser?.canAutoreassignProposal) && (
															<>
																<Button
																	mr="2"
																	isDisabled={data?.getCompanySubmissionProposals?.companySubmissionProposals?.length === 0}
																	leftIcon={<FontAwesomeIcon icon={faRetweet} style={{ fontSize: 16 }} />}
																	colorScheme="gray"
																	variant="solid"
																	size="sm"
																	borderRadius={c.borderRadius}
																	border="1px solid #bdc3c7"
																	onClick={onOpen}
																>
																	{t('Reassign')}
																</Button>
															</>
														)}
														<Button
															mr="2"
															onClick={() => generateExcelData()}
															leftIcon={<FontAwesomeIcon icon={faFileExcel} style={{ fontSize: 16 }} />}
															colorScheme="gray"
															variant="solid"
															size="sm"
															borderRadius={c.borderRadius}
															border="1px solid #bdc3c7"
														>
															{t('Export')}
														</Button>
														<Button
															mr="2"
															onClick={() => window?.print()}
															leftIcon={<FontAwesomeIcon icon={faPrint} style={{ fontSize: 16 }} />}
															colorScheme="gray"
															variant="solid"
															size="sm"
															borderRadius={c.borderRadius}
															border="1px solid #bdc3c7"
														>
															{t('Print')}
														</Button>
													</Flex>
													<Button
														onClick={() => setOpenFilter(true)}
														leftIcon={<FontAwesomeIcon icon={faSlidersH} style={{ fontSize: 16 }} />}
														colorScheme={`${
															number ||
															companyName ||
															department ||
															office ||
															fromDate ||
															toDate ||
															read ||
															type ||
															showSpam ||
															showNearlyDue ||
															showIncomplete
																? 'yellow'
																: 'gray'
														}`}
														variant="solid"
														size="sm"
														borderRadius={c.borderRadius}
														border="1px solid #bdc3c7}"
													>
														{t('Filter')}
													</Button>
												</Box>
											</AccordionPanel>
										</AccordionItem>
									</Accordion>
								</Box>
								<Flex className="header-menu" alignItems="center" justifyContent="flex-end" minW="361px">
									{(currentUser?.username === 'admin' || currentUser?.canAutoreassignProposal) && (
										<>
											<Button
												mr="2"
												isDisabled={data?.getCompanySubmissionProposals?.companySubmissionProposals?.length === 0}
												leftIcon={<FontAwesomeIcon icon={faRetweet} style={{ fontSize: 16 }} />}
												colorScheme="gray"
												variant="solid"
												size="sm"
												borderRadius={c.borderRadius}
												border="1px solid #bdc3c7"
												onClick={onOpen}
											>
												{t('Reassign')}
											</Button>
										</>
									)}
									<Button
										mr="2"
										onClick={() => generateExcelData()}
										leftIcon={<FontAwesomeIcon icon={faFileExcel} style={{ fontSize: 16 }} />}
										colorScheme="gray"
										variant="solid"
										size="sm"
										borderRadius={c.borderRadius}
										border="1px solid #bdc3c7"
									>
										{t('Export')}
									</Button>
									<Button
										mr="2"
										onClick={() => window?.print()}
										leftIcon={<FontAwesomeIcon icon={faPrint} style={{ fontSize: 16 }} />}
										colorScheme="gray"
										variant="solid"
										size="sm"
										borderRadius={c.borderRadius}
										border="1px solid #bdc3c7"
									>
										{t('Print')}
									</Button>
									<Button
										onClick={() => setOpenFilter(true)}
										leftIcon={<FontAwesomeIcon icon={faSlidersH} style={{ fontSize: 16 }} />}
										colorScheme={`${
											number ||
											companyName ||
											department ||
											office ||
											fromDate ||
											toDate ||
											read ||
											type ||
											showSpam ||
											showNearlyDue ||
											showIncomplete
												? 'yellow'
												: 'gray'
										}`}
										variant="solid"
										size="sm"
										borderRadius={c.borderRadius}
										border="1px solid #bdc3c7}"
									>
										{t('Filter')}
									</Button>
								</Flex>
							</Flex>
							<Box p="16px" pt="0">
								<Table variant="simple" className="table-company-proposal-list responsive-table-container">
									<Thead>
										<Tr className="td-as-th">
											<Th align="left">{t('Number')}</Th>
											<Th align="left">{t('Company/Institution Name')}</Th>
											<Th align="left">{t('Subject')}</Th>
											<Th align="left">{t('Proposal Created At')}</Th>
											<Th align="left">{t('Due Date')}</Th>
											<Th align="right">{t('Status')}</Th>
										</Tr>
									</Thead>
									<Tbody>
										{loading && <LoadingResponsiveTable column={6} />}
										{data?.getCompanySubmissionProposals?.companySubmissionProposals.length <= 0 && (
											<Tr>
												<center>
													<Text>{t('No Data')}</Text>
												</center>
											</Tr>
										)}
										{data?.getCompanySubmissionProposals?.companySubmissionProposals?.map((item, index) => {
											return (
												<Tr
													className={checkRead(item) ? 'read' : 'new'}
													cursor="pointer"
													key={`item-${index}`}
													_hover={{ bg: 'gray.200' }}
												>
													<Td
														className="zoom-td"
														onClick={() => {
															setViewingId(item?.id);
															setOpenDetailModal(true);
														}}
													>
														{item?.no}
													</Td>
													<Td onClick={() => history.push(`/audit-company/detail?id=${item.id}`)}>
														<Text fontWeight="500" maxW="400px" noOfLines={4}>
															{getDisplayCompanyName(item)}
														</Text>
													</Td>
													<Td onClick={() => history.push(`/audit-company/detail?id=${item.id}`)}>
														<Text fontWeight="500" maxW="400px" noOfLines={4}>
															{item.subject}
														</Text>
													</Td>
													<Td onClick={() => history.push(`/audit-company/detail?id=${item.id}`)}>
														{moment(item?.startServiceDate ? item?.startServiceDate : item.createdAt).format('LLLL')}
													</Td>
													<Td onClick={() => history.push(`/audit-company/detail?id=${item.id}`)}>
														{item?.dueDate ? moment(item?.dueDate).format(c.dateTextOnlyFormat) : 'N/A'}
													</Td>
													<Td onClick={() => history.push(`/audit-company/detail?id=${item.id}`)}>
														<Flex justifyContent="flex-end">
															{item?.submissionStatus === '1' && getStatus({ auditServiceStatus: item?.auditServiceStatus })}
															{item?.submissionStatus === '2' && <Badge colorScheme="purple">{t('PENDING PAYMENT')}</Badge>}
															{item?.submissionStatus === '3' && <Badge colorScheme="red">{t('CANCEL')}</Badge>}
															{item?.spam === true && (
																<Badge ml={2} colorScheme="red">
																	{t('Spam')}
																</Badge>
															)}
														</Flex>
													</Td>
												</Tr>
											);
										})}
									</Tbody>
									<tfoot className="responsive-footer">
										<Tr className="td-as-th">
											<Th align="left">{t('Number')}</Th>
											<Th align="left">{t('Company/Institution Name')}</Th>
											<Th align="left">{t('Subject')}</Th>
											<Th align="left">{t('Proposal Created At')}</Th>
											<Th align="left">{t('Due Date')}</Th>
											<Th align="right">{t('Status')}</Th>
										</Tr>
									</tfoot>
								</Table>
								<Flex className="hidden-print" justifyContent="flex-end" mr="24px" pb="4" mt="2" fontWeight="600">
									{t('Total Rows')}: {data?.getCompanySubmissionProposals?.totalRows}
								</Flex>
								<Flex className="hidden-print" justifyContent="flex-end" w="100%" mt="4">
									{data?.getCompanySubmissionProposals?.totalPages > 1 && (
										<ReactPaginate
											previousLabel={<ChevronLeftIcon fontSize="20px" />}
											nextLabel={<ChevronRightIcon fontSize="20px" />}
											breakLabel={'...'}
											breakClassName={'break-me'}
											disableInitialCallback={true}
											pageCount={data?.getCompanySubmissionProposals?.totalPages}
											marginPagesDisplayed={1}
											pageRangeDisplayed={1}
											onPageChange={handlePageChanged}
											containerClassName={'pagination'}
											activeClassName={'active'}
											initialPage={(currentPage - 1) | 0}
										/>
									)}
								</Flex>
								<Box justifyContent="flex-end" className="show-only-printing">
									<Text ml="2" mt="4">
										{t('Total Rows')}: <b>{data?.getCompanySubmissionProposals?.totalRows}</b>
									</Text>
								</Box>
								<Flex className="hidden-print" justifyContent="flex-end" mt="6">
									<Text mr="2">{t('Show more rows')}</Text>
									<Checkbox isChecked={more === 'true'} onChange={handleMoreChanged} />
								</Flex>
							</Box>
						</Box>
					</Center>
				</Container>
				<ListAuditCompanyProposalFilter
					callback={(e) => history.push(`/audit-company${e}`)}
					isOpen={openFilter}
					onClose={() => setOpenFilter(false)}
					fromDate={fromDate}
					toDate={toDate}
					read={read}
					number={number}
					companyName={companyName}
					department={department}
					office={office}
					type={type}
					status={status}
					showSpam={showSpam === 'true' ? true : false}
					showNearlyDue={showNearlyDue === 'true' ? true : false}
					showIncomplete={showIncomplete === 'true' ? true : false}
					auditServiceStatus={queryAuditServiceStatus()}
					more={more}
				/>
				{openDownloadExcel && <ExcelExportModal title="Proposal" isOpen={true} onClose={() => setOpenDownloadExcel(false)} data={excelData} />}
				{openDetailModal && viewingId && <ProposalDetailModal id={viewingId} onCloseModal={() => setOpenDetailModal(false)} auditServiceOnly={true} />}
				{loading && <LoadingOverlayText />}
				{isOpen && (
					<ProposalReassignModal proposals={data?.getCompanySubmissionProposals?.companySubmissionProposals} isOpen={isOpen} onClose={onClose} />
				)}
			</>
		</PrintLayout>
	);
}

export default AuditCompanyProposal;
