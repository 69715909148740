/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Box,
	Button,
	Flex,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text,
	Tooltip,
	useDisclosure,
	useToast,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { LoadingCompanyLicense } from '../../Other';
import QRCodeGenerator from '../../QRCodeGenerator';
import AddCompanyLicenseModal from '../../Modal/AddCompanyLicenseModal';
import EditCompanyLicenseModal from '../../Modal/EditCompanyLicenseModal';
import CompanyLongInformation from './CompanyLongInformation';
import c from '../../../constant';
import useStore from '../../../store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';

const Q_COMPANY_LICENSE_TYPE = gql`
	query get($companyId: String!, $type: InsuranceSeparatedLicenseType!) {
		getCompanyLicenseByType(companyId: $companyId, type: $type) {
			id
			companyId
			type
			number
			displayNumber
			url
			isGeoManual
			geoManual
			houseNumber
			streetNumber
			province
			district
			commune
			village
			provinceObject {
				code
				name
				latinName
				administrativeUnit
				administrativeUnitLatin
			}
			districtObject {
				code
				name
				latinName
				administrativeUnit
				administrativeUnitLatin
			}
			communeObject {
				code
				name
				latinName
				administrativeUnit
				administrativeUnitLatin
			}
			villageObject {
				code
				name
				latinName
				administrativeUnit
				administrativeUnitLatin
			}
			statuteFile
			licenseFile
			licenseValidFrom
			licenseValidTo
			isPermanentLicense
			description
			createdAt
			createdBy
			updatedAt
			updatedBy
			status
		}
	}
`;

const M_DEL_COMPANY_LICENSE = gql`
	mutation delete($id: String!) {
		deleteCompanyLicense(id: $id)
	}
`;

const InsuranceLicense = ({ companyId = null, licenseType = null }) => {
	const toast = useToast();
	const { t } = useTranslation();
	const { currentUser: loginUser } = useStore((state) => state.currentUser);
	const { isOpen: isCreatingCompanyLicense, onOpen: openCreatingCompanyLicense, onClose: closeCreatingCompanyLicense } = useDisclosure();
	const { isOpen: isEditingCompanyLicense, onOpen: openEditingCompanyLicense, onClose: closeEditingCompanyLicense } = useDisclosure();
	const { isOpen: isDeletingCompanyLicense, onOpen: openDeletingCompanyLicense, onClose: closeDeletingCompanyLicense } = useDisclosure();
	const [getCompanyLicense, { loading: loadingGet, data: dataGet }] = useLazyQuery(Q_COMPANY_LICENSE_TYPE);
	const [deleteCompanyLicense, { loading: loadingDelete, data: dataDelete, error: errorDelete }] = useMutation(M_DEL_COMPANY_LICENSE);

	useEffect(() => {
		reloadCompanyLicense();
	}, [companyId, licenseType]);

	useEffect(() => {
		if (errorDelete) {
			if (errorDelete?.graphQLErrors?.length > 0) {
				errorDelete?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(errorDelete?.message ? errorDelete?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [errorDelete]);

	useEffect(() => {
		if (dataDelete) {
			if (dataDelete?.deleteCompanyLicense) {
				toast({
					title: t('Company license deleted successfully'),
					status: 'success',
					duration: 5000,
					isClosable: true,
					position: 'bottom-right',
				});
				closeDeletingCompanyLicense();
				reloadCompanyLicense();
			}
		}
	}, [dataDelete]);

	const reloadCompanyLicense = () => {
		if (companyId && licenseType) {
			const variables = { companyId: companyId, type: licenseType };
			getCompanyLicense({ variables });
		}
	};

	const ActionMenu = () => {
		return (
			<Menu border="1px solid red">
				<Tooltip label={`${t('Edit')} ${t('Delete')}`}>
					<MenuButton cursor="pointer">
						<Flex pl="2" pr="2">
							<FontAwesomeIcon icon={faEllipsisV} style={{ fontSize: 16, marginTop: 4, marginLeft: 6 }} />
						</Flex>
					</MenuButton>
				</Tooltip>
				<MenuList pt="4" pb="4" pl="3" pr="3" borderRadius={c.borderRadius}>
					<MenuItem color="blue.400" onClick={openEditingCompanyLicense}>
						{t('Edit')}
					</MenuItem>
					<MenuItem color="red.400" onClick={openDeletingCompanyLicense}>
						{t('Delete')}
					</MenuItem>
				</MenuList>
			</Menu>
		);
	};

	const Information = () => {
		if (dataGet?.getCompanyLicenseByType) {
			return (
				<Flex pt={3} pb={3}>
					<Box width="100%">
						<CompanyLongInformation
							type={dataGet?.getCompanyLicenseByType?.type}
							url={dataGet?.getCompanyLicenseByType?.url}
							number={dataGet?.getCompanyLicenseByType?.number}
							displayNumber={dataGet?.getCompanyLicenseByType?.displayNumber}
							isGeoManual={dataGet?.getCompanyLicenseByType?.isGeoManual}
							geoManual={dataGet?.getCompanyLicenseByType?.geoManual}
							houseNumber={dataGet?.getCompanyLicenseByType?.houseNumber}
							streetNumber={dataGet?.getCompanyLicenseByType?.streetNumber}
							villageObject={dataGet?.getCompanyLicenseByType?.villageObject}
							communeObject={dataGet?.getCompanyLicenseByType?.communeObject}
							districtObject={dataGet?.getCompanyLicenseByType?.districtObject}
							provinceObject={dataGet?.getCompanyLicenseByType?.provinceObject}
							licenseFileSigned={dataGet?.getCompanyLicenseByType?.licenseFile?.fileSigned}
							licenseSize={dataGet?.getCompanyLicenseByType?.licenseFile?.size}
							isPermanentLicense={dataGet?.getCompanyLicenseByType?.isPermanentLicense}
							statuteFileSigned={dataGet?.getCompanyLicenseByType?.statuteFile?.fileSigned}
							statuteSize={dataGet?.getCompanyLicenseByType?.statuteFile?.size}
							description={dataGet?.getCompanyLicenseByType?.description}
							validFrom={dataGet?.getCompanyLicenseByType?.licenseValidFrom}
							validTo={dataGet?.getCompanyLicenseByType?.licenseValidTo}
							status={dataGet?.getCompanyLicenseByType?.status}
						/>
					</Box>
					<Box>
						<a
							href={
								c.env === 'development'
									? 'http://localhost:3009/company?id=' + companyId + '&type=' + licenseType
									: 'http://irc.gov.kh/company?id=' + companyId + '&type=' + licenseType
							}
							target="_blank"
							rel="noopener noreferrer"
						>
							<QRCodeGenerator
								size={120}
								text={
									c.env === 'development'
										? 'http://localhost:3009/company?id=' + companyId + '&type=' + licenseType
										: 'http://irc.gov.kh/company?id=' + companyId + '&type=' + licenseType
								}
								mainColor="black"
								cornerColorInnerColor="black"
							/>
						</a>
					</Box>
					{(loginUser?.role === 'admin' || loginUser?.canUpdateCompanyLicensing) && (
						<Box ml={4}>
							<ActionMenu />
						</Box>
					)}
				</Flex>
			);
		} else {
			return (
				<Flex>
					<Flex className="hidden-print" width="100%" direction="column" alignContent="center" alignItems="center" m={12}>
						<Text mb={2} fontSize="md">
							{t('No license has been added')}
						</Text>
						{(loginUser?.role === 'admin' || loginUser?.canUpdateCompanyLicensing) && (
							<Tooltip label={t('Add License')}>
								<Flex
									userSelect="none"
									onClick={openCreatingCompanyLicense}
									cursor="pointer"
									alignItems="center"
									justifyContent="center"
									w="120px"
									h="32px"
									borderRadius={c.borderRadius}
									border="1px solid #dbdbdb"
									boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
								>
									<Text>{t('Add License')}</Text>
								</Flex>
							</Tooltip>
						)}
					</Flex>
					<Flex className="show-only-printing">
						<Text mb={2} fontSize="md">
							{t('No license has been added')}
						</Text>
					</Flex>
				</Flex>
			);
		}
	};

	return (
		<>
			{loadingGet ? <LoadingCompanyLicense /> : <Information />}
			{isCreatingCompanyLicense && (
				<AddCompanyLicenseModal
					id={companyId}
					type={licenseType}
					isOpen={true}
					onClose={closeCreatingCompanyLicense}
					onCallback={reloadCompanyLicense}
				/>
			)}
			{isEditingCompanyLicense && (
				<EditCompanyLicenseModal
					licenseData={dataGet?.getCompanyLicenseByType}
					isOpen={true}
					onClose={closeEditingCompanyLicense}
					onCallback={reloadCompanyLicense}
				/>
			)}
			<AlertDialog closeOnEsc={false} closeOnOverlayClick={false} isOpen={isDeletingCompanyLicense} onClose={closeDeletingCompanyLicense}>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							{t('Delete')}
						</AlertDialogHeader>
						<AlertDialogBody>{t('Are you sure to delete this license?')}</AlertDialogBody>
						<AlertDialogFooter>
							<Button mr={4} isLoading={loadingDelete} onClick={closeDeletingCompanyLicense}>
								{t('Cancel')}
							</Button>
							<Button
								isLoading={loadingDelete}
								colorScheme="red"
								onClick={() => {
									deleteCompanyLicense({ variables: { id: dataGet?.getCompanyLicenseByType?.id } });
								}}
							>
								{t('Delete')}
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</>
	);
};

export default InsuranceLicense;
