/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faFileAlt, faFileInvoice, faPrint } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import {
	Box,
	Button,
	Center,
	Container,
	Flex,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	SimpleGrid,
	Text,
	useDisclosure,
	TableContainer as ChakraTableContainer,
	Table as ChakraTable,
	Thead as ChakraThead,
	Tbody as ChakraTbody,
	Tr as ChakraTr,
	Th as ChakraTh,
	Td as ChakraTd,
	Tooltip,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useLocation, Link } from 'react-router-dom';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { PartnerRequestStatus as Status, GradeApproval } from './constants';
import { usePartnerRequest } from '../../../contexts/partner-request';
import LoadingOverlayText from '../../../components/LoadingOverlay/LoadingOverlayText';
import PrintLayout from '../../../components/Print/PrintLayout';
import BreadcrumbComponent from '../../../components/BreadcrumbComponent/BreadcrumbComponent';
import PartnerRequestStatus from '../../../components/ManageAgentPartner/PartnerRequest/Status/PartnerRequestStatus';
import PartnerRequestCertificateStatus from '../../../components/ManageAgentPartner/PartnerRequest/Status/PartnerRequestCertificateStatus';
import PartnerRequestContractFile from '../../../components/ManageAgentPartner/PartnerRequest/Status/PartnerRequestContractFile';
import PartnerRequestCertificatesApproval from '../../../components/ManageAgentPartner/PartnerRequest/Approval/PartnerRequestCertificatesApproval';
import PartnerRequestContractsApproval from '../../../components/ManageAgentPartner/PartnerRequest/Approval/PartnerRequestContractsApproval';
import PartnerRequestCertificateStatusDescription from '../../../components/ManageAgentPartner/PartnerRequest/Status/PartnerRequestCertificateStatusDescription';
import PartnerRequestContractStatus from '../../../components/ManageAgentPartner/PartnerRequest/Status/PartnerRequestContractStatus';
import PartnerRequestContractStatusDescription from '../../../components/ManageAgentPartner/PartnerRequest/Status/PartnerRequestContractStatusDescription';
import DownloadAndViewButton from '../../../components/Files/DownloadAndViewButton';
import EventLoggingButton from '../../../components/ManageAgentPartner/Modal/EventLoggingButton';
import CardName from '../../../components/CompanyProposal/AdditionalFeature/CardName';
import CardDate from '../../../components/CompanyProposal/AdditionalFeature/CardDate';
import CardLabelValue from '../../../components/CompanyProposal/AdditionalFeature/CardLabelValue';
import useStore from '../../../store';
import formatMoneyKH from '../../../utils/formatMoneyKH';
import c from '../../../constant';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import './style.css';

function ManageAgentPartner() {
	const history = useHistory();
	const location = useLocation();
	const urlParams = new URLSearchParams(location.search);
	const id = urlParams.get('id') ? urlParams.get('id') : null;
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { currentLang: lang } = useStore((state) => state.currentLang);
	const { currentUser: user } = useStore((state) => state.currentUser);
	const { t } = useTranslation();
	const { loadRequest, loadingRequest, loadedData } = usePartnerRequest();

	useEffect(() => {
		loadRequest(id);
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		loadRequest(id);
	}, [id]);

	function Number({ number = null }) {
		return (
			<Text fontSize="sm" fontWeight="normal">
				{number}
			</Text>
		);
	}

	function CompanyNumber({ number = null }) {
		return (
			<Text fontSize="sm" fontWeight="semibold">
				{number}
			</Text>
		);
	}

	function Company({ company = null }) {
		return (
			<Text fontSize="sm" fontWeight="normal">
				{lang === 'kh' ? company?.nameKhmer : company?.name}
			</Text>
		);
	}

	return (
		<>
			<PrintLayout>
				<Container maxW="container.xl" mt="16px" mb="16px">
					<BreadcrumbComponent
						list={[
							{
								name: 'MANAGE AGENT PARTNER',
								path: '/manage-agent-partner',
							},
							{
								name: 'AGENT PARTNER REQUEST',
								path: '/manage-agent-partner/partner-request',
							},
							{
								name: 'DETAIL',
								path: '#',
							},
						]}
					/>
					<Center mt="5">
						<Box
							w="100%"
							bg="white"
							borderRadius={c.borderRadius}
							border="1px solid #dbdbdb"
							boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
							pb="32px"
						>
							<Flex
								className="hidden-print responsive-header-for-table"
								bg="#FAFAFA"
								p="16px"
								pt="8px"
								pb="8px"
								mb="16px"
								alignItems="center"
								justifyContent="space-between"
								borderBottom="1px solid #dbdbdb"
							>
								<Text fontSize="x-large" fontWeight="bold">
									{t('Detail')}
								</Text>
								<Flex>
									<>
										<Button
											onClick={() => window?.print()}
											leftIcon={<FontAwesomeIcon icon={faPrint} style={{ fontSize: 16 }} />}
											colorScheme="gray"
											variant="solid"
											size="sm"
											borderRadius={c.borderRadius}
											border="1px solid #bdc3c7"
											ml={2}
										>
											{t('Print')}
										</Button>
									</>
									<Button
										onClick={onOpen}
										leftIcon={<FontAwesomeIcon icon={faFileInvoice} style={{ fontSize: 16 }} />}
										colorScheme="gray"
										variant="solid"
										size="sm"
										borderRadius={c.borderRadius}
										border="1px solid #bdc3c7"
										ml={2}
									>
										{t('Payment')}
									</Button>
									<EventLoggingButton label="Logs" typeObjectId={id} />
									{loadedData?.status === Status.reviewAgent && (
										<>
											{c.stepOfficeRoles?.includes(user?.position) && (
												<>
													<PartnerRequestCertificatesApproval
														id={id}
														grade={GradeApproval.primary}
														candidates={loadedData?.candidates}
													/>
												</>
											)}
										</>
									)}
									{loadedData?.status === Status.reviewAgentFinal && (
										<>
											{c.stepDepartmentRoles?.includes(user?.position) && (
												<>
													<PartnerRequestCertificatesApproval
														id={id}
														grade={GradeApproval.secondary}
														candidates={loadedData?.candidates}
													/>
												</>
											)}
										</>
									)}
									{loadedData?.status === Status.reviewContract && (
										<>
											{c.stepOfficeRoles?.includes(user?.position) && (
												<>
													<PartnerRequestContractsApproval
														id={id}
														grade={GradeApproval.primary}
														candidates={loadedData?.candidates}
													/>
												</>
											)}
										</>
									)}
								</Flex>
							</Flex>
							{!loadingRequest && loadedData?.id && (
								<Box p="16px" pt="0">
									<Flex w="100%" bg="white" border="1px dotted #dbdbdb" borderRadius={c.borderRadius} pl={4} pr={4} pt={4} pb={4} mb={2}>
										<SimpleGrid w="100%" columns={[1, null, 2]} spacing={4}>
											<Flex direction="column">
												<CardLabelValue width="260px" label={'Number'} value={<CompanyNumber number={loadedData?.number} />} />
												<CardLabelValue width="260px" label={'Company'} value={<Company company={loadedData?.company} />} />
												<CardLabelValue
													width="260px"
													label={'Received Request Date'}
													value={<CardDate input={loadedData?.startedAt} />}
												/>
												<CardLabelValue
													width="260px"
													label={'Requested Agents'}
													value={<Number number={loadedData?.numberOfRequestedAgent} />}
												/>
												<CardLabelValue
													width="260px"
													label={'Approved Agents'}
													value={<Number number={loadedData?.numberOfApprovedAgent} />}
												/>
												<CardLabelValue
													width="260px"
													label={'Submitted Contracts'}
													value={<Number number={loadedData?.numberOfSubmittedContract} />}
												/>
												<CardLabelValue
													width="260px"
													label={'Approved Contracts'}
													value={<Number number={loadedData?.numberOfApprovedContract} />}
												/>
												<CardLabelValue width="260px" label={'Request Done At'} value={<CardDate input={loadedData?.completedAt} />} />
												<>
													{loadedData?.status === Status.expired && (
														<>
															<CardLabelValue
																width="260px"
																label={'Expired At'}
																value={<CardDate input={loadedData?.expiredAt} />}
															/>
														</>
													)}
												</>
												<>
													{loadedData?.status === Status.completed && loadedData?.permit && (
														<>
															<CardLabelValue
																width="260px"
																label={'Permit'}
																value={<DownloadAndViewButton marginLeft={0} minW={20} url={loadedData?.permit?.url} />}
															/>
														</>
													)}
												</>
												<CardLabelValue width="260px" label={'Status'} value={<PartnerRequestStatus status={loadedData?.status} />} />
											</Flex>
											<Flex direction="column">
												<CardLabelValue width="260px" label={'Last Update'} value={<CardDate input={loadedData?.updatedAt} />} />
												<CardLabelValue
													width="260px"
													label={'Agent Approved By Office At'}
													value={<CardDate input={loadedData?.certificatesPrimaryApprovedAt} />}
												/>
												<CardLabelValue
													width="260px"
													label={'Agent Approved At Office By'}
													value={<CardName id={loadedData?.certificatesPrimaryApprovedBy} />}
												/>
												<CardLabelValue
													width="260px"
													label={'Agent Approved By Department At'}
													value={<CardDate input={loadedData?.certificatesSecondaryApprovedAt} />}
												/>
												<CardLabelValue
													width="260px"
													label={'Agent Approved At Department By'}
													value={<CardName id={loadedData?.certificatesSecondaryApprovedBy} />}
												/>
												<CardLabelValue
													width="260px"
													label={'Contract Submitted At'}
													value={<CardDate input={loadedData?.contractsSubmittedAt} />}
												/>
												<CardLabelValue
													width="260px"
													label={'Contract Approved At'}
													value={<CardDate input={loadedData?.contractsPrimaryApprovedAt} />}
												/>
												<CardLabelValue
													width="260px"
													label={'Contract Approved By'}
													value={<CardName id={loadedData?.contractsPrimaryApprovedBy} />}
												/>
											</Flex>
										</SimpleGrid>
									</Flex>
									<Flex w="100%" bg="white">
										<Flex
											flex={1}
											direction="column"
											border="1px dotted #dbdbdb"
											borderRadius={c.borderRadius}
											pl={4}
											pr={4}
											pt={4}
											pb={4}
											mt={2}
											overflowX="scroll"
										>
											<Table variant="simple" className="table-company-proposal-list responsive-table-container">
												<Thead>
													<Tr className="td-as-th">
														<Th align="left">{t('Code')}</Th>
														<Th align="left">{t('Name')}</Th>
														<Th align="left">{t('Company Branch')}</Th>
														{/* HIDE SALE LOCATION <Th align="left">{t('Sale Location')}</Th> */}
														<Th align="left">{t('Specialized Certificate')}</Th>
														<Th align="left">{t('Partner Status')}</Th>
														<Th align="left">{t('Partner Status Description')}</Th>
														<Th align="left">{t('Contract')}</Th>
														<Th align="left">{t('Contract Status')}</Th>
														<Th align="right">{t('Contract Status Description')}</Th>
														<Th className="hidden-print" align="right">
															{t('Logs')}
														</Th>
													</Tr>
												</Thead>
												<Tbody>
													{loadedData?.candidates?.map((e, i) => (
														<Tr _hover={{ bg: 'gray.200' }} cursor="pointer" key={`item-${i}`}>
															<Td>
																<Tooltip label={t('Click to view agent detail')}>
																	<Link to={`/agent/detail?id=${e?.agent?.id}`}>
																		<Text
																			fontSize="sm"
																			fontWeight="semibold"
																			color="blue.700"
																			_hover={{ color: 'blue.400', textDecoration: 'underline' }}
																		>
																			{e?.agent?.code}
																		</Text>
																	</Link>
																</Tooltip>
															</Td>
															<Td>
																<Text fontSize="sm" fontWeight="medium">
																	<>
																		{e?.agent?.nationality !== 'KH' ? (
																			<>{e?.agent?.latinName}</>
																		) : (
																			<>{lang === 'kh' ? e?.agent?.name : e?.agent?.latinName}</>
																		)}
																	</>
																</Text>
															</Td>
															<Td>
																<>
																	{e?.branch ? (
																		<>
																			<Text fontSize="sm" fontWeight="md" color="blue.700">
																				{lang === 'kh'
																					? e?.branch?.name || e?.branch?.nameLatin || 'N/A'
																					: e?.branch?.nameLatin || e?.branch?.name || 'N/A'}
																			</Text>
																		</>
																	) : (
																		<Text fontSize="sm" fontWeight="normal">
																			{'N/A'}
																		</Text>
																	)}
																</>
															</Td>
															{/* HIDE SALE LOCATION <Td>
																<>
																	{e?.geoLocation ? (
																		<>
																			<Text fontSize="sm" fontWeight="md" color="blue.700">
																				{lang === 'kh' ? e?.geoLocation?.name : e?.geoLocation?.latinName}
																			</Text>
																		</>
																	) : (
																		<Text fontSize="sm" fontWeight="normal">
																			{'N/A'}
																		</Text>
																	)}
																</>
															</Td> */}
															<Td>
																{e?.certificate?.url && (
																	<>
																		<DownloadAndViewButton marginLeft={0} minW={20} url={e?.certificate?.url} />
																	</>
																)}
															</Td>
															<Td>
																<PartnerRequestCertificateStatus
																	id={e?.id}
																	requestStatus={loadedData?.status}
																	certificateStatus={e?.certificateStatus}
																/>
															</Td>
															<Td>
																<PartnerRequestCertificateStatusDescription
																	requestStatus={loadedData?.status}
																	certificateStatus={e?.certificateStatus}
																	certificateStatusDescription={e?.certificateStatusDescription}
																/>
															</Td>
															<Td>
																<PartnerRequestContractFile
																	requestStatus={loadedData?.status}
																	contract={e?.contract}
																	cancelledContract={e?.cancelledContract}
																/>
															</Td>
															<Td>
																<PartnerRequestContractStatus
																	id={e?.id}
																	requestStatus={loadedData?.status}
																	certificateStatus={e?.certificateStatus}
																	contract={e?.contract}
																	contractStatus={e?.contractStatus}
																	cancelledContract={e?.cancelledContract}
																/>
															</Td>
															<Td align="right">
																<PartnerRequestContractStatusDescription
																	requestStatus={loadedData?.status}
																	certificateStatus={e?.certificateStatus}
																	contractStatus={e?.contractStatus}
																	contractStatusDescription={e?.contractStatusDescription}
																	cancelledContract={e?.cancelledContract}
																/>
															</Td>
															<Td className="hidden-print" align="right">
																<EventLoggingButton typeObjectId={id} actionObjectId={e?.id} />
															</Td>
														</Tr>
													))}
												</Tbody>
												<tfoot className="responsive-footer">
													<Tr className="td-as-th">
														<Th align="left">{t('Code')}</Th>
														<Th align="left">{t('Name')}</Th>
														<Th align="left">{t('Company Branch')}</Th>
														{/* HIDE SALE LOCATION <Th align="left">{t('Sale Location')}</Th> */}
														<Th align="left">{t('Specialized Certificate')}</Th>
														<Th align="left">{t('Partner Status')}</Th>
														<Th align="left">{t('Partner Status Description')}</Th>
														<Th align="left">{t('Contract')}</Th>
														<Th align="left">{t('Contract Status')}</Th>
														<Th align="right">{t('Contract Status Description')}</Th>
														<Th className="hidden-print" align="right">
															{t('Logs')}
														</Th>
													</Tr>
												</tfoot>
											</Table>
										</Flex>
									</Flex>
								</Box>
							)}
							{!loadingRequest && !loadedData?.id && (
								<Flex direction="column" justifyContent="center" alignItems="center" p={24}>
									<FontAwesomeIcon icon={faFileAlt} style={{ fontSize: 56, color: '#A0AEC0' }} />
									<Text fontSize="lg" fontWeight="bold" color="gray.600" mt={6}>
										{t('No request for partner with given ID has been found!')}
									</Text>
									<Button
										leftIcon={<FontAwesomeIcon icon={faArrowLeft} style={{ fontSize: 16 }} />}
										colorScheme="gray"
										variant="solid"
										size="sm"
										borderRadius={c.borderRadius}
										border="1px solid #bdc3c7"
										onClick={() => history.push(`/manage-agent-partner/partner-request`)}
										mt={4}
									>
										<Text fontSize="md" fontWeight="semibold">
											{t('Back To List')}
										</Text>
									</Button>
								</Flex>
							)}
							{loadingRequest && <Flex direction="column" justifyContent="center" alignItems="center" p={32} />}
						</Box>
					</Center>
				</Container>
			</PrintLayout>
			<Modal size="lg" isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent borderRadius={c.borderRadius}>
					<ModalHeader>{t('Payment')}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<ChakraTableContainer>
							<ChakraTable size="sm">
								<ChakraThead>
									<ChakraTr>
										<ChakraTh>{t('Label')}</ChakraTh>
										<ChakraTh isNumeric>{t('Amount')}(KHR)</ChakraTh>
										<ChakraTh isNumeric>Trx.ID</ChakraTh>
									</ChakraTr>
								</ChakraThead>
								<ChakraTbody>
									{loadedData?.paymentData?.map((item, index) => {
										return (
											<ChakraTr>
												<ChakraTd>{item?.label}</ChakraTd>
												<ChakraTd isNumeric>{formatMoneyKH(parseFloat(item?.amount))}</ChakraTd>
												<ChakraTd
													isNumeric
													cursor={user.role === 'admin' ? 'pointer' : 'default'}
													_hover={{ textDecoration: user.role === 'admin' ? 'underline' : 'none' }}
													onClick={() => {
														if (user.role === 'admin') {
															history.push(`/payment?page=1&trxId=${item?.tranId}`);
														}
													}}
												>
													{item?.tranId}
												</ChakraTd>
											</ChakraTr>
										);
									})}
								</ChakraTbody>
							</ChakraTable>
						</ChakraTableContainer>
					</ModalBody>
				</ModalContent>
			</Modal>
			{loadingRequest && <LoadingOverlayText />}
		</>
	);
}

export default ManageAgentPartner;
