import React, { useEffect, useState } from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Box,
    Button,
    useToast
} from "@chakra-ui/react";
import c from "../../constant"
import { gql, useLazyQuery } from '@apollo/client';
import './style.css'
import { useTranslation } from "react-i18next";
import ReactExport from "react-export-excel";
import moment from "moment";
import 'moment/locale/en-gb';


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


const limit = 999999999
const QUERY_PUBLIC_FORM_DATA = gql`
query getPublicFormDataList($id:String!){
    getPublicFormDataList(
        id:$id,
        pagerInput:{
            limit:${limit}
            offset:0
        }
    ){
        totalPages
        publicForms{
            id
            publicFormId
            createdAt
            userInputData
        }
    }
  }
`

const QUERY_PUBLIC_FORM = gql`
query getPublicFormById($id:String!){
    getPublicFormById(
        id:$id
    ){
        id,
        title,
        header,
        formSetting
    }
  }
`


function PublicFormExportModal({
    isOpen = false, onClose = () => null, id
}) {
    moment.locale("en-gb")

    const { t } = useTranslation();
    const [loadData, { error, loading, data }] = useLazyQuery(QUERY_PUBLIC_FORM_DATA)
    const toast = useToast()
    const [loadPublicForm, { error: errorForm, loading: loadingForm, data: dataForm }] = useLazyQuery(QUERY_PUBLIC_FORM)

    const [dataExportList, setDataExportList] = useState([])

    useEffect(() => {
        if (isOpen && id) {
            loadPublicForm({
                variables: {
                    id
                }
            })
        }
    }, [isOpen, id])

    useEffect(() => {
        if (error) {
            if (error?.graphQLErrors?.length > 0) {
                error?.graphQLErrors.map(({ message }, i) => (
                    toast({
                        title: t(message),
                        status: "error",
                        isClosable: true,
                        position: "bottom-right"
                    })
                ))
            } else {
                toast({
                    title: t(error?.message ? error?.message : "something went wrong"),
                    status: "error",
                    isClosable: true,
                    position: "bottom-right"
                })
            }
        }
    }, [error])


    useEffect(() => {
        if (data) {
            let tmpList = data?.getPublicFormDataList?.publicForms
            let tmpExportList = []
            tmpList.map((item, index) => {
                let tmpItem = {
                    id: item?.id,
                    createdAt: moment(item?.createdAt).format("LLLL"),
                }
                const userInputData = item?.userInputData
                for (var k in userInputData) {
                    if (userInputData.hasOwnProperty(k)) {
                        tmpItem = { ...tmpItem, [k]: displayDataByType(userInputData[k], getJSONTypeFromFormSettingByName(k)) }
                    }
                }
                tmpExportList.push(tmpItem)
            })
            setDataExportList(tmpExportList)
        }
    }, [data])

    function getJSONTypeFromFormSettingByName(name) {
        let res = ""
        const tmpFormSetting = dataForm?.getPublicFormById?.formSetting
        if (tmpFormSetting?.form) {
            tmpFormSetting["form"]?.map((item) => {
                if (name === item?.name) {
                    res = item?.type
                }
            })
        }
        return res
    }

    function displayDataByType(data, type) {
        switch (type) {
            case "date":
                return data?moment(data).format(c.dateOnlyFormat):""
            default:
                return data
        }
    }

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => {
                onClose()
            }}
            size="md"
        >
            <ModalOverlay />
            <ModalContent borderRadius={c.borderRadius}>
                <ModalHeader>{t("Export Form")}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Box w="100%" p="16px">


                        <Button
                            id="login-btn"
                            variant="solid"
                            size="md"
                            h="42px"
                            w="128px"
                            type="submit"
                            borderRadius={c.borderRadius}
                            color="#3498db"
                            isLoading={loading}
                            onClick={() => loadData({
                                variables: {
                                    id
                                }
                            })}
                        >
                            {t("Generate")}
                        </Button>
                        {(dataExportList.length > 0) && <ExcelFile
                            filename={`Form Report - Generated At ${moment().format("DD MM YYYY hh mm ss")}`}
                            element={
                                <Button
                                    variant="solid"
                                    size="md"
                                    h="42px"
                                    w="128px"
                                    type="submit"
                                    borderRadius={c.borderRadius}
                                    color="#3498db"
                                    ml="2"
                                >
                                    {t("Download")}
                                </Button>
                            }>
                            <ExcelSheet data={dataExportList} name={`List`}>
                                <ExcelColumn label="ID" value="id" />
                                <ExcelColumn label="Created At" value="createdAt" />
                                {dataForm?.getPublicFormById?.formSetting?.form?.map((item, index) => {
                                    return (
                                        <ExcelColumn key={index} label={item?.label} value={item?.name} />
                                    )
                                })}
                            </ExcelSheet>
                        </ExcelFile>}


                    </Box>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

export default PublicFormExportModal;
