import React, { useEffect, useState } from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Button,
    Box,
    Text
} from "@chakra-ui/react";
import c from "../../constant"
import './style.css'
import { useTranslation } from "react-i18next";
import ReactExport from "react-export-excel";
import moment from "moment";
import 'moment/locale/km';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import getDepartmentLabel from "../../utils/getDepartmentLabel";
import getPositionLabel from "../../utils/getPositionLabel"
import _ from 'lodash'

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


const QUERY_EMPLOYEES = gql`
query getEmployees($offset:Float, $limit:Float, $name: String, $isActive: Boolean){
        getEmployeesFSA(
          name:$name
          isActive: $isActive
          pagerInput:{
          offset:$offset,
          limit:$limit
        }){
          totalRows
          totalPages
          employees{
            id
            department
            office
            govtNumber
            fullName
            fullNameLatin
            gender
            dob
            createdAt
            phoneNumber
            currentWorkingStatus
            firstWorkingPlace
            additionalRoleOnCurrentJob
            active
            pob
            isPobManual
            pobManual
            currentAddress
            isCurrentAddressManual
            currentAddressManual
            email
            degree
            idCardNumber
            spouseStatus,
            childInformation
            generalEducation
            specialization
            inTheFieldOfPublicFunctions
            inThePrivateSector
            foreignLanguage
          }
        }
      
  }
`


function ExcelExportModalEmployeeFSA({
    isOpen = false, onClose = () => null
}) {
    moment.locale("km")
    const [loadEmployees, { error, loading, data:employeeData }] = useLazyQuery(QUERY_EMPLOYEES)

    const { t } = useTranslation();
    const [colData, setColData] = useState([])
    const [data, setData] = useState([])
    useEffect(()=>{
        loadEmployees({
            variables:{
                offset: 0,
                limit:1000,
                isActive:true
            }
        })
    },[])

    useEffect(()=>{
        if(employeeData){
            const tmpData = employeeData?.getEmployeesFSA?.employees
            let newData = []
            tmpData?.map((item)=>{
                let tmpJobHistory = []
                item?.inTheFieldOfPublicFunctions?.map(item=>{
                    tmpJobHistory.push(item)
                })
                item?.inThePrivateSector?.map(item=>{
                    tmpJobHistory.push(item)
                })
                const jobHistory = _.orderBy(tmpJobHistory, [(obj) => new Date(obj?.endDate)], ['desc'])


                const currentAddressObject = item?.currentAddress?JSON.parse(item?.currentAddress):{}
                const pobObject = item?.pob?JSON.parse(item?.pob):{}
                const rowData = {
                    "A នាយកដ្ឋាន":t(getDepartmentLabel(item?.department))||"",
                    "B ឈ្មោះ":item?.fullName||"",
                    "C ឈ្មោះឡាតាំង":item?.fullNameLatin?.toUpperCase()||"",
                    "D ភេទ":(item?.gender==="male"?"ប្រុស":(item?.gender==="female"?"ស្រី":""))||"",
                    "E សញ្ជាតិ":"",
                    "F ថ្ងៃខែឆ្នាំកំណើត":item?.dob?moment(item?.dob).format(c.excelDateOnlyTextFormat):""||"",
                    "G ភូមិ POB":(item?.isPobManual?item?.pobManual:pobObject?.village?.[0]?.name)||"",
                    "H ឃុំ /សង្កាត់ POB":pobObject?.commune?.[0]?.name||"",
                    "I ក្រុង /ស្រុក /ខណ្ឌ POB":pobObject?.district?.[0]?.name||"",
                    "J រាជធានី /ខេត្ត POB":pobObject?.province?.[0]?.name||"",
                    "K កម្រិតវប្បធម៌":getDegreeName(item?.degree)||"",
                    "L អង្គភាព /ទីកន្លែងបំពេញការងារ":"និយ័តករធានារ៉ាប់រងកម្ពុជា",
                    "M ភារកិច្ចទទួលខុសត្រូវ":"",
                    "N លេខអត្តសញ្ញាណបណ្ណសញ្ជាតិខ្មែរ":item?.idCardNumber||"",
                    "O លេខគណនី (ប្រាក់រៀល)":"",
                    "P ផ្ទះលេខ":currentAddressObject?.streetNumber||"",
                    "Q ផ្លូវលេខ":currentAddressObject?.houseNumber||"",
                    "R ភូមិ":(item?.isCurrentAddressManual?item?.currentAddressManual:currentAddressObject?.village?.[0]?.name)||"",
                    "S ឃុំ /សង្កាត់":currentAddressObject?.commune?.[0]?.name||"",
                    "T ក្រុង /ស្រុក /ខណ្ឌ":currentAddressObject?.district?.[0]?.name||"",
                    "U រាជធានី /ខេត្ត":currentAddressObject?.province?.[0]?.name||"",
                    "V លេខទូរស័ព្ទ":item?.phoneNumber||"",
                    "W Email":item?.email||"",
                    //ព័ត៌មានអំពីគ្រួសារ
                    "X ឈ្មោះប្ដី/ប្រពន្ធ":item?.spouseStatus?.spouseName||"",
                    "Y អក្សរពុម្ពឡាតាំង":item?.spouseStatus?.spouseNameLatin||"",
                    "Z ស្ថានភាព":item?.spouseStatus?.spouseStatus||"",
                    "AA ថ្ងៃខែឆ្នាំកំណើត":item?.spouseStatus?.spouseDob?moment(item?.spouseStatus?.spouseDob).format(c.excelDateOnlyTextFormat):"",
                    "AB មុខរបរ":item?.spouseStatus?.spouseJob||"",
                    "AC អង្គភាព":item?.spouseStatus?.spouseJobInstitution||"",
                    "AD ចំនួនកូន":item?.childInformation?.length||"",
                    //ព័ត៌មានអំពីស្ថានភាពមុខងារបច្ចុប្បន្ន
                    "AE កាលបរិច្ឆេទចូលបម្រើការងារដំបូង ": item?.firstWorkingPlace?.firstWorkingstartDate?moment(item?.firstWorkingPlace?.firstWorkingstartDate).format(c.excelDateOnlyTextFormat):"",
                    "AF ប្រភេទ":"",
                    "AG ថ្នាក់":item?.currentWorkingStatus?.currentWorkingStatusJobTitle,
                    "AH មុខតំណែង ":item?.currentWorkingStatus?.currentWorkingStatusPosition?getPositionLabel(item?.currentWorkingStatus?.currentWorkingStatusPosition):"",
                    "AI អង្គភាព":item?.currentWorkingStatus?.currentWorkingStatusOrganization||"និយ័តករធានារ៉ាប់រងកម្ពុជា",
                    "AJ នាយកដ្ឋាន":t(getDepartmentLabel(item?.department))||"",
                    "AK ការិយាល័យ":item?.office||"",
                    // កម្រិតវប្បធម៌ទូទៅ
                    "AL វគ្គ ឬកម្រិតសិក្សា":item?.generalEducation?.[0]?.courseOrLevelOfStudy||"",
                    "AM គ្រឹះស្ថានសិក្សាបណ្តុះបណ្តាល និងទីកន្លែង":item?.generalEducation?.[0]?.trainingInstitutions||"",
                    "AN សញ្ញាបត្រដែលទទួលបាន":item?.generalEducation?.[0]?.DegreeObtained||"",
                    "AO ថ្ងៃខែឆ្នាំចូលសិក្សា":item?.generalEducation?.[0]?.dateOfEnrollment?moment(item?.generalEducation?.[0]?.dateOfEnrollment).format(c.excelDateOnlyTextFormat):"",
                    "AP ថ្ងៃខែឆ្នាំបញ្ចប់ការសិក្សា":item?.generalEducation?.[0]?.dateOfGraduation?moment(item?.generalEducation?.[0]?.dateOfGraduation).format(c.excelDateOnlyTextFormat):"",
                    // កម្រិតសញ្ញាបត្រ
                    // 1
                    "AQ វគ្គ ឬកម្រិតសិក្សា":item?.degree?.[0]?.courseOrLevelOfStudy||"",
                    "AR គ្រឹះស្ថានសិក្សាបណ្តុះបណ្តាល និងទីកន្លែង":item?.degree?.[0]?.trainingInstitutions||"",
                    "AS សញ្ញាបត្រដែលទទួលបាន":item?.degree?.[0]?.DegreeObtained||"",
                    "AT ថ្ងៃខែឆ្នាំចូលសិក្សា":item?.degree?.[0]?.dateOfEnrollment?moment(item?.degree?.[0]?.dateOfEnrollment).format(c.excelDateOnlyTextFormat):"",
                    "AU ថ្ងៃខែឆ្នាំបញ្ចប់ការសិក្សា":item?.degree?.[0]?.dateOfGraduation?moment(item?.degree?.[0]?.dateOfGraduation).format(c.excelDateOnlyTextFormat):"",
                    // 2
                    "AV វគ្គ ឬកម្រិតសិក្សា":item?.degree?.[1]?.courseOrLevelOfStudy||"",
                    "AW គ្រឹះស្ថានសិក្សាបណ្តុះបណ្តាល និងទីកន្លែង":item?.degree?.[1]?.trainingInstitutions||"",
                    "AX សញ្ញាបត្រដែលទទួលបាន":item?.degree?.[1]?.DegreeObtained||"",
                    "AY ថ្ងៃខែឆ្នាំចូលសិក្សា":item?.degree?.[1]?.dateOfEnrollment?moment(item?.degree?.[1]?.dateOfEnrollment).format(c.excelDateOnlyTextFormat):"",
                    "AZ ថ្ងៃខែឆ្នាំបញ្ចប់ការសិក្សា":item?.degree?.[1]?.dateOfGraduation?moment(item?.degree?.[1]?.dateOfGraduation).format(c.excelDateOnlyTextFormat):"",
                    // 3
                    "BA វគ្គ ឬកម្រិតសិក្សា":item?.degree?.[2]?.courseOrLevelOfStudy||"",
                    "BB គ្រឹះស្ថានសិក្សាបណ្តុះបណ្តាល និងទីកន្លែង":item?.degree?.[2]?.trainingInstitutions||"",
                    "BC សញ្ញាបត្រដែលទទួលបាន":item?.degree?.[2]?.DegreeObtained||"",
                    "BD ថ្ងៃខែឆ្នាំចូលសិក្សា":item?.degree?.[2]?.dateOfGraduation?moment(item?.degree?.[2]?.dateOfGraduation).format(c.excelDateOnlyTextFormat):"",
                    "BE ថ្ងៃខែឆ្នាំបញ្ចប់ការសិក្សា":item?.degree?.[2]?.dateOfGraduation?moment(item?.degree?.[2]?.dateOfGraduation).format(c.excelDateOnlyTextFormat):"",
                    // ជំនាញឯកទេស
                    // 1
                    "BF វគ្គ ឬកម្រិតសិក្សា":item?.specialization?.[0]?.courseOrLevelOfStudy||"",
                    "BG គ្រឹះស្ថានសិក្សាបណ្តុះបណ្តាល និងទីកន្លែង":item?.specialization?.[0]?.trainingInstitutions||"",
                    "BH សញ្ញាបត្រដែលទទួលបាន":item?.specialization?.[0]?.DegreeObtained||"",
                    "BI ថ្ងៃខែឆ្នាំចូលសិក្សា":item?.specialization?.[0]?.dateOfGraduation?moment(item?.specialization?.[0]?.dateOfGraduation).format(c.excelDateOnlyTextFormat):"",
                    "BJ ថ្ងៃខែឆ្នាំបញ្ចប់ការសិក្សា":item?.specialization?.[0]?.dateOfGraduation?moment(item?.specialization?.[0]?.dateOfGraduation).format(c.excelDateOnlyTextFormat):"",
                    // 2
                    "BK វគ្គ ឬកម្រិតសិក្សា":item?.specialization?.[1]?.courseOrLevelOfStudy||"",
                    "BL គ្រឹះស្ថានសិក្សាបណ្តុះបណ្តាល និងទីកន្លែង":item?.specialization?.[1]?.trainingInstitutions||"",
                    "BM សញ្ញាបត្រដែលទទួលបាន":item?.specialization?.[1]?.DegreeObtained||"",
                    "BN ថ្ងៃខែឆ្នាំចូលសិក្សា":item?.specialization?.[1]?.dateOfGraduation?moment(item?.specialization?.[1]?.dateOfGraduation).format(c.excelDateOnlyTextFormat):"",
                    "BO ថ្ងៃខែឆ្នាំបញ្ចប់ការសិក្សា":item?.specialization?.[1]?.dateOfGraduation?moment(item?.specialization?.[1]?.dateOfGraduation).format(c.excelDateOnlyTextFormat):"",
                    // 3
                    "BP វគ្គ ឬកម្រិតសិក្សា":item?.specialization?.[2]?.courseOrLevelOfStudy||"",
                    "BQ គ្រឹះស្ថានសិក្សាបណ្តុះបណ្តាល និងទីកន្លែង":item?.specialization?.[2]?.trainingInstitutions||"",
                    "BR សញ្ញាបត្រដែលទទួលបាន":item?.specialization?.[2]?.DegreeObtained||"",
                    "BS ថ្ងៃខែឆ្នាំចូលសិក្សា":item?.specialization?.[2]?.dateOfGraduation?moment(item?.specialization?.[2]?.dateOfGraduation).format(c.excelDateOnlyTextFormat):"",
                    "BT ថ្ងៃខែឆ្នាំបញ្ចប់ការសិក្សា":item?.specialization?.[2]?.dateOfGraduation?moment(item?.specialization?.[2]?.dateOfGraduation).format(c.excelDateOnlyTextFormat):"",
                    // ប្រវត្តិការងារ,
                    // 1
                    "BU ចូល":jobHistory?.[0]?.startDate?moment(jobHistory?.[0]?.startDate).format(c.excelDateOnlyTextFormat):"",
                    "BV បញ្ចប់":jobHistory?.[0]?.endDate?moment(jobHistory?.[0]?.endDate).format(c.excelDateOnlyTextFormat):"",
                    "BW ក្រសួង/ស្ថាប័ន":jobHistory?.[0]?.institution||jobHistory?.[0]?.institutionDivision,
                    "BX អង្គភាព":jobHistory?.[0]?.division||"",
                    "BY មុខតំណែង":jobHistory?.[0]?.position||"",
                    // 2
                    "BZ ចូល":jobHistory?.[1]?.startDate?moment(jobHistory?.[1]?.startDate).format(c.excelDateOnlyTextFormat):"",
                    "CA បញ្ចប់":jobHistory?.[1]?.endDate?moment(jobHistory?.[1]?.endDate).format(c.excelDateOnlyTextFormat):"",
                    "CB ក្រសួង/ស្ថាប័ន":jobHistory?.[1]?.institution||jobHistory?.[1]?.institutionDivision,
                    "CD អង្គភាព":jobHistory?.[1]?.division||"",
                    "CE មុខតំណែង":jobHistory?.[1]?.position||"",
                    // 3
                    "CF ចូល":jobHistory?.[2]?.startDate?moment(jobHistory?.[2]?.startDate).format(c.excelDateOnlyTextFormat):"",
                    "CG បញ្ចប់":jobHistory?.[2]?.endDate?moment(jobHistory?.[2]?.endDate).format(c.excelDateOnlyTextFormat):"",
                    "CH ក្រសួង/ស្ថាប័ន":jobHistory?.[2]?.institution||jobHistory?.[2]?.institutionDivision,
                    "CI អង្គភាព":jobHistory?.[2]?.division||"",
                    "CJ មុខតំណែង":jobHistory?.[2]?.position||"",
                    // សមត្ថភាពភាសាបរទេស
                    // 1
                    "CK ភាសា":item?.foreignLanguage?.[0]?.language,
                    "CL អាន":item?.foreignLanguage?.[0]?.read,
                    "CM សរសេរ":item?.foreignLanguage?.[0]?.write,
                    "CN និយាយ":item?.foreignLanguage?.[0]?.speak,
                    "CO ស្តាប់":item?.foreignLanguage?.[0]?.listen,
                    // 2
                    "CP ភាសា":item?.foreignLanguage?.[0]?.language,
                    "CQ អាន":item?.foreignLanguage?.[0]?.read,
                    "CR សរសេរ":item?.foreignLanguage?.[0]?.write,
                    "CS និយាយ":item?.foreignLanguage?.[0]?.speak,
                    "CT ស្តាប់":item?.foreignLanguage?.[0]?.listen,
                    // 3
                    "CU ភាសា":item?.foreignLanguage?.[0]?.language,
                    "CV អាន":item?.foreignLanguage?.[0]?.read,
                    "CW សរសេរ":item?.foreignLanguage?.[0]?.write,
                    "CX និយាយ":item?.foreignLanguage?.[0]?.speak,
                    "CY ស្តាប់":item?.foreignLanguage?.[0]?.listen,
                }
                newData.push(rowData)
            })
            setData(newData)
        }
    },[employeeData])

    const title = "Staff"

    function getDegreeName(degree){
        let res = ""
        if(degree){
            degree.map((item,index)=>{
                res =res +`${index>0?" / ":""}`+item?.DegreeObtained
            })
        }
        return res
    }

    function convertCamelCaseToCaseText(text) {
        const result = text?.replace(/([A-Z])/g, " $1");
        return result?.charAt(0).toUpperCase() + result?.slice(1);
    }

    useEffect(() => {
        if (data?.length > 0) {
            const row = data[0]
            const rowData = []
            for (var k in row) {
                if (row.hasOwnProperty(k)) {
                    rowData.push({ label: convertCamelCaseToCaseText(k), value: k })
                }
            }
            setColData(rowData)
        }
    }, [data])
    return (
        <Modal
            isOpen={isOpen}
            onClose={() => {
                onClose()
            }}
            size="md"
        >
            <ModalOverlay />
            <ModalContent pb="4" borderRadius={c.borderRadius}>
                <ModalHeader>{t("Export To Excel")}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>

                    <Box w="100%" pt="16px" pb="16px">
                        {loading&&<Text>{t("Loading")}...</Text>}
                        {data.length > 0 && <ExcelFile
                            filename={`${title} - Generated At ${moment().format("DD MM YYYY hh mm ss")}`}
                            element={
                                <Button
                                    variant="solid"
                                    size="md"
                                    h="42px"
                                    w="128px"
                                    type="submit"
                                    borderRadius={c.borderRadius}
                                    color="#3498db"
                                >
                                    {t("Download")}
                                </Button>
                            }>
                            <ExcelSheet data={data} name={`Table`}>
                                {
                                    colData?.map((item, index) => {
                                        return (
                                            <ExcelColumn key={`k-${index}`} label={item?.label} value={item?.value} />
                                        )
                                    })
                                }
                            </ExcelSheet>
                        </ExcelFile>}
                    </Box>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

export default ExcelExportModalEmployeeFSA;


