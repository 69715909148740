/* eslint-disable react-hooks/exhaustive-deps */
import { Text } from '@chakra-ui/react';
import { PartnerRequestStatus } from '../../../../containers/ManageAgentPartner/PartnerRequest/constants';
import PartnerRequestCancelledContractStatus from './PartnerRequestCancelledContractStatus';
import DownloadAndViewButton from '../../../Files/DownloadAndViewButton';
import PartnerRequestCertificateAwaitingStatus from './PartnerRequestCertificateAwaitingStatus';

const PartnerRequestContractFile = ({ requestStatus = null, contract = null, cancelledContract = false }) => {
	return (
		<>
			{requestStatus > PartnerRequestStatus.reviewContractFinal ? (
				<>
					<>
						{true === cancelledContract ? (
							<>
								<PartnerRequestCancelledContractStatus />
							</>
						) : (
							<>
								{contract ? (
									<>
										<DownloadAndViewButton marginLeft={0} minW={20} url={contract?.url} />
									</>
								) : (
									<>
										<Text fontSize="sm" fontWeight="normal">
											{'N/A'}
										</Text>
									</>
								)}
							</>
						)}
					</>
				</>
			) : (
				<>
					{requestStatus > PartnerRequestStatus.reviewAgentFinal ? (
						<>
							{requestStatus === PartnerRequestStatus.waitingContract && (
								<>
									<PartnerRequestCertificateAwaitingStatus />
								</>
							)}
							{requestStatus === PartnerRequestStatus.reviewContract && (
								<>
									{true === cancelledContract ? (
										<>
											<PartnerRequestCancelledContractStatus />
										</>
									) : (
										<>
											{contract ? (
												<>
													<DownloadAndViewButton marginLeft={0} minW={20} url={contract?.url} />
												</>
											) : (
												<>
													<Text fontSize="sm" fontWeight="normal">
														{'N/A'}
													</Text>
												</>
											)}
										</>
									)}
								</>
							)}
							{requestStatus === PartnerRequestStatus.reviewContractFinal && (
								<>
									{true === cancelledContract ? (
										<>
											<PartnerRequestCancelledContractStatus />
										</>
									) : (
										<>
											{contract ? (
												<>
													<DownloadAndViewButton marginLeft={0} minW={20} url={contract?.url} />
												</>
											) : (
												<>
													<Text fontSize="sm" fontWeight="normal">
														{'N/A'}
													</Text>
												</>
											)}
										</>
									)}
								</>
							)}
						</>
					) : (
						<>
							<Text fontSize="sm" fontWeight="medium">
								{'-'}
							</Text>
						</>
					)}
				</>
			)}
		</>
	);
};

export default PartnerRequestContractFile;
