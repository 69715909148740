import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Box, Flex, Button, useToast, Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
} from "@chakra-ui/react";
import c from "../../constant"
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { useHistory } from "react-router-dom";
import RadioFormField from "../../components/form/RadioFormField"
import TextFormField from "../../components/form/TextFormField"
import countriesData from "../../utils/countries"
import SelectSearchFormField from "../../components/form/SelectSearchFormField"
import { getInitSelectSearch } from "../../utils"
import DateFormField from "../form/DateFormField";
import moment from "moment";

const EDIT_COMPANY_SHAREHOLDER_MUTATION = gql`
mutation update($name: String, $representative: String, $gender: String, $nationality: String, $amount: Float, $currency: String, $percentage: Float, $id: String!, $approvalDate: DateTime){
    editCompanyShareholder(
      id:$id
      percentage:$percentage
      amount:$amount
      nationality:$nationality
      name:$name
      representative: $representative
      gender:$gender
      currency: $currency
      approvalDate: $approvalDate
    )
  }
 `

 const QUERY_DATA = gql`
 query get($id: String!){
    getCompanyShareholderById(id:$id){
      id
      name
      representative
      gender
      nationality
      amount
      currency
      percentage
      createdAt
      approvalDate
    }
  }
 `

const signUpSchema = Yup.object().shape({
    name: Yup.string(),
    representative: Yup.string().nullable(),
    gender: Yup.string().nullable(),
    nationality: Yup.object().nullable(),
    amount: Yup.string().nullable(),
    currency: Yup.string(),
    percentage: Yup.string().nullable(),
    approvalDate: Yup.string().nullable()
});

function EditShareholder({
    isOpen = false, onClose = () => null, callback = () => null, id
}) {
    const history = useHistory();
    const toast = useToast()
    const { t } = useTranslation();
    const [editCompanyShareholder, { loading, error, data }] = useMutation(
        EDIT_COMPANY_SHAREHOLDER_MUTATION
    );

    const [loadData, { loading: loadingData, error: errorData, data: dataData }] = useLazyQuery(
        QUERY_DATA
    );
    
    useEffect(() => {
        if (id) {
            loadData({
                variables: {
                    id
                }
            })
        }
    }, [id]);

    useEffect(() => {
        if (data) {
            if (data?.editCompanyShareholder) {
                toast({
                    title: t("Company shareholder updated successfully"),
                    status: "success",
                    isClosable: true,
                    position: "top-right"
                })
                onClose()
                callback("Company shareholder updated successfully")
            }
        }
    }, [data])

    useEffect(() => {
        if (error) {
            error.graphQLErrors.map(({ message }, i) => (
                toast({
                    title: t(message),
                    status: "error",
                    isClosable: true,
                    position: "top-right"
                })
            ))
        }
    }, [error])


    function update(values) {
        editCompanyShareholder({
            variables: {
                id: id,
                amount: parseFloat(values.amount),
                currency: values.currency,
                percentage: parseFloat(values.percentage),
                nationality: values?.nationality?.value ? values?.nationality?.value : null,
                name: values.name,
                representative: values.representative,
                gender: values.gender,
                approvalDate: values?.approvalDate?moment(values.approvalDate).toDate():null,
            }
        })
    }

    function getNationalityOptions() {
        let tmpData = []
        countriesData.map((item) => {
            tmpData.push({
                label: t(item.nationality),
                value: item.nationality
            })
        })
        return tmpData
    }

    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={() => {
                    onClose()

                }}
                size="md"
            >
                <ModalOverlay />
                <ModalContent borderRadius={c.borderRadius} pb="2">
                    <ModalHeader>{t("Edit Company Shareholder")}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {dataData?.getCompanyShareholderById&&<Formik
                            initialValues={{
                                name: dataData?.getCompanyShareholderById?.name,
                                representative: dataData?.getCompanyShareholderById?.representative,
                                gender: dataData?.getCompanyShareholderById?.gender,

                                nationality: getInitSelectSearch({ value: dataData?.getCompanyShareholderById?.nationality, options: getNationalityOptions() }),

                                amount: dataData?.getCompanyShareholderById?.amount,
                                currency: dataData?.getCompanyShareholderById?.currency,
                                percentage: dataData?.getCompanyShareholderById?.percentage,
                                approvalDate: dataData?.getCompanyShareholderById?.approvalDate
                            }}
                            validationSchema={signUpSchema}
                            onSubmit={(values) => {
                                update(values);
                            }}
                        >
                            {(props) => (
                                <Form>
                                    <Box mt="3">
                                        <TextFormField
                                            {...props}
                                            label="Company Name"
                                            name="name"
                                        />

                                        <TextFormField
                                            {...props}
                                            label="Representative"
                                            name="representative"
                                        />

                                        <RadioFormField
                                            label="Gender"
                                            name="gender"
                                            radios={[
                                                { label: "Male", value: "male" },
                                                { label: "Female", value: "female" },
                                            ]}
                                            initValue={dataData?.getCompanyShareholderById?.gender}

                                        />

                                        <SelectSearchFormField
                                            {...props}
                                            name="nationality"
                                            label="Nationality"
                                            placeholder="Nationality"
                                            options={getNationalityOptions()}
                                        />

                                        <TextFormField
                                            {...props}
                                            label="Amount"
                                            name="amount"
                                            type="number"
                                        />

                                        <RadioFormField
                                            label="Currency"
                                            name="currency"
                                            radios={[
                                                { label: "KHR", value: "KHR" },
                                                { label: "USD", value: "USD" },
                                            ]}
                                            initValue={dataData?.getCompanyShareholderById?.currency}
                                        />

                                        <TextFormField
                                            {...props}
                                            label="Percentage"
                                            name="percentage"
                                        />

                                        <DateFormField
                                            name="approvalDate"
                                            label="Approval Date"
                                            initialDate={dataData?.getCompanyShareholderById?.approvalDate}
                                        />

                                        <Flex
                                            textAlign="right"
                                            mt="6"
                                            justifyContent="space-between"
                                            alignItems="center"
                                        >
                                            <Button
                                                id="login-btn"
                                                variant="solid"
                                                size="md"
                                                h="42px"
                                                w="128px"
                                                bg="primary.dark"
                                                _hover={{ bg: "primary.dark" }}
                                                color="white"
                                                // borderRadius="31px"
                                                type="submit"
                                                isLoading={loading}
                                                loadingText={t("Edit")}
                                                spinnerPlacement="start"
                                            >
                                                {t(`Edit`)}
                                            </Button>
                                        </Flex>
                                    </Box>
                                </Form>
                            )}
                        </Formik>}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
}

export default EditShareholder;
