import React, { useState, useRef } from "react";
import { Input, FormControl, FormLabel, FormHelperText, Button, Flex, Box } from "@chakra-ui/react";
import { Field } from "formik";
import { useTranslation } from "react-i18next";
import c from "../../constant"
import { canvasPreview } from './canvasPreview'
import { useDebounceEffect } from './useDebounceEffect'
import Resizer from "react-image-file-resizer";

import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    Crop,
    PixelCrop,
} from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

let aspect = 480 / 640
const ImageCropFormField = props => {
    const { t } = useTranslation();
    const {
        name,
        label,
        required,
        maxFileSize = 5242880,
        note,
        resizedToWidth
    } = props;
    if(props?.aspect){
        aspect = props?.aspect
    }
    const imgRef = useRef(null)
    const [crop, setCrop] = useState()
    const [imgSrc, setImgSrc] = useState('')

    const previewCanvasRef = useRef(null)
    const [completedCrop, setCompletedCrop] = useState()
    const [scale, setScale] = useState(1)
    const [rotate, setRotate] = useState(0)

    const [apply, setApply] = useState(false)


    let formState = null

    function onSelectFile(e) {
        if (e.target.files && e.target.files.length > 0) {
            setCrop(undefined) // Makes crop preview update between images.
            const reader = new FileReader()
            reader.addEventListener('load', () =>
                setImgSrc(reader.result.toString() || ''),
            )
            reader.readAsDataURL(e.target.files[0])
        }
    }

    function onImageLoad(e) {
        if (aspect) {
            const { width, height } = e.currentTarget
            setCrop(centerAspectCrop(width, height, aspect))
        }
    }


    useDebounceEffect(
        async () => {
            if (
                apply &&
                completedCrop?.width &&
                completedCrop?.height &&
                imgRef.current &&
                previewCanvasRef.current
            ) {
                // We use canvasPreview as it's much faster than imgPreview.
                canvasPreview(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop,
                    scale,
                    rotate,
                    convasCallback
                )
            }
        },
        100,
        [completedCrop, scale, rotate, apply],
    )

    function convasCallback(e) {
        if (formState) {
            Resizer.imageFileResizer(
                e,
                resizedToWidth,
                "auto",
                "JPEG",
                90,
                0,
                (uri) => {
                    formState.setFieldValue(name, uri);
                },
                "file"
            );


        }
    }

    return (
        <Field name={name}>
            {({ field, meta, form }) => {
                formState = form
                return (
                    <FormControl margin="normal" mb="4">
                        <FormLabel>{t(label)}{required && <span style={{ color: "red" }}>*</span>}</FormLabel>
                        <Input
                            accept="image/*"
                            pt="4px"
                            id={name}
                            name={name}
                            type="file"
                            onChange={e => {
                                onSelectFile(e)
                                setApply(false)
                                form.setFieldValue(name, null);
                            }}
                        />
                        <center style={{ marginTop: apply ? -64 : 0 }}>
                            {imgSrc && (
                                <Flex opacity={apply ? 0 : 1} pointerEvents={apply ? "none" : "unset"} direction="column" p="2" bg="#ecf0f1">
                                    <Box pb="2">
                                        <ReactCrop
                                            crop={crop}
                                            onChange={(_, percentCrop) => setCrop(percentCrop)}
                                            onComplete={(c) => setCompletedCrop(c)}
                                            aspect={aspect}
                                        >
                                            <img
                                                ref={imgRef}
                                                alt="Crop me"
                                                src={imgSrc}
                                                style={{ transform: `scale(${scale}) rotate(${rotate}deg)`, height: 300, width: "auto" }}
                                                onLoad={onImageLoad}
                                            />
                                        </ReactCrop>
                                    </Box>
                                    <Button borderRadius={c.borderRadius} colorScheme='blue' onClick={() => setApply(true)}>{t("Apply")}</Button>
                                </Flex>
                            )}

                            {apply && <Box p="2" marginTop={`-${completedCrop.height}`}>
                                {completedCrop && (
                                    <canvas
                                        ref={previewCanvasRef}
                                        style={{
                                            border: '1px solid black',
                                            objectFit: 'contain',
                                            width: completedCrop.width,
                                            height: completedCrop.height,
                                        }}
                                    />
                                )}
                            </Box>}
                        </center>

                        {note && note}
                        <FormLabel fontSize="13px" color="gray.500">{t("Maximum file size")}{` ( ${parseFloat(maxFileSize / 1048576).toFixed(2)}MB ) `}</FormLabel>
                        {meta.touched && meta.error && (
                            <FormHelperText
                                id={`error-message-${name}`}
                                color="red.400"
                                fontSize="12px"
                            >
                                {t(name)}{" "}
                                {t("is")}{" "}
                                {t(meta.error)}
                            </FormHelperText>
                        )}

                    </FormControl>)
            }}
        </Field>
    );
};

export default ImageCropFormField;

function centerAspectCrop(
    mediaWidth,
    mediaHeight,
    aspect,
) {
    return centerCrop(
        makeAspectCrop(
            {
                unit: '%',
                width: 90,
            },
            aspect,
            mediaWidth,
            mediaHeight,
        ),
        mediaWidth,
        mediaHeight,
    )
}
