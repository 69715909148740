/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
	Box,
	Flex,
	Text,
	Button,
	Table,
	Thead,
	Tbody,
	Tfoot,
	Tr,
	Th,
	Td,
	Center,
	Tooltip,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	AlertDialog,
	AlertDialogOverlay,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogBody,
	AlertDialogFooter,
	useToast,
} from '@chakra-ui/react';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { SectionHeader } from '../../Other';
import ReactPaginate from 'react-paginate';
import DownloadAndViewButton from '../../Files/DownloadAndViewButton';
import EditCompanyCertificateModal from '../../Modal/EditCompanyRegisteredOfficeModal';
import AddCompanyRegisteredOfficeModal from '../../Modal/AddCompanyRegisteredOfficeModal';
import LoadingTable from '../../Table/Loading';
import DistributionSystemRegisteredOfficePrint from './DistributionSystemRegisteredOfficePrint';
import useMoment from '../../../hooks/useMoment';
import useStore from '../../../store';
import c from '../../../constant';

const Q_GET_COMPANY_CERTIFIACTES = gql`
	query GetCompanyCertificate($id: String!, $offset: Float, $limit: Float, $companyCertificateType: String) {
		getCompanyCertificate(companyId: $id, companyCertificateType: $companyCertificateType, pagerInput: { offset: $offset, limit: $limit }) {
			totalRows
			totalPages
			branches {
				id
				number
				name
				nameLatin
				managerName
				managerNameLatin
				validFrom
				validTo
				file
				streetNumber
				houseNumber
				geoManual
				isGeoManual
				provinceObject {
					code
					name
					latinName
					administrativeUnit
					administrativeUnitLatin
				}
				districtObject {
					code
					name
					latinName
					administrativeUnit
					administrativeUnitLatin
				}
				communeObject {
					code
					name
					latinName
					administrativeUnit
					administrativeUnitLatin
				}
				villageObject {
					code
					name
					latinName
					administrativeUnit
					administrativeUnitLatin
				}
				isPermanentLicense
			}
		}
	}
`;

const M_DELETE_COMPANY_CERTIFICATE = gql`
	mutation DeleteCompanyCertificate($id: String!) {
		deleteCompanyCertificate(id: $id)
	}
`;

let workingId = null;
let limit = 10;
let offset = 0;

function DistributionSystemRegisteredOffice({ id, ...props }) {
	const deleteDialogRef = useRef();
	const deleteDialogCancelRef = useRef();
	const toast = useToast();
	const { toDateString } = useMoment();
	const { t } = useTranslation();
	const { currentUser } = useStore((state) => state.currentUser);
	const [isOpeningInsertDialog, setOpeningInsertDialog] = useState(false);
	const [isOpeningEditDialog, setOpeningEditDialog] = useState(false);
	const [isOpeningDeleteDialog, setOpeningDeleteDialog] = useState(false);
	const [loadCompanyCertificates, { loading: loadingCompanyCertificates, error: errorCompanyCertificates, data: dataCompanyCertificates }] =
		useLazyQuery(Q_GET_COMPANY_CERTIFIACTES);
	const [deleteCompanyCertificate, { loading: loadingDeleteCompanyCertificate, error: errorDeleteCompanyCertificate, data: dataDeleteCompanyCertificate }] =
		useMutation(M_DELETE_COMPANY_CERTIFICATE);

	const refreshCompanyCallback = useCallback(() => {
		offset = 0;
	}, [id]);

	const loadCompanyCertificatesCallback = useCallback(() => {
		const variables = {
			id,
			offset,
			limit,
			companyCertificateType: 'r',
		};
		loadCompanyCertificates({ variables });
	}, [id]);

	useEffect(() => {
		refreshCompanyCallback();
	}, [refreshCompanyCallback]);

	useEffect(() => {
		loadCompanyCertificatesCallback();
	}, [loadCompanyCertificatesCallback]);

	useEffect(() => {
		if (errorCompanyCertificates) {
			errorCompanyCertificates.graphQLErrors.map(({ message }, i) =>
				toast({
					title: t(message),
					status: 'error',
					isClosable: true,
					position: 'top-right',
				})
			);
		}
	}, [errorCompanyCertificates]);

	useEffect(() => {
		if (dataDeleteCompanyCertificate?.deleteCompanyCertificate) {
			toast({
				title: t('Company Registered Office deleted successfully'),
				status: 'success',
				isClosable: true,
				position: 'top-right',
			});
			loadCompanyCertificatesCallback();
			onRejectDeleteDialog();
		}
	}, [dataDeleteCompanyCertificate]);

	useEffect(() => {
		if (errorDeleteCompanyCertificate) {
			errorDeleteCompanyCertificate.graphQLErrors.map(({ message }, i) =>
				toast({
					title: t(message),
					status: 'error',
					isClosable: true,
					position: 'top-right',
				})
			);
		}
	}, [errorDeleteCompanyCertificate]);

	function ActionMenu({ id }) {
		return (
			<Menu>
				<Tooltip label={`${t('Edit')} ${t('Delete')}`}>
					<MenuButton cursor="pointer">
						<Flex pl="2" pr="2">
							<FontAwesomeIcon icon={faEllipsisV} style={{ fontSize: 16, marginTop: 4, marginLeft: 6 }} />
						</Flex>
					</MenuButton>
				</Tooltip>
				<MenuList pt="4" pb="4" pl="3" pr="3" borderRadius={c.borderRadius}>
					<MenuItem
						color={'blue.500'}
						onClick={() => {
							workingId = id;
							setOpeningEditDialog(true);
						}}
					>
						{t('Edit')}
					</MenuItem>
					<MenuItem
						color={'red.500'}
						onClick={() => {
							workingId = id;
							setOpeningDeleteDialog(true);
						}}
					>
						{t('Delete')}
					</MenuItem>
				</MenuList>
			</Menu>
		);
	}

	function onPageChange(value) {
		offset = value * limit;
		loadCompanyCertificatesCallback();
	}

	function onRejectInsertDialog() {
		setOpeningInsertDialog(false);
	}

	function onApproveInsertDialog() {
		loadCompanyCertificatesCallback();
	}

	function onRejectDeleteDialog() {
		setOpeningDeleteDialog(false);
	}

	function onApproveDeleteDialog() {
		const variables = { id: workingId };
		deleteCompanyCertificate({ variables });
	}

	function onRejectEditDialog() {
		setOpeningEditDialog(false);
	}

	function onApproveEditDialog() {
		loadCompanyCertificatesCallback();
	}

	return (
		<>
			<Flex {...props}>
				<Box className="hidden-print" width="100%">
					<SectionHeader title={t('Registered Office')} />
					<Center mt={4}>
						<Box
							w="100%"
							bg="white"
							borderRadius={c.borderRadius}
							border="1px solid #dbdbdb"
							boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
							pb={2}
						>
							<Flex p={4} pt={2} pb={2} mb={4} h="48px" bg="#FAFAFA" alignItems="center" justifyContent="end" borderBottom="1px solid #dbdbdb">
								<Flex alignItems="center">
									{(currentUser?.role === 'admin' || currentUser?.canUpdateCompanyLicensing) && (
										<Tooltip label={t('Add Company Registered Office')}>
											<Flex
												userSelect="none"
												onClick={() => setOpeningInsertDialog(true)}
												cursor="pointer"
												alignItems="center"
												justifyContent="center"
												w="32px"
												h="32px"
												borderRadius={c.borderRadius}
												border="1px solid #dbdbdb"
												boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
											>
												<Text fontSize="26px" mt="2px" fontWeight="500">
													+
												</Text>
											</Flex>
										</Tooltip>
									)}
								</Flex>
							</Flex>
							<Box p="16px">
								<Table variant="simple">
									<Thead>
										<Tr>
											<Th>{t('Number')}</Th>
											<Th>{t('Name')}</Th>
											<Th>{t('License Valid')}</Th>
											{(currentUser?.role === 'admin' || currentUser?.canUpdateCompanyLicensing) && <Th isNumeric>{t('Actions')}</Th>}
										</Tr>
									</Thead>
									<Tbody>
										{loadingCompanyCertificates && <LoadingTable column={4} row={3} />}
										{dataCompanyCertificates?.getCompanyCertificate?.branches?.length <= 0 && (
											<Tr>
												<Td>
													<Text>{t('No Data')}</Text>
												</Td>
											</Tr>
										)}
										{dataCompanyCertificates?.getCompanyCertificate?.branches?.map((item, index) => {
											return (
												<Tr key={`item-${index}`} bg="white" _hover={{ bg: 'gray.200' }}>
													<Td>
														<Text fontWeight="bold">{item.number}</Text>
													</Td>
													<Td>
														<Text>{item.name}</Text>
													</Td>
													<Td>
														<>
															{item?.isPermanentLicense ? (
																<Text mb={1}>{t('Permanent')}</Text>
															) : (
																<>
																	{item?.validFrom && item?.validTo && (
																		<>
																			<Text mb={1}>
																				{toDateString(item?.validFrom, c.dateOnlyFormat)}
																				&nbsp;-&nbsp;
																				{toDateString(item?.validTo, c.dateOnlyFormat)}
																			</Text>
																		</>
																	)}
																</>
															)}
															{item?.file?.file && item?.file?.size && (
																<DownloadAndViewButton url={item?.file?.file} size={item?.file?.size} marginLeft={0} />
															)}
														</>
													</Td>
													{(currentUser?.role === 'admin' || currentUser?.canUpdateCompanyLicensing) && (
														<Td isNumeric>
															<ActionMenu id={item?.id} />
														</Td>
													)}
												</Tr>
											);
										})}
									</Tbody>
									<Tfoot>
										<Tr>
											<Th>{t('Number')}</Th>
											<Th>{t('Name')}</Th>
											<Th>{t('License Valid')}</Th>
											{(currentUser?.role === 'admin' || currentUser?.canUpdateCompanyLicensing) && <Th isNumeric>{t('Actions')}</Th>}
										</Tr>
									</Tfoot>
								</Table>
								<Flex justifyContent="flex-end" w="100%" mt="4">
									{dataCompanyCertificates?.getCompanyCertificate?.totalPages > 1 && (
										<ReactPaginate
											previousLabel={<ChevronLeftIcon fontSize="20px" />}
											nextLabel={<ChevronRightIcon fontSize="20px" />}
											breakLabel={'...'}
											breakClassName={'break-me'}
											disableInitialCallback={true}
											pageCount={dataCompanyCertificates?.getCompanyCertificate?.totalPages}
											marginPagesDisplayed={2}
											pageRangeDisplayed={3}
											onPageChange={({ selected }) => onPageChange(selected)}
											containerClassName={'pagination'}
											activeClassName={'active'}
											initialPage={offset / limit}
										/>
									)}
								</Flex>
							</Box>
						</Box>
					</Center>
				</Box>
			</Flex>
			<AlertDialog isOpen={isOpeningDeleteDialog} leastDestructiveRef={deleteDialogRef} onClose={onRejectDeleteDialog}>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							{t('Delete Registered Office')}
						</AlertDialogHeader>

						<AlertDialogBody>{t("Are you sure? You can't undo this action afterwards")}</AlertDialogBody>
						<AlertDialogFooter>
							<Button mr={4} isLoading={loadingDeleteCompanyCertificate} ref={deleteDialogCancelRef} onClick={onRejectDeleteDialog}>
								{t('Cancel')}
							</Button>
							<Button isLoading={loadingDeleteCompanyCertificate} colorScheme="red" onClick={onApproveDeleteDialog}>
								{t('Delete')}
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
			<AddCompanyRegisteredOfficeModal companyId={id} isOpen={isOpeningInsertDialog} onClose={onRejectInsertDialog} callback={onApproveInsertDialog} />
			{isOpeningEditDialog && <EditCompanyCertificateModal id={workingId} isOpen={true} onClose={onRejectEditDialog} callback={onApproveEditDialog} />}
			{dataCompanyCertificates && <DistributionSystemRegisteredOfficePrint dataCompanyCertificates={dataCompanyCertificates} />}
		</>
	);
}

export default DistributionSystemRegisteredOffice;
