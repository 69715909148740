/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Button, useToast, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { getInitSelectSearch } from '../../utils';
import RadioFormField from '../../components/form/RadioFormField';
import TextFormField from '../../components/form/TextFormField';
import SelectSearchFormField from '../../components/form/SelectSearchFormField';
import DateFormField from '../form/DateFormField';
import FileFormField from '../../components/form/FileFormField';
import countriesData from '../../utils/countries';
import moment from 'moment';
import c from '../../constant';
import * as Yup from 'yup';

const UPDATE_MUTATION = gql`
	mutation update(
		$id: String!
		$name: String!
		$gender: String
		$nationality: String
		$position: String
		$approvalDate: DateTime
		$file: Upload
		$fileSize: Float
	) {
		updateCompanyCEOById(
			id: $id
			name: $name
			gender: $gender
			nationality: $nationality
			position: $position
			approvalDate: $approvalDate
			file: $file
			fileSize: $fileSize
		)
	}
`;

const QUERY_DATA = gql`
	query get($id: String!) {
		getCompanyCEOById(id: $id) {
			id
			name
			gender
			position
			nationality
			createdAt
			approvalDate
		}
	}
`;

const signUpSchema = Yup.object().shape({
	name: Yup.string().required('Required'),
	gender: Yup.string().nullable(),
	nationality: Yup.object().nullable(),
	position: Yup.string().nullable(),
	approvalDate: Yup.string().nullable(),
	file: Yup.mixed().test('fileSize', 'over limit', (value) => {
		if (value) {
			return value?.size <= c.maxFileSize / 10;
		} else {
			return true;
		}
	}),
});

function Create({ type = null, id = null, isOpen = false, onClose = () => null, callback = () => null }) {
	const toast = useToast();
	const { t } = useTranslation();
	const [loadData, { data: dataData }] = useLazyQuery(QUERY_DATA);
	const [updateMutation, { loading, error, data }] = useMutation(UPDATE_MUTATION);

	useEffect(() => {
		id && loadData({ variables: { id } });
	}, [id]);

	useEffect(() => {
		if (data) {
			if (data?.updateCompanyCEOById) {
				toast({
					title: t('Company CEO updated successfully'),
					status: 'success',
					isClosable: true,
					position: 'top-right',
				});
				onClose();
				callback('Company CEO updated successfully');
			}
		}
	}, [data]);

	useEffect(() => {
		if (error) {
			error.graphQLErrors.map(({ message }, i) =>
				toast({
					title: t(message),
					status: 'error',
					isClosable: true,
					position: 'top-right',
				})
			);
		}
	}, [error]);

	function update(values) {
		updateMutation({
			variables: {
				id: id,
				name: values.name,
				gender: values.gender,
				nationality: values?.nationality?.value ? values?.nationality?.value : null,
				position: values.position,
				approvalDate: values?.approvalDate ? moment(values.approvalDate).toDate() : null,
				file: values.file,
				fileSize: values?.file?.size,
			},
		});
	}

	return (
		<>
			<Modal isOpen={isOpen} onClose={onClose} size="md">
				<ModalOverlay />
				<ModalContent borderRadius={c.borderRadius} pb="2">
					<ModalHeader>{t('Edit')}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						{dataData?.getCompanyCEOById && (
							<Formik
								initialValues={{
									name: dataData?.getCompanyCEOById?.name,
									gender: dataData?.getCompanyCEOById?.gender,
									nationality: getInitSelectSearch({
										value: dataData?.getCompanyCEOById?.nationality,
										options: countriesData.map((item) => ({ label: t(item.nationality), value: item.nationality })),
									}),
									position: dataData?.getCompanyCEOById?.position,
									approvalDate: dataData?.getCompanyCEOById?.approvalDate,
									file: null,
								}}
								validationSchema={signUpSchema}
								onSubmit={(values) => update(values)}
							>
								{(props) => (
									<Form>
										<Box mt="3">
											<TextFormField {...props} label="Name" name="name" required />
											<RadioFormField
												label="Gender"
												name="gender"
												radios={[
													{ label: 'Male', value: 'male' },
													{ label: 'Female', value: 'female' },
												]}
												initValue={dataData?.getCompanyCEOById?.gender}
											/>
											<SelectSearchFormField
												{...props}
												name="nationality"
												label="Nationality"
												placeholder="Nationality"
												options={countriesData.map((item) => ({ label: t(item.nationality), value: item.nationality }))}
											/>
											{type !== c.governanceCeo.ceo && <TextFormField {...props} label="Position" name="position" />}
											<DateFormField name="approvalDate" label="Approval Date" initialDate={dataData?.getCompanyCEOById?.approvalDate} />
											<FileFormField {...props} label="Permit letter" name="file" maxFileSize={c.maxFileSize / 10} />
											<Flex textAlign="right" mt="6" justifyContent="space-between" alignItems="center">
												<Button
													id="login-btn"
													variant="solid"
													size="md"
													h="42px"
													w="128px"
													bg="primary.dark"
													_hover={{ bg: 'primary.dark' }}
													color="white"
													type="submit"
													isLoading={loading}
													loadingText={t('Edit')}
													spinnerPlacement="start"
												>
													{t(`Edit`)}
												</Button>
											</Flex>
										</Box>
									</Form>
								)}
							</Formik>
						)}
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
}

export default Create;
