/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Flex, Text, Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export default function CardLabelValue({ label = null, value = null, width = '120px' }) {
	const { t } = useTranslation();

	return (
		<Flex mb={1} alignItems="center">
			<Text minW={width} maxW={width} w={width} fontSize="sm" fontWeight="600">
				{t(label)}
			</Text>
			<Box>{value}</Box>
		</Flex>
	);
}
