import c from '../constant';

const getDepartments = (lang) => {
	const options = c.departments.reduce((p, c) => {
		let options = p;
		options.push({ label: lang === 'kh' ? c?.labelKh : c?.label, value: c?.value });
		return options;
	}, []);
	return options;
};

const getDepartmentOffices = (dep, lang) => {
	const option = c.departments.find((e) => e?.value === dep);
	if (option) {
		const options = option?.offices?.reduce((p, c) => {
			let options = p;
			options.push({ label: lang === 'kh' ? c?.labelKh : c?.label, value: c?.value });
			return options;
		}, []);
		return options;
	}
	return [];
};

const getDepartment = (dep, lang) => {
	const department = c.departments.find((e) => e?.value === dep);
	if (department) {
		return { label: lang === 'kh' ? department?.labelKh : department?.label, value: department?.value };
	}
	return null;
};

const getOffice = (dep, ofc, lang) => {
	const department = c.departments.find((e) => e?.value === dep);
	if (department) {
		const office = department?.offices?.find((e) => e?.value === ofc);
		if (office) {
			return { label: lang === 'kh' ? office?.labelKh : office?.label, value: office?.value };
		}
	}
	return null;
};

export { getDepartments, getDepartmentOffices, getDepartment, getOffice };
