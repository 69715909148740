import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Box, Flex, Text, Button, useToast, SimpleGrid, Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure
} from "@chakra-ui/react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import TextFormField from "../../../../components/form/TextFormField"
import DateFormField from "../../../../components/form/DateFormField"
import DateInputFormField from "../../../../components/form/DateInputFormField"
import RadioFormField from "../../../../components/form/RadioFormField"
import GeoFormField from "../../../../components/form/GeoFormField"
import CheckboxFormField from "../../../../components/form/CheckBoxFormField"
import TextAreaFormField from "../../../../components/form/TextAreaFormField"
import TextFormFieldReadOnly from "../../../../components/form/TextFormFieldReadOnly"
import { gql, useMutation } from '@apollo/client';
import moment from "moment"
import useStore from "../../../../store";
import { faSave, faPenSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import c from "../../../../constant";
import GeoCodeResoliver from "../../../../components/Geo/GeoCodeResolver"
import PreUploadFileFormField from "../../../../components/form/PreUploadFileFormField"
import DownloadAndViewButtonUserUpload from "../../../../components/Files/DownloadAndViewButtonUserUpload"

const UPDATE_EMPLOYEE_MUTATION = gql`
mutation update($id:String!, $personalData: String){
    updateEmployeePersonalData(
      id:$id
      personalData:$personalData
    )
  }
`


const signUpSchema = Yup.object().shape({
    govtNumber: Yup.string(),
    mefNumber: Yup.string(),
    fullName: Yup.string(),
    fullNameLatin: Yup.string(),
    gender: Yup.string(),
    dob: Yup.string().nullable(),
    maritalStatus: Yup.string(),
    pob: Yup.object().nullable(),
    isPobManual: Yup.boolean(),
    pobManual: Yup.string(),
    currentAddress: Yup.object().nullable(),
    isCurrentAddressManual: Yup.boolean(),
    currentAddressManual: Yup.string(),
    email: Yup.string(),
    phoneNumber: Yup.string(),
    idCardNumber: Yup.string(),
    idCardExpirationDate: Yup.string().nullable(),
    passportNumber: Yup.string(),
    passportExpirationDate: Yup.string().nullable(),
    documentIDCard: Yup.string(),
    documentPassport: Yup.string(),
});

function PersonalInformation({ id, initData, callbackOnSuccess }) {
    const toast = useToast()
    const { t } = useTranslation();
    const [updateEmployee, { error, data, loading }] = useMutation(
        UPDATE_EMPLOYEE_MUTATION
    );
    const { currentLang } = useStore((state) => state.currentLang);
    moment.locale(currentLang === "kh" ? "km" : "en-gb")

    const [isEditing, setIsEditing] = useState(false)

    const {
        isOpen,
        onClose,
        onOpen,
    } = useDisclosure()


    const [isPobManual, setIsPobManual] = useState(initData?.isPobManual)
    const [isCurrentAddressManual, setCurrentAddressManual] = useState(initData?.isCurrentAddressManual)

    useEffect(() => {
        if (error) {
            if (error?.graphQLErrors?.length > 0) {
                error?.graphQLErrors.map(({ message }, i) => (
                    toast({
                        title: t(message),
                        status: "error",
                        isClosable: true,
                        position: "bottom-right"
                    })
                ))
            } else {
                toast({
                    title: t(error?.message ? error?.message : "something went wrong"),
                    status: "error",
                    isClosable: true,
                    position: "bottom-right"
                })
            }
        }
    }, [error])

    useEffect(() => {
        if (data) {
            if (data.updateEmployeePersonalData) {
                toast({
                    title: t("Officials updated"),
                    description: t("Officials updated successfully"),
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                    position: "bottom-right"
                })
                callbackOnSuccess()
            }
        }
    }, [data])

    function rowData({ labelEN, labelKH, value }) {
        return (
            <Flex>
                <Text fontWeight="bold">{currentLang === "en" ? labelEN : labelKH}</Text>:
                <Text ml="4">{value || ""}</Text>
            </Flex>
        )
    }

    function getJsonGeoData(tmpData) {
        if (tmpData) {
            const data = JSON.parse(tmpData)
            return {
                province: data?.province,
                district: data?.district,
                commune: data?.commune,
                village: data?.village,
                streetNumber: data?.streetNumber,
                houseNumber: data?.houseNumber,
            }
        } else {
            return null
        }
    }

    function getAddress(stringData) {
        if (stringData) {
            const data = JSON.parse(stringData)
            return (<GeoCodeResoliver
                province={data?.province}
                district={data?.district}
                commune={data?.commune}
                village={data?.village}
                houseNumber={data?.houseNumber}
                streetNumber={data?.streetNumber}
            />)
        } else {
            return null
        }
    }


    function viewFile(value){
        const tmpValue = value?JSON.parse(value):null
        return tmpValue?<DownloadAndViewButtonUserUpload url={tmpValue?.file} size={tmpValue?.fileSize}/>:"" 
    }

    function displayPersonalInfo() {
        const item = initData
        return (
            <>
                <SimpleGrid columns={[1, 2, 3]} spacing={2} p="26px">
                    {rowData({ labelEN: "អត្តលេខមន្ត្រីរាជការ", labelKH: "អត្តលេខមន្ត្រីរាជការ", value: item?.govtNumber })}
                    {rowData({ labelEN: "លេខប័ណ្ណសម្គាល់មន្ត្រីកសហវ", labelKH: "លេខប័ណ្ណសម្គាល់មន្ត្រីកសហវ", value: item?.mefNumber })}
                    {rowData({ labelEN: "គោត្តនាម និងនាម", labelKH: "គោត្តនាម និងនាម", value: item?.fullName })}
                    {rowData({ labelEN: "គោត្តនាម និងនាមជាអក្សរឡាតាំង", labelKH: "គោត្តនាម និងនាមជាអក្សរឡាតាំង", value: item?.fullNameLatin?.toUpperCase() })}
                    {rowData({ labelEN: "ភេទ", labelKH: "ភេទ", value: t(item?.gender) })}
                    {rowData({ labelEN: "ថ្ងៃខែឆ្នាំកំណើត", labelKH: "ថ្ងៃខែឆ្នាំកំណើត", value: item?.dob ? moment(item?.dob).format(c.excelDateOnlyTextFormat) : "" })}
                    {rowData({ labelEN: "ស្ថានភាពគ្រូសារ", labelKH: "ស្ថានភាពគ្រូសារ", value: t(item?.maritalStatus) })}
                    {rowData({ labelEN: "កន្លែងកំណើត", labelKH: "កន្លែងកំណើត", value: item?.isPobManual ? item?.pobManual : getAddress(item?.pob) })}
                    {rowData({ labelEN: "អាសយដ្ឋានបច្ចុប្បន្ន", labelKH: "អាសយដ្ឋានបច្ចុប្បន្ន", value: item?.isCurrentAddressManual ? item?.currentAddressManual : getAddress(item?.currentAddress) })}
                    {rowData({ labelEN: "អុីម៉ែល", labelKH: "អុីម៉ែល", value: item?.email })}
                    {rowData({ labelEN: "លេខទូរសព្ទ", labelKH: "លេខទូរសព្ទ", value: item?.phoneNumber })}
                    {rowData({ labelEN: "អត្តសញ្ញាណប័ណ្ណ", labelKH: "អត្តសញ្ញាណប័ណ្ណ", value: item?.idCardNumber })}
                    {rowData({ labelEN: "កាលបរិច្ឆេទផុតកំណត់អត្តសញ្ញាណប័ណ្ណ", labelKH: "កាលបរិច្ឆេទផុតកំណត់អត្តសញ្ញាណប័ណ្ណ", value: item?.idCardExpirationDate ? moment(item?.idCardExpirationDate).format(c.excelDateOnlyTextFormat) : "" })}
                    {rowData({ labelEN: "លិខិតឆ្លងដែន", labelKH: "លិខិតឆ្លងដែន", value: item?.passportNumber })}
                    {rowData({ labelEN: "កាលបរិច្ឆេទផុតកំណត់លិខិតឆ្លងដែន", labelKH: "កាលបរិច្ឆេទផុតកំណត់លិខិតឆ្លងដែន", value: item?.passportExpirationDate ? moment(item?.passportExpirationDate).format(c.excelDateOnlyTextFormat) : "" })}
                </SimpleGrid>
                <SimpleGrid columns={[1, 2, 3]} spacing={2} p="26px" pt="0">
                    {rowData({ labelEN: "ឯកសារអត្តសញ្ញាណប័ណ្ណ", labelKH: "ឯកសារអត្តសញ្ញាណប័ណ្ណ", value: viewFile(initData?.documentIDCard) })}
                    {rowData({ labelEN: "ឯកសារលិខិតឆ្លងដែន", labelKH: "ឯកសារលិខិតឆ្លងដែន", value: viewFile(initData?.documentPassport) })}
                </SimpleGrid>
                
                
            </>
        )
    }

    function geoObjectToValue(obj) {
        return JSON.stringify({
            province: obj?.province?.value || (obj?.province || ""),
            district: obj?.district?.value || (obj?.district || ""),
            commune: obj?.commune?.value || (obj?.commune || ""),
            village: obj?.village?.value || (obj?.village || ""),
            streetNumber: obj?.streetNumber || "",
            houseNumber: obj?.houseNumber || ""
        })
    }

    return (
        <>

            <Box mt="3">
                <Flex mt="4" mb="4" alignItems="center">
                    <Text fontSize="xl" fontWeight="bold" color="#c0392b">១. ព័ត៌មានផ្ទាល់ខ្លួន</Text>

                    {!isEditing && <Button
                        variant="outline"
                        size="sm"
                        ml="4"
                        borderRadius={"0px !important"}
                        leftIcon={<FontAwesomeIcon icon={faPenSquare} style={{ fontSize: 14 }} />}
                        onClick={() => onOpen()}
                        colorScheme='whatsapp'
                    >
                        {t(`Edit`)}
                    </Button>}
                </Flex>

                {
                    !isEditing && <>
                        {displayPersonalInfo()}
                    </>
                }

                
                    <Modal scrollBehavior="inside" size="2xl" isOpen={isOpen} onClose={onClose}>
                        <ModalOverlay
                            backdropFilter='auto'
                            bg='blackAlpha.300'
                            backdropBlur='2px'
                        />
                        <Formik
                            initialValues={{
                                govtNumber: initData?.govtNumber || "",
                                mefNumber: initData?.mefNumber || "",
                                fullName: initData?.fullName || "",
                                fullNameLatin: initData?.fullNameLatin || "",
                                gender: initData?.gender || "",
                                dob: initData?.dob || "",
                                maritalStatus: initData?.maritalStatus || "",
                                pob: initData?.pob ? getJsonGeoData(initData?.pob) : null,
                                isPobManual: initData?.isPobManual || false,
                                pobManual: initData?.pobManual || "",
                                currentAddress: initData?.currentAddress ? getJsonGeoData(initData?.currentAddress) : null,
                                isCurrentAddressManual: initData?.isCurrentAddressManual || false,
                                currentAddressManual: initData?.currentAddressManual || "",
                                email: initData?.email || "",
                                phoneNumber: initData?.phoneNumber || "",
                                idCardNumber: initData?.idCardNumber || "",
                                idCardExpirationDate: initData?.idCardExpirationDate || "",
                                passportNumber: initData?.passportNumber || "",
                                passportExpirationDate: initData?.passportExpirationDate || "",
                                documentIDCard: initData?.documentIDCard||"",
                                documentPassport: initData?.documentPassport||""
                            }}
                            validationSchema={signUpSchema}
                            onSubmit={(values) => {
                                updateEmployee({
                                    variables: {
                                        id,
                                        personalData: JSON.stringify({
                                            govtNumber: values?.govtNumber,
                                            mefNumber: values?.mefNumber,
                                            fullName: values?.fullName,
                                            fullNameLatin: values?.fullNameLatin,
                                            gender: values?.gender,
                                            dob: values?.dob || null,
                                            maritalStatus: values?.maritalStatus,
                                            pob: values?.pob ? geoObjectToValue(values?.pob) : "",
                                            isPobManual: values?.isPobManual,
                                            pobManual: values?.pobManual,
                                            currentAddress: values?.currentAddress ? geoObjectToValue(values?.currentAddress) : "",
                                            isCurrentAddressManual: values?.isCurrentAddressManual,
                                            currentAddressManual: values?.currentAddressManual,
                                            email: values?.email,
                                            phoneNumber: values?.phoneNumber,
                                            idCardNumber: values?.idCardNumber,
                                            idCardExpirationDate: values?.idCardExpirationDate || null,
                                            passportNumber: values?.passportNumber,
                                            passportExpirationDate: values?.passportExpirationDate || null,
                                            documentIDCard: values?.documentIDCard||"",
                                            documentPassport: values?.documentPassport||""
                                        })
                                    }
                                })
                            }}
                        >
                            {(props) => (
                                <Form>
                                    <ModalContent borderRadius={c.borderRadius}>
                                        <ModalHeader>{t("Edit")}</ModalHeader>
                                        <ModalCloseButton />
                                        <ModalBody>

                                            <Box borderRadius={c.borderRadius}>
                                                <SimpleGrid columns={[1, 2]} spacing={2}>
                                                    <TextFormField
                                                        {...props}
                                                        label="អត្តលេខមន្ត្រីរាជការ"
                                                        name="govtNumber"
                                                    />
                                                    <TextFormField
                                                        {...props}
                                                        label="លេខប័ណ្ណសម្គាល់មន្ត្រីកសហវ"
                                                        name="mefNumber"
                                                    />
                                                </SimpleGrid>
                                                <SimpleGrid columns={[1, 2]} spacing={2}>
                                                    {/* <TextFormField
                                                        {...props}
                                                        label="គោត្តនាម និងនាម"
                                                        name="fullName"
                                                    /> */}
                                                    <TextFormFieldReadOnly
                                                        {...props}
                                                        label="គោត្តនាម និងនាម"
                                                        name="fullName"
                                                        placeholder={initData?.fullName||""}
                                                        disabled={true}
                                                    />
                                                    {/* <TextFormField
                                                        {...props}
                                                        label="គោត្តនាម និងនាមជាអក្សរឡាតាំង"
                                                        name="fullNameLatin"
                                                        required
                                                    /> */}
                                                    <TextFormFieldReadOnly
                                                        {...props}
                                                        label="គោត្តនាម និងនាមជាអក្សរឡាតាំង"
                                                        name="fullNameLatin"
                                                        placeholder={initData?.fullNameLatin?.toUpperCase()||""}
                                                        disabled={true}
                                                    />
                                                </SimpleGrid>
                                                <SimpleGrid columns={[1, 2]} spacing={2}>


                                                    <RadioFormField
                                                        {...props}
                                                        label="ភេទ"
                                                        name="gender"
                                                        radios={[
                                                            { label: "Male", value: "male" },
                                                            { label: "Female", value: "female" }
                                                        ]}
                                                        initValue={initData?.gender}
                                                    />

                                                    <RadioFormField
                                                        {...props}
                                                        label="ស្ថានភាពគ្រូសារ"
                                                        name="maritalStatus"
                                                        radios={[
                                                            { label: "Single", value: "single" },
                                                            { label: "Married", value: "married" },
                                                            { label: "Divorced", value: "divorced" }
                                                        ]}
                                                        initValue={initData?.maritalStatus}
                                                    />
                                                </SimpleGrid>
                                                <SimpleGrid columns={[1, 2]} spacing={2}>
                                                    <DateInputFormField
                                                        {...props}
                                                        label="ថ្ងៃខែឆ្នាំកំណើត"
                                                        name="dob"
                                                        initialDate={initData?.dob}
                                                    />

                                                </SimpleGrid>
                                                <SimpleGrid columns={[1, 2]} spacing={2}>

                                                    {!isPobManual && <GeoFormField
                                                        {...props}
                                                        name="pob"
                                                        label="កន្លែងកំណើត"
                                                        buttomComponent={
                                                            <CheckboxFormField
                                                                {...props}
                                                                label="Input"
                                                                name="isPobManual"
                                                                callbackValueChange={e => setIsPobManual(e)}
                                                            // values={isPobManual}
                                                            />
                                                        }
                                                    />}

                                                    {isPobManual && <TextAreaFormField
                                                        {...props}
                                                        label="កន្លែងកំណើត"
                                                        name="pobManual"
                                                        required={true}
                                                        buttomComponent={
                                                            <CheckboxFormField
                                                                {...props}
                                                                label="Input"
                                                                name="isPobManual"
                                                                callbackValueChange={e => setIsPobManual(e)}
                                                            // values={isPobManual}
                                                            />
                                                        }
                                                    />}

                                                    {!isCurrentAddressManual && <GeoFormField
                                                        {...props}
                                                        name="currentAddress"
                                                        label="អាសយដ្ឋានបច្ចុប្បន្ន"
                                                        buttomComponent={
                                                            <CheckboxFormField
                                                                {...props}
                                                                label="Input"
                                                                name="isCurrentAddressManual"
                                                                callbackValueChange={e => setCurrentAddressManual(e)}
                                                            // values={isCurrentAddressManual}
                                                            />
                                                        }
                                                    />}

                                                    {isCurrentAddressManual && <TextAreaFormField
                                                        {...props}
                                                        label="អាសយដ្ឋានបច្ចុប្បន្ន"
                                                        name="currentAddressManual"
                                                        buttomComponent={
                                                            <CheckboxFormField
                                                                {...props}
                                                                label="Input"
                                                                name="isCurrentAddressManual"
                                                                callbackValueChange={e => setCurrentAddressManual(e)}
                                                            // values={isCurrentAddressManual}
                                                            />
                                                        }
                                                    />}
                                                </SimpleGrid>
                                                <SimpleGrid columns={[1, 2]} spacing={2}>
                                                    <TextFormField
                                                        {...props}
                                                        label="អុីម៉ែល"
                                                        name="email"
                                                    />
                                                    <TextFormField
                                                        {...props}
                                                        label="លេខទូរសព្ទ"
                                                        name="phoneNumber"
                                                    />
                                                </SimpleGrid>
                                                <SimpleGrid columns={[1, 2]} spacing={2}>
                                                    <TextFormField
                                                        {...props}
                                                        label="អត្តសញ្ញាណប័ណ្ណ"
                                                        name="idCardNumber"
                                                    />
                                                    <DateInputFormField
                                                        {...props}
                                                        label="កាលបរិច្ឆេទផុតកំណត់អត្តសញ្ញាណប័ណ្ណ"
                                                        name="idCardExpirationDate"
                                                        initialDate={initData?.idCardExpirationDate}
                                                    />
                                                </SimpleGrid>
                                                <SimpleGrid columns={[1, 2]} spacing={2}>
                                                    <TextFormField
                                                        {...props}
                                                        label="លិខិតឆ្លងដែន"
                                                        name="passportNumber"
                                                    />
                                                    <DateInputFormField
                                                        {...props}
                                                        label="កាលបរិច្ឆេទផុតកំណត់លិខិតឆ្លងដែន"
                                                        name="passportExpirationDate"
                                                        initialDate={initData?.passportExpirationDate}
                                                    />
                                                </SimpleGrid>
                                                <SimpleGrid columns={[1, 2]} spacing={2}>
                                                <PreUploadFileFormField
                                                    {...props}
                                                    label={"ឯកសារអត្តសញ្ញាណប័ណ្ណ"}
                                                    name={"documentIDCard"}
                                                />
                                                <PreUploadFileFormField
                                                    {...props}
                                                    label={"ឯកសារលិខិតឆ្លងដែន"}
                                                    name={"documentPassport"}
                                                />
                                                </SimpleGrid>
                                            </Box>
                                        </ModalBody>
                                        <ModalFooter bg="rgba(236, 240, 241,0.5)">
                                            <Button
                                                borderRadius={c.borderRadius}
                                                colorScheme='green'
                                                type="submit"
                                                isLoading={loading}
                                                loadingText={t("Save")}
                                                spinnerPlacement="start"
                                            >
                                                {t("Save")}
                                            </Button>
                                        </ModalFooter>
                                    </ModalContent>
                                </Form>
                            )}
                        </Formik>
                    </Modal>
            </Box>
        </>
    );
}

export default PersonalInformation;