import React from 'react';

import { Skeleton, Flex } from '@chakra-ui/react';
import { Tr, Td } from 'react-super-responsive-table';

function LoadingTable(props) {
	return (
		<>
			{Array(props?.row ? props.row : 10)
				.fill(0)
				.map((ei, i) => {
					return (
						<Tr className="loading-responsive-tr" key={`table-row-item-${i}`}>
							{Array(props?.column ? props.column : 0)
								.fill(0)
								.map((ej, j) => (
									<Td p="2px" key={`table-data-item-${j}`}>
										{
											<Skeleton m="8px">
												<Flex flex="1" minH="28px"></Flex>
											</Skeleton>
										}
									</Td>
								))}
						</Tr>
					);
				})}
		</>
	);
}

export default LoadingTable;
