/* eslint-disable react-hooks/exhaustive-deps */
import { gql, useLazyQuery } from '@apollo/client';
import { useContext, createContext } from 'react';

const Q_GET_REQUEST = gql`
	query get {
		getCountAgentTerminationRequest {
			countPrimaryReview
			countSecondaryReview
			countCompleted
			countAll
		}
	}
`;

const TerminationRequestCountContext = createContext();

export const TerminationRequestCountProvider = ({ children }) => {
	const [load, { data }] = useLazyQuery(Q_GET_REQUEST);

	const loadCountTerminationRequest = () => {
		load();
	};

	return (
		<TerminationRequestCountContext.Provider
			value={{
				loadCountTerminationRequest,
				countPrimaryReview: parseInt(data?.getCountAgentTerminationRequest?.countPrimaryReview),
				countSecondaryReview: parseInt(data?.getCountAgentTerminationRequest?.countSecondaryReview),
				countCompleted: parseInt(data?.getCountAgentTerminationRequest?.countCompleted),
				countAll: parseInt(data?.getCountAgentTerminationRequest?.countAll),
			}}
		>
			{children}
		</TerminationRequestCountContext.Provider>
	);
};

export const useTerminationRequestCount = () => {
	return useContext(TerminationRequestCountContext);
};
