import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Container, Center, Box, Flex, Text, useToast, Button, Textarea, Input } from "@chakra-ui/react";
import BreadcrumbComponent from "../../../components/BreadcrumbComponent/BreadcrumbComponent";
import JoditEditor from "jodit-react";
import { useLocation, useHistory } from "react-router-dom";
import '../style.css'
import c from "../../../constant"
import { gql, useMutation } from '@apollo/client';


const CREATE_PUBLIC_FORM_MUTATION = gql`
    mutation (
        $title: String!
        $header: String!
        $emailContent: String
        $formSetting: JSONObject
    ){
        createPublicForm(publicFormInput:{
            title: $title
            header: $header
            emailContent: $emailContent
            formSetting: $formSetting
        })
    }
`


function PublicFormList() {
    const toast = useToast()
    const { t } = useTranslation();
    const location = useLocation();
    const history = useHistory();
    const urlParams = new URLSearchParams(location.search);
    const [formSettingError, setFormSettingError] = useState(false)
    const [formSetting, setFormSetting] = useState([])
    const [formTitle, setFormTitle] = useState("")
    const [richText, setRichText] = useState("")
    const [richTextEmail, setRichTextEmail] = useState("")
    const [createPublicForm, {loading, error, data}] = useMutation(
        CREATE_PUBLIC_FORM_MUTATION
    );
    useEffect(()=> {
        if(data?.createPublicForm){
            toast({
                title: "Public form created.",
                status: "success",
                duration: 5000,
                isClosable: true,
                position: "top-right"
            })
            setTimeout(() => {
                history.push(`/website/public-form-list`);
            }, 200);
        }
    },[data])

    function isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    return (
        <>
            <Container maxW="container.xl" mt="16px" mb="16px">
                <BreadcrumbComponent
                    list={[
                        {
                            name: "WEBSITE",
                            path: "/website",
                        },
                        {
                            name: "Public Form",
                            path: "/website/public-form-list",
                        },
                        {
                            name: "Add Public Form",
                            path: "#",
                        },
                    ]}
                />
                <Center mt="5">
                    <Box
                        w="100%"
                        bg="white"
                        borderRadius={c.borderRadius}
                        border="1px solid #dbdbdb"
                        boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
                        pb="32px"
                    >
                        <Flex bg="#FAFAFA" p="16px" pt="8px" pb="8px" mb="16px" justifyContent="space-between" borderBottom="1px solid #dbdbdb">
                            <Text
                                fontSize="x-large"
                                fontWeight="bold"
                            >
                                {t("Add Public Form")}
                            </Text>
                        </Flex>
                        <Box m="4">
                            <JoditEditor
                            onChange={newContent => {
                                setRichText(newContent)
                            }}
                        />
                        </Box>

                        <Box m="4">
                            <Text>{t("Email")}</Text>
                            <JoditEditor
                                onChange={newContent => {
                                    setRichTextEmail(newContent)
                                }}
                            />
                        </Box>

                        <Box m="4">
                        <Input placeholder='Title' onChange={e=>{
                            setFormTitle(e?.target?.value)
                        }} />
                        <Box mt="6">
                            <a target="_blank" href="https://formbuilder.online/docs/formBuilder/demos/react/">https://formbuilder.online/docs/formBuilder/demos/react/</a>
                        </Box>
                        <Textarea minH="300px" mt="2" placeholder='JSON FORM SETTING' onChange={e=>{
                            setFormSetting(JSON?.parse(isJson(e?.target?.value)?e?.target?.value:null))
                            setFormSettingError(!(isJson(e?.target?.value)))
                        }}/>
                        {formSettingError&&<Text color="red">Form setting error</Text>}
                        </Box>

                        <Flex m="4">
                            <Flex w="100%" justifyContent="flex-end">
                                <Button
                                    id="login-btn"
                                    variant="solid"
                                    size="md"
                                    h="42px"
                                    w="128px"
                                    type="submit"
                                    borderRadius={c.borderRadius}
                                    mt="8"
                                    colorScheme='blue'
                                    isLoading={loading}
                                    onClick={()=>{
                                        console.log("rich text:", richText)
                                        console.log("formSetting:", formSetting)
                                        console.log("formTitle:", formTitle)
                                        createPublicForm({
                                            variables:{
                                                title: formTitle,
                                                header: richText,
                                                formSetting: formSetting,
                                                emailContent: richTextEmail
                                            }
                                        })
                                    }}
                                >
                                    {t("Save")}
                                </Button>
                            </Flex>
                        </Flex>
                    </Box>
                </Center>
            </Container>
        </>
    );
}

export default PublicFormList;
