import React, { useState, useEffect } from "react";
import { Box, FormControl, FormLabel, FormHelperText } from "@chakra-ui/react";
import { Field } from "formik";
import "@amir04lm26/react-modern-calendar-date-picker/lib/DatePicker.css";
import DatePicker from "@amir04lm26/react-modern-calendar-date-picker";
import moment from "moment";
import "moment/locale/en-gb";
import useStore from "../../store";
import { useTranslation } from "react-i18next";

const DateFormField = props => {
	const { t } = useTranslation();
	const { currentLang } = useStore((state) => state.currentLang);
	moment.locale("en-gb")

	const now = moment().toDate()
	const {
		name,
		label,
		required,
		initialDate
	} = props;

	const formatInputValue = () => {
		if (!selectedDay) return '';

		const dateFormat = moment({
			year: selectedDay.year,
			day: selectedDay.day,
			month: selectedDay.month - 1
		}).format("D / M / YYYY");
		return dateFormat;
	};

	

	// useEffect(() => {
	// 	if (selectedDay) {

	// 	}
	// }, [selectedDay])

	const minusYears = 0

	const maxYear = moment(now).subtract(minusYears, 'years').format("yyyy")
	const month = moment(now).subtract(minusYears, 'years').format("M")
	const day = moment(now).subtract(minusYears, 'years').format("D")

	const maximumDate = {
		year: parseInt(maxYear),
		month: parseInt(month),
		day: parseInt(day)
	}

	let defaultValue = {
		year: parseInt(maxYear),
		month: parseInt(month),
		day: parseInt(day)
	};


	if(initialDate){
		const year = moment(initialDate).format("yyyy")
		const month = moment(initialDate).format("M")
		const day = moment(initialDate).format("D")
		defaultValue = {
			year: parseInt(year),
			month: parseInt(month),
			day: parseInt(day)
		}
	}

	const [selectedDay, setSelectedDay] = useState(defaultValue);
	const [valueChanged, setValueChanged] = useState(initialDate?true:false);

	return (
		<Field name={name}>
			{({ field, meta, form }) => (
				<Box mb="4" className="dp-container">
					<FormControl id={name} >
						<FormLabel>{t(label)}{required && <span style={{ color: "red" }}>*</span>}</FormLabel>
						<DatePicker
							// maximumDate={maximumDate}
							value={selectedDay}
							onChange={e => {
								const dateFormat = moment({
									year: e.year,
									day: e.day,
									month: e.month - 1
								}).toDate()
								form.setFieldValue(name, dateFormat)
								setSelectedDay(e)
								setValueChanged(true)
							}}
							inputPlaceholder={label} // placeholder
							formatInputText={formatInputValue} // format value
							inputClassName={valueChanged?"my-custom-date-picker":"my-custom-date-picker-null"} // custom class
							shouldHighlightWeekends
							locale={currentLang === 'kh' ? myCustomLocale : 'en'}
							
						/>
						{meta.touched && meta.error && (
							<FormHelperText
								id="error-message-password"
								color="red.400"
								fontSize="12px"
							>
								{t(name)}{" "}
								{t("is")}{" "}
								{t(meta.error)}
							</FormHelperText>
						)}
					</FormControl>
				</Box>
			)}
		</Field>
	);
};

export default DateFormField;

const myCustomLocale = {
	// months list by order
	months: [
		'មករា',
		'ខែកុម្ភៈ',
		'មីនា',
		'មេសា',
		'ឧសភា',
		'មិថុនា',
		'កក្កដា',
		'សីហា',
		'កញ្ញា',
		'តុលា',
		'វិច្ឆិកា',
		'ធ្នូ',
	],

	// week days by order
	weekDays: [
		{
			name: 'អាទិត្យ', // used for accessibility 
			short: 'អាទិត្យ', // displayed at the top of days' rows
			isWeekend: true, // is it a formal weekend or not?
		},
		{
			name: 'ច័ន្ទ',
			short: 'ច័ន្ទ',
		},
		{
			name: 'អង្គារ',
			short: 'អង្គារ',
		},
		{
			name: 'ពុធ',
			short: 'ពុធ',
		},
		{
			name: 'ព្រហស្បតិ៍',
			short: 'ព្រហស្បតិ៍',
		},
		{
			name: 'សុក្រ',
			short: 'សុក្រ',
		},
		{
			name: 'សៅរ៍',
			short: 'សៅរ៍',
			isWeekend: true,
		},
	],

	// just play around with this number between 0 and 6
	weekStartingIndex: 0,

	// return a { year: number, month: number, day: number } object
	getToday(gregorainTodayObject) {
		return gregorainTodayObject;
	},

	// return a native JavaScript date here
	toNativeDate(date) {
		return new Date(date.year, date.month - 1, date.day);
	},

	// return a number for date's month length
	getMonthLength(date) {
		return new Date(date.year, date.month, 0).getDate();
	},

	// return a transformed digit to your locale
	transformDigit(digit) {
		return digit;
	},

	// texts in the date picker
	nextMonth: 'Next Month',
	previousMonth: 'Previous Month',
	openMonthSelector: 'Open Month Selector',
	openYearSelector: 'Open Year Selector',
	closeMonthSelector: 'Close Month Selector',
	closeYearSelector: 'Close Year Selector',
	defaultPlaceholder: 'Select...',

	// for input range value
	from: 'from',
	to: 'to',


	// used for input value when multi dates are selected
	digitSeparator: ',',

	// if your provide -2 for example, year will be 2 digited
	yearLetterSkip: 0,

	// is your language rtl or ltr?
	isRtl: false,
}