import React, {useEffect} from "react";
import { useTranslation } from "react-i18next";
import {
    Container, Box, Flex, Text, Center, Spinner
} from "@chakra-ui/react";
import BreadcrumbComponent from "../../components/BreadcrumbComponent/BreadcrumbComponent";
import c from "../../constant"
import { useLocation } from "react-router-dom";
import moment from "moment";
import 'moment/locale/en-gb';
import { gql, useLazyQuery } from '@apollo/client';

const QUERY_NUMEBR = gql`
    query get($id: String!){
        getPersonalCompanySubmissionsProposalNumberById(id:$id)
    }
`

function AddCompanyProposalSuccess() {
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    moment.locale("en-gb")
    const { t } = useTranslation();
    const number = urlParams.get('number') ? urlParams.get('number') : null;
    const [loadNumber, { error, loading, data }] = useLazyQuery(QUERY_NUMEBR)

    useEffect(()=>{
        loadNumber({
            variables:{
                id:number
            }
        })
    },[])
    
    return (
        <>
            <Container maxW="container.xl" mt="16px" mb="16px">
                <BreadcrumbComponent
                    list={[
                        {
                            name: "PROPOSAL",
                            path: "/company-submission-proposal?page=1",
                        },
                        {
                            name: "Add proposal",
                            path: "/company-submission-proposal/add",
                        },
                        {
                            name: "Success",
                            path: "#",
                        },
                    ]}
                />
                <Center mt="5">
                    <Box
                        w="100%"
                        bg="white"
                        borderRadius={c.borderRadius}
                        border="1px solid #dbdbdb"
                        boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
                        pb="32px"
                    >
                        <Flex bg="#FAFAFA" p="16px" pt="8px" pb="8px" mb="16px" justifyContent="space-between" borderBottom="1px solid #dbdbdb">
                            <Text
                                fontSize="x-large"
                                fontWeight="bold"
                            >
                                {t("Add proposal success")}
                            </Text>
                        </Flex>

                        <Box p="16px" maxW="480px">
                            <Text>{t("We have received your proposal")}</Text>
                            <Text>{t("Your proposal number is")} : 
                            {loading&&<Spinner
                                thickness='4px'
                                speed='0.65s'
                                emptyColor='gray.200'
                                color='blue.500'
                                size='md'
                            />}
                            <b style={{fontSize:24, color:"#27ae60", marginLeft:"8px"}}>{data?.getPersonalCompanySubmissionsProposalNumberById}</b>
                            </Text>
                        </Box>
                    </Box>
                </Center>
            </Container>
        </>
    );
}

export default AddCompanyProposalSuccess;
