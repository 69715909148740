import React, { useEffect } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, Button, Box, Flex } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import TextFormField from '../form/TextFormField';
import CheckBoxFormField from '../form/CheckBoxFormField';
import RadioFormField from '../form/RadioFormField';
import moment from 'moment';
import c from '../../constant';
import * as Yup from 'yup';
import 'moment/locale/en-gb';

const validationSchema = Yup.object().shape({
	code: Yup.number().nullable(),
	name: Yup.string().nullable(),
	idCardNumber: Yup.string().nullable(),
	payment: Yup.string().nullable(),
	working: Yup.string().nullable(),
	unreadOnly: Yup.boolean().nullable(),
});

function AgentFilter({
	isOpen = false,
	onClose = () => null,
	callback = () => null,
	code = null,
	name = null,
	idCardNumber = null,
	payment = null,
	working = null,
	read = null,
	status = null,
}) {
	const { t } = useTranslation();

	useEffect(() => {
		moment.locale('en-gb');
	}, []);

	return (
		<>
			<Modal borderRadius={c.borderRadius} isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent borderRadius={c.borderRadius}>
					<ModalHeader>{t('Filter')}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Formik
							initialValues={{
								code: code,
								name: name,
								idCardNumber: idCardNumber,
								payment: payment,
								working: working,
								unreadOnly: read,
								status: status,
							}}
							validationSchema={validationSchema}
							onSubmit={(values) => {
								let url = '?page=1';
								if (values.code) {
									url += `&code=${values.code}`;
								}
								if (values.name) {
									url += `&name=${values.name}`;
								}
								if (values.idCardNumber) {
									url += `&idCardNumber=${values.idCardNumber}`;
								}
								if (values.payment) {
									url += `&payment=${values.payment}`;
								}
								if (values.working) {
									url += `&working=${values.working}`;
								}
								if (values.unreadOnly) {
									url += `&read=false`;
								}
								if (status) {
									url += `&status=${status}`;
								}
								callback(url);
								onClose();
							}}
						>
							{(props) => (
								<Box pb="4">
									<Form>
										<TextFormField {...props} label="Registration number" name="code" type="number" />
										<TextFormField {...props} label="Name" name="name" />
										<TextFormField {...props} label="ID card number" name="idCardNumber" />
										<RadioFormField
											label="Payment"
											name="payment"
											radios={[
												{ label: 'Paid', value: 'APPROVED' },
												{ label: 'Pending for payment', value: 'PENDING' },
												{ label: 'All', value: 'ALL' },
											]}
											initValue={payment}
										/>
										<RadioFormField
											label="Working"
											name="working"
											radios={[
												{ label: 'Partner', value: 'PARTNER' },
												{ label: 'Non-Partner', value: 'NONPARTNER' },
												{ label: 'All', value: 'ALL' },
											]}
											initValue={working}
										/>
										<CheckBoxFormField {...props} label="Show unread only" name="unreadOnly" />
										<Flex mt="32px" justifyContent="flex-end">
											<Button
												id="login-btn"
												variant="solid"
												size="md"
												h="42px"
												w="128px"
												type="submit"
												borderRadius={c.borderRadius}
												color="#3498db"
											>
												{t('Apply')}
											</Button>
											<Button
												size="md"
												h="42px"
												ml={4}
												w="128px"
												onClick={() => {
													callback('?page=1');
													onClose();
												}}
												variant="ghost"
											>
												{t('Clear')}
											</Button>
										</Flex>
									</Form>
								</Box>
							)}
						</Formik>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
}

export default AgentFilter;
