/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import {
	Button,
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	useDisclosure,
} from '@chakra-ui/react';
import { gql, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { useTerminationRequest } from '../../../../contexts/termination-request';
import c from '../../../../constant';
import { useTerminationRequestCount } from '../../../../contexts/termination-request-count';

const M_SUBMIT_APPROVAL = gql`
	mutation submit($id: String!, $grade: TerminationReqeustApprovalGrade!) {
		submitCandidatesApproval(id: $id, grade: $grade)
	}
`;

export default function TerminationRequestCandidatesApproval({ id = null, grade = null, candidates = [] }) {
	const { t } = useTranslation();
	const { loadRequest } = useTerminationRequest();
	const { loadCountTerminationRequest } = useTerminationRequestCount();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [required, setRequired] = useState(true);
	const [submitApproval, { loading, data }] = useMutation(M_SUBMIT_APPROVAL);

	useEffect(() => {
		setRequired(
			candidates?.reduce((p, c) => {
				if (c?.status === null) {
					return true;
				} else {
					return p;
				}
			}, false)
		);
	}, [candidates]);

	useEffect(() => {
		if (data?.submitCandidatesApproval) {
			onClose();
			loadRequest(id);
			loadCountTerminationRequest();
		}
	}, [data]);

	return (
		<>
			<Button
				isLoading={loading}
				isDisabled={required}
				onClick={onOpen}
				rightIcon={<FontAwesomeIcon icon={faCheck} style={{ fontSize: 16 }} />}
				colorScheme="gray"
				variant="solid"
				size="sm"
				borderRadius={c.borderRadius}
				border="1px solid #bdc3c7"
				ml={2}
			>
				{t('Done')}
			</Button>
			<AlertDialog closeOnEsc={false} closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							{t('Submit Approval')}
						</AlertDialogHeader>
						<AlertDialogBody>{t('Are you sure?')}</AlertDialogBody>
						<AlertDialogFooter>
							<Button mr={4} isLoading={loading} onClick={onClose}>
								{t('Cancel')}
							</Button>
							<Button
								isLoading={loading}
								colorScheme="green"
								onClick={() => {
									const variables = { id, grade };
									submitApproval({ variables });
								}}
							>
								{t('Yes')}
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</>
	);
}
