/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Box, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import InsuranceLicense from './InsuranceLicense';

const InsuranceLicenses = ({ companyId = null, licenseTypes = [] }) => {
	const { t } = useTranslation();

	return (
		<>
			<Box mt={8}>
				<Box mt={4}>
					<Tabs defaultIndex={0} className="agent_tab hidden-print" variant="enclosed">
						<TabList>
							{licenseTypes.map((type) => (
								<Tab>{t(type?.label)}</Tab>
							))}
						</TabList>
						<TabPanels>
							{licenseTypes.map((type) => (
								<TabPanel p={0}>
									<Box pl={4} pr={4} pt={4} pb={6} border="1px solid #E2E8F0">
										<InsuranceLicense companyId={companyId} licenseType={type?.value} />
									</Box>
								</TabPanel>
							))}
						</TabPanels>
					</Tabs>
				</Box>
				<Box className="show-only-printing" mt={1}>
					{licenseTypes.map((type) => (
						<Box pl={4} pr={4} pt={2} pb={6} mt={6} border="1px solid #E2E8F0">
							<Box mb={2}>
								<Text fontWeight="bold" fontSize="xl">
									{t(type?.label)}
								</Text>
							</Box>
							<InsuranceLicense companyId={companyId} licenseType={type?.value} />
						</Box>
					))}
				</Box>
			</Box>
		</>
	);
};

export default InsuranceLicenses;
