/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Box, Flex, Skeleton } from '@chakra-ui/react';

function LoadingCompanyInformation() {
	return (
		<>
			<Flex w="100%">
				<Box width="100%">
					<Flex alignItems="center" mt="2">
						<Skeleton width="124px" height="21px" mr={4} />
						<Skeleton width="240px" height="21px" mr={0} />
					</Flex>
					<Flex alignItems="center" mt="2">
						<Skeleton width="124px" height="21px" mr={4} />
						<Skeleton width="240px" height="21px" mr={0} />
					</Flex>
					<Flex alignItems="center" mt="2">
						<Skeleton width="124px" height="21px" mr={4} />
						<Skeleton width="240px" height="21px" mr={0} />
					</Flex>
					<Flex alignItems="center" mt="2">
						<Skeleton width="124px" height="21px" mr={4} />
						<Skeleton width="240px" height="21px" mr={0} />
					</Flex>
					<Flex alignItems="center" mt="2">
						<Skeleton width="124px" height="21px" mr={4} />
						<Skeleton width="240px" height="21px" mr={0} />
					</Flex>
					<Flex alignItems="center" mt="2">
						<Skeleton width="124px" height="21px" mr={4} />
						<Skeleton width="240px" height="21px" mr={0} />
					</Flex>
					<Flex alignItems="center" mt="2">
						<Skeleton width="124px" height="21px" mr={4} />
						<Skeleton width="240px" height="21px" mr={0} />
					</Flex>
				</Box>
			</Flex>
		</>
	);
}
export default LoadingCompanyInformation;
