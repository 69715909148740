/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
	Container,
	Box,
	Flex,
	Text,
	Table,
	Thead,
	Tbody,
	Tfoot,
	Tr,
	Th,
	Td,
	Center,
	useToast,
	Button,
	AlertDialog,
	AlertDialogOverlay,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogBody,
	AlertDialogFooter,
	useDisclosure,
	Checkbox,
	Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
	Input
} from '@chakra-ui/react';
import { faCheckCircle, faClock, faEnvelopeOpenText, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingTable from '../../../components/Table/Loading';
import BreadcrumbComponent from '../../../components/BreadcrumbComponent/BreadcrumbComponent';
import ReactPaginate from 'react-paginate';
import useMoment from '../../../hooks/useMoment';
import c from '../../../constant';
import JoditEditor from "jodit-react";

const Q_MAIL = gql`
	query get($offset: Float, $limit: Float) {
		getEmailList(pagerInput: { limit: $limit, offset: $offset }) {
			totalPages
			totalRows
			emails {
				id
				createdAt
				send
				mailOptions
			}
		}
	}
`;

const M_DELETE_EMAIL = gql`
	mutation delete($id: String!) {
		deleteEmailById(id: $id)
	}
`;


const MUTATION_SEND_MAIL = gql`
	mutation send(
		$html: String!
		$subject: String!
		$mailTo: String!
	){
		adminSendEmail(
			mailTo:$mailTo,
			subject:$subject,
			html:$html
		)
	}
`

let workingId = null;

function MailList() {
	const toast = useToast();
	const location = useLocation();
	const history = useHistory();
	const urlParams = new URLSearchParams(location.search);
	const currentPage = urlParams.get('page') ? urlParams.get('page') : 1;
	const more = urlParams.get('more') ? urlParams.get('more') : null;
	const { t } = useTranslation();
	const { toDateString } = useMoment();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [loadMail, { loading: loadingMail, error: errorLoadMail, data: dataLoadMail }] = useLazyQuery(Q_MAIL);
	const [deleteEmail, { loading: loadingDeleteMail, error: errorDeleteMail, data: dataDeleteMail }] = useMutation(M_DELETE_EMAIL);
	const [sendMailMutation, { loading: loadingSendMail, error: errorSendMail, data: dateSendMail }] = useMutation(MUTATION_SEND_MAIL);
	const editor = useRef(null)
	const [toMail, setToMail] = useState("")
	const [subject, setSubject] = useState("")
	const [content, setContent] = useState("")
	const config = useMemo(
        () => ({
            readonly: false, 
        }),
        []
    );

	useEffect(() => {
		load();
	}, []);

	useEffect(() => {
		load();
	}, [more, currentPage]);

	useEffect(() => {
		if (errorLoadMail) {
			if (errorLoadMail?.graphQLErrors?.length > 0) {
				errorLoadMail?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(errorLoadMail?.message ? errorLoadMail?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [errorLoadMail]);

	useEffect(() => {
		if (dataDeleteMail) {
			onClose();
			load();
			toast({
				title: t('All tasks are completed'),
				status: 'success',
				duration: 5000,
				isClosable: true,
				position: 'bottom-right',
			});
		}
	}, [dataDeleteMail]);

	useEffect(() => {
		if (errorDeleteMail) {
			if (errorDeleteMail?.graphQLErrors?.length > 0) {
				errorDeleteMail?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(errorDeleteMail?.message ? errorDeleteMail?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [errorDeleteMail]);

	function load() {
		const offset = parseInt(currentPage ? (currentPage - 1) * (more === 'true' ? 300 : 10) : 0) || 0;
		loadMail({
			variables: {
				offset: offset < 0 ? 0 : offset,
				limit: more === 'true' ? 300 : 10,
			},
		});
	}

	function handlePageChanged({ selected }) {
		let url = `?page=${selected + 1}`;

		if (more !== null) {
			url += `&more=${more}`;
		}

		history.push(`/settings/email${url}`);
	}

	function handleMoreChanged() {
		let url = `?page=1`;

		url += `&more=${more === 'true' ? 'false' : 'true'}`;

		history.push(`/settings/email${url}`);
	}

	function handleRowClicked(id) {
		history.push(`/settings/email/content?id=${id}`);
	}

	function sendEmail(){
		sendMailMutation({
			variables:{
				mailTo:toMail,
				subject,
				html: content
			}
		})
	}

	useEffect(()=>{
		if(dateSendMail?.adminSendEmail){
			alert('Mail send successfully')
		}
	},[dateSendMail])

	useEffect(()=>{
		if(errorSendMail){
			alert('Mail send error')
		}
	},[errorSendMail])

	return (
		<>
			<Container maxW="container.xl" mt="16px" mb="16px">
				<BreadcrumbComponent
					list={[
						{
							name: 'Settings',
							path: '/settings',
						},
						{
							name: 'email',
							path: '#',
						},
					]}
				/>
				<Center mt="5">
					<Box
						w="100%"
						bg="white"
						borderRadius={c.borderRadius}
						border="1px solid #dbdbdb"
						boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
						pb="32px"
					>
						<Flex
							alignItems="center"
							justifyContent="space-between"
							bg="#FAFAFA"
							p="16px"
							pt="8px"
							pb="8px"
							mb="16px"
							borderBottom="1px solid #dbdbdb"
						>
							<Flex justifyContent="center" alignItems="center">
								<Text fontSize="x-large" fontWeight="bold" mr="4">
									{t('Emails')}
								</Text>
							</Flex>
						</Flex>

						<Accordion allowToggle>
							<AccordionItem>
							<h2>
								<AccordionButton>
								<Box as='span' flex='1' textAlign='left'>
									Send Mail
								</Box>
								<AccordionIcon />
								</AccordionButton>
							</h2>
							<AccordionPanel pb={4}>
								<>
									<Input placeholder='Mail To' type="email" onChange={e=>{setToMail(e.currentTarget.value)}} />
										<Input placeholder='Subject' onChange={e=>{setSubject(e.currentTarget.value)}}/>
										<JoditEditor
											ref={editor}
											value={content}
											config={config}
											tabIndex={1} // tabIndex of textarea
											onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
											onChange={newContent => {
												setContent(newContent)
											}}
										/>
										<Button isLoading={loadingSendMail} onClick={()=> sendEmail()}>Send</Button>
									</>
							</AccordionPanel>
							</AccordionItem>
						
						</Accordion>
						

						{loadingMail && (
							<Flex p={4}>
								<Table variant="simple">
									<LoadingTable column={6} />
								</Table>
							</Flex>
						)}
						{!loadingMail && dataLoadMail?.getEmailList?.emails?.length <= 0 && (
							<Flex direction="column" alignItems="center" justifyContent="center" pt={16} pl={4} pr={4} pb={16}>
								<FontAwesomeIcon
									icon={faEnvelopeOpenText}
									style={{
										fontSize: 80,
										color: '#E2E8F0',
									}}
								/>
								<Text color="gray.400" mt={4}>
									{t('No Data')}
								</Text>
							</Flex>
						)}
						{!loadingMail && dataLoadMail?.getEmailList?.emails?.length > 0 && (
							<Box p={4}>
								<Table variant="simple">
									<Thead>
										<Tr className="td-as-th">
											<Th>{t('ID')}</Th>
											<Th>{t('Created At')}</Th>
											<Th>{t('Send')}</Th>
											<Th>{t('Subject')}</Th>
											<Th>{t('Email')}</Th>
											<Th className="hidden-print" isNumeric>
												{t('Actions')}
											</Th>
										</Tr>
									</Thead>
									<Tbody>
										{dataLoadMail?.getEmailList?.emails?.map((email, index) => {
											return (
												<Tr
													_hover={{ bg: 'gray.200' }}
													className="td-as-th"
													key={`email-${index}`}
													cursor="pointer"
													bg="white"
													w="auto"
													minW="40px"
													minH="40px"
													h="40px"
												>
													<Td onClick={() => handleRowClicked(email?.id)}>{email?.id}</Td>
													<Td onClick={() => handleRowClicked(email?.id)}>{toDateString(email?.createdAt, c.longDateFormat)}</Td>
													<Td onClick={() => handleRowClicked(email?.id)}>
														{email?.send ? (
															<FontAwesomeIcon
																icon={faCheckCircle}
																style={{
																	fontSize: 16,
																	color: '#38A169',
																}}
															/>
														) : (
															<FontAwesomeIcon
																icon={faClock}
																style={{
																	fontSize: 16,
																	color: '#CBD5E0',
																}}
															/>
														)}
													</Td>
													<Td onClick={() => handleRowClicked(email?.id)}>{email?.mailOptions.subject}</Td>
													<Td onClick={() => handleRowClicked(email?.id)}>{email?.mailOptions.to}</Td>
													<Td className="hidden-print" isNumeric>
														<Flex justifyContent="flex-end">
															<Flex
																_hover={{ bg: 'gray.300' }}
																justifyContent="center"
																alignItems="center"
																w={8}
																h={8}
																bg="gray.100"
																borderRadius={8}
																onClick={() => {
																	workingId = email?.id;
																	onOpen();
																}}
															>
																<FontAwesomeIcon
																	icon={faTrash}
																	style={{
																		fontSize: 16,
																		color: '#E53E3E',
																	}}
																/>
															</Flex>
														</Flex>
													</Td>
												</Tr>
											);
										})}
									</Tbody>
									<Tfoot className="hidden-print">
										<Tr>
											<Th>{t('ID')}</Th>
											<Th>{t('Created At')}</Th>
											<Th>{t('Send')}</Th>
											<Th>{t('Email')}</Th>
											<Th>{t('Subject')}</Th>
											<Th className="hidden-print" isNumeric>
												{t('Actions')}
											</Th>
										</Tr>
									</Tfoot>
								</Table>
								<Flex className="hidden-print" justifyContent="flex-end" mr={6} mt={2}>
									<Text fontWeight="600">
										{t('Total Rows')}: {dataLoadMail?.getEmailList?.totalRows}
									</Text>
								</Flex>
								{dataLoadMail?.getEmailList?.totalPages > 1 && (
									<Flex className="hidden-print" justifyContent="flex-end" w="100%" mt={10}>
										<ReactPaginate
											previousLabel={<ChevronLeftIcon fontSize="20px" />}
											nextLabel={<ChevronRightIcon fontSize="20px" />}
											breakLabel={'...'}
											breakClassName={'break-me'}
											disableInitialCallback={true}
											pageCount={dataLoadMail?.getEmailList?.totalPages}
											marginPagesDisplayed={2}
											pageRangeDisplayed={3}
											onPageChange={handlePageChanged}
											containerClassName={'pagination'}
											activeClassName={'active'}
											initialPage={(currentPage - 1) | 0}
										/>
									</Flex>
								)}
								<Flex className="hidden-print" justifyContent="flex-end" mt="6">
									<Text mr="2">{t('Show more rows')}</Text>
									<Checkbox isChecked={more === 'true'} onChange={handleMoreChanged} />
								</Flex>
							</Box>
						)}
					</Box>
				</Center>
			</Container>
			<AlertDialog closeOnOverlayClick={false} closeOnEsc={false} isOpen={isOpen} onClose={onClose}>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							{t('Delete')}
						</AlertDialogHeader>

						<AlertDialogBody>{t("Are you sure? You can't undo this action afterwards")}</AlertDialogBody>
						<AlertDialogFooter>
							<Button mr={4} isLoading={loadingDeleteMail} onClick={onClose}>
								{t('Cancel')}
							</Button>
							<Button
								isLoading={loadingDeleteMail}
								colorScheme="red"
								onClick={() => {
									if (workingId) {
										deleteEmail({ variables: { id: workingId } });
									}
								}}
							>
								{t('Delete')}
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</>
	);
}

export default MailList;
