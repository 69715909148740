import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Container,
	Box,
	Flex,
	Text,
	Center,
	Table,
	Thead,
	Tbody,
	Tfoot,
	Tr,
	Th,
	Td,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	Input,
	InputRightElement,
	InputGroup,
	Button,
	Badge,
	useToast,
	Tooltip,
	Checkbox,
} from '@chakra-ui/react';
import BreadcrumbComponent from '../../components/BreadcrumbComponent/BreadcrumbComponent';
import { faEllipsisV, faFileExcel, faMoneyBillAlt, faTable } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import c from '../../constant';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import './style.css';
import { useLocation, useHistory } from 'react-router-dom';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import useStore from '../../store';
import LoadingTable from '../../components/Table/Loading';
import getPositionLabel from '../../utils/getPositionLabel';
import ExcelExportModal from '../../components/Modal/ExcelExportModal';
import ExcelExportModalEmployeeFSA from '../../components/Modal/ExcelExportModalEmployeeFSA';
import { Link } from 'react-router-dom';
import 'moment/locale/en-gb';
import 'moment/locale/km';
import _ from 'lodash';

const limit = 10;
const QUERY_EMPLOYEES = gql`
	query getEmployees($offset: Float, $limit: Float, $name: String, $isActive: Boolean) {
		getEmployees(name: $name, isActive: $isActive, pagerInput: { offset: $offset, limit: $limit }) {
			totalRows
			totalPages
			employees {
				id
				govtNumber
				fullName
				fullNameLatin
				gender
				dob
				createdAt
				phoneNumber
				currentWorkingStatus
				firstWorkingPlace
				additionalRoleOnCurrentJob
				active
			}
		}
	}
`;

const UPDATE_USER_MUTATION = gql`
	mutation updateUserByAdmin($id: String!, $active: Boolean) {
		updateUserByAdmin(id: $id, active: $active)
	}
`;

function UserList() {
	const { t } = useTranslation();
	const location = useLocation();
	const history = useHistory();
	const urlParams = new URLSearchParams(location.search);
	const currentPage = urlParams.get('page') ? urlParams.get('page') : 1;
	const toast = useToast();
	const [username, setUsername] = useState('');
	const [rowLimit, setRowLimit] = useState(10);

	const { currentLang } = useStore((state) => state.currentLang);
	moment.locale(currentLang === 'kh' ? 'km' : 'en-gb');

	const [loadEmployees, { error, loading, data }] = useLazyQuery(QUERY_EMPLOYEES);
	const [updateUserByAdmin, { loading: loadingUpdate, error: errorUpdate, data: dataUpdate }] = useMutation(UPDATE_USER_MUTATION);

	const [showExportExcel, setShowExportExcel] = useState(false);
	const [excelData, setExcelData] = useState([]);
	const [showEportFSA, setShowEportFSA] = useState(false);

	useEffect(() => {
		window.scrollTo(0, 0);
		loadEmployeesWithVar();
	}, []);

	function handlePageClick(data) {
		let selected = data.selected;
		const page = selected + 1;
		history.push(`/employee/list?page=${page}`);
	}

	useEffect(() => {
		if (currentPage) {
			window.scrollTo(0, 0);
			loadEmployeesWithVar();
		}
	}, [currentPage, rowLimit]);

	function loadEmployeesWithVar() {
		loadEmployees({
			variables: {
				// offset: currentPage ? ((currentPage - 1) * limit) : 0,
				offset: currentPage ? (currentPage - 1) * rowLimit : 0,
				limit: 9999,
				name: username,
				isActive: true,
			},
		});
	}

	function handleSearch() {
		handlePageClick({ selected: 0 });
		loadEmployeesWithVar();
	}

	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			handlePageClick({ selected: 0 });
			loadEmployeesWithVar();
		}
	};

	useEffect(() => {
		if (dataUpdate?.updateUserByAdmin) {
			loadEmployeesWithVar();
			toast({
				title: t('User update successfully'),
				status: 'success',
				isClosable: true,
				position: 'top-right',
			});
		}
	}, [dataUpdate]);

	function updateUser({ id, active }) {
		updateUserByAdmin({
			variables: {
				id,
				active: !active,
			},
		});
	}

	function actionMenu({ id, active }) {
		return (
			<Menu>
				<MenuButton cursor="pointer">
					<Flex>
						<FontAwesomeIcon icon={faEllipsisV} style={{ fontSize: 16, marginTop: 4, marginLeft: 6 }} />
					</Flex>
				</MenuButton>
				<MenuList pt="4" pb="4" pl="3" pr="3" borderRadius={c.borderRadius}>
					<MenuItem color={active ? 'red' : 'green'} onClick={() => updateUser({ id, active })}>
						{active ? t('Deactivate') : t('Activate')}
					</MenuItem>
				</MenuList>
			</Menu>
		);
	}

	function exportToExcel() {
		let excelData = [];
		if (data?.getEmployees?.employees?.length > 0) {
			orderEmployee(data?.getEmployees?.employees)?.map((item, index) => {
				const tmpData = {
					'ល.រ': index + 1,
					អត្ថលេខ: item?.govtNumber,
					'នាម-គោត្តនាម': item?.fullName,
					ភេទ: t(item?.gender),
					ថ្ងៃខែឆ្នាំកំណើត: item?.dob ? moment(item?.dob).format(c.excelDateOnlyTextFormat) : '',
					មុខងារ: item?.currentWorkingStatus?.currentWorkingStatusPosition
						? getPositionLabel(item?.currentWorkingStatus?.currentWorkingStatusPosition)
						: '',
					ឋានៈស្មើ: item?.currentWorkingStatus?.currentWorkingStatusJobTitle,
					'ក្របខ័ណ្ឌ ឋានន្តរស័កិ្ត​ និងថ្នាក់': item?.firstWorkingPlace?.firstWorkingJobTitle,
					ថ្ងៃខែឆ្នាំចូលបំរើការងាររដ្ឋ: item?.firstWorkingPlace?.firstWorkingstartDate
						? moment(item?.firstWorkingPlace?.firstWorkingstartDate).format(c.excelDateOnlyTextFormat)
						: '',
					លេខទូរសព្ទ: t(item?.phoneNumber),
				};
				excelData.push(tmpData);
			});
		}
		setExcelData(excelData);
		setShowExportExcel(true);
	}

	function getStatusEqualTo(data) {
		let tmpData = _.orderBy(data, ['date'], ['desc']);
		return tmpData?.[0]?.title;
	}

	function orderEmployee(employees) {
		const tmpEmp = [];
		employees?.map((item) => {
			tmpEmp.push({ ...item, currentWorkingStatusPosition: parseInt(item?.currentWorkingStatus?.currentWorkingStatusPosition || 9999) });
		});
		return _.orderBy(tmpEmp, ['currentWorkingStatusPosition', 'createdAt'], ['asc', 'asc']);
	}

	return (
		<>
			<Container maxW="container.xl" mt="16px" mb="16px">
				<BreadcrumbComponent
					list={[
						{
							name: t('OFFICIAL PROFILE'),
							path: '#',
						},
					]}
				/>
				<Center mt="5">
					<Box
						w="100%"
						bg="white"
						borderRadius={c.borderRadius}
						border="1px solid #dbdbdb"
						boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
						pb="32px"
					>
						<Flex bg="#FAFAFA" p="16px" pt="8px" pb="8px" mb="16px" justifyContent="space-between" borderBottom="1px solid #dbdbdb">
							<Text fontSize="x-large" fontWeight="bold">
								{t('Officials List')}
							</Text>
							<Flex alignItems="center">
								<Tooltip label={t('Export To Excel for FSA')}>
									<Flex onClick={() => setShowEportFSA(true)} mr="3" cursor="pointer">
										<FontAwesomeIcon icon={faTable} style={{ fontSize: 16, marginLeft: 6 }} />
									</Flex>
								</Tooltip>
								<Tooltip label={t('Export To Excel')}>
									<Flex onClick={() => exportToExcel()} mr="3" cursor="pointer">
										<FontAwesomeIcon icon={faFileExcel} style={{ fontSize: 16, marginLeft: 6 }} />
									</Flex>
								</Tooltip>

								<Tooltip label={t('Salary')}>
									<Link to={`/salary/list?page=1`}>
										<Flex onClick={() => exportToExcel()} mr="3" cursor="pointer">
											<FontAwesomeIcon icon={faMoneyBillAlt} style={{ fontSize: 16, marginLeft: 6 }} />
										</Flex>
									</Link>
								</Tooltip>
								<InputGroup mr="3" size="md" w="210px">
									<Input
										onKeyDown={handleKeyDown}
										pr="4.5rem"
										placeholder={t('Name')}
										value={username}
										onChange={(e) => setUsername(e.target.value)}
									/>
									<InputRightElement zIndex={0} width="4.5rem">
										<Button h="1.75rem" size="sm" onClick={handleSearch}>
											{t('Search')}
										</Button>
									</InputRightElement>
								</InputGroup>
							</Flex>
						</Flex>

						<Box p="16px">
							<Table variant="simple">
								{/* <TableCaption>Imperial to metric conversion factors</TableCaption> */}
								<Thead>
									<Tr>
										{/* <Th>ល.រ</Th> */}
										<Th>អត្ថលេខ</Th>
										<Th>នាម-គោត្តនាម</Th>
										<Th>ភេទ</Th>
										<Th>ថ្ងៃខែឆ្នាំកំណើត</Th>
										<Th>មុខងារ</Th>
										<Th>ឋានៈស្មើ</Th>
										<Th>ក្របខ័ណ្ឌ ឋានន្តរស័កិ្ត​ និងថ្នាក់</Th>
										<Th>ថ្ងៃខែឆ្នាំចូលបំរើការងាររដ្ឋ</Th>
										<Th>លេខទូរសព្ទ</Th>
										{/* <Th>{t("Full Name In Khmer")}</Th>
                                        <Th>{t("Full Name In Latin")}</Th>
                                        <Th>{t("Phone number")}</Th> */}
									</Tr>
								</Thead>
								<Tbody>
									{loading && <LoadingTable column={10} />}
									{data?.getEmployees?.employees.length <= 0 && (
										<Tr>
											<Td>
												<Text>{t('No Data')}</Text>
											</Td>
										</Tr>
									)}
									{orderEmployee(data?.getEmployees?.employees)?.map((item, index) => {
										return (
											<Tr
												key={`item-${index}`}
												cursor="pointer"
												_hover={{ bg: 'gray.200' }}
												onClick={() => history.push(`/employee/detail?id=${item.id}`)}
											>
												{/* <Td></Td> */}
												<Td>{item?.govtNumber || '-'}</Td>
												<Td>{item?.fullName}</Td>
												<Td>{t(item?.gender || '-')}</Td>
												<Td>{item?.dob ? moment(item?.dob).format(c.excelDateOnlyTextFormat) : '-'}</Td>
												<Td>
													{item?.currentWorkingStatus?.currentWorkingStatusPosition
														? getPositionLabel(item?.currentWorkingStatus?.currentWorkingStatusPosition)
														: '-'}
												</Td>
												<Td>{getStatusEqualTo(item?.additionalRoleOnCurrentJob) || '-'}</Td>
												<Td>{item?.currentWorkingStatus?.currentWorkingStatusJobTitle || '-'}</Td>
												<Td>
													{item?.firstWorkingPlace?.firstWorkingstartDate
														? moment(item?.firstWorkingPlace?.firstWorkingstartDate).format(c.excelDateOnlyTextFormat)
														: '-'}
												</Td>
												<Td>{t(item?.phoneNumber) || '-'}</Td>
											</Tr>
										);
									})}
								</Tbody>
								<Tfoot>
									<Tr>
										{/* <Th>{t("Full Name In Khmer")}</Th>
                                        <Th>{t("Full Name In Latin")}</Th>
                                        <Th>{t("Phone number")}</Th> */}
										{/* <Th>ល.រ</Th> */}
										<Th>អត្ថលេខ</Th>
										<Th>នាម-គោត្តនាម</Th>
										<Th>ភេទ</Th>
										<Th>ថ្ងៃខែឆ្នាំកំណើត</Th>
										<Th>មុខងារ</Th>
										<Th>ឋានៈស្មើ</Th>
										<Th>ក្របខ័ណ្ឌ ឋានន្តរស័កិ្ត​ និងថ្នាក់</Th>
										<Th>ថ្ងៃខែឆ្នាំចូលបំរើការងាររដ្ឋ</Th>
										<Th>លេខទូរសព្ទ</Th>
									</Tr>

									<Tr>
										<Th />
										<Th />
										<Th />
										<Th />
										<Th />
										<Th />
										<Th />
										<Th />
										<Th isNumeric>
											{t('Total Rows')}: {data?.getEmployees?.totalRows}
										</Th>
									</Tr>
								</Tfoot>
							</Table>
							<Flex justifyContent="flex-end" w="100%" mt="4">
								{data?.getEmployees?.totalPages > 1 && (
									<ReactPaginate
										previousLabel={<ChevronLeftIcon fontSize="20px" />}
										nextLabel={<ChevronRightIcon fontSize="20px" />}
										breakLabel={'...'}
										breakClassName={'break-me'}
										pageCount={data?.getEmployees?.totalPages}
										marginPagesDisplayed={2}
										pageRangeDisplayed={3}
										onPageChange={handlePageClick}
										containerClassName={'pagination'}
										activeClassName={'active'}
										initialPage={(currentPage - 1) | 0}
									/>
								)}
							</Flex>
							{/* <Flex className="hidden-print" justifyContent="flex-end" mt="6">
                                <Text mr="2">{t("Show more rows")}</Text>
                                <Checkbox isChecked={rowLimit>10} onChange={()=>{
                                    handlePageClick({selected:0})
                                    if(rowLimit>10){
                                        setRowLimit(10)
                                    } else {
                                        setRowLimit(300)
                                    }
                                }}></Checkbox>
                            </Flex> */}
						</Box>
					</Box>
				</Center>
			</Container>
			{showExportExcel && excelData?.length > 0 && (
				<ExcelExportModal isOpen={true} onClose={() => setShowExportExcel(false)} title="IRC" data={excelData} />
			)}

			{showEportFSA && <ExcelExportModalEmployeeFSA isOpen={true} onClose={() => setShowEportFSA(false)} title="IRC" data={excelData} />}
		</>
	);
}

export default UserList;
