import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Box, Flex, Text, Button, useToast, SimpleGrid,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure
} from "@chakra-ui/react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import TextFormField from "../../../../components/form/TextFormField"
import DateFormField from "../../../../components/form/DateFormField"
import DateInputFormField from "../../../../components/form/DateInputFormField"

import GeoFormField from "../../../../components/form/GeoFormField"
import CheckboxFormField from "../../../../components/form/CheckBoxFormField"
import TextAreaFormField from "../../../../components/form/TextAreaFormField"
import GeoCodeResoliver from "../../../../components/Geo/GeoCodeResolver"
import PreUploadFileFormField from "../../../../components/form/PreUploadFileFormField"
import { gql, useMutation } from '@apollo/client';
import moment from "moment"
import useStore from "../../../../store";
import { faSave, faPenSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import c from "../../../../constant";
import DownloadAndViewButtonUserUpload from "../../../../components/Files/DownloadAndViewButtonUserUpload"

const UPDATE_EMPLOYEE_MUTATION = gql`
mutation update($id:String!, $familyStatus: String){
    updateEmployeeFamilyStatus(
      id:$id
      familyStatus:$familyStatus
    )
  }
`


const signUpSchema = Yup.object().shape({
    fatherName: Yup.string(),
    fatherNameLatin: Yup.string(),
    fatherStatus: Yup.string(),
    fatherDob: Yup.string().nullable(),
    fatherNationality: Yup.string(),
    fatherCurrentAddress: Yup.object().nullable(),
    fatherIsCurrentAddressManual: Yup.boolean(),
    fatherCurrentAddressManual: Yup.string(),
    fatherJob: Yup.string(),
    fatherJobInstitution: Yup.string(),


    motherName: Yup.string(),
    motherNameLatin: Yup.string(),
    motherStatus: Yup.string(),
    motherDob: Yup.string().nullable(),
    motherNationality: Yup.string(),
    motherCurrentAddress: Yup.object().nullable(),
    motherIsCurrentAddressManual: Yup.boolean(),
    motherCurrentAddressManual: Yup.string(),
    motherJob: Yup.string(),
    motherJobInstitution: Yup.string(),
    document: Yup.string(),

});

function FamilyStatus({ id, initData, callbackOnSuccess }) {
    const toast = useToast()
    const { t } = useTranslation();
    const [updateEmployee, { error, data, loading }] = useMutation(
        UPDATE_EMPLOYEE_MUTATION
    );
    const { currentLang } = useStore((state) => state.currentLang);
    moment.locale(currentLang === "kh" ? "km" : "en-gb")

    const [isEditing, setIsEditing] = useState(false)
    const [motherIsCurrentAddressManual, setMotherIsCurrentAddressManual] = useState(initData?.motherIsCurrentAddressManual)
    const [fatherIsCurrentAddressManual, setFatherIsCurrentAddressManual] = useState(initData?.fatherIsCurrentAddressManual)

    const {
        isOpen,
        onClose,
        onOpen,
    } = useDisclosure()

    useEffect(() => {
        if (error) {
            if (error?.graphQLErrors?.length > 0) {
                error?.graphQLErrors.map(({ message }, i) => (
                    toast({
                        title: t(message),
                        status: "error",
                        isClosable: true,
                        position: "bottom-right"
                    })
                ))
            } else {
                toast({
                    title: t(error?.message ? error?.message : "something went wrong"),
                    status: "error",
                    isClosable: true,
                    position: "bottom-right"
                })
            }
        }
    }, [error])

    useEffect(() => {
        if (data) {
            if (data.updateEmployeeFamilyStatus) {
                toast({
                    title: t("Officials updated"),
                    description: t("Officials updated successfully"),
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                    position: "bottom-right"
                })
                callbackOnSuccess()
            }
        }
    }, [data])

    function rowData({ labelEN, labelKH, value }) {
        return (
            <Flex alignItems="center">
                <Text fontWeight="bold">{currentLang === "en" ? labelEN : labelKH}</Text>:
                <Text ml="4">{value || ""}</Text>
            </Flex>
        )
    }

    function viewFile(value){
        const tmpValue = value?JSON.parse(value):null
        return tmpValue?<DownloadAndViewButtonUserUpload url={tmpValue?.file} size={tmpValue?.fileSize}/>:"" 
    }

    function displayData() {
        return (
            <Box>
                <SimpleGrid columns={[1, 2, 3]} spacing={2}>
                    {rowData({ labelEN: "ឈ្មោះឪពុក", labelKH: "ឈ្មោះឪពុក", value: initData?.fatherName })}
                    {rowData({ labelEN: "ឈ្មោះឪពុកជាអក្សរឡាតាំង", labelKH: "ឈ្មោះឪពុកជាអក្សរឡាតាំង", value: initData?.fatherNameLatin?.toUpperCase() })}
                    {rowData({ labelEN: "ស្ថានភាពឪពុក", labelKH: "ស្ថានភាពឪពុក", value: initData?.fatherStatus })}
                    {rowData({ labelEN: "ថ្ងៃខែឆ្នាំកំណើតឪពុក", labelKH: "ថ្ងៃខែឆ្នាំកំណើតឪពុក", value: initData?.fatherDob ? moment(initData?.fatherDob).format(c.excelDateOnlyTextFormat) : "" })}
                    {rowData({ labelEN: "សញ្ជាតិឪពុក", labelKH: "សញ្ជាតិឪពុក", value: initData?.fatherNationality })}
                    {rowData({ labelEN: "ទីលំនៅបច្ចុប្បន្នឪពុក", labelKH: "ទីលំនៅបច្ចុប្បន្នឪពុក", value: initData?.fatherIsCurrentAddressManual ? initData?.fatherCurrentAddressManual : getAddress(initData?.fatherCurrentAddress) })}
                    {rowData({ labelEN: "មុខរបរឪពុក", labelKH: "មុខរបរឪពុក", value: initData?.fatherJob })}
                    {rowData({ labelEN: "ស្ថាប័ន/អង្គភាពឪពុក", labelKH: "ស្ថាប័ន/អង្គភាពឪពុក", value: initData?.fatherJobInstitution })}
                </SimpleGrid>
                <SimpleGrid mt="4" columns={[1, 2, 3]} spacing={2}>
                    {rowData({ labelEN: "ឈ្មោះម្តាយ", labelKH: "ឈ្មោះម្តាយ", value: initData?.motherName })}
                    {rowData({ labelEN: "ឈ្មោះម្តាយជាអក្សរឡាតាំង", labelKH: "ឈ្មោះម្តាយជាអក្សរឡាតាំង", value: initData?.motherNameLatin?.toUpperCase() })}
                    {rowData({ labelEN: "ស្ថានភាពម្តាយ", labelKH: "ស្ថានភាពម្តាយ", value: initData?.motherStatus })}
                    {rowData({ labelEN: "ថ្ងៃខែឆ្នាំកំណើតម្តាយ", labelKH: "ថ្ងៃខែឆ្នាំកំណើតម្តាយ", value: initData?.motherDob ? moment(initData?.motherDob).format(c.excelDateOnlyTextFormat) : "" })}
                    {rowData({ labelEN: "សញ្ជាតិម្តាយ", labelKH: "សញ្ជាតិម្តាយ", value: initData?.motherNationality })}
                    {rowData({ labelEN: "ទីលំនៅបច្ចុប្បន្នម្តាយ", labelKH: "ទីលំនៅបច្ចុប្បន្នម្តាយ", value: initData?.motherIsCurrentAddressManual ? initData?.motherCurrentAddressManual : getAddress(initData?.motherCurrentAddress) })}
                    {rowData({ labelEN: "មុខរបរម្តាយ", labelKH: "មុខរបរម្តាយ", value: initData?.motherJob })}
                    {rowData({ labelEN: "ស្ថាប័ន/អង្គភាពម្តាយ", labelKH: "ស្ថាប័ន/អង្គភាពម្តាយ", value: initData?.motherJobInstitution })}
                </SimpleGrid>
                <SimpleGrid mt="4" columns={[1, 2, 3]} spacing={2}>
                    {rowData({ labelEN: "ឯកសារយោង", labelKH: "ឯកសារយោង", value: viewFile(initData?.document) })}
                </SimpleGrid>
            </Box>
        )
    }

    function getJsonGeoData(tmpData) {
        if (tmpData) {
            const data = JSON.parse(tmpData)
            return {
                province: data?.province,
                district: data?.district,
                commune: data?.commune,
                village: data?.village,
                streetNumber: data?.streetNumber,
                houseNumber: data?.houseNumber,
            }
        } else {
            return null
        }
    }

    function geoObjectToValue(obj) {
        return JSON.stringify({
            province: obj?.province?.value || (obj?.province || ""),
            district: obj?.district?.value || (obj?.district || ""),
            commune: obj?.commune?.value || (obj?.commune || ""),
            village: obj?.village?.value || (obj?.village || ""),
            streetNumber: obj?.streetNumber || "",
            houseNumber: obj?.houseNumber || ""
        })
    }

    function getAddress(stringData) {
        if (stringData) {
            const data = JSON.parse(stringData)
            return (<GeoCodeResoliver
                province={data?.province}
                district={data?.district}
                commune={data?.commune}
                village={data?.village}
                houseNumber={data?.houseNumber}
                streetNumber={data?.streetNumber}
            />)
        } else {
            return null
        }
    }

    return (
        <>

            <Box p="26px" pt="0">
                <Flex mt="4" mb="4" alignItems="center">
                    <Text fontSize="lg" fontWeight="bold" mt="4" mb="4" color="#2980b9">ក. ព័ត៌មានឪពុកម្តាយ</Text>
                    {isEditing && <Button
                        variant="outline"
                        size="sm"
                        ml="4"
                        borderRadius={c.borderRadius}
                        onClick={() => setIsEditing(false)}
                        colorScheme='gray'
                    >
                        {t(`Cancel`)}
                    </Button>}
                    {!isEditing && <Button
                        variant="outline"
                        size="sm"
                        ml="4"
                        borderRadius={"0px !important"}
                        leftIcon={<FontAwesomeIcon icon={faPenSquare} style={{ fontSize: 14 }} />}
                        onClick={() => onOpen()}
                        colorScheme='whatsapp'
                    >
                        {t(`Edit`)}
                    </Button>}
                </Flex>

                {
                    !isEditing && <>
                        {displayData()}
                    </>
                }
                <Modal scrollBehavior="inside" size="5xl" isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay
                        backdropFilter='auto'
                        bg='blackAlpha.300'
                        backdropBlur='2px'
                    />
                    <Formik
                        initialValues={{
                            fatherName: initData?.fatherName || "",
                            fatherNameLatin: initData?.fatherNameLatin || "",
                            fatherStatus: initData?.fatherStatus || "",
                            fatherDob: initData?.fatherDob || "",
                            fatherNationality: initData?.fatherNationality || "",
                            fatherCurrentAddress: initData?.fatherCurrentAddress ? getJsonGeoData(initData?.fatherCurrentAddress) : null,
                            fatherIsCurrentAddressManual: initData?.fatherIsCurrentAddressManual || false,
                            fatherCurrentAddressManual: initData?.fatherCurrentAddressManual || "",
                            fatherJob: initData?.fatherJob || "",
                            fatherJobInstitution: initData?.fatherJobInstitution || "",


                            motherName: initData?.motherName || "",
                            motherNameLatin: initData?.motherNameLatin || "",
                            motherStatus: initData?.motherStatus || "",
                            motherDob: initData?.motherDob || "",
                            motherNationality: initData?.motherNationality || "",
                            motherCurrentAddress: initData?.motherCurrentAddress ? getJsonGeoData(initData?.motherCurrentAddress) : null,
                            motherIsCurrentAddressManual: initData?.motherIsCurrentAddressManual || false,
                            motherCurrentAddressManual: initData?.motherCurrentAddressManual || "",
                            motherJob: initData?.motherJob || "",
                            motherJobInstitution: initData?.motherJobInstitution || "",
                            document: initData?.document||""
                        }}
                        validationSchema={signUpSchema}
                        onSubmit={(values) => {
                            updateEmployee({
                                variables: {
                                    id,
                                    familyStatus: JSON.stringify({
                                        fatherName: values?.fatherName,
                                        fatherNameLatin: values?.fatherNameLatin,
                                        fatherStatus: values?.fatherStatus,
                                        fatherDob: values?.fatherDob,
                                        fatherNationality: values?.fatherNationality,
                                        fatherCurrentAddress: values?.fatherCurrentAddress ? geoObjectToValue(values?.fatherCurrentAddress) : "",
                                        fatherIsCurrentAddressManual: values?.fatherIsCurrentAddressManual,
                                        fatherCurrentAddressManual: values?.fatherCurrentAddressManual,
                                        fatherJob: values?.fatherJob,
                                        fatherJobInstitution: values?.fatherJobInstitution,


                                        motherName: values?.motherName,
                                        motherNameLatin: values?.motherNameLatin,
                                        motherStatus: values?.motherStatus,
                                        motherDob: values?.motherDob,
                                        motherNationality: values?.motherNationality,
                                        motherCurrentAddress: values?.motherCurrentAddress ? geoObjectToValue(values?.motherCurrentAddress) : "",
                                        motherIsCurrentAddressManual: values?.motherIsCurrentAddressManual,
                                        motherCurrentAddressManual: values?.motherCurrentAddressManual,
                                        motherJob: values?.motherJob,
                                        motherJobInstitution: values?.motherJobInstitution,
                                        document: values?.document||""
                                    })
                                }
                            })
                        }}
                    >
                        {(props) => (
                            <Form>
                                <ModalContent borderRadius={c.borderRadius}>
                                    <ModalHeader>{t("Edit")}</ModalHeader>
                                    <ModalCloseButton />
                                    <ModalBody>
                                        <Box>
                                            <SimpleGrid columns={[1, 2, 3]} spacing={2}>
                                                {/* father */}
                                                <TextFormField
                                                    {...props}
                                                    label="ឈ្មោះឪពុក"
                                                    name="fatherName"
                                                />

                                                <TextFormField
                                                    {...props}
                                                    label="ឈ្មោះឪពុកជាអក្សរឡាតាំង"
                                                    name="fatherNameLatin"
                                                />

                                                <TextFormField
                                                    {...props}
                                                    label="ស្ថានភាពឪពុក"
                                                    name="fatherStatus"
                                                />

                                                <DateInputFormField
                                                    {...props}
                                                    label="ថ្ងៃខែឆ្នាំកំណើតឪពុក"
                                                    name="fatherDob"
                                                    initialDate={initData?.fatherDob}
                                                />

                                                <TextFormField
                                                    {...props}
                                                    label="សញ្ជាតិឪពុក"
                                                    name="fatherNationality"
                                                />

                                                {!fatherIsCurrentAddressManual && <GeoFormField
                                                    {...props}
                                                    name="fatherCurrentAddress"
                                                    label="ទីលំនៅបច្ចុប្បឪពុក"
                                                    buttomComponent={
                                                        <CheckboxFormField
                                                            {...props}
                                                            label="Input"
                                                            name="fatherIsCurrentAddressManual"
                                                            callbackValueChange={e => setFatherIsCurrentAddressManual(e)}
                                                        />
                                                    }
                                                />}

                                                {fatherIsCurrentAddressManual && <TextAreaFormField
                                                    {...props}
                                                    label="ទីលំនៅបច្ចុប្បឪពុក"
                                                    name="fatherCurrentAddressManual"
                                                    buttomComponent={
                                                        <CheckboxFormField
                                                            {...props}
                                                            label="Input"
                                                            name="fatherIsCurrentAddressManual"
                                                            callbackValueChange={e => setFatherIsCurrentAddressManual(e)}
                                                        />
                                                    }
                                                />}


                                                <TextFormField
                                                    {...props}
                                                    label="មុខរបរឪពុក"
                                                    name="fatherJob"
                                                />

                                                <TextFormField
                                                    {...props}
                                                    label="ស្ថាប័ន/អង្គភាពឪពុក"
                                                    name="fatherJobInstitution"
                                                />
                                            </SimpleGrid>
                                            {/* mother */}
                                            <SimpleGrid columns={[1, 2, 3]} spacing={2}>
                                                <TextFormField
                                                    {...props}
                                                    label="ឈ្មោះម្តាយ"
                                                    name="motherName"
                                                />

                                                <TextFormField
                                                    {...props}
                                                    label="ឈ្មោះម្តាយជាអក្សរឡាតាំង"
                                                    name="motherNameLatin"
                                                />

                                                <TextFormField
                                                    {...props}
                                                    label="ស្ថានភាពម្តាយ"
                                                    name="motherStatus"
                                                />

                                                <DateInputFormField
                                                    {...props}
                                                    label="ថ្ងៃខែឆ្នាំកំណើតម្តាយ"
                                                    name="motherDob"
                                                    initialDate={initData?.motherDob}
                                                />

                                                <TextFormField
                                                    {...props}
                                                    label="សញ្ជាតិម្តាយ"
                                                    name="motherNationality"
                                                />

                                                {!motherIsCurrentAddressManual && <GeoFormField
                                                    {...props}
                                                    name="motherCurrentAddress"
                                                    label="ទីលំនៅបច្ចុប្បម្តាយ"
                                                    buttomComponent={
                                                        <CheckboxFormField
                                                            {...props}
                                                            label="Input"
                                                            name="motherIsCurrentAddressManual"
                                                            callbackValueChange={e => setMotherIsCurrentAddressManual(e)}
                                                        />
                                                    }
                                                />}

                                                {motherIsCurrentAddressManual && <TextAreaFormField
                                                    {...props}
                                                    label="ទីលំនៅបច្ចុប្បម្តាយ"
                                                    name="motherCurrentAddressManual"
                                                    buttomComponent={
                                                        <CheckboxFormField
                                                            {...props}
                                                            label="Input"
                                                            name="motherIsCurrentAddressManual"
                                                            callbackValueChange={e => setMotherIsCurrentAddressManual(e)}
                                                        />
                                                    }
                                                />}


                                                <TextFormField
                                                    {...props}
                                                    label="មុខរបរម្តាយ"
                                                    name="motherJob"
                                                />

                                                <TextFormField
                                                    {...props}
                                                    label="ស្ថាប័ន/អង្គភាពម្តាយ"
                                                    name="motherJobInstitution"
                                                />


                                            </SimpleGrid>
                                            <PreUploadFileFormField
                                                {...props}
                                                label={"ឯកសារយោង(សំបុត្រកំណើត/សៀវភៅគ្រួសារ)"}
                                                name={"document"}
                                            />
                                        </Box>
                                    </ModalBody>
                                    <ModalFooter bg="rgba(236, 240, 241,0.5)">
                                        <Button
                                            borderRadius={c.borderRadius}
                                            colorScheme='green'
                                            type="submit"
                                            isLoading={loading}
                                            loadingText={t("Save")}
                                            spinnerPlacement="start"
                                        >
                                            {t("Save")}
                                        </Button>
                                    </ModalFooter>
                                </ModalContent>
                            </Form>
                        )}
                    </Formik>
                </Modal>
            </Box>
        </>
    );
}

export default FamilyStatus;