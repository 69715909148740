import React, { useEffect, useState } from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Box, 
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    useToast
} from "@chakra-ui/react";
import c from "../../constant"
import './style.css'
import { useTranslation } from "react-i18next";
import { gql, useMutation } from '@apollo/client';


const QUERY_CHANGE_CATEGORY = gql`
    mutation changeArticleCategoryById($id:String!,$category:String!){
        changeArticleCategoryById(
            id:$id
            category:$category
        )
    }
`

function SelectUserModal({
    isOpen = false, onClose = () => null, callback = () => null, articleId = null
}) {
    const { t } = useTranslation();
    const [changeCategory, { error, loading, data }]  = useMutation(QUERY_CHANGE_CATEGORY)
    const toast = useToast()

    function selectCategory(category){
        changeCategory({
            variables:{
                id: articleId,
                category
            }
        })
    }

    useEffect(()=>{
        if(data?.changeArticleCategoryById){
            onClose()
            toast({
                title: t(data?.changeArticleCategoryById),
                status: "success",
                isClosable: true,
                position: "top-right"
            })
        }
    },[data])

    useEffect(()=>{
        if(error){
            toast({
                title: t("Change catrgory error"),
                status: "error",
                isClosable: true,
                position: "top-right"
            })
        }
    },[error])


    return (
        <Modal
            isOpen={isOpen} 
            onClose={()=>{
                onClose()
            }}
        >
            <ModalOverlay />
            <ModalContent borderRadius={c.borderRadius}>
                <ModalHeader>{t("Categories")}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Box p="16px">
                        <Table variant="simple">
                            {/* <TableCaption>Imperial to metric conversion factors</TableCaption> */}
                            <Thead>
                                <Tr>
                                    <Th>{t("Category")}</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {categories?.map((category, index) => {
                                    return (
                                        <Tr key={`user-${index}`} onClick={()=>selectCategory(category.value)} cursor="pointer" _hover={{ backgroundColor: "gray.200" }}>
                                            <Td fontWeight="500" >{t(category.title)}</Td>
                                        </Tr>
                                    )
                                })}
                            </Tbody>
                            <Tfoot>
                                <Tr>
                                    <Th>{t("Category")}</Th>
                                </Tr>
                            </Tfoot>
                        </Table>
                    </Box>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

export default SelectUserModal;


const categories=[
    {
        title: "News",
        value: "news"
    },
    {
        title: "Announcement",
        value: "announcement"
    },
    {
        title: "Educational broadcasting",
        value: "educational-broadcasting"
    },
    {
        title: "Social programs",
        value: "social-programs"
    },
    {
        title: "Statistics - Monthly",
        value: "statistics-monthly"
    },
    {
        title: "Statistics - Quarterly",
        value: "statistics-quarterly"
    },
    {
        title: "Statistics - Annually",
        value: "statistics-annually"
    },
    {
        title: "Laws",
        value: "laws"
    },
    {
        title: "Sub decrees",
        value: "sub-decrees"
    },
    {
        title: "Prakas",
        value: "prakas"
    },
    {
        title: "Guidance",
        value: "guidance"
    },
    {
        title: "Other",
        value: "other"
    },
    {
        title: "Publication",
        value: "publication"
    },
    {
        title: "Inprogress Sandbox",
        value: "inprogress-insurance-regulatory-sandbox"
    },
    {
        title: "Successed Sandbox",
        value: "successed-insurance-regulatory-sandbox"
    },
    {
        title: "Sandbox Info",
        value: "sandbox-info"
    },
    
]