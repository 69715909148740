/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { Badge, Flex, Text, Tooltip } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { CloseIcon } from '@chakra-ui/icons';
import { PartnerRequestStatus, GradeApproval } from '../../../../containers/ManageAgentPartner/PartnerRequest/constants';
import { gql, useMutation } from '@apollo/client';
import { usePartnerRequest } from '../../../../contexts/partner-request';
import PartnerRequestEditingStatus from './PartnerRequestEditingStatus';
import PartnerRequestCertificateApproval from '../Approval/PartnerRequestCertificateApproval';
import useStore from '../../../../store';
import c from '../../../../constant';

const M_SUBMIT_APPROVAL = gql`
	mutation submit($id: String!, $grade: PartnerReqeustApprovalGrade!, $status: PartnerReqeustCertificateStatus, $description: String) {
		submitCertificateApproval(id: $id, grade: $grade, status: $status, description: $description)
	}
`;

let status = null;
let description = null;

const PartnerRequestCertificateStatus = ({ id = null, requestStatus = null, certificateStatus = null }) => {
	const { t } = useTranslation();
	const { loadedData, setLoadedData } = usePartnerRequest();
	const { currentUser: user } = useStore((state) => state.currentUser);
	const [submitApproval, { data }] = useMutation(M_SUBMIT_APPROVAL);

	useEffect(() => {
		if (data?.submitCertificateApproval) {
			setLoadedData({
				...loadedData,
				candidates: loadedData?.candidates?.map((e) => {
					if (e?.id === id) {
						e.certificateStatus = status;
						e.certificateStatusDescription = description;
					}
					return e;
				}),
			});
		}
	}, [data]);

	return (
		<>
			{requestStatus > PartnerRequestStatus.reviewAgentFinal && (
				<>
					{certificateStatus === 'APPROVED' && (
						<>
							<Tooltip label={`${t('approved')}`}>
								<Badge pl={2} pr={2} colorScheme="green">
									<Text fontWeight="bold" fontSize="xs" color="green.500">
										{t('approved')}
									</Text>
								</Badge>
							</Tooltip>
						</>
					)}
					{certificateStatus === 'REJECTED' && (
						<>
							<Tooltip label={`${t('Rejected')}`}>
								<Badge pl={2} pr={2} colorScheme="red">
									<Text fontWeight="bold" fontSize="xs" color="red.500">
										{t('Rejected')}
									</Text>
								</Badge>
							</Tooltip>
						</>
					)}
					{!certificateStatus && (
						<>
							<>
								<Text fontSize="sm" fontWeight="medium">
									{t('N/A')}
								</Text>
							</>
						</>
					)}
				</>
			)}
			{requestStatus === PartnerRequestStatus.reviewAgent && (
				<>
					{c.stepOfficeRoles.includes(user?.position) ? (
						<>
							{certificateStatus ? (
								<>
									{certificateStatus === 'APPROVED' && (
										<>
											<Tooltip label={`${t('approved')} (${t('You can change this status')})`}>
												<Badge pl={2} pr={2} colorScheme="green">
													<Flex justifyContent="center" alignItems="center">
														<Text fontWeight="bold" fontSize="xs" color="green.500">
															{t('approved')}
														</Text>
														<>
															<CloseIcon
																w={2}
																h={2}
																ml={2}
																color="green.500"
																onClick={() => {
																	status = null;
																	description = null;
																	const variables = { id, grade: GradeApproval.primary, status, description };
																	submitApproval({ variables });
																}}
															/>
														</>
													</Flex>
												</Badge>
											</Tooltip>
										</>
									)}
									{certificateStatus === 'REJECTED' && (
										<>
											<Tooltip label={`${t('Rejected')} (${t('You can change this status')})`}>
												<Badge pl={2} pr={2} colorScheme="red">
													<Flex justifyContent="center" alignItems="center">
														<Text fontWeight="bold" fontSize="xs" color="red.500">
															{t('Rejected')}
														</Text>
														<>
															<CloseIcon
																w={2}
																h={2}
																ml={2}
																color="red.500"
																onClick={() => {
																	status = null;
																	description = null;
																	const variables = { id, grade: GradeApproval.primary, status, description };
																	submitApproval({ variables });
																}}
															/>
														</>
													</Flex>
												</Badge>
											</Tooltip>
										</>
									)}
								</>
							) : (
								<>
									<PartnerRequestCertificateApproval id={id} requestStatus={requestStatus} />
								</>
							)}
						</>
					) : (
						<>
							<Text fontSize="sm" fontWeight="medium">
								{t('-')}
							</Text>
						</>
					)}
				</>
			)}
			{requestStatus === PartnerRequestStatus.reviewAgentFinal && (
				<>
					{c.stepDepartmentRoles.includes(user?.position) ? (
						<>
							{certificateStatus ? (
								<>
									{certificateStatus === 'APPROVED' && (
										<>
											<Tooltip label={`${t('approved')} (${t('You can change this status')})`}>
												<Badge pl={2} pr={2} colorScheme="green">
													<Flex justifyContent="center" alignItems="center">
														<Text fontWeight="bold" fontSize="xs" color="green.500">
															{t('approved')}
														</Text>
														<>
															<CloseIcon
																w={2}
																h={2}
																ml={2}
																color="green.500"
																onClick={() => {
																	status = null;
																	description = null;
																	const variables = { id, grade: GradeApproval.secondary, status, description };
																	submitApproval({ variables });
																}}
															/>
														</>
													</Flex>
												</Badge>
											</Tooltip>
										</>
									)}
									{certificateStatus === 'REJECTED' && (
										<>
											<Tooltip label={`${t('Rejected')} (${t('You can change this status')})`}>
												<Badge pl={2} pr={2} colorScheme="red">
													<Flex justifyContent="center" alignItems="center">
														<Text fontWeight="bold" fontSize="xs" color="red.500">
															{t('Rejected')}
														</Text>
														<>
															<CloseIcon
																w={2}
																h={2}
																ml={2}
																color="red.500"
																onClick={() => {
																	status = null;
																	description = null;
																	const variables = { id, grade: GradeApproval.secondary, status, description };
																	submitApproval({ variables });
																}}
															/>
														</>
													</Flex>
												</Badge>
											</Tooltip>
										</>
									)}
								</>
							) : (
								<>
									<PartnerRequestCertificateApproval id={id} requestStatus={requestStatus} />
								</>
							)}
						</>
					) : (
						<>
							<>
								{certificateStatus ? (
									<>
										{certificateStatus === 'APPROVED' && (
											<>
												<Tooltip label={`${t('approved')}`}>
													<Badge pl={2} pr={2} colorScheme="green">
														<Text fontWeight="bold" fontSize="xs" color="green.500">
															{t('approved')}
														</Text>
													</Badge>
												</Tooltip>
											</>
										)}
										{certificateStatus === 'REJECTED' && (
											<>
												<Tooltip label={`${t('Rejected')}`}>
													<Badge pl={2} pr={2} colorScheme="red">
														<Text fontWeight="bold" fontSize="xs" color="red.500">
															{t('Rejected')}
														</Text>
													</Badge>
												</Tooltip>
											</>
										)}
									</>
								) : (
									<>
										<PartnerRequestEditingStatus />
									</>
								)}
							</>
						</>
					)}
				</>
			)}
		</>
	);
};

export default PartnerRequestCertificateStatus;
