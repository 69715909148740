/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Button, useToast, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, SimpleGrid } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { gql, useMutation } from '@apollo/client';
import TextFormField from '../../components/form/TextFormField';
import DateFormField from '../form/DateFormField';
import FileFormField from '../../components/form/FileFormField';
import TextAreaFormField from '../form/TextAreaFormField';
import CheckBoxFormField from '../form/CheckBoxFormField';
import GeoFormField from '../form/GeoFormField';
import * as Yup from 'yup';
import c from '../../constant';

const M_UPDATE_LICENSE = gql`
	mutation updateCompanyLicense(
		$id: String!
		$description: String
		$isPermanentLicense: Boolean
		$licenseValidTo: DateTime
		$licenseValidFrom: DateTime
		$licenseSize: Float
		$licenseFile: Upload
		$statuteSize: Float
		$statuteFile: Upload
		$isGeoManual: Boolean
		$geoManual: String
		$geoLocation: String
		$url: String
		$displayNumber: String
		$number: String
	) {
		updateCompanyLicense(
			id: $id
			description: $description
			isPermanentLicense: $isPermanentLicense
			licenseValidTo: $licenseValidTo
			licenseValidFrom: $licenseValidFrom
			licenseSize: $licenseSize
			licenseFile: $licenseFile
			statuteSize: $statuteSize
			statuteFile: $statuteFile
			isGeoManual: $isGeoManual
			geoManual: $geoManual
			geoLocation: $geoLocation
			url: $url
			displayNumber: $displayNumber
			number: $number
		) {
			id
		}
	}
`;

const validationSchema = Yup.object().shape({
	number: Yup.string(),
	url: Yup.string().url(),
	isGeoManual: Yup.boolean(),
	geoManual: Yup.mixed().when('isGeoManual', {
		is: (isGeoManual) => isGeoManual === true,
		then: Yup.string().required('required'),
		otherwise: Yup.string(),
	}),
	geoLocation: Yup.object().nullable(),
	statute: Yup.mixed().test('fileSize', 'over limit', (value) => {
		if (value) {
			return value?.size <= c.maxFileSize;
		} else {
			return true;
		}
	}),
	license: Yup.mixed().test('fileSize', 'over limit', (value) => {
		if (value) {
			return value?.size <= c.maxFileSize / 10;
		} else {
			return true;
		}
	}),
	licenseValidFrom: Yup.string().nullable(),
	licenseValidTo: Yup.string().nullable(),
	isPermanentLicense: Yup.boolean().nullable(),
	description: Yup.string().nullable(),
});

function EditCompanyLicenseModal({ licenseData = null, isOpen = false, onClose = () => null, onCallback = () => null }) {
	const toast = useToast();
	const { t } = useTranslation();
	const [isGeoManual, setGeoManual] = useState(licenseData?.isGeoManual);
	const [isPermanentLicense, setPermanentLicense] = useState(licenseData?.isPermanentLicense);
	const [updateLicense, { loading, data, error }] = useMutation(M_UPDATE_LICENSE);

	useEffect(() => {
		if (error) {
			if (error?.graphQLErrors?.length > 0) {
				error?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(error?.message ? error?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [error]);

	useEffect(() => {
		if (data) {
			if (data.updateCompanyLicense) {
				toast({
					title: t('Company license edited successfully'),
					status: 'success',
					duration: 5000,
					isClosable: true,
					position: 'bottom-right',
				});
				onClose();
				onCallback();
			}
		}
	}, [data]);

	const update = (values) => {
		const variables = {
			id: licenseData?.id,
			number: values?.number,
			url: values.url,
			isGeoManual: values?.isGeoManual,
			geoManual: values?.geoManual,
			geoLocation: JSON.stringify(values?.geoLocation),
			statuteFile: values.statute,
			statuteSize: values?.statute?.size,
			licenseFile: values.license,
			licenseSize: values?.license?.size,
			licenseValidTo: values?.licenseValidTo,
			licenseValidFrom: values?.licenseValidFrom,
			isPermanentLicense: values?.isPermanentLicense,
			description: values?.description,
		};
		updateLicense({ variables });
	};

	return (
		<>
			<Modal closeOnEsc={false} closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="4xl">
				<ModalOverlay />
				<ModalContent borderRadius={c.borderRadius} pb="2">
					<ModalHeader>{t('Add License')}</ModalHeader>
					<ModalCloseButton isDisabled={loading} />
					<ModalBody>
						<Formik
							initialValues={{
								number: licenseData?.number || '',
								url: licenseData?.url || '',
								isGeoManual,
								geoManual: licenseData?.geoManual || '',
								geoLocation: {
									province: licenseData?.province,
									district: licenseData?.district,
									commune: licenseData?.commune,
									village: licenseData?.village,
									streetNumber: licenseData?.streetNumber,
									houseNumber: licenseData?.houseNumber,
								},
								isPermanentLicense,
								description: licenseData?.description || '',
							}}
							validationSchema={validationSchema}
							onSubmit={update}
						>
							{(props) => (
								<Form>
									<Box mt="3">
										<SimpleGrid columns={2} spacingX={6} spacingY={0}>
											<Box>
												<TextFormField {...props} label="Number" name="number" />
												<TextFormField {...props} label="URL" name="url" />
												<FileFormField {...props} label="Statute" name="statute" maxFileSize={c.maxFileSize} />
												<FileFormField {...props} label="License" name="license" maxFileSize={c.maxFileSize / 10} />
												{!isPermanentLicense && (
													<>
														<DateFormField
															name="licenseValidFrom"
															label="License Valid From"
															initialDate={licenseData?.licenseValidFrom}
														/>
														<DateFormField
															name="licenseValidTo"
															label="License Valid To"
															initialDate={licenseData?.licenseValidTo}
														/>
													</>
												)}
												<CheckBoxFormField
													{...props}
													label="Is Permanent License"
													name="isPermanentLicense"
													callbackValueChange={setPermanentLicense}
												/>
											</Box>
											<Box>
												{isGeoManual ? (
													<TextAreaFormField
														{...props}
														label="Address"
														name="geoManual"
														required={true}
														buttomComponent={
															<CheckBoxFormField {...props} label="Input" name="isGeoManual" callbackValueChange={setGeoManual} />
														}
													/>
												) : (
													<GeoFormField
														{...props}
														label="Address"
														name="geoLocation"
														buttomComponent={
															<CheckBoxFormField {...props} label="Input" name="isGeoManual" callbackValueChange={setGeoManual} />
														}
													/>
												)}
												<TextAreaFormField {...props} label="Description" name="description" />
											</Box>
										</SimpleGrid>
										<Flex textAlign="right" mt="6" justifyContent="space-between" alignItems="center">
											<Button
												_hover={{ bg: 'primary.dark' }}
												id="login-btn"
												variant="solid"
												size="md"
												h="42px"
												w="128px"
												bg="primary.dark"
												color="white"
												type="submit"
												isLoading={loading}
												loadingText={t('CREATE')}
												spinnerPlacement="start"
											>
												{t(`Edit`)}
											</Button>
										</Flex>
									</Box>
								</Form>
							)}
						</Formik>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
}

export default EditCompanyLicenseModal;
