import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Box, Button, useToast, Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
} from "@chakra-ui/react";
import c from "../../constant"
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { gql, useMutation } from '@apollo/client';
import TextFormField from "../../components/form/TextFormField"

const UPDATE_NAME = gql`
mutation update(
    $name: String!, 
    $id: String!
    $type: String!
){
    updateNameByAgentId(
        newName:$name
        id:$id
        type:$type
    )
}
`

const agentSchema = Yup.object().shape({
    name: Yup.string().required()
});

function UpdateAgentName({
    isOpen = true, onClose = () => null, callback = () => null, id, name, nameEn, type
}) {
    const toast = useToast()
    const { t } = useTranslation();
    const [updateName, { loading, error, data }] = useMutation(
        UPDATE_NAME
    );


    useEffect(() => {
        if (data) {
            if (data?.updateNameByAgentId) {
                toast({
                    title: t("Name updated successfully"),
                    status: "success",
                    isClosable: true,
                    position: "top-right"
                })
                onClose()
                callback("Name updated successfully")
            }
        }
    }, [data])

    useEffect(() => {
        if (error) {
            error.graphQLErrors.map(({ message }, i) => (
                toast({
                    title: t(message),
                    status: "error",
                    isClosable: true,
                    position: "top-right"
                })
            ))
        }
    }, [error])

    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={() => {
                    onClose()
                }}
                size="md"
                scrollBehavior="outside"
                isCentered
            >
                <ModalOverlay />
                <ModalContent borderRadius={c.borderRadius} pb="2">
                    <ModalHeader>{t("Update Agent Name")}</ModalHeader>
                    <ModalCloseButton />
                    {name&&<ModalBody>
                        <Formik
                            initialValues={{
                                name:type==="en"?nameEn:name
                            }}
                            validationSchema={agentSchema}
                            onSubmit={(values) => {
                                updateName({
                                    variables:{
                                        name: values?.name,
                                        id,
                                        type
                                    }
                                })
                            }}
                        >
                            {(props) => (
                                <Box>
                                    <Form>

                                         <TextFormField
                                            {...props}
                                            label={type==="en"?"Latin name":"Name"}
                                            name="name"
                                            required
                                            font="NotoSansKhmer"
                                        />
                                        
                                        <Button
                                            id="login-btn"
                                            variant="solid"
                                            size="md"
                                            h="42px"
                                            w="128px"
                                            type="submit"
                                            borderRadius={c.borderRadius}
                                            isLoading={loading}
                                            float="right"
                                        >
                                            {t("Submit")}
                                        </Button>
                                    </Form>
                                </Box>
                            )}
                        </Formik>
                    </ModalBody>}
                </ModalContent>
            </Modal>
        </>
    );
}

export default UpdateAgentName;
