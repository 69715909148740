/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Badge, useDisclosure, useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { gql, useLazyQuery } from '@apollo/client';
import InsuranceInstitutionNearlyDueModal from '../../components/Modal/InsuranceInstitutionNearlyDueModal';

const Q_VALIDITY = gql`
	query get {
		getNearlyDueCompanyValidity {
			totalCount
			licenseCount
			licenses {
				company {
					id
					name
					nameKhmer
					logo
				}
				validFrom
				validTo
				separatedLicenseType
				status
			}
			branchCount
			branches {
				khmerName
				latinName
				company {
					id
					name
					nameKhmer
					logo
				}
				validFrom
				validTo
				status
			}
		}
	}
`;

function InsuranceInstitutionBadge() {
	const toast = useToast();
	const { t } = useTranslation();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [getValidity, { data, error }] = useLazyQuery(Q_VALIDITY);

	useEffect(() => {
		getValidity();
	}, []);

	useEffect(() => {
		if (error) {
			error.graphQLErrors.map(({ message }, i) =>
				toast({
					title: t(message),
					status: 'error',
					isClosable: true,
					position: 'top-right',
				})
			);
		}
	}, [error]);

	return (
		<>
			{data?.getNearlyDueCompanyValidity?.totalCount > 0 && (
				<>
					<Badge zIndex="99" top="2px" left="2px" position="absolute" colorScheme="yellow" fontSize="16px" onMouseDown={onOpen}>
						{data?.getNearlyDueCompanyValidity?.totalCount} {t('Need(s) Renewal')}
					</Badge>
					{isOpen && <InsuranceInstitutionNearlyDueModal data={data?.getNearlyDueCompanyValidity} isOpen={isOpen} onClose={onClose} />}
				</>
			)}
		</>
	);
}

export default InsuranceInstitutionBadge;
