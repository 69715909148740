/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Box, Flex, Text, Button, Center, useToast } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import { Formik, Form } from 'formik';
import BreadcrumbComponent from '../../../../components/BreadcrumbComponent/BreadcrumbComponent';
import TextFormField from '../../../../components/form/TextFormField';
import * as Yup from 'yup';
import c from '../../../../constant';

const M_CREATE_TYPE_DOCUMENT = gql`
	mutation create($type: String!, $typeEn: String, $description: String) {
		createTypeOfDocumentNumber(type: $type, typeEn: $typeEn, description: $description) {
			id
			type
			typeEn
			description
		}
	}
`;

const validationSchema = Yup.object().shape({
	type: Yup.string().required('required'),
	typeEn: Yup.string(),
	description: Yup.string(),
});

function TypeOfDocumentNumberAdd() {
	const { t } = useTranslation();
	const history = useHistory();
	const toast = useToast();
	const [createTypeOfDocumentNumber, { error, loading, data }] = useMutation(M_CREATE_TYPE_DOCUMENT);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if (data) {
			if (data?.createTypeOfDocumentNumber) {
				toast({
					title: t('Type of document number added successfully'),
					status: 'success',
					duration: 5000,
					isClosable: true,
					position: 'bottom-right',
				});
				history.push('/type-of-issued-letter?page=type-of-issued-letter');
			}
		}
	}, [data]);

	useEffect(() => {
		if (error) {
			if (error?.graphQLErrors?.length > 0) {
				error?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(error?.message ? error?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [error]);

	return (
		<>
			<Container maxW="container.xl" mt="16px" mb="16px">
				<BreadcrumbComponent
					list={[
						{
							name: 'Settings',
							path: '/settings',
						},
						{
							name: 'TYPE OF ISSUED LETTER',
							path: '/type-of-issued-letter?page=type-of-issued-letter',
						},
						{
							name: 'Add Issued Letter Type',
							path: '#',
						},
					]}
				/>
				<Center mt="5">
					<Box
						w="100%"
						bg="white"
						borderRadius={c.borderRadius}
						border="1px solid #dbdbdb"
						boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
						pb="32px"
					>
						<Flex
							alignItems="center"
							justifyContent="space-between"
							bg="#FAFAFA"
							p="16px"
							pt="8px"
							pb="8px"
							mb="16px"
							borderBottom="1px solid #dbdbdb"
						>
							<Flex justifyContent="center" alignItems="center">
								<Text fontSize="x-large" fontWeight="bold" mr="4">
									{t('Add Issued Letter Type')}
								</Text>
							</Flex>
						</Flex>
						<Box p="16px" maxW="480px">
							<Formik
								initialValues={{
									type: '',
									typeEn: '',
									description: '',
								}}
								validationSchema={validationSchema}
								onSubmit={(values) => {
									const variables = {
										type: values.type,
										typeEn: values.typeEn,
										description: values.description,
									};
									createTypeOfDocumentNumber({ variables });
								}}
							>
								{(props) => (
									<Box>
										<Form>
											<TextFormField {...props} label="Type" name="type" required />
											<TextFormField {...props} label="Type En" name="typeEn" />
											<TextFormField {...props} label="Description" name="description" />
											<Button
												id="login-btn"
												variant="solid"
												size="md"
												h="42px"
												w="128px"
												type="submit"
												borderRadius={c.borderRadius}
												isLoading={loading}
											>
												{t('Submit')}
											</Button>
										</Form>
									</Box>
								)}
							</Formik>
						</Box>
					</Box>
				</Center>
			</Container>
		</>
	);
}

export default TypeOfDocumentNumberAdd;
