import React, {useState, useEffect} from "react";
import { useTranslation } from "react-i18next";
import {
    Box, Flex, Button, useToast, Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
} from "@chakra-ui/react";
import c from "../../constant"
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { gql, useMutation } from '@apollo/client';
import { useHistory } from "react-router-dom";
import FileFormField from "../../components/form/FileFormField"
import UploadingModalProgress from "../UploadingModalProgress/UploadingModalProgress"

const UPDATE_MUTATION = gql`
mutation update(
    $file: Upload!,
    $fileSize: Float!,
    $id: String!
){
    changeCompanySubmissionProposalAdmin(
      id:$id
      file: $file
      fileSize: $fileSize
    )
  }
 `

 const MAX_FILE_SIZE = 104857600;

const signUpSchema = Yup.object().shape({
    file: Yup.mixed().test("fileSize", "over limit", (value) => {
        if (value) {
            return value?.size <= MAX_FILE_SIZE
        } else {
            return true
        }
    }).required('required'),
});

function ChengeProposalFile({
    onClose = () => null, callback = () => null, id
}) {
    const history = useHistory();
    const toast = useToast()
    const { t } = useTranslation();


    const [updateMutation, { loading, error, data }] = useMutation(
        UPDATE_MUTATION
    );
    const [progress, setProgress] = useState(0);
    const [uploading, setUploading] = useState(false)

    useEffect(()=>{
        if(data?.changeCompanySubmissionProposalAdmin){
            callback()
            onClose()
        }
    },[data])

    useEffect(() => {
        if (error) {
            setUploading(false)
            if(error?.graphQLErrors?.length>0){
            error?.graphQLErrors.map(({ message }, i) => (
                toast({
                    title: t(message),
                    status: "error",
                    isClosable: true,
                    position: "bottom-right"
                })
            ))
            } else {
                toast({
                    title: t(error?.message?error?.message:"something went wrong"),
                    status: "error",
                    isClosable: true,
                    position: "bottom-right"
                })
            }
        }
    }, [error])

    async function submit(values) {
        setUploading(true)
        let variables = {
            id,
            file: values.file,
            fileSize: values?.file?.size,
        }
       
        let abort;
        updateMutation({
            variables,
            context: {
                fetchOptions: {
                    useUpload: true,
                    onProgress: (ev) => {
                        setProgress(ev.loaded / ev.total);
                    },
                    onAbortPossible: (abortHandler) => {
                        abort = abortHandler;
                    }
                }
            }
        })

        if (abort) {
            abort();
        }
    }

    return (
        <>
            <Modal
                isOpen={true}
                onClose={() => {
                    onClose()
                }}
                size="md"
            >
                <ModalOverlay />
                <ModalContent borderRadius={c.borderRadius} pb="2">
                    <ModalHeader></ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Formik
                            initialValues={{
                                file: null,
                            }}
                            validationSchema={signUpSchema}
                            onSubmit={(values) => {
                                submit(values);
                            }}
                        >
                            {(props) => (
                                <Form>
                                    <Box mt="3">
                                        <FileFormField
                                            {...props}
                                            label="Required Document"
                                            name="file"
                                            maxFileSize={MAX_FILE_SIZE}
                                            required
                                        />

                                        <Flex
                                            mt="6"
                                            alignItems="center"
                                            flex="1"
                                            justifyContent="flex-end"
                                        >
                                            <Button
                                                id="login-btn"
                                                variant="solid"
                                                size="md"
                                                h="42px"
                                                w="128px"
                                                bg="primary.dark"
                                                _hover={{ bg: "primary.dark" }}
                                                color="white"
                                                // borderRadius="31px"
                                                type="submit"
                                                isLoading={loading}
                                                loadingText={t("Add")}
                                                spinnerPlacement="start"
                                            >
                                                {t(`Update`)}
                                            </Button>
                                        </Flex>
                                    </Box>
                                </Form>
                            )}
                        </Formik>
                    </ModalBody>
                </ModalContent>
            </Modal>

            <UploadingModalProgress isOpen={uploading} progress={progress}/>

        </>
    );
}

export default ChengeProposalFile;
