/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useMutation } from '@apollo/client';
import { faList } from '@fortawesome/free-solid-svg-icons';
import {
	Box,
	Button,
	useToast,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	Select,
	useDisclosure,
	Flex,
	Text,
} from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TextFormField from '../../components/form/TextFormField';
import DateFormField from '../form/DateFormField';
import FileFormField from '../../components/form/FileFormField';
import CompanyFormField from '../../components/form/CompanyFormField';
import TextAreaFormField from '../../components/form/TextAreaFormField';
import DownloadAndViewButton from '../../components/Files/DownloadAndViewButton';
import UploadingModalProgress from '../../components/UploadingModalProgress/UploadingModalProgress';
import CompanyPickerModal from '../Modal/CompanyPickerModal';
import c from '../../constant';
import * as Yup from 'yup';

const M_EDIT_LICENSE_OR_FINE = gql`
	mutation EditLicenseOrFine(
		$id: String!
		$companyID: String
		$companyName: String
		$subject: String!
		$amount: Float!
		$currency: String!
		$file: Upload
		$fileSize: Float
		$type: String!
		$invoiceDate: DateTime!
	) {
		editLicenseOrFine(
			id: $id
			companyID: $companyID
			companyName: $companyName
			subject: $subject
			amount: $amount
			currency: $currency
			file: $file
			fileSize: $fileSize
			type: $type
			invoiceDate: $invoiceDate
		)
	}
`;

const validationFileSchema = Yup.object().shape({
	companyID: Yup.string(),
	companyName: Yup.string().when('companyID', {
		is: (companyID) => companyID,
		then: Yup.string(),
		otherwise: Yup.string().required('required'),
	}),
	subject: Yup.string().required('required'),
	amount: Yup.number()
		.typeError('required to input number')
		.positive('required to input number greater than zero')
		.integer('required to input only integer')
		.required('required'),
	file: Yup.mixed()
		.test('fileSize', 'over limited size', (value) => {
			if (value) {
				return value?.size <= c.maxFileSize;
			} else {
				return true;
			}
		})
		.required('required'),
	transactionDate: Yup.string().required('required'),
});

const notValidationFileSchema = Yup.object().shape({
	companyID: Yup.string(),
	companyName: Yup.string().when('companyID', {
		is: (companyID) => companyID,
		then: Yup.string(),
		otherwise: Yup.string().required('required'),
	}),
	subject: Yup.string().required('required'),
	amount: Yup.number()
		.typeError('required to input number')
		.positive('required to input number greater than zero')
		.integer('required to input only integer')
		.required('required'),
	file: Yup.mixed(),
	transactionDate: Yup.string().required('required'),
});

function EditLicenseOrFineModal({ detail = null, isOpen = false, onClose = () => null, callback = () => null }) {
	const toast = useToast();
	const {
		id: detailId,
		company: detailCompany,
		companyName: detailCompanyName,
		subject: detailSubject,
		amount: detailAmount,
		currency: detailCurrency,
		file: detailFile,
		fileSize: detailFileSize,
		type: detailType,
		invoiceDate: detailInvoiceDate,
	} = detail;
	const { t } = useTranslation();
	const { isOpen: isOpenDialog, onOpen: onOpenDialog, onClose: onCloseDialog } = useDisclosure();
	const [company, setCompany] = useState(detailCompany);
	const [currency, setCurrency] = useState(detailCurrency);
	const [isRemoveFile, setRemoveFile] = useState(null === detailFile);
	const [editLicenseOrFine, { loading, error, data }] = useMutation(M_EDIT_LICENSE_OR_FINE);

	useEffect(() => {
		if (data) {
			if (data?.editLicenseOrFine) {
				let title = '';
				switch (detailType?.toLowerCase()) {
					case 'fine':
						title = 'Fine edited successfully';
						break;
					case 'license-fee':
						title = 'License fee edited successfully';
						break;
					default:
						title = 'Revenue edited successfully';
						break;
				}
				toast({
					title: t(title),
					status: 'success',
					isClosable: true,
					position: 'top-right',
				});
				onClose();
				callback();
			}
		}
	}, [data]);

	useEffect(() => {
		if (error) {
			error.graphQLErrors.map(({ message }, i) =>
				toast({
					title: t(message),
					status: 'error',
					isClosable: true,
					position: 'top-right',
				})
			);
		}
	}, [error]);

	async function submit(values) {
		let companyName = values?.companyName;
		if (values?.companyID) {
			companyName = null;
		}
		let variables = {
			id: detailId,
			companyID: values?.companyID,
			companyName: companyName,
			subject: values?.subject,
			amount: parseFloat(values?.amount),
			currency,
			file: values?.file,
			fileSize: values?.file?.size,
			type: detailType,
			invoiceDate: values?.transactionDate,
		};
		editLicenseOrFine({ variables });
	}

	return (
		<>
			<Modal isOpen={isOpen} onClose={onClose} size="md">
				<ModalOverlay />
				<ModalContent borderRadius={c.borderRadius} pb={2}>
					<ModalHeader>{t(`Edit ${detailType}`)}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Formik
							initialValues={{
								companyID: '',
								companyName: detailCompanyName ?? '',
								amount: detailAmount ?? '',
								subject: detailSubject ?? '',
								file: null,
								transactionDate: detailInvoiceDate ?? '',
							}}
							validationSchema={isRemoveFile ? validationFileSchema : notValidationFileSchema}
							onSubmit={(values) => submit(values)}
						>
							{(props) => (
								<Box>
									<Form>
										{null !== company && (
											<CompanyFormField
												{...props}
												label="Company/Institution Name"
												name="companyID"
												required
												callBackClear={() => setCompany(null)}
												callBackList={onOpenDialog}
												selectedCompany={company}
											/>
										)}
										{null === company && (
											<TextFormField
												{...props}
												label="Company/Institution Name"
												name="companyName"
												required
												rightElement={
													<Button
														onClick={onOpenDialog}
														leftIcon={
															<FontAwesomeIcon
																style={{
																	fontSize: 16,
																	cursor: 'pointer',
																}}
																icon={faList}
															/>
														}
														mt="7px"
														variant="solid"
														h="42px"
														mr="3px"
														borderRadius={c.borderRadius}
													>
														{t('List')}
													</Button>
												}
											/>
										)}
										<TextFormField
											{...props}
											label="Amount"
											name="amount"
											rightElement={
												<Select
													variant="filled"
													defaultValue={detailCurrency}
													onChange={function (e) {
														setCurrency(e.target.value);
													}}
													fontWeight={'bold'}
													fontSize={'lg'}
													m={'10px 5px 0 0'}
													p={'0'}
													cursor={'pointer'}
												>
													<option value="KHR">KHR</option>
													<option value="USD">USD</option>
												</Select>
											}
											required
										/>
										<TextAreaFormField {...props} label="Subject" name="subject" required />
										{isRemoveFile && <FileFormField {...props} label="File" name="file" maxFileSize={c.maxFileSize} required />}
										{!isRemoveFile && (
											<Box mb={4}>
												<Flex mb={2}>
													<Text fontSize="md">
														{t('File')}
														{<span style={{ color: 'red' }}>*</span>}
													</Text>
												</Flex>
												<Flex alignItems="center">
													<DownloadAndViewButton marginLeft={0} url={detailFile} size={detailFileSize} />
													<Text ml="6" cursor="pointer" color="blue.500" onClick={() => setRemoveFile(true)}>
														{t('Remove')}
													</Text>
												</Flex>
											</Box>
										)}
										<DateFormField {...props} initialDate={detailInvoiceDate} label="Transaction Date" name="transactionDate" required />
										<Button id="login-btn" variant="solid" mt={2} size="md" h="42px" w="128px" type="submit" borderRadius={c.borderRadius}>
											{t('Submit')}
										</Button>
									</Form>
								</Box>
							)}
						</Formik>
					</ModalBody>
				</ModalContent>
			</Modal>
			<UploadingModalProgress isOpen={loading} />
			{isOpenDialog && <CompanyPickerModal onClose={onCloseDialog} callBack={(e) => setCompany(e)} />}
		</>
	);
}

export default EditLicenseOrFineModal;
