import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Box, Flex, Text, Button, useToast, SimpleGrid, Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure
} from "@chakra-ui/react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import TextFormField from "../../../../components/form/TextFormField"
import DateFormField from "../../../../components/form/DateFormField"
import GeoFormField from "../../../../components/form/GeoFormField"
import CheckboxFormField from "../../../../components/form/CheckBoxFormField"
import TextAreaFormField from "../../../../components/form/TextAreaFormField"
import DateInputFormField from "../../../../components/form/DateInputFormField"

import GeoCodeResoliver from "../../../../components/Geo/GeoCodeResolver"
import PreUploadFileFormField from "../../../../components/form/PreUploadFileFormField"

import DownloadAndViewButtonUserUpload from "../../../../components/Files/DownloadAndViewButtonUserUpload"


import { gql, useMutation } from '@apollo/client';
import moment from "moment"
import useStore from "../../../../store";
import { faSave, faPenSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import c from "../../../../constant";

const UPDATE_EMPLOYEE_MUTATION = gql`
mutation update($id:String!, $spouseStatus: String){
    updateEmployeeSpouseStatus(
      id:$id
      spouseStatus:$spouseStatus
    )
  }
`


const signUpSchema = Yup.object().shape({
    spouseName: Yup.string(),
    spouseNameLatin: Yup.string(),
    spouseStatus: Yup.string(),
    spouseDob: Yup.string().nullable(),
    spouseNationality: Yup.string(),
    spouseCurrentAddress: Yup.object().nullable(),
    spouseIsCurrentAddressManual: Yup.boolean(),
    spouseCurrentAddressManual: Yup.string(),
    spouseJob: Yup.string(),
    spouseJobInstitution: Yup.string(),
    spouseAllowance: Yup.string(),
    spousePhoneNumber: Yup.string(),


});

function SpouseStatus({ id, initData, callbackOnSuccess }) {
    const toast = useToast()
    const { t } = useTranslation();
    const [updateEmployee, { error, data, loading }] = useMutation(
        UPDATE_EMPLOYEE_MUTATION
    );
    const { currentLang } = useStore((state) => state.currentLang);
    moment.locale(currentLang === "kh" ? "km" : "en-gb")

    const [isEditing, setIsEditing] = useState(false)

    const [spouseIsCurrentAddressManual, setFpouseIsCurrentAddressManual] = useState(initData?.spouseIsCurrentAddressManual)

    const {
        isOpen,
        onClose,
        onOpen,
    } = useDisclosure()

    useEffect(() => {
        if (error) {
            if (error?.graphQLErrors?.length > 0) {
                error?.graphQLErrors.map(({ message }, i) => (
                    toast({
                        title: t(message),
                        status: "error",
                        isClosable: true,
                        position: "bottom-right"
                    })
                ))
            } else {
                toast({
                    title: t(error?.message ? error?.message : "something went wrong"),
                    status: "error",
                    isClosable: true,
                    position: "bottom-right"
                })
            }
        }
    }, [error])

    useEffect(() => {
        if (data) {
            if (data.updateEmployeeSpouseStatus) {
                toast({
                    title: t("Officials updated"),
                    description: t("Officials updated successfully"),
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                    position: "bottom-right"
                })
                callbackOnSuccess()
            }
        }
    }, [data])

    function rowData({ labelEN, labelKH, value }) {
        return (
            <Flex alignItems="center">
                <Text fontWeight="bold">{currentLang === "en" ? labelEN : labelKH}</Text>:
                <Text ml="4">{value || ""}</Text>
            </Flex>
        )
    }

    function viewFile(value){
        const tmpValue = value?JSON.parse(value):null
        return tmpValue?<DownloadAndViewButtonUserUpload url={tmpValue?.file} size={tmpValue?.fileSize}/>:"" 
    }

    function displayData() {
        return (
            <>
                <SimpleGrid columns={[1, 2, 3]} spacing={2}>
                    {rowData({ labelEN: "ឈ្មោះប្តី ឫប្រពន្ធ", labelKH: "ឈ្មោះប្តី ឫប្រពន្ធ", value: initData?.spouseName })}
                    {rowData({ labelEN: "ជាអក្សរឡាតាំង", labelKH: "ជាអក្សរឡាតាំង", value: initData?.spouseNameLatin?.toUpperCase() })}
                    {rowData({ labelEN: "ស្ថានភាព", labelKH: "ស្ថានភាព", value: initData?.spouseStatus })}
                    {rowData({ labelEN: "ថ្ងៃខែឆ្នាំកំណើត", labelKH: "ថ្ងៃខែឆ្នាំកំណើត", value: initData?.spouseDob ? moment(initData?.spouseDob).format(c.excelDateOnlyTextFormat) : "" })}
                    {rowData({ labelEN: "សញ្ជាតិ", labelKH: "សញ្ជាតិ", value: initData?.spouseNationality })}
                    {rowData({ labelEN: "ទីលំនៅបច្ចុប្បន្ន", labelKH: "ទីលំនៅបច្ចុប្បន្ន", value: initData?.spouseIsCurrentAddressManual ? initData?.spouseCurrentAddressManual : getAddress(initData?.spouseCurrentAddress) })}
                    {rowData({ labelEN: "មុខរបរ", labelKH: "មុខរបរ", value: initData?.spouseJob })}
                    {rowData({ labelEN: "ស្ថាប័ន/អង្គភាព", labelKH: "ស្ថាប័ន/អង្គភាព", value: initData?.spouseJobInstitution })}
                    {rowData({ labelEN: "ប្រាក់ឧបត្ថម្ភ", labelKH: "ប្រាក់ឧបត្ថម្ភ", value: initData?.spouseAllowance })}
                    {rowData({ labelEN: "លេខទូរសព្ទ", labelKH: "លេខទូរសព្ទ", value: initData?.spousePhoneNumber })}
                </SimpleGrid>
                <SimpleGrid mt="4" columns={[1, 2, 3]} spacing={2}>
                    {rowData({ labelEN: "ឯកសារយោង", labelKH: "ឯកសារយោង", value: viewFile(initData?.document) })}
                </SimpleGrid>
            </>
        )
    }

    function getJsonGeoData(tmpData) {
        if (tmpData) {
            const data = JSON.parse(tmpData)
            return {
                province: data?.province,
                district: data?.district,
                commune: data?.commune,
                village: data?.village,
                streetNumber: data?.streetNumber,
                houseNumber: data?.houseNumber,
            }
        } else {
            return null
        }
    }

    function geoObjectToValue(obj) {
        return JSON.stringify({
            province: obj?.province?.value || (obj?.province || ""),
            district: obj?.district?.value || (obj?.district || ""),
            commune: obj?.commune?.value || (obj?.commune || ""),
            village: obj?.village?.value || (obj?.village || ""),
            streetNumber: obj?.streetNumber || "",
            houseNumber: obj?.houseNumber || ""
        })
    }

    function getAddress(stringData) {
        if (stringData) {
            const data = JSON.parse(stringData)
            return (<GeoCodeResoliver
                province={data?.province}
                district={data?.district}
                commune={data?.commune}
                village={data?.village}
                houseNumber={data?.houseNumber}
                streetNumber={data?.streetNumber}
            />)
        } else {
            return null
        }
    }

    return (
        <>

            <Box p="26px" pt="0">
                <Flex mt="4" mb="4" alignItems="center">

                    <Text fontSize="lg" fontWeight="bold" mt="4" mb="4" color="#2980b9">គ. ព័ត៌មានសហព័ន្ធ</Text>

                    {!isEditing && <Button
                        variant="outline"
                        size="sm"
                        ml="4"
                        borderRadius={"0px !important"}
                        leftIcon={<FontAwesomeIcon icon={faPenSquare} style={{ fontSize: 14 }} />}
                        onClick={() => onOpen()} colorScheme='whatsapp'
                    >
                        {t(`Edit`)}
                    </Button>}
                </Flex>

                {
                    !isEditing && <>
                        {displayData()}
                    </>
                }
                <Modal scrollBehavior="inside" size="5xl" isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay
                        backdropFilter='auto'
                        bg='blackAlpha.300'
                        backdropBlur='2px'
                    />
                    <Formik
                        initialValues={{
                            spouseName: initData?.spouseName || "",
                            spouseNameLatin: initData?.spouseNameLatin || "",
                            spouseStatus: initData?.spouseStatus || "",
                            spouseDob: initData?.spouseDob || "",
                            spouseNationality: initData?.spouseNationality || "",
                            spouseCurrentAddress: initData?.spouseCurrentAddress ? getJsonGeoData(initData?.spouseCurrentAddress) : null,
                            spouseIsCurrentAddressManual: initData?.spouseIsCurrentAddressManual || false,
                            spouseCurrentAddressManual: initData?.spouseCurrentAddressManual || "",
                            spouseJob: initData?.spouseJob || "",
                            spouseJobInstitution: initData?.spouseJobInstitution || "",
                            spouseAllowance: initData?.spouseAllowance || "",
                            spousePhoneNumber: initData?.spousePhoneNumber || "",
                            document: initData?.document||""

                        }}
                        validationSchema={signUpSchema}
                        onSubmit={(values) => {
                            updateEmployee({
                                variables: {
                                    id,
                                    spouseStatus: JSON.stringify({
                                        spouseName: values?.spouseName,
                                        spouseNameLatin: values?.spouseNameLatin,
                                        spouseStatus: values?.spouseStatus,
                                        spouseDob: values?.spouseDob,
                                        spouseNationality: values?.spouseNationality,
                                        spouseCurrentAddress: values?.spouseCurrentAddress ? geoObjectToValue(values?.spouseCurrentAddress) : "",
                                        spouseIsCurrentAddressManual: values?.spouseIsCurrentAddressManual,
                                        spouseCurrentAddressManual: values?.spouseCurrentAddressManual,
                                        spouseJob: values?.spouseJob,
                                        spouseJobInstitution: values?.spouseJobInstitution,
                                        spouseAllowance: values?.spouseAllowance,
                                        spousePhoneNumber: values?.spousePhoneNumber,
                                        document: values?.document,


                                    })
                                }
                            })
                        }}
                    >
                        {(props) => (
                            <Form>
                                <ModalContent borderRadius={c.borderRadius}>
                                    <ModalHeader>{t("Edit")}</ModalHeader>
                                    <ModalCloseButton />
                                    <ModalBody>
                                        <Box>
                                            <SimpleGrid columns={[1, 2, 3]} spacing={2}>
                                                {/* spouse */}
                                                <TextFormField
                                                    {...props}
                                                    label="ឈ្មោះប្តី ឫប្រពន្ធ"
                                                    name="spouseName"
                                                />

                                                <TextFormField
                                                    {...props}
                                                    label="ជាអក្សរឡាតាំង"
                                                    name="spouseNameLatin"
                                                />

                                                <TextFormField
                                                    {...props}
                                                    label="ស្ថានភាព"
                                                    name="spouseStatus"
                                                />

                                                <DateInputFormField
                                                    {...props}
                                                    label="ថ្ងៃខែឆ្នាំកំណើត"
                                                    name="spouseDob"
                                                    initialDate={initData?.spouseDob}
                                                />

                                                <TextFormField
                                                    {...props}
                                                    label="សញ្ជាតិ"
                                                    name="spouseNationality"
                                                />

                                                {!spouseIsCurrentAddressManual && <GeoFormField
                                                    {...props}
                                                    name="spouseCurrentAddress"
                                                    label="ទីលំនៅបច្ចុប្បន្ន"
                                                    buttomComponent={
                                                        <CheckboxFormField
                                                            {...props}
                                                            label="Input"
                                                            name="spouseIsCurrentAddressManual"
                                                            callbackValueChange={e => setFpouseIsCurrentAddressManual(e)}
                                                        />
                                                    }
                                                />}

                                                {spouseIsCurrentAddressManual && <TextAreaFormField
                                                    {...props}
                                                    label="ទីលំនៅបច្ចុប្បន្ន"
                                                    name="spouseCurrentAddressManual"
                                                    buttomComponent={
                                                        <CheckboxFormField
                                                            {...props}
                                                            label="Input"
                                                            name="spouseIsCurrentAddressManual"
                                                            callbackValueChange={e => setFpouseIsCurrentAddressManual(e)}
                                                        />
                                                    }
                                                />}


                                                <TextFormField
                                                    {...props}
                                                    label="មុខរបរ"
                                                    name="spouseJob"
                                                />

                                                <TextFormField
                                                    {...props}
                                                    label="ស្ថាប័ន/អង្គភាព"
                                                    name="spouseJobInstitution"
                                                />

                                                <TextFormField
                                                    {...props}
                                                    label="ប្រាក់ឧបត្ថម្ភ"
                                                    name="spouseAllowance"
                                                />

                                                <TextFormField
                                                    {...props}
                                                    label="លេខទូរសព្ទ"
                                                    name="spousePhoneNumber"
                                                />

                                            </SimpleGrid>
                                            <PreUploadFileFormField
                                                {...props}
                                                label={"ឯកសារយោង"}
                                                name={"document"}
                                            />
                                        </Box>
                                    </ModalBody>
                                    <ModalFooter bg="rgba(236, 240, 241,0.5)">
                                        <Button
                                            borderRadius={c.borderRadius}
                                            colorScheme='green'
                                            type="submit"
                                            isLoading={loading}
                                            loadingText={t("Save")}
                                            spinnerPlacement="start"
                                        >
                                            {t("Save")}
                                        </Button>
                                    </ModalFooter>
                                </ModalContent>
                            </Form>
                        )}
                    </Formik>
                </Modal>
            </Box>
        </>
    );
}

export default SpouseStatus;