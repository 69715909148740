import React from "react";
import { Input, Box, FormControl, FormLabel, FormHelperText, InputGroup, InputRightElement } from "@chakra-ui/react";
import { Field } from "formik";
import { useTranslation } from "react-i18next";
import PrivateFilePicker from "../../components/PrivateFilePicker/PrivateFilePicker"

const PreUploadFileFormField = props => {
    const {
        name,
        type,
        label,
        placeholder,
        required,
        rightElement,
        min,
        maxFileSize = 5242880,
        accept="image/jpeg,image/gif,image/png,application/pdf,.zip,.rar,.7zip"
    } = props;
    const { t } = useTranslation();

    
    return (
        <Field name={name}>
            {({ field, meta, form }) => (
                <Box mb="4">
                    <FormControl id={name} >
                        <FormLabel>{t(label)}{required&&<span style={{color:"red"}}>*</span>}</FormLabel>
                        <InputGroup size='md'>
                            
                        <PrivateFilePicker
                            initContent={null}
                            onContentChange={(content) =>{
                                if(content){
                                    form.setFieldValue(name, JSON.stringify(content))
                                }
                            }}
                        />
                        {/* <Input
                            accept={accept}
                            pt="4px"
                            id={name}
                            name={name}
                            type="file"
                            onChange={e => {
                                e.preventDefault();
                                let reader = new FileReader();
                                let file = e.target.files[0];
                                if (file) {
                                    reader.readAsDataURL(file);
                                    console.log("name, file:", name, file)
                                    // form.setFieldValue(name, file);
                                }
                            }}
                        /> */}
                            {rightElement&&<InputRightElement width='4.5rem'>
                                {rightElement}
                            </InputRightElement>}
                        </InputGroup>
                        {meta.touched && meta.error && (
                            <FormHelperText
                                id={`error-message-${name}`}
                                color="red.400"
                                fontSize="12px"
                            >
                                {t(name)}{" "}
                                {t("is")}{" "}
                                {t(meta.error)}
                            </FormHelperText>
                        )}
                    </FormControl>

                </Box>
            )}
        </Field>
    );
};

export default PreUploadFileFormField;
