import React, { useState } from "react";
import { Box, FormControl, FormLabel, FormHelperText, Button } from "@chakra-ui/react";
import { Field } from "formik";
import "@amir04lm26/react-modern-calendar-date-picker/lib/DatePicker.css";
import { Calendar } from "@amir04lm26/react-modern-calendar-date-picker";
import moment from "moment";
import "moment/locale/en-gb";
import c from "../../constant"
import { useTranslation } from "react-i18next";
import useStore from "../../store"

const DateRangeFormField = props => {
	moment.locale("en-gb")
	const { t } = useTranslation();
	const { currentLang } = useStore((state) => state.currentLang);
	const {
		name,
		label,
		required,
		initialDate
	} = props;
	const [show, setShow] = useState((initialDate.fromDate && initialDate.toDate) ? true : false)

	let defaultRange = {
		from: null,
		to: null,
	};

	if (initialDate.fromDate && initialDate.toDate) {
		const defaultFrom = JSON.parse(initialDate.fromDate);

		const defaultTo = JSON.parse(initialDate.toDate);
		defaultRange = {
			from: defaultFrom,
			to: defaultTo
		}
	}

	const [selectedDayRange, setSelectedDayRange] = useState(
		defaultRange
	);

	return (
		<Field name={name}>
			{({ field, meta, form }) => (
				<Box mb="4">
					<FormControl id={name} >
						<FormLabel>{t(label)}{required && <span style={{ color: "red" }}>*</span>}</FormLabel>
						<Button onClick={() => setShow(!show)} variant="solid" borderRadius={c.borderRadius} border="1px solid gray.200" w="100%">{!show ? `↓ ${t("Date range")}` : `↑ ${t("Date range")}`}</Button>
						{show &&
							<Box mt="4">
								<Calendar
									value={selectedDayRange}
									onChange={e => {
										setSelectedDayRange(e)
										form.setFieldValue(name, JSON.stringify(e))
									}}
									shouldHighlightWeekends
									locale={currentLang === 'kh' ? myCustomLocale : 'en'}
								/></Box>}
						{meta.touched && meta.error && (
							<FormHelperText
								id="error-message-password"
								color="red.400"
								fontSize="12px"
							>
								{(name)}{" "}
								{("is")}{" "}
								{(meta.error)}
							</FormHelperText>
						)}
					</FormControl>
				</Box>
			)}
		</Field>
	);
};

export default DateRangeFormField;

const myCustomLocale = {
	// months list by order
	months: [
		'មករា',
		'ខែកុម្ភៈ',
		'មីនា',
		'មេសា',
		'ឧសភា',
		'មិថុនា',
		'កក្កដា',
		'សីហា',
		'កញ្ញា',
		'តុលា',
		'វិច្ឆិកា',
		'ធ្នូ',
	],

	// week days by order
	weekDays: [
		{
			name: 'អាទិត្យ', // used for accessibility 
			short: 'អាទិត្យ', // displayed at the top of days' rows
			isWeekend: true, // is it a formal weekend or not?
		},
		{
			name: 'ច័ន្ទ',
			short: 'ច័ន្ទ',
		},
		{
			name: 'អង្គារ',
			short: 'អង្គារ',
		},
		{
			name: 'ពុធ',
			short: 'ពុធ',
		},
		{
			name: 'ព្រហស្បតិ៍',
			short: 'ព្រហស្បតិ៍',
		},
		{
			name: 'សុក្រ',
			short: 'សុក្រ',
		},
		{
			name: 'សៅរ៍',
			short: 'សៅរ៍',
			isWeekend: true,
		},
	],

	// just play around with this number between 0 and 6
	weekStartingIndex: 0,

	// return a { year: number, month: number, day: number } object
	getToday(gregorainTodayObject) {
		return gregorainTodayObject;
	},

	// return a native JavaScript date here
	toNativeDate(date) {
		return new Date(date.year, date.month - 1, date.day);
	},

	// return a number for date's month length
	getMonthLength(date) {
		return new Date(date.year, date.month, 0).getDate();
	},

	// return a transformed digit to your locale
	transformDigit(digit) {
		return digit;
	},

	// texts in the date picker
	nextMonth: 'Next Month',
	previousMonth: 'Previous Month',
	openMonthSelector: 'Open Month Selector',
	openYearSelector: 'Open Year Selector',
	closeMonthSelector: 'Close Month Selector',
	closeYearSelector: 'Close Year Selector',
	defaultPlaceholder: 'Select...',

	// for input range value
	from: 'from',
	to: 'to',


	// used for input value when multi dates are selected
	digitSeparator: ',',

	// if your provide -2 for example, year will be 2 digited
	yearLetterSkip: 0,

	// is your language rtl or ltr?
	isRtl: false,
}