import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Container, Center, Tooltip, Box, Flex, Text, useToast, useClipboard, Input, Textarea, Button, Tabs, TabList, TabPanels, Tab, TabPanel, Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
} from "@chakra-ui/react";
import BreadcrumbComponent from "../../../components/BreadcrumbComponent/BreadcrumbComponent";
import LoadingTable from "../../../components/Table/Loading";
import moment from "moment"
import PublicFormExportModal from "../../../components/Modal/PublicFormExportModal"
import { useLocation, useHistory } from "react-router-dom";
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import ReactPaginate from 'react-paginate';
import '../style.css'
import c from "../../../constant"
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import JoditEditor from "jodit-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";

const limit = 20
const QUERY_PUBLIC_FORM = gql`
query getPublicFormById($id:String!){
    getPublicFormById(
        id:$id
    ){
        id,
        title,
        header,
        formSetting
        emailContent
    }
  }
`

const QUERY_PUBLIC_FORM_DATA = gql`
query getPublicFormDataList($id:String!, $offset:Float){
    getPublicFormDataList(
        id:$id,
        pagerInput:{
            limit:${limit}
            offset:$offset
        }
    ){
        totalPages
        publicForms{
            id
            publicFormId
            createdAt
            userInputData
        }
    }
  }
`



const UPDATE_PUBLIC_FORM_MUTATION = gql`
    mutation up(
        $id: String!
        $title: String!
        $header: String!
        $emailContent: String
        $formSetting: JSONObject
    ){
        editPublicForm(id:$id,publicFormInput:{
          title:$title,
          header:$header,
          emailContent: $emailContent,
          formSetting:$formSetting
        })
      }
`

function PublicFormList() {
    const toast = useToast()
    const { t } = useTranslation();
    const location = useLocation();
    const history = useHistory();
    const [loadPublicForm, { error, loading, data }] = useLazyQuery(QUERY_PUBLIC_FORM)
    const urlParams = new URLSearchParams(location.search);
    const id = urlParams.get('id');
    const currentPage = urlParams.get('page') ? urlParams.get('page') : 1;
    const [loadPublicFormData, { error: errorData, loading: loadingData, data: dataPublicForm }] = useLazyQuery(QUERY_PUBLIC_FORM_DATA)
    const [updatePublicForm, { loading: loadingUpdatePF, error: errorUpdatePF, data: dataUpdatePF }] = useMutation(
        UPDATE_PUBLIC_FORM_MUTATION
    );
    const [urlValue, setUrlValue] = React.useState('')
    const { hasCopied, onCopy } = useClipboard(urlValue)
    const [formSettingError, setFormSettingError] = useState(false)
    const [formSetting, setFormSetting] = useState([])
    const [formTitle, setFormTitle] = useState("")
    const [richText, setRichText] = useState("")
    const [richTextEmail, setRichTextEmail] = useState("")
    const [tableDataKey, setTableDataKey] = useState([])
    const [openPublicFormExportModal, setOpenPublicFormExportModal] = useState(false)

    useEffect(() => {
        window.scrollTo(0, 0);
        loadPublicForm({
            variables: {
                id
            }
        })
        loadPublicFormData({
            variables: {
                id,
                offset: currentPage ? ((currentPage - 1) * limit) : 0,
            }
        })


    }, []);

    useEffect(() => {
        loadPublicFormData({
            variables: {
                id,
                offset: currentPage ? ((currentPage - 1) * limit) : 0,
            }
        })
    }, [currentPage])

    useEffect(() => {
        if (data?.getPublicFormById) {
            let baseUrl = c.env === "production" ? "https://irc.gov.kh/form?id=" : "http://localhost:3009/form?id="
            setUrlValue(baseUrl + data?.getPublicFormById?.id)


            setRichText(data?.getPublicFormById?.header)
            setRichTextEmail(data?.getPublicFormById?.emailContent)
            setFormTitle(data?.getPublicFormById?.title)
            setFormSetting(JSON.stringify(data?.getPublicFormById?.formSetting, undefined, 4))
        }
    }, [data])

    useEffect(() => {
        if (dataUpdatePF?.editPublicForm) {
            toast({
                title: "Public form edited.",
                status: "success",
                duration: 5000,
                isClosable: true,
                position: "top-right"
            })
            // setTimeout(() => {
            //     history.push(`/website/public-form-list`);
            // }, 200);
        }
    }, [dataUpdatePF])


    useEffect(() => {
        if (error) {
            error?.graphQLErrors?.map(({ message }, i) => (
                toast({
                    title: message,
                    status: "error",
                    isClosable: true,
                    position: "top-right"
                })
            ))
        }
    }, [error])

    function isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    useEffect(() => {
        if (dataPublicForm?.getPublicFormDataList?.publicForms?.length > 0) {
            const tmpKey = []
            let tmpData = dataPublicForm?.getPublicFormDataList?.publicForms[0]?.userInputData
            for (var k in tmpData) {
                if (tmpData.hasOwnProperty(k)) {
                    tmpKey.push(k)
                }
            }
            setTableDataKey(tmpKey)
        }
    }, [dataPublicForm])

    function handlePageClick(data) {
        let selected = data.selected;
        const page = selected + 1

        let url = `?page=${page}&id=${id}`

        history.push(`/website/public-form-detail${url}`)
    }


    function editFormSetting() {
        return <>
            <Box
                w="100%"
                bg="white"
                borderRadius={c.borderRadius}
                border="1px solid #dbdbdb"
                boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
                pb="32px"
            >
                <Flex direction="row" bg="#FAFAFA" p="16px" pt="8px" pb="8px" mb="16px" justifyContent="space-between" borderBottom="1px solid #dbdbdb">
                    <Text
                        fontSize="x-large"
                        fontWeight="bold"
                    >
                        {t("Add Public Form")}
                    </Text>
                </Flex>
                <Box m="4">
                    <JoditEditor
                        value={richText}
                        onChange={newContent => {
                            setRichText(newContent)
                        }}
                    />
                </Box>

                <Box m="4">
                    <Text>{t("Email")}</Text>
                    <JoditEditor
                        value={richTextEmail}
                        onChange={newContent => {
                            setRichTextEmail(newContent)
                        }}
                    />
                </Box>

                <Box m="4">
                    <Input value={formTitle} placeholder='Title' onChange={e => {
                        setFormTitle(e?.target?.value)
                    }} />
                    <Box mt="6">
                        <a target="_blank" href="https://formbuilder.online/docs/formBuilder/demos/react/">https://formbuilder.online/docs/formBuilder/demos/react/</a>
                    </Box>
                    <Textarea
                        minH="300px"
                        // value={formSetting}
                        defaultValue={formSetting}
                        mt="2"
                        placeholder='JSON FORM SETTING'
                        onChange={e => {
                            setFormSetting(e?.target?.value)
                            setFormSettingError(!(isJson(e?.target?.value)))
                        }}
                    />
                    {formSettingError && <Text color="red">Form setting error</Text>}
                </Box>

                <Flex m="4">
                    <Flex w="100%" justifyContent="flex-end">
                        <Button
                            id="login-btn"
                            variant="solid"
                            size="md"
                            h="42px"
                            w="128px"
                            type="submit"
                            borderRadius={c.borderRadius}
                            mt="8"
                            colorScheme='blue'  
                            isLoading={loadingUpdatePF}
                            onClick={() => {
                                updatePublicForm({
                                    variables: {
                                        id,
                                        title: formTitle,
                                        header: richText,
                                        formSetting: JSON?.parse(formSetting),
                                        emailContent: richTextEmail
                                    }
                                })
                            }}
                        >
                            {t("Save")}
                        </Button>
                    </Flex>
                </Flex>
            </Box>
        </>
    }

    function getJSONLabelFromFormSettingByName(name) {
        let res = null
        const tmpFormSetting = JSON.parse(formSetting)
        if (tmpFormSetting?.form) {
            tmpFormSetting["form"]?.map((item) => {
                if (name === item?.name) {
                    res = item?.label
                }
            })
        }
        return res
    }

    function getJSONTypeFromFormSettingByName(name) {
        let res = ""
        const tmpFormSetting = JSON.parse(formSetting)
        if (tmpFormSetting?.form) {
            tmpFormSetting["form"]?.map((item) => {
                if (name === item?.name) {
                    res = item?.type
                }
            })
        }
        return res
    }


    function getTDJSON(item) {
        let tmpVal = []
        let tmpType = []
        for (var k in item) {
            if (item.hasOwnProperty(k)) {
                tmpVal.push(item[k])
                let type = getJSONTypeFromFormSettingByName(k)
                tmpType.push(type)
            }
        }
        return <>
            {
                tmpVal.map((data, index) => {
                    return (
                        <Td key={`json-td-${index}`}>
                            {displayDataByType(data, tmpType[index])}
                        </Td>
                    )
                })
            }
        </>
    }

    function displayDataByType(data, type) {
        switch (type) {
            case "date":
                return data?moment(data).format(c.dateOnlyFormat):""
            default:
                return data
        }
    }

    return (
        <>
            <Container maxW="container.xl" mt="16px" mb="16px">
                <BreadcrumbComponent
                    list={[
                        {
                            name: "WEBSITE",
                            path: "/website",
                        },
                        {
                            name: "Public Form",
                            path: "/website/public-form-list",
                        },
                        {
                            name: "Detail",
                            path: "#",
                        },
                    ]}
                />
                <Center mt="5">
                    <Box
                        w="100%"
                        bg="white"
                        borderRadius={c.borderRadius}
                        border="1px solid #dbdbdb"
                        boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
                        pb="32px"
                    >
                        <Flex direction="row" alignItems="center" bg="#FAFAFA" p="16px" pt="8px" pb="8px" mb="16px" justifyContent="space-between" borderBottom="1px solid #dbdbdb">
                            <Text
                                fontSize="x-large"
                                fontWeight="bold"
                            >
                                {t("Public Form Detail")}
                            </Text>

                            <Tooltip label={t("Export Form")}>
                                <Box>
                                    <FontAwesomeIcon onClick={() => setOpenPublicFormExportModal(true)} icon={faFileExport} style={{ fontSize: 18, marginRight: 16, cursor: "pointer", color: "black" }} />
                                </Box>
                            </Tooltip>
                        </Flex>
                        {!error &&
                            <Box p="16px">
                                <Flex mb={2}>
                                    <Input value={urlValue} isReadOnly placeholder='Welcome' />
                                    <Button onClick={onCopy} ml={2}>
                                        {hasCopied ? 'Copied' : 'Copy'}
                                    </Button>
                                </Flex>
                            </Box>
                        }
                        <Box m="4">
                            <Tabs isFitted >
                                <TabList mb='1em'>
                                    <Tab _selected={{ color: 'white', bg: 'blue.500' }}>{t("List")}</Tab>
                                    <Tab _selected={{ color: 'white', bg: 'blue.500' }}>{t("Form Setting")}</Tab>
                                </TabList>
                                <TabPanels>
                                    <TabPanel>
                                        <Center mt="5">
                                            <Box
                                                w="100%"
                                                bg="white"
                                                borderRadius={c.borderRadius}
                                                border="1px solid #dbdbdb"
                                                boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
                                                pb="32px"
                                            >
                                                {/* <Flex bg="#FAFAFA" p="16px" pt="8px" pb="8px" mb="16px" alignItems="center" justifyContent="space-between" borderBottom="1px solid #dbdbdb">
                                            <Text
                                                fontSize="x-large"
                                                fontWeight="bold"
                                            >
                                                {t("Complaint")} {data?.getComplaints?.countUnreadComplaints>0&&`(${data?.getComplaints?.countUnreadComplaints})`}
                                            </Text>
                                            <FontAwesomeIcon onClick={()=>setOpenFilter(true)} icon={faSlidersH} style={{fontSize:18, cursor: "pointer", color:(fromDate || toDate || read || status || showSpam)?"#c0392b":"black"}}/>
                                        </Flex> */}

                                                <Box p="16px" overflowX="scroll">
                                                    <Table variant="simple" size='sm' overflowX="scroll">
                                                        {/* <TableCaption>Imperial to metric conversion factors</TableCaption> */}
                                                        <Thead>
                                                            <Tr>
                                                                <Th>{t("Created At")}</Th>
                                                                {
                                                                    tableDataKey?.map((hItem, index) => {
                                                                        return (
                                                                            <Th key={`k-header-${index}`}>{getJSONLabelFromFormSettingByName(hItem)}</Th>
                                                                        )
                                                                    })
                                                                }
                                                            </Tr>

                                                        </Thead>
                                                        <Tbody>
                                                            {loadingData && <LoadingTable column={4} />}
                                                            {
                                                                dataPublicForm?.getPublicFormDataList?.publicForms.length <= 0 && <Tr>
                                                                    <Td><Text>{t("No Data")}</Text></Td>
                                                                </Tr>
                                                            }
                                                            {dataPublicForm?.getPublicFormDataList?.publicForms?.map((item, index) => {
                                                                return (
                                                                    <Tr cursor="pointer" _hover={{ bg: "gray.200" }} key={`complaint-${index}`} >
                                                                        <Td fontWeight="500">
                                                                            {moment(item.createdAt).format("LLLL")}
                                                                        </Td>
                                                                        {
                                                                            getTDJSON(item?.userInputData)
                                                                        }
                                                                    </Tr>
                                                                )
                                                            })}
                                                        </Tbody>
                                                        <Tfoot>
                                                            <Tr>
                                                                <Th>{t("Created At")}</Th>
                                                                {
                                                                    tableDataKey?.map((hItem, index) => {
                                                                        return (
                                                                            <Th key={`k-header-${index}`}>{getJSONLabelFromFormSettingByName(hItem)}</Th>
                                                                        )
                                                                    })
                                                                }
                                                            </Tr>
                                                            {/* <Tr>
                                                        
                                                        <Th isNumeric>                                   
                                                            {t("Total Rows")}: {dataPublicForm?.getPublicFormDataList?.totalRows}
                                                        </Th>
                                                    </Tr> */}
                                                        </Tfoot>
                                                    </Table>
                                                    <Flex justifyContent="flex-end" w="100%" mt="4">
                                                        {dataPublicForm?.getPublicFormDataList?.totalPages > 1 && <ReactPaginate
                                                            previousLabel={<ChevronLeftIcon fontSize="20px" />}
                                                            nextLabel={<ChevronRightIcon fontSize="20px" />}
                                                            breakLabel={'...'}
                                                            breakClassName={'break-me'}
                                                            pageCount={dataPublicForm?.getPublicFormDataList?.totalPages}
                                                            marginPagesDisplayed={2}
                                                            pageRangeDisplayed={3}
                                                            onPageChange={handlePageClick}
                                                            containerClassName={'pagination'}
                                                            activeClassName={'active'}
                                                            initialPage={(currentPage - 1) | 0}
                                                        />}
                                                    </Flex>
                                                </Box>
                                            </Box>
                                        </Center>
                                    </TabPanel>
                                    <TabPanel>
                                        {editFormSetting()}
                                    </TabPanel>
                                </TabPanels>
                            </Tabs>
                        </Box>
                    </Box>
                </Center>
            </Container>
            {
                openPublicFormExportModal && 
                <PublicFormExportModal isOpen={true} id={id} onClose={() => setOpenPublicFormExportModal(false)} />
            }
        </>
    );
}

export default PublicFormList;
