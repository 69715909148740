import React, { useState, useRef, useEffect } from 'react';
import { Flex, Tooltip, Text, Box, useToast, List, ListItem, ListIcon, OrderedList, UnorderedList } from '@chakra-ui/react';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import c from '../../constant';
import DownloadAndViewButton from '../Files/DownloadAndViewButton';
import ChengeProposalStepFile from "./ChengeProposalStepFile";
import useStore from '../../store';

const maxFileSize = 10485760 * 10;
const leftLabelWidth = '165px';
const fileColor = '#7f8c8d';

const QUERY_STEP_LABEL = gql`
	query get($ids: JSON!) {
		getStepFileLabelByArrayId(ids: $ids)
	}
`;

export default function StepFileDetail({ data, id, callbackReload }) {
	const { t } = useTranslation();
	const toast = useToast();
	const fileInput = useRef(null);
	const [loadStepLabel, { error, loading, data: stepLabel }] = useLazyQuery(QUERY_STEP_LABEL);
	const [ids, setIds] = useState([]);
	const [changingFile, setChangingFile] = useState(null)
	const [parentId, setParentId] = useState(null)
	const { currentUser } = useStore((state) => state.currentUser);

	useEffect(() => {
		let res = [];
		data?.map((item) => {
			if (item?.type === 'file') {
				res.push(item?.id);
			} else if (item?.type === 'checklist') {
				res.push(item?.id);
				item?.list?.map((sub) => {
					res.push(sub?.id);
				});
			}
		});
		setIds(res);
	}, []);

	useEffect(() => {
		loadStepLabel({
			variables: { ids },
		});
	}, [ids]);

	function getLabelById(id) {
		return (
			<>
				<Text p="2">
					<pre style={{ whiteSpace: 'pre-wrap', fontSize: 14, fontFamily: 'Rajdhani, Hanuman, sans-serif, Moul !important' }}>
						{stepLabel?.getStepFileLabelByArrayId[id]}
					</pre>
				</Text>
			</>
		);
	}

	return (
		<>
			<Flex mt="2" className="step-file-detail">
				<Text mt="2" fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
					{t('Files For The Service')}
				</Text>
				<Flex className="list-of-step" direction="column" ml="4">
					<UnorderedList spacing={2}>
						{data?.map((item, index) => (
							<ListItem _hover={{ bg: '#ecf0f1' }}>
								{item?.type === 'file' && (
									<>
										{getLabelById(item?.id)}
										{item?.url ? (
											<Flex alignItems="center" ml="20px">
												<Text
													onClick={() => {
														if (currentUser?.role === "admin") {
															setChangingFile(item)
														}
													}}
													color={fileColor}
												>
													{t('File')}:
												</Text>
												<DownloadAndViewButton url={item?.url} size={item?.size} />
											</Flex>
										) : (
											<Flex alignItems="center" ml="20px">
												<Text color={fileColor}>
													{t('File')}: <b>N/A</b>
												</Text>
											</Flex>
										)}
									</>
								)}
								{item?.type === 'checklist' && (
									<Box>
										{getLabelById(item?.id)}
										{item?.checked && <></>}
										<Box m="2" mt="0">
											<UnorderedList listStyleType="circle" spacing={2}>
												{item?.list?.map((sub, sIndex) => {
													return (
														<ListItem>
															{getLabelById(sub?.id)}
															{sub?.url ? (
																<Flex alignItems="center" ml="20px">
																	<Text onClick={() => {
																		if (currentUser?.role === "admin") {
																			setChangingFile(sub)
																			setParentId(item?.id)
																		}
																	}} color={fileColor}>{t('File')}:</Text>
																	<DownloadAndViewButton url={sub?.url} size={sub?.size} />
																</Flex>
															) : (
																<Flex alignItems="center" ml="20px">
																	<Text color={fileColor}>
																		{t('File')}: <b>N/A</b>
																	</Text>
																</Flex>
															)}
														</ListItem>
													);
												})}
											</UnorderedList>
										</Box>
									</Box>
								)}
							</ListItem>
						))}
					</UnorderedList>
				</Flex>
			</Flex>

			{changingFile && <ChengeProposalStepFile onClose={() => {
				setChangingFile(null)
				setParentId(null)
			}} parentId={parentId}
				changingFile={changingFile}
				id={id}
				label={getLabelById(changingFile?.id)}
				callback={() => callbackReload()}
			/>}
		</>
	);
}
