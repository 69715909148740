/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Box, Flex, Text, Badge, Center } from '@chakra-ui/react';
import { faList, faUserPlus, faUserMinus, faUserEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useLocation, useHistory } from 'react-router-dom';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import PrintLayout from '../../components/Print/PrintLayout';
import BreadcrumbComponent from '../../components/BreadcrumbComponent/BreadcrumbComponent';
import { Link } from 'react-router-dom';
import c from '../../constant';
import useStore from '../../store';


const QUERY_COUNT = gql`
query{
  getCountCompanySaleProfessionalNewRequest
  getCountCompanySaleProfessionalTerminationRequest
}
`

function List() {
	const { t } = useTranslation();
	const location = useLocation();
	const history = useHistory();
	const { currentUser } = useStore((state) => state.currentUser);


	const [countNew, setCountNew] = useState(0)
	const [countTerminate, setCountTerminate] = useState(0)

	const [loadCount, { data }] = useLazyQuery(QUERY_COUNT)
	useEffect(() => {
		loadCount()
	}, [])

	useEffect(() => {
		if (data) {
			if (parseInt(currentUser?.position) >= 5) {
				if (data?.getCountCompanySaleProfessionalNewRequest) {
					const tempNew = JSON.parse(data?.getCountCompanySaleProfessionalNewRequest)
					setCountNew(tempNew?.reviewingByOffice)
				}

				if (data?.getCountCompanySaleProfessionalTerminationRequest) {
					const tempTerminate = JSON.parse(data?.getCountCompanySaleProfessionalTerminationRequest)
					setCountTerminate(tempTerminate?.reviewingByOffice)
				}
			}
			if (parseInt(currentUser?.position) <= 4) {
				if (data?.getCountCompanySaleProfessionalNewRequest) {
					const tempNew = JSON.parse(data?.getCountCompanySaleProfessionalNewRequest)
					setCountNew(tempNew?.reviewingByDepartment)
				}
			}
		}
	}, [data])

	function getStatusValue() {
		if (parseInt(currentUser?.position) >= 5) {
			return 'reviewing_by_office'
		}
		if (parseInt(currentUser?.position) <= 4) {
			return 'reviewing_by_department'
		}
		return 'all'
	}

	const menu = [
		{
			title: 'New Request',
			path: `/company-sale-professional/new/list?page=1&status=${getStatusValue()}`,
			icon: faUserPlus,
		},
		{
			title: 'TERMINATE_CONTRACT',
			path: `/company-sale-professional/termination/list?page=1&status=reviewing_by_office`,
			icon: faUserMinus,
		},
		{
			title: 'List',
			path: `/company-sale-professional/list?page=1&status=approved`,
			icon: faList,
		},
	];

	function getBadge(item) {
		
		switch (item?.title) {
			case 'New Request':
				return (
					countNew > 0 ? <Badge colorScheme="red" variant="solid" ml={1}>
						{countNew}
					</Badge> : null
				)
			case 'TERMINATE_CONTRACT':
				return (
					countTerminate > 0 ? <Badge colorScheme="red" variant="solid" ml={1}>
						{countTerminate}
					</Badge> : null
				)
			default:
				return null
		}
	}

	function menuItem({ item, index }) {
		return (
			<Link key={`menu-${index}`} to={item.path}>
				<Flex
					h="64px"
					alignItems="center"
					bg="white"
					borderRadius={c.borderRadius}
					border="1px solid #dbdbdb"
					boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
					p="16px"
					cursor="pointer"
					mb="4"
				>
					<FontAwesomeIcon icon={item?.icon} color="#303030" p="2" style={{ fontSize: 20 }} />
					<Text fontSize="md" fontWeight="md" ml="4">
						{t(item?.title)}
					</Text>
					<>{getBadge(item)}</>
				</Flex>
			</Link>
		);
	}



	return (
		<PrintLayout>
			<>
				<Container maxW="container.xl" mt="16px" mb="16px">
					<BreadcrumbComponent
						list={[
							{
								name: 'Insurance Sale Staff',
								path: '#',
							},
						]}
					/>
					<Center className="show-only-printing">
						<Text textAlign="center" color="#62B3ED" fontFamily="Moul" fontSize={18}>
							{t("Insurance Sale Staff")}
						</Text>
					</Center>
					<Center mt="5">
						<Box
							w="100%"
							pb="32px"
						>
							<Box maxW="400px">
								{menu.map((item, index) => (
									<>{menuItem({ item, index })}</>
								))}
							</Box>
						</Box>
					</Center>
				</Container>
			</>
		</PrintLayout>
	);
}

export default List;


