/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
	Tag,
	TagLabel,
	TagCloseButton,
	Box,
	FormControl,
	FormLabel,
	FormHelperText,
	InputGroup,
	InputRightElement,
	Button,
	Flex,
	Input,
	useDisclosure,
} from '@chakra-ui/react';
import { Field, useFormikContext } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import CompanyPickerModal from '../Modal/CompanyPickerModal';
import useStore from '../../store';
import c from '../../constant';

const CompanyInputOrSelectFormField = ({ name = null, label = null, inputOnly = false, inputPlaceholder = null, required = false }) => {
	const { t } = useTranslation();
	const { values } = useFormikContext();
	const { currentLang: lang } = useStore((state) => state.currentLang);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [selectedCompany, setSelectedCompany] = useState(null);

	useEffect(() => {
		if (values?.company?.companyId) {
			const companyString = localStorage.getItem('@CompanyInputOrSelectFormField');
			if (companyString) {
				setSelectedCompany(JSON.parse(companyString));
			}
		}
	}, [values?.company?.companyId]);

	return (
		<>
			<Box mb="4">
				<Field name={name}>
					{({ form, field, meta }) => (
						<FormControl id={name}>
							<>
								<FormLabel>
									{t(label)}
									{required && <span style={{ color: 'red' }}>*</span>}
								</FormLabel>
							</>
							<>
								<InputGroup size="md">
									{null !== selectedCompany && (
										<>
											<Flex
												w="100%"
												h={12}
												pl="0.2rem"
												pr="5.2rem"
												borderWidth={1}
												borderColor="gray.200"
												borderRadius={c.borderRadius}
												alignItems="center"
												justifyContent="flex-start"
											>
												<Tag width="100%" height={10} borderRadius={c.borderRadius} variant="solid" colorScheme="green">
													<TagLabel flex={1} textAlign="left" fontSize="md">
														{lang === 'kh' ? selectedCompany?.nameKhmer : selectedCompany?.name}
													</TagLabel>
													<TagCloseButton
														onClick={() => {
															localStorage.setItem('@CompanyInputOrSelectFormField', null);
															setSelectedCompany(null);
															form.setFieldValue(name, { companyName: '', companyId: null });
														}}
													/>
												</Tag>
											</Flex>
										</>
									)}
									{null === selectedCompany && (
										<>
											<Input
												value={field?.value?.companyName}
												width="100%"
												h={12}
												pr="5.5rem"
												type="text"
												placeholder={t(inputPlaceholder)}
												onChange={(event) => {
													form.setFieldValue(name, { companyName: event.target.value, companyId: null });
												}}
											/>
										</>
									)}
									{false === inputOnly && (
										<InputRightElement width="5.0rem" h={12}>
											<Button
												h={10}
												mr={1}
												leftIcon={
													<FontAwesomeIcon
														style={{
															fontSize: 16,
															cursor: 'pointer',
														}}
														icon={faList}
													/>
												}
												onClick={onOpen}
											>
												{t('List')}
											</Button>
										</InputRightElement>
									)}
								</InputGroup>
							</>
							<>
								{meta.touched && meta.error && (
									<FormHelperText id={`error-message-${name}`} color="red.400" fontSize="12px">
										{t(name)} {t('is')} {t(meta.error)}
									</FormHelperText>
								)}
							</>
							<>
								{isOpen && (
									<CompanyPickerModal
										onClose={onClose}
										callBack={(e) => {
											localStorage.setItem('@CompanyInputOrSelectFormField', JSON.stringify(e));
											setSelectedCompany(e);
											form.setFieldValue(name, { companyName: '', companyId: e?.id });
										}}
									/>
								)}
							</>
						</FormControl>
					)}
				</Field>
			</Box>
		</>
	);
};

export default CompanyInputOrSelectFormField;
