import React, { useEffect, useState } from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Button,
    Box,
    Flex,
    useToast
} from "@chakra-ui/react";
import c from "../../../constant"
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import ImageCropFormField from "../../../components/form/ImageCropFormField";
import { gql, useMutation } from '@apollo/client';

const MAX_FILE_SIZE = 104857600 / 20;


const MUTATION_UPDATE_EMPLOYEE_PROFILE_IMAGE = gql`
mutation update(
        $id: String!,
        $profileImage: Upload,
        $profileImageSize: Float
    ){
        updateEmployeeProfileImage(
            id: $id
            profileImage:$profileImage,
            profileImageSize: $profileImageSize
        )
    }
`

const formSchema = Yup.object().shape({
    profileImage: Yup.mixed().test("fileSize", "over limit", (value) => {
        if (value) {
            return value?.size <= MAX_FILE_SIZE
        } else {
            return true
        }
    }).required("required")
});


function EditProfileImage({
    onClose = () => null, id, callbackOnSuccess = () => null
}) {
    const { t } = useTranslation();
    const toast = useToast()

    const [updateEmployee, { error, data, loading }] = useMutation(
        MUTATION_UPDATE_EMPLOYEE_PROFILE_IMAGE
    );

    useEffect(() => {
        if (error) {
            if (error?.graphQLErrors?.length > 0) {
                error?.graphQLErrors.map(({ message }, i) => (
                    toast({
                        title: t(message),
                        status: "error",
                        isClosable: true,
                        position: "bottom-right"
                    })
                ))
            } else {
                toast({
                    title: t(error?.message ? error?.message : "something went wrong"),
                    status: "error",
                    isClosable: true,
                    position: "bottom-right"
                })
            }
        }
    }, [error])

    useEffect(() => {
        if (data) {
            if (data.updateEmployeeProfileImage) {
                toast({
                    title: t("Officials updated"),
                    description: t("Officials updated successfully"),
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                    position: "bottom-right"
                })
                callbackOnSuccess()
            }
        }
    }, [data])

    return (
        <Modal
            isOpen={true}
            onClose={() => {
                onClose()
            }}
            size="md"
        >
            <ModalOverlay
                backdropFilter='auto'
                bg='blackAlpha.300'
                backdropBlur='2px'
            />
            <ModalContent pb="4" borderRadius={c.borderRadius}>
                <ModalHeader>{t("Edit Profile Image")}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>

                    <Box w="100%" pt="16px" pb="16px">

                        <Formik
                            initialValues={{
                                profileImage: null
                            }}
                            validationSchema={formSchema}
                            onSubmit={(values) => {
                                updateEmployee({
                                    variables: {
                                        id,
                                        profileImage: values.profileImage,
                                        profileImageSize: values.profileImage?.size,
                                    }
                                })
                            }}
                        >
                            {(props) => (
                                <Form>
                                    <Box mt="3">

                                        <ImageCropFormField
                                            {...props}
                                            label="Profile Image"
                                            name="profileImage"
                                            maxFileSize={MAX_FILE_SIZE}
                                            required
                                            aspect={480 / 640}
                                            resizedToWidth={480}
                                        />

                                        <Flex
                                            textAlign="right"
                                            mt="6"
                                            justifyContent="flex-end"
                                            alignItems="center"
                                        >
                                            <Button
                                                id="login-btn"
                                                variant="solid"
                                                size="md"
                                                h="42px"
                                                w="128px"
                                                bg="primary.dark"
                                                _hover={{ bg: "primary.dark" }}
                                                color="white"
                                                // borderRadius="31px"
                                                type="submit"
                                                isLoading={loading}
                                                loadingText={t("CREATE")}
                                                spinnerPlacement="start"
                                            >
                                                {t(`Update`)}
                                            </Button>
                                        </Flex>
                                    </Box>
                                </Form>
                            )}
                        </Formik>

                    </Box>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

export default EditProfileImage;
