import React from "react";
import {
  Box, Flex, Modal, Image,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text
} from "@chakra-ui/react";
import c from "../../constant"
import moment from "moment"
import "moment/locale/en-gb";
import GeoCodeResoliver from "../Geo/GeoCodeResolver"
import { useTranslation } from "react-i18next";
import DownloadAndViewButton from "../Files/DownloadAndViewButton";
import PlaceholderImage from "../../assets/images/profile-4x6-placeholder.jpg"

const leftLabelWidth = "160px"
const marginTopRow = 1
const paddingTopRow = 1
const paddingBottomRow = 1

function AgentCurrentInfoRenewal({
  isOpen = true, onClose = () => null, data = null
}) {
  moment.locale("en-gb")
  const { t } = useTranslation();

  function rowInfo({ label, rightComponent, labelWidth = null }) {
    return (
      <Flex mt={marginTopRow} pt={paddingTopRow} pb={paddingBottomRow}>
        <Text fontWeight="bold" minW={labelWidth ? labelWidth : leftLabelWidth} w={labelWidth ? labelWidth : leftLabelWidth}>{t(label)}</Text>
        {rightComponent}
      </Flex>
    )
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose()
        }}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent borderRadius={c.borderRadius} pt="2">
          <ModalHeader pb={1}>{t("Current Info")}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb="8">
            {data?.profileImage &&
              <Flex direction="column" mb={2}>
                <Image fallbackSrc={PlaceholderImage} src={c.baseFilePath + data?.profileImage?.file} w="100px" minH="125px" h="auto" />
              </Flex>}
            {!data?.isCurrentAddressManual && rowInfo({
              label: "Current address",
              rightComponent: (
                <Box ml="4">
                  <GeoCodeResoliver
                    province={data?.caProvinceOrCity}
                    district={data?.caDistrict}
                    commune={data?.caCommune}
                    village={data?.caVillage}
                    houseNumber={data?.caHouseNumber}
                    streetNumber={data?.caStreetNumber}
                  />
                </Box>
              )
            })}
            {data?.isCurrentAddressManual &&
              rowInfo({
                label: "Current address",
                rightComponent: (
                  <Text ml="4">{data?.currentAddressManual}</Text>
                )
              })}
            {rowInfo({
              label: "Current Address File",
              rightComponent: (
                <>
                  {
                    data?.currentAddressFile?.size ?
                      <Box>
                        <DownloadAndViewButton url={
                          data?.currentAddressFile?.file
                        }
                          size={data?.currentAddressFile?.size} />
                      </Box> : <Text ml="4">N/A</Text>
                  }
                </>

              )
            })}

            {rowInfo({
              label: "phoneNumber",
              rightComponent: (
                <Text ml="4">{(data?.phoneNumber)}</Text>
              )
            })}
            {rowInfo({
              label: "email",
              rightComponent: (
                <Text ml="4">{(data?.email)}</Text>
              )
            })}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default AgentCurrentInfoRenewal;
