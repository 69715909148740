/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
	Flex,
	Text,
	Button,
	Box,
	AlertDialog,
	AlertDialogOverlay,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogFooter,
	useDisclosure,
	Textarea,
	useToast,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { gql, useMutation } from '@apollo/client';
import CardCaption from './CardCaption';
import CardStatus from './CardStatus';
import CardLabelValue from './CardLabelValue';
import CardName from './CardName';
import CardDate from './CardDate';
import useStore from '../../../store';
import c from '../../../constant';
import useMoment from '../../../hooks/useMoment';
import ResizeTextarea from 'react-textarea-autosize';

const M_UPDATE_STEP = gql`
	mutation update($proposalId: String!, $step: ProcessStep!, $data: JSON!) {
		updateAdditionalFeatureProcessStep(proposalId: $proposalId, step: $step, data: $data)
	}
`;

export default function ProcessDepartmentCard({ proposalId = null, office = null, department = null, callback = () => null, process = null }) {
	const toast = useToast();
	const { t } = useTranslation();
	const { dateNow } = useMoment();
	const { isOpen: isOpenApproving, onOpen: onOpenApproving, onClose: onCloseApproving } = useDisclosure();
	const { isOpen: isOpenRejecting, onOpen: onOpenRejecting, onClose: onCloseRejecting } = useDisclosure();
	const { currentUser: user } = useStore((state) => state.currentUser);
	const [reason, setReason] = useState('');
	const [reasonForCompany, setReasonForCompany] = useState('');
	const [updateStep, { loading, error, data }] = useMutation(M_UPDATE_STEP);

	useEffect(() => {
		if (process?.office?.rejectionReasonForCompany) {
			setReasonForCompany(process?.office?.rejectionReasonForCompany || '');
		}
	}, [process]);

	useEffect(() => {
		if (data) {
			toast({
				title: t('All tasks are completed'),
				status: 'success',
				isClosable: true,
				position: 'top-right',
			});
			callback();
			onCloseApproving();
			onCloseRejecting();
		}
	}, [data]);

	useEffect(() => {
		if (error) {
			error.graphQLErrors.map(({ message }, i) =>
				toast({
					title: t(message),
					status: 'error',
					isClosable: true,
					position: 'top-right',
				})
			);
		}
	}, [error]);

	const approve = () => {
		const variables = {
			proposalId,
			step: c.stepDepartment,
			data: {
				status: c.approved,
				approvedAt: dateNow(),
				approvedBy: user?.id,
				rejectedAt: null,
				rejectedBy: null,
				rejectionReason: null,
				rejectionReasonForCompany: null,
			},
		};
		updateStep({ variables });
	};

	const reject = () => {
		const variables = {
			proposalId,
			step: c.stepDepartment,
			data: {
				status: c.rejected,
				approvedAt: null,
				approvedBy: null,
				rejectedAt: dateNow(),
				rejectedBy: user?.id,
				rejectionReason: reason,
				rejectionReasonForCompany: reasonForCompany,
			},
		};
		updateStep({ variables });
	};

	return (
		<>
			<Box className={department?.status === null ? 'disableForm' : ''}>
				<CardCaption label="Department" status={department?.status} />
				<Box mt="2">
					<CardLabelValue label="Status" value={<CardStatus value={department?.status} />} />
					{department?.status === c.approved && (
						<Box>
							<CardLabelValue label="Approved By" value={<CardName id={department?.approvedBy} />} />
							<CardLabelValue label="approved at" value={<CardDate input={department?.approvedAt} />} />
						</Box>
					)}
					{department?.status === c.rejected && (
						<Box>
							<CardLabelValue label="Rejected By" value={<CardName id={department?.rejectedBy} />} />
							<CardLabelValue label="Rejected At" value={<CardDate input={department?.rejectedAt} />} />
							<Box _hover={{ bg: '#ecf0f1' }}>
								<CardLabelValue label="Rejection Reason" value={<pre className="text-area-viewer">{department?.rejectionReason}</pre>} />
							</Box>
						</Box>
					)}
					{(department?.status === c.waiting || department?.status === null) && (
						<Box>
							<CardLabelValue label="Approved By" value={<CardName />} />
							<CardLabelValue label="approved at" value={<CardDate />} />
							<Flex className={c.stepDepartmentRoles.includes(user?.position) ? '' : 'disableButton'} mt={2}>
								<Button w="100px" mr={4} borderRadius={c.borderRadius} size="sm" colorScheme="green" onClick={onOpenApproving}>
									{t('approve')}
								</Button>
								<Button w="100px" borderRadius={c.borderRadius} size="sm" colorScheme="red" onClick={onOpenRejecting}>
									{t('Reject')}
								</Button>
							</Flex>
						</Box>
					)}
				</Box>
			</Box>
			<AlertDialog isOpen={isOpenApproving} onClose={onCloseApproving}>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							{t('approve')}
						</AlertDialogHeader>
						<AlertDialogBody>{t('Are you sure to approve this proposal?')}</AlertDialogBody>
						<AlertDialogFooter>
							<Button mr={4} isLoading={loading} onClick={onCloseApproving}>
								{t('Cancel')}
							</Button>
							<Button isLoading={loading} colorScheme="green" onClick={approve}>
								{t('approve')}
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
			<AlertDialog isOpen={isOpenRejecting} onClose={onCloseRejecting}>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							{t('Reject')}
						</AlertDialogHeader>
						<AlertDialogBody>
							<Text>
								{t('Please input rejection reason')}
								<span style={{ color: 'red' }}>*</span>
							</Text>
							<Textarea mt={2} value={reason} onChange={(e) => setReason(e.target.value ?? '')} />

							<Text mt="4">
								{t('Rejection reason for company')}
								<span style={{ color: 'red' }}>*</span>
							</Text>
							<Textarea mt={2} value={reasonForCompany} onChange={(e) => setReasonForCompany(e.target.value ?? '')} as={ResizeTextarea} />
						</AlertDialogBody>
						<AlertDialogFooter>
							<Button mr={4} isLoading={loading} onClick={onCloseRejecting}>
								{t('Cancel')}
							</Button>
							<Button isLoading={loading} colorScheme="red" disabled={reason && reasonForCompany ? false : true} onClick={reject}>
								{t('Reject')}
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</>
	);
}
