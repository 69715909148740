/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
	Container,
	Box,
	Text,
	Center,
	Table,
	Tbody,
	Tfoot,
	Tr,
	Th,
	Td,
	Badge,
} from '@chakra-ui/react';
import c from '../../../constant';
import moment from 'moment';
import '../../../containers/CompanySubmissionReport/style.css';
import useStore from '../../../store';

function ReportInlinePrint({ data, ...props }) {
	const { t } = useTranslation();
	const { currentLang } = useStore((state) => state.currentLang);
	moment.locale(currentLang === 'kh' ? 'km' : 'en-gb');

	function reportTypeBadge(type) {
		switch (type) {
			case 'MONTHLY':
				return <Badge colorScheme="green">{t('MONTHLY')}</Badge>;
			case 'QUARTERLY':
				return <Badge colorScheme="yellow">{t('QUARTERLY')}</Badge>;
			case 'SEMESTERLY':
				return <Badge colorScheme="purple">{t('SEMESTERLY')}</Badge>;
			case 'ANNUALLY':
				return <Badge colorScheme="red">{t('ANNUALLY')}</Badge>;
			default:
				return null;
		}
	}

	function reportTypeOfReports(type, newTypeDetail) {
		switch (type) {
			case 'FINANCIAL_REPORT':
				return <Badge colorScheme="green">{t('Financial Report')}</Badge>;
			case 'COMPLAINT_SETTLEMENT_REPORT':
				return <Badge colorScheme="yellow">{t('Complaint Settlement Report')}</Badge>;
			case 'INSURANCE_AGENT_REPORT':
				return <Badge colorScheme="purple">{t('Insurance Agent Report')}</Badge>;
			case 'NEW_TYPE':
				return (
					<Badge colorScheme="gray">
						{currentLang === 'kh' ? newTypeDetail?.titleKh : newTypeDetail?.title}
					</Badge>
				);
			default:
				break;
		}
	}

	function getDisplayCompanyName(item) {
		let displayName = item?.companyName;
		if (item?.company?.id) {
			if (currentLang === 'kh') {
				displayName = item?.company?.nameKhmer;
			} else {
				displayName = item?.company?.name;
			}
		}
		return displayName;
	}

	function checkReadRow(item) {
		let read = true;
		read = item?.notification?.read;
		return read;
	}

	return (
		<>
			<Container
				className="show-only-printing"
				maxW="container.xl"
				mt="16px"
				mb="16px"
				{...props}
			>
				<Center>
					<Text textAlign="center" color="#62B3ED" fontFamily="Moul" fontSize={18}>
						{t('Company Report')}
					</Text>
				</Center>
				<Center mt="5">
					<Box
						w="100%"
						bg="white"
						borderRadius={c.borderRadius}
						border="1px solid #dbdbdb"
						boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
						pb="32px"
					>
						<Box p="16px">
							<Table variant="simple">
								<Tbody>
									<Tr className="td-as-th">
										<Th>{t('Company Name')}</Th>
										<Th>{t('Created At')}</Th>
										<Th isNumeric>{t('Report Type')}</Th>
										<Th isNumeric>{t('Type')}</Th>
									</Tr>
									{data?.getCompanySubmissionReportsByCompanyId
										?.companySubmissionReports.length <= 0 && (
										<Tr>
											<Td>
												<Text>{t('No Data')}</Text>
											</Td>
										</Tr>
									)}
									{data?.getCompanySubmissionReportsByCompanyId?.companySubmissionReports?.map(
										(item, index) => {
											return (
												<Tr
													cursor="pointer"
													_hover={{ bg: 'gray.200' }}
													key={`item-${index}`}
													bg={
														checkReadRow(item) === false
															? 'gray.100'
															: 'white'
													}
												>
													<Td fontWeight="500">
														<Text maxW="400px" noOfLines={4}>
															{getDisplayCompanyName(item)}
														</Text>
													</Td>
													<Td>{moment(item.createdAt).format('LLLL')}</Td>
													<Td isNumeric>
														{reportTypeOfReports(
															item.reportType,
															item?.newTypeDetail
														)}
													</Td>
													<Td isNumeric>
														{reportTypeBadge(item.type)}{' '}
														{item.spam === true && (
															<Badge colorScheme="red">
																{t('Spam')}
															</Badge>
														)}
													</Td>
												</Tr>
											);
										}
									)}
								</Tbody>
								<Tfoot className="hidden-print">
									<Tr>
										<Th>{t('Company Name')}</Th>
										<Th>{t('Created At')}</Th>
										<Th isNumeric>{t('Report Type')}</Th>
										<Th isNumeric>{t('Type')}</Th>
									</Tr>
									<Tr>
										<Th />
										<Th />
										<Th />
										<Th isNumeric>
											{t('Total Rows')}:{' '}
											{
												data?.getCompanySubmissionReportsByCompanyId
													?.totalRows
											}
										</Th>
									</Tr>
								</Tfoot>
							</Table>
							<Box justifyContent="flex-end" className="show-only-printing">
								<Text ml="2" mt="4">
									{t('Total Rows')}:{' '}
									<b>{data?.getCompanySubmissionReportsByCompanyId?.totalRows}</b>
								</Text>
							</Box>
						</Box>
					</Box>
				</Center>
			</Container>
		</>
	);
}

export default ReportInlinePrint;
