import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Box, Flex, Text, Center, Table, Tr, Td, Input, Button, useToast, Image } from '@chakra-ui/react';
import BreadcrumbComponent from '../../../components/BreadcrumbComponent/BreadcrumbComponent';
import c from '../../../constant';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import moment from 'moment';
import './style.css';
import { useLocation, useHistory } from 'react-router-dom';
import useStore from '../../../store';
import getPositionLabel from '../../../utils/getPositionLabel';
import _ from 'lodash';
import 'moment/locale/km';
import useDetectPrint from 'react-detect-print';
import DateTimePicker from 'react-datetime-picker';
import LOGO from '../../../assets/images/logo.png';
require('@thyrith/momentkh')(moment);

const headerColor = '#48769A';

const QUERY_DATA = gql`
	query get($id: String!) {
		getSalaryTableById(id: $id) {
			id
			title
			data
			printDate
		}
	}
`;

const MUTATION_UPDATE_TABLE = gql`
	mutation update($data: String!, $id: String!, $printDate: DateTime!) {
		updateSalaryTableById(data: $data, printDate: $printDate, id: $id)
	}
`;

function UserList() {
	const { t } = useTranslation();
	const location = useLocation();
	const history = useHistory();
	const toast = useToast();
	const urlParams = new URLSearchParams(location.search);
	const id = urlParams.get('id') ? urlParams.get('id') : null;
	const printing = useDetectPrint();
	const [today, setToday] = useState(moment());
	const { currentLang } = useStore((state) => state.currentLang);
	moment.locale('km');

	const [saveTableDataMutation, { error: saveTableError, data: saveTableDataRes, loading: saveTableLoading }] = useMutation(MUTATION_UPDATE_TABLE);

	const [loadData, { error: formSettingError, loading: formSettingLoading, data: formSettingData }] = useLazyQuery(QUERY_DATA);

	const [tableData, setTableData] = useState(null);

	useEffect(() => {
		if (saveTableError) {
			if (saveTableError?.graphQLErrors?.length > 0) {
				saveTableError?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(saveTableError?.message ? saveTableError?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [saveTableError]);

	useEffect(() => {
		if (saveTableDataRes) {
			if (saveTableDataRes.updateSalaryTableById) {
				toast({
					title: t('Officials updated'),
					description: t('Officials updated successfully'),
					status: 'success',
					duration: 5000,
					isClosable: true,
					position: 'bottom-right',
				});
				loadData({
					variables: {
						id,
					},
				});
			}
		}
	}, [saveTableDataRes]);

	useEffect(() => {
		window.scrollTo(0, 0);
		loadData({
			variables: {
				id,
			},
		});
	}, []);

	function totalField(fieldName) {
		let res = 0;
		tableData?.map((item, index) => {
			if (item?.type === 'employee') {
				if (item?.field?.[fieldName]) {
					const colValue = item?.field?.[fieldName] || 0;
					res = res + parseFloat(colValue);
				}
			}
		});
		return res <= 0 ? '-' : formatPrintMoney(res);
	}

	function getStatusEqualTo(data) {
		let tmpData = _.orderBy(data, ['date'], ['desc']);
		return tmpData?.[0]?.title;
	}

	useEffect(() => {
		if (formSettingData) {
			const tmpData = _.orderBy(formSettingData?.getSalaryTableById?.data, ['index'], ['asc']);
			let finalData = [];
			let employeeNo = 1;
			tmpData.map((item) => {
				if (item?.type === 'employee') {
					if (!item?.field?.employeeNo) {
						finalData.push({ ...item, field: { employeeNo } });
						employeeNo = employeeNo + 1;
					} else {
						finalData.push(item);
					}
				} else {
					finalData.push(item);
				}
			});
			setTableData(finalData);

			if (formSettingData?.getSalaryTableById?.printDate) {
				setToday(moment(formSettingData?.getSalaryTableById?.printDate));
			} else {
				setToday(moment());
			}
		}
	}, [formSettingData]);

	function inputValueChange({ value, index, field }) {
		let tmpTableData = [...tableData];
		let tmpField = tmpTableData[index]?.field || {};
		tmpField = { ...tmpField, [field]: parseFloat(value) };
		const newRow = { ...tmpTableData[index], field: tmpField };
		tmpTableData[index] = newRow;
		setTableData(tmpTableData);
	}

	function saveTable() {
		saveTableDataMutation({
			variables: {
				id,
				data: JSON.stringify(tableData),
				printDate: moment(today).toDate(),
			},
		});
	}

	function formatPrintMoney(num) {
		const result = num.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
		return result;
	}

	function getTotal(index) {
		const tmpRow = tableData?.[index];
		let field = tmpRow?.field;
		let res =
			getNumber(field?.money) +
			getNumber(field?.compensation) +
			getNumber(field?.function) +
			getNumber(field?.additional) +
			getNumber(field?.children) +
			getNumber(field?.spouse) +
			getNumber(field?.reminder);
		return res > 0 ? res : '';
	}

	function grandTotal() {
		let res = 0;
		tableData?.map((tmpRow) => {
			let field = tmpRow?.field;
			let subTotal =
				getNumber(field?.money) +
				getNumber(field?.compensation) +
				getNumber(field?.function) +
				getNumber(field?.additional) +
				getNumber(field?.children) +
				getNumber(field?.spouse) +
				getNumber(field?.reminder);
			res = res + parseFloat(subTotal);
		});
		return res > 0 ? formatPrintMoney(res) : '';
	}

	function grandTotalPayout() {
		let res = 0;
		tableData?.map((tmpRow) => {
			let field = tmpRow?.field;
			let subTotal =
				getNumber(field?.money) +
				getNumber(field?.compensation) +
				getNumber(field?.function) +
				getNumber(field?.additional) +
				getNumber(field?.children) +
				getNumber(field?.spouse) +
				getNumber(field?.reminder) -
				getNumber(field?.payTaxes);
			res = res + parseFloat(subTotal);
		});
		return res > 0 ? formatPrintMoney(res) : '';
	}

	function getNumber(data) {
		if (data) {
			return parseFloat(data);
		} else {
			return 0;
		}
	}

	function displayPrintData(value) {
		return (
			<div style={{ fontWeight: 600 }} className="show-only-printing">
				{(value > 0 ? formatPrintMoney(value) : '') || ''}
			</div>
		);
	}

	function viewTable() {
		return (
			<>
				{formSettingLoading && <Text>{t('Loading')}</Text>}
				{!formSettingLoading && (
					<Table class="tg">
						<thead>
							<tr>
								<th class="tg-0lax" rowspan="2">
									ល.រ
								</th>
								<th class="tg-0lax" rowspan="2">
									អត្ថលេខ
								</th>
								<th class="tg-0lax" rowspan="2" style={{ width: 110 }}>
									នាម-គោល
								</th>
								<th class="tg-0lax" rowspan="2">
									ភេទ
								</th>
								<th class="tg-0lax" style={{ width: 90 }}>
									ថ្ងៃ ខែ ឆ្នាំ
								</th>
								<th class="tg-0lax" style={{ minWidth: 82 }} rowspan="2">
									មុខងារ
								</th>
								<th class="tg-0lax" rowspan="2" style={{ minWidth: 50 }}>
									ឋានៈស្មើ
								</th>
								<th class="tg-0lax" style={{ width: 90 }}>
									ក្របខណ្ឌ
								</th>
								<th class="tg-0lax" rowspan="2">
									ទឹកប្រាក់
								</th>
								<th class="tg-0lax">ប្រាក់</th>
								<th class="tg-0lax" colspan="2">
									បំណាច់
								</th>
								<th class="tg-0lax" colspan="2">
									ប្រាក់វិភាជន៏គ្រួសារ
								</th>
								<th class="tg-0lax" rowspan="2">
									ប្រាក់រំលឹក
								</th>
								<th class="tg-0lax" rowspan="2">
									សរុបទូទៅ
								</th>
								<th class="tg-0lax" style={{ minWidth: 80 }}>
									ទឹកប្រាក់
								</th>
								<th class="tg-0lax">បៀវត្ស</th>
							</tr>
							<tr>
								<th class="tg-0lax">កំណើត</th>
								<th class="tg-0lax">ឋានន្តរស័ក្តិ និងថ្នាក់</th>
								<th class="tg-0lax">បង្គ្រប់</th>
								<th class="tg-0lax">មុខងារ</th>
								<th class="tg-0lax">បន្ថែម</th>
								<th class="tg-0lax">កូន</th>
								<th class="tg-0lax">សហព័ទ្ធ</th>
								<th class="tg-0lax">ត្រូវបង់ពន្ធ</th>
								<th class="tg-0lax">ត្រូវបើក</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td colspan="2" style={{ textAlign: 'center', fontWeight: 'bold' }}>
									Total
								</td>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
								<td style={{ textAlign: 'right', fontWeight: 600 }}>{totalField('money')}</td>
								<td style={{ textAlign: 'right', fontWeight: 600 }}>{totalField('compensation')}</td>
								<td style={{ textAlign: 'right', fontWeight: 600 }}>{totalField('function')}</td>
								<td style={{ textAlign: 'right', fontWeight: 600 }}>{totalField('additional')}</td>
								<td style={{ textAlign: 'right', fontWeight: 600 }}>{totalField('children')}</td>
								<td style={{ textAlign: 'right', fontWeight: 600 }}>{totalField('spouse')}</td>
								<td style={{ textAlign: 'right', fontWeight: 600 }}>{totalField('reminder')}</td>
								<td style={{ textAlign: 'right', fontWeight: 600 }}>{grandTotal()}</td>
								<td style={{ textAlign: 'right', fontWeight: 600 }}>{totalField('payTaxes')}</td>
								<td style={{ textAlign: 'right', fontWeight: 600 }}>{grandTotalPayout()}</td>
							</tr>
							{tableData?.length > 0 &&
								tableData?.map((item, index) => {
									switch (item?.type) {
										case 'header':
											return (
												<Tr key={`${index}-g-row`} className="tableHeader">
													<Td fontFamily="Moul !important" colSpan={18}>
														{item.data}
													</Td>
												</Tr>
											);
										case 'subHeader':
											return (
												<Tr key={`${index}-g-row`}>
													<Td></Td>
													<Td fontFamily="Moul !important" colSpan={17}>
														{item?.data}
													</Td>
												</Tr>
											);
										case 'employee': {
											const employee = item?.employeeData;
											return (
												<Tr key={`${index}-g-row`}>
													<Td textAlign="center">
														{displayPrintData(item?.field?.employeeNo)}
														<Input
															className="hidden-print"
															w="30px"
															type="number"
															value={item?.field?.employeeNo || ''}
															onChange={(e) => inputValueChange({ value: e.target.value, index: index, field: 'employeeNo' })}
															variant="filled"
															placeholder=""
														/>
													</Td>
													<Td textAlign="center" fontWeight="600">
														{employee?.govtNumber}
													</Td>
													<Td>
														{item?.title} {employee?.fullName}
													</Td>
													<Td textAlign="center">{t(employee?.gender)}</Td>
													<Td textAlign="center">{employee?.dob && moment(employee?.dob).format(c.excelDateOnlyTextFormat)}</Td>
													<Td>
														{employee?.currentWorkingStatus?.currentWorkingStatusPosition
															? getPositionLabel(employee?.currentWorkingStatus?.currentWorkingStatusPosition)
															: ''}
													</Td>
													<Td>{getStatusEqualTo(employee?.additionalRoleOnCurrentJob)}</Td>
													<Td textAlign="center">{employee?.currentWorkingStatus?.currentWorkingStatusJobTitle}</Td>
													<Td textAlign="right">
														{displayPrintData(item?.field?.money)}
														<Input
															className="hidden-print"
															type="number"
															value={item?.field?.money || ''}
															onChange={(e) => inputValueChange({ value: e.target.value, index: index, field: 'money' })}
															variant="filled"
															placeholder=""
														/>
													</Td>
													<Td textAlign="right">
														{displayPrintData(item?.field?.compensation)}
														<Input
															className="hidden-print"
															type="number"
															value={item?.field?.compensation || ''}
															onChange={(e) => inputValueChange({ value: e.target.value, index: index, field: 'compensation' })}
															variant="filled"
															placeholder=""
														/>
													</Td>
													<Td textAlign="right">
														{displayPrintData(item?.field?.function)}
														<Input
															className="hidden-print"
															type="number"
															value={item?.field?.function || ''}
															onChange={(e) => inputValueChange({ value: e.target.value, index: index, field: 'function' })}
															variant="filled"
															placeholder=""
														/>
													</Td>
													<Td textAlign="right">
														{displayPrintData(item?.field?.additional)}
														<Input
															className="hidden-print"
															type="number"
															value={item?.field?.additional || ''}
															onChange={(e) => inputValueChange({ value: e.target.value, index: index, field: 'additional' })}
															variant="filled"
															placeholder=""
														/>
													</Td>
													<Td textAlign="right">
														{displayPrintData(item?.field?.children)}
														<Input
															className="hidden-print"
															type="number"
															value={item?.field?.children || ''}
															onChange={(e) => inputValueChange({ value: e.target.value, index: index, field: 'children' })}
															variant="filled"
															placeholder=""
														/>
													</Td>
													<Td textAlign="right">
														{displayPrintData(item?.field?.spouse)}
														<Input
															className="hidden-print"
															type="number"
															value={item?.field?.spouse || ''}
															onChange={(e) => inputValueChange({ value: e.target.value, index: index, field: 'spouse' })}
															variant="filled"
															placeholder=""
														/>
													</Td>
													<Td textAlign="right">
														{displayPrintData(item?.field?.reminder)}
														<Input
															className="hidden-print"
															type="number"
															value={item?.field?.reminder || ''}
															onChange={(e) => inputValueChange({ value: e.target.value, index: index, field: 'reminder' })}
															variant="filled"
															placeholder=""
														/>
													</Td>
													<Td textAlign="right" fontWeight="600">
														{getTotal(index) > 0 ? formatPrintMoney(getTotal(index)) : ''}
													</Td>
													<Td textAlign="right">
														{displayPrintData(item?.field?.payTaxes)}
														<Input
															className="hidden-print"
															type="number"
															value={item?.field?.payTaxes || ''}
															onChange={(e) => inputValueChange({ value: e.target.value, index: index, field: 'payTaxes' })}
															variant="filled"
															placeholder=""
														/>
													</Td>
													<Td textAlign="right" fontWeight="600">
														{getTotal(index) - (item?.field?.payTaxes || 0) > 0
															? formatPrintMoney(getTotal(index) - (item?.field?.payTaxes || 0))
															: ''}
													</Td>
												</Tr>
											);
										}
										default:
											return null;
									}
								})}
						</tbody>
					</Table>
				)}
			</>
		);
	}
	function printFooter() {
		// let today = moment();
		let year = moment(today).format('YYYY');
		let day = moment(today).format('D');
		let month = moment(today).format('MMMM');
		let khmerYear = today.toLunarDate('ឆ្នាំa e ព.ស.b');
		let khmerDateShort = today.toLunarDate('ថ្ងៃW dN ខែm ឆ្នាំa e ព.ស.b');
		// let todayEN = រាជធានីភ្នំពេញ ថ្ងៃទី{day} ខែ{month} គ.ស.{year}
		//    let todayEN = moment(today).format('រាជធានីភ្នំពេញ ថ្ងៃទីdddd ខែmmmm គ.ស.yyyy')
		const fontSize = '12px';
		const lineHeight = '24px';
		return (
			<Flex justifyContent="space-between" mt="32px">
				<Flex lineHeight={lineHeight} fontSize={fontSize} direction="column" alignItems="center">
					<Text fontFamily="Moul">បានឃើញ និងឯកភាព</Text>
					<Flex>
						<Text fontFamily="MEF1">ថ្ងៃ</Text>
						<Text fontFamily="MEF1" ml="90px" mr="60px">
							ខែ
						</Text>
						<Text fontFamily="MEF1">{khmerYear}</Text>
					</Flex>
					<Flex>
						<Text fontFamily="MEF1">រាជធានីភ្នំពេញ ថ្ងៃទី </Text>
						<Text fontFamily="MEF1" ml="50px">
							ខែ
						</Text>
						<Text fontFamily="MEF1" ml="50px">
							គ.ស.{year}
						</Text>
					</Flex>
					<Text fontFamily="Moul">អគ្គនាយក</Text>
					<Text fontFamily="Moul">និយ័តករធានារ៉ាប់រងកម្ពុជា</Text>
				</Flex>
				<Flex lineHeight={lineHeight} fontSize={fontSize} direction="column" alignItems="center">
					<Text fontFamily="Moul">បានឃើញ និងពិនិត្យត្រឹមត្រូវ</Text>
					<Text fontFamily="MEF1">{khmerDateShort}</Text>
					<Text fontFamily="MEF1">
						រាជធានីភ្នំពេញ ថ្ងៃទី{day} ខែ{month} គ.ស.{year}
					</Text>
					<Text fontFamily="Moul">ប្រធាន</Text>
					<Text fontFamily="Moul">នាយកដ្ឋានកិច្ចការទូទៅ</Text>
				</Flex>
				<Flex lineHeight={lineHeight} fontSize={fontSize} direction="column" alignItems="center">
					<Text fontFamily="MEF1">{khmerDateShort}</Text>
					<Text fontFamily="MEF1">
						រាជធានីភ្នំពេញ ថ្ងៃទី{day} ខែ{month} គ.ស.{year}
					</Text>
					<Text fontFamily="Moul">អ្នករៀបចំ</Text>
					<Text fontFamily="Moul">នាយកដ្ឋានកិច្ចការទូទៅ</Text>
				</Flex>
			</Flex>
		);
	}

	function printFooter() {
		// let today = moment();
		let year = moment(today).format('YYYY');
		let day = moment(today).format('DD');
		let month = moment(today).format('MMMM');
		let khmerYear = today.toLunarDate('ឆ្នាំa e ព.ស.b');
		let khmerDateShort = today.toLunarDate('ថ្ងៃW dN ខែm ឆ្នាំa e ព.ស.b');
		// let todayEN = រាជធានីភ្នំពេញ ថ្ងៃទី{day} ខែ{month} គ.ស.{year}
		// let todayEN = moment(today).format('រាជធានីភ្នំពេញ ថ្ងៃទីdddd ខែmmmm គ.ស.yyyy')
		const fontSize = '12px';
		const lineHeight = '24px';
		return (
			<Flex justifyContent="space-between" mt="32px">
				<Flex lineHeight={lineHeight} fontSize={fontSize} direction="column" alignItems="center">
					<Text fontFamily="Moul">បានឃើញ និងឯកភាព</Text>
					<Flex>
						<Text fontFamily="MEF1">ថ្ងៃ</Text>
						<Text fontFamily="MEF1" ml="90px" mr="60px">
							ខែ
						</Text>
						<Text fontFamily="MEF1">{khmerYear}</Text>
					</Flex>
					<Flex>
						<Text fontFamily="MEF1">រាជធានីភ្នំពេញ ថ្ងៃទី </Text>
						<Text fontFamily="MEF1" ml="50px">
							ខែ
						</Text>
						<Text fontFamily="MEF1" ml="50px">
							គ.ស.{year}
						</Text>
					</Flex>
					<Text fontFamily="Moul">អគ្គនាយក</Text>
					<Text fontFamily="Moul">និយ័តករធានារ៉ាប់រងកម្ពុជា</Text>
				</Flex>
				<Flex lineHeight={lineHeight} fontSize={fontSize} direction="column" alignItems="center">
					<Text fontFamily="Moul">បានឃើញ និងពិនិត្យត្រឹមត្រូវ</Text>
					<Text fontFamily="MEF1">{khmerDateShort}</Text>
					<Text fontFamily="MEF1">
						រាជធានីភ្នំពេញ ថ្ងៃទី{day} ខែ{month} គ.ស.{year}
					</Text>
					<Text fontFamily="Moul">ប្រធាន</Text>
					<Text fontFamily="Moul">នាយកដ្ឋានកិច្ចការទូទៅ</Text>
				</Flex>
				<Flex lineHeight={lineHeight} fontSize={fontSize} direction="column" alignItems="center">
					<Text fontFamily="MEF1">{khmerDateShort}</Text>
					<Text fontFamily="MEF1">
						រាជធានីភ្នំពេញ ថ្ងៃទី{day} ខែ{month} គ.ស.{year}
					</Text>
					<Text fontFamily="Moul">អ្នករៀបចំ</Text>
					<Text fontFamily="Moul">នាយកដ្ឋានកិច្ចការទូទៅ</Text>
				</Flex>
			</Flex>
		);
	}

	return (
		<>
			{/* <Box> */}
			<Box className="show-only-printing">
				<Flex>
					<Flex minW="180px" w="180px" align="center" direction="column" pb="2">
						<Image mt="16px" src={LOGO} w="114px" h="114px" />
						<Text color={headerColor} fontSize="14px" fontFamily="Moul">
							និយ័តករធានារ៉ាប់រងកម្ពុជា
						</Text>
					</Flex>
					<Flex flexGrow="1" pb="2" justifyContent="flex-end" alignItems="center" direction="column">
						<Text fontSize="14px" fontFamily="Moul" color={headerColor}>
							{formSettingData?.getSalaryTableById?.title}
						</Text>
					</Flex>
					<Flex w="170px" minW="170px"></Flex>
				</Flex>
				{viewTable()}
				{printing && printFooter()}
				{/* {printFooter()} */}
			</Box>
			<Container className="hidden-print" maxW="container.xl" mt="16px" mb="16px">
				<BreadcrumbComponent
					list={[
						{
							name: t('OFFICIAL PROFILE'),
							path: '/employee/list?page=1',
						},
						{
							name: t('Salary'),
							path: '/salary/list?page=1',
						},
						{
							name: t('Edit'),
							path: '#',
						},
					]}
				/>
				<Center mt="5">
					<Box
						w="100%"
						bg="white"
						borderRadius={c.borderRadius}
						border="1px solid #dbdbdb"
						boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
						pb="32px"
					>
						<Flex bg="#FAFAFA" p="16px" pt="8px" pb="8px" mb="16px" justifyContent="space-between" borderBottom="1px solid #dbdbdb">
							<Text fontSize="x-large" fontWeight="bold">
								{t('Salary Table')}
							</Text>
							<Flex>
								{/* <Flex
                                        cursor="pointer"
                                        alignItems="center"
                                        justifyContent="center"
                                        w="40px"
                                        h="40px"
                                        borderRadius={c.borderRadius}
                                        border="1px solid #dbdbdb"
                                        boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
                                        mr="4"
                                        onClick={() => window?.print()}
                                    >
                                        <FaPrint size={16}/>
                                    </Flex> */}
								<Button borderRadius={c.borderRadius} colorScheme="green" isLoading={saveTableLoading} onClick={() => saveTable()}>
									{t('Save')}
								</Button>
							</Flex>
						</Flex>
						<Box p="16px">{viewTable()}</Box>
						<Flex w="100%" justifyContent="flex-end" pr="4" alignItems="center">
							<Text mr="1">កាលបរិច្ឆេទបោះពុម្ព</Text>
							<DateTimePicker
								style={{ paddingLeft: 10 }}
								yearPlaceholder="yyyy"
								dayPlaceholder="dd"
								monthPlaceholder="mm"
								clearIcon={null}
								disableCalendar={true}
								onChange={(e) => {
									setToday(moment(e));
								}}
								format="dd-MM-y"
								value={today}
								disableClock={true}
							/>
						</Flex>
					</Box>
				</Center>
			</Container>
		</>
	);
}

export default UserList;
