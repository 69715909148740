/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Box, Flex, Button, Table, Thead, Tbody, Tfoot, Tr, TableContainer, Center, useToast } from '@chakra-ui/react';
import { useHistory, useLocation } from 'react-router-dom';
import { gql, useLazyQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faPrint, faSlidersH } from '@fortawesome/free-solid-svg-icons';
import PrintLayout from '../../../../components/Print/PrintLayout';
import BreadcrumbComponent from '../../../../components/BreadcrumbComponent/BreadcrumbComponent';
import TableHead from '../../../../components/TableHead';
import NoData from '../../../../components/NoData';
import LocalizedDate from '../../../../components/LocalizedDate';
import CompanyName from '../../../../components/CompanyName';
import IntermediaryStatus from '../../../../components/ManageAgentPartner/Miscellaneous/IntermediaryStatus';
import TableData from '../../../../components/TableData';
import TotalRow from '../../../../components/TotalRow';
import ReactPaginate from 'react-paginate';
import c from '../../../../constant';
import IntermediaryTab from '../components/IntermediaryTab';
import IntermediaryFilter from '../components/FilterIntermediary';
import { useState } from 'react';
import datePickerFromToToDate from '../../../../utils/datePickerFromToToDate';

const Q_GET_REQUESTS = gql`
	query get(
		$pagerInput: PagerInput!
		$type: String
		$status: String
		$number: String
		$receivedDateFrom: DateTime
		$receivedDateTo: DateTime
		$companyId: String
		$companyName: String
	) {
		getIntermediaryRequestForPartnerList(
			pagerInput: $pagerInput
			type: $type
			status: $status
			number: $number
			receivedDateFrom: $receivedDateFrom
			receivedDateTo: $receivedDateTo
			companyId: $companyId
			companyName: $companyName
		) {
			totalRows
			totalPages
			list {
				id
				number
				adminStatus
				paymentStatus
				createdAt
				createdBy
				startedAt
				company {
					name
					nameKhmer
					logo
				}
				type
			}
		}
	}
`;

const Q_GET_COUNT_REQUESTS = gql`
	query get($type: String) {
		getCountStatusIntermediaryRequestForPartnerList(type: $type)
	}
`;

function IntermediaryPartnerRequest() {
	const toast = useToast();
	const location = useLocation();
	const urlParams = new URLSearchParams(location.search);
	const page = urlParams.get('page') || null;
	const type = urlParams.get('type') || 'new';

	const status = urlParams.get('status') || null;
	const number = urlParams.get('number') || null;
	const receivedDateFrom = urlParams.get('receivedDateFrom') || null;
	const receivedDateTo = urlParams.get('receivedDateTo') || null;
	const companyName = urlParams.get('companyName') || null;
	const companyId = urlParams.get('companyId') || null;
	const { push } = useHistory();
	const { t } = useTranslation();
	const [loadData, { loading, data, error }] = useLazyQuery(Q_GET_REQUESTS);
	const [loadCountData, { data: countData }] = useLazyQuery(Q_GET_COUNT_REQUESTS);
	const [isOpenFilter, setIsOpenFilter] = useState(false);
	let objectCountData = countData?.getCountStatusIntermediaryRequestForPartnerList
		? JSON.parse(countData?.getCountStatusIntermediaryRequestForPartnerList)
		: null;

	useEffect(() => {
		if (type) {
			loadCountData({ variables: { type } });
		}
	}, [type]);

	useEffect(() => {
		const limit = 10;
		const offset = parseInt(page ? (page - 1) * limit : 0) || 0;
		const pagerInput = { limit, offset: offset < 0 ? 0 : offset };
		loadData({
			variables: {
				pagerInput,
				type,
				status,
				number,
				receivedDateFrom: datePickerFromToToDate(receivedDateFrom),
				receivedDateTo: datePickerFromToToDate(receivedDateTo),
				companyId,
				companyName,
			},
		});
		window.scrollTo(0, 0);
	}, [page, type, status, number, receivedDateFrom, receivedDateTo, companyId, companyName]);

	useEffect(() => {
		if (error) {
			if (error?.graphQLErrors?.length > 0) {
				error?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(error?.message ? error?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [error]);

	function handlePageChanged({ selected }) {
		let url = `?page=${selected + 1}`;
		if (type) {
			url = url + `&type=${type}`;
		}

		if (companyId) {
			url += `&companyId=${companyId}`;
		}

		if (companyName) {
			url += `&companyName=${companyName}`;
		}

		if (number) {
			url += `&number=${number}`;
		}

		if (receivedDateFrom) {
			url += `&receivedDateFrom=${receivedDateFrom}`;
		}

		if (receivedDateTo) {
			url += `&receivedDateTo=${receivedDateTo}`;
		}

		if (status) {
			url += `&status=${status}`;
		}

		push(`/intermediary/partner-request${url}`);
	}

	function handleStatusChanged(statusData) {
		let url = `?page=1`;
		if (type) {
			url = url + `&type=${type}`;
		}

		if (companyId) {
			url += `&companyId=${companyId}`;
		}

		if (companyName) {
			url += `&companyName=${companyName}`;
		}

		if (number) {
			url += `&number=${number}`;
		}

		if (receivedDateFrom) {
			url += `&receivedDateFrom=${receivedDateFrom}`;
		}

		if (receivedDateTo) {
			url += `&receivedDateTo=${receivedDateTo}`;
		}

		if (statusData) {
			url += `&status=${statusData}`;
		}

		push(`/intermediary/partner-request${url}`);
	}

	return (
		<>
			<PrintLayout>
				<Container maxW="container.xl" mt="16px" mb="16px">
					<BreadcrumbComponent
						list={[
							{
								name: 'Manage Agent Partner',
								path: '/manage-agent-partner',
							},
							{
								name: 'Partner with Insurance Company',
								path: '#',
							},
						]}
					/>
					<Center mt="5">
						<Box
							w="100%"
							bg="white"
							borderRadius={c.borderRadius}
							border="1px solid #dbdbdb"
							boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
							pb="16px"
						>
							<Flex
								className="hidden-print responsive-header-for-table"
								bg="#FAFAFA"
								p="16px"
								pt="0"
								pb="0"
								mb="16px"
								alignItems="center"
								justifyContent="space-between"
								borderBottom="1px solid #dbdbdb"
							>
								<Flex className="panel-tab-container" pb="2px" overflow="scroll" minH="54px">
									{objectCountData && (
										<IntermediaryTab
											onCallback={(e) => handleStatusChanged(e)}
											initTab="all"
											data={[
												{
													label: 'R Office',
													tooltip: 'Reviewing By Office',
													badge: {
														value: objectCountData['count_reviewing_by_office'] || '0',
													},
													value: 'reviewing_by_office',
													important: 'office',
												},
												{
													label: 'R Department',
													tooltip: 'Reviewing By Department',
													badge: {
														value: objectCountData['count_reviewing_by_department'] || '0',
													},
													value: 'reviewing_by_department',
													important: 'department',
												},
												{
													label: 'R DG',
													tooltip: 'Reviewing by DG',
													badge: {
														value: objectCountData['count_reviewing_by_dg'] || '0',
													},
													value: 'reviewing_by_dg',
													important: 'dg',
												},
												{
													label: 'W Contract',
													tooltip: 'Waiting for Contract',
													badge: {
														value: objectCountData['count_waiting_for_contract'] || '0',
													},
													value: 'waiting_for_contract',
													important: '',
												},
												{
													label: 'R C Office',
													tooltip: 'Reviewing Contract By Office',
													badge: {
														value: objectCountData['count_reviewing_contract_by_office'] || '0',
													},
													value: 'reviewing_contract_by_office',
													important: 'office',
												},
												{
													label: 'R C Department',
													tooltip: 'Reviewing Contract By Department',
													badge: {
														value: objectCountData['count_reviewing_contract_by_department'] || '0',
													},
													value: 'reviewing_contract_by_department',
													important: 'department',
												},
												{
													label: 'Completed',
													badge: null,
													value: 'completed',
													important: '',
												},
												{
													label: 'All',
													badge: null,
													value: 'all',
													important: '',
												},
											]}
										/>
									)}
								</Flex>
								<Flex justifyContent="center" alignItems="center">
									<Button
										onClick={() => window?.print()}
										leftIcon={<FontAwesomeIcon icon={faPrint} style={{ fontSize: 16 }} />}
										colorScheme="gray"
										variant="solid"
										size="sm"
										borderRadius={c.borderRadius}
										border="1px solid #bdc3c7"
										ml={2}
									>
										{t('Print')}
									</Button>
									<Button
										onClick={() => setIsOpenFilter(true)}
										leftIcon={<FontAwesomeIcon icon={faSlidersH} style={{ fontSize: 16 }} />}
										colorScheme={number || (receivedDateFrom && receivedDateTo) || companyName || companyId ? 'yellow' : 'gray'}
										variant="solid"
										size="sm"
										borderRadius={c.borderRadius}
										border="1px solid #bdc3c7"
										ml={2}
									>
										{t('Filter')}
									</Button>
								</Flex>
							</Flex>
							<Box p="16px" pt="8px" pb="8px" mb="16px">
								{!loading && data?.getIntermediaryRequestForPartnerList?.list?.length <= 0 && (
									<>
										<NoData />
									</>
								)}
								{!loading && data?.getIntermediaryRequestForPartnerList?.list?.length > 0 && (
									<>
										<TableContainer borderWidth={1} borderStyle="dotted" pl={2} pr={2} pt={1} pb={1}>
											<Table variant="simple">
												<Thead>
													<Tr>
														<TableHead title="Number" />
														<TableHead title="Created At" />
														<TableHead title="Company" />
														<TableHead title="Status" isNumeric={true} />
													</Tr>
												</Thead>
												<Tbody>
													{data?.getIntermediaryRequestForPartnerList?.list?.map((e, i) => (
														<Tr
															_hover={{ bg: 'gray.200' }}
															key={`tr-${i}`}
															cursor="pointer"
															onMouseDown={() => push(`/intermediary/partner-request/detail?type=${type}&id=${e?.id}`)}
														>
															<TableData pt={3} pb={3} title={e?.number} />
															<TableData pt={3} pb={3} child={<LocalizedDate dateString={e?.createdAt} />} />
															<TableData pt={3} pb={3} child={<CompanyName company={e?.company} />} />
															<TableData pt={3} pb={3} child={<IntermediaryStatus status={e?.adminStatus} />} isNumeric={true} />
														</Tr>
													))}
												</Tbody>
												<Tfoot>
													<Tr>
														<TableHead title="Number" />
														<TableHead title="Created At" />
														<TableHead title="Company" />
														<TableHead title="Status" isNumeric={true} />
													</Tr>
												</Tfoot>
											</Table>
										</TableContainer>
										{data?.getIntermediaryRequestForPartnerList?.totalRows > 0 && (
											<>
												<Flex justifyContent="flex-end" direction="row">
													<TotalRow count={data?.getIntermediaryRequestForPartnerList?.totalRows} mt={2} />
												</Flex>
											</>
										)}
										{data?.getIntermediaryRequestForPartnerList?.totalPages > 1 && (
											<>
												<Flex alignItems="flex-end" direction="column" mt={4}>
													<Flex alignItems="flex-end" direction="column">
														<ReactPaginate
															previousLabel={<FontAwesomeIcon icon={faChevronLeft} style={{ fontSize: 12, cursor: 'pointer' }} />}
															nextLabel={<FontAwesomeIcon icon={faChevronRight} style={{ fontSize: 12, cursor: 'pointer' }} />}
															breakLabel={'...'}
															breakClassName={'break-me'}
															disableInitialCallback={true}
															pageCount={data?.getIntermediaryRequestForPartnerList?.totalPages}
															marginPagesDisplayed={2}
															pageRangeDisplayed={3}
															onPageChange={handlePageChanged}
															containerClassName={'pagination'}
															activeClassName={'active'}
															initialPage={(page - 1) | 0}
														/>
													</Flex>
												</Flex>
											</>
										)}
									</>
								)}
							</Box>
						</Box>
					</Center>
				</Container>
			</PrintLayout>

			<>
				{isOpenFilter && (
					<IntermediaryFilter
						isOpen={true}
						onClose={() => setIsOpenFilter(false)}
						onCallback={(result) => {
							let url = '';

							if (status) {
								url += `&status=${status}`;
							}

							if (result?.number) {
								url += `&number=${result?.number}`;
							}

							if (result?.receivedDateFrom && result?.receivedDateTo) {
								url += `&receivedDateFrom=${result?.receivedDateFrom}&receivedDateTo=${result?.receivedDateTo}`;
							}

							if (result?.companyName) {
								url += `&companyName=${result?.companyName}`;
							}

							if (result?.companyId) {
								url += `&companyId=${result?.companyId}`;
							}

							push(`/intermediary/partner-request?type=new&page=1${url}`);
						}}
						number={number}
						receivedDateFrom={receivedDateFrom}
						receivedDateTo={receivedDateTo}
						companyName={companyName}
						companyId={companyId}
					/>
				)}
			</>
		</>
	);
}

export default IntermediaryPartnerRequest;
