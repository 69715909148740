import React, { useEffect, useState } from 'react';
import { Container, Box, Flex, Text, Button } from "@chakra-ui/react"
import BreadcrumbComponent from "../../../components/BreadcrumbComponent/BreadcrumbComponent";
// import CamdigikeyList from "./list";
// import CamdigikeyList1 from "./list1";
import TableComponent from "../../../components/Table/Cloudscape/TableComponent";
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useLocalStorage from 'use-local-storage';
import { useDebounce } from 'use-debounce';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import c from '../../../constant';
import Modal from "@cloudscape-design/components/modal";
import {
    ButtonDropdown,
} from '@cloudscape-design/components';

const NAME_COLUMN_WIDTH = 180;

const GET_CLIENT_TOKEN = gql`
    mutation clientSignInByCamDigiKeyId($camDigiKeyId: String!){
        clientSignInByCamDigiKeyId(camDigiKeyId:$camDigiKeyId){
            accessToken
            expAt
            loginType
        }
    }
`

const QUERY_CAMDIGIKEYUSERS = gql`
	query getCamDigiKeyUsers($offset: Float, $limit: Float, $firstName: String, $lastName: String, $searchText: String) {
		getCamDigiKeyUsers(
			pagerInput: { offset: $offset, limit: $limit }
			filtersInput: { firstName: $firstName, lastName: $lastName, searchText: $searchText }
		) {
			totalRows
			totalPages
			Camdigikeys {
				id
				camdigikeyId
				dob
				email
				firstNameKh
				lastNameKh
				firstNameEn
				lastNameEn
				gender
				mobilePhone
				nationality
				personalCode
				status
			}
		}
	}
`;


export default function Camdigikey() {
    const { t } = useTranslation();
    const location = useLocation();
    const history = useHistory();
    const urlParams = new URLSearchParams(location.search);
    const currentPage = urlParams.get('page') || 1;
    const [loadCamdigikeyusers, { loading, data }] = useLazyQuery(QUERY_CAMDIGIKEYUSERS);
    const [visibleGetToken, setVisibleGetToken] = useState(false)
    const [loadToken, { data:tokenData }] = useMutation(GET_CLIENT_TOKEN);

    // Table config
    const [totalPage, setTotalPage] = useState(0)
    const tableName = "camdigikey";
    const rawColumns = [
        {
            id: 'personalCode',
            label: t('ID Card Number'),
            alwayVisible: true,
            header: <Flex style={{ paddingLeft: 20, paddingRight: 20 }}>{t('ID Card Number')}</Flex>,
            cell: (item) => (
                <Flex pl="20px" pr="20px" cursor="pointer">
                    <Text fontWeight={600} color="#0972d3" textDecoration="underline">
                        {item.personalCode}
                    </Text>
                </Flex>
            ),
            isRowHeader: true,
            width: 160,
        },
        {
            id: 'lastNameKh',
            label: t('Last Name'),
            header: t('Last Name'),
            cell: (item) => item.lastNameKh,
            width: NAME_COLUMN_WIDTH,
        },
        {
            id: 'firstNameKh',
            label: t('First Name'),
            header: t('First Name'),
            cell: (item) => item.firstNameKh,
            width: NAME_COLUMN_WIDTH,
        },
        {
            id: 'lastNameEn',
            label: t('Last Name EN'),
            header: t('Last Name EN'),
            cell: (item) => item.lastNameEn,
            width: NAME_COLUMN_WIDTH,
        },
        {
            id: 'firstNameEn',
            label: t('First Name EN'),
            header: t('First Name EN'),
            cell: (item) => item.firstNameEn,
            width: NAME_COLUMN_WIDTH,
        },
        {
            id: 'gender',
            label: t('Gender'),
            header: t('Gender'),
            cell: (item) => item.gender,
        },
        {
            id: 'dob',
            label: t('Date Of Birth'),
            header: t('Date Of Birth'),
            cell: (item) => item.dob,
            width: 150,
        },
        {
            id: 'mobilePhone',
            label: t('Phone'),
            header: t('Phone'),
            cell: (item) => item.mobilePhone,
            width: 160,
        },
        {
            id: 'email',
            label: t('Email'),
            header: t('Email'),
            cell: (item) => item.email,
            width: 200,
        },
        {
            id: 'nationality',
            label: t('Nationality'),
            header: t('Nationality'),
            cell: (item) => item.nationality,
        },
        {
            id: 'camdigikeyId',
            label: t('CamDigikey ID'),
            header: t('CamDigikey ID'),
            cell: (item) => item.camdigikeyId,
            width: 160,
        },
        {
            id: 'actions',
            label: t('Actions'),
            header: t('Actions'),
            cell: (item) => actionMenu(item),
        },
    ]
    const [preferences, setPreferences] = useLocalStorage(`${tableName}-table-Preferences`, {
        pageSize: 10,
        contentDisplay: rawColumns.map((column) => ({ id: column.id, visible: true })),
        wrapLines: false,
        stripedRows: false,
        contentDensity: 'comfortable',
        stickyColumns: { first: 1, last: 0 },
    });
    const { pageSize } = preferences;

    function getHeader() {
        return (
            <Flex className="table-header" direction={'row'} alignItems={'center'}>
                <Flex flexGrow={1} className="title">
                    <Text fontSize="xl" fontWeight={600} mt="10px" mb="10px">
                        {t('CamDigiKey')}
                    </Text>
                </Flex>
                <Flex direction={'row'}>
                    <Button
                        ml="10px"
                        onClick={() => window?.print()}
                        leftIcon={<FontAwesomeIcon icon={faPrint} style={{ fontSize: 16 }} />}
                        colorScheme="gray"
                        variant="solid"
                        size="sm"
                        borderRadius={c.borderRadius}
                        border="1px solid #bdc3c7"
                    >
                        {t('Print')}
                    </Button>
                </Flex>
            </Flex>
        )
    }
    // end table config

    useEffect(() => {
        loadCamdigikeyusersWithVar();
        window.scrollTo(0, 0);
    }, []);

    function onTablePageChange(e) {
        history.push(`/settings/camdigikey?page=${e?.detail?.currentPageIndex}`);
    }

    const [searchText, setSearchText] = useState('');
    const [deboundSearchText] = useDebounce(searchText, 1000);
    function onSearchTextChange(e) {
        setSearchText(e?.detail?.filteringText);
    }
    useEffect(() => {
        history.push(`/settings/camdigikey?page=1`);
    }, [deboundSearchText]);

    useEffect(() => {
        loadCamdigikeyusersWithVar();
    }, [currentPage, deboundSearchText, pageSize]);

    useEffect(() => {
        if (data?.getCamDigiKeyUsers?.totalPages) {
            setTotalPage(data?.getCamDigiKeyUsers?.totalPages || 1);
        }
    }, [data]);

    function loadCamdigikeyusersWithVar() {
        loadCamdigikeyusers({
            variables: {
                offset: currentPage ? (currentPage - 1) * pageSize : 0,
                limit: pageSize,
                // firstName: firstname,
                // lastName: lastname,
                searchText: deboundSearchText,
            },
        });
    }

    function actionMenu(item) {
        return (
            <ButtonDropdown
                className="action-button-table"
                variant="inline-icon"
                expandToViewport={true}
                items={[
                    { id: 'getTokenForWebsite', text: t('Get Token For Website') },
                ]}
                onItemClick={e => {
                    switch (e?.detail?.id) {
                        case "getTokenForWebsite":
                            setVisibleGetToken(true)
                            loadToken({
                                variables:{
                                    camDigiKeyId: item?.camdigikeyId
                                }
                            })
                            break;
                        default:
                            break;
                    }
                }}
            />
        )
    }

    return (
        <>
            <Container maxW="container.xl" mt="16px" mb="16px">
                <BreadcrumbComponent
                    list={[
                        {
                            name: "Settings",
                            path: "/settings",
                        },
                        {
                            name: "Camdigikey",
                            path: "#",
                        },
                    ]}
                />
                <Box pb="32px" w="100%" maxW={"8xl"} mt="5">
                    {/* <CamdigikeyList/> */}
                    {/* <CamdigikeyList1/> */}
                    <TableComponent
                        tableName={tableName}
                        rawColumns={rawColumns}
                        currentPage={currentPage}
                        totalPage={totalPage}
                        loading={loading}
                        items={data?.getCamDigiKeyUsers?.Camdigikeys || []}
                        preferences={preferences}
                        setPreferences={setPreferences}
                        onTablePageChange={onTablePageChange}
                        onSearchTextChange={onSearchTextChange}
                        searchText={searchText}
                        totalRows={data?.getCamDigiKeyUsers?.totalRows || ''}
                        header={getHeader()}
                    />
                </Box>
            </Container>

            {/* Get token for website */}
            <Modal
            onDismiss={() => setVisibleGetToken(false)}
            visible={visibleGetToken}
            header=""
            >
            {
                JSON.stringify(tokenData?.clientSignInByCamDigiKeyId)
            }
            </Modal>
        </>
    )
}

