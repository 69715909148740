/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { Box, Flex, Button, useToast, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, Grid, GridItem } from '@chakra-ui/react';
import TextFormField from '../../components/form/TextFormField';
import DateFormField from '../form/DateFormField';
import FileFormField from '../../components/form/FileFormField';
import moment from 'moment';
import c from '../../constant';
import * as Yup from 'yup';

const Q_GET_CERTIFICATE = gql`
	query get($id: String!) {
		getCompanyCertificateById(id: $id) {
			id
			number
			name
			nameLatin
			managerName
			managerNameLatin
			validFrom
			validTo
			file
			streetNumber
			houseNumber
			geoManual
			isGeoManual
			province
			district
			commune
			village
		}
	}
`;

const M_UPDATE_CERTIFICATE = gql`
	mutation create(
		$certificateFile: Upload
		$certificateFileSize: Float
		$geoLocation: String
		$isGeoManual: Boolean
		$geoManual: String
		$validTo: DateTime
		$validFrom: DateTime
		$name: String!
		$nameLatin: String
		$number: String
		$id: String!
		$managerName: String
		$managerNameLatin: String
	) {
		updateCompanyCertificateById(
			certificateFile: $certificateFile
			certificateFileSize: $certificateFileSize
			geoLocation: $geoLocation
			isGeoManual: $isGeoManual
			geoManual: $geoManual
			validTo: $validTo
			validFrom: $validFrom
			name: $name
			nameLatin: $nameLatin
			number: $number
			id: $id
			managerName: $managerName
			managerNameLatin: $managerNameLatin
		)
	}
`;

const validationSchema = Yup.object().shape({
	number: Yup.string().nullable(),
	name: Yup.string().required('required'),
	managerName: Yup.string().nullable(),
	managerNameLatin: Yup.string().nullable(),
	validFrom: Yup.string().nullable(),
	validTo: Yup.string().nullable(),
	file: Yup.mixed().test('fileSize', 'over limit', (value) => {
		if (value) {
			return value?.size <= c.maxFileSize / 10;
		} else {
			return true;
		}
	}),
});

function Create({ id, isOpen = false, onClose = () => null, callback = () => null }) {
	const toast = useToast();
	const { t } = useTranslation();
	const [loadCertificate, { data: getData }] = useLazyQuery(Q_GET_CERTIFICATE);
	const [updateCertificate, { loading, updateError, data: updateData }] = useMutation(M_UPDATE_CERTIFICATE);

	useEffect(() => {
		if (id) {
			const variables = { id };
			loadCertificate({ variables });
		}
	}, [id]);

	useEffect(() => {
		if (updateData) {
			toast({
				title: t('Company certificate updated successfully'),
				status: 'success',
				isClosable: true,
				position: 'top-right',
			});
			onClose();
			callback('Company certificate updated successfully');
		}
	}, [updateData]);

	useEffect(() => {
		if (updateError) {
			updateError.graphQLErrors.map(({ message }, i) =>
				toast({
					title: t(message),
					status: 'error',
					isClosable: true,
					position: 'top-right',
				})
			);
		}
	}, [updateError]);

	const certificateDate = (input) => {
		if (input) {
			return moment(input).toDate();
		}
		return null;
	};

	const certificateGeolocation = (input) => {
		const geoLocation = {
			province: input?.province,
			district: input?.district,
			commune: input?.commune,
			village: input?.village,
			streetNumber: input?.streetNumber,
			houseNumber: input?.houseNumber,
		};
		return geoLocation;
	};

	return (
		<>
			<Modal isOpen={isOpen} onClose={onClose} size="xl">
				<ModalOverlay />
				<ModalContent borderRadius={c.borderRadius} pb={4}>
					<ModalHeader>{t('Edit Company Certificate')}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						{getData?.getCompanyCertificateById && (
							<Formik
								validationSchema={validationSchema}
								initialValues={{
									number: getData?.getCompanyCertificateById?.number || '',
									name: getData?.getCompanyCertificateById?.name || '',
									nameLatin: getData?.getCompanyCertificateById?.nameLatin || '',
									managerName: getData?.getCompanyCertificateById?.managerName || '',
									managerNameLatin: getData?.getCompanyCertificateById?.managerNameLatin || '',
									geoLocation: certificateGeolocation(getData?.getCompanyCertificateById),
									isGeoManual: getData?.getCompanyCertificateById?.isGeoManual || false,
									geoManual: getData?.getCompanyCertificateById?.geoManual || '',
									validFrom: certificateDate(getData?.getCompanyCertificateById?.validFrom),
									validTo: certificateDate(getData?.getCompanyCertificateById?.validTo),
									file: null,
								}}
								onSubmit={(values) => {
									const variables = {
										id: id,
										number: values.number,
										name: values.name,
										nameLatin: values.nameLatin,
										managerName: values.managerName,
										managerNameLatin: values.managerNameLatin,
										geoLocation: values.geoLocation ? JSON.stringify(values.geoLocation) : null,
										isGeoManual: values?.isGeoManual,
										geoManual: values?.geoManual,
										certificateFile: values.file,
										certificateFileSize: values?.file?.size,
										validTo: values?.validTo,
										validFrom: values?.validFrom,
									};
									updateCertificate({ variables });
								}}
							>
								{(props) => (
									<Form>
										<Box mt="3">
											<Grid templateColumns="repeat(1, 1fr)" gap={6}>
												<GridItem w="100%">
													<TextFormField {...props} label="Number" name="number" />
													<TextFormField {...props} label="Name" name="name" required />
													<TextFormField {...props} label="Insurance product sale manager's Khmer name" name="managerName" />
													<TextFormField {...props} label="Insurance product sale manager's Latin name" name="managerNameLatin" />
													<FileFormField {...props} label="File" name="file" maxFileSize={c.maxFileSize / 10} />
													<Grid templateColumns="repeat(2, 1fr)" gap={6}>
														<GridItem w="100%">
															<DateFormField
																name="validFrom"
																label="Valid From"
																initialDate={getData?.getCompanyCertificateById?.validFrom}
															/>
														</GridItem>
														<GridItem w="100%">
															<DateFormField
																name="validTo"
																label="Valid To"
																initialDate={getData?.getCompanyCertificateById?.validTo}
															/>
														</GridItem>
													</Grid>
												</GridItem>
											</Grid>
											<Flex textAlign="right" mt="6" justifyContent="space-between" alignItems="center" float="right">
												<Button
													id="login-btn"
													variant="solid"
													size="md"
													h="42px"
													w="128px"
													bg="primary.dark"
													_hover={{ bg: 'primary.dark' }}
													color="white"
													type="submit"
													isLoading={loading}
													loadingText={t('Edit')}
													spinnerPlacement="start"
												>
													{t(`Edit`)}
												</Button>
											</Flex>
										</Box>
									</Form>
								)}
							</Formik>
						)}
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
}

export default Create;
