import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Container,
	Box,
	Flex,
	Text,
	Center,
	Badge,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	useToast,
	Tooltip,
	Wrap,
	WrapItem,
	Avatar,
	Button,
	AlertDialog,
	AlertDialogOverlay,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogBody,
	AlertDialogFooter,
} from '@chakra-ui/react';
import BreadcrumbComponent from '../../components/BreadcrumbComponent/BreadcrumbComponent';
import c from '../../constant';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import moment from 'moment';
import './style.css';
import { useLocation, useHistory } from 'react-router-dom';
import ViewComment from '../../components/Comment/ViewComment';
import useStore from '../../store';
import { faPrint, faMinusSquare, faPlusSquare, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PrintLayout from '../../components/Print/PrintLayout';
import { CheckIcon, WarningIcon, RepeatIcon, SpinnerIcon } from '@chakra-ui/icons';
import DownloadAndViewButton from '../../components/Files/DownloadAndViewButton';
import SelectUserModal from '../../components/Modal/SelectUserModal';
import DoneFileUplaod from '../../components/ComplaintOnInsurance/DoneFileUplaod';
import UserDislayNameById from '../../components/UserDislayNameById';

import 'moment/locale/en-gb';
import 'moment/locale/km';

const leftLabelWidth = '200px';

const QUERY_COMPLAINT = gql`
	query get($id: String!) {
		getComplaintOnInsuranceById(id: $id) {
			id
			no
			complaintOnInsuranceCategories
			plaintiff
			name
			gender
			dob
			state
			district
			commune
			village
			streetNumber
			houseNumber
			phoneNumber
			email
			socialMediaUrl
			companyName
			companyObjects
			address
			subject
			reason
			complaintId
			cause
			demand
			proof
			signatureOrFingerPrintOfPlaintiffOrRepresentative
			plaintiffOrRepresentativeName
			plaintiffOrRepresentativePhoneNumber
			copyOfIdCard
			copyOfBusinessLicense
			copyOfInsuranceAgreement
			proofFile
			transferComplaintFile
			otherFile
			createdAt
			complaintType
			settled
			settledDate
			spam
			notification {
				read
			}
			assignTo {
				user {
					id
					username
					fullName
					fullNameKhmer
					avatar
				}
				createdBy
				createdAt
			}
			doneFile
		}
	}
`;

const MUTATION_SETTLED_COMPLAINT = gql`
	mutation settled($id: String!) {
		settledComplaintOnInsuranceById(id: $id)
	}
`;

const QUERY_SET_AS_SPAM = gql`
	mutation setComplaintOnInsuranceByIdAsSpam($id: String!) {
		setComplaintOnInsuranceByIdAsSpam(id: $id)
	}
`;

const QUERY_SET_AS_NOT_SPAM = gql`
	mutation setComplaintOnInsuranceByIdAsNotSpam($id: String!) {
		setComplaintOnInsuranceByIdAsNotSpam(id: $id)
	}
`;

const MUTATION_ASSIGN_USER = gql`
	mutation assign($id: String!, $userId: String!) {
		assignComplaintOnInsuranceToUser(id: $id, userId: $userId)
	}
`;

const MUTATION_REMOVE_USER = gql`
	mutation remove($id: String!, $userId: String!) {
		removeAssignedComplaintOnInsuranceFromUser(id: $id, userId: $userId)
	}
`;

const MUTATION_SET_INPROGRESS_COMPLAINT = gql`
	mutation set($id: String!) {
		setComplaintOnInsuranceByIdAsInprogress(id: $id)
	}
`;
function ComplaintDetail() {
	const toast = useToast();
	const { t } = useTranslation();
	const location = useLocation();
	const history = useHistory();
	const urlParams = new URLSearchParams(location.search);
	const id = urlParams.get('id') ? urlParams.get('id') : null;
	const { currentLang } = useStore((state) => state.currentLang);
	moment.locale(currentLang === 'kh' ? 'km' : 'en-gb');
	const [complaint, setComplaint] = useState(null);
	const { currentUser } = useStore((state) => state.currentUser);

	const [loadComplaint, { error, loading, data }] = useLazyQuery(QUERY_COMPLAINT);
	const [settledComplaintById, { error: settledError, loading: settledLoading, data: settledData }] = useMutation(MUTATION_SETTLED_COMPLAINT);
	const [setAsSpamComplaint, { error: errorSetAsSpam, loading: loadingSetAsSpam, data: dataSetAsSpam }] = useMutation(QUERY_SET_AS_SPAM);
	const [setAsNotSpamComplaint, { error: errorSetAsNotSpam, loading: loadingSetAsNotSpam, data: dataSetAsNotSpam }] = useMutation(QUERY_SET_AS_NOT_SPAM);

	const setCountUnreadNotifications = useStore((state) => state.setCountUnreadNotifications);
	const { countUnreadNotifications } = useStore((state) => state.countUnreadNotifications);
	const [assignUserMutation, { error: errorAssign, loading: loadingAssign, data: dataAssign }] = useMutation(MUTATION_ASSIGN_USER);
	const [setInprogressComplaintById, { error: setInprogressError, loading: setInprogressLoading, data: setInprogressData }] =
		useMutation(MUTATION_SET_INPROGRESS_COMPLAINT);

	const [isOpen, setIsOpen] = useState(false);
	const [removingUserId, setRemovingUserId] = useState(null);
	const [selectUserModalOpen, setSelectUserModalOpen] = useState(false);

	const cancelRef = useRef();
	const onClose = () => setIsOpen(false);

	const [removeUserMutation, { error: errorRemove, loading: loadingRemove, data: dataRemove }] = useMutation(MUTATION_REMOVE_USER);

	useEffect(() => {
		if (dataRemove?.removeAssignedComplaintOnInsuranceFromUser) {
			toast({
				title: t('User removed successfully'),
				status: 'success',
				isClosable: true,
				position: 'top-right',
			});
			loadComplaint({
				variables: {
					id,
				},
			});
			onClose();
		}
	}, [dataRemove]);

	useEffect(() => {
		if (errorRemove) {
			toast({
				title: t('Remove assigned user error'),
				status: 'error',
				isClosable: true,
				position: 'top-right',
			});
		}
	}, [errorRemove]);

	useEffect(() => {
		window.scrollTo(0, 0);
		loadComplaint({
			variables: {
				id,
			},
		});
	}, []);

	useEffect(() => {
		if (id) {
			loadComplaint({
				variables: {
					id,
				},
			});
		}
	}, [id]);

	useEffect(() => {
		if (data?.getComplaintOnInsuranceById) {
			setComplaint(data?.getComplaintOnInsuranceById);
		}
	}, [data]);

	function complaintType(type) {
		return type === 'COMPLAINT' ? <Badge colorScheme="purple">{t('Complaint')}</Badge> : <Badge colorScheme="green">{t('Cancel Complaint')}</Badge>;
	}

	function row(label, value) {
		return (
			<Flex style={{ pageBreakInside: 'avoid' }} p="2" _hover={{ bg: 'gray.200' }}>
				<Text mr="2" fontWeight="500" w="200px" minW="200px">
					{t(label)}:
				</Text>
				<Box>
					{value?.file ? (
						<Box ml="-4">
							<DownloadAndViewButton url={value?.file} size={value?.fileSize} />
						</Box>
					) : (
						<>
							{
								// label === "Complaint ID"?
								// <Text color="blue.300" cursor="pointer" onClick={()=>history.push(`/complaint-on-insurance/detail?id=${value}`)}>{value?value:""}</Text>:
								<Text>{value ? t(value) : ''}</Text>
							}
						</>
					)}
				</Box>
			</Flex>
		);
	}

	useEffect(() => {
		if (settledData) {
			toast({
				title: t('Complaint settled'),
				status: 'success',
				isClosable: true,
				position: 'top-right',
			});
			loadComplaint({
				variables: {
					id,
				},
			});
		}
	}, [settledData]);

	function setSettledComplaint() {
		settledComplaintById({
			variables: {
				id,
			},
		});
	}

	function getStatus({ settled, settledDate }) {
		return settled === true ? (
			<Tooltip label={settledDate ? moment(settledDate).format('LLLL') : ''}>
				<Badge colorScheme="green">{t('Settled')}</Badge>
			</Tooltip>
		) : (
			<Badge colorScheme="yellow">{t('In progress')}</Badge>
		);
	}

	useEffect(() => {
		if (errorSetAsSpam) {
			toast({
				title: t('Set as spam error'),
				status: 'error',
				isClosable: true,
				position: 'top-right',
			});
		}
	}, [errorSetAsSpam]);

	useEffect(() => {
		if (errorSetAsNotSpam) {
			toast({
				title: t('Set as not spam error'),
				status: 'error',
				isClosable: true,
				position: 'top-right',
			});
		}
	}, [errorSetAsNotSpam]);

	useEffect(() => {
		if (dataSetAsNotSpam?.setComplaintOnInsuranceByIdAsNotSpam) {
			toast({
				title: t('Set as not spam successfully'),
				status: 'success',
				isClosable: true,
				position: 'top-right',
			});
			loadComplaint({
				variables: {
					id,
				},
			});
		}
	}, [dataSetAsNotSpam]);

	useEffect(() => {
		if (dataSetAsSpam?.setComplaintOnInsuranceByIdAsSpam) {
			toast({
				title: t('Set as spam successfully'),
				status: 'success',
				isClosable: true,
				position: 'top-right',
			});
			loadComplaint({
				variables: {
					id,
				},
			});
		}
	}, [dataSetAsSpam]);

	function setSpam() {
		setAsSpamComplaint({
			variables: {
				id,
			},
		});
	}

	function setNotSpam() {
		setAsNotSpamComplaint({
			variables: {
				id,
			},
		});
	}

	useEffect(() => {
		if (data?.getComplaintOnInsuranceById?.notification?.read === false) {
			setCountUnreadNotifications(countUnreadNotifications - 1);
		}
	}, [data]);

	function getDisplayName(user) {
		let result = user?.username;
		if (currentLang === 'kh') {
			if ((result = user?.fullNameKhmer)) {
				result = user?.fullNameKhmer;
			} else {
				if (user?.fullName) {
					result = user?.fullName;
				} else {
					result = user?.username;
				}
			}
		} else {
			if ((result = user?.fullName)) {
				result = user?.fullName;
			} else {
				if (user?.fullNameKhmer) {
					result = user?.fullNameKhmer;
				} else {
					result = user?.username;
				}
			}
		}

		return result;
	}

	function removeAssignUserDialog(user) {
		setIsOpen(true);
		setRemovingUserId(user);
	}

	function selectAssignUser(userData) {
		assignUserMutation({
			variables: {
				id: id,
				userId: userData.id,
			},
		});
	}

	useEffect(() => {
		if (errorAssign) {
			if (errorAssign?.graphQLErrors?.length > 0) {
				errorAssign?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'top-right',
					})
				);
			} else {
				toast({
					title: t(errorAssign?.message ? errorAssign?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'top-right',
				});
			}
		}
	}, [errorAssign]);

	useEffect(() => {
		if (dataAssign?.assignComplaintOnInsuranceToUser) {
			toast({
				title: t('User assigned successfully'),
				status: 'success',
				isClosable: true,
				position: 'top-right',
			});
			loadComplaint({
				variables: {
					id,
				},
			});
		}
	}, [dataAssign]);

	function setInprogressComplaint() {
		setInprogressComplaintById({
			variables: {
				id: id,
			},
		});
	}

	useEffect(() => {
		if (setInprogressData?.setComplaintOnInsuranceByIdAsInprogress) {
			toast({
				title: t('Complaint set to in progress successfully'),
				status: 'success',
				isClosable: true,
				position: 'top-right',
			});
			loadComplaint({
				variables: {
					id,
				},
			});
		}
	}, [setInprogressData]);

	return (
		<>
			<PrintLayout>
				<>
					<Container maxW="container.xl" mt="16px" mb="16px">
						<BreadcrumbComponent
							list={[
								{
									name: 'COMPLAINT',
									path: '/complaint-type',
								},
								{
									name: 'COMPLAINT ON INSURANCE',
									path: '/complaint-on-insurance?page=1&status=in progress',
								},
								{
									name: 'DETAIL',
									path: '#',
								},
							]}
						/>
						<Center mt="5">
							<Box
								w="100%"
								bg="white"
								borderRadius={c.borderRadius}
								border="1px solid #dbdbdb"
								boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
								pb="32px"
							>
								<Flex
									justifyContent="space-between"
									alignItems="center"
									bg="#FAFAFA"
									p="16px"
									pt="8px"
									pb="8px"
									mb="16px"
									borderBottom="1px solid #dbdbdb"
								>
									<Flex alignItems="center">
										<Text fontSize="x-large" fontWeight="bold" mr="4">
											{t('Complaint On Insurance')}
										</Text>
										{data?.getComplaintOnInsuranceById?.complaintType && complaintType(data?.getComplaintOnInsuranceById?.complaintType)}
										{data?.getComplaintOnInsuranceById?.spam === true && (
											<Badge ml="4" colorScheme="red">
												{t('Spam')}
											</Badge>
										)}
									</Flex>
									<Flex className="hidden-print">
										<Button
											mr="2"
											onClick={() => window?.print()}
											leftIcon={<FontAwesomeIcon icon={faPrint} style={{ fontSize: 16 }} />}
											colorScheme="gray"
											variant="solid"
											size="sm"
											borderRadius={c.borderRadius}
											border="1px solid #bdc3c7"
										>
											{t('Print')}
										</Button>
										{currentUser?.role === 'admin' && (
											<>
												{!error && data?.getComplaintOnInsuranceById?.id && (
													<Menu>
														<MenuButton cursor="pointer">
															<Button
																colorScheme="gray"
																variant="solid"
																size="sm"
																borderRadius={c.borderRadius}
																border="1px solid #bdc3c7"
															>
																<FontAwesomeIcon icon={faEllipsisH} style={{ fontSize: 16 }} />
															</Button>
														</MenuButton>
														<MenuList pt="4" pb="4" pl="3" pr="3" borderRadius={c.borderRadius}>
															{data?.getComplaintOnInsuranceById?.settled === false && currentUser.role === 'admin' && (
																<MenuItem icon={<CheckIcon />} color="green" onClick={() => setSettledComplaint()}>
																	{t('Settled')}
																</MenuItem>
															)}
															{data?.getComplaintOnInsuranceById?.settled === true && currentUser.role === 'admin' && (
																<MenuItem icon={<SpinnerIcon />} onClick={() => setInprogressComplaint()}>
																	{t('In progress')}
																</MenuItem>
															)}
															{data?.getComplaintOnInsuranceById?.spam === false && (
																<MenuItem icon={<WarningIcon />} onClick={() => setSpam()}>
																	{t('Spam')}
																</MenuItem>
															)}
															{data?.getComplaintOnInsuranceById?.spam === true && (
																<MenuItem icon={<RepeatIcon />} onClick={() => setNotSpam()}>
																	{t('Not Spam')}
																</MenuItem>
															)}
														</MenuList>
													</Menu>
												)}
											</>
										)}
									</Flex>
								</Flex>

								{loading && <Text ml="8">{t('Loading')}</Text>}

								{error && <Text>{t(error?.message)}</Text>}

								{data?.getComplaintOnInsuranceById && (
									<Box p="16px">
										{row('Number', complaint?.no)}
										{row(
											'Complaint Category',
											complaint?.complaintOnInsuranceCategories === 'COMPLAINT_OF_DISSATISFACTION_ON_INSURANCE'
												? 'Complaint of dissatifaction on insurance'
												: 'Complaint on dispute insurance'
										)}
										{row('Created at', moment(complaint?.createdAt).format('LLL'))}

										<Flex p="2" _hover={{ bg: 'gray.200' }}>
											<Text mr="2" fontWeight="500" w="200px">
												{t('Status')}:
											</Text>
											<Box>{getStatus({ settled: complaint?.settled, settledDate: complaint?.settledDate })}</Box>
										</Flex>
										{complaint?.settled && <>{row('Settled at', moment(complaint?.settledDate).format('LLL'))}</>}
										<Text ml="2" mt="4" fontWeight="bold" fontSize="xl">
											{complaint?.plaintiff === 'REAL_PERSON' ? t('Natural person') : t('Legal entity')}
										</Text>
										{complaint?.plaintiff === 'REAL_PERSON' && row('Name', complaint?.name)}
										{complaint?.plaintiff === 'REAL_PERSON' && row('Gender', t(complaint?.gender))}
										{complaint?.plaintiff === 'REAL_PERSON' &&
											row('Date of birth', complaint?.dob ? moment(complaint?.dob).format('l') : '')}
										{complaint?.plaintiff === 'REAL_PERSON' && row('State', complaint?.state)}
										{complaint?.plaintiff === 'REAL_PERSON' && row('District', complaint?.district)}
										{complaint?.plaintiff === 'REAL_PERSON' && row('Commune', complaint?.commune)}
										{complaint?.plaintiff === 'REAL_PERSON' && row('Village', complaint?.village)}
										{complaint?.plaintiff === 'REAL_PERSON' && row('Street number', complaint?.streetNumber)}
										{complaint?.plaintiff === 'REAL_PERSON' && row('House number', complaint?.houseNumber)}
										{complaint?.plaintiff === 'LEGAL_ENTITY' && row('Company name', complaint?.companyName)}
										{complaint?.plaintiff === 'LEGAL_ENTITY' && row('Company objects', complaint?.companyObjects)}
										{complaint?.plaintiff === 'LEGAL_ENTITY' && row('Address', complaint?.address)}
										{row('Phone number', complaint?.phoneNumber)}
										{row('Email', complaint?.email)}
										{row('Social media URL', complaint?.socialMediaUrl)}

										<Text ml="2" mt="4" fontWeight="bold" fontSize="xl">
											{t('Detail')}
										</Text>
										{row('Subject', complaint?.subject)}
										{complaint?.complaintType === 'CANCELATION_COMPLAINT' && row('Reason', complaint?.reason)}
										{complaint?.complaintType === 'CANCELATION_COMPLAINT' && row('Complaint ID', complaint?.complaintId)}
										{complaint?.complaintType === 'COMPLAINT' && row('Cause', complaint?.cause)}
										{complaint?.complaintType === 'COMPLAINT' && row('Demand', complaint?.demand)}
										{complaint?.complaintType === 'COMPLAINT' && row('Proof', complaint?.proof)}

										<Text ml="2" mt="4" fontWeight="bold" fontSize="xl">
											{t('Plaintiff/Representative')}
										</Text>
										{row('Name', complaint?.plaintiffOrRepresentativeName)}
										{row('Phone number', complaint?.plaintiffOrRepresentativePhoneNumber)}
										{row('Signature or fingerprint', complaint?.signatureOrFingerPrintOfPlaintiffOrRepresentative)}

										<Text ml="2" mt="4" fontWeight="bold" fontSize="xl">
											{t('Files')}
										</Text>
										{complaint?.complaintType === 'COMPLAINT' && (
											<>
												{row('ID card', complaint?.copyOfIdCard)}
												{row('Business license', complaint?.copyOfBusinessLicense)}
												{row('Insurance agreement', complaint?.copyOfInsuranceAgreement)}
												{row('Proof file', complaint?.proofFile)}
												{row('Transfer complaint file', complaint?.transferComplaintFile)}
											</>
										)}
										{row('Other file', complaint?.otherFile)}

										<Flex alignItems="center" mt="2" pl="8px">
											<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
												{t('Assign to')}:
											</Text>
											<Wrap ml="4">
												{data?.getComplaintOnInsuranceById?.assignTo?.map((aTo, index) => {
													return (
														<Flex direction="column" justifyContent="center" alignItems="center" key={'u-' + index}>
															<Tooltip
																label={
																	<>
																		{t('Assign By')}&nbsp;
																		<UserDislayNameById id={aTo?.createdBy} />
																		&nbsp;{', '}&nbsp;
																		{moment(aTo.createdAt).format('LLLL')}
																	</>
																}
															>
																<WrapItem className="avatarWraper">
																	{(currentUser?.role === 'admin' || currentUser?.canAssignUserToComplaint) &&
																		currentUser?.id !== aTo?.user?.id && (
																			<FontAwesomeIcon
																				onClick={() => removeAssignUserDialog(aTo?.user)}
																				className="remove"
																				icon={faMinusSquare}
																				style={{
																					fontSize: 18,
																					display: 'none',
																					position: 'absolute',
																					zIndex: '100',
																					marginLeft: 32,
																					color: 'red',
																					backgroundColor: 'white',
																					padding: 2,
																					cursor: 'pointer',
																				}}
																			/>
																		)}
																	<Avatar
																		name={getDisplayName(aTo?.user)}
																		src={aTo?.user?.avatar?.icon ? c.baseFilePath + aTo?.user?.avatar?.icon : null}
																	/>
																</WrapItem>
															</Tooltip>
															<Text textAlign="center">{getDisplayName(aTo.user)}</Text>
														</Flex>
													);
												})}
												{(currentUser?.role === 'admin' || currentUser?.canAssignUserToComplaint) && (
													<Flex>
														<Button
															className="hidden-print"
															onClick={() => setSelectUserModalOpen(true)}
															mt={1}
															leftIcon={<FontAwesomeIcon icon={faPlusSquare} style={{ fontSize: 18 }} />}
															colorScheme="blue"
															variant="outline"
														>
															{t('Add')}
														</Button>
													</Flex>
												)}
											</Wrap>
										</Flex>

										<Flex alignItems="center" mt="2" pl="8px">
											<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
												{t('Done')}:
											</Text>
											{data?.getComplaintOnInsuranceById?.settled ? (
												<>
													{data?.getComplaintOnInsuranceById?.doneFile ? (
														<DownloadAndViewButton
															url={data?.getComplaintOnInsuranceById?.doneFile?.file}
															size={data?.getComplaintOnInsuranceById?.doneFile?.fileSize}
														/>
													) : (
														<Text ml="4">N/A</Text>
													)}
												</>
											) : (
												<>
													{currentUser?.role === 'admin' || currentUser?.canUploadDoneFileToComplaint ? (
														<DoneFileUplaod
															id={id}
															callback={() => {
																window.scrollTo(0, 0);
																setTimeout(() => {
																	loadComplaint({
																		variables: {
																			id,
																		},
																	});
																}, 500);
															}}
														/>
													) : (
														<Text ml="4">N/A</Text>
													)}
												</>
											)}
										</Flex>
									</Box>
								)}
							</Box>
						</Center>
						{data?.getComplaintOnInsuranceById?.id && <ViewComment id={data?.getComplaintOnInsuranceById?.id} type="COMPLAINT_ON_INSURANCE" />}
					</Container>

					{selectUserModalOpen && (
						<SelectUserModal
							role="complaint"
							callback={selectAssignUser}
							isOpen={selectUserModalOpen}
							onClose={() => setSelectUserModalOpen(false)}
						/>
					)}

					<AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
						<AlertDialogOverlay>
							<AlertDialogContent>
								<AlertDialogHeader fontSize="lg" fontWeight="bold">
									{t('Remove Assigned User')}
								</AlertDialogHeader>

								<AlertDialogBody>
									{t(`Are you sure you want to remove`)} "{getDisplayName(removingUserId)}"?
								</AlertDialogBody>

								<AlertDialogFooter>
									<Button ref={cancelRef} onClick={onClose}>
										{t('Cancel')}
									</Button>
									<Button
										isLoading={loadingRemove}
										colorScheme="red"
										onClick={() => {
											removeUserMutation({
												variables: {
													id: id,
													userId: removingUserId?.id,
												},
											});
										}}
										ml={3}
									>
										{t('Remove')}
									</Button>
								</AlertDialogFooter>
							</AlertDialogContent>
						</AlertDialogOverlay>
					</AlertDialog>
				</>
			</PrintLayout>
		</>
	);
}

export default ComplaintDetail;
