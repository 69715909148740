/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Box, Flex, Text, Center, SimpleGrid, Link, useToast } from '@chakra-ui/react';
import { gql, useLazyQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import BreadcrumbComponent from '../../../components/BreadcrumbComponent/BreadcrumbComponent';
import ReactHtmlParser from 'react-html-parser';
import useMoment from '../../../hooks/useMoment';
import c from '../../../constant';

const leftLabelWidth = '124px';

const Q_EMAIL = gql`
	query get($id: String!) {
		getEmailById(id: $id) {
			id
			createdAt
			mailOptions
		}
	}
`;

function MailContent() {
	const location = useLocation();
	const urlParams = new URLSearchParams(location.search);
	const id = urlParams.get('id') ? urlParams.get('id') : null;
	const toast = useToast();
	const { toDateString } = useMoment();
	const { t } = useTranslation();
	const [loadMail, { error, data }] = useLazyQuery(Q_EMAIL);

	useEffect(() => {
		loadMail({ variables: { id } });
	}, []);

	useEffect(() => {
		if (error) {
			if (error?.graphQLErrors?.length > 0) {
				error?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(error?.message ? error?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [error]);

	function htmlContent(mailOptions) {
		return mailOptions?.attachments?.reduce((p, c) => p.replace(new RegExp('cid:' + c?.cid, 'g'), c?.href), mailOptions?.html);
	}

	return (
		<>
			<Container maxW="container.xl" mt="16px" mb="16px">
				<BreadcrumbComponent
					list={[
						{
							name: 'Settings',
							path: '/settings',
						},
						{
							name: 'Email',
							path: '/settings/email',
						},
						{
							name: 'Email Content',
							path: '#',
						},
					]}
				/>
				<Center mt="5">
					<Box
						w="100%"
						bg="white"
						borderRadius={c.borderRadius}
						border="1px solid #dbdbdb"
						boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
						pb="32px"
					>
						<Flex
							alignItems="center"
							justifyContent="space-between"
							bg="#FAFAFA"
							p="16px"
							pt="8px"
							pb="8px"
							mb="16px"
							borderBottom="1px solid #dbdbdb"
						>
							<Flex justifyContent="center" alignItems="center">
								<Text fontSize="x-large" fontWeight="bold" mr="4">
									{t('Email Content')}
								</Text>
							</Flex>
						</Flex>
						<Box p="16px">
							<Flex mt="2">
								<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
									{t('Created At')}
								</Text>
								<Text ml="1">{toDateString(data?.getEmailById?.createdAt, c.longDateFormat)}</Text>
							</Flex>
							<Flex mt="2">
								<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
									{t('Recipient')}
								</Text>
								<Text ml="1">{data?.getEmailById?.mailOptions?.to}</Text>
							</Flex>
							<Flex mt="2">
								<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
									{t('Sender')}
								</Text>
								<Text ml="1">{data?.getEmailById?.mailOptions?.from}</Text>
							</Flex>
							<Flex mt="2">
								<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
									{t('Subject')}
								</Text>
								<Text ml="1">{data?.getEmailById?.mailOptions?.subject}</Text>
							</Flex>
							<Flex mt="2">
								<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
									{t('Attachment')}
								</Text>
								<SimpleGrid ml={1} columns={1} spacing={4}>
									{data?.getEmailById?.mailOptions?.attachments?.map((e) => (
										<Box>
											<Text h={4} fontSize="sm">
												{e?.cid}
											</Text>
											<Text h={4} fontSize="sm">
												{e?.filename}
											</Text>
											<Link href={e?.href}>{e?.href}</Link>
										</Box>
									))}
								</SimpleGrid>
							</Flex>
							<Flex mt="4">{ReactHtmlParser(htmlContent(data?.getEmailById?.mailOptions))}</Flex>
						</Box>
					</Box>
				</Center>
			</Container>
		</>
	);
}

export default MailContent;
