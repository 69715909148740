/* eslint-disable */
import React, { useEffect, useState } from "react";
import {
    Image,
    Box,
    Center,
    Text,
    Input,
    Button,
    Flex,
    useToast,
    Progress
} from "@chakra-ui/react";
import Logo from "../../assets/images/logo.png";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import c from "../../constant";
import OneSignal from 'react-onesignal';
import useStore from "../../store";

const LOGIN_WITH_CAMDIGIKEY = gql`
query signIn($token: String!, $onesignalUserId: String){
    adminLoginWithCamDigiKey(
        onesignalUserId: $onesignalUserId
        token: $token
    )
}
`

const LINK_WITH_CAMDIGIKEY = gql`
mutation linkAccount($authToken: String!, $adminToken: String!){
    linkToCamDigiKey(
        authToken: $authToken
        adminToken: $adminToken
    )
}
`


function Login() {
    const toast = useToast()
    const history = useHistory();
    const { t } = useTranslation();
	const { currentLang } = useStore((state) => state.currentLang);
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const authToken = urlParams.get('authToken') ? urlParams.get('authToken') : null;
    const type = urlParams.get('type') ? urlParams.get('type') : null;
    const [onesignalUserId, setOneSignalUserId] = useState("")

    
    
    const [loginUser, {loading, error:errorLogin, data}] = useLazyQuery(
        LOGIN_WITH_CAMDIGIKEY
    );

    const [linkCamdigiAccount, {loading:loadingLink, error:errorLink, data:dataLink}] = useMutation(
        LINK_WITH_CAMDIGIKEY
    );

    useEffect(()=>{
        if(dataLink?.linkToCamDigiKey){
            toast({
                title: t("Link with CamDigiKey successfully, please relogin"),
                status: "success",
                isClosable: true,
                position:"top-right"
            })
            history.push("/")
        }
    },[dataLink])

    useEffect(()=>{
        if(data?.adminLoginWithCamDigiKey){
            sessionStorage.setItem("accessToken",data?.adminLoginWithCamDigiKey)
            history.push("/")
        }
        if(data===null){
            warningMessage("Login with CamDigiKey Failed")
            history.push("/")
        }
    },[data])

    useEffect(()=>{
        if(errorLink){
            warningMessage(errorLink.message)
            history.push("/")
        }
    },[errorLink])

    useEffect(()=>{
        if(errorLogin){
            warningMessage(errorLogin.message)
            history.push("/")
        }
    },[errorLogin])

    function warningMessage(message){
        toast({
            title: t(message),
            status: "warning",
            isClosable: true,
            position:"top-right"
        })
    }

    useEffect(()=>{
        OneSignal.getUserId().then(res=>{
            setOneSignalUserId(res)
        })
    },[])

    useEffect(()=>{
        if(authToken||type){
            if(type==="linkAccount"){
                adminLinkAccount(authToken)
            } else {
                loginWithCamDigiKeyToken(authToken)
            }
        }
    },[authToken, type])


    function loginWithCamDigiKeyToken(token){
        loginUser({
            variables:{
                token,
                onesignalUserId
            }
        })
    }

    function adminLinkAccount(camDigiKeyToken){
        const tmpAdminAccessToken = localStorage.getItem("adminAccessToken")
        linkCamdigiAccount({
            variables:{
                authToken:camDigiKeyToken,
                adminToken:tmpAdminAccessToken
            }
        })
    }



    return (
        <Box style={styles.container}>
            <Box
                p="16px"
                pt="32px"
                pb="32px"
                maxW="550px"
                w="100%"
                overflow="hidden"
            >
                <Box p="8" w="100%" bg="white" borderRadius="3px" 
                minH="476px">
                    <Center>
                        <Image src={Logo} boxSize="160px" loading="eager"/>
                    </Center>
                    <Center mt="16px">
                        <Text fontFamily="Moul" fontWeight={currentLang==="en"?"bold":"400"} textAlign="center" fontSize="20px" color="primary.dark">
                            {t("INSURANCE REGULATOR of CAMBODIA")}
                        </Text>
                    </Center>
                    {(loadingLink||loading)&&<Progress mt="20" size='xs' isIndeterminate />}
                </Box>
            </Box>
        </Box>
    );
}

export default Login;

const styles = {
    container: {
        minHeight: `calc(100vh - 234px)`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#ecf0f1",
    },
};
