/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Badge } from '@chakra-ui/react';
import { gql, useLazyQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const QUERY_COUNT = gql`
	query {
		countUnreadAgentRegistration
	}
`;

const QUERY_RENEWAL_COUNT = gql`
	query {
		countInprogressAgentRenewal
	}
`;

function AgentRegistrationBadge() {
	const { t } = useTranslation();
	const history = useHistory();
	const [loadCount, { data: countData }] = useLazyQuery(QUERY_COUNT);
	const [loadRenewalCount, { data: countRenewalData }] = useLazyQuery(QUERY_RENEWAL_COUNT);

	useEffect(() => {
		loadCount();
		loadRenewalCount();
	}, []);

	return (
		<>
			{parseInt(countData?.countUnreadAgentRegistration) > 0 && (
				<>
					<Badge fontSize="x-large" zIndex="99" top="2px" right="2px" position="absolute" colorScheme="red">
						{parseInt(countData?.countUnreadAgentRegistration)}
					</Badge>
				</>
			)}
			{parseInt(countRenewalData?.countInprogressAgentRenewal) > 0 && (
				<>
					<Badge
						zIndex="99"
						top="2px"
						right="2px"
						position="absolute"
						colorScheme="yellow"
						fontSize="16px"
						onClick={() => {
							history.push('/agent-renewal');
						}}
					>
						{parseInt(countRenewalData?.countInprogressAgentRenewal)} {t('Renew')}
					</Badge>
				</>
			)}
		</>
	);
}

export default AgentRegistrationBadge;
