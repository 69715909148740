import { Box, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

function LabelChild({ label = null, child = null }) {
	const { t } = useTranslation();

	return (
		<Box>
			<Text color="gray.600" fontWeight="semibold">
				{t(label)}
			</Text>
			<Box mt={1}>
				<>{child}</>
			</Box>
		</Box>
	);
}

export default LabelChild;
