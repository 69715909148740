const TerminationRequestStatus = {
	reviewing: 1,
	reviewingFinal: 2,
	completed: 3,
};

const GradeApproval = {
	primary: 'Primary',
	secondary: 'Secondary',
};

const TerminationRequestLocaleKeyValue = {
	6: 'Registration Card Not Renewed',
	5: 'TerminateContract',
	3: 'Resign',
	2: 'Terminated',
	1: 'Other',
};

export { TerminationRequestStatus, GradeApproval, TerminationRequestLocaleKeyValue };
