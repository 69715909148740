/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Box, Flex, Text, Center, Button, useToast } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { gql, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import BreadcrumbComponent from '../../components/BreadcrumbComponent/BreadcrumbComponent';
import RadioFormField from '../../components/form/RadioFormField';
import TextFormField from '../../components/form/TextFormField';
import FileFormField from '../../components/form/FileFormField';
import ImagePickerOneSize from '../../components/ImagePicker/ImagePickerOneSize';
import useStore from '../../store';
import GeoFormField from '../../components/form/GeoFormField';
import CheckboxFormField from '../../components/form/CheckBoxFormField';
import TextAreaFormField from '../../components/form/TextAreaFormField';
import DateFormField from '../../components/form/DateFormField';
import CheckBoxFormField from '../../components/form/CheckBoxFormField';
import LoadingOverlayText from '../../components/LoadingOverlay/LoadingOverlayText';
import c from '../../constant';
import * as Yup from 'yup';

const CREATE_COMPANY_MUTATION = gql`
	mutation create(
		$name: String!
		$nameKhmer: String!
		$url: String
		$logo: String
		$type: InsuranceInstitutionsType!
		$geoLocation: String
		$statuteFile: Upload
		$statuteSize: Float
		$licenseFile: Upload
		$licenseSize: Float
		$isGeoManual: Boolean!
		$geoManual: String
		$licenseValidTo: DateTime
		$licenseValidFrom: DateTime
		$number: String
		$isPermanentLicense: Boolean
		$description: String
		$isBancassurance: Boolean
	) {
		createCompany(
			name: $name
			nameKhmer: $nameKhmer
			url: $url
			logo: $logo
			type: $type
			geoLocation: $geoLocation
			statuteFile: $statuteFile
			statuteSize: $statuteSize
			licenseFile: $licenseFile
			licenseSize: $licenseSize
			isGeoManual: $isGeoManual
			geoManual: $geoManual
			licenseValidTo: $licenseValidTo
			licenseValidFrom: $licenseValidFrom
			number: $number
			isPermanentLicense: $isPermanentLicense
			description: $description
			isBancassurance: $isBancassurance
		)
	}
`;

const validationSchema = Yup.object().shape({
	name: Yup.string().required('Required'),
	khmerName: Yup.string().required('Required'),
	type: Yup.string().required('Required'),
	number: Yup.string(),
	url: Yup.string().url(),
	isGeoManual: Yup.boolean(),
	geoManual: Yup.mixed().when('isGeoManual', {
		is: (isGeoManual) => isGeoManual === true,
		then: Yup.string().required('required'),
		otherwise: Yup.string(),
	}),
	geoLocation: Yup.object().nullable(),
	statute: Yup.mixed().test('fileSize', 'over limit', (value) => {
		if (value) {
			return value?.size <= c.maxFileSize;
		} else {
			return true;
		}
	}),
	license: Yup.mixed().test('fileSize', 'over limit', (value) => {
		if (value) {
			return value?.size <= c.maxFileSize / 10;
		} else {
			return true;
		}
	}),
	licenseValidFrom: Yup.string().nullable(),
	licenseValidTo: Yup.string().nullable(),
	isPermanentLicense: Yup.boolean().nullable(),
	isBancassurance: Yup.boolean().nullable(),
	description: Yup.string().nullable(),
});

const criterion = 'INSURANCE_BROKER';

function Create() {
	const history = useHistory();
	const toast = useToast();
	const { t } = useTranslation();
	const { currentUser } = useStore((state) => state.currentUser);
	const [logoUrl, setLogoUrl] = useState(null);
	const [isBroker, setBroker] = useState(false);
	const [isGeoManual, setIsGeoManual] = useState(false);
	const [isPermanentLicense, setIsPermanentLicense] = useState(false);
	const [isBancassurance, setIsBancassurance] = useState(false);
	const [selectedCompanyType, setSelectedCompanyType] = useState('');

	const [createCompany, { loading, error, data }] = useMutation(CREATE_COMPANY_MUTATION);

	useEffect(() => {
		if (data) {
			if (data?.createCompany) {
				toast({
					title: t('Company created successfully'),
					status: 'success',
					isClosable: true,
					position: 'top-right',
				});
				history.push('/company');
			}
		}
	}, [data]);

	useEffect(() => {
		if (error) {
			if (error?.graphQLErrors?.length > 0) {
				error?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(error?.message ? error?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [error]);

	function create(values) {
		const variables = {
			name: values.name,
			nameKhmer: values.khmerName,
			url: values.url,
			logo: logoUrl,
			type: values.type,
			geoLocation: JSON.stringify(values.geoLocation),
			statuteFile: values.statute,
			statuteSize: values?.statute?.size,
			licenseFile: values.license,
			licenseSize: values?.license?.size,
			isGeoManual: values?.isGeoManual,
			geoManual: values?.geoManual,
			number: values?.number,
			licenseValidTo: values?.licenseValidTo,
			licenseValidFrom: values?.licenseValidFrom,
			isPermanentLicense: values?.isPermanentLicense,
			description: values?.description,
			isBancassurance: values?.isBancassurance,
		};
		createCompany({ variables });
	}

	return (
		<>
			<Container maxW="container.xl" mt="16px" mb="16px">
				<BreadcrumbComponent
					list={[
						{
							name: t('Insurance Institution'),
							path: '/company',
						},
						{
							name: t('Create'),
							path: '#',
						},
					]}
				/>
				{(currentUser?.role === 'admin' || currentUser?.canUpdateCompanyLicensing) && (
					<Center mt="5">
						<Box
							w="100%"
							bg="white"
							borderRadius={c.borderRadius}
							border="1px solid #dbdbdb"
							boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
							pb="32px"
						>
							<Flex bg="#FAFAFA" p="16px" pt="8px" pb="8px" mb="16px" justifyContent="space-between" borderBottom="1px solid #dbdbdb">
								<Text fontSize="x-large" fontWeight="bold">
									{t('Create New Company')}
								</Text>
							</Flex>
							<Box p="16px" maxW="480px">
								<Formik
									initialValues={{
										name: '',
										khmerName: '',
										url: '',
										type: '',
										geoLocation: null,
										statute: null,
										license: null,
										isGeoManual: false,
										geoManual: '',
										number: '',
										licenseValidFrom: null,
										licenseValidTo: null,
										isPermanentLicense: false,
										description: '',
										isBancassurance: false,
									}}
									validationSchema={validationSchema}
									onSubmit={create}
								>
									{(props) => (
										<Form>
											<ImagePickerOneSize
												label="Logo"
												onContentChange={(e) => {
													setLogoUrl(e?.medium);
												}}
											/>

											<Box mt="3">
												<TextFormField {...props} label="Name In Khmer" name="khmerName" required />
												<TextFormField {...props} label="Name In Latin" name="name" required />
												<RadioFormField
													label="Type"
													name="type"
													radios={[
														{ label: 'ASSOCIATION', value: 'ASSOCIATION' },
														{ label: 'GENERAL INSURANCE', value: 'GENERAL_INSURANCE' },
														{ label: 'LIFE INSURANCE', value: 'LIFE_INSURANCE' },
														{ label: 'MICRO INSURANCE', value: 'MICRO_INSURANCE' },
														{ label: 'REINSURANCE', value: 'REINSURANCE' },
														{ label: 'INSURANCE BROKER', value: 'INSURANCE_BROKER' },
														{ label: 'Insurance Agent Co Ltd', value: 'INSURANCE_AGENT' },
														{ label: 'Insurance Loss Adjuster Co Ltd', value: 'INSURANCE_LOSS_ADJUSTER' },
														{ label: 'INSURANCE AUDIT SERVICE', value: 'AUDIT_SERVICE' },
														{ label: 'INSURANCE CLAIM MANAGEMENT', value: 'INSURANCE_CLAIM_MANAGEMENT' },
														{ label: 'INSURANCE ACTUARIAL FIRM', value: 'ACTUARIAL_FIRM' },
													]}
													callbackValueChange={(e) => {
														setBroker(e === criterion);
														setSelectedCompanyType(e);
													}}
												/>
												{selectedCompanyType === 'INSURANCE_AGENT' && (
													<Box ml="4">
														<CheckBoxFormField
															{...props}
															label={t('Is Bancassurance')}
															name="isBancassurance"
															callbackValueChange={(e) => setIsBancassurance(e)}
														/>
													</Box>
												)}
												{!isBroker && (
													<Box>
														<TextFormField {...props} label="Number" name="number" />
														<TextFormField {...props} label="URL" name="url" />
														{isGeoManual ? (
															<TextAreaFormField
																{...props}
																label="Address"
																name="geoManual"
																required={true}
																buttomComponent={
																	<CheckboxFormField
																		{...props}
																		label="Input"
																		name="isGeoManual"
																		callbackValueChange={(e) => setIsGeoManual(e)}
																	/>
																}
															/>
														) : (
															<GeoFormField
																{...props}
																name="geoLocation"
																label="Address"
																buttomComponent={
																	<CheckboxFormField
																		{...props}
																		label="Input"
																		name="isGeoManual"
																		callbackValueChange={(e) => setIsGeoManual(e)}
																	/>
																}
															/>
														)}
														<FileFormField {...props} label="License" name="license" maxFileSize={c.maxFileSize / 10} />
														<FileFormField {...props} label="Statute" name="statute" maxFileSize={c.maxFileSize} />
														<CheckBoxFormField
															{...props}
															label="Is Permanent License"
															name="isPermanentLicense"
															callbackValueChange={(e) => setIsPermanentLicense(e)}
														/>

														{!isPermanentLicense && (
															<>
																<DateFormField name="licenseValidFrom" label="License Valid From" />
																<DateFormField name="licenseValidTo" label="License Valid To" />
															</>
														)}
														<TextAreaFormField {...props} label="Description" name="description" />
													</Box>
												)}
												<Flex textAlign="right" mt="6" justifyContent="space-between" alignItems="center">
													<Button
														_hover={{ bg: 'primary.dark' }}
														id="login-btn"
														variant="solid"
														size="md"
														h="42px"
														w="128px"
														bg="primary.dark"
														color="white"
														type="submit"
														isLoading={loading}
														loadingText={t('CREATE')}
														spinnerPlacement="start"
													>
														{t(`CREATE`)}
													</Button>
												</Flex>
											</Box>
										</Form>
									)}
								</Formik>
							</Box>
						</Box>
					</Center>
				)}
			</Container>
			{loading && <LoadingOverlayText />}
		</>
	);
}

export default Create;
