/* eslint-disable react-hooks/exhaustive-deps */
import { Button, useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import EventLoggingModal from './EventLoggingModal';
import c from '../../../constant';

export default function EventLoggingButton({ typeObjectId = null, actionObjectId = null, label = null }) {
	const { t } = useTranslation();
	const { isOpen, onOpen, onClose } = useDisclosure();

	return (
		<>
			<Button
				isLoading={false}
				isDisabled={false}
				onClick={onOpen}
				leftIcon={<FontAwesomeIcon icon={faFileAlt} style={{ fontSize: 16 }} />}
				iconSpacing={label ? 2 : 0}
				colorScheme="gray"
				variant="solid"
				size="sm"
				borderRadius={c.borderRadius}
				border="1px solid #bdc3c7"
				ml={2}
			>
				{t(label)}
			</Button>
			<EventLoggingModal typeObjectId={typeObjectId} actionObjectId={actionObjectId} isOpen={isOpen} onClose={onClose} />
		</>
	);
}
