import React, { useEffect, } from "react";
import { useTranslation } from "react-i18next";
import { Container, Center, Box, Flex, Text, useToast, Button } from "@chakra-ui/react";
import BreadcrumbComponent from "../../components/BreadcrumbComponent/BreadcrumbComponent";
import { Link } from "react-router-dom";
import ArticleRow from "../../components/ArticleRow/ArticleRow";
import { useLocation, useHistory } from "react-router-dom";
import { capitalizeFirstLetter } from "../../utils"
import { gql, useLazyQuery } from '@apollo/client';
import ReactPaginate from 'react-paginate';
import './style.css'
import c from "../../constant"
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const limit = 10
const QUERY_NEWS = gql`
query article($offset:Float,$category:String){
    adminArticles(
        filtersInput:{
            category: $category
        }
        pagerInput:{
            limit:${limit}
            offset:$offset
        }
    ){
        articles{
            id
            title
            category
            description
            thumbnail,
            isPublish
            publishDate
        }
        totalPages
    }
  }
`

function ListArticles() {
    const toast = useToast()
    const { t } = useTranslation();
    const location = useLocation();
    const history = useHistory();
    const [loadArticle, { error, loading, data }] = useLazyQuery(QUERY_NEWS)
    const urlParams = new URLSearchParams(location.search);
    const category = urlParams.get('category');
    const currentPage = urlParams.get('page') ? urlParams.get('page') : 1;
    useEffect(() => {
        window.scrollTo(0, 0);
        loadArticle({
            variables: {
                offset: currentPage ? ((currentPage - 1) * limit) : 0,
                category
            }
        })
    }, []);

    function handlePageClick(data) {
        let selected = data.selected;
        const page = selected + 1
        history.push(`/website/articles?category=${category}&page=${page}`)
    }

    useEffect(() => {
        if (error) {
            error.graphQLErrors.map(({ message }, i) => (
                toast({
                    title: message,
                    status: "error",
                    isClosable: true,
                    position: "top-right"
                })
            ))
        }
    }, [error])

    useEffect(() => {
        if (currentPage) {
            window.scrollTo(0, 0);
            loadArticle({
                variables: {
                    offset: currentPage ? ((currentPage - 1) * limit) : 0,
                    category
                }
            })
        }
    }, [currentPage])

    return (
        <>
            <Container maxW="container.xl" mt="16px" mb="16px">
                <BreadcrumbComponent
                    list={[
                        {
                            name: "WEBSITE",
                            path: "/website",
                        },
                        {
                            name: "ARTICLE",
                            path: "#",
                        },
                    ]}
                />
                <Center mt="5">
                    <Box
                        w="100%"
                        bg="white"
                        borderRadius={c.borderRadius}
                        border="1px solid #dbdbdb"
                        boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
                        pb="32px"
                    >
                        <Flex bg="#FAFAFA" p="16px" pt="8px" pb="8px" mb="16px" justifyContent="space-between" borderBottom="1px solid #dbdbdb">
                            <Text
                                fontSize="x-large"
                                fontWeight="bold"
                            >
                                {t(capitalizeFirstLetter(category))}
                            </Text>
                            <Link to={`/website/new-article?category=${category}`}>
                                <Button
                                    leftIcon={<FontAwesomeIcon icon={faPlus} style={{ fontSize: 16 }} />}
                                    colorScheme='gray'
                                    variant='solid'
                                    size="sm"
                                    borderRadius={c.borderRadius}
                                    border="1px solid #bdc3c7"
                                >
                                    {t("Add")}
                                </Button>
                            </Link>
                        </Flex>
                        {!error &&
                            <Box p="16px">
                                {(data?.adminArticles?.articles ? data?.adminArticles?.articles : c.skeletonData).map((item, index) => {
                                    return (
                                        <Link key={index} to={`/website/edit-article?id=${item.id}`}>
                                            <ArticleRow data={item} loading={loading} />
                                        </Link>
                                    );
                                })}
                            </Box>
                        }
                        <center>
                            {data?.adminArticles?.totalPages > 1 && <ReactPaginate
                                previousLabel={<ChevronLeftIcon fontSize="20px" />}
                                nextLabel={<ChevronRightIcon fontSize="20px" />}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={data?.adminArticles?.totalPages}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={3}
                                onPageChange={handlePageClick}
                                containerClassName={'pagination'}
                                activeClassName={'active'}
                                initialPage={(currentPage - 1) | 0}
                            />}
                        </center>
                    </Box>
                </Center>
            </Container>
        </>
    );
}

export default ListArticles;
