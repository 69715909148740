/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Box, Flex, Text, Center, Button, useToast } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';
import BreadcrumbComponent from '../../components/BreadcrumbComponent/BreadcrumbComponent';
import RadioFormField from '../../components/form/RadioFormField';
import TextFormField from '../../components/form/TextFormField';
import ImagePickerOneSize from '../../components/ImagePicker/ImagePickerOneSize';
import useStore from '../../store';
import GeoFormField from '../../components/form/GeoFormField';
import FileFormField from '../../components/form/FileFormField';
import TextAreaFormField from '../../components/form/TextAreaFormField';
import DateFormField from '../../components/form/DateFormField';
import moment from 'moment';
import CheckboxFormField from '../../components/form/CheckBoxFormField';
import CheckBoxFormField from '../../components/form/CheckBoxFormField';
import c from '../../constant';
import * as Yup from 'yup';
import LoadingOverlayText from '../../components/LoadingOverlay/LoadingOverlayText';

const UPDATE_COMPANY_MUTATION = gql`
	mutation update(
		$id: String!
		$name: String!
		$nameKhmer: String!
		$url: String
		$logo: String
		$type: InsuranceInstitutionsType!
		$geoLocation: String
		$statuteFile: Upload
		$statuteSize: Float
		$licenseFile: Upload
		$licenseSize: Float
		$isGeoManual: Boolean!
		$geoManual: String
		$licenseValidTo: DateTime
		$licenseValidFrom: DateTime
		$number: String
		$isPermanentLicense: Boolean
		$description: String
		$isBancassurance: Boolean
	) {
		updateCompanyById(
			id: $id
			name: $name
			nameKhmer: $nameKhmer
			url: $url
			logo: $logo
			type: $type
			geoLocation: $geoLocation
			statuteFile: $statuteFile
			statuteSize: $statuteSize
			licenseFile: $licenseFile
			licenseSize: $licenseSize
			isGeoManual: $isGeoManual
			geoManual: $geoManual
			licenseValidTo: $licenseValidTo
			licenseValidFrom: $licenseValidFrom
			number: $number
			isPermanentLicense: $isPermanentLicense
			description: $description
			isBancassurance: $isBancassurance
		)
	}
`;

const QUERY_COMPANY_BY_ID = gql`
	query get($id: String!) {
		getCompanyById(id: $id) {
			id
			name
			nameKhmer
			logo
			url
			type
			province
			district
			commune
			village
			streetNumber
			houseNumber
			statuteFile
			statuteFileSigned
			statuteSize
			licenseFile
			licenseFileSigned
			licenseSize
			isGeoManual
			geoManual
			licenseValidTo
			licenseValidFrom
			number
			isPermanentLicense
			description
			isBancassurance
		}
	}
`;

const validationSchema = Yup.object().shape({
	name: Yup.string().required('Required'),
	khmerName: Yup.string().required('Required'),
	type: Yup.string().required('Required'),
	number: Yup.string(),
	url: Yup.string().url(),
	isGeoManual: Yup.boolean(),
	geoManual: Yup.mixed().when('isGeoManual', {
		is: (isGeoManual) => isGeoManual === true,
		then: Yup.string().nullable(),
		otherwise: Yup.string().nullable(),
	}),
	geoLocation: Yup.object().nullable(),
	statute: Yup.mixed().test('fileSize', 'over limit', (value) => {
		if (value) {
			return value?.size <= c.maxFileSize;
		} else {
			return true;
		}
	}),
	license: Yup.mixed().test('fileSize', 'over limit', (value) => {
		if (value) {
			return value?.size <= c.maxFileSize / 10;
		} else {
			return true;
		}
	}),
	licenseValidFrom: Yup.string().nullable(),
	licenseValidTo: Yup.string().nullable(),
	isPermanentLicense: Yup.boolean().nullable(),
	description: Yup.string().nullable(),
	isBancassurance: Yup.boolean().nullable(),
});

const criterion = 'INSURANCE_BROKER';

function Update() {
	const history = useHistory();
	const toast = useToast();
	const location = useLocation();
	const urlParams = new URLSearchParams(location.search);
	const id = urlParams.get('id') ? urlParams.get('id') : null;
	const { t } = useTranslation();
	const { currentUser } = useStore((state) => state.currentUser);
	const [logoUrl, setLogoUrl] = useState(null);
	const [isBroker, setBroker] = useState(false);
	const [isGeoManual, setIsGeoManual] = useState(false);
	const [isPermanentLicense, setIsPermanentLicense] = useState(false);
	const [isBancassurance, setIsBancassurance] = useState(false);
	const [selectedCompanyType, setSelectedCompanyType] = useState('');

	const [updateCompany, { loading, error, data }] = useMutation(UPDATE_COMPANY_MUTATION);
	const [loadCompany, { loading: loadingCompany, error: errorCompany, data: dataCompany }] = useLazyQuery(QUERY_COMPANY_BY_ID);

	useEffect(() => {
		if (id) {
			loadCompany({ variables: { id } });
		}
	}, [id]);

	useEffect(() => {
		setBroker(dataCompany?.getCompanyById?.type === criterion);
		setIsGeoManual(dataCompany?.getCompanyById?.isGeoManual || false);
		setIsPermanentLicense(dataCompany?.getCompanyById?.isPermanentLicense || false);
		setIsBancassurance(dataCompany?.getCompanyById?.isBancassurance || false);
		setSelectedCompanyType(dataCompany?.getCompanyById?.type);
	}, [dataCompany]);

	useEffect(() => {
		if (errorCompany) {
			if (errorCompany?.graphQLErrors?.length > 0) {
				errorCompany?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(errorCompany?.message ? errorCompany?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [errorCompany]);

	useEffect(() => {
		if (data) {
			if (data?.updateCompanyById) {
				toast({
					title: t('Company updated successfully'),
					status: 'success',
					isClosable: true,
					position: 'top-right',
				});
				history.push(`/company/detail?id=${id}`);
			}
		}
	}, [data]);

	useEffect(() => {
		if (error) {
			if (error?.graphQLErrors?.length > 0) {
				error?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(error?.message ? error?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [error]);

	function geoObjectToValue(obj) {
		return JSON.stringify({
			province: obj?.province?.value || obj?.province || '' ? { value: obj?.province?.value || obj?.province || '' } : null,
			district: obj?.district?.value || obj?.district || '' ? { value: obj?.district?.value || obj?.district || '' } : null,
			commune: obj?.commune?.value || obj?.commune || '' ? { value: obj?.commune?.value || obj?.commune || '' } : null,
			village: obj?.village?.value || obj?.village || '' ? { value: obj?.village?.value || obj?.village || '' } : null,
			streetNumber: obj?.streetNumber || '',
			houseNumber: obj?.houseNumber || '',
		});
	}

	function update(values) {
		let variables = {
			id,
			name: values.name,
			nameKhmer: values.khmerName,
			url: values.url,
			logo: logoUrl,
			type: values.type,
			geoLocation: values.geoLocation ? geoObjectToValue(values.geoLocation) : null,
			statuteFile: null,
			statuteSize: null,
			licenseFile: null,
			licenseSize: null,
			isGeoManual: values?.isGeoManual,
			geoManual: values?.geoManual,
			number: values?.number,
			licenseValidTo: values?.licenseValidTo,
			licenseValidFrom: values?.licenseValidFrom,
			isPermanentLicense: values?.isPermanentLicense,
			description: values?.description,
			isBancassurance: isBancassurance,
		};
		if (values.statute) {
			variables = {
				...variables,
				statuteFile: values.statute,
				statuteSize: values?.statute?.size,
			};
		}
		if (values.license) {
			variables = {
				...variables,
				licenseFile: values.license,
				licenseSize: values?.license?.size,
			};
		}
		updateCompany({ variables });
	}

	return (
		<>
			<Container maxW="container.xl" mt="16px" mb="16px">
				<BreadcrumbComponent
					list={[
						{
							name: t('Insurance Institution'),
							path: '/company',
						},
						{
							name: t('Update'),
							path: '#',
						},
					]}
				/>
				{(currentUser?.role === 'admin' || currentUser?.canUpdateCompanyLicensing) && (
					<Center mt="5">
						<Box
							w="100%"
							bg="white"
							borderRadius={c.borderRadius}
							border="1px solid #dbdbdb"
							boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
							pb="32px"
						>
							<Flex bg="#FAFAFA" p="16px" pt="8px" pb="8px" mb="16px" justifyContent="space-between" borderBottom="1px solid #dbdbdb">
								<Text fontSize="x-large" fontWeight="bold">
									{t('Update Company')}
								</Text>
							</Flex>
							{dataCompany?.getCompanyById && (
								<Box p="16px" maxW="480px">
									<Formik
										initialValues={{
											name: dataCompany?.getCompanyById?.name ? dataCompany?.getCompanyById?.name : '',
											khmerName: dataCompany?.getCompanyById?.nameKhmer ? dataCompany?.getCompanyById?.nameKhmer : '',
											url: dataCompany?.getCompanyById?.url ? dataCompany?.getCompanyById?.url : '',
											type: dataCompany?.getCompanyById?.type ? dataCompany?.getCompanyById?.type : '',
											geoLocation: {
												province: dataCompany?.getCompanyById?.province,
												district: dataCompany?.getCompanyById?.district,
												commune: dataCompany?.getCompanyById?.commune,
												village: dataCompany?.getCompanyById?.village,
												streetNumber: dataCompany?.getCompanyById?.streetNumber,
												houseNumber: dataCompany?.getCompanyById?.houseNumber,
											},
											statute: null,
											license: null,
											isGeoManual: dataCompany?.getCompanyById?.isGeoManual,
											geoManual: dataCompany?.getCompanyById?.geoManual,
											number: dataCompany?.getCompanyById?.number ? dataCompany?.getCompanyById?.number : '',
											licenseValidFrom: moment(dataCompany?.getCompanyById?.licenseValidFrom).toDate() || null,
											licenseValidTo: moment(dataCompany?.getCompanyById?.licenseValidTo).toDate() || null,
											isPermanentLicense: dataCompany?.getCompanyById?.isPermanentLicense,
											description: dataCompany?.getCompanyById?.description ? dataCompany?.getCompanyById?.description : '',
											isBancassurance: dataCompany?.getCompanyById?.isBancassurance,
										}}
										validationSchema={validationSchema}
										onSubmit={update}
									>
										{(props) => (
											<Form>
												<ImagePickerOneSize
													initContent={dataCompany?.getCompanyById?.logo ? { medium: dataCompany?.getCompanyById?.logo } : null}
													label="Logo"
													onContentChange={(e) => setLogoUrl(e?.medium)}
												/>
												<Box mt="3">
													<TextFormField {...props} label="Name In Khmer" name="khmerName" required />
													<TextFormField {...props} label="Name In Latin" name="name" required />
													<RadioFormField
														label="Type"
														name="type"
														radios={[
															{ label: 'ASSOCIATION', value: 'ASSOCIATION' },
															{ label: 'GENERAL INSURANCE', value: 'GENERAL_INSURANCE' },
															{ label: 'LIFE INSURANCE', value: 'LIFE_INSURANCE' },
															{ label: 'MICRO INSURANCE', value: 'MICRO_INSURANCE' },
															{ label: 'REINSURANCE', value: 'REINSURANCE' },
															{ label: 'INSURANCE BROKER', value: 'INSURANCE_BROKER' },
															{ label: 'Insurance Agent Co Ltd', value: 'INSURANCE_AGENT' },
															{ label: 'Insurance Loss Adjuster Co Ltd', value: 'INSURANCE_LOSS_ADJUSTER' },
															{ label: 'INSURANCE AUDIT SERVICE', value: 'AUDIT_SERVICE' },
															{ label: 'INSURANCE CLAIM MANAGEMENT', value: 'INSURANCE_CLAIM_MANAGEMENT' },
															{ label: 'INSURANCE ACTUARIAL FIRM', value: 'ACTUARIAL_FIRM' },
														]}
														initValue={dataCompany?.getCompanyById?.type || ''}
														callbackValueChange={(e) => {
															setBroker(e === criterion);
															setSelectedCompanyType(e);
														}}
													/>

													{selectedCompanyType === 'INSURANCE_AGENT' && (
														<Box ml="4">
															<CheckBoxFormField
																{...props}
																label={t('Is Bancassurance')}
																name="isBancassurance"
																callbackValueChange={(e) => setIsBancassurance(e)}
															/>
														</Box>
													)}

													{!isBroker && (
														<Box>
															<TextFormField {...props} label="Number" name="number" />
															<TextFormField {...props} label="URL" name="url" />
															{isGeoManual ? (
																<TextAreaFormField
																	{...props}
																	label="Address"
																	name="geoManual"
																	required={true}
																	buttomComponent={
																		<CheckboxFormField
																			{...props}
																			label="Input"
																			name="isGeoManual"
																			callbackValueChange={(e) => setIsGeoManual(e)}
																		/>
																	}
																/>
															) : (
																<GeoFormField
																	{...props}
																	name="geoLocation"
																	label="Address"
																	buttomComponent={
																		<CheckboxFormField
																			{...props}
																			label="Input"
																			name="isGeoManual"
																			callbackValueChange={(e) => setIsGeoManual(e)}
																		/>
																	}
																/>
															)}
															<FileFormField {...props} label="License" name="license" maxFileSize={c.maxFileSize / 10} />
															<FileFormField {...props} label="Statute" name="statute" maxFileSize={c.maxFileSize} />
															<CheckBoxFormField
																{...props}
																label="Is Permanent License"
																name="isPermanentLicense"
																callbackValueChange={(e) => setIsPermanentLicense(e)}
															/>

															{!isPermanentLicense && (
																<>
																	<DateFormField
																		name="licenseValidFrom"
																		label="License Valid From"
																		initialDate={dataCompany?.getCompanyById?.licenseValidFrom}
																	/>
																	<DateFormField
																		name="licenseValidTo"
																		label="License Valid To"
																		initialDate={dataCompany?.getCompanyById?.licenseValidTo}
																	/>
																</>
															)}
															<TextAreaFormField {...props} label="Description" name="description" />
														</Box>
													)}
													<Flex textAlign="right" mt="6" justifyContent="space-between" alignItems="center">
														<Button
															id="login-btn"
															variant="solid"
															size="md"
															h="42px"
															w="128px"
															bg="primary.dark"
															_hover={{ bg: 'primary.dark' }}
															color="white"
															type="submit"
															isLoading={loading}
															loadingText={t('UPDATE')}
															spinnerPlacement="start"
														>
															{t(`UPDATE`)}
														</Button>
													</Flex>
												</Box>
											</Form>
										)}
									</Formik>
								</Box>
							)}
						</Box>
					</Center>
				)}
			</Container>
			{loading || (loadingCompany && <LoadingOverlayText />)}
		</>
	);
}

export default Update;
