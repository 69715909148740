import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Box, Flex, Button, useToast, Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
} from "@chakra-ui/react";
import c from "../../constant"
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { gql, useMutation } from '@apollo/client';
import { useHistory } from "react-router-dom";
import RadioFormField from "../../components/form/RadioFormField"
import TextFormField from "../../components/form/TextFormField"
import countriesData from "../../utils/countries"
import SelectSearchFormField from "../../components/form/SelectSearchFormField"
import moment from "moment";
import DateFormField from "../form/DateFormField";

const CREATE_COMPANY_SHAREHOLDER_MUTATION = gql`
mutation create($name: String, $representative: String, $gender: String, $nationality: String, $amount: Float, $currency: String, $percentage: Float, $companyId: String!, $approvalDate: DateTime){
    createCompanyShareholder(
      companyId:$companyId
      percentage:$percentage
      amount:$amount
      nationality:$nationality
      name:$name
      representative: $representative
      gender:$gender
      currency: $currency
      approvalDate:$approvalDate
    )
  }
 `

const signUpSchema = Yup.object().shape({
    name: Yup.string(),
    representative: Yup.string().nullable(),
    gender: Yup.string().nullable(),
    nationality: Yup.object().nullable(),
    amount: Yup.string(),
    currency: Yup.string(),
    percentage: Yup.string(),
    approvalDate: Yup.string().nullable()
});

function Create({
    isOpen = false, onClose = () => null, callback = () => null, companyId
}) {
    const history = useHistory();
    const toast = useToast()
    const { t } = useTranslation();
    const [createCompanyShareholder, { loading, error, data }] = useMutation(
        CREATE_COMPANY_SHAREHOLDER_MUTATION
    );
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (data) {
            if (data?.createCompanyShareholder) {
                toast({
                    title: t("Company shareholder created successfully"),
                    status: "success",
                    isClosable: true,
                    position: "top-right"
                })
                onClose()
                callback("Company shareholder created successfully")
            }
        }
    }, [data])

    useEffect(() => {
        if (error) {
            error.graphQLErrors.map(({ message }, i) => (
                toast({
                    title: t(message),
                    status: "error",
                    isClosable: true,
                    position: "top-right"
                })
            ))
        }
    }, [error])


    function create(values) {
        createCompanyShareholder({
            variables: {
                companyId: companyId,
                amount: parseFloat(values.amount),
                currency: values.currency,
                percentage: parseFloat(values.percentage),
                nationality: values?.nationality?.value ? values?.nationality?.value : null,
                name: values.name,
                representative: values.representative,
                gender: values.gender,
                approvalDate: values.approvalDate?moment(values.approvalDate).toDate():null
            }
        })
    }

    function getNationalityOptions() {
        let tmpData = []
        countriesData.map((item) => {
            tmpData.push({
                label: t(item.nationality),
                value: item.nationality
            })
        })
        return tmpData
    }

    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={() => {
                    onClose()

                }}
                size="md"
            >
                <ModalOverlay />
                <ModalContent borderRadius={c.borderRadius} pb="2">
                    <ModalHeader>{t("Add Company Shareholder")}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Formik
                            initialValues={{
                                name: "",
                                representative: "",
                                gender: "",
                                nationality: "",
                                amount: "",
                                currency: "",
                                percentage: "",
                                approvalDate: null
                            }}
                            validationSchema={signUpSchema}
                            onSubmit={(values) => {
                                create(values);
                            }}
                        >
                            {(props) => (
                                <Form>
                                    <Box mt="3">
                                        <TextFormField
                                            {...props}
                                            label="Company Name"
                                            name="name"
                                        />

                                        <TextFormField
                                            {...props}
                                            label="Representative"
                                            name="representative"
                                        />

                                        <RadioFormField
                                            label="Gender"
                                            name="gender"
                                            radios={[
                                                { label: "Male", value: "male" },
                                                { label: "Female", value: "female" },
                                            ]}
                                        />

                                        <SelectSearchFormField
                                            {...props}
                                            name="nationality"
                                            label="Nationality"
                                            placeholder="Nationality"
                                            options={getNationalityOptions()}
                                        />

                                        <TextFormField
                                            {...props}
                                            label="Amount"
                                            name="amount"
                                            // type="number"
                                        />

                                        <RadioFormField
                                            label="Currency"
                                            name="currency"
                                            radios={[
                                                { label: "KHR", value: "KHR" },
                                                { label: "USD", value: "USD" },
                                            ]}
                                        />

                                        <TextFormField
                                            {...props}
                                            label="Percentage"
                                            name="percentage"
                                        />

                                        <DateFormField
                                            name="approvalDate"
                                            label="Approval Date"
                                        />

                                        <Flex
                                            textAlign="right"
                                            mt="6"
                                            justifyContent="space-between"
                                            alignItems="center"
                                        >
                                            <Button
                                                id="login-btn"
                                                variant="solid"
                                                size="md"
                                                h="42px"
                                                w="128px"
                                                bg="primary.dark"
                                                _hover={{ bg: "primary.dark" }}
                                                color="white"
                                                // borderRadius="31px"
                                                type="submit"
                                                isLoading={loading}
                                                loadingText={t("Add")}
                                                spinnerPlacement="start"
                                            >
                                                {t(`Add`)}
                                            </Button>
                                        </Flex>
                                    </Box>
                                </Form>
                            )}
                        </Formik>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
}

export default Create;
