import { Modal, ModalOverlay, ModalContent, Text, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import c from '../../constant';

export default function LoadingOverlayText() {
	const { t } = useTranslation();

	return (
		<>
			<Modal bg="rgba(0,0,0,0)" isOpen={true}>
				<ModalOverlay bg="rgba(0,0,0,0)" />
				<ModalContent borderRadius={c.borderRadius} bg="rgba(0,0,0,0)" p="4" boxShadow="unset">
					<Flex bg="rgba(0,0,0,0)" justifyContent="center">
						<Text bg="#ffeaa7" color="black" pt="2px" pb="2px" pl="3" pr="3" border="2px solid #fdcb6e" fontWeight="bold">
							{t('Loading')}
						</Text>
					</Flex>
				</ModalContent>
			</Modal>
		</>
	);
}
