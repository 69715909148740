import { Text, Th } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

function TableHead({ title = null, child = null, isNumeric = false, width = 'auto', pl = 1, pr = 1, pt = 1, pb = 1 }) {
	const { t } = useTranslation();

	return (
		<Th isNumeric={isNumeric} width={width} pl={pl} pr={pr} pt={pt} pb={pb}>
			{title ? (
				<>
					<Text color="gray.600" fontSize="14px">
						{t(title)}
					</Text>
				</>
			) : (
				<>{child}</>
			)}
		</Th>
	);
}

export default TableHead;
