import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Container, Box, Flex, Text, Center, Table,
    Checkbox,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    Badge,
    Tooltip,
    Spinner
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { gql, useLazyQuery } from '@apollo/client';


const QUERY_DETAIL = gql`
query get($id: String!){
    getPaymentById(id:$id){
        id
        tranId
        status
      }
  }
`

const QUERY_CHECK_TR = gql`
query check($tranId: String!){
    paywayCheckTrByTranId(tranId:$tranId)
  }
`
function RecheckAndGetUpdateStatusOfTransaction({ tranId, id, onNext }) {
    const { t } = useTranslation();
    const [checkTrByCode, { error:errorCheckTr, loading:loadingCheckTr, data:dataTr }] = useLazyQuery(QUERY_CHECK_TR)
    const [loadDetail, { error, loading, data }] = useLazyQuery(QUERY_DETAIL)

    useEffect(()=>{
        if(error || errorCheckTr || data){
            onNext()
        }
    },[error, errorCheckTr, data])

    function getStatusBadge(type){
        switch (type) {
            case "PENDING":
                return <Badge colorScheme="yellow">{t("PENDING")}</Badge>
            case "APPROVED":
                return <Badge colorScheme="green">{t("APPROVED")}</Badge>
            case "EXPIRED":
                return <Badge colorScheme="gray">{t("EXPIRED")}</Badge>
            case "DECLINED":
                return <Badge colorScheme="gray">{t("DECLINED")}</Badge>
            case "REFUNDED":
                return <Badge colorScheme="purple">{t("REFUNDED")}</Badge>
            case "ERROR":
                return <Badge colorScheme="red">{t("ERROR")}</Badge>
            default:
                return null;
        }
    }

    useEffect(()=>{
        if(tranId){
            checkTrByCode({
                variables:{
                    tranId
                }
            })
        }
    },[tranId])

    useEffect(()=>{
        if(dataTr){
            loadDetail({
                variables: {
                    id
                }
            })
        }
    },[dataTr])

    return (
        <Flex w="100%" justifyContent="flex-end">
            {
                errorCheckTr&&<Text color="red">Failed to Check</Text>
            }
            {
                (loadingCheckTr||loading)&&
                <Spinner
                    thickness='4px'
                    speed='0.65s'
                    emptyColor='gray.200'
                    color='blue.500'
                    size='sm'
                />
            }
            {
                data?.getPaymentById?.status?getStatusBadge(data?.getPaymentById?.status):null
            }
        </Flex>
    )
}

export default React.memo(RecheckAndGetUpdateStatusOfTransaction)