/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Text, Box, Button, Alert, AlertDescription, Flex } from '@chakra-ui/react';
import { WarningIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ProposalNumber from './ProposalNumber';
import useStore from '../store';

const Q_NOTIFICATIONS = gql`
	query GetNotifications($pagerInput: PagerInput!, $viewType: NotificationViewType, $category: NotificationCategories, $openType: String) {
		getNotifications(pagerInput: $pagerInput, viewType: $viewType, category: $category, openType: $openType) {
			totalPages
			notifications {
				id
				category
				openId
				openType
				locationId
				createdAt
				title
				description
				read
				createdBy {
					id
					username
					fullName
					fullNameKhmer
					avatar
				}
				viewType
				viewData
			}
		}
	}
`;

const M_READ_NOTIFICATION = gql`
	mutation read($id: String!) {
		readNotificationById(id: $id)
	}
`;

function ProposalAnnouncement({ auditServiceOnly = false }) {
	const history = useHistory();
	const { countUnreadNotifications } = useStore((state) => state.countUnreadNotifications);
	const setCountUnreadNotifications = useStore((state) => state.setCountUnreadNotifications);
	const { t } = useTranslation();
	const [getNotifications, { data }] = useLazyQuery(Q_NOTIFICATIONS);
	const [readNotification, { data: dataRead }] = useMutation(M_READ_NOTIFICATION);
	const [notifications, setNotifications] = useState();

	useEffect(() => {
		const variables = {
			pagerInput: { offset: 0, limit: 5 },
			viewType: 'ANNOUNCEMENT',
			category: 'COMPANY_PROPOSAL',
			openType: auditServiceOnly ? 'COMPANY_AUDIT_PROPOSAL' : 'COMPANY_SUBMISSION_PROPOSAL',
		};
		getNotifications({ variables });
	}, []);

	useEffect(() => {
		if (data?.getNotifications) {
			setNotifications(data?.getNotifications?.notifications?.filter((e, i) => e?.read === false));
		}
	}, [data]);

	useEffect(() => {
		if (dataRead?.readNotificationById) {
			const variables = {
				pagerInput: { offset: 0, limit: 5 },
				viewType: 'ANNOUNCEMENT',
				category: 'COMPANY_PROPOSAL',
				openType: auditServiceOnly ? 'COMPANY_AUDIT_PROPOSAL' : 'COMPANY_SUBMISSION_PROPOSAL',
			};
			getNotifications({ variables });
		}
	}, [dataRead]);

	const review = (id, proposalId) => {
		if (auditServiceOnly) {
			history.push(`/audit-company/detail?id=${proposalId}`);
		} else {
			history.push(`/company-submission-proposal/detail?id=${proposalId}`);
		}
		readNotification({ variables: { id } });
		setCountUnreadNotifications(countUnreadNotifications - 1);
	};

	return (
		<>
			{notifications?.map((e, i) => {
				return (
					<Alert status="warning" variant="left-accent" mt={1}>
						<WarningIcon color="#DD6B20" fontSize="24px" mr={4} />
						<Box w="100%">
							<AlertDescription>
								<Flex alignItems="center" justifyContent="space-between">
									<Flex justifyContent="center" alignItems="center">
										<ProposalNumber id={e?.openId} />
										<Text fontSize="md" fontWeight="600" ml={1}>
											{t(e?.description)}
										</Text>
										<Text fontSize="md" fontWeight="600" ml={0}>
											&nbsp;{t('that you need to check')}
										</Text>
									</Flex>
									<Button colorScheme="orange" onClick={() => review(e?.id, e?.viewData?.proposalId)}>
										{t('Review Proposal')}
									</Button>
								</Flex>
							</AlertDescription>
						</Box>
					</Alert>
				);
			})}
		</>
	);
}

export default ProposalAnnouncement;
