import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Container,
	Box,
	Flex,
	Text,
	Center,
	Table,
	Thead,
	Tbody,
	Tfoot,
	Tr,
	Th,
	Td,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	Input,
	InputRightElement,
	InputGroup,
	Button,
	Badge,
	useToast,
	Tooltip,
	Checkbox,
} from '@chakra-ui/react';
import BreadcrumbComponent from '../../../components/BreadcrumbComponent/BreadcrumbComponent';
import { faEllipsisV, faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import c from '../../../constant';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import './style.css';
import { useLocation, useHistory } from 'react-router-dom';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import useStore from '../../../store';
import LoadingTable from '../../../components/Table/Loading';
import getPositionLabel from '../../../utils/getPositionLabel';
import ExcelExportModal from '../../../components/Modal/ExcelExportModal';
import { Link } from 'react-router-dom';

import 'moment/locale/en-gb';
import 'moment/locale/km';

const QUERY_DATA = gql`
	query get($type: String!) {
		getOtherSettingByType(type: $type) {
			id
			type
			active
			data
		}
	}
`;

function UserList() {
	const { t } = useTranslation();
	const location = useLocation();
	const history = useHistory();
	const toast = useToast();

	const { currentLang } = useStore((state) => state.currentLang);
	moment.locale(currentLang === 'kh' ? 'km' : 'en-gb');

	const [loadData, { error: formSettingError, loading: formSettingLoading, data: formSettingData }] = useLazyQuery(QUERY_DATA);

	useEffect(() => {
		window.scrollTo(0, 0);
		loadData({
			variables: {
				type: 'salary',
			},
		});
	}, []);

	return (
		<>
			<Container maxW="container.xl" mt="16px" mb="16px">
				<BreadcrumbComponent
					list={[
						{
							name: t('OFFICIAL PROFILE'),
							path: '/employee/list?page=1',
						},
						{
							name: t('Salary'),
							path: '#',
						},
					]}
				/>
				<Center mt="5">
					<Box
						w="100%"
						bg="white"
						borderRadius={c.borderRadius}
						border="1px solid #dbdbdb"
						boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
						pb="32px"
					>
						<Flex bg="#FAFAFA" p="16px" pt="8px" pb="8px" mb="16px" justifyContent="space-between" borderBottom="1px solid #dbdbdb">
							<Text fontSize="x-large" fontWeight="bold">
								{t('Add New Salary')}
							</Text>
						</Flex>

						<Box p="16px">
							<Table class="tg">
								<thead>
									<tr>
										<th class="tg-0lax" rowspan="2">
											ល.រ
										</th>
										<th class="tg-0lax" rowspan="2">
											អត្ថលេខ
										</th>
										<th class="tg-0lax" rowspan="2">
											នាម-គោល
										</th>
										<th class="tg-0lax" rowspan="2">
											ភេទ
										</th>
										<th class="tg-0lax">ថ្ងៃ ខែ ឆ្នាំ</th>
										<th class="tg-0lax" rowspan="2">
											មុខងារ
										</th>
										<th class="tg-0lax" rowspan="2">
											ឋានៈស្មើ
										</th>
										<th class="tg-0lax">ក្របខណ្ឌ</th>
										<th class="tg-0lax" rowspan="2">
											ទឹកប្រាក់
										</th>
										<th class="tg-0lax">ប្រាក់</th>
										<th class="tg-0lax" colspan="2">
											បំណាច់
										</th>
										<th class="tg-0lax" colspan="2">
											ប្រាក់វិភាជន៏គ្រួសារ
										</th>
										<th class="tg-0lax" rowspan="2">
											ប្រាក់រំលឹក
										</th>
										<th class="tg-0lax" rowspan="2">
											សរុបទូទៅ
										</th>
										<th class="tg-0lax">ទឹកប្រាក់</th>
										<th class="tg-0lax">បៀវត្ស</th>
									</tr>
									<tr>
										<th class="tg-0lax">កំណើត</th>
										<th class="tg-0lax">ឋានន្តរស័ក្តិ និងថ្នាក់</th>
										<th class="tg-0lax">បង្គ្រប់</th>
										<th class="tg-0lax">មុខងារ</th>
										<th class="tg-0lax">បន្ថែម</th>
										<th class="tg-0lax">កូន</th>
										<th class="tg-0lax">សហព័ទ្ធ</th>
										<th class="tg-0lax">ត្រូវបង់ពន្ធ</th>
										<th class="tg-0lax">ត្រូវបើក</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td colspan="2">Total</td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td>-</td>
										<td>-</td>
										<td>-</td>
										<td>-</td>
										<td>-</td>
										<td>-</td>
										<td>-</td>
										<td>-</td>
										<td>-</td>
										<td>-</td>
									</tr>
									{formSettingData?.getOtherSettingByType?.data?.map((item, index) => {
										switch (item?.type) {
											case 'header':
												return (
													<Tr className="tableHeader">
														<Td fontFamily="Moul !important" colSpan={18}>
															{item.data}
														</Td>
													</Tr>
												);
											case 'subHeader':
												return (
													<Tr>
														<Td></Td>
														<Td fontFamily="Moul !important" colSpan={17}>
															{item?.data}
														</Td>
													</Tr>
												);
											case 'employee': {
												const employee = JSON.parse(item?.data);
												return (
													<tr>
														<td></td>
														<td></td>
														<td>{employee?.fullNameKhmer}</td>
														<td></td>
														<td></td>
														<td></td>
														<td></td>
														<td></td>
														<td></td>
														<td></td>
														<td></td>
														<td></td>
														<td></td>
														<td></td>
														<td></td>
														<td></td>
														<td></td>
														<td></td>
													</tr>
												);
											}
											default:
												return null;
										}
									})}
								</tbody>
							</Table>
						</Box>
					</Box>
				</Center>
			</Container>
		</>
	);
}

export default UserList;
