import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Container, Box, Flex, Text, Center, Avatar, Button, Divider, SimpleGrid, useToast, Radio, RadioGroup, Stack
} from "@chakra-ui/react";
import BreadcrumbComponent from "../../components/BreadcrumbComponent/BreadcrumbComponent";
import c from "../../constant"
import { useLocation } from "react-router-dom";
import moment from "moment";
import 'moment/locale/en-gb';
import { gql, useLazyQuery } from '@apollo/client';
import useStore from '../../store';
import { faMinusSquare, faPlusSquare, faArrowRight, faLongArrowAltRight, faFileInvoice, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SelectUserModal from '../../components/Modal/SelectUserModal';
import * as _ from "lodash"
import TransferProposalAndGetUpdateStatus from "../../components/Other/TransferProposalAndGetUpdateStatus"

const QUERY_USER_BY_ID = gql`
    
query get($id: String!){
    getUserById(id:$id){
      id
      fullName
      fullNameKhmer
      avatar
    }
  }
`



const QUERY_ALL_PROPOSAL_BY_USER_ID = gql`
query get($id: String!){
    getAllAssignedProposalByUserId(userId:$id)
  }
`




function TransferProposal() {
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const toast = useToast()
    moment.locale("en-gb")
    const { t } = useTranslation();
    const id = urlParams.get('id') || null;
    const [loadUser, { error: errorUser, loading: loadingUser, data: userData }] = useLazyQuery(QUERY_USER_BY_ID)
    const { currentLang } = useStore((state) => state.currentLang);
    const [selectUserModalOpen, setSelectUserModalOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);

    const [fromData, setFromData] = useState(null)
    const [toData, setToData] = useState(null)
    const [transferList, setTransferList] = useState(null)

    const [currentIndex, setCurrentIndex] = useState(0)
    const [startTransfer, setStartTransfer] = useState(false)
    const [notificationValue, setNotificationValue] = React.useState('none')


    const [loadAllProposalByUserId, { error: errorAllProposal, loading: loadingAllProposal, data: allProposal }] = useLazyQuery(QUERY_ALL_PROPOSAL_BY_USER_ID)

    useEffect(() => {
        if (currentIndex && transferList) {
            if (currentIndex === (transferList?.length - 1)) {
                toast({
                    title: `Transfer Completed`,
                    status: "success",
                    isClosable: true,
                })
            }
        }
    }, [currentIndex, transferList])

    useEffect(() => {
        if (allProposal?.getAllAssignedProposalByUserId) {
            if (fromData === null) {
                setFromData(JSON.parse(allProposal?.getAllAssignedProposalByUserId))
            } else {
                setToData(JSON.parse(allProposal?.getAllAssignedProposalByUserId))
            }
        }
    }, [allProposal])

    function getDisplayName(user) {
        let displayName = user?.username;
        if (currentLang === 'kh') {
            displayName = user?.fullNameKhmer ? user?.fullNameKhmer : user?.fullName ? user?.fullName : user?.username;
        } else {
            displayName = user?.fullName ? user?.fullName : user?.fullNameKhmer ? user?.fullNameKhmer : user?.username;
        }
        return displayName;
    }
    useEffect(() => {
        loadUser({
            variables: {
                id
            }
        })

        loadAllProposalByUserId({
            variables: {
                id
            }
        })
    }, [])


    function selectAssignUser(userData) {
        setSelectedUser(userData)
        loadAllProposalByUserId({
            variables: {
                id: userData?.id
            }
        })
    }


    useEffect(() => {
        if (fromData && toData) {
            const numberFrom = []
            const numberTo = []
            fromData?.map((item) => numberFrom.push(item?.proposalNumber))
            toData?.map((item) => numberTo.push(item?.proposalNumber))
            let without = _.without(numberFrom, ...numberTo)

            const tmpTransferList = []
            without.map((item) => {
                const tmpData = _.filter(fromData, function (o) { return o.proposalNumber === item; });
                tmpTransferList.push(tmpData[0])
            })
            setTransferList(tmpTransferList)
        }
    }, [fromData, toData])

    return (
        <>
            <Container maxW="container.xl" mt="16px" mb="16px">
                <BreadcrumbComponent
                    list={[
                        {
                            name: t("Users"),
                            path: "/users?page=1",
                        },
                        {
                            name: t("Transfer Proposal"),
                            path: "#",
                        },
                    ]}
                />
                <Center mt="5">
                    <Box
                        w="100%"
                        bg="white"
                        borderRadius={c.borderRadius}
                        border="1px solid #dbdbdb"
                        boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
                        pb="32px"
                    >
                        <Flex bg="#FAFAFA" p="16px" pt="8px" pb="8px" mb="16px" justifyContent="space-between" borderBottom="1px solid #dbdbdb">
                            <Text
                                fontSize="x-large"
                                fontWeight="bold"
                            >
                                {t("Transfer Proposal")}
                            </Text>
                        </Flex>

                        <Box p="16px">
                            <Flex justifyContent="center" alignItems="center">
                                <Flex w="150px" justifyContent="center" alignItems="center" direction="column">
                                    <Avatar
                                        name={getDisplayName(userData?.getUserById)}
                                        src={userData?.getUserById?.avatar?.icon ? c.baseFilePath + userData?.getUserById?.avatar?.icon : null}
                                    />
                                    <Text fontWeight="bold">{getDisplayName(userData?.getUserById)}</Text>
                                </Flex>
                                <FontAwesomeIcon icon={faLongArrowAltRight} style={{ fontSize: 32, marginLeft: 32, marginRight: 32, color: "#2980b9" }} />
                                <Flex w="150px" justifyContent="center" alignItems="center" direction="column">
                                    {!selectedUser && <Flex>
                                        <Button
                                            className="hidden-print"
                                            onClick={() => setSelectUserModalOpen(true)}
                                            mt={1}
                                            leftIcon={<FontAwesomeIcon icon={faPlusSquare} style={{ fontSize: 18 }} />}
                                            colorScheme="blue"
                                            variant="outline"
                                        >
                                            {t('Choose')}
                                        </Button>
                                    </Flex>}
                                    {
                                        selectedUser && <>
                                            <Avatar
                                                name={getDisplayName(selectedUser)}
                                                src={selectedUser?.avatar?.icon ? c.baseFilePath + selectedUser?.avatar?.icon : null}
                                            />
                                            <Text fontWeight="bold">{getDisplayName(selectedUser)}</Text>
                                        </>
                                    }
                                </Flex>
                            </Flex>
                            {selectedUser?.id && <Flex mt="4" justifyContent="center" alignItems="center">
                                <Button onClick={() => setStartTransfer(true)}>{t("Transfer Proposal")}&nbsp;({transferList?.length})</Button>
                            </Flex>}
                            {selectedUser?.id && <Box>
                                <Text textDecoration="underline" fontWeight="bold" fontSize="xl">Notification</Text>
                                <RadioGroup onChange={setNotificationValue} value={notificationValue}>
                                <Stack>
                                    <Radio value='none'>None</Radio>
                                    <Radio value='all'>All</Radio>
                                </Stack>
                                </RadioGroup>
                            </Box>}
                            <Divider mt="4" mb="4" />
                            <Flex marginTop={4}>
                                <SimpleGrid w="100%" columns={10} spacing={5}>
                                    {
                                        transferList && <>
                                            {
                                                transferList?.map((item, index) => {
                                                    return (
                                                        <Box key={index} cursor="pointer" _hover={{ bg: "silver" }}>
                                                            <Flex alignItems="center">
                                                                <Text fontWeight="bold">#{item?.proposalNumber}</Text>
                                                                {(startTransfer && currentIndex >= index) && <>
                                                                    {selectedUser?.id && <TransferProposalAndGetUpdateStatus
                                                                        id={item?.proposalId}
                                                                        userId={selectedUser?.id}
                                                                        notificationValue={notificationValue}
                                                                        onNext={() => setCurrentIndex(currentIndex + 1)}
                                                                    />}
                                                                </>}
                                                            </Flex>
                                                        </Box>)
                                                })
                                            }
                                        </>
                                    }
                                </SimpleGrid>


                            </Flex>
                        </Box>
                    </Box>
                </Center>
            </Container>

            {selectUserModalOpen && (
                <SelectUserModal
                    role="companySubmissionProposal"
                    callback={selectAssignUser}
                    isOpen={selectUserModalOpen}
                    onClose={() => setSelectUserModalOpen(false)}
                />
            )}
        </>
    );
}

export default TransferProposal;
