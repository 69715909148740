import { Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

function TotalRow({ label = 'Total Rows', count = 0, mt = 4 }) {
	const { t } = useTranslation();

	return (
		<>
			<Text fontWeight="md" color="gray.600" mt={mt}>
				{t(label)}: <b>{count}</b>
			</Text>
		</>
	);
}

export default TotalRow;
