/* eslint-disable react-hooks/exhaustive-deps */
import { Text } from '@chakra-ui/react';
import { TerminationRequestStatus } from '../../../../containers/ManageAgentPartner/TerminationRequest/constands';

const TerminationRequestDescription = ({ requestStatus = null, description = null }) => {
	return (
		<>
			{requestStatus === TerminationRequestStatus.reviewing && (
				<>
					{description ? (
						<>
							<Text fontSize="sm" fontWeight="medium">
								{description}
							</Text>
						</>
					) : (
						<>
							<Text fontSize="sm" fontWeight="normal">
								{'N/A'}
							</Text>
						</>
					)}
				</>
			)}
			{requestStatus === TerminationRequestStatus.reviewingFinal && (
				<>
					{description ? (
						<>
							<Text fontSize="sm" fontWeight="medium">
								{description}
							</Text>
						</>
					) : (
						<>
							<Text fontSize="sm" fontWeight="normal">
								{'N/A'}
							</Text>
						</>
					)}
				</>
			)}
			{requestStatus === TerminationRequestStatus.completed && (
				<>
					{description ? (
						<>
							<Text fontSize="sm" fontWeight="medium">
								{description}
							</Text>
						</>
					) : (
						<>
							<Text fontSize="sm" fontWeight="normal">
								{'N/A'}
							</Text>
						</>
					)}
				</>
			)}
		</>
	);
};

export default TerminationRequestDescription;
