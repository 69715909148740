import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Box, Flex, Button, useToast, Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
} from "@chakra-ui/react";
import c from "../../constant"
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { useHistory } from "react-router-dom";
import RadioFormField from "../../components/form/RadioFormField"
import TextFormField from "../../components/form/TextFormField"
import countriesData from "../../utils/countries"
import SelectSearchFormField from "../../components/form/SelectSearchFormField"
import { getInitSelectSearch } from "../../utils"
import moment from "moment";
import DateFormField from "../form/DateFormField";
import FileFormField from "../../components/form/FileFormField"

const UPDATE_MUTATION = gql`
mutation update(
    $name: String!,
    $gender: String , 
    $nationality: String, 
    $position: String, 
    $id: String!
    $approvalDate: DateTime
    $file: Upload
    $fileSize: Float
){
    updateCompanyBoardOfDirectorById(
        id:$id
        position:$position
        nationality:$nationality
        name:$name
        gender:$gender
        approvalDate: $approvalDate
        file: $file
        fileSize: $fileSize
    )
  }
 `


const QUERY_DATA = gql`
 query get($id: String!){
    getCompanyBoardOfDirectorById(id:$id){
       id
       name
       gender
       nationality
       position
       createdAt
       approvalDate
     }
   }
 `

const signUpSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    gender: Yup.string(),
    nationality: Yup.object().nullable(),
    position: Yup.string(),
    approvalDate: Yup.string().nullable(),
    file: Yup.mixed().test("fileSize", "over limit", (value) => {
        if (value) {
            return value?.size <= c.maxFileSize / 10
        } else {
            return true
        }
    }),
});

function Create({
    isOpen = false, onClose = () => null, callback = () => null, id
}) {
    const history = useHistory();
    const toast = useToast()
    const { t } = useTranslation();


    const [updateMutation, { loading, error, data }] = useMutation(
        UPDATE_MUTATION
    );

    const [loadData, { loading: loadingData, error: errorData, data: dataData }] = useLazyQuery(
        QUERY_DATA
    );

    useEffect(() => {
        if (id) {
            loadData({
                variables: {
                    id
                }
            })
        }
    }, [id]);

    useEffect(() => {
        if (data) {
            if (data?.updateCompanyBoardOfDirectorById) {
                toast({
                    title: t("Company board of director updated successfully"),
                    status: "success",
                    isClosable: true,
                    position: "top-right"
                })
                onClose()
                callback("Company board of director updated successfully")
            }
        }
    }, [data])

    useEffect(() => {
        if (error) {
            error.graphQLErrors.map(({ message }, i) => (
                toast({
                    title: t(message),
                    status: "error",
                    isClosable: true,
                    position: "top-right"
                })
            ))
        }
    }, [error])


    function update(values) {
        updateMutation({
            variables: {
                position: values.position,
                nationality: values?.nationality?.value ? values?.nationality?.value : null,
                name: values.name,
                gender: values.gender,
                approvalDate: values?.approvalDate?moment(values.approvalDate).toDate():null,
                id: id,
                file: values.file,
                fileSize: values?.file?.size,
            }
        })
    }

    function getNationalityOptions() {
        let tmpData = []
        countriesData.map((item) => {
            tmpData.push({
                label: t(item.nationality),
                value: item.nationality
            })
        })
        return tmpData
    }

    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={() => {
                    onClose()

                }}
                size="md"
            >
                <ModalOverlay />
                <ModalContent borderRadius={c.borderRadius} pb="2">
                    <ModalHeader>{t("Edit Company Board Of Director")}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {dataData?.getCompanyBoardOfDirectorById && <Formik
                            initialValues={{
                                name: dataData?.getCompanyBoardOfDirectorById?.name,
                                gender: dataData?.getCompanyBoardOfDirectorById?.gender,
                                nationality: getInitSelectSearch({ value: dataData?.getCompanyBoardOfDirectorById?.nationality, options: getNationalityOptions() }),
                                position: dataData?.getCompanyBoardOfDirectorById?.position,
                                approvalDate: dataData?.getCompanyBoardOfDirectorById?.approvalDate,
                                file: null
                            }}
                            validationSchema={signUpSchema}
                            onSubmit={(values) => {
                                update(values);
                            }}
                        >
                            {(props) => (
                                <Form>
                                    <Box mt="3">
                                        <TextFormField
                                            {...props}
                                            label="Name"
                                            name="name"
                                        />

                                        <RadioFormField
                                            label="Gender"
                                            name="gender"
                                            radios={[
                                                { label: "Male", value: "male" },
                                                { label: "Female", value: "female" },
                                            ]}
                                            initValue={dataData?.getCompanyBoardOfDirectorById?.gender}
                                        />

                                        <SelectSearchFormField
                                            {...props}
                                            name="nationality"
                                            label="Nationality"
                                            placeholder="Nationality"
                                            options={getNationalityOptions()}
                                        />

                                        <TextFormField
                                            {...props}
                                            label="Position"
                                            name="position"
                                        />

                                        <DateFormField
                                            name="approvalDate"
                                            label="Approval Date"
                                            initialDate={dataData?.getCompanyBoardOfDirectorById?.approvalDate}
                                        />

                                        <FileFormField
                                            {...props}
                                            label="Permit letter"
                                            name="file"
                                            maxFileSize={c.maxFileSize / 10}
                                        />

                                        <Flex
                                            textAlign="right"
                                            mt="6"
                                            justifyContent="space-between"
                                            alignItems="center"
                                        >
                                            <Button
                                                id="login-btn"
                                                variant="solid"
                                                size="md"
                                                h="42px"
                                                w="128px"
                                                bg="primary.dark"
                                                _hover={{ bg: "primary.dark" }}
                                                color="white"
                                                // borderRadius="31px"
                                                type="submit"
                                                isLoading={loading}
                                                loadingText={t("Edit")}
                                                spinnerPlacement="start"
                                            >
                                                {t(`Edit`)}
                                            </Button>
                                        </Flex>
                                    </Box>
                                </Form>
                            )}
                        </Formik>}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
}

export default Create;
