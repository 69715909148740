/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Box, Flex, Text, Center, Checkbox, Button } from '@chakra-ui/react';
import { faSlidersH, faPrint, faFileExcel, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { gql, useLazyQuery } from '@apollo/client';
import { useLocation, useHistory } from 'react-router-dom';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Link } from 'react-router-dom';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import BreadcrumbComponent from '../../components/BreadcrumbComponent/BreadcrumbComponent';
import ReactPaginate from 'react-paginate';
import PrintLayout from '../../components/Print/PrintLayout';
import ExcelExportModal from '../../components/Modal/ExcelExportModal';
import DocumentNumberFilter from '../../components/Filter/DocumentNumberFilter';
import datePickerFromToToDate from '../../utils/datePickerFromToToDate';
import getDepartmentLabel from '../../utils/getDepartmentLabel';
import useStore from '../../store';
import moment from 'moment';
import c from '../../constant';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import DownloadAndViewButton from '../../components/Files/DownloadAndViewButton';
import LoadingResponsiveTable from '../../components/Table/LoadingResponsive';

const QUERY_LIST = gql`
	query get(
		$limit: Float
		$offset: Float
		$typeId: String
		$toDate: DateTime
		$fromDate: DateTime
		$companyName: String
		$department: String
		$subject: String
		$number: String
	) {
		getListDocumentNumber(
			pagerInput: { limit: $limit, offset: $offset }
			typeId: $typeId
			toDate: $toDate
			fromDate: $fromDate
			companyName: $companyName
			department: $department
			subject: $subject
			number: $number
		) {
			list {
				id
				subject
				displayNumber
				number
				createdAt
				year
				department
				companyName
				attachmentFile
				proposalNumber
				company {
					id
					name
					nameKhmer
				}
				writeDate
				attachmentFile
			}
			totalRows
			totalPages
		}
	}
`;

function ListDocument() {
	const { t } = useTranslation();
	const location = useLocation();
	const history = useHistory();
	const urlParams = new URLSearchParams(location.search);
	const currentPage = urlParams.get('page') ? urlParams.get('page') : 1;
	const typeId = urlParams.get('typeId') || null;
	const typeLabel = urlParams.get('typeLabel') || '';
	const number = urlParams.get('number') || '';
	const subject = urlParams.get('subject') || '';
	const department = urlParams.get('department') || '';
	const companyName = urlParams.get('companyName') || '';
	const fromDate = urlParams.get('fromDate') || '';
	const toDate = urlParams.get('toDate') || '';
	const [rowLimit, setRowLimit] = useState(10);
	const { currentLang } = useStore((state) => state.currentLang);
	const { currentUser: user } = useStore((state) => state.currentUser);
	moment.locale(currentLang === 'kh' ? 'km' : 'en-gb');
	const [loadList, { data, loading }] = useLazyQuery(QUERY_LIST);
	const [showExportExcel, setShowExportExcel] = useState(false);
	const [excelData, setExcelData] = useState([]);

	useEffect(() => {
		window.scrollTo(0, 0);
		getListWithVariables();
	}, []);

	const [openFilter, setOpenFilter] = useState(false);

	function handlePageClick(data) {
		let selected = data.selected;
		const page = selected + 1;

		let url = `?page=${page}`;
		if (typeId) {
			url += `&typeId=${typeId}`;
		}
		if (typeLabel) {
			url += `&typeLabel=${typeLabel}`;
		}
		history.push(`/issued-letter/list${url}`);
	}

	useEffect(() => {
		if (currentPage) {
			window.scrollTo(0, 0);
			getListWithVariables();
		}
	}, [rowLimit, currentPage, typeId, toDate, fromDate, companyName, department, subject, number]);

	function getListWithVariables() {
		let variables = {
			offset: currentPage ? (currentPage - 1) * rowLimit : 0,
			limit: rowLimit,
			typeId,
			toDate: toDate ? datePickerFromToToDate(toDate) : null,
			fromDate: fromDate ? datePickerFromToToDate(fromDate) : null,
			companyName: companyName || null,
			department: department || null,
			subject: subject || null,
			number: number || null,
		};
		loadList({
			variables,
		});
	}

	function getDisplayCompanyName(item) {
		let displayName = item?.companyName;
		if (item?.company?.id) {
			if (currentLang === 'kh') {
				displayName = item?.company?.nameKhmer;
			} else {
				displayName = item?.company?.name;
			}
		}
		return displayName;
	}

	function exportToExcel() {
		let excelData = [];
		if (data?.getListDocumentNumber?.list?.length > 0) {
			data?.getListDocumentNumber?.list?.map((item) => {
				const tmpData = {
					លេខ: item?.displayNumber,
					ឆ្នាំ: item?.year,
					លេខថេរ: item?.number,
					កម្មវត្ថុ: item?.subject,
					នាយកដ្ឋាន: item?.department ? t(getDepartmentLabel(item?.department)) : '',
					ក្រុមហ៊ុន: item?.company?.id ? item?.company?.nameKhmer : item?.companyName,
					កាលបរិច្ឆេទបង្កើត: moment(item?.createdAt).format(c.excelDateTimeFormat),
					លេខការស្នើសុំ: item?.proposalNumber,
					ឯកសារ: item?.attachmentFile ? JSON.stringify(item?.attachmentFile) : '',
				};
				excelData.push(tmpData);
			});
		}
		setExcelData(excelData);
		setShowExportExcel(true);
	}

	function callbackFilter(e) {
		let url = '';
		if (typeId) {
			url += `&typeId=${typeId}`;
		}
		if (typeLabel) {
			url += `&typeLabel=${typeLabel}`;
		}
		history.push(`/issued-letter/list${e}${url}`);
	}

	function openDetail(id) {
		history.push(`/issued-letter/detail?typeId=${typeId}&typeLabel=${typeLabel}&id=${id}`)
	}

	return (
		<PrintLayout>
			<>
				<Container maxW="container.xl" mt="16px" mb="16px">
					<BreadcrumbComponent
						list={[
							{
								name: 'ISSUED LETTER',
								path: '/issued-letter?page=1&count=300',
							},
							{
								name: 'List',
								path: '#',
							},
						]}
					/>

					<Center className="show-only-printing">
						<Text textAlign="center" color="#62B3ED" fontFamily="Moul" fontSize={18}>
							លេខ​ឯកសារ
						</Text>
					</Center>

					<Center mt="5">
						<Box
							w="100%"
							bg="white"
							borderRadius={c.borderRadius}
							border="1px solid #dbdbdb"
							boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
							pb="32px"
						>
							<Flex
								className="hidden-print responsive-header-for-table"
								bg="#FAFAFA"
								p="16px"
								pt="0"
								pb="0"
								mb="16px"
								alignItems="center"
								justifyContent="space-between"
								borderBottom="1px solid #dbdbdb"
							>
								<Flex className="panel-tab-container" minW="303px">
									<Flex h="54px" alignItems="center" fontWeight="bold" fontSize="xl" color="#2980b9">
										{typeLabel}
									</Flex>
								</Flex>
								<Flex className="header-menu" justifyContent="flex-end" alignItems="center" minW="361px">
									<Button
										mr="2"
										onClick={() => exportToExcel()}
										leftIcon={<FontAwesomeIcon icon={faFileExcel} style={{ fontSize: 16 }} />}
										colorScheme="gray"
										variant="solid"
										size="sm"
										borderRadius={c.borderRadius}
										border="1px solid #bdc3c7"
									>
										{t('Export')}
									</Button>
									<Button
										mr="2"
										onClick={() => window?.print()}
										leftIcon={<FontAwesomeIcon icon={faPrint} style={{ fontSize: 16 }} />}
										colorScheme="gray"
										variant="solid"
										size="sm"
										borderRadius={c.borderRadius}
										border="1px solid #bdc3c7"
									>
										{t('Print')}
									</Button>
									<>
										{user?.canCreateIssuedLetter && (
											<>
												<Link to={`/issued-letter/add?typeId=${typeId}&typeLabel=${typeLabel}`}>
													<Button
														mr="2"
														leftIcon={<FontAwesomeIcon icon={faPlus} style={{ fontSize: 16 }} />}
														colorScheme="gray"
														variant="solid"
														size="sm"
														borderRadius={c.borderRadius}
														border="1px solid #bdc3c7"
													>
														{t('Add')}
													</Button>
												</Link>
											</>
										)}
									</>
									<Button
										onClick={() => setOpenFilter(true)}
										leftIcon={<FontAwesomeIcon icon={faSlidersH} style={{ fontSize: 16 }} />}
										colorScheme={`${number || subject || department || companyName || fromDate || toDate ? 'yellow' : 'gray'}`}
										variant="solid"
										size="sm"
										borderRadius={c.borderRadius}
										border="1px solid #bdc3c7}"
									>
										{t('Filter')}
									</Button>
								</Flex>
							</Flex>
							<Box p="16px" pt="0">
								<Table variant="simple" className="table-company-proposal-list responsive-table-container">
									<Thead>
										<Tr className="td-as-th">
											<Th align="left">{t('Number')}</Th>
											<Th align="left">{t('Subject')}</Th>
											<Th align="left">{t('Company')}</Th>
											<Th align="left">{t('Department')}</Th>
											<Th align="left">{t('Date')}</Th>
											<Th align="left">{t('Year')}</Th>
											<Th className="hidden-print" align="left">{t('File')}</Th>
										</Tr>
									</Thead>
									<Tbody>
										{loading && <LoadingResponsiveTable column={7} />}
										{data?.getListDocumentNumber?.list?.map((item, index) => {
											return (
												<Tr
													key={`tr-${index}`}
												>
													<Td onClick={() => openDetail(item?.id)}>{item?.displayNumber}</Td>
													<Td onClick={() => openDetail(item?.id)}>
														<Text noOfLines={3}>{item?.subject}</Text>
													</Td>
													<Td onClick={() => openDetail(item?.id)}>{getDisplayCompanyName(item)||"-"}</Td>
													<Td onClick={() => openDetail(item?.id)}>{item?.department ? t(getDepartmentLabel(item?.department)) : '-'}</Td>
													<Td onClick={() => openDetail(item?.id)}>{moment(item?.writeDate).format(c.dateOnlyFormat)}</Td>
													<Td onClick={() => openDetail(item?.id)}>{item?.year}</Td>
													<Td className="hidden-print">
														{item?.attachmentFile?.file ?
															<Box ml="-4">
																<DownloadAndViewButton url={item?.attachmentFile?.file} size={item?.attachmentFile?.fileSize} />
															</Box>
															: "N/A"}
													</Td>
												</Tr>
											);
										})}
									</Tbody>
									<tfoot className="responsive-footer">
										<Tr className="td-as-th">
											<Th align="left">{t('Number')}</Th>
											<Th align="left">{t('Subject')}</Th>
											<Th align="left">{t('Company')}</Th>
											<Th align="left">{t('Department')}</Th>
											<Th align="left">{t('Date')}</Th>
											<Th align="left">{t('Year')}</Th>
											<Th className="hidden-print" align="left">{t('File')}</Th>
										</Tr>
									</tfoot>
								</Table>
								<Flex className="hidden-print" justifyContent="flex-end" mr="24px" pb="4" mt="2" fontWeight="600">
									{t('Total Rows')}: {data?.getListDocumentNumber?.totalRows}
								</Flex>
								<Flex className="hidden-print" justifyContent="flex-end" w="100%" mt="4">
									{
										<ReactPaginate
											previousLabel={<ChevronLeftIcon fontSize="20px" />}
											nextLabel={<ChevronRightIcon fontSize="20px" />}
											breakLabel={'...'}
											breakClassName={'break-me'}
											pageCount={data?.getListDocumentNumber?.totalPages}
											marginPagesDisplayed={1}
											pageRangeDisplayed={1}
											onPageChange={handlePageClick}
											containerClassName={'pagination'}
											activeClassName={'active'}
											initialPage={(currentPage - 1) | 0}
										/>
									}
								</Flex>

								<Flex className="hidden-print" justifyContent="flex-end" mt="6">
									<Text mr="2">{t('Show more rows')}</Text>
									<Checkbox
										isChecked={rowLimit > 10}
										onChange={() => {
											handlePageClick({ selected: 0 });
											if (rowLimit > 10) {
												setRowLimit(10);
											} else {
												setRowLimit(300);
											}
										}}
									></Checkbox>
								</Flex>
							</Box>
						</Box>
					</Center>
				</Container>

				{showExportExcel && excelData?.length > 0 && (
					<ExcelExportModal isOpen={true} onClose={() => setShowExportExcel(false)} title={typeLabel} data={excelData} />
				)}

				{openFilter && (
					<DocumentNumberFilter
						callback={callbackFilter}
						isOpen={true}
						onClose={() => setOpenFilter(false)}
						number={number}
						subject={subject}
						department={department}
						companyName={companyName}
						fromDate={fromDate}
						toDate={toDate}
					/>
				)}
			</>
		</PrintLayout>
	);
}

export default ListDocument;
