import React, { useState, useEffect } from "react";
import { Box, FormControl, FormLabel, FormHelperText } from "@chakra-ui/react";
import { Field } from "formik";
import moment from "moment";
import DateTimePicker from 'react-datetime-picker';
import { useTranslation } from "react-i18next";

import "moment/locale/en-gb";

const DatetimeFormField = props => {
	const { t } = useTranslation();
	const {
		name,
		label,
		required
	} = props;
	moment.locale("en-gb")
	// const [value, onChange] = useState(new Date());

	

	return (
		<Field name={name}>
			{({ field, meta, form }) => (
				<Box mb="4">
					<FormControl id={name} >
						<FormLabel>{t(label)}{required && <span style={{ color: "red" }}>*</span>}</FormLabel>
						<DateTimePicker clearIcon={null} disableCalendar={true} className={meta.touched && meta.error?"datetimePickerFormError":"datetimePickerForm"} required={required} onChange={(e)=>{
							// onChange(e)
							console.log("e:", e)
							form.setFieldValue(name, e)
							}} format="dd-MM-y h:mm a" value={field.value?field.value:new Date()} disableClock={true}/>
						<FormHelperText>{t("Date format: Day - Month - Year")}</FormHelperText>
						{meta.touched && meta.error && (
							<FormHelperText
								id="error-message-datetime-picker"
								color="red.400"
								fontSize="12px"
							>
								{t(name)}{" "}
								{t("is")}{" "}
								{t(meta.error)}
							</FormHelperText>
						)}
					</FormControl>
				</Box>
			)}
		</Field>
	);
};

export default DatetimeFormField;