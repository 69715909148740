/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Button, Box, Flex, DrawerBody } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList } from '@fortawesome/free-solid-svg-icons';
import TextFormField from '../form/TextFormField';
import DateRangeFormField from '../form/DateRangeFormField';
import SelectFormField from '../form/SelectFormField';
import CompanyFormField from '../../components/form/CompanyFormField';
import CompanyPickerModal from '../../components/Modal/CompanyPickerModal';
import c from '../../constant';
import * as Yup from 'yup';

const filterSchema = Yup.object().shape({
	trxId: Yup.string(),
	lastName: Yup.string(),
	firstName: Yup.string(),
	email: Yup.string(),
	phone: Yup.string(),
	createdDate: Yup.string(),
	approvedDate: Yup.string(),
	transactionDate: Yup.string(),
	paymentStatus: Yup.string(),
	companyName: Yup.string(),
	selectedCompany: Yup.string(),
	proposalNumber: Yup.string(),
});

function PaymentFilter({
	callback,
	onClose,
	trxId = '',
	lastName = '',
	firstName = '',
	email = '',
	phone = '',
	fromDate = null,
	toDate = null,
	fromApprovedDate = null,
	toApprovedDate = null,
	fromTransactionDate = null,
	toTransactionDate = null,
	paymentStatus = '',
	pageType = '',
	companyName = '',
	selectedCompanyId = '',
	proposalNumber = '',
}) {
	const { t } = useTranslation();
	const [showCompanyPicker, setShowCompanyPicker] = useState(false);
	const [selectedCompany, setSelectedCompany] = useState(null);

	useEffect(() => {
		if (selectedCompanyId) {
			let tmpSelectedCompany = localStorage.getItem('tmpPaymentSelectedCompany');
			if (tmpSelectedCompany) {
				tmpSelectedCompany = JSON.parse(tmpSelectedCompany);
				setSelectedCompany(tmpSelectedCompany);
			}
		}
	}, []);

	let defaultRange = '';
	if (fromDate && toDate) {
		const defaultFrom = JSON.parse(fromDate);
		const defaultTo = JSON.parse(toDate);
		defaultRange = {
			from: defaultFrom,
			to: defaultTo,
		};
	}

	let defaultApprovedRange = '';
	if (fromApprovedDate && toApprovedDate) {
		const defaultFrom = JSON.parse(fromApprovedDate);
		const defaultTo = JSON.parse(toApprovedDate);
		defaultApprovedRange = {
			from: defaultFrom,
			to: defaultTo,
		};
	}

	let defaultTransactionRange = '';
	if (fromTransactionDate && toTransactionDate) {
		const defaultFrom = JSON.parse(fromTransactionDate);
		const defaultTo = JSON.parse(toTransactionDate);
		defaultTransactionRange = {
			from: defaultFrom,
			to: defaultTo,
		};
	}

	return (
		<>
			<Formik
				initialValues={{
					trxId: trxId || '',
					lastName: lastName || '',
					firstName: firstName || '',
					email: email || '',
					phone: phone || '',
					paymentStatus: paymentStatus || '',
					createdDate: defaultRange ? JSON.stringify(defaultRange) : '',
					approvedDate: defaultApprovedRange ? JSON.stringify(defaultApprovedRange) : '',
					transactionDate: defaultTransactionRange ? JSON.stringify(defaultTransactionRange) : '',
					companyName: companyName || '',
					selectedCompany: selectedCompanyId || '',
					proposalNumber: proposalNumber || '',
				}}
				validationSchema={filterSchema}
				onSubmit={(values) => {
					let url = '?page=1';
					if (pageType) {
						url += `&pageType=${pageType}`;
					}
					if (values.trxId) {
						url += `&trxId=${values.trxId}`;
					}
					if (values.lastName) {
						url += `&lastName=${values.lastName}`;
					}
					if (values.firstName) {
						url += `&firstName=${values.firstName}`;
					}
					if (values.email) {
						url += `&email=${values.email}`;
					}
					if (values.phone) {
						url += `&phone=${values.phone}`;
					}
					if (values.paymentStatus) {
						url += `&paymentStatus=${values.paymentStatus}`;
					}
					if (values.createdDate) {
						const tmpDate = JSON.parse(values.createdDate);
						if (tmpDate?.from && tmpDate?.to) {
							const dateFrom = JSON.stringify(tmpDate.from);
							const dateTo = JSON.stringify(tmpDate.to);
							url += `&fromDate=${dateFrom}&toDate=${dateTo}`;
						}
					}
					if (values.approvedDate) {
						const tmpDate = JSON.parse(values.approvedDate);
						if (tmpDate?.from && tmpDate?.to) {
							const dateFrom = JSON.stringify(tmpDate.from);
							const dateTo = JSON.stringify(tmpDate.to);
							url += `&fromApprovedDate=${dateFrom}&toApprovedDate=${dateTo}`;
						}
					}
					if (values.transactionDate) {
						const tmpDate = JSON.parse(values.transactionDate);
						if (tmpDate?.from && tmpDate?.to) {
							const dateFrom = JSON.stringify(tmpDate.from);
							const dateTo = JSON.stringify(tmpDate.to);
							url += `&fromTransactionDate=${dateFrom}&toTransactionDate=${dateTo}`;
						}
					}
					if (values.companyName) {
						url += `&companyName=${values.companyName}`;
					}
					if (values?.selectedCompany) {
						url += `&selectedCompanyId=${values.selectedCompany}`;
					}
					if (values.proposalNumber) {
						url += `&proposalNumber=${values.proposalNumber}`;
					}
					callback(url);
				}}
			>
				{(props) => (
					<>
						<DrawerBody>
							<Box>
								<Form>
									<Flex w="100%">
										<Box w="100%" mr="2">
											<TextFormField {...props} label="TRX. ID" name="trxId" />
										</Box>
										<Box w="100%" ml="2">
											<SelectFormField
												{...props}
												name="paymentStatus"
												label="Status"
												placeholder="Status"
												options={[
													{ label: 'ALL', value: 'ALL' },
													{ label: 'PENDING', value: 'PENDING' },
													{ label: 'APPROVED', value: 'APPROVED' },
													{ label: 'EXPIRED', value: 'EXPIRED' },
													{ label: 'DECLINED', value: 'DECLINED' },
													{ label: 'REFUNDED', value: 'REFUNDED' },
													{ label: 'ERROR', value: 'ERROR' },
												]}
												t={t}
												initialValue={paymentStatus ? paymentStatus : ''}
											/>
										</Box>
									</Flex>
									{(!pageType || pageType === 'public-service' || pageType === 'all') && (
										<Flex w="100%">
											<Box w="100%" mr="2">
												<TextFormField {...props} label="Number" name="proposalNumber" />
											</Box>
											<Box w="100%" ml="2" />
										</Flex>
									)}
									<Box>
										<center>
											<DateRangeFormField {...props} label="Created Date" name="createdDate" initialDate={{ fromDate, toDate }} />
										</center>
									</Box>

									<Box>
										<center>
											<DateRangeFormField
												{...props}
												label="Payment Approved At"
												name="approvedDate"
												initialDate={{ fromDate: fromApprovedDate, toDate: toApprovedDate }}
											/>
										</center>
									</Box>

									<Box>
										<center>
											<DateRangeFormField
												{...props}
												label="Transaction Date"
												name="transactionDate"
												initialDate={{ fromDate: fromTransactionDate, toDate: toTransactionDate }}
											/>
										</center>
									</Box>

									{!selectedCompany?.id && (
										<TextFormField
											{...props}
											label="Company/Institution Name"
											name="companyName"
											rightElement={
												<Button
													onClick={() => setShowCompanyPicker(true)}
													leftIcon={
														<FontAwesomeIcon
															style={{
																fontSize: 16,
																cursor: 'pointer',
															}}
															icon={faList}
														/>
													}
													mt="7px"
													variant="solid"
													h="42px"
													mr="3px"
													borderRadius={c.borderRadius}
												>
													{t('List')}
												</Button>
											}
										/>
									)}
									{selectedCompany?.id && (
										<CompanyFormField
											{...props}
											label="Company/Institution Name"
											name="selectedCompany"
											callBackClear={() => {
												setSelectedCompany(null);
												localStorage.setItem('tmpPaymentSelectedCompany', null);
											}}
											callBackList={() => setShowCompanyPicker(true)}
											selectedCompany={selectedCompany}
										/>
									)}
									<Flex w="100%">
										<Box w="100%" mr="2">
											<TextFormField {...props} label="Last Name" name="lastName" />
										</Box>
										<Box w="100%" ml="2">
											<TextFormField {...props} label="First Name" name="firstName" />
										</Box>
									</Flex>
									<Flex w="100%">
										<Box w="100%" mr="2">
											<TextFormField {...props} label="Email" name="email" />
										</Box>
										<Box w="100%" ml="2">
											<TextFormField {...props} label="Phone" name="phone" />
										</Box>
									</Flex>
									<Flex mt="16px" mb="16px" justifyContent="flex-end">
										<Button
											size="md"
											h="42px"
											mr={4}
											w="148px"
											onClick={() => {
												localStorage.setItem('tmpPaymentSelectedCompany', null);
												callback(`?page=1&pageType=${pageType}`);
												onClose();
											}}
											variant="ghost"
										>
											{t('Clear')}
										</Button>
										<Button
											id="login-btn"
											variant="solid"
											size="md"
											h="42px"
											w="148px"
											type="submit"
											borderRadius={c.borderRadius}
											color="#3498db"
										>
											{t('Apply')}
										</Button>
									</Flex>
								</Form>
							</Box>
						</DrawerBody>
						{showCompanyPicker && (
							<CompanyPickerModal
								onClose={() => setShowCompanyPicker(false)}
								callBack={(e) => {
									localStorage.setItem('tmpPaymentSelectedCompany', JSON.stringify(e));
									setSelectedCompany(e);
								}}
							/>
						)}
					</>
				)}
			</Formik>
		</>
	);
}

export default PaymentFilter;
