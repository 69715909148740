/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import {
	Flex,
	Box,
	Center,
	Tooltip,
	Text,
	Table,
	Tbody,
	Thead,
	Tfoot,
	Tr,
	Th,
	Td,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	useToast,
	AlertDialog,
	AlertDialogOverlay,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogBody,
	AlertDialogFooter,
	Button,
	useDisclosure,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { DragHandleIcon } from '@chakra-ui/icons';
import arrayMove from 'array-move';
import AddCompanyCeoModal from '../../Modal/AddCompanyCEO';
import EditCompanyCeoModal from '../../Modal/EditCompanyCEOModal';
import DownloadAndViewButton from '../../../components/Files/DownloadAndViewButton';
import LoadingTable from '../../Table/Loading';
import useMoment from '../../../hooks/useMoment';
import useStore from '../../../store';
import c from '../../../constant';

const Q_GET_CEOS = gql`
	query ($id: String!, $type: String) {
		getCompanyCEO(companyId: $id, type: $type) {
			id
			name
			gender
			position
			nationality
			createdAt
			approvalDate
			file
			type
		}
	}
`;

const M_DELETE_CEO = gql`
	mutation DeleteCompanyCEO($id: String!) {
		deleteCompanyCEO(id: $id)
	}
`;

const M_UPDATE_ORDER = gql`
	mutation ($orders: String!) {
		updateCEOsOrder(orders: $orders)
	}
`;

let workingId = null;

function GovernanceCeo({ id = null, type = null, onDataChanged = () => null, ...props }) {
	const deleteDialogRef = useRef();
	const deleteDialogCancelRef = useRef();
	const toast = useToast();
	const { t } = useTranslation();
	const { toDateString } = useMoment();
	const { currentUser } = useStore((state) => state.currentUser);
	const { isOpen: isInserting, onOpen: openInserting, onClose: closeInserting } = useDisclosure();
	const { isOpen: isDeleting, onOpen: openDeleting, onClose: closeDeleting } = useDisclosure();
	const { isOpen: isEditing, onOpen: openEditing, onClose: closeEditing } = useDisclosure();
	const [lists, setLists] = useState([]);
	const [loadCompanyCeos, { loading: loadingCeos, error: errorCeos, data: dataCeos }] = useLazyQuery(Q_GET_CEOS);
	const [deleteCompanyCeo, { loading: loadingDeleteCeo, error: errorDeleteCeo, data: dataDeleteCeo }] = useMutation(M_DELETE_CEO);
	const [updateCEOsOrder] = useMutation(M_UPDATE_ORDER);

	useEffect(() => loadCeos(), [id]);

	useEffect(() => {
		if (errorCeos) {
			errorCeos.graphQLErrors.map(({ message }, i) =>
				toast({
					title: t(message),
					status: 'error',
					isClosable: true,
					position: 'top-right',
				})
			);
		}
	}, [errorCeos]);

	useEffect(() => {
		if (dataDeleteCeo?.deleteCompanyCEO) {
			toast({
				title: t('Operation success'),
				status: 'success',
				isClosable: true,
				position: 'top-right',
			});
			loadCeos();
			closeDeleting();
		}
	}, [dataDeleteCeo]);

	useEffect(() => {
		if (errorDeleteCeo) {
			errorDeleteCeo.graphQLErrors.map(({ message }, i) =>
				toast({
					title: t(message),
					status: 'error',
					isClosable: true,
					position: 'top-right',
				})
			);
		}
	}, [errorDeleteCeo]);

	useEffect(() => {
		dataCeos?.getCompanyCEO && setLists(dataCeos?.getCompanyCEO);
	}, [dataCeos?.getCompanyCEO]);

	useEffect(() => {
		lists && onDataChanged(lists);
	}, [lists]);

	function loadCeos() {
		id && loadCompanyCeos({ variables: { id, type: type === c.governanceCeo.ceo ? null : type } });
	}

	function onSortEnd({ oldIndex, newIndex }) {
		const moves = arrayMove(lists, oldIndex, newIndex);
		const orders = moves.map((item, order) => ({ id: item?.id, order }));
		const variables = { orders: JSON.stringify(orders) };
		updateCEOsOrder({ variables });
		setLists(moves);
	}

	const SortableIcon = SortableHandle(() => (
		<Tooltip label={t('Drag to arrainge order')}>
			<DragHandleIcon />
		</Tooltip>
	));

	const SortableItem = SortableElement(({ item }) => {
		return (
			<Tr cursor="pointer" _hover={{ bg: 'gray.200' }} bg="white">
				<Td>
					<SortableIcon />
				</Td>
				<Td>{item?.name}</Td>
				<Td>{t(item?.gender)}</Td>
				{type !== c.governanceCeo.ceo && <Td>{t(item?.position)}</Td>}
				<Td>{t(item?.nationality)}</Td>
				<Td>{toDateString(item?.approvalDate, c.defaultDateFormat)}</Td>
				<Td className="hidden-print" w="228px">
					{item?.file?.file && <DownloadAndViewButton marginLeft={0} url={item?.file?.file} size={item?.file?.size} />}
				</Td>
				{(currentUser?.role === 'admin' || currentUser?.canUpdateCompanyGovernance) && (
					<Td className="hidden-print" isNumeric>
						<ActionMenuBoardOfDirector id={item?.id} />
					</Td>
				)}
			</Tr>
		);
	});

	const SortableList = SortableContainer(({ items }) => {
		return (
			<Tbody>
				{loadingCeos && <LoadingTable column={6} row={3} />}
				{items?.length <= 0 && (
					<Tr>
						<Td colSpan={10}>
							<Text>{t('No Data')}</Text>
						</Td>
					</Tr>
				)}
				{items?.map((item, i) => (
					<SortableItem key={`item-${item?.id}`} index={i} item={item} />
				))}
			</Tbody>
		);
	});

	const ActionMenuBoardOfDirector = ({ id }) => {
		return (
			<Menu>
				<Tooltip label={`${t('Edit')} ${t('Delete')}`}>
					<MenuButton cursor="pointer">
						<Flex pl="2" pr="2">
							<FontAwesomeIcon icon={faEllipsisV} style={{ fontSize: 16, marginTop: 4, marginLeft: 6 }} />
						</Flex>
					</MenuButton>
				</Tooltip>
				<MenuList pt="4" pb="4" pl="3" pr="3" borderRadius={c.borderRadius}>
					<MenuItem
						color={'blue.500'}
						onClick={() => {
							workingId = id;
							openEditing();
						}}
					>
						{t('Edit')}
					</MenuItem>
					<MenuItem
						color={'red'}
						onClick={() => {
							workingId = id;
							openDeleting();
						}}
					>
						{t('Delete')}
					</MenuItem>
				</MenuList>
			</Menu>
		);
	};

	return (
		<>
			<Flex {...props}>
				<Box width="100%">
					<Center>
						<Box
							w="100%"
							bg="white"
							borderRadius={c.borderRadius}
							border="1px solid #dbdbdb"
							boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
							pb={2}
						>
							<Flex p={4} pt={2} pb={2} mb={4} h="48px" bg="#FAFAFA" alignItems="center" justifyContent="end" borderBottom="1px solid #dbdbdb">
								<Flex alignItems="center">
									{(currentUser?.role === 'admin' || currentUser?.canUpdateCompanyGovernance) && (
										<Tooltip label={t('Add')}>
											<Flex
												className="hidden-print"
												userSelect="none"
												onClick={openInserting}
												cursor="pointer"
												alignItems="center"
												justifyContent="center"
												w="32px"
												h="32px"
												borderRadius={c.borderRadius}
												border="1px solid #dbdbdb"
												boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
											>
												<Text fontSize="26px" mt="2px" fontWeight="500">
													+
												</Text>
											</Flex>
										</Tooltip>
									)}
								</Flex>
							</Flex>
							<Box p="16px">
								<Table variant="simple">
									<Thead>
										<Tr>
											<Th />
											<Th>{t('Name')}</Th>
											<Th>{t('Gender')}</Th>
											{type !== c.governanceCeo.ceo && <Th>{t('Position')}</Th>}
											<Th>{t('Nationality')}</Th>
											<Th>{t('Approval Date')}</Th>
											<Th>{t('Permit letter')}</Th>
											{(currentUser?.role === 'admin' || currentUser?.canUpdateCompanyGovernance) && (
												<Th className="hidden-print" isNumeric>
													{t('Actions')}
												</Th>
											)}
										</Tr>
									</Thead>
									<SortableList useDragHandle items={lists} onSortEnd={onSortEnd} />
									<Tfoot>
										<Tr>
											<Th />
											<Th>{t('Name')}</Th>
											<Th>{t('Gender')}</Th>
											{type !== c.governanceCeo.ceo && <Th>{t('Position')}</Th>}
											<Th>{t('Nationality')}</Th>
											<Th>{t('Approval Date')}</Th>
											<Th>{t('Permit letter')}</Th>
											{(currentUser?.role === 'admin' || currentUser?.canUpdateCompanyGovernance) && (
												<Th className="hidden-print" isNumeric>
													{t('Actions')}
												</Th>
											)}
										</Tr>
									</Tfoot>
								</Table>
							</Box>
						</Box>
					</Center>
				</Box>
			</Flex>
			<AlertDialog isOpen={isDeleting} leastDestructiveRef={deleteDialogRef} onClose={closeDeleting}>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							{t('Delete')}
						</AlertDialogHeader>
						<AlertDialogBody>{t("Are you sure? You can't undo this action afterwards")}</AlertDialogBody>
						<AlertDialogFooter>
							<Button mr={4} isLoading={loadingDeleteCeo} ref={deleteDialogCancelRef} onClick={closeDeleting}>
								{t('Cancel')}
							</Button>
							<Button
								isLoading={loadingDeleteCeo}
								colorScheme="red"
								onClick={() => {
									const variables = { id: workingId };
									deleteCompanyCeo({ variables });
								}}
							>
								{t('Delete')}
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
			{isInserting && <AddCompanyCeoModal type={type} companyId={id} isOpen={true} onClose={closeInserting} callback={loadCeos} />}
			{isEditing && <EditCompanyCeoModal type={type} id={workingId} isOpen={true} onClose={closeEditing} callback={loadCeos} />}
		</>
	);
}

export default GovernanceCeo;
