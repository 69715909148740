/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useLazyQuery } from '@apollo/client';
import { Flex, useToast, SimpleGrid } from '@chakra-ui/react';
import LeaveUseCard from './LeaveUseCard';

const Q_GET_LEAVE_USE = gql`
	query GetLeaveUseByEmployeeId($employeeId: String!) {
		getLeaveUseByEmployeeId(employeeId: $employeeId) {
			usedAnnualLeave
			usedShortLeave
			usedMaternityLeave
			usedSickLeave
			usedPersonalLeave
			usedWorkFromHomeLeave
		}
	}
`;

function LeaveUse({ employeeId }) {
	const toast = useToast();
	const { t } = useTranslation();
	const [loadLeaveUse, { error, data, loading }] = useLazyQuery(Q_GET_LEAVE_USE);

	useEffect(() => {
		const variables = { employeeId };
		loadLeaveUse({ variables });
	}, [employeeId]);

	useEffect(() => {
		if (error) {
			error.graphQLErrors.map(({ message }, i) =>
				toast({
					title: t(message),
					status: 'error',
					isClosable: true,
					position: 'top-right',
				})
			);
		}
	}, [error]);

	return (
		<>
			<Flex className="hidden-print" pb={8}>
				<SimpleGrid width="100%" columns={6} spacing={8}>
					<LeaveUseCard
						title={t('Annual leave')}
						loading={loading}
						data={data?.getLeaveUseByEmployeeId?.usedAnnualLeave}
					/>
					<LeaveUseCard
						title={t('Short leave')}
						loading={loading}
						data={data?.getLeaveUseByEmployeeId?.usedShortLeave}
					/>
					<LeaveUseCard
						title={t('MATERNITY LEAVE')}
						loading={loading}
						data={data?.getLeaveUseByEmployeeId?.usedMaternityLeave}
					/>
					<LeaveUseCard
						title={t('PERSONAL LEAVE')}
						loading={loading}
						data={data?.getLeaveUseByEmployeeId?.usedPersonalLeave}
					/>
					<LeaveUseCard
						title={t('SICK LEAVE')}
						loading={loading}
						data={data?.getLeaveUseByEmployeeId?.usedSickLeave}
					/>
					<LeaveUseCard
						title={t('Work from home')}
						loading={loading}
						data={data?.getLeaveUseByEmployeeId?.usedWorkFromHomeLeave}
					/>
				</SimpleGrid>
			</Flex>
		</>
	);
}

export default LeaveUse;
