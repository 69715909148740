import { gql, useLazyQuery } from '@apollo/client';
import { useContext, createContext, useState, useEffect } from 'react';

const Q_GET_REQUEST = gql`
	query get($id: String!) {
		getAgentTerminationRequestById(id: $id) {
			id
			number
			createdAt
			createdBy
			updatedAt
			updatedBy
			status
			company {
				id
				name
				nameKhmer
			}
			numberOfRequestedAgent
			numberOfApprovedAgent
			terminationsOfficeApprovedAt
			terminationsOfficeApprovedBy
			terminationsDepartmentApprovedAt
			terminationsDepartmentApprovedBy
			completedAt
			completedBy
			candidates {
				id
				agent {
					id
					code
					name
					latinName
					nationality
				}
				endDate
				reason
				attachment
				createdAt
				createdBy
				updatedAt
				updatedBy
				description
				status
				statusDescription
				branch {
					id
					name
					nameLatin
				}
				geoLocation {
					id
					name
					latinName
				}
			}
		}
	}
`;

const TerminationRequestContext = createContext();

export const TerminationRequestProvider = ({ children }) => {
	const [load, { loading: loadingRequest, data }] = useLazyQuery(Q_GET_REQUEST);
	const [loadedData, setLoadedData] = useState(null);

	useEffect(() => {
		if (data) {
			setLoadedData(data?.getAgentTerminationRequestById);
		}
	}, [data]);

	const loadRequest = (id) => {
		load({ variables: { id } });
	};

	return (
		<TerminationRequestContext.Provider value={{ loadRequest, loadingRequest, loadedData, setLoadedData }}>{children}</TerminationRequestContext.Provider>
	);
};

export const useTerminationRequest = () => {
	return useContext(TerminationRequestContext);
};
