import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Container, Box, Flex, Text,
    Button,
    Badge,
    useToast
} from "@chakra-ui/react";
import BreadcrumbComponent from "../../components/BreadcrumbComponent/BreadcrumbComponent";
import c from "../../constant"
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import moment from "moment"
import './style.css'
import { useLocation, useHistory } from "react-router-dom";
import useStore from "../../store";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TableComponent from "../../components/Table/Cloudscape/TableComponent";
import useLocalStorage from 'use-local-storage';
import { useDebounce } from 'use-debounce';
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import getDepartmentLabel from "../../utils/getDepartmentLabel";
import getPositionLabel from "../../utils/getPositionLabel";
import getOfficeLabel from "../../utils/getOfficeLabel";
import {
    ButtonDropdown,
} from '@cloudscape-design/components';
import 'moment/locale/en-gb';
import 'moment/locale/km';
const NAME_COLUMN_WIDTH = 180;

const QUERY_USERS = gql`
query getUsers($offset:Float, $limit: Float, $name: String){
    getUsers(
        pagerInput:{
            limit:$limit
            offset:$offset
        }
        name: $name
    ){
      users{
        id
        username
        fullName
        fullNameKhmer
        createdAt
        role
        department
        office
        position
        active
      }
      totalPages
      totalRows
    }
  }
`

const UPDATE_USER_MUTATION = gql`
mutation updateUserByAdmin($id: String!, $active: Boolean){
    updateUserByAdmin(
        id: $id
        active: $active
    )
}
`

const CONVERT_USER_TO_EMPLOYEE = gql`
    mutation convert($id: String!){
        convertUserToEmployeeById(id:$id)
    }
`
function UserList() {
    const { t } = useTranslation();
    const location = useLocation();
    const history = useHistory();
    const urlParams = new URLSearchParams(location.search);
    const currentPage = urlParams.get('page') || 1;
    const toast = useToast()

    const { currentLang } = useStore((state) => state.currentLang);
    moment.locale(currentLang === "kh" ? "km" : "en-gb")

    const [loadUsers, { error, loading, data }] = useLazyQuery(QUERY_USERS)
    const [updateUserByAdmin, { loading: loadingUpdate, error: errorUpdate, data: dataUpdate }] = useMutation(
        UPDATE_USER_MUTATION
    );
    const [convertUserToEmployee, { loading: loadingConvert, error: errorConvert, data: dataConvert }] = useMutation(
        CONVERT_USER_TO_EMPLOYEE
    );

    const [tmpId, setTmpId] = useState(null)

    function loadUserWithVar() {
        loadUsers({
            variables: {
                offset: currentPage ? (currentPage - 1) * pageSize : 0,
                limit: pageSize,
                name: deboundSearchText
            }
        })
    }

    useEffect(() => {
        if (dataUpdate?.updateUserByAdmin) {
            loadUserWithVar()
            if (tmpId) {
                convertUserToEmployee({
                    variables: { id: tmpId }
                })
            }
            setTmpId(null)
            toast({
                title: t("User update successfully"),
                status: "success",
                isClosable: true,
                position: "top-right"
            })
        }
    }, [dataUpdate])

    function updateUser({ id, active }) {
        setTmpId(id)
        updateUserByAdmin({
            variables: {
                id,
                active: !active
            }
        })
    }

    function actionMenu({ id, active }) {
        return (
            <ButtonDropdown
                className="action-button-table"
                variant="inline-icon"
                ariaLabel={`${id} actions`}
                expandToViewport={true}
                items={[
                    { id: 'toggleActiveInactive', text: <p style={{ color: active ? "red" : "#27ae60" }}>{active ? t("Deactivate") : t("Activate")}</p> },
                    { id: 'transferProposal', text: t('Transfer Proposal') },
                ]}
                onItemClick={e => {
                    switch (e?.detail?.id) {
                        case "toggleActiveInactive":
                            updateUser({ id, active })
                            break;
                        case "transferProposal":
                            history.push("/users/transfer-proposal?id=" + id)
                            break;
                        default:
                            break;
                    }
                }}
            />
        )
    }


    // Table config
    const [totalPage, setTotalPage] = useState(0)
    const tableName = "listuser";
    const rawColumns = [
        {
            id: 'username',
            label: t('Username'),
            alwayVisible: true,
            header: <Flex style={{ paddingLeft: 20, paddingRight: 20 }}>{t('Username')}</Flex>,
            cell: (item) => (
                <Link to={`/users/update?id=${item?.id}`}>
                    <Flex pl="20px" pr="20px" cursor="pointer">
                        <Text fontWeight={600} color="#0972d3" textDecoration="underline">
                            {item.username}
                        </Text>
                    </Flex>
                </Link>
            ),
            isRowHeader: true,
            width: 160,
        },
        {
            id: 'fullNameKhmer',
            label: t('Full Name Khmer'),
            header: t('Full Name Khmer'),
            cell: (item) => item.fullNameKhmer || '-',
            width: NAME_COLUMN_WIDTH,
        },
        {
            id: 'fullName',
            label: t('Full Name'),
            header: t('Full Name'),
            cell: (item) => item.fullName || '-',
            width: NAME_COLUMN_WIDTH,
        },
        {
            id: 'position',
            label: t('Position'),
            header: t('Position'),
            cell: (item) => item?.position ? getPositionLabel(item?.position) : "-",
            width: NAME_COLUMN_WIDTH,
        },
        {
            id: 'department',
            label: t('Department'),
            header: t('Department'),
            cell: (item) => item?.department ? t(getDepartmentLabel(item.department)) : "-",
            width: 350,
        },
        {
            id: 'office',
            label: t('Office'),
            header: t('Office'),
            cell: (item) => getOfficeLabel(item.office, currentLang) || "-",
            width: 350,
        },
        {
            id: 'createdAt',
            label: t('Created At'),
            header: t('Created At'),
            cell: (item) => item.createdAt ? moment(item.createdAt).format("LLLL") : "",
            width: 230,
        },
        {
            id: 'status',
            label: t('Status'),
            header: t('Status'),
            cell: (item) => item.active ?
                <Badge colorScheme='green'>{t("ACTIVE")}</Badge> :
                <Badge colorScheme='red'>{t("INACTIVE")}</Badge>
        },
        {
            id: 'actions',
            label: t('Actions'),
            header: t('Actions'),
            cell: (item) => actionMenu({ id: item?.id, active: item?.active }),
        },
    ]
    const [preferences, setPreferences] = useLocalStorage(`${tableName}-table-Preferences`, {
        pageSize: 10,
        contentDisplay: rawColumns.map((column) => ({ id: column.id, visible: true })),
        wrapLines: false,
        stripedRows: false,
        contentDensity: 'comfortable',
        stickyColumns: { first: 1, last: 1 },
    });
    const { pageSize } = preferences;

    function getHeader() {
        return (
            <Flex className="table-header" direction={'row'} alignItems={'center'}>
                <Flex flexGrow={1} className="title">
                    <Text fontSize="xl" fontWeight={600} mt="10px" mb="10px">
                        {t('Users')}
                    </Text>
                </Flex>
                <Flex direction={'row'}>
                    {<Link to={`/users/create`}>
                        <Button
                            ml="10px"
                            onClick={() => null}
                            leftIcon={<FontAwesomeIcon icon={faPlus} style={{ fontSize: 16 }} />}
                            colorScheme={`${false ? 'yellow' : 'gray'}`}
                            variant="solid"
                            size="sm"
                            borderRadius={c.borderRadius}
                            border="1px solid #bdc3c7}"
                        >
                            {t("Add")}
                        </Button>
                    </Link>}
                </Flex>
            </Flex>
        )
    }
    // end table config

    useEffect(() => {
        loadUserWithVar();
        window.scrollTo(0, 0);
    }, []);

    function onTablePageChange(e) {
        history.push(`/users?page=${e?.detail?.currentPageIndex}`);
    }

    const [searchText, setSearchText] = useState('');
    const [deboundSearchText] = useDebounce(searchText, 1000);
    function onSearchTextChange(e) {
        setSearchText(e?.detail?.filteringText);
    }
    useEffect(() => {
        history.push(`/users?page=1`);
    }, [deboundSearchText]);

    useEffect(() => {
        loadUserWithVar();
    }, [currentPage, deboundSearchText, pageSize]);

    useEffect(() => {
        if (data?.getUsers?.totalPages) {
            setTotalPage(data?.getUsers?.totalPages || 1);
        }
    }, [data]);

    return (
        <>
            <Container maxW="container.xl" mt="16px" mb="16px">
                <BreadcrumbComponent
                    list={[
                        {
                            name: "USERS",
                            path: "#",
                        }
                    ]}
                />
                <Box pb="32px" w="100%" maxW={"8xl"} mt="5">
                    <TableComponent
                        tableName={tableName}
                        rawColumns={rawColumns}
                        currentPage={currentPage}
                        totalPage={totalPage}
                        loading={loading}
                        items={data?.getUsers?.users || []}
                        preferences={preferences}
                        setPreferences={setPreferences}
                        onTablePageChange={onTablePageChange}
                        onSearchTextChange={onSearchTextChange}
                        searchText={searchText}
                        totalRows={data?.getUsers?.totalRows || ''}
                        header={getHeader()}
                    />
                </Box>
            </Container>
        </>
    );
}

export default UserList;
