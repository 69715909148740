import React from "react";
import { Flex, Badge, Text, Box } from "@chakra-ui/react";
import moment from "moment";
import "moment/locale/en-gb";
import "moment/locale/km";
import c from "../../constant"
import ProgressiveImage from "../ProgressiveImage"
import { Skeleton, SkeletonText } from "@chakra-ui/react"

export default function ArticleRow({data, loading}) {
    moment.locale("en-gb");
    
    return (
        <Flex cursor="pointer" marginBottom="48px">
            <Box marginRight="20px" w="100%">
                {
                    !loading&&<>
                        <Text
                            fontSize="22px"
                            lineHeight="28px"
                            fontWeight="600"
                            noOfLines="2"
                            pt="6px"
                        >
                            {data?.title}
                        </Text>
                        <Text
                            fontSize="16px"
                            noOfLines="2"
                            color="text.gray"
                            fontWeight="500"
                            pt="8px"
                        >
                            {data?.description}
                        </Text>
                        <Text
                            pt="8px"
                            color={
                                moment(data?.publishDate).toDate()>moment().toDate()?
                                "red":
                                "text.gray"
                            }
                            fontWeight="500"
                            fontSize="13px"
                        >
                            Publish Date: {moment(data?.publishDate).format("MMMM Do YYYY, h:mm a")}
                        </Text>
                        {(!(data?.isPublish)&&data?.id)&&<Badge fontSize="18px" mt="2" colorScheme="red">Unpublished</Badge>}
                    </>
                }
                {loading && <SkeletonText noOfLines={4}/>}
            </Box>
            <Skeleton isLoaded={!loading}>
                <Box minW="200px" w="200px" h="134px" overflow="hidden">
                    {(data?.thumbnail?.low&&data?.thumbnail?.placeholder)&&<ProgressiveImage
                        src={(c.baseFilePath+data?.thumbnail?.low)}
                        placeholder={c.baseFilePath+data?.thumbnail?.placeholder}
                        style={{
                            width:"100%",
                            height:"auto",
                            backgroundColor:"silver"
                        }}
                    />}
                </Box>
            </Skeleton>
        </Flex>
    );
}
