/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Text, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon } from '@chakra-ui/react';
import './style.css';

function FilterAccordion({
	trxId = null,
	lastName = null,
	firstName = null,
	email = null,
	phone = null,
	paymentStatus = null,
	fromDate = null,
	toDate = null,
	fromApprovedDate = null,
	toApprovedDate = null,
	companyName = null,
	selectedCompanyId = null,
	proposalNumber = null,
}) {
	const { t } = useTranslation();

	function filterInfo(data) {
		return (
			<>
				{data?.map((item, index) => {
					return (
						<Flex key={index} bg={index % 2 === 0 ? 'gray.100' : 'white'}>
							<Text pl="2">{t(item?.label)}:</Text>
							<Text pr="2" textAlign="right" flex={1}>
								{item?.value || 'N/A'}
							</Text>
						</Flex>
					);
				})}
			</>
		);
	}

	return (
		<>
			{(trxId ||
				lastName ||
				firstName ||
				email ||
				phone ||
				paymentStatus ||
				fromDate ||
				toDate ||
				fromApprovedDate ||
				toApprovedDate ||
				companyName ||
				selectedCompanyId ||
				proposalNumber) && (
				<Accordion w="364px" mt="4" float="right" allowMultiple>
					<AccordionItem>
						<h2>
							<AccordionButton>
								<AccordionIcon />
								<Box as="span" flex="1" fontSize={14} textAlign="right">
									{t('Filter Info')}
								</Box>
							</AccordionButton>
						</h2>
						<AccordionPanel pb={4}>
							{filterInfo([
								{ label: 'TRX.ID', value: trxId },
								{ label: 'Proposal Number', value: proposalNumber },
								{ label: 'Status', value: paymentStatus },
								{
									label: 'Created Date',
									value:
										fromDate && toDate
											? `${JSON.parse(fromDate)?.day + '/' + JSON.parse(fromDate)?.month + '/' + JSON.parse(fromDate)?.year} - ${
													JSON.parse(toDate)?.day + '/' + JSON.parse(toDate)?.month + '/' + JSON.parse(toDate)?.year
											  }`
											: '',
								},
								{
									label: 'Payment Approved At',
									value:
										fromApprovedDate && toApprovedDate
											? `${
													JSON.parse(fromApprovedDate)?.day +
													'/' +
													JSON.parse(fromApprovedDate)?.month +
													'/' +
													JSON.parse(fromApprovedDate)?.year
											  } - ${
													JSON.parse(toApprovedDate)?.day +
													'/' +
													JSON.parse(toApprovedDate)?.month +
													'/' +
													JSON.parse(toApprovedDate)?.year
											  }`
											: '',
								},
								{ label: 'Company/Institution Name', value: '' },
								{ label: 'Last Name', value: lastName },
								{ label: 'First Name', value: firstName },
								{ label: 'Email', value: email },
								{ label: 'Phone', value: phone },
							])}
						</AccordionPanel>
					</AccordionItem>
				</Accordion>
			)}
		</>
	);
}

export default FilterAccordion;
