/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, Flex, Box, useToast, Button } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { gql, useMutation } from '@apollo/client';
import TextFormField from '../form/TextFormField';
import FileFormField from '../form/FileFormField';
import InputAgentCodeFormField from '../form/InputAgentCodeFormField';
import c from '../../constant';
import * as Yup from 'yup';

const M_ADD_PROFESSIONAL_INDIVIDUAL = gql`
	mutation add($companyId: String!, $agentCode: String, $number: String, $referenceFile: Upload, $referenceSize: Float) {
		addCompanyProIndividual(companyId: $companyId, agentCode: $agentCode, number: $number, referenceFile: $referenceFile, referenceSize: $referenceSize)
	}
`;

const validationSchema = Yup.object().shape({
	agent: Yup.string().required('Required'),
	documentNumber: Yup.string(),
	referenceDocument: Yup.mixed().test('fileSize', 'over limit', (value) => {
		if (value) {
			return value?.size <= c.maxFileSize / 10;
		} else {
			return true;
		}
	}),
});

function AddLicensedProfessionalAgentModal({ companyId = null, isOpen = false, onClose = () => null, onCallback = () => null }) {
	const toast = useToast();
	const { t } = useTranslation();
	const [addCompanyProIndividual, { loading, data, error }] = useMutation(M_ADD_PROFESSIONAL_INDIVIDUAL);

	useEffect(() => {
		if (error) {
			if (error?.graphQLErrors?.length > 0) {
				error?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(error?.message ? error?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [error]);

	useEffect(() => {
		if (data) {
			if (data.addCompanyProIndividual) {
				toast({
					title: t('Professional individual has been added successfully'),
					status: 'success',
					duration: 5000,
					isClosable: true,
					position: 'bottom-right',
				});
				onClose();
				onCallback();
			}
		}
	}, [data]);

	const submit = (values) => {
		const variables = {
			companyId,
			agentCode: values?.agent,
			number: values?.documentNumber,
			referenceFile: values?.referenceDocument,
			referenceSize: values?.referenceDocument?.size,
		};
		addCompanyProIndividual({ variables });
	};

	return (
		<>
			<Modal closeOnEsc={false} closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent borderRadius={c.borderRadius} pb="2">
					<ModalHeader>{t('Add Professional Insurance Agent based on Licensing')}</ModalHeader>
					<ModalCloseButton isDisabled={loading} />
					<ModalBody>
						<Formik
							initialValues={{
								agent: '',
								documentNumber: '',
								referenceDocument: null,
							}}
							validationSchema={validationSchema}
							onSubmit={submit}
						>
							{(props) => (
								<Form>
									<Box mt="3">
										<TextFormField {...props} label="Number" name="documentNumber" />
										<InputAgentCodeFormField label="Agent" name="agent" placeholder="Please input completed agent code" required={true} />
										<FileFormField {...props} label="Reference Document" name="referenceDocument" maxFileSize={c.maxFileSize / 10} />
										<Flex textAlign="right" mt="6" justifyContent="space-between" alignItems="center">
											<Button
												id="login-btn"
												variant="solid"
												size="md"
												h="42px"
												w="128px"
												bg="primary.dark"
												_hover={{ bg: 'primary.dark' }}
												color="white"
												type="submit"
												isLoading={loading}
												loadingText={t('Add')}
												spinnerPlacement="start"
											>
												{t(`Add`)}
											</Button>
										</Flex>
									</Box>
								</Form>
							)}
						</Formik>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
}

export default AddLicensedProfessionalAgentModal;
