import React, { useEffect } from "react";
import { Checkbox, Stack, CheckboxGroup, FormControl, Box, FormLabel, FormHelperText, Text, Flex } from "@chakra-ui/react";
import { Field } from "formik";
import { useTranslation } from "react-i18next";

const CheckListFormField = props => {
    const {
        name,
        label,
        list,
        required,
        nested,
    } = props;
    const { t } = useTranslation();

    const [checkList, setCheckList] = React.useState(props.initValue?props.initValue:[])
    
    useEffect(()=>{
        if(props?.initValue?.length>0 && props?.callBack){
            props?.callBack(props?.initValue)
        }
    },[])

    function checkItem(checked, value, form){
        let tmpList = checkList
        if(checked===true){
            if(tmpList.length<=0){
                tmpList = [value]
            } else {
                tmpList = [...tmpList, value]
            }
        } else {
            const index = tmpList.indexOf(value);
            if (index > -1) {
                tmpList.splice(index, 1);
            }
        }
        setCheckList(tmpList)

        form.setFieldValue(name, tmpList)
        if(props?.callBack){
            props?.callBack(tmpList)
        }
    }

    return (
        <Field name={name}>
            {({ field, meta, form }) => {
                return (
                <Box mb="4" mt="4">
                <FormControl id={name} >
                    {label&&<FormLabel>{t(label)}{required&&<span style={{color:"red"}}>*</span>}</FormLabel>}
                    <CheckboxGroup colorScheme='green' defaultValue={checkList}>
                    <Stack spacing={[1, 5]} direction={['column']} ml="2">
                        {
                            list?.map((item,index)=>{
                                return (
                                <Box key={`rd-${index}`}>
                                    <Checkbox 
                                    onChange={(e) => {
                                        checkItem(e.target.checked, item.value, form)
                                    }}
                                      value={item.value}>{t(item.label)}</Checkbox>
                                    <Box ml="26px">
                                        {
                                            nested?.map((item,nIndex)=>{
                                                if(index==item?.index){
                                                    return(
                                                        <Box borderLeft="1px solid silver" pl="2" key={`nest-${index}-${nIndex}`}>
                                                        {item.child?item.child:null}
                                                        </Box>
                                                    )
                                                } else {
                                                    return null
                                                }
                                            })
                                        }
                                    </Box>
                                </Box>)
                            })
                        }
                    </Stack>
                    </CheckboxGroup>

                    {meta.touched && meta.error && (
                        <FormHelperText
                            id="error-message-password"
                            color="red.400"
                            fontSize="12px"
                        >
                            {t(name)}{" "}
                            {t("is")}{" "}
                            {t(meta.error)}
                        </FormHelperText>
                    )}
                </FormControl>

            </Box>
                
            )}}
        </Field>
    );
};

export default CheckListFormField;
