import React from 'react';
import { Box, Flex, Alert, AlertIcon, AlertTitle, AlertDescription, Image, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import c from '../../../../constant';
import useMoment from '../../../../hooks/useMoment';
import DownloadAndViewButton from '../../../Files/DownloadAndViewButton';
import CardName from '../CardName';

function InvoiceApproved({ label = null, invoice = null, licenseFile = null }) {
	const { t } = useTranslation();
	const { toDateString } = useMoment();

	return (
		<>
			<Alert status="success" variant="subtle" flexDirection="column" alignItems="center" justifyContent="center" textAlign="center" p={6} mt={6}>
				<AlertTitle fontSize="lg" mt={0} mr={0}>
					{t(label)}
				</AlertTitle>
				<AlertDescription mt={1} mr={0}>
					<Flex justifyContent="center" alignItems="center">
						<Flex ml={1} mr={1}>
							<Text fontWeight="bold">{t('approved at')}</Text>
							<>&nbsp;</>
							<Text>{toDateString(invoice?.approvedAt, c.dateTimeFormat)}</Text>
						</Flex>
						<Flex ml={1} mr={1}>
							<Text fontWeight="bold">{t('Approved By')}</Text>
							<>&nbsp;</>
							<CardName id={invoice?.approvedBy} />
						</Flex>
					</Flex>
					<Flex justifyContent="center" alignItems="center" mt={2}>
						<DownloadAndViewButton url={c.baseFilePath + invoice?.file} marginLeft={0} />
					</Flex>
				</AlertDescription>
			</Alert>
			<Alert status="success" variant="subtle" flexDirection="column" alignItems="center" justifyContent="center" textAlign="center" p={6} mt={6}>
				<AlertIcon boxSize="40px" mt={0} mr={0} />
				<AlertTitle fontSize="lg" mt={4} mr={0}>
					{t('Registration Certificate')}
				</AlertTitle>
				<AlertDescription mt={1} mr={0}>
					<Flex justifyContent="center" alignItems="center" mt={2}>
						<Image src={c.baseFilePath + licenseFile} w="25%" h="auto" />
					</Flex>
					<Box mt={2}>{toDateString(invoice?.approvedAt, c.dateTimeFormat)}</Box>
					<Flex justifyContent="center" alignItems="center" mt={2}>
						<DownloadAndViewButton url={c.baseFilePath + licenseFile} marginLeft={0} />
					</Flex>
				</AlertDescription>
			</Alert>
		</>
	);
}

export default InvoiceApproved;
