/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Box, Badge, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import c from '../../../constant';

export default function CardStatus({ value = null }) {
	const { t } = useTranslation();
	if (value === c.waiting) {
		return (
			<Box>
				<Badge fontSize="12px" variant="solid">
					{t('In progress')}
				</Badge>
			</Box>
		);
	} else if (value === c.approved) {
		return (
			<Box>
				<Badge fontSize="12px" colorScheme="green" variant="solid">
					{t('approved')}
				</Badge>
			</Box>
		);
	} else if (value === c.rejected) {
		return (
			<Box>
				<Badge fontSize="12px" colorScheme="red" variant="solid">
					{t('Rejected')}
				</Badge>
			</Box>
		);
	} else {
		return <Text>N/A</Text>;
	}
}
