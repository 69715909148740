/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { Badge, Flex, Text, Tooltip } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { CloseIcon } from '@chakra-ui/icons';
import { PartnerRequestStatus, GradeApproval } from '../../../../containers/ManageAgentPartner/PartnerRequest/constants';
import { gql, useMutation } from '@apollo/client';
import PartnerRequestContractApproval from '../Approval/PartnerRequestContractApproval';
import PartnerRequestEditingStatus from './PartnerRequestEditingStatus';
import useStore from '../../../../store';
import c from '../../../../constant';
import { usePartnerRequest } from '../../../../contexts/partner-request';

const M_SUBMIT_APPROVAL = gql`
	mutation submit($id: String!, $grade: PartnerReqeustApprovalGrade!, $status: PartnerReqeustContractStatus, $description: String) {
		submitContractApproval(id: $id, grade: $grade, status: $status, description: $description)
	}
`;

let status = null;
let description = null;

const PartnerRequestContractStatus = ({
	id = null,
	requestStatus = null,
	contract = null,
	certificateStatus = null,
	contractStatus = null,
	cancelledContract = null,
}) => {
	const { t } = useTranslation();
	const { loadedData, setLoadedData } = usePartnerRequest();
	const { currentUser: user } = useStore((state) => state.currentUser);
	const [submitApproval, { data }] = useMutation(M_SUBMIT_APPROVAL);

	useEffect(() => {
		if (data?.submitContractApproval) {
			setLoadedData({
				...loadedData,
				candidates: loadedData?.candidates?.map((e) => {
					if (e?.id === id) {
						e.contractStatus = status;
						e.contractStatusDescription = description;
					}
					return e;
				}),
			});
		}
	}, [data]);

	return (
		<>
			{requestStatus > PartnerRequestStatus.reviewContractFinal && (
				<>
					{contractStatus === 'APPROVED' && (
						<>
							<Tooltip label={`${t('approved')}`}>
								<Badge pl={2} pr={2} colorScheme="green">
									<Text fontWeight="bold" fontSize="xs" color="green.500">
										{t('approved')}
									</Text>
								</Badge>
							</Tooltip>
						</>
					)}
					{contractStatus === 'REJECTED' && (
						<>
							<Tooltip label={`${t('Rejected')}`}>
								<Badge pl={2} pr={2} colorScheme="red">
									<Text fontWeight="bold" fontSize="xs" color="red.500">
										{t('Rejected')}
									</Text>
								</Badge>
							</Tooltip>
						</>
					)}
					{!contractStatus && (
						<>
							<>
								<Text fontSize="sm" fontWeight="medium">
									{t('N/A')}
								</Text>
							</>
						</>
					)}
				</>
			)}
			{requestStatus === PartnerRequestStatus.reviewContract && (
				<>
					{c.stepOfficeRoles.includes(user?.position) ? (
						<>
							{contractStatus ? (
								<>
									{contractStatus === 'APPROVED' && (
										<>
											<Tooltip label={`${t('approved')} (${t('You can change this status')})`}>
												<Badge pl={2} pr={2} colorScheme="green">
													<Flex justifyContent="center" alignItems="center">
														<Text fontWeight="bold" fontSize="xs" color="green.500">
															{t('approved')}
														</Text>
														<>
															<CloseIcon
																w={2}
																h={2}
																ml={2}
																color="green.500"
																onClick={() => {
																	status = null;
																	description = null;
																	const variables = { id, grade: GradeApproval.primary, status, description };
																	submitApproval({ variables });
																}}
															/>
														</>
													</Flex>
												</Badge>
											</Tooltip>
										</>
									)}
									{contractStatus === 'REJECTED' && (
										<>
											<Tooltip label={`${t('Rejected')} (${t('You can change this status')})`}>
												<Badge pl={2} pr={2} colorScheme="red">
													<Flex justifyContent="center" alignItems="center">
														<Text fontWeight="bold" fontSize="xs" color="red.500">
															{t('Rejected')}
														</Text>
														<>
															<CloseIcon
																w={2}
																h={2}
																ml={2}
																color="red.500"
																onClick={() => {
																	status = null;
																	description = null;
																	const variables = { id, grade: GradeApproval.primary, status, description };
																	submitApproval({ variables });
																}}
															/>
														</>
													</Flex>
												</Badge>
											</Tooltip>
										</>
									)}
								</>
							) : (
								<>
									{contract ? (
										<>
											<PartnerRequestContractApproval id={id} requestStatus={requestStatus} />
										</>
									) : (
										<>
											<Text fontSize="sm" fontWeight="medium">
												{t('N/A')}
											</Text>
										</>
									)}
								</>
							)}
						</>
					) : (
						<>
							<Text fontSize="sm" fontWeight="medium">
								{t('-')}
							</Text>
						</>
					)}
				</>
			)}
			{requestStatus === PartnerRequestStatus.reviewContractFinal && (
				<>
					{c.stepDepartmentRoles.includes(user?.position) ? (
						<>
							{contractStatus ? (
								<>
									{contractStatus === 'APPROVED' && (
										<>
											<Tooltip label={`${t('approved')} (${t('You can change this status')})`}>
												<Badge pl={2} pr={2} colorScheme="green">
													<Flex justifyContent="center" alignItems="center">
														<Text fontWeight="bold" fontSize="xs" color="green.500">
															{t('approved')}
														</Text>
														<>
															<CloseIcon
																w={2}
																h={2}
																ml={2}
																color="green.500"
																onClick={() => {
																	status = null;
																	description = null;
																	const variables = { id, grade: GradeApproval.secondary, status, description };
																	submitApproval({ variables });
																}}
															/>
														</>
													</Flex>
												</Badge>
											</Tooltip>
										</>
									)}
									{contractStatus === 'REJECTED' && (
										<>
											<Tooltip label={`${t('Rejected')} (${t('You can change this status')})`}>
												<Badge pl={2} pr={2} colorScheme="red">
													<Flex justifyContent="center" alignItems="center">
														<Text fontWeight="bold" fontSize="xs" color="red.500">
															{t('Rejected')}
														</Text>
														<>
															<CloseIcon
																w={2}
																h={2}
																ml={2}
																color="red.500"
																onClick={() => {
																	status = null;
																	description = null;
																	const variables = { id, grade: GradeApproval.secondary, status, description };
																	submitApproval({ variables });
																}}
															/>
														</>
													</Flex>
												</Badge>
											</Tooltip>
										</>
									)}
								</>
							) : (
								<>
									{contract ? (
										<>
											<PartnerRequestContractApproval id={id} requestStatus={requestStatus} />
										</>
									) : (
										<>
											<Text fontSize="sm" fontWeight="medium">
												{t('N/A')}
											</Text>
										</>
									)}
								</>
							)}
						</>
					) : (
						<>
							{contractStatus ? (
								<>
									{contractStatus === 'APPROVED' && (
										<>
											<Tooltip label={`${t('approved')}`}>
												<Badge pl={2} pr={2} colorScheme="green">
													<Text fontWeight="bold" fontSize="xs" color="green.500">
														{t('approved')}
													</Text>
												</Badge>
											</Tooltip>
										</>
									)}
									{contractStatus === 'REJECTED' && (
										<>
											<Tooltip label={`${t('Rejected')}`}>
												<Badge pl={2} pr={2} colorScheme="red">
													<Text fontWeight="bold" fontSize="xs" color="red.500">
														{t('Rejected')}
													</Text>
												</Badge>
											</Tooltip>
										</>
									)}
								</>
							) : (
								<>
									<>
										{cancelledContract ? (
											<>
												<Text fontSize="sm" fontWeight="normal">
													{'N/A'}
												</Text>
											</>
										) : (
											<>
												{certificateStatus === 'APPROVED' ? (
													<>
														{c.stepDepartmentRoles.includes(user?.position) ? (
															<>
																<Text fontSize="sm" fontWeight="medium">
																	{'-'}
																</Text>
															</>
														) : (
															<>
																<PartnerRequestEditingStatus />
															</>
														)}
													</>
												) : (
													<>
														<Text fontSize="sm" fontWeight="normal">
															{'N/A'}
														</Text>
													</>
												)}
											</>
										)}
									</>
								</>
							)}
						</>
					)}
				</>
			)}
			{requestStatus === PartnerRequestStatus.reviewAgent && (
				<>
					<Text fontSize="sm" fontWeight="medium">
						{'-'}
					</Text>
				</>
			)}
			{requestStatus === PartnerRequestStatus.reviewAgentFinal && (
				<>
					<Text fontSize="sm" fontWeight="medium">
						{'-'}
					</Text>
				</>
			)}
			{requestStatus === PartnerRequestStatus.waitingContract && (
				<>
					<Text fontSize="sm" fontWeight="medium">
						{'-'}
					</Text>
				</>
			)}
		</>
	);
};

export default PartnerRequestContractStatus;
