import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Container, Box, Flex, Text, Center, SkeletonText, useToast,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    useDisclosure,
    Button
} from "@chakra-ui/react";
import BreadcrumbComponent from "../../components/BreadcrumbComponent/BreadcrumbComponent";
import { DragHandleIcon } from '@chakra-ui/icons'
import DownloadAndViewButton from "../../components/Files/DownloadAndViewButton";
import c from "../../constant"
import './style.css'
import { useLocation, useHistory } from "react-router-dom";
import PrintLayout from "../../components/Print/PrintLayout";
import AddCompanyReportType from "../../components/Modal/AddCompanyReportType"
import { SortableContainer, SortableElement, sortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import LoadingOverlay from "../../components/LoadingOverlay/LoadingOverlay"
import EditCompanyReportType from "../../components/Modal/EditCompanyReportType";
import ReactHtmlParser from 'react-html-parser';
import useStore from "../../store";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const QUERY_DATA = gql`
query get($active: Boolean){
    getCompanyReportTypes(active:$active){
        id
        title
        titleKh
        period
        active
    }
  }
`



const UPDATE_INDEX_MUTATION = gql`
    mutation update(
        $array: String!
    ){
        updateCompanyReportTypeIndex(
            array: $array
        )
    }
`

const UPDATE_ACTIVE_MUTATION = gql`
    mutation update(
        $id: String!,
        $active: Boolean!
    ){
        updateActiveCompanyReportType(
            id: $id
            active: $active
        )
    }
`


function CompanyReportTypeList() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { t } = useTranslation();
    const location = useLocation();
    const history = useHistory();
    const urlParams = new URLSearchParams(location.search);
    const toast = useToast()
    const status = urlParams.get('status') ? urlParams.get('status') : null;
    const { currentLang } = useStore((state) => state.currentLang);

    const [openAddPublicService, setOpenAddPublicService] = useState(false)
    const [openEditPublicService, setOpenEditPublicService] = useState(false)
    const [editingId, setEditingId] = useState(null)
    const [listItem, setListItem] = useState()
    const [loadData, { data, loading, error }] = useLazyQuery(QUERY_DATA)

    const [viewing, setViewing] = useState({})

    const [updateIndex, { loading: loadingUpdateIndex, error: errorUpdateIndex, data: dataUpdateIndex }] = useMutation(
        UPDATE_INDEX_MUTATION
    );

    const [updateActive, { loading: loadingUpdateActive, error: errorUpdateActive, data: dataUpdateActive }] = useMutation(
        UPDATE_ACTIVE_MUTATION
    );


    useEffect(() => {
        if (dataUpdateActive?.updateActiveCompanyReportType) {
            loadData({
                variables: {
                    active: status === "inactive" ? false : true
                }
            })
        }
    }, [dataUpdateActive])

    useEffect(() => {
        if (errorUpdateActive) {
            errorUpdateActive.graphQLErrors.map(({ message }, i) => (
                toast({
                    title: t(message),
                    status: "error",
                    isClosable: true,
                    position: "top-right"
                })
            ))
        }
    }, [errorUpdateActive])


    useEffect(() => {
        if (errorUpdateIndex) {
            errorUpdateIndex.graphQLErrors.map(({ message }, i) => (
                toast({
                    title: t(message),
                    status: "error",
                    isClosable: true,
                    position: "top-right"
                })
            ))
        }
    }, [errorUpdateIndex])

    function onSortEnd({ oldIndex, newIndex }) {
        const newArray = arrayMove(listItem, oldIndex, newIndex)
        setListItem(newArray)
        const updateIndexList = []
        newArray.map((item, index) => {
            updateIndexList.push({ id: item.id, index })
        })
        updateIndex({
            variables: {
                array: JSON.stringify(updateIndexList)
            }
        })
    };

    useEffect(() => {
        loadData({
            variables: {
                active: status === "inactive" ? false : true
            }
        })
    }, [])

    useEffect(() => {
        loadData({
            variables: {
                active: status === "inactive" ? false : true
            }
        })
    }, [status])

    useEffect(() => {
        if (data?.getCompanyReportTypes) {
            setListItem(data?.getCompanyReportTypes)
        }
    }, [data])


    function handleStatusChange(statusValue) {
        const page = 1

        let url = `?`

        if (statusValue) {
            url += `status=${statusValue}`
        }
        history.push(`/website/company-report-type-list${url}`)
    }

    function panelTabs({ name, value }) {
        let active = status ? status : "active"

        return (
            <Flex userSelect="none" minW="100px" onClick={() => {
                handleStatusChange(value)
            }} cursor="pointer" mb={"-2px"} _hover={{ boxShadow: `inset 0 -2px 0 0 ${value === active ? "#2980b9" : "#bdc3c7"}` }} boxShadow={value === active ? "inset 0 -2px 0 0 #2980b9" : ""} pt="16px" pb="16px" pl="12px" pr="12px" alignItems="center" justifyContent="center">
                <Text>{t(name)}</Text>
                {/* <Badge mb="2px" ml="4px">{amount>=0?amount:""}</Badge> */}
            </Flex>
        )
    }



    const SortableItem = SortableElement((item) => {
        return (
            <Flex
                w="100%"
                tabIndex={0}
                mb="2"
                minH="32px"
                alignItems="center"
                bg="white"
                borderRadius={c.borderRadius}
                border="1px solid #dbdbdb"
                boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
                p="16px"
            >
                <DragHandle />
                <Flex

                    w="100%"
                    h="100%"
                >
                    <Text
                        onClick={() => {
                            setOpenEditPublicService(true)
                            setEditingId(item?.value?.id)
                        }}
                        cursor="pointer"
                        _hover={{
                            textDecoration: "underline"
                        }}
                        mr="2"
                    >
                        {currentLang==="en"?item?.value?.title:item?.value?.titleKh}
                    </Text>
                </Flex>
                <Flex>
                    <Button size="sm" onClick={() => {
                        setOpenEditPublicService(true)
                        setEditingId(item?.value?.id)
                    }} mr="2" colorScheme='yellow' variant='link'>
                        {t("Edit")}
                    </Button>
                    <Button
                        size="sm"
                        isLoading={loadingUpdateActive}
                        onClick={() => {
                            updateActive({
                                variables: {
                                    id: item?.value?.id,
                                    active: !item?.value?.active
                                }
                            })
                        }} colorScheme={item?.value?.active ? 'red' : 'green'} variant='link'>
                        {item?.value?.active ? t("Inactive") : t("Active")}
                    </Button>
                </Flex>
            </Flex>
        )
    });

    const SortableList = SortableContainer(({ items }) => {
        return (
            <Box w="100%" m="4">
                {items.map((value, index) => (
                    <SortableItem key={`item-${value?.id}`} index={index} kIndex={index} value={value} />
                ))}
            </Box>
        );
    });

    return (
        <PrintLayout>
            <>
                <Container maxW="container.xl" mt="16px" mb="16px">
                    <BreadcrumbComponent
                        list={[
                            {
                                name: "WEBSITE",
                                path: "/website",
                            },
                            {
                                name: "Company Report Type List",
                                path: "#",
                            }
                        ]}
                    />
                    <Center className="show-only-printing">
                        <Text textAlign="center" color="#62B3ED" fontFamily="Moul" fontSize={18}>របាយការណ៍សុំច្បាប់</Text>
                    </Center>
                    <Center mt="5">
                        <Box
                            w="100%"
                            bg="white"
                            borderRadius={c.borderRadius}
                            border="1px solid #dbdbdb"
                            boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
                            pb="32px"
                        >
                            <Flex className="hidden-print" bg="#FAFAFA" p="16px" pt="0" pb="0" mb="16px" alignItems="center" justifyContent="space-between" borderBottom="1px solid #dbdbdb">
                                <Flex overflowX="scroll" overflowY="none" pb="2px">
                                    {panelTabs({ name: "Active", value: "active" })}
                                    {panelTabs({ name: "inactive", value: "inactive" })}
                                </Flex>

                                <Flex alignItems="center">
                                    <Button
                                        onClick={() => setOpenAddPublicService(true)}
                                        leftIcon={<FontAwesomeIcon icon={faPlus} style={{ fontSize: 16 }} />}
                                        colorScheme='gray'
                                        variant='solid'
                                        size="sm"
                                        borderRadius={c.borderRadius}
                                        border="1px solid #bdc3c7"
                                    >
                                        {t("Add")}
                                    </Button>
                                </Flex>


                            </Flex>

                            {loading && <Box p="4">
                                <SkeletonText mt='4' noOfLines={4} spacing='4' />
                            </Box>}

                            <Flex w="100%">
                                {(!loading && !error && (listItem?.length > 0)) && <SortableList useDragHandle items={listItem} onSortEnd={onSortEnd} />}
                            </Flex>
                        </Box>
                    </Center>
                </Container>
                <AddCompanyReportType isOpen={openAddPublicService} onClose={() => setOpenAddPublicService(false)} callback={() => loadData({ variables: { active: status === "inactive" ? false : true } })} />

                {openEditPublicService && <EditCompanyReportType
                    id={editingId}
                    isOpen={openEditPublicService}
                    onClose={() => setOpenEditPublicService(false)}
                    callback={() => loadData({ variables: { active: status === "inactive" ? false : true } })}
                />}

                {loadingUpdateIndex && <LoadingOverlay />}


                <Modal
                    // scrollBehavior="inside"
                    onClose={() => {
                        onClose();
                        setViewing({});
                    }}
                    isOpen={isOpen}
                    allowPinchZoom={true}
                >
                    <ModalOverlay />
                    <ModalContent borderRadius={c.borderRadius}>
                        <ModalHeader>
                            <Flex marginRight="40px">
                                <Text noOfLines={1} paddingRight="16px">
                                    {t("Detail")}
                                </Text>
                            </Flex>
                        </ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <center>
                                <Box maxW="1080px" textAlign="left" marginBottom="92px">


                                    <Text>{viewing.title}</Text>
                                    <Box ml="4" mt="4" w="100%" overflow="hidden">
                                        {ReactHtmlParser(viewing?.contentBody) }
                                    </Box>
                                    {viewing?.file?.fileSize > 0 && <Text mt="8" fontWeight="bold">ឯកសារតម្រូវសម្រាប់សេវា</Text>}
                                    {viewing?.file?.fileSize > 0 && <Box ml="-4">
                                        <DownloadAndViewButton viewButton={false} url={c.baseFilePath + viewing?.file?.file} size={viewing?.file?.fileSize} />
                                    </Box>}
                                </Box>
                            </center>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </>
        </PrintLayout>
    );
}

export default CompanyReportTypeList;

const DragHandle = sortableHandle(() => <Flex minH="32px" justifyContent="center" alignItems="center" cursor="move" mr="4"><DragHandleIcon /></Flex>);
