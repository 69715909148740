import React, { useEffect } from "react";
import { Tag, TagLabel, TagCloseButton, Box, FormControl, FormLabel, FormHelperText, InputGroup, InputRightElement, Button, Flex } from "@chakra-ui/react";
import { Field } from "formik";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons";
import c from "../../constant";
import useStore from "../../store";

const CompanyFormField = props => {
    const { currentLang } = useStore((state) => state.currentLang);

    const { t } = useTranslation('common');
    const {
        name,
        label,
        required,
        selectedCompany,
    } = props;

    useEffect(()=>{
        if(props?.selectedCompany){
            props?.setFieldValue(name, selectedCompany?.id)
        }
    },[props?.selectedCompany])

    return (
        <Field name={name}>
            {({ field, meta }) => (
                <Box mb="4">
                    <FormControl id={name} >
                        <FormLabel>{t(label)}{required && <span style={{ color: "red" }}>*</span>}</FormLabel>
                        <InputGroup size='md'>
                            <Flex alignItems="center" w="100%" borderRadius={c.borderRadius} h="48px" border="1px solid #CBD5E0">
                                {/* <Text fontSize={18} ml="4">asd</Text> */}
                                <Tag
                                    size="lg"
                                    ml="2px"
                                    borderRadius={c.borderRadius}
                                    variant='solid'
                                    colorScheme='green'
                                    w="calc(100% - 75px)"
                                    h="42px"
                                    >
                                    <TagLabel w="100%" fontSize={18} pt="8px" pb="8px">
                                        {currentLang === 'kh'?selectedCompany?.nameKhmer: selectedCompany?.name}
                                    </TagLabel>
                                    <TagCloseButton onClick={()=>{
                                            props?.setFieldValue(name, "")
                                            props?.callBackClear()
                                        }} />
                                    </Tag>
                            </Flex>

                            <InputRightElement width='4.5rem'>
                            <Button
                                onClick={()=>props?.callBackList()}
                                leftIcon={
                                    <FontAwesomeIcon
                                        style={{
                                            fontSize: 16,
                                            cursor: "pointer",
                                        }}
                                        icon={faList}
                                    />
                                } 
                                mt="7px"
                                variant="solid"
                                h="42px"
                                mr="3px"
                                borderRadius={c.borderRadius}
                            >
                                {t("List")}
                            </Button>
                            </InputRightElement>
                        </InputGroup>

                        {meta.touched && meta.error && (
                            <FormHelperText
                                id={`error-message-${name}`}
                                color="red.400"
                                fontSize="12px"
                            >
                                {t(name)}{" "}
                                {t("is")}{" "}
                                {t(meta.error)}
                            </FormHelperText>
                        )}
                    </FormControl>

                </Box>
            )}
        </Field>
    );
};

export default CompanyFormField;
