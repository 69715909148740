/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { Badge } from '@chakra-ui/react';
import { usePartnerRequestCount } from '../../../contexts/partner-request-count';
import { useTerminationRequestCount } from '../../../contexts/termination-request-count';
import { useBranchChangeRequestCount } from '../../../contexts/branch-change-request-count';
import useStore from '../../../store';
import c from '../../../constant';

const individualBadgeType = {
	newContract: 'newContract',
	terminateContract: 'terminateContract',
	changeBranch: 'changeBranch',
};

function IndividualBadgeCount({ type = null }) {
	const { currentUser: user } = useStore((state) => state.currentUser);
	const { loadCountPartnerRequest, countAllPartnerRequestPrimary, countAllPartnerRequestSecondary } = usePartnerRequestCount();
	const { loadCountTerminationRequest, countPrimaryReview, countSecondaryReview } = useTerminationRequestCount();
	const { loadCountBranchChangeRequest, countReview } = useBranchChangeRequestCount();

	useEffect(() => {
		type === individualBadgeType.newContract && loadCountPartnerRequest();
		type === individualBadgeType.terminateContract && loadCountTerminationRequest();
		type === individualBadgeType.changeBranch && loadCountBranchChangeRequest();
	}, [type]);

	return (
		<>
			{type === individualBadgeType.newContract && (
				<>
					{c.stepOfficeRoles.includes(user?.position) && (
						<>
							{parseInt(countAllPartnerRequestPrimary) > 0 && (
								<>
									<Badge colorScheme="red" variant="solid" ml={1}>
										{parseInt(countAllPartnerRequestPrimary)}
									</Badge>
								</>
							)}
						</>
					)}
					{c.stepDepartmentRoles.includes(user?.position) && (
						<>
							{parseInt(countAllPartnerRequestSecondary) > 0 && (
								<>
									<Badge colorScheme="red" variant="solid" ml={1}>
										{parseInt(countAllPartnerRequestSecondary)}
									</Badge>
								</>
							)}
						</>
					)}
				</>
			)}
			{type === individualBadgeType.terminateContract && (
				<>
					{c.stepOfficeRoles.includes(user?.position) && (
						<>
							{parseInt(countPrimaryReview) > 0 && (
								<>
									<Badge colorScheme="red" variant="solid" ml={1}>
										{countPrimaryReview}
									</Badge>
								</>
							)}
						</>
					)}
					{c.stepDepartmentRoles.includes(user?.position) && (
						<>
							{parseInt(countSecondaryReview) > 0 && (
								<>
									<Badge colorScheme="red" variant="solid" ml={1}>
										{countSecondaryReview}
									</Badge>
								</>
							)}
						</>
					)}
				</>
			)}
			{type === individualBadgeType.changeBranch && (
				<>
					{c.stepOfficeRoles.includes(user?.position) && (
						<>
							{parseInt(countReview) > 0 && (
								<>
									<Badge colorScheme="red" variant="solid" ml={1}>
										{countReview}
									</Badge>
								</>
							)}
						</>
					)}
				</>
			)}
		</>
	);
}

export { IndividualBadgeCount, individualBadgeType };
