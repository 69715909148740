import { Badge, Text, Tooltip } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const PartnerRequestCertificateAwaitingStatus = () => {
	const { t } = useTranslation();

	return (
		<Tooltip label={t('Waiting')}>
			<Badge colorScheme="yellow" variant="outline">
				<Text fontSize="xs" fontWeight="normal" color="yellow.600">
					{t('Waiting')}
				</Text>
			</Badge>
		</Tooltip>
	);
};

export default PartnerRequestCertificateAwaitingStatus;
