/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import c from "../../constant";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
  Flex,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { gql, useLazyQuery } from "@apollo/client";
import { Formik, Form } from "formik";
import DateRangeFormField from "../form/DateRangeFormField";
import CheckBoxFormField from "../form/CheckBoxFormField";
import RadioFormField from "../form/RadioFormField";
import moment from "moment";
import * as Yup from "yup";
import "moment/locale/en-gb";

const QUERY_COMPANY_REPORT_TYPE = gql`
  query get($period: String!) {
    getPublicCompanyReportTypes(period: $period) {
      id
      title
      titleKh
    }
  }
`;

const filterSchema = Yup.object().shape({
  dateRange: Yup.string().nullable(),
  type: Yup.string().nullable(),
  newReportType: Yup.string().nullable(),
  unread: Yup.boolean().nullable(),
  spam: Yup.boolean().nullable()
});

function CompanyDetailReportFilter({
  onApply,
  onClose,
  fromDate = null,
  toDate = null,
  type = null,
  newReportType = null,
  unread = null,
  spam = null,
}) {
  moment.locale("en-gb");
  const { t } = useTranslation();
  const [loadReportTypeByPeriod, { data: dataReportType }] = useLazyQuery(QUERY_COMPANY_REPORT_TYPE);
  const [selectedType, setSlectedType] = useState(type);
  const [selectedNewReportType, setSelectedNewReportType] = useState(newReportType);

  useEffect(() => {
    if (selectedType) {
      loadReportTypeByPeriod({ variables: { period: selectedType } });
    }
  }, [selectedType]);

  function getDateRange(fromDate, toDate) {
    if (fromDate && toDate) {
      const from = JSON.parse(fromDate);
      const to = JSON.parse(toDate);
      return JSON.stringify({ from, to });
    }
    return null;
  }

  function getReportTypeRadioData(data) {
    let res = [];
    data?.map((item) => {
      res.push({
        label: item?.title,
        value: item?.id,
      });
    });
    return res;
  }

  return (
    <>
      <Modal borderRadius={c.borderRadius} isOpen={true} onClose={onClose}>
        <ModalOverlay />
        <ModalContent borderRadius={c.borderRadius}>
          <ModalHeader>{t("Filter")}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Formik
              validationSchema={filterSchema}
              initialValues={{
                dateRange: getDateRange(fromDate, toDate),
                type: type,
                newReportType: newReportType,
                unread: unread,
                spam: spam,
              }}
              onSubmit={(values) => {
                let dateRange = null;
                let type = null;
                let newReportType = null;
                let unread = null;
                let spam = null;
                if (values.dateRange) {
                  dateRange = values.dateRange
                }
                if (values.type && values.type !== "all") {
                  type = values.type
                }
                if (values.newReportType) {
                  newReportType = selectedNewReportType
                }
                if (values.unread) {
                  unread = values.unread
                }
                if (values.spam) {
                  spam = values.spam
                }
                onApply({
                  dateRange,
                  type,
                  newReportType,
                  unread,
                  spam,
                });
                onClose();
              }}
            >
              {(props) => (
                <Box pb="4">
                  <Form>
                    <center>
                      <DateRangeFormField
                        {...props}
                        label="Created Date"
                        name="dateRange"
                        initialDate={{ fromDate, toDate }}
                      />
                    </center>

                    <RadioFormField
                      label="Type"
                      name="type"
                      radios={[
                        { label: "All", value: "all" },
                        { label: "Monthly", value: "MONTHLY" },
                        { label: "Quarterly", value: "QUARTERLY" },
                        { label: "Semesterly", value: "SEMESTERLY" },
                        { label: "Annually", value: "ANNUALLY" },
                      ]}
                      initValue={null === type ? "all" : type}
                      callbackValueChange={(e) => {
                        setSlectedType(e);
                        setSelectedNewReportType(null);
                      }}
                    />

                    {dataReportType?.getPublicCompanyReportTypes?.length > 0 && (
                      <RadioFormField
                        label="Report Type"
                        name="newReportType"
                        radios={getReportTypeRadioData(dataReportType?.getPublicCompanyReportTypes)}
                        initValue={selectedNewReportType}
                        callbackValueChange={(e) => setSelectedNewReportType(e)}
                      />
                    )}

                    <Box marginTop="4">
                      <CheckBoxFormField
                        {...props}
                        label="Show unread only"
                        name="unread"
                      />
                    </Box>

                    <Box marginTop="4">
                      <CheckBoxFormField
                        {...props}
                        label="Show spam"
                        name="spam"
                      />
                    </Box>

                    <Flex mt="32px" justifyContent="flex-end">
                      <Button
                        id="login-btn"
                        variant="solid"
                        size="md"
                        h="42px"
                        w="128px"
                        type="submit"
                        borderRadius={c.borderRadius}
                        color="#3498db"
                      >
                        {t("Apply")}
                      </Button>
                      <Button
                        size="md"
                        h="42px"
                        ml={4}
                        w="128px"
                        onClick={() => {
                          onApply({
                            dateRange: null,
                            type: null,
                            newReportType: null,
                            unread: null,
                            spam: null,
                          });
                          onClose();
                        }}
                        variant="ghost"
                      >
                        {t("Clear")}
                      </Button>
                    </Flex>
                  </Form>
                </Box>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default CompanyDetailReportFilter;
