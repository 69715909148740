/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
	Flex,
	Text,
	Box,
	Badge,
	Tooltip
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import useStore from '../../../../store';

function IntermediaryTab({ data=[], initTab='all', onCallback = () => null }) {
	const { t } = useTranslation();
	const { currentUser } = useStore((state) => state.currentUser);
	const [activeTab, setActiveTab] = useState(initTab)

	useEffect(()=>{
		onCallback(activeTab)
	},[activeTab])

	function checkImportant(important){
		let isImportant = false
		
		if((currentUser?.position==="5"||currentUser?.position==="6"||currentUser?.position==="7"||currentUser?.position==="8") && important === 'office'){
			isImportant = true
		}

		if((currentUser?.position==="3"||currentUser?.position==="4") && important === 'department'){
			isImportant = true
		}

		if((currentUser?.position==="1") && important === 'dg'){
			isImportant = true
		}

		return isImportant
	}

	function panelTab({ index, label, value, badge, tooltip, important }) {
		const active = value===activeTab
		let isImportant = checkImportant(important)
		return (
			<Box
				key={`it-${index}`}
				_hover={{ boxShadow: `inset 0 -2px 0 0 ${active ? '#2980b9' : '#bdc3c7'}` }}
				className="panel-tab"
				userSelect="none"
				// minW="100px"
				onMouseDown={() => setActiveTab(value)}
				cursor="pointer"
				mb={'-2px'}
				boxShadow={active ? 'inset 0 -2px 0 0 #2980b9' : ''}
				pt="16px"
				pb="16px"
				pl="12px"
				pr="12px"
				alignItems="center"
				justifyContent="center"
				display="inline-block"
			>
				<Tooltip label={t(tooltip?tooltip:label)}>
				<Flex>
					<Text whiteSpace="nowrap" fontWeight={active ? '700' : '500'} color="gray.700">
						{t(label)}
					</Text>
					{badge!==null&&<Box minW="18px">
							<>
								&nbsp;
								<>
									<Badge colorScheme={isImportant?'red':'blue'} variant={ active ? 'solid' : 'outline'} pl={1} pr={1}>
										{badge?.value}
									</Badge>
								</>
							</>
					</Box>}
				</Flex>
				</Tooltip>
			</Box>
		);
	}

	return (
		<Flex>
			{
				data?.map((item,index)=>{
					return(
						panelTab({
							index,
							label: item?.label,
							value: item?.value,
							badge: item?.badge,
							tooltip: item?.tooltip,
							important: item?.important
						})
					)
				})
			}
		</Flex>
	);
}

export default IntermediaryTab;
