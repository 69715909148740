import c from '../constant';
const getPositionLabel = (positionValue, lang = 'kh') => {
  let label = '';
  c.position.map((p) => {
    if (p.value.toString() === positionValue.toString()) {
      label = lang === 'kh' ? p.labelKh : p.label;
    }
  });
  return label;
};

export default getPositionLabel;
