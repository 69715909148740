/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { Text, Tooltip } from '@chakra-ui/react';
import useStore from '../../../store';

const Q_CANDIDATE = gql`
	query get($id: String!) {
		getBranchChangeRequestCandidateById(id: $id) {
			id
			agent {
				id
				code
				name
				latinName
				nationality
			}
		}
	}
`;

export default function EventLoggingCandidateBranchChangeRequest({ id = null }) {
	const { currentLang: lang } = useStore((state) => state.currentLang);
	const [getCandidate, { data }] = useLazyQuery(Q_CANDIDATE);

	useEffect(() => {
		if (id) {
			const variables = { id };
			getCandidate({ variables });
		}
	}, [id]);

	return (
		<>
			{data?.getBranchChangeRequestCandidateById && (
				<Tooltip
					label={
						data?.getBranchChangeRequestCandidateById?.agent?.nationality !== 'KH'
							? data?.getBranchChangeRequestCandidateById?.agent?.latinName
							: lang === 'kh'
							? data?.getBranchChangeRequestCandidateById?.agent?.name
							: data?.getBranchChangeRequestCandidateById?.agent?.latinName
					}
				>
					<Text cursor="pointer" fontSize="sm" fontWeight="semibold" color="gray.500">
						{data?.getBranchChangeRequestCandidateById?.agent?.code}
					</Text>
				</Tooltip>
			)}
		</>
	);
}
