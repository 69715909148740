/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import {
	Flex,
	Box,
	Center,
	Tooltip,
	Text,
	Table,
	Tbody,
	Thead,
	Tfoot,
	Tr,
	Th,
	Td,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	useToast,
	AlertDialog,
	AlertDialogOverlay,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogBody,
	AlertDialogFooter,
	Button,
	useDisclosure,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import AddCompanyActuaryModal from '../../Modal/AddCompanyActuaryModal';
import EditCompanyActuaryModal from '../../Modal/EditCompanyActuaryModal';
import DownloadAndViewButton from '../../Files/DownloadAndViewButton';
import LoadingTable from '../../Table/Loading';
import useStore from '../../../store';
import c from '../../../constant';
import CompanyName from '../../CompanyName';
import moment from "moment"
const Q_GET_ACTUARIES = gql`
	query ($id: String!) {
		getCompanyActuaries(companyId: $id) {
			id
			actuaryName
			actuary {
				id
				name
				nameKhmer
				logo
				type
			}
			designation
			qualification
			attachment
			approvalDate
		}
	}
`;

const M_DELETE = gql`
	mutation ($id: String!) {
		deleteCompanyActuaryById(id: $id)
	}
`;

let workingId = null;

function GovernanceActuary({ id = null, onDataChanged = () => null, ...props }) {
	const deleteDialogRef = useRef();
	const deleteDialogCancelRef = useRef();
	const toast = useToast();
	const { t } = useTranslation();
	const { currentUser } = useStore((state) => state.currentUser);
	const { isOpen: isInserting, onOpen: openInserting, onClose: closeInserting } = useDisclosure();
	const { isOpen: isDeleting, onOpen: openDeleting, onClose: closeDeleting } = useDisclosure();
	const { isOpen: isEditing, onOpen: openEditing, onClose: closeEditing } = useDisclosure();
	const [loadCompanyActuaries, { loading: loadingActuaries, error: errorActuaries, data: dataActuaries }] = useLazyQuery(Q_GET_ACTUARIES);
	const [deleteCompanyActuary, { loading: loadingDelete, error: errorDelete, data: dataDelete }] = useMutation(M_DELETE);

	useEffect(() => loadActuaries(), [id]);

	useEffect(() => {
		if (errorActuaries) {
			errorActuaries.graphQLErrors.map(({ message }, i) =>
				toast({
					title: t(message),
					status: 'error',
					isClosable: true,
					position: 'top-right',
				})
			);
		}
	}, [errorActuaries]);

	useEffect(() => {
		if (dataDelete?.deleteCompanyActuaryById) {
			toast({
				title: t('Operation success'),
				status: 'success',
				isClosable: true,
				position: 'top-right',
			});
			loadActuaries();
			closeDeleting();
		}
	}, [dataDelete]);

	useEffect(() => {
		if (errorDelete) {
			errorDelete.graphQLErrors.map(({ message }, i) =>
				toast({
					title: t(message),
					status: 'error',
					isClosable: true,
					position: 'top-right',
				})
			);
		}
	}, [errorDelete]);

	useEffect(() => {
		dataActuaries && onDataChanged(dataActuaries);
	}, [dataActuaries]);

	function loadActuaries() {
		if (id) {
			const variables = { id };
			loadCompanyActuaries({ variables });
		}
	}

	const ActionMenuBoardOfDirector = ({ id }) => {
		return (
			<Menu>
				<Tooltip label={`${t('Edit')} ${t('Delete')}`}>
					<MenuButton cursor="pointer">
						<Flex pl="2" pr="2">
							<FontAwesomeIcon icon={faEllipsisV} style={{ fontSize: 16, marginTop: 4, marginLeft: 6 }} />
						</Flex>
					</MenuButton>
				</Tooltip>
				<MenuList pt="4" pb="4" pl="3" pr="3" borderRadius={c.borderRadius}>
					<MenuItem
						color={'blue.500'}
						onClick={() => {
							workingId = id;
							openEditing();
						}}
					>
						{t('Edit')}
					</MenuItem>
					<MenuItem
						color={'red'}
						onClick={() => {
							workingId = id;
							openDeleting();
						}}
					>
						{t('Delete')}
					</MenuItem>
				</MenuList>
			</Menu>
		);
	};

	return (
		<>
			<Flex {...props}>
				<Box width="100%">
					<Center>
						<Box
							w="100%"
							bg="white"
							borderRadius={c.borderRadius}
							border="1px solid #dbdbdb"
							boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
							pb={2}
						>
							<Flex p={4} pt={2} pb={2} mb={4} h="48px" bg="#FAFAFA" alignItems="center" justifyContent="end" borderBottom="1px solid #dbdbdb">
								<Flex alignItems="center">
									{(currentUser?.role === 'admin' || currentUser?.canUpdateCompanyGovernance) && (
										<Tooltip label={t('Add')}>
											<Flex
												className="hidden-print"
												userSelect="none"
												onClick={openInserting}
												cursor="pointer"
												alignItems="center"
												justifyContent="center"
												w="32px"
												h="32px"
												borderRadius={c.borderRadius}
												border="1px solid #dbdbdb"
												boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
											>
												<Text fontSize="26px" mt="2px" fontWeight="500">
													+
												</Text>
											</Flex>
										</Tooltip>
									)}
								</Flex>
							</Flex>
							<Box p="16px">
								<Table variant="simple">
									<Thead>
										<Tr>
											<Th>{t('Name of Actuary / Company')}</Th>
											<Th>{t('Designation')}</Th>
											<Th>{t('Qualification')}</Th>
											<Th>{t('Approval Date')}</Th>
											<Th>{t('Attachment')}</Th>
											{(currentUser?.role === 'admin' || currentUser?.canUpdateCompanyGovernance) && (
												<Th className="hidden-print" isNumeric>
													{t('Actions')}
												</Th>
											)}
										</Tr>
									</Thead>
									<Tbody>
										{loadingActuaries && (
											<LoadingTable column={currentUser?.role === 'admin' || currentUser?.canUpdateCompanyGovernance ? 6 : 5} row={3} />
										)}
										{dataActuaries?.getCompanyActuaries.length <= 0 && (
											<Tr>
												<Td colSpan={20}>
													<Text>{t('No Data')}</Text>
												</Td>
											</Tr>
										)}
										{dataActuaries?.getCompanyActuaries?.map((el, i) => {
											return (
												<Tr cursor="pointer" _hover={{ bg: 'gray.200' }} key={`item-${i}`} bg="white">
													<Td>
														<CompanyName company={el?.actuary} companyName={el?.actuaryName} />
													</Td>
													<Td>{el?.designation}</Td>
													<Td>{el?.qualification}</Td>
													<Td>{el?.approvalDate?moment(el?.approvalDate).format(c.dateOnlyFormat):'-'}</Td>
													<Td className="hidden-print" w="228px">
														{el?.attachment?.file && (
															<DownloadAndViewButton marginLeft={0} url={el?.attachment?.file} size={el?.attachment?.size} />
														)}
													</Td>
													{(currentUser?.role === 'admin' || currentUser?.canUpdateCompanyGovernance) && (
														<Td className="hidden-print" isNumeric>
															<ActionMenuBoardOfDirector id={el?.id} />
														</Td>
													)}
												</Tr>
											);
										})}
									</Tbody>
									<Tfoot>
										<Tr>
											<Th>{t('Name of Actuary / Company')}</Th>
											<Th>{t('Designation')}</Th>
											<Th>{t('Qualification')}</Th>
											<Th>{t('Approval Date')}</Th>
											<Th>{t('Attachment')}</Th>
											{(currentUser?.role === 'admin' || currentUser?.canUpdateCompanyGovernance) && (
												<Th className="hidden-print" isNumeric>
													{t('Actions')}
												</Th>
											)}
										</Tr>
									</Tfoot>
								</Table>
							</Box>
						</Box>
					</Center>
				</Box>
			</Flex>
			<AlertDialog isOpen={isDeleting} leastDestructiveRef={deleteDialogRef} onClose={closeDeleting}>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							{t('Delete')}
						</AlertDialogHeader>
						<AlertDialogBody>{t("Are you sure? You can't undo this action afterwards")}</AlertDialogBody>
						<AlertDialogFooter>
							<Button mr={4} isLoading={loadingDelete} ref={deleteDialogCancelRef} onClick={closeDeleting}>
								{t('Cancel')}
							</Button>
							<Button
								isLoading={loadingDelete}
								colorScheme="red"
								onClick={() => {
									const variables = { id: workingId };
									deleteCompanyActuary({ variables });
								}}
							>
								{t('Delete')}
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
			{isInserting && <AddCompanyActuaryModal companyId={id} isOpen={true} onClose={closeInserting} onCallback={loadActuaries} />}
			{isEditing && <EditCompanyActuaryModal id={workingId} isOpen={true} onClose={closeEditing} onCallback={loadActuaries} />}
		</>
	);
}

export default GovernanceActuary;
