import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Box, Flex, Button, useToast, Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
} from "@chakra-ui/react";
import c from "../../constant"
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { gql, useMutation } from '@apollo/client';
import { useHistory } from "react-router-dom";
import RadioFormField from "../../components/form/RadioFormField"
import TextFormField from "../../components/form/TextFormField"
import countriesData from "../../utils/countries"
import SelectSearchFormField from "../../components/form/SelectSearchFormField"
import moment from "moment";
import DateFormField from "../form/DateFormField";
import FileFormField from "../../components/form/FileFormField"

const CREATE_COMPANY_SHAREHOLDER_MUTATION = gql`
mutation create(
    $name: String!, $gender: String , $nationality: String, $position: String, $companyId: String!, $approvalDate: DateTime,
    $file: Upload,
    $fileSize: Float
){
    createCompanyBoardOfDirector(
      companyId:$companyId
      position:$position
      nationality:$nationality
      name:$name
      gender:$gender
      approvalDate:$approvalDate
      file: $file
      fileSize: $fileSize
    )
  }
 `

const signUpSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    gender: Yup.string(),
    nationality: Yup.object().nullable(),
    position: Yup.string(),
    approvalDate: Yup.string().nullable(),
    file: Yup.mixed().test("fileSize", "over limit", (value) => {
        if (value) {
            return value?.size <= c.maxFileSize / 10
        } else {
            return true
        }
    }),
});

function Create({
    isOpen = false, onClose = () => null, callback = () => null, companyId
}) {
    const history = useHistory();
    const toast = useToast()
    const { t } = useTranslation();


    const [createCompanyBoardOfDirector, { loading, error, data }] = useMutation(
        CREATE_COMPANY_SHAREHOLDER_MUTATION
    );
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (data) {
            if (data?.createCompanyBoardOfDirector) {
                toast({
                    title: t("Company board of director created successfully"),
                    status: "success",
                    isClosable: true,
                    position: "top-right"
                })
                onClose()
                callback("Company board of director created successfully")
            }
        }
    }, [data])

    useEffect(() => {
        if (error) {
            error.graphQLErrors.map(({ message }, i) => (
                toast({
                    title: t(message),
                    status: "error",
                    isClosable: true,
                    position: "top-right"
                })
            ))
        }
    }, [error])


    function create(values) {
        createCompanyBoardOfDirector({
            variables: {
                companyId: companyId,
                position: values.position,
                nationality: values?.nationality?.value ? values?.nationality?.value : null,
                name: values.name,
                gender: values.gender,
                approvalDate: values.approvalDate?moment(values.approvalDate).toDate():null,
                file: values.file,
                fileSize: values?.file?.size,
            }
        })
    }

    function getNationalityOptions() {
        let tmpData = []
        countriesData.map((item) => {
            tmpData.push({
                label: t(item.nationality),
                value: item.nationality
            })
        })
        return tmpData
    }

    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={() => {
                    onClose()

                }}
                size="md"
            >
                <ModalOverlay />
                <ModalContent borderRadius={c.borderRadius} pb="2">
                    <ModalHeader>{t("Add Company Board Of Director")}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Formik
                            initialValues={{
                                name: "",
                                gender: "",
                                nationality: "",
                                position: "",
                                approvalDate: null,
                                file: null
                            }}
                            validationSchema={signUpSchema}
                            onSubmit={(values) => {
                                create(values);
                            }}
                        >
                            {(props) => (
                                <Form>
                                    <Box mt="3">
                                        <TextFormField
                                            {...props}
                                            label="Name"
                                            name="name"
                                            required
                                        />

                                        <RadioFormField
                                            label="Gender"
                                            name="gender"
                                            radios={[
                                                { label: "Male", value: "male" },
                                                { label: "Female", value: "female" },
                                            ]}
                                        />

                                        <SelectSearchFormField
                                            {...props}
                                            name="nationality"
                                            label="Nationality"
                                            placeholder="Nationality"
                                            options={getNationalityOptions()}
                                        />

                                        <TextFormField
                                            {...props}
                                            label="Position"
                                            name="position"
                                        />

                                        <DateFormField
                                            name="approvalDate"
                                            label="Approval Date"
                                        />

                                        <FileFormField
                                            {...props}
                                            label="Permit letter"
                                            name="file"
                                            maxFileSize={c.maxFileSize / 10}
                                        />

                                        <Flex
                                            textAlign="right"
                                            mt="6"
                                            justifyContent="space-between"
                                            alignItems="center"
                                        >
                                            <Button
                                                id="login-btn"
                                                variant="solid"
                                                size="md"
                                                h="42px"
                                                w="128px"
                                                bg="primary.dark"
                                                _hover={{ bg: "primary.dark" }}
                                                color="white"
                                                type="submit"
                                                isLoading={loading}
                                                loadingText={t("Add")}
                                                spinnerPlacement="start"
                                            >
                                                {t(`Add`)}
                                            </Button>
                                        </Flex>
                                    </Box>
                                </Form>
                            )}
                        </Formik>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
}

export default Create;
