/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import {
	Flex,
	Box,
	Center,
	Tooltip,
	Text,
	Table,
	Tbody,
	Thead,
	Tfoot,
	Tr,
	Th,
	Td,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	AlertDialog,
	AlertDialogOverlay,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogBody,
	AlertDialogFooter,
	Button,
	useToast,
} from '@chakra-ui/react';
import { SectionHeader } from '../../Other';
import { useTranslation } from 'react-i18next';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import AddCompanyShareholderModal from '../../../components/Modal/AddCompanyShareholder';
import EditCompanyShareholderModal from '../../../components/Modal/EditCompanyShareholder';
import c from '../../../constant';
import useStore from '../../../store';
import formatMoney from '../../../utils/formatMoney';
import useMoment from '../../../hooks/useMoment';
import LoadingTable from '../../Table/Loading';
import ShareholderPrint from './ShareholderPrint';

const Q_GET_COMPANY_SHAREHOLDERS = gql`
	query GetCompanyShareholder($id: String!) {
		getCompanyShareholder(companyId: $id) {
			id
			name
			representative
			gender
			nationality
			amount
			currency
			percentage
			createdAt
			approvalDate
		}
	}
`;

const M_DELETE_COMPANY_SHAREHOLDER = gql`
	mutation DeleteCompanyShareholder($id: String!) {
		deleteCompanyShareholder(id: $id)
	}
`;

let workingId = null;

function CompanyShareholder({ id, ...props }) {
	const deleteDialogRef = useRef();
	const deleteDialogCancelRef = useRef();
	const toast = useToast();
	const { t } = useTranslation();
	const { toDateString } = useMoment();
	const { currentUser } = useStore((state) => state.currentUser);
	const [isOpeningInsertDialog, setOpeningInsertDialog] = useState(false);
	const [isOpeningDeleteDialog, setOpeningDeleteDialog] = useState(false);
	const [isOpeningEditDialog, setOpeningEditDialog] = useState(false);
	const [loadCompanyShareholders, { loading: loadingCompanyShareholders, error: errorCompanyShareholders, data: dataCompanyShareholders }] =
		useLazyQuery(Q_GET_COMPANY_SHAREHOLDERS);
	const [deleteCompanyShareholder, { loading: loadingDeleteCompanyShareholder, error: errorDeleteCompanyShareholder, data: dataDeleteCompanyShareholder }] =
		useMutation(M_DELETE_COMPANY_SHAREHOLDER);
	const [totalKhr, setTotalKhr] = useState(0);
	const [totalUsd, setTotalUsd] = useState(0);
	const [totalPercentage, setTotalPercentage] = useState(0);

	useEffect(() => {
		if (id) {
			const variables = { id };
			loadCompanyShareholders({ variables });
		}
	}, [id]);

	useEffect(() => {
		if (errorCompanyShareholders) {
			errorCompanyShareholders.graphQLErrors.map(({ message }, i) =>
				toast({
					title: t(message),
					status: 'error',
					isClosable: true,
					position: 'top-right',
				})
			);
		}
	}, [errorCompanyShareholders]);

	useEffect(() => {
		if (dataDeleteCompanyShareholder?.deleteCompanyShareholder) {
			toast({
				title: t('Company shareholder deleted successfully'),
				status: 'success',
				isClosable: true,
				position: 'top-right',
			});
			const variables = { id };
			loadCompanyShareholders({ variables });
			onRejectDeleteDialog();
		}
	}, [dataDeleteCompanyShareholder]);

	useEffect(() => {
		if (errorDeleteCompanyShareholder) {
			errorDeleteCompanyShareholder.graphQLErrors.map(({ message }, i) =>
				toast({
					title: t(message),
					status: 'error',
					isClosable: true,
					position: 'top-right',
				})
			);
		}
	}, [errorDeleteCompanyShareholder]);

	const ActionMenuShareholder = ({ id }) => {
		return (
			<Menu>
				<Tooltip label={`${t('Delete')}`}>
					<MenuButton cursor="pointer">
						<Flex pl="2" pr="2">
							<FontAwesomeIcon icon={faEllipsisV} style={{ fontSize: 16, marginTop: 4, marginLeft: 6 }} />
						</Flex>
					</MenuButton>
				</Tooltip>
				<MenuList pt="4" pb="4" pl="3" pr="3" borderRadius={c.borderRadius}>
					<MenuItem
						color={'red'}
						onClick={() => {
							workingId = id;
							setOpeningDeleteDialog(true);
						}}
					>
						{t('Delete')}
					</MenuItem>
				</MenuList>
			</Menu>
		);
	};

	function onRejectInsertDialog() {
		setOpeningInsertDialog(false);
	}

	function onApproveInsertDialog() {
		if (id) {
			const variables = { id };
			loadCompanyShareholders({ variables });
		}
	}

	function onRejectDeleteDialog() {
		setOpeningDeleteDialog(false);
	}

	function onApproveDeleteDialog() {
		const variables = { id: workingId };
		deleteCompanyShareholder({ variables });
	}

	function editCompanyShareHolder(id) {
		if (currentUser?.role === 'admin' || currentUser?.canUpdateCompanyLicensing) {
			workingId = id;
			setOpeningEditDialog(true);
		}
	}

	function onRejectEditDialog() {
		setOpeningEditDialog(false);
	}

	function onApproveEditDialog() {
		const variables = { id };
		loadCompanyShareholders({ variables });
	}

	useEffect(() => {
		if (dataCompanyShareholders) {
			totalMoneyAndPercentage();
		}
	}, [dataCompanyShareholders]);

	function totalMoneyAndPercentage() {
		let khr = 0;
		let usd = 0;
		let percentage = 0;
		dataCompanyShareholders?.getCompanyShareholder?.map((item) => {
			percentage = percentage + parseFloat(item?.percentage);
			if (item?.currency === 'USD') {
				usd = usd + parseFloat(item?.amount);
			}
			if (item?.currency === 'KHR') {
				khr = khr + parseFloat(item?.amount);
			}
		});

		setTotalKhr(khr);
		setTotalUsd(usd);
		setTotalPercentage(percentage);
	}

	return (
		<>
			<Flex className="hidden-print" mt={6} {...props}>
				<Box width="100%">
					<SectionHeader title={t('Shareholder')} />
					<Center mt={4}>
						<Box
							width="100%"
							bg="white"
							borderRadius={c.borderRadius}
							border="1px solid #dbdbdb"
							boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
							pb={2}
						>
							<Flex h="48px" bg="#FAFAFA" pl="16px" pr="16px" mb="16px" alignItems="center" justifyContent="end" borderBottom="1px solid #dbdbdb">
								<Flex alignItems="center">
									{(currentUser?.role === 'admin' || currentUser?.canUpdateCompanyLicensing) && (
										<Tooltip label={t('Add Shareholder')}>
											<Flex
												className="hidden-print"
												userSelect="none"
												onClick={() => setOpeningInsertDialog(true)}
												cursor="pointer"
												alignItems="center"
												justifyContent="center"
												w="32px"
												h="32px"
												borderRadius={c.borderRadius}
												border="1px solid #dbdbdb"
												boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
											>
												<Text fontSize="26px" mt="2px" fontWeight="500">
													+
												</Text>
											</Flex>
										</Tooltip>
									)}
								</Flex>
							</Flex>
							<Box p="16px">
								<Table variant="simple">
									<Thead>
										<Tr>
											<Th>
												{t('Company Name')}/{t('Individual')}
											</Th>
											<Th>{t('Representative')}</Th>
											<Th>{t('Gender')}</Th>
											<Th>{t('Nationality')}</Th>
											<Th>
												{t('Amount')}
												<Box color="#e67e22">
													(
													{totalKhr ? (
														<span>
															{formatMoney(totalKhr)} {t('KHR')}
														</span>
													) : (
														''
													)}
													{totalUsd ? (
														<span>
															{totalKhr ? (
																<>
																	, <br />
																</>
															) : (
																''
															)}
															{formatMoney(totalUsd)} {t('USD')}
														</span>
													) : (
														''
													)}
													)
												</Box>
											</Th>
											<Th>
												{t('Percentage')} <Text color="#e67e22">({totalPercentage || 0}%)</Text>
											</Th>
											<Th>{t('Approval Date')}</Th>
											{(currentUser?.role === 'admin' || currentUser?.canUpdateCompanyLicensing) && (
												<Th className="hidden-print" isNumeric>
													{t('Actions')}
												</Th>
											)}
										</Tr>
									</Thead>
									<Tbody>
										{loadingCompanyShareholders && <LoadingTable column={8} row={3} />}
										{dataCompanyShareholders?.getCompanyShareholder.length <= 0 && (
											<Tr>
												<Td>
													<Text>{t('No Data')}</Text>
												</Td>
											</Tr>
										)}
										{dataCompanyShareholders?.getCompanyShareholder?.map((item, index) => {
											return (
												<Tr cursor="pointer" _hover={{ bg: 'gray.200' }} key={`item-${index}`} bg="white">
													<Td onClick={() => editCompanyShareHolder(item?.id)}>{item.name}</Td>
													<Td onClick={() => editCompanyShareHolder(item?.id)}>{item.representative}</Td>
													<Td onClick={() => editCompanyShareHolder(item?.id)}>{t(item.gender)}</Td>
													<Td onClick={() => editCompanyShareHolder(item?.id)}>{t(item.nationality)}</Td>
													<Td onClick={() => editCompanyShareHolder(item?.id)}>
														{formatMoney(item.amount)} {item.currency ? `(${t(item.currency)})` : ''}
													</Td>
													<Td onClick={() => editCompanyShareHolder(item?.id)}>
														{item.percentage} {item?.percentage ? '%' : null}
													</Td>
													<Td onClick={() => editCompanyShareHolder(item?.id)}>
														{toDateString(item.approvalDate, c.defaultDateFormat)}
													</Td>
													{(currentUser?.role === 'admin' || currentUser?.canUpdateCompanyLicensing) && (
														<Td className="hidden-print" isNumeric>
															<ActionMenuShareholder id={item?.id} />
														</Td>
													)}
												</Tr>
											);
										})}
									</Tbody>
									<Tfoot>
										<Tr>
											<Th>
												{t('Company Name')}/{t('Individual')}
											</Th>
											<Th>{t('Representative')}</Th>
											<Th>{t('Gender')}</Th>
											<Th>{t('Nationality')}</Th>
											<Th>{t('Amount')}</Th>
											<Th>{t('Percentage')}</Th>
											<Th>{t('Approval Date')}</Th>
											{(currentUser?.role === 'admin' || currentUser?.canUpdateCompanyLicensing) && (
												<Th className="hidden-print" isNumeric>
													{t('Actions')}
												</Th>
											)}
										</Tr>
									</Tfoot>
								</Table>
							</Box>
						</Box>
					</Center>
				</Box>
			</Flex>
			<AlertDialog isOpen={isOpeningDeleteDialog} leastDestructiveRef={deleteDialogRef} onClose={onRejectDeleteDialog}>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							{t('Delete Company Shareholder')}
						</AlertDialogHeader>
						<AlertDialogBody>{t("Are you sure? You can't undo this action afterwards")}</AlertDialogBody>
						<AlertDialogFooter>
							<Button mr={4} isLoading={loadingDeleteCompanyShareholder} ref={deleteDialogCancelRef} onClick={onRejectDeleteDialog}>
								{t('Cancel')}
							</Button>
							<Button isLoading={loadingDeleteCompanyShareholder} colorScheme="red" onClick={onApproveDeleteDialog}>
								{t('Delete')}
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
			<AddCompanyShareholderModal companyId={id} isOpen={isOpeningInsertDialog} onClose={onRejectInsertDialog} callback={onApproveInsertDialog} />
			{isOpeningEditDialog && <EditCompanyShareholderModal id={workingId} isOpen={true} onClose={onRejectEditDialog} callback={onApproveEditDialog} />}
			{dataCompanyShareholders && <ShareholderPrint dataCompanyShareholders={dataCompanyShareholders} />}
		</>
	);
}

export default CompanyShareholder;
