import React from "react";
import c from "../../constant"
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Button,
    Box,
    Flex
} from "@chakra-ui/react"
import { Formik, Form } from "formik";
import * as Yup from "yup";
import TextFormField from "../form/TextFormField";
import DateRangeFormField from "../form/DateRangeFormField";
import CheckBoxFormField from "../form/CheckBoxFormField";
import RadioFormField from "../form/RadioFormField";
import moment from "moment";
import { useTranslation } from "react-i18next";
import 'moment/locale/en-gb';
const filterSchema = Yup.object().shape({
    number: Yup.string(),
    name: Yup.string(),
    status: Yup.string(),
    type: Yup.string(),
    createdDate: Yup.string(),
    unreadOnly: Yup.boolean(),
    showSpam: Yup.boolean()
});

function ComplaintFilter({ isOpen, onClose, callback , 
    number="",
    name="",
    status="",
    type="",
    fromDate=null,
    toDate=null,
    read=null,
    showSpam=null
}) {
    moment.locale("en-gb")
    const { t } = useTranslation();
    let defaultRange = ""
	if(fromDate&&toDate){
        const defaultFrom = JSON.parse(fromDate);

		const defaultTo = JSON.parse(toDate);
		defaultRange = {
			from: defaultFrom,
			to: defaultTo
		}
    }
    return (
        <>

            <Modal borderRadius={c.borderRadius} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent borderRadius={c.borderRadius}>
                    <ModalHeader>{t("Filter")}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Formik
                            initialValues={{
                                number: number?number:"",
                                name: name?name:"",
                                status: status?status:"",
                                type: type?type:"",
                                createdDate: defaultRange?JSON.stringify(defaultRange):"",
                                unreadOnly: read?read:"",
                                showSpam: showSpam?showSpam: false
                            }}
                            validationSchema={filterSchema}
                            onSubmit={(values) => {
                                let url = "?page=1"
                                if(values.number){
                                    url+=`&number=${values.number}`
                                }
                                if(values.name){
                                    url+=`&name=${values.name}`
                                }

                                if(values.status){
                                    url+=`&status=${values.status}`
                                }

                                if(values.type){
                                    url+=`&type=${values.type}`
                                }

                                if(values.createdDate){
                                    const tmpDate = JSON.parse(values.createdDate)
                                    if(tmpDate?.from&&tmpDate?.to){
                                        const dateFrom = JSON.stringify(tmpDate.from)
                                        const dateTo = JSON.stringify(tmpDate.to)
                                        url+=`&fromDate=${dateFrom}&toDate=${dateTo}`
                                    }
                                }
                                if(values.unreadOnly){
                                    url+=`&read=false`
                                }
                                if(values.showSpam){
                                    url+=`&showSpam=true`
                                }
                                callback(url)
                                onClose()
                            }}
                        >
                            {(props) => (
                                <Box pb="4">
                                    <Form>

                                        <TextFormField
                                            {...props}
                                            label="Number"
                                            name="number"

                                        />
                                        <TextFormField
                                            {...props}
                                            label="Plaintiff/Representative Name"
                                            name="name"

                                        />
                                        <center>
                                        <DateRangeFormField
                                            {...props}
                                            label="Created Date"
                                            name="createdDate"
                                            initialDate={{fromDate,toDate}}
                                        />
                                        </center>

                                        <RadioFormField
                                            label="Type"
                                            name="type"
                                            radios={[
                                                { label: "All", value: "all" },
                                                { label: "Complaint", value: "COMPLAINT" },
                                                { label: "Cancel Complaint", value: "CANCELATION_COMPLAINT" }
                                            ]}
                                            initValue={type}
                                        />

                                        <CheckBoxFormField
                                            {...props}
                                            label="Show unread only"
                                            name="unreadOnly"
                                        />
                                        <Box marginTop="4">
                                            <CheckBoxFormField
                                                {...props}
                                                label="Show spam"
                                                name="showSpam"
                                            />
                                        </Box>
                                        <Flex mt="32px" justifyContent="flex-end">
                                            <Button
                                                id="login-btn"
                                                variant="solid"
                                                size="md"
                                                h="42px"
                                                w="128px"
                                                type="submit"
                                                borderRadius={c.borderRadius}
                                                color="#3498db"
                                            >
                                                {t("Apply")}
                                            </Button>
                                            <Button
                                                size="md"
                                                h="42px"
                                                ml={4}
                                                w="128px" onClick={() => {
                                                    callback('?page=1')
                                                    onClose()
                                                }} variant="ghost">{t("Clear")}</Button>
                                        </Flex>
                                    </Form>

                                </Box>
                            )}
                        </Formik>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export default ComplaintFilter