import React, { useState, useEffect } from "react";
import { gql, useLazyQuery } from '@apollo/client';
import useStore from "../store";
import { Spinner, Text } from '@chakra-ui/react'
import getDepartmentLabel from "../utils/getDepartmentLabel"
import { useTranslation } from "react-i18next";

const QUERY_USER = gql`
  query getUserById($id: String!){
    getUserById(
        id: $id
    ){
        id
        username
        fullName
        fullNameKhmer
        department
    }
  }
`
const UserDislayNameById = ({ id }) => {
  const { currentLang } = useStore((state) => state.currentLang);
  const { t } = useTranslation();
  const [loadUser, { error, loading, data }] = useLazyQuery(QUERY_USER)
  useEffect(() => {
    if(id){
    loadUser({
      variables: {
        id
      }
    })}
  }, [])

  function getDisplayName(user) {
    let displayName = user?.username
    if (currentLang === "kh") {
      displayName = user?.fullNameKhmer ? user?.fullNameKhmer : (user?.fullName ? user?.fullName : user?.username)
    } else {
      displayName = user?.fullName ? user?.fullName : (user?.fullNameKhmer ? user?.fullNameKhmer : user?.username)
    }
    return displayName
  }

  return (
    id?<>
      {error && <Text color="red">{t("Error")}</Text>}
      {loading && <Spinner size='xs' />}
      {
        getDisplayName(data?.getUserById)
      }
      {
        data?.getUserById?.department&&<>,&nbsp;{t(getDepartmentLabel(data?.getUserById?.department))}</>
      }
    </>:<>
      {t("Unknown")}
    </>
  );
}

export default UserDislayNameById;