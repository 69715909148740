import { useEffect, useState } from "react"

import {
  Modal,
  ModalOverlay,
  ModalContent,
  Text,
  CircularProgress,
  Flex,
  Box
} from "@chakra-ui/react"
import { useTranslation } from "react-i18next";

export default function UploadingModal({ isOpen, progress }) {
  const { t } = useTranslation('common');
  const [showText, setShowText] = useState(false)
  useEffect(()=>{
    setTimeout(() => {
      setShowText(true)
    }, 100);
    
  },[])
  return (
    <>
      <Modal isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent borderRadius={"2px"}>
          <Flex direction="column" alignItems="center" p="10">
            <CircularProgress isIndeterminate color="blue.300" size="100px" thickness="6px" />
            {showText&&<Box mt="4">
            {(progress && progress < 1) ? <Text>{t("Uploading")} {((progress).toFixed(2) * 100).toFixed(0)}%</Text>:null}
            {(!progress || progress === 1) ? <Text>{t("Processing")}</Text>:null}
            </Box>}
          </Flex>
        </ModalContent>
      </Modal>
    </>
  )
}