/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Container,
	Box,
	Flex,
	Text,
	Center,
	Avatar,
	Wrap,
	WrapItem,
	Button,
	AlertDialog,
	AlertDialogOverlay,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogBody,
	AlertDialogFooter,
	useToast,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	Badge,
	Tooltip,
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	TableContainer,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	Divider,
} from '@chakra-ui/react';
import BreadcrumbComponent from '../../components/BreadcrumbComponent/BreadcrumbComponent';
import { faMinusSquare, faPlusSquare, faPrint, faFileInvoice, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import c from '../../constant';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import moment from 'moment';
import './style.css';
import { useLocation, useHistory } from 'react-router-dom';
import ViewComment from '../../components/Comment/ViewComment';
import useStore from '../../store';
import SelectUserModal from '../../components/Modal/SelectUserModal';
import { CheckIcon, WarningIcon, RepeatIcon, RepeatClockIcon } from '@chakra-ui/icons';
import PrintLayout from '../../components/Print/PrintLayout';
import DoneFileUplaod from '../../components/CompanyProposal/DoneFileUplaod';
import ViewDoneFile from '../../components/CompanyProposal/ViewDoneFile';
import UserDislayNameById from '../../components/UserDislayNameById';
import DownloadAndViewButton from '../../components/Files/DownloadAndViewButton';
import PublicService from '../../components/QUERY/PublicService';
import ChengeProposalFile from '../../components/Modal/ChengeProposalFile';
import StepFileDetail from '../../components/CompanyProposal/StepFileDetail';
import ViewCamDigiUser from '../../components/Modal/ViewCamDigiUser';
import formatMoneyKH from '../../utils/formatMoneyKH';
import RequestAuditLicense from '../../components/CompanyProposal/AdditionalFeature/RequestAuditLicense';
import RequestAuditLicenseRenewal from '../../components/CompanyProposal/AdditionalFeature/RequestAuditLicenseRenewal';
import AssignToDepartment from '../../components/CompanyProposal/AssignToDepartment';
import AssignToOffice from '../../components/CompanyProposal/AssignToOffice';

const leftLabelWidth = '165px';
const QUERY_PROPOSAL_DETAIL = gql`
	query get($id: String!) {
		getCompanySubmissionProposalById(id: $id) {
			id
			companyName
			subject
			publicService
			serviceFee
			serviceFeeFileSize
			file
			fileSize
			createdAt
			read
			assignTo {
				read
				user {
					id
					username
					fullName
					fullNameKhmer
					avatar
					department
					office
				}
				createdAt
				createdBy
			}
			assignedDepartment {
				department
				createdAt
				createdBy
			}
			assignedOffice {
				office
				createdAt
				createdBy
			}
			approved
			approvedAt
			dueDate
			spam
			company {
				id
				name
				nameKhmer
				logo
			}
			no
			done
			doneAt
			createdBy
			selectedStep
			stepFiles
			createdByClientId
			submissionStatus
			paymentData
			startServiceDate
			additionalFeatureData
		}
	}
`;

const QUERY_REMOVE_USER = gql`
	mutation remove($id: String!, $userId: String!) {
		removeAssignedCompanySubmissionProposalFromUser(id: $id, userId: $userId)
	}
`;

const QUERY_ASSIGN_USER = gql`
	mutation assign($id: String!, $userId: String!) {
		assignCompanySubmissionProposalToUser(id: $id, userId: $userId)
	}
`;

const QUERY_APPROVED_PROPOSAL = gql`
	mutation assign($id: String!, $status: Boolean) {
		setApprovedProposalById(id: $id, status: $status)
	}
`;

const MUTATION_AS_DONE = gql`
	mutation done($id: String!, $status: Boolean!) {
		setCompanyProposalAsDone(id: $id, status: $status)
	}
`;

const QUERY_SET_AS_SPAM_PROPOSAL = gql`
	mutation setProposalByIdAsSpam($id: String!) {
		setProposalByIdAsSpam(id: $id)
	}
`;

const QUERY_SET_AS_NOT_SPAM_PROPOSAL = gql`
	mutation setProposalByIdAsNotSpam($id: String!) {
		setProposalByIdAsNotSpam(id: $id)
	}
`;

function ProposalDetail({ auditServiceOnly = false }) {
	const { t } = useTranslation();
	const location = useLocation();
	const history = useHistory();
	const urlParams = new URLSearchParams(location.search);
	const id = urlParams.get('id') ? urlParams.get('id') : null;
	const { currentLang } = useStore((state) => state.currentLang);
	moment.locale(currentLang === 'kh' ? 'km' : 'en-gb');
	const [loadProposalDetail, { loading, data }] = useLazyQuery(QUERY_PROPOSAL_DETAIL);
	const [isOpen, setIsOpen] = useState(false);
	const onClose = () => setIsOpen(false);
	const cancelRef = useRef();
	const [removingUserId, setRemovingUserId] = useState(null);
	const [removeUserMutation, { error: errorRemove, loading: loadingRemove, data: dataRemove }] = useMutation(QUERY_REMOVE_USER);
	const [assignUserMutation, { error: errorAssign, data: dataAssign }] = useMutation(QUERY_ASSIGN_USER);
	const [setApprovedProposal, { error: errorApproved, data: dataApproved }] = useMutation(QUERY_APPROVED_PROPOSAL);
	const [setAsSpamProposal, { error: errorSetAsSpam, data: dataSetAsSpam }] = useMutation(QUERY_SET_AS_SPAM_PROPOSAL);
	const [setAsNotSpamProposal, { error: errorSetAsNotSpam, data: dataSetAsNotSpam }] = useMutation(QUERY_SET_AS_NOT_SPAM_PROPOSAL);
	const [setAsDone, { error: errorSetAsDone, data: dataSetAsDone }] = useMutation(MUTATION_AS_DONE);

	const [showChangeFile, setShowChangeFile] = useState(false);
	const [viewCamdigiUserInfo, setViewCamdigiUserInfo] = useState(false);
	const { currentUser } = useStore((state) => state.currentUser);
	const toast = useToast();
	const [selectUserModalOpen, setSelectUserModalOpen] = useState(false);
	const { isOpen: isOpenPayment, onOpen: onOpenPayment, onClose: onClosePayment } = useDisclosure();

	useEffect(() => {
		window.scrollTo(0, 0);
		loadProposalDetail({
			variables: {
				id,
			},
		});
	}, []);

	useEffect(() => {
		if (id) {
			loadProposalDetail({
				variables: {
					id,
				},
			});
		}
	}, [id]);

	function removeAssignUserDialog(user) {
		setIsOpen(true);
		setRemovingUserId(user);
	}

	useEffect(() => {
		if (dataAssign?.assignCompanySubmissionProposalToUser) {
			toast({
				title: t('User assigned successfully'),
				status: 'success',
				isClosable: true,
				position: 'top-right',
			});
			loadProposalDetail({
				variables: {
					id,
				},
			});
		}
	}, [dataAssign]);

	useEffect(() => {
		if (dataRemove?.removeAssignedCompanySubmissionProposalFromUser) {
			toast({
				title: t('User removed successfully'),
				status: 'success',
				isClosable: true,
				position: 'top-right',
			});
			loadProposalDetail({
				variables: {
					id,
				},
			});
			onClose();
		}
	}, [dataRemove]);

	useEffect(() => {
		if (dataSetAsNotSpam?.setProposalByIdAsNotSpam) {
			toast({
				title: t('Set as not spam successfully'),
				status: 'success',
				isClosable: true,
				position: 'top-right',
			});
			loadProposalDetail({
				variables: {
					id,
				},
			});
		}
	}, [dataSetAsNotSpam]);

	useEffect(() => {
		if (dataSetAsSpam?.setProposalByIdAsSpam) {
			toast({
				title: t('Set as spam successfully'),
				status: 'success',
				isClosable: true,
				position: 'top-right',
			});
			loadProposalDetail({
				variables: {
					id,
				},
			});
		}
	}, [dataSetAsSpam]);

	useEffect(() => {
		if (dataApproved?.setApprovedProposalById) {
			toast({
				title: t(dataApproved?.setApprovedProposalById),
				status: 'success',
				isClosable: true,
				position: 'top-right',
			});
			loadProposalDetail({
				variables: {
					id,
				},
			});
		}
	}, [dataApproved]);

	useEffect(() => {
		if (errorAssign) {
			toast({
				title: t('Assign user error'),
				status: 'error',
				isClosable: true,
				position: 'top-right',
			});
			loadProposalDetail({
				variables: {
					id,
				},
			});
		}
	}, [errorAssign]);

	useEffect(() => {
		if (errorRemove) {
			toast({
				title: t(errorRemove?.graphQLErrors[0]?.message ? errorRemove?.graphQLErrors[0]?.message : 'Remove assigned user error'),
				status: 'error',
				isClosable: true,
				position: 'top-right',
			});
			loadProposalDetail({
				variables: {
					id,
				},
			});
		}
	}, [errorRemove]);

	useEffect(() => {
		if (errorApproved) {
			toast({
				title: t('Approve/reject error'),
				status: 'error',
				isClosable: true,
				position: 'top-right',
			});
			loadProposalDetail({
				variables: {
					id,
				},
			});
		}
	}, [errorApproved]);

	useEffect(() => {
		if (errorSetAsSpam) {
			toast({
				title: t('Set as spam error'),
				status: 'error',
				isClosable: true,
				position: 'top-right',
			});
			loadProposalDetail({
				variables: {
					id,
				},
			});
		}
	}, [errorSetAsSpam]);

	useEffect(() => {
		if (errorSetAsNotSpam) {
			toast({
				title: t('Set as not spam error'),
				status: 'error',
				isClosable: true,
				position: 'top-right',
			});
			loadProposalDetail({
				variables: {
					id,
				},
			});
		}
	}, [errorSetAsNotSpam]);

	function selectAssignUser(userData) {
		assignUserMutation({
			variables: {
				id: id,
				userId: userData.id,
			},
		});
	}

	function approvedProposal(status) {
		setApprovedProposal({
			variables: {
				id,
				status,
			},
		});
	}

	function setSpam() {
		setAsSpamProposal({
			variables: {
				id,
			},
		});
	}

	function setNotSpam() {
		setAsNotSpamProposal({
			variables: {
				id,
			},
		});
	}

	function getStatus({ done, doneDate }) {
		return done === false ? (
			<Badge colorScheme="yellow">{t('In progress')}</Badge>
		) : (
			<Tooltip label={doneDate ? moment(doneDate).format('LLLL') : ''}>
				<Badge colorScheme="green">{t('Done')}</Badge>
			</Tooltip>
		);
	}

	function getDisplayName(user) {
		let result = user?.username;
		if (currentLang === 'kh') {
			if ((result = user?.fullNameKhmer)) {
				result = user?.fullNameKhmer;
			} else {
				if (user?.fullName) {
					result = user?.fullName;
				} else {
					result = user.username;
				}
			}
		} else {
			if ((result = user?.fullName)) {
				result = user?.fullName;
			} else {
				if (user?.fullNameKhmer) {
					result = user?.fullNameKhmer;
				} else {
					result = user.username;
				}
			}
		}

		return result;
	}

	function getDisplayName(user) {
		let displayName = user?.username;
		if (currentLang === 'kh') {
			displayName = user?.fullNameKhmer ? user?.fullNameKhmer : user?.fullName ? user?.fullName : user?.username;
		} else {
			displayName = user?.fullName ? user?.fullName : user?.fullNameKhmer ? user?.fullNameKhmer : user?.username;
		}
		return displayName;
	}

	function getDisplayCompanyName(item) {
		let displayName = item?.companyName;
		if (item?.company?.id) {
			if (currentLang === 'kh') {
				displayName = item?.company?.nameKhmer;
			} else {
				displayName = item?.company?.name;
			}
		}
		return displayName;
	}

	function doneProposal(status) {
		setAsDone({ variables: { id, status } });
	}

	useEffect(() => {
		if (dataSetAsDone) {
			if (dataSetAsDone?.setCompanyProposalAsDone) {
				toast({
					title: t('Set as done successfully'),
					status: 'success',
					isClosable: true,
					position: 'top-right',
				});
				loadProposalDetail({
					variables: {
						id,
					},
				});
			}
		}
	}, [dataSetAsDone]);

	useEffect(() => {
		if (errorSetAsDone) {
			toast({
				title: t('Set as done error'),
				status: 'error',
				isClosable: true,
				position: 'top-right',
			});
			loadProposalDetail({
				variables: {
					id,
				},
			});
		}
	}, [errorSetAsDone]);

	return (
		<PrintLayout>
			<>
				<Container maxW="container.xl" mt="16px" mb="16px">
					<BreadcrumbComponent
						list={[
							{
								name: auditServiceOnly ? 'AUDIT COMPANY' : 'PROPOSAL',
								path: auditServiceOnly ? '/audit-company?page=1' : '/company-submission-proposal?page=1',
							},
							{
								name: 'DETAIL',
								path: '#',
							},
						]}
					/>
					<Center mt="5">
						<Box
							w="100%"
							bg="white"
							borderRadius={c.borderRadius}
							border="1px solid #dbdbdb"
							boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
							pb="32px"
						>
							<Flex
								className="proposal-detail-menu-header"
								justifyContent="space-between"
								alignItems="center"
								bg="#FAFAFA"
								p="16px"
								pt="8px"
								pb="8px"
								mb="16px"
								borderBottom="1px solid #dbdbdb"
							>
								<Flex alignItems="center">
									<Text fontSize="x-large" fontWeight="bold" mr="4" className="menu-title">
										{t('Proposal Detail')}
									</Text>
									{data?.getCompanySubmissionProposalById?.spam === true && <Badge colorScheme="red">{t('Spam')}</Badge>}
								</Flex>

								<Flex className="hidden-print">
									{data?.getCompanySubmissionProposalById?.publicService && (
										<Button
											mr="2"
											onClick={() => onOpenPayment()}
											leftIcon={<FontAwesomeIcon icon={faFileInvoice} style={{ fontSize: 16 }} />}
											colorScheme="gray"
											variant="solid"
											size="sm"
											borderRadius={c.borderRadius}
											border="1px solid #bdc3c7"
										>
											{t('Payment')}
										</Button>
									)}

									<Button
										mr="2"
										onClick={() => window?.print()}
										leftIcon={<FontAwesomeIcon icon={faPrint} style={{ fontSize: 16 }} />}
										colorScheme="gray"
										variant="solid"
										size="sm"
										borderRadius={c.borderRadius}
										border="1px solid #bdc3c7"
									>
										{t('Print')}
									</Button>

									{currentUser?.role === 'admin' && (
										<>
											<Menu>
												<MenuButton cursor="pointer">
													<Button
														onClick={() => window?.print()}
														colorScheme="gray"
														variant="solid"
														size="sm"
														borderRadius={c.borderRadius}
														border="1px solid #bdc3c7"
													>
														<FontAwesomeIcon icon={faEllipsisH} style={{ fontSize: 16 }} />
													</Button>
												</MenuButton>

												<MenuList pt="4" pb="4" pl="3" pr="3" borderRadius={c.borderRadius}>
													{data?.getCompanySubmissionProposalById?.done === false && (
														<MenuItem icon={<CheckIcon />} color="green" onClick={() => doneProposal(true)}>
															{t('Done')}
														</MenuItem>
													)}
													{data?.getCompanySubmissionProposalById?.done === true && (
														<MenuItem icon={<RepeatClockIcon />} color="orange" onClick={() => doneProposal(false)}>
															{t('In progress')}
														</MenuItem>
													)}
													{/* <MenuItem icon={<CloseIcon />} color="red" onClick={() => approvedProposal(false)}>{t("Reject")}</MenuItem> */}
													{/* {data?.getCompanySubmissionProposalById?.approved !== null && <MenuItem icon={<TimeIcon />} color="black" onClick={() => approvedProposal(null)}>{t("In progress")}</MenuItem>} */}

													{data?.getCompanySubmissionProposalById?.spam === false && (
														<MenuItem icon={<WarningIcon />} onClick={() => setSpam()}>
															{t('Spam')}
														</MenuItem>
													)}
													{data?.getCompanySubmissionProposalById?.spam === true && (
														<MenuItem icon={<RepeatIcon />} onClick={() => setNotSpam()}>
															{t('Not Spam')}
														</MenuItem>
													)}
												</MenuList>
											</Menu>
										</>
									)}
								</Flex>
							</Flex>

							{loading && <Text ml="8">{t('Loading')}</Text>}

							{data?.getCompanySubmissionProposalById && (
								<Box p="16px">
									<Flex>
										<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
											{t('Number')}
										</Text>
										<Text ml="4" fontWeight="600">
											{data?.getCompanySubmissionProposalById.no}
										</Text>
									</Flex>

									<Flex mt="2">
										<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
											{t('Company/Institution Name')}
										</Text>
										<Text ml="4">{getDisplayCompanyName(data?.getCompanySubmissionProposalById)}</Text>
									</Flex>

									<Flex mt="2">
										<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
											{t('Subject')}
										</Text>
										<Text ml="4">
											<pre
												style={{
													whiteSpace: 'pre-wrap',
													paddingBottom: 16,
													fontSize: 14,
													fontFamily: 'Rajdhani, Hanuman, sans-serif, Moul !important',
												}}
											>
												{data?.getCompanySubmissionProposalById?.subject}
											</pre>
										</Text>
									</Flex>

									<Flex
										onClick={() => {
											if (data?.getCompanySubmissionProposalById?.createdByClientId) {
												setViewCamdigiUserInfo(true);
											}
										}}
										mt="2"
										cursor={data?.getCompanySubmissionProposalById?.createdByClientId ? 'pointer' : 'default'}
									>
										<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
											{t('Proposal Created At')}
										</Text>
										<Tooltip
											label={
												data?.getCompanySubmissionProposalById?.createdByClientId ? (
													<>{t('Click to view')}</>
												) : (
													<>
														{' '}
														{t('Created By')} <UserDislayNameById id={data?.getCompanySubmissionProposalById?.createdBy} />
													</>
												)
											}
										>
											<Text
												ml="4"
												_hover={{ textDecoration: data?.getCompanySubmissionProposalById?.createdByClientId ? 'underline' : 'unset' }}
											>
												{moment(
													data?.getCompanySubmissionProposalById?.startServiceDate
														? data?.getCompanySubmissionProposalById?.startServiceDate
														: data?.getCompanySubmissionProposalById?.createdAt
												).format('LLLL')}
											</Text>
										</Tooltip>
									</Flex>

									{
										<Flex mt="2">
											<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
												{t('Due Date')}
											</Text>
											<Text ml="4">
												{data?.getCompanySubmissionProposalById?.dueDate
													? moment(data?.getCompanySubmissionProposalById?.dueDate).format(c.dateTextOnlyFormat)
													: 'N/A'}
											</Text>
										</Flex>
									}

									{data?.getCompanySubmissionProposalById?.publicService && (
										<Flex mt="2">
											<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
												{t('Public Service')}
											</Text>
											<Text ml="4">
												<PublicService
													value={data?.getCompanySubmissionProposalById?.publicService}
													step={data?.getCompanySubmissionProposalById?.selectedStep}
												/>
											</Text>
										</Flex>
									)}

									{
										<Flex mt="2">
											<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
												{t('Status')}
											</Text>
											<Text ml="4">
												{getStatus({
													done: data?.getCompanySubmissionProposalById?.done,
													doneDate: data?.getCompanySubmissionProposalById?.doneAt,
												})}
											</Text>
										</Flex>
									}
									{data?.getCompanySubmissionProposalById?.approvedAt && (
										<Flex mt="2">
											<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
												{t(`${data?.getCompanySubmissionProposalById?.approved ? 'Approved' : 'Rejected'} At`)}
											</Text>
											<Text ml="4">{moment(data?.getCompanySubmissionProposalById?.approvedDate).format('LLLL')}</Text>
										</Flex>
									)}

									{data?.getCompanySubmissionProposalById?.serviceFee && (
										<Flex alignItems="center" mt="2">
											<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
												{t('Service Fee')}
											</Text>
											<DownloadAndViewButton
												url={data?.getCompanySubmissionProposalById?.serviceFee}
												size={data?.getCompanySubmissionProposalById?.serviceFeeFileSize}
											/>
										</Flex>
									)}

									{data?.getCompanySubmissionProposalById?.file && (
										<Flex alignItems="center" mt="2">
											<Text
												fontWeight="bold"
												minW={leftLabelWidth}
												w={leftLabelWidth}
												onClick={() => {
													if (currentUser?.role === 'admin') {
														setShowChangeFile(true);
													}
												}}
											>
												{t('File')}
											</Text>
											<DownloadAndViewButton
												url={data?.getCompanySubmissionProposalById?.file}
												size={data?.getCompanySubmissionProposalById?.fileSize}
											/>
										</Flex>
									)}

									{data?.getCompanySubmissionProposalById?.stepFiles?.length > 0 && (
										<StepFileDetail
											id={id}
											data={data?.getCompanySubmissionProposalById?.stepFiles}
											callbackReload={() =>
												loadProposalDetail({
													variables: {
														id,
													},
												})
											}
										/>
									)}

									<AssignToDepartment
										id={id}
										data={data?.getCompanySubmissionProposalById?.assignedDepartment}
										officeData={data?.getCompanySubmissionProposalById?.assignedOffice}
										callBack={() =>
											loadProposalDetail({
												variables: {
													id,
												},
											})
										}
										assignedUserData={data?.getCompanySubmissionProposalById?.assignTo}
									/>

									<AssignToOffice
										id={id}
										data={data?.getCompanySubmissionProposalById?.assignedOffice}
										callBack={() =>
											loadProposalDetail({
												variables: {
													id,
												},
											})
										}
										assignedUserData={data?.getCompanySubmissionProposalById?.assignTo}
									/>

									<Flex alignItems="center" mt="2">
										<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
											{t('Assign to')}:
										</Text>
										<Wrap ml="4">
											{data?.getCompanySubmissionProposalById?.assignTo?.map((aTo, index) => {
												return (
													<Flex direction="column" justifyContent="center" alignItems="center" key={'u-' + index}>
														<Tooltip
															label={
																<>
																	{t('Assign By')}&nbsp;
																	<UserDislayNameById id={aTo?.createdBy} />
																	&nbsp;{', '}&nbsp;
																	{moment(aTo.createdAt).format('LLLL')}
																</>
															}
														>
															<WrapItem className="avatarWraper">
																{(currentUser?.role === 'admin' ||
																	(currentUser?.canAssignUserToCompanyProposal === true &&
																		currentUser.id === aTo.createdBy)) && (
																	<FontAwesomeIcon
																		onClick={() => removeAssignUserDialog(aTo?.user)}
																		className="remove"
																		icon={faMinusSquare}
																		style={{
																			fontSize: 18,
																			display: 'none',
																			position: 'absolute',
																			zIndex: '100',
																			marginLeft: 32,
																			color: 'red',
																			backgroundColor: 'white',
																			padding: 2,
																			cursor: 'pointer',
																		}}
																	/>
																)}
																<Avatar
																	name={getDisplayName(aTo?.user)}
																	src={aTo?.user?.avatar?.icon ? c.baseFilePath + aTo?.user?.avatar?.icon : null}
																/>
															</WrapItem>
														</Tooltip>

														<Text textAlign="center">{getDisplayName(aTo.user)}</Text>
													</Flex>
												);
											})}
											{(currentUser?.role === 'admin' || currentUser?.canAssignUserToCompanyProposal === true) && (
												<Flex>
													<Button
														className="hidden-print"
														onClick={() => setSelectUserModalOpen(true)}
														mt={1}
														leftIcon={<FontAwesomeIcon icon={faPlusSquare} style={{ fontSize: 18 }} />}
														colorScheme="blue"
														variant="outline"
													>
														{t('Add')}
													</Button>
												</Flex>
											)}
										</Wrap>
									</Flex>
									<Flex alignItems="center" mt="2">
										<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
											{t('Done')}:
										</Text>
										{data?.getCompanySubmissionProposalById?.done ? (
											<ViewDoneFile
												id={id}
												oldRecord={data?.getCompanySubmissionProposalById?.approved !== null}
												callback={() => {
													window.scrollTo(0, 0);
													loadProposalDetail({
														variables: {
															id,
														},
													});
												}}
											/>
										) : (
											<>
												{currentUser?.canUploadDoneFileToCompanyProposal || currentUser?.role === 'admin' ? (
													<DoneFileUplaod
														id={id}
														callback={() => {
															window.scrollTo(0, 0);
															loadProposalDetail({
																variables: {
																	id,
																},
															});
														}}
													/>
												) : (
													<Text ml="4">N/A</Text>
												)}
											</>
										)}
									</Flex>
									<>
										{data?.getCompanySubmissionProposalById?.additionalFeatureData && (
											<>
												<Divider mt="2" mb="4" />
												{data?.getCompanySubmissionProposalById?.additionalFeatureData?.type === c.auditServiceNewLicenseType && (
													<RequestAuditLicense
														proposalId={data?.getCompanySubmissionProposalById?.id}
														logo={data?.getCompanySubmissionProposalById?.additionalFeatureData?.logo?.file}
														name={data?.getCompanySubmissionProposalById?.additionalFeatureData?.nameEN}
														nameKhmer={data?.getCompanySubmissionProposalById?.additionalFeatureData?.nameKH}
														address={data?.getCompanySubmissionProposalById?.additionalFeatureData?.address}
														status={data?.getCompanySubmissionProposalById?.additionalFeatureData?.status}
														licenseFile={data?.getCompanySubmissionProposalById?.additionalFeatureData?.licenseFile}
														user={data?.getCompanySubmissionProposalById?.additionalFeatureData?.user}
														process={data?.getCompanySubmissionProposalById?.additionalFeatureData?.process}
														invoice={data?.getCompanySubmissionProposalById?.additionalFeatureData?.invoice}
														note={data?.getCompanySubmissionProposalById?.additionalFeatureData?.note}
														callback={() => loadProposalDetail({ variables: { id } })}
													/>
												)}
												{data?.getCompanySubmissionProposalById?.additionalFeatureData?.type === c.auditServiceRenewLicenseType && (
													<RequestAuditLicenseRenewal
														companyId={data?.getCompanySubmissionProposalById?.additionalFeatureData?.companyId}
														proposalId={data?.getCompanySubmissionProposalById?.id}
														logo={data?.getCompanySubmissionProposalById?.additionalFeatureData?.logo?.file}
														name={data?.getCompanySubmissionProposalById?.additionalFeatureData?.nameEN}
														nameKhmer={data?.getCompanySubmissionProposalById?.additionalFeatureData?.nameKH}
														address={data?.getCompanySubmissionProposalById?.additionalFeatureData?.address}
														status={data?.getCompanySubmissionProposalById?.additionalFeatureData?.status}
														licenseFile={data?.getCompanySubmissionProposalById?.additionalFeatureData?.licenseFile}
														user={data?.getCompanySubmissionProposalById?.additionalFeatureData?.user}
														process={data?.getCompanySubmissionProposalById?.additionalFeatureData?.process}
														invoice={data?.getCompanySubmissionProposalById?.additionalFeatureData?.invoice}
														callback={() => loadProposalDetail({ variables: { id } })}
													/>
												)}
											</>
										)}
									</>
								</Box>
							)}
						</Box>
					</Center>
					{data?.getCompanySubmissionProposalById?.id && (
						<ViewComment id={id} type={auditServiceOnly ? 'COMPANY_AUDIT_PROPOSAL' : 'COMPANY_SUBMISSION_PROPOSAL'} />
					)}
				</Container>

				<AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
					<AlertDialogOverlay>
						<AlertDialogContent>
							<AlertDialogHeader fontSize="lg" fontWeight="bold">
								{t('Remove Assigned User')}
							</AlertDialogHeader>

							<AlertDialogBody>
								{t(`Are you sure you want to remove`)} "{getDisplayName(removingUserId)}"?
							</AlertDialogBody>

							<AlertDialogFooter>
								<Button ref={cancelRef} onClick={onClose}>
									{t('Cancel')}
								</Button>
								<Button
									isLoading={loadingRemove}
									colorScheme="red"
									onClick={() => {
										removeUserMutation({
											variables: {
												id: id,
												userId: removingUserId?.id,
											},
										});
									}}
									ml={3}
								>
									{t('Remove')}
								</Button>
							</AlertDialogFooter>
						</AlertDialogContent>
					</AlertDialogOverlay>
				</AlertDialog>
				{selectUserModalOpen && (
					<SelectUserModal
						role="companySubmissionProposal"
						callback={selectAssignUser}
						isOpen={selectUserModalOpen}
						onClose={() => setSelectUserModalOpen(false)}
						departments={currentUser?.role !== 'admin' ? (currentUser?.position > 1 ? currentUser?.department : null) : null}
						offices={currentUser?.position > 4 ? currentUser?.office : null}
					/>
				)}

				{showChangeFile && (
					<ChengeProposalFile
						id={id}
						onClose={() => setShowChangeFile(false)}
						callback={() => {
							loadProposalDetail({
								variables: {
									id,
								},
							});
						}}
					/>
				)}

				{viewCamdigiUserInfo && (
					<ViewCamDigiUser onClose={() => setViewCamdigiUserInfo(false)} id={data?.getCompanySubmissionProposalById?.createdByClientId} />
				)}

				<Modal size="xl" isOpen={isOpenPayment} onClose={onClosePayment}>
					<ModalOverlay />
					<ModalContent borderRadius={c.borderRadius}>
						<ModalHeader>{t('Payment')}</ModalHeader>
						<ModalCloseButton />
						<ModalBody>
							<TableContainer>
								<Table size="sm">
									<Thead>
										<Tr>
											<Th>{t('Label')}</Th>
											<Th isNumeric>{t('Amount')}(KHR)</Th>
											<Th isNumeric>Trx.ID</Th>
											{/* <Th isNumeric>{t("Reuse")}</Th> */}
										</Tr>
									</Thead>
									<Tbody>
										{data?.getCompanySubmissionProposalById?.paymentData?.map((item, index) => {
											return (
												<Tr>
													<Td maxW="150px">
														<Text>{item?.label}</Text>
													</Td>
													<Td isNumeric>{formatMoneyKH(parseFloat(item?.amount))}</Td>
													<Td isNumeric>
														<Tooltip label={item?.isReuseTr ? t('Reuse transaction') : null}>
															<Text
																cursor={
																	currentUser.role === 'admin' || currentUser?.paymentPublicService ? 'pointer' : 'default'
																}
																_hover={{
																	textDecoration:
																		currentUser.role === 'admin' || currentUser?.paymentPublicService
																			? 'underline'
																			: 'none',
																}}
																onClick={() => {
																	if (currentUser.role === 'admin' || currentUser?.paymentPublicService) {
																		history.push(`/payment?page=1&pageType=public-service&trxId=${item?.tranId}`);
																	}
																}}
																color={item?.isReuseTr ? '#d35400' : ''}
															>
																{item?.tranId}
															</Text>
														</Tooltip>
														{item?.fromProposalId && (
															<Text
																mt="1"
																cursor={'pointer'}
																_hover={{ textDecoration: 'underline' }}
																onClick={() => {
																	if (auditServiceOnly) {
																		history.push(`/audit-company/detail?id=${item?.fromProposalId}`);
																	} else {
																		history.push(`/company-submission-proposal/detail?id=${item?.fromProposalId}`);
																	}
																	onClosePayment();
																}}
															>
																{t('View old proposal')}
															</Text>
														)}
													</Td>
												</Tr>
											);
										})}
									</Tbody>
								</Table>
							</TableContainer>
						</ModalBody>
					</ModalContent>
				</Modal>
			</>
		</PrintLayout>
	);
}

export default ProposalDetail;
