import { Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import c from '../constant';

function LabelDetail({
	label = null,
	titleDetail = null,
	childDetail = null,
	mt = '2px',
	mb = '2px',
	direction = 'column',
	labelWidth = '',
	hoverBgColor = 'gray.100',
}) {
	const { t } = useTranslation();

	return (
		<>
			<Flex _hover={{ bg: hoverBgColor }} flex={1} direction="column" borderRadius={c.borderRadius} mt={mt} mb={mb}>
				<Flex direction={direction}>
					{label && (
						<>
							<Flex w={labelWidth}>
								<Text fontWeight="600" color="#303030">
									{t(label)}
								</Text>
							</Flex>
						</>
					)}
					<Flex direction="column" justifyContent="center" alignItems="flex-start" mt={0}>
						{titleDetail ? (
							<>
								<Text fontWeight="normal" color="#303030">
									{t(titleDetail)}
								</Text>
							</>
						) : (
							<>{childDetail}</>
						)}
					</Flex>
				</Flex>
			</Flex>
		</>
	);
}

export default LabelDetail;
