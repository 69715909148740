import React, {useEffect} from "react";
import {
    Badge,
} from "@chakra-ui/react";
import { gql, useLazyQuery } from '@apollo/client';
const QUERY_COUNT = gql`
query{
  countUnreadLeaveRequest
}
`

function LeaveRequestBadge() {
  const [loadCount, { data: countData }] = useLazyQuery(QUERY_COUNT)
  useEffect(() => {
    loadCount()
  }, [])
  return (
    (parseInt(countData?.countUnreadLeaveRequest))>0?<Badge
      fontSize="x-large"
      zIndex="99"
      top="2px" right="2px"
      position="absolute"
      colorScheme="red"
    >      
      {parseInt(countData?.countUnreadLeaveRequest)}
    </Badge>:null
  );
}

export default LeaveRequestBadge;
