import React, { useEffect, useState } from 'react';
import './App.css';
import i18next from './i18n';
import Routes from './routes';
import { HelmetProvider } from 'react-helmet-async';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { ApolloProvider } from '@apollo/client';
import { applyTheme } from '@cloudscape-design/components/theming';
import { PartnerRequestProvider } from './contexts/partner-request';
import { TerminationRequestProvider } from './contexts/termination-request';
import { PartnerRequestCountProvider } from './contexts/partner-request-count';
import { TerminationRequestCountProvider } from './contexts/termination-request-count';
import { BranchChangeRequestProvider } from './contexts/branch-change-request';
import { BranchChangeRequestCountProvider } from './contexts/branch-change-request-count';
import client from './apollo/client';
import useStore from './store';
import OneSignal from 'react-onesignal';
import c from './constant';
import axios from 'axios';
import MaintenanceMessageComponent from './components/Maintenance/MaintenanceMessage';
import themeCloudscape from './utils/themeCloudscape';

function App() {
	const { reset } = applyTheme({ theme: themeCloudscape });
	const setCurrentLang = useStore((state) => state.setCurrentLang);
	const lang = localStorage.getItem('language');
	const [status, setStatus] = useState(null);
	const [statusDate, setStatusDate] = useState(null);

	useEffect(() => {
		// const lang = "kh";
		if (lang !== null) {
			i18next.changeLanguage(lang);
			setCurrentLang(lang);
		} else {
			i18next.changeLanguage('en');
			setCurrentLang('en');
		}

		OneSignal.init({
			appId: c.onesignalAppId,
			allowLocalhostAsSecureOrigin: true,
		}).then(() => {
			OneSignal.showSlidedownPrompt().then(() => {
				// do other stuff
				console.log('showSlidedownPrompt');
			});
		});
	}, [lang, setCurrentLang]);

	useEffect(() => {
		checkMaintenanceStatus();
		reset();
	}, []);

	function checkMaintenanceStatus() {
		axios.get(c.baseFilePath + `status/status.json?timestamp=${new Date().getTime()}`).then(function (response) {
			setStatus(response?.data?.status);
			setStatusDate(response?.data?.date);
		});
	}

	let defaultFont = 'Hanuman';
	let setFont = localStorage.getItem('font');
	if (setFont) {
		defaultFont = setFont;
	}
	const theme = extendTheme({
		fonts: {
			heading: 'Open Sans',
			body: `Rajdhani, ${defaultFont}, sans-serif, Moul, Bokor`,
		},
		styles: {
			global: {
				th: {
					fontFamily: 'unset !important',
				},
				// styles for the `body`
				body: {
					backgroundColor: '#F0F2F5',
				},
				p: {
					color: '#303030',
				},
				pre: {
					fontFamily: `Rajdhani, ${defaultFont}, sans-serif, Moul, Bokor`,
				},
			},
		},
		colors: {
			blueNavy: {
				500: '#14213D',
			},
			primary: {
				light: '#81c784',
				main: '#4caf50',
				dark: '#388e3c',
			},
			contrast: {
				main: '#1D438A',
			},
			text: {
				gray: 'rgba(117, 117, 117, 1)',
				main: '#303030',
			},
		},
	});

	return (
		<>
			{status === 0 && <MaintenanceMessageComponent date={statusDate} />}
			<React.Suspense fallback={null}>
				<ApolloProvider client={client}>
					<HelmetProvider>
						<ChakraProvider theme={theme}>
							<PartnerRequestCountProvider>
								<TerminationRequestCountProvider>
									<BranchChangeRequestCountProvider>
										<PartnerRequestProvider>
											<TerminationRequestProvider>
												<BranchChangeRequestProvider>
													<Routes />
												</BranchChangeRequestProvider>
											</TerminationRequestProvider>
										</PartnerRequestProvider>
									</BranchChangeRequestCountProvider>
								</TerminationRequestCountProvider>
							</PartnerRequestCountProvider>
						</ChakraProvider>
					</HelmetProvider>
				</ApolloProvider>
			</React.Suspense>
		</>
	);
}

export default App;
