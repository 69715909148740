import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
	Container, Box, Flex, Text, Center, Table,
	Thead,
	Tbody,
	Tfoot,
	Tr,
	Th,
	Td,
	Tooltip,
	Badge,
	Button
} from "@chakra-ui/react";
import BreadcrumbComponent from "../../components/BreadcrumbComponent/BreadcrumbComponent";
import { faPrint, faSlidersH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import c from "../../constant"
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import moment from "moment"
import ReactPaginate from 'react-paginate';
import './style.css'
import { useLocation, useHistory } from "react-router-dom";
import useStore from "../../store";
import LoadingTable from "../../components/Table/Loading";
import { Link } from "react-router-dom";
import { formatAgentCode } from "../../utils/index"
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import UserDislayNameById from "../../components/UserDislayNameById"
import PrintLayout from "../../components/Print/PrintLayout";
import AgentRenewalFilter from "../../components/Filter/AgentRenewalFilter";
import datePickerFromToToDate from '../../utils/datePickerFromToToDate';
import 'moment/locale/en-gb';
import 'moment/locale/km';

const limit = 10
const QUERY_RENEWAL = gql`
query getData(
		$status: String, 
		$offset:Float,
		$toApprovedDate: DateTime,
		$fromApprovedDate: DateTime,
		$toSubmitDate: DateTime,
		$fromSubmitDate: DateTime,
		$name: String,
		$code: String
	){
	geAgentRenewalDisplay(
		pagerInput:{
			limit:${limit}
			offset:$offset
		}
		status:$status
		toApprovedDate: $toApprovedDate,
		fromApprovedDate: $fromApprovedDate,
		toSubmitDate: $toSubmitDate,
		fromSubmitDate: $fromSubmitDate,
		name: $name,
		code: $code
	){
		AgentRenewalDisplay{
			id
			agentId
			createdAt
			isApproved
			approvedAt
			approvedBy
			agent{
				id
				name
				latinName
				code
			}
			agentValidity{
				validFrom
				validTo
				paymentStatus
				paymentStatusChangeAt
				tranId
			}
		}
		totalRows
		totalPages
	}
}
`


function RenewalList() {
	const { t } = useTranslation();
	const location = useLocation();
	const history = useHistory();
	const urlParams = new URLSearchParams(location.search);
	const currentPage = urlParams.get('page') ? urlParams.get('page') : 1;
	const status = urlParams.get('status') ? urlParams.get('status') : 'inprogress';
	const { currentLang } = useStore((state) => state.currentLang);
	const code = urlParams.get('code') ? urlParams.get('code') : null;
    const name = urlParams.get('name') ? urlParams.get('name') : null;
    
	const fromSubmitDate = urlParams.get('fromSubmitDate') ? urlParams.get('fromSubmitDate') : null;
	const toSubmitDate = urlParams.get('toSubmitDate') ? urlParams.get('toSubmitDate') : null;

	const fromApprovedDate = urlParams.get('fromApprovedDate') ? urlParams.get('fromApprovedDate') : null;
	const toApprovedDate = urlParams.get('toApprovedDate') ? urlParams.get('toApprovedDate') : null;

	moment.locale(currentLang === "kh" ? "km" : "en-gb")
	const [loadData, { error, loading, data }] = useLazyQuery(QUERY_RENEWAL)
	const [openFilter, setOpenFilter] = useState(false)

	useEffect(() => {
		window.scrollTo(0, 0);
		loadData({
			variables: {
				status,
				offset: currentPage ? ((currentPage - 1) * limit) : 0,
				toApprovedDate: datePickerFromToToDate(toApprovedDate),
				fromApprovedDate: datePickerFromToToDate(fromApprovedDate),
				toSubmitDate: datePickerFromToToDate(toSubmitDate),
				fromSubmitDate: datePickerFromToToDate(fromSubmitDate),
				name: name,
				code: code
			}
		})
	}, [status, currentPage, code, name, fromSubmitDate, toSubmitDate, fromApprovedDate, toApprovedDate]);

	function handlePageClick(data) {
		let selected = data.selected;
		const page = selected + 1

		let url = `?page=${page}`

		if (status) {
			url += `&status=${status}`
		}

		if (code) {
			url += `&code=${code}`
		}
		if (name) {
			url += `&name=${name}`
		}

		if (fromSubmitDate) {
			url += `&fromSubmitDate=${fromSubmitDate}`
		}
		if (toSubmitDate) {
			url += `&toSubmitDate=${toSubmitDate}`
		}

		if (fromApprovedDate) {
			url += `&fromApprovedDate=${fromApprovedDate}`
		}
		if (toApprovedDate) {
			url += `&toApprovedDate=${toApprovedDate}`
		}

		history.push(`/agent-renewal${url}`)
	}

	function panelTabs({ name, value }) {
		let active = status ? status : "inprogress"

		return (
			<Flex userSelect="none" minW="100px" onClick={() => {
				handleStatusChange(value)
			}} cursor="pointer" mb={"-2px"} _hover={{ boxShadow: `inset 0 -2px 0 0 ${value === active ? "#2980b9" : "#bdc3c7"}` }} boxShadow={value === active ? "inset 0 -2px 0 0 #2980b9" : ""} pt="16px" pb="16px" pl="12px" pr="12px" alignItems="center" justifyContent="center">
				<Text>{t(name)}</Text>
			</Flex>
		)
	}

	function handleStatusChange(statusValue) {
		const page = 1

		let url = `?page=${page}`

		if (statusValue) {
			url += `&status=${statusValue}`
		}
		if (code) {
			url += `&code=${code}`
		}
		if (name) {
			url += `&name=${name}`
		}

		if (fromSubmitDate) {
			url += `&fromSubmitDate=${fromSubmitDate}`
		}
		if (toSubmitDate) {
			url += `&toSubmitDate=${toSubmitDate}`
		}

		if (fromApprovedDate) {
			url += `&fromApprovedDate=${fromApprovedDate}`
		}
		if (toApprovedDate) {
			url += `&toApprovedDate=${toApprovedDate}`
		}
		
		history.push(`/agent-renewal${url}`)
	}

	function callbackFilter(e) {
		let url = e;
		history.push(`/agent-renewal${url}`);
	}

	function getStatus(status, payment) {
		let bgColor = "gray"
		let label = "IN PROGRESS"

		switch (status) {
			case null:
				bgColor = 'yellow'
				label = 'IN PROGRESS'
				break;
			case true: {
				if (payment === "PENDING") {
					bgColor = 'yellow'
					label = 'Payment Pending'
				} else if (payment === "APPROVED") {
					bgColor = 'green'
					label = 'Completed'
				} else if (payment === "EXPIRED") {
					bgColor = 'gray'
					label = 'Payment Expired'
				}
				break;
			}
			case false:
				bgColor = 'red'
				label = 'REJECTED'
				break;
			default:
				break;
		}
		return (
			<Badge colorScheme={bgColor}>
				{t(label)}
			</Badge>
		)
	}

	return (
		<PrintLayout>
			<>
				<Container maxW="container.xl" mt="16px" mb="16px">
					<BreadcrumbComponent
						list={[
							{
								name: "Insurance Agent Registration",
								path: "/agent?page=1",
							},
							{
								name: "Agent Renewal List",
								path: "#",
							},
						]}
					/>
					<Center className="show-only-printing">
                        <Text textAlign="center" color="#62B3ED" fontFamily="Moul" fontSize={18}>បញ្ជីរសុំបន្តសុពលភ្នាក់ងារធានារ៉ាប់រង</Text>
                    </Center>
					<Center mt="5">
						<Box
							w="100%"
							bg="white"
							borderRadius={c.borderRadius}
							border="1px solid #dbdbdb"
							boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
							pb="32px"
						>
							<Flex className="hidden-print" bg="#FAFAFA" p="16px" pt="0" pb="0" mb="16px" alignItems="center" justifyContent="space-between" borderBottom="1px solid #dbdbdb">
								<Flex pb="2px">
									{panelTabs({ name: "In Progress", value: "inprogress" })}
									{panelTabs({ name: "Rejected", value: "rejected" })}
									{panelTabs({ name: "Payment Pending", value: "approve-wait-payment" })}
									{panelTabs({ name: "Payment Expired", value: "approve-payment-expired" })}
									{panelTabs({ name: "Completed", value: "approve-payment-completed" })}
								</Flex>
								<Flex alignItems="center">
									<Button
										mr="2"
										onClick={() => window?.print()}
										leftIcon={<FontAwesomeIcon icon={faPrint} style={{ fontSize: 16 }} />}
										colorScheme='gray'
										variant='solid'
										size="sm"
										borderRadius={c.borderRadius}
										border="1px solid #bdc3c7"
									>
										{t("Print")}
									</Button>
									<Button
										onClick={() => setOpenFilter(true)}
										leftIcon={<FontAwesomeIcon icon={faSlidersH} style={{ fontSize: 16 }} />}
										colorScheme={`${(code || name || fromSubmitDate || toSubmitDate || fromApprovedDate || toApprovedDate) ? "yellow" : "gray"}`}
										variant='solid'
										size="sm"
										borderRadius={c.borderRadius}
										border="1px solid #bdc3c7}"
									>
										{t("Filter")}
									</Button>
								</Flex>
							</Flex>

							<Box p="16px">
								<Table variant="simple">
									{/* <TableCaption>Imperial to metric conversion factors</TableCaption> */}
									<Thead>
										<Tr>
											<Th>{t("Registration number")}</Th>
											<Th>{t("Name")}</Th>
											<Th>{t("Latin name")}</Th>
											<Th>{t("Created At")}</Th>
											<Th>{t(status === "rejected" ? "Rejected At" : "Approved At")}</Th>
											<Th isNumeric>{t("Status")}</Th>
										</Tr>
									</Thead>
									<Tbody>
										{loading && <LoadingTable column={6} />}
										{
											data?.geAgentRenewalDisplay?.AgentRenewalDisplay?.length <= 0 && <Tr>
												<Td><Text>{t("No Data")}</Text></Td>
											</Tr>
										}
										{data?.geAgentRenewalDisplay?.AgentRenewalDisplay?.map((item, index) => {
											return (
												<Tr key={`agent-${index}`}

													cursor="pointer" _hover={{ bg: "gray.200" }} onClick={() => {

														history.push(`/agent/detail?id=${item?.agent.id}`)
													}}
												>
													{/* <Td onClick={() => history.push(`/users/update?id=${user.id}`)} color="#2980b9" fontWeight="500" cursor="pointer" _hover={{ textDecoration: "underline", textUnderlineOffset: 2 }}>{user.username}</Td> */}
													<Td fontWeight={item?.agent?.code ? "bold" : 400} color={item?.agent?.code ? "#3181CE" : "gray.800"}>
														{item?.agent?.code ? formatAgentCode(item?.agent?.code) : "N/A"}
													</Td>


													<Td>{item?.agent?.name}</Td>
													<Td>{item?.agent?.latinName}</Td>
													<Td>{moment(item?.createdAt).format("LLLL")}</Td>
													<Td>
														<Tooltip
															label={
																<>
																	{`${t("By")} `}
																	<UserDislayNameById id={item?.approvedBy} />
																</>
															}
														>
															{moment(item?.approvedAt).format("LLLL")}
														</Tooltip>
													</Td>
													<Td isNumeric>{getStatus(item?.isApproved, item?.agentValidity?.paymentStatus)}</Td>

												</Tr>
											)
										})}
									</Tbody>
									<Tfoot>
										<Tr>
											<Th>{t("Registration number")}</Th>
											<Th>{t("Name")}</Th>
											<Th>{t("Latin name")}</Th>
											<Th>{t("Created At")}</Th>
											<Th>{t(status === "rejected" ? "Rejected At" : "Approved At")}</Th>
											<Th isNumeric>{t("Status")}</Th>
										</Tr>
										<Tr>
											<Th />
											<Th />
											<Th />
											<Th />
											<Th />
											<Th isNumeric>
												{t("Total Rows")}: {data?.geAgentRenewalDisplay?.totalRows}
											</Th>
										</Tr>
									</Tfoot>
								</Table>
								<Flex justifyContent="flex-end" w="100%" mt="4">
									{data?.geAgentRenewalDisplay?.totalPages > 1 && <ReactPaginate
										previousLabel={<ChevronLeftIcon fontSize="20px" />}
										nextLabel={<ChevronRightIcon fontSize="20px" />}
										breakLabel={'...'}
										breakClassName={'break-me'}
										pageCount={data?.geAgentRenewalDisplay?.totalPages}
										marginPagesDisplayed={2}
										pageRangeDisplayed={3}
										onPageChange={handlePageClick}
										containerClassName={'pagination'}
										activeClassName={'active'}
										initialPage={(currentPage - 1) | 0}
									/>}
								</Flex>
							</Box>
						</Box>
					</Center>
				</Container>

				<AgentRenewalFilter
                    callback={callbackFilter}
                    isOpen={openFilter}
                    onClose={() => setOpenFilter(false)}
                    code={code}
                    name={name}
                    fromSubmitDate={fromSubmitDate}
                    toSubmitDate={toSubmitDate}
					fromApprovedDate={fromApprovedDate}
                    toApprovedDate={toApprovedDate}
					status={status}
                />
			</>
		</PrintLayout>
	);
}

export default RenewalList;
