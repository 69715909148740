import React, { useEffect, useState } from "react";
import {
    Flex,
    Text,
    Box,
    Tooltip,
    Progress
} from "@chakra-ui/react";
import FileViewer from "../Modal/FileViewer";
import c from "../../constant";
import { useTranslation } from "react-i18next";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import mime from "mime-types"
import { gql, useLazyQuery } from '@apollo/client';

const QUERY_DATA = gql`
query get($url: String!){
    resolveUserUploadFile(url:$url)
  }
`

function DownloadAndViewButtonUserUpload({ url, size, viewButton = null }) {
    const { t } = useTranslation();
    const [openViewer, setOpenViewer] = useState(false)
    const fileName = url
    const ext = fileName?.substr(fileName?.lastIndexOf('.') + 1);
    const mimeType = mime?.lookup(ext)
    const [loadData, { error, loading, data }] = useLazyQuery(QUERY_DATA)
    const [isDownloading, setIsDownloading] = useState(false)
    const [isViewing, setIsViewing] = useState(false)

    function downloadFile() {
        setIsDownloading(true)
        getSignedFile()
    }

    function viewFile() {
        setIsViewing(true)
        getSignedFile()
    }

    function getSignedFile() {
        loadData({
            variables: {
                url
            }
        })
    }

    useEffect(() => {
        if (data && isDownloading) {
            console.log("data download:", data?.resolveUserUploadFile)
            setIsDownloading(false)
            download(data?.resolveUserUploadFile)
        }
        if (data && isViewing) {
            setOpenViewer(true)
            setIsViewing(false)
        }

    }, [data, isDownloading, isViewing])

    function download(url) {
        window.open(url, '_blank').focus();
    }

    function showViewButton() {
        if (viewButton === null) {
            let show = false
            if (mimeType) {
                if ((mimeType?.includes("pdf")) || (mimeType?.includes("image"))) {
                    show = true
                }
            }
            return show
        } else {
            return viewButton
        }

    }

    function getFileName(file){
        const tmpFile = file.split("/")
        if(tmpFile?.length>0){
            return tmpFile[tmpFile?.length-1]
        }else {
            return ""
        }
    }

    return (
        <>
            {url && <Flex>
                <Text className="show-only-printing">{getFileName(fileName)}</Text>
                <Box className="hidden-print" h="37px" onMouseDown={() => {
                    downloadFile()
                }} minW={"123px"} _hover={{ bg: "#3498db !important" }} cursor="pointer" ml="4" bg="#3498db" p="2" borderRadius={c.borderRadius} borderRightRadius={showViewButton() ? 0 : c.borderRadius}>
                    <Text color="white">
                        {t("Download")} {size && ` ( ${(parseFloat(size / 1048576) < 0.01 && size > 0) ?
                                0.01 :
                                parseFloat(size / 1048576).toFixed(2)}MB ) `}
                    </Text>
                    {loading && <Box>
                        <Progress w="100%" size='xs' isIndeterminate />
                    </Box>}
                </Box>

                {showViewButton() && <Tooltip label={t('View File')}>
                    <Box _hover={{ bg: "#3498db !important" }} className="hidden-print" onMouseDown={() => viewFile()} borderLeft="1px solid #bdc3c7" cursor="pointer" bg="#3498db" p="2" borderRadius={c.borderRadius} borderLeftRadius={0} >
                        <FontAwesomeIcon icon={faEye} style={{ fontSize: 14, color: "white" }} />
                    </Box>
                </Tooltip>}
            </Flex>}

            {(openViewer && data) && <FileViewer onClose={() => setOpenViewer(false)} url={data?.resolveUserUploadFile} />}
        </>
    );
}

export default DownloadAndViewButtonUserUpload;
