import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Box, Flex, Text } from '@chakra-ui/react';
import BreadcrumbComponent from '../../../components/BreadcrumbComponent/BreadcrumbComponent';
import { faRobot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import c from '../../../constant';

function Settings() {
    const { t } = useTranslation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    function menuItem({ item, index }) {
        return (
            <Link key={`menu-${index}`} to={item.path}>
                <Flex
                    h="64px"
                    alignItems="center"
                    bg="white"
                    borderRadius={c.borderRadius}
                    border="1px solid #dbdbdb"
                    boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
                    p="16px"
                    cursor="pointer"
                    mb="4"
                >
                    <FontAwesomeIcon icon={item?.icon} color="#303030" p="2" style={{ fontSize: 20 }} />
                    <Text fontWeight="500" ml="4">
                        {t(item.title)}
                    </Text>
                </Flex>
            </Link>
        );
    }


    return (
        <>
            <Container maxW="container.xl" mt="16px" mb="16px">
                <BreadcrumbComponent
                    list={[
                        {
                            name: 'Settings',
                            path: '/settings',
                        },
                        {
                            name: 'Proposal',
                            path: '#',
                        },
                    ]}
                />
                <Box pb="32px" w="100%" maxW="400px" mt="5">
                    {menu.map((item, index) => menuItem({ item, index }))}
                </Box>
            </Container>
        </>
    );
}

export default Settings;

const menu = [
    {
        title: 'Auto assign to proposal',
        path: '/settings/proposal/auto-assign-to-proposal',
        icon: faRobot,
    },
];
