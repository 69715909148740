/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import {
	Container,
	Box,
	Flex,
	Text,
	Center,
	Button,
	useToast,
	AlertDialog,
	AlertDialogOverlay,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogBody,
	AlertDialogFooter,
	useDisclosure,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BreadcrumbComponent from '../../../../components/BreadcrumbComponent/BreadcrumbComponent';
import LoadingOverlayText from '../../../../components/LoadingOverlay/LoadingOverlayText';
import c from '../../../../constant';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const Q_GET_TYPE_REFERENCE = gql`
	query get($id: String!) {
		getDocumentNumberTypeReferenceById(id: $id) {
			id
			khTitle
			enTitle
			description
			defaultChoice
		}
	}
`;

const M_DELETE_TYPE_REFERENCE = gql`
	mutation delete($id: String!) {
		deleteDocumentNumberTypeReference(id: $id)
	}
`;

function ReferenceTypeDetail() {
	const toast = useToast();
	const history = useHistory();
	const location = useLocation();
	const urlParams = new URLSearchParams(location.search);
	const id = urlParams.get('id') || null;
	const { t } = useTranslation();
	const { isOpen, onClose, onOpen } = useDisclosure();
	const [getTypeReference, { error, data, loading }] = useLazyQuery(Q_GET_TYPE_REFERENCE);
	const [deleteTypeReference, { error: errorDelete, data: dataDelete, loading: deleting }] = useMutation(M_DELETE_TYPE_REFERENCE);

	useEffect(() => {
		const variables = { id };
		getTypeReference({ variables });
	}, []);

	useEffect(() => {
		if (error) {
			if (error?.graphQLErrors?.length > 0) {
				error?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(error?.message ? error?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [error]);

	useEffect(() => {
		if (errorDelete) {
			if (errorDelete?.graphQLErrors?.length > 0) {
				errorDelete?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(errorDelete?.message ? errorDelete?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [errorDelete]);

	useEffect(() => {
		if (dataDelete?.deleteDocumentNumberTypeReference) {
			toast({
				title: t('Document type reference deleted successfully'),
				status: 'success',
				duration: 5000,
				isClosable: true,
				position: 'bottom-right',
			});
			history.push('/type-of-issued-letter?page=reference-type');
		}
	}, [dataDelete]);

	function row(label, value, pb) {
		return (
			<Flex style={{ pageBreakInside: 'avoid' }} pt={1} pb={pb}>
				<Text fontWeight="bold" minW="160px" w="160px">
					{t(label)}
				</Text>
				<Box>
					<Text ml="4" fontWeight="bold">
						{value ? t(value) : ''}
					</Text>
				</Box>
			</Flex>
		);
	}

	return (
		<>
			{loading && <LoadingOverlayText />}
			<Container maxW="container.xl" mt="16px" mb="16px">
				<BreadcrumbComponent
					list={[
						{
							name: 'Settings',
							path: '/settings',
						},
						{
							name: 'TYPE OF ISSUED LETTER',
							path: '/type-of-issued-letter?page=reference-type',
						},
						{
							name: 'Reference Type Detail',
							path: '/type-of-issued-letter/detail-reference-type?id=' + id,
						},
					]}
				/>
				<Center mt="5">
					<Box
						w="100%"
						bg="white"
						borderRadius={c.borderRadius}
						border="1px solid #dbdbdb"
						boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
						pb={4}
					>
						<Flex bg="#FAFAFA" p="16px" pt="8px" pb="8px" mb="16px" justifyContent="space-between" borderBottom="1px solid #dbdbdb">
							<Text fontSize="x-large" fontWeight="bold">
								{t('Reference Type Detail')}
							</Text>
							<Flex justifyContent="flex-end" alignItems="center">
								<Link to={'/type-of-issued-letter/edit-reference-type?id=' + id}>
									<Button
										mr="2"
										leftIcon={<FontAwesomeIcon icon={faEdit} style={{ fontSize: 16 }} />}
										colorScheme="gray"
										variant="solid"
										size="sm"
										borderRadius={c.borderRadius}
										border="1px solid #bdc3c7"
									>
										{t('Edit')}
									</Button>
								</Link>
								<Button
									mr="0"
									leftIcon={<FontAwesomeIcon icon={faTrash} style={{ fontSize: 16 }} />}
									colorScheme="red"
									variant="solid"
									size="sm"
									borderRadius={c.borderRadius}
									border="1px solid #bdc3c7"
									onClick={onOpen}
								>
									{t('Delete')}
								</Button>
							</Flex>
						</Flex>
						<Box p="16px" pt="0" className="leave-detail-container">
							{row('Khmer Title', data?.getDocumentNumberTypeReferenceById?.khTitle, 1)}
							{row('English Title', data?.getDocumentNumberTypeReferenceById?.enTitle, 1)}
							{row('Description', data?.getDocumentNumberTypeReferenceById?.description, 1)}
							{row('Default', data?.getDocumentNumberTypeReferenceById?.defaultChoice ? 'TRUE' : 'FALSE', 1)}
						</Box>
					</Box>
				</Center>
			</Container>
			<AlertDialog isOpen={isOpen} onClose={onClose} closeOnEsc={deleting ? false : true} closeOnOverlayClick={deleting ? false : true}>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							{t('Delete')}
						</AlertDialogHeader>
						<AlertDialogBody>{t("Are you sure? You can't undo this action afterwards")}</AlertDialogBody>
						<AlertDialogFooter>
							<Button mr={4} isLoading={deleting} onClick={onClose}>
								{t('Cancel')}
							</Button>
							<Button
								isLoading={deleting}
								colorScheme="red"
								onClick={() => {
									const variables = { id };
									deleteTypeReference({ variables });
								}}
							>
								{t('Delete')}
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</>
	);
}

export default ReferenceTypeDetail;
