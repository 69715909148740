import React from 'react';
import { AspectRatio, Box, Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import Lottie from 'lottie-react';
import AnimationFile from '../../../../assets/lotties/reviewing.json';

export default function InvoiceInprogress({ label = null }) {
	const { t } = useTranslation();
	return (
		<Flex mt={8} mb={4} justifyContent="center" alignItems="center">
			<Box>
				<AspectRatio maxW="420px" ratio={8 / 7}>
					<Lottie loop={true} animationData={AnimationFile} />
				</AspectRatio>
				<Text fontSize="xl" fontWeight="600" mt={4}>
					{t(label)}
				</Text>
			</Box>
		</Flex>
	);
}
