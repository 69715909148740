import React, { useEffect, useState } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, Spinner, useToast, Center, Button, Box, Text } from '@chakra-ui/react';
import c from '../../constant';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import ReactPaginate from 'react-paginate';
import './style.css';
import { useTranslation } from 'react-i18next';
import getRoleLabel from '../../utils/getRoleLabel';
import DateRangeFormField from '../../components/form/DateRangeFormField';
import ReactExport from 'react-export-excel';

import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import 'moment/locale/en-gb';
import _ from 'lodash';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const limit = 99999;
const QUERY_DATA = gql`
  query getCompanies(
        $offset:Float,
        $companyName: String
        $companyType: InsuranceInstitutionsType
    ){
        getCompanies(
        pagerInput:{
            limit:${limit}
            offset:$offset
        }
        companyName: $companyName
        companyType: $companyType
    ){
      
        companies{
            id
            name
            nameKhmer
            type
            active
            logo
            type
          }
          totalPages
          totalRows
    }
  }
`;

function CompanyExportModal({ isOpen = false, onClose = () => null, callback = () => null, companyType = null }) {
	moment.locale('en-gb');
	const toast = useToast();
	const { t } = useTranslation();
	const [loadCompanies, { error, loading, data }] = useLazyQuery(QUERY_DATA);

	const [allCompanies, setAllCompanies] = useState([]);
	const [loadingData, setLoadingData] = useState(true);

	useEffect(() => {
		loadCompanies({
			variables: {
				offset: 0,
				companyName: null,
				companyType: companyType ? (companyType === 'All' ? null : companyType) : null,
			},
		});
	}, []);

	useEffect(() => {
		if (data?.getCompanies?.companies?.length > 0) {
			const tmpCompany = data.getCompanies?.companies?.filter((arr) => arr.active === true);
			let tmpRes = _.sortBy(tmpCompany, ['name', 'type']);
			setAllCompanies(tmpRes);
			setLoadingData(false);
		}
	}, [data]);

	useEffect(() => {
		if (error) {
			if (error?.graphQLErrors?.length > 0) {
				error?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(error?.message ? error?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [error]);

	function getDataset(type) {
		const tmpData = allCompanies;
		return tmpData.filter((com) => com.type === type);
	}

	return (
		<Modal
			isOpen={isOpen}
			onClose={() => {
				onClose();
			}}
			size="md"
		>
			<ModalOverlay />
			<ModalContent pb="4" borderRadius={c.borderRadius}>
				<ModalHeader>{t('Export company to Excel')}</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Center>
						{loadingData && <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />}
						{!loadingData && allCompanies.length <= 0 && <Text fontWeight={600}>{t('No data')}</Text>}
						{error && (
							<Text color="red" fontWeight={600}>
								{t('Failed to load data')}
							</Text>
						)}
					</Center>
					<Box w="100%" pt="16px" pb="16px">
						{allCompanies.length > 0 && (
							<ExcelFile
								filename={`Companies - Generated At ${moment().format('DD MM YYYY hh mm ss')}`}
								element={
									<Button variant="solid" size="md" h="42px" w="128px" type="submit" borderRadius={c.borderRadius} color="#3498db">
										{t('Download')}
									</Button>
								}
							>
								<ExcelSheet data={allCompanies} name={`All Companies (${allCompanies.length})`}>
									<ExcelColumn label="Name in Latin" value="name" />
									<ExcelColumn label="Name in Khmer" value="nameKhmer" />
									<ExcelColumn label="Type" value="type" />
								</ExcelSheet>

								{insuranceCompanyType.map((item, index) => {
									return (
										<ExcelSheet key={index} data={getDataset(item.value)} name={`${item.label} (${getDataset(item.value).length})`}>
											<ExcelColumn label="Name in Latin" value="name" />
											<ExcelColumn label="Name in Khmer" value="nameKhmer" />
											<ExcelColumn label="Type" value="type" />
										</ExcelSheet>
									);
								})}
							</ExcelFile>
						)}
					</Box>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
}

export default CompanyExportModal;

const insuranceCompanyType = [
	{ label: 'GENERAL', value: 'GENERAL_INSURANCE' },
	{ label: 'LIFE', value: 'LIFE_INSURANCE' },
	{ label: 'MICRO', value: 'MICRO_INSURANCE' },
	{ label: 'RE', value: 'REINSURANCE' },
	{ label: 'BROKER', value: 'INSURANCE_BROKER' },
	{ label: 'AGENT', value: 'INSURANCE_AGENT' },
	{ label: 'ASSOCIATION', value: 'ASSOCIATION' },
	{ label: 'LOSS ADJUSTER', value: 'INSURANCE_LOSS_ADJUSTER' },
	{ label: 'AUDIT', value: 'AUDIT_SERVICE' },
	{ label: 'CLAIM MANAGEMENT', value: 'INSURANCE_CLAIM_MANAGEMENT' },
	{ label: 'ACTUARIAL FIRM', value: 'ACTUARIAL_FIRM' },
];
