import React from "react";
import c from "../../constant"
function Card(props) {
  const { h, minH } = props;
  const styles = {
    container: {
      background: `#FFFFFF 0% 0% no-repeat padding-box`,
      boxShadow: `0px 0px 5px #00000029`,
      borderRadius: c.borderRadius,
      overflow: "visible",
      height: h ? h : null,
      minHeight: minH ? minH : null,
    },
  };

  return (
    <div style={styles.container}>
      {props?.children ? props?.children : null}
    </div>
  );
}

export default Card;
