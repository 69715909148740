/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import {
	Flex,
	Box,
	Center,
	Tooltip,
	Text,
	Table,
	Tbody,
	Thead,
	Tfoot,
	Tr,
	Th,
	Td,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	useToast,
	AlertDialog,
	AlertDialogOverlay,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogBody,
	AlertDialogFooter,
	Button,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { SectionHeader } from '../../Other';
import AddCompanyCeoModal from '../../Modal/AddCompanyCEO';
import EditCompanyCeoModal from '../../Modal/EditCompanyCEOModal';
import DownloadAndViewButton from '../../../components/Files/DownloadAndViewButton';
import c from '../../../constant';
import useStore from '../../../store';
import useMoment from '../../../hooks/useMoment';

const Q_GET_COMPANY_CEOS = gql`
	query GetCompanyCEO($id: String!) {
		getCompanyCEO(companyId: $id) {
			id
			name
			gender
			nationality
			createdAt
			approvalDate
			file
		}
	}
`;

const M_DELETE_COMPANY_CEO = gql`
	mutation DeleteCompanyCEO($id: String!) {
		deleteCompanyCEO(id: $id)
	}
`;

let workingId = null;

function DistributionSystemBroker({ id, ...props }) {
	const deleteDialogRef = useRef();
	const deleteDialogCancelRef = useRef();
	const toast = useToast();
	const { t } = useTranslation();
	const { toDateString } = useMoment();
	const { currentUser } = useStore((state) => state.currentUser);
	const [isOpeningInsertDialog, setOpeningInsertDialog] = useState(false);
	const [isOpeningDeleteDialog, setOpeningDeleteDialog] = useState(false);
	const [isOpeningEditDialog, setOpeningEditDialog] = useState(false);
	const [loadCompanyCeos, { loading: loadingCompanyCeos, error: errorCompanyCeos, data: dataCompanyCeos }] = useLazyQuery(Q_GET_COMPANY_CEOS);
	const [deleteCompanyCeo, { loading: loadingDeleteCompanyCeo, error: errorDeleteCompanyCeo, data: dataDeleteCompanyCeo }] =
		useMutation(M_DELETE_COMPANY_CEO);

	useEffect(() => {
		if (id) {
			const variables = { id };
			loadCompanyCeos({ variables });
		}
	}, [id]);

	useEffect(() => {
		if (errorCompanyCeos) {
			errorCompanyCeos.graphQLErrors.map(({ message }, i) =>
				toast({
					title: t(message),
					status: 'error',
					isClosable: true,
					position: 'top-right',
				})
			);
		}
	}, [errorCompanyCeos]);

	useEffect(() => {
		if (dataDeleteCompanyCeo?.deleteCompanyCEO) {
			toast({
				title: t('Company CEO deleted successfully'),
				status: 'success',
				isClosable: true,
				position: 'top-right',
			});
			const variables = { id };
			loadCompanyCeos({ variables });
			onRejectDeleteDialog();
		}
	}, [dataDeleteCompanyCeo]);

	useEffect(() => {
		if (errorDeleteCompanyCeo) {
			errorDeleteCompanyCeo.graphQLErrors.map(({ message }, i) =>
				toast({
					title: t(message),
					status: 'error',
					isClosable: true,
					position: 'top-right',
				})
			);
		}
	}, [errorDeleteCompanyCeo]);

	function onRejectInsertDialog() {
		setOpeningInsertDialog(false);
	}

	function onApproveInsertDialog() {
		if (id) {
			const variables = { id };
			loadCompanyCeos({ variables });
		}
	}

	function onRejectDeleteDialog() {
		setOpeningDeleteDialog(false);
	}

	function onApproveDeleteDialog() {
		const variables = { id: workingId };
		deleteCompanyCeo({ variables });
	}

	function editCeo(id) {
		if (currentUser?.role === 'admin' || currentUser?.canUpdateCompanyGovernance) {
			workingId = id;
			setOpeningEditDialog(true);
		}
	}

	function onRejectEditDialog() {
		setOpeningEditDialog(false);
	}

	function onApproveEditDialog() {
		const variables = { id };
		loadCompanyCeos({ variables });
	}

	const ActionMenuBoardOfDirector = ({ id }) => {
		return (
			<Menu>
				<Tooltip label={`${t('Delete')}`}>
					<MenuButton cursor="pointer">
						<Flex pl="2" pr="2">
							<FontAwesomeIcon icon={faEllipsisV} style={{ fontSize: 16, marginTop: 4, marginLeft: 6 }} />
						</Flex>
					</MenuButton>
				</Tooltip>
				<MenuList pt="4" pb="4" pl="3" pr="3" borderRadius={c.borderRadius}>
					<MenuItem
						color={'red'}
						onClick={() => {
							workingId = id;
							setOpeningDeleteDialog(true);
						}}
					>
						{t('Delete')}
					</MenuItem>
				</MenuList>
			</Menu>
		);
	};

	return (
		<>
			<Flex mt={6} {...props}>
				<Box className="hidden-print" width="100%">
					<SectionHeader title={t('Broker')} />
					<Center mt={4}>
						<Box
							w="100%"
							bg="white"
							borderRadius={c.borderRadius}
							border="1px solid #dbdbdb"
							boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
							pb={2}
						>
							<Flex p={4} pt={2} pb={2} mb={4} h="48px" bg="#FAFAFA" alignItems="center" justifyContent="end" borderBottom="1px solid #dbdbdb">
								<Flex alignItems="center"></Flex>
							</Flex>

							<Box p={4}>
								<Flex p={4}>
									<Text>{t('No Data')}</Text>
								</Flex>
							</Box>
						</Box>
					</Center>
				</Box>
			</Flex>
			<AlertDialog isOpen={isOpeningDeleteDialog} leastDestructiveRef={deleteDialogRef} onClose={onRejectDeleteDialog}>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							{t('Delete Company CEO')}
						</AlertDialogHeader>
						<AlertDialogBody>{t("Are you sure? You can't undo this action afterwards")}</AlertDialogBody>
						<AlertDialogFooter>
							<Button mr={4} isLoading={loadingDeleteCompanyCeo} ref={deleteDialogCancelRef} onClick={onRejectDeleteDialog}>
								{t('Cancel')}
							</Button>
							<Button isLoading={loadingDeleteCompanyCeo} colorScheme="red" onClick={onApproveDeleteDialog}>
								{t('Delete')}
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
			<AddCompanyCeoModal companyId={id} isOpen={isOpeningInsertDialog} onClose={onRejectInsertDialog} callback={onApproveInsertDialog} />
			{isOpeningEditDialog && <EditCompanyCeoModal id={workingId} isOpen={true} onClose={onRejectEditDialog} callback={onApproveEditDialog} />}
		</>
	);
}

export default DistributionSystemBroker;
