/* eslint-disable react-hooks/exhaustive-deps */
import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Flex, Text, Table, Thead, Tbody, Tfoot, Tr, Th, Td, Image } from '@chakra-ui/react';
import { gql, useLazyQuery } from '@apollo/client';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import PlaceHolderLogo from '../../assets/images/logo-placeholder.png';
import LoadingTable from '../../components/Table/Loading';
import datePickerFromToToDate from '../../utils/datePickerFromToToDate';
import ReactPaginate from 'react-paginate';
import formatMoneyKH from '../../utils/formatMoneyKH';
import useMoment from '../../hooks/useMoment';
import c from '../../constant';
import './style.css';
import useStore from '../../store';

const Q_LICENSE_OR_FINE = gql`
	query GetLicenseOrFine(
		$feeType: String!
		$limit: Float!
		$offset: Float!
		$companyID: String
		$fromDate: DateTime
		$toDate: DateTime
		$fromTransactionDate: DateTime
		$toTransactionDate: DateTime
		$trxId: String
		$deleted: Boolean
	) {
		getLicenseOrFine(
			feeType: $feeType
			pagerInput: { limit: $limit, offset: $offset }
			companyID: $companyID
			fromDate: $fromDate
			toDate: $toDate
			fromTransactionDate: $fromTransactionDate
			toTransactionDate: $toTransactionDate
			trxId: $trxId
			deleted: $deleted
		) {
			feesType {
				id
				number
				createdAt
				amount
				subject
				companyName
				company {
					id
					name
					logo
					nameKhmer
				}
				currency
				invoiceDate
			}
			totalPages
			totalRows
		}
	}
`;

const LicenseOrFineList = forwardRef(
	(
		{
			pageType = null,
			page = null,
			limit = null,
			selectedCompanyId = null,
			fromDate = null,
			toDate = null,
			fromTransactionDate = null,
			toTransactionDate = null,
			trxId = null,
			deleted = false,
			onPageChanged = () => null,
		},
		ref
	) => {
		const history = useHistory();
		const { t } = useTranslation();
		const { toDateString } = useMoment();
		const { currentLang: lang } = useStore((state) => state.currentLang);
		const [loadLicenseOrFine, { loading, data }] = useLazyQuery(Q_LICENSE_OR_FINE);

		useEffect(() => {
			loadLicenseOrFineWithVariables();
		}, [pageType, page, limit, selectedCompanyId, fromDate, toDate, fromTransactionDate, toTransactionDate, trxId, deleted]);

		useImperativeHandle(ref, () => ({
			generateExcel() {
				const tmp = [];
				data?.getLicenseOrFine?.feesType?.forEach((item) => {
					tmp.push({
						number: item?.number,
						createdAt: toDateString(item?.createdAt, c.excelDateTimeFormat),
						transactionDate: toDateString(item.invoiceDate, c.excelDateOnlyFormat),
						amount: item?.amount,
						currency: item?.currency,
						companyName: item?.company?.nameKhmer ? item?.company?.nameKhmer : item?.companyName || 'N/A',
						companyNameLatin: item?.company?.name ? item?.company?.name : item?.companyName || 'N/A',
					});
				});
				return tmp;
			},
		}));

		function loadLicenseOrFineWithVariables() {
			const variables = {
				feeType: pageType,
				offset: page ? (page - 1) * limit : 0,
				limit,
				companyID: selectedCompanyId,
				fromDate: datePickerFromToToDate(fromDate),
				toDate: datePickerFromToToDate(toDate),
				fromTransactionDate: datePickerFromToToDate(fromTransactionDate),
				toTransactionDate: datePickerFromToToDate(toTransactionDate),
				trxId: trxId,
				deleted: false,
			};
			loadLicenseOrFine({ variables });
		}

		return (
			<>
				<Table variant="simple">
					<Thead>
						<Tr>
							<Th>{t('Number')}</Th>
							<Th>{t('Created At')}</Th>
							<Th>{t('Transaction Date')}</Th>
							<Th isNumeric>{t('Amount')}</Th>
							<Th>{t('Subject')}</Th>
							<Th>{t('Company')}</Th>
						</Tr>
					</Thead>
					<Tbody>
						{loading && <LoadingTable column={6} />}
						{data?.getLicenseOrFine?.feesType.length <= 0 && (
							<Tr>
								<Td isNumeric>
									<Text>{t('No Data')}</Text>
								</Td>
							</Tr>
						)}
						{data?.getLicenseOrFine?.feesType?.map((item, index) => {
							return (
								<Tr
									cursor="pointer"
									_hover={{ bg: 'gray.200' }}
									key={`item-${index}`}
									onClick={() => history.push(`/payment/license-or-fine-detail?id=${item.number}`)}
									bg={item?.notification?.read === false ? 'gray.100' : 'white'}
								>
									<Td>{item.number}</Td>
									<Td>{toDateString(item.createdAt, c.longDateFormat)}</Td>
									<Td>{toDateString(item.invoiceDate, c.defaultDateFormat)}</Td>
									<Td onClick={() => history.push(`/payment/license-or-fine-detail?id=${item.number}`)} isNumeric fontWeight="bold">
										<Flex alignContent={'end'} justifyContent={'end'}>
											<Text color="#49BA78">{formatMoneyKH(parseFloat(item.amount))}</Text>
											<Text color="#bdc3c7" ml="1" textTransform={'uppercase'}>
												{item?.currency}
											</Text>
										</Flex>
									</Td>
									<Td>{item.subject}</Td>
									{item?.company !== null ? (
										<Td onClick={() => history.push(`/payment/license-or-fine-detail?id=${item.number}`)}>
											<Flex alignItems="center">
												<Text noOfLines={1}>
													{item?.lastName}
													&nbsp;
													{item?.firstName}
												</Text>
												{item?.company?.logo && (
													<>
														<Image
															w="auto"
															h="24px"
															maxW="194px"
															src={item?.company?.logo ? c.baseFilePath + (item?.company?.logo ? item?.company?.logo : null) : ''}
															objectFit="contain"
															fallbackSrc={PlaceHolderLogo}
															mr="2"
														/>
													</>
												)}
												<Text noOfLines={1}>{lang === 'kh' ? item?.company?.nameKhmer : item?.company.name}</Text>
											</Flex>
										</Td>
									) : (
										<Td>{item?.companyName}</Td>
									)}
								</Tr>
							);
						})}
					</Tbody>
					<Tfoot>
						<Tr>
							<Th>{t('Number')}</Th>
							<Th>{t('Created At')}</Th>
							<Th>{t('Transaction Date')}</Th>
							<Th isNumeric>{t('Amount')}</Th>
							<Th>{t('Subject')}</Th>
							<Th>{t('Company')}</Th>
						</Tr>
						<Tr>
							<Th />
							<Th />
							<Th />
							<Th />
							<Th />
							<Th isNumeric>
								{t('Total Rows')}: {data?.getLicenseOrFine?.totalRows}
							</Th>
						</Tr>
					</Tfoot>
				</Table>
				<Flex justifyContent="flex-end" w="100%" mt="4">
					{data?.getLicenseOrFine?.totalPages > 1 && (
						<ReactPaginate
							previousLabel={<ChevronLeftIcon fontSize="20px" />}
							nextLabel={<ChevronRightIcon fontSize="20px" />}
							breakLabel={'...'}
							breakClassName={'break-me'}
							pageCount={data?.getLicenseOrFine?.totalPages}
							marginPagesDisplayed={2}
							pageRangeDisplayed={3}
							onPageChange={({ selected }) => onPageChanged(selected)}
							containerClassName={'pagination'}
							activeClassName={'active'}
							initialPage={(page - 1) | 0}
							disableInitialCallback={true}
						/>
					)}
				</Flex>
			</>
		);
	}
);

export default LicenseOrFineList;
