/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Box, FormControl, FormLabel, FormHelperText } from '@chakra-ui/react';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import c from '../../constant';

const SelectSearchFormField = ({ name = null, label = null, placeholder = null, required = false, options = [], disabled = false, callback = () => null }) => {
	const { t } = useTranslation();

	const customStyles = {
		menu: (provided) => ({
			...provided,
			borderRadius: c.borderRadius,
			zIndex: 999,
			textAlign: 'left',
		}),
		control: (provided, state) => {
			return {
				...provided,
				borderRadius: c.borderRadius,
				marginBottom: 4,
				height: 46,
				borderColor: state?.selectProps?.isError ? '#FC8181' : '#e2e8f0',
				boxShadow: state?.selectProps?.isError ? '0 0 0 1px #fc8181' : '',
				fontSize: 16,
				paddingLeft: 6,
				textAlign: 'left',
			};
		},
	};

	return (
		<Field name={name}>
			{({ field, meta, form }) => {
				return (
					<Box mb="4">
						<FormControl id={name}>
							<FormLabel>
								{t(label)}
								{required && <span style={{ color: 'red' }}>*</span>}
							</FormLabel>
							<Select
								isDisabled={disabled}
								styles={customStyles}
								placeholder={t(placeholder)}
								isClearable={true}
								isRtl={false}
								isSearchable={true}
								name={name}
								options={options}
								onChange={(e) => {
									form.setFieldValue(name, e);
									callback(e || '');
								}}
								value={field?.value}
								isError={meta.touched && meta.error}
							/>
							{meta.touched && meta.error && (
								<FormHelperText id={`error-message-${name}`} color="red.400" fontSize="12px">
									{t(name)} {t('is')} {t(meta.error)}
								</FormHelperText>
							)}
						</FormControl>
					</Box>
				);
			}}
		</Field>
	);
};

export default SelectSearchFormField;
