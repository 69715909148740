/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import c from '../../../constant';

export default function CardCaption({ status = null, label = null }) {
	const { t } = useTranslation();
	return (
		<Flex
			borderBottomWidth="2px"
			borderBottomStyle="dotted"
			borderBottomColor={status ? (status === c.approved ? '#38A169' : status === c.rejected ? '#E53E3E' : '#718096') : '#718096'}
			alignItem="center"
		>
			<Flex mr="4px" mb="2px" w="16px" h="16px" alignSelf="center" justifyContent="center" alignItems="center" borderRadius="50%" bg="#ecf0f1">
				{status === c.approved && <FontAwesomeIcon icon={faCheckCircle} style={{ color: '#38A169' }} />}
				{status === c.rejected && <FontAwesomeIcon icon={faExclamationCircle} style={{ color: '#E53E3E' }} />}
				{(status === c.waiting || status === null) && <FontAwesomeIcon icon={faClock} style={{ color: '#718096' }} />}
			</Flex>
			<Text fontSize="lg" fontWeight={700}>
				{t(label)}
			</Text>
		</Flex>
	);
}
