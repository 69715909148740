import React, { useEffect, useState } from "react";
import {
    Box, Flex, Modal, Image,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton, Text, Center
} from "@chakra-ui/react";
import c from "../../constant"
import { fetchFile } from "../../utils/axiosFileFetchPrivateS3"
import Lottie from "lottie-react";
import AnimationFile from "../../assets/lotties/68784-transfer-cloud.json";
import useStore from "../../store";

function FileViewer({
    isOpen = true, onClose = () => null, callback = () => null, url
}) {
    const [fetchingFile, setFetchingFile] = useState(true)
    const [file, setFile] = useState(null)
    const [mimeType, setMimeType] = useState(null)

    const fileViewerLoadedPercentage = useStore((state) => state.fileViewerLoadedPercentage);

    useEffect(() => {
        initFILE()
    }, []);

    async function initFILE() {
        const file = await fetchFile(url)
        setFile(file?.fileURL)
        setMimeType(file?.mimeType)
        setTimeout(() => {
            if(file){
                setFetchingFile(false)
                if(getMobileOperatingSystem()!=="unknown" && file?.mimeType?.includes("pdf")){
                    onClose()
                    window.open(file?.fileURL)
                }
            }
        }, 300);
    }

    function getMobileOperatingSystem() {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            return "Windows Phone";
        }
        if (/android/i.test(userAgent)) {
            return "Android";
        }
        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "iOS";
        }
        return "unknown";
    }

    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={() => {
                    onClose()
                }}
                size="6xl"
                scrollBehavior="outside"

            >
                <ModalOverlay />
                <ModalContent borderRadius={c.borderRadius} pt="2">
                    <ModalHeader></ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pl={0} pr={0} pb={0} minH="80vh">

                        {
                            fetchingFile &&
                            <Box>
                                <Flex w="100%" flex={1} alignItems="center" justifyContent="center" h="200px">
                                    <Lottie loop={true} animationData={AnimationFile} />
                                </Flex>
                                <Text textAlign="center" fontSize="4xl" fontWeight="600">{(fileViewerLoadedPercentage && fileViewerLoadedPercentage < 100) ? `${fileViewerLoadedPercentage}%` : ""}</Text>
                            </Box>
                        }

                        {(file && !fetchingFile) &&
                            <>
                                {mimeType?.includes("pdf") && <iframe className="file-viewer-iframe" src={file} style={{ width: "100%", height: "80vh" }} frameBorder="0"></iframe>}
                                {mimeType?.includes("image") &&
                                    <Center>
                                        <Image src={file} maxW="100%" h="auto" />
                                    </Center>
                                }
                            </>
                        }

                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
}

export default FileViewer;
