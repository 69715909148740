import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Box, Flex, Button, useToast, Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
} from "@chakra-ui/react";
import c from "../../constant"
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { gql, useMutation } from '@apollo/client';
import FileFormField from "../form/FileFormField"

import DateFormField from "../form/DateFormField"
import TextAreaFormField from "../../components/form/TextAreaFormField"
import RadioFormField from "../../components/form/RadioFormField"

import SelectSearchFormField from "../form/SelectSearchFormField";
import SelectFormField from "../form/SelectFormField";

const MUTATION = gql`
mutation op(
        $id: String!,
        $endAt: String!, 
        $description: String,
        $type: String!,
        $endFile: Upload
        $endFileSize: Float
    ){
        deactivateAgentCompany(
            id:$id,
            endAt:$endAt,
            description:$description,
            type:$type,
            endFile: $endFile
            endFileSize: $endFileSize
        )
    }
`

const complaintOnInsuranceSchema = Yup.object().shape({
    deactivateDescription: Yup.string(),
    endAt: Yup.string().nullable().required("required"),
    type: Yup.string(),
    endFile: Yup.mixed().test("fileSize", "over limit", (value) => {
        if (value) {
            return value?.size <= c.maxFileSize/10
        } else {
            return true
        }
    }),
});

function DeactivateAgentCompany({
    isOpen = false, onClose = () => null, callback = () => null, id
}) {
    const toast = useToast()
    const { t } = useTranslation();

    const [showCompanyPicker, setShowCompanyPicker] = useState(false)
    const [selectedCompany, setSelectedCompany] = useState(null)
    const [selectedType, setSelectedType] = useState(null)
    const [deactivateAgentCompanyMutation, { loading, error, data }] = useMutation(
        MUTATION
    );


    useEffect(() => {
        if (data) {
            if (data?.deactivateAgentCompany) {
                toast({
                    title: t("Agent company deactivated successfully"),
                    status: "success",
                    isClosable: true,
                    position: "top-right"
                })
                onClose()
                callback("Agent company deactivate successfully")
            }
        }
    }, [data])

    useEffect(() => {
        if (error) {
            error.graphQLErrors.map(({ message }, i) => (
                toast({
                    title: t(message),
                    status: "error",
                    isClosable: true,
                    position: "top-right"
                })
            ))
        }
    }, [error])

    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={() => {
                    onClose()
                }}
                size="md"
                scrollBehavior="outside"
                isCentered
            >
                <ModalOverlay />
                <ModalContent borderRadius={c.borderRadius} pb="2">
                    <ModalHeader>{t("Deactivate Agent Company")}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Formik
                            initialValues={{
                                deactivateDescription: "",
                                publicService: "",
                                endAt:null,
                                endFile: null,
                                type:""
                            }}
                            validationSchema={complaintOnInsuranceSchema}
                            onSubmit={(values) => {
                                deactivateAgentCompanyMutation({
                                    variables: {
                                        id,
                                        endAt: values.endAt,
                                        type: values.type,
                                        description: values.deactivateDescription,
                                        endFile: values.endFile,
                                        endFileSize: values?.endFile?.size,
                                    }
                                })
                            }}
                        >
                            {(props) => (
                                <Box>
                                    <Form>
                                        <RadioFormField
                                            label="Type"
                                            name="type"
                                            radios={deactivateType}
                                            callbackValueChange={e=>setSelectedType(e)}
                                        />

                                        {selectedType==="1"&&<TextAreaFormField
                                            label="Description"
                                            name="deactivateDescription"
                                        />}

                                        <DateFormField
                                            name="endAt"
                                            label="End At"
                                            required
                                        />

                                    <FileFormField
                                            {...props}
                                            label="Attachment"
                                            name="endFile"
                                            maxFileSize={c.maxFileSize/10}
                                        />
                                        <Button
                                            id="login-btn"
                                            variant="solid"
                                            size="md"
                                            h="42px"
                                            w="128px"
                                            type="submit"
                                            borderRadius={c.borderRadius}
                                            isLoading={loading}
                                            float="right"
                                        >
                                            {t("Deactivate")}
                                        </Button>
                                    </Form>
                                </Box>
                            )}
                        </Formik>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
}

export default DeactivateAgentCompany;


const deactivateType = [
    {value:"6", label:"បោះបង់ការចុះកិច្ចសន្យា"},
    {value:"5", label:"បញ្ចប់កិច្ចសន្យា"},
    {value:"4", label:"ផ្លាស់ប្តូរសាខ"},
    {value:"3", label:"លាឈប់"},
    {value:"2", label:"ត្រូវបានបញ្ឈប់"},
    {value:"1", label:"ផ្សេងៗ"},
]