/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Box, Text, Center, Table, Tbody, Tr, Td, Badge, Tooltip, Flex } from '@chakra-ui/react';
import c from '../../../constant';
import '../../../containers/CompanySubmissionReport/style.css';
import useStore from '../../../store';
import useMoment from '../../../hooks/useMoment';

function ReportInlinePrint({ data, ...props }) {
	const { t } = useTranslation();
	const { toDateString } = useMoment();
	const { currentLang } = useStore((state) => state.currentLang);
	const { currentUser } = useStore((state) => state.currentUser);

	function getRowColor(item) {
		let read = false;
		if (currentUser?.role === 'admin') {
			read = item?.read;
		} else {
			item?.assignTo.forEach((assignee) => {
				if (assignee.user?.id === currentUser?.id) {
					read = assignee?.read;
				}
			});
		}
		return read ? 'white' : 'gray.100';
	}

	function getCompanyName(item) {
		let displayName = item?.companyName;
		if (item?.company?.id) {
			if (currentLang === 'kh') {
				displayName = item?.company?.nameKhmer;
			} else {
				displayName = item?.company?.name;
			}
		}
		return displayName;
	}

	function getCreatedDate(input, fallback, format, locale = null) {
		if (input) {
			return toDateString(input, format, locale);
		} else if (fallback) {
			return toDateString(fallback, format, locale);
		} else {
			return 'N/A';
		}
	}

	function getDueDate(input, format, locale = null) {
		if (input) {
			return toDateString(input, format, locale);
		} else {
			return 'N/A';
		}
	}

	function getType(item) {
		return (
			<>
				{item?.publicService ? <Badge colorScheme="green">{t('Public Service')}</Badge> : <Badge colorScheme="yellow">{t('Other')}</Badge>}
				{item.spam === true && (
					<Badge ml={2} colorScheme="red">
						{t('Spam')}
					</Badge>
				)}
			</>
		);
	}

	function getStatus({ done, doneDate }) {
		if (done) {
			return (
				<Tooltip label={toDateString(doneDate, c.longDateFormat)}>
					<Badge colorScheme="green">{t('Done')}</Badge>
				</Tooltip>
			);
		} else {
			return <Badge colorScheme="yellow">{t('In progress')}</Badge>;
		}
	}

	function getSubmissionStatus(item) {
		switch (item?.submissionStatus) {
			case '1':
				return getStatus({ done: item?.done, doneDate: item?.doneAt });
			case '2':
				return <Badge colorScheme="purple">{t('PENDING PAYMENT')}</Badge>;
			case '3':
				return <Badge colorScheme="red">{t('CANCEL')}</Badge>;
			default:
				return null;
		}
	}

	return (
		<>
			<Container className="show-only-printing" maxW="container.xl" mt="16px" mb="16px" {...props}>
				<Center>
					<Text textAlign="center" color="#62B3ED" fontFamily="Moul" fontSize={18}>
						{t('Proposal Report')}
					</Text>
				</Center>
				<Center mt="5">
					<Box
						w="100%"
						bg="white"
						borderRadius={c.borderRadius}
						border="1px solid #dbdbdb"
						boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
						pb="32px"
					>
						<Box p="16px">
							<Table variant="simple" className="table-company-proposal-list">
								<Tbody>
									<Tr className="td-as-th">
										<Td>{t('Number')}</Td>
										<Td>{t('Company/Institution Name')}</Td>
										<Td>{t('Subject')}</Td>
										<Td>{t('Proposal Created At')}</Td>
										<Td>{t('Due Date')}</Td>
										<Td>{t('Type')}</Td>
										<Td isNumeric>{t('Status')}</Td>
									</Tr>
									{data?.getCompanySubmissionProposalsByCompanyId?.companySubmissionProposals.length <= 0 && (
										<Tr>
											<Td>
												<Text>{t('No Data')}</Text>
											</Td>
										</Tr>
									)}
									{data?.getCompanySubmissionProposalsByCompanyId?.companySubmissionProposals?.map((item, index) => {
										return (
											<Tr cursor="pointer" _hover={{ bg: 'gray.200' }} key={`item-${index}`} bg={getRowColor(item)}>
												<Td _hover={{ bg: 'rgba(52, 152, 219,.1)' }} cursor="zoom-in">
													{item?.no}
												</Td>
												<Td fontWeight="500">
													<Text maxW="400px" noOfLines={4}>
														{getCompanyName(item)}
													</Text>
												</Td>
												<Td fontWeight="500">
													<Text maxW="400px" noOfLines={4}>
														{item.subject}
													</Text>
												</Td>
												<Td>{getCreatedDate(item?.startServiceDate, item?.createdAt, c.longDateFormat)}</Td>
												<Td>{getDueDate(item?.dueDate, c.dateTextOnlyFormat)}</Td>
												<Td>
													<Flex>{getType(item)}</Flex>
												</Td>
												<Td isNumeric>
													<Box>{getSubmissionStatus(item)}</Box>
												</Td>
											</Tr>
										);
									})}
								</Tbody>
							</Table>
							<Box justifyContent="flex-end">
								<Text ml="2" mt="4">
									{t('Total Rows')}: <b>{data?.getCompanySubmissionProposalsByCompanyId?.totalRows}</b>
								</Text>
							</Box>
						</Box>
					</Box>
				</Center>
			</Container>
		</>
	);
}

export default ReportInlinePrint;
