import React, { useEffect } from "react";
import {
    Flex, Tooltip, Text, useToast, Box, AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay, Button
} from "@chakra-ui/react";
import {
    faMinusSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon, } from "@fortawesome/react-fontawesome";
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useTranslation } from "react-i18next";
import c from "../../constant";
import useStore from "../../store";
import moment from "moment";
import './style.css'
import DoneFileUplaod from "./DoneFileUplaod";
import DownloadAndViewButton from "../Files/DownloadAndViewButton"

const QUERY_DONE_PROPOSAL = gql`
query get($id: String!){
    getCompanySubmissionDoneProposalById(id:$id){
      id
      attachmentFile
      createdAt
      user{
        username
        fullName
        fullNameKhmer
      }
    }
  }
`

const MUTATION_REMOVE_DONE = gql`
    mutation rm($id: String!){
        removeProposalDoneFileById(id:$id)
    }
`

export default function ViewDoneFile({ id, callback, oldRecord }) {
    const { t } = useTranslation();
    const toast = useToast()
    const [loadDoneProposal, { error, loading, data }] = useLazyQuery(QUERY_DONE_PROPOSAL)
    const [removeDone, { error: errorRemoveDone, loading: loadingRemoveDone, data: dataRemoveDone }] = useMutation(MUTATION_REMOVE_DONE)
    const { currentLang } = useStore((state) => state.currentLang);
    const [isOpen, setIsOpen] = React.useState(false)
    const onClose = () => setIsOpen(false)
    const cancelRef = React.useRef()
    const { currentUser } = useStore((state) => state.currentUser);

    useEffect(() => {
        if (dataRemoveDone?.removeProposalDoneFileById) {
            toast({
                title: t("Done file removed successfully"),
                status: "success",
                isClosable: true,
                position: "top-right"
            })
            callback()
        }
    }, [dataRemoveDone])

    useEffect(() => {
        if (errorRemoveDone) {
            errorRemoveDone.graphQLErrors.map(({ message }, i) => (
                toast({
                    title: t(message),
                    status: "error",
                    isClosable: true,
                    position: "top-right"
                })
            ))
        }
    }, [errorRemoveDone])
    useEffect(() => {
        if (id) {
            loadDoneProposal({
                variables: {
                    id
                }
            })
        }
    }, [])

    function getDisplayName(user) {
        let displayName = user?.username
        if (currentLang === "kh") {
            displayName = user?.fullNameKhmer ? user?.fullNameKhmer : (user?.fullName ? user?.fullName : user?.username)
        } else {
            displayName = user?.fullName ? user?.fullName : (user?.fullNameKhmer ? user?.fullNameKhmer : user?.username)
        }
        return displayName
    }

    return (
        <>
            {loading && <Text ml="4">{t("Loading")}...</Text>}
            {error && <Text color="red" ml="4">{t("Error")}</Text>}
            {oldRecord && (!data?.getCompanySubmissionDoneProposalById?.id) && <>{(currentUser?.canUploadDoneFileToCompanyProposal||currentUser.role==="admin")?<DoneFileUplaod id={id} oldRecord={data?.getCompanySubmissionProposalById?.approved !== null} callback={() => {
                callback()
            }} />:<Text className="" ml="4">N/A</Text>}</>}
            {
                (data === null) && <Text className="" ml="4">N/A</Text>
            }
            {(data?.getCompanySubmissionDoneProposalById?.id) && <Flex>
                <Tooltip label={`${t("Upload By")} ${getDisplayName(data?.getCompanySubmissionDoneProposalById?.user)} ${t("at")} ${moment(data?.getCompanySubmissionDoneProposalById?.createdAt).format('LLL')}`}>
                    <Flex className="viewContainer" alignItems="center" mt="2">
                        <DownloadAndViewButton url={data?.getCompanySubmissionDoneProposalById?.attachmentFile?.file} size={data?.getCompanySubmissionDoneProposalById?.attachmentFile?.fileSize} />
                        {currentUser?.role === "admin" && <FontAwesomeIcon
                            onClick={() => setIsOpen(true)}
                            className="remove"
                            icon={faMinusSquare}
                            style={{
                                fontSize: 18,
                                display: "none",
                                position: "absolute",
                                zIndex: "100",
                                marginLeft: 122,
                                marginBottom: 30,
                                color: "red",
                                backgroundColor: "white",
                                padding: 2,
                                cursor: "pointer"
                            }} />}
                    </Flex>
                </Tooltip>
            </Flex>}

            <>
                <AlertDialog
                    isOpen={isOpen}
                    leastDestructiveRef={cancelRef}
                    onClose={onClose}
                >
                    <AlertDialogOverlay>
                        <AlertDialogContent>
                            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                                {t("Delete done file")}
                            </AlertDialogHeader>

                            <AlertDialogBody>
                                {t("Are you sure? You can't undo this action afterwards")}
                            </AlertDialogBody>

                            <AlertDialogFooter>
                                <Button ref={cancelRef} disabled={loadingRemoveDone} onClick={onClose}>
                                    {t("Cancel")}
                                </Button>
                                <Button isLoading={loadingRemoveDone} colorScheme='red' onClick={() => {
                                    removeDone({ variables: { id } })
                                }} ml={3}>
                                    {t("Delete")}
                                </Button>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                    </AlertDialogOverlay>
                </AlertDialog>
            </>
        </>
    );
}
