import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Container,
	Box,
	Flex,
	Text,
	Center,
	SkeletonText,
	useToast,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	useDisclosure,
	Button,
	SimpleGrid,
} from '@chakra-ui/react';
import BreadcrumbComponent from '../../../components/BreadcrumbComponent/BreadcrumbComponent';
import { DragHandleIcon } from '@chakra-ui/icons';
import DownloadAndViewButton from '../../../components/Files/DownloadAndViewButton';
import c from '../../../constant';
import './style.css';
import { useLocation, useHistory } from 'react-router-dom';
import PrintLayout from '../../../components/Print/PrintLayout';
import AddPublicService from '../../../components/Modal/AddPublicService';
import { SortableContainer, SortableElement, sortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import LoadingOverlay from '../../../components/LoadingOverlay/LoadingOverlay';
import EditPublicService from '../../../components/Modal/EditPublicService';
import TableForm from '../../../components/Table/TableFormCustomSalary';

const CREATE_PRODUCT = gql`
	mutation create($type: String, $data: String) {
		createOtherSetting(type: $type, data: $data)
	}
`;

const QUERY_DATA = gql`
	query get($type: String!) {
		getOtherSettingByType(type: $type) {
			id
			type
			active
			data
		}
	}
`;

function Report() {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { t } = useTranslation();
	const location = useLocation();
	const history = useHistory();
	const urlParams = new URLSearchParams(location.search);
	const toast = useToast();
	const status = urlParams.get('status') ? urlParams.get('status') : null;

	const [createData, { error, loading, data }] = useMutation(CREATE_PRODUCT);

	const [listItem, setListItem] = useState();

	function save(data) {
		console.log('data:', data);
		createData({
			variables: {
				type: 'salary',
				data: JSON.stringify(data?.data),
			},
		});
	}

	const [loadData, { error: formSettingError, loading: formSettingLoading, data: formSettingData }] = useLazyQuery(QUERY_DATA);

	console.log('formSettingData:', formSettingData);
	useEffect(() => {
		loadData({
			variables: {
				type: 'salary',
			},
		});
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if (data) {
			console.log('data:', data);
			if (data.createOtherSetting) {
				toast({
					title: t('setting updated successfully'),
					status: 'success',
					duration: 5000,
					isClosable: true,
					position: 'bottom-right',
				});
				loadData({
					variables: {
						type: 'salary',
					},
				});
			}
		}
	}, [data]);

	return (
		<PrintLayout>
			<>
				<Container maxW="container.xl" mt="16px" mb="16px">
					<BreadcrumbComponent
						list={[
							{
								name: t('OFFICIAL PROFILE'),
								path: '/employee/list?page=1',
							},
							{
								name: t('Salary'),
								path: '/employee/list?page=1',
							},
							{
								name: t('Salary Setting'),
								path: '#',
							},
						]}
					/>
					<Center className="show-only-printing">
						<Text textAlign="center" color="#62B3ED" fontFamily="Moul" fontSize={18}>
							របាយការណ៍សុំច្បាប់
						</Text>
					</Center>
					<Center mt="5">
						<Box
							w="100%"
							bg="white"
							borderRadius={c.borderRadius}
							border="1px solid #dbdbdb"
							boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
							pb="32px"
						>
							<Flex
								alignItems="center"
								justifyContent="space-between"
								bg="#FAFAFA"
								p="16px"
								pt="8px"
								pb="8px"
								mb="16px"
								borderBottom="1px solid #dbdbdb"
							>
								<Flex justifyContent="center" alignItems="center">
									<Text fontSize="x-large" fontWeight="bold" mr="4">
										{t('Salary Form Setting')}
									</Text>
								</Flex>
							</Flex>

							<Box p="4">
								{formSettingData?.getOtherSettingByType?.active && (
									<SimpleGrid>
										<TableForm
											name="salarySetting"
											label={
												<Text fontSize="lg" pl="26px" fontWeight="bold" mt="4" mb="4" color="#2980b9">
													Table Setting
												</Text>
											}
											labelKh={
												<Text fontSize="lg" pl="26px" fontWeight="bold" mt="4" mb="4" color="#2980b9">
													ការកំណត់តារាង
												</Text>
											}
											columns={[
												{ name: 'id', label: 'id', labelKh: 'id', type: 'text', hide: true },
												{ name: 'no', label: 'ល.រ', labelKh: 'ល.រ', type: 'text', hide: true },
												{
													name: 'type',
													label: 'Type',
													labelKh: 'Type',
													type: 'radio',
													options: [
														{ value: 'header', label: 'Header' },
														{ value: 'subHeader', label: 'Sub-Header' },
														{ value: 'employee', label: 'Employee' },
													],
												},
												{
													name: 'title',
													label: 'ងារ',
													labelKh: 'ងារ',
													type: 'radio',
													options: [
														{ value: 'ឯកឧត្តម', label: 'ឯកឧត្តម' },
														{ value: 'លោក', label: 'លោក' },
														{ value: 'លោកស្រី', label: 'លោកស្រី' },
													],
												},
												{ name: 'data', label: 'Data', labelKh: 'Data', type: 'text' },
											]}
											orderBy={{
												field: 'no',
												type: 'asc',
											}}
											value={formSettingData?.getOtherSettingByType?.data?.length > 0 ? formSettingData?.getOtherSettingByType?.data : []}
											onSave={(e) => save({ tableName: 'salarySetting', data: e })}
										/>
									</SimpleGrid>
								)}
							</Box>
						</Box>
					</Center>
				</Container>
			</>
		</PrintLayout>
	);
}

export default Report;

const DragHandle = sortableHandle(() => (
	<Flex minH="32px" justifyContent="center" alignItems="center" cursor="move" mr="4">
		<DragHandleIcon />
	</Flex>
));
