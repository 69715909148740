import React, { useState } from "react";
import { Checkbox, Box, FormControl, FormLabel, FormHelperText } from "@chakra-ui/react";
import { Field } from "formik";
import { useTranslation } from "react-i18next";

const CheckBoxFormField = props => {
    const {
        name,
        type,
        label,
        placeholder,
        required,
        callbackValueChange,
    } = props;
    const { t } = useTranslation();
    const [isChecked, setIsChecked] = useState(props?.values[name])
    return (
        <Field name={name}>
            {({ field, meta, form }) => (
                <Box mb="4">
                    <FormControl id={name} >
                        <Checkbox {...field} value={isChecked} defaultIsChecked={isChecked}
                            onChange={e => {
                                setIsChecked(!isChecked)
                                form.setFieldValue(name, !isChecked)
                                if (callbackValueChange) {
                                    callbackValueChange(!isChecked)
                                }
                            }}>{t(label)}</Checkbox>

                        {meta.touched && meta.error && (
                            <FormHelperText
                                id={`error-message-${name}`}
                                color="red.400"
                                fontSize="12px"
                            >
                                {t(name)}{" "}
                                {t("is")}{" "}
                                {t(meta.error)}
                            </FormHelperText>
                        )}
                    </FormControl>

                </Box>
            )}
        </Field>
    );
};

export default CheckBoxFormField;
