/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Box, Flex, Text, Center, Table, Checkbox, Tbody, Tfoot, Tr, Th, Td, Badge, Tooltip, Button } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { faSlidersH, faPrint, faFileExcel, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { gql, useLazyQuery } from '@apollo/client';
import { useLocation, useHistory } from 'react-router-dom';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import BreadcrumbComponent from '../../components/BreadcrumbComponent/BreadcrumbComponent';
import CompanySubmissionProposalFilter from '../../components/Filter/CompanySubmissionProposalFilter';
import LoadingTable from '../../components/Table/Loading';
import PrintLayout from '../../components/Print/PrintLayout';
import ExcelExportModal from '../../components/Modal/ExcelExportModal';
import RecheckTransactionModal from '../../components/Modal/RecheckTransactionModal';
import ReactPaginate from 'react-paginate';
import datePickerFromToToDate from '../../utils/datePickerFromToToDate';
import moment from 'moment';
import useStore from '../../store';
import c from '../../constant';
import './style.css';

const QUERY_PROPOSALS = gql`
	query getCompanySubmissionProposals(
		$limit: Float
		$offset: Float
		$fromDate: DateTime
		$toDate: DateTime
		$read: Boolean
		$number: Float
		$companyName: String
		$department: String
		$office: String
		$status: String
		$publicService: Boolean
		$spam: Boolean
		$showNearlyDue: Boolean
		$state: String
		$showIncomplete: Boolean
		$isListReport: Boolean
	) {
		getCompanySubmissionProposals(
			pagerInput: { limit: $limit, offset: $offset }
			fromDate: $fromDate
			toDate: $toDate
			read: $read
			number: $number
			companyName: $companyName
			department: $department
			office: $office
			status: $status
			publicService: $publicService
			spam: $spam
			showNearlyDue: $showNearlyDue
			state: $state
			showIncomplete: $showIncomplete
			isListReport: $isListReport
		) {
			companySubmissionProposals {
				id
				no
				companyName
				subject
				createdAt
				read
				assignTo {
					read
					user {
						id
					}
				}
				approved
				approvedAt
				dueDate
				publicService
				spam
				company {
					id
					name
					nameKhmer
					logo
				}
				done
				doneAt
				submissionStatus
			}
			countUnreadCompanySubmissionProposals
			totalPages
			totalRows
		}
	}
`;

const QUERY_ALL_PUBLIC_SERVICES = gql`
	query {
		getAllPublicServices {
			id
			value
			title
			active
		}
	}
`;

const QUERY_DATA_PAYMENT_BY_REF_ID = gql`
	query get($refId: String!) {
		getPaymentsByRefId(refId: $refId) {
			payments {
				id
				tranId
				userId
				createdAt
				firstName
				lastName
				email
				phone
				paymentOption
				amount
				currency
				status
				refType
				refId
			}
			totalRows
			totalPages
		}
	}
`;

function ListReport() {
	const { t } = useTranslation();
	const location = useLocation();
	const history = useHistory();
	const urlParams = new URLSearchParams(location.search);
	const currentPage = urlParams.get('page') ? urlParams.get('page') : 1;
	const state = urlParams.get('state') ? urlParams.get('state') : '';
	const { currentLang } = useStore((state) => state.currentLang);
	const companyName = urlParams.get('companyName') ? urlParams.get('companyName') : null;
	const number = urlParams.get('number') ? urlParams.get('number') : null;
	const department = urlParams.get('department') ? urlParams.get('department') : null;
	const office = urlParams.get('office') ? urlParams.get('office') : null;
	const status = urlParams.get('status') ? urlParams.get('status') : null;
	const fromDate = urlParams.get('fromDate') ? urlParams.get('fromDate') : null;
	const toDate = urlParams.get('toDate') ? urlParams.get('toDate') : null;
	const read = urlParams.get('read') ? urlParams.get('read') : null;
	const showSpam = urlParams.get('showSpam') ? urlParams.get('showSpam') : null;
	const showNearlyDue = urlParams.get('showNearlyDue') ? urlParams.get('showNearlyDue') : null;
	const showIncomplete = urlParams.get('showIncomplete') ? urlParams.get('showIncomplete') : null;
	const type = urlParams.get('type') ? urlParams.get('type') : null;
	moment.locale(currentLang === 'kh' ? 'km' : 'en-gb');
	const [loadProposals, { loading, data }] = useLazyQuery(QUERY_PROPOSALS);
	const [loadPublicServices, { data: dataPublicServices }] = useLazyQuery(QUERY_ALL_PUBLIC_SERVICES);
	const [loadPaymentByRefId, { data: dataPayment }] = useLazyQuery(QUERY_DATA_PAYMENT_BY_REF_ID);

	const [isOpenRecheckModal, setIsOpenRecheckModal] = useState(false);

	const [rowLimit, setRowLimit] = useState(10);

	const [excelData, setExcelData] = useState(null);
	const [openDownloadExcel, setOpenDownloadExcel] = useState(null);

	useEffect(() => {
		window.scrollTo(0, 0);
		loadProposals({
			variables: {
				companyName,
				department,
				office,
				number: parseInt(number),
				offset: currentPage ? (currentPage - 1) * rowLimit : 0,
				fromDate: datePickerFromToToDate(fromDate),
				toDate: datePickerFromToToDate(toDate),
				read: read === 'false' ? false : read === 'true' ? true : null,
				type,
				status: status !== null ? (status === 'all' ? null : status) : 'in progress',
				state: state ? state : 'open',
				showIncomplete: showIncomplete === 'true' ? true : null,
				limit: rowLimit,
				isListReport: true,
			},
		});
		loadPublicServices();
	}, []);

	const [openFilter, setOpenFilter] = useState(false);

	function handlePageClick(data) {
		let selected = data.selected;
		const page = selected + 1;
		let url = `?page=${page}`;

		if (state) {
			url += `&state=${state}`;
		}

		if (number) {
			url += `&number=${number}`;
		}

		if (companyName) {
			url += `&companyName=${companyName}`;
		}

		if (department) {
			url += `&department=${department}`;
		}

		if (office) {
			url += `&office=${office}`;
		}

		if (status) {
			url += `&status=${status}`;
		}

		if (fromDate) {
			url += `&fromDate=${fromDate}`;
		}

		if (toDate) {
			url += `&toDate=${toDate}`;
		}

		if (type) {
			url += `&type=${type}`;
		}

		if (read !== null) {
			const unreadOnly = read ? read : '';
			if (unreadOnly) {
				url += `&read=false`;
			}
		}

		if (showSpam) {
			url += `&showSpam=${showSpam}`;
		}

		if (showNearlyDue) {
			url += `&showNearlyDue=${showNearlyDue}`;
		}

		if (showIncomplete) {
			url += `&showIncomplete=${showIncomplete}`;
		}

		history.push(`/company-submission-proposal-list-report${url}`);
	}

	function handleStatusChange(statusValue) {
		const page = 1;
		let url = `?page=${page}`;

		if (statusValue) {
			url += `&status=${statusValue}`;
		}

		if (number) {
			url += `&number=${number}`;
		}

		if (companyName) {
			url += `&companyName=${companyName}`;
		}

		if (department) {
			url += `&department=${department}`;
		}

		if (office) {
			url += `&office=${office}`;
		}

		if (fromDate) {
			url += `&fromDate=${fromDate}`;
		}

		if (toDate) {
			url += `&toDate=${toDate}`;
		}

		if (type) {
			url += `&type=${type}`;
		}

		if (read !== null) {
			const unreadOnly = read ? read : '';
			if (unreadOnly) {
				url += `&read=false`;
			}
		}

		if (showSpam) {
			url += `&showSpam=${showSpam}`;
		}

		if (showNearlyDue) {
			url += `&showNearlyDue=${showNearlyDue}`;
		}

		if (showIncomplete) {
			url += `&showIncomplete=${showIncomplete}`;
		}

		history.push(`/company-submission-proposal-list-report${url}`);
	}

	useEffect(() => {
		if (currentPage) {
			window.scrollTo(0, 0);
			loadProposals({
				variables: {
					number: parseInt(number),
					companyName,
					department,
					office,
					offset: currentPage ? (currentPage - 1) * rowLimit : 0,
					fromDate: datePickerFromToToDate(fromDate),
					toDate: datePickerFromToToDate(toDate),
					read: read === 'false' ? false : read === 'true' ? true : null,
					publicService: type !== null ? (type === 'all' ? null : type === 'PUBLIC_SERVICE' ? true : false) : null,
					status: status !== null ? (status === 'all' ? null : status) : 'in progress',
					spam: showSpam === 'true' ? true : null,
					showNearlyDue: showNearlyDue === 'true' ? true : null,
					state: state ? state : 'open',
					showIncomplete: showIncomplete === 'true' ? true : null,
					limit: rowLimit,
					isListReport: true,
				},
			});
			// loadStateProposals()
		}
	}, [rowLimit, currentPage, fromDate, toDate, read, number, companyName, department, office, type, status, showSpam, showNearlyDue, state, showIncomplete]);

	function callbackFilter(e) {
		let url = e;
		if (state) {
			url += `&state=${state}`;
		}
		history.push(`/company-submission-proposal-list-report${url}`);
	}

	function checkRead(item) {
		let read = true;
		return read;
	}

	function getStatus({ done, doneDate }) {
		return done === false ? (
			<Badge colorScheme="yellow">{t('In progress')}</Badge>
		) : (
			<Tooltip label={doneDate ? moment(doneDate).format('LLLL') : ''}>
				<Badge colorScheme="green">{t('Done')}</Badge>
			</Tooltip>
		);
	}

	function getProposalType(publicService) {
		return publicService ? <Badge colorScheme="green">{t('Public Service')}</Badge> : <Badge colorScheme="yellow">{t('Other')}</Badge>;
	}

	function getDisplayCompanyName(item) {
		let displayName = item?.companyName;
		if (item?.company?.id) {
			if (currentLang === 'kh') {
				displayName = item?.company?.nameKhmer;
			} else {
				displayName = item?.company?.name;
			}
		}
		return displayName;
	}

	function panelTabs({ name, value }) {
		let active = status ? status : 'in progress';

		return (
			<Flex
				userSelect="none"
				minW="100px"
				onClick={() => {
					handleStatusChange(value);
				}}
				cursor="pointer"
				mb={'-2px'}
				_hover={{ boxShadow: `inset 0 -2px 0 0 ${value === active ? '#2980b9' : '#bdc3c7'}` }}
				boxShadow={value === active ? 'inset 0 -2px 0 0 #2980b9' : ''}
				pt="16px"
				pb="16px"
				pl="12px"
				pr="12px"
				alignItems="center"
				justifyContent="center"
			>
				<Text>{t(name)}</Text>
				{/* <Badge mb="2px" ml="4px">{amount>=0?amount:""}</Badge> */}
			</Flex>
		);
	}

	function openReCheckTr(id) {
		setIsOpenRecheckModal(true);
		loadPaymentByRefId({
			variables: {
				refId: id,
			},
		});
	}

	function generateExcelData() {
		const tmp = [];
		data?.getCompanySubmissionProposals?.companySubmissionProposals?.map((item, index) => {
			tmp.push({
				number: item?.no,
				companyName: getDisplayCompanyName(item),
				subject: item.subject,
				createdAt: moment(item.createdAt).format(c.excelDateTimeFormat),
				dueDate: item?.dueDate ? moment(item?.dueDate).format(c.excelDateOnlyFormat) : 'N/A',
				type: item?.publicService ? 'Public Service' : 'Other',
				publicServiceName: item?.publicService ? getPublicServiceByValue(item?.publicService) : null,
				status: item?.done === false ? 'In progress' : 'Done',
			});
		});
		setExcelData(tmp);
		setTimeout(() => {
			setOpenDownloadExcel(true);
		}, 100);
	}

	function getPublicServiceByValue(value) {
		let res = '';
		if (value) {
			let data = dataPublicServices?.getAllPublicServices?.filter((pb) => pb.value === value);
			res = data[0]?.title;
		}
		return res;
	}

	return (
		<PrintLayout>
			<>
				<Container maxW="container.xl" mt="16px" mb="16px">
					<BreadcrumbComponent
						list={[
							{
								name: 'PROPOSAL',
								path: '/company-submission-proposal',
							},
							{
								name: 'List Report',
								path: '#',
							},
						]}
					/>
					<Center className="show-only-printing">
						<Text textAlign="center" color="#62B3ED" fontFamily="Moul" fontSize={18}>
							សំណើក្រុមហ៊ុន
						</Text>
					</Center>
					<Center mt="5">
						<Box
							w="100%"
							bg="white"
							borderRadius={c.borderRadius}
							border="1px solid #dbdbdb"
							boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
							pb="32px"
						>
							<Flex
								className="hidden-print"
								marginLeft="-29px"
								marginTop="82px"
								fontWeight="600"
								position="absolute"
								justifyContent="center"
								alignItems="center"
								w="80px"
								h="24px"
								color="white"
								bg="red"
								transform="rotate(-90deg)"
							>
								{t('Report')}
							</Flex>
							<Flex
								className="hidden-print"
								bg="#FAFAFA"
								p="16px"
								pt="0"
								pb="0"
								mb="16px"
								alignItems="center"
								justifyContent="space-between"
								borderBottom="1px solid #dbdbdb"
							>
								<Flex>
									{panelTabs({ name: 'In progress', value: 'in progress' })}
									{panelTabs({ name: 'Done', value: 'done' })}
									{panelTabs({ name: 'All', value: 'all' })}
								</Flex>

								<Flex alignItems="center">
									{/* <Tooltip label={t("Export To Excel")}>
                                        <Flex mr="3" cursor="pointer" onClick={() => {
                                            generateExcelData()
                                        }}>
                                            <FontAwesomeIcon icon={faFileExcel} style={{ fontSize: 16, marginLeft: 6 }} />
                                        </Flex>
                                    </Tooltip> */}
									<Button
										mr="2"
										onClick={() => generateExcelData()}
										leftIcon={<FontAwesomeIcon icon={faFileExcel} style={{ fontSize: 16 }} />}
										colorScheme="gray"
										variant="solid"
										size="sm"
										borderRadius={c.borderRadius}
										border="1px solid #bdc3c7"
									>
										{t('Export')}
									</Button>
									{/* <Flex onClick={() => window?.print()} mr="3" cursor="pointer">
                                        <FontAwesomeIcon icon={faPrint} style={{ fontSize: 16, marginLeft: 6 }} />
                                    </Flex> */}
									<Button
										mr="2"
										onClick={() => window?.print()}
										leftIcon={<FontAwesomeIcon icon={faPrint} style={{ fontSize: 16 }} />}
										colorScheme="gray"
										variant="solid"
										size="sm"
										borderRadius={c.borderRadius}
										border="1px solid #bdc3c7"
									>
										{t('Print')}
									</Button>
									<Link to={`/company-submission-proposal/add`}>
										<Button
											mr="2"
											// onClick={() => window?.print()}
											leftIcon={<FontAwesomeIcon icon={faPlus} style={{ fontSize: 16 }} />}
											colorScheme="gray"
											variant="solid"
											size="sm"
											borderRadius={c.borderRadius}
											border="1px solid #bdc3c7"
										>
											{t('Add')}
										</Button>
									</Link>
									<Button
										onClick={() => setOpenFilter(true)}
										leftIcon={<FontAwesomeIcon icon={faSlidersH} style={{ fontSize: 16 }} />}
										colorScheme={
											number ||
											companyName ||
											department ||
											office ||
											fromDate ||
											toDate ||
											read ||
											type ||
											showSpam ||
											showNearlyDue ||
											showIncomplete
												? 'yellow'
												: 'gray'
										}
										variant="solid"
										size="sm"
										borderRadius={c.borderRadius}
										border="1px solid #bdc3c7}"
									>
										{t('Filter')}
									</Button>
									{/* <FontAwesomeIcon onClick={() => setOpenFilter(true)} icon={faSlidersH} style={{ fontSize: 18, cursor: "pointer", color: (number || companyName || fromDate || toDate || read || type || showSpam || showNearlyDue) ? "#c0392b" : "black" }} /> */}
								</Flex>
							</Flex>
							<Box p="16px">
								<Table variant="simple" className="table-company-proposal-list responsive-table-container">
									<Tbody>
										<Tr className="td-as-th">
											<Td>{t('Number')}</Td>
											<Td>{t('Company Name')}</Td>
											<Td>{t('Subject')}</Td>
											<Td>{t('Proposal Created At')}</Td>
											<Td>{t('Due Date')}</Td>
											<Td>{t('Type')}</Td>
											<Td isNumeric>{t('Status')}</Td>
										</Tr>
										{loading && <LoadingTable column={7} />}
										{data?.getCompanySubmissionProposals?.companySubmissionProposals.length <= 0 && (
											<Tr>
												<Td>
													<Text>{t('No Data')}</Text>
												</Td>
											</Tr>
										)}
										{data?.getCompanySubmissionProposals?.companySubmissionProposals?.map((item, index) => {
											return (
												<Tr
													cursor="not-allowed"
													_hover={{ bg: 'gray.200' }}
													key={`item-${index}`}
													bg={checkRead(item) ? 'white' : 'gray.100'}
												>
													<Td>{item?.no}</Td>
													<Td fontWeight="500">
														<Text maxW="400px" noOfLines={4}>
															{getDisplayCompanyName(item)}
														</Text>
													</Td>
													<Td fontWeight="500">
														<Text maxW="400px" noOfLines={4}>
															{item.subject}
														</Text>
													</Td>
													<Td>{moment(item.createdAt).format('LLLL')}</Td>
													<Td>{item?.dueDate ? moment(item?.dueDate).format(c.dateTextOnlyFormat) : 'N/A'}</Td>
													<Td>
														<Flex>
															{getProposalType(item.publicService)}
															{item.spam === true && (
																<Badge ml={2} colorScheme="red">
																	{t('Spam')}
																</Badge>
															)}
														</Flex>
													</Td>
													<Td isNumeric>
														{' '}
														<Box>
															{item?.submissionStatus === '1' ? (
																getStatus({ done: item?.done, doneDate: item?.doneAt })
															) : (
																<>
																	{item?.submissionStatus === '2' && (
																		<Badge
																			cursor="pointer"
																			onClick={() => {
																				openReCheckTr(item?.id);
																			}}
																			colorScheme="purple"
																		>
																			{t('PENDING PAYMENT')}
																		</Badge>
																	)}
																	{item?.submissionStatus === '3' && <Badge colorScheme="red">{t('CANCEL')}</Badge>}
																</>
															)}
														</Box>
													</Td>
												</Tr>
											);
										})}
									</Tbody>
									<Tfoot className="hidden-print">
										<Tr>
											<Th>{t('Number')}</Th>
											<Th>{t('Company Name')}</Th>
											<Th>{t('Subject')}</Th>
											<Th>{t('Proposal Created At')}</Th>
											<Th>{t('Due Date')}</Th>
											<Th>{t('Type')}</Th>
											<Th isNumeric>{t('Status')}</Th>
										</Tr>
										<Tr>
											<Th />
											<Th />
											<Th />
											<Th />
											<Th />
											<Th />
											<Th isNumeric>
												{t('Total Rows')}: {data?.getCompanySubmissionProposals?.totalRows}
											</Th>
										</Tr>
									</Tfoot>
								</Table>
								<Flex className="hidden-print" justifyContent="flex-end" w="100%" mt="4">
									{data?.getCompanySubmissionProposals?.totalPages > 1 && (
										<ReactPaginate
											previousLabel={<ChevronLeftIcon fontSize="20px" />}
											nextLabel={<ChevronRightIcon fontSize="20px" />}
											breakLabel={'...'}
											breakClassName={'break-me'}
											pageCount={data?.getCompanySubmissionProposals?.totalPages}
											marginPagesDisplayed={2}
											pageRangeDisplayed={3}
											onPageChange={handlePageClick}
											containerClassName={'pagination'}
											activeClassName={'active'}
											initialPage={(currentPage - 1) | 0}
										/>
									)}
								</Flex>
								<Box justifyContent="flex-end" className="show-only-printing">
									<Text ml="2" mt="4">
										{t('Total Rows')}: <b>{data?.getCompanySubmissionProposals?.totalRows}</b>
									</Text>
								</Box>
								<Flex className="hidden-print" justifyContent="flex-end" mt="6">
									<Text mr="2">{t('Show more rows')}</Text>
									<Checkbox
										isChecked={rowLimit > 10}
										onChange={() => {
											handlePageClick({ selected: 0 });
											if (rowLimit > 10) {
												setRowLimit(10);
											} else {
												setRowLimit(300);
											}
										}}
									></Checkbox>
								</Flex>
							</Box>
						</Box>
					</Center>
				</Container>
				<CompanySubmissionProposalFilter
					callback={callbackFilter}
					isOpen={openFilter}
					onClose={() => setOpenFilter(false)}
					fromDate={fromDate}
					toDate={toDate}
					read={read}
					number={number}
					companyName={companyName}
					department={department}
					office={office}
					type={type}
					status={status}
					showSpam={showSpam === 'true' ? true : false}
					showNearlyDue={showNearlyDue === 'true' ? true : false}
					showIncomplete={showIncomplete === 'true' ? true : false}
					fromListReport={true}
				/>

				{openDownloadExcel && <ExcelExportModal title="Proposal" isOpen={true} onClose={() => setOpenDownloadExcel(false)} data={excelData} />}

				{isOpenRecheckModal && dataPayment && (
					<RecheckTransactionModal
						onClose={() => {
							window.scrollTo(0, 0);
							loadProposals({
								variables: {
									companyName,
									number: parseInt(number),
									department,
									office,
									offset: currentPage ? (currentPage - 1) * rowLimit : 0,
									fromDate: datePickerFromToToDate(fromDate),
									toDate: datePickerFromToToDate(toDate),
									read: read === 'false' ? false : read === 'true' ? true : null,
									type,
									status: status !== null ? (status === 'all' ? null : status) : 'in progress',
									state: state ? state : 'open',
									showIncomplete: showIncomplete === 'true' ? true : null,
									limit: rowLimit,
									isListReport: true,
								},
							});
							setIsOpenRecheckModal(false);
						}}
						data={dataPayment?.getPaymentsByRefId?.payments || []}
					/>
				)}
			</>
		</PrintLayout>
	);
}

export default ListReport;
