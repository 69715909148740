import React, { useEffect, useState } from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Box,
    Button,
    useToast,
    Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  StatGroup,
  Divider,
  Tooltip
} from "@chakra-ui/react";
import c from "../../constant"
import { gql, useLazyQuery } from '@apollo/client';
import './style.css'
import { useTranslation } from "react-i18next";
import ReactExport from "react-export-excel";
import moment from "moment";
import 'moment/locale/en-gb';
import UserDislayNameById from "../UserDislayNameById"
const QUERY_DETAIL = gql`
query get($id: String!){
    getAgentById(id:$id){
        id
        timelines
    }
  }
`


function AgentTimelinesModal({
    onClose = () => null, id
}) {
    moment.locale("en-gb")

    const { t } = useTranslation();
    const [loadDetail, { error, loading, data }] = useLazyQuery(QUERY_DETAIL)

    useEffect(()=>{
        loadDetail({
            variables:{
                id
            }
        })
    },[])
    
    console.log("id:", id)
    
    

    return (
        <Modal
            isOpen={true}
            onClose={() => {
                onClose()
            }}
            size="md"
        >
            <ModalOverlay />
            <ModalContent borderRadius={c.borderRadius}>
                <ModalHeader>{t("Timelines")}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Box w="100%" p="16px">

                    {
                        data?.getAgentById?.timelines?.map((item,index)=>{
                            return(
                                <>
                                <Stat>
                                <StatLabel>{moment(item?.createdAt).format("LLLL")}</StatLabel>

                                
                                
                                <StatLabel>
                                    <StatHelpText color="#2980b9">{item.type}</StatHelpText>
                                    {item?.createdBy?
                                     <Tooltip label={<UserDislayNameById id={item?.createdBy}/>}>
                                    <Box>User: {item?.createdBy}</Box>
                                    </Tooltip>
                                    :null}
                                </StatLabel>
                                {item?.description&&<StatHelpText>Description: {item?.description}</StatHelpText>}
                                </Stat>
                                <Divider mt="6" mb="6"/>
                                </>
                            )
                        })
                    }
                        
                    </Box>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

export default AgentTimelinesModal;
