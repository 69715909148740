/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Box, FormControl, FormLabel, FormHelperText, InputGroup, Input, InputRightElement, IconButton, Flex, useDisclosure } from '@chakra-ui/react';
import { Field, useFormikContext } from 'formik';
import { IoIosCalendar } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import '@amir04lm26/react-modern-calendar-date-picker/lib/DatePicker.css';
import { Calendar } from '@amir04lm26/react-modern-calendar-date-picker';
import useStore from '../../store';
import moment from 'moment';
import c from '../../constant';

const DateInputOrSelectFormField = ({ name = null, label = null, required = false }) => {
	const { t } = useTranslation();
	const { values, setFieldValue } = useFormikContext();
	const { currentLang: lang } = useStore((state) => state.currentLang);
	const { isOpen, onToggle } = useDisclosure();
	const [textDate, setTextDate] = useState(values[name]);

	const toDay = (i) => {
		moment.locale('en-gb');
		const day = parseInt(moment(i, c.dateFormats).format('DD'));
		return day;
	};

	const toMonth = (i) => {
		moment.locale('en-gb');
		const month = parseInt(moment(i, c.dateFormats).format('MM'));
		return month;
	};

	const toYear = (i) => {
		moment.locale('en-gb');
		const year = parseInt(moment(i, c.dateFormats).format('YYYY'));
		return year;
	};

	const toDate = (i) => {
		moment.locale('en-gb');
		const m = moment(i, c.dateFormats);
		if (m.isValid()) {
			return m.toDate();
		}
		return null;
	};

	const toTextDate = (date) => {
		if (date) {
			moment.locale('en-gb');
			const textDate = moment(date).format(c.dateFormat);
			return textDate;
		}
		return null;
	};

	const toCalendarDate = (input) => {
		const date = toDate(input);
		if (date) {
			const day = toDay(date);
			const month = toMonth(date);
			const year = toYear(date);
			if (day && month && year) {
				return { day, month, year };
			}
		}
		return null;
	};

	return (
		<Field name={name}>
			{({ field, meta: { touched, error }, form }) => (
				<Box mb={4}>
					<FormControl id={name}>
						<FormLabel>
							{t(label)}
							{required && <span style={{ color: 'red' }}>*</span>}
						</FormLabel>
						<InputGroup size="lg">
							<Input
								type="text"
								value={textDate}
								size="lg"
								disabled={isOpen}
								errorBorderColor="red.300"
								isInvalid={touched && error}
								placeholder="DD-MM-YYYY"
								onChange={(e) => {
									setFieldValue(name, e.target.value);
									setTextDate(e.target.value);
								}}
							/>
							<InputRightElement>
								<IconButton icon={<IoIosCalendar />} onClick={onToggle} />
							</InputRightElement>
						</InputGroup>
						{isOpen && (
							<Flex justifyContent="center" mt={4}>
								<Calendar
									shouldHighlightWeekends={true}
									minimumDate={c.minCalendar}
									maximumDate={c.maxCalendar}
									locale={lang === 'kh' ? myCustomLocale : 'en'}
									value={toCalendarDate(values[name])}
									onChange={(e) => {
										const input = { day: e.day, month: e.month - 1, year: e.year };
										const date = moment(input).toDate();
										const textDate = toTextDate(date);
										setFieldValue(name, textDate);
										setTextDate(textDate);
									}}
								/>
							</Flex>
						)}
						<FormHelperText>{t('Date format: Day - Month - Year')}</FormHelperText>
						{touched && error && (
							<FormHelperText id="error" color="red.400" fontSize="12px">
								{t(name)} {t('is')} {t(error)}
							</FormHelperText>
						)}
					</FormControl>
				</Box>
			)}
		</Field>
	);
};

export default DateInputOrSelectFormField;

const myCustomLocale = {
	// months list by order
	months: ['មករា', 'ខែកុម្ភៈ', 'មីនា', 'មេសា', 'ឧសភា', 'មិថុនា', 'កក្កដា', 'សីហា', 'កញ្ញា', 'តុលា', 'វិច្ឆិកា', 'ធ្នូ'],

	// week days by order
	weekDays: [
		{
			name: 'អាទិត្យ',
			short: 'អាទិត្យ',
			isWeekend: true,
		},
		{
			name: 'ច័ន្ទ',
			short: 'ច័ន្ទ',
		},
		{
			name: 'អង្គារ',
			short: 'អង្គារ',
		},
		{
			name: 'ពុធ',
			short: 'ពុធ',
		},
		{
			name: 'ព្រហស្បតិ៍',
			short: 'ព្រហស្បតិ៍',
		},
		{
			name: 'សុក្រ',
			short: 'សុក្រ',
		},
		{
			name: 'សៅរ៍',
			short: 'សៅរ៍',
			isWeekend: true,
		},
	],

	// just play around with this number between 0 and 6
	weekStartingIndex: 0,

	// return a { year: number, month: number, day: number } object
	getToday(gregorainTodayObject) {
		return gregorainTodayObject;
	},

	// return a native JavaScript date here
	toNativeDate(date) {
		return new Date(date.year, date.month - 1, date.day);
	},

	// return a number for date's month length
	getMonthLength(date) {
		return new Date(date.year, date.month, 0).getDate();
	},

	// return a transformed digit to your locale
	transformDigit(digit) {
		return digit;
	},

	// texts in the date picker
	nextMonth: 'Next Month',

	previousMonth: 'Previous Month',

	openMonthSelector: 'Open Month Selector',

	openYearSelector: 'Open Year Selector',

	closeMonthSelector: 'Close Month Selector',

	closeYearSelector: 'Close Year Selector',

	defaultPlaceholder: 'Select...',

	// for input range value
	from: 'from',

	to: 'to',

	// used for input value when multi dates are selected
	digitSeparator: ',',

	// if your provide -2 for example, year will be 2 digited
	yearLetterSkip: 0,

	// is your language rtl or ltr?
	isRtl: false,
};
