import { gql, useLazyQuery } from '@apollo/client';
import { useContext, createContext, useState, useEffect } from 'react';

const Q_GET_REQUEST = gql`
	query get($id: String!) {
		getBranchChangeRequestById(id: $id) {
			id
			number
			createdAt
			createdBy
			updatedAt
			updatedBy
			status
			company {
				id
				name
				nameKhmer
			}
			approvedAt
			approvedBy
			numberOfRequestedAgent
			numberOfApprovedAgent
			completedAt
			completedBy
			candidates {
				id
				company {
					id
					name
					nameKhmer
				}
				agent {
					id
					code
					name
					latinName
					nationality
				}
				createdAt
				createdBy
				updatedAt
				updatedBy
				status
				statusDescription
				statusUpdatedAt
				statusUpdatedBy
				oldBranch {
					id
					name
					nameLatin
				}
				oldGeoLocation {
					id
					name
					latinName
				}
				newBranch {
					id
					name
					nameLatin
				}
				newGeoLocation {
					id
					name
					latinName
				}
			}
		}
	}
`;

const BranchChangeRequestContext = createContext();

export const BranchChangeRequestProvider = ({ children }) => {
	const [load, { loading: loadingRequest, data }] = useLazyQuery(Q_GET_REQUEST);
	const [loadedData, setLoadedData] = useState(null);

	useEffect(() => {
		if (data) {
			setLoadedData(data?.getBranchChangeRequestById);
		}
	}, [data]);

	const loadRequest = (id) => {
		load({ variables: { id } });
	};

	return (
		<BranchChangeRequestContext.Provider value={{ loadRequest, loadingRequest, loadedData, setLoadedData }}>{children}</BranchChangeRequestContext.Provider>
	);
};

export const useBranchChangeRequest = () => {
	return useContext(BranchChangeRequestContext);
};
