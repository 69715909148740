/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { gql, useMutation } from '@apollo/client';
import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Button,
	Flex,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Tooltip,
	useDisclosure,
	useToast,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import EditLicensedProIndividualModal from '../../Modal/EditLicensedProIndividualModal';
import c from '../../../constant';

const DELETE_PROFESSIONAL_MUTATION = gql`
	mutation delete($id: String!) {
		deleteCompanyProIndividual(id: $id)
	}
`;

const LicensedProIndividuaUpdateDelete = ({ id = null, number = null, khmerName = null, latinName = null, onCallback = () => null }) => {
	const toast = useToast();
	const { t } = useTranslation();
	const { isOpen: isEditing, onOpen: openEditing, onClose: closeEditing } = useDisclosure();
	const { isOpen: isDeleting, onOpen: openDeleting, onClose: closeDeleting } = useDisclosure();
	const [deleteProfessional, { loading, data, error }] = useMutation(DELETE_PROFESSIONAL_MUTATION);

	useEffect(() => {
		if (error) {
			if (error?.graphQLErrors?.length > 0) {
				error?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(error?.message ? error?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [error]);

	useEffect(() => {
		if (data) {
			if (data.deleteCompanyProIndividual) {
				toast({
					title: t('Professional individual has been deleted successfully'),
					status: 'success',
					duration: 5000,
					isClosable: true,
					position: 'bottom-right',
				});
				closeDeleting();
				onCallback();
			}
		}
	}, [data]);

	return (
		<>
			<Menu>
				<Tooltip label={`${t('Edit')} ${t('Delete')}`}>
					<MenuButton cursor="pointer">
						<Flex pl="2" pr="2">
							<FontAwesomeIcon icon={faEllipsisV} style={{ fontSize: 16, marginTop: 4, marginLeft: 6 }} />
						</Flex>
					</MenuButton>
				</Tooltip>
				<MenuList pt="4" pb="4" pl="3" pr="3" borderRadius={c.borderRadius}>
					<MenuItem color={'blue.500'} onClick={openEditing}>
						{t('Edit')}
					</MenuItem>
					<MenuItem color={'red.500'} onClick={openDeleting}>
						{t('Delete')}
					</MenuItem>
				</MenuList>
			</Menu>
			<AlertDialog closeOnEsc={false} closeOnOverlayClick={false} isOpen={isDeleting} onClose={closeDeleting}>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							{t('Delete')}
						</AlertDialogHeader>
						<AlertDialogBody>{t('Are you sure you want to delete?')}</AlertDialogBody>
						<AlertDialogFooter>
							<Button mr={4} isLoading={loading} onClick={closeDeleting}>
								{t('Cancel')}
							</Button>
							<Button isLoading={loading} colorScheme="red" onClick={() => deleteProfessional({ variables: { id } })}>
								{t('Delete')}
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
			{isEditing && (
				<EditLicensedProIndividualModal
					id={id}
					number={number}
					khmerName={khmerName}
					latinName={latinName}
					isOpen={true}
					onClose={closeEditing}
					onCallback={onCallback}
				/>
			)}
		</>
	);
};

export default LicensedProIndividuaUpdateDelete;
