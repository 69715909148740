import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Box, Flex, Text, Center, Input, Button, useToast, InputRightElement, InputGroup, Divider, SimpleGrid, Image } from '@chakra-ui/react';
import BreadcrumbComponent from '../../components/BreadcrumbComponent/BreadcrumbComponent';
import { faPenSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import c from '../../constant';
import useStore from '../../store';
import TableForm from '../../components/Table/TableForm';
import FirstWorkingPlaceAndCurrentWorkingStatus from './FormPart/PartTwo/FirstWorkingPlaceAndCurrentWorkingStatus';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import PlaceholderImage from '../../assets/images/profile-4x6-placeholder.jpg';
import PersonalInformation from './FormPart/PartOne/PersonalInformation';
import FamilyStatus from './FormPart/PartSeven/FamilyStatus';
import SpouseStatus from './FormPart/PartSeven/SpouseStatus';
import EditProfileImage from './FormPart/EditProfileImage';
import PrintEmployee from './PrintEmployee/PrintEmployee';
import checkOneRole from '../../utils/checkOneRole';

const QUERY_EMPLOYEE_BY_ID = gql`
	query get($id: String!) {
		getEmployeeById(id: $id) {
			id
			createdAt
			profileImage
			govtNumber
			mefNumber
			fullName
			fullNameLatin
			gender
			dob
			maritalStatus
			pob
			isPobManual
			pobManual
			currentAddress
			isCurrentAddressManual
			currentAddressManual
			email
			phoneNumber
			idCardNumber
			documentIDCard
			idCardExpirationDate
			passportNumber
			documentPassport
			passportExpirationDate

			firstWorkingPlace
			currentWorkingStatus
			familyStatus
			spouseStatus
			additionalRoleOnCurrentJob
			situationRemainsWithinTheOriginalFramework
			unpaidVacancies
			inTheFieldOfPublicFunctions
			inThePrivateSector
			medalCertificateOfAppreciation
			discipline
			generalEducation
			degree
			specialization
			vocationalTraining
			foreignLanguage
			siblingInformation
			childInformation
		}
	}
`;

const SAVE_TABLE_DATA_MUTATION = gql`
	mutation update($id: String!, $data: String!, $tableName: String!) {
		updateEmployeeTable(id: $id, data: $data, tableName: $tableName)
	}
`;

function Create() {
	const history = useHistory();
	const toast = useToast();
	const location = useLocation();
	const urlParams = new URLSearchParams(location.search);
	const id = urlParams.get('id') ? urlParams.get('id') : null;
	const { t } = useTranslation();
	const { currentLang } = useStore((state) => state.currentLang);
	const { currentUser } = useStore((state) => state.currentUser);

	const [isEditProfileImage, setIsEditProfileImage] = useState(false);

	useEffect(() => {
		window.scrollTo(0, 0);
		getEmployee({
			variables: {
				id,
			},
		});
	}, []);

	const [getEmployee, { error, data, loading }] = useLazyQuery(QUERY_EMPLOYEE_BY_ID);

	const [saveTableDataMutation, { error: saveTableError, data: saveTableDataRes, loading: saveTableLoading }] = useMutation(SAVE_TABLE_DATA_MUTATION);

	function saveTableData({ tableName, data }) {
		saveTableDataMutation({
			variables: {
				id,
				tableName,
				data: JSON.stringify(data),
			},
		});
	}

	useEffect(() => {
		if (saveTableError) {
			if (saveTableError?.graphQLErrors?.length > 0) {
				saveTableError?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(saveTableError?.message ? saveTableError?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [saveTableError]);

	useEffect(() => {
		if (saveTableDataRes) {
			if (saveTableDataRes.updateEmployeeTable) {
				toast({
					title: t('Officials updated'),
					description: t('Officials updated successfully'),
					status: 'success',
					duration: 5000,
					isClosable: true,
					position: 'bottom-right',
				});
				getEmployee({
					variables: {
						id,
					},
				});
			}
		}
	}, [saveTableDataRes]);

	return (
		<>
			<div className="show-only-printing">{data?.getEmployeeById?.id && <PrintEmployee data={data?.getEmployeeById} />}</div>
			<Container className="hidden-print" maxW="container.xl" mt="16px" mb="16px">
				<BreadcrumbComponent
					list={
						checkOneRole(currentUser?.role, 'employee') || checkOneRole(currentUser?.role, 'admin')
							? [
									{
										name: t('OFFICIAL PROFILE'),
										path: '/employee/list?page=1',
									},
									{
										name: t('Detail'),
										path: `/employee/detail?id=${id}`,
									},
									{
										name: t('Edit'),
										path: `#`,
									},
							  ]
							: [
									{
										name: t('Curriculum Vitae'),
										path: `/employee/detail?id=${id}`,
									},
									{
										name: t('Edit'),
										path: `#`,
									},
							  ]
					}
				/>
				<Center mt="5">
					<Box
						w="100%"
						bg="white"
						borderRadius={c.borderRadius}
						border="1px solid #dbdbdb"
						boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
						pb="32px"
					>
						<Flex bg="#FAFAFA" p="16px" pt="8px" pb="8px" mb="16px" justifyContent="space-between" borderBottom="1px solid #dbdbdb">
							<Text fontSize="x-large" fontWeight="bold">
								{t('Edit')}
							</Text>
						</Flex>
						{loading && <Text textAlign="center">{t('Loading')}</Text>}

						{data?.getEmployeeById?.id && (
							<Box m="16px">
								<Flex direction="column" w="200px">
									<Image fallbackSrc={PlaceholderImage} src={data?.getEmployeeById?.profileImage?.file} w="100%" minH="250px" h="auto" />
									<Button
										variant="outline"
										size="sm"
										borderRadius="0px !important"
										leftIcon={<FontAwesomeIcon icon={faPenSquare} style={{ fontSize: 14 }} />}
										onClick={() => setIsEditProfileImage(true)}
										colorScheme="whatsapp"
									>
										{t(`Edit`)}
									</Button>
								</Flex>

								<PersonalInformation
									id={id}
									initData={{
										...data?.getEmployeeById,
									}}
									callbackOnSuccess={() => {
										getEmployee({
											variables: {
												id,
											},
										});
									}}
								/>
								<FirstWorkingPlaceAndCurrentWorkingStatus
									id={id}
									initData={{
										...data?.getEmployeeById?.firstWorkingPlace,
										...data?.getEmployeeById?.currentWorkingStatus,
									}}
									callbackOnSuccess={() => {
										getEmployee({
											variables: {
												id,
											},
										});
									}}
								/>
								<SimpleGrid>
									<TableForm
										name="additionalRoleOnCurrentJob"
										label={
											<Text fontSize="lg" pl="26px" fontWeight="bold" mt="4" mb="4" color="#2980b9">
												គ. តួនាទីបន្ថែមលើមុខងារបច្ចុប្បន្ន(ឋាន:ស្មើ)
											</Text>
										}
										labelKh={
											<Text fontSize="lg" pl="26px" fontWeight="bold" mt="4" mb="4" color="#2980b9">
												គ. តួនាទីបន្ថែមលើមុខងារបច្ចុប្បន្ន(ឋាន:ស្មើ)
											</Text>
										}
										columns={[
											{ name: 'id', label: 'id', labelKh: 'id', type: 'text', hide: true },
											{ name: 'no', label: 'ល.រ', labelKh: 'ល.រ', type: 'text' },
											{ name: 'date', label: 'ថ្ងៃខែឆ្នាំ', labelKh: 'ថ្ងៃខែឆ្នាំ', type: 'date' },
											{ name: 'file', label: 'ឯកសារ', labelKh: 'ឯកសារ', type: 'file' },
											{ name: 'position', label: 'មុខតំណែង', labelKh: 'មុខតំណែង', type: 'text' },
											{ name: 'title', label: 'ឋាន:ស្មើ', labelKh: 'ឋាន:ស្មើ', type: 'text' },
											{ name: 'division', label: 'អង្គភាព', labelKh: 'អង្គភាព', type: 'text' },
										]}
										orderBy={{
											field: 'no',
											type: 'asc',
										}}
										value={data?.getEmployeeById?.additionalRoleOnCurrentJob || []}
										onSave={(e) => saveTableData({ tableName: 'additionalRoleOnCurrentJob', data: e })}
									/>
								</SimpleGrid>
								<SimpleGrid>
									<TableForm
										name="situationRemainsWithinTheOriginalFramework"
										label={
											<Text fontSize="lg" pl="26px" fontWeight="bold" mt="4" mb="4" color="#2980b9">
												ឃ. ស្ថានភាពនៅស្ថិតនៅក្រៅក្របខ័ណ្ឌដើម
											</Text>
										}
										labelKh={
											<Text fontSize="lg" pl="26px" fontWeight="bold" mt="4" mb="4" color="#2980b9">
												ឃ. ស្ថានភាពនៅស្ថិតនៅក្រៅក្របខ័ណ្ឌដើម
											</Text>
										}
										columns={[
											{ name: 'id', label: 'id', labelKh: 'id', type: 'text', hide: true },
											{ name: 'no', label: 'ល.រ', labelKh: 'ល.រ', type: 'text' },
											{ name: 'description', label: 'បរិយាយ', labelKh: 'បរិយាយ', type: 'textArea' },
											{ name: 'startDate', label: 'កាលបរិច្ឆេទ​ចាប់ផ្តើម', labelKh: 'កាលបរិច្ឆេទ​ចាប់ផ្តើម', type: 'date' },
											{ name: 'endDate', label: 'កាលបរិច្ឆេទបញ្ចប់', labelKh: 'កាលបរិច្ឆេទបញ្ចប់', type: 'date' },
										]}
										orderBy={{
											field: 'no',
											type: 'asc',
										}}
										value={data?.getEmployeeById?.situationRemainsWithinTheOriginalFramework || []}
										onSave={(e) => saveTableData({ tableName: 'situationRemainsWithinTheOriginalFramework', data: e })}
									/>
								</SimpleGrid>
								<SimpleGrid>
									<TableForm
										name="unpaidVacancies"
										label={
											<Text fontSize="lg" pl="26px" fontWeight="bold" mt="4" mb="4" color="#2980b9">
												ង. ស្ថានភាពនៅទំនេរគ្មានបៀវត្ស
											</Text>
										}
										labelKh={
											<Text fontSize="lg" pl="26px" fontWeight="bold" mt="4" mb="4" color="#2980b9">
												ង. ស្ថានភាពនៅទំនេរគ្មានបៀវត្ស
											</Text>
										}
										columns={[
											{ name: 'id', label: 'id', labelKh: 'id', type: 'text', hide: true },
											{ name: 'no', label: 'ល.រ', labelKh: 'ល.រ', type: 'text' },
											{ name: 'description', label: 'បរិយាយ', labelKh: 'បរិយាយ', type: 'textArea' },
											{ name: 'startDate', label: 'កាលបរិច្ឆេទ​ចាប់ផ្តើម', labelKh: 'កាលបរិច្ឆេទ​ចាប់ផ្តើម', type: 'date' },
											{ name: 'endDate', label: 'កាលបរិច្ឆេទបញ្ចប់', labelKh: 'កាលបរិច្ឆេទបញ្ចប់', type: 'date' },
										]}
										orderBy={{
											field: 'no',
											type: 'asc',
										}}
										value={data?.getEmployeeById?.unpaidVacancies || []}
										onSave={(e) => saveTableData({ tableName: 'unpaidVacancies', data: e })}
									/>
								</SimpleGrid>

								<Text fontSize="xl" mt="4" fontWeight="bold" color="#c0392b">
									៣ ប្រវត្តិការងារ
								</Text>
								<SimpleGrid>
									<TableForm
										name="inTheFieldOfPublicFunctions"
										label={
											<Text fontSize="lg" pl="26px" fontWeight="bold" mt="4" mb="4" color="#2980b9">
												ក. ក្នុងវិស័យមុខងារសាធារណៈ
											</Text>
										}
										labelKh={
											<Text fontSize="lg" pl="26px" fontWeight="bold" mt="4" mb="4" color="#2980b9">
												ក. ក្នុងវិស័យមុខងារសាធារណៈ
											</Text>
										}
										columns={[
											{ name: 'id', label: 'id', labelKh: 'id', type: 'text', hide: true },
											{ name: 'startDate', label: 'ថ្ងៃចូលបំពេញការងារ', labelKh: 'ថ្ងៃចូលបំពេញការងារ', type: 'date' },
											{ name: 'endDate', label: 'បញ្ចប់បំពេញការងារ', labelKh: 'បញ្ចប់បំពេញការងារ', type: 'date' },
											{ name: 'institution', label: 'ក្រសួង/ស្ថាប័ន', labelKh: 'ក្រសួង/ស្ថាប័ន', type: 'text' },
											{ name: 'division', label: 'អង្គភាព', labelKh: 'អង្គភាព', type: 'text' },
											{ name: 'position', label: 'មុខតំណែង', labelKh: 'មុខតំណែង', type: 'text' },
											{ name: 'other', label: 'ផ្សេងៗ', labelKh: 'ផ្សេងៗ', type: 'text' },
										]}
										value={data?.getEmployeeById?.inTheFieldOfPublicFunctions || []}
										onSave={(e) => saveTableData({ tableName: 'inTheFieldOfPublicFunctions', data: e })}
									/>
								</SimpleGrid>
								<SimpleGrid>
									<TableForm
										name="inThePrivateSector"
										label={
											<Text fontSize="lg" pl="26px" fontWeight="bold" mt="4" mb="4" color="#2980b9">
												ខ. ក្នុងវិស័យឯកជន
											</Text>
										}
										labelKh={
											<Text fontSize="lg" pl="26px" fontWeight="bold" mt="4" mb="4" color="#2980b9">
												ខ. ក្នុងវិស័យឯកជន
											</Text>
										}
										columns={[
											{ name: 'id', label: 'id', labelKh: 'id', type: 'text', hide: true },
											{ name: 'startDate', label: 'ថ្ងៃចូលបំពេញការងារ', labelKh: 'ថ្ងៃចូលបំពេញការងារ', type: 'date' },
											{ name: 'endDate', label: 'បញ្ចប់បំពេញការងារ', labelKh: 'បញ្ចប់បំពេញការងារ', type: 'date' },
											{ name: 'institutionDivision', label: 'គ្រឺះស្ថាន/អង្គភាព', labelKh: 'គ្រឺះស្ថាន/អង្គភាព', type: 'text' },
											{ name: 'position', label: 'មុខតំណែង', labelKh: 'មុខតំណែង', type: 'text' },
											{ name: 'technicalSkill', label: 'ជំនាញបច្ចេកទេស', labelKh: 'ជំនាញបច្ចេកទេស', type: 'textArea' },
											{ name: 'other', label: 'ផ្សេងៗ', labelKh: 'ផ្សេងៗ', type: 'text' },
										]}
										value={data?.getEmployeeById?.inThePrivateSector || []}
										onSave={(e) => saveTableData({ tableName: 'inThePrivateSector', data: e })}
									/>
								</SimpleGrid>

								<Text fontSize="xl" mt="4" fontWeight="bold" color="#c0392b">
									៤ គ្រឿងឥស្សរិយយស ប័ណ្ណសរសើរ ឬទណ្ឌកម្មវិន័យ
								</Text>
								<SimpleGrid>
									<TableForm
										name="medalCertificateOfAppreciation"
										label={
											<Text fontSize="lg" pl="26px" fontWeight="bold" mt="4" mb="4" color="#2980b9">
												គ្រឿងឥស្សរិយយស ប័ណ្ណសរសើរ
											</Text>
										}
										labelKh={
											<Text fontSize="lg" pl="26px" fontWeight="bold" mt="4" mb="4" color="#2980b9">
												គ្រឿងឥស្សរិយយស ប័ណ្ណសរសើរ
											</Text>
										}
										columns={[
											{ name: 'id', label: 'id', labelKh: 'id', type: 'text', hide: true },
											{ name: 'fileNumber', label: 'លេខឯកសារ', labelKh: 'លេខឯកសារ', type: 'text' },
											{ name: 'date', label: 'ការបរិច្ឆេទ', labelKh: 'ការបរិច្ឆេទ', type: 'date' },
											{ name: 'institution', label: 'ស្ថាប័ន/អង្គភាព(ស្នើសុំ)', labelKh: 'ស្ថាប័ន/អង្គភាព(ស្នើសុំ)', type: 'text' },
											{ name: 'content', label: 'ខ្លឹមសារ', labelKh: 'ខ្លឹមសារ', type: 'textArea' },
											{ name: 'type', label: 'ប្រភេទ', labelKh: 'ប្រភេទ', type: 'text' },
											{ name: 'file', label: 'ឯកសារ', labelKh: 'ឯកសារ', type: 'file' },
										]}
										value={data?.getEmployeeById?.medalCertificateOfAppreciation || []}
										onSave={(e) => saveTableData({ tableName: 'medalCertificateOfAppreciation', data: e })}
									/>
								</SimpleGrid>
								<SimpleGrid>
									<TableForm
										name="discipline"
										label={
											<Text fontSize="lg" pl="26px" fontWeight="bold" mt="4" mb="4" color="#2980b9">
												ទណ្ឌកម្មវិន័យ
											</Text>
										}
										labelKh={
											<Text fontSize="lg" pl="26px" fontWeight="bold" mt="4" mb="4" color="#2980b9">
												ទណ្ឌកម្មវិន័យ
											</Text>
										}
										columns={[
											{ name: 'id', label: 'id', labelKh: 'id', type: 'text', hide: true },
											{ name: 'fileNumber', label: 'លេខឯកសារ', labelKh: 'លេខឯកសារ', type: 'text' },
											{ name: 'date', label: 'ការបរិច្ឆេទ', labelKh: 'ការបរិច្ឆេទ', type: 'date' },
											{ name: 'institution', label: 'ស្ថាប័ន/អង្គភាព(ស្នើសុំ)', labelKh: 'ស្ថាប័ន/អង្គភាព(ស្នើសុំ)', type: 'text' },
											{ name: 'content', label: 'ខ្លឹមសារ', labelKh: 'ខ្លឹមសារ', type: 'textArea' },
											{ name: 'type', label: 'ប្រភេទ', labelKh: 'ប្រភេទ', type: 'text' },
											{ name: 'file', label: 'ឯកសារ', labelKh: 'ឯកសារ', type: 'file' },
										]}
										value={data?.getEmployeeById?.discipline || []}
										onSave={(e) => saveTableData({ tableName: 'discipline', data: e })}
									/>
								</SimpleGrid>

								<Text fontSize="xl" mt="4" fontWeight="bold" color="#c0392b">
									៥ កម្រិត​វប្បធម៌ទូទៅ ការបណ្តុះបណ្តាលវិជ្ជាជីវៈ និងការបណ្តុះបណ្តាលបន្ត
								</Text>
								{[
									{
										name: 'generalEducation',
										label: 'កម្រិត​វប្បធម៌ទូទៅ',
										labelKh: 'កម្រិត​វប្បធម៌ទូទៅ ',
										value: data?.getEmployeeById?.generalEducation,
									},
									{ name: 'degree', label: 'កម្រិតសញ្ញាបត្រ', labelKh: 'កម្រិតសញ្ញាបត្រ', value: data?.getEmployeeById?.degree },
									{ name: 'specialization', label: 'ជំនាញឯកទេស', labelKh: 'ជំនាញឯកទេស', value: data?.getEmployeeById?.specialization },
									{
										name: 'vocationalTraining',
										label: 'វគ្តបណ្តុះបណ្តាលវិជ្ជាជីវៈ (ក្រោម ១២ ខែ)',
										labelKh: 'វគ្តបណ្តុះបណ្តាលវិជ្ជាជីវៈ (ក្រោម ១២ ខែ)',
										value: data?.getEmployeeById?.vocationalTraining,
									},
								].map((item, index) => {
									return (
										<SimpleGrid key={index}>
											<TableForm
												name={item?.name}
												label={
													<Text fontSize="lg" pl="26px" fontWeight="bold" mt="4" mb="4" color="#2980b9">
														{item?.label}
													</Text>
												}
												labelKh={
													<Text fontSize="lg" pl="26px" fontWeight="bold" mt="4" mb="4" color="#2980b9">
														{item?.labelKh}
													</Text>
												}
												columns={[
													{ name: 'id', label: 'id', labelKh: 'id', type: 'text', hide: true },
													{ name: 'courseOrLevelOfStudy', label: 'វគ្គ ឬកម្រិតសិក្សា', labelKh: 'វគ្គ ឬកម្រិតសិក្សា', type: 'text' },
													{
														name: 'trainingInstitutions',
														label: 'គ្រឹះស្ថានសិក្សាបណ្តុះបណ្តាល',
														labelKh: 'គ្រឹះស្ថានសិក្សាបណ្តុះបណ្តាល',
														type: 'text',
													},
													{ name: 'DegreeObtained', label: 'សញ្ញាបត្រដែលទទួលបាន', labelKh: 'សញ្ញាបត្រដែលទទួលបាន', type: 'text' },
													{ name: 'dateOfEnrollment', label: 'ថ្ងៃខែឆ្នាំចូលសិក្សា', labelKh: 'ថ្ងៃខែឆ្នាំចូលសិក្សា', type: 'date' },
													{
														name: 'dateOfGraduation',
														label: 'ថ្ងៃខែឆ្នាំបញ្ចប់សិក្សា',
														labelKh: 'ថ្ងៃខែឆ្នាំបញ្ចប់សិក្សា',
														type: 'date',
													},
													{ name: 'file', label: 'ឯកសារ', labelKh: 'ឯកសារ', type: 'file' },
												]}
												value={item?.value || []}
												onSave={(e) => saveTableData({ tableName: item?.name, data: e })}
											/>
										</SimpleGrid>
									);
								})}
								<SimpleGrid>
									<TableForm
										name="foreignLanguage"
										label={
											<Text fontSize="xl" fontWeight="bold" color="#c0392b">
												៦ សមត្ថភាពភាសាបរទេស
											</Text>
										}
										labelKh={
											<Text fontSize="xl" fontWeight="bold" color="#c0392b">
												៦. សមត្ថភាពភាសាបរទេស
											</Text>
										}
										orderBy={{
											field: 'no',
											type: 'asc',
										}}
										columns={[
											{ name: 'id', label: 'id', labelKh: 'id', type: 'text', hide: true },
											{ name: 'no', label: 'No', labelKh: 'ល.រ', type: 'text' },
											{ name: 'language', label: 'ភាសា', labelKh: 'ភាសា', type: 'text' },
											{ name: 'read', label: 'អាន', labelKh: 'អាន', type: 'text' },
											{ name: 'write', label: 'សរសេរ', labelKh: 'សរសេរ', type: 'text' },
											{ name: 'speak', label: 'និយាយ', labelKh: 'និយាយ', type: 'text' },
											{ name: 'listen', label: 'ស្តាប់', labelKh: 'ស្តាប់', type: 'text' },
										]}
										value={data?.getEmployeeById?.foreignLanguage || []}
										onSave={(e) => saveTableData({ tableName: 'foreignLanguage', data: e })}
									/>
								</SimpleGrid>

								<Text mt="4" fontSize="xl" fontWeight="bold" color="#c0392b">
									៧. ស្ថានភាពគ្រូសារ
								</Text>
								<FamilyStatus
									id={id}
									initData={{
										...data?.getEmployeeById?.familyStatus,
									}}
									callbackOnSuccess={() => {
										getEmployee({
											variables: {
												id,
											},
										});
									}}
								/>

								<SimpleGrid>
									<TableForm
										name="siblingInformation"
										label={
											<Text fontSize="lg" pl="26px" fontWeight="bold" mt="4" mb="4" color="#2980b9">
												ខ. ព័ត៌មានបងប្អូន
											</Text>
										}
										labelKh={
											<Text fontSize="lg" pl="26px" fontWeight="bold" mt="4" mb="4" color="#2980b9">
												ខ. ព័ត៌មានបងប្អូន
											</Text>
										}
										columns={[
											{ name: 'id', label: 'id', labelKh: 'id', type: 'text', hide: true },
											{ name: 'no', label: 'ល.រ', labelKh: 'ល.រ', type: 'text' },
											{ name: 'fullName', label: 'គោត្តនាម និងនាម', labelKh: 'គោត្តនាម និងនាម', type: 'text' },
											{ name: 'fullNameInLatin', label: 'ជាអក្សរឡាតាំង', labelKh: 'ជាអក្សរឡាតាំង', type: 'capitalText' },
											{
												name: 'gender',
												label: 'ភេទ',
												labelKh: 'ភេទ',
												type: 'radio',
												options: [
													{ value: 'male', label: 'ប្រុស' },
													{ value: 'female', label: 'ស្រី' },
												],
											},
											{ name: 'dob', label: 'ថ្ងៃខៃឆ្នាំកំណើត', labelKh: 'ថ្ងៃខៃឆ្នាំកំណើត', type: 'date' },
											{ name: 'job', label: 'មុខរបរ(អង្គភាព)', labelKh: 'មុខរបរ(អង្គភាព)', type: 'text' },
										]}
										orderBy={{
											field: 'no',
											type: 'asc',
										}}
										value={data?.getEmployeeById?.siblingInformation || []}
										onSave={(e) => saveTableData({ tableName: 'siblingInformation', data: e })}
									/>
								</SimpleGrid>

								<SpouseStatus
									id={id}
									initData={{
										...data?.getEmployeeById?.spouseStatus,
									}}
									callbackOnSuccess={() => {
										getEmployee({
											variables: {
												id,
											},
										});
									}}
								/>
								<SimpleGrid>
									<TableForm
										name="childInformation"
										label={
											<Text fontSize="lg" pl="26px" fontWeight="bold" mt="4" mb="4" color="#2980b9">
												ឃ. ព័ត៌មានកូន
											</Text>
										}
										labelKh={
											<Text fontSize="lg" pl="26px" fontWeight="bold" mt="4" mb="4" color="#2980b9">
												ឃ. ព័ត៌មានកូន
											</Text>
										}
										columns={[
											{ name: 'id', label: 'id', labelKh: 'id', type: 'text', hide: true },
											{ name: 'no', label: 'ល.រ', labelKh: 'ល.រ', type: 'text' },
											{ name: 'fullName', label: 'គោត្តនាម និងនាម', labelKh: 'គោត្តនាម និងនាម', type: 'text' },
											{ name: 'fullNameInLatin', label: 'ជាអក្សរឡាតាំង', labelKh: 'ជាអក្សរឡាតាំង', type: 'capitalText' },
											{
												name: 'gender',
												label: 'ភេទ',
												labelKh: 'ភេទ',
												type: 'radio',
												options: [
													{ value: 'male', label: 'ប្រុស' },
													{ value: 'female', label: 'ស្រី' },
												],
											},
											{ name: 'dob', label: 'ថ្ងៃខៃឆ្នាំកំណើត', labelKh: 'ថ្ងៃខៃឆ្នាំកំណើត', type: 'date' },
											{ name: 'job', label: 'មុខរបរ(អង្គភាព)', labelKh: 'មុខរបរ(អង្គភាព)', type: 'text' },
											{ name: 'allowance', label: 'ប្រាក់ឧបត្ថម្ភ', labelKh: 'ប្រាក់ឧបត្ថម្ភ', type: 'text' },
											{ name: 'file', label: 'ឯកសារយោង', labelKh: 'ឯកសារយោង', type: 'file' },
										]}
										orderBy={{
											field: 'no',
											type: 'asc',
										}}
										value={data?.getEmployeeById?.childInformation || []}
										onSave={(e) => saveTableData({ tableName: 'childInformation', data: e })}
									/>
								</SimpleGrid>
							</Box>
						)}
					</Box>
				</Center>
			</Container>

			{isEditProfileImage && (
				<EditProfileImage
					id={id}
					onClose={() => setIsEditProfileImage(false)}
					callbackOnSuccess={() => {
						setIsEditProfileImage(false);
						getEmployee({
							variables: {
								id,
							},
						});
					}}
				/>
			)}
		</>
	);
}

export default Create;
