/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Button, useToast, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { gql, useMutation } from '@apollo/client';
import CompanyInputOrSelectFormField from '../form/CompanyInputOrSelectFormField';
import TextFormField from '../form/TextFormField';
import FileFormField from '../form/FileFormField';
import c from '../../constant';
import * as Yup from 'yup';
import moment from "moment";
import DateFormField from "../form/DateFormField";
 
const M_CREATE = gql`
	mutation create(
		$companyId: String!
		$actuaryName: String
		$actuaryId: String
		$designation: String
		$qualification: String
		$approvalLetter: String
		$attachmentFile: Upload
		$attachmentFileSize: Float
		$approvalDate: DateTime
	) {
		createCompanyActuary(
			companyId: $companyId
			actuaryName: $actuaryName
			actuaryId: $actuaryId
			designation: $designation
			qualification: $qualification
			approvalLetter: $approvalLetter
			attachmentFile: $attachmentFile
			attachmentFileSize: $attachmentFileSize
			approvalDate:$approvalDate
		)
	}
`;

const validationSchema = Yup.object().shape({
	nameActuaryOrCompany: Yup.mixed().test('empty', 'required', (value) => value?.companyName || value?.companyId),
	designation: Yup.string().nullable(),
	qualification: Yup.string().nullable(),
	approvalLetter: Yup.string().nullable(),
	approvalDate: Yup.string().nullable(),
	attachmentFile: Yup.mixed().test('fileSize', 'over limit', (value) => {
		if (value) {
			return value?.size <= c.maxFileSize / 10;
		} else {
			return true;
		}
	}),
});

function AddCompanyActuaryModal({ companyId = null, isOpen = false, onClose = () => null, onCallback = () => null }) {
	const toast = useToast();
	const { t } = useTranslation();
	const [createCompanyActuary, { loading, error, data }] = useMutation(M_CREATE);

	useEffect(() => {
		if (data) {
			if (data?.createCompanyActuary) {
				toast({
					title: t('Operation success'),
					status: 'success',
					isClosable: true,
					position: 'top-right',
				});
				onClose();
				onCallback();
			}
		}
	}, [data]);

	useEffect(() => {
		if (error) {
			error.graphQLErrors.map(({ message }, i) =>
				toast({
					title: t(message),
					status: 'error',
					isClosable: true,
					position: 'top-right',
				})
			);
		}
	}, [error]);

	return (
		<>
			<Modal closeOnEsc={!loading} closeOnOverlayClick={!loading} isOpen={isOpen} onClose={onClose} size="md">
				<ModalOverlay />
				<ModalContent borderRadius={c.borderRadius} pb="2">
					<ModalHeader>{t('Add')}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Formik
							initialValues={{
								nameActuaryOrCompany: { companyName: null, companyId: null },
								designation: '',
								qualification: '',
								approvalLetter: '',
								attachmentFile: null,
							}}
							validationSchema={validationSchema}
							onSubmit={(values) => {
								const variables = {
									companyId: companyId,
									actuaryId: values?.nameActuaryOrCompany?.companyId,
									actuaryName: values?.nameActuaryOrCompany?.companyName,
									designation: values?.designation || null,
									qualification: values?.qualification || null,
									approvalLetter: values?.approvalLetter || null,
									attachmentFile: values?.attachmentFile,
									attachmentFileSize: values?.attachmentFile?.size,
									approvalDate: values.approvalDate?moment(values.approvalDate).toDate():null,
								};
								createCompanyActuary({ variables });
							}}
						>
							{(props) => (
								<Form>
									<Box mt="3">
										<CompanyInputOrSelectFormField
											label="Name of Actuary / Company"
											name="nameActuaryOrCompany"
											inputPlaceholder="Name of Actuary / Company"
											inputOnly
											required
										/>
										<TextFormField {...props} label="Designation" placeholder={t('Designation')} name="designation" />
										<TextFormField {...props} label="Qualification" placeholder={t('Qualification')} name="qualification" />
										{/* <TextFormField {...props} label="Approval Letter" placeholder={t('Approval Letter')} name="approvalLetter" /> */}
										
										<DateFormField
                                            name="approvalDate"
                                            label="Approval Date"
                                        />

										<FileFormField {...props} label="Attachment" name="attachmentFile" maxFileSize={c.maxFileSize / 10} />
										<Flex textAlign="right" mt="6" justifyContent="space-between" alignItems="center">
											<Button
												id="login-btn"
												variant="solid"
												size="md"
												h="42px"
												w="128px"
												bg="primary.dark"
												_hover={{ bg: 'primary.dark' }}
												color="white"
												type="submit"
												isLoading={loading}
												loadingText={t('Add')}
												spinnerPlacement="start"
											>
												{t(`Add`)}
											</Button>
										</Flex>
									</Box>
								</Form>
							)}
						</Formik>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
}

export default AddCompanyActuaryModal;
