/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Flex, Text, CircularProgress, Box } from '@chakra-ui/react';
import { SectionHeader } from '../Other';
import c from '../../constant';

function LeaveUseCard({ title, loading, data }) {
	return (
		<>
			{loading && (
				<Box>
					<Flex justifyContent="center">
						<CircularProgress isIndeterminate size={8} />
					</Flex>
				</Box>
			)}
			{false === loading && null !== data && (
				<Box
					width="100%"
					bg="white"
					borderRadius={c.borderRadius}
					border="1px solid #dbdbdb"
					boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
				>
					<Flex
						p={4}
						h="48px"
						bg="#FAFAFA"
						alignItems="center"
						justifyContent="center"
						borderBottom="1px solid #dbdbdb"
						overflow="hidden"
					>
						<SectionHeader fontSize="sm" title={title} />
					</Flex>
					<Flex p={4} alignItems="center" justifyContent="center">
						<Text fontSize="2xl" fontWeight="semibold">
							{data}
						</Text>
					</Flex>
				</Box>
			)}
		</>
	);
}

export default LeaveUseCard;
