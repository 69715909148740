import React, { useEffect, useState } from "react";
import { Box, Text, Flex, Input, CircularProgress , useToast, Checkbox } from "@chakra-ui/react";
import fileUpload from "../../providers/privateFileUpload"
import c from "../../constant"
import UploadingModal from "../UploadingModal/UploadingModal"
import { useTranslation } from "react-i18next";

const PrivateFilePicker =React.memo(function ({initContent, onContentChange}) {
    const { t } = useTranslation();
    const toast = useToast()
    const [original, setOriginal] = useState(initContent?.file);
    
    const [fileName, setFileName] = useState(initContent?.fileName);
    const [fileSize, setFileSize] = useState(initContent?.fileSize);
    
    const [updated, setUpdated] = useState(false)

    const [loading, setLoading] = useState(false)
    const [error, serError] = useState(false)

    const [isDefaultDownload, setIsDefaultDownload] = useState(initContent?.isDefaultDownload?initContent?.isDefaultDownload:false)
    
    function errorMessage(type){
        toast({
            title: `File type of ${type} failed to upload`,
            status: "error",
            position:"top-right",
            isClosable: true,
        })
    }


    useEffect(()=>{
        if(original){
            const myArray = original.split("/");
            const fileName = myArray[myArray?.length-1]; 
            const content = {
                file:original,
                fileName:fileName||"N/A",
                fileSize,
            }
            onContentChange(content)
        }
    },[original,fileName, isDefaultDownload])

    function fileChangedHandler(event) {
        var fileInput = false;
        if (event.target.files[0]) {
            setFileSize(event.target.files[0].size)
            fileInput = true;
        }
        if (fileInput) {
            setLoading(true)
            fileUpload(event.target.files[0]).then(response=>{
                setOriginal(response);
                setUpdated(true)
                setLoading(false)
            }).catch((error)=>{
                setLoading(false)
                errorMessage("PDF")
                serError(true)
            })
        }
    }

    return (
        <>
        <Box w="100%" bg="gray.200" p="4" mb="4" borderRadius={c.borderRadius}>
            <Flex alignItems="center" mt="4" mb="4">
                <Text fontWeight="bold" mr="8">
                    {t("Please choose a file")}
                </Text>
                {loading&&<>
                        <CircularProgress size="32px" isIndeterminate color="orange.300" />
                        <Text ml="2">{t("Uploading File, Please wait until the upload finishes")}</Text>
                </>}
            </Flex>
            <input
                id="file"
                type="file"
                accept="*"
                onChange={fileChangedHandler}
            />
            <br />
        </Box>
        <UploadingModal isOpen={(!error&&loading)}/>
        </>
    );
});

export default PrivateFilePicker;
