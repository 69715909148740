import React from "react";
import {
  Flex,
  Text
} from "@chakra-ui/react";
import useStore from "../store";

function Footer() {
  const { currentLang } = useStore((state) => state.currentLang);

  return (
    <Flex
      className="hidden-print"
      boxShadow="0px 3px 3px #00000029"
      bg="#FAFAFA"
      justifyContent="center"
    >
      <Flex
        h="80px"
        maxW="95%"
        flex="1"
        alignItems="center"
        justifyContent="space-between"
      >
        {/* Footer */}
        <Text fontWeight="500" ml="2" mt="2">{currentLang === "en"?`©2022, INSURANCE REGULATOR of CAMBODIA. All rights reserved.`:`©2022, រក្សាសិទ្ធិដោយនិយ័តករធានារ៉ាប់រងកម្ពុជា`}</Text>
      </Flex>
    </Flex>
  );
}

export default Footer;
