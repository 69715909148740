import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Container,
  Box,
  Flex,
  Text,
  Center,
  Table,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  Badge,
  Checkbox,
  Button,
} from "@chakra-ui/react";
import BreadcrumbComponent from "../../components/BreadcrumbComponent/BreadcrumbComponent";
import { faSlidersH, faPrint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import c from "../../constant";
import { gql, useLazyQuery } from "@apollo/client";
import moment from "moment";
import ReactPaginate from "react-paginate";
import "./style.css";
import { useLocation, useHistory } from "react-router-dom";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import useStore from "../../store";
import CompanySubmissionReportFilter from "../../components/Filter/CompanySubmissionReportFilter";
import LoadingTable from "../../components/Table/Loading";
import datePickerFromToToDate from "../../utils/datePickerFromToToDate";
import PrintLayout from "../../components/Print/PrintLayout";

const limit = 10;
const QUERY_REPORTS = gql`
  query getCompanySubmissionReports(
    $limit: Float
    $offset: Float
    $fromDate: DateTime
    $toDate: DateTime
    $read: Boolean
    $companyName: String
    $type: CompanySubmissionReportTypes
    $spam: Boolean
    $reportType: CompanySubmissionReportTypesOfReport
    $newReportType: String
  ) {
    getCompanySubmissionReports(
      pagerInput: { limit: $limit, offset: $offset }
      fromDate: $fromDate
      toDate: $toDate
      read: $read
      companyName: $companyName
      type: $type
      spam: $spam
      reportType: $reportType
      newReportType: $newReportType
    ) {
      companySubmissionReports {
        id
        companyName
        createdAt
        type
        reportType
        spam
        company {
          id
          name
          nameKhmer
          logo
        }
        notification {
          read
        }
        assignTo {
          user {
            id
            username
            fullName
            fullNameKhmer
            avatar
          }
          createdBy
        }
        newTypeDetail {
          titleKh
          title
        }
      }
      countUnreadCompanySubmissionReports
      totalPages
      totalRows
    }
  }
`;

function Report() {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const urlParams = new URLSearchParams(location.search);
  const currentPage = urlParams.get("page") ? urlParams.get("page") : 1;
  const { currentLang } = useStore((state) => state.currentLang);
  const companyName = urlParams.get("companyName") ? urlParams.get("companyName") : null;
  const fromDate = urlParams.get("fromDate") ? urlParams.get("fromDate") : null;
  const toDate = urlParams.get("toDate") ? urlParams.get("toDate") : null;
  const read = urlParams.get("read") ? urlParams.get("read") : null;
  const type = urlParams.get("type") ? urlParams.get("type") : null;
  const reportType = urlParams.get("reportType") ? urlParams.get("reportType") : null;
  const newReportType = urlParams.get("newReportType") ? urlParams.get("newReportType") : null;

  const showSpam = urlParams.get("showSpam") ? urlParams.get("showSpam") : null;
  const { currentUser } = useStore((state) => state.currentUser);
  const [rowLimit, setRowLimit] = useState(10);

  moment.locale(currentLang === "kh" ? "km" : "en-gb");
  const [loadReports, { error, loading, data }] = useLazyQuery(QUERY_REPORTS);

  useEffect(() => {
    window.scrollTo(0, 0);
    loadReports({
      variables: {
        companyName,
        offset: currentPage ? (currentPage - 1) * limit : 0,
        fromDate: datePickerFromToToDate(fromDate),
        toDate: datePickerFromToToDate(toDate),
        read: read === "false" ? false : read === "true" ? true : null,
        // newType: type==="all"?null:type,
        type: type === "all" ? null : type,
        newReportType: newReportType === "all" ? null : newReportType,
        limit: rowLimit,
      },
    });
  }, []);

  const [openFilter, setOpenFilter] = useState(false);

  function handlePageClick(data) {
    let selected = data.selected;
    const page = selected + 1;

    let url = `?page=${page}`;
    if (companyName) {
      url += `&companyName=${companyName}`;
    }
    if (fromDate) {
      url += `&fromDate=${fromDate}`;
    }
    if (toDate) {
      url += `&toDate=${toDate}`;
    }
    if (type) {
      url += `&type=${type}`;
    }
    if (reportType) {
      url += `&reportType=${reportType}`;
    }
    if (newReportType) {
      url += `&newReportType=${newReportType}`;
    }
    if (read !== null) {
      const unreadOnly = read ? read : "";
      if (unreadOnly) {
        url += `&read=false`;
      }
    }
    if (showSpam) {
      url += `&showSpam=${showSpam}`;
    }
    history.push(`/company-submission-report${url}`);
  }

  useEffect(() => {
    if (currentPage) {
      window.scrollTo(0, 0);
      loadReports({
        variables: {
          companyName,
          offset: currentPage ? (currentPage - 1) * limit : 0,
          fromDate: datePickerFromToToDate(fromDate),
          toDate: datePickerFromToToDate(toDate),
          read: read === "false" ? false : read === "true" ? true : null,
          type: type === "all" ? null : type,
          newReportType: newReportType === "all" ? null : newReportType,
          spam: showSpam === "true" ? true : null,
          limit: rowLimit,
        },
      });
    }
  }, [rowLimit, currentPage, fromDate, toDate, read, companyName, type, showSpam, reportType, newReportType]);

  function reportTypeBadge(type) {
    switch (type) {
      case "MONTHLY":
        return <Badge colorScheme="green">{t("MONTHLY")}</Badge>;
      case "QUARTERLY":
        return <Badge colorScheme="yellow">{t("QUARTERLY")}</Badge>;
      case "SEMESTERLY":
        return <Badge colorScheme="purple">{t("SEMESTERLY")}</Badge>;
      case "ANNUALLY":
        return <Badge colorScheme="red">{t("ANNUALLY")}</Badge>;
      default:
        return null;
    }
  }

  function reportTypeOfReports(type, newTypeDetail) {
    switch (type) {
      case "FINANCIAL_REPORT":
        return <Badge colorScheme="green">{t("Financial Report")}</Badge>;
      case "COMPLAINT_SETTLEMENT_REPORT":
        return <Badge colorScheme="yellow">{t("Complaint Settlement Report")}</Badge>;
      case "INSURANCE_AGENT_REPORT":
        return <Badge colorScheme="purple">{t("Insurance Agent Report")}</Badge>;
      case "NEW_TYPE":
        return <Badge colorScheme="gray">{currentLang === "kh" ? newTypeDetail?.titleKh : newTypeDetail?.title}</Badge>;
      default:
        break;
    }
  }

  function callbackFilter(e) {
    history.push(`/company-submission-report${e}`);
  }

  function getDisplayCompanyName(item) {
    let displayName = item?.companyName;
    if (item?.company?.id) {
      if (currentLang === "kh") {
        displayName = item?.company?.nameKhmer;
      } else {
        displayName = item?.company?.name;
      }
    }
    return displayName;
  }

  function checkReadRow(item) {
    let read = true;
    read = item?.notification?.read;

    return read;
  }

  function panelTabs({ name, value }) {
    let active = type ? type : "all";

    return (
      <Flex
        userSelect="none"
        minW="100px"
        onClick={() => {
          handleTabChange(value);
        }}
        cursor="pointer"
        mb={"-2px"}
        _hover={{ boxShadow: `inset 0 -2px 0 0 ${value === active ? "#2980b9" : "#bdc3c7"}` }}
        boxShadow={value === active ? "inset 0 -2px 0 0 #2980b9" : ""}
        pt="16px"
        pb="16px"
        pl="12px"
        pr="12px"
        alignItems="center"
        justifyContent="center"
      >
        <Text>{t(name)}</Text>
      </Flex>
    );
  }

  function handleTabChange(tabValue) {
    const page = 1;

    let url = `?page=${page}`;

    if (tabValue) {
      url += `&type=${tabValue}`;
    }
    if (companyName) {
      url += `&companyName=${companyName}`;
    }
    if (fromDate) {
      url += `&fromDate=${fromDate}`;
    }
    if (toDate) {
      url += `&toDate=${toDate}`;
    }
    if (read !== null) {
      const unreadOnly = read ? read : "";
      if (unreadOnly) {
        url += `&read=false`;
      }
    }
    if (showSpam) {
      url += `&showSpam=${showSpam}`;
    }
    history.push(`/company-submission-report${url}`);
  }

  return (
    <PrintLayout>
      <>
        <Container maxW="container.xl" mt="16px" mb="16px">
          <BreadcrumbComponent
            list={[
              {
                name: "COMPANY REPORT",
                path: "#",
              },
            ]}
          />
          <Center className="show-only-printing">
            <Text textAlign="center" color="#62B3ED" fontFamily="Moul" fontSize={18}>
              របាយការណ៍ក្រុមហ៊ុន
            </Text>
          </Center>
          <Center mt="5">
            <Box
              w="100%"
              bg="white"
              borderRadius={c.borderRadius}
              border="1px solid #dbdbdb"
              boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
              pb="32px"
            >
              <Flex
                className="hidden-print"
                bg="#FAFAFA"
                p="16px"
                pt="0"
                pb="0"
                mb="16px"
                alignItems="center"
                justifyContent="space-between"
                borderBottom="1px solid #dbdbdb"
              >
                <Flex pb="2px">
                  {tabList.map((item) => {
                    return panelTabs({ name: item.label, value: item.value });
                  })}
                </Flex>
                <Flex alignItems="center">
                  <Button
                    mr="2"
                    onClick={() => window?.print()}
                    leftIcon={<FontAwesomeIcon icon={faPrint} style={{ fontSize: 16 }} />}
                    colorScheme="gray"
                    variant="solid"
                    size="sm"
                    borderRadius={c.borderRadius}
                    border="1px solid #bdc3c7"
                  >
                    {t("Print")}
                  </Button>
                  <Button
                    onClick={() => setOpenFilter(true)}
                    leftIcon={<FontAwesomeIcon icon={faSlidersH} style={{ fontSize: 16 }} />}
                    colorScheme={`${
                      companyName || fromDate || toDate || read || showSpam || newReportType ? "yellow" : "gray"
                    }`}
                    variant="solid"
                    size="sm"
                    borderRadius={c.borderRadius}
                    border="1px solid #bdc3c7}"
                  >
                    {t("Filter")}
                  </Button>
                  {/* <FontAwesomeIcon onClick={()=>setOpenFilter(true)} icon={faSlidersH} style={{fontSize:18, cursor: "pointer", color:(companyName || fromDate || toDate || read || showSpam || newReportType)?"#c0392b":"black"}}/> */}
                </Flex>
              </Flex>

              <Box p="16px">
                <Table variant="simple">
                  <Tbody>
                    <Tr className="td-as-th">
                      <Th>{t("Company Name")}</Th>
                      <Th>{t("Created At")}</Th>
                      <Th isNumeric>{t("Report Type")}</Th>
                      <Th isNumeric>{t("Type")}</Th>
                    </Tr>
                    {loading && <LoadingTable column={4} />}
                    {data?.getCompanySubmissionReports?.companySubmissionReports.length <= 0 && (
                      <Tr>
                        <Td>
                          <Text>{t("No Data")}</Text>
                        </Td>
                      </Tr>
                    )}
                    {data?.getCompanySubmissionReports?.companySubmissionReports?.map((item, index) => {
                      return (
                        <Tr
                          cursor="pointer"
                          _hover={{ bg: "gray.200" }}
                          onClick={() => history.push(`/company-submission-report/detail?id=${item.id}`)}
                          key={`item-${index}`}
                          bg={checkReadRow(item) === false ? "gray.100" : "white"}
                        >
                          <Td fontWeight="500">
                            <Text maxW="400px" noOfLines={4}>
                              {getDisplayCompanyName(item)}
                            </Text>
                          </Td>
                          <Td>{moment(item.createdAt).format("LLLL")}</Td>
                          <Td isNumeric>{reportTypeOfReports(item.reportType, item?.newTypeDetail)}</Td>
                          <Td isNumeric>
                            {reportTypeBadge(item.type)}{" "}
                            {item.spam === true && <Badge colorScheme="red">{t("Spam")}</Badge>}
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                  <Tfoot className="hidden-print">
                    <Tr>
                      <Th>{t("Company Name")}</Th>
                      <Th>{t("Created At")}</Th>
                      <Th isNumeric>{t("Report Type")}</Th>
                      <Th isNumeric>{t("Type")}</Th>
                    </Tr>
                    <Tr>
                      <Th />
                      <Th />
                      <Th />
                      <Th isNumeric>
                        {t("Total Rows")}: {data?.getCompanySubmissionReports?.totalRows}
                      </Th>
                    </Tr>
                  </Tfoot>
                </Table>
                <Flex className="hidden-print" justifyContent="flex-end" w="100%" mt="4">
                  {data?.getCompanySubmissionReports?.totalPages > 1 && (
                    <ReactPaginate
                      previousLabel={<ChevronLeftIcon fontSize="20px" />}
                      nextLabel={<ChevronRightIcon fontSize="20px" />}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={data?.getCompanySubmissionReports?.totalPages}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                      initialPage={(currentPage - 1) | 0}
                    />
                  )}
                </Flex>
                <Box justifyContent="flex-end" className="show-only-printing">
                  <Text ml="2" mt="4">
                    {t("Total Rows")}: <b>{data?.getCompanySubmissionReports?.totalRows}</b>
                  </Text>
                </Box>
                <Flex className="hidden-print" justifyContent="flex-end" mt="6">
                  <Text mr="2">{t("Show more rows")}</Text>
                  <Checkbox
                    isChecked={rowLimit > 10}
                    onChange={() => {
                      handlePageClick({ selected: 0 });
                      if (rowLimit > 10) {
                        setRowLimit(10);
                      } else {
                        setRowLimit(300);
                      }
                    }}
                  ></Checkbox>
                </Flex>
              </Box>
            </Box>
          </Center>
        </Container>
        {openFilter && (
          <CompanySubmissionReportFilter
            callback={callbackFilter}
            isOpen={true}
            onClose={() => setOpenFilter(false)}
            fromDate={fromDate}
            toDate={toDate}
            read={read}
            companyName={companyName}
            type={type}
            showSpam={showSpam === "true" ? true : false}
            reportType={reportType}
            newReportType={newReportType}
          />
        )}
      </>
    </PrintLayout>
  );
}

export default Report;

const tabList = [
  { label: "All", value: "all" },
  { label: "Monthly", value: "MONTHLY" },
  { label: "Quarterly", value: "QUARTERLY" },
  { label: "Semesterly", value: "SEMESTERLY" },
  { label: "Annually", value: "ANNUALLY" },
];
