/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import {
	Box,
	Button,
	Flex,
	Image,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Text,
	Tooltip,
	useDisclosure,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import LogoPlaceholder from '../../../../assets/images/logo-placeholder.png';
import TextFormField from '../../../../components/form/TextFormField';
import useStore from '../../../../store';
import c from '../../../../constant';
import * as Yup from 'yup';

const Q_DETAIL = gql`
	query get($id: String!) {
		getCompanySubmissionProposalById(id: $id) {
			id
			company {
				id
				name
				nameKhmer
				geoManual
			}
			additionalFeatureData
		}
	}
`;

const M_UPDATE = gql`
	mutation update($proposalId: String!, $data: JSON!) {
		manuallyGenerateAdditionalFeatureNote(proposalId: $proposalId, data: $data)
	}
`;

const validationSchema = Yup.object().shape({
	documentNumber: Yup.string().required('Required'),
	khmerName: Yup.string().required('Required'),
	latinName: Yup.string().required('Required'),
	address: Yup.string().required('Required'),
	lunarSignedDate: Yup.string().required('Required'),
	solarSignedDate: Yup.string().required('Required'),
});

export default function InvoiceProgressRegenerateNote({ id = null, callback = () => null }) {
	const { t } = useTranslation();
	const { currentUser: user } = useStore((state) => state.currentUser);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { isOpen: isOpenForm, onOpen: onOpenForm, onClose: onCloseForm } = useDisclosure();
	const [loadDetail, { data }] = useLazyQuery(Q_DETAIL);
	const [updateDetail, { data: dataDetail, loading: loadingDetail }] = useMutation(M_UPDATE);

	useEffect(() => {
		if (id && user?.role === 'admin') {
			loadDetail({ variables: { id } });
		}
	}, [id, user]);

	useEffect(() => {
		if (dataDetail?.manuallyGenerateAdditionalFeatureNote) {
			callback();
		}
	}, [dataDetail]);

	return (
		<>
			{data?.getCompanySubmissionProposalById?.additionalFeatureData?.process?.directorGeneral?.status === 'approved' &&
				data?.getCompanySubmissionProposalById?.additionalFeatureData?.note && (
					<>
						<Flex mt={16} mb={0} justifyContent="center" alignItems="center">
							<Flex
								width="100%"
								direction="column"
								alignItems="center"
								bg="#ecf0f1"
								borderWidth={1}
								borderStyle="dotted"
								borderColor="gray.400"
								borderRadius={c.borderRadius}
								pt={6}
								pl={4}
								pr={4}
								pb={6}
							>
								<Text fontWeight="bold" fontSize="md" mb={2}>
									{t('NOTE')}
								</Text>
								<Box className={loadingDetail ? 'disableForm' : ''}>
									<Tooltip label={t('Click to view')}>
										<Image
											src={c.baseFilePath + data?.getCompanySubmissionProposalById?.additionalFeatureData?.note}
											w="auto"
											h="auto"
											maxWidth="120px"
											fallbackSrc={LogoPlaceholder}
											cursor="pointer"
											onMouseDown={onOpen}
										/>
									</Tooltip>
								</Box>
								<Tooltip label={t('Regenerate')}>
									<Button mt={8} colorScheme="green" variant="solid" size="sm" onMouseDown={onOpenForm} isLoading={loadingDetail}>
										{t('Regenerate')}
									</Button>
								</Tooltip>
							</Flex>
						</Flex>
						<Modal isOpen={isOpen} onClose={onClose} size="xl">
							<ModalOverlay />
							<ModalContent borderRadius={c.borderRadius} pt="2">
								<ModalHeader>{t('NOTE')}</ModalHeader>
								<ModalCloseButton />
								<ModalBody>
									<Flex justifyContent="center" pt={8} pb={12}>
										<Image
											src={c.baseFilePath + data?.getCompanySubmissionProposalById?.additionalFeatureData?.note}
											fallbackSrc={LogoPlaceholder}
										/>
									</Flex>
								</ModalBody>
							</ModalContent>
						</Modal>
						<Modal isOpen={isOpenForm} onClose={onCloseForm} size="xl">
							<ModalOverlay />
							<ModalContent borderRadius={c.borderRadius} pb="2">
								<ModalHeader>{t('Regenerate')}</ModalHeader>
								<ModalCloseButton />
								<ModalBody>
									<Formik
										initialValues={{
											documentNumber: '',
											khmerName: '',
											latinName: '',
											address: '',
											lunarSignedDate: '',
											solarSignedDate: '',
										}}
										validationSchema={validationSchema}
										onSubmit={(values) => {
											onCloseForm();
											setTimeout(() => {
												const documentNumber = values?.documentNumber;
												const khmerName = values?.khmerName;
												const latinName = values?.latinName;
												const address = values?.address;
												const lunarSignedDate = values?.lunarSignedDate;
												const solarSignedDate = values?.solarSignedDate;
												const data = {
													documentNumber,
													khmerName,
													latinName,
													address,
													lunarSignedDate,
													solarSignedDate,
												};
												const variables = { proposalId: id, data };
												updateDetail({ variables });
											}, 100);
										}}
									>
										{(props) => (
											<Form>
												<Box mt="3">
													<TextFormField
														{...props}
														label="Document Number"
														name="documentNumber"
														placeholder={t('០០១ ន.ធ.ក./ទស')}
														required
													/>
													<TextFormField {...props} label="Khmer Name" name="khmerName" placeholder={t('ក ខ គ')} required />
													<TextFormField {...props} label="Latin Name" name="latinName" placeholder={t('A B C')} required />
													<TextFormField
														{...props}
														label="Address"
														name="address"
														placeholder={t(
															'អគារលេខ ១៦៨ ( ជាន់ទី៦ ) ផ្លូវ ៥៩៨ សង្កាត់ច្រាំងចំរេះ១ ខណ្ឌឬស្សីកែវ រាជធានីភ្នំពេញ កម្ពុជា'
														)}
														required
													/>
													<TextFormField
														{...props}
														label="Lunar Signed Date"
														name="lunarSignedDate"
														placeholder={t('ថ្ងៃសុក្រ ៩រោច ខែមិគសិរ ឆ្នាំថោះ បញ្ចស័ក ព.ស.២៥៦៧')}
														required
													/>
													<TextFormField
														{...props}
														label="Solar Signed Date"
														name="solarSignedDate"
														placeholder={t('រាជធានីភ្នំពេញ ថ្ងៃទី០៥ ខែមករា គ.ស.២០២៤')}
														required
													/>
													<Flex mt="6" justifyContent="right" alignItems="center">
														<Button
															id="login-btn"
															variant="solid"
															size="md"
															h="42px"
															w="128px"
															bg="primary.dark"
															_hover={{ bg: 'primary.dark' }}
															color="white"
															type="submit"
															isLoading={false}
															loadingText={t('Add')}
															spinnerPlacement="start"
														>
															{t(`Submit`)}
														</Button>
													</Flex>
												</Box>
											</Form>
										)}
									</Formik>
								</ModalBody>
							</ModalContent>
						</Modal>
					</>
				)}
		</>
	);
}
