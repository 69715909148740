import React, { useState, useRef, useEffect } from "react";
import { Flex, Tooltip, Text, Textarea, Button, useToast, Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton, } from "@chakra-ui/react";
import {
    faFile
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { gql, useMutation } from '@apollo/client';
import c from "../../constant"
import { useTranslation } from "react-i18next";

const EDIT_COMMENT_MUTATION = gql`
mutation edit(
    $id: String!
    $attachmentFileSize: Float
    $attachmentFile: Upload
    $description: String
    $removeAttachment: Boolean
){
    editComment(
        id: $id
        description:$description,
        attachmentFile:$attachmentFile,
        attachmentFileSize:$attachmentFileSize,
        removeAttachment:$removeAttachment
    ){
      id
    }
  }
`

export default function EditComment({comment, onClose, callback, isOpen}) {
    const { t } = useTranslation();
    const toast = useToast()
    const fileInput = useRef(null)
    const [description, setDescription] = useState(comment?.description?comment?.description:"")
    const [attachmentFile, setAttachmentFile] = useState(null)

    const [removeAttachment, setRemoveAttachment] = useState(false)

    const [editComment, {loading, error, data}] = useMutation(
        EDIT_COMMENT_MUTATION
    );

    useEffect(()=>{
        if(error){
            error.graphQLErrors.map(({ message }, i) => (
                toast({
                    title: t(message),
                    status: "error",
                    isClosable: true,
                    position:"top-right"
                })
            ))
        }
    },[error])

    useEffect(()=>{
        if(data?.editComment?.id){
            callback()
        }
    }, [data])

    const handleClickFile = () => {
        fileInput.current.click()
    }

    const handleFileChange = event => {
        var fileInput = false;
        if (event.target.files[0]) {
            fileInput = true;
        }
        if (fileInput) {
            const file = event.target.files[0]
            if(file.size>c.maxFileSize){
                toast({
                    title: t("File size oevr limit"),
                    status: "warning",
                    isClosable: true,
                    position:"top-right"
                })
            }else {
                setAttachmentFile(event.target.files[0])
            }
        }
    }

    function updateComment(){
            const variables = {
                id:comment?.id,
                description,
                attachmentFile:attachmentFile,
                attachmentFileSize:attachmentFile?.size,
                removeAttachment
            }
            editComment({
                variables
            })
    }

    return (
        <Modal size="xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent borderRadius={c.borderRadius}>
          <ModalHeader>{t("Edit Comment")}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          <>
            <Flex direction="column" pb="2">
                <Textarea disabled={loading} value={description} onChange={e => setDescription(e.target.value)} w="100%" placeholder={t("Leave a comment")} noOfLines={4} />
                
                {(!(comment?.attachmentFile?.file)||removeAttachment)&&<Flex>
                    <Tooltip label={t("Maximum file size 100MB")}>
                        <Flex cursor="pointer" alignItems="center" m="2" onClick={() => {
                            if(!loading){
                                handleClickFile()
                            }
                        }}>
                            <Text>{t("Attachment")}</Text>
                            <FontAwesomeIcon
                                icon={faFile}
                                color="#303030"
                                style={{ fontSize: 18, marginLeft:4 }}
                            />
                            <Text bg="gray.200" pl="2" pr="2" ml="2">{attachmentFile?.name}</Text>
                        </Flex>
                    </Tooltip>
                    <input
                        style={{ display: "none" }}
                        type="file"
                        onChange={(e) => handleFileChange(e)}
                        ref={fileInput}
                        accept="application/pdf,.xlsx,.xls,image/*,.doc, .docx,.txt,.zip,.rar,.7zip"
                    />
                </Flex>}
                {!removeAttachment&&<Flex mt="2" alignItems="center">
                    {comment?.attachmentFile?.file && <a href={comment?.attachmentFile?.file} target="_blank"
                        download style={{ display: "block", width: 180 }}>
                        <Flex ml={2} bg="gray.300" borderRadius={c.borderRadius} p="2" justifyContent="center" alignItems="center" w="180px">
                            <Text color="blue.500">{t("Attachment")} {` ( ${parseFloat(comment?.attachmentFile?.fileSize / 1048576).toFixed(2)}MB ) `}</Text>
                        </Flex>
                    </a>}
                    {comment?.attachmentFile?.file&&<Text ml="6" cursor="pointer" onClick={()=>setRemoveAttachment(true)}>{t("Remove")}</Text>}
                </Flex>}
                <Flex justifyContent="flex-end">
                    <Button isLoading={loading} onClick={()=>updateComment()}>{t("Update")}</Button>
                </Flex>
            </Flex>
        </>
          </ModalBody>

        </ModalContent>
      </Modal>
        
    );
}
