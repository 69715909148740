/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Text, useToast } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DragHandleIcon } from '@chakra-ui/icons';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import LoadingOverlayText from '../../../../components/LoadingOverlay/LoadingOverlayText';
import arrayMove from 'array-move';
import c from '../../../../constant';

const Q_REFERENCE_LIST = gql`
	query get {
		getDocumentNumberTypeReferences(pagerInput: { offset: 0, limit: 10000 }) {
			list {
				id
				enTitle
				khTitle
				description
				order
			}
			totalPages
			totalRows
		}
	}
`;

const M_REFERENCE_ORDER = gql`
	mutation update($orders: JSON!) {
		updateDocumentNumberTypeReferenceOrder(orders: $orders)
	}
`;

function ReferenceType() {
	const toast = useToast();
	const history = useHistory();
	const { t } = useTranslation();
	const [list, setList] = useState([]);
	const [loadData, { loading: loadingLoad, data: dataLoad, error: errorLoad }] = useLazyQuery(Q_REFERENCE_LIST);
	const [updateData, { loading: loadingUpdate, error: errorUpdate }] = useMutation(M_REFERENCE_ORDER);

	useEffect(() => {
		loadData();
	}, []);

	useEffect(() => {
		if (dataLoad?.getDocumentNumberTypeReferences?.list?.length > 0) {
			setList(dataLoad?.getDocumentNumberTypeReferences?.list);
		}
	}, [dataLoad]);

	useEffect(() => {
		if (errorLoad) {
			if (errorLoad?.graphQLErrors?.length > 0) {
				errorLoad?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(errorLoad?.message ? errorLoad?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [errorLoad]);

	useEffect(() => {
		if (errorUpdate) {
			if (errorUpdate?.graphQLErrors?.length > 0) {
				errorUpdate?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(errorUpdate?.message ? errorUpdate?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [errorUpdate]);

	function onSortEnd({ oldIndex, newIndex }) {
		const localList = arrayMove(list, oldIndex, newIndex);
		const variables = {
			orders: localList?.map(({ id }, order) => {
				return { id, order };
			}),
		};
		updateData({ variables });
		setList(localList);
	}

	const SortableIcon = SortableHandle(() => <DragHandleIcon mr={4} />);

	const SortableItem = SortableElement((item) => {
		return (
			<Flex
				w="100%"
				mb="2"
				minH="32px"
				alignItems="center"
				bg="white"
				borderRadius={c.borderRadius}
				border="1px solid #dbdbdb"
				boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
				p="16px"
			>
				<SortableIcon />
				<Flex
					direction="column"
					w="100%"
					h="100%"
					_hover={{ textDecoration: 'underline' }}
					cursor="pointer"
					onClick={() => history.push('/type-of-issued-letter/detail-reference-type?id=' + item?.value?.id)}
				>
					<Text fontSize="md" color="black.400" fontWeight="700">
						{item?.value?.khTitle}
					</Text>
					<Text fontSize="sm" color="gray.500" fontWeight="400">
						{item?.value?.description}
					</Text>
				</Flex>
			</Flex>
		);
	});

	const SortableList = SortableContainer(({ items }) => {
		return (
			<Box w="100%">
				{items.map((value, index) => (
					<SortableItem key={`item-${value?.id}`} index={index} value={value} />
				))}
			</Box>
		);
	});

	return (
		<Box borderWidth={1} borderColor="gray.200" pt={6} pb={2} pl={4} pr={4}>
			{!loadingLoad && list?.length <= 0 && (
				<Flex w="100%" justifyContent="center" alignItems="center" direction="column" pt={24} pb={24} pl={0} pr={0}>
					<FontAwesomeIcon
						icon={faFileAlt}
						style={{
							fontSize: 40,
							color: '#E2E8F0',
						}}
					/>
					<Text color="gray.400" mt={4}>
						{t('No Data')}
					</Text>
				</Flex>
			)}
			{!loadingLoad && list?.length > 0 && (
				<Flex w="100%">
					<SortableList useDragHandle items={list} onSortEnd={onSortEnd} />
				</Flex>
			)}
			{(loadingLoad || loadingUpdate) && <LoadingOverlayText />}
		</Box>
	);
}

export default ReferenceType;
