import React, { useEffect } from "react";
import {
	Badge,
} from "@chakra-ui/react";
import { gql, useLazyQuery } from '@apollo/client';
import useStore from '../../store';


const QUERY_COUNT = gql`
query{
  getCountCompanySaleProfessionalNewRequest
  getCountCompanySaleProfessionalTerminationRequest
}
`

function InsuranceCompanySaleStaffBadge() {
	const { currentUser } = useStore((state) => state.currentUser);
	const [loadCount, { data }] = useLazyQuery(QUERY_COUNT)
	useEffect(() => {
		loadCount()
	}, [])

	function getValue() {
		if (data?.getCountCompanySaleProfessionalNewRequest && data?.getCountCompanySaleProfessionalTerminationRequest) {
			if (parseInt(currentUser?.position) >= 5) {
				let val = 0

				if (data?.getCountCompanySaleProfessionalNewRequest) {
					const tempNew = JSON.parse(data?.getCountCompanySaleProfessionalNewRequest)
					val = val + tempNew?.reviewingByOffice
				}

				if (data?.getCountCompanySaleProfessionalTerminationRequest) {
					const tempTerminate = JSON.parse(data?.getCountCompanySaleProfessionalTerminationRequest)
					val = val + tempTerminate?.reviewingByOffice
				}
				return val
			}
			if (parseInt(currentUser?.position) <= 4) {
				let val = 0
				if (data?.getCountCompanySaleProfessionalNewRequest) {
					const tempNew = JSON.parse(data?.getCountCompanySaleProfessionalNewRequest)
					val = val + tempNew?.reviewingByDepartment
				}
				return val
			}
		} return 0
	}

	return (
		getValue() > 0 ? <Badge
			fontSize="x-large"
			zIndex="99"
			top="2px" right="2px"
			position="absolute"
			colorScheme="red"
		>
			{getValue()}
		</Badge> : null
	);
}

export default InsuranceCompanySaleStaffBadge;
