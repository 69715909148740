/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Box, Flex, Image, Text } from '@chakra-ui/react';
import { CompanyBadge } from '../../Other';
import PlaceHolderLogo from '../../../assets/images/logo-placeholder.png';
import QRCodeGenerator from '../../QRCodeGenerator';
import c from '../../../constant';

const CompanyShortInformation = ({ id = null, logo = null, name = null, nameKhmer = null, type = null }) => {
	return (
		<>
			<Box>
				<Flex justifyContent="space-between">
					<Image w="100%" h="auto" maxW="194px" src={logo ? c.baseFilePath + logo : ''} objectFit="contain" fallbackSrc={PlaceHolderLogo} mb={4} />
					{type !== c.insuranceCompanyType.broker.value && (
						<a
							href={c.env === 'development' ? 'http://localhost:3009/company?id=' + id : 'http://irc.gov.kh/company?id=' + id}
							target="_blank"
							rel="noopener noreferrer"
						>
							<QRCodeGenerator
								size={120}
								text={c.env === 'development' ? 'http://localhost:3009/company?id=' + id : 'http://irc.gov.kh/company?id=' + id}
								mainColor="black"
								cornerColorInnerColor="black"
							/>
						</a>
					)}
				</Flex>
				<Text fontSize="xl" fontWeight="bold">
					{nameKhmer}
				</Text>
				<Text fontSize="xl" fontWeight="bold">
					{name}
				</Text>
				<CompanyBadge type={type} />
			</Box>
		</>
	);
};

export default CompanyShortInformation;
