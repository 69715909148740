import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Container, Box, Flex, Text, Center,Input, Button, useToast, InputRightElement, InputGroup } from "@chakra-ui/react";

import c from "../../constant"
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { gql, useMutation } from '@apollo/client';
import { useHistory } from "react-router-dom";

const SIGNUP_MUTATION= gql`
mutation signUpAdmin($username: String!, $password: String!){
	signUp(userInput:{
    username: $username
    password: $password
  })
}`

const signUpSchema = Yup.object().shape({
    username: Yup.string().required("Required"),
    password: Yup.string().required("Required"),
});

function Create() {
    const history = useHistory();
    const toast = useToast()
    const { t } = useTranslation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [signUpUser, {loading, error, data}] = useMutation(
        SIGNUP_MUTATION
    );

    function signUp(value){
        signUpUser({
            variables:{
                username: value.username,
                password: value.password
            }
        })
    }

    useEffect(()=>{
        if(error){
            error.graphQLErrors.map(({ message }, i) => (
                toast({
                    title: message,
                    status: "error",
                    isClosable: true,
                    position:"top-right"
                })
            ))
        }
    },[error])

    useEffect(()=>{
        if(data){
            if(data?.signUp){
                    toast({
                    title: "User created successfully",
                    status: "success",
                    isClosable: true,
                    position:"top-right"
                })
                history.push("/")
            }
        }
    },[data])

    return (
        <>
            <Container maxW="container.xl" mt="16px" mb="16px">
                
                    <Center mt="5">
                        <Box
                            w="100%"
                            bg="white"
                            borderRadius={c.borderRadius}
                            border="1px solid #dbdbdb"
                            boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
                            pb="32px"
                        >
                            <Flex bg="#FAFAFA" p="16px" pt="8px" pb="8px" mb="16px" justifyContent="space-between" borderBottom="1px solid #dbdbdb">
                                <Text
                                    fontSize="x-large"
                                    fontWeight="bold"
                                >
                                    Create New User
                                </Text>
                            </Flex>
                            
                                <Box p="16px" maxW="480px">
                                <Formik
                        initialValues={{
                            username: "",
                            password: "",
                        }}
                        validationSchema={signUpSchema}
                        onSubmit={(values) => {
                            signUp(values);
                        }}
                    >
                        {({ errors, touched }) => (
                            <Form>
                                <Box mt="8">
                                    <Field name="username">
                                        {({ field, meta }) => (
                                            <>
                                                <Input
                                                    {...field}
                                                    id="username"
                                                    size="lg"
                                                    placeholder={t(`Username`)}
                                                    fontWeight="500"
                                                    autoComplete="off"
                                                />
                                                {meta.touched && meta.error && (
                                                    <Text
                                                        id="error-message-username"
                                                        color="red.400"
                                                        fontSize="12px"
                                                    >
                                                        {t("Username")}{" "}
                                                        {t("is")}{" "}
                                                        {t(meta.error)}
                                                    </Text>
                                                )}
                                            </>
                                        )}
                                    </Field>

                                    <Field name="password">
                                        {({ field, meta, form }) => (
                                            <>
                                            <InputGroup size="md" 
                                                    mt="3">
                                            <Input
                                                    {...field}
                                                    id="password"
                                                    placeholder={t(`Password`)}
                                                    size="lg"
                                                    fontWeight="500"
                                                    autoComplete="off"
                                                />
                                                <InputRightElement width="4.5rem">
                                                    <Button
                                                        mt="8px"
                                                        mr="6px"
                                                        h="100%"
                                                        size="sm"
                                                        onClick={()=>{
                                                            form.setFieldValue('password', generatePassword(), true);
                                                        }}
                                                    >
                                                        Generate
                                                    </Button>
                                                </InputRightElement>
                                                </InputGroup>
                                                
                                                {meta.touched && meta.error && (
                                                    <Text
                                                        id="error-message-password"
                                                        color="red.400"
                                                        fontSize="12px"
                                                    >
                                                        {t("Password")}{" "}
                                                        {t("is")}{" "}
                                                        {t(meta.error)}
                                                    </Text>
                                                )}
                                            </>
                                        )}
                                    </Field>

                                    <Flex
                                        textAlign="right"
                                        mt="6"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Button
                                            id="login-btn"
                                            variant="solid"
                                            size="md"
                                            h="42px"
                                            w="128px"
                                            bg="primary.dark"
                                            _hover={{ bg: "primary.dark" }}
                                            color="white"
                                            // borderRadius="31px"
                                            type="submit"
                                            isLoading={loading}
                                            loadingText={t("CREATE")}
                                            spinnerPlacement="start"
                                        >
                                            {t(`CREATE`)}
                                        </Button>
                                    </Flex>
                                </Box>
                            </Form>
                        )}
                    </Formik>
                                </Box>
                        </Box>
                    </Center>
            </Container>
        </>
    );
}

export default Create;

const generatePassword = (
    length = 8,
    wishlist = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz~!@-#$'
  ) =>
    Array.from(crypto.getRandomValues(new Uint32Array(length)))
      .map((x) => wishlist[x % wishlist.length])
      .join('')