/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Center, Box, Flex, Text, Table, Tbody, Tr, Th, Td, Tfoot, Thead } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { SectionHeader } from '../../Other';
import formatMoney from '../../../utils/formatMoney';
import moment from 'moment';
import c from '../../../constant';

function ShareholderPrint({ dataCompanyShareholders, ...props }) {
  const { t } = useTranslation();

  return (
    <>
      <Flex className="show-only-printing" mt={4} {...props}>
        <Box width="100%">
          <Center>
            <Box
              width="100%"
              bg="white"
              borderRadius={c.borderRadius}
              border="1px solid #dbdbdb"
              boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
              pb={2}
            >
              <Flex
                h="48px"
                bg="#FAFAFA"
                pl="16px"
                pr="16px"
                mb="16px"
                alignItems="center"
                justifyContent="space-between"
                borderBottom="1px solid #dbdbdb"
              >
                <SectionHeader title={t('Shareholder')} fontSize="lg" />
              </Flex>
              <Box p="16px">
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>
                        {t('Company Name')}/{t('Individual')}
                      </Th>
                      <Th>{t('Representative')}</Th>
                      <Th>{t('Gender')}</Th>
                      <Th>{t('Nationality')}</Th>
                      <Th>{t('Amount')}</Th>
                      <Th>{t('Percentage')}</Th>
                      <Th>{t('Approval Date')}</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {dataCompanyShareholders?.getCompanyShareholder.length <= 0 && (
                      <Tr>
                        <Td>
                          <Text>{t('No Data')}</Text>
                        </Td>
                      </Tr>
                    )}
                    {dataCompanyShareholders?.getCompanyShareholder?.map((item, index) => {
                      return (
                        <Tr
                          cursor="pointer"
                          _hover={{ bg: 'gray.200' }}
                          key={`item-${index}`}
                          bg="white"
                        >
                          <Td>{item.name}</Td>
                          <Td>{item.representative}</Td>
                          <Td>{t(item.gender)}</Td>
                          <Td>{t(item.nationality)}</Td>
                          <Td>
                            {formatMoney(item.amount)}{' '}
                            {item.currency ? `(${t(item.currency)})` : ''}
                          </Td>
                          <Td>
                            {item.percentage} {item?.percentage ? '%' : null}
                          </Td>
                          <Td>
                            {item.approvalDate
                              ? moment(item.approvalDate).format('DD MMMM YYYY')
                              : null}
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                  <Tfoot>
                    <Tr>
                      <Th>
                        {t('Company Name')}/{t('Individual')}
                      </Th>
                      <Th>{t('Representative')}</Th>
                      <Th>{t('Gender')}</Th>
                      <Th>{t('Nationality')}</Th>
                      <Th>{t('Amount')}</Th>
                      <Th>{t('Percentage')}</Th>
                      <Th>{t('Approval Date')}</Th>
                    </Tr>
                  </Tfoot>
                </Table>
              </Box>
            </Box>
          </Center>
        </Box>
      </Flex>
    </>
  );
}

export default ShareholderPrint;
