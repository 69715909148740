import { Text } from '@chakra-ui/react';
import React from 'react';
import useStore from '../../store';

function FullAddress({ object, ...props }) {
  const { currentLang } = useStore((state) => state.currentLang);

  function commaEn() {
    return currentLang !== 'kh' ? ',' : null;
  }

  function getGeoLocationName(obj) {
    return obj?.code
      ? currentLang === 'kh'
        ? `${obj?.administrativeUnit}${obj?.name}`
        : `${obj?.administrativeUnitLatin} ${obj?.latinName}`
      : null;
  }

  return (
    <Text {...props}>
      {object?.houseNumber && (
        <>
          {`${currentLang === 'kh' ? '​ផ្ទះលេខ ' : '#'}${
            object?.houseNumber ? object?.houseNumber : null
          }`}
          {commaEn()}
          &nbsp;
        </>
      )}
      {object?.streetNumber && (
        <>
          {`${currentLang === 'kh' ? 'ផ្លូវ ' : 'St. '}${
            object?.streetNumber ? object?.streetNumber : null
          }`}
          {commaEn()}
          &nbsp;
        </>
      )}
      {object?.villageObject && (
        <>
          {getGeoLocationName(object?.villageObject)}
          {commaEn()}
          &nbsp;
        </>
      )}
      {object?.communeObject && (
        <>
          {getGeoLocationName(object?.communeObject)}
          {commaEn()}
          &nbsp;
        </>
      )}
      {object?.districtObject && (
        <>
          {getGeoLocationName(object?.districtObject)}
          {commaEn()}
          &nbsp;
        </>
      )}
      {object?.provinceObject && <>{getGeoLocationName(object?.provinceObject)}</>}
    </Text>
  );
}

export default FullAddress;
