/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Badge, Spinner, Tooltip } from '@chakra-ui/react';
import { gql, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

const M_ADD_RESPONSIBLE = gql`
	mutation add($id: String!) {
		addResponsibleUnitsToProposal(id: $id)
	}
`;

function ProposalReassignStatus({ id, onNext = () => null }) {
	const { t } = useTranslation();
	const [result, setResult] = useState(null);
	const [reassignProposal, { loading, data }] = useMutation(M_ADD_RESPONSIBLE);

	useEffect(() => {
		const storedString = localStorage.getItem('@ProposalReassign');
		let objects = JSON.parse(storedString) || [];
		const object = objects.find((e) => e?.id === id);
		if (object) {
			setTimeout(() => {
				setResult(object);
				onNext();
			}, 50);
		} else {
			const variables = { id };
			reassignProposal({ variables });
		}
	}, [id]);

	useEffect(() => {
		if (data) {
			const object = data?.addResponsibleUnitsToProposal;
			const storedString = localStorage.getItem('@ProposalReassign');
			let objects = JSON.parse(storedString) || [];
			const findIndex = objects.findIndex((e) => e?.id === id);
			if (findIndex === -1) {
				objects = [...objects, object];
			} else {
				objects[findIndex] = object;
			}
			localStorage.setItem('@ProposalReassign', JSON.stringify(objects));
			setResult(object);
			onNext();
		}
	}, [data]);

	return (
		<>
			{loading ? (
				<>
					<Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="sm" />
				</>
			) : (
				<>
					{result?.status === true && (
						<>
							<Tooltip label={t('Reassigned')}>
								<Badge
									colorScheme="green"
									variant="solid"
									cursor="pointer"
									onClick={() => {
										const variables = { id };
										reassignProposal({ variables });
									}}
								>
									{t('Reassigned')}
								</Badge>
							</Tooltip>
						</>
					)}
					{result?.status === false && (
						<>
							<Tooltip label={`${t('Not Reassigned')} (${t('Click to retry')})`}>
								<Badge
									colorScheme="gray"
									variant="solid"
									cursor="pointer"
									onClick={() => {
										const variables = { id };
										reassignProposal({ variables });
									}}
								>
									{t('Not Reassigned')}
								</Badge>
							</Tooltip>
						</>
					)}
				</>
			)}
		</>
	);
}

export default React.memo(ProposalReassignStatus);
