import React, { useEffect, useState } from "react";
import { Input, FormControl, Box, FormLabel, FormHelperText, option } from "@chakra-ui/react";
import { Field } from "formik";
import { useTranslation } from 'react-i18next'
import Select from 'react-select';
import { gql, useLazyQuery } from '@apollo/client';
import c from "../../constant"
const QUERY_PROVINCE = gql`
query{
    getProvince{
      id
      code
      name
      latinName
      administrativeUnit
      administrativeUnitEn
      administrativeUnitLatin
    }
  }
`

const QUERY_DISTRICT = gql`
query get($code: String!){
    getDistrict(code:$code){
      id
      code
      name
      latinName
      administrativeUnit
      administrativeUnitEn
      administrativeUnitLatin
    }
  }
`

const QUERY_COMMUNE = gql`
query get($code: String!){
    getCommune(code:$code){
      id
      code
      name
      latinName
      administrativeUnit
      administrativeUnitEn
      administrativeUnitLatin
    }
  }
`


const QUERY_VILLAGE = gql`
query get($code: String!){
    getVillage(code:$code){
      id
      code
      name
      latinName
      administrativeUnit
      administrativeUnitEn
      administrativeUnitLatin
    }
  }
`

const GeoFormField = props => {
    const { t } = useTranslation('common');
    const [value, setValue] = React.useState("")
    const {
        name,
        label,
        radios,
        callbackValueChange,
        required,
        options,
        buttomComponent=null
    } = props;


    const [loadProvince, { error:errorProvince, loading:loadingProvince, data:dataProvince }] = useLazyQuery(QUERY_PROVINCE)
    const [loadDistrict, { error:errorDistrict, loading:loadingDistrict, data:dataDistrict }] = useLazyQuery(QUERY_DISTRICT)
    const [loadCommune, { error:errorCommune, loading:loadingCommune, data:dataCommune }] = useLazyQuery(QUERY_COMMUNE)
    const [loadVillage, { error:errorVillage, loading:loadingVillage, data:dataVillage }] = useLazyQuery(QUERY_VILLAGE)
// Selected Data
    const [province, setProvince] = useState(null)
    const [district, setDistrict] = useState(null)
    const [commune, setCommune] = useState(null)
    const [village, setVillage] = useState(null)
    const [streetNumber, setStreetNumber] = useState("")
    const [houseNumber, setHouseNumber] = useState("")
// Selected Data

    const [initialValue, setInitialValue] = useState(null)

    useEffect(()=>{
        loadProvince()
    },[])

    function getGeoData(data){
        const res = []
        data?.map((item)=>{
            let tmp = {
                value:item?.code,
                label:`${item?.administrativeUnit} ${item?.name} - ${item?.administrativeUnitLatin}  ${item?.latinName}`
            }
            res.push(tmp)
        })
        return res
    }

    const customStyles = {
        menu: (provided) => ({
            ...provided,
            borderRadius: c.borderRadius
        }),
        control: (provided, state) => {
            return ({
                ...provided,
                borderRadius: c.borderRadius,
                marginBottom: 4,
                height:46,
                borderColor:state?.selectProps?.isError?"#FC8181":"#e2e8f0",
                boxShadow:state?.selectProps?.isError?"0 0 0 1px #fc8181":"",
                fontSize:16,
                paddingLeft:6,
            })
        }
    }

    useEffect(()=>{
        if(initialValue){
            const provinceObject = getGeoDataObjectByCode(initialValue?.province,dataProvince?.getProvince)
            setProvince(provinceObject)
            
            // const districtObject = getGeoDataObjectByCode(initialValue?.district,dataDistrict?.getDistrict)
            // setDistrict(districtObject)
        }
    },[initialValue, dataProvince])

    useEffect(()=>{
        if(province?.value){
            loadDistrict({variables:{code:province?.value}})
        }
    },[province])

    useEffect(()=>{
        if(dataDistrict && initialValue?.district){
            const districtObject = getGeoDataObjectByCode(initialValue?.district,dataDistrict?.getDistrict)
            setDistrict(districtObject)
        }
    },[dataDistrict])

    useEffect(()=>{
        if(district?.value){
            loadCommune({variables:{code:district?.value}})
        }
    },[district])

    useEffect(()=>{
        if(dataCommune && initialValue?.commune){
            const communeObject = getGeoDataObjectByCode(initialValue?.commune,dataCommune?.getCommune)
            setCommune(communeObject)
        }
    },[dataCommune])

    useEffect(()=>{
        if(commune?.value){
            loadVillage({variables:{code:commune?.value}})
        }
    },[commune])

    useEffect(()=>{
        if(dataVillage && initialValue?.village){
            const villageObject = getGeoDataObjectByCode(initialValue?.village,dataVillage?.getVillage)
            setVillage(villageObject)
        }
    },[dataVillage])

    function getGeoDataObjectByCode(code, data){
        const tmp =  data?.filter(function (el) {
            return el.code === code
        });
        let res = null
        if(tmp?.length>0){
            const geoObj = tmp[0]
            res = {
                value: geoObj.code,
                label: `${geoObj?.administrativeUnit} ${geoObj?.name} - ${geoObj?.administrativeUnitLatin} ${geoObj?.latinName}`
            }
        }
        return res
    }

    return (
        <Field name={name}>
            {({ field, meta, form }) => {
                if(meta?.value){
                    setInitialValue(meta?.initialValue)
                    setStreetNumber(meta?.value?.streetNumber)
                    setHouseNumber(meta?.value?.houseNumber)
                }
                if(meta.touched && required){
                    if(
                        !(meta?.value?.village) ||  
                        !(meta?.value?.commune) ||
                        !(meta?.value?.district) ||
                        !(meta?.value?.province) ||
                        !streetNumber ||
                        !houseNumber
                    ){
                        if(!meta.error){
                            form.setFieldError(name, "Required")
                        }
                    }
                }
                return (
                <Box mb="4">
                <FormControl id={name} >
                    {label&&<FormLabel>{t(label)}{required&&<span style={{color:"red"}}>*</span>}</FormLabel>}
                    <Select
                        styles={customStyles}
                        placeholder={t('Province/City')}
                        isDisabled={loadingProvince}
                        isLoading={loadingProvince}
                        isClearable={true}
                        isRtl={false}
                        isSearchable={true}
                        name="province"
                        options={getGeoData(dataProvince?.getProvince)}
                        onChange={e=>{
                            setProvince(e)
                            setDistrict(null)
                            setCommune(null)
                            setVillage(null)
                            if(e?.value){
                                loadDistrict({variables:{code:e?.value}})
                            }
                            form.setFieldValue(name, {
                                province:e,
                                district:null,
                                commune:null,
                                village:null,
                                streetNumber,
                                houseNumber
                            })
                        }}
                        value={province}
                        isError={required && meta.touched && meta.error && !meta?.value?.province}
                    />
                    <Select
                        styles={customStyles}
                        placeholder={t('District')}
                        isDisabled={(province?.value?false:true)||loadingDistrict}
                        isLoading={loadingDistrict}
                        isClearable={true}
                        isRtl={false}
                        isSearchable={true}
                        name="district"
                        options={getGeoData(dataDistrict?.getDistrict)}
                        onChange={e=>{
                            setDistrict(e)
                            setCommune(null)
                            setVillage(null)
                            if(e?.value){
                                loadCommune({variables:{code:e?.value}})
                            }
                            form.setFieldValue(name, {
                                province,
                                district:e,
                                commune:null,
                                village:null,
                                streetNumber,
                                houseNumber
                            })
                        }}
                        value={district}
                        isError={required && meta.touched && meta.error && !meta?.value?.district}
                    />
                    <Select
                        styles={customStyles}
                        placeholder={t('Commune')}
                        isDisabled={(district?.value?false:true)||loadingCommune}
                        isLoading={loadingCommune}
                        isClearable={true}
                        isRtl={false}
                        isSearchable={true}
                        name="commune"
                        options={getGeoData(dataCommune?.getCommune)}
                        onChange={e=>{
                            setCommune(e)
                            setVillage(null)
                            if(e?.value){
                                loadVillage({variables:{code:e?.value}})
                            }
                            form.setFieldValue(name, {
                                province,
                                district,
                                commune:e,
                                village:null,
                                streetNumber,
                                houseNumber
                            })
                        }}
                        value={commune}
                        isError={required && meta.touched && meta.error && !meta?.value?.commune}
                    />
                    <Select
                        styles={customStyles}
                        placeholder={t('Village')}
                        isDisabled={(commune?.value?false:true)||loadingVillage}
                        isLoading={loadingVillage}
                        isClearable={true}
                        isRtl={false}
                        isSearchable={true}
                        name="village"
                        options={getGeoData(dataVillage?.getVillage)}
                        onChange={e=>{
                            setVillage(e)
                            form.setFieldValue(name, {
                                province,
                                district,
                                commune,
                                village:e,
                                streetNumber,
                                houseNumber
                            })
                        }}
                        value={village}
                        isError={required && meta.touched && meta.error && !meta?.value?.village}
                    />

                    <Input
                        id='streetNumber'
                        type="text"
                        placeholder={t("Street number")}
                        size="lg"
                        errorBorderColor="red.300"
                        isInvalid={required && meta.touched && meta.error && !streetNumber}
                        onChange={e=>{
                            setStreetNumber(e.target.value)
                            form.setFieldValue(name, {
                                province,
                                district,
                                commune,
                                village,
                                streetNumber:e.target.value,
                                houseNumber
                            })
                        }}
                        mb="4px"
                        fontSize={16}
                        value={meta?.value?.streetNumber}
                    />
                    <Input
                        id='houseNumber'
                        type="text"
                        placeholder={t("House number")}
                        size="lg"
                        errorBorderColor="red.300"
                        isInvalid={required && meta.touched && meta.error && !houseNumber}
                        onChange={e=>{
                            setHouseNumber(e.target.value)
                            form.setFieldValue(name, {
                                province,
                                district,
                                commune,
                                village,
                                streetNumber,
                                houseNumber:e.target.value
                            })
                        }}
                        mb="4px"
                        fontSize={16}
                        value={meta?.value?.houseNumber}
                    />

                    {buttomComponent}

                    {meta.touched && meta.error && (
                        <FormHelperText
                            id="error-message-password"
                            color="red.400"
                            fontSize="12px"
                        >
                            {t(name)}{" "}
                            {t("is")}{" "}
                            {t(meta.error)}
                        </FormHelperText>
                    )}
                </FormControl>

            </Box>
                
            )}}
        </Field>
    );
};


export default GeoFormField;


const styles = {
    selectStyle:{
        borderRadius: 0
    }
}