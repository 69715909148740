import { Box, Text } from '@chakra-ui/react';
import React from 'react';

function SectionHeader({ title, fontSize = 'x-large', fontWeight = 'bold', ...props }) {
	return (
		<Box {...props}>
			<Text noOfLines={1} isTruncated={true} fontSize={fontSize} fontWeight={fontWeight}>
				{title}
			</Text>
		</Box>
	);
}

export default SectionHeader;
