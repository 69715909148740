/* eslint-disable react-hooks/exhaustive-deps */
import { Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { PartnerRequestStatus } from '../../../../containers/ManageAgentPartner/PartnerRequest/constants';
import useStore from '../../../../store';
import c from '../../../../constant';
import PartnerRequestEditingStatus from './PartnerRequestEditingStatus';

const PartnerRequestCertificateStatusDescription = ({ requestStatus = null, certificateStatus = null, certificateStatusDescription = null }) => {
	const { t } = useTranslation();
	const { currentUser: user } = useStore((state) => state.currentUser);

	return (
		<>
			{requestStatus > PartnerRequestStatus.reviewAgentFinal && (
				<>
					{certificateStatusDescription ? (
						<>
							<Text fontSize="sm" fontWeight="medium">
								{certificateStatusDescription}
							</Text>
						</>
					) : (
						<Text fontSize="sm" fontWeight="normal">
							{'N/A'}
						</Text>
					)}
				</>
			)}
			{requestStatus === PartnerRequestStatus.reviewAgent && (
				<>
					{c.stepOfficeRoles.includes(user?.position) ? (
						<>
							{certificateStatusDescription ? (
								<>
									<Text fontSize="sm" fontWeight="medium">
										{certificateStatusDescription}
									</Text>
								</>
							) : (
								<>
									{certificateStatus ? (
										<>
											<Text fontSize="sm" fontWeight="normal">
												{'N/A'}
											</Text>
										</>
									) : (
										<>
											<Text fontSize="sm" fontWeight="medium">
												{'-'}
											</Text>
										</>
									)}
								</>
							)}
						</>
					) : (
						<>
							<Text fontSize="sm" fontWeight="medium">
								{t('-')}
							</Text>
						</>
					)}
				</>
			)}
			{requestStatus === PartnerRequestStatus.reviewAgentFinal && (
				<>
					{certificateStatusDescription ? (
						<>
							<Text fontSize="sm" fontWeight="medium">
								{certificateStatusDescription}
							</Text>
						</>
					) : (
						<>
							{certificateStatus ? (
								<>
									<Text fontSize="sm" fontWeight="normal">
										{'N/A'}
									</Text>
								</>
							) : (
								<>
									{c.stepDepartmentRoles.includes(user?.position) ? (
										<>
											<Text fontSize="sm" fontWeight="medium">
												{'-'}
											</Text>
										</>
									) : (
										<>
											<PartnerRequestEditingStatus />
										</>
									)}
								</>
							)}
						</>
					)}
				</>
			)}
		</>
	);
};

export default PartnerRequestCertificateStatusDescription;
