import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Container,
	Box,
	Flex,
	Text,
	Center,
	Table,
	Thead,
	Tbody,
	Tfoot,
	Tr,
	Th,
	Td,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	Input,
	Button,
	useToast,
	Tooltip,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	FormControl,
	FormLabel,
} from '@chakra-ui/react';
import BreadcrumbComponent from '../../../components/BreadcrumbComponent/BreadcrumbComponent';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import c from '../../../constant';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import moment from 'moment';
import './style.css';
import { useLocation, useHistory } from 'react-router-dom';
import useStore from '../../../store';
import LoadingTable from '../../../components/Table/Loading';
import { Link } from 'react-router-dom';
import { ChevronDownIcon } from '@chakra-ui/icons';
import 'moment/locale/en-gb';
import 'moment/locale/km';

const QUERY_EMPLOYEES = gql`
	query {
		getSalaryTable {
			id
			title
			createdAt
		}
	}
`;

const MUTATION_CREATE_NEW_SALARY_TABLE = gql`
	mutation create($title: String!) {
		generateNewSalaryTable(title: $title)
	}
`;
const MUTATION_DELETE_SALARY_TABLE = gql`
	mutation delete($id: String!) {
		deleteSalaryTableById(id: $id)
	}
`;

function UserList() {
	const { t } = useTranslation();
	const location = useLocation();
	const history = useHistory();
	const urlParams = new URLSearchParams(location.search);
	const currentPage = urlParams.get('page') ? urlParams.get('page') : 1;
	const toast = useToast();
	const [username, setUsername] = useState('');
	const [rowLimit, setRowLimit] = useState(10);
	const initialRef = React.useRef(null);

	const [openNewModal, setOpenNewModal] = useState(false);
	const [title, setTitle] = useState('');
	const { currentLang } = useStore((state) => state.currentLang);
	moment.locale(currentLang === 'kh' ? 'km' : 'en-gb');

	const [loadEmployees, { error, loading, data }] = useLazyQuery(QUERY_EMPLOYEES);

	const [createSalaryTableMutation, { error: createTableError, data: createTableData, loading: createTableLoading }] =
		useMutation(MUTATION_CREATE_NEW_SALARY_TABLE);

	const [deleteSalaryTableMutation, { error: deleteTableError, data: deleteTableData, loading: deleteTableLoading }] =
		useMutation(MUTATION_DELETE_SALARY_TABLE);

	useEffect(() => {
		if (createTableData?.generateNewSalaryTable) {
			history.push(`/salary/edit?id=${createTableData?.generateNewSalaryTable}`);
		}
	}, [createTableData]);

	useEffect(() => {
		if (deleteTableData) {
			toast({
				title: t('Salary table deleted successfully'),
				status: 'success',
				isClosable: true,
				position: 'bottom-right',
			});
			loadEmployees();
		}
	}, [deleteTableData]);

	useEffect(() => {
		if (createTableError) {
			if (createTableError?.graphQLErrors?.length > 0) {
				createTableError?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(createTableError?.message ? createTableError?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [createTableError]);

	useEffect(() => {
		window.scrollTo(0, 0);
		loadEmployees();
	}, []);

	return (
		<>
			<Container maxW="container.xl" mt="16px" mb="16px">
				<BreadcrumbComponent
					list={[
						{
							name: t('OFFICIAL PROFILE'),
							path: '/employee/list?page=1',
						},
						{
							name: t('Salary'),
							path: '#',
						},
					]}
				/>
				<Center mt="5">
					<Box
						w="100%"
						bg="white"
						borderRadius={c.borderRadius}
						border="1px solid #dbdbdb"
						boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
						pb="32px"
					>
						<Flex bg="#FAFAFA" p="16px" pt="8px" pb="8px" mb="16px" justifyContent="space-between" borderBottom="1px solid #dbdbdb">
							<Text fontSize="x-large" fontWeight="bold">
								{t('Salary')}
							</Text>
							<Flex>
								<Flex alignItems="center">
									<Tooltip label={t('Salary Setting')}>
										<Link to={`/salary/setting`}>
											<Flex mr="4" cursor="pointer">
												<FontAwesomeIcon icon={faCog} style={{ fontSize: 16, marginLeft: 6 }} />
											</Flex>
										</Link>
									</Tooltip>
								</Flex>
								<Flex
									onClick={() => setOpenNewModal(true)}
									cursor="pointer"
									alignItems="center"
									justifyContent="center"
									w="32px"
									h="32px"
									borderRadius={c.borderRadius}
									border="1px solid #dbdbdb"
									boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
								>
									<Text fontSize="26px" mt="2px" fontWeight="500">
										+
									</Text>
								</Flex>
							</Flex>
						</Flex>

						<Box p="16px">
							<Table variant="simple">
								{/* <TableCaption>Imperial to metric conversion factors</TableCaption> */}
								<Thead>
									<Tr>
										<Th>{t('Title')}</Th>
										<Th>{t('Created At')}</Th>
										<Th>{t('Actions')}</Th>
									</Tr>
								</Thead>
								<Tbody>
									{loading && <LoadingTable column={2} />}
									{data?.getSalaryTable?.length <= 0 && (
										<Tr>
											<Td>
												<Text>{t('No Data')}</Text>
											</Td>
										</Tr>
									)}
									{data?.getSalaryTable?.map((item, index) => {
										return (
											<Tr key={`item-${index}`} cursor="pointer" _hover={{ bg: 'rgba(236, 240, 241,1.0)' }}>
												<Td onClick={() => history.push(`/salary/edit?id=${item.id}`)}>{item?.title}</Td>
												<Td onClick={() => history.push(`/salary/edit?id=${item.id}`)}>{moment(item?.createdAt).format('LLLL')}</Td>
												<Td>
													<Menu>
														<MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
															{t('Actions')}
														</MenuButton>
														<MenuList>
															<MenuItem color="red" onClick={() => deleteSalaryTableMutation({ variables: { id: item?.id } })}>
																{t('Delete')}
															</MenuItem>
														</MenuList>
													</Menu>
												</Td>
											</Tr>
										);
									})}
								</Tbody>
								<Tfoot>
									<Tr>
										<Th>{t('Title')}</Th>
										<Th>{t('Created At')}</Th>
										<Th>{t('Actions')}</Th>
									</Tr>
								</Tfoot>
							</Table>
						</Box>
					</Box>
				</Center>
			</Container>
			{openNewModal && (
				<>
					<Modal
						initialFocusRef={initialRef}
						isOpen={true}
						onClose={() => {
							setOpenNewModal(false);
							setTitle('');
						}}
					>
						<ModalOverlay />
						<ModalContent borderRadius={c.borderRadius}>
							<ModalHeader>{t('Create new salary table')}</ModalHeader>
							<ModalCloseButton />
							<ModalBody pb={6}>
								<FormControl>
									<FormLabel>{t('Title')}</FormLabel>
									<Input
										borderRadius={c.borderRadius}
										onChange={(e) => {
											setTitle(e.target.value);
										}}
										required
										ref={initialRef}
										placeholder={t('Title')}
									/>
								</FormControl>
							</ModalBody>

							<ModalFooter>
								<Button
									isLoading={createTableLoading}
									onClick={() => {
										if (title) {
											createSalaryTableMutation({
												variables: {
													title,
												},
											});
										} else {
											alert(`${t('Please input title')}`);
										}
									}}
									borderRadius={c.borderRadius}
									colorScheme="blue"
									mr={3}
								>
									{t('Save')}
								</Button>
								<Button
									isLoading={createTableLoading}
									borderRadius={c.borderRadius}
									onClick={() => {
										setOpenNewModal(false);
										setTitle('');
									}}
								>
									{t('Cancel')}
								</Button>
							</ModalFooter>
						</ModalContent>
					</Modal>
				</>
			)}
		</>
	);
}

export default UserList;
