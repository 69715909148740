/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import {
	Flex,
	Box,
	Center,
	Tooltip,
	Text,
	Table,
	Tbody,
	Thead,
	Tfoot,
	Tr,
	Th,
	Td,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	useToast,
	AlertDialog,
	AlertDialogOverlay,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogBody,
	AlertDialogFooter,
	Button,
} from '@chakra-ui/react';
import { DragHandleIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import AddCompanyBoardOfDirectorModal from '../../Modal/AddCompanyBoardOfDirectorModal';
import EditCompanyBoardOfDirectorModal from '../../Modal/EditCompanyBoardOfDirectorModal';
import DownloadAndViewButton from '../../../components/Files/DownloadAndViewButton';
import LoadingOverlay from '../../../components/LoadingOverlay/LoadingOverlay';
import arrayMove from 'array-move';
import c from '../../../constant';
import useStore from '../../../store';
import useMoment from '../../../hooks/useMoment';
import LoadingTable from '../../Table/Loading';

const Q_GET_BOARD = gql`
	query GetCompanyBoardOfDirector($id: String!) {
		getCompanyBoardOfDirector(companyId: $id) {
			id
			name
			gender
			nationality
			position
			createdAt
			approvalDate
			file
		}
	}
`;
const M_DELETE_BOARD = gql`
	mutation DeleteCompanyBoardOfDirector($id: String!) {
		deleteCompanyBoardOfDirector(id: $id)
	}
`;
const M_UPDATE_BOARD_INDEX = gql`
	mutation UpdateboardOfDirectorIndex($array: String!) {
		updateboardOfDirectorIndex(array: $array)
	}
`;

let workingId = null;

function GovernanceBoardOfDirector({ id, onDataChanged, ...props }) {
	const deleteDialogRef = useRef();
	const deleteDialogCancelRef = useRef();
	const toast = useToast();
	const { t } = useTranslation();
	const { toDateString } = useMoment();
	const { currentUser } = useStore((state) => state.currentUser);
	const [lists, setLists] = useState([]);
	const [isOpeningInsertDialog, setOpeningInsertDialog] = useState(false);
	const [isOpeningDeleteDialog, setOpeningDeleteDialog] = useState(false);
	const [isOpeningEditDialog, setOpeningEditDialog] = useState(false);
	const [loadCompanyBoardOfDirectors, { loading: loadingBoard, error: errorBoard, data: dataBoard }] = useLazyQuery(Q_GET_BOARD);
	const [deleteCompanyBoardOfDirector, { loading: loadingDeleteBoard, error: errorDeleteBoard, data: dataDeleteBoard }] = useMutation(M_DELETE_BOARD);
	const [updateCompanyBoardOfDirectorIndex, { loading: loadingUpdateBoardIndex, error: errorUpdateBoardIndex }] = useMutation(M_UPDATE_BOARD_INDEX);

	useEffect(() => {
		if (id) {
			const variables = { id };
			loadCompanyBoardOfDirectors({ variables });
		}
	}, [id]);

	useEffect(() => {
		if (dataBoard?.getCompanyBoardOfDirector) {
			setLists(dataBoard?.getCompanyBoardOfDirector);
		}
	}, [dataBoard]);

	useEffect(() => {
		if (lists) {
			onDataChanged(lists);
		}
	}, [lists]);

	useEffect(() => {
		if (errorBoard) {
			errorBoard.graphQLErrors.map(({ message }, i) =>
				toast({
					title: t(message),
					status: 'error',
					isClosable: true,
					position: 'top-right',
				})
			);
		}
	}, [errorBoard]);

	useEffect(() => {
		if (dataDeleteBoard?.deleteCompanyBoardOfDirector) {
			toast({
				title: t('Company board of director deleted successfully'),
				status: 'success',
				isClosable: true,
				position: 'top-right',
			});
			const variables = { id };
			loadCompanyBoardOfDirectors({ variables });
			onRejectDeleteDialog();
		}
	}, [dataDeleteBoard]);

	useEffect(() => {
		if (errorDeleteBoard) {
			errorDeleteBoard.graphQLErrors.map(({ message }, i) =>
				toast({
					title: t(message),
					status: 'error',
					isClosable: true,
					position: 'top-right',
				})
			);
		}
	}, [errorDeleteBoard]);

	useEffect(() => {
		if (errorUpdateBoardIndex) {
			errorUpdateBoardIndex.graphQLErrors.map(({ message }, i) =>
				toast({
					title: t(message),
					status: 'error',
					isClosable: true,
					position: 'top-right',
				})
			);
		}
	}, [errorUpdateBoardIndex]);

	function onRejectInsertDialog() {
		setOpeningInsertDialog(false);
	}

	function onApproveInsertDialog() {
		if (id) {
			const variables = { id };
			loadCompanyBoardOfDirectors({ variables });
		}
	}

	function onRejectDeleteDialog() {
		setOpeningDeleteDialog(false);
	}

	function onApproveDeleteDialog() {
		const variables = { id: workingId };
		deleteCompanyBoardOfDirector({ variables });
	}

	function onRejectEditDialog() {
		setOpeningEditDialog(false);
	}

	function onApproveEditDialog() {
		const variables = { id };
		loadCompanyBoardOfDirectors({ variables });
	}

	function onSortEnd({ oldIndex, newIndex }) {
		const newArray = arrayMove(lists, oldIndex, newIndex);
		setLists(newArray);
		const updateIndexList = newArray.map((item, index) => ({ id: item.id, index }));
		const variables = { array: JSON.stringify(updateIndexList) };
		updateCompanyBoardOfDirectorIndex({ variables });
	}

	const ActionMenuBoardOfDirector = ({ id }) => {
		return (
			<Menu>
				<Tooltip label={`${t('Edit')} ${t('Delete')}`}>
					<MenuButton cursor="pointer">
						<Flex pl="2" pr="2">
							<FontAwesomeIcon icon={faEllipsisV} style={{ fontSize: 16, marginTop: 4, marginLeft: 6 }} />
						</Flex>
					</MenuButton>
				</Tooltip>
				<MenuList pt="4" pb="4" pl="3" pr="3" borderRadius={c.borderRadius}>
					<MenuItem
						color={'blue.500'}
						onClick={() => {
							workingId = id;
							setOpeningEditDialog(true);
						}}
					>
						{t('Edit')}
					</MenuItem>
					<MenuItem
						color={'red'}
						onClick={() => {
							workingId = id;
							setOpeningDeleteDialog(true);
						}}
					>
						{t('Delete')}
					</MenuItem>
				</MenuList>
			</Menu>
		);
	};

	const SortableIcon = SortableHandle(() => (
		<Tooltip label={t('Drag to arrainge order')}>
			<DragHandleIcon />
		</Tooltip>
	));

	const SortableItem = SortableElement((item) => {
		return (
			<Tr cursor="pointer" _hover={{ bg: 'gray.200' }} bg="white" w="100%" p={4}>
				<Td>
					<SortableIcon />
				</Td>
				<Td mr="2">{item?.value.name}</Td>
				<Td mr="2">{t(item?.value.gender)}</Td>
				<Td mr="2">{item?.value.nationality}</Td>
				<Td mr="2">{item?.value.position}</Td>
				<Td mr="2">{toDateString(item?.value.approvalDate, c.defaultDateFormat)}</Td>
				<Td mr="2">{item?.value.file?.file && <DownloadAndViewButton marginLeft={0} url={item?.value.file?.file} size={item?.value.file?.size} />}</Td>
				{(currentUser?.role === 'admin' || currentUser?.canUpdateCompanyGovernance) && (
					<Td className="hidden-print" isNumeric>
						<ActionMenuBoardOfDirector id={item?.value?.id} />
					</Td>
				)}
			</Tr>
		);
	});

	const SortableList = SortableContainer(({ items }) => {
		return (
			<Tbody w="100%">
				{loadingBoard && <LoadingTable column={8} row={3} />}
				{items?.length <= 0 && (
					<Tr>
						<Td />
						<Td colSpan={20}>
							<Text>{t('No Data')}</Text>
						</Td>
					</Tr>
				)}
				{items?.map((value, index) => (
					<SortableItem key={`item-${value?.id}`} index={index} value={value} />
				))}
			</Tbody>
		);
	});

	return (
		<>
			<Flex {...props}>
				<Box width="100%">
					<Center>
						<Box
							width="100%"
							bg="white"
							borderRadius={c.borderRadius}
							border="1px solid #dbdbdb"
							boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
							pb={2}
						>
							<Flex p={4} pt={2} pb={2} mb={4} h="48px" bg="#FAFAFA" alignItems="center" justifyContent="end" borderBottom="1px solid #dbdbdb">
								<Flex alignItems="center">
									{(currentUser?.role === 'admin' || currentUser?.canUpdateCompanyGovernance) && (
										<Tooltip label={t('Add Board Of Director')}>
											<Flex
												className="hidden-print"
												userSelect="none"
												onClick={() => setOpeningInsertDialog(true)}
												cursor="pointer"
												alignItems="center"
												justifyContent="center"
												w="32px"
												h="32px"
												borderRadius={c.borderRadius}
												border="1px solid #dbdbdb"
												boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
											>
												<Text fontSize="26px" mt="2px" fontWeight="500">
													+
												</Text>
											</Flex>
										</Tooltip>
									)}
								</Flex>
							</Flex>
							<Box p="16px">
								<Table variant="simple">
									<Thead>
										<Tr>
											<Th />
											<Th>{t('Name')}</Th>
											<Th>{t('Gender')}</Th>
											<Th>{t('Nationality')}</Th>
											<Th>{t('Position')}</Th>
											<Th>{t('Approval Date')}</Th>
											<Th>{t('Permit letter')}</Th>
											{(currentUser?.role === 'admin' || currentUser?.canUpdateCompanyGovernance) && (
												<Th className="hidden-print" isNumeric>
													{t('Actions')}
												</Th>
											)}
										</Tr>
									</Thead>
									<SortableList useDragHandle items={lists} onSortEnd={onSortEnd} />
									<Tfoot>
										<Tr>
											<Th />
											<Th>{t('Name')}</Th>
											<Th>{t('Gender')}</Th>
											<Th>{t('Nationality')}</Th>
											<Th>{t('Position')}</Th>
											<Th>{t('Approval Date')}</Th>
											<Th>{t('Permit letter')}</Th>
											{(currentUser?.role === 'admin' || currentUser?.canUpdateCompanyGovernance) && (
												<Th className="hidden-print" isNumeric>
													{t('Actions')}
												</Th>
											)}
										</Tr>
									</Tfoot>
								</Table>
							</Box>
						</Box>
					</Center>
				</Box>
			</Flex>
			<AlertDialog isOpen={isOpeningDeleteDialog} leastDestructiveRef={deleteDialogRef} onClose={onRejectDeleteDialog}>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							{t('Delete Company Board Of Director')}
						</AlertDialogHeader>
						<AlertDialogBody>{t("Are you sure? You can't undo this action afterwards")}</AlertDialogBody>
						<AlertDialogFooter>
							<Button mr={4} isLoading={loadingDeleteBoard} ref={deleteDialogCancelRef} onClick={onRejectDeleteDialog}>
								{t('Cancel')}
							</Button>
							<Button isLoading={loadingDeleteBoard} colorScheme="red" onClick={onApproveDeleteDialog}>
								{t('Delete')}
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
			<AddCompanyBoardOfDirectorModal companyId={id} isOpen={isOpeningInsertDialog} onClose={onRejectInsertDialog} callback={onApproveInsertDialog} />
			{isOpeningEditDialog && (
				<EditCompanyBoardOfDirectorModal id={workingId} isOpen={true} onClose={onRejectEditDialog} callback={onApproveEditDialog} />
			)}
			{loadingUpdateBoardIndex && <LoadingOverlay />}
		</>
	);
}

export default GovernanceBoardOfDirector;
