/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Box, Flex, useToast } from '@chakra-ui/react';
import { gql, useLazyQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { LoadingCompanyInformation } from '../../Other';
import InsuranceLicenses from '../Customization/InsuranceLicenses';
import CompanyShortInformation from '../Customization/CompanyShortInformation';
import CompanyLongInformation from '../Customization/CompanyLongInformation';
import c from '../../../constant';

const QUERY_COMPANY_BY_ID = gql`
	query get($id: String!) {
		getCompanyById(id: $id) {
			id
			name
			nameKhmer
			logo
			url
			type
			streetNumber
			houseNumber
			ceoName
			ceoNationality
			province
			district
			commune
			village
			isGeoManual
			geoManual
			statuteFileSigned
			statuteSize
			licenseFileSigned
			licenseSize
			provinceObject {
				code
				name
				latinName
				administrativeUnit
				administrativeUnitLatin
			}
			districtObject {
				code
				name
				latinName
				administrativeUnit
				administrativeUnitLatin
			}
			communeObject {
				code
				name
				latinName
				administrativeUnit
				administrativeUnitLatin
			}
			villageObject {
				code
				name
				latinName
				administrativeUnit
				administrativeUnitLatin
			}
			licenseValidFrom
			licenseValidTo
			number
			displayNumber
			description
			isPermanentLicense
			status
		}
	}
`;

function CompanyInformation({ id, ...props }) {
	const toast = useToast();
	const { t } = useTranslation();
	const [loadCompany, { loading, error, data }] = useLazyQuery(QUERY_COMPANY_BY_ID);

	useEffect(() => {
		if (id) {
			loadCompany({ variables: { id } });
		}
	}, [id]);

	useEffect(() => {
		if (error) {
			if (error?.graphQLErrors?.length > 0) {
				error?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(error?.message ? error?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [error]);

	return (
		<>
			<Flex w="100%" {...props}>
				{loading ? (
					<>
						<LoadingCompanyInformation />
					</>
				) : (
					<>
						{data?.getCompanyById && (
							<Box width="100%">
								<CompanyShortInformation
									id={data?.getCompanyById?.id}
									logo={data?.getCompanyById?.logo}
									name={data?.getCompanyById?.name}
									nameKhmer={data?.getCompanyById?.nameKhmer}
									type={data?.getCompanyById?.type}
								/>
								{data?.getCompanyById?.type !== c.insuranceCompanyType.broker.value && (
									<Box mt={4}>
										<CompanyLongInformation
											type={data?.getCompanyById?.type}
											url={data?.getCompanyById?.url}
											number={data?.getCompanyById?.number}
											displayNumber={data?.getCompanyById?.displayNumber}
											isGeoManual={data?.getCompanyById?.isGeoManual}
											geoManual={data?.getCompanyById?.geoManual}
											houseNumber={data?.getCompanyById?.houseNumber}
											streetNumber={data?.getCompanyById?.streetNumber}
											villageObject={data?.getCompanyById?.villageObject}
											communeObject={data?.getCompanyById?.communeObject}
											districtObject={data?.getCompanyById?.districtObject}
											provinceObject={data?.getCompanyById?.provinceObject}
											licenseFileSigned={data?.getCompanyById?.licenseFileSigned}
											licenseSize={data?.getCompanyById?.licenseSize}
											isPermanentLicense={data?.getCompanyById?.isPermanentLicense}
											statuteFileSigned={data?.getCompanyById?.statuteFileSigned}
											statuteSize={data?.getCompanyById?.statuteSize}
											description={data?.getCompanyById?.description}
											validFrom={data?.getCompanyById?.licenseValidFrom}
											validTo={data?.getCompanyById?.licenseValidTo}
											status={data?.getCompanyById?.status}
										/>
									</Box>
								)}
								{data?.getCompanyById?.type === c.insuranceCompanyType.broker.value && (
									<InsuranceLicenses
										companyId={id}
										licenseTypes={[
											c.insuranceSeperatedLicenseType.brokerGeneral,
											c.insuranceSeperatedLicenseType.brokerLife,
											c.insuranceSeperatedLicenseType.brokerRe,
										]}
									/>
								)}
							</Box>
						)}
					</>
				)}
			</Flex>
		</>
	);
}
export default CompanyInformation;
