import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Box, Flex, Button, useToast, Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
} from "@chakra-ui/react";
import c from "../../constant"
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { gql, useMutation } from '@apollo/client';

import DateFormField from "../form/DateFormField"
import TextAreaFormField from "../../components/form/TextAreaFormField"

import SelectSearchFormField from "../form/SelectSearchFormField";
import SelectFormField from "../form/SelectFormField";

const MUTATION = gql`
mutation op($id: String!, $isBlacklist: Boolean!, $description: String!){
    blacklistAgent(id:$id,description:$description,isBlacklist:$isBlacklist)
  }
`

const formSchema = Yup.object().shape({
    description: Yup.string().required("required"),
});

function BlackListAgentCompany({
    isOpen = false, onClose = () => null, callback = () => null, agentId=null, putToAgentToBlackList=null
}) {
    const toast = useToast()
    const { t } = useTranslation();

    const [showCompanyPicker, setShowCompanyPicker] = useState(false)
    const [selectedCompany, setSelectedCompany] = useState(null)

    const [submitMutation, { loading, error, data }] = useMutation(
        MUTATION
    );


    useEffect(() => {
        if (data) {
            if (data?.blacklistAgent) {
                toast({
                    title: t(`Agent ${putToAgentToBlackList?'unblacklist':'blacklisted'} successfully`),
                    status: "success",
                    isClosable: true,
                    position: "top-right"
                })
                onClose()
                callback(`Agent ${putToAgentToBlackList?'unblacklist':'blacklisted'} successfully`)
            }
        }
    }, [data])

    useEffect(() => {
        if (error) {
            error.graphQLErrors.map(({ message }, i) => (
                toast({
                    title: t(message),
                    status: "error",
                    isClosable: true,
                    position: "top-right"
                })
            ))
        }
    }, [error])

    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={() => {
                    onClose()
                }}
                size="md"
            >
                <ModalOverlay />
                <ModalContent borderRadius={c.borderRadius} pb="2">
                    <ModalHeader>{t(putToAgentToBlackList?"Blacklist":"Unblacklist")}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Formik
                            initialValues={{
                                description: ""
                            }}
                            validationSchema={formSchema}
                            onSubmit={(values) => {
                                if(putToAgentToBlackList!==null){
                                    submitMutation({
                                        variables: {
                                            id:agentId,
                                            isBlacklist: putToAgentToBlackList,
                                            description: values.description
                                        }
                                    })
                                }
                            }}
                        >
                            {(props) => (
                                <Box>
                                    <Form>

                                        <TextAreaFormField
                                            label="Description"
                                            name="description"
                                            required
                                        />

                                        <Button
                                            id="login-btn"
                                            variant="solid"
                                            size="md"
                                            h="42px"
                                            w="128px"
                                            type="submit"
                                            borderRadius={c.borderRadius}
                                            isLoading={loading}
                                        >
                                            {t("Submit")}
                                        </Button>
                                    </Form>
                                </Box>
                            )}
                        </Formik>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
}

export default BlackListAgentCompany;
