/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Flex, Box, Tabs, TabList, Tab, TabPanels, TabPanel } from '@chakra-ui/react';
import { SectionHeader } from '../../Other';
import { useTranslation } from 'react-i18next';
import { gql, useLazyQuery } from '@apollo/client';
import GovernanceBoardOfDirector from './GovernanceBoardOfDirector';
import GovernanceCeo from './GovernanceCeo';
import GovernanceActuary from './GovernanceActuary';
import GovernanceBoardOfDirectorPrint from './GovernanceBoardOfDirectorPrint';
import GovernanceCeoPrint from './GovernanceCeoPrint';
import GovernanceActuaryPrint from './GovernanceActuaryPrint';
import c from '../../../constant';

const Q_COMPANY = gql`
	query get($id: String!) {
		getCompanyById(id: $id) {
			id
			name
			nameKhmer
			logo
			type
		}
	}
`;

function CompanyGovernance({ id, ...props }) {
	const { t } = useTranslation();
	const [dataCompanyBoardOfDirectors, setDataCompanyBoardOfDirectors] = useState(null);
	const [ceos, setCeos] = useState(null);
	const [auditors, setAuditors] = useState(null);
	const [investors, setInvestors] = useState(null);
	const [actuaries, setActuaries] = useState(null);
	const [loadCompany, { data }] = useLazyQuery(Q_COMPANY);

	useEffect(() => {
		id && loadCompany({ variables: { id } });
	}, [id]);

	return (
		<>
			<Flex className="hidden-print" mt={6} {...props}>
				<Box width="100%">
					<SectionHeader title={t('Governance')} />
					<Box mt={4}>
						<Tabs defaultIndex={0} className="agent_tab hidden-print" variant="enclosed">
							<TabList>
								<Tab>{t('Board Of Director')}</Tab>
								<Tab>{t('CEO')}</Tab>
								<Tab>{t('Audit Commission')}</Tab>
								<Tab>{t('Investment Commission')}</Tab>
								{[
									c.insuranceCompanyType.general.value,
									c.insuranceCompanyType.life.value,
									c.insuranceCompanyType.micro.value,
									c.insuranceCompanyType.re.value,
								].includes(data?.getCompanyById?.type) && <Tab>{t('Actuary')}</Tab>}
							</TabList>
							<TabPanels>
								<TabPanel pl={2} pr={2} pb={0}>
									<GovernanceBoardOfDirector id={id} onDataChanged={setDataCompanyBoardOfDirectors} />
								</TabPanel>
								<TabPanel pl={2} pr={2} pb={0}>
									<GovernanceCeo id={id} type={c.governanceCeo.ceo} onDataChanged={setCeos} />
								</TabPanel>
								<TabPanel pl={2} pr={2} pb={0}>
									<GovernanceCeo id={id} type={c.governanceCeo.auditCommission} onDataChanged={setAuditors} />
								</TabPanel>
								<TabPanel pl={2} pr={2} pb={0}>
									<GovernanceCeo id={id} type={c.governanceCeo.investmentCommission} onDataChanged={setInvestors} />
								</TabPanel>
								{[
									c.insuranceCompanyType.general.value,
									c.insuranceCompanyType.life.value,
									c.insuranceCompanyType.micro.value,
									c.insuranceCompanyType.re.value,
								].includes(data?.getCompanyById?.type) && (
									<TabPanel pl={2} pr={2} pb={0}>
										<GovernanceActuary id={id} onDataChanged={setActuaries} />
									</TabPanel>
								)}
							</TabPanels>
						</Tabs>
					</Box>
				</Box>
			</Flex>
			{dataCompanyBoardOfDirectors && <GovernanceBoardOfDirectorPrint dataCompanyBoardOfDirectors={dataCompanyBoardOfDirectors} />}
			{ceos && <GovernanceCeoPrint type={c.governanceCeo.ceo} title="CEO" list={ceos} />}
			{auditors && <GovernanceCeoPrint type={c.governanceCeo.auditCommission} title="Audit Commission" list={auditors} />}
			{investors && <GovernanceCeoPrint type={c.governanceCeo.investmentCommission} title="Investment Commission" list={investors} />}
			{[
				c.insuranceCompanyType.general.value,
				c.insuranceCompanyType.life.value,
				c.insuranceCompanyType.micro.value,
				c.insuranceCompanyType.re.value,
			].includes(data?.getCompanyById?.type) && <>{actuaries && <GovernanceActuaryPrint title="Actuary" list={actuaries} />}</>}
		</>
	);
}

export default CompanyGovernance;
