import { Text } from '@chakra-ui/react';
import useStore from '../store';
import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/km';
import c from '../constant';

function LocalizedDate({ dateString = null, dateFormat = c.dateTimeFormat, color = 'gray.600' }) {
	const { currentLang: lang } = useStore((state) => state.currentLang);

	moment.locale(lang === 'kh' ? 'km' : 'en-gb');

	return (
		<>
			<Text color={color}>
				{dateString ? moment(dateString).format(dateFormat) : 'N/A'}
			</Text>
		</>
	);
}

export default LocalizedDate;
