const checkOneRole=(all,check)=>{
    let pass = false
    const tmpUserRole = all ? all.split(",") : []
    tmpUserRole.map((rUser) => {
        if (check === rUser) {
            pass = true
        }
    })
    return pass
}

export default checkOneRole