import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Box, Flex, Text, Badge } from '@chakra-ui/react';
import BreadcrumbComponent from '../../components/BreadcrumbComponent/BreadcrumbComponent';
import { faScroll, faChartLine } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import c from '../../constant';
import { gql, useLazyQuery } from '@apollo/client';

const QUERY_COUNT_UNREAD_COMPLAINTS = gql`
	query {
		getCountUnreadComplaints
	}
`;
const QUERY_COUNT_UNREAD_COMPLAINTS_ON_INSURANCE = gql`
	query {
		getCountUnreadComplaintsOnInsurance
	}
`;

function ComplaintType() {
	const { t } = useTranslation();

	const [loadCountUnreadComplaints, { data: countComplaints }] = useLazyQuery(QUERY_COUNT_UNREAD_COMPLAINTS);
	const [loadCountUnreadComplaintsOnInsurance, { data: countComplaintsOnInsurance }] = useLazyQuery(QUERY_COUNT_UNREAD_COMPLAINTS_ON_INSURANCE);

	useEffect(() => {
		window.scrollTo(0, 0);
		loadCountUnreadComplaints();
		loadCountUnreadComplaintsOnInsurance();
	}, []);

	function menuItem({ item, index }) {
		return (
			<Link key={`menu-${index}`} to={item.path}>
				<Flex
					h="64px"
					alignItems="center"
					bg="white"
					borderRadius={c.borderRadius}
					border="1px solid #dbdbdb"
					boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
					p="16px"
					cursor="pointer"
					mb="4"
				>
					<Flex flex="1">
						<FontAwesomeIcon icon={item?.icon} color="#303030" p="2" style={{ fontSize: 20 }} />
						<Text fontWeight="500" ml="4">
							{t(item.title)}
						</Text>
					</Flex>
					{countComplaintsOnInsurance?.getCountUnreadComplaintsOnInsurance &&
					countComplaintsOnInsurance?.getCountUnreadComplaintsOnInsurance > 0 &&
					item.title === 'Complaint On Insurance' ? (
						<Badge fontSize="x-large" colorScheme="red">
							{parseInt(countComplaintsOnInsurance?.getCountUnreadComplaintsOnInsurance)}
						</Badge>
					) : null}
					{countComplaints?.getCountUnreadComplaints && countComplaints?.getCountUnreadComplaints > 0 && item.title === 'Complaint' ? (
						<Badge fontSize="x-large" colorScheme="red">
							{parseInt(countComplaints?.getCountUnreadComplaints)}
						</Badge>
					) : null}
				</Flex>
			</Link>
		);
	}

	return (
		<>
			<Container maxW="container.xl" mt="16px" mb="16px">
				<BreadcrumbComponent
					list={[
						{
							name: 'COMPLAINT',
							path: '#',
						},
					]}
				/>
				<Box pb="32px" w="100%" maxW="400px" mt="5">
					{menu.map((item, index) => menuItem({ item, index }))}
				</Box>
			</Container>
		</>
	);
}

export default ComplaintType;

const menu = [
	{
		title: 'Complaint On Insurance',
		path: '/complaint-on-insurance?page=1&status=in progress',
		icon: faScroll,
	},
	{
		title: 'Complaint',
		path: '/complaint?page=1&status=in progress',
		icon: faScroll,
	},
	{
		title: 'Report',
		path: '/complaint/report',
		icon: faChartLine,
	},
];
