import React from "react";
import c from "../../constant"
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    Text,
    ModalBody,
    ModalCloseButton,
    Button,
    Box,
    Flex
} from "@chakra-ui/react"
import { Formik, Form } from "formik";
import * as Yup from "yup";
import TextFormField from "../form/TextFormField";
import DateRangeFormField from "../form/DateRangeFormField";
import moment from "moment";
import { useTranslation } from "react-i18next";
import useStore from "../../store";
import 'moment/locale/en-gb';

const filterSchema = Yup.object().shape({
    code: Yup.number(),
    name: Yup.string(),
    idCardNumber: Yup.string(),
    unreadOnly: Yup.boolean(),
    dateSubmitRange: Yup.string(),
    dateApprovedRange: Yup.string(),

});

function AgentRenewalFilter({ isOpen, onClose, callback,
    code = "",
    name = "",
    fromSubmitDate = null,
    toSubmitDate = null,
    fromApprovedDate = null,
    toApprovedDate = null,
    status
}) {
    moment.locale("en-gb")
    const { t } = useTranslation();

    let dateSubmitRange = ""
    if (fromSubmitDate && toSubmitDate) {
        const defaultFrom = JSON.parse(fromSubmitDate);
        const defaultTo = JSON.parse(toSubmitDate);
        dateSubmitRange = {
            from: defaultFrom,
            to: defaultTo
        }
    }

    let dateApprovedRange = ""
    if (fromApprovedDate && toApprovedDate) {
        const defaultFrom = JSON.parse(fromApprovedDate);
        const defaultTo = JSON.parse(toApprovedDate);
        dateApprovedRange = {
            from: defaultFrom,
            to: defaultTo
        }
    }

    return (
        <>
            <Modal borderRadius={c.borderRadius} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent borderRadius={c.borderRadius}>
                    <ModalHeader>{t("Filter")}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Formik
                            initialValues={{
                                code: code ? code : "",
                                name: name ? name : "",
                                dateSubmitRange: dateSubmitRange ? JSON.stringify(dateSubmitRange) : "",
                                dateApprovedRange: dateApprovedRange ? JSON.stringify(dateApprovedRange) : "",
                            }}
                            validationSchema={filterSchema}
                            onSubmit={(values) => {

                                let url = "?page=1"
                                if (status) {
                                    url += `&status=${status}`
                                }
                                if (values.code) {
                                    url += `&code=${values.code}`
                                }
                                if (values.name) {
                                    url += `&name=${values.name}`
                                }
                                if (values.dateSubmitRange) {
                                    const tmpDate = JSON.parse(values.dateSubmitRange)
                                    if (tmpDate?.from && tmpDate?.to) {
                                        const dateFrom = JSON.stringify(tmpDate.from)
                                        const dateTo = JSON.stringify(tmpDate.to)
                                        url += `&fromSubmitDate=${dateFrom}&toSubmitDate=${dateTo}`
                                    }
                                }
                                if (values.dateApprovedRange) {
                                    const tmpDate = JSON.parse(values.dateApprovedRange)
                                    if (tmpDate?.from && tmpDate?.to) {
                                        const dateFrom = JSON.stringify(tmpDate.from)
                                        const dateTo = JSON.stringify(tmpDate.to)
                                        url += `&fromApprovedDate=${dateFrom}&toApprovedDate=${dateTo}`
                                    }
                                }
                                callback(url)
                                onClose()
                            }}
                        >
                            {(props) => (
                                <Box pb="4">
                                    <Form>
                                        <TextFormField
                                            {...props}
                                            label="Registration number"
                                            name="code"
                                        />
                                        <TextFormField
                                            {...props}
                                            label="Name"
                                            name="name"
                                        />

                                        <center>
                                            <DateRangeFormField
                                                {...props}
                                                label="Created Date"
                                                name="dateSubmitRange"
                                                initialDate={{ fromDate: fromSubmitDate, toDate: toSubmitDate }}
                                                disabled
                                            />
                                        </center>

                                        <center>
                                            <DateRangeFormField
                                                {...props}
                                                label="Approved Date"
                                                name="dateApprovedRange"
                                                initialDate={{ fromDate: fromApprovedDate, toDate: toApprovedDate }}
                                                disabled
                                            />
                                        </center>

                                        <Flex mt="32px" justifyContent="flex-end">
                                            <Button
                                                id="login-btn"
                                                variant="solid"
                                                size="md"
                                                h="42px"
                                                w="128px"
                                                type="submit"
                                                borderRadius={c.borderRadius}
                                                color="#3498db"
                                            >
                                                {t("Apply")}
                                            </Button>
                                            <Button
                                                size="md"
                                                h="42px"
                                                ml={4}
                                                w="128px" onClick={() => {
                                                    callback('?page=1')
                                                    onClose()
                                                }} variant="ghost">{t("Clear")}</Button>
                                        </Flex>
                                    </Form>
                                </Box>
                            )}
                        </Formik>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export default AgentRenewalFilter