import React, { useState } from 'react';
import { Flex, Text, Box, Tooltip } from '@chakra-ui/react';
import FileViewer from '../Modal/FileViewer';
import c from '../../constant';
import { useTranslation } from 'react-i18next';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import mime from 'mime-types';

function DownloadAndViewButton({ url, size, viewButton = null, marginLeft = 4, minW = '124px' }) {
	const { t } = useTranslation();
	const [openViewer, setOpenViewer] = useState(false);
	const fileName = (url?.split('/')?.pop()?.split('?X-Amz-Algorithm'))[0];
	const ext = fileName?.substr(fileName?.lastIndexOf('.') + 1);
	const mimeType = mime?.lookup(ext);

	function showViewButton() {
		if (viewButton === null) {
			let show = false;
			if (mimeType) {
				if (mimeType?.includes('pdf') || mimeType?.includes('image')) {
					show = true;
				}
			}
			return show;
		} else {
			return viewButton;
		}
	}

	return (
		<>
			{url && (
				<Flex>
					<a href={url ? url : ''} download>
						<Box
							minW={minW}
							_hover={{ bg: '#3498db !important' }}
							cursor="pointer"
							ml={marginLeft}
							bg="#3498db"
							p="2"
							borderRadius={c.borderRadius}
							borderRightRadius={showViewButton() ? 0 : c.borderRadius}
						>
							<Text color="white">
								{t('Download')}{' '}
								{size && ` ( ${parseFloat(size / 1048576) < 0.01 && size > 0 ? 0.01 : parseFloat(size / 1048576).toFixed(2)}MB ) `}
							</Text>
						</Box>
					</a>
					{showViewButton() && (
						<Tooltip label={t('View File')}>
							<Box
								_hover={{ bg: '#3498db !important' }}
								className="hidden-print"
								onMouseDown={() => setOpenViewer(true)}
								borderLeft="1px solid #bdc3c7"
								cursor="pointer"
								bg="#3498db"
								p="2"
								borderRadius={c.borderRadius}
								borderLeftRadius={0}
							>
								<FontAwesomeIcon icon={faEye} style={{ fontSize: 14, color: 'white' }} />
							</Box>
						</Tooltip>
					)}
				</Flex>
			)}
			{openViewer && <FileViewer onClose={() => setOpenViewer(false)} url={url} />}
		</>
	);
}

export default DownloadAndViewButton;
