/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Spinner, Button, useToast, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton } from '@chakra-ui/react';
import c from '../../constant';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import RadioFormField from '../../components/form/RadioFormField';
import TextFormField from '../../components/form/TextFormField';
import SelectSearchFormField from '../../components/form/SelectSearchFormField';
import CompanyPickerModal from './CompanyPickerModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList } from '@fortawesome/free-solid-svg-icons';
import CompanyFormField from '../form/CompanyFormField';
import DateFormField from '../form/DateFormField';
import FileFormField from '../form/FileFormField';
import useStore from '../../store';
import moment from 'moment';

const QUERY_PROVINCE = gql`
	query {
		getProvince {
			id
			code
			name
			latinName
			administrativeUnit
			administrativeUnitEn
			administrativeUnitLatin
		}
	}
`;

const EDIT_COMPANY = gql`
	mutation edit(
		$id: String!
		$companyId: String
		$companyName: String
		$startAt: String!
		$startFile: Upload
		$startFileSize: Float
		$insuranceAgentCompanyType: String
		$insuranceAgentCompanyId: String
		$insuranceAgentBranchId: String
		$inChargeBranchId: String
		$geoCode: String
	) {
		editAgentCompany(
			id: $id
			companyId: $companyId
			companyName: $companyName
			startAt: $startAt
			startFile: $startFile
			startFileSize: $startFileSize
			insuranceAgentCompanyType: $insuranceAgentCompanyType
			insuranceAgentCompanyId: $insuranceAgentCompanyId
			insuranceAgentBranchId: $insuranceAgentBranchId
			inChargeBranchId: $inChargeBranchId
			geoCode: $geoCode
		)
	}
`;

const QUERY_COMPANY_BRANCH = gql`
	query get($id: String!, $offset: Float) {
		getCompanyCertificate(companyId: $id, pagerInput: { limit: 999, offset: $offset }) {
			totalRows
			totalPages
			branches {
				id
				number
				name
				nameLatin
				managerName
				managerNameLatin
				validFrom
				validTo
				file
				streetNumber
				houseNumber
				geoManual
				isGeoManual
				provinceObject {
					code
					name
					latinName
					administrativeUnit
					administrativeUnitLatin
				}

				districtObject {
					code
					name
					latinName
					administrativeUnit
					administrativeUnitLatin
				}

				communeObject {
					code
					name
					latinName
					administrativeUnit
					administrativeUnitLatin
				}

				villageObject {
					code
					name
					latinName
					administrativeUnit
					administrativeUnitLatin
				}
			}
		}
	}
`;

const complaintOnInsuranceSchema = Yup.object().shape({
	companyName: Yup.string(),
	selectedCompany: Yup.string().nullable(),
	insuranceAgentCompanyType: Yup.string().required('required'),
	selectedInsurangeAgentCompany: Yup.string(),
	selectedOperationBranch: Yup.object().nullable(),
	inChargeBranch: Yup.object().nullable(),
	geoLocation: Yup.object().nullable(),
	startAt: Yup.string().nullable().required('required'),
	startFile: Yup.mixed().test('fileSize', 'over limit', (value) => {
		if (value) {
			return value?.size <= c.maxFileSize / 10;
		} else {
			return true;
		}
	}),
});

function Create({ isOpen = false, onClose = () => null, callback = () => null, workingData = null }) {
	const toast = useToast();
	const { t } = useTranslation();

	const [showCompanyPicker, setShowCompanyPicker] = useState(false);
	const [selectedCompany, setSelectedCompany] = useState(null);
	const [insuranceAgentCompanyType, setInsuranceAgentCompanyType] = useState('1');
	const [selectedInsuranceAgentCompany, setSelectedInsuranceAgentCompany] = useState(null);
	const [selectingInsuranceAgentCompany, setSelectingInsuranceAgentCompany] = useState(false);
	const [selectedOperationBranch, setSelectedOperationBranch] = useState(null);
	const { currentLang } = useStore((state) => state.currentLang);

	useEffect(() => {
		setSelectedCompany(workingData?.company);

		setInsuranceAgentCompanyType(workingData?.insuranceAgentCompanyType);

		setSelectedInsuranceAgentCompany(workingData?.insuranceAgentCompany);

		if (workingData?.inChargeBranch) {
			let tmpBranch = branchToList(workingData?.inChargeBranch);
			setSelectedOperationBranch(tmpBranch);
		}

		if (workingData?.insuranceAgentBranch) {
			let tmpBranch = branchToList(workingData?.insuranceAgentBranch);
			setSelectedOperationBranch(tmpBranch);
		}
	}, []);

	function branchToList(item) {
		return {
			label: item?.name,
			value: item?.id,
		};
	}

	const [editCompany, { loading, error, data }] = useMutation(EDIT_COMPANY);

	const [loadCompanyBranch, { loading: loadingCompanyBranch, data: dataCompanyBranch }] = useLazyQuery(QUERY_COMPANY_BRANCH);

	const [loadProvinces, { data: dataProvinces }] = useLazyQuery(QUERY_PROVINCE);

	const [branchList, setBranchList] = useState([]);

	useEffect(() => {
		if (data) {
			if (data?.editAgentCompany) {
				toast({
					title: t('Company updated successfully'),
					status: 'success',
					isClosable: true,
					position: 'top-right',
				});
				onClose();
				callback('Company updated successfully');
			}
		}
	}, [data]);

	useEffect(() => {
		if (error) {
			error.graphQLErrors.map(({ message }, i) =>
				toast({
					title: t(message),
					status: 'error',
					isClosable: true,
					position: 'top-right',
				})
			);
		}
	}, [error]);

	useEffect(() => {
		if (selectedInsuranceAgentCompany?.id && (insuranceAgentCompanyType === '2' || insuranceAgentCompanyType === '3')) {
			let storedBranchList = localStorage.getItem(`${selectedInsuranceAgentCompany?.id}`);
			storedBranchList = JSON.parse(storedBranchList);
			if (storedBranchList?.pullDate && storedBranchList?.pullDate > selectedInsuranceAgentCompany?.latestUpdateBranchAt) {
				setBranchList(storedBranchList?.list);
			} else {
				loadCompanyBranch({
					variables: {
						id: selectedInsuranceAgentCompany?.id,
						offset: 0,
					},
				});
			}
		}
	}, [selectedInsuranceAgentCompany, insuranceAgentCompanyType]);

	// in charge branch
	useEffect(() => {
		if (selectedCompany?.id && insuranceAgentCompanyType === '1') {
			let storedBranchList = localStorage.getItem(`${selectedCompany?.id}`);
			storedBranchList = JSON.parse(storedBranchList);
			if (storedBranchList?.pullDate && storedBranchList?.pullDate > selectedCompany?.latestUpdateBranchAt) {
				setBranchList(storedBranchList?.list);
			} else {
				loadCompanyBranch({
					variables: {
						id: selectedCompany?.id,
						offset: 0,
					},
				});
			}
		}
	}, [selectedCompany, insuranceAgentCompanyType]);

	useEffect(() => {
		if (selectedCompany?.id && insuranceAgentCompanyType === '1') {
			loadProvinces();
		}
	}, [selectedCompany, insuranceAgentCompanyType]);

	useEffect(() => {
		if (dataCompanyBranch?.getCompanyCertificate?.branches?.length > 0) {
			const tmp = [];
			dataCompanyBranch?.getCompanyCertificate?.branches?.map((item) => {
				tmp.push({
					value: item?.id,
					label: item?.name,
					labelEn: item?.nameLatin,
				});
			});
			setBranchList(tmp);
			const now = moment().toDate();
			localStorage.setItem(
				`${insuranceAgentCompanyType === '1' ? selectedCompany?.id : selectedInsuranceAgentCompany?.id}`,
				JSON.stringify({
					pullDate: now,
					list: tmp,
				})
			);
		} else {
			setBranchList([]);
		}
	}, [dataCompanyBranch]);

	function getListBanchByWithLang(branchList) {
		const tmp = [];
		branchList?.map((item) => {
			tmp.push({
				label: currentLang === 'kh' ? item?.label : item?.labelEn,
				value: item?.value,
			});
		});
		return tmp;
	}

	return (
		<>
			<Modal
				isOpen={isOpen}
				onClose={() => {
					onClose();
				}}
				size="md"
				scrollBehavior="outside"
				isCentered
			>
				<ModalOverlay />
				<ModalContent borderRadius={c.borderRadius} pb="2">
					<ModalHeader>{t('Edit Company')}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Formik
							initialValues={{
								companyName: '',
								selectedCompany: selectedCompany ? selectedCompany : null,
								insuranceAgentCompanyType: insuranceAgentCompanyType ? insuranceAgentCompanyType : '1',
								startFile: null,
								startAt: workingData?.startDate ? moment(workingData?.startDate).toDate() : null,
								selectedInsuranceAgentCompany: selectedInsuranceAgentCompany ? selectedInsuranceAgentCompany : null,
								selectedOperationBranch: selectedOperationBranch ? selectedOperationBranch : '',
								inChargeBranch: selectedOperationBranch ? selectedOperationBranch : '',
								geoLocation: workingData?.geoLocation
									? {
											label: currentLang === 'kh' ? workingData?.geoLocation?.name : workingData?.geoLocation?.latinName,
											value: workingData?.geoLocation?.code,
									  }
									: null,
							}}
							validationSchema={complaintOnInsuranceSchema}
							onSubmit={(values) => {
								const variables = {
									id: workingData?.id,
									companyId: values.selectedCompany || null,
									companyName: values.companyName || null,
									startAt: values.startAt,
									startFile: values.startFile,
									startFileSize: values?.startFile?.size,
									insuranceAgentCompanyType: values?.insuranceAgentCompanyType,
									insuranceAgentCompanyId: values?.insuranceAgentCompanyType === '1' ? null : values?.selectedInsuranceAgentCompany,
									insuranceAgentBranchId:
										values?.insuranceAgentCompanyType === '1'
											? null
											: values?.selectedInsuranceAgentCompany
											? values?.selectedOperationBranch
												? values?.selectedOperationBranch?.value
												: null
											: null,
									inChargeBranchId:
										values?.insuranceAgentCompanyType === '2' || values?.insuranceAgentCompanyType === '3'
											? null
											: values?.selectedCompany
											? values?.inChargeBranch
												? values?.inChargeBranch?.value
												: null
											: null,
									geoCode:
										values?.insuranceAgentCompanyType === '2' || values?.insuranceAgentCompanyType === '3'
											? null
											: values?.selectedCompany
											? values?.geoLocation
												? values?.geoLocation?.value
												: null
											: null,
								};
								editCompany({
									variables,
								});
							}}
						>
							{(props) => (
								<Box>
									<Form>
										{selectedCompany?.id && (
											<CompanyFormField
												{...props}
												label="Partnered Insurance Company"
												name="selectedCompany"
												callBackClear={() => {
													setSelectedCompany(null);
													localStorage.setItem('selectedCompany', null);
												}}
												callBackList={() => {
													setShowCompanyPicker(true);
													setSelectingInsuranceAgentCompany(false);
												}}
												selectedCompany={selectedCompany}
											/>
										)}
										{!selectedCompany?.id && (
											<TextFormField
												{...props}
												label="Partnered Insurance Company"
												name="companyName"
												rightElement={
													<Button
														onClick={() => {
															setShowCompanyPicker(true);
															setSelectingInsuranceAgentCompany(false);
														}}
														leftIcon={
															<FontAwesomeIcon
																style={{
																	fontSize: 16,
																	cursor: 'pointer',
																}}
																icon={faList}
															/>
														}
														mt="7px"
														variant="solid"
														h="42px"
														mr="3px"
														borderRadius={c.borderRadius}
													>
														{t('List')}
													</Button>
												}
											/>
										)}

										<RadioFormField
											name="insuranceAgentCompanyType"
											label="Insurance Agent Company Type"
											radios={[
												{ label: 'Direct Agent', value: '1' },
												{ label: 'Bancassurance', value: '2' },
												{ label: 'Professional Individual based on Licensing', value: '3' },
											]}
											initValue={insuranceAgentCompanyType?.toString() || ''}
											required
											callbackValueChange={(e) => {
												setInsuranceAgentCompanyType(e);
												localStorage.setItem('iaCompanyType', JSON.stringify(e));
												props.initialValues.inChargeBranch = '';
												props.initialValues.selectedOperationBranch = '';
												props.setFieldValue('inChargeBranch', '');
												props.setFieldValue('geoLocation', '');
												props.setFieldValue('selectedOperationBranch', '');
												setSelectedOperationBranch(null);
											}}
										/>
										{insuranceAgentCompanyType === '1' && (
											<>
												{loadingCompanyBranch && (
													<>
														<Spinner />
													</>
												)}
												{selectedCompany?.id && (
													<>
														<SelectSearchFormField
															{...props}
															name="inChargeBranch"
															label="In Charge Branch"
															placeholder="In Charge Branch"
															options={selectedCompany?.id ? getListBanchByWithLang(branchList) : []}
															callback={(e) => {
																localStorage.setItem('selectedOperationBranch', JSON.stringify(e));
																props.initialValues.selectedOperationBranch = '';
																props.setFieldValue('selectedOperationBranch', '');
															}}
														/>
														<SelectSearchFormField
															{...props}
															name="geoLocation"
															label="Location"
															placeholder="Location"
															options={dataProvinces?.getProvince?.reduce((p, c) => {
																let options = p;
																options.push({ label: currentLang === 'kh' ? c?.name : c?.latinName, value: c?.code });
																return options;
															}, [])}
															callback={(e) => null}
														/>
													</>
												)}
											</>
										)}

										{(insuranceAgentCompanyType === '2' || insuranceAgentCompanyType === '3') && (
											<>
												{selectedInsuranceAgentCompany?.id && (
													<CompanyFormField
														{...props}
														label="Insurance Agent Company"
														name="selectedInsuranceAgentCompany"
														required
														callBackClear={() => {
															setSelectedInsuranceAgentCompany(null);
															localStorage.setItem('selectedIACompany', null);
															localStorage.setItem('selectedOperationBranch', null);
															props.setFieldValue('selectedOperationBranch', '');
															props.initialValues.selectedOperationBranch = null;
															setBranchList([]);
														}}
														callBackList={() => {
															localStorage.setItem('selectedOperationBranch', null);
															props.setFieldValue('selectedOperationBranch', '');
															props.initialValues.selectedOperationBranch = null;
															setBranchList([]);

															setSelectedInsuranceAgentCompany(null);
															// selectedInsuranceAgentCompany
															setTimeout(() => {
																setShowCompanyPicker(true);
																setSelectingInsuranceAgentCompany(true);
															}, 100);
														}}
														selectedCompany={selectedInsuranceAgentCompany}
													/>
												)}
												{!selectedInsuranceAgentCompany?.id && (
													<TextFormField
														{...props}
														disabled={true}
														label="Insurance Agent Company"
														name="tmpCompanyName"
														required
														rightElement={
															<Button
																onClick={() => {
																	setShowCompanyPicker(true);
																	setSelectingInsuranceAgentCompany(true);
																}}
																leftIcon={
																	<FontAwesomeIcon
																		style={{
																			fontSize: 16,
																			cursor: 'pointer',
																		}}
																		icon={faList}
																	/>
																}
																mt="7px"
																variant="solid"
																h="42px"
																mr="3px"
																borderRadius={c.borderRadius}
															>
																{t('List')}
															</Button>
														}
													/>
												)}
												{loadingCompanyBranch && (
													<>
														<Spinner />
													</>
												)}
												{selectedInsuranceAgentCompany && (
													<SelectSearchFormField
														{...props}
														name="selectedOperationBranch"
														label="In Charge Branch"
														placeholder="Select a branch"
														disabled={true}
														options={selectedInsuranceAgentCompany?.id ? getListBanchByWithLang(branchList) : []}
														callback={(e) => {
															localStorage.setItem('selectedOperationBranch', JSON.stringify(e));
															props.initialValues.inChargeBranch = '';
															props.setFieldValue('inChargeBranch', '');
														}}
													/>
												)}
											</>
										)}

										<DateFormField name="startAt" label="Start At" required initialDate={workingData?.startDate} />

										<FileFormField {...props} label="Attachment" name="startFile" maxFileSize={c.maxFileSize / 10} />

										<Button
											id="login-btn"
											variant="solid"
											size="md"
											h="42px"
											w="128px"
											type="submit"
											borderRadius={c.borderRadius}
											isLoading={loading}
											float="right"
										>
											{t('Edit')}
										</Button>
									</Form>
								</Box>
							)}
						</Formik>
					</ModalBody>
				</ModalContent>
			</Modal>
			{showCompanyPicker && (
				<CompanyPickerModal
					onClose={() => setShowCompanyPicker(false)}
					callBack={(e) => {
						if (selectingInsuranceAgentCompany) {
							setSelectedInsuranceAgentCompany(e);
							localStorage.setItem('selectedIACompany', JSON.stringify(e));
						} else {
							setSelectedCompany(e);
							localStorage.setItem('selectedCompany', JSON.stringify(e));
						}
					}}
				/>
			)}
		</>
	);
}

export default Create;
