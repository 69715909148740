import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Box, Flex, Text, Button, Table, Thead, Tbody, Tfoot, Tr, Th, Td, Center, useToast } from '@chakra-ui/react';
import BreadcrumbComponent from '../../../components/BreadcrumbComponent/BreadcrumbComponent';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import LoadingTable from '../../../components/Table/Loading';
import moment from 'moment';
import useStore from '../../../store';
import { useLocation, useHistory } from 'react-router-dom';
import c from '../../../constant';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import RadioFormField from '../../../components/form/RadioFormField';
import TextFormField from '../../../components/form/TextFormField';

const CREATE_PRODUCT = gql`
	mutation create($name: String!, $code: String!, $amount: Float!, $currency: String!, $refType: String!) {
		createProduct(name: $name, code: $code, amount: $amount, currency: $currency, refType: $refType)
	}
`;

const complaintOnInsuranceSchema = Yup.object().shape({
	name: Yup.string().required('required'),
	code: Yup.string().required('required'),
	amount: Yup.string().required('required'),
	currency: Yup.string().required('required'),
	refType: Yup.string().required('required'),
});

function AddProduct() {
	const { t } = useTranslation();
	const { currentLang } = useStore((state) => state.currentLang);
	const history = useHistory();
	const toast = useToast();
	const [createProduct, { error, loading, data }] = useMutation(CREATE_PRODUCT);
	moment.locale(currentLang === 'kh' ? 'km' : 'en-gb');

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if (data) {
			console.log('data:', data);
			if (data.createProduct) {
				toast({
					title: t('Product created successfully'),
					status: 'success',
					duration: 5000,
					isClosable: true,
					position: 'bottom-right',
				});
				history.push('/product/list');
			}
		}
	}, [data]);

	useEffect(() => {
		if (error) {
			if (error?.graphQLErrors?.length > 0) {
				error?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(error?.message ? error?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [error]);

	return (
		<>
			<Container maxW="container.xl" mt="16px" mb="16px">
				<BreadcrumbComponent
					list={[
						{
							name: 'Settings',
							path: '/settings',
						},
						{
							name: 'Products',
							path: '/product/list',
						},
						,
						{
							name: 'Add',
							path: '#',
						},
					]}
				/>
				<Center mt="5">
					<Box
						w="100%"
						bg="white"
						borderRadius={c.borderRadius}
						border="1px solid #dbdbdb"
						boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
						pb="32px"
					>
						<Flex
							alignItems="center"
							justifyContent="space-between"
							bg="#FAFAFA"
							p="16px"
							pt="8px"
							pb="8px"
							mb="16px"
							borderBottom="1px solid #dbdbdb"
						>
							<Flex justifyContent="center" alignItems="center">
								<Text fontSize="x-large" fontWeight="bold" mr="4">
									{t('Add')}
									{t('Products')}
								</Text>
							</Flex>
						</Flex>

						<Box p="16px" maxW="480px">
							<Formik
								initialValues={{
									name: '',
									code: '',
									amount: '',
									currency: '',
									refType: '',
								}}
								validationSchema={complaintOnInsuranceSchema}
								onSubmit={(values) => {
									console.log(values);
									createProduct({
										variables: {
											name: values.name,
											code: values.code,
											amount: parseFloat(values.amount),
											currency: values.currency,
											refType: values.refType,
										},
									});
								}}
							>
								{(props) => (
									<Box>
										<Form>
											<TextFormField {...props} label="Name" name="name" required />
											<TextFormField {...props} label="Code" name="code" required />

											<TextFormField {...props} label="Amount" name="amount" required />

											<RadioFormField
												name="currency"
												label="Currency"
												radios={[
													{ label: 'KHR', value: 'KHR' },
													{ label: 'USD', value: 'USD' },
												]}
												required
											/>

											<RadioFormField
												name="refType"
												label="Ref Type"
												radios={[
													{ label: 'Register Agent', value: 'register_agent' },
													{ label: 'Agent Renewal', value: 'agent_renewal' },
													{ label: 'Public Service', value: 'public_service' },
												]}
												required
											/>

											<Button
												id="login-btn"
												variant="solid"
												size="md"
												h="42px"
												w="128px"
												type="submit"
												borderRadius={c.borderRadius}
												isLoading={loading}
											>
												{t('Save')}
											</Button>
										</Form>
									</Box>
								)}
							</Formik>
						</Box>
					</Box>
				</Center>
			</Container>
		</>
	);
}

export default AddProduct;
