import { Box, AspectRatio, Flex, Text, Image, MenuButton, Menu, MenuList, MenuItem, Button, Avatar, useToast } from "@chakra-ui/react";
import Countdown from 'react-countdown';

import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
export default function MaintenanceMessage({ date }) {

    
    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            return <Text fontWeight={600} color="#c0392b">Server is under maintenance</Text>;
        } else {
            return <Text fontWeight={600} color="#c0392b">
                The server will be maintenance in <span style={{color:"white", fontSize:20}}>{hours}:{minutes}:{seconds}</span>  . Please comeback later.
            </Text>;
        }
    };


    return (
        <Flex className="hidden-print" justifyContent="center" p="2" bg="#2c3e50" alignItems="center" minH={34}>
            <FontAwesomeIcon
                style={{
                    color: "#c0392b",
                    fontSize: 16,
                    marginRight: 8,
                    marginLeft: 8
                }}
                icon={faExclamationTriangle}
            />
            <Countdown date={moment(date).toDate()} renderer={renderer} />
        </Flex>
    );
}
