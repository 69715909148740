import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Box, Flex, Button, useToast, Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Text
} from "@chakra-ui/react";
import c from "../../constant"
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { useHistory } from "react-router-dom";
import TextFormField from "../../components/form/TextFormField"
import TextAreaFormField from "../../components/form/TextAreaFormField"
import FileFormField from "../../components/form/FileFormField"
import CheckBoxFormField from "../../components/form/CheckBoxFormField"
import RichTextEditor from "../../components/RichTextEditor/RichTextEditor";
import TableForm from "../../components/Table/TableFormWithoutSaveButton";
import RadioFormField from "../../components/form/RadioFormField"
import CheckListFormField from "../../components/form/CheckListFormField"

const EDIT_MUTATION = gql`
    mutation edit(
        $title: String!,
        $titleKh: String!,
        $departments: String!,
        $period: String!,
        $active:Boolean,,
        $id: String!
    ){
        editCompanyReportType(
            companyReportTypesInput:{
                title:$title,
                titleKh:$titleKh,
                departments:$departments,
                period:$period,
                active:$active
            }
            id: $id
        )
    }
`


const QUERY_DATA = gql`
query get($id: String!){
    getCompanyReportTypeById(id:$id){
        id
        title
        titleKh
        departments
        period
        active
    }
  }
`

const MAX_FILE_SIZE = 104857600

const signUpSchema = Yup.object().shape({
    title: Yup.string().required("Required"),
    licenseFee: Yup.string(),
    administrativeFee: Yup.string(),
    servicePeriod: Yup.string(),
    dueDate: Yup.number().nullable(),
    valid: Yup.string(),
    value: Yup.string(),
    file: Yup.mixed().test("fileSize", "over limit", (value) => {
        if (value) {
            return value?.size <= MAX_FILE_SIZE
        } else {
            return true
        }
    }),
    removeFile: Yup.boolean(),
});

function Create({
    isOpen = false, onClose = () => null, callback = () => null, id
}) {
    const history = useHistory();
    const toast = useToast()
    const { t } = useTranslation();
    const [body, setBody] = useState(null)
    const [steps, setSteps] = useState(null)


    const [editPublicService, { loading, error, data }] = useMutation(
        EDIT_MUTATION
    );

    const [loadData, { loading: loadingData, error: errorData, data: dataData }] = useLazyQuery(
        QUERY_DATA
    );



    useEffect(() => {
        if (id) {
            loadData({
                variables: {
                    id
                }
            })
        }
    }, [id]);

    useEffect(() => {
        if (data) {
            if (data?.editCompanyReportType) {
                toast({
                    title: t("Public service edited successfully"),
                    status: "success",
                    isClosable: true,
                    position: "top-right"
                })
                onClose()
                callback("Public service edited successfully")
            }
        }
    }, [data])

    useEffect(() => {
        if (error) {
            error.graphQLErrors.map(({ message }, i) => (
                toast({
                    title: t(message),
                    status: "error",
                    isClosable: true,
                    position: "top-right"
                })
            ))
        }
    }, [error])

    function arrayRoleToString(role){
        let result = ""
        if(role){
            role.map((item,index)=>{
                if(index===0){
                    result=item
                } else {
                    result += `,${item}`
                }
            })
        }
        return result
    }

    function create(values) {
        let variables = {
            title: values.title,
            titleKh: values.titleKh,
            departments: arrayRoleToString(values?.departments),
            period:values?.period,
            active: values.active,
            id
        }
        editPublicService({
            variables
        })
    }

    function stringToArray(role) {
        return role ? role.split(",") : []
    }

    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={() => {
                    onClose()

                }}
                size="6xl"
            >
                <ModalOverlay />
                <ModalContent borderRadius={c.borderRadius} pb="2">
                    <ModalHeader>{t("Edit Company Report Type")}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {dataData?.getCompanyReportTypeById && <Formik
                            initialValues={{
                                title: dataData?.getCompanyReportTypeById?.title,
                                titleKh: dataData?.getCompanyReportTypeById?.titleKh,
                                period: dataData?.getCompanyReportTypeById?.period,
                                departments: dataData?.getCompanyReportTypeById?.departments ? stringToArray(dataData?.getCompanyReportTypeById?.departments) : "",
                            }}
                            validationSchema={signUpSchema}
                            onSubmit={(values) => {
                                create(values);
                            }}
                        >
                            {(props) => (
                                <Form>
                                    <Box mt="3">
                                        <Flex>
                                            <Flex flex="1" direction="column" mr="2">
                                                <TextAreaFormField
                                                    {...props}
                                                    label="Title"
                                                    name="title"
                                                    required
                                                />
                                                <TextAreaFormField
                                                    {...props}
                                                    label="Title Khmer"
                                                    name="titleKh"
                                                    required
                                                />
                                            </Flex>
                                            <Flex flex="1" direction="column" ml="2">
                                                <RadioFormField
                                                    {...props}
                                                    name="period"
                                                    label="Period"
                                                    radios={[
                                                        { value: "MONTHLY", label: "Monthly" },
                                                        { value: "QUARTERLY", label: "Quarterly" },
                                                        { value: "SEMESTERLY", label: "Semesterly" },
                                                        { value: "ANNUALLY", label: "Annually" },
                                                    ]}
                                                    required={true}
                                                    initValue={dataData?.getCompanyReportTypeById?.period}
                                                />

                                                <CheckListFormField
                                                    {...props}
                                                    label="Department"
                                                    name="departments"
                                                    list={c.departments}
                                                    initValue={stringToArray(dataData?.getCompanyReportTypeById?.departments)}
                                                // callBack={e=>{
                                                //     setDepartmentCallback(e)
                                                // }}
                                                />
                                            </Flex>
                                        </Flex>



                                        <Flex
                                            w="100%"
                                            mt="6"
                                            justifyContent="flex-end"
                                        >
                                            <Button
                                                id="login-btn"
                                                variant="solid"
                                                size="md"
                                                h="42px"
                                                w="128px"
                                                bg="primary.dark"
                                                _hover={{ bg: "primary.dark" }}
                                                color="white"
                                                // borderRadius="31px"
                                                type="submit"
                                                isLoading={loading}
                                                loadingText={t("Edit")}
                                                spinnerPlacement="start"
                                            >
                                                {t(`Edit`)}
                                            </Button>
                                        </Flex>
                                    </Box>
                                </Form>
                            )}
                        </Formik>}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
}

export default Create;

