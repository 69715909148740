/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Container, Box, Flex, Text, Center, useToast, Button, TabPanels, TabPanel, TabList, Tab, Tabs } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { gql, useLazyQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { faPrint, faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SectionHeader } from '../../components/Other';
import c from '../../constant';
import useStore from '../../store';
import BreadcrumbComponent from '../../components/BreadcrumbComponent/BreadcrumbComponent';
import PrintEmployee from './PrintEmployee/PrintEmployee';
import LeaveRequest from '../../components/Employee/LeaveRequest';
import checkOneRole from '../../utils/checkOneRole';
import './style.css';

const keyOfficialDetailLastTabIndex = 'officialDetailLastTabIndex';

const Q_GET_EMPLOYEE_BY_ID = gql`
	query get($id: String!) {
		getEmployeeById(id: $id) {
			id
			createdAt
			profileImage
			govtNumber
			mefNumber
			fullName
			fullNameLatin
			gender
			dob
			maritalStatus
			pob
			isPobManual
			pobManual
			currentAddress
			isCurrentAddressManual
			currentAddressManual
			email
			phoneNumber
			idCardNumber
			documentIDCard
			idCardExpirationDate
			passportNumber
			documentPassport
			passportExpirationDate
			firstWorkingPlace
			currentWorkingStatus
			familyStatus
			spouseStatus
			additionalRoleOnCurrentJob
			situationRemainsWithinTheOriginalFramework
			unpaidVacancies
			inTheFieldOfPublicFunctions
			inThePrivateSector
			medalCertificateOfAppreciation
			discipline
			generalEducation
			degree
			specialization
			vocationalTraining
			foreignLanguage
			siblingInformation
			childInformation
		}
	}
`;

function EmployeeDetail() {
	const toast = useToast();
	const location = useLocation();
	const urlParams = new URLSearchParams(location.search);
	const id = urlParams.get('id') ? urlParams.get('id') : null;
	const { t } = useTranslation();
	const { currentUser } = useStore((state) => state.currentUser);
	const [tabAttribute, setTabAttribute] = useState({ index: 0, display: 'none' });
	const [getEmployeeInformation, { error: errorEmployeeInformation, data: dataEmployeeInformation, loading: loadingEmployeeInformation }] =
		useLazyQuery(Q_GET_EMPLOYEE_BY_ID);

	useEffect(() => {
		window.scrollTo(0, 0);
		getEmployeeInformation({ variables: { id } });
	}, []);

	useEffect(() => {
		if (errorEmployeeInformation) {
			errorEmployeeInformation.graphQLErrors.map(({ message }, i) =>
				toast({
					title: t(message),
					status: 'error',
					isClosable: true,
					position: 'top-right',
				})
			);
		}
	}, [errorEmployeeInformation]);

	useEffect(() => {
		if (currentUser) {
			const roles = currentUser?.role?.split(',') ?? [];
			if (roles?.includes('admin') || roles?.includes('employee') || currentUser?.canViewLeaveRequestReport) {
				setTabAttribute({
					index: +localStorage.getItem(keyOfficialDetailLastTabIndex) ?? 0,
					display: 'flex',
				});
			}
		}
	}, [currentUser]);

	function storeLastTabIndex(value) {
		localStorage.setItem(keyOfficialDetailLastTabIndex, value);
	}

	return (
		<>
			<Container maxW="container.xl" mb={4}>
				<BreadcrumbComponent
					list={
						checkOneRole(currentUser?.role, 'employee') || checkOneRole(currentUser?.role, 'admin')
							? [
									{
										name: t('OFFICIAL PROFILE'),
										path: '/employee/list?page=1',
									},
									{
										name: t('Detail'),
										path: '#',
									},
							  ]
							: [
									{
										name: t('Curriculum Vitae'),
										path: '#',
									},
							  ]
					}
				/>
				<Center className="content-detail">
					<Box
						className="subcontent-detail"
						w="100%"
						bg="white"
						borderRadius={c.borderRadius}
						boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
						pb={4}
					>
						<Flex
							className="hidden-print"
							height="48px"
							bg="#FAFAFA"
							p={4}
							pt={2}
							pb={2}
							mb={4}
							justifyContent="space-between"
							borderBottom="1px solid #dbdbdb"
						>
							<SectionHeader title={t('Official Detail')} />
							<Flex alignItems="center">
								<Button
									mr="2"
									onClick={() => window?.print()}
									leftIcon={<FontAwesomeIcon icon={faPrint} style={{ fontSize: 16 }} />}
									colorScheme="gray"
									variant="solid"
									size="sm"
									borderRadius={c.borderRadius}
									border="1px solid #bdc3c7"
								>
									{t('Print')}
								</Button>
								{
									<Link to={`/employee/edit?id=${id}`}>
										<Button
											leftIcon={<FontAwesomeIcon icon={faEdit} style={{ fontSize: 16 }} />}
											colorScheme="gray"
											variant="solid"
											size="sm"
											borderRadius={c.borderRadius}
											border="1px solid #bdc3c7"
										>
											{t('Edit')}
										</Button>
									</Link>
								}
							</Flex>
						</Flex>
						<Tabs
							className="tabs"
							index={tabAttribute.index}
							size="md"
							variant="enclosed"
							onChange={(index) => setTabAttribute({ ...tabAttribute, index })}
						>
							<TabList className="hidden-print" display={tabAttribute.display}>
								<Tab onClick={() => storeLastTabIndex('0')}>{t('Curriculum Vitae')}</Tab>
								<Tab onClick={() => storeLastTabIndex('1')}>{t('Request Leave')}</Tab>
							</TabList>
							<TabPanels>
								<TabPanel p={1}>
									<Box className="content-tab">
										{loadingEmployeeInformation && <Text textAlign="center">{t('Loading')}</Text>}
										{dataEmployeeInformation?.getEmployeeById && <PrintEmployee data={dataEmployeeInformation?.getEmployeeById} />}
									</Box>
								</TabPanel>
								<TabPanel p={1}>
									<Box className="content-tab">
										<LeaveRequest employeeId={id} />
									</Box>
								</TabPanel>
							</TabPanels>
						</Tabs>
					</Box>
				</Center>
			</Container>
		</>
	);
}

export default EmployeeDetail;
