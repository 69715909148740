/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Text } from '@chakra-ui/react';
import { gql, useLazyQuery } from '@apollo/client';
import useStore from '../../../store';

const Q_USER = gql`
	query getUserById($id: String!) {
		getUserById(id: $id) {
			id
			username
			fullName
			fullNameKhmer
			department
		}
	}
`;

export default function CardName({ id = null, defaultValue = 'N/A' }) {
	const { currentLang: lang } = useStore((state) => state.currentLang);
	const [getUser, { data }] = useLazyQuery(Q_USER);

	useEffect(() => {
		if (id) {
			const variables = { id };
			getUser({ variables });
		}
	}, [id]);

	return (
		<Text>
			{data
				? lang === 'kh'
					? data?.getUserById?.fullNameKhmer
						? data?.getUserById?.fullNameKhmer
						: data?.getUserById?.fullName
						? data?.getUserById?.fullName
						: data?.getUserById?.username
					: data?.getUserById?.fullName
					? data?.getUserById?.fullName
					: data?.getUserById?.fullNameKhmer
					? data?.getUserById?.fullNameKhmer
					: data?.getUserById?.username
				: defaultValue}
		</Text>
	);
}
