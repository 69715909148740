import React, { useEffect, useState } from "react";
import { Box, Text, Flex, Input, Image, useToast } from "@chakra-ui/react";
import Resizer from "react-image-file-resizer";
import fileUpload from "../../providers/fileUpload"
import c from "../../constant"
import UploadingModal from "../UploadingModal/UploadingModal"
import { useTranslation } from "react-i18next";

const ImagePicker = ({initContent={}, onContentChange, size=460, label="Please choose an Image file"}) => {
    const { t } = useTranslation();
    const toast = useToast()
    // const [original, setOriginal] = useState(initContent?.original);
    const [medium, setMedium] = useState(initContent?.medium?initContent?.medium:"");
    const [icon, setIcon] = useState(initContent?.icon?initContent?.icon:"");
    const [preview, setPreView] = useState(initContent?.medium?c.baseFilePath+initContent?.medium:"");

    const [loading, setLoading] = useState(false)
    const [error, serError] = useState(false)

    function errorMessage(type){
        toast({
            title: `Image type of ${type} failed to upload`,
            status: "error",
            position:"top-right",
            isClosable: true,
        })
    }

    useEffect(()=>{
        if(medium && icon){
            const content = {
                medium,
                icon
            }
            onContentChange(content)
            setLoading(false)
        }
    },[medium,icon])

    function fileChangedHandler(event) {
        setLoading(true)
        var fileInput = false;
        if (event.target.files[0]) {
            fileInput = true;
        }
        if (fileInput) {
            try {
                Resizer.imageFileResizer(
                    event.target.files[0],
                    size,
                    "auto",
                    "JPEG",
                    90,
                    0,
                    (uri) => {
                        setPreView(uri);
                    },
                    "base64"
                );
                Resizer.imageFileResizer(
                    event.target.files[0],
                    size,
                    "auto",
                    "JPEG",
                    90,
                    0,
                    (uri) => {
                        fileUpload(uri).then(response=>{
                            setMedium(response);
                        }).catch(()=>{
                            serError(true)
                            errorMessage("medium")
                        })
                    },
                    "file"
                );
                Resizer.imageFileResizer(
                    event.target.files[0],
                    88,
                    "auto",
                    "JPEG",
                    90,
                    0,
                    (uri) => {
                        fileUpload(uri).then(response=>{
                            setIcon(response);
                        }).catch(()=>{
                            serError(true)
                            errorMessage("medium")
                        })
                    },
                    "file"
                );
            } catch (err) {
                console.log(err);
            }
        }
    }

    return (
        <>
            <Box bg="gray.200" p="4" mb="4" borderRadius={c.borderRadius}>
                
                <Image src={
                    initContent?.medium?`${c.baseFilePath}${initContent?.medium}`:preview
                    } w="100%" h="auto" mt="0" />
                <Text fontWeight="bold" mb="2" mt="2">
                    {t(label)}
                </Text>
                <input
                    id="file"
                    type="file"
                    accept="image/*"
                    onChange={fileChangedHandler}
                />
            </Box>
            <UploadingModal isOpen={(!error&&loading)}/>
        </>
    );
};

export default ImagePicker;
