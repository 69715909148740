/* eslint-disable react-hooks/exhaustive-deps */
import { gql, useLazyQuery } from '@apollo/client';
import { useContext, createContext } from 'react';

const Q_GET_REQUEST = gql`
	query get {
		getCountAgentPartnerRequest {
			countPrimaryCertificateReview
			countSecondaryCertificateReview
			countWaitingForContract
			countPrimaryContractReview
			countSecondaryContractReview
			countCompleted
			countAll
		}
	}
`;

const Q_INTERMEDIARY_COUNT = gql`
	query get {
		getIntermediaryBadgeCount
	}
`;

const PartnerRequestCountContext = createContext();

export const PartnerRequestCountProvider = ({ children }) => {
	const [load, { data }] = useLazyQuery(Q_GET_REQUEST);
	const [loadIntermediaryCount, { data: dataIntermediaryCount }] = useLazyQuery(Q_INTERMEDIARY_COUNT);

	const loadCountPartnerRequest = () => {
		load();
	};

	const loadRequestIntermediaryCount = () => {
		loadIntermediaryCount();
	};

	return (
		<PartnerRequestCountContext.Provider
			value={{
				loadCountPartnerRequest,
				countPrimaryCertificateReview: parseInt(data?.getCountAgentPartnerRequest?.countPrimaryCertificateReview),
				countSecondaryCertificateReview: parseInt(data?.getCountAgentPartnerRequest?.countSecondaryCertificateReview),
				countWaitingForContract: parseInt(data?.getCountAgentPartnerRequest?.countWaitingForContract),
				countPrimaryContractReview: parseInt(data?.getCountAgentPartnerRequest?.countPrimaryContractReview),
				countSecondaryContractReview: parseInt(data?.getCountAgentPartnerRequest?.countSecondaryContractReview),
				countCompleted: parseInt(data?.getCountAgentPartnerRequest?.countCompleted),
				countAll: parseInt(data?.getCountAgentPartnerRequest?.countAll),
				countAllPartnerRequestPrimary:
					parseInt(data?.getCountAgentPartnerRequest?.countPrimaryCertificateReview) +
					parseInt(data?.getCountAgentPartnerRequest?.countPrimaryContractReview),
				countAllPartnerRequestSecondary:
					parseInt(data?.getCountAgentPartnerRequest?.countSecondaryCertificateReview) +
					parseInt(data?.getCountAgentPartnerRequest?.countSecondaryContractReview),
				loadRequestIntermediaryCount,
				intermediaryBadgeCount: dataIntermediaryCount?.getIntermediaryBadgeCount,
			}}
		>
			{children}
		</PartnerRequestCountContext.Provider>
	);
};

export const usePartnerRequestCount = () => {
	return useContext(PartnerRequestCountContext);
};
