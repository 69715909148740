import React from 'react';
import Table from '@cloudscape-design/components/table';
import Box from '@cloudscape-design/components/box';
import SpaceBetween from '@cloudscape-design/components/space-between';
import TextFilter from '@cloudscape-design/components/text-filter';
import Pagination from '@cloudscape-design/components/pagination';
import c from '../../../constant';
import { useTranslation } from 'react-i18next';
import { Flex, Text } from '@chakra-ui/react'; 
import './table-style.css';
import '@cloudscape-design/global-styles/index.css';
import CollectionPreferences from '@cloudscape-design/components/collection-preferences';
import { useColumnWidths } from './table-helper/use-column-widths';
import useStore from '../../../store';

const TableComponent = ({
	tableName,
	header,
	rawColumns,
	currentPage,
	totalPage,
	loading,
	items,
	preferences,
	setPreferences,
	onTablePageChange,
	onSearchTextChange,
	searchText,
	totalRows
}) => {
	const { currentLang } = useStore((state) => state.currentLang);
	const { t } = useTranslation();
	const [columnDefinitions, saveWidths] = useColumnWidths(
		`${tableName}-table-Widths`,
		rawColumns.map((column) => ({ ...column })),
	);

	return (
		<Flex w="100%" bg="white" borderRadius={c.borderRadius} border="1px solid #dbdbdb" boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)" p="10px">
			<Table
				className="my-table"
				header={header}
				loading={loading}
				stickyHeader={true}
				resizableColumns={true}
				stickyColumns={preferences?.stickyColumns}
				stripedRows={preferences?.stripedRows}
				columnDisplay={preferences?.contentDisplay}
				wrapLines={preferences?.wrapLines}
				contentDensity={preferences?.contentDensity}
				variant="embedded"
				columnDefinitions={columnDefinitions}
				onColumnWidthsChange={saveWidths}
				items={items}
				loadingText="Loading"
				empty={
					<Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
						<SpaceBetween size="m">
							<b>{t('No data')}</b>
						</SpaceBetween>
					</Box>
				}
				filter={<TextFilter filteringPlaceholder={t('Search')} filteringText={searchText} onChange={(e) => onSearchTextChange(e)} />}
				pagination={
					<Pagination disabled={loading} currentPageIndex={parseInt(currentPage)} pagesCount={totalPage} onChange={(e) => onTablePageChange(e)} />
				}
				footer={
					<Flex justifyContent="space-between">
						<Flex mr="20px"></Flex>
						<Text color="#0972d3" fontWeight={600} fontSize={14}>
							{t('Total Rows')}: {totalRows}
						</Text>
					</Flex>
				}
				preferences={
					<CollectionPreferences
						title={t('Preferences')}
						confirmLabel={t('Confirm')}
						cancelLabel={t('Cancel')}
						preferences={preferences}
						onConfirm={({ detail }) => setPreferences(detail)}
						
						pageSizePreference={{
							title: t('Page size'),
							options: [
								{ value: 10, label: `10 ${t('rows')}` },
								{ value: 25, label: `25 ${t('rows')}` },
								{ value: 50, label: `50 ${t('rows')}` },
								{ value: 300, label: `300 ${t('rows')}` },
							],
						}}
						wrapLinesPreference={{
							label: currentLang === 'kh'?"រុំបន្ទាត់":"Wrap lines",
							description: currentLang === 'kh'?"ជ្រើសរើសដើម្បីមើលទិន្នន័យទាំងអស់ ហើយរុំបន្ទាត់":"Select to see all the text and wrap the lines"
						}}
    					stripedRowsPreference={{
							label: currentLang === 'kh'?"ជួរឆ្នូត":"Striped rows",
							description:currentLang === 'kh'?"ជ្រើសរើសដើម្បីបន្ថែមជួរដេកដែលមានស្រមោលឆ្លាស់គ្នា។":"Select to add alternating shaded rows"
						}}
						contentDensityPreference={{
							label: currentLang === 'kh'?"ទម្រង់បង្រួម":"Compact mode",
							description:currentLang === 'kh'?"ជ្រើសរើស​ដើម្បី​បង្ហាញ​ខ្លឹមសារ​ក្នុង​ទម្រង់​បង្រួម​":"Select to display content in a denser, more compact mode"
						}}
						contentDisplayPreference={{
							options: rawColumns.map((column) => ({
								id: column.id,
								label: column.label || '',
								alwaysVisible: column?.alwayVisible || false,
							})),
						}}
						stickyColumnsPreference={{
							firstColumns: {
								title: currentLang === 'kh' ? 'ស្អិត​ជួរ​ឈរ​ដំបូង' : 'Stick first column(s)',
								description:
									currentLang === 'kh'
										? 'រក្សា​ជួរ​ឈរ​ដំបូង​ដែល​មើល​ឃើញ ខណៈ​ពេល​ដែលផ្លាស់ទី​មាតិកា​តារាង​ដេក។'
										: 'Keep the first column(s) visible while horizontally scrolling the table content.',
								options: [
									{ label: currentLang === 'kh' ? 'គ្មាន' : 'None', value: 0 },
									{ label: currentLang === 'kh' ? 'ជួរទីមួយ' : 'First column', value: 1 },
									{ label: currentLang === 'kh' ? 'ជួរឈរពីរដំបូង' : 'First two columns', value: 2 },
								],
							},
							lastColumns: {
								title: currentLang === 'kh' ? 'ស្អិត​ជួរឈរចុងក្រោយ' : 'Stick last column',
								description:
									currentLang === 'kh'
										? 'រក្សា​ជួរ​ឈរ​ចុងក្រោយែល​មើល​ឃើញ ខណៈ​ពេល​ដែលផ្លាស់ទី​មាតិកា​តារាង​ដេក។'
										: 'Keep the last column visible while horizontally scrolling the table content.',
								options: [
									{ label: currentLang === 'kh' ? 'គ្មាន' : 'None', value: 0 },
									{ label: currentLang === 'kh' ? 'ជួរចុងក្រោយ' : 'Last column', value: 1 },
								],
							},
						}}
					/>
				}
			/>
		</Flex>
	);
};

export default React.memo(TableComponent);