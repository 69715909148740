/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Center, Box, Flex, Text, Table, Tbody, Tr, Th, Td, Tfoot, Thead } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { SectionHeader } from '../../Other';
import c from '../../../constant';

function ContactStaffPrint({ dataCompanyContactStaffs, ...props }) {
  const { t } = useTranslation();

  function formatPhoneNumber(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }

  return (
    <>
      <Flex className="show-only-printing" mt={4} {...props}>
        <Box width="100%">
          <Center>
            <Box
              w="100%"
              bg="white"
              borderRadius={c.borderRadius}
              border="1px solid #dbdbdb"
              boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
              pb={2}
            >
              <Flex
                h="48px"
                bg="#FAFAFA"
                pl="16px"
                pr="16px"
                mb="16px"
                alignItems="center"
                justifyContent="space-between"
                borderBottom="1px solid #dbdbdb"
              >
                <SectionHeader title={t('Contact Staff')} fontSize="lg" />
              </Flex>
              <Box p="16px">
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>{t('Name')}</Th>
                      <Th>{t('Gender')}</Th>
                      <Th>{t('Phone number')}</Th>
                      <Th>{t('Email')}</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {dataCompanyContactStaffs?.getCompanyStaff.length <= 0 && (
                      <Tr>
                        <Td>
                          <Text>{t('No Data')}</Text>
                        </Td>
                      </Tr>
                    )}
                    {dataCompanyContactStaffs?.getCompanyStaff?.map((item, index) => {
                      return (
                        <Tr
                          cursor="pointer"
                          _hover={{ bg: 'gray.200' }}
                          key={`item-${index}`}
                          bg="white"
                        >
                          <Td>{item.name}</Td>
                          <Td>{t(item.gender)}</Td>
                          <Td>{item.phoneNumber ? formatPhoneNumber(item?.phoneNumber) : ''}</Td>
                          <Td></Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                  <Tfoot>
                    <Tr>
                      <Th>{t('Name')}</Th>
                      <Th>{t('Gender')}</Th>
                      <Th>{t('Phone number')}</Th>
                      <Th>{t('Email')}</Th>
                    </Tr>
                  </Tfoot>
                </Table>
              </Box>
            </Box>
          </Center>
        </Box>
      </Flex>
    </>
  );
}

export default ContactStaffPrint;
