import React, { useEffect } from "react";
import { gql, useLazyQuery } from '@apollo/client';

const QUERY_DATA = gql`
query get($id: String!){
    getCountAPIRequestByDomainId(id:$id)
  }
`

function Count({ id }) {
    const [loadData, { data }] = useLazyQuery(QUERY_DATA)
    useEffect(() => {
        loadData({
            variables: {
                id
            }
        })
    }, []);

    return (
        <>
            {data?.getCountAPIRequestByDomainId}
        </>
    );
}

export default Count;

