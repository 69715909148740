import { Text } from '@chakra-ui/react';

function AgentCode({ code = null }) {
	return (
		<>
			<Text fontSize="md" fontWeight="semibold" color="blue.500">
				{code}
			</Text>
		</>
	);
}

export default AgentCode;
