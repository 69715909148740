import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Container,
	Center,
	Box,
	Flex,
	Text,
	MenuButton,
	MenuList,
	MenuItem,
	Menu,
	Button,
	Input,
	Textarea,
	useToast,
	Switch,
	FormControl,
	FormLabel,
	Tooltip,
	Tabs,
	TabList,
	Tab,
	TabPanels,
	TabPanel,
} from '@chakra-ui/react';
import BreadcrumbComponent from '../../components/BreadcrumbComponent/BreadcrumbComponent';
import { ChevronDownIcon, CloseIcon, ChevronUpIcon, CopyIcon } from '@chakra-ui/icons';
import { useLocation } from 'react-router-dom';
import RichTextEditor from '../../components/RichTextEditor/RichTextEditor';
import ImagePicker from '../../components/ImagePicker/ImagePicker';
import PDFPicker from '../../components/PDFPicker/PDFPicker';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import c from '../../constant';
import DateTimePicker from 'react-datetime-picker';
import moment from 'moment';
import 'moment/locale/en-gb';
import arrayMove from 'array-move';
import { v4 as uuidv4 } from 'uuid';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ChangeArticleCategoryModal from '../../components/Modal/ChangeArticleCategoryModal';

moment.locale('en-gb');

const UPDATE_ARTICLE_MUTATION = gql`
	mutation (
		$title: String
		$description: String
		$thumbnail: JSON
		$category: String!
		$content: JSON
		$titleKH: String
		$descriptionKH: String
		$thumbnailKH: JSON
		$contentKH: JSON
		$isPublish: Boolean!
		$id: String!
		$publishDate: DateTime!
		$display: Boolean!
	) {
		updateArticleById(
			id: $id
			articleInput: {
				title: $title
				description: $description
				thumbnail: $thumbnail
				category: $category
				content: $content
				titleKH: $titleKH
				descriptionKH: $descriptionKH
				thumbnailKH: $thumbnailKH
				contentKH: $contentKH
				isPublish: $isPublish
				publishDate: $publishDate
				display: $display
			}
		) {
			id
			title
			thumbnail
			description
			content
			category
			createdAt
			updatedAt
			deletedAt
			isPublish
			display
		}
	}
`;

const QUERY_ARTICLE_BY_ID = gql`
	query article($id: String!) {
		adminArticle(id: $id) {
			id
			title
			description
			thumbnail
			isPublish
			content
			titleKH
			descriptionKH
			thumbnailKH
			contentKH
			category
			publishDate
			display
		}
	}
`;

function EditArticle() {
	const { t } = useTranslation();
	const location = useLocation();
	const toast = useToast();
	const urlParams = new URLSearchParams(location.search);
	const id = urlParams.get('id');
	const history = useHistory();

	const [updateArticle, { loading, error, data }] = useMutation(UPDATE_ARTICLE_MUTATION);

	const [queryArticleById, { loading: loadingArticle, error: errorArticle, data: dataArticle }] = useLazyQuery(QUERY_ARTICLE_BY_ID);

	const [articleTitle, setArticleTitle] = useState('');
	const [articleDescription, setArticleDescription] = useState('');
	const [articleThumbnail, setArticleThumbnail] = useState({});

	const [articleTitleKH, setArticleTitleKH] = useState('');
	const [articleDescriptionKH, setArticleDescriptionKH] = useState('');
	const [articleThumbnailKH, setArticleThumbnailKH] = useState({});

	const [mainContentKH, setMainContentKH] = useState([]);

	const [mainContent, setMainContent] = useState([]);
	const [isPublish, setIsPublish] = useState(false);
	const [category, setCategory] = useState('');
	const [articleId, setArticleId] = useState('');
	const [publishDate, setPublishDate] = useState(moment().toDate());
	const [display, setDisplay] = useState(false);

	const [showEditor, setShowEditor] = useState(false);
	const [openChangeCategory, setOpenChangeCategory] = useState(false);

	const [removingThumbnail, setRemovingThumbnail] = useState(false);
	const [removingThumbnailKH, setRemovingThumbnailKH] = useState(false);

	useEffect(() => {
		window.scrollTo(0, 0);
		queryArticleById({
			variables: {
				id,
			},
		});
	}, []);

	useEffect(() => {
		if (dataArticle) {
			try {
				const article = dataArticle?.adminArticle;
				setArticleTitle(article.title);
				setArticleDescription(article.description);
				setArticleThumbnail(article.thumbnail);
				setIsPublish(article?.isPublish);
				setCategory(article?.category);
				setArticleId(article?.id);
				setPublishDate(moment(article?.publishDate).toDate());
				setDisplay(article?.display);
				const tmpMainContent = [];
				article?.content?.map((item) => {
					tmpMainContent.push({ content: item.content, type: item.type });
				});
				setMainContent(tmpMainContent);

				// Khmer

				setArticleTitleKH(article.titleKH);
				setArticleDescriptionKH(article.descriptionKH);
				setArticleThumbnailKH(article.thumbnailKH);

				const tmpMainContentKH = [];
				article?.contentKH?.map((item) => {
					tmpMainContentKH.push({ content: item.content, type: item.type });
				});
				setMainContentKH(tmpMainContentKH);

				setTimeout(() => {
					setShowEditor(true);
				}, 1000);
			} catch (error) {
				console.log(error);
			}
		}
	}, [dataArticle]);

	useEffect(() => {
		if (data?.updateArticleById?.id) {
			toast({
				title: 'Article updated.',
				status: 'success',
				duration: 5000,
				isClosable: true,
				position: 'top-right',
			});
			setTimeout(() => {
				history.push(`/website`);
			}, 500);
		}
	}, [data]);

	useEffect(() => {
		if (error) {
			error.graphQLErrors.map(({ message }, i) =>
				toast({
					title: message,
					status: 'error',
					isClosable: true,
					position: 'top-right',
				})
			);
		}
	}, [error]);

	function contentChange({ content, index }) {
		let tmpContent = mainContent;
		tmpContent[index].content = content;
		setMainContent(tmpContent);
	}

	function addType(type) {
		const tmpMainContent = [...mainContent];
		tmpMainContent.push({
			type,
			content: null,
		});
		setMainContent(tmpMainContent);
		setTimeout(() => {
			window.scrollTo({
				top: document.body.scrollHeight,
				left: 0,
			});
		}, 300);
	}

	function saveArticle() {
		if (articleTitle && mainContent[0]?.content) {
			const variables = {
				id: articleId,
				title: articleTitle,
				description: articleDescription,
				thumbnail: articleThumbnail,
				category: category,
				content: mainContent,
				isPublish: isPublish,
				publishDate: moment(publishDate).toDate(),

				// Khmer
				titleKH: articleTitleKH,
				descriptionKH: articleDescriptionKH,
				thumbnailKH: articleThumbnailKH,
				contentKH: mainContentKH,

				display: display,
			};
			updateArticle({
				variables,
			});
		} else {
			!articleTitle && warningMessage('Title is required.');
			!mainContent[0]?.content && warningMessage('Content is required.');
		}
	}

	function warningMessage(message) {
		toast({
			title: message,
			status: 'warning',
			isClosable: true,
			position: 'top-right',
		});
	}

	function sendObjectToKH(index) {
		setTimeout(() => {
			const item = { ...mainContent[index] };
			setMainContentKH([...mainContentKH, item]);
			toast({
				title: 'Copy object to Khmer content successfully.',
				status: 'success',
				duration: 2000,
				isClosable: true,
				position: 'top-right',
			});
		}, 500);
	}

	function removeButton(index) {
		return (
			<Flex justifyContent="flex-end" mt="8">
				<Box mb="-10" zIndex="900">
					<Tooltip label="Make a copy at Khmer content">
						<CopyIcon fontSize="22px" mr="4px" cursor="pointer" onClick={() => sendObjectToKH(index)} />
					</Tooltip>

					{index != 0 && (
						<Tooltip label="Move this content up">
							<ChevronUpIcon fontSize="32px" cursor="pointer" onClick={() => moveIndex({ oldIndex: index, newIndex: index - 1 })} />
						</Tooltip>
					)}
					<Tooltip label="Move this content down">
						<ChevronDownIcon cursor="pointer" fontSize="32px" onClick={() => moveIndex({ oldIndex: index, newIndex: index + 1 })} />
					</Tooltip>
				</Box>
				<Tooltip label="Remove this content up">
					<Box mb="-10" cursor="pointer" zIndex="999" onClick={() => removeContentByIndex(index)}>
						<CloseIcon m="2" />
					</Box>
				</Tooltip>
			</Flex>
		);
	}

	function removeContentByIndex(x) {
		const tmpMainContent = [...mainContent];
		tmpMainContent.splice(x, 1);
		setMainContent(tmpMainContent);
	}

	function moveIndex({ oldIndex, newIndex }) {
		const items = [...mainContent];
		setMainContent(arrayMove(items, oldIndex, newIndex));
	}

	function removeThumbnailButton() {
		return (
			<Flex justifyContent="flex-end" mt="8">
				<Tooltip label="Remove this thumbnail">
					<Box
						mb="-20px"
						cursor="pointer"
						zIndex="999"
						onClick={() => {
							setRemovingThumbnail(true);
							setArticleThumbnail({});

							setTimeout(() => {
								setRemovingThumbnail(false);
							}, 300);
						}}
					>
						<CloseIcon m="2" />
					</Box>
				</Tooltip>
			</Flex>
		);
	}

	// Khmer

	function contentChangeKH({ content, index }) {
		let tmpContent = mainContentKH;
		tmpContent[index].content = content;
		setMainContentKH(tmpContent);
	}

	function addTypeKH(type) {
		const tmpMainContent = [...mainContentKH];
		tmpMainContent.push({
			type,
			content: '',
		});
		setMainContentKH(tmpMainContent);
		setTimeout(() => {
			window.scrollTo({
				top: document.body.scrollHeight,
				left: 0,
			});
		}, 300);
	}

	function removeButtonKH(index) {
		return (
			<Flex justifyContent="flex-end" mt="8">
				<Box mb="-10" zIndex="900">
					{index != 0 && (
						<Tooltip label="Move this content up">
							<ChevronUpIcon fontSize="32px" cursor="pointer" onClick={() => moveIndexKH({ oldIndex: index, newIndex: index - 1 })} />
						</Tooltip>
					)}
					<Tooltip label="Move this content down">
						<ChevronDownIcon cursor="pointer" fontSize="32px" onClick={() => moveIndexKH({ oldIndex: index, newIndex: index + 1 })} />
					</Tooltip>
				</Box>
				<Tooltip label="Remove this content up">
					<Box mb="-10" cursor="pointer" zIndex="999" onClick={() => removeContentByIndexKH(index)}>
						<CloseIcon m="2" />
					</Box>
				</Tooltip>
			</Flex>
		);
	}

	function removeContentByIndexKH(x) {
		const tmpMainContent = [...mainContentKH];
		tmpMainContent.splice(x, 1);
		setMainContentKH(tmpMainContent);
	}

	function moveIndexKH({ oldIndex, newIndex }) {
		const items = [...mainContentKH];
		setMainContentKH(arrayMove(items, oldIndex, newIndex));
	}

	function removeThumbnailButtonKH() {
		return (
			<Flex justifyContent="flex-end" mt="8">
				<Tooltip label="Remove this thumbnail">
					<Box
						mb="-20px"
						cursor="pointer"
						zIndex="999"
						onClick={() => {
							setRemovingThumbnailKH(true);
							setArticleThumbnailKH({});

							setTimeout(() => {
								setRemovingThumbnailKH(false);
							}, 300);
						}}
					>
						<CloseIcon m="2" />
					</Box>
				</Tooltip>
			</Flex>
		);
	}

	// End Khmer

	return (
		<>
			<Container maxW="container.xl" mt="16px" mb="16px">
				<BreadcrumbComponent
					list={[
						{
							name: 'WEBSITE',
							path: '/website',
						},
						{
							name: 'EDIT ARTICLE',
							path: '#',
						},
					]}
				/>
				<Center mt="5">
					<Box
						w="100%"
						bg="white"
						borderRadius={c.borderRadius}
						border="1px solid #dbdbdb"
						boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
						pb="92px"
					>
						<Flex bg="#FAFAFA" p="16px" pt="8px" pb="8px" mb="16px" justifyContent="space-between" borderBottom="1px solid #dbdbdb">
							<Text fontSize="x-large" fontWeight="bold">
								{t('Edit Article')}
							</Text>
							<Flex>
								<Menu>
									<MenuButton cursor="pointer">
										<Flex>
											<FontAwesomeIcon icon={faEllipsisV} style={{ fontSize: 16, marginTop: 4, marginLeft: 6 }} />
										</Flex>
									</MenuButton>
									<MenuList pt="4" pb="4" pl="3" pr="3" borderRadius={c.borderRadius}>
										<MenuItem color="green" onClick={() => setOpenChangeCategory(true)}>
											{t('Change category')}
										</MenuItem>
									</MenuList>
								</Menu>
							</Flex>
						</Flex>
						{errorArticle && (
							<>
								{errorArticle?.graphQLErrors?.map((item, index) => {
									return (
										<Text color="red" ml="4" key={`error-message-${index}`}>
											{item.message}
										</Text>
									);
								})}
							</>
						)}
						{!showEditor && !errorArticle && <Text textAlign="center">{t('Loading')}</Text>}
						{showEditor && (
							<>
								<Tabs variant="enclosed">
									<TabList ml="16px">
										<Tab _selected={{ color: 'white', bg: 'blue.500' }}>English</Tab>
										<Tab _selected={{ color: 'white', bg: 'green.400' }}>ភាសាខ្មែរ</Tab>
									</TabList>
									<TabPanels>
										<TabPanel>
											<Button
												pointerEvents="none"
												position="fixed"
												transform="rotateZ(90deg)"
												zIndex="999"
												top="50%"
												left="-24px"
												color="white"
												bg="blue.500"
											>
												English
											</Button>
											{/* English */}

											<Flex p="4" justifyContent="space-between" borderBottom="1px solid #dbdbdb">
												<Box>
													<Text fontWeight="bold">{t('Title')}</Text>
													<Input
														bg="white"
														w="300px"
														id="file-name"
														type="text"
														value={articleTitle}
														onChange={(e) => setArticleTitle(e.target.value)}
													/>
													<Text mt="4" fontWeight="bold">
														{t('Description')}
													</Text>
													<Textarea
														value={articleDescription}
														onChange={(e) => setArticleDescription(e.target.value)}
														placeholder=""
														size="md"
														h="150px"
													/>
												</Box>
												<Box maxW="450px">
													{removeThumbnailButton()}
													<Text fontWeight="bold">{t('Thumbnail')}</Text>
													{removingThumbnail ? (
														<Text>...</Text>
													) : (
														<ImagePicker
															initContent={articleThumbnail}
															onContentChange={(content) => setArticleThumbnail(content)}
														/>
													)}
												</Box>
											</Flex>
											<Box p="16px">
												{mainContent.map((item, index) => {
													if (item.type === 'rich-text') {
														return (
															<Box key={uuidv4()} mb="16px">
																{removeButton(index)}
																<RichTextEditor
																	key={uuidv4()}
																	initContent={item.content}
																	onContentChange={(content) =>
																		contentChange({
																			content,
																			index,
																		})
																	}
																/>
															</Box>
														);
													}

													if (item.type === 'image') {
														return (
															<Box key={uuidv4()}>
																{removeButton(index)}
																<ImagePicker
																	initContent={item.content}
																	onContentChange={(content) =>
																		contentChange({
																			content,
																			index,
																		})
																	}
																/>
															</Box>
														);
													}

													if (item.type === 'pdf') {
														return (
															<Box key={uuidv4()}>
																{removeButton(index)}
																<PDFPicker
																	initContent={item.content}
																	onContentChange={(content) =>
																		contentChange({
																			content,
																			index,
																		})
																	}
																/>
															</Box>
														);
													}
												})}
												<Box mt="16px">
													<Menu>
														<MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
															{t('Add Content')}
														</MenuButton>
														<MenuList borderRadius={c.borderRadius}>
															<MenuItem onClick={() => addType('rich-text')}>{t('Rich Text')}</MenuItem>
															<MenuItem onClick={() => addType('image')}>{t('Image')}</MenuItem>
															<MenuItem onClick={() => addType('pdf')}>{t('Download PDF')}</MenuItem>
														</MenuList>
													</Menu>
												</Box>
											</Box>
											{/* End English */}
										</TabPanel>
										<TabPanel>
											<Button
												pointerEvents="none"
												position="fixed"
												transform="rotateZ(90deg)"
												zIndex="999"
												top="50%"
												left="-24px"
												color="white"
												bg="green.400"
											>
												ភាសាខ្មែរ
											</Button>
											{/* Khmer */}
											<Flex p="4" justifyContent="space-between" borderBottom="1px solid #dbdbdb">
												<Box>
													<Text fontWeight="bold">{t('Title')}</Text>
													<Input
														bg="white"
														w="300px"
														id="file-name"
														type="text"
														value={articleTitleKH}
														onChange={(e) => setArticleTitleKH(e.target.value)}
													/>
													<Text mt="4" fontWeight="bold">
														{t('Description')}
													</Text>
													<Textarea
														value={articleDescriptionKH}
														onChange={(e) => setArticleDescriptionKH(e.target.value)}
														placeholder=""
														size="md"
														h="150px"
													/>
												</Box>
												<Box maxW="450px">
													{removeThumbnailButtonKH()}
													<Text fontWeight="bold">{t('Thumbnail')}</Text>
													{removingThumbnailKH ? (
														<Text>...</Text>
													) : (
														<ImagePicker
															initContent={articleThumbnailKH}
															onContentChange={(content) => setArticleThumbnailKH(content)}
														/>
													)}
												</Box>
											</Flex>
											<Box p="16px">
												{mainContentKH.map((item, index) => {
													if (item.type === 'rich-text') {
														return (
															<Box key={uuidv4()} mb="16px">
																{removeButtonKH(index)}
																<RichTextEditor
																	key={uuidv4()}
																	initContent={item.content}
																	onContentChange={(content) =>
																		contentChangeKH({
																			content,
																			index,
																		})
																	}
																/>
															</Box>
														);
													}

													if (item.type === 'image') {
														return (
															<Box key={uuidv4()}>
																{removeButtonKH(index)}
																<ImagePicker
																	initContent={item.content}
																	onContentChange={(content) =>
																		contentChangeKH({
																			content,
																			index,
																		})
																	}
																/>
															</Box>
														);
													}

													if (item.type === 'pdf') {
														return (
															<Box key={uuidv4()}>
																{removeButtonKH(index)}
																<PDFPicker
																	initContent={item.content}
																	onContentChange={(content) =>
																		contentChangeKH({
																			content,
																			index,
																		})
																	}
																/>
															</Box>
														);
													}
												})}
												<Box mt="16px">
													<Menu>
														<MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
															{t('Add Content')}
														</MenuButton>
														<MenuList borderRadius={c.borderRadius}>
															<MenuItem onClick={() => addTypeKH('rich-text')}>{t('Rich Text')}</MenuItem>
															<MenuItem onClick={() => addTypeKH('image')}>{t('Image')}</MenuItem>
															<MenuItem onClick={() => addTypeKH('pdf')}>{t('Download PDF')}</MenuItem>
														</MenuList>
													</Menu>
												</Box>
											</Box>
											{/* End Khmer */}
										</TabPanel>
									</TabPanels>
								</Tabs>
								<Box>
									<Flex justifyContent="flex-end" alignItems="center" mt="4">
										<Text mr="2" fontWeight="bold">
											{t('Publish Date')}
										</Text>
										<DateTimePicker onChange={setPublishDate} value={publishDate} />
									</Flex>
									<Flex mt="4" direction="column">
										<Box>
											<FormControl display="flex" alignItems="center" justifyContent="flex-end">
												<FormLabel htmlFor="email-alerts" mb="0">
													{t('Publish')}
												</FormLabel>
												<Switch
													isChecked={isPublish}
													id="email-alerts"
													colorScheme="green"
													size="lg"
													onChange={() => setIsPublish(!isPublish)}
												/>
											</FormControl>
										</Box>
										{category === 'announcement' && (
											<Box mt="4">
												<FormControl display="flex" alignItems="center" justifyContent="flex-end">
													<FormLabel htmlFor="email-alerts" mb="0">
														{t('Display Announcement')}
													</FormLabel>
													<Switch
														isChecked={display}
														id="email-alerts"
														colorScheme="green"
														size="lg"
														onChange={() => setDisplay(!display)}
													/>
												</FormControl>
											</Box>
										)}
									</Flex>
									<Button w="100px" float="right" isLoading={loading} mt="16px" onClick={() => saveArticle()}>
										{t('Save')}
									</Button>
								</Box>
							</>
						)}
					</Box>
				</Center>
			</Container>

			{openChangeCategory && <ChangeArticleCategoryModal isOpen={true} onClose={() => setOpenChangeCategory(false)} articleId={id} />}
		</>
	);
}

export default EditArticle;
