import c from "../constant"
import _ from "lodash"
const getOfficeLabel=(value, lang="en")=>{
    let label = ""
    if(value){
        c.departments?.map(dp=>{
            dp?.offices?.map(office=>{
                if(office.value===value){
                    label = lang==="en"?office.label:office.labelKh
                }
            })
        })
    }
    return label
}

export default getOfficeLabel