import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import c from "../../constant";
import TokenGroup from "@cloudscape-design/components/token-group";
import Modal from "@cloudscape-design/components/modal";
import Box from "@cloudscape-design/components/box";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import {
    Flex,
    Text,
    Avatar,
    WrapItem
} from '@chakra-ui/react';
import getOfficeLabel from "../../utils/getOfficeLabel"
import SelectOfficeModal from "./SelectOfficeModal"
import "./style.css"
import useStore from "../../store"
import { faMinusSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { gql, useMutation } from '@apollo/client';

const REMOVE_OFFICE = gql`
    mutation assign($id: String!, $office: String!, $removeUsers: JSON!
        ) {
        removeCompanySubmissionProposalOfficeAndUser(id: $id, office: $office, removeUsers: $removeUsers)
    }
`;


const leftLabelWidth = '165px';
export default function AssignToOffice({ data, id, callBack, assignedUserData }) {
    const { t } = useTranslation();
    const { currentUser } = useStore((state) => state.currentUser);
    const { currentLang } = useStore((state) => state.currentLang);
    const canAssign = (parseFloat(currentUser?.position) <= 4 && currentUser?.canAssignUserToCompanyProposal)
    const [visible, setVisible] = React.useState(false);
    const [visibleSelectDepartmentModal, setVisibleSelectDepartmentModal] = React.useState(false);
    const [removing, setRemoving] = React.useState(null);
    const [assignedOffice, setAssignedOffice] = useState([]);
    const [removingUsers, setRemovingUsers] = useState([]);
    const [removeOfficeMutation, { error: errorRemove, data: dataRemove, loading: loadingRemove }] = useMutation(REMOVE_OFFICE);

    useEffect(() => {
        if (data) {
            const tmp = []
            data?.map((item) => {
                tmp.push({
                    label: t(getOfficeLabel(item?.office)),
                    value: item?.office
                })
            })
            setAssignedOffice(tmp)
        }
    }, [data])

    let loadingUnassign = false
    function removeOffice() {
        const tmpRemovingUser = []
        removingUsers?.map((item) => tmpRemovingUser.push(item?.user?.id))
        removeOfficeMutation({
            variables: {
                id,
                office: removing?.value,
                removeUsers: tmpRemovingUser
            }
        })
        setVisible(false)
    }

    function getAssignedUserByOffice(office) {
        const tmp = []
        assignedUserData?.map(item => {
            if ((item?.user?.office === office) && item?.createdBy === currentUser?.id) {
                tmp.push(item)
            }
        })
        setRemovingUsers(tmp)
    }

    function getDisplayName(user) {
        let displayName = user?.username;
        if (currentLang === 'kh') {
            displayName = user?.fullNameKhmer ? user?.fullNameKhmer : user?.fullName ? user?.fullName : user?.username;
        } else {
            displayName = user?.fullName ? user?.fullName : user?.fullNameKhmer ? user?.fullNameKhmer : user?.username;
        }
        return displayName;
    }

    function removeTheRemovingUser(user) {
        const tmp = []
        removingUsers?.map((item) => {
            if (user?.id !== item?.user?.id) {
                tmp.push(item)
            }
        })
        setRemovingUsers(tmp)
    }

    useEffect(() => {
        if (dataRemove) {
            callBack()
        }
    }, [dataRemove])

    return (
        <>
            <Flex alignItems="center" mt="2">
                <Text
                    fontWeight="bold"
                    minW={leftLabelWidth}
                    w={leftLabelWidth}
                    marginRight="4"
                >
                    {t('Office')}
                </Text>
                {assignedOffice?.length > 0 ? <Flex marginRight="2">
                    <TokenGroup
                        className={canAssign ? "" : "can-not-assign-department"}
                        onDismiss={({ detail: { itemIndex } }) => {
                            setRemoving(assignedOffice[itemIndex])
                            getAssignedUserByOffice(assignedOffice[itemIndex]?.value)
                            setVisible(true)
                        }}
                        items={assignedOffice}
                    />
                </Flex> : <Text className="show-only-printing ">N/A</Text>}
                <Flex marginTop="8px">
                    {canAssign ? <Button className="hidden-print" onClick={() => setVisibleSelectDepartmentModal(true)} iconName="add-plus" variant="primary" /> : <>
                        {assignedOffice?.length <= 0 && <Text className="hidden-print">N/A</Text>}
                    </>}
                </Flex>
            </Flex>

            <Modal
                onDismiss={() => {
                    if (!loadingUnassign) {
                        setVisible(false)
                    }
                }}
                visible={visible}
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="link" loading={loadingUnassign} onClick={() => setVisible(false)}>{t("Cancel")}</Button>
                            <Button loading={loadingUnassign} variant="primary" onClick={() => removeOffice()}>{t("Okay")}</Button>
                        </SpaceBetween>
                    </Box>
                }
                header={`${t("Unassign department")} ${removing?.label}`}
            >
                <Flex>
                    {t("User below will be unassign from this proposal")}
                </Flex>
                <Flex mt="2">
                    {removingUsers?.length <= 0 && <>N/A</>}
                    {
                        removingUsers?.map((item, index) => {
                            return (
                                <Flex key={`r-u-${index}`} direction="column" justifyContent="center" alignItems="center" mr="1">
                                    <WrapItem className="avatarWraper">
                                        {(currentUser?.role === 'admin' ||
                                            (currentUser?.canAssignUserToCompanyProposal === true &&
                                                currentUser.id === item.createdBy)) && (
                                                <FontAwesomeIcon
                                                    onClick={() => removeTheRemovingUser(item?.user)}
                                                    className="remove"
                                                    icon={faMinusSquare}
                                                    style={{
                                                        fontSize: 18,
                                                        display: 'none',
                                                        position: 'absolute',
                                                        zIndex: '100',
                                                        marginLeft: 32,
                                                        color: 'red',
                                                        backgroundColor: 'white',
                                                        padding: 2,
                                                        cursor: 'pointer',
                                                    }}
                                                />
                                            )}
                                        <Avatar
                                            name={getDisplayName(item?.user)}
                                            src={item?.user?.avatar?.icon ? c.baseFilePath + item?.user?.avatar?.icon : null}
                                        />
                                    </WrapItem>
                                    <Text textAlign="center">{getDisplayName(item.user)}</Text>
                                </Flex>
                            )
                        })
                    }
                </Flex>
            </Modal>

            {visibleSelectDepartmentModal && <SelectOfficeModal
                selectedItems={assignedOffice}
                onClose={() => setVisibleSelectDepartmentModal(false)}
                callBack={e => callBack()}
                id={id}
            />}
        </>
    );
}
