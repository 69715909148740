const PartnerRequestStatus = {
	cancelled: 1,
	waitingPayment: 2,
	reviewAgent: 3,
	reviewAgentFinal: 4,
	waitingContract: 5,
	reviewContract: 6,
	reviewContractFinal: 7,
	completed: 8,
	expired: 9,
};

const GradeApproval = {
	primary: 'Primary',
	secondary: 'Secondary',
};

export { PartnerRequestStatus, GradeApproval };
