import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Box, Flex, Button, useToast, Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Text
} from "@chakra-ui/react";
import c from "../../constant"
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { gql, useMutation } from '@apollo/client';
import { useHistory } from "react-router-dom";
import TextFormField from "../../components/form/TextFormField"
import TextAreaFormField from "../../components/form/TextAreaFormField"
import FileFormField from "../../components/form/FileFormField"
import RichTextEditor from "../../components/RichTextEditor/RichTextEditor";
import TableForm from "../../components/Table/TableFormWithoutSaveButton";
import RadioFormField from "../../components/form/RadioFormField"
import CheckListFormField from "../../components/form/CheckListFormField"

const CREATE_MUTATION = gql`
    mutation create(
        $title: String!,
        $titleKh: String!,
        $departments: String!,
        $period: String!,
        $active:Boolean,
    ){
        createCompanyReportType(
            companyReportTypesInput:{
                title:$title,
                titleKh:$titleKh,
                departments:$departments,
                period:$period,
                active:$active
            }
        )
    }
` 
 const MAX_FILE_SIZE = 104857600

const signUpSchema = Yup.object().shape({
    title: Yup.string().required("Required"),
    titleKh: Yup.string().required("Required"),
    departments: Yup.array(),
    period: Yup.string().required("Required"),
    
});

function Create({
    isOpen = false, onClose = () => null, callback = () => null, companyId
}) {
    const history = useHistory();
    const toast = useToast()
    const { t } = useTranslation();

    const [body, setBody] = useState(null)
    const [steps, setSteps] = useState(null)
    
    const [createPublicService, { loading, error, data }] = useMutation(
        CREATE_MUTATION
    );

    
    

    useEffect(() => {
        if (data) {
            if (data?.createCompanyReportType) {
                toast({
                    title: t("Company report type created successfully"),
                    status: "success",
                    isClosable: true,
                    position: "top-right"
                })
                onClose()
                callback("created successfully")
            }
        }
    }, [data])

    useEffect(() => {
        if (error) {
            error.graphQLErrors.map(({ message }, i) => (
                toast({
                    title: t(message),
                    status: "error",
                    isClosable: true,
                    position: "top-right"
                })
            ))
        }
    }, [error])

    function arrayRoleToString(role){
        let result = ""
        if(role){
            role.map((item,index)=>{
                if(index===0){
                    result=item
                } else {
                    result += `,${item}`
                }
            })
        }
        return result
    }

    function create(values) {   
        let variables = {
            title: values.title,
            titleKh: values.titleKh,
            departments: arrayRoleToString(values.departments),
            period:values.period,
            active:true,
        }

        createPublicService({
            variables
        })
    }

    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={() => {
                    onClose()

                }}
                size="6xl"
            >
                <ModalOverlay />
                <ModalContent borderRadius={c.borderRadius} pb="2">
                    <ModalHeader>{t("Add Company Report Type")}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Formik
                            initialValues={{
                                title: "",
                                titleKh: "",
                                departments: "",
                                period:"",
                            }}
                            validationSchema={signUpSchema}
                            onSubmit={(values) => {
                                create(values);
                            }}
                        >
                            {(props) => (
                                <Form>
                                    <Box mt="3">
                                        <Flex>
                                            <Flex direction="column" flex="1" mr="2">
                                            <TextAreaFormField
                                                {...props}
                                                label="Title"
                                                name="title"
                                                required
                                            />
                                            <TextAreaFormField
                                                {...props}
                                                label="Title Khmer"
                                                name="titleKh"
                                                required
                                            />
                                            </Flex>
                                            <Flex direction="column" flex="1" ml="2">
                                            <RadioFormField 
                                                name="period"
                                                label="Period"
                                                radios={[
                                                    {value:"MONTHLY",label:"Monthly"},
                                                    {value:"QUARTERLY",label:"Quarterly"},
                                                    {value:"SEMESTERLY",label:"Semesterly"},
                                                    {value:"ANNUALLY",label:"Annually"},
                                                ]}
                                                required={true}
                                            />

                                            <CheckListFormField
                                                label="Department"
                                                name="departments"
                                                list={c.departments}
                                                // callBack={e=>{
                                                //     setDepartmentCallback(e)
                                                // }}
                                            />
                                            </Flex>
                                        </Flex>
                                            

                                            
                                        

                                        <Flex
                                            w="100%"
                                            mt="6"
                                            justifyContent="flex-end"
                                        >
                                            <Button
                                                id="login-btn"
                                                variant="solid"
                                                size="md"
                                                h="42px"
                                                w="128px"
                                                bg="primary.dark"
                                                _hover={{ bg: "primary.dark" }}
                                                color="white"
                                                // borderRadius="31px"
                                                type="submit"
                                                isLoading={loading}
                                                loadingText={t("Add")}
                                                spinnerPlacement="start"
                                            >
                                                {t(`Add`)}
                                            </Button>
                                        </Flex>
                                    </Box>
                                </Form>
                            )}
                        </Formik>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
}

export default Create;
