/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Box, Flex, Text, Center, Table, Tbody, Tfoot, Tr, Th, Td, TableContainer, Thead, Button, Badge } from '@chakra-ui/react';
import { faSlidersH, faPrint, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatAgentCode } from '../../../utils/index';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useLocation, useHistory } from 'react-router-dom';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import ReactPaginate from 'react-paginate';
import LoadingTable from '../../../components/Table/Loading';
import PrintLayout from '../../../components/Print/PrintLayout';
import BreadcrumbComponent from '../../../components/BreadcrumbComponent/BreadcrumbComponent';
import useStore from '../../../store';
import moment from 'moment';
import c from '../../../constant';
import CompanyName from '../../../components/CompanyName';
import IntermediaryTab from '../../ManageAgentPartner/Intermediary/components/IntermediaryTab';
import IntermediaryStatus from '../../../components/ManageAgentPartner/Miscellaneous/IntermediaryStatus';
import LocalizedDate from '../../../components/LocalizedDate';
import TotalRow from '../../../components/TotalRow';
import IntermediaryFilter from '../../ManageAgentPartner/Intermediary/components/FilterIntermediary';
import datePickerFromToToDate from '../../../utils/datePickerFromToToDate';

const QUERY_DATA = gql`
	query get(
		$offset: Float
		$status: String
		$number: String
		$receivedDateFrom: DateTime
		$receivedDateTo: DateTime
		$companyId: String
		$companyName: String
	) {
		getCompanySaleProfessionalNewReuqestList(
			status: $status
			number: $number
			receivedDateFrom: $receivedDateFrom
			receivedDateTo: $receivedDateTo
			companyId: $companyId
			companyName: $companyName
			pagerInput: { limit: 10, offset: $offset }
		) {
			totalRows
			totalPages
			list {
				id
				number
				createdAt
				createdBy
				status
				adminStatus
				company {
					id
					name
					nameKhmer
					logo
					type
				}
			}
		}
	}
`;

const QUERY_COUNT = gql`
	query {
		getCountCompanySaleProfessionalNewRequest
	}
`;

function List() {
	const { t } = useTranslation();
	const location = useLocation();
	const history = useHistory();
	const { push } = useHistory();

	const urlParams = new URLSearchParams(location.search);
	const page = urlParams.get('page') ? urlParams.get('page') : 1;
	const status = urlParams.get('status') ? urlParams.get('status') : null;

	const number = urlParams.get('number') || null;
	const receivedDateFrom = urlParams.get('receivedDateFrom') || null;
	const receivedDateTo = urlParams.get('receivedDateTo') || null;
	const companyName = urlParams.get('companyName') || null;
	const companyId = urlParams.get('companyId') || null;
	const [isOpenFilter, setIsOpenFilter] = useState(false);

	const { currentLang } = useStore((state) => state.currentLang);
	const { currentUser: user } = useStore((state) => state.currentUser);

	const [loadData, { loading, data }] = useLazyQuery(QUERY_DATA);
	const [loadCount, { data: countData }] = useLazyQuery(QUERY_COUNT);

	const [objectCountData, setObjectCountData] = useState({});

	useEffect(() => {
		loadCount();
	}, []);

	useEffect(() => {
		if (countData) {
			setObjectCountData(JSON.parse(countData?.getCountCompanySaleProfessionalNewRequest));
		}
	}, [countData]);

	useEffect(() => {
		window.scrollTo(0, 0);
		const offset = parseInt(page ? (page - 1) * 10 : 0) || 0;
		loadData({
			variables: {
				offset,
				status,
				number,
				receivedDateFrom: datePickerFromToToDate(receivedDateFrom),
				receivedDateTo: datePickerFromToToDate(receivedDateTo),
				companyId,
				companyName,
			},
		});
	}, [status, page, number, receivedDateFrom, receivedDateTo, companyId, companyName]);

	function handleStatusChanged(statusData) {
		let url = `?page=1`;

		if (companyId) {
			url += `&companyId=${companyId}`;
		}

		if (companyName) {
			url += `&companyName=${companyName}`;
		}

		if (number) {
			url += `&number=${number}`;
		}

		if (receivedDateFrom) {
			url += `&receivedDateFrom=${receivedDateFrom}`;
		}

		if (receivedDateTo) {
			url += `&receivedDateTo=${receivedDateTo}`;
		}

		if (statusData) {
			url += `&status=${statusData}`;
		}

		push(`/company-sale-professional/new/list${url}`);
	}

	function handlePageChanged({ selected }) {
		let url = `?page=${selected + 1}`;

		if (companyId) {
			url += `&companyId=${companyId}`;
		}

		if (companyName) {
			url += `&companyName=${companyName}`;
		}

		if (number) {
			url += `&number=${number}`;
		}

		if (receivedDateFrom) {
			url += `&receivedDateFrom=${receivedDateFrom}`;
		}

		if (receivedDateTo) {
			url += `&receivedDateTo=${receivedDateTo}`;
		}

		if (status) {
			url += `&status=${status}`;
		}

		push(`/company-sale-professional/new/list${url}`);
	}

	return (
		<PrintLayout>
			<>
				<Container maxW="container.xl" mt="16px" mb="16px">
					<BreadcrumbComponent
						list={[
							{
								name: 'Insurance Sale Staff',
								path: '/company-sale-professional/menu',
							},
							{
								name: 'New Request',
								path: '#',
							},
						]}
					/>
					<Center className="show-only-printing">
						<Text textAlign="center" color="#62B3ED" fontFamily="Moul" fontSize={18}>
							{t('New Request')}
						</Text>
					</Center>
					<Center mt="5">
						<Box
							w="100%"
							bg="white"
							borderRadius={c.borderRadius}
							border="1px solid #dbdbdb"
							boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
							pb="32px"
						>
							<Flex
								className="hidden-print"
								bg="#FAFAFA"
								p="16px"
								pt="0"
								pb="0"
								mb="16px"
								alignItems="center"
								justifyContent="space-between"
								borderBottom="1px solid #dbdbdb"
							>
								<Flex className="panel-tab-container" minW="303px">
									<IntermediaryTab
										onCallback={(e) => handleStatusChanged(e)}
										initTab={status}
										data={[
											{
												label: 'Reviewing By Office',
												tooltip: 'Reviewing By Office',
												badge: {
													value: objectCountData['reviewingByOffice'] || '0',
												},
												value: 'reviewing_by_office',
												important: 'office',
											},
											{
												label: 'Reviewing By Department',
												tooltip: 'Reviewing By Department',
												badge: {
													value: objectCountData['reviewingByDepartment'] || '0',
												},
												value: 'reviewing_by_department',
												important: 'department',
											},
											{
												label: 'Completed',
												badge: null,
												value: 'completed',
												important: '',
											},
											{
												label: 'All',
												badge: null,
												value: 'all',
												important: '',
											},
										]}
									/>
								</Flex>
								<Flex alignItems="center">
									<Button
										mr="2"
										onClick={() => window?.print()}
										leftIcon={<FontAwesomeIcon icon={faPrint} style={{ fontSize: 16 }} />}
										colorScheme="gray"
										variant="solid"
										size="sm"
										borderRadius={c.borderRadius}
										border="1px solid #bdc3c7"
									>
										{t('Print')}
									</Button>
									<Button
										onClick={() => setIsOpenFilter(true)}
										leftIcon={<FontAwesomeIcon icon={faSlidersH} style={{ fontSize: 16 }} />}
										colorScheme={number || (receivedDateFrom && receivedDateTo) || companyName || companyId ? 'yellow' : 'gray'}
										variant="solid"
										size="sm"
										borderRadius={c.borderRadius}
										border="1px solid #bdc3c7"
										ml={2}
									>
										{t('Filter')}
									</Button>
								</Flex>
							</Flex>

							<Box p="16px">
								<TableContainer borderWidth={1} borderStyle="dotted" pl={2} pr={2} pt={1} pb={1}>
									<Table variant="simple" size="sm">
										<Thead>
											<Tr>
												<Th>{t('Number')}</Th>
												<Th>{t('Created At')}</Th>
												<Th>{t('Company')}</Th>
												<Th isNumeric>{t('Status')}</Th>
											</Tr>
										</Thead>
										<Tbody>
											{loading && (
												<>
													<Tr>
														<Td colSpan={4}>
															<Flex justifyContent="flex-start" alignItems="center">
																{t('Loading')}
															</Flex>
														</Td>
													</Tr>
												</>
											)}
											{!loading && data?.getCompanySaleProfessionalNewReuqestList?.list?.length <= 0 && (
												<>
													<Tr>
														<Td colSpan={4}>
															<Flex justifyContent="flex-start" alignItems="center">
																{t('No Data')}
															</Flex>
														</Td>
													</Tr>
												</>
											)}
											{!loading &&
												data?.getCompanySaleProfessionalNewReuqestList?.list?.map((item, index) => {
													return (
														<Tr
															key={index}
															cursor="pointer"
															_hover={{ bg: 'gray.100' }}
															onMouseDown={() => history.push(`/company-sale-professional/new/detail?id=${item?.id}`)}
														>
															<Td>{item?.number}</Td>
															<Td>
																<LocalizedDate dateString={item?.createdAt} />
															</Td>
															<Td>
																<Text noOfLines={1}>
																	<CompanyName company={item?.company} />
																</Text>
															</Td>
															<Td isNumeric>
																<IntermediaryStatus status={item?.adminStatus} />
															</Td>
														</Tr>
													);
												})}
										</Tbody>
										<Tfoot>
											<Tr>
												<Th>{t('Number')}</Th>
												<Th>{t('Created At')}</Th>
												<Th>{t('Company')}</Th>
												<Th isNumeric>{t('Status')}</Th>
											</Tr>
										</Tfoot>
									</Table>
								</TableContainer>
								{data?.getCompanySaleProfessionalNewReuqestList?.totalRows > 0 && (
									<>
										<Flex justifyContent="flex-end" direction="row">
											<TotalRow count={data?.getCompanySaleProfessionalNewReuqestList?.totalRows} mt={2} />
										</Flex>
									</>
								)}
								{data?.getCompanySaleProfessionalNewReuqestList?.totalPages > 1 && (
									<>
										<Flex alignItems="flex-end" direction="column" mt={4}>
											<Flex alignItems="flex-end" direction="column">
												<ReactPaginate
													previousLabel={<FontAwesomeIcon icon={faChevronLeft} style={{ fontSize: 12, cursor: 'pointer' }} />}
													nextLabel={<FontAwesomeIcon icon={faChevronRight} style={{ fontSize: 12, cursor: 'pointer' }} />}
													breakLabel={'...'}
													breakClassName={'break-me'}
													disableInitialCallback={true}
													pageCount={data?.getCompanySaleProfessionalNewReuqestList?.totalPages}
													marginPagesDisplayed={2}
													pageRangeDisplayed={3}
													onPageChange={handlePageChanged}
													containerClassName={'pagination'}
													activeClassName={'active'}
													initialPage={(page - 1) | 0}
												/>
											</Flex>
										</Flex>
									</>
								)}
							</Box>
						</Box>
					</Center>
				</Container>

				<>
					{isOpenFilter && (
						<IntermediaryFilter
							isOpen={true}
							onClose={() => setIsOpenFilter(false)}
							onCallback={(result) => {
								let url = '';

								if (status) {
									url += `&status=${status}`;
								}

								if (result?.number) {
									url += `&number=${result?.number}`;
								}

								if (result?.receivedDateFrom && result?.receivedDateTo) {
									url += `&receivedDateFrom=${result?.receivedDateFrom}&receivedDateTo=${result?.receivedDateTo}`;
								}

								if (result?.companyName) {
									url += `&companyName=${result?.companyName}`;
								}

								if (result?.companyId) {
									url += `&companyId=${result?.companyId}`;
								}

								push(`/company-sale-professional/new/list?page=1${url}`);
							}}
							number={number}
							receivedDateFrom={receivedDateFrom}
							receivedDateTo={receivedDateTo}
							companyName={companyName}
							companyId={companyId}
						/>
					)}
				</>
			</>
		</PrintLayout>
	);
}

export default List;

const tabs = [
	{ label: 'Reviewing', value: 'Reviewing', secondValue: 1 },
	{ label: 'Done', value: 'Completed', secondValue: 2 },
	{ label: 'All', value: 'All', secondValue: 0 },
];
