import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Box, Flex, Text, Center, GridItem, Grid, ListItem, List } from '@chakra-ui/react';
import BreadcrumbComponent from '../../../components/BreadcrumbComponent/BreadcrumbComponent';
import c from '../../../constant';
import { gql, useLazyQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';

const QUERY_EMPLOYEE_BY_ID = gql`
	query getCamdigikeyUserById($id: String!) {
		getCamdigikeyUserById(id: $id) {
			id
			firstNameEn
			lastNameEn
			firstNameKh
			lastNameKh
			gender
			jti
			mobilePhone
			nationality
			personalCode
			status
			email
		}
	}
`;

function CamdigikeyDetails() {
	const location = useLocation();
	const urlParams = new URLSearchParams(location.search);
	const id = urlParams.get('id') ? urlParams.get('id') : null;
	const { t } = useTranslation();
	const [CamdigikeyUser, { loading, data }] = useLazyQuery(QUERY_EMPLOYEE_BY_ID);

	useEffect(() => {
		window.scrollTo(0, 0);
		CamdigikeyUserWithVar();
	}, []);
	function CamdigikeyUserWithVar() {
		CamdigikeyUser({
			variables: {
				id: id,
			},
		});
	}

	return (
		<>
			<Container className="hidden-print" maxW="container.xl" mt="16px" mb="16px">
				<BreadcrumbComponent
					list={[
						{
							name: 'Settings',
							path: '/settings',
						},
						{
							name: 'Camdigikey',
							path: '/settings/camdigikey',
						},
						{
							name: 'details',
							path: '#',
						},
					]}
				/>
				<Center mt="5">
					<Box
						w="100%"
						bg="white"
						borderRadius={c.borderRadius}
						border="1px solid #dbdbdb"
						boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
						pb="32px"
					>
						<Flex bg="#FAFAFA" p="16px" pt="8px" pb="8px" mb="16px" justifyContent="space-between" borderBottom="1px solid #dbdbdb">
							<Text fontSize="x-large" fontWeight="bold">
								{t('CamDigiKey User')}
							</Text>
						</Flex>

						{loading && <Text textAlign="center">{t('Loading')}</Text>}

						<Container w="100%" maxW={'8xl'} fontSize={'md'}>
							<Box>
								<Text fontSize={'2xl'} fontWeight={'bold'}>
									រូបវន្តបុគ្គល
								</Text>
							</Box>
							<List>
								<ListItem p={5}>
									<Grid templateColumns="repeat(3, 1fr)" gap={6}>
										<GridItem colSpan={1}>គោត្តនាម - នាម :</GridItem>
										<GridItem colSpan={2}>
											{data?.getCamdigikeyUserById.lastNameKh + ' ' + data?.getCamdigikeyUserById.firstNameKh}
										</GridItem>
									</Grid>
								</ListItem>
								<ListItem p={5}>
									<Grid templateColumns="repeat(3, 1fr)" gap={6}>
										<GridItem colSpan={1}>គោត្តនាម - នាម ជាភាសាឡាតាំង :</GridItem>
										<GridItem colSpan={2}>
											{data?.getCamdigikeyUserById.lastNameEn + ' ' + data?.getCamdigikeyUserById.firstNameEn}
										</GridItem>
									</Grid>
								</ListItem>
								<ListItem p={5}>
									<Grid templateColumns="repeat(3, 1fr)" gap={6}>
										<GridItem colSpan={1}>ភេទ :</GridItem>
										<GridItem colSpan={2}>{data?.getCamdigikeyUserById.gender}</GridItem>
									</Grid>
								</ListItem>
								<ListItem p={5}>
									<Grid templateColumns="repeat(3, 1fr)" gap={6}>
										<GridItem colSpan={1}>សញ្ជាតិ :</GridItem>
										<GridItem colSpan={2}>{data?.getCamdigikeyUserById.nationality}</GridItem>
									</Grid>
								</ListItem>
								<ListItem p={5}>
									<Grid templateColumns="repeat(3, 1fr)" gap={6}>
										<GridItem colSpan={1}>លេខទំនាក់ទំនង :</GridItem>
										<GridItem colSpan={2}>{data?.getCamdigikeyUserById.mobilePhone}</GridItem>
									</Grid>
								</ListItem>
								<ListItem p={5}>
									<Grid templateColumns="repeat(3, 1fr)" gap={6}>
										<GridItem colSpan={1}>អុីម៉ែល :</GridItem>
										<GridItem colSpan={2}>{data?.getCamdigikeyUserById.email}</GridItem>
									</Grid>
								</ListItem>
							</List>
						</Container>
					</Box>
				</Center>
			</Container>
		</>
	);
}

export default CamdigikeyDetails;
