import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Box, Flex, Button, useToast,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter

} from "@chakra-ui/react";
import c from "../../constant"

function DeleteModal({
    onClose = () => null, callback = () => null, loading=false, title=""
}) {
    const toast = useToast()
    const { t } = useTranslation();


    return (
        <>
            <AlertDialog
                isOpen={true}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                    {t(title)}
                    </AlertDialogHeader>

                    <AlertDialogBody>
                    {t("Are you sure? You can't undo this action afterwards")}
                    </AlertDialogBody>

                    <AlertDialogFooter>
                    <Button onClick={onClose}>
                        {t("Cancel")}
                    </Button>
                    <Button isLoading={loading} colorScheme='red' onClick={()=>{
                        callback()
                    }}
                    ml={3}>
                        {t("Delete")}
                    </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    );
}

export default DeleteModal;
