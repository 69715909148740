import React, { useEffect, useState } from "react";
import {
    Textarea, Text, Box, Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    IconButton,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Flex,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { AddIcon } from '@chakra-ui/icons'
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import TextFormField from "../form/TextFormField"
import RadioFormField from "../form/RadioFormField"
import PreUploadFileFormField from "../form/PreUploadFileFormField"
import TextAreaFormField from "../form/TextAreaFormField"
import DateFormField from "../form/DateFormField"
import DateInputFormField from "../form/DateInputFormField"

import useStore from "../../store";
import c from "../../constant";
import { faSave, faPenSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { v4 as uuid } from 'uuid';
import _ from 'lodash';
import moment from "moment";
import DownloadAndViewButtonUserUpload from "../Files/DownloadAndViewButtonUserUpload"
const TableFormField = props => {
    const { t } = useTranslation('common');
    const { currentLang } = useStore((state) => state.currentLang);
    moment.locale(currentLang === "kh" ? "km" : "en-gb")
    const {
        name,
        label,
        labelKh,
        columns,
        value,
        orderBy,
        onSave,
        hideDelete
    } = props;
    const originalValue = value?[...value]:[]
    const [editRow, setEditRow] = useState(null)
    const [openEditRow, setOpenEditRow] = useState(false)
    const [isEditing, setIsEditing] = useState(true)
    const [tableData, setTableData] = useState(value?value:[])

    useEffect(()=>{
        if(tableData&&onSave){
            onSave(tableData)
        }   
    },[tableData])

    function onAdd(e){
        let tmpData = [...tableData]
        tmpData = [...tmpData, e]
        setTableData(tmpData)
    }

    function onUpdate(e){
        let tmpData = [...tableData]
        let updateTmpData = []
        tmpData.map(item=>{
            if(item?.id===e?.id){
                updateTmpData.push(e)
            } else {
                updateTmpData.push(item)
            }
        })
        setTableData(updateTmpData)
    }

    function onDelete(e){
        let tmpData = [...tableData]
        let updateTmpData = []
        tmpData.map(item=>{
            if(item?.id===e?.id){
            } else {
                updateTmpData.push(item)
            }
        })
        setTableData(updateTmpData)
        setOpenEditRow(false)
    }

    function displayRowData({value,type}){
        switch (type) {
            case "text":
                return value
            case "capitalText":
                return value?.toUpperCase()
            case "textArea":
                return <pre style={{maxWidth:400,whiteSpace: "pre-wrap"
                }}>{value}</pre>
            case "date":
                return value?moment(value).format(c.excelDateOnlyTextFormat):""
            case "file":{
                const tmpValue = value?JSON.parse(value):null
                return tmpValue?<DownloadAndViewButtonUserUpload url={tmpValue?.file} size={tmpValue?.fileSize}/>:""
            }
            case "radio":{
                return t(value)
            }
            default:
                return "";
        }
    }


    return (
        <Flex direction="column">
            <TableContainer  pt="0">
                <Table variant='simple'>
                    <Thead>
                        <Tr>
                            {columns.map((item, index) => {
                                if(!item?.hide){
                                return (
                                    <Th key={`th-${index}`}>{currentLang === "kh" ? item?.labelKh : item?.label}</Th>
                                )}
                            })}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {
                            (!tableData || tableData?.length <= 0) && <Tr
                            >
                                {columns.map((item, index) => {
                                    if(!item?.hide){
                                    return (
                                        <Td key={`td-null-${index}`}>N/A</Td>
                                    )}
                                })}
                            </Tr>
                        }
                        {
                            _.orderBy(tableData,[`${orderBy?.field}`],[`${orderBy?.type}`])?.map((item, index) => {
                                return (
                                    <Tr key={`td-${index}`} onClick={() => {
                                        if (isEditing) {
                                            setOpenEditRow(true)
                                            setEditRow(item)
                                        }
                                    }}
                                        cursor={isEditing ? "pointer" : "unset"}
                                        _hover={{
                                            bg: "rgba(236, 240, 241,.3)"
                                        }}
                                    >
                                        {columns.map((col, colIndex) => {
                                            if(!col?.hide){
                                                return (
                                                    <Td key={`td-${index}-${colIndex}`}>{
                                                        displayRowData({value:item[col?.name],type:col?.type})
                                                        // (item[col?.name]).toString()
                                                    }</Td>
                                                )
                                            }
                                        })}
                                    </Tr>
                                )
                            })
                        }
                    </Tbody>
                </Table>
            </TableContainer>
            {isEditing && <RowForm {...props} onAdd={onAdd} type="add" />}

            {isEditing && openEditRow && <RowForm {...props} hideDelete={hideDelete} onUpdate={onUpdate} initData={editRow} type="edit" onCloseCallBack={() => {
                setOpenEditRow(false)
                setEditRow(null)
            }} onDelete={onDelete} />}
        </Flex>
    );
};

export default TableFormField;


const RowForm = props => {
    const { t } = useTranslation('common');
    const {
        name,
        label,
        columns,
        value,
        onAdd,
        type,
        onCloseCallBack = () => null,
        initData, onUpdate,
        onDelete,
        hideDelete
    } = props;

    const [schem, setSchem] = useState(null)
    const [initValue, setInitValue] = useState(null)
    const [isOpen, setIsOpen] = useState(type === "add" ? false : true)
    const { currentLang } = useStore((state) => state.currentLang);

    const [uuidRow, setUuidRow] = useState(uuid())

    useEffect(() => {
        if (columns) {

            let tmpSchem = {}
            let tmpInitValue = {}
            columns?.map((item, index) => {
                if (item?.type === "text" || item?.type === "capitalText") {
                    tmpSchem = { ...tmpSchem, [item?.name]: Yup.string() }
                    tmpInitValue = { ...tmpInitValue, [item?.name]: initData ? initData[item?.name] : (item?.name==="id"?uuidRow:"") }
                } else if (item?.type === "file") {
                    tmpSchem = { ...tmpSchem, [item?.name]: Yup.string() }
                    tmpInitValue = { ...tmpInitValue, [item?.name]: initData ? initData[item?.name] : (item?.name==="id"?uuidRow:"") }
                } else if (item?.type === "textArea") {
                    tmpSchem = { ...tmpSchem, [item?.name]: Yup.string() }
                    tmpInitValue = { ...tmpInitValue, [item?.name]: initData ? initData[item?.name] : "" }
                } else if (item?.type === "date") {
                    tmpSchem = { ...tmpSchem, [item?.name]: Yup.string().nullable() }
                    tmpInitValue = { ...tmpInitValue, [item?.name]: initData ? initData[item?.name] : "" }
                } else if (item?.type === "radio") {
                    tmpSchem = { ...tmpSchem, [item?.name]: Yup.string() }
                    tmpInitValue = { ...tmpInitValue, [item?.name]: initData ? initData[item?.name] : "" }
                } else {
                    tmpSchem = { ...tmpSchem, [item?.name]: Yup.string() }
                    tmpInitValue = { ...tmpInitValue, [item?.name]: initData ? initData[item?.name] : "" }
                }
            })
            setSchem(Yup.object().shape({
                ...tmpSchem
            }))
            setInitValue(tmpInitValue)
        }
    }, [columns, uuidRow])

    return (
        <>
            {type === "add" && <Button mb="26px" colorScheme='green' borderRadius="0px !important" onClick={() => setIsOpen(true)}>{t("Add")}</Button>}
            <Modal isOpen={isOpen} onClose={() => {
                setIsOpen(false)
                onCloseCallBack()
            }}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{t("Add")}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody mb="4">
                        {(schem && initValue) && <Formik
                            key="inputFormTable"
                            initialValues={{ ...initValue }}
                            validationSchema={
                                schem
                            }
                            onSubmit={(values) => {
                                if (type === "add") {
                                    setUuidRow(uuid())
                                    onAdd(values);
                                    setIsOpen(false)
                                } else {
                                    onUpdate(values)
                                    onCloseCallBack()
                                }
                            }}
                        >
                            {(props) => {
                                return (
                                    <Box>
                                        <Form key="formKey">
                                            {columns.map((col, colIndex) => {
                                                return (
                                                    <Box key={`td-add-${colIndex}`} display={col?.hide ? "none" : "unset"}>
                                                        {(col.type === "text"||col.type === "capitalText") && <TextFormField
                                                            {...props}
                                                            label={currentLang === "kh" ? col?.labelKh : col?.label}
                                                            name={col?.name}
                                                        />}
                                                        {col.type === "file" && <PreUploadFileFormField
                                                            {...props}
                                                            label={currentLang === "kh" ? col?.labelKh : col?.label}
                                                            name={col?.name}
                                                        />}
                                                        {col.type === "textArea" && <TextAreaFormField
                                                            {...props}
                                                            label={currentLang === "kh" ? col?.labelKh : col?.label}
                                                            name={col?.name}
                                                        />}
                                                        {col.type === "date" && <DateInputFormField
                                                            {...props}
                                                            label={currentLang === "kh" ? col?.labelKh : col?.label}
                                                            name={col?.name}
                                                            initialDate={initValue[col?.name]||null}
                                                        />}

                                                        {(col.type === "radio") && <RadioFormField
                                                            {...props}
                                                            label={col?.label}
                                                            name={col?.name}
                                                            radios={col?.options}
                                                            initValue={initValue[col?.name]||""}
                                                        />}
                                                    </Box>
                                                )
                                            })}
                                            <Flex justifyContent="flex-end">
                                                {(type === "edit"&&(!hideDelete)) && <Button
                                                    variant="ghost"
                                                    colorScheme='red'
                                                    size="md"
                                                    h="42px"
                                                    w="128px"
                                                    mr="4"
                                                    onClick={() => onDelete(initData)}
                                                >
                                                    {t(`Delete`)}
                                                </Button>}
                                                <Button
                                                    id="login-btn"
                                                    variant="solid"
                                                    size="md"
                                                    h="42px"
                                                    w="128px"
                                                    bg="primary.dark"
                                                    _hover={{ bg: "primary.dark" }}
                                                    color="white"
                                                    // borderRadius="31px"
                                                    type="submit"
                                                    // isLoading={loading}
                                                    loadingText={t("CREATE")}
                                                    spinnerPlacement="start"
                                                >
                                                    {t(type === "add" ? `Add` : `Update`)}
                                                </Button>
                                            </Flex>
                                        </Form>
                                    </Box>
                                )
                            }}
                        </Formik>}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}