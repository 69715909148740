import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Container,
	Box,
	Flex,
	Text,
	Center,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	Badge,
	useToast,
	Tooltip,
	Wrap,
	WrapItem,
	Avatar,
	Button,
	AlertDialog,
	AlertDialogOverlay,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogBody,
	AlertDialogFooter,
} from '@chakra-ui/react';
import BreadcrumbComponent from '../../components/BreadcrumbComponent/BreadcrumbComponent';
import { faPrint, faMinusSquare, faPlusSquare, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import c from '../../constant';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import moment from 'moment';
import './style.css';
import { useLocation } from 'react-router-dom';
import ViewComment from '../../components/Comment/ViewComment';
import useStore from '../../store';
import PrintLayout from '../../components/Print/PrintLayout';
import { CheckIcon, WarningIcon, RepeatIcon, SpinnerIcon } from '@chakra-ui/icons';
import DownloadAndViewButton from '../../components/Files/DownloadAndViewButton';
import SelectUserModal from '../../components/Modal/SelectUserModal';
import DoneFileUplaod from '../../components/complaint/DoneFileUplaod';
import UserDislayNameById from '../../components/UserDislayNameById';

const leftLabelWidth = '120px';

const QUERY_COMPLAINT = gql`
	query get($id: String!) {
		getComplaintById(id: $id) {
			id
			no
			subject
			description
			createdAt
			complaintFile
			complaintFileSize
			complaintType
			settled
			settledDate
			spam
			notification {
				read
			}
			assignTo {
				user {
					id
					username
					fullName
					fullNameKhmer
					avatar
				}
				createdBy
				createdAt
			}
			doneFile
		}
	}
`;

const QUERY_SET_AS_SPAM = gql`
	mutation setComplaintByIdAsSpam($id: String!) {
		setComplaintByIdAsSpam(id: $id)
	}
`;

const QUERY_SET_AS_NOT_SPAM = gql`
	mutation setComplaintByIdAsNotSpam($id: String!) {
		setComplaintByIdAsNotSpam(id: $id)
	}
`;

const MUTATION_ASSIGN_USER = gql`
	mutation assign($id: String!, $userId: String!) {
		assignComplaintToUser(id: $id, userId: $userId)
	}
`;

const MUTATION_REMOVE_USER = gql`
	mutation remove($id: String!, $userId: String!) {
		removeAssignedComplaintFromUser(id: $id, userId: $userId)
	}
`;

const MUTATION_SET_INPROGRESS_COMPLAINT = gql`
	mutation set($id: String!) {
		setComplaintByIdAsInprogress(id: $id)
	}
`;
const MUTATION_SET_SETTLED_COMPLAINT = gql`
	mutation set($id: String!) {
		setSettledComplaintById(id: $id)
	}
`;

function ComplaintDetail() {
	const { t } = useTranslation();
	const location = useLocation();
	const urlParams = new URLSearchParams(location.search);
	const id = urlParams.get('id') ? urlParams.get('id') : null;
	const { currentLang } = useStore((state) => state.currentLang);
	moment.locale(currentLang === 'kh' ? 'km' : 'en-gb');
	const [loadComplaint, { error, loading, data }] = useLazyQuery(QUERY_COMPLAINT);
	const [setInprogressComplaintById, { error: setInprogressError, loading: setInprogressLoading, data: setInprogressData }] =
		useMutation(MUTATION_SET_INPROGRESS_COMPLAINT);
	const [settledComplaintById, { error: setSettledError, loading: setSettledLoading, data: setSettledData }] = useMutation(MUTATION_SET_SETTLED_COMPLAINT);

	const [setAsSpamComplaint, { error: errorSetAsSpam, loading: loadingSetAsSpam, data: dataSetAsSpam }] = useMutation(QUERY_SET_AS_SPAM);
	const [setAsNotSpamComplaint, { error: errorSetAsNotSpam, loading: loadingSetAsNotSpam, data: dataSetAsNotSpam }] = useMutation(QUERY_SET_AS_NOT_SPAM);
	const setCountUnreadNotifications = useStore((state) => state.setCountUnreadNotifications);
	const { countUnreadNotifications } = useStore((state) => state.countUnreadNotifications);
	const { currentUser } = useStore((state) => state.currentUser);
	const [selectUserModalOpen, setSelectUserModalOpen] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [removingUserId, setRemovingUserId] = useState(null);

	const [assignUserMutation, { error: errorAssign, loading: loadingAssign, data: dataAssign }] = useMutation(MUTATION_ASSIGN_USER);
	const [removeUserMutation, { error: errorRemove, loading: loadingRemove, data: dataRemove }] = useMutation(MUTATION_REMOVE_USER);

	const cancelRef = useRef();
	const onClose = () => setIsOpen(false);

	useEffect(() => {
		if (dataRemove?.removeAssignedComplaintFromUser) {
			toast({
				title: t('User removed successfully'),
				status: 'success',
				isClosable: true,
				position: 'top-right',
			});
			loadComplaint({
				variables: {
					id,
				},
			});
			onClose();
		}
	}, [dataRemove]);

	useEffect(() => {
		if (errorAssign) {
			if (errorAssign?.graphQLErrors?.length > 0) {
				errorAssign?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'top-right',
					})
				);
			} else {
				toast({
					title: t(errorAssign?.message ? errorAssign?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'top-right',
				});
			}
		}
	}, [errorAssign]);

	useEffect(() => {
		if (dataAssign?.assignComplaintToUser) {
			toast({
				title: t('User assigned successfully'),
				status: 'success',
				isClosable: true,
				position: 'top-right',
			});
			loadComplaint({
				variables: {
					id,
				},
			});
		}
	}, [dataAssign]);

	useEffect(() => {
		if (setSettledData?.setSettledComplaintById) {
			toast({
				title: t('Complaint set as settled successfully'),
				status: 'success',
				isClosable: true,
				position: 'top-right',
			});
			loadComplaint({
				variables: {
					id,
				},
			});
		}
	}, [setSettledData]);

	const toast = useToast();

	useEffect(() => {
		window.scrollTo(0, 0);
		loadComplaint({
			variables: {
				id,
			},
		});
	}, []);

	useEffect(() => {
		if (id) {
			loadComplaint({
				variables: {
					id,
				},
			});
		}
	}, [id]);

	useEffect(() => {
		if (setInprogressData?.setComplaintByIdAsInprogress) {
			toast({
				title: t('Complaint set to in progress successfully'),
				status: 'success',
				isClosable: true,
				position: 'top-right',
			});
			loadComplaint({
				variables: {
					id,
				},
			});
		}
	}, [setInprogressData]);

	function complaintType(type) {
		return type === 'COMPLAINT' ? (
			<Badge className="hidden-print" colorScheme="purple">
				{t('Complaint')}
			</Badge>
		) : (
			<Badge className="hidden-print" colorScheme="green">
				{t('Cancel Complaint')}
			</Badge>
		);
	}

	function setSettledComplaint() {
		settledComplaintById({
			variables: {
				id,
			},
		});
	}

	function getStatus({ settled, settledDate }) {
		return settled === true ? (
			<Tooltip label={settledDate ? moment(settledDate).format('LLLL') : ''}>
				<Badge colorScheme="green">{t('Settled')}</Badge>
			</Tooltip>
		) : (
			<Badge colorScheme="yellow">{t('In progress')}</Badge>
		);
	}

	useEffect(() => {
		if (errorSetAsSpam) {
			toast({
				title: t('Set as spam error'),
				status: 'error',
				isClosable: true,
				position: 'top-right',
			});
		}
	}, [errorSetAsSpam]);

	useEffect(() => {
		if (errorRemove) {
			toast({
				title: t('Remove assigned user error'),
				status: 'error',
				isClosable: true,
				position: 'top-right',
			});
		}
	}, [errorRemove]);

	useEffect(() => {
		if (errorSetAsNotSpam) {
			toast({
				title: t('Set as not spam error'),
				status: 'error',
				isClosable: true,
				position: 'top-right',
			});
		}
	}, [errorSetAsNotSpam]);

	useEffect(() => {
		if (dataSetAsNotSpam?.setComplaintByIdAsNotSpam) {
			toast({
				title: t('Set as not spam successfully'),
				status: 'success',
				isClosable: true,
				position: 'top-right',
			});
			loadComplaint({
				variables: {
					id,
				},
			});
		}
	}, [dataSetAsNotSpam]);

	useEffect(() => {
		if (dataSetAsSpam?.setComplaintByIdAsSpam) {
			toast({
				title: t('Set as spam successfully'),
				status: 'success',
				isClosable: true,
				position: 'top-right',
			});
			loadComplaint({
				variables: {
					id,
				},
			});
		}
	}, [dataSetAsSpam]);

	useEffect(() => {
		if (data?.getComplaintById?.notification?.read === false) {
			setCountUnreadNotifications(countUnreadNotifications - 1);
		}
	}, [data]);

	function setSpam() {
		setAsSpamComplaint({
			variables: {
				id,
			},
		});
	}

	function setNotSpam() {
		setAsNotSpamComplaint({
			variables: {
				id,
			},
		});
	}

	function getDisplayName(user) {
		let result = user?.username;
		if (currentLang === 'kh') {
			if ((result = user?.fullNameKhmer)) {
				result = user?.fullNameKhmer;
			} else {
				if (user?.fullName) {
					result = user?.fullName;
				} else {
					result = user?.username;
				}
			}
		} else {
			if ((result = user?.fullName)) {
				result = user?.fullName;
			} else {
				if (user?.fullNameKhmer) {
					result = user?.fullNameKhmer;
				} else {
					result = user?.username;
				}
			}
		}

		return result;
	}

	function removeAssignUserDialog(user) {
		setIsOpen(true);
		setRemovingUserId(user);
	}

	function selectAssignUser(userData) {
		assignUserMutation({
			variables: {
				id: id,
				userId: userData.id,
			},
		});
	}

	function setInprogressComplaint() {
		setInprogressComplaintById({
			variables: {
				id: id,
			},
		});
	}

	return (
		<>
			<PrintLayout>
				<>
					<Container maxW="container.xl" mt="16px" mb="16px">
						<BreadcrumbComponent
							list={[
								{
									name: 'COMPLAINT',
									path: '/complaint-type',
								},
								{
									name: 'Complaint',
									path: '/complaint?page=1&status=in progress',
								},
								{
									name: 'DETAIL',
									path: '#',
								},
							]}
						/>
						<Center mt="5">
							<Box
								w="100%"
								bg="white"
								borderRadius={c.borderRadius}
								border="1px solid #dbdbdb"
								boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
								pb="32px"
							>
								<Flex
									justifyContent="space-between"
									alignItems="center"
									bg="#FAFAFA"
									p="16px"
									pt="8px"
									pb="8px"
									mb="16px"
									borderBottom="1px solid #dbdbdb"
								>
									<Flex alignItems="center">
										<Text fontSize="x-large" fontWeight="bold" mr="4">
											{t('Complaint Detail')}
										</Text>
										{data?.getComplaintById?.complaintType && complaintType(data?.getComplaintById?.complaintType)}
										{data?.getComplaintById?.spam === true && (
											<Badge ml="4" colorScheme="red">
												{t('Spam')}
											</Badge>
										)}
									</Flex>
									<Flex className="hidden-print">
										<Button
											mr="2"
											onClick={() => window?.print()}
											leftIcon={<FontAwesomeIcon icon={faPrint} style={{ fontSize: 16 }} />}
											colorScheme="gray"
											variant="solid"
											size="sm"
											borderRadius={c.borderRadius}
											border="1px solid #bdc3c7"
										>
											{t('Print')}
										</Button>
										{currentUser?.role === 'admin' && (
											<>
												{!error && data?.getComplaintById?.id && (
													<Menu>
														<MenuButton cursor="pointer">
															<Button
																colorScheme="gray"
																variant="solid"
																size="sm"
																borderRadius={c.borderRadius}
																border="1px solid #bdc3c7"
															>
																<FontAwesomeIcon icon={faEllipsisH} style={{ fontSize: 16 }} />
															</Button>
														</MenuButton>
														<MenuList pt="4" pb="4" pl="3" pr="3" borderRadius={c.borderRadius}>
															{data?.getComplaintById?.settled === false && currentUser.role === 'admin' && (
																<MenuItem color={'green'} icon={<CheckIcon />} onClick={() => setSettledComplaint()}>
																	{t('Settled')}
																</MenuItem>
															)}
															{data?.getComplaintById?.settled === true && currentUser.role === 'admin' && (
																<MenuItem icon={<SpinnerIcon />} onClick={() => setInprogressComplaint()}>
																	{t('In progress')}
																</MenuItem>
															)}
															{data?.getComplaintById?.spam === false && (
																<MenuItem icon={<WarningIcon />} onClick={() => setSpam()}>
																	{t('Spam')}
																</MenuItem>
															)}
															{data?.getComplaintById?.spam === true && (
																<MenuItem icon={<RepeatIcon />} onClick={() => setNotSpam()}>
																	{t('Not Spam')}
																</MenuItem>
															)}
														</MenuList>
													</Menu>
												)}
											</>
										)}
									</Flex>
								</Flex>

								{loading && <Text ml="8">{t('Loading')}</Text>}

								{data?.getComplaintById && (
									<Box p="16px">
										<Flex>
											<Text fontWeight="bold" w="120px" minW="120px">
												{t('Number')}:
											</Text>
											<Text ml="4">{data?.getComplaintById?.no}</Text>
										</Flex>

										<Flex mt="2">
											<Text fontWeight="bold" w="120px" minW="120px">
												{t('Subject')}:
											</Text>
											<Text ml="4">{data?.getComplaintById?.subject}</Text>
										</Flex>

										<Flex mt="2">
											<Text fontWeight="bold" w="120px" minW="120px">
												{t('Create At')}:
											</Text>
											<Text ml="4">{moment(data?.getComplaintById?.createdAt).format('LLLL')}</Text>
										</Flex>

										<Flex mt="2">
											<Text fontWeight="bold" w="120px" minW="120px">
												{t('Status')}:
											</Text>
											<Box ml="4">
												{getStatus({ settled: data?.getComplaintById?.settled, settledDate: data?.getComplaintById?.settledDate })}
											</Box>
										</Flex>
										{data?.getComplaintById?.settled && (
											<>
												<Flex mt="2">
													<Text fontWeight="bold" w="120px" minW="120px">
														{t('Settled at')}:
													</Text>
													<Text ml="4">{moment(data?.getComplaintById?.settledDate).format('LLL')}</Text>
												</Flex>
											</>
										)}

										{data?.getComplaintById?.description && (
											<Flex mt="4">
												<Text fontWeight="bold" w="120px" minW="120px">
													{t('Details')}:
												</Text>
												<Text ml="4">{data?.getComplaintById?.description}</Text>
											</Flex>
										)}

										{data?.getComplaintById?.complaintFile && (
											<Flex mt="4" alignItems="center">
												<Text fontWeight="bold" w="120px" minW="120px">
													{t('File')}:
												</Text>
												<DownloadAndViewButton
													url={data?.getComplaintById?.complaintFile}
													size={data?.getComplaintById?.complaintFileSize}
												/>
											</Flex>
										)}

										<Flex alignItems="center" mt="2">
											<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
												{t('Assign to')}:
											</Text>
											<Wrap ml="4">
												{data?.getComplaintById?.assignTo?.map((aTo, index) => {
													return (
														<Flex direction="column" justifyContent="center" alignItems="center" key={'u-' + index}>
															<Tooltip
																label={
																	<>
																		{t('Assign By')}&nbsp;
																		<UserDislayNameById id={aTo?.createdBy} />
																		&nbsp;{', '}&nbsp;
																		{moment(aTo.createdAt).format('LLLL')}
																	</>
																}
															>
																<WrapItem className="avatarWraper">
																	{(currentUser?.role === 'admin' || currentUser?.canAssignUserToComplaint) &&
																		currentUser?.id !== aTo?.user?.id && (
																			<FontAwesomeIcon
																				onClick={() => {
																					removeAssignUserDialog(aTo?.user);
																				}}
																				className="remove"
																				icon={faMinusSquare}
																				style={{
																					fontSize: 18,
																					display: 'none',
																					position: 'absolute',
																					zIndex: '100',
																					marginLeft: 32,
																					color: 'red',
																					backgroundColor: 'white',
																					padding: 2,
																					cursor: 'pointer',
																				}}
																			/>
																		)}
																	<Avatar
																		name={getDisplayName(aTo?.user)}
																		src={aTo?.user?.avatar?.icon ? c.baseFilePath + aTo?.user?.avatar?.icon : null}
																	/>
																</WrapItem>
															</Tooltip>
															<Text textAlign="center">{getDisplayName(aTo.user)}</Text>
														</Flex>
													);
												})}
												{(currentUser?.role === 'admin' || currentUser?.canAssignUserToComplaint) && (
													<Flex>
														<Button
															className="hidden-print"
															onClick={() => setSelectUserModalOpen(true)}
															mt={1}
															leftIcon={<FontAwesomeIcon icon={faPlusSquare} style={{ fontSize: 18 }} />}
															colorScheme="blue"
															variant="outline"
														>
															{t('Add')}
														</Button>
													</Flex>
												)}
											</Wrap>
										</Flex>

										<Flex alignItems="center" mt="2">
											<Text fontWeight="bold" minW={leftLabelWidth} w={leftLabelWidth}>
												{t('Done')}:
											</Text>
											{data?.getComplaintById?.settled ? (
												<>
													{data?.getComplaintById?.doneFile ? (
														<DownloadAndViewButton
															url={data?.getComplaintById?.doneFile?.file}
															size={data?.getComplaintById?.doneFile?.fileSize}
														/>
													) : (
														<Text ml="4">N/A</Text>
													)}
												</>
											) : (
												<>
													{currentUser?.role === 'admin' || currentUser?.canUploadDoneFileToComplaint ? (
														<DoneFileUplaod
															id={id}
															callback={() => {
																window.scrollTo(0, 0);
																setTimeout(() => {
																	loadComplaint({
																		variables: {
																			id,
																		},
																	});
																}, 500);
															}}
														/>
													) : (
														<Text ml="4">N/A</Text>
													)}
												</>
											)}
										</Flex>
									</Box>
								)}
							</Box>
						</Center>
						{data?.getComplaintById?.id && <ViewComment id={data?.getComplaintById?.id} type="COMPLAINT" />}
					</Container>

					{selectUserModalOpen && (
						<SelectUserModal
							role="complaint"
							callback={selectAssignUser}
							isOpen={selectUserModalOpen}
							onClose={() => setSelectUserModalOpen(false)}
						/>
					)}

					<AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
						<AlertDialogOverlay>
							<AlertDialogContent>
								<AlertDialogHeader fontSize="lg" fontWeight="bold">
									{t('Remove Assigned User')}
								</AlertDialogHeader>

								<AlertDialogBody>
									{t(`Are you sure you want to remove`)} "{getDisplayName(removingUserId)}"?
								</AlertDialogBody>

								<AlertDialogFooter>
									<Button ref={cancelRef} onClick={onClose}>
										{t('Cancel')}
									</Button>
									<Button
										isLoading={loadingRemove}
										colorScheme="red"
										onClick={() => {
											removeUserMutation({
												variables: {
													id: id,
													userId: removingUserId?.id,
												},
											});
										}}
										ml={3}
									>
										{t('Remove')}
									</Button>
								</AlertDialogFooter>
							</AlertDialogContent>
						</AlertDialogOverlay>
					</AlertDialog>
				</>
			</PrintLayout>
		</>
	);
}

export default ComplaintDetail;
