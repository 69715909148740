/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Box, Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Tooltip, useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import TextFormField from '../../../form/TextFormField';
import useStore from '../../../../store';
import c from '../../../../constant';
import * as Yup from 'yup';

const Q_DETAIL = gql`
	query get($id: String!) {
		getCompanySubmissionProposalById(id: $id) {
			id
			company {
				id
				name
				nameKhmer
				geoManual
			}
			additionalFeatureData
		}
	}
`;

const M_UPDATE = gql`
	mutation update($proposalId: String!, $data: JSON!) {
		manuallyAuditServiceCompanyCertificate(proposalId: $proposalId, data: $data)
	}
`;

const validationSchema = Yup.object().shape({
	documentNumber: Yup.string().required('Required'),
	khmerName: Yup.string().required('Required'),
	latinName: Yup.string().required('Required'),
	address: Yup.string().required('Required'),
	validFromDate: Yup.string().required('Required'),
	validToDate: Yup.string().required('Required'),
	lunarSignedDate: Yup.string().required('Required'),
	solarSignedDate: Yup.string().required('Required'),
});

export default function InvoiceApprovedRegenerateLicense({ id = null, callback = () => null }) {
	const { t } = useTranslation();
	const { currentUser: user } = useStore((state) => state.currentUser);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [loadDetail, { data }] = useLazyQuery(Q_DETAIL);
	const [updateDetail, { data: dataDetail, loading: loadingDetail }] = useMutation(M_UPDATE);

	useEffect(() => {
		if (id && user?.role === 'admin') {
			loadDetail({ variables: { id } });
		}
	}, [id, user]);

	useEffect(() => {
		if (dataDetail?.manuallyAuditServiceCompanyCertificate) {
			callback();
		}
	}, [dataDetail]);

	return (
		<>
			{data?.getCompanySubmissionProposalById?.additionalFeatureData?.process?.directorGeneral?.status === 'approved' &&
				data?.getCompanySubmissionProposalById?.additionalFeatureData?.licenseFile &&
				data?.getCompanySubmissionProposalById?.additionalFeatureData?.companyId && (
					<>
						<Flex mt={0} mb={0} justifyContent="center" alignItems="center">
							<Flex width="100%" direction="column" alignItems="center" bg="green.100" borderRadius={c.borderRadius} pt={1} pl={4} pr={4} pb={8}>
								<Tooltip label={t('Regenerate')}>
									<Button mt={1} colorScheme="green" variant="solid" size="sm" onMouseDown={onOpen} isLoading={loadingDetail}>
										{t('Regenerate')}
									</Button>
								</Tooltip>
							</Flex>
						</Flex>
						<Modal isOpen={isOpen} onClose={onClose} size="xl">
							<ModalOverlay />
							<ModalContent borderRadius={c.borderRadius} pb="2">
								<ModalHeader>{t('Regenerate')}</ModalHeader>
								<ModalCloseButton />
								<ModalBody>
									<Formik
										initialValues={{
											documentNumber: '',
											khmerName: '',
											latinName: '',
											address: '',
											validFromDate: '',
											validToDate: '',
											lunarSignedDate: '',
											solarSignedDate: '',
										}}
										validationSchema={validationSchema}
										onSubmit={(values) => {
											onClose();
											setTimeout(() => {
												const documentNumber = values?.documentNumber;
												const khmerName = values?.khmerName;
												const latinName = values?.latinName;
												const address = values?.address;
												const validFromDate = values?.validFromDate;
												const validToDate = values?.validToDate;
												const lunarSignedDate = values?.lunarSignedDate;
												const solarSignedDate = values?.solarSignedDate;
												const data = {
													documentNumber,
													khmerName,
													latinName,
													address,
													validFromDate,
													validToDate,
													lunarSignedDate,
													solarSignedDate,
												};
												const variables = { proposalId: id, data };
												updateDetail({ variables });
											}, 100);
										}}
									>
										{(props) => (
											<Form>
												<Box mt="3">
													<TextFormField
														{...props}
														label="Document Number"
														name="documentNumber"
														placeholder={t('០០១ ន.ធ.ក./ទស')}
														required
													/>
													<TextFormField {...props} label="Khmer Name" name="khmerName" placeholder={t('ក ខ គ')} required />
													<TextFormField {...props} label="Latin Name" name="latinName" placeholder={t('A B C')} required />
													<TextFormField
														{...props}
														label="Address"
														name="address"
														placeholder={t(
															'អគារលេខ ១៦៨ ( ជាន់ទី៦ ) ផ្លូវ ៥៩៨ សង្កាត់ច្រាំងចំរេះ១ ខណ្ឌឬស្សីកែវ រាជធានីភ្នំពេញ កម្ពុជា'
														)}
														required
													/>
													<TextFormField
														{...props}
														label="Valid From"
														name="validFromDate"
														placeholder={t('ថ្ងៃទី០៥ ខែមករា គ.ស.២០២៤')}
														required
													/>
													<TextFormField
														{...props}
														label="Valid To"
														name="validToDate"
														placeholder={t('ថ្ងៃទី០៤ ខែមករា គ.ស.២០២៧')}
														required
													/>
													<TextFormField
														{...props}
														label="Lunar Signed Date"
														name="lunarSignedDate"
														placeholder={t('ថ្ងៃសុក្រ ៩រោច ខែមិគសិរ ឆ្នាំថោះ បញ្ចស័ក ព.ស.២៥៦៧')}
														required
													/>
													<TextFormField
														{...props}
														label="Solar Signed Date"
														name="solarSignedDate"
														placeholder={t('រាជធានីភ្នំពេញ ថ្ងៃទី០៥ ខែមករា គ.ស.២០២៤')}
														required
													/>
													<Flex mt="6" justifyContent="right" alignItems="center">
														<Button
															id="login-btn"
															variant="solid"
															size="md"
															h="42px"
															w="128px"
															bg="primary.dark"
															_hover={{ bg: 'primary.dark' }}
															color="white"
															type="submit"
															isLoading={false}
															loadingText={t('Add')}
															spinnerPlacement="start"
														>
															{t(`Submit`)}
														</Button>
													</Flex>
												</Box>
											</Form>
										)}
									</Formik>
								</ModalBody>
							</ModalContent>
						</Modal>
					</>
				)}
		</>
	);
}
