/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Badge } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { gql, useLazyQuery } from '@apollo/client';

const Q_COUNT_UNREAD_PROPOSALS = gql`
	query get($auditServiceOnly: Boolean) {
		getCountUnreadCompanySubmissionProposals(auditServiceOnly: $auditServiceOnly)
	}
`;

const Q_COUNT_NEARLYDUE_PROPOSALS = gql`
	query get($auditServiceOnly: Boolean) {
		getCountNearlyDueCompanySubmissionProposals(auditServiceOnly: $auditServiceOnly)
	}
`;

function CompanySubmissionProposalBadge({ auditServiceOnly = false }) {
	const { t } = useTranslation();
	const [loadCountUnreadComplaints, { data: count }] = useLazyQuery(Q_COUNT_UNREAD_PROPOSALS);
	const [loadCountNearlyDueComplaints, { data: countNearlyDue }] = useLazyQuery(Q_COUNT_NEARLYDUE_PROPOSALS);

	useEffect(() => {
		loadCountUnreadComplaints({ variables: { auditServiceOnly } });
		loadCountNearlyDueComplaints({ variables: { auditServiceOnly } });
	}, []);

	return (
		<>
			{parseInt(count?.getCountUnreadCompanySubmissionProposals) > 0 && (
				<Badge fontSize="x-large" zIndex="99" top="2px" right="2px" position="absolute" colorScheme="red">
					{parseInt(count?.getCountUnreadCompanySubmissionProposals)}
				</Badge>
			)}
			{parseInt(countNearlyDue?.getCountNearlyDueCompanySubmissionProposals) > 0 && (
				<Badge zIndex="99" top="2px" left="2px" position="absolute" colorScheme="yellow" fontSize="16px">
					{parseInt(countNearlyDue?.getCountNearlyDueCompanySubmissionProposals)} {t('Nearly Due')}
				</Badge>
			)}
		</>
	);
}

export default CompanySubmissionProposalBadge;
