/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Button, useToast, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton } from '@chakra-ui/react';
import c from '../../constant';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { gql, useMutation } from '@apollo/client';
import FileFormField from '../../components/form/FileFormField';

const ADD_ATTACHMENT_MUTATION = gql`
	mutation add($id: String!, $attachmentFile: Upload!, $attachmentFileSize: Float!) {
		addDocumentNumberAttachmentFile(id: $id, attachmentFile: $attachmentFile, attachmentFileSize: $attachmentFileSize)
	}
`;

const validationSchema = Yup.object().shape({
	attachmentFile: Yup.mixed()
		.test('fileSize', 'over limit', (value) => {
			if (value) {
				return value?.size <= c.maxFileSize / 10;
			} else {
				return true;
			}
		})
		.required('Required'),
});

function Create({ id = null, isOpen = false, onClose = () => null, onCallback = () => null }) {
	const toast = useToast();
	const { t } = useTranslation();
	const [addAttachmentFile, { loading, error, data }] = useMutation(ADD_ATTACHMENT_MUTATION);

	useEffect(() => {
		if (data) {
			if (data?.addDocumentNumberAttachmentFile) {
				toast({
					title: t('Attachment file added successfully'),
					status: 'success',
					isClosable: true,
					position: 'top-right',
				});
				onClose();
				onCallback();
			}
		}
	}, [data]);

	useEffect(() => {
		if (error) {
			if (error?.graphQLErrors?.length > 0) {
				error?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(error?.message ? error?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [error]);

	const submit = (values) => {
		addAttachmentFile({ variables: { id, attachmentFile: values?.attachmentFile, attachmentFileSize: values?.attachmentFile?.size } });
	};

	return (
		<>
			<Modal closeOnEsc={false} closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="md">
				<ModalOverlay />
				<ModalContent borderRadius={c.borderRadius} pb="2">
					<ModalHeader>{t('Add Attachment File')}</ModalHeader>
					<ModalCloseButton isDisabled={loading} />
					<ModalBody>
						<Formik
							initialValues={{
								attachmentFile: null,
							}}
							validationSchema={validationSchema}
							onSubmit={submit}
						>
							{(props) => (
								<Form>
									<Box mt="3">
										<FileFormField {...props} label="Attachment File" name="attachmentFile" maxFileSize={c.maxFileSize / 10} required />
										<Flex textAlign="right" mt="6" justifyContent="space-between" alignItems="center">
											<Button
												id="login-btn"
												variant="solid"
												size="md"
												h="42px"
												w="128px"
												bg="primary.dark"
												_hover={{ bg: 'primary.dark' }}
												color="white"
												type="submit"
												isLoading={loading}
												loadingText={t('Add')}
												spinnerPlacement="start"
											>
												{t(`Add`)}
											</Button>
										</Flex>
									</Box>
								</Form>
							)}
						</Formik>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
}

export default Create;
