import Promise from "promise";
import axios from "axios"
import c from "../constant"

const privateFileUpload = file =>{
    return new Promise((resolve, reject) => {

        let accessToken = sessionStorage.getItem("accessToken")
      

        var formdata = new FormData();
        formdata.append("operations", "{\"query\":\"mutation UploadFile($file:Upload!) { uploadPrivateFile(file:$file)}\", \"variables\": { \"file\": null }}");
        formdata.append("map", "{ \"0\": [\"variables.file\"] }");
        formdata.append("0", file, file?.name);

        axios({
            method: "post",
            url: c.baseGQL,
            data: formdata,
            headers: { 
                "Content-Type": "multipart/form-data",
                "authorization": "Bearer "+accessToken
            },
          })
            .then( (response)=> {
              //handle success
              console.log(response?.data?.data?.uploadPrivateFile);
              resolve(response?.data?.data?.uploadPrivateFile)
            })
            .catch((error)=> {
              //handle error
              console.log(error);
              reject(error)
            });
      });
    
}

export default privateFileUpload