/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useLazyQuery } from '@apollo/client';
import { Link, useLocation } from 'react-router-dom';
import { Container, Box, Flex, Text, SimpleGrid, Tooltip, useToast } from '@chakra-ui/react';
import BreadcrumbComponent from '../../components/BreadcrumbComponent/BreadcrumbComponent';
import LoadingOverlayText from '../../components/LoadingOverlay/LoadingOverlayText';
import c from '../../constant';

const QUERY_DATA = gql`
	query get($offset: Float!, $limit: Float!) {
		getTypeOfDocumentNumberList(pagerInput: { offset: $offset, limit: $limit }) {
			list {
				id
				type
				typeEn
				description
			}
			totalPages
			totalRows
		}
	}
`;

function DocumentNumber() {
	const toast = useToast();
	const location = useLocation();
	const urlParams = new URLSearchParams(location.search);
	const page = parseInt(urlParams.get('page')) || 1;
	const count = parseInt(urlParams.get('count')) || 300;
	const { t } = useTranslation();
	const [getTypeOfDocumentNumberList, { error, loading, data }] = useLazyQuery(QUERY_DATA);

	useEffect(() => {
		loadData();
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if (error) {
			if (error?.graphQLErrors?.length > 0) {
				error?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(error?.message ? error?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [error]);

	const loadData = () => {
		const limit = count;
		const offset = ((page - 1) | 0) * limit;
		const variables = { offset, limit };
		getTypeOfDocumentNumberList({ variables });
	};

	function menuItem({ item, index }) {
		return (
			<Link key={`menu-${index}`} to={'/issued-letter/list?typeId=' + item?.id + '&typeLabel=' + item?.type + '&page=1'}>
				<Flex
					minH="100px"
					bg="white"
					borderRadius={c.borderRadius}
					border="1px solid #dbdbdb"
					boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
					p="16px"
					cursor="pointer"
					direction="column"
				>
					<Text fontWeight="700" fontSize="xl" color="black.400">
						{t(item?.type)}
					</Text>
					<Text fontWeight="400" fontSize="12px" color="gray.500" noOfLines={2}>
						{t(item?.description)}
					</Text>
				</Flex>
			</Link>
		);
	}

	return (
		<>
			<Container maxW="container.xl" mt="16px" mb="16px">
				<BreadcrumbComponent
					list={[
						{
							name: 'ISSUED LETTER',
							path: '#',
						},
					]}
				/>
				<Box pb="32px" w="100%" mt="5">
					{loading && <LoadingOverlayText />}
					{data?.getTypeOfDocumentNumberList?.list?.length <= 0 && <Text>{t('No Data')}</Text>}
					<SimpleGrid columns={[3, null, 6]} spacing={4}>
						{data?.getTypeOfDocumentNumberList?.list?.map((item, index) => menuItem({ item, index }))}
					</SimpleGrid>
				</Box>
			</Container>
		</>
	);
}

export default DocumentNumber;

const menu = [
	{
		title: 'ន.ធ.ក.',
		path: '/website/articles?category=news&page=1',
	},
	{
		title: 'Announcement',
		path: '/website/articles?category=announcement&page=1',
	},
	{
		title: 'Knowledge sharing',
		path: '/website/articles?category=knowledge-sharing&page=1',
	},
	{
		title: 'Educational broadcasting',
		path: '/website/articles?category=educational-broadcasting&page=1',
	},
	{
		title: 'Social programs',
		path: '/website/articles?category=social-programs&page=1',
	},
	{
		title: 'Statistics - Monthly',
		path: '/website/articles?category=statistics-monthly&page=1',
	},
	{
		title: 'Statistics - Quarterly',
		path: '/website/articles?category=statistics-quarterly&page=1',
	},
	{
		title: 'Statistics - Annually',
		path: '/website/articles?category=statistics-annually&page=1',
	},
	{
		title: 'Laws',
		path: '/website/articles?category=laws&page=1',
	},
	{
		title: 'Sub decrees',
		path: '/website/articles?category=sub-decrees&page=1',
	},
	{
		title: 'Prakas',
		path: '/website/articles?category=prakas&page=1',
	},
	{
		title: 'Guidance',
		path: '/website/articles?category=guidance&page=1',
	},
	{
		title: 'Other',
		path: '/website/articles?category=other&page=1',
	},
	{
		title: 'Publication',
		path: '/website/articles?category=publication&page=1',
	},
	{
		title: 'Public Service',
		path: '/website/articles?category=public-service&page=1',
	},
];
