import React, { useEffect, useState } from "react";
import {
    Box, Flex, Text, Center, Tooltip, Menu, MenuButton, MenuList, MenuItem, AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Button,
    useToast,
    Avatar,
    Tabs,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    useColorModeValue
} from "@chakra-ui/react";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import c from "../../constant"
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import moment from "moment"
import WriteComment from "./WriteComment";
import useStore from "../../store";
import EditComment from "./EditComment"
import { useTranslation } from "react-i18next";
import DownloadAndViewButton from "../Files/DownloadAndViewButton";

const QUERY_COMMENTS = gql`
query get($commentOn: String!, $type: String!, $isSubComment: Boolean){
    getCommentsOn(commentOn:$commentOn, type:$type, isSubComment:$isSubComment){
        id
        description
        attachmentFile
        createdAt
        updatedAt
        deleted
        isSubComment
        user{
            username
            id
            fullName
            fullNameKhmer
            avatar
        }
    }
  }
`

const DELETE_COMMENT = gql`
mutation delete($id: String!){
    deleteComment(id:$id)
  }
`

function ViewComment({ id, type, enableSubComments = false }) {
    const toast = useToast()
    const { t } = useTranslation();
    const { currentUser } = useStore((state) => state.currentUser);
    const { currentLang } = useStore((state) => state.currentLang);
    const [loadComments, { error: errorComments, loading: loadingComments, data: dataComments }] = useLazyQuery(QUERY_COMMENTS)
    const [loadSubComments, { error: errorSubComments, loading: loadingSubComments, data: dataSubComments }] = useLazyQuery(QUERY_COMMENTS)
    const [deleteCommentMutation, { error: errorDeleteComments, loading: loadingDeleteComments, data: dataDeleteComments }] = useMutation(DELETE_COMMENT)


    const [isOpen, setIsOpen] = React.useState(false)
    const onClose = () => setIsOpen(false)
    const cancelRef = React.useRef()

    const [deletingCommentId, setDeletingCommentId] = useState("")
    const [editingComment, setEditingComment] = useState({})
    const [showEdit, setShowEdit] = useState(false)

    const colors = useColorModeValue(
        ['rgba(52, 152, 219,.03)', 'white'],
        ['rgba(52, 152, 219,.03)', 'white'],
    )
    const [tabIndex, setTabIndex] = useState(1)
    const bg = colors[tabIndex]

    useEffect(() => {
        loadCommentsWithVariables()
    }, []);

    useEffect(() => {
        if (id) {
            loadCommentsWithVariables()
        }
    }, [id])


    function commentCallback() {
        loadCommentsWithVariables()
    }

    function loadCommentsWithVariables() {
        loadComments({
            variables: {
                commentOn: id,
                type
            }
        })
        if (enableSubComments) {
            loadSubComments({
                variables: {
                    commentOn: id,
                    type,
                    isSubComment: true
                }
            })
        }
    }

    function deleteComment() {
        deleteCommentMutation({
            variables: {
                id: deletingCommentId
            }
        })
    }

    useEffect(() => {
        if (dataDeleteComments?.deleteComment) {
            onClose()
            loadCommentsWithVariables()
            toast({
                title: "Comment deleted successfully",
                status: "success",
                isClosable: true,
                position: "top-right"
            })
        }
    }, [dataDeleteComments])

    function getDisplayName(user) {
        let displayName = user?.username
        if (currentLang === "kh") {
            displayName = user?.fullNameKhmer ? user?.fullNameKhmer : (user?.fullName ? user?.fullName : user?.username)
        } else {
            displayName = user?.fullName ? user?.fullName : (user?.fullNameKhmer ? user?.fullNameKhmer : user?.username)
        }
        return displayName
    }

    return (
        <>
            <Center mt="5" className={dataComments?.getCommentsOn.length <= 0 ? "hidden-print commentContainer" : "commentContainer"}>
                <Box
                    w="100%"
                    bg="white"
                    borderRadius={c.borderRadius}
                    border="1px solid #dbdbdb"
                    boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
                >
                    <Tabs pb="32px" variant='unstyled' defaultIndex={1} onChange={(index) => setTabIndex(index)} bg={bg}>
                        <Flex w="100%" alignItems="center" bg="#FAFAFA" p="0" mb="16px" borderBottom="1px solid #dbdbdb">
                            <Text
                                fontSize="x-large"
                                fontWeight="bold"
                                mr="4"
                                textAlign="left"
                                w="100%"
                                p="16px"
                                pt="8px"
                                pb="8px"
                            >
                                {t("Comments")}
                            </Text>
                            {enableSubComments && <TabList className="hidden-print">
                                <Tab h="52px" borderRadius="0 !important" _selected={{ color: 'white', bg: 'green.400' }}>
                                    <Text p="0" ml="16px" mr="16px" color={tabIndex === 0 ? "white" : "black"} fontWeight="bold">{t("Sub")}</Text>
                                </Tab>
                                <Tab h="52px" borderRadius="0 !important" _selected={{ color: 'white', bg: 'blue.500' }}>
                                    <Text p="0" ml="16px" mr="16px" color={tabIndex === 1 ? "white" : "black"} fontWeight="bold">{t("Main")}</Text>
                                </Tab>
                            </TabList>}
                        </Flex>
                        <Box>
                            <TabPanels>
                                <TabPanel p="0">
                                    <Flex direction="column">
                                        {loadingSubComments && <Text m="4">{t("Loading")}...</Text>}
                                        {errorSubComments && <Text m="4">Error: {t("comments can not be load")}</Text>}
                                        {
                                            dataSubComments?.getCommentsOn?.map((comment) => {
                                                return (
                                                    <Box style={{}} bg={"#EFF2F5"} id={comment.id} key={comment.id} m="4" borderRadius={c.borderRadius}>
                                                        <Box>
                                                            <Flex p="2" alignItems="center">
                                                                <Flex flex={1} alignItems="center">
                                                                    <Avatar mb="2px" size='xs' name={getDisplayName(comment?.user)} src={comment?.user?.avatar?.icon ? (c.baseFilePath + comment?.user?.avatar?.icon) : null} />
                                                                    <Text ml="1" fontWeight="bold">{getDisplayName(comment?.user)}</Text>
                                                                    {
                                                                        comment?.updatedAt ?
                                                                            <Tooltip label={`${t("commented")} ${moment(comment?.createdAt).format("LLL")}.
                                                            ${t("Updated at")} ${moment(comment?.updatedAt).format("LLL")}`}>
                                                                                <Text className="hidden-print" ml="2" color="gray.500">{t("updated")} {moment(comment?.updatedAt).fromNow()}</Text>
                                                                            </Tooltip> :
                                                                            <Tooltip label={moment(comment?.createdAt).format("LLL")}>
                                                                                <Text className="hidden-print" ml="2" color="gray.500">{t("commented")} {moment(comment?.createdAt).fromNow()}</Text>
                                                                            </Tooltip>
                                                                    }
                                                                    <Text fontSize={10} className="show-only-printing">
                                                                        &nbsp;
                                                                        {
                                                                            comment?.updatedAt ? `${t("commented")} ${moment(comment?.createdAt).format("LLL")}.
                                                            ${t("Updated at")} ${moment(comment?.updatedAt).format("LLL")}` :
                                                                                (t("commented") + " " + moment(comment?.createdAt).format("LLL"))
                                                                        }
                                                                    </Text>
                                                                </Flex>
                                                                {
                                                                    (((currentUser?.id === comment?.user?.id)) && !comment.deleted) &&
                                                                    <Menu isLazy>
                                                                        <MenuButton pt="1">
                                                                            <FontAwesomeIcon className="hidden-print" style={{ color: "#34495e" }} icon={faEllipsisH} />
                                                                        </MenuButton>
                                                                        <MenuList borderRadius={c.borderRadius}>
                                                                            <MenuItem onClick={() => {
                                                                                setEditingComment(comment)
                                                                                setShowEdit(true)
                                                                            }}>{t("Edit")}</MenuItem>
                                                                            <MenuItem color="red" onClick={() => {
                                                                                setIsOpen(true)
                                                                                setDeletingCommentId(comment?.id)
                                                                            }}>{t("Delete")}</MenuItem>
                                                                        </MenuList>
                                                                    </Menu>
                                                                }
                                                            </Flex>
                                                            <Box w="100%" h="1px" bg={"gray.200"} />
                                                            <Box m="2" pb="2">
                                                                <Box mt="4" opacity={comment.deleted ? 0.2 : 1}>
                                                                    {comment?.description && <pre style={{ whiteSpace: "pre-wrap", paddingBottom: 16, }}>
                                                                        {comment?.description}
                                                                    </pre>}
                                                                </Box>
                                                                {comment?.attachmentFile?.file &&
                                                                    <Box ml="-4">
                                                                        <DownloadAndViewButton url={comment?.attachmentFile?.file} size={comment?.attachmentFile?.fileSize} />
                                                                    </Box>
                                                                }
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                )
                                            })
                                        }
                                    </Flex>
                                </TabPanel>
                                <TabPanel p="0">
                                    <Flex direction="column">
                                        {loadingComments && <Text m="4">{t("Loading")}...</Text>}
                                        {errorComments && <Text m="4">Error: {t("comments can not be load")}</Text>}
                                        {
                                            dataComments?.getCommentsOn?.map((comment) => {
                                                return (
                                                    <Box style={{}} bg={"#EFF2F5"} id={comment.id} key={comment.id} m="4" borderRadius={c.borderRadius}>
                                                        <Box>
                                                            <Flex p="2" alignItems="center">
                                                                <Flex flex={1} alignItems="center">
                                                                    <Avatar mb="2px" size='xs' name={getDisplayName(comment?.user)} src={comment?.user?.avatar?.icon ? (c.baseFilePath + comment?.user?.avatar?.icon) : null} />
                                                                    <Text ml="1" fontWeight="bold">{getDisplayName(comment?.user)}</Text>
                                                                    {
                                                                        comment?.updatedAt ?
                                                                            <Tooltip label={`${t("commented")} ${moment(comment?.createdAt).format("LLL")}.
                                                            ${t("Updated at")} ${moment(comment?.updatedAt).format("LLL")}`}>
                                                                                <Text className="hidden-print" ml="2" color="gray.500">{t("updated")} {moment(comment?.updatedAt).fromNow()}</Text>
                                                                            </Tooltip> :
                                                                            <Tooltip label={moment(comment?.createdAt).format("LLL")}>
                                                                                <Text className="hidden-print" ml="2" color="gray.500">{t("commented")} {moment(comment?.createdAt).fromNow()}</Text>
                                                                            </Tooltip>
                                                                    }
                                                                    <Text fontSize={10} className="show-only-printing">
                                                                        &nbsp;
                                                                        {
                                                                            comment?.updatedAt ? `${t("commented")} ${moment(comment?.createdAt).format("LLL")}.
                                                            ${t("Updated at")} ${moment(comment?.updatedAt).format("LLL")}` :
                                                                                (t("commented") + " " + moment(comment?.createdAt).format("LLL"))
                                                                        }
                                                                    </Text>
                                                                </Flex>
                                                                {
                                                                    (((currentUser?.id === comment?.user?.id)) && !comment.deleted) &&
                                                                    <Menu isLazy>
                                                                        <MenuButton pt="1">
                                                                            <FontAwesomeIcon className="hidden-print" style={{ color: "#34495e" }} icon={faEllipsisH} />
                                                                        </MenuButton>
                                                                        <MenuList borderRadius={c.borderRadius}>
                                                                            <MenuItem onClick={() => {
                                                                                setEditingComment(comment)
                                                                                setShowEdit(true)
                                                                            }}>{t("Edit")}</MenuItem>
                                                                            <MenuItem color="red" onClick={() => {
                                                                                setIsOpen(true)
                                                                                setDeletingCommentId(comment?.id)
                                                                            }}>{t("Delete")}</MenuItem>
                                                                        </MenuList>
                                                                    </Menu>
                                                                }
                                                            </Flex>
                                                            <Box w="100%" h="1px" bg={"gray.200"} />
                                                            <Box m="2" pb="2">
                                                                <Box mt="4" opacity={comment.deleted ? 0.2 : 1}>
                                                                    {comment?.description && <pre style={{ whiteSpace: "pre-wrap", paddingBottom: 16, }}>
                                                                        {comment?.description}
                                                                    </pre>}
                                                                </Box>
                                                                {comment?.attachmentFile?.file &&
                                                                    <Box ml="-4">
                                                                        <DownloadAndViewButton url={comment?.attachmentFile?.file} size={comment?.attachmentFile?.fileSize} />
                                                                    </Box>
                                                                }
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                )
                                            })
                                        }
                                    </Flex>
                                </TabPanel>
                            </TabPanels>
                            <WriteComment id={id} type={type} isSubComment={tabIndex === 1 ? false : true} callback={commentCallback} />
                        </Box>
                    </Tabs>
                </Box>
            </Center>


            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent borderRadius={c.borderRadius}>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            {t("Delete Comment")}
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            {t("Are you sure? You can't undo this action afterwards")}
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button borderRadius={c.borderRadius} ref={cancelRef} onClick={onClose}>
                                {t("Cancel")}
                            </Button>
                            <Button isLoading={loadingDeleteComments} borderRadius={c.borderRadius} colorScheme="red" onClick={() => {
                                if (!loadingDeleteComments) {
                                    deleteComment()
                                }
                            }} ml={3}>
                                {t("Delete")}
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>

            {showEdit &&
                <EditComment
                    comment={editingComment}
                    isOpen={showEdit}
                    onClose={() => {
                        setEditingComment({})
                        setShowEdit(false)
                    }}
                    callback={() => {
                        setEditingComment({})
                        setShowEdit(false)
                        loadCommentsWithVariables()
                    }}
                />
            }
        </>
    )
}

export default ViewComment