import React from "react";
import { Input, Box, FormControl, FormLabel, FormHelperText, InputGroup, InputRightElement } from "@chakra-ui/react";
import { Field } from "formik";
import { useTranslation } from "react-i18next";

const TextFormFieldReadOnly = props => {
    const {
        name,
        type,
        label,
        placeholder,
        required,
        rightElement,
        min,
        disabled=false
    } = props;
    const { t } = useTranslation();
    return (
        <Field name={name}>
            {({ field, meta }) => (
                <Box mb="4">
                    <FormControl id={name} >
                        <FormLabel>{t(label)}{required&&<span style={{color:"red"}}>*</span>}</FormLabel>
                        <InputGroup size='md'>
                        <Input
                            placeholder={placeholder}
                            disabled={disabled}
                        />
                        <Input
                            display="none"
                            {...field}
                            value={field?.value?field?.value:""}
                            id={name}
                            type={type ? type : "text"}
                            placeholder={placeholder ? placeholder : ""}
                            size="lg"
                            errorBorderColor="red.300"
                            isInvalid={meta.touched && meta.error}
                            step="any"
                            min={min?min:null}
                            onWheelCapture={e => {
                                e.target.blur()
                            }}
                            disabled={disabled}
                        />
                            {rightElement&&<InputRightElement width='4.5rem'>
                                {rightElement}
                            </InputRightElement>}
                        </InputGroup>
                        {meta.touched && meta.error && (
                            <FormHelperText
                                id={`error-message-${name}`}
                                color="red.400"
                                fontSize="12px"
                            >
                                {t(name)}{" "}
                                {t("is")}{" "}
                                {t(meta.error)}
                            </FormHelperText>
                        )}
                    </FormControl>

                </Box>
            )}
        </Field>
    );
};

export default TextFormFieldReadOnly;
