import { Text } from '@chakra-ui/react';
import useStore from '../store';

function Address({
	provinceObject = null,
	districtObject = null,
	communeObject = null,
	villageObject = null,
	streetNumber = null,
	houseNumber = null,
	isGeoManual = false,
	geoManual = null,
	...props
}) {
	const { currentLang: locale } = useStore((state) => state.currentLang);

	function commaEn() {
		return locale === 'en' ? ',' : null;
	}

	function getGeoLocationName(object) {
		return object?.code && (locale === 'kh' ? `${object?.administrativeUnit}${object?.name}` : `${object?.administrativeUnitLatin} ${object?.latinName}`);
	}

	return (
		<>
			{isGeoManual && <Text {...props}>{geoManual}</Text>}
			{!isGeoManual && (
				<Text {...props}>
					{houseNumber && (
						<>
							{`${locale === 'kh' ? '​ផ្ទះលេខ ' : '#'}${houseNumber || null}`}
							{commaEn()}
							&nbsp;
						</>
					)}
					{streetNumber && (
						<>
							{`${locale === 'kh' ? 'ផ្លូវ ' : 'St. '}${streetNumber || null}`}
							{commaEn()}
							&nbsp;
						</>
					)}
					{villageObject && (
						<>
							{getGeoLocationName(villageObject)}
							{commaEn()}
							&nbsp;
						</>
					)}
					{communeObject && (
						<>
							{getGeoLocationName(communeObject)}
							{commaEn()}
							&nbsp;
						</>
					)}
					{districtObject && (
						<>
							{getGeoLocationName(districtObject)}
							{commaEn()}
							&nbsp;
						</>
					)}
					{provinceObject && <>{getGeoLocationName(provinceObject)}</>}
				</Text>
			)}
		</>
	);
}

export default Address;
