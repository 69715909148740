import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Text,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
  Flex,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import TextFormField from '../form/TextFormField';
import DateRangeFormField from '../form/DateRangeFormField';
import CheckBoxFormField from '../form/CheckBoxFormField';
import RadioFormField from '../form/RadioFormField';
import useStore from '../../store';
import c from '../../constant';
import * as Yup from 'yup';

const filterSchema = Yup.object().shape({
  number: Yup.number().nullable(),
  dateRange: Yup.string().nullable(),
  status: Yup.string().nullable(),
  publicService: Yup.string().nullable(),
  unread: Yup.boolean().nullable(),
  spam: Yup.boolean().nullable(),
  showNearlyDue: Yup.boolean().nullable(),
  showIncomplete: Yup.boolean().nullable(),
  state: Yup.string().nullable(),
});

function CompanyDetailProposalFilter({
  onApply,
  onClose,
  number = null,
  fromDate = null,
  toDate = null,
  status = null,
  publicService = null,
  unread = null,
  spam = null,
  showNearlyDue = null,
  showIncomplete = false,
  state = null,
}) {
  const { t } = useTranslation();
  const { currentUser } = useStore((state) => state.currentUser);

  function getDateRange(fromDate, toDate) {
    if (fromDate && toDate) {
      const from = JSON.parse(fromDate);
      const to = JSON.parse(toDate);
      return JSON.stringify({ from, to });
    }
    return null;
  }

  return (
    <>
      <Modal borderRadius={c.borderRadius} isOpen={true} onClose={onClose}>
        <ModalOverlay />
        <ModalContent borderRadius={c.borderRadius}>
          <ModalHeader>{t('Filter')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Formik
              validationSchema={filterSchema}
              initialValues={{
                number,
                dateRange: getDateRange(fromDate, toDate),
                status,
                publicService,
                unread,
                spam,
                showNearlyDue,
                showIncomplete,
                state,
              }}
              onSubmit={(values) => {
                let number = null;
                let dateRange = null;
                let status = null;
                let publicService = null;
                let unread = null;
                let spam = null;
                let showNearlyDue = null;
                let showIncomplete = null;
                let state = null;
                if (values.number) {
                  number = values.number;
                }
                if (values.dateRange) {
                  dateRange = values.dateRange;
                }
                if (values.status) {
                  status = values.status;
                }
                if (values.publicService) {
                  publicService = values.publicService;
                }
                if (values.unread) {
                  unread = values.unread;
                }
                if (values.spam) {
                  spam = values.spam;
                }
                if (values.showNearlyDue) {
                  showNearlyDue = values.showNearlyDue;
                }
                if (values.showIncomplete) {
                  showIncomplete = values.showIncomplete;
                }
                if (values.state) {
                  state = values.state;
                }
                onApply({
                  number,
                  dateRange,
                  status,
                  publicService,
                  unread,
                  spam,
                  showNearlyDue,
                  showIncomplete,
                  state,
                });
                onClose();
              }}
            >
              {(props) => (
                <Box pb="4">
                  <Form>
                    <TextFormField {...props} label="Number" name="number" type="number" />
                    <center>
                      <DateRangeFormField
                        {...props}
                        label="Created Date"
                        name="dateRange"
                        initialDate={{ fromDate, toDate }}
                      />
                    </center>
                    <RadioFormField
                      label="Type"
                      name="publicService"
                      radios={[
                        { label: 'All', value: 'all' },
                        { label: 'Public Service', value: 'PUBLIC_SERVICE' },
                        { label: 'Other', value: 'OTHER' },
                      ]}
                      initValue={publicService}
                    />
                    <Box mt={4}>
                      <CheckBoxFormField {...props} label="Show unread only" name="unread" />
                    </Box>
                    <Box mt={4}>
                      <CheckBoxFormField
                        {...props}
                        label="Show Only Due Within 7 Days"
                        name="showNearlyDue"
                      />
                    </Box>
                    <Text fontSize={11} color="gray">
                      ( {t('When show only due within 7 days, other filter will not affect')} )
                    </Text>
                    {currentUser?.role === 'admin' && (
                      <Box mt={4}>
                        <CheckBoxFormField {...props} label="Show spam" name="spam" />
                      </Box>
                    )}
                    {currentUser?.role === 'admin' && (
                      <Box mt={4}>
                        <CheckBoxFormField
                          {...props}
                          label="Show incomplete submission"
                          name="showIncomplete"
                        />
                      </Box>
                    )}
                    <Flex mt={8} justifyContent="flex-end">
                      <Button
                        id="login-btn"
                        variant="solid"
                        size="md"
                        h="42px"
                        w="128px"
                        type="submit"
                        borderRadius={c.borderRadius}
                        color="#3498db"
                      >
                        {t('Apply')}
                      </Button>
                      <Button
                        size="md"
                        h="42px"
                        ml={4}
                        w="128px"
                        onClick={() => {
                          onApply({
                            number: null,
                            dateRange: null,
                            status: null,
                            publicService: null,
                            unread: null,
                            spam: null,
                            showNearlyDue: null,
                            showIncomplete: null,
                            state: null,
                          });
                          onClose();
                        }}
                        variant="ghost"
                      >
                        {t('Clear')}
                      </Button>
                    </Flex>
                  </Form>
                </Box>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default CompanyDetailProposalFilter;
