/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { gql, useLazyQuery } from '@apollo/client';
import { Container, Box, Flex, Text, Center, Button, useToast } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BreadcrumbComponent from '../../../../components/BreadcrumbComponent/BreadcrumbComponent';
import LoadingOverlayText from '../../../../components/LoadingOverlay/LoadingOverlayText';
import c from '../../../../constant';

const Q_GET_TYPE_DOCUMENT = gql`
	query get($id: String!) {
		getTypeOfDocumentNumberById(id: $id) {
			id
			type
			typeEn
			description
		}
	}
`;

function TypeOfDocumentNumberDetail() {
	const toast = useToast();
	const location = useLocation();
	const urlParams = new URLSearchParams(location.search);
	const id = urlParams.get('id') || null;
	const { t } = useTranslation();
	const [getTypeOfDocumentNumber, { error, data, loading }] = useLazyQuery(Q_GET_TYPE_DOCUMENT);

	useEffect(() => {
		const variables = { id };
		getTypeOfDocumentNumber({ variables });
	}, []);

	useEffect(() => {
		if (error) {
			if (error?.graphQLErrors?.length > 0) {
				error?.graphQLErrors.map(({ message }, i) =>
					toast({
						title: t(message),
						status: 'error',
						isClosable: true,
						position: 'bottom-right',
					})
				);
			} else {
				toast({
					title: t(error?.message ? error?.message : 'something went wrong'),
					status: 'error',
					isClosable: true,
					position: 'bottom-right',
				});
			}
		}
	}, [error]);

	function row(label, value, pb) {
		return (
			<Flex style={{ pageBreakInside: 'avoid' }} pt={1} pb={pb}>
				<Text fontWeight="bold" minW="160px" w="160px">
					{t(label)}
				</Text>
				<Box>
					<Text ml="4" fontWeight="bold">
						{value ? t(value) : ''}
					</Text>
				</Box>
			</Flex>
		);
	}

	return (
		<>
			{loading && <LoadingOverlayText />}
			<Container maxW="container.xl" mt="16px" mb="16px">
				<BreadcrumbComponent
					list={[
						{
							name: 'Settings',
							path: '/settings',
						},
						{
							name: 'TYPE OF ISSUED LETTER',
							path: '/type-of-issued-letter?page=type-of-issued-letter',
						},
						{
							name: 'Issued Letter Type Detail',
							path: '/type-of-issued-letter/detail-type-of-issued-letter?id=' + id,
						},
					]}
				/>
				<Center mt="5">
					<Box
						w="100%"
						bg="white"
						borderRadius={c.borderRadius}
						border="1px solid #dbdbdb"
						boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
						pb={4}
					>
						<Flex bg="#FAFAFA" p="16px" pt="8px" pb="8px" mb="16px" justifyContent="space-between" borderBottom="1px solid #dbdbdb">
							<Text fontSize="x-large" fontWeight="bold">
								{t('Issued Letter Type Detail')}
							</Text>
							<Link to={'/type-of-issued-letter/edit-type-of-issued-letter?id=' + id}>
								<Button
									mr="2"
									leftIcon={<FontAwesomeIcon icon={faEdit} style={{ fontSize: 16 }} />}
									colorScheme="gray"
									variant="solid"
									size="sm"
									borderRadius={c.borderRadius}
									border="1px solid #bdc3c7"
								>
									{t('Edit')}
								</Button>
							</Link>
						</Flex>
						<Box p="16px" pt="0" className="leave-detail-container">
							{row('Type', data?.getTypeOfDocumentNumberById?.type, 1)}
							{row('Type En', data?.getTypeOfDocumentNumberById?.typeEn, 1)}
							{row('Description', data?.getTypeOfDocumentNumberById?.description, 0)}
						</Box>
					</Box>
				</Center>
			</Container>
		</>
	);
}

export default TypeOfDocumentNumberDetail;
