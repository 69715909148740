import React, { useEffect, useState } from 'react';
import { Container, Box, Flex, Text, Button } from "@chakra-ui/react"
import BreadcrumbComponent from "../../../components/BreadcrumbComponent/BreadcrumbComponent";
import TableComponent from "../../../components/Table/Cloudscape/TableComponent";
import { gql, useLazyQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import useLocalStorage from 'use-local-storage';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import c from '../../../constant';
import DateRangePicker from "@cloudscape-design/components/date-range-picker";
import moment from 'moment';
import _ from "lodash"

const QUERY_DATA = gql`
    query get(
        $toDate: DateTime,
        $fromDate: DateTime
    ){
        getPublicServicesReport(fromDate: $fromDate, toDate:$toDate)
    }
`;

export default function PublicServiceReport() {
    const { t } = useTranslation();
    const [pickedDateRange, setPickedDateRange] = React.useState(undefined);
    const [items, setItems] = useState([])
    const [loadData, { loading, data }] = useLazyQuery(QUERY_DATA);

    useEffect(() => {
        loadData()
    }, [])

    useEffect(() => {
        if (data?.getPublicServicesReport) {
            setItems(JSON.parse(data?.getPublicServicesReport))
        }
    }, [data])

    useEffect(() => {
        if (pickedDateRange) {
            loadData({
                variables: {
                    fromDate: moment(pickedDateRange?.startDate).toDate(),
                    toDate: moment(pickedDateRange?.endDate).toDate()
                }
            })
        } else {
            loadData()
        }
    }, [pickedDateRange])

    const tableName = "report-public-service-amount";
    const rawColumns = [
        {
            id: 'title',
            label: t('ID Card Number'),
            alwayVisible: true,
            header: "Title",
            cell: (item) => item?.title,
            isRowHeader: true,
        },
        {
            id: 'amount',
            label: t('Amount'),
            header: t('Amount'),
            cell: (item) => item.count,
            width: 200
        }
    ]
    const [preferences, setPreferences] = useLocalStorage(`${tableName}-table-pref`, {
        pageSize: 10,
        contentDisplay: rawColumns.map((column) => ({ id: column.id, visible: true })),
        wrapLines: false,
        stripedRows: false,
        contentDensity: 'compact',
        stickyColumns: { first: 0, last: 0 },
    });

    function getHeader() {
        return (
            <Flex className="table-header" direction={'row'} alignItems={'center'}>
                <Flex flexGrow={1} className="title">
                    <Text fontSize="xl" fontWeight={600} mt="10px" mb="10px">
                        {t('Public Serivce')}
                    </Text>
                </Flex>
                <Flex direction={'row'}>
                    <FilterByDateRange />
                    <Button
                        ml="10px"
                        onClick={() => window?.print()}
                        leftIcon={<FontAwesomeIcon icon={faPrint} style={{ fontSize: 16 }} />}
                        colorScheme="gray"
                        variant="solid"
                        size="sm"
                        borderRadius={c.borderRadius}
                        border="1px solid #bdc3c7"
                    >
                        {t('Print')}
                    </Button>
                </Flex>
            </Flex>
        )
    }
    // end table config

    function FilterByDateRange() {
        return (
            <DateRangePicker
                onChange={({ detail }) => setPickedDateRange(detail.value)}
                value={pickedDateRange}
                rangeSelectorMode="absolute-only"
                placeholder="Filter by a date and time range"
                i18nStrings={{ applyButtonLabel: "Apply", clearButtonLabel: "Clear" }}
                absoluteFormat="long-localized"
                showClearButton={true}
                dateOnly={true}
            />
        )
    }

    return (
        <>
            <Container maxW="container.xl" mt="16px" mb="16px">
                <BreadcrumbComponent
                    list={[
                        {
                            name: "Settings",
                            path: "/settings",
                        },
                        {
                            name: "Public Service Report",
                            path: "#",
                        },
                    ]}
                />
                <Box pb="32px" w="100%" maxW={"8xl"} mt="5">
                    <TableComponent
                        tableName={tableName}
                        rawColumns={rawColumns}
                        currentPage={1}
                        totalPage={1}
                        loading={loading}
                        items={items || []}
                        preferences={preferences}
                        setPreferences={setPreferences}
                        onTablePageChange={() => null}
                        onSearchTextChange={() => null}
                        searchText={""}
                        totalRows={items?.length || ''}
                        header={getHeader()}
                    />
                </Box>
            </Container>
        </>
    )
}
