import React from 'react';
import { Badge } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

function CompanyAgentBadge({ type }) {
	const { t } = useTranslation();
	switch (type) {
		case '1':
			return <Badge colorScheme="purple">{t('Direct Agent')}</Badge>;
		case '2':
			return <Badge colorScheme="green">{t('Bancassurance')}</Badge>;
		case '3':
			return <Badge colorScheme="red">{t('Professional Individual based on Licensing')}</Badge>;
		default:
			return null;
	}
}
export default CompanyAgentBadge;
