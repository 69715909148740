import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "@cloudscape-design/components/modal";
import Button from "@cloudscape-design/components/button";
import {
    Flex,
    Text,
    useToast
} from '@chakra-ui/react';
import c from "../../constant"
import Cards from "@cloudscape-design/components/cards";
import useStore from '../../store';
import { Avatar } from '@chakra-ui/react'
import Popover from "@cloudscape-design/components/popover";
import SelectUserModal from '../Modal/SelectUserModal';
import "./style.css"
import { gql, useLazyQuery, useMutation } from '@apollo/client';

const ADD_TO = gql`
	mutation add($id: String!, $type: String!) {
		addToAutoAssignProposal(id: $id, type: $type)
	}
`;

const REMOVE_FROM = gql`
	mutation remove($id: String!, $type: String!) {
		removeFromAutoAssignProposal(id: $id, type: $type)
	}
`;

const ASSIGN_OFFICE = gql`
	mutation assign($id: String!, $office: String!) {
		assignCompanySubmissionProposalToOffice(id: $id, office: $office)
	}
`;

const QUERY_USERS = gql`
	query get($type: String!){
		getAutoAssignProposalList(type:$type) {
			user {
				id
				username
				fullName
				fullNameKhmer
				createdAt
				role
				avatar
				active
                department
                office
			}
            type
			id
		}
	}
`;

export default function SelectOfficeModal({ onClose, selectedItems: fromParent, callBack, id }) {
    const { t } = useTranslation();
    const toast = useToast();
    const { currentLang } = useStore((state) => state.currentLang);
    const { currentUser } = useStore((state) => state.currentUser);
    const CURRENT_USER_DEPARTMENT = currentUser?.department
    const [officeData, setOfficeData] = useState()
    const [selectUserModalOpen, setSelectUserModalOpen] = useState(false)
    const [selectingOffice, setSelectingOffice] = useState(null)
    // gql
    const [addUser, { error: errorAdd, data: addData, loading: addLoading }] = useMutation(ADD_TO);
    const [removeUser, { error: errorRemove, data: removeData, loading: removeLoading }] = useMutation(REMOVE_FROM);
    const [loadUser, { error: errorDefault, loading: loadingDefault, data: dataDefault }] = useLazyQuery(QUERY_USERS);
    const [assignOffice, { error: errorAssign, data: dataAssign, loading: loadingAssign }] = useMutation(ASSIGN_OFFICE);

    useEffect(() => {
        loadUser({
            variables: {
                type: "office"
            }
        })
    }, [])

    useEffect(() => {
        if (addData || removeData) {
            loadUser({
                variables: {
                    type: "office"
                }
            })
        }
    }, [addData, removeData])

    useEffect(() => {
        let offices = null
        c.departments.map((dp) => {
            if (dp?.value === CURRENT_USER_DEPARTMENT) {
                offices = dp.offices
            }
        })

        const tmp = []
        offices?.map((of) => {
            const tmpPeople = []
            dataDefault?.getAutoAssignProposalList?.map((u) => {
                if (u?.user?.office === of?.value) {
                    tmpPeople.push(u)
                }
            })
            tmp.push({
                ...of,
                peoples: tmpPeople
            })
        })
        setOfficeData(tmp)
    }, [dataDefault])

    function selectOneItem(item) {
        assignOffice({
            variables: {
                id,
                office: item?.value ? item?.value : ""
            }
        })
    }

    useEffect(() => {
        if (dataAssign) {
            toast({
                title: t('Office updated successfully'),
                status: 'success',
                isClosable: true,
                position: 'top-right',
            });
            callBack()
            onClose()
        }
    }, [dataAssign])

    useEffect(() => {
        if (errorAssign) {
            toast({
                title: t('Office updated error'),
                status: 'error',
                isClosable: true,
                position: 'top-right',
            });
        }
    }, [errorAssign])

    function getDisplayName(user) {
        let result = user?.username;
        if (currentLang === 'kh') {
            if ((result = user?.fullNameKhmer)) {
                result = user?.fullNameKhmer;
            } else {
                if (user?.fullName) {
                    result = user?.fullName;
                } else {
                    result = user.username;
                }
            }
        } else {
            if ((result = user?.fullName)) {
                result = user?.fullName;
            } else {
                if (user?.fullNameKhmer) {
                    result = user?.fullNameKhmer;
                } else {
                    result = user.username;
                }
            }
        }
        return result;
    }

    return (
        <>
            <Modal
                onDismiss={() => {
                    onClose()
                }}
                visible={true}
                header={t("Please select office")}
            >

                <Cards
                    className="item-card"
                    cardDefinition={{
                        header: item => (
                            <Text onClick={() => selectOneItem(item)} lineHeight="30px" cursor="pointer" fontSize="md" textDecoration="underline" color="#0872D3">
                                {currentLang === "en" ? item?.label : item?.labelKh}
                            </Text>
                        ),
                        sections: [
                            {
                                id: "departmentDirector",
                                header: "",
                                content: item => <Flex alignItems="center">
                                    {item?.peoples?.map((p) =>
                                        <Popover
                                            dismissButton={false}
                                            position="bottom"
                                            size="small"
                                            triggerType="custom"
                                            content={<>
                                                {getDisplayName(p?.user)}
                                                <Button
                                                    iconName="remove"
                                                    variant="icon"
                                                    onClick={() => {
                                                        removeUser({
                                                            variables: {
                                                                id: p?.user?.id,
                                                                type: "office"
                                                            }
                                                        })
                                                    }}
                                                />
                                            </>}
                                        >
                                            <Avatar cursor="pointer" size="xs" mr="2" name={getDisplayName(p?.user)} src={c.baseFilePath + p?.user?.avatar?.icon} />
                                        </Popover>
                                    )}
                                    <Button
                                        size="xs"
                                        onClick={() => {
                                            setSelectUserModalOpen(true)
                                            setSelectingOffice(item?.value)
                                        }}
                                        iconName="add-plus"
                                        variant="icon"
                                    />
                                </Flex>
                            }
                        ]
                    }}
                    cardsPerRow={[
                        { cards: 1 },
                        { minWidth: 500, cards: 2 }
                    ]}
                    items={officeData}
                    loadingText={t("Loading")}
                    loading={false}
                    trackBy="value"
                />
            </Modal>

            {selectUserModalOpen && (
                <SelectUserModal
                    role="companySubmissionProposal"
                    callback={e => addUser({
                        variables: { id: e?.id, type: "office" }
                    })}
                    isOpen={selectUserModalOpen}
                    onClose={() => setSelectUserModalOpen(false)}
                    departments={CURRENT_USER_DEPARTMENT}
                    offices={selectingOffice}
                />
            )}
        </>
    );
}
