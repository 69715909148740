import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
    Container, Box, Flex, Text, Button, Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td, Center
} from "@chakra-ui/react";
import BreadcrumbComponent from "../../../components/BreadcrumbComponent/BreadcrumbComponent";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import LoadingTable from "../../../components/Table/Loading";
import moment from "moment"
import useStore from "../../../store";
import { useLocation, useHistory } from "react-router-dom";
import c from "../../../constant"
import { gql, useMutation, useLazyQuery } from '@apollo/client';



const QUERY_DATA = gql`
    query{
        getAnnouncementList{
            id
            title
            description
            articleId
        }
    }
`

function ProductList() {
    const { t } = useTranslation();
    const { currentLang } = useStore((state) => state.currentLang);
    const history = useHistory();

    const [loadData, { error, loading, data }] = useLazyQuery(QUERY_DATA)
    moment.locale(currentLang === "kh" ? "km" : "en-gb")

    useEffect(() => {
        loadData()
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Container maxW="container.xl" mt="16px" mb="16px">
                <BreadcrumbComponent
                    list={[
                        {
                            name: "Settings",
                            path: "/settings",
                        },
                        {
                            name: "Announcement",
                            path: "#",
                        },
                    ]}
                />
                <Center mt="5" >

                    <Box
                        w="100%"
                        bg="white"
                        borderRadius={c.borderRadius}
                        border="1px solid #dbdbdb"
                        boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
                        pb="32px"
                    >
                        <Flex alignItems="center" justifyContent="space-between" bg="#FAFAFA" p="16px" pt="8px" pb="8px" mb="16px" borderBottom="1px solid #dbdbdb">
                            <Flex justifyContent="center" alignItems="center">
                                <Text
                                    fontSize="x-large"
                                    fontWeight="bold"
                                    mr="4"
                                >
                                    {t("Announcement")}
                                </Text>
                            </Flex>
                            <Link to={`/announcement/add`}>
                                <Flex
                                    cursor="pointer"
                                    alignItems="center"
                                    justifyContent="center"
                                    w="32px"
                                    h="32px"
                                    borderRadius={c.borderRadius}
                                    border="1px solid #dbdbdb"
                                    boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
                                >
                                    <Text fontSize="26px" mt="2px" fontWeight="500">
                                        +
                                    </Text>
                                </Flex>
                            </Link>

                        </Flex>
                        <Table variant="simple">
                            <Tbody>
                                <Tr className="td-as-th">
                                    <Th>{t("Title")}</Th>
                                    <Th>{t("Description")}</Th>
                                    <Th>{t("Article ID")}</Th>
                                </Tr>
                                {loading && <LoadingTable column={9} />}
                                {
                                    data?.getAnnouncementList?.length <= 0 && <Tr>
                                        <Td isNumeric><Text>{t("No Data")}</Text></Td>
                                    </Tr>
                                }
                                {data?.getAnnouncementList?.map((item, index) => {
                                    return (
                                        <Tr cursor="pointer" _hover={{ bg: "gray.200" }} key={`item-${index}`} >
                                            <Td>{item?.title}</Td>
                                            <Td>{item?.description}</Td>
                                            <Td>{item?.articleId}</Td>
                                        </Tr>
                                    )
                                })}
                            </Tbody>
                            <Tfoot className="hidden-print">
                                <Tr>
                                    <Th>{t("Title")}</Th>
                                    <Th>{t("Description")}</Th>
                                    <Th>{t("Article ID")}</Th>
                                </Tr>


                            </Tfoot>
                        </Table>

                    </Box>
                </Center>
            </Container>
        </>
    );
}

export default ProductList;

