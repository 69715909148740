import create from 'zustand';

const useStore = create((set) => ({
	branchValidityAlertCount: 0,
	currentUser: {},
	currentLang: 'en',
	countUnreadNotifications: 0,
	fileViewerLoadedPercentage: 0,
	setCurrentUser: (currentUser) => set((state) => ({ currentUser: { ...state.currentUser, currentUser } })),
	setCurrentLang: (currentLang) => set((state) => ({ currentLang: { ...state.currentLang, currentLang } })),
	setCountUnreadNotifications: (countUnreadNotifications) =>
		set((state) => ({ countUnreadNotifications: { ...state.countUnreadNotifications, countUnreadNotifications } })),
	setFileViewerLoadedPercentage: (fileViewerLoadedPercentage) =>
		set((state) => ({ fileViewerLoadedPercentage: { ...state.fileViewerLoadedPercentage, fileViewerLoadedPercentage } })),
	setBranchValidityAlertCount: (branchValidityAlertCount) => {
		set((state) => ({ branchValidityAlertCount: { ...state.branchValidityAlertCount, branchValidityAlertCount } }));
	},
}));

export default useStore;
