import React, { useEffect, useState } from "react";
import {
    Flex,
    Text,
    Box,
    Button,
    Alert,
    AlertTitle,
    AlertDescription,
    CloseButton,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from "@chakra-ui/react";
import useStore from "../store";
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { PhoneIcon, AddIcon, WarningIcon } from '@chakra-ui/icons'
import c from "../constant"
import { useTranslation } from "react-i18next";
import ViewArticle from "./Article/index"
const GET_ANN_QUERY = gql`
query get($id:String!){
	getAnnouncementById(id:$id){
        id
        title
        description
        articleId
    }
}
`


const SEEN_ANN_MUTATION = gql`
	mutation{
		seenAnnouncement
	}
`
function Announcement({ id }) {
    const { currentLang } = useStore((state) => state.currentLang);
    const {
        isOpen: isVisible,
        onClose,
        onOpen,
    } = useDisclosure({ defaultIsOpen: true })
    const [hideBox, setHideBox] = useState(false)
    const { t } = useTranslation();

    const [loadAnn, { error, loading, data }] = useLazyQuery(GET_ANN_QUERY)
    const [seenAnn, { error: errorSeenAnn, loading: loadingSeenAnn, data: dataSeenAnn }] = useMutation(SEEN_ANN_MUTATION)

    const [viewArticle, setViewArticle] = useState(false)
    useEffect(() => {
        if (id) {
            loadAnn({
                variables: { id }
            })
        }
    }, [id])

    useEffect(() => {
        if (dataSeenAnn?.seenAnnouncement) {
            setHideBox(true)
        }
    }, [dataSeenAnn])

    return (
        <>
            {(data?.getAnnouncementById?.id && !hideBox) ?
                <Alert status='info' variant='left-accent'>
                    <WarningIcon color="#3181CE" fontSize="24px" mr="16px" />
                    <Box w="100%">
                        <AlertTitle>{data?.getAnnouncementById?.title}</AlertTitle>
                        <AlertDescription>
                            <Text mr="4">{data?.getAnnouncementById?.description}</Text>
                            <Button borderRadius={c.borderRadius} mt="2" onClick={() => setViewArticle(true)}>{t("View Info")}</Button>
                        </AlertDescription>
                    </Box>
                    <CloseButton
                        alignSelf='flex-start'
                        position='relative'
                        right={-1}
                        top={-1}
                        onClick={() => {
                            seenAnn()
                            onClose()
                        }}
                    />
                </Alert>
                : null}

            {viewArticle && <Modal scrollBehavior="inside" size="6xl" isOpen={true} onClose={() => setViewArticle(false)}>
                <ModalOverlay
                    backdropFilter='auto'
                    bg='blackAlpha.300'
                    backdropBlur='2px'
                />
                <ModalContent borderRadius={c.borderRadius}>
                    <ModalHeader>{t("View Info")}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <ViewArticle id={data?.getAnnouncementById?.articleId} />
                    </ModalBody>
                </ModalContent>
            </Modal>}
        </>
    );
}

export default Announcement;
