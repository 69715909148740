/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import {
	Flex,
	Box,
	Center,
	Tooltip,
	Text,
	Table,
	Tbody,
	Thead,
	Tfoot,
	Tr,
	Th,
	Td,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	AlertDialog,
	AlertDialogOverlay,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogBody,
	AlertDialogFooter,
	Button,
	useToast,
} from '@chakra-ui/react';
import { SectionHeader } from '../../Other';
import { useTranslation } from 'react-i18next';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import c from '../../../constant';
import useStore from '../../../store';
import AddCompanyContactStaffModal from '../../../components/Modal/AddCompanyStaff';
import EditCompanyContactStaffModal from '../../../components/Modal/EditCompanyContactStaffModal';
import LoadingTable from '../../Table/Loading';
import ContactStaffPrint from './ContactStaffPrint';

const Q_GET_COMPANY_CONSTACT_STAFFS = gql`
	query GetCompanyStaff($id: String!) {
		getCompanyStaff(companyId: $id) {
			id
			name
			gender
			phoneNumber
			email
			createdAt
		}
	}
`;

const M_DELETE_COMPANY_CONTACT_STAFF = gql`
	mutation delete($id: String!) {
		deleteCompanyStaff(id: $id)
	}
`;

let workingId = null;

function CompanyContactStaff({ id, ...props }) {
	const deleteDialogRef = useRef();
	const deleteDialogCancelRef = useRef();
	const toast = useToast();
	const { t } = useTranslation();
	const { currentUser } = useStore((state) => state.currentUser);
	const [isOpeningInsertDialog, setOpeningInsertDialog] = useState(false);
	const [isOpeningDeleteDialog, setOpeningDeleteDialog] = useState(false);
	const [isOpeningEditDialog, setOpeningEditDialog] = useState(false);
	const [loadCompanyContactStaffs, { loading: loadingCompanyContactStaffs, error: errorCompanyContactStaffs, data: dataCompanyContactStaffs }] =
		useLazyQuery(Q_GET_COMPANY_CONSTACT_STAFFS);
	const [
		deleteCompanyContactStaff,
		{ loading: loadingDeleteCompanyContactStaff, error: errorDeleteCompanyContactStaff, data: dataDeleteCompanyContactStaff },
	] = useMutation(M_DELETE_COMPANY_CONTACT_STAFF);

	useEffect(() => {
		if (id) {
			const variables = { id };
			loadCompanyContactStaffs({ variables });
		}
	}, [id]);

	useEffect(() => {
		if (errorCompanyContactStaffs) {
			errorCompanyContactStaffs.graphQLErrors.map(({ message }, i) =>
				toast({
					title: t(message),
					status: 'error',
					isClosable: true,
					position: 'top-right',
				})
			);
		}
	}, [errorCompanyContactStaffs]);

	useEffect(() => {
		if (dataDeleteCompanyContactStaff?.deleteCompanyStaff) {
			toast({
				title: t('Company staff deleted successfully'),
				status: 'success',
				isClosable: true,
				position: 'top-right',
			});
			const variables = { id };
			loadCompanyContactStaffs({ variables });
			onRejectDeleteDialog();
		}
	}, [dataDeleteCompanyContactStaff]);

	useEffect(() => {
		if (errorDeleteCompanyContactStaff) {
			errorDeleteCompanyContactStaff.graphQLErrors.map(({ message }, i) =>
				toast({
					title: t(message),
					status: 'error',
					isClosable: true,
					position: 'top-right',
				})
			);
		}
	}, [errorDeleteCompanyContactStaff]);

	function formatPhoneNumber(x) {
		if (x) {
			return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
		}
		return '';
	}

	function ActionMenuShareholder({ id }) {
		return (
			<Menu>
				<Tooltip label={`${t('Delete')}`}>
					<MenuButton cursor="pointer">
						<Flex pl="2" pr="2">
							<FontAwesomeIcon icon={faEllipsisV} style={{ fontSize: 16, marginTop: 4, marginLeft: 6 }} />
						</Flex>
					</MenuButton>
				</Tooltip>
				<MenuList pt="4" pb="4" pl="3" pr="3" borderRadius={c.borderRadius}>
					<MenuItem
						color={'red'}
						onClick={() => {
							workingId = id;
							setOpeningDeleteDialog(true);
						}}
					>
						{t('Delete')}
					</MenuItem>
				</MenuList>
			</Menu>
		);
	}

	function onRejectInsertDialog() {
		setOpeningInsertDialog(false);
	}

	function onApproveInsertDialog() {
		if (id) {
			const variables = { id };
			loadCompanyContactStaffs({ variables });
		}
	}

	function onRejectDeleteDialog() {
		setOpeningDeleteDialog(false);
	}

	function onApproveDeleteDialog() {
		const variables = { id: workingId };
		deleteCompanyContactStaff({ variables });
	}

	function editContactStaff(id) {
		if (currentUser?.role === 'admin' || currentUser?.canUpdateCompanyContact) {
			workingId = id;
			setOpeningEditDialog(true);
		}
	}

	function onRejectEditDialog() {
		setOpeningEditDialog(false);
	}

	function onApproveEditDialog() {
		const variables = { id };
		loadCompanyContactStaffs({ variables });
	}

	return (
		<>
			<Flex className="hidden-print" mt={6} {...props}>
				<Box width="100%">
					<SectionHeader title={t('Contact Staff')} />
					<Center mt={4}>
						<Box
							width="100%"
							bg="white"
							borderRadius={c.borderRadius}
							border="1px solid #dbdbdb"
							boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
							pb={2}
						>
							<Flex p={4} pt={2} pb={2} mb={4} h="48px" bg="#FAFAFA" alignItems="center" justifyContent="end" borderBottom="1px solid #dbdbdb">
								<Flex alignItems="center">
									{(currentUser?.role === 'admin' || currentUser?.canUpdateCompanyContact) && (
										<Tooltip label={t('Add Staff')}>
											<Flex
												className="hidden-print"
												userSelect="none"
												onClick={() => setOpeningInsertDialog(true)}
												cursor="pointer"
												alignItems="center"
												justifyContent="center"
												w="32px"
												h="32px"
												borderRadius={c.borderRadius}
												border="1px solid #dbdbdb"
												boxShadow="0 1px 2px rgba(var(--black, #000), 0.1)"
											>
												<Text fontSize="26px" mt="2px" fontWeight="500">
													+
												</Text>
											</Flex>
										</Tooltip>
									)}
								</Flex>
							</Flex>
							<Box p="16px">
								<Table variant="simple">
									<Thead>
										<Tr>
											<Th>{t('Name')}</Th>
											<Th>{t('Gender')}</Th>
											<Th>{t('Phone number')}</Th>
											<Th>{t('Email')}</Th>
											{(currentUser?.role === 'admin' || currentUser?.canUpdateCompanyContact) && (
												<Th className="hidden-print" isNumeric>
													{t('Actions')}
												</Th>
											)}
										</Tr>
									</Thead>
									<Tbody>
										{loadingCompanyContactStaffs && <LoadingTable column={5} row={3} />}
										{dataCompanyContactStaffs?.getCompanyStaff.length <= 0 && (
											<Tr>
												<Td>
													<Text>{t('No Data')}</Text>
												</Td>
											</Tr>
										)}
										{dataCompanyContactStaffs?.getCompanyStaff?.map((item, index) => {
											return (
												<Tr cursor="pointer" _hover={{ bg: 'gray.200' }} key={`item-${index}`} bg="white">
													<Td onClick={() => editContactStaff(item?.id)}>{item.name}</Td>
													<Td onClick={() => editContactStaff(item?.id)}>{t(item.gender)}</Td>
													<Td onClick={() => editContactStaff(item?.id)}>{formatPhoneNumber(item?.phoneNumber)}</Td>
													<Td onClick={() => editContactStaff(item?.id)}>{t(item?.email)}</Td>
													{(currentUser?.role === 'admin' || currentUser?.canUpdateCompanyContact) && (
														<Td className="hidden-print" isNumeric>
															<ActionMenuShareholder id={item?.id} />
														</Td>
													)}
												</Tr>
											);
										})}
									</Tbody>
									<Tfoot>
										<Tr>
											<Th>{t('Name')}</Th>
											<Th>{t('Gender')}</Th>
											<Th>{t('Phone number')}</Th>
											<Th>{t('Email')}</Th>
											{(currentUser?.role === 'admin' || currentUser?.canUpdateCompanyContact) && (
												<Th className="hidden-print" isNumeric>
													{t('Actions')}
												</Th>
											)}
										</Tr>
									</Tfoot>
								</Table>
							</Box>
						</Box>
					</Center>
				</Box>
			</Flex>
			<AlertDialog isOpen={isOpeningDeleteDialog} leastDestructiveRef={deleteDialogRef} onClose={onRejectDeleteDialog}>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							{t('Delete Company Staff')}
						</AlertDialogHeader>
						<AlertDialogBody>{t("Are you sure? You can't undo this action afterwards")}</AlertDialogBody>
						<AlertDialogFooter>
							<Button mr={4} isLoading={loadingDeleteCompanyContactStaff} ref={deleteDialogCancelRef} onClick={onRejectDeleteDialog}>
								{t('Cancel')}
							</Button>
							<Button isLoading={loadingDeleteCompanyContactStaff} colorScheme="red" onClick={onApproveDeleteDialog}>
								{t('Delete')}
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
			<AddCompanyContactStaffModal companyId={id} isOpen={isOpeningInsertDialog} onClose={onRejectInsertDialog} callback={onApproveInsertDialog} />
			{isOpeningEditDialog && <EditCompanyContactStaffModal id={workingId} isOpen={true} onClose={onRejectEditDialog} callback={onApproveEditDialog} />}
			{dataCompanyContactStaffs && <ContactStaffPrint dataCompanyContactStaffs={dataCompanyContactStaffs} />}
		</>
	);
}

export default CompanyContactStaff;
